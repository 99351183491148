import { IButtonStyles, ITheme } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../theme';
import { IFormSectionStyleProps, IFormSectionStyles } from './FormSection.types';

export const getStyles = (props: IFormSectionStyleProps): IFormSectionStyles => {
  const { sectionWidth, expanded, theme, spaceBetween, hasBorderBottom = true } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const defaultWidth = 644;

  return {
    root: {
      width: sectionWidth || defaultWidth,
      borderTopWidth: 1,
      borderBottomWidth: hasBorderBottom ? 1 : 0,
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderColor: theme.palette.neutralLight,
      selectors: {
        $content: {
          display: expanded ? 'block' : 'none',
        },
      },
    },
    title: [
      'ms-FormSectionTitle',
      {
        display: 'flex',
        justifyContent: spaceBetween ? 'space-between' : 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.palette.white,
        height: '42px',
        cursor: 'pointer',
        selectors: {
          ':hover': {
            backgroundColor: theme.palette.neutralLighter,
          },
        },
      },
    ],
    content: [
      'ms-FormSectionContent',
      {
        backgroundColor: theme.palette.white,
        marginBottom: spacing.size32,
        height: 'inherit',
      },
    ],
    titleLabel: [
      'ms-fontWeight-semibold',
      {
        fontSize: theme.fonts.mediumPlus.fontSize,
      },
    ],
  };
};

export const iconButtonStyles = (theme: ITheme): IButtonStyles => {
  const spacing = theme.spacing as IPubcenterSpacing;
  return {
    root: [
      {
        paddingLeft: spacing.size8,
        paddingRight: spacing.size12,
        paddingTop: spacing.size16,
        paddingBottom: '14px',
        color: theme.palette.neutralSecondary,
        fontSize: theme.fonts.small.fontSize,
      },
    ],
  };
};
