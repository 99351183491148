import { Stack } from '@fluentui/react';
import React, { useRef } from 'react';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../utils';
import { ICustomCalenderDateRange } from '../components/CustomCalender/CustomCalender.types';
import {
  ADUNIT_ALLOWED_CHARTS,
  COUNTRY_ALLOWED_CHARTS,
  MEDIATION_ALLOWED_CHARTS,
  PERFORMANCE_ALLOWED_CHARTS,
  SITE_ALLOWED_CHARTS,
} from '../constants/allowedChartsType';
import { useMediationAvailable } from '../hooks/useMediationAvailable';
import { useMediationReportMetadata, useReportMetadata, useReportQuery } from '../hooks/useReportQuery';
import { AdUnitsReportCard } from './AdUnitsReportCard/AdUnitsReportCard';
import { CountryReportCard } from './CountryReportCard/CountryReportCard';
import { MediationReportCard } from './MediationReportCard/MediationReportCard';
import { MetricsSummaryCards } from './MetricsSummaryCards/MetricsSummaryCards';
import { PerformanceReportCard } from './PerformanceReportCard/PerformanceReportCard';
import { constructRequestBody } from './ReportCard/ReportCard.helpers';
import { ChartGrain, ReportCardType } from './ReportCard/ReportCard.types';
import { SitesReportCard } from './SitesReportCard/SitesReportCard';
import { getClassNames, getStackTokens } from './TythonReportCards.styles';
export interface IDashboardCardsProps extends IRouterWithIntl {
  dateRange: ICustomCalenderDateRange;
}

export const TythonReportCards = injectIntlAndObserver((props: IDashboardCardsProps) => {
  const { dateRange } = props;
  const classNames = getClassNames(props);
  const { isMediationAvailable } = useMediationAvailable();
  const { data: reportMetadata } = useReportMetadata();
  const { data: mediationReportMetadata } = useMediationReportMetadata(!!isMediationAvailable);
  const adunitReportCardRef = useRef<HTMLDivElement>(null);
  const sitesReportCardRef = useRef<HTMLDivElement>(null);
  const performanceReportCardRef = useRef<HTMLDivElement>(null);
  const mediationReportCardRef = useRef<HTMLDivElement>(null);
  const countryReportCardRef = useRef<HTMLDivElement>(null);

  const { data: summaryData } = useReportQuery(
    constructRequestBody(ReportCardType.SUMMARY, dateRange, ChartGrain.NONE, [], [], reportMetadata?.metrics || []),
    false,
    !!reportMetadata
  );

  return (
    <>
      <Stack tokens={getStackTokens()}>
        <MetricsSummaryCards summaryData={summaryData} reportMetadata={reportMetadata} />
        <Stack>
          <Stack.Item grow basis={'100%'}>
            <PerformanceReportCard
              dateRange={dateRange}
              summaryData={summaryData}
              allowedChartTypes={PERFORMANCE_ALLOWED_CHARTS}
              showDetailsReportLink={true}
              isDetailedView={false}
              filters={[]}
              history={props.history}
              location={props.location}
              match={props.match}
              reportCardRef={performanceReportCardRef}
              reportMetadata={reportMetadata}
            />
          </Stack.Item>
        </Stack>
        <Stack tokens={getStackTokens()}>
          <div className={classNames.reportCard}>
            <div>
              <AdUnitsReportCard
                dateRange={dateRange}
                allowedChartTypes={ADUNIT_ALLOWED_CHARTS}
                showDetailsReportLink={true}
                isDetailedView={false}
                filters={[]}
                history={props.history}
                location={props.location}
                match={props.match}
                reportCardRef={adunitReportCardRef}
                reportMetadata={reportMetadata}
              />
            </div>
            <div>
              <SitesReportCard
                dateRange={dateRange}
                allowedChartTypes={SITE_ALLOWED_CHARTS}
                showDetailsReportLink={true}
                isDetailedView={false}
                filters={[]}
                history={props.history}
                location={props.location}
                match={props.match}
                reportCardRef={sitesReportCardRef}
                reportMetadata={reportMetadata}
              />
            </div>
            <div>
              <CountryReportCard
                dateRange={dateRange}
                allowedChartTypes={COUNTRY_ALLOWED_CHARTS}
                showDetailsReportLink={true}
                isDetailedView={false}
                filters={[]}
                history={props.history}
                location={props.location}
                match={props.match}
                reportCardRef={countryReportCardRef}
                reportMetadata={reportMetadata}
              />
            </div>
            {isMediationAvailable && (
              <div>
                <MediationReportCard
                  dateRange={dateRange}
                  allowedChartTypes={MEDIATION_ALLOWED_CHARTS}
                  showDetailsReportLink={true}
                  isDetailedView={false}
                  filters={[]}
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  reportCardRef={mediationReportCardRef}
                  reportMetadata={mediationReportMetadata}
                />
              </div>
            )}
          </div>
        </Stack>
      </Stack>
    </>
  );
});
