import { IFormLabelStyleProps, IFormLabelStyles } from './FormLabel.types';

export const getStyles = (props: IFormLabelStyleProps): IFormLabelStyles => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    subComponentStyles: {
      Label: {
        root: ['ms-fontWeight-semibold'],
      },
    },
  };
};
