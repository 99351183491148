import { FontSizes, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../theme';
import { IOptionCardStyle } from './OptionCard.types';
const theme = (getTheme() as unknown) as IPubcenterTheme;
const spacing = theme.spacing as IPubcenterSpacing;
const palette = theme.palette as IPubcenterPalette;
export const getStyles = (isSelected: boolean): IOptionCardStyle => {
  const font = FontSizes;

  return mergeStyleSets({
    contentStyle: {},
    contentInfoStyle: {
      fontSize: font.size14,
      ...(!isSelected && { color: palette.neutralForeground2Rest }),
      textAlign: 'left',
      padding: `${spacing.size4} ${spacing.size12} ${spacing.size12} ${spacing.size12}`,
      lineHeight: theme.fonts.size14.lineHeight,
    },
    subContentStyle: {
      textAlign: 'left',
      fontSize: font.size12,
      lineHeight: theme.fonts.size12.lineHeight,
      padding: `0 ${spacing.size12} ${spacing.size12} ${spacing.size12}`,
      color: palette.neutralForeground3Rest,
    },
    contentMoreStyle: {
      display: 'flex',
      alignItems: 'center',
      gap: spacing.size8,
      textAlign: 'left',
      fontSize: font.size12,
      lineHeight: theme.fonts.size12.lineHeight,
      padding: `${spacing.size4} ${spacing.size12} ${spacing.size12} ${spacing.size12}`,
    },
    contentMoreInfoStyle: {
      color: palette.neutralForeground1Rest,
    },
    infoIconStyle: {
      alignSelf: 'start',
      fontSize: font.size16,
      color: palette.neutralForeground3Rest,
    },
    helperLink: {
      position: 'relative',
      zIndex: 1,
      paddingLeft: spacing.size2,
    },
  });
};
