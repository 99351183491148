import { getTheme, Spinner, Toggle } from '@fluentui/react';
import React, { useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { injectIntlAndObserver } from '../../../../../../utils';
import { getClassNames } from '../../../Create/CreateAdunit';
import messages from '../../CreateAdunit/messages';
import { getStyles } from '../../CreateAdunit/styles';
import { ITythonAdUnit } from '../../CreateAdunit/types';
import { OptOutMediationPopup } from './DisablingMediationPopup';

export interface IAdUnitMediationSelectorProps {
  activeAdunit?: ITythonAdUnit;
  shouldRenderComponent: boolean;
  toggleIsLoading: boolean;
  onAdunitMediationChange: (active: boolean) => void;
}

const AdUnitMediationSelector = injectIntlAndObserver(function DisablingMediationComponent({
  intl: { formatMessage },
  activeAdunit,
  shouldRenderComponent,
  toggleIsLoading,
  onAdunitMediationChange,
}: IAdUnitMediationSelectorProps & InjectedIntlProps): JSX.Element | null {
  const [shouldRenderMediationOptOutPopUp, setShouldRenderMediationOptOutPopUp] = useState(false);

  const theme = getTheme();
  const classNames = getClassNames(getStyles, { theme });

  if (!shouldRenderComponent) {
    return null;
  }

  const popupProps = {
    isShown: shouldRenderMediationOptOutPopUp,
    onDismiss: () => setShouldRenderMediationOptOutPopUp(false),
    onPrimaryClick: () => {
      onAdunitMediationChange(false);
      setShouldRenderMediationOptOutPopUp(false);
    },
    onSecondaryClick: () => setShouldRenderMediationOptOutPopUp(false),
  };

  return (
    <>
      <OptOutMediationPopup popupProps={popupProps} />
      <div className={classNames.mediationHeader}>{formatMessage(messages.mediationHeader)}</div>
      <div>
        <p className={classNames.mediationDescriptionWrapper}>{formatMessage(messages.mediationDescription)}</p>
      </div>
      <div className={classNames.mediationToggle}>
        <p>{formatMessage(messages.mediationToggleLabel)}</p>
        {toggleIsLoading ? (
          <Spinner />
        ) : (
          <Toggle
            ariaLabel={formatMessage(messages.mediationToggleLabel)}
            checked={activeAdunit?.mediationEnabled === true}
            title="mediationToggle"
            onChange={(_, state) => {
              if (!state) {
                setShouldRenderMediationOptOutPopUp(true);
              } else {
                onAdunitMediationChange(state);
              }
            }}
          />
        )}
      </div>
    </>
  );
});

export default AdUnitMediationSelector;
