import { MessageBarType } from '@fluentui/react';
import { runInAction } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { GenerateDevToken, Model, User, getAppStore, getEntity, onNotificationChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { DevTokenFetch } from '../../Manage/ManagePage.types';
import { onGenerateDevTokenStatus } from '../actions';
import { getStore } from '../store/store';

orchestrator(onGenerateDevTokenStatus, async (msg) => {
  const store = getStore();
  const selectedItem = msg.selectedItem as Model;
  let notification: INotificationBarItem = {};

  if (store && selectedItem) {
    const { userContext, publisher } = getAppStore();
    if (userContext && publisher) {
      try {
        const userId = selectedItem.id;
        const user = new User(userId);
        Log.debug('Generating a new dev token for user ' + userId);

        // While awaiting the call to generate new dev token, the intermediate value of token is set to undefined
        // This facilitates the spinner to show up with the following text
        runInAction(() => {
          if (store.devTokenPanelParams) {
            store.devTokenPanelParams.developerToken = undefined;
            store.devTokenPanelParams.SpinnerText = 'Generating Developer Token...';
          }
        });

        await getEntity([publisher, user], userContext, GenerateDevToken).then((response) => {
          runInAction(() => {
            const generatedToken = response.toString();
            Log.info('Generated dev token: ' + generatedToken);
            if (store.devTokenPanelParams) {
              store.devTokenPanelParams.developerToken = generatedToken;
            }
          });
        });
        notification = {
          text: 'Developer token has been successfully generated for the selected user ' + userId,
          messageBarType: MessageBarType.success,
        };
      } catch (exception) {
        notification = {
          text: `Generating the token raised an error "${exception}"`,
          messageBarType: MessageBarType.error,
        };
        // This will make the UI show an error message instead of infinite spinner
        runInAction(() => {
          if (store.devTokenPanelParams) {
            store.devTokenPanelParams.developerToken = DevTokenFetch.ApiCallFailed;
          }
        });
        Log.error('Generating the token raised an error ' + exception.toString());
      }
      onNotificationChanged(notification);
    }
  }
});
