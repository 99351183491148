import { defineMessages } from 'react-intl';

export default defineMessages({
  accountType: {
    id: 'signup.accountType',
    defaultMessage: 'Account Type',
  },
  signupDetails: {
    id: 'signup.signupDetails',
    defaultMessage: 'Sign-up details',
  },
  finish: {
    id: 'signup.finish',
    defaultMessage: 'Finish',
  },
  acctTitle: {
    id: 'signup.accountType.title',
    defaultMessage: 'Which account type would you like to create?',
  },
  acctContent: {
    id: 'signup.accountType.content',
    defaultMessage:
      'You can’t change the account type after you sign up. We recommend that you don’t use your personal Microsoft account to register an organization account type. ',
  },
  organizationContent: {
    id: 'signup.accountType.organization.content',
    defaultMessage: 'Sign up with a Microsoft account connected to your business or organization.',
  },
  individualContent: {
    id: 'signup.accountType.individual.content',
    defaultMessage: 'Sign up with your individual Microsoft account.',
  },
  organizationSubContentI: {
    id: 'signup.accountType.organization.subcontentI',
    defaultMessage: 'Ideal for multiple accounts.',
  },
  organizationSubContentII: {
    id: 'signup.accountType.organization.subcontentII',
    defaultMessage: 'Requires business entity verification.',
  },
  individualSubContentI: {
    id: 'signup.accountType.individual.subcontentI',
    defaultMessage: 'Ideal for self-management',
  },
  individualSubContentII: {
    id: 'signup.accountType.individual.subcontentII',
    defaultMessage: 'Requires personal information verification  ',
  },
  moreInfo: {
    id: 'signup.accountType.moreInfo',
    defaultMessage: 'More info',
  },
  back: {
    id: 'signup.backButton',
    defaultMessage: 'Back',
  },
  ready: {
    id: 'signup.readyToGoButton',
    defaultMessage: `Let's go`,
  },
  joinTheWaitlist: {
    id: 'signup.joinTheWaitlist',
    defaultMessage: 'Join the waitlist',
  },
  waitlistPopupDesc: {
    id: 'signup.waitlistPopupDesc',
    defaultMessage: `It looks like you're not located in the US. {appName} is only available in the US, but we're expanding globally soon. Please join our waitlist, and we'll contact you as soon as the service is available in your country.`,
  },
  waitlistTermsMessage: {
    id: 'signup.waitlistTermsMessage',
    defaultMessage: 'I agree to give Microsoft permission to contact me about my interest in joining {appName}.',
  },
  joinWaitlistButtonName: {
    id: 'signup.joinWaitlistButtonName',
    defaultMessage: 'Join waitlist',
  },
  signupFailedToLoadEntity: {
    id: 'signup.failedToLoadEntity',
    defaultMessage: 'Failed to load entity',
  },
  signupFixInvalidFields: {
    id: 'signup.fixInvalidFields',
    defaultMessage: 'You have {count} invalid field(s), please fix to continue.',
  },
});
