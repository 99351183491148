import { BrowserAuthOptions } from '@azure/msal-browser';
import { IDictionary } from '../types';

type AuthOptions = BrowserAuthOptions & { msaTenantId: string; msaAuthority: string; devToken: string; redirectUri: string };

const localConfig1: AuthOptions = {
  authority: 'https://login.windows-ppe.net/common',
  clientId: 'bbd8c357-c363-4d20-9e4a-2c98f7977eab', // PubCenter-UI (PPE)
  postLogoutRedirectUri: 'http://localhost:3000',
  redirectUri: 'http://localhost:3000/login',
  msaTenantId: '4925308c-f164-4d2d-bc7e-0631132e9375',
  msaAuthority: 'https://login.live-int.com',
  devToken: 'BBD37VB98',
};

const localConfig2: AuthOptions = {
  authority: 'https://login.windows-ppe.net/common',
  clientId: 'bbd8c357-c363-4d20-9e4a-2c98f7977eab', // PubCenter-UI (PPE)
  postLogoutRedirectUri: 'http://localhost:3001',
  redirectUri: 'http://localhost:3001/login',
  msaTenantId: '4925308c-f164-4d2d-bc7e-0631132e9375',
  msaAuthority: 'https://login.live-int.com',
  devToken: 'BBD37VB98',
};

const localConfig: AuthOptions = window.location.host.indexOf('3000') > -1 ? localConfig1 : localConfig2;

const devConfigOnline: AuthOptions = {
  authority: 'https://login.windows-ppe.net/common',
  clientId: 'bbd8c357-c363-4d20-9e4a-2c98f7977eab', // PubCenter-UI (PPE)
  postLogoutRedirectUri: 'https://pubcenter.azurewebsites.net',
  redirectUri: 'https://pubcenter.azurewebsites.net/login',
  msaTenantId: '4925308c-f164-4d2d-bc7e-0631132e9375',
  msaAuthority: 'https://login.live-int.com',
  devToken: '#{devToken_dev}#',
};

const devConfigOffline: AuthOptions = {
  authority: 'https://login.windows-ppe.net/common',
  clientId: 'bbd8c357-c363-4d20-9e4a-2c98f7977eab', // PubCenter-UI (PPE)
  postLogoutRedirectUri: 'https://pubcenter2.azurewebsites.net',
  redirectUri: 'https://pubcenter2.azurewebsites.net/login',
  msaTenantId: '4925308c-f164-4d2d-bc7e-0631132e9375',
  msaAuthority: 'https://login.live-int.com',
  devToken: '#{devToken_dev}#',
};

const devConfigNewDomain: AuthOptions = {
  authority: 'https://login.windows-ppe.net/common',
  clientId: 'bbd8c357-c363-4d20-9e4a-2c98f7977eab', // PubCenter-UI (PPE)
  postLogoutRedirectUri: 'https://si.monetizenow.microsoft.com',
  redirectUri: 'https://si.monetizenow.microsoft.com/login',
  msaTenantId: '4925308c-f164-4d2d-bc7e-0631132e9375',
  msaAuthority: 'https://login.live-int.com',
  devToken: '#{devToken_dev}#',
};

const devConfigNewDomainOffline: AuthOptions = {
  authority: 'https://login.windows-ppe.net/common',
  clientId: 'bbd8c357-c363-4d20-9e4a-2c98f7977eab', // PubCenter-UI (PPE)
  postLogoutRedirectUri: 'https://offline.si.monetizenow.microsoft.com',
  redirectUri: 'https://offline.si.monetizenow.microsoft.com/login',
  msaTenantId: '4925308c-f164-4d2d-bc7e-0631132e9375',
  msaAuthority: 'https://login.live-int.com',
  devToken: '#{devToken_dev}#',
};

const devConfigMapping: IDictionary<AuthOptions> = {
  'pubcenter.azurewebsites.net': devConfigOnline,
  'pubcenter2.azurewebsites.net': devConfigOffline,
  'si.monetizenow.microsoft.com': devConfigNewDomain,
  'offline.si.monetizenow.microsoft.com': devConfigNewDomainOffline,
};

const devConfig: AuthOptions = devConfigMapping[window.location.host] || devConfigOnline;

const prodConfigOnline: AuthOptions = {
  authority: 'https://login.microsoftonline.com/common',
  clientId: '60e7cf7c-2b0d-468b-802d-99fa045382b8', // PubCenter-UI (PROD)
  postLogoutRedirectUri: 'https://next.pubcenter.microsoft.com',
  redirectUri: 'https://next.pubcenter.microsoft.com/login',
  msaTenantId: '9188040d-6c67-4c5b-b112-36a304b66dad',
  msaAuthority: 'https://login.live.com',
  devToken: '#{devToken_prod}#',
};

const prodConfigOffline: AuthOptions = {
  authority: 'https://login.microsoftonline.com/common',
  clientId: '60e7cf7c-2b0d-468b-802d-99fa045382b8', // PubCenter-UI (PROD)
  postLogoutRedirectUri: 'https://pubcenternext-staging.azurewebsites.net',
  redirectUri: 'https://pubcenternext-staging.azurewebsites.net/login',
  msaTenantId: '9188040d-6c67-4c5b-b112-36a304b66dad',
  msaAuthority: 'https://login.live.com',
  devToken: '#{devToken_prod}#',
};

const prodConfigNewDomain: AuthOptions = {
  authority: 'https://login.microsoftonline.com/common',
  clientId: '60e7cf7c-2b0d-468b-802d-99fa045382b8', // PubCenter-UI (PROD)
  postLogoutRedirectUri: 'https://monetizenow.microsoft.com',
  redirectUri: 'https://monetizenow.microsoft.com/login',
  msaTenantId: '9188040d-6c67-4c5b-b112-36a304b66dad',
  msaAuthority: 'https://login.live.com',
  devToken: '#{devToken_prod}#',
};

const prodConfigNewDomainStaging: AuthOptions = {
  authority: 'https://login.microsoftonline.com/common',
  clientId: '60e7cf7c-2b0d-468b-802d-99fa045382b8', // PubCenter-UI (PROD)
  postLogoutRedirectUri: 'https://staging.monetizenow.microsoft.com',
  redirectUri: 'https://staging.monetizenow.microsoft.com/login',
  msaTenantId: '9188040d-6c67-4c5b-b112-36a304b66dad',
  msaAuthority: 'https://login.live.com',
  devToken: '#{devToken_prod}#',
};

const prodConfigMapping: IDictionary<AuthOptions> = {
  'next.pubcenter.microsoft.com': prodConfigOnline,
  'pubcenternext-staging.azurewebsites.net': prodConfigOffline,
  'monetizenow.microsoft.com': prodConfigNewDomain,
  'staging.monetizenow.microsoft.com': prodConfigNewDomainStaging,
};

const prodConfig: AuthOptions = prodConfigMapping[window.location.host] || prodConfigOnline;

export const envBasedConfig =
  process.env.REACT_APP_ENV === 'production' ? prodConfig : process.env.REACT_APP_ENV === 'staging' ? devConfig : localConfig;

export const aadConfig = {
  auth: {
    ...envBasedConfig,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    tokenRenewalOffsetSeconds: 300,
  },
};

// Used only during login call, initially we need permissions for all these scopes from the user.
export const devPubCenterAuthTokenRequest = {
  scopes: ['openid', 'profile', 'offline_access', 'https://si.monetizenow.microsoft.com/ppe.pubcenter.api'],
};

// Used to fetch tokens before making API calls
// Token is either fetched from cache or renewed using refresh grant when access token is about to expire.
export const devSilentTokenRequest = {
  scopes: ['https://si.monetizenow.microsoft.com/ppe.pubcenter.api'],
};

export const prodPubCenterAuthTokenRequest = {
  scopes: ['openid', 'profile', 'offline_access', 'https://next.pubcenter.microsoft.com/prod.pubcenter.api'],
};

export const prodSilentTokenRequest = {
  scopes: ['https://next.pubcenter.microsoft.com/prod.pubcenter.api'],
};

// export const silentTokenRequestRedirectUri = envBasedConfig.postLogoutRedirectUri + '/auth.html'; // check why we added auth.html here ?
export const silentTokenRequestRedirectUri = envBasedConfig.postLogoutRedirectUri;

export const authTokenRequest = process.env.REACT_APP_ENV === 'production' ? prodPubCenterAuthTokenRequest : devPubCenterAuthTokenRequest;

export const silentTokenRequest = process.env.REACT_APP_ENV === 'production' ? prodSilentTokenRequest : devSilentTokenRequest;

export const corpTenantId =
  process.env.REACT_APP_ENV === 'production' ? '72f988bf-86f1-41af-91ab-2d7cd011db47' : 'f686d426-8d16-42db-81b7-ab578e110ccd';

export const accountEnvironment = process.env.REACT_APP_ENV === 'production' ? 'login.microsoftonline.com' : 'login.windows-ppe.net';
