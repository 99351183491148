import * as React from 'react';
import { useEffect, useState } from 'react';
import { IFormEditParams, IFormStateProps } from '../../../../../../../../components/Form';
import { StepState } from '../../../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../../../../../utils/ReactUtils';
import { getCreatePropertyStore } from '../../../../__data__/store/store';
import PropertyIdField from '../../../../components/PropertyIdField';
import PropertyNameInputField from '../../../../components/PropertyNameInputField';
import PropertyUrlInputField from '../../../../components/PropertyUrlInputField';
import { onFormInitialized } from '../../__data__/orchestrators';
import { ClarityOptions } from './components/ClarityOptions';

export interface IFormInitializationState {
  initializeForm?: boolean;
  id?: string;
}

export interface ICreatePropertyProps extends IRouterWithIntl<IFormEditParams>, IFormStateProps {
  stepState?: StepState;
}

export const CreateProperty = injectIntlWithObserver<ICreatePropertyProps & IFormInitializationState>((props) => {
  const { match, mode, stepState } = props;
  const { publisherId, accountId, id: entityId } = match.params;
  const { active } = getCreatePropertyStore();
  const [activePropertyId, setActivePropertyId] = useState(entityId);

  useEffect(() => {
    if (activePropertyId === entityId || publisherId === undefined) {
      return;
    }
    setActivePropertyId(entityId);
    onFormInitialized(mode, publisherId, accountId, entityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  useEffect(() => {
    if (publisherId === undefined) {
      return;
    }
    onFormInitialized(mode, publisherId, accountId, entityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PropertyNameInputField mode={mode} />
      <PropertyIdField id={active.id} />
      <PropertyUrlInputField mode={mode} stepState={stepState} />
      <ClarityOptions {...props} stepState={stepState} />
    </>
  );
});
