import { getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../../../theme';
import { ISignUpDetailsStyles } from './SignUpDetails.types';

export const getStyles = (): ISignUpDetailsStyles => {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { ResponsiveSmall } = BreakPoints;

  return mergeStyleSets({
    rootStyle: {
      position: 'relative',
      marginTop: spacing.size12,
      color: palette.black,
      width: '560px',
      left: 'auto',
      textAlign: 'left',
      [ResponsiveSmall]: {
        width: '100%',
      },
    },
    headerStyle: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '28px',
      marginBottom: '10px',
    },
    subheaderStyle: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      textAlign: 'left',
    },
    choiceGroupStyle: {
      marginBottom: spacing.size20,
      [ResponsiveSmall]: {
        width: 'calc(100% - 40px)',
      },
    },
    comboBoxStyle: {
      marginBottom: spacing.size20,
      width: '-webkit-fill-available',
      [ResponsiveSmall]: {
        width: 'calc(100% - 40px)',
      },
    },
    termsAndConditionsStyle: {
      marginTop: spacing.size20,
      marginBottom: spacing.size20,
      [ResponsiveSmall]: {
        width: 'calc(100% - 40px)',
      },
    },
    termsContainerStyle: {
      boxSizing: 'border-box',
      flexDirection: 'row',
      width: '560px',
      height: '136px',
      border: '1px solid #605E5C',
      borderRadius: '2px',
      overflowY: 'scroll',
      paddingLeft: '2%',
      paddingRight: '2%',
      textAlign: 'justify',
      [ResponsiveSmall]: {
        width: '100%',
        overflowX: 'hidden',
      },
    },
    messageBarStyle: {
      background: palette.themeLighterAlt,
      [ResponsiveSmall]: {
        width: 'calc(100% - 40px)',
      },
    },
    nameContainerStyle: {
      display: 'flex',
      flexDirection: 'row',
      gap: spacing.size16,
      [ResponsiveSmall]: {
        flexDirection: 'column',
        width: '100%',
      },
    },
    linksStyle: {
      color: palette.themeDarkAlt,
      lineHeight: spacing.size22,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    privacyLinkWrapper: {
      display: 'flex',
      gap: spacing.size2,
      marginTop: spacing.size12,
    },
    privacyLinkStyle: {
      color: palette.themeDarkAlt,
      lineHeight: spacing.size22,
      cursor: 'pointer',
    },
  });
};
