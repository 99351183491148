import { IStyle } from '@fluentui/react';
import { useBoolean, useId, useSetTimeout } from '@fluentui/react-hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { useI18n } from '../../../../../../i18n/useI18n';
import { getAppStore, onNotificationRemoved } from '../../../../../@data';
import { useWindowSize } from '../../../../../hooks';
import { getStore } from '../../../../../hooks/useWindowSize/store/store';
import { onInitializePaymentsPage } from '../../@data/actions/actions';
import { getAccountStatus } from '../../@data/store/selectors';
import { getStore as getAccountSettingStore } from '../../Settings/@data/store/store';

const NOT_CREATED_ACCOUNT_STATUS = 'NotCreated';
const NOTIFICATION_DISMISS_BUFFER = 60000;
export const NOTIFICATION_RENDER_BUFFER = 1000;
export type PaymentNotificationBufferLocalStorageKey =
  | 'payment-teaching-bubble-pushed'
  | 'payment-setup-notification-pushed'
  | 'payment-setup-cheer-dialog-pushed';
export const TEACHING_BUBBLE_NOTIFICATION_FLAG: PaymentNotificationBufferLocalStorageKey = 'payment-teaching-bubble-pushed';
export const SETUP_PAYMENTS_NOTIFICATION_FLAG: PaymentNotificationBufferLocalStorageKey = 'payment-setup-notification-pushed';
export const SETUP_PAYMENTS_CHEER_DIALOG_FLAG: PaymentNotificationBufferLocalStorageKey = 'payment-setup-cheer-dialog-pushed';
export const SETUP_PAYMENTS_NOTIFICATION_ACTION_BUTTON_ID = 'paymentSetupNotificationActionButton';
export const SETUP_PAYMENTS_NOTIFICATION_ID = 'paymentSetupNotification';

export const removePaymentNotificationBuffers = () => {
  if (window.localStorage) {
    [SETUP_PAYMENTS_NOTIFICATION_FLAG, TEACHING_BUBBLE_NOTIFICATION_FLAG, SETUP_PAYMENTS_CHEER_DIALOG_FLAG].forEach((key) => {
      window.localStorage.removeItem(key);
    });
  }
};

export const setPaymentNotificationBuffer = (key: PaymentNotificationBufferLocalStorageKey) => {
  if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(true));
  }
};

const { windowStoreSerializer } = useWindowSize();

const usePaymentsOnboarding = (formatMessage: InjectedIntl['formatMessage']) => {
  const status = getAccountStatus();
  const onboardingContainerId = useId('onboarding-container');
  const xPayContainerRef = useRef<HTMLDivElement>(null);
  const [isSDKLoaded, setSDKLoaded] = useState<boolean>(false);
  const { publisher, userContext, notifications } = getAppStore();
  const { systemPreferences } = getAccountSettingStore().active;
  const { setTimeout } = useSetTimeout();
  const [isInitializingXPayContainer, { setFalse: onXPayInitializeResolved }] = useBoolean(true);
  const isTeachingBubbleHandledPerUserSession = localStorage.getItem(TEACHING_BUBBLE_NOTIFICATION_FLAG);
  const { isWindowExtraSmallSize, isWindowSmallSize } = windowStoreSerializer(getStore());
  const { locale, countryCode } = useI18n({ systemPreferences, publisher, userContext });

  const handleInitXPayContainer = (succeed: boolean) => {
    if (succeed) {
      setTimeout(() => onXPayInitializeResolved(), 500);
    }
    setPaymentNotificationBuffer(TEACHING_BUBBLE_NOTIFICATION_FLAG);
  };

  const teachingBubbleResponsiveStylings = useMemo<IStyle>(
    () => ({
      backgroundColor: 'rgb(0, 120, 212)',
      position: 'absolute',
      top: isWindowExtraSmallSize || isWindowSmallSize ? -200 : -172,
    }),
    [isWindowExtraSmallSize, isWindowSmallSize]
  );

  /**
   * workaround to hide setup payment notification button, we don't need to render the redirection button as this is
   * already in the correct tab for user to setup payment, if user stay at the page, dismiss notification in 1 minute
   */
  useEffect(() => {
    const setupNotificationButton = document.getElementById(SETUP_PAYMENTS_NOTIFICATION_ACTION_BUTTON_ID);
    let setupNotificationButtonOGStyle;
    if (setupNotificationButton) {
      setupNotificationButtonOGStyle = setupNotificationButton.style.display;
      setupNotificationButton.style.display = 'none';
      setTimeout(() => onNotificationRemoved({ id: SETUP_PAYMENTS_NOTIFICATION_ID }), NOTIFICATION_DISMISS_BUFFER);
    }

    return () => {
      if (setupNotificationButton) {
        setupNotificationButton.style.display = setupNotificationButtonOGStyle;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://xpaycdn.azureedge.net/js/pack/xPayUIAccountOnboardingSDK.js'
        : 'https://xpaycdn-int.azureedge.net/js/pack/xPayUIAccountOnboardingSDK.js';
    script.async = true;
    script.onload = () => {
      setSDKLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const shouldRenderTeachingBubble = status === NOT_CREATED_ACCOUNT_STATUS && !isTeachingBubbleHandledPerUserSession;

    isSDKLoaded && onInitializePaymentsPage(shouldRenderTeachingBubble, locale, countryCode, handleInitXPayContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSDKLoaded, locale]);

  return {
    isInitializingXPayContainer,
    xPayContainerRef,
    onboardingContainerId,
    teachingBubbleResponsiveStylings,
  };
};

export default usePaymentsOnboarding;
