import React from 'react';
import { injectIntlAndObserver } from '../../../../../utils';
import { getClassNames } from './SingleDay.styles';
import { ISingleDayProps } from './SingleDay.types';

export const SingleDay = injectIntlAndObserver(function SingleDayComponent(props: ISingleDayProps): JSX.Element {
  const { dayObj, handleDateRangeSelection, selectedDateRange, hoveredDateRange, handleDateRangeHover, turn, isDisabled } = props;
  const { day, fullDay, isInCurrentMonth } = dayObj;
  const { dateNotInCurrentMonth, dateInCurrentMonth, dateSelected, dateSelectedInBetween, disabledDate } = getClassNames();

  function getDayClasses() {
    const disabledClass = isDisabled ? disabledDate : '';
    const isInCurrentMonthClass = !isDisabled ? (isInCurrentMonth ? dateInCurrentMonth : dateNotInCurrentMonth) : '';
    const dayIndex = selectedDateRange.findIndex(
      (date) => date.toDateString() === new Date(fullDay.year, fullDay.month, fullDay.day).toDateString()
    );
    const isInHoverRange = hoveredDateRange?.find(
      (date) => date.toDateString() === new Date(fullDay.year, fullDay.month, fullDay.day).toDateString()
    );
    const selectedClass =
      isInCurrentMonth && dayIndex !== -1
        ? dayIndex === 0 || dayIndex === selectedDateRange.length - 1
          ? dateSelected
          : dateSelectedInBetween
        : '';
    const hoverClass = isInCurrentMonth && isInHoverRange ? dateSelected : '';
    return `${disabledClass} ${selectedClass} ${isInCurrentMonthClass} ${hoverClass}`;
  }

  return (
    <span
      id={isInCurrentMonth ? Object.values(fullDay).join('/') : ''}
      className={getDayClasses()}
      onClick={() => !isDisabled && isInCurrentMonth && handleDateRangeSelection(fullDay)}
      onMouseEnter={() => {
        turn === 1 && isInCurrentMonth && !isDisabled && handleDateRangeHover?.(fullDay);
      }}
    >
      {day}
    </span>
  );
});
