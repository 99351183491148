import {
  Checkbox,
  DetailsHeader,
  DetailsRow,
  IDetailsCheckboxProps,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IDetailsRowProps,
  mergeStyleSets,
  Selection,
  Shimmer,
  TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { getClassNames } from '../GridV2.styles';
import { IGridV2Column } from '../GridV2.types';

export const RenderHeaderCheckbox = (selection: Selection, headerProps?: IDetailsHeaderProps): JSX.Element | null => {
  if (!headerProps) {
    return null;
  }

  const { detailsHeaderRootStyle } = mergeStyleSets(getClassNames());

  return (
    <DetailsHeader
      {...headerProps}
      styles={{ root: detailsHeaderRootStyle }}
      onRenderColumnHeaderTooltip={(tooltipHostProps: IDetailsColumnRenderTooltipProps) => {
        return <TooltipHost {...tooltipHostProps} />;
      }}
      onRenderDetailsCheckbox={(props: IDetailsCheckboxProps, defaultRender) => {
        return (
          <Checkbox
            checked={selection.getItems().every((x) => x?.['isSelected'] === true)}
            ariaLabel={'ToggleAll'}
            onChange={(_, checked) => selection.setAllSelected(!checked)}
          />
        );
      }}
    />
  );
};

export const RenderRowCheckbox = (
  id: string,
  selection: Selection,
  detailsRowProps?: IDetailsRowProps,
  hideDisableStyle?: boolean
): JSX.Element | null => {
  if (!detailsRowProps) {
    return null;
  }

  const { item } = detailsRowProps ?? {};
  const { detailsRowRootStyle, disabledRowStyle, checkCell } = mergeStyleSets(getClassNames());

  return (
    <DetailsRow
      data-testid="grid-item"
      disabled={item?.isDisabled && !hideDisableStyle}
      {...detailsRowProps}
      styles={({ disabled }) => ({ root: `${detailsRowRootStyle} ${disabled && !hideDisableStyle ? disabledRowStyle : ''}` })}
      checkboxCellClassName={checkCell}
      onRenderItemColumn={(itemToRender, _, column: IGridV2Column) => {
        return column.isLoading ? (
          <Shimmer styles={{ root: { display: 'flex', alignItems: 'center', height: '100%' }, shimmerWrapper: { height: '7px' } }} />
        ) : (
          column.onRenderData?.(itemToRender) || itemToRender[column.fieldName!]
        );
      }}
      onRenderDetailsCheckbox={(props: IDetailsCheckboxProps, defaultRender) => {
        return (
          <Checkbox
            checked={props.checked}
            ariaLabel={`Toggle-Select-Row`}
            onChange={() => {
              const index = selection.getItems()?.findIndex((row) => row?.[id] === item?.[id]);
              if (index !== -1) {
                selection.toggleIndexSelected(index);
              }
            }}
          />
        );
      }}
    />
  );
};
