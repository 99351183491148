import { Icon, Spinner } from '@fluentui/react';
import React from 'react';
import { removeToastNotification } from '../../@data/mutatorActions';
import { getClassNames } from './ToastNotification.styles';
import { IToastNotificationItemProps, IToastNotificationProps, ToastIcon, ToastType } from './ToastNotification.types';

export function ToastNotification({ items, intl }: IToastNotificationProps) {
  const { notificationsContainer } = getClassNames();

  return (
    <div className={notificationsContainer}>
      {items?.map((item) => (
        <ToastNotificationItem key={item?.id.toString()} item={item} intl={intl} />
      ))}
    </div>
  );
}

function ToastNotificationItem({ item, intl }: IToastNotificationItemProps) {
  const { suppressAutoDismiss, animationTime, onDismiss, textMessageDescriptor, intlMessageValues, noDismissButton, text } = item.content;

  const classNames = getClassNames({
    suppressAutoDismiss: suppressAutoDismiss,
    type: item.type,
    animationTime: animationTime,
  });

  const onMessageDismiss = async () => {
    if (!onDismiss) {
      return;
    }
    await onDismiss();
  };

  return (
    <div
      role="alert"
      aria-live="assertive"
      className={classNames.notificationAnimation}
      onAnimationEnd={async () => {
        await onMessageDismiss();
        if (suppressAutoDismiss === true && noDismissButton === false) {
          return;
        }
        removeToastNotification(item);
      }}
    >
      <div className={classNames.notificationBody}>
        <Icon iconName={ToastIcon[item.type]} />
        {item.type === ToastType.loading && <Spinner />}

        {textMessageDescriptor ? intl.formatMessage(textMessageDescriptor, intlMessageValues) : text}
        {!noDismissButton && (
          <Icon
            className={classNames.notificationIcon}
            iconName={'Cancel'}
            aria-label={item.content.dismissButtonAriaLabel}
            onClick={() => {
              removeToastNotification(item);
              onMessageDismiss();
            }}
          />
        )}
      </div>
    </div>
  );
}
