import { FormattedMessage, MessageValue } from 'react-intl';
import ReportMetadataMessages from '../ReportMetadata.messages';

export type FormatMessageFuncType = (
  messageDescriptor: FormattedMessage.MessageDescriptor,
  values?:
    | {
        [key: string]: MessageValue;
      }
    | undefined
) => string;

export function getReportMetadataGrainMessageId(key) {
  return `reportMetadata.grains.${key}`;
}
export function getReportMetadataColumnMessageId(key) {
  return `reportMetadataColumns.${key}`;
}

export function getReportColumnMetadataDisplayName(key: string, formatMessage: FormatMessageFuncType) {
  if (!key) {
    return '';
  }
  const messageId = getReportMetadataColumnMessageId(key);
  return formatReportMetadataMessageById(formatMessage, messageId);
}
export function getReportGrainsMetadataDisplayName(key: string, formatMessage: FormatMessageFuncType) {
  const messageId = getReportMetadataGrainMessageId(key);
  return formatReportMetadataMessageById(formatMessage, messageId);
}

function formatReportMetadataMessageById(formatMessage: FormatMessageFuncType, messageId: string) {
  if (!ReportMetadataMessages[messageId]) {
    return '';
  }
  return formatMessage(ReportMetadataMessages[messageId]);
}
