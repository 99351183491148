import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import { PopupType, User, getAccessPermission, getAppStore } from '../../../../../../@data';
import { hasDeleteAccess, hasUpdateAccess } from '../../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../../App.messages';
import { IGridV2Column } from '../../../../../../layout/GridPageV2';
import { setDialogType } from '../../../../../../layout/GridPageV2/@data/mutatorActions';
import messages from '../../ManagePage.messages';

export function getGridColumns(formatMessage, isWindowRegularSize, routeHistory, longestEmailLength): IGridV2Column[] {
  const { publisher, entityGridColumns } = getAppStore();
  const gridColumns = entityGridColumns?.find((x) => x.entity === User)?.gridColumns as IGridV2Column[];

  const columns: IGridV2Column[] = [
    {
      columnOrder: 0,
      key: 'username',
      fieldName: 'username',
      name: formatMessage(messages.username),
      minWidth: longestEmailLength,
      maxWidth: longestEmailLength + 10,
      isSearchable: true,
      isVisible: gridColumns?.find((x) => x.key === 'username')?.isVisible ?? true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 1,
      key: 'email',
      fieldName: 'email',
      name: formatMessage(messages.emailAddress),
      minWidth: longestEmailLength,
      maxWidth: longestEmailLength + 10,
      isSearchable: true,
      isVisible: gridColumns?.find((x) => x.key === 'email')?.isVisible ?? true,
      isExportable: true,
      isResizable: true,
    },
    {
      columnOrder: 2,
      key: 'status',
      fieldName: 'status',
      name: formatMessage(AppMessages.status),
      minWidth: 75,
      maxWidth: 175,
      isSearchable: true,
      isResizable: true,
      isVisible: gridColumns?.find((x) => x.key === 'status')?.isVisible ?? true,
      isExportable: true,
    },
    {
      columnOrder: 3,
      key: 'actions',
      name: formatMessage(AppMessages.actions),
      minWidth: 150,
      maxWidth: 200,
      isPadded: true,
      isSearchable: true,
      isVisible: gridColumns?.find((x) => x.key === 'actions')?.isVisible ?? true,
      suppressSorting: true,
      isResizable: true,
      onRender: (item: User) => {
        const accessPermission = getAccessPermission(item);

        return (
          <>
            <CommandBarButton
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'edit' }}
              ariaLabel={formatMessage(AppMessages.edit)}
              text={isWindowRegularSize ? formatMessage(AppMessages.edit) : ''}
              disabled={!hasUpdateAccess(accessPermission) || publisher === undefined}
              onClick={() => routeHistory.push(`/partner-management/${publisher?.id}/user/edit/${item.id}`)}
            />
            <CommandBarButton
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'Delete' }}
              text={isWindowRegularSize ? formatMessage(AppMessages.delete) : ''}
              onClick={() => setDialogType(PopupType.DeleteConfirmation)}
              disabled={!hasDeleteAccess(accessPermission)}
            />
          </>
        );
      },
    },
  ];

  return columns;
}
