import { useCallback, useEffect, useMemo, useState } from 'react';
import { Log } from '../../../../../../logging/src';
import { InventoryPlatformType, TythonAdUnitServingConfig, getAppStore, showSuccessToastNotification } from '../../../../../@data';
import { PropertyServingConfigs } from '../../../../../@data/store/schema/models/ServingConfigs';
import { FormState } from '../../../../../components/Form';
import { getTythonAdsManageRoute } from '../../../../../utils/routeUtils';
import { fetchPropertiesServingConfigs } from '../../../AdBlock/@data/services';
import {
  DEFAULT_AD_PREVIEW_BACKGROUND_COLOR,
  DEFAULT_AD_PREVIEW_BUTTON_BACKGROUND_COLOR,
  DEFAULT_AD_PREVIEW_FONT,
  DEFAULT_AD_PREVIEW_TITLE_BACKGROUND_COLOR,
} from '../../TythonAdunit/CreateAdunit/constants';
import { ITythonAdUnit } from '../../TythonAdunit/CreateAdunit/types';
import { IAdPreviewConfig } from '../../TythonAdunit/components/AdUnitTypeSelector/PreviewComponent';
import { createTythonAdUnit } from '../../TythonAdunit/services/createTythonAdUnit';
import { fetchTythonAdUnitById } from '../../TythonAdunit/services/loadTythonAdUnitById';
import { updateTythonAdUnit } from '../../TythonAdunit/services/updateTythonAdUnit';
import messages from '../messages';
import {
  AppAdUnitBannerSize,
  AppAdUnitDetailPageProps,
  AppAdUnitType,
  SelectedAppAdUnitBannerSize,
  UseCreateTythonAppAdUnitType,
  VideoPlayMethod,
} from './types';

const DEFAULT_NATIVE_CONFIG = {
  adWidth: 500,
  adHeight: 250,
  fontFamily: DEFAULT_AD_PREVIEW_FONT,
  background: DEFAULT_AD_PREVIEW_BACKGROUND_COLOR,
  buttonBackground: DEFAULT_AD_PREVIEW_BUTTON_BACKGROUND_COLOR,
  titleColor: DEFAULT_AD_PREVIEW_TITLE_BACKGROUND_COLOR,
};

const mapPropertyToApplicationOptions = (servingConfigs: PropertyServingConfigs[]) => {
  return servingConfigs
    .filter((item) => item.inventoryPlatformType === InventoryPlatformType.UWP)
    .map((property) => ({
      key: property.propertyId! as number,
      secondaryLabel: property.MicrosoftStoreAppId as string,
      label: property.name as string,
    }));
};

const useCreateTythonAppAdUnit: UseCreateTythonAppAdUnitType = ({ mode, formatMessage, routeProps }) => {
  const [adUnitName, setAdUnitName] = useState<string | undefined>(undefined);
  const [adUnitApplicationId, setAdUnitApplicationId] = useState<string | undefined>(undefined); // property id
  // const [adUnitType, setAdUnitType] = useState<AppAdUnitType | undefined>(undefined);
  const [adUnitType, setAdUnitType] = useState<AppAdUnitType | undefined>(AppAdUnitType.Native);
  const [bannerAdUnitAdSize, setBannerAdUnitAdSize] = useState<SelectedAppAdUnitBannerSize>('320 x 50');
  const [videoAdUnitPlayMethod, setVideoAdUnitPlayMethod] = useState<VideoPlayMethod>('auto_sound_on');
  const [applicationOptions, setApplicationOptions] = useState<{ key: number; label: string; secondaryLabel: string }[]>([]);
  const [videoAdUnitSkipAllowed, setVideoAdUnitSkipAllowed] = useState<boolean | undefined>(undefined);
  const [videoAdUnitDuration, setVideoAdUnitDuration] = useState<string | undefined>(undefined);
  const [nativeAdUnitConfig, setNativeAdUnitConfig] = useState<IAdPreviewConfig>(DEFAULT_NATIVE_CONFIG);
  const [rawAdUnit, setRawAdUnit] = useState<TythonAdUnitServingConfig | undefined>(undefined);

  const [shouldRenderPrimaryForm, setShouldRenderPrimaryForm] = useState<boolean>(true);
  const [shouldRenderResultSummary, setShouldRenderResultSummary] = useState<boolean>(false);
  const [isSavingAdUnit, setIsSavingAdUnit] = useState<boolean>(false);
  const [isAdUnitLoading, setIsAdUnitLoading] = useState<boolean>(false);
  const { publisher, userContext, account } = getAppStore();
  const isEditMode = mode === FormState.Edit || rawAdUnit !== undefined;

  /**
   * initialize form props for existing ad unit
   */
  useEffect(() => {
    // load property serving configs
    async function asyncInitAdUnitData(formStateMode: FormState) {
      const propertyServingConfigPromise = fetchPropertiesServingConfigs(publisher!);
      setIsAdUnitLoading(true);
      if (formStateMode === FormState.Edit) {
        try {
          const adUnitId = (routeProps.match.params as { id: string }).id!;
          const fetchedAdUnitPromise = fetchTythonAdUnitById(publisher!, userContext!, adUnitId!, formatMessage);
          const [propertyServingConfig, adUnitRaw] = await Promise.all([propertyServingConfigPromise, fetchedAdUnitPromise]);
          const nextOptions = mapPropertyToApplicationOptions(propertyServingConfig);
          setApplicationOptions(nextOptions);
          setRawAdUnit(adUnitRaw);
          setAdUnitName(adUnitRaw.adUnitName);
          setAdUnitApplicationId(`${adUnitRaw.propertyId}`);
          setAdUnitType((adUnitRaw.adTypes[0] as unknown) as AppAdUnitType);
          if (((adUnitRaw.adTypes[0] as unknown) as AppAdUnitType) === AppAdUnitType.Native) {
            setNativeAdUnitConfig({ ...DEFAULT_NATIVE_CONFIG, ...adUnitRaw.nativeProperties } as IAdPreviewConfig);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsAdUnitLoading(false);
        }
      } else {
        try {
          const [propertyServingConfig] = await Promise.all([propertyServingConfigPromise]);
          if (propertyServingConfig.length) {
            const nextOptions = mapPropertyToApplicationOptions(propertyServingConfig);
            setApplicationOptions(nextOptions);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsAdUnitLoading(false);
        }
      }
    }
    asyncInitAdUnitData(mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primaryFormProps = useMemo(() => {
    const validationError = {
      addUnitName: undefined,
    };
    return {
      adUnitName,
      onAdUnitNameChange: setAdUnitName,
      adUnitApplicationId,
      onAdUnitApplicationIdChange: setAdUnitApplicationId,
      adUnitType,
      onAdUnitTypeChange: setAdUnitType,
      shouldRenderPrimaryForm,
      validationError,
      applicationOptions: applicationOptions,
      selectedBannerAdUnitAdSize: bannerAdUnitAdSize,
      onBannerAdUnitAdSizeChange: setBannerAdUnitAdSize,
      bannerAdSizeOptions: Object.values(AppAdUnitBannerSize).map((item) => ({ ...item, key: item.value })),
      videoAdUnitSkipAllowed,
      onVideoAdUnitSkipAllowedChange: setVideoAdUnitSkipAllowed,
      videoAdUnitDuration,
      onVideoAdUnitDurationChange: setVideoAdUnitDuration,
      nativeAdUnitConfig,
      onNativeAdUnitConfigChange: setNativeAdUnitConfig,
      videoAdUnitPlayMethod,
      onVideoAdUnitPlayMethodChange: setVideoAdUnitPlayMethod,
      isAdUnitLoading,
      isEditMode,
    };
  }, [
    adUnitName,
    adUnitApplicationId,
    videoAdUnitSkipAllowed,
    adUnitType,
    shouldRenderPrimaryForm,
    bannerAdUnitAdSize,
    applicationOptions,
    videoAdUnitDuration,
    nativeAdUnitConfig,
    videoAdUnitPlayMethod,
    isAdUnitLoading,
    isEditMode,
  ]);

  const onAdUnitSubmit = useCallback(async () => {
    const isCreatingNewAdUnit = window?.location?.href.includes('adunit/createApp');

    if (!publisher || !userContext || !account) {
      return;
    }
    const { adHeight, adWidth, ...deStructuredNativeConfig } = nativeAdUnitConfig;

    if (isCreatingNewAdUnit) {
      await createTythonAdUnit(
        publisher,
        userContext,
        account,
        ({
          propertyId: adUnitApplicationId,
          name: adUnitName,
          adTypes: [adUnitType!],
          nativeProperties: deStructuredNativeConfig,
        } as unknown) as ITythonAdUnit,
        setIsSavingAdUnit
      )
        .then((data) => {
          setRawAdUnit(data.data.servingConfig);
          setShouldRenderResultSummary(true);
          setShouldRenderPrimaryForm(false);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      await updateTythonAdUnit(
        publisher,
        userContext,
        ({
          raw: rawAdUnit,
          id: rawAdUnit?.adUnitId,
          propertyId: adUnitApplicationId,
          name: adUnitName,
          adTypes: [adUnitType!],
          nativeProperties: deStructuredNativeConfig,
          accountId: rawAdUnit?.accountId,
        } as unknown) as ITythonAdUnit,
        rawAdUnit?.adUnitName !== adUnitName,
        setIsSavingAdUnit
      ).then((data) => {
        if (data && (data as { isDirty?: boolean }).isDirty === false) {
          setShouldRenderResultSummary(true);
          setShouldRenderPrimaryForm(false);
          return;
        }
        Log.info(`update tython ad unit success ${JSON.stringify(data)}`);
        showSuccessToastNotification({ text: formatMessage(messages.appAdUnitUpdateSuccess, { adunitId: rawAdUnit?.adUnitId }) });
        setShouldRenderResultSummary(true);
        setShouldRenderPrimaryForm(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nativeAdUnitConfig, adUnitName, adUnitApplicationId, adUnitType]);

  const detailPageProps = useMemo(() => {
    // Define default properties
    let props: AppAdUnitDetailPageProps = {
      saving: isSavingAdUnit,
      overlayHeight: '100vh',
      loading: isAdUnitLoading,
      onSubmit: () => {
        return;
      },
      submitButtonDisabled: isAdUnitLoading,
      submitLabel: '',
      titleLabel: '',
      cancelLabel: '',
      hasFormTitle: true,
      hideNavButton: false,
      pageMode: mode,
    };

    if (shouldRenderPrimaryForm) {
      props = {
        ...props,
        titleLabel: formatMessage(messages.createNewAppAdUnitPrimaryFormTitle),
        cancelLabel: formatMessage(messages.adUnitFormCancelLabel),
        submitLabel: formatMessage(messages.adUnitFormNextLabel),
        onSubmit: () => {
          onAdUnitSubmit();
        },
        onCancel: () => routeProps.history.push(getTythonAdsManageRoute(true)),
      };
    } else {
      props = {
        ...props,
        submitLabel: formatMessage(messages.adUnitFormDoneLabel),
        onSubmit: () => routeProps.history.push(getTythonAdsManageRoute(true)),
        hideNavButton: true,
      };
    }

    return props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, shouldRenderPrimaryForm, adUnitType, shouldRenderResultSummary, isSavingAdUnit, onAdUnitSubmit, isAdUnitLoading]);

  const resultSummaryProps = useMemo(() => {
    return {
      shouldRenderResultSummary,
      adUnitId: rawAdUnit?.adUnitId?.toString() ?? '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRenderResultSummary, rawAdUnit]);

  return {
    primaryFormProps,
    detailPageProps,
    resultSummaryProps,
  };
};

export default useCreateTythonAppAdUnit;
