import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing } from '../../../theme';
import { IPubcenterPalette } from './../../../theme';
import { ICardStyleProps, ICardStyles } from './Card.types';

/**
 * Gives the default styles for the Card component.
 * @param props - Props used to generate the styles.
 * @returns - classnames for the Card component.
 */
export const getStyles = (props: ICardStyleProps): ICardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { size14 } = theme.fonts as IPubCenterFonts;

  // Reference: https://github.com/microsoft/fluentui/blob/master/packages/merge-styles/README.md#basic-usage
  return mergeStyleSets({
    rootStyle: {
      position: 'relative',
      borderRadius: '2px',
      boxShadow: '0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108);',
      height: '100%',
    },
    titleStyle: {
      ...size14,
      fontWeight: FontWeights.semibold,
      margin: 0,
    },
    tooltipTitle: {
      display: 'flex',
      gap: '10px',
    },
    dismissButtonStyle: {
      zIndex: 1,
      position: 'absolute',
      top: '5px',
      right: '5px',
      color: palette.neutralSecondary,
    },
    bodyStyle: {
      padding: `${spacing.size8} ${spacing.size16}`,
      height: '100%',
      justifyContent: 'space-between',
    },
    titleBar: {
      padding: `${spacing.size8} ${spacing.size16}`,
    },
    footerStyle: {
      position: 'absolute',
      zIndex: 0,
      bottom: '0px',
      left: '0px',
      width: '100%',
    },
    footerWrapper: {
      marginTop: '40px',
    },
    imageStyle: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
    },
  });
};

export const shimmerStyles = { root: { height: '100%' }, dataWrapper: { height: '100%' } };

export const getImageStyle = { height: '100%' };

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
