import { IconButton } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { useI18n } from '../../../../../i18n/useI18n';
import { getAppStore } from '../../../../@data';
import { getStore } from '../../../ad-management/Account/Settings/@data/store/store';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { default as messages } from '../../Report.messages';
import { formatMetric } from '../../utils/metricsFormatter';
import { TythonReportingDataRow } from '../@data/store/schema';
import { MetricMarker } from '../MetricMarker/MetricMarker';
import { IMetricMarkerProps } from '../MetricMarker/MetricMarker.types';
import { ActiveMetrics } from '../ReportCard/ReportCard.types';
import { customStyles, getClassNames } from './ChartMetricPicker.styles';

export interface IChartMetricPickerProps extends InjectedIntlProps {
  mode?: ChartSelectionMode;
  summaryData?: TythonReportingDataRow;
  activeMetrics: ActiveMetrics;
  onMetricSelected: (selectedMetric: IMetricMarkerProps) => void;
  onEditButtonClicked: () => void;
}
export enum ChartSelectionMode {
  SINGLE = 'single',
  MULTI = 'multi',
}
export const ChartMetricPicker = injectIntl(
  ({ activeMetrics, summaryData, onMetricSelected, onEditButtonClicked, intl: { formatMessage } }: IChartMetricPickerProps) => {
    const { contentStyle, markerWrapper } = getClassNames();
    const { publisher, userContext } = getAppStore();
    const { systemPreferences } = getStore().active;
    const { locale } = useI18n({ systemPreferences, publisher, userContext });

    const renderMarker = (metricProps: IMetricMarkerProps): JSX.Element => {
      return (
        <MetricMarker
          key={metricProps.metricKey}
          color={metricProps.color}
          metricName={metricProps.metricName}
          metricData={formatMetric(TythonColumnsEnum[metricProps.metricKey], summaryData?.[metricProps.metricKey], locale)}
          isActive={metricProps.isActive}
          onClick={() => {
            onMetricSelected(metricProps);
          }}
          metricKey={''}
          isVisible={metricProps.isVisible}
          disabled={metricProps.disabled}
          isIncompatible={metricProps.isIncompatible}
          incompatibleTooltipCantText={metricProps.incompatibleTooltipCantText}
          incompatibleTooltipWontText={metricProps.incompatibleTooltipWontText}
        />
      );
    };
    const renderVisibleMarkers = () => {
      return Object.keys(activeMetrics)
        .filter((key) => activeMetrics[key].isVisible && !activeMetrics[key].disabled)
        .map((key) => renderMarker(activeMetrics[key]));
    };
    return (
      <>
        {renderVisibleMarkers()}
        <div className={markerWrapper}>
          <div className={contentStyle}>
            <IconButton
              iconProps={{ iconName: 'Edit' }}
              onClick={onEditButtonClicked}
              styles={{ root: customStyles.addMetricButtonRootStyle }}
              title={formatMessage(messages.metricPickerEditButtonTitle)}
            />
          </div>
        </div>
      </>
    );
  }
);
