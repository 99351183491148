import { IDropdownOption } from '@fluentui/react';
import { Filter } from '../../../../../@data';
import AppMessages from '../../../../../App.messages';
import { FilterType, IFilterDetails } from '../../../../../components/GridFilter/GridFilter.types';
import { IFilterItem } from '../../../../../layout/GridPageV2';
import messages from '../ManagePage.messages';

export function getFilterItems(formatMessage): IFilterItem[] {
  const filters: IFilterItem[] = [
    { columnName: 'name', filterName: formatMessage(messages.name), filterType: FilterType.string },
    { columnName: 'id', filterName: formatMessage(messages.id), filterType: FilterType.relational },
    {
      columnName: 'filterScope',
      filterName: formatMessage(messages.scope),
      filterType: FilterType.custom,
      filterOptions: [
        { key: 'Publisher', text: 'Publisher level filter' },
        { key: 'Account', text: 'Account level filter' },
      ] as IDropdownOption[],
    },
    {
      columnName: 'status',
      filterName: formatMessage(AppMessages.status),
      filterType: FilterType.custom,
      filterOptions: [
        { key: 'Active', text: formatMessage(AppMessages.statusActive) },
        { key: 'Inactive', text: formatMessage(AppMessages.statusInactive) },
      ] as IDropdownOption[],
    },
    {
      filterName: formatMessage(messages.dateRangeFilterName),
      filterType: FilterType.date,
      showNullsCheckboxLabel: 'Indefinite date',
      customFunction: (dataRow: Filter, filterDetails: IFilterDetails) => {
        const startTime = dataRow.startTimeStamp ? dataRow.startTimeStamp.toString() : undefined;
        const endTime = dataRow.endTimeStamp ? dataRow.endTimeStamp.toString() : undefined;

        const startDateFilter = filterDetails.startDate ? new Date(filterDetails.startDate.getTime()).toISOString() : undefined;
        const endDateFilter = filterDetails.endDate ? new Date(filterDetails.endDate.getTime()).toISOString() : undefined;

        if (!filterDetails.includeNulls) {
          if (!startTime && !endTime) {
            return false;
          }

          if (startDateFilter && endTime && endTime < startDateFilter) {
            return false;
          }
          if (endDateFilter && startTime && endDateFilter < startTime) {
            return false;
          }
          return true;
        } else {
          // if includeNulls is true, then return filters which are applied indefinetly.
          if (endTime) {
            return false;
          }
          return true;
        }
      },
    },
  ];

  return filters;
}
