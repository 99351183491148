/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, Icon, Link, PrimaryButton } from '@fluentui/react';
import { Link as V2Link } from '@fluentui/react-components';
import React from 'react';
import { IHeaderItem } from '../../components/Header/Header.types';
import { InlineAnchorProps, IUseCMUIExperience, QueryArticleAnchorProps, QueryArticleButtonProps } from './index.types';
import { initApexPlatform } from './init-apex';

/**
 * This is a custom hook to integrate Microsoft Advertising - Campaign Management UI(CMUI) help experience
 * This hook @returns 2 core components CMUIInlineHelpAnchor and CMUIPopupArticleByIdAnchor
 *
 * <CMUIInlineHelpAnchor /> will render a hover card with given `topic` content at absolute position, PM will
 * share topic string
 * <CMUIPopupArticleByIdAnchor /> will render a anchor tag to support click open the popup help panel and render
 * content of the given `query` article
 *
 * @example
 *    const { CMUIInlineHelpAnchor, CMUIPopupArticleByIdAnchor } = useCMUIHelpExperience();
 *
 *    <CMUIInlineHelpAnchor name="Inline Help Button" topic="pop_PCv4_ServingStatus" className={this._classNames....} />
 *     @param name is required
 *     @param topic is required to map the expected inline help content
 *
 *    <CMUIPopupArticleByIdAnchor query="ext07502" name="query by id" className={this._classNames....} />
 *     @param name is required
 *     @param query is required to map the expected pop up article id
 *
 * @reference
 *     https://msasg.visualstudio.com/Bing_Ads/_artifacts/feed/BingAdsWebUISharedComponents/Npm/@bingads-webui-help%2Fapex/overview
 */
export const useCMUIHelpExperience = (): IUseCMUIExperience => {
  const CMUI_HELP_BUTTON_ID = '#helpbutton';

  const popupClickHandler = (evt) => {
    window?.PopupController?.PopupJsClick(evt.target);
  };

  const initCMUIHelpButtonEventListener = () => {
    const helpButtons = document.querySelectorAll(CMUI_HELP_BUTTON_ID);
    for (let i = 0; i < helpButtons.length; i++) {
      helpButtons[i].addEventListener('click', popupClickHandler);
    }
  };

  const removeCMUIHelpButtonEventListener = () => {
    const helpButtons = document.querySelectorAll(CMUI_HELP_BUTTON_ID);
    for (let i = 0; i < helpButtons.length; i++) {
      helpButtons[i].removeEventListener('click', popupClickHandler);
    }
  };

  // for now header help icon only expose to Tython flow mvp users
  const CMUIHeaderMenuItemSerializer = (isTythonSelfServeUser: boolean, items: IHeaderItem[] | undefined) => {
    const headerHelpIconProps = {
      key: 'HelpIcon',
      title: 'Help',
      icon: 'Help',
      className: 'HelpTopic',
      query: 'ext07501',
      shouldOverflowUnderExtraSmallScreen: true,
    };
    if (isTythonSelfServeUser && !items?.some((i) => i.key === headerHelpIconProps.key)) {
      if (items) {
        items.unshift(headerHelpIconProps);
      } else {
        items = [headerHelpIconProps];
      }
    }
    return items;
  };

  const CMUIInlineHelpAnchor = (props: InlineAnchorProps) => {
    const eleRef = React.createRef<HTMLAnchorElement>();

    const onClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (eleRef.current) {
        window.PopupController.PopupJsClick(eleRef.current);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { topic, name, className, iconName } = props;
    const iconStyle: React.CSSProperties = {
      fontSize: '16px',
      margin: '0px 4px',
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
      flexShrink: 0,
      color: 'rgb(0, 120, 212)',
    };

    return (
      <a className={className} ref={eleRef} id="helpbutton" tabIndex={0} type="helppopup" onClick={onClick} href="#" topic={topic.trim()}>
        {name && name}
        {iconName && <Icon iconName={iconName} style={iconStyle} />}
      </a>
    );
  };

  const CMUIPopupArticleByIdAnchor = (props: QueryArticleAnchorProps) => {
    const { query, name, className, disabled, useV2Link = false } = props;
    if (useV2Link) {
      return (
        <V2Link href="/#" query={query} className={css('HelpTopic', className)} disabled={disabled}>
          {name}
        </V2Link>
      );
    }

    return (
      <Link href="/#" query={query} className={css('HelpTopic', className)}>
        {name}
      </Link>
    );
  };

  const CMUIPopupArticleUsingButton = (props: QueryArticleButtonProps) => {
    const { query, className, anchorStyle, formatMessage, buttonText, buttonStyle } = props;
    return (
      <>
        <a
          hidden={true}
          href="/#"
          query={query}
          id="helpbutton"
          className={css(className, anchorStyle)}
          // style={{ textDecoration: 'none', color: 'black' }}
        >
          Learn more
        </a>
        <PrimaryButton
          text={formatMessage(buttonText)}
          className={buttonStyle}
          onClick={() => {
            document.getElementById('helpbutton')?.click();
          }}
          tabIndex={0}
        />
      </>
    );
  };

  return {
    CMUIHeaderMenuItemSerializer,
    CMUIPopupArticleUsingButton,
    CMUIInlineHelpAnchor,
    CMUIPopupArticleByIdAnchor,
    initCMUIHelpButtonEventListener,
    init: initApexPlatform,
    removeCMUIHelpButtonEventListener,
  };
};
