/* eslint-disable @typescript-eslint/consistent-type-assertions */
function generateChars(length: number) {
  let chars = '';
  for (let i = 0; i < length; i++) {
    const array = new Uint8Array(1);
    // tslint:disable-next-line
    const crypto = window.crypto || (<any>window).msCrypto;
    crypto.getRandomValues(array);
    const randomChar = Math.floor((array[0] / 1000) * 64);
    chars += randomChar.toString(36);
  }
  return chars;
}

export function generateGuid() {
  // format : 'xxxxxxxx-xxxx-4xxx-yxxx-xx'

  return generateChars(8) + '-' + generateChars(4) + '-' + generateChars(4) + '-' + generateChars(4) + '-' + generateChars(2);
}
