import { classNamesFunction, css, Dropdown, DropdownMenuItemType, getTheme, IDropdownOption, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Log } from '../../../logging/src';
import {
  Account,
  getAppStore,
  getEntity,
  listEntity,
  onLoadingChanged,
  onNotificationChanged,
  onSetFirstPartyBoost,
  Publisher,
  PublisherSearch,
} from '../../@data';
import { default as appMessages, default as AppMessages } from '../../App.messages';
import { IListItem, ListPicker } from '../../components/ListPicker';
import { APP_NAME_PUBCENTER, NO_VALUE_PLACEHOLDER } from '../../constants/AppConstants';
import { delay } from '../../utils/DelayUtils';
import messages from './PublisherAccountPicker.messages';
import { getStyles } from './PublisherAccountPicker.styles';
import {
  IPublisherAccountPickerProps,
  IPublisherAccountPickerState,
  IPublisherAccountPickerStyleProps,
  IPublisherAccountPickerStyles,
} from './PublisherAccountPicker.types';

const getClassNames = classNamesFunction<IPublisherAccountPickerStyleProps, IPublisherAccountPickerStyles>();
const publisherSearchApiVersion = '1.3';

export const PublisherAccountPicker = injectIntl(
  class extends React.Component<IPublisherAccountPickerProps, IPublisherAccountPickerState> {
    private _accountsForCurrentPublisher: IDropdownOption[] = [];
    private _isComponentMounted: boolean;
    private defaultSelectedAccount: Account | undefined = undefined;

    constructor(props: IPublisherAccountPickerProps) {
      super(props);

      this.state = {
        publisher: this.props.publisher,
        account: this.props.account,
        isAccountSelectionDisabled: this.props.isAccountSelectionDisabled,
        isPublisherSelectionDisabled: this.props.isPublisherSelectionDisabled,
      };
    }

    public async componentDidUpdate(prevProps: IPublisherAccountPickerProps) {
      if (this.props.publisher !== prevProps.publisher || this.props.account !== prevProps.account) {
        if (this.props.publisher) {
          await this._updateAccountsCache(this.props.publisher);
        } else {
          this._accountsForCurrentPublisher = [];
          this.setState({ publisher: undefined, account: undefined });
        }
      }

      const { publisher, account } = this.props;
      const urlPath = this.props.location.pathname;
      if (publisher && account) {
        if (account.id === 0 && urlPath && (urlPath.includes('channel') || urlPath.includes('adfilter'))) {
          this._updateAccountsCache(publisher, true);
        }
        this._handleAllAccountsOptions();
      }
    }

    public async componentDidMount() {
      this._isComponentMounted = true;
      if (this.state.publisher) {
        if (this.props.isAccountHidden) {
          // this is a hack for now, the global search routing is incomplete, since in AdManagement the below await _updateAccountsCache
          // prevents the route to be changed on OnChange action
          await delay(1000);
          if (this._isComponentMounted) {
            this.props.onChange(this.state.publisher, undefined);
          }
        } else {
          await this._updateAccountsCache(this.state.publisher);
        }
      }
    }

    public async componentWillUnmount() {
      this._isComponentMounted = false;
    }

    public render(): JSX.Element {
      const { formatMessage } = this.props.intl;
      const classNames = getClassNames(getStyles, {
        theme: getTheme(),
        isAccountDisabled: !this.state.publisher || this.state.isAccountSelectionDisabled,
        isPublisherDisabled: this.state.isPublisherSelectionDisabled,
      });
      const selectedPublisherItem =
        this.state.publisher && this.state.publisher.name
          ? {
              key: String(this.state.publisher.id),
              name: String(this.state.publisher.name),
            }
          : undefined;
      const selectedAccountItem = this.state.account && this.state.account.name ? String(this.state.account.id) : undefined;
      this._handleAllAccountsOptions();

      return (
        <div className={classNames.root} role="complementary" aria-label={formatMessage(messages.publisherAccountPickerAriaLabel)}>
          <div className={css(classNames.listGroup, classNames.publisherPicker)}>
            <ListPicker
              label={formatMessage(appMessages.publisher)}
              selectedItem={selectedPublisherItem}
              onResolveSuggestions={this._onPublishersResolveSuggestions}
              getTextFromItem={(item: IListItem) => item.name}
              inputPlaceholder={formatMessage(messages.publisherPlaceholder)}
              noResultsFoundText={formatMessage(messages.publisherNotFound)}
              removeButtonAriaLabel={formatMessage(messages.listPickerRemoveAriaLabel)}
              onChange={this._onPublisherChanged}
              disabled={this.state.isPublisherSelectionDisabled}
            />
          </div>
          {!this.props.isAccountHidden && (
            <div className={css(classNames.listGroup, classNames.accountPicker)}>
              <Dropdown
                id={'accountPicker'}
                placeholder={formatMessage(messages.accountPlaceholder)}
                styles={classNames.subComponentStyles.dropdown}
                label={formatMessage(appMessages.account)}
                options={this._accountsForCurrentPublisher}
                selectedKey={selectedAccountItem}
                onChange={this._onAccountChanged}
                disabled={!this.state.publisher || this.state.isAccountSelectionDisabled}
                calloutProps={{ calloutMaxHeight: 300 }}
              />
            </div>
          )}
        </div>
      );
    }

    private _onPublisherChanged = async (item?: IListItem) => {
      if (!item) {
        this.setState({
          publisher: undefined,
          account: undefined,
        });

        this.props.onChange(undefined, undefined);
        return;
      }

      // Query the db for the full publisher.
      const publisher = new Publisher(parseInt(item.key, 10));
      publisher.name = item.name;

      if (this.state.isAccountSelectionDisabled) {
        this.props.onChange(publisher, undefined);
      }
      // Update current accounts cache:
      await this._updateAccountsCache(publisher);
    };

    private async _updateAccountsCache(publisher: Publisher, isUpdateAccount?: boolean) {
      onLoadingChanged(true);
      const userContext = getAppStore().userContext;
      try {
        // If isUpdateAccount is true, then change account selected to defaultSelectedAccount of the Publisher
        if (this.defaultSelectedAccount && isUpdateAccount) {
          const account = new Account(this.defaultSelectedAccount.id);
          account.name = this.defaultSelectedAccount.name;

          this.setState({ publisher: publisher, account: account });
          this.props.onChange(publisher, account);
          onLoadingChanged(false);
          return;
        }

        // If user is changing account of the same publisher, to prevent unneccesary API call and reduce page load time
        if (
          !this.state.isAccountSelectionDisabled &&
          this._accountsForCurrentPublisher.length > 0 &&
          this.state.account &&
          this._isComponentMounted &&
          !isUpdateAccount
        ) {
          this.setState({ publisher: publisher, account: this.state.account });
          this.props.onChange(publisher, this.state.account);
          onLoadingChanged(false);
          return;
        }

        // Prevent unnecessary API call and reduce load time when the Account Picker (at the top) is hidden. For example,
        // this happens when on the AdQuality page.
        if (this.props.isAccountHidden) {
          this.setState({ publisher: publisher, account: this.state.account });
          this.props.onChange(publisher, this.state.account);
          onLoadingChanged(false);
          return;
        }

        let accounts = await listEntity([publisher], userContext, Account);
        accounts = accounts.sort((a, b) => (String(a.name)!.toLowerCase() < String(b.name)!.toLowerCase() ? -1 : 1));

        if (this._isComponentMounted && (!accounts || accounts.length === 0)) {
          this._accountsForCurrentPublisher = [];
          this.setState({ publisher: publisher, account: undefined });
          this.props.onChange(publisher, undefined);
          onLoadingChanged(false);
          return;
        }

        this._accountsForCurrentPublisher = accounts.map((p) => ({
          key: String(p.id),
          text: String(p.name!),
        }));

        this._accountsForCurrentPublisher.unshift({ key: 'divider', text: NO_VALUE_PLACEHOLDER, itemType: DropdownMenuItemType.Divider });
        this._accountsForCurrentPublisher.unshift({
          key: String('0'),
          text: this.props.intl.formatMessage(messages.allAccountsOption),
        } as IDropdownOption);

        if (!this.state.isAccountSelectionDisabled) {
          if (this.state.account && this._isComponentMounted && !isUpdateAccount) {
            this.setState({ publisher: publisher, account: this.state.account });
            this.props.onChange(publisher, this.state.account);
          } else {
            const publisherFullInfo = await getEntity<Publisher>([publisher], userContext);
            onSetFirstPartyBoost(publisherFullInfo.publisherTier);
            publisher.publisherTier = publisherFullInfo.publisherTier;

            if (publisherFullInfo && publisherFullInfo.defaultAccountId) {
              this.defaultSelectedAccount = accounts.find((acc) => acc.id === publisherFullInfo.defaultAccountId);
            } else {
              if (accounts) {
                this.defaultSelectedAccount = accounts[0];
              }
            }

            if (this.defaultSelectedAccount && this._isComponentMounted) {
              const account = new Account(this.defaultSelectedAccount.id);
              account.name = this.defaultSelectedAccount.name;

              this.setState({ publisher: publisher, account: account });
              this.props.onChange(publisher, account);
            }
          }
        }
      } catch (e) {
        onNotificationChanged({
          text: this.props.intl.formatMessage(AppMessages.customerInvalid, { appName: APP_NAME_PUBCENTER }),
          messageBarType: MessageBarType.error,
        });
      }

      onLoadingChanged(false);
    }

    private _onPublishersResolveSuggestions = async (filter: string): Promise<IListItem[]> => {
      if (filter.trim().length === 0) {
        return [];
      }
      const userContext = getAppStore().userContext!;

      try {
        const publishers =
          (await listEntity(
            [],
            userContext,
            PublisherSearch,
            new URLSearchParams([
              ['type', 'publishername'],
              ['value', filter],
              ['publisherstatus', 'active'],
            ]),
            publisherSearchApiVersion
          )) || [];

        if (Number(filter)) {
          const publisher = new Publisher(parseInt(filter, 10));
          const publisherAsync = await getEntity([publisher], userContext);

          if (publisherAsync) {
            const newPublisher = new PublisherSearch();
            newPublisher.publisherId = publisherAsync.id!;
            newPublisher.publisherName = String(publisherAsync.name);
            publishers.push(newPublisher);
          }
        }

        return publishers.map((p) => ({
          key: String(p.publisherId),
          name: p.publisherName!,
        }));
      } catch (e) {
        Log.error(`Fetching publisher suggestions failed with error: ${e}`);
      }

      return [];
    };

    private _onAccountChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
      if (!option) {
        this.setState({
          account: undefined,
        });

        this.props.onChange(this.state.publisher, undefined);
        return;
      }

      // Query the db for the full account.
      const account = new Account(parseInt(option.key.toString(), 10));
      account.name = option.text;

      this.setState({ account });

      this.props.onChange(this.state.publisher, account);
    };

    private _handleAllAccountsOptions = () => {
      const urlPath = this.props.location.pathname;
      const accounts = this._accountsForCurrentPublisher;

      if (urlPath && urlPath.includes('adunit') && accounts.length > 0 && accounts[0].key === '0') {
        accounts[0].disabled = false;
      }

      if (urlPath && (urlPath.includes('channel') || urlPath.includes('adfilter')) && accounts.length > 0 && accounts[0].key === '0') {
        accounts[0].disabled = true;
      }
      this._accountsForCurrentPublisher = accounts;
    };
  }
);
