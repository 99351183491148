import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, LifeCycleStatus, onLoadingChanged, onNotificationChanged, onSavingChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import messages from '../../AdReview/AdCreativePage.messages';
import { onBlockAdsDataChanged, onBlockAdsItemStatusChanged } from '../actions';
import { deactivateBlockedAds, getBlockSummaryData } from '../services/AdBlockService';
import { createAdBlock } from '../services/AdCreativeService';
import { BlockAdsData } from '../store/schema/models/BlockAdsData';
import { getStore } from '../store/store';

orchestrator(onBlockAdsItemStatusChanged, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const status = msg.status;

  let notification: INotificationBarItem = {};

  if (publisher && publisher.id && userContext) {
    const body: BlockAdsData = new BlockAdsData();
    body.channelId = msg.data.channelId;
    body.channelName = msg.data.channelName;
    body.adId = msg.data.adId;
    body.adTitle = msg.data.adTitle;
    body.advertiserAccountId = msg.data.advertiserAccountId;
    body.reason = msg.data.reason;
    body.reasonCategory = msg.data.reasonCategory;
    body.modifiedBy = userContext.id.toString();
    body.modifiedDateTime = new Date(Date.now()).toISOString();
    body.startDate = null;
    body.endDate = null;
    body.isEnabled = true;
    body.pastRevenue = msg.data.pastRevenue;
    body.allImages = msg.data.allImages;

    const params = new URLSearchParams([['targetCustomerId', publisher.id.toString()]]);

    onSavingChanged(true);
    onLoadingChanged(true);
    try {
      let result;
      if (status === LifeCycleStatus.Active) {
        result = await createAdBlock([body], params);
      } else if (status === LifeCycleStatus.Inactive) {
        result = await deactivateBlockedAds(
          [
            ['channel', msg.data.channelId!.toString()],
            ['adid', msg.data.adId!.toString()],
          ],
          params
        );
      }

      if (result) {
        const active = getStore().active;
        const blockSummaryParams = new URLSearchParams([['targetCustomerId', publisher.id.toString()]]);
        let blockSummaryData: BlockAdsData[] = [];
        if (active.selectedAccount) {
          if (active.selectedAccount === -1) {
            blockSummaryData = await getBlockSummaryData([['publisher', publisher.id!.toString()]], blockSummaryParams);
            // } else if (active.selectedAdUnit && active.selectedAdUnit !== -1) {
            //   blockSummaryData = await getBlockSummaryData([['adunit', active.selectedAdUnit.toString()]], blockSummaryParams);
          } else {
            blockSummaryData = await getBlockSummaryData([['account', active.selectedAccount.toString()]], blockSummaryParams);
          }
        }
        blockSummaryData.forEach((d) => (d.isEnabled ? (d.status = LifeCycleStatus.Active) : (d.status = LifeCycleStatus.Inactive)));
        onBlockAdsDataChanged(blockSummaryData);
      }
      notification = {
        textMessageDescriptor: messages.adqualityUpdatedBlockedAdsStatusSuccess,
        intlMessageValues: { ID: msg.data.adId },
        messageBarType: MessageBarType.success,
      };

      onNotificationChanged(notification);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityUpdateBlockedAdsStatusFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
      onSavingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
