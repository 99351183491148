import { Model } from './Model';

export class BrandResponse extends Model {
  public apiPath = 'metadata/xandr/brands';
  public className = '';
  public id: number;
  public name: string;
  // Need to be addressed to match camal case
  // tslint:disable-next-line
  public category_id: number;
  // Need to be addressed to match camal case
  // tslint:disable-next-line
  public integration_domain: string;
  public urls: string[];
}

export class BrandDetailsResponse extends BrandResponse {
  public apiPath = 'metadata/xandr/brands/details';
  public className = '';
}
export class BrandSearchResponse extends BrandResponse {
  public apiPath = 'metadata/xandr/brands';
  public className = '';
}
