import { downloadFile } from './downloadFile';

export function downloadCSV(csvData: string, csvHeaders: string, filename: string) {
  if (!filename) {
    return;
  }
  const csvDataMarker = 'data:text/csv;charset=utf-8,';
  const csvFileData = csvDataMarker + (csvHeaders ? `${csvHeaders}\n` : '') + csvData;
  const encodedCSV = encodeURI(csvFileData);
  downloadFile(encodedCSV, filename);
}
