import { orchestrator } from 'satcheljs';
import {
  Model,
  NotificationLabel,
  NotificationSeverity,
  PopupType,
  Publisher,
  PublisherSearch,
  deleteEntity,
  getAppStore,
  onNotificationCardAdded,
  onPopupOpenChanged,
  showErrorToastNotification,
  showLoadingToastNotification,
  showSuccessToastNotification,
} from '../../../../@data';

import notificationMessages from '../../../../NotificationsPanel.Types.messages';
import { getSuccessNotificationDeleteItem } from '../../../../utils';
import messages from '../../GridV2.messages';
import { onDeleteItem } from '../actions/commandBarActions';
import { setSelectedItems } from '../mutatorActions';
import { getStore } from '../store/store';

orchestrator(onDeleteItem, async (msg) => {
  onPopupOpenChanged(PopupType.None);

  const { entity } = getStore();
  const selectedItem = msg.selectedItem as Model;

  const showErrorMessage = () => {
    showErrorToastNotification({ textMessageDescriptor: messages.gridDataDeletingItemFailed });
    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      titleMessageDescriptor: notificationMessages.DeletingItemFailureTitle,
      contentMessageDescriptor: notificationMessages.DeletingItemFailureContent,
      notificationLabel: NotificationLabel.DeletingItemFailure,
    });
  };

  const deleteSucceeded = async (result, entityInstance, formatMessage) => {
    if (result) {
      const items = getStore().data;
      if (items) {
        const copiedItems = items.filter((item) => item.id !== entityInstance.id);
        msg.deleteAction?.(copiedItems);
        msg.setData?.(copiedItems);
        await msg.postDeleteAction?.([msg.selectedItem]);
      }
      setSelectedItems([]);

      showSuccessToastNotification({ text: getSuccessNotificationDeleteItem([selectedItem], entityInstance, formatMessage) });
    }
  };

  if (entity && selectedItem) {
    const { userContext, publisher } = getAppStore();

    if (userContext && publisher) {
      let entityInstance = new entity();
      entityInstance.id = selectedItem.id;
      if (entityInstance instanceof PublisherSearch) {
        entityInstance = new Publisher();
        entityInstance.id = (selectedItem as PublisherSearch).publisherId;
        selectedItem.name = (selectedItem as PublisherSearch).publisherName;
      }

      await msg.preDeleteAction?.([msg.selectedItem]);

      try {
        let result;
        if (entityInstance instanceof Publisher) {
          showLoadingToastNotification({
            textMessageDescriptor: messages.gridDataDeletingItem,
            onDismiss: async () => {
              try {
                result = await deleteEntity([entityInstance], undefined, userContext);
                await deleteSucceeded(result, entityInstance, msg.formatMessage);
              } catch (e) {
                showErrorMessage();
              }
            },
          });
        } else {
          showLoadingToastNotification({
            textMessageDescriptor: messages.gridDataDeletingItem,
            onDismiss: async () => {
              try {
                result = await deleteEntity([publisher, entityInstance], undefined, userContext);
                await deleteSucceeded(result, entityInstance, msg.formatMessage);
              } catch (e) {
                showErrorMessage();
              }
            },
          });
        }
      } catch (e) {
        showErrorMessage();
      }
    }
  }
});
