import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { onLoadingChanged, Publisher, showErrorToastNotification, UpdateUserContactDetails, User } from '../../../../../@data';
import { isIndividualPublisher } from '../../../../../@data/selectors';
import { deepClone } from '../../../../../utils';
import { setPublisherEvalutionStatusToInReview } from '../../../@data/actions/entityEvaluationMutatorActions';
import messages from '../../ProfilePage.messages';
import {
  setIsSubmittedAction,
  setPrimaryUserDetailsAction,
  setProfileEditModeAction,
  setPublisherProfileDetailsAction,
  updatePublisherProfileAction,
} from '../actions';
import { updatePublisherDetails } from '../services';
import { updateUserContactDetails } from '../services/updateUserContactDetails';

const animationTimeMS: number = 5000;

orchestrator(updatePublisherProfileAction, async ({ profile }) => {
  try {
    onLoadingChanged(true);

    const newPublisherDetails: Publisher = deepClone(profile.publisher);
    if (!newPublisherDetails) {
      throw new Error('Publisher details are missing');
    }

    // NOTE: Setting this from UI because API doesn't have a way to tell this. Pending on @srivatsa.
    newPublisherDetails.profileComplete = true;

    const newUserDetails: User = deepClone(profile.user);
    if (!newUserDetails) {
      throw new Error('User details are missing');
    }

    // For individual publishers, we need to set the user address as publisher address.
    if (isIndividualPublisher()) {
      newPublisherDetails.address = { ...newPublisherDetails.address, ...newUserDetails.postalAddress };
    }

    const newUserContactDetails = new UpdateUserContactDetails();
    newUserContactDetails.postalAddress = newUserDetails.postalAddress;
    newUserContactDetails.userId = newUserDetails.id;
    newUserContactDetails.phone = newUserDetails.contactInfo?.phone1;

    // Keeping the User and Publisher update sequential and in this order to avoid race condition with CRM Profile sync
    await updateUserContactDetails(newUserContactDetails);
    const updatedPublisherDetails = await updatePublisherDetails(newPublisherDetails);

    // Update the local store with the updated publisher details
    setPublisherProfileDetailsAction(updatedPublisherDetails);

    // Update the local store with the updated user details
    setPrimaryUserDetailsAction(newUserDetails);

    // Change the edit mode back to summary mode
    setProfileEditModeAction(false);
    setIsSubmittedAction(true);

    setPublisherEvalutionStatusToInReview(newPublisherDetails);
  } catch (error) {
    if (error.code === 400) {
      showErrorToastNotification({ text: JSON.parse(error.message)[0].description, animationTime: animationTimeMS });
    } else {
      showErrorToastNotification({ textMessageDescriptor: messages.updatePublisherProfileDetailsFailed });
    }

    Log.error(error);
  } finally {
    onLoadingChanged(false);
  }
});
