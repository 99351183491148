import { mixed, object, string } from 'yup';
import { Publisher, SelfServePublisher, User, getAddressValidationModel, isOrganizationPublisher } from '../../../../../../@data';
import { PublisherType } from '../../../../../../@data/store/schema/enums/PublisherType';
import { ORG_NAME_MAX_LENGTH, ORG_NAME_MIN_LENGTH } from '../../../../../signup/@data';

export function profileValidationModel() {
  if (isOrganizationPublisher()) {
    return object<SelfServePublisher>().shape({
      user: getUserProfileValidationModel(),
      publisher: getPublisherProfileValidationModel(),
    });
  } else {
    return object<SelfServePublisher>().shape({
      user: getUserProfileValidationModel(),
    });
  }
}

export function getUserProfileValidationModel() {
  return object<User>().shape({
    contactInfo: object().shape({
      phone1: string()
        // TODO: for now make +1 as mandatory US country code, when enabling for other countries, we can make it dynamic
        .matches(/^\+1 \d{10}$/, {
          message: 'Invalid phone number',
        })
        .required()
        .label('Phone1'),
    }),
    postalAddress: getAddressValidationModel(),
  });
}

export function getPublisherProfileValidationModel() {
  return object<Publisher>().shape({
    publisherType: mixed().oneOf([PublisherType.individual, PublisherType.organization]).required(),
    name: string().required().min(ORG_NAME_MIN_LENGTH).max(ORG_NAME_MAX_LENGTH).label('Organization name'),
    extendedProperties: object().shape({
      organizationEmailAddress: string().email().required().label('Organization email'),
      organizationUrl: string().domain('Invalid organization URL').required().label('Organization URL'),
      ceoName: string().name().required().label('CEO name'),
    }),
    address: getAddressValidationModel(),
  });
}
