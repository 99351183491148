import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette } from '../../../../../../theme';

export interface IReportDetailsGridStyleProps {
  theme: ITheme;
}

type StyleProperties = 'breakdownWrapper' | 'breakdownText' | 'inCompatibleColumnWrapper';
type ReportDetailsGridStyles = Record<StyleProperties, string>;

export const getStyles = (props: IReportDetailsGridStyleProps): ReportDetailsGridStyles => {
  const theme = getTheme();
  const palette = theme.palette as IPubcenterPalette;
  return mergeStyleSets({
    breakdownWrapper: {
      display: 'flex',
      gap: '5px',
    },
    breakdownText: {
      display: 'flex',
      alignItems: 'center',
    },
    inCompatibleColumnWrapper: {
      color: palette.neutralTertiary,
      display: 'flex',
      gap: '8px',
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
