import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { getAppStore, showErrorToastNotification } from '../../../../../@data';
import { getColumnMembers } from '../../../../../@data/services/ReportingService';
import { getAdunitStore } from '../../../../ad-management/Adunit/@data';
import ReportMessages from '../../../Report.messages';
import { ReportCardType } from '../../ReportCard/ReportCard.types';
import { fetchReportDimensionColumnMembers, setColumnMembers } from '../actions';
import { IColumnMemberDetails } from '../store/schema';

orchestrator(fetchReportDimensionColumnMembers, async (msg) => {
  try {
    const { publisher, account } = getAppStore();
    if (!publisher || !account) {
      return;
    }
    const reportCardType = msg.reportCardType;
    if (reportCardType === ReportCardType.MEDIATION) {
      when(
        () => !getAdunitStore().isFetchingAdUnitStatus,
        () => {
          const allAdUnits = getAdunitStore().adUnitsRaw.data || [];
          const columnMembers: IColumnMemberDetails[] = allAdUnits
            .filter((adunit) => adunit.mediatedWithAdSense && adunit.mediatedWithAdSense.mediationStatus)
            .map((adunit) => ({
              Id: adunit.id,
              Value: String(adunit.name),
              DisplayName: String(adunit.name),
            }));
          setColumnMembers(msg.requestParams.column, columnMembers);
        }
      );
    } else {
      const columnMembers = await getColumnMembers(msg.requestParams);
      setColumnMembers(msg.requestParams.column, columnMembers);
    }
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: ReportMessages.dimensionColumnMembersFailedMessage });
  }
});
