import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import messages from '../../App.messages';
import { onNotificationChanged } from '../actions';
import { showTokenExpirationNotification } from '../actions/onTokenExpirationActions';

const EXPIRED_NOTIFICATION_SHOWN_STATE_KEY = 'isExpiredNotificationShown';

orchestrator(showTokenExpirationNotification, async () => {
  if ((window.history.state || {})[EXPIRED_NOTIFICATION_SHOWN_STATE_KEY]) {
    return;
  }

  onNotificationChanged({
    textMessageDescriptor: messages.sessionExpiredLoginAgain,
    messageBarType: MessageBarType.info,
  });
  window.history.pushState({ [EXPIRED_NOTIFICATION_SHOWN_STATE_KEY]: true }, '');
  return;
});
