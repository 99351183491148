import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../../../logging/src';
import { getAppStore, onLoadingChanged, putEntity, showErrorToastNotification } from '../../../../../../../../@data';
import { AdSenseMediationToggle } from '../../../../../../../../@data/store/schema/models/AdSenseMediation';
import { googleConnectorMessages } from '../../GoogleConnector.messages';
import { onMediateGoogleAccount } from '../actions';
import { onChangeMediationToggle } from '../mutatorActions';

orchestrator(onMediateGoogleAccount, async ({ mediate, formatMessage }) => {
  const { publisher, account } = getAppStore();

  onLoadingChanged(true);
  const operation = mediate ? 'Enabling' : 'Disabling';
  let errorToastNotification = mediate
    ? formatMessage(googleConnectorMessages.googleConnectorMediationOperationEnabling)
    : formatMessage(googleConnectorMessages.googleConnectorMediationOperationDisabling);

  errorToastNotification = errorToastNotification + formatMessage(googleConnectorMessages.googleConnectorMediationFailed);

  if (publisher && account) {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('routeSuffix', 'api/mediation/admanager/toggleMediation');
      const request: AdSenseMediationToggle = {
        apiPath: 'preauth/mediation',
        className: 'oauth',
        toggle: mediate,
      };

      const response = await putEntity([], request, undefined, AdSenseMediationToggle, urlParams, undefined);

      if (response && response?.mediationStatus === mediate) {
        onChangeMediationToggle(mediate);
      } else {
        onChangeMediationToggle(!mediate);
        showErrorToastNotification({ text: errorToastNotification });
      }
    } catch (error) {
      onChangeMediationToggle(!mediate);
      showErrorToastNotification({ text: errorToastNotification });
      Log.error(`Error while ${operation} adSense mediation: ${error}`);
    } finally {
      onLoadingChanged(false);
    }
  }
});
