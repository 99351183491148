import { action } from 'satcheljs';
import { Model } from '../../../../../@data';
import { IDevTokenPanelParams } from '../../Manage/ManagePage.types';

export const onDevTokenPanelShown = action('onDevTokenPanelShown', (devTokenPanelParams: IDevTokenPanelParams) => ({
  devTokenPanelParams,
}));

export const onDevTokenPanelHide = action('onDevTokenPanelHide');

export const onFetchDevTokenStatus = action('onFetchDevTokenStatus', (selectedItem: Model) => ({
  selectedItem,
}));

export const onGenerateDevTokenStatus = action('onGenerateDevTokenStatus', (selectedItem: Model) => ({
  selectedItem,
}));

export const onTokenStatusItem = action('onTokenStatusItem', (selectedItem: Model) => ({
  selectedItem,
}));
