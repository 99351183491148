import { FontSizes, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../../../../theme';
import { IChooseAccountStyles } from './ChooseAccount.types';

const theme = (getTheme() as unknown) as IPubcenterTheme;
const palette = theme.palette as IPubcenterPalette;
const spacing = theme.spacing as IPubcenterSpacing;
const font = FontSizes;

export const getStyles = (): IChooseAccountStyles => {
  return mergeStyleSets({
    selectedOptionLabelWrapper: {
      fontSize: font.size16,
      lineHeight: theme.fonts.size16.lineHeight,
      fontWeight: '600',
      maxWidth: 'none',
    },
    optionLabelWrapper: {
      fontSize: font.size14,
      lineHeight: theme.fonts.size14.lineHeight,
      fontWeight: '600',
      maxWidth: 'none',
    },
    optionTitleWrapper: {
      width: '100%',
      display: 'flex',
      gap: spacing.size4,
      alignSelf: 'center',
    },
    optionTitleBadge: {
      color: palette.brandForeground1Rest,
      border: `1px solid ${palette.brandForeground1Rest}`,
      fontWeight: '600',
      fontSize: font.size10,
      lineHeight: theme.fonts.size10.lineHeight,
      borderRadius: '10000px',
      alignSelf: 'center',
      padding: spacing.size2,
    },
    subComponentStyles: {
      optionCardStyle: {},
    },
  });
};

export const getChoiceGroupStyles = () => {
  return {
    flexContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: spacing.size24,
    },
  };
};

export const getOptionStyles = (isSelected: boolean) => {
  return {
    root: {
      width: '100%',
      backgroundColor: palette.white,
    },
    choiceFieldWrapper: {
      borderRadius: spacing.size4,
      width: '100%',
      backgroundColor: isSelected ? palette.neutralLighter : palette.white,
      border: `${spacing.size1} solid ${palette.neutralLight}`,
      boxShadow: `0 ${spacing.size2} ${spacing.size4} 0 rgba(0, 0, 0, 0.14)`,
    },
    innerField: {
      paddingLeft: spacing.size12,
      paddingRight: spacing.size12,
    },
    field: {
      '::before': {
        opacity: 1,
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      border: 'none',
      paddingTop: spacing.size4,
    },
    input: {
      cursor: 'pointer',
    },
    labelWrapper: {
      fontSize: isSelected ? font.size16 : font.size14,
      lineHeight: isSelected ? theme.fonts.size16.lineHeight : theme.fonts.size14.lineHeight,
      fontWeight: '600',
      width: '100%',
      display: 'flex',
      maxWidth: 'none',
    },
  };
};
