import {
  FontWeights,
  IDialogContentStyleProps,
  IDialogContentStyles,
  IDialogStyleProps,
  IDialogStyles,
  IStyleFunctionOrObject,
  getTheme,
  mergeStyleSets,
} from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing } from '../../../../../../theme';

export interface IFRECardStyles {
  root: string;
  dot: string;
  selectedDot: string;
  content: string;
  title: string;
  description: string;
  primaryButton: string;
  subComponentStyles: {
    dialogContent: IStyleFunctionOrObject<IDialogContentStyleProps, IDialogContentStyles>;
    dialog: IStyleFunctionOrObject<IDialogStyleProps, IDialogStyles>;
  };
}

export const getFRECardStyles = () => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const fonts = theme.fonts as IPubCenterFonts;

  return mergeStyleSets({
    root: {
      borderRadius: spacing.size16,
      boxShadow: theme.effects.elevation16,
    },
    dot: {
      width: spacing.size8,
      height: spacing.size8,
      borderRadius: '50%',
      backgroundColor: '#185ABD',
      opacity: 0.3,
    },
    selectedDot: {
      width: spacing.size16,
      height: spacing.size8,
      borderRadius: spacing.size8,
      backgroundColor: '#185ABD',
    },
    content: {
      padding: spacing.size24,
    },
    title: {
      ...fonts.size20,
      fontWeight: FontWeights.semibold,
      marginBottom: spacing.size20,
    },
    description: {
      ...fonts.size14,
      marginBottom: spacing.size36,
    },
    subComponentStyles: {
      dialogContent: {
        header: { display: 'none' },
        inner: { padding: 0 },
      },
      dialog: {
        dialogDefaultMaxWidth: spacing.size600,
        main: {
          borderRadius: spacing.size16,
          '@media(min-width: 640px)': {
            maxWidth: spacing.size600,
          },
        },
      },
    },
  });
};
