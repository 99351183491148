import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class Filter extends Model {
  public className = UserRoleEntity.Filter;
  public name: number;
  public id: number;
  public filterType: number;
  public channelList: number;
  public excludedURLs: number;
  public excludedAdKeywords: number;
  public excludedQueryKeywords: number;
  public appendOnly: number;

  constructor(params: Filter) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: Filter, filter: Filter) {
    // tslint:disable:no-bitwise
    this.accessPermission |= filter.accessPermission;
    this.channelList |= filter.channelList;
    this.excludedAdKeywords |= filter.excludedAdKeywords;
    this.excludedQueryKeywords |= filter.excludedQueryKeywords;
    this.excludedURLs |= filter.excludedURLs;
    this.filterType |= filter.filterType;
    this.id |= filter.id;
    this.name |= filter.name;
    this.appendOnly |= filter.appendOnly;
    // tslint:enable:no-bitwise
  }
}
