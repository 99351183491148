const offlineUrlIdentifiers = [
  'pubcenter2.azurewebsites.net', // nonTython SI LDC2
  'pubcenternext-staging.azurewebsites.net', // nonTython prod LDC2
  'offline.si.monetizenow.microsoft.com', // tython SI LDC2
  'staging.monetizenow.microsoft.com', // tython prod LDC2
];

const isOfflineUI = offlineUrlIdentifiers.some((url) => window.location.hostname.indexOf(url) > -1) ? 'offline.' : '';

const uiConfigInternal = {
  apiBaseAddress:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}search.pubcenter.microsoft.com/api/v{version}`
      : `https://${isOfflineUI}search.si.pubcenter.microsoft.com/api/v{version}`,
  metadataApiBaseAddess:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}search.pubcenter.microsoft.com/api/v{version}/metadata/clientcenter/`
      : `https://${isOfflineUI}search.si.pubcenter.microsoft.com/api/v{version}/metadata/clientcenter/`,
  customerMetadataApiBaseAddess:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}search.pubcenter.microsoft.com/api/v{version}/metadata/customer/`
      : `https://${isOfflineUI}search.si.pubcenter.microsoft.com/api/v{version}/metadata/customer/`,
  adUnitMetadataApiBaseAddress:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}search.pubcenter.microsoft.com/api/v{version}/metadata/adunit/`
      : `https://${isOfflineUI}search.si.pubcenter.microsoft.com/api/v{version}/metadata/adunit/`,
  adQualityApiBaseAddress:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}search.pubcenter.microsoft.com/api/`
      : `https://${isOfflineUI}search.si.pubcenter.microsoft.com/api/`,
  reportingDashboardAddress:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}pubcenter.microsoft.com/Dashboard?commonsessionid={commonSessionId}#paidsearchdashboard`
      : `https://${isOfflineUI}si.pubcenter.microsoft.com/Dashboard?commonsessionid={commonSessionId}#paidsearchdashboard`,
  reportingApiBaseAddress:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}search.pubcenter.microsoft.com/api/preauth/reporting?routeSuffix=api/`
      : `https://${isOfflineUI}search.si.pubcenter.microsoft.com/api/preauth/reporting?routeSuffix=api/`,
  mediationReportingApiBaseAddress:
    process.env.REACT_APP_ENV === 'production'
      ? `https://${isOfflineUI}search.pubcenter.microsoft.com/api/preauth/mediation?routeSuffix=api/`
      : `https://${isOfflineUI}search.si.pubcenter.microsoft.com/api/preauth/mediation?routeSuffix=api/`,
  defaultApiVersion: '1.2',
};

export const uiConfig = {
  getApiBaseAddress(apiVersion?: string): string {
    return uiConfig.replaceApiVersion(uiConfigInternal.apiBaseAddress, apiVersion);
  },

  getMetadataApiBaseAddress(apiVersion?: string): string {
    return uiConfig.replaceApiVersion(uiConfigInternal.metadataApiBaseAddess, apiVersion);
  },

  getCustomerMetadataApiBaseAddress(apiVersion?: string): string {
    return uiConfig.replaceApiVersion(uiConfigInternal.customerMetadataApiBaseAddess, apiVersion);
  },

  getAdUnitMetadataApiBaseAddress(apiVersion?: string): string {
    return uiConfig.replaceApiVersion(uiConfigInternal.adUnitMetadataApiBaseAddress, apiVersion);
  },

  getAdQualityApiBaseAddress(apiVersion?: string): string {
    return uiConfig.replaceApiVersion(uiConfigInternal.adQualityApiBaseAddress, apiVersion);
  },

  getReportingDashboardAddress(commonSessionId: string): string {
    return uiConfigInternal.reportingDashboardAddress.replace('{commonSessionId}', commonSessionId);
  },

  getDefaultApiVersion(): string {
    return uiConfigInternal.defaultApiVersion;
  },

  getReportingApiBaseAddress(apiVersion?: string): string {
    return uiConfigInternal.reportingApiBaseAddress;
  },

  getMediationReportingApiBaseAddress(apiVersion?: string): string {
    return uiConfigInternal.mediationReportingApiBaseAddress;
  },

  replaceApiVersion(endpointBaseAddress: string, apiVersion?: string): string {
    if (!apiVersion) {
      apiVersion = uiConfigInternal.defaultApiVersion;
    }

    return endpointBaseAddress.replace('{version}', String(apiVersion));
  },

  getApexInitUrl(): string {
    // This is the endpoint of apex service
    // For PROD environment it is https://help.ads.microsoft.com
    // For SI environment it is https://help.ads-int.microsoft.com
    return process.env.REACT_APP_ENV === 'production' ? 'https://help.ads.microsoft.com' : 'https://help.ads-int.microsoft.com';
  },
};
