import { Dropdown, Stack } from '@fluentui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useI18n } from '../../../../../i18n/useI18n';
import { getAppStore } from '../../../../@data';
import { RoutePrefix } from '../../../../Routes';
import { Card } from '../../../../components/Card/Card';
import { HighChartsWrapper } from '../../../../components/HighCharts/HighChartsLibrary';
import { CHART_WRAPPER } from '../../../../constants/DataTestIdConstants';
import { injectIntlAndObserver } from '../../../../utils';
import { getStore } from '../../../ad-management/Account/Settings/@data/store/store';
import { setDataInURL } from '../../@data/store/UrlQueryStore';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { ReportsQueryStoreKeys } from '../../@data/store/schema/ReportsQueryStoreKeys';
import { RouteName } from '../../@data/store/store';
import messages from '../../Report.messages';
import { ratioMetrics } from '../../constants/ratioMetrics';
import { IMetadataElement } from '../@data/store/schema';
import { ChartMetricPicker, ChartSelectionMode } from '../ChartMetricPicker/ChartMetricPicker';
import { MetricsSidePanel } from '../ChartMetricPicker/MetricsSidePanel';
import { GrainSelector } from '../GrainSelector/GrainSelector';
import { IMetricMarkerProps } from '../MetricMarker/MetricMarker.types';
import { ReportDetailsGrid } from '../ReportDetailsPage/ReportDetailsGrid/ReportDetailsGrid';
import { ShareModal } from '../ShareModal/ShareModal';
import {
  getActiveMarkers,
  getChart,
  getMetricDropdownOptions,
  getShimmerCustomElements,
  getStartingBreakdown,
  getStartingChartGrain,
} from './ReportCard.helpers';
import { getClassNames, getMetricPickerStackTokens, getStackTokens } from './ReportCard.styles';
import { ActiveMetrics, ChartGrain, ChartTypes, IChartsOptions, IReportCardProps, ReportCardType } from './ReportCard.types';
import { TopBarActions } from './TopBarActions';

export const ReportCard = injectIntlAndObserver<IReportCardProps>(
  ({
    title,
    type,
    intl: { formatMessage },
    reportData,
    reportDetailedData,
    summaryData = [],
    isSummaryDataLoaded,
    reportMetadata,
    allowedChartTypes,
    defaultColumns,
    defaultMetrics,
    defaultChartGrain,
    allowedChartGrains,
    showMetricsMarkers = false,
    mainDimension,
    dateDimension,
    enableHideChartsButton,
    showDetailsReportLink,
    dateRange,
    isDetailedView,
    filters,
    hasMinimumBreakdown = false,
    onChartGrainChanged,
    onSelectedMetricChanged,
    onActiveMetricsChanged,
    onBreakDownChanged,
    reportCardRef,
    history,
    location,
    match,
  }: IReportCardProps) => {
    const classNames = getClassNames();
    const { publisher, userContext } = getAppStore();
    const { systemPreferences } = getStore().active;
    const { locale } = useI18n({ systemPreferences, publisher, userContext });
    const viewReportRoute = `/${RoutePrefix.adManagement}/${publisher?.id}/${RouteName.dashboard}/${type}/${RouteName.reportDetails}`;
    const startingChartType = allowedChartTypes[0] || { chartType: ChartTypes.DONUT, disabled: false, iconName: ChartTypes.DONUT };
    const chartGrainStoreKey = `${title}-${ReportsQueryStoreKeys.CHART_GRAIN}`;
    const [currentChartType, setCurrentChartType] = useState(startingChartType.chartType);
    const startingChartGrain = getStartingChartGrain(
      defaultChartGrain,
      allowedChartGrains,
      startingChartType.chartType,
      chartGrainStoreKey
    );
    const chartComponentRef = useRef<HighChartsWrapper.RefObject>(null);
    const mapChartComponentRef = useRef<HighChartsWrapper.RefObject>(null);
    const [currentChartGrain, setCurrentChartGrain] = useState(startingChartGrain);
    const [currentBreakdown, setCurrentBreakdown] = useState(getStartingBreakdown(mainDimension));
    const defaultSelectedMetric = defaultMetrics && defaultMetrics[0] ? defaultMetrics[0] : '';
    const [selectedMetric, setSelectedMetric] = useState(defaultSelectedMetric);
    const [enableShare, setEnableShare] = useState(false);
    const [showCharts, setShowCharts] = useState(true);
    const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
    const [activeMetrics, setActiveMetrics] = useState<ActiveMetrics>({});

    const isMetadataLoaded = !!reportMetadata;
    const hasEmptyData = !reportData || (reportData && reportData.length <= 0);
    const isDataLoaded = !!reportData;
    const showMetricsDropdown = currentChartType !== ChartTypes.LINE;
    const isDetailedDataLoaded = !!reportDetailedData;

    const [mainDimensionMetadata, setMainDimensionMetadata] = useState<IMetadataElement>({
      key: '',
      filterKey: '',
      displayName: '',
      incompatibleFields: [],
    });
    const [selectedMetricMetadata, setSelectedMetricMetadata] = useState<IMetadataElement>({
      key: '',
      filterKey: '',
      displayName: '',
      incompatibleFields: [],
    });
    const chartsOptions: IChartsOptions = {
      currentChartType,
      currentChartGrain,
      reportData: reportData || [],
      mainDimension: mainDimensionMetadata,
      selectedMetric: selectedMetricMetadata,
      chartComponentRef,
      mapChartComponentRef,
      activeMetrics,
      dateDimension,
      countryCodeDimension: TythonColumnsEnum.CountryCode,
      formatMessage,
    };
    const disabledRatioMetric = useMemo(() => {
      return currentChartType !== ChartTypes.LINE && currentChartType !== ChartTypes.MAP && ratioMetrics.includes(String(selectedMetric));
    }, [currentChartType, selectedMetric]);

    const onChartTypeChange = (newChartType: ChartTypes): void => {
      if (newChartType === ChartTypes.LINE) {
        setCurrentChartGrain(getStartingChartGrain(defaultChartGrain, allowedChartGrains, newChartType, chartGrainStoreKey));
      } else {
        setCurrentChartGrain(ChartGrain.NONE);
      }
      setCurrentChartType(newChartType);
    };
    const getTopBarActions = (): React.ReactNode => {
      return (
        <TopBarActions
          currentChartType={currentChartType}
          onChartTypeChange={onChartTypeChange}
          setCurrentChartGrain={setCurrentChartGrain}
          setEnableShare={setEnableShare}
          allowedChartTypes={allowedChartTypes}
          reportData={{
            data: reportData || [],
            isDataLoaded: !!reportData,
          }}
          selectedMetric={selectedMetric}
          columnsMetadata={reportMetadata ? [...reportMetadata.metrics, ...reportMetadata.dimensions] : []}
          reportName={title}
          mainDimension={mainDimension}
          enableHideChartsButton={enableHideChartsButton}
          onChartsHideClicked={(hidden: boolean) => {
            setShowCharts(!hidden);
          }}
          currentChartGrain={currentChartGrain}
          isDetailedView={isDetailedView}
          cardRef={reportCardRef}
          dateRange={dateRange}
          sysLocale={locale}
        />
      );
    };
    const onActiveGrainChanged = (grain: ChartGrain) => {
      setDataInURL(chartGrainStoreKey, grain);
      setCurrentChartGrain(grain);
    };

    const stringifiedFilters = JSON.stringify(filters);
    useEffect(() => {
      if (isMetadataLoaded) {
        const loadedMetrics = getActiveMarkers(
          reportMetadata.metrics,
          filters,
          currentChartType,
          defaultMetrics,
          formatMessage,
          type === ReportCardType.PERFORMANCE ? '' : selectedMetric
        );
        setActiveMetrics({
          ...loadedMetrics,
        });
        onActiveMetricsChanged && onActiveMetricsChanged(loadedMetrics);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMetadataLoaded, currentChartType, stringifiedFilters, locale]);

    useEffect(() => {
      if (disabledRatioMetric) {
        setSelectedMetric(defaultSelectedMetric);
      }
    }, [disabledRatioMetric, defaultSelectedMetric]);
    useEffect(() => {
      setMainDimensionMetadata(
        reportMetadata?.dimensions.find((dimension) => dimension.key === mainDimension) ||
          ({
            key: '',
            filterKey: '',
            displayName: '',
            incompatibleFields: [],
          } as IMetadataElement)
      );
      setSelectedMetricMetadata(
        reportMetadata?.metrics.find((metric) => metric.key === selectedMetric) ||
          ({
            key: '',
            filterKey: '',
            displayName: '',
            incompatibleFields: [],
          } as IMetadataElement)
      );
    }, [reportMetadata, mainDimension, selectedMetric]);
    useEffect(() => {
      onChartGrainChanged && onChartGrainChanged(currentChartGrain);
    }, [currentChartGrain, onChartGrainChanged]);
    useEffect(() => {
      onSelectedMetricChanged && onSelectedMetricChanged(selectedMetric);
    }, [selectedMetric, onSelectedMetricChanged]);
    return (
      <div className={classNames.cardWrapper}>
        <Card
          titleText={title}
          shimmerCustomElement={getShimmerCustomElements(currentChartType)}
          isDataLoaded={(isDataLoaded || currentChartType === ChartTypes.GRID) && Object.keys(activeMetrics).length > 0}
          cardTopBarActions={getTopBarActions()}
          styleProps={{ titleBar: classNames.cardsTitleBarStyle }}
        >
          <div ref={reportCardRef}>
            <Stack tokens={getStackTokens()}>
              {showCharts && currentChartType === ChartTypes.LINE && (
                <Stack horizontalAlign="end">
                  <GrainSelector
                    activeGrain={currentChartGrain}
                    activeGrainChanged={onActiveGrainChanged}
                    allowedGrains={allowedChartGrains}
                  />
                </Stack>
              )}
              <Stack horizontal horizontalAlign="start" wrap tokens={getMetricPickerStackTokens()}>
                {!hasEmptyData && showMetricsDropdown && !showMetricsMarkers && (
                  <Dropdown
                    className={classNames.metricsDropdown}
                    options={getMetricDropdownOptions(reportMetadata?.metrics || [], currentChartType, formatMessage)}
                    defaultSelectedKey={selectedMetric}
                    onChange={(_, option) => {
                      if (option) {
                        setSelectedMetric(String(option.key));
                      }
                    }}
                    ariaLabel={formatMessage(messages.metricsDropdownLabel)}
                  />
                )}
                {!hasEmptyData && showMetricsMarkers && showCharts && (
                  <>
                    <MetricsSidePanel
                      activeMetrics={activeMetrics}
                      isOpen={showSidePanel}
                      onPanelHide={() => {
                        setShowSidePanel(false);
                      }}
                      onMetricsChanged={(changedMetrics: ActiveMetrics) => {
                        setActiveMetrics({ ...changedMetrics });
                        onActiveMetricsChanged && onActiveMetricsChanged(changedMetrics);
                      }}
                    />
                    <ChartMetricPicker
                      mode={type === ReportCardType.PERFORMANCE ? ChartSelectionMode.MULTI : ChartSelectionMode.SINGLE}
                      activeMetrics={activeMetrics}
                      summaryData={summaryData[0]}
                      onMetricSelected={(selected: IMetricMarkerProps) => {
                        if (selected.disabled) {
                          return;
                        }
                        if (type !== ReportCardType.PERFORMANCE) {
                          for (const key of Object.keys(activeMetrics)) {
                            activeMetrics[key].isActive = false;
                          }
                          setSelectedMetric(selected.metricKey);
                        }
                        selected.isActive = !selected.isActive;
                        setActiveMetrics({ ...activeMetrics });
                        onActiveMetricsChanged && onActiveMetricsChanged(activeMetrics);
                      }}
                      onEditButtonClicked={() => {
                        setShowSidePanel(true);
                      }}
                    />
                  </>
                )}
              </Stack>
              {reportData && showCharts && (
                <Stack data-test-id={`${CHART_WRAPPER}-${currentChartType}-${type}`} styles={{ root: { width: '100%' } }}>
                  {getChart(chartsOptions, locale)}
                </Stack>
              )}
            </Stack>
          </div>
          {isDetailedView && (
            <div>
              <ReportDetailsGrid
                history={history}
                location={location}
                match={match}
                metrics={reportMetadata?.metrics || []}
                dimensions={reportMetadata?.dimensions || []}
                data={reportDetailedData || []}
                isDetailedDataLoaded={!!isDetailedDataLoaded}
                initialBreakdown={reportMetadata?.dimensions.filter((dimension) => currentBreakdown.includes(dimension.key)) || []}
                currentChartGrain={currentChartGrain}
                onBreakdownChanged={(newBreakdown: IMetadataElement[]) => {
                  const updatedBreakdown = [...newBreakdown.map((breakdown) => breakdown.key)];
                  onBreakDownChanged && onBreakDownChanged(updatedBreakdown);
                  setCurrentBreakdown(updatedBreakdown);
                  setDataInURL(ReportsQueryStoreKeys.BREAKDOWN, updatedBreakdown);
                }}
                defaultColumns={defaultColumns}
                hasMinimumBreakdown={hasMinimumBreakdown}
                dateDimension={dateDimension}
                filters={filters}
              />
            </div>
          )}
          {showDetailsReportLink && (
            <div>
              <Link className={classNames.linkStyle} to={`${viewReportRoute}${window.location.search}`}>
                <span>{formatMessage(messages.viewReportLink)}</span>
              </Link>
            </div>
          )}
        </Card>
        <ShareModal
          disableModal={() => {
            setEnableShare(false);
          }}
          enabled={enableShare}
        />
      </div>
    );
  }
);
