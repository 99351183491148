import { IDropdownOption, Link, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { FilterCondition } from '../../../../../@data/store/schema/enums/FilterCondition';
import { TextFilter } from '../../../../../components/Filters/TextFilter/TextFilter';
import { FormSection } from '../../../../../components/Form';
import { injectIntlAndObserver } from '../../../../../utils';
import { onDropdownValueChanged, onTextBoxValueChanged } from '../../@data/mutatorActions';
import { IConditionValue } from '../../@data/store/schema';
import messages from './MultiTextFilters.messages';
import { getClassNames } from './MultiTextFilters.styles';
import { IMultiTextFiltersProps } from './MultiTextFilters.types';

export const MultiTextFilters = injectIntlAndObserver<IMultiTextFiltersProps>(function MultiTextFiltersComponent(
  props: IMultiTextFiltersProps
): JSX.Element {
  const { formatMessage } = props.intl;
  const { addMore } = messages;
  const { root } = mergeStyleSets(getClassNames());
  const { conditions = [] } = props;

  const isLastPairCompleted = (): boolean => {
    const lastCondition = conditions[conditions.length - 1].condition !== undefined;
    const lastValue = conditions[conditions.length - 1].value !== '';
    return lastCondition && lastValue;
  };

  const hasElements = (): boolean => {
    return conditions.some((pair: IConditionValue<string>) => {
      return pair.condition && pair.value !== '';
    });
  };

  const onSelectHandle = (heading: string, value: IDropdownOption<FilterCondition>, index: number) => {
    onDropdownValueChanged(heading, value, index);
  };

  const onChangeHandle = (heading: string, value: string, index: number) => {
    onTextBoxValueChanged(heading, value, index);
  };

  return (
    <div className={root}>
      <FormSection formWidth={300} title={props.heading} expanded={hasElements()} rightChevron>
        {conditions.map((pair, index: number) => (
          <React.Fragment key={index}>
            <TextFilter
              condition={pair.condition}
              value={pair.value}
              index={index}
              heading={props.heading}
              onSelectFunc={onSelectHandle}
              onChangeFunc={onChangeHandle}
            />
          </React.Fragment>
        ))}
        <Link
          onClick={() => {
            return props.onAddMoreClick!(props.heading);
          }}
          disabled={!isLastPairCompleted()}
        >
          {formatMessage(addMore)}
        </Link>
      </FormSection>
    </div>
  );
});
