import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { deleteEntity, getAppStore, onAuthLogout, onNotificationChanged, showSuccessToastNotification } from '../../../../../../@data';
import messages from '../../SettingsPage.messages';
import { onPublisherDeleted } from '../actions/onPublisherDeleted';

orchestrator(onPublisherDeleted, async () => {
  const { publisher, userContext } = getAppStore();
  if (userContext && publisher) {
    try {
      await deleteEntity([publisher], undefined, userContext);

      showSuccessToastNotification({ textMessageDescriptor: messages.deletedPublisherSuccess });
      onAuthLogout();
    } catch (e) {
      onNotificationChanged({
        text: `Deleting the publisher caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      });
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.invalidStateLoginOrReport,
    });
  }
});
