import { getTheme, IButtonStyles, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../theme';

export interface IProfileHeaderStyleProps {}

export interface IProfileHeaderStyles {
  actionButtonsWrapper?: string;
  dropDownWrapper?: string;
  iconStyle?: string;
}

export const getClassNames = (props: IProfileHeaderStyleProps): IProfileHeaderStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    actionButtonsWrapper: {
      marginTop: spacing.size8,
    },
    dropDownWrapper: {
      display: 'flex',
      padding: `${spacing.size0} ${spacing.size4}`,
      height: spacing.size40,
      alignItems: 'center',
    },
    iconStyle: {
      paddingLeft: spacing.size5,
    },
  });
};

export function getButtonStyles(): IButtonStyles {
  const theme = getTheme();
  const pallete = theme.palette as IPubcenterPalette;

  return {
    root: {
      width: '100%',
    },
    rootHovered: {
      backgroundColor: pallete.neutralLighter,
      color: pallete.neutralPrimary,
    },
    icon: {
      color: pallete.neutralPrimary,
    },
    iconHovered: {
      color: pallete.neutralPrimary,
    },
    iconPressed: {
      color: pallete.neutralPrimary,
    },
  };
}

export const getDropdownStyles = () => {
  const theme = getTheme();
  const size = theme.spacing as IPubcenterSpacing;
  return {
    root: {
      '.ms-Dropdown-title': {
        borderWidth: size.size0,
      },
    },
  };
};
