import { BreakPoints, IPubCenterFonts, IPubcenterTheme } from '../../../theme';
import { NotificationSeverity } from '../../@data';
import { flexWithJustifyBetween } from '../../utils/GlobalStyleUtils';
import { INotificationCardStyleProps, INotificationCardStyles } from './NotificationCard.types';

export const getStyles = (props: INotificationCardStyleProps): INotificationCardStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { size14 } = theme.fonts as IPubCenterFonts;
  const severity = props.severity;
  const { spacing } = theme;
  const { ResponsiveSmall } = BreakPoints;

  return {
    root: {},
    cardContainerStyle: {
      display: 'flex',
      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
      borderRadius: 2,
      background: '#f9f9f9',
      [ResponsiveSmall]: {
        flexWrap: 'wrap',
      },
      marginBottom: spacing.size8,
      padding: spacing.size12,
    },
    iconStyle: {
      color: severity === NotificationSeverity.Critical ? '#A80000' : severity === NotificationSeverity.Warning ? '#D83B01' : '#797775',
    },
    headerRowStyle: {
      ...flexWithJustifyBetween,
      borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
    },
    leftContainerStyle: {},
    contentStyle: {
      marginTop: spacing.size4,
      marginBottom: spacing.size4,
    },
    titleStyle: {
      fontWeight: '600',
      lineHeight: '14px',
    },
    rightContainerStyle: {
      paddingLeft: spacing.size8,
    },
    buttonStyle: {
      ...size14,
      color: theme.palette.highContrastPrimary,
      paddingTop: spacing.size8,
      textDecoration: 'none',
      paddingRight: '0px',
      border: 'none',
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
        border: 'none',
        color: theme.palette.highContrastPrimary,
        textDecoration: 'none',
      },
      '&:active': {
        background: 'transparent',
        border: 'none',
        color: theme.palette.highContrastPrimary,
        textDecoration: 'none',
      },
    },
    linkStyle: {
      ...size14,
      paddingLeft: spacing.size20,
      color: theme.palette.themePrimary,
      textDecoration: 'none',
      paddingTop: spacing.size10,
    },
    timestampStyle: {
      marginTop: spacing.size12,
      marginBottom: spacing.size12,
      color: '#727272',
    },
    subComponentStyles: {
      messageCard: {
        root: {
          marginBottom: theme.spacing.s1,
          selectors: {
            '&:last-child': {
              marginBottom: 0,
            },
          },
        },
      },
    },
  };
};
