import { ApiErrorCodes, TokenType, getAppStore, handleDomainRedirection, onAuthLogout } from '..';
import { Log } from '../../../logging/src';
import { PUBCENTER_TYTHON_DOMAIN_MAPPING, USER_SIGNUP_KEY } from '../../constants';
import { isLocationPubCenter } from '../../utils';
import { showTokenExpirationNotification } from '../actions/onTokenExpirationActions';
import { ApiError } from '../services/ApiService';
import { loginUserWithAdditionalClaims } from '../services/PubCenterAADAuthService';
import { IResponse } from '../store/schema';

export async function handleApiErrors<T>(response: Response, url: string, errorLogMessage?: string): Promise<T> {
  let errorObj: Error;
  const responseJson: IResponse<T> = await response.json();
  const { userContext } = getAppStore();

  switch (response.status) {
    case 302: // Redirect
      if (responseJson.data) {
        if (isLocationPubCenter()) {
          Log.write('HandleApiError - Tython user, redirecting to new domain');
          handleDomainRedirection(userContext?.userName ?? userContext?.email);
        }
        return Promise.resolve({} as T);
      }
      errorObj = new ApiError(302, JSON.stringify(responseJson)); // placeholder, will not be used
      break;
    case 401:
      // Promt the user to re-login in case additional claim challenge is presented by the API
      if (userContext?.tokenType === TokenType.PubCenterAADToken && response.headers.has('WWW-Authenticate')) {
        loginUserWithAdditionalClaims(response.headers.get('WWW-Authenticate'));
        errorObj = new ApiError(401, JSON.stringify(responseJson));
        break;
      }

      const errorCode = responseJson.errors?.[0]?.code;

      if (errorCode === ApiErrorCodes.UserDoesNotExist) {
        if (localStorage.getItem(USER_SIGNUP_KEY) === 'true') {
          const origin = isLocationPubCenter() ? PUBCENTER_TYTHON_DOMAIN_MAPPING[window.location.host] : window.location.origin;
          const signupPageRoute = encodeURI(origin + '/signup');
          Log.write(`User does not exist, redirecting to new domain signup page: ${signupPageRoute}`);

          window.location.replace(signupPageRoute);
        } else {
          const UserDoesNotExistPageRoute = encodeURI(window.location.origin + '/user-does-not-exist');
          Log.error(`Redirecting to ${UserDoesNotExistPageRoute} error page`);

          window.location.replace(UserDoesNotExistPageRoute);
        }
      } else if (
        errorCode &&
        [
          ApiErrorCodes.InvalidOAuthToken,
          ApiErrorCodes.InvalidCCAuthToken,
          ApiErrorCodes.InvalidUserCredentials,
          ApiErrorCodes.UserAlreadyMigrated,
          ApiErrorCodes.UserIdNotFound,
        ].includes(errorCode as ApiErrorCodes)
      ) {
        Log.error(`User scope call failed, logging out the user with error code: ${errorCode}`);
        showTokenExpirationNotification();
        onAuthLogout({ isTokenExpired: true });
      }
      errorObj = new ApiError(
        401,
        responseJson.errors ? JSON.stringify(responseJson) : 'The user does not have the necessary role permissions for this operation'
      );
      break;
    case 403:
      errorObj = new ApiError(403, responseJson.errors ? JSON.stringify(responseJson.errors) : 'Forbidden');
      break;
    case 404:
      errorObj = new ApiError(404, responseJson.errors ? JSON.stringify(responseJson.errors) : 'Not Found');
      break;
    case 400:
      errorObj = new ApiError(400, responseJson.errors ? JSON.stringify(responseJson.errors) : 'Bad Request');
      break;
    case 500:
      errorObj = new ApiError(500, responseJson.errors ? JSON.stringify(responseJson.errors) : 'Internal server error');
      break;
    default:
      errorObj = new Error(responseJson.errors ? JSON.stringify(responseJson) : 'Invalid response');
      break;
  }
  return handleError(errorObj, url, errorLogMessage);
}

export function handleError<T>(error: Error, url: string, errorMessage?: string): Promise<T> {
  Log.error(errorMessage ?? `Error: ${error.message} occurred for the API URL: ${url}`);
  return Promise.reject(error);
}
