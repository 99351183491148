import { defineMessages } from 'react-intl';

export default defineMessages({
  createNewAppAdUnitPrimaryFormTitle: {
    id: 'appAdUnit.createNewAppAdUnitPrimaryFormTitle',
    defaultMessage: 'App details',
  },
  appAdUnitNameFieldLabel: {
    id: 'appAdUnit.appAdUnitNameFieldLabel',
    defaultMessage: 'Ad unit name',
  },
  appAdUnitApplicationIdFieldLabel: {
    id: 'appAdUnit.appAdUnitApplicationIdFieldLabel',
    defaultMessage: 'Connected application',
  },
  appAdUnitTypeFieldLabel: {
    id: 'appAdUnit.appAdUnitTypeFieldLabel',
    defaultMessage: 'Ad types',
  },
  appAdUnitTypeBanner: {
    id: 'appAdUnit.appAdUnitTypeBanner',
    defaultMessage: 'Banner',
  },
  appAdUnitTypeInterstitial: {
    id: 'appAdUnit.appAdUnitTypeInterstitial',
    defaultMessage: 'Interstitial',
  },
  appAdUnitTypeNative: {
    id: 'appAdUnit.appAdUnitTypeNative',
    defaultMessage: 'Native',
  },
  appAdUnitTypeBannerDescription: {
    id: 'appAdUnit.appAdUnitTypeBannerDescription',
    defaultMessage:
      "Rectangular ads that occupy a portion of an app's layout; can be refreshed automatically after a period of time. See {helpLink} for more information.",
  },
  appAdUnitTypeBannerHelpLink: {
    id: 'appAdUnit.appAdUnitTypeBannerHelpLink',
    defaultMessage: 'Overview of banner ads',
  },
  appAdUnitTypeInterstitialDescription: {
    id: 'appAdUnit.appAdUnitTypeInterstitialDescription',
    defaultMessage:
      'Full-page ad format that appears at natural breaks and transitions, such as level completion. See {helpLink} for more information.',
  },
  appAdUnitTypeInterstitialHelpLink: {
    id: 'appAdUnit.appAdUnitTypeInterstitialHelpLink',
    defaultMessage: 'Overview of interstitial ads',
  },
  appAdUnitTypeNativeDescription: {
    id: 'appAdUnit.appAdUnitTypeNativeDescription',
    defaultMessage:
      'Customizable ad format that matches the look and feel of your app; appears inline with app content. See {helpLink} for more information.',
  },
  overviewNativeAdsLink: {
    id: 'appAdUnit.overviewNativeAdsLink',
    defaultMessage: 'Overview of native ads',
  },
  appAdUnitTypeDisplayAdsTitle: {
    id: 'appAdUnit.appAdUnitTypeDisplayAdsTitle',
    defaultMessage: 'Display ad',
  },
  appAdUnitTypeNativeAdsTitle: {
    id: 'appAdUnit.appAdUnitTypeNativeAdsTitle',
    defaultMessage: 'Native ad',
  },
  appAdUnitTypeVideoAdsTitle: {
    id: 'appAdUnit.appAdUnitTypeVideoAdsTitle',
    defaultMessage: 'Video ad',
  },
  appAdUnitDisplayAdsBannerAdSizeLabel: {
    id: 'appAdUnit.appAdUnitDisplayAdsBannerAdSizeLabel',
    defaultMessage: 'Size',
  },
  appAdUnitVideoAdsInterstitialVideoAdConfigTitle: {
    id: 'appAdUnit.appAdUnitVideoAdsInterstitialVideoAdConfigTitle',
    defaultMessage: 'Video ad configuration',
  },
  appAdUnitNativeAdConfigTitle: {
    id: 'appAdUnit.appAdUnitNativeAdConfigTitle',
    defaultMessage: 'Native ad configuration',
  },
  appAdUnitNativeAdsConfigFontLabel: {
    id: 'appAdUnit.appAdUnitNativeAdsConfigFontLabel',
    defaultMessage: 'Font',
  },
  appAdUnitNativeAdsConfigTitleColorLabel: {
    id: 'appAdUnit.appAdUnitNativeAdsConfigTitleColorLabel',
    defaultMessage: 'Title color',
  },
  appAdUnitNativeAdsConfigBackgroundLabel: {
    id: 'appAdUnit.appAdUnitNativeAdsConfigBackgroundLabel',
    defaultMessage: 'Background color',
  },
  appAdUnitNativeAdsConfigButtonBackgroundLabel: {
    id: 'appAdUnit.appAdUnitNativeAdsConfigButtonBackgroundLabel',
    defaultMessage: 'Button background color',
  },
  appAdUnitNativeAdsConfigMsgBar: {
    id: 'appAdUnit.appAdUnitNativeAdsConfigMsgBar',
    defaultMessage: 'Ads might look different from the preview shown.',
  },
  appAdUnitVideoAdsInterstitialVideoAdConfigVideoSkipLabel: {
    id: 'appAdUnit.appAdUnitVideoAdsInterstitialVideoAdConfigVideoSkipLabel',
    defaultMessage: 'Can users skip the video?',
  },
  createNewAppAdUnitBannerAdTypeFormTitle: {
    id: 'appAdUnit.createNewAppAdUnitBannerAdTypeFormTitle',
    defaultMessage: 'Banner ad media types',
  },
  createNewAppAdUnitInterstitialAdTypeFormTitle: {
    id: 'appAdUnit.createNewAppAdUnitInterstitialAdTypeFormTitle',
    defaultMessage: 'Interstitial ad media types',
  },
  createNewAppAdUnitNativeAdTypeFormTitle: {
    id: 'appAdUnit.createNewAppAdUnitNativeAdTypeFormTitle',
    defaultMessage: 'Native ad media types',
  },
  adTypeInfoMsgContent: {
    id: 'appAdUnit.adTypeInfoMsgContent',
    defaultMessage:
      'Smart auto-sizing will adapt to your site’s container, and so the actual ads served might look slightly different from the examples shown. For smart auto-sizing to work, {helpLink}.',
  },
  adTypeInfoMsgHelpLink: {
    id: 'appAdUnit.adTypeInfoMsgHelpLink',
    defaultMessage: 'ensure you have properly defined your container sizes',
  },
  adTypePreviewTitle: {
    id: 'appAdUnit.adTypePreviewTitle',
    defaultMessage: 'Ad preview',
  },
  adTypePreviewLayoutHorizontal: {
    id: 'appAdUnit.adTypePreviewLayoutHorizontal',
    defaultMessage: 'Horizontal',
  },
  adTypePreviewLayoutVertical: {
    id: 'appAdUnit.adTypePreviewLayoutVertical',
    defaultMessage: 'Vertical',
  },
  adTypePreviewLayoutSquare: {
    id: 'appAdUnit.adTypePreviewLayoutSquare',
    defaultMessage: 'Square',
  },
  adUnitFormCancelLabel: {
    id: 'appAdUnit.adUnitFormCancelLabel',
    defaultMessage: 'Cancel',
  },
  adUnitFormPreviousLabel: {
    id: 'appAdUnit.adUnitFormPreviousLabel',
    defaultMessage: 'Previous',
  },
  adUnitFormNextLabel: {
    id: 'appAdUnit.adUnitFormNextLabel',
    defaultMessage: 'Next',
  },
  adUnitFormDoneLabel: {
    id: 'appAdUnit.adUnitFormDoneLabel',
    defaultMessage: 'Done',
  },
  adUnitFormSubmissionLabelSuccess: {
    id: 'appAdUnit.adUnitFormSubmissionLabelSuccess',
    defaultMessage: 'Place the ad unit inside your app',
  },
  adUnitResultSummarySecondaryContent: {
    id: 'appAdUnit.adUnitResultSummarySecondaryContent',
    defaultMessage: 'Ad unit is successfully created. New ad unit may take up to an hour to start showing ads.',
  },
  adUnitResultSummarySecondaryContentSecondary: {
    id: 'appAdUnit.adUnitResultSummarySecondaryContentSecondary',
    defaultMessage: 'Follow these instructions to place the ad unit inside your app.',
  },
  adUnitResultSummaryCodeHelpContent: {
    id: 'appAdUnit.adUnitResultSummaryCodeHelpContent',
    defaultMessage: 'Need help with ad unit code? Try this.',
  },
  adUnitResultSummaryCodeButton: {
    id: 'appAdUnit.adUnitResultSummaryCodeButton',
    defaultMessage: 'Show code template',
  },
  adUnitResultSummaryCodeButtonInactive: {
    id: 'appAdUnit.adUnitResultSummaryCodeButtonInactive',
    defaultMessage: 'Hide code template',
  },
  adUnitResultSummaryFollowInstructionContent1: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent1',
    defaultMessage: 'Next, place the ad unit inside your app',
  },
  adUnitResultSummaryFollowInstructionContent2: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent2',
    defaultMessage: 'Follow these instructions:',
  },
  adUnitResultSummaryFollowInstructionContent3: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent3',
    defaultMessage: 'Complete the instructions in the {helpLink} using this publisher code',
  },
  adUnitResultSummaryFollowInstructionContent3HelpLink: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent3HelpLink',
    defaultMessage: 'Microsoft pubCenter Ads SDK guide',
  },
  adUnitResultSummaryFollowInstructionContent4: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent4',
    defaultMessage:
      "Follow the {helpLink} to integrate the SDK. You'll specify ad type, size, and placement when you integrate the code using this Ad Unit Code (Placement Code):",
  },
  adUnitResultSummaryFollowInstructionContent4HelpLink: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent4HelpLink',
    defaultMessage: 'banner implementation guide',
  },
  adUnitResultSummaryFollowInstructionContent5: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent5',
    defaultMessage: 'Review the {helpLink} to ensure your implementation complies.',
  },
  adUnitResultSummaryFollowInstructionContent5HelpLink: {
    id: 'appAdUnit.adUnitResultSummaryFollowInstructionContent5HelpLink',
    defaultMessage: 'Microsoft pubCenter Ad Policies',
  },
  copyCode: {
    id: 'appAdUnit.copyCode',
    defaultMessage: 'Copy text',
  },
  copiedCode: {
    id: 'appAdUnit.copiedCode',
    defaultMessage: 'Copied!',
  },
  adPreviewPrimaryTitle: {
    id: 'appAdUnit.adPreviewPrimaryTitle',
    defaultMessage: 'Floral arrangements for all occasions',
  },
  adPreviewPrimaryDescriptionLine1: {
    id: 'appAdUnit.adPreviewPrimaryDescriptionLine1',
    defaultMessage: 'the leading floral experts',
  },
  adPreviewPrimaryDescriptionLine2: {
    id: 'appAdUnit.adPreviewPrimaryDescriptionLine2',
    defaultMessage: 'We use only the freshest, highest-quality flowers and greenery to ensure that each bouquet is a work of art.',
  },
  adPreviewPrimaryDescriptionActionButton: {
    id: 'appAdUnit.adPreviewPrimaryDescriptionActionButton',
    defaultMessage: 'Shop now',
  },
  adPreviewAdBadgeContent: {
    id: 'appAdUnit.adPreviewAdBadgeContent',
    defaultMessage: 'Ad',
  },
  appDetailSubTitle: {
    id: 'appAdUnit.appDetailSubTitle',
    defaultMessage: 'Currently we only support adding live apps that are in the Microsoft Store',
  },
  appAdUnitNamePlaceholder: {
    id: 'appAdUnit.appAdUnitNamePlaceholder',
    defaultMessage: 'Enter Ad Unit Name',
  },
  appAdUnitVideoSkipYes: {
    id: 'appAdUnit.appAdUnitVideoSkipYes',
    defaultMessage: 'Yes, video can be skipped.',
  },
  appAdUnitVideoSkipNo: {
    id: 'appAdUnit.appAdUnitVideoSkipNo',
    defaultMessage: 'No, video cannot be skipped.',
  },
  appAdUnitVideoAdsDuration: {
    id: 'appAdUnit.appAdUnitVideoAdsDuration',
    defaultMessage: 'Video duration',
  },
  appAdUnitVideoAdsDurationMetric: {
    id: 'appAdUnit.appAdUnitVideoAdsDurationMetric',
    defaultMessage: 'seconds',
  },
  appAdUnitNativePreviewTitle: {
    id: 'appAdUnit.appAdUnitNativePreviewTitle',
    defaultMessage: 'Ad preview',
  },
  nativeAdPreviewContainerSize: {
    id: 'appAdUnit.nativeAdPreviewContainerSize',
    defaultMessage: 'Container size',
  },
  appAdUnitVideoPlayMethodAutoSoundOn: {
    id: 'appAdUnit.appAdUnitVideoPlayMethodAutoSoundOn',
    defaultMessage: 'Auto-play, sound on',
  },
  appAdUnitVideoPlayMethodAutoSoundOff: {
    id: 'appAdUnit.appAdUnitVideoPlayMethodAutoSoundOff',
    defaultMessage: 'Auto-play, sound off',
  },
  appAdUnitVideoPlayMethodClickToPlay: {
    id: 'appAdUnit.appAdUnitVideoPlayMethodClickToPlay',
    defaultMessage: 'Click-to-play',
  },
  appAdUnitVideoPlayMethodMouseOff: {
    id: 'appAdUnit.appAdUnitVideoPlayMethodMouseOff',
    defaultMessage: 'Mouse-off',
  },
  appAdUnitVideoPlayMethodAutoplay: {
    id: 'appAdUnit.appAdUnitVideoPlayMethodAutoplay',
    defaultMessage: 'Auto-play',
  },
  appAdUnitVideoPlayMethodSoundUnknown: {
    id: 'appAdUnit.appAdUnitVideoPlayMethodSoundUnknown',
    defaultMessage: 'Sound unknown',
  },
  appAdUnitVideoPlayMethodFieldLabel: {
    id: 'appAdUnit.appAdUnitVideoPlayMethodFieldLabel',
    defaultMessage: 'Video auto-play method',
  },
  appAdUnitUpdateSuccess: {
    id: 'appAdUnit.appAdUnitUpdateSuccess',
    defaultMessage: 'Updated app ad unit {adunitId} success',
  },
});
