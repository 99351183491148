import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../../../theme';

export interface IEmptyChartStyleProps {
  theme: ITheme;
}

type StyleProperties =
  | 'highlightedFilterInfoContainer'
  | 'filterInfoContainer'
  | 'filterInfoContainerUnfocused'
  | 'filterListPageStyle'
  | 'filterCloseIcon'
  | 'highlightedFilterInfoSelectors'
  | 'filterInfoSelectors'
  | 'filterBarFocused'
  | 'filterBarUnFocused'
  | 'filterIcon'
  | 'filterInfoFocused'
  | 'hidden'
  | 'filterInfoUnfocused';
type FilterListBarStyles = Record<StyleProperties, string>;

export const getStyles = (props: IEmptyChartStyleProps): FilterListBarStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  return mergeStyleSets({
    filterBarFocused: {
      backgroundColor: theme.palette.neutralLighter,
      display: 'flex',
      marginBottom: spacing.size16,
      position: 'relative',
    },
    filterBarUnFocused: {
      marginBottom: spacing.size16,
      marginLeft: spacing.size24,
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    filterInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.neutralSecondary,
      margin: '8px 0 8px 16px',
      color: theme.palette.white,
      cursor: 'pointer',
      alignItems: 'center',
    },
    filterInfoContainerUnfocused: {
      display: 'flex',
      flexDirection: 'row',
    },
    highlightedFilterInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.themePrimary,
      margin: '8px 0 8px 16px',
      color: theme.palette.white,
      cursor: 'pointer',
    },
    filterInfoFocused: {
      fontSize: theme.fonts.medium.fontSize,
      alignContent: 'center',
      padding: '4px 16px',
      lineHeight: '19px',
      display: 'inline-block',
    },
    filterInfoSelectors: {
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.neutralPrimaryAlt,
        },
      },
    },
    highlightedFilterInfoSelectors: {
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.themeDark,
        },
      },
    },
    filterCloseIcon: {
      '&:focus, &:hover': {
        backgroundColor: theme.palette.neutralPrimaryAlt,
        color: theme.palette.white,
      },
      color: theme.palette.white,
    },
    hidden: {
      '&:focus, &:hover, &:click': {
        backgroundColor: theme.palette.white,
        color: theme.palette.white,
      },
      color: theme.palette.white,
      backgroundColor: theme.palette.white,
      width: 0,
    },
    filterIcon: {
      padding: '5px 4px',
      color: theme.palette.themePrimary,
    },
    filterInfoUnfocused: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.themePrimary,
      textAlign: 'center',
      verticalAlign: 'center',
      cursor: 'pointer',
      padding: '3px 4px',
    },
    filterListPageStyle: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
