import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class PublisherProfileTabs extends Model {
  public className = UserRoleEntity.PublisherProfileTabs;
  public profile: number;
  public userManagement: number;
  public payments: number;
  public settings: number;

  constructor(params: PublisherProfileTabs) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: PublisherProfileTabs, publisherProfileTabs: PublisherProfileTabs) {
    // tslint:disable:no-bitwise
    this.accessPermission |= publisherProfileTabs.accessPermission;
    this.profile |= publisherProfileTabs.profile;
    this.userManagement |= publisherProfileTabs.userManagement;
    this.payments |= publisherProfileTabs.payments;
    this.settings |= publisherProfileTabs.settings;
    // tslint:enable:no-bitwise
  }
}
