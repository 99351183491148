import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { getAppStore, onUserContextChanged, UserContext } from '../../../../@data';
import { loginPubCenterAADUser } from '../../../../@data/services/PubCenterAADAuthService';
import { aadConfig } from '../../../../config/pubCenterAAD.config';
import { onLoadingSet, onPubCenterAADLogin } from '../actions';

orchestrator(onPubCenterAADLogin, async ({ requestParams }) => {
  Log.write('Logging in through new integrated login flow!!');

  let userContext = getAppStore().userContext;
  if (!userContext) {
    userContext = new UserContext();
  }

  onUserContextChanged(userContext);
  onLoadingSet(true);

  requestParams = { redirectStartPage: aadConfig.auth.redirectUri, ...requestParams };
  loginPubCenterAADUser(requestParams);
});
