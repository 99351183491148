import { IProcessedStyleSet, getTheme } from '@fluentui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAdUnitTypeSelectorProps } from '.';
import { usePreloadSVGImages } from '../../../../../../hooks';
import { getClassNames } from '../../../Create/CreateAdunit';
import { getStyles } from '../../../Create/CreateAdunit.styles';
import { ICreateAdunitStyles } from '../../../Create/CreateAdunit.types';
import { TythonAdTypes, TythonAdUnitDisplayAdTypeLayout } from '../../CreateAdunit/constants';
import HorizontalBottomAdPreview from '../../resources/HorizontalBottomAdPreview.svg';
import HorizontalUpperAdPreview from '../../resources/HorizontalUpperAdPreview.svg';
import SquareBottomAdPreview from '../../resources/SquareBottomAdPreview.svg';
import SquareLeftAdPreview from '../../resources/SquareLeftAdPreview.svg';
import VerticalBottomAdPreview from '../../resources/VerticalBottomAdPreview.svg';
import VerticalLeftAdPreview from '../../resources/VerticalLeftAdPreview.svg';
import { IAdPreviewConfig } from './PreviewComponent';

type UseAdUnitTypeSelector = ({
  adTypes,
  nativeProperties,
  onChange,
}: IAdUnitTypeSelectorProps) => {
  classNames: IProcessedStyleSet<ICreateAdunitStyles>;
  isSdkLoaded: boolean;
  adPreviewConfig: IAdPreviewConfig;
  isNativeAdActive: boolean;
  isDisplayAdActive: boolean;
  shouldRenderNoSelectionMsg: boolean;
  shouldRenderFewerSelectionMsg: boolean;
  displayAdLayout: TythonAdUnitDisplayAdTypeLayout;
  onDisaplayAdLayoutChange: (layout: TythonAdUnitDisplayAdTypeLayout) => void;
  onAdPreviewConfigChange: (key: keyof IAdPreviewConfig, value?: string) => void;
  onToggleNativeAd: (active: boolean) => void;
  onToggleDisplayAd: (active: boolean) => void;
  onToggleAdType: (active: boolean, type: TythonAdTypes) => void;
};

export const DEFAULT_AD_PREVIEW_HEIGHT = 250;
const DEFAULT_AD_PREVIEW_WIDTH = 500;
const NUMERIC_FIELDS = ['adHeight', 'adWidth'];

const theme = getTheme();

const useAdUnitTypeSelector: UseAdUnitTypeSelector = ({ adTypes = [], nativeProperties, onChange }) => {
  const { fontFamily, background, buttonBackground, titleColor } = nativeProperties ?? {};
  const [isSdkLoaded, setSdkLoaded] = useState<boolean>(false);
  const [adDimensions, setAdDimensions] = useState<[number, number]>([DEFAULT_AD_PREVIEW_HEIGHT, DEFAULT_AD_PREVIEW_WIDTH]);
  const [displayAdLayout, setDisplayAdLayout] = useState<TythonAdUnitDisplayAdTypeLayout>(TythonAdUnitDisplayAdTypeLayout.Horizontal);

  const imageList = [
    HorizontalUpperAdPreview,
    VerticalLeftAdPreview,
    SquareLeftAdPreview,
    HorizontalBottomAdPreview,
    SquareBottomAdPreview,
    VerticalBottomAdPreview,
  ];
  usePreloadSVGImages(imageList);

  const classNames = getClassNames(getStyles, {
    theme,
    adPreviewHeight: adDimensions[0],
    adPreviewWidth: adDimensions[1],
  });

  const adPreviewConfig = useMemo(
    () => ({
      adHeight: adDimensions[0],
      adWidth: adDimensions[1],
      fontFamily: fontFamily,
      background: background,
      buttonBackground: buttonBackground,
      titleColor: titleColor,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adDimensions, nativeProperties]
  );

  const [isNativeAdActive, isDisplayAdActive, shouldRenderNoSelectionMsg, shouldRenderFewerSelectionMsg] = useMemo(
    () => [
      adTypes?.includes(TythonAdTypes.Native),
      adTypes?.includes(TythonAdTypes.Display),
      adTypes ? adTypes.length === 0 : false,
      adTypes ? adTypes.length === 1 : false,
    ],
    [adTypes]
  );

  const onAdPreviewConfigChange = (key: keyof IAdPreviewConfig, value: string) => {
    switch (key) {
      case 'adHeight': {
        setAdDimensions([Number(value), adDimensions[1]]);
        break;
      }

      case 'adWidth': {
        setAdDimensions([adDimensions[0], Number(value)]);
        break;
      }

      default: {
        const { adHeight, adWidth, ...nativePropertyConfigs } = adPreviewConfig;
        onChange({ nativeProperties: { ...nativePropertyConfigs, [key]: NUMERIC_FIELDS.includes(key) ? Number(value) : value } });
      }
    }
  };

  const onToggleAdType = useCallback(
    (active: boolean, type: TythonAdTypes) => {
      if (active) {
        onChange({ adTypes: Array.from(new Set([...adTypes, type])) });
      } else {
        onChange({ adTypes: adTypes.filter((currentType) => currentType !== type) });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adTypes]
  );

  // load ad preview SDK at parent level to cache the script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://adsdk.microsoft.com/ad-preview/sdk.js';
    script.async = true;
    script.crossOrigin = 'anonymous';

    script.onload = () => {
      setSdkLoaded(true);
    };

    document.head.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  /**
   * pre-load svg when component mounts
   * TODO: move this to a common hook
   */

  // useEffect(() => {
  //   const imageList = [
  //     HorizontalUpperAdPreview,
  //     VerticalLeftAdPreview,
  //     SquareLeftAdPreview,
  //     HorizontalBottomAdPreview,
  //     SquareBottomAdPreview,
  //     VerticalBottomAdPreview,
  //   ];
  //   const preloadContainer = document.createElement('div');
  //   preloadContainer.style.display = 'none';

  //   imageList.forEach((imageSrc) => {
  //     const img = new Image();
  //     img.src = imageSrc;
  //     img.width = 0;
  //     img.height = 0;
  //     preloadContainer.appendChild(img);
  //   });

  //   document.body.appendChild(preloadContainer);

  //   return () => {
  //     // Cleanup to remove the preloaded images when not needed
  //     document.body.removeChild(preloadContainer);
  //   };
  // }, []);

  return {
    isSdkLoaded,
    classNames,
    adPreviewConfig,
    isNativeAdActive,
    isDisplayAdActive,
    shouldRenderNoSelectionMsg,
    shouldRenderFewerSelectionMsg,
    displayAdLayout,
    onDisaplayAdLayoutChange: setDisplayAdLayout,
    onToggleAdType,
    onAdPreviewConfigChange,
    onToggleDisplayAd: (active) => onToggleAdType(active, TythonAdTypes.Display),
    onToggleNativeAd: (active) => onToggleAdType(active, TythonAdTypes.Native),
  };
};

export default useAdUnitTypeSelector;
