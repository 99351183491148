import { defineMessages } from 'react-intl';

export default defineMessages({
  accountSettings: {
    id: 'settingsPage.accountSettings',
    defaultMessage: 'Account settings',
  },
  userSettings: {
    id: 'settingsPage.userSettings',
    defaultMessage: 'Profile settings',
  },
  publisherPreferencesFailed: {
    id: 'publisherPreferences.failed',
    defaultMessage: 'We’re unable to access your publisher preferences at this time. Please try again later.',
  },
  userPreferencesFailed: {
    id: 'userPreferences.failed',
    defaultMessage: 'We’re unable to access your user preferences at this time. Please try again later.',
  },
  notificationPreferencesFailed: {
    id: 'notificationPreferences.failed',
    defaultMessage: 'We’re unable to update your notification preferences. Please try again later.',
  },
  deletingUserSuccess: {
    id: 'userManagement.deletingUserSuccess',
    defaultMessage: 'Successfully deleted the user - {name}',
  },
  updatedNotificationPreferencesSuccessfully: {
    id: 'notificationPreferences.updatedSuccessfully',
    defaultMessage: 'Updated notification preferences successfully',
  },
  deletedPublisherSuccess: {
    id: 'userManagement.deletedPublisherSuccess',
    defaultMessage: 'Successfully deleted the publisher - {name}',
  },
  invalidStateLoginOrReport: {
    id: 'userManagement.invalidStateReLoginOrReport',
    defaultMessage: 'You have arrived in an invalid state, please re-login or report if issue persists.',
  },
});
