import { Checkbox, classNamesFunction, ComboBox, getTheme, IComboBoxOption, IProcessedStyleSet, TextField } from '@fluentui/react';
import * as React from 'react';
import { getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged, onNavigateBack } from '../../../../@data';
import { FormItem, FormState } from '../../../../components/Form';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { userLocales } from '../../../../config/locale';

import { LOCALE_LANGUAGE_MAPPING } from '../../../../globalization';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { injectIntlWithObserver } from '../../../../utils';
import { RouteName } from '../../@data';
import { createReturnUrl } from '../../@data/service/PartnerManagementService';
import { getStore } from '../@data';
import {
  onFormAccountRoleChanged,
  onFormCancelled,
  onFormEmailAddressChanged,
  onFormFirstNameChanged,
  onFormInitialized,
  onFormLastNameChanged,
  onFormRoleAccountListChanged,
  onFormSubmitted,
  onFormUserLocaleChanged,
  onFormUsernameChanged,
} from '../@data/actions';
import { AccountRole } from '../AccountRole/AccountRole';
import messages from '../UserPage.messages';
import { getStyles } from './InviteUser.styles';
import { IFormInitializationState, IInviteUserProps, IInviteUserStyleProps, IInviteUserStyles } from './InviteUser.types';

const getClassNames = classNamesFunction<IInviteUserStyleProps, IInviteUserStyles>();

export const InviteUser = injectIntlWithObserver(
  class extends React.Component<IInviteUserProps, IFormInitializationState> {
    private _classNames: IProcessedStyleSet<IInviteUserStyles>;
    constructor(props: IInviteUserProps) {
      super(props);
      this.state = {
        initializeForm: true,
      };
    }

    public componentDidMount() {
      const publisherId = this.props.match.params.publisherId;
      const entityId = this.props.match.params.id;
      this.setState({ id: entityId });
      if (publisherId) {
        onFormInitialized(this.props.mode, publisherId, entityId);
      }
    }

    public componentDidUpdate() {
      const publisherId = this.props.match.params.publisherId;
      const entityId = this.props.match.params.id;
      if (this.state.initializeForm && this.state.id !== entityId) {
        this.setState({ id: entityId });
        if (publisherId) {
          onFormInitialized(this.props.mode, publisherId, entityId);
        }
      }
    }

    public render(): JSX.Element {
      const { formatMessage } = this.props.intl;
      const theme = getTheme();
      this._classNames = getClassNames(getStyles, { theme });

      const publisherId = this.props.match.params.publisherId;
      const store = getStore();
      const helpInfo = getAppStore().helpInfo;
      return (
        <DetailPage
          titleLabel={this.props.mode === FormState.Edit ? formatMessage(messages.editPageTitle) : formatMessage(messages.createPageTitle)}
          helpId={'pop_PCv4_UserInvite'}
          submitLabel={formatMessage(this.props.mode === FormState.Edit ? messages.updateUser : messages.createPageTitle)}
          onSubmit={() => this._onSubmit(publisherId)}
          onCancel={() => this._onCancel(publisherId)}
          onNavClick={() => this._onCancel(publisherId)}
          saving={getAppStore().saving}
          pageMode={this.props.mode}
        >
          <>
            <FormItem>
              <TextField
                required={true}
                label={formatMessage(messages.firstName)}
                ariaLabel={formatMessage(messages.firstName)}
                value={store.active.firstName}
                onChange={(_, value) => onFormFirstNameChanged(value)}
                errorMessage={store.errors.firstName}
                disabled={store.active.isLegacyUser || this.props.mode === FormState.Edit}
              />
            </FormItem>
            <FormItem>
              <TextField
                required={true}
                label={formatMessage(messages.lastName)}
                ariaLabel={formatMessage(messages.lastName)}
                value={store.active.lastName}
                onChange={(_, value) => onFormLastNameChanged(value)}
                errorMessage={store.errors.lastName}
                disabled={store.active.isLegacyUser || this.props.mode === FormState.Edit}
              />
            </FormItem>
            <FormItem formWidth={440}>
              <TextField
                required={true}
                label={formatMessage(messages.emailAddress)}
                ariaLabel={formatMessage(messages.emailAddress)}
                value={store.active.emailAddress}
                onChange={(_, value) => onFormEmailAddressChanged(value)}
                errorMessage={store.errors.emailAddress}
                disabled={store.active.isLegacyUser || this.props.mode === FormState.Edit}
              />
            </FormItem>
          </>

          {store.active.isLegacyUser && (
            <>
              <FormItem>
                <TextField
                  label={formatMessage(messages.telephoneNumber)}
                  ariaLabel={formatMessage(messages.telephoneNumber)}
                  value={store.active.phoneNumber}
                  disabled={true}
                />
              </FormItem>
              <FormItem>
                <TextField
                  label={formatMessage(messages.faxNumber)}
                  ariaLabel={formatMessage(messages.faxNumber)}
                  value={store.active.faxNumber}
                  disabled={true}
                />
              </FormItem>
            </>
          )}
          {store.active.isLegacyUser && (
            <FormItem>
              <TextField
                label={formatMessage(messages.userName)}
                ariaLabel={formatMessage(messages.userName)}
                required={true}
                value={store.active.userName}
                onChange={(_, value) => onFormUsernameChanged(value)}
                errorMessage={store.errors.userName}
                disabled={this.props.mode === FormState.Edit}
              />
            </FormItem>
          )}
          {store.active.isLegacyUser && (
            <FormItem>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                label={formatMessage(messages.preferredLanguage)}
                ariaLabel={formatMessage(messages.preferredLanguage)}
                required={true}
                options={this._createLocaleOptions(false)}
                selectedKey={store.active.language}
                disabled={store.active.isLegacyUser}
                tabIndex={store.active.isLegacyUser ? -1 : undefined}
              />
            </FormItem>
          )}
          {!store.active.isLegacyUser && (
            <FormItem>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                label={formatMessage(messages.preferredLanguage)}
                ariaLabel={formatMessage(messages.preferredLanguage)}
                required={true}
                options={this._createLocaleOptions(true)}
                selectedKey={store.active.localeId}
                errorMessage={store.errors.localeId}
                onChange={(_, value) => onFormUserLocaleChanged(value)}
                disabled={this.props.mode === FormState.Edit}
                tabIndex={this.props.mode === FormState.Edit ? -1 : undefined}
              />
            </FormItem>
          )}
          <FormItem formWidth={800}>
            <AccountRole
              defaultSelectedKey={store.active.accountRole}
              accountList={store.active.accountsList}
              accountManagerAccountsSelected={store.active.AccountManagerAccountsSelected}
              reportUserAccounstSelected={store.active.ReportUserAccounstSelected}
              adViewAccountsSelected={store.active.AdViewAccountsSelected}
              listManagerAccountsSelected={store.active.ListManagerAccountsSelected}
              onChange={(_, value) => onFormAccountRoleChanged(value!)}
              onSelectedItems={(items) => onFormRoleAccountListChanged(items)}
              errorMessage={
                store.errors.AccountManagerAccountsSelected ||
                store.errors.ReportUserAccounstSelected ||
                store.errors.ListManagerAccountsSelected ||
                store.errors.AdViewAccountsSelected
              }
            />
          </FormItem>
          {store.active.isLegacyUser && (
            <>
              <FormItem>
                <FormLabel
                  labelContent={formatMessage(messages.marketingContactSetting)}
                  calloutContent={helpInfo}
                  iconButtonOnClick={() => onHelpInfoCalloutOpened('')}
                  calloutOnDismiss={() => onHelpInfoChanged()}
                  intl={this.props.intl}
                />
                <Checkbox
                  label={formatMessage(messages.email)}
                  ariaLabel={formatMessage(messages.email)}
                  styles={this._classNames.subComponentStyles.checkbox}
                  checked={!!store.active.contactByEmail}
                  disabled={true}
                />
                <Checkbox
                  label={formatMessage(messages.phone)}
                  ariaLabel={formatMessage(messages.phone)}
                  styles={this._classNames.subComponentStyles.checkbox}
                  checked={!!store.active.contactByPhone}
                  disabled={true}
                />
                <Checkbox
                  label={formatMessage(messages.postalMail)}
                  ariaLabel={formatMessage(messages.postalMail)}
                  styles={this._classNames.subComponentStyles.checkbox}
                  checked={store.active.contactByPost}
                  disabled={true}
                />
              </FormItem>
            </>
          )}
        </DetailPage>
      );
    }

    private _createLocaleOptions(isId: boolean): IComboBoxOption[] {
      const availableLocales: IComboBoxOption[] = [];
      const LOCALE = LOCALE_LANGUAGE_MAPPING[getAppStore().locale];

      Object.keys(userLocales).forEach((userLocale) => {
        availableLocales!.push({
          key: isId ? parseInt(userLocale, 10) : userLocales[userLocale],
          text: LOCALE[userLocale],
          ariaLabel: LOCALE[userLocale],
        });
      });

      availableLocales.sort((a, b) => {
        const firstValue = a.text;
        const secondValue = b.text;

        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      });
      return availableLocales;
    }

    private _onSubmit = (publisherId?: string): void => {
      this.setState({ initializeForm: false });
      onFormSubmitted(this.props.mode, this.props.history);
    };

    private _onCancel = (publisherId?: string): void => {
      this.setState({ initializeForm: false });
      onFormCancelled();
      const returnUrl = createReturnUrl(RouteName.user, publisherId);
      onNavigateBack(this.props.history, returnUrl);
    };
  }
);
