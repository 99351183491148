import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import { IHeaderItem } from '../../../../../../components/Header/Header.types';
import { getTythonReportDashboardPageRoute } from '../../../../../../utils/routeUtils';
import { IAnnouncementCard } from '../../AnnouncementCard/AnnouncementCard';
import { connectToSupportTeam } from '../../ResourcesSection/ResourcesSection';
import messages from '../Announcements.message';
import { getClassNames } from '../Announcements.styles';

export const getAnnouncementCards = (
  history,
  setIsNotificationPanelOpen,
  itemData: IHeaderItem,
  CMUIPopupArticleUsingButton,
  formatMessage
): IAnnouncementCard[] => {
  const { buttonStyle } = getClassNames();

  const announcementCards: IAnnouncementCard[] = [
    {
      title: formatMessage(messages.firstAnnouncementTitle),
      description: formatMessage(messages.firstAnnouncementDescription),
      date: 1705693326950,
      button: (
        <PrimaryButton
          text={formatMessage(messages.firstAnnouncementButton)}
          className={buttonStyle}
          onClick={() => connectToSupportTeam()}
          tabIndex={0}
        />
      ),
    },
    {
      title: formatMessage(messages.secondAnnouncementTitle),
      description: formatMessage(messages.secondAnnouncementDescription),
      date: 1693008001000,
      button: (
        <CMUIPopupArticleUsingButton
          query="ext07504"
          className={itemData.className}
          anchorStyle={{ textDecoration: 'none', color: 'black' }}
          formatMessage={formatMessage}
          buttonText={messages.secondAnnouncementButton}
          buttonStyle={buttonStyle}
        />
      ),
    },
    {
      title: formatMessage(messages.thirdAnnouncementTitle),
      description: formatMessage(messages.thirdAnnouncementDescription),
      date: 1693008001000,
      button: (
        <PrimaryButton
          text={formatMessage(messages.thirdAnnouncementButton)}
          className={buttonStyle}
          onClick={() => handleViewReportButton()}
          tabIndex={0}
        />
      ),
    },
  ];

  function handleViewReportButton() {
    history.push(getTythonReportDashboardPageRoute());
  }

  return announcementCards;
};
