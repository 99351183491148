import { mixed, object, string } from 'yup';
import { NetworkAffiliation } from '../../../../../../@data';
import { IPublisherViewModel } from './IPublisherViewModel';

export function publisherEditErrorModel() {
  return object<IPublisherViewModel>().shape({
    name: string()
      .trim()
      .required(),
    partner: string().required(),
    networkAffiliation: mixed()
      .oneOf([NetworkAffiliation.Syndicated, NetworkAffiliation.OwnedAndOperated])
      .required(),
    addressLine1: string().required(),
    addressLine2: string(),
    city: string().required(),
    state: string().required(),
    country: string().required()
  });
}
