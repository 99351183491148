import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  getAppStore,
  listEntity,
  onLoadingChanged,
  onNotificationChanged,
  showErrorToastNotification,
  User,
} from '../../../../../../@data';
import messages from '../../../UserManagement.messages';
import { loadUsers } from '../actions/usersTabActions';
import { setUsers } from '../mutatorActions';

orchestrator(loadUsers, async () => {
  const { userContext, publisher } = getAppStore();

  if (userContext && publisher) {
    try {
      onLoadingChanged(true);

      const users = await listEntity([publisher], userContext, User, new URLSearchParams([['extendedInfo', 'true']]));
      setUsers(users);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.loadUserDetailsFailed });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.userManagementInvalidState,
    });
  }
});
