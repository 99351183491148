import { IAccountPaymentInformation, IAdDeliverySetting, IAdExtensionOption, IMarket } from '../interfaces';
import { Model } from './Model';

export class Account extends Model {
  public apiPath = undefined;
  public className = 'accounts';
  public number?: number;
  public countryCode?: string;
  public currency?: string;
  public currencyCode?: string;
  public languageCode?: string;
  public primaryContactUserId?: number;
  public primaryContactUsername?: string;
  public createdDateTime?: string;
  public lastModifiedDateTime?: string;
  public lastModifiedBy?: string;
  public eTag?: string;
  public additionalMarkets?: IMarket[];
  public supportedSearchMarkets?: string[];
  public supportedMobileSearchMarkets?: string[];
  public enabledFeatures?: string[];
  public adDeliverySettings?: IAdDeliverySetting[];
  public timeZoneId?: number;
  public revenueShare?: number;
  public allowRichAdsInSearch?: boolean;
  public adExtensionOptions?: IAdExtensionOption[];
  public storeIds?: number[];
  public partnerCodes?: string[];
  public formCodes?: string[];
  public startDistributionPlacementCodes?: string[];
  public startDistributionOCIDL2s?: string[];
  public startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage?: number;
  public paymentInformation?: IAccountPaymentInformation;
}
