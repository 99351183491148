import { EvidenceStatus } from '../enums';
import { Model } from './Model';

export class AppealHistory extends Model {
  public className = 'appeals';
  public apiPath = undefined;
  public rootRejectionCode: string;
  public latestEvidenceDocumentType: string;
  public scanStatus: EvidenceStatus;
  public submissionStatus: EvidenceStatus;
  public reviewStatus: string;
  public evidenceSubmissionHistory: EvidenceSubmissionHistory[];
}

export class EvidenceSubmissionHistory {
  public customerDocumentName: string;
  public customerDocumentFileSizeBytes: number;
  public uploadedDateTime: Date;
  public submittedByUserId: string;
  public submittedByUserName: string;
  public evidenceDocumentType: string;
}
