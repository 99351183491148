import { Model } from '../../../../../../../@data';

export class BlockImageSummaryData extends Model {
  public apiPath = undefined;
  public className = 'blockImagesSummaryData';
  public adId?: number;
  public adTitle?: string;
  public image?: string;
  public adUnitId?: number;
  public blockReason?: string;
  public blockReasonComment?: string;
  public reportedBy?: number;
  public reportedByName?: string;
  public modifiedDateTime?: string;
}
