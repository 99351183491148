import { Avatar, Body1, Body2, Button, Card, Link, Title3 } from '@fluentui/react-components';
import { Copy20Regular } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AdUnit, getAppStore } from '../../../../../@data';
import AppAdUnitCodePanel, { useAppAdUnitCodePanel } from '../../../Ads/Manage/hooks/useAppAdUnitCodePanel';
import messages from '../messages';
import CodeIcon from './resources/codeIcon.svg';
import { useAppAdUnitResultSummaryStyles } from './styles';
import { AppAdUnitResultSummaryType } from './types';

const AppAdUnitResultSummary: AppAdUnitResultSummaryType = ({ adUnitId, shouldRenderResultSummary, intl: { formatMessage } }) => {
  const classNames = useAppAdUnitResultSummaryStyles();
  const adUnitPanelProps = useAppAdUnitCodePanel(true);
  const { publisher } = getAppStore();
  const [isCodeBlockActive, setIsCodeBlockActive] = React.useState<boolean>(false);

  const onCopy = (content: string) => {
    navigator.clipboard.writeText(content);
  };

  useEffect(() => {
    adUnitPanelProps.onPanelTrigger(isCodeBlockActive, ({ id: adUnitId } as unknown) as AdUnit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeBlockActive]);

  if (!shouldRenderResultSummary) {
    return <></>;
  }

  const publisherIdCodeSnippet = `publisher_${publisher?.id}`;
  return (
    <div className={classNames.root}>
      <Title3 block align="start">
        {formatMessage(messages.adUnitFormSubmissionLabelSuccess)}
      </Title3>
      <br />
      <Body2 block align="start">
        {formatMessage(messages.adUnitResultSummarySecondaryContent)}
      </Body2>
      <Body2 block align="start">
        {formatMessage(messages.adUnitResultSummarySecondaryContentSecondary)}
      </Body2>
      <br />
      <Body1 block>
        <Avatar initials="1" size={20} color="navy" className={classNames.stepAvatar} />
        <FormattedMessage
          {...messages.adUnitResultSummaryFollowInstructionContent3}
          values={{
            helpLink: (
              <Link
                target="_blank"
                href="https://eng.ms/docs/experiences-devices/webxt/microsoft-ads/pubcenter/microsoftadssdk/microsoft-ads-sdk/ads/docs/quickstart-guide"
              >
                {formatMessage(messages.adUnitResultSummaryFollowInstructionContent3HelpLink)}
              </Link>
            ),
          }}
        />
      </Body1>
      <div className={classNames.codeCardWrapper}>
        <Card className={classNames.codeCard}>
          <img src={CodeIcon} className={classNames.codeIcon} alt={formatMessage(messages.adUnitResultSummaryFollowInstructionContent3)} />
          <div className={classNames.codeCardContent}>
            <Body1>{publisherIdCodeSnippet}</Body1>
            <Button onClick={() => onCopy(publisherIdCodeSnippet)} icon={<Copy20Regular />} />
          </div>
        </Card>
      </div>

      <Body1 block>
        <Avatar initials="2" size={20} color="navy" className={classNames.stepAvatar} />
        <FormattedMessage
          {...messages.adUnitResultSummaryFollowInstructionContent4}
          values={{
            helpLink: formatMessage(messages.adUnitResultSummaryFollowInstructionContent4HelpLink),
          }}
        />
      </Body1>
      <div className={classNames.codeCardWrapper}>
        <Card className={classNames.codeCard}>
          <img src={CodeIcon} className={classNames.codeIcon} alt={formatMessage(messages.adUnitResultSummaryFollowInstructionContent4)} />
          <div className={classNames.codeCardContent}>
            <Body1>{`adunit_${adUnitId}`}</Body1>
            <Button onClick={() => onCopy(`adunit_${adUnitId}`)} icon={<Copy20Regular />} />
          </div>
        </Card>
        <Body1 block align="start" className={classNames.codeHelpText}>
          {formatMessage(messages.adUnitResultSummaryCodeHelpContent)}
        </Body1>
        <Button onClick={() => setIsCodeBlockActive(!isCodeBlockActive)} className={classNames.codePanelButton}>
          {formatMessage(
            adUnitPanelProps.isPanelOpen ? messages.adUnitResultSummaryCodeButtonInactive : messages.adUnitResultSummaryCodeButton
          )}
        </Button>
        <AppAdUnitCodePanel {...adUnitPanelProps} />
      </div>

      <Body1 block>
        <Avatar initials="3" size={20} color="navy" className={classNames.stepAvatar} />
        <FormattedMessage
          {...messages.adUnitResultSummaryFollowInstructionContent5}
          values={{
            helpLink: formatMessage(messages.adUnitResultSummaryFollowInstructionContent5HelpLink),
          }}
        />
      </Body1>
    </div>
  );
};

export default injectIntl(AppAdUnitResultSummary);
