import { defineMessages } from 'react-intl';

export default defineMessages({
  createPageTitle: {
    id: 'partnermanagement.account.createPageTitle',
    defaultMessage: 'Create Account',
  },
  editPageTitle: {
    id: 'partnermanagement.account.editPageTitle',
    defaultMessage: 'Edit Account',
  },
  updateAccount: {
    id: 'partnermanagement.account.updateAccount',
    defaultMessage: 'Update Account',
  },
  accountName: {
    id: 'partnermanagement.account.accountName',
    defaultMessage: 'Account name',
  },
  primaryContact: {
    id: 'partnermanagement.account.primaryContact',
    defaultMessage: 'Primary Contact',
  },
  publisherName: {
    id: 'partnermanagement.account.publisherName',
    defaultMessage: 'Publisher name',
  },
  accountNumber: {
    id: 'partnermanagement.account.accountNumber',
    defaultMessage: 'Account Number',
  },
  accountContentDeliverySetting: {
    id: 'partnermanagement.account.accountContentDeliverySetting',
    defaultMessage: 'Account content delivery setting',
  },
  accountContentDeliverySettingInfo: {
    id: 'partnermanagement.account.accountContentDeliverySettingInfo',
    defaultMessage: 'These content delivery settings will be applied to all ad types for this account.',
  },
  adCountryPolicy: {
    id: 'partnermanagement.account.adCountryPolicy',
    defaultMessage: 'Ad country/region policy',
  },
  adLanguage: {
    id: 'partnermanagement.account.adLanguage',
    defaultMessage: 'Ad Language',
  },
  accountContentSettingType: {
    id: 'partnermanagement.account.accountContentSettingType',
    defaultMessage: 'Setting type',
  },
  accountContentSettingTypeSimple: {
    id: 'partnermanagement.account.accountContentSettingTypeSimple',
    defaultMessage: 'Simple settings',
  },
  accountContentSettingTypeAdvanced: {
    id: 'partnermanagement.account.accountContentSettingTypeAdvanced',
    defaultMessage: 'Advanced settings',
  },
  allowedMarketsTitle: {
    id: 'partnermanagement.account.allowedMarketsTitle',
    defaultMessage: 'Allowed markets',
  },
  allowedMarketsOptionAll: {
    id: 'partnermanagement.account.allowedMarketsOptionAll',
    defaultMessage: 'Managed at account level',
  },
  skuTitle: {
    id: 'partnermanagement.account.skuTitle',
    defaultMessage: 'SKUs',
  },
  createSku: {
    id: 'partnermanagement.account.createSku',
    defaultMessage: 'Create SKU Mapping',
  },
  editSku: {
    id: 'partnermanagement.account.editSku',
    defaultMessage: 'Edit SKU Mapping',
  },
  skuCategory: {
    id: 'partnermanagement.account.skuCategory',
    defaultMessage: 'SKU category',
  },
  skuCode: {
    id: 'partnermanagement.account.skuCode',
    defaultMessage: 'SKU code',
  },
  advancedAccountTitle: {
    id: 'partnermanagement.account.advancedAccountTitle',
    defaultMessage: 'Advanced account attributes',
  },
  paymentOption: {
    id: 'partnermanagement.account.paymentOption',
    defaultMessage: 'Payment option',
  },
  paymentOptionMonthlyThreshold: {
    id: 'partnermanagement.account.paymentOptionMonthlyThreshold',
    defaultMessage: 'Monthly Threshold',
  },
  paymentOptionNoPay: {
    id: 'partnermanagement.account.paymentOptionNoPay',
    defaultMessage: 'No Pay',
  },
  lifecycleStatusCreated: {
    id: 'partnermanagement.account.lifecycleStatusCreated',
    defaultMessage: 'Created',
  },
  lifecycleStatusSubmitted: {
    id: 'partnermanagement.account.lifecycleStatusSubmitted',
    defaultMessage: 'Submitted',
  },
  lifecycleStatusPending: {
    id: 'partnermanagement.account.lifecycleStatusPending',
    defaultMessage: 'Pending',
  },
  lifecycleStatusFailed: {
    id: 'partnermanagement.account.lifecycleStatusFailed',
    defaultMessage: 'Failed',
  },
  lifecycleStatusPinFailed: {
    id: 'partnermanagement.account.lifecycleStatusPinFailed',
    defaultMessage: 'Pin Failed',
  },
  lifecycleStatusBlacklisted: {
    id: 'partnermanagement.account.lifecycleStatusBlacklisted',
    defaultMessage: 'Blacklisted',
  },
  lifecycleStatusSuccess: {
    id: 'partnermanagement.account.lifecycleStatusSuccess',
    defaultMessage: 'Success',
  },
  lifecycleStatusValidated: {
    id: 'partnermanagement.account.lifecycleStatusValidated',
    defaultMessage: 'Validated',
  },

  financialStatusLabel: {
    id: 'partnermanagement.account.financialStatusLabel',
    defaultMessage: 'Financial status',
  },
  financialStatusNoHold: {
    id: 'partnermanagement.account.financialStatusNoHold',
    defaultMessage: 'No Hold',
  },
  financialStatusHold: {
    id: 'partnermanagement.account.financialStatusHold',
    defaultMessage: 'CSO Hold',
  },
  financialStatusTaxOnHold: {
    id: 'partnermanagement.account.financialStatusTaxOnHold',
    defaultMessage: 'Tax On Hold',
  },
  financialStatusUserHold: {
    id: 'partnermanagement.account.financialStatusUserHold',
    defaultMessage: 'User Hold',
  },
  lifecycleStatusLabel: {
    id: 'partnermanagement.account.lifecycleStatusLabel',
    defaultMessage: 'Payment Lifecycle status',
  },
  revenueSharePercentageLabel: {
    id: 'partnermanagement.account.revenueSharePercentageLabel',
    defaultMessage: 'Revenue share percentage',
  },
  operationCostRationLabel: {
    id: 'partnermanagement.account.operationCostRationLabel',
    defaultMessage: 'Operation cost ratio',
  },
  accountLevelattributesLabel: {
    id: 'partnermanagement.account.accountLevelattributesLabel',
    defaultMessage: 'Account level attributes',
  },
  accountLevelattributesDesc: {
    id: 'partnermanagement.account.accountLevelattributesDesc',
    defaultMessage: 'Select account level attributes',
  },
  enableAdunitDebugging: {
    id: 'partnermanagement.account.enableAdunitDebugging',
    defaultMessage: 'Enable ad unit debugging',
  },
  enablePassedURLs: {
    id: 'partnermanagement.account.enablePassedURLs',
    defaultMessage: 'Enable passed URLs',
  },
  enablePassedKeywords: {
    id: 'partnermanagement.account.enablePassedKeywords',
    defaultMessage: 'Enable passed keywords',
  },
  donotCrawl: {
    id: 'partnermanagement.account.donotCrawl',
    defaultMessage: 'Do not crawl',
  },
  enableGlobalDefaultKeywords: {
    id: 'partnermanagement.account.enableGlobalDefaultKeywords',
    defaultMessage: 'Enable global default keywords',
  },
  enableAdultAds: {
    id: 'partnermanagement.account.enableAdultAds',
    defaultMessage: 'Enable adult ads',
  },
  enableRAIS: {
    id: 'partnermanagement.account.enableRAIS',
    defaultMessage: 'Enable RAIS for search ad units',
  },
  phoneExtension: {
    id: 'partnermanagement.account.phoneExtension',
    defaultMessage: 'Phone extensions',
  },
  enableSiteLink: {
    id: 'partnermanagement.account.enableSiteLink ',
    defaultMessage: 'Enable SiteLink extensions',
  },
  localExtension: {
    id: 'partnermanagement.account.localExtension',
    defaultMessage: 'Local extensions',
  },
  locationExtension: {
    id: 'partnermanagement.account.locationExtension',
    defaultMessage: 'Location extensions',
  },
  callExtension: {
    id: 'partnermanagement.account.callExtension',
    defaultMessage: 'Call extension',
  },
  meteredCallExtensions: {
    id: 'partnermanagement.account.meteredCallExtensions',
    defaultMessage: 'Metered call extensions',
  },
  meteredRatingExtensions: {
    id: 'partnermanagement.account.meteredRatingExtensions',
    defaultMessage: 'Merchant rating extensions',
  },
  createAccount: {
    id: 'partnermanagement.account.createAccount',
    defaultMessage: 'Create Account',
  },
  storeIdLabel: {
    id: 'partnermanagement.account.storeIdLabel',
    defaultMessage: 'Store IDs',
  },
  storeIdExampleText: {
    id: 'partnermanagement.account.storeIdExample',
    defaultMessage: 'Example Store ID 1, enter one per line',
  },
  partnerCodeLabel: {
    id: 'partnermanagement.account.partnerCodeLabel',
    defaultMessage: 'Partner Codes for Distribution Reporting',
  },
  partnerCodeText: {
    id: 'partnermanagement.account.partnerCodeText',
    defaultMessage: 'Example Partner Code ABC123, enter one per line',
  },
  formCodeLabel: {
    id: 'partnermanagement.account.formCodeLabel',
    defaultMessage: 'Form Codes for Distribution Reporting',
  },
  formCodeText: {
    id: 'partnermanagement.account.FormCodeText',
    defaultMessage: 'Example Form Code ABC123, enter one per line',
  },
  startDistributionPlacementCodeLabel: {
    id: 'partnermanagement.account.startDistributionPlacementCodeLabel',
    defaultMessage: 'Placement Codes for Start Distribution Reporting',
  },
  startDistributionPlacementCodeText: {
    id: 'partnermanagement.account.startDistributionPlacementCodeText',
    defaultMessage: 'Example Placement Code ABC123, enter one per line',
  },
  startDistributionOCIDL2Label: {
    id: 'partnermanagement.account.startDistributionOCIDL2Label',
    defaultMessage: 'OCIDL2 for Start Distribution Content Reporting',
  },
  startDistributionOCIDL2Text: {
    id: 'partnermanagement.account.startDistributionOCIDL2Text',
    defaultMessage: 'Example OCIDL2 ThirdParty_ABC123, enter one per line',
  },
  startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageText: {
    id: 'partnermanagement.account.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageText',
    defaultMessage: 'Start Distribution Native Ads in Feeds & Headlines Revenue Share Percentage',
  },
  privateMarketplace: {
    id: 'partnermanagement.account.privateMarketplace',
    defaultMessage: 'Enable Private Marketplace',
  },
  inHousePromotions: {
    id: 'partnermanagement.account.inHousePromotions',
    defaultMessage: 'Sponsored Product Ads Stores',
  },
  boost: {
    id: 'partnermanagement.account.boost',
    defaultMessage: 'Boost Advertiser Accounts',
  },
  boostIdsLabel: {
    id: 'partnermanagement.account.boostIdsLabel',
    defaultMessage: 'Advertiser Account IDs',
  },
  boostIdsExampleText: {
    id: 'partnermanagement.account.boostIdsExampleText',
    defaultMessage: 'Example Account ID 1, enter one per line',
  },
  privateMarketplaceIdsLabel: {
    id: 'partnermanagement.account.privateMarketplaceIdsLabel',
    defaultMessage: 'Private Marketplace IDs',
  },
  privateMarketplaceExampleText: {
    id: 'partnermanagement.account.privateMarketplaceIdsExampleText',
    defaultMessage: 'Example ID 1, enter one per line',
  },
});
