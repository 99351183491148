import { mutator } from 'satcheljs';
import { INotificationCardItem } from '../../components/NotificationCard';
import { SESSION_NOTIFICATIONS_UI } from '../../constants/AppConstants';
import { onNotificationCardAdded, onNotificationCardRemoved, onNotificationPanelClosed, onNotificationPanelItemsChanged } from '../actions';
import { getAppStore } from '../store/store';

mutator(onNotificationCardAdded, (msg) => {
  const store = getAppStore();
  const panelNotifications = store.panelNotifications || [];
  const newNotificationCard: INotificationCardItem = {
    id: ((crypto as unknown) as { randomUUID: () => string }).randomUUID(),
    isUIGenerated: true,
    timestamp: new Date(),
    ...msg.notification,
  };
  store.panelNotifications = [...panelNotifications, newNotificationCard];

  if (newNotificationCard.isUIGenerated) {
    // persist in session storage as well
    const existingNotifications = sessionStorage.getItem(SESSION_NOTIFICATIONS_UI);

    let notifications: INotificationCardItem[] = [];
    if (existingNotifications) {
      notifications = JSON.parse(existingNotifications);
    }

    // Add a new item to the list, if it doesn't already exist
    if (notifications.length === 0 || !notifications.some((notification) => notification.id === newNotificationCard.id)) {
      notifications.push(newNotificationCard);

      // Store the updated list back to sessionStorage
      const updatedData = JSON.stringify(notifications);
      sessionStorage.setItem(SESSION_NOTIFICATIONS_UI, updatedData);
    }
  }
});

mutator(onNotificationCardRemoved, (msg) => {
  const store = getAppStore();
  const panelNotifications = store.panelNotifications || [];
  const selectedNotificationId = msg.id;
  const indexOfNotification = panelNotifications.findIndex((notification) => notification.id === selectedNotificationId);
  store.panelNotifications = [...panelNotifications.slice(0, indexOfNotification), ...panelNotifications.slice(indexOfNotification + 1)];

  const existingNotifications = sessionStorage.getItem(SESSION_NOTIFICATIONS_UI);

  let notifications: INotificationCardItem[] = [];
  if (existingNotifications) {
    notifications = JSON.parse(existingNotifications);
  }

  const indexOfSSNotification = notifications.findIndex((notification) => notification.id === selectedNotificationId);
  notifications = [...notifications.slice(0, indexOfSSNotification), ...notifications.slice(indexOfSSNotification + 1)];

  // Store the updated list back to sessionStorage
  const updatedData = JSON.stringify(notifications);
  sessionStorage.setItem(SESSION_NOTIFICATIONS_UI, updatedData);
});

mutator(onNotificationPanelItemsChanged, (msg) => {
  const store = getAppStore();
  store.panelNotifications = msg.value;
});

mutator(onNotificationPanelClosed, (msg) => {
  getAppStore().notificationPanelCollapsed = msg.isClosed;
});
