import { uiConfig } from '../../config/ui.config';
import { IColumnMemberDetails, IMetadataElement } from '../../pages/report/DashboardCards/@data/store/schema';
import { MediationReportingDataRequestBody } from '../store/schema/models/MediationReportingDataRequestBody';
import { ReportColumnMembersParams } from '../store/schema/models/ReportColumnMembersParams';
import { MediationReportingDataResponse } from '../store/schema/models/ReportingDataResponse';
import { handleApiErrors, handleError } from '../utils/handleApiErrors';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';
import { ApiError } from './ApiService';

export async function getMediationReportData(
  requestBody: MediationReportingDataRequestBody,
  abortController: AbortController
): Promise<{ data: MediationReportingDataResponse; aborted: boolean }> {
  const baseUrl = `${uiConfig.getMediationReportingApiBaseAddress()}`;
  const redirectUrl = 'mediation/adSense/report/data';
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
      signal: abortController.signal,
    });
    if (!response.ok) {
      return handleApiErrors<{ data: MediationReportingDataResponse; aborted: boolean }>(response, url);
    }

    const responseJson = await response.json();
    if (responseJson) {
      return { data: responseJson, aborted: false };
    }
    return Promise.resolve({ aborted: false } as { data: MediationReportingDataResponse; aborted: boolean });
  } catch (err) {
    if (err.name === 'AbortError') {
      return Promise.resolve({ aborted: true } as { data: MediationReportingDataResponse; aborted: boolean });
    }
    const error = `getReportData operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getDimensions(reportType: string): Promise<IMetadataElement[]> {
  const baseUrl = `${uiConfig.getMediationReportingApiBaseAddress()}`;
  const redirectUrl = `mediation/adSense/report/metadata/dimensions`;
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IMetadataElement[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson.data;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getDimensions operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getMetrics(reportType: string): Promise<IMetadataElement[]> {
  const baseUrl = `${uiConfig.getMediationReportingApiBaseAddress()}`;
  const redirectUrl = `mediation/adSense/report/metadata/metrics`;
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IMetadataElement[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson.data;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getMetrics operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getGranularity(reportType: string): Promise<IMetadataElement[]> {
  const baseUrl = `${uiConfig.getMediationReportingApiBaseAddress()}`;
  const redirectUrl = `mediation/adSense/report/metadata/grains`;
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IMetadataElement[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson.data;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getGranularity operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getColumnMembers(requestParams: ReportColumnMembersParams): Promise<IColumnMemberDetails[]> {
  const baseUrl = `${uiConfig.getMediationReportingApiBaseAddress()}`;
  const redirectUrl = `mediation/adSense/report/metadata/columnMembers?${new URLSearchParams(requestParams)}`;
  const url = baseUrl + encodeURIComponent(redirectUrl);

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IColumnMemberDetails[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson.data;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getColumnMembers operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
