import React from 'react';
import { AdUnit, isTythonSelfServeUser } from '../../../../@data';
import { GridV2 } from '../../../../layout/GridPageV2';
import { injectRouterAndIntlWithObserver } from '../../../../utils';
import { getAdunitStore } from '../../Adunit/@data';
import { getGridPropertyStore } from '../../Property/Manage/__data__/store/store';
import { AppAdsManagePageType } from '../Manage/types';
import AppAdUnitCodePanel, { useAppAdUnitCodePanel } from './hooks/useAppAdUnitCodePanel';
import useAppAdsGrid from './hooks/useAppAdsGrid';
import messages from './messages';

const AppAdsManagePage: AppAdsManagePageType = ({ intl: { formatMessage }, match, history, location }) => {
  const { adUnitsRaw } = getAdunitStore();
  const adUnitPanelProps = useAppAdUnitCodePanel();
  const { dataGridProps, commandBarItems } = useAppAdsGrid({ formatMessage, history, onPanelTrigger: adUnitPanelProps.onPanelTrigger });
  const adUnitsGridReady = isTythonSelfServeUser()
    ? !adUnitsRaw.isLoading && !getGridPropertyStore().isFetchingPropertyStatus
    : !adUnitsRaw.isLoading;

  return (
    <div>
      <AppAdUnitCodePanel {...adUnitPanelProps} />
      <GridV2
        history={history}
        location={location}
        match={match}
        entity={AdUnit}
        pageTitle={{ title: formatMessage(messages.appAdunitManagePageGridTitle), helpId: 'pop_PCv4_AdUnits' }}
        isGridReady={adUnitsGridReady}
        dataGridProps={dataGridProps}
        commandBarProps={commandBarItems}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
    </div>
  );
};

export default injectRouterAndIntlWithObserver(AppAdsManagePage);
