import { defineMessages } from 'react-intl';

export default defineMessages({
  publisherPlaceholder: {
    id: 'picker.publisherPlaceholder',
    defaultMessage: 'Search for publisher name or ID'
  },
  publisherNotFound: {
    id: 'picker.publisherNotFound',
    defaultMessage: 'No publishers found'
  },
  accountPlaceholder: {
    id: 'picker.accountPlaceholder',
    defaultMessage: 'Select publisher first and then account'
  },
  accountNotFound: {
    id: 'picker.accountNotFound',
    defaultMessage: 'No accounts found'
  },
  allAccountsOption: {
    id: 'allAccountsOption',
    defaultMessage: 'All accounts',
  },
  listPickerRemoveAriaLabel: {
    id: 'listPickerRemoveAriaLabel',
    defaultMessage: 'Clear selection'
  },
  publisherAccountPickerAriaLabel: {
    id: 'publisherAccountPickerAriaLabel',
    defaultMessage: 'Publisher account picker'
  }
});
