import { action, mutatorAction } from 'satcheljs';
import { getLoginPageStore } from '..';
import { IStringDictionary } from '../../../../@data';

export const onEmailChanged = mutatorAction('onEmailChanged', (email: string) => {
  getLoginPageStore().active.email = email;
});

export const onErrorsChanged = mutatorAction('onErrorsChanged', (errors?: IStringDictionary) => {
  getLoginPageStore().errors = errors || {};
});

export const onLoginButtonClicked = action('onLoginButtonClicked');
