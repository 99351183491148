import { defineMessages } from 'react-intl';

export default defineMessages({
  reportTitle: {
    id: 'report.reportTitle',
    defaultMessage: 'Reports',
  },
  reportDescriptionForPublisherSelected: {
    id: 'report.reportDescriptionForPublisherSelected',
    defaultMessage:
      // tslint:disable-next-line:max-line-length
      'Reports aren’t available in the latest version of pubCenter yet. To access reports through the previous version, click Go to reports.',
  },
  reportDescriptionForPublisherNotSelected: {
    id: 'report.reportDescriptionForPublisherNotSelected',
    defaultMessage:
      // tslint:disable-next-line:max-line-length
      'Reports aren’t available in the latest version of pubCenter yet.  To access reports through the previous version, please select a publisher first.',
  },
  reportDescriptionForReportsNotApplicable: {
    id: 'report.reportDescriptionForReportsNotApplicable',
    defaultMessage: 'Reports aren’t enabled for this publisher.',
  },
  reportLink: {
    id: 'report.reportLink',
    defaultMessage: 'Go to reports',
  },
  metricsDropdownLabel: {
    id: 'report.metricsDropdownLabel',
    defaultMessage: 'Metrics Dropdown',
  },
  shareScheduleDropdownLabel: {
    id: 'report.shareScheduleDropdownLabel',
    defaultMessage: 'Schedule',
  },
  viewReportLink: {
    id: 'report.viewReportLink',
    defaultMessage: 'View report',
  },
  noData: {
    id: 'report.noData',
    defaultMessage: 'No data matches filters',
  },
  breakDownBy: {
    id: 'report.breakDownBy',
    defaultMessage: 'Break down by',
  },
  toggleChartIcon: {
    id: 'report.toggleChartIcon',
    defaultMessage: 'Show/Hide chart',
  },
  chartSelectIcon: {
    id: 'report.chartSelectIcon',
    defaultMessage: '{chartType} chart',
  },
  metricPickerEditButtonTitle: {
    id: 'report.metricPickerEditButtonTitle',
    defaultMessage: 'Add or remove metrics',
  },
  exportCSV: {
    id: 'report.exportCSV',
    defaultMessage: 'Export as CSV',
  },
  exportPNG: {
    id: 'report.exportPNG',
    defaultMessage: 'Export as PNG',
  },
  chartIncompatibleFieldsWithFilterWillNotBeShown: {
    id: 'report.chartIncompatibleFieldsWithFilterWillNotBeShown',
    defaultMessage: '{metrics} values will not be shown when an {dimensions} filter is applied.',
  },
  chartIncompatibleFieldsWithFilterCantBeShown: {
    id: 'report.chartIncompatibleFieldsWithFilterCantBeShown',
    defaultMessage: '{metrics} values can not be shown when an {dimensions} filter is applied.',
  },
  gridIncompatibleFieldsWhenAddingColumnWithFilter: {
    id: 'report.gridIncompatibleFieldsWhenAddingColumnWithFilter',
    defaultMessage: '{metrics} will not show values because an {dimensions} filter is applied.',
  },
  gridIncompatibleFieldsWhenAddingColumnWithBreakdown: {
    id: 'report.gridIncompatibleFieldsWhenAddingColumnWithBreakdown',
    defaultMessage: '{metrics} will not show values because performance is broken down by {dimensions}.',
  },
  gridIncompatibleFieldsWhenAddingColumnWithFilterAndBreakdown: {
    id: 'report.gridIncompatibleFieldsWhenAddingColumnWithFilterAndBreakdown',
    defaultMessage: '{metrics} will not show values because performance is filtered and broken down by {dimensions}.',
  },
  gridIncompatibleFieldsWithFilter: {
    id: 'report.gridIncompatibleFieldsWithFilter',
    defaultMessage: '{metrics} values can not be shown because an {dimensions} filter is applied.',
  },
  gridIncompatibleFieldsWithBreakdown: {
    id: 'report.gridIncompatibleFieldsWithBreakdown',
    defaultMessage: '{metrics} values can not be shown because performance is broken down by {dimensions}.',
  },
  gridIncompatibleFieldsWithFilterAndBreakdown: {
    id: 'report.gridIncompatibleFieldsWithFilterAndBreakdown',
    defaultMessage:
      '{metrics} values can not be shown because an {dimensions} filter is applied and performance is broken down by {dimensions}.',
  },
  gridIncompatibleFieldsWhenBreakingDown: {
    id: 'report.gridIncompatibleFieldsWhenBreakingDown',
    defaultMessage: '{metrics} values will not be shown if broken down by {dimensions}.',
  },
  topBarExportPNG: {
    id: 'topBar.exportPNG',
    defaultMessage: 'Generating and exporting PNG',
  },
  dimensionColumnMembersFailedMessage: {
    id: 'report.dimensionColumnMembersFailedMessage',
    defaultMessage: "We're unable to load your publisher details at this time. Please try again later.",
  },
  loadingReportSummaryFailedMessage: {
    id: 'report.loadingReportSummaryFailedMessage',
    defaultMessage: "We're unable to load your summary.",
  },
  loadingReportFailedMessage: {
    id: 'report.loadingReportFailedMessage',
    defaultMessage: "We're unable to load your {reportType} report.",
  },
  loadingDetailedReportFailedMessage: {
    id: 'report.loadingDetailedReportFailedMessage',
    defaultMessage: "We're unable to load your detailed {reportType} report.",
  },
  loadingMediationReportFailedMessage: {
    id: 'report.loadingMediationReportFailedMessage',
    defaultMessage: "We're unable to load your mediation report.",
  },
  loadingReportMetadataFailedMessage: {
    id: 'report.loadingReportMetadataFailedMessage',
    defaultMessage: "We're unable to load your metadata for reports.",
  },
  loadingPublisherDetailsFailedMessage: {
    id: 'report.loadingPublisherDetailsFailedMessage',
    defaultMessage:
      'Your publisher details are incomplete. Please make sure that your publisher details are updated so that your site and ad units are reported.',
  },
  failedToExportReportAsPNG: {
    id: 'report.failedToExportReportAsPNG',
    defaultMessage: 'Failed to export report as PNG',
  },
  reportInvalidState: {
    id: 'report.reportInvalidState',
    defaultMessage: 'You have arrived in an invalid state, please report.',
  },
  failedToFetchPublisherReportDetails: {
    id: 'report.failedToFetchPublisherReportDetails',
    defaultMessage: 'Failed to fetch publisher details to determine if reporting is applicable',
  },
  reportsNotAvailableAtThisTime: {
    id: 'report.reportsNotAvailableAtThisTime',
    defaultMessage: 'Reports are not available at this time. Please contact support to resolve this issue.',
  },
});
