import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { buildRequestHeadersWithAuthToken, getAppStore, showErrorToastNotification } from '../../../../../@data';
import { uiConfig } from '../../../../../config/ui.config';
import messages from '../../ProfilePage.messages';
import { submitEvidenceDocument } from '../actions';
import { DocumentUploadStatus } from '../store/schema';

orchestrator(submitEvidenceDocument, async ({ setDocumentUploadStatus, documentContent }) => {
  const { publisher, userContext } = getAppStore();

  if (userContext && publisher && documentContent) {
    const url = `${uiConfig.getApiBaseAddress()}/entityEvaluation/publishers/${publisher.id}/appeals/submitEvidence`;
    const headers = await buildRequestHeadersWithAuthToken(url, userContext, false);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: documentContent,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      Log.info(`Evidence submitted successfully for: ${documentContent?.get('EvidenceDocumentType')}`);

      setDocumentUploadStatus(DocumentUploadStatus.Uploaded);
    } catch (error) {
      showErrorToastNotification({ textMessageDescriptor: messages.failedToSubmitEvidence });
      Log.error(`Failed to submit evidence: ${error.message}`);
    }
  }
});
