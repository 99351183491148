import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../../theme';
import { ICommonStyleProps } from '../../../../../types';

type StyleProperties = 'container' | 'headerSection';
type SetupCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SetupCardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    container: {
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      boxShadow: `0px 0px ${spacing.size7} 0px rgba(0,0,0,0.2)`,
    },
    headerSection: {
      margin: `${spacing.size15} 0px`,
      fontSize: spacing.size20,
      fontWeight: '600',
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
