import { Environment } from "./Environment";
import { ILogManager } from "./ILogManager";
import { LoggingUtils } from "./LoggingUtils";
import { LogType } from "./LogType";


export class ConsoleLoggerManager implements ILogManager {
  public log(logType: LogType, logMessage: string | number | object): void {
    this._consoleLog(logType, logMessage);
  }

  private _getContextMessage(logType: LogType, logMessage: string | number | object): String {
    const contextMsg = logType + ': ' + JSON.stringify(logMessage);
    return contextMsg;
  }

  private _consoleLog(logType: LogType, logMessage: string | number | object) {
    const contextMsg = this._getContextMessage(logType, logMessage);
    const environment = LoggingUtils.getEnvironment();

    switch(logType) {
      case LogType.DEBUG: {
        if (environment === Environment.TEST || environment === Environment.SI) {
          console.log(contextMsg);
        }
        break;
      }

      case LogType.INFO: {
        if (environment === Environment.TEST || environment === Environment.SI) {
          // tslint:disable-next-line: no-console
          console.info(contextMsg);
        }
        break;
      }

      case LogType.WARN: {
        if (environment === Environment.TEST || environment === Environment.SI) {
          // tslint:disable-next-line: no-console
          console.warn(contextMsg);
        }
        break;
      }

      case LogType.ERROR: {
        console.error(contextMsg);
        break;
      }

      default: {
        console.log(contextMsg);
        break;
      }
    }
  }
}