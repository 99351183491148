import { ITheme, classNamesFunction, getTheme } from '@fluentui/react';
import { BreakPoints, IPubcenterPalette, IPubcenterSpacing } from '../../../theme';
import { ISupportPageStyles } from './types';

const getStyles = ({ theme }) => {
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { ResponsiveSmall, ResponsiveMedium } = BreakPoints;
  const imageWidth = spacing.size192;
  return {
    footerWrapper: {
      height: '100%',
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: `calc(100% - ${spacing.size48})`,
      overflowX: 'scroll',
    },
    pageTitle: {
      fontSize: spacing.size20,
      fontWeight: '600',
      lineHeight: spacing.size28,
    },
    formWrapper: {
      marginTop: spacing.size24,
      '.ms-TextField-fieldGroup': {
        borderRadius: spacing.size2,
        borderColor: palette.neutralTertiaryAlt,
      },
      [ResponsiveSmall]: {
        width: '100%',
      },
    },
    emailIcon: {
      marginLeft: `calc((100% - ${imageWidth})/2)`,
      width: imageWidth,
    },
    submitButton: {
      margin: `${spacing.size12} 0`,
      float: 'right',
    },
    errorBannerWrapper: {
      marginBottom: 0,
    },
    formSuccessHead: {
      marginTop: spacing.size24,
    },
    descriptionWordCountWrapper: {
      display: 'block',
      textAlign: 'right',
      fontSize: spacing.size10,
      fontColor: palette.neutralTertiaryAlt,
    },
    formInputStyles: {
      width: 440,
      [ResponsiveMedium]: { width: 324 },
      [ResponsiveSmall]: { width: 280 },
    },
    transparentText: {
      color: 'transparent',
      display: 'block',
      userSelect: 'none'
    }
  };
};

const getClassNames = classNamesFunction<{ theme: ITheme }, ISupportPageStyles>();
export const classNames = getClassNames(getStyles, { theme: getTheme() });
