import { AuditLog, AuditType } from '../../../../@data';
import ActionDetailsMessages from '../ActionDetails.messages';
import { getEntityTypeDisplayMap } from './convertAttributeValues';
import { getActionDetailsForAdunit } from './getAdUnitActionDetails';
import { getActionDetailsForPayment } from './getPaymentActionDetails';
import { getActionDetailsForProperty } from './getPropertyActionDetails';
import { getActionDetailsForPublisher } from './getPublisherActionDetails';
import { getActionDetailsForUser } from './getUserActionDetails';

export class ActionDetail {
  public title: string;
  public changes: string[];
}

export function getActionDetails(auditLog: AuditLog, formatMessage): ActionDetail {
  const actionDetail = new ActionDetail();

  actionDetail.title = `${auditLog.entityName ?? ''} ${auditLog.entityId ?? ''} `;

  switch (auditLog.auditType) {
    case AuditType.CREATE:
      actionDetail.title = `${formatMessage(ActionDetailsMessages.created)} ${
        auditLog.entityName ? auditLog.entityName : getEntityTypeDisplayMap(auditLog.entityType, formatMessage)
      }`;
      break;
    case AuditType.DELETE:
      actionDetail.title = `${formatMessage(ActionDetailsMessages.deleted)} ${auditLog.entityName}`;
      break;
    case AuditType.EXPORT:
      actionDetail.title = `${formatMessage(ActionDetailsMessages.exported)} ${getEntityTypeDisplayMap(
        auditLog.entityType,
        formatMessage
      )} ${formatMessage(ActionDetailsMessages.details)}`;
      break;
    case AuditType.DOWNLOAD:
      actionDetail.title = `${formatMessage(ActionDetailsMessages.downloaded)} ${getEntityTypeDisplayMap(
        auditLog.entityType,
        formatMessage
      )} ${formatMessage(ActionDetailsMessages.details)}`;
      break;
    case AuditType.INVITE: {
      actionDetail.title = `${auditLog.userEmail} invited `;
      const invitedUserEmail = auditLog.changes?.find((change) => change.attributeChanged === 'Email')?.newValue;
      const invitedUserRole = auditLog.changes?.find((change) => change.attributeChanged === 'UserRole')?.newValue;
      if (invitedUserEmail) {
        actionDetail.title += ` ${invitedUserEmail}${invitedUserRole ? ` as ${invitedUserRole}` : ''}}`;
      } else if (invitedUserRole) {
        actionDetail.title += `${invitedUserRole}`;
      } else {
        actionDetail.title += 'new user';
      }
      break;
    }
    case AuditType.INVITE_ACCEPT:
      actionDetail.title = `${auditLog.userEmail} accepted the invitation}`;
      break;
    case AuditType.UPDATE:
      updateActionDetailsForUpdate(actionDetail, auditLog, formatMessage);
      break;

    default:
      actionDetail.title += formatMessage(ActionDetailsMessages.updated);
      break;
  }

  return actionDetail;
}

function updateActionDetailsForUpdate(actionDetail: ActionDetail, auditLog: AuditLog, formatMessage) {
  switch (auditLog.entityType) {
    case 'AdUnit':
      getActionDetailsForAdunit(actionDetail, auditLog, formatMessage);
      break;
    case 'Property':
      getActionDetailsForProperty(actionDetail, auditLog, formatMessage);
      break;
    case 'User':
      getActionDetailsForUser(actionDetail, auditLog, formatMessage);
      break;
    case 'Payment':
      getActionDetailsForPayment(actionDetail, auditLog, formatMessage);
      break;
    case 'Publisher':
      getActionDetailsForPublisher(actionDetail, auditLog, formatMessage);
      break;
    default:
      actionDetail.title = `${auditLog.entityType} ${formatMessage(ActionDetailsMessages.updated)}`;
      break;
  }
}
