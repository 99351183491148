import { Stack } from '@fluentui/react';
import React from 'react';
import { getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged } from '../../../../@data';
import { FormTitle } from '../../../../components/Form';
import { injectIntlAndObserver } from '../../../../utils';
import '../@data/orchestrators';
import { Balance } from './Balance/Balance';
import { EstimatedEarnings } from './EstimatedEarnings/EstimatedEarnings';
import { ChartGrain, EstimatedEarningsChartTypes } from './EstimatedEarnings/EstimatedEarnings.types';
import { PaymentsOnboarding } from './Onboarding/PaymentsOnboarding';
import messages from './PaymentsPage.messages';
import { chartsContainersStyles, getClassNames, getStackTokens } from './PaymentsPage.styles';

export const PaymentsPage = injectIntlAndObserver((props) => {
  const classNames = getClassNames(props);
  const { intl } = props;
  const { formatMessage } = intl;
  const { helpInfo, locale } = getAppStore();

  return (
    <div className="paymentsPageRoot">
      <FormTitle
        intl={intl}
        title={formatMessage(messages.pageTitle)}
        styles={{ root: classNames.pageTitleStyle }}
        id="pop_PCv4_Payments"
        calloutContent={helpInfo}
        iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_Payments', locale)}
        calloutOnDismiss={() => onHelpInfoChanged()}
      />

      <Stack styles={chartsContainersStyles} horizontal horizontalAlign="space-between" wrap tokens={getStackTokens()}>
        <Stack.Item className={classNames.chartCard} grow>
          <Balance />
        </Stack.Item>
        <Stack.Item className={classNames.chartCard} grow>
          <EstimatedEarnings defaultChartType={EstimatedEarningsChartTypes.LINE} defaultChartGrain={ChartGrain.MONTHLY} />
        </Stack.Item>
      </Stack>
      <Stack horizontal horizontalAlign="space-between" wrap tokens={getStackTokens()}>
        <Stack.Item className={classNames.chartCard} grow>
          <PaymentsOnboarding />
        </Stack.Item>
      </Stack>
    </div>
  );
});
