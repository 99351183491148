import { useEffect } from 'react';
import { Model } from '../../../@data';
import { onNumberOfPagesChanged, onPageNumberChanged } from '../@data/mutatorActions';
import { getStore } from '../@data/store/store';
import { IPaginationProps, ISelectableRow } from '../GridV2.types';

export const usePagination = (items: ISelectableRow[], paginationProps?: IPaginationProps) => {
  const { paginator } = getStore();

  const paginateData = (pageNumber?: number, pageSize?: number) => {
    const dataLength = items?.length;
    if (dataLength && pageNumber && pageSize) {
      return items?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize) || [];
    } else {
      return items || [];
    }
  };

  const initializePagination = (filteredRowsUsingFilter: Model[]) => {
    if (paginationProps && paginationProps.shouldGridHandlePagination) {
      onPageNumberChanged(1);
      onNumberOfPagesChanged(Math.ceil(filteredRowsUsingFilter.length / paginator.currentPageSize));
    }
  };

  useEffect(() => {
    if (paginationProps === undefined) {
      return;
    }

    if (paginationProps.numberOfPages) {
      onNumberOfPagesChanged(paginationProps.numberOfPages);
    } else if (paginationProps.shouldGridHandlePagination) {
      onNumberOfPagesChanged(Math.ceil(items.length / paginator.currentPageSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProps]);

  return { paginateData, initializePagination };
};
