import { getEntityAccessPermissions } from '../../../../../@data';
import { UserRoleEntity } from '../../../../../@data/services/UserRoleService';
import { UserSettings } from '../../../../../@data/store/schema/userrolemodels/UserSettings';

export function hasAccessToNotifications() {
  const userSettingsPermissions = getEntityAccessPermissions(UserRoleEntity.UserSettings) as UserSettings | null;
  if (!userSettingsPermissions) {
    return true; // completeAccess
  }

  const { billingAlerts, servingStatus, profileReview, invitedUsers } = userSettingsPermissions;
  return billingAlerts || servingStatus || profileReview || invitedUsers;
}
