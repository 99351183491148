import AppMessages from '../../../../App.messages';
import messages from '../AuditHistory.messages';

export function getAdUnitTypes(value?: string) {
  const adUnitTypes: string[] = [];

  if (value) {
    if (value.includes('Display')) {
      adUnitTypes.push('Display');
    }
    if (value.includes('Native')) {
      adUnitTypes.push('Native');
    }
  }
  return adUnitTypes.join(', ');
}

export function getLifeCycleStatusIdValue(value?: string) {
  if (value && value.includes('111')) {
    return 'Active';
  } else if (value && value.includes('112')) {
    return 'Inactive';
  }
  return '';
}

export function getAddedRemovedCount(value?: string) {
  if (!value) {
    return [0, 0];
  }
  let addCount = 0,
    removeCount = 0;

  let messageParts = value.split(',');
  if (messageParts.length > 1) {
    messageParts = messageParts[0].split(' ').concat(messageParts[1].split(' '));
  } else {
    messageParts = value.split(' ');
  }

  if (value.includes('Added')) {
    addCount = Number(messageParts[1]);
  }
  if (value.includes('Removed') && messageParts.length === 2) {
    removeCount = Number(messageParts[1]);
  } else if (value.includes('Removed') && messageParts.length > 3) {
    removeCount = Number(messageParts[messageParts.length - 1]);
  }

  return [addCount, removeCount];
}

export const getEntityTypeDisplayMap = (entityType: string, formatMessage): string => {
  switch (entityType.toLowerCase()) {
    case 'adunit':
      return formatMessage(messages.adunit);
    case 'property':
      return formatMessage(AppMessages.site);
    case 'user':
      return formatMessage(messages.user);
    case 'publisher':
      return formatMessage(AppMessages.publisher);
    case 'payment':
      return formatMessage(messages.payment);
    case 'report':
      return formatMessage(messages.report);
    default:
      return '';
  }
};
