import { Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { isTythonSelfServeUser } from '../../../../../../@data';
import AppMessages from '../../../../../../App.messages';
import { IHeaderItem, PanelTypes } from '../../../../../../components/Header/Header.types';
import { APP_NAME_NEW, CUSTOM_WIDTH } from '../../../../../../constants/AppConstants';
import { FeedbackPanel, renderFooterContent } from '../../../../../../layout/PageLayout/header-panels';
import messages from '../ResourcesSection.messages';

interface IHeaderData {
  items?: IHeaderItem[];
}

export const getHeaderItems = (CMUIHeaderMenuItemSerializer, formatMessage) => {
  const items: IHeaderItem[] = [
    {
      key: 'feedback',
      icon: 'Feedback',
      title: formatMessage(messages.feedbackTitle, { appName: APP_NAME_NEW }),
      type: PanelTypes.Feedback,
      panel: FeedbackPanel,
      panelProps: {
        isFooterAtBottom: true,
        onRenderFooterContent: renderFooterContent,
      },
      shouldOverflowUnderExtraSmallScreen: true,
    },
  ];

  const itemsData: IHeaderData = {
    items: CMUIHeaderMenuItemSerializer(isTythonSelfServeUser(), items),
  };

  return itemsData;
};

export const createPanel = (item: IHeaderItem, isFeedbackOpen: boolean, formatMessage, setIsFeedbackOpen): JSX.Element => {
  // For icons such as CMUI help icon, panels are integrated with 3rd party libraries no need to render native panel
  if (item && !item.panel) {
    return <></>;
  }

  return (
    <Panel
      headerText={item?.title}
      type={PanelType.custom}
      customWidth={CUSTOM_WIDTH}
      isOpen={isFeedbackOpen}
      isLightDismiss={true}
      onDismiss={() => hidePanel(setIsFeedbackOpen)}
      closeButtonAriaLabel={formatMessage(AppMessages.close)}
      isFooterAtBottom={item?.panelProps?.isFooterAtBottom}
      onRenderFooterContent={() => item?.panelProps?.onRenderFooterContent?.({ onDismiss: () => hidePanel(setIsFeedbackOpen) }) || null}
    >
      {item?.panel ? React.cloneElement(<item.panel />, { hidePanel: () => hidePanel(setIsFeedbackOpen) }) : <p>Loading...</p>}
    </Panel>
  );
};

export const showPanel = (setIsFeedbackOpen) => {
  setIsFeedbackOpen(true);
};

const hidePanel = (setIsFeedbackOpen) => {
  setIsFeedbackOpen(false);
};
