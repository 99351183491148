import { defineMessages } from 'react-intl';

export default defineMessages({
  'reportMetadata.grains.None': { id: 'reportMetadata.grains.None', defaultMessage: 'None' },
  'reportMetadata.grains.Hour': { id: 'reportMetadata.grains.Hour', defaultMessage: 'Hourly' },
  'reportMetadata.grains.Day': { id: 'reportMetadata.grains.Day', defaultMessage: 'Daily' },
  'reportMetadata.grains.Week': { id: 'reportMetadata.grains.Week', defaultMessage: 'Weekly' },
  'reportMetadata.grains.Month': { id: 'reportMetadata.grains.Month', defaultMessage: 'Monthly' },
  'reportMetadata.grains.MonthAndYear': { id: 'reportMetadata.grains.MonthAndYear', defaultMessage: 'Monthly' },
  'reportMetadataColumns.ImpressionsServed': { id: 'reportMetadataColumns.ImpressionsServed', defaultMessage: 'Filled impressions' },
  'reportMetadataColumns.ImpressionsViewed': { id: 'reportMetadataColumns.ImpressionsViewed', defaultMessage: 'Viewable impressions' },
  'reportMetadataColumns.Viewability': { id: 'reportMetadataColumns.Viewability', defaultMessage: 'Viewability rate' },
  'reportMetadataColumns.Revenue': { id: 'reportMetadataColumns.Revenue', defaultMessage: 'Revenue' },
  'reportMetadataColumns.FillRate': { id: 'reportMetadataColumns.FillRate', defaultMessage: 'Fill rate' },
  'reportMetadataColumns.eCPM': { id: 'reportMetadataColumns.eCPM', defaultMessage: 'eCPM' },
  'reportMetadataColumns.Clicks': { id: 'reportMetadataColumns.Clicks', defaultMessage: 'Clicks' },
  'reportMetadataColumns.CTR': { id: 'reportMetadataColumns.CTR', defaultMessage: 'CTR' },
  'reportMetadataColumns.DateTime': { id: 'reportMetadataColumns.DateTime', defaultMessage: 'Date' },
  'reportMetadataColumns.AdUnitName': { id: 'reportMetadataColumns.AdUnitName', defaultMessage: 'Ad unit' },
  'reportMetadataColumns.AdType': { id: 'reportMetadataColumns.AdType', defaultMessage: 'Ad type' },
  'reportMetadataColumns.PropertyUrl': { id: 'reportMetadataColumns.PropertyUrl', defaultMessage: 'Site URL' },
  'reportMetadataColumns.Country': { id: 'reportMetadataColumns.Country', defaultMessage: 'Country' },
  'reportMetadataColumns.CurrencyCode': { id: 'reportMetadataColumns.CurrencyCode', defaultMessage: 'Currency' },
  'reportMetadataColumns.Impressions': {
    id: 'reportMetadataColumns.Impressions',
    defaultMessage: 'Filled Impressions',
  },
  'reportMetadataColumns.AdClicks': { id: 'reportMetadataColumns.AdClicks', defaultMessage: 'Clicks' },
  'reportMetadataColumns.AdCTR': { id: 'reportMetadataColumns.AdCTR', defaultMessage: 'CTR' },
  'reportMetadataColumns.AdECPM': { id: 'reportMetadataColumns.AdECPM', defaultMessage: 'eCPM' },
  'reportMetadataColumns.ViewableImpressions': {
    id: 'reportMetadataColumns.ViewableImpressions',
    defaultMessage: 'Viewable Impressions',
  },
  'reportMetadataColumns.MeasurableImpressions': {
    id: 'reportMetadataColumns.MeasurableImpressions',
    defaultMessage: 'Measurable Impressions',
  },
  'reportMetadataColumns.ImpressionsRate': {
    id: 'reportMetadataColumns.ImpressionsRate',
    defaultMessage: 'Impressions %',
  },
  'reportMetadataColumns.EligibleImpressions': {
    id: 'reportMetadataColumns.EligibleImpressions',
    defaultMessage: 'Eligible Impressions',
  },
  'reportMetadataColumns.MeasurableImpressionsRate': {
    id: 'reportMetadataColumns.MeasurableImpressionsRate',
    defaultMessage: 'Measurable Impressions %',
  },
  'reportMetadataColumns.ResponsesServed': {
    id: 'reportMetadataColumns.ResponsesServed',
    defaultMessage: 'Responses Served',
  },
  'reportMetadataColumns.Date': { id: 'reportMetadataColumns.Date', defaultMessage: 'Date' },
  'reportMetadataColumns.AdUnitId': {
    id: 'reportMetadataColumns.AdUnitId',
    defaultMessage: 'AdUnitId',
  },

  'reportMetadataColumns.OperatingSystem': {
    id: 'reportMetadataColumns.OperatingSystem',
    defaultMessage: 'Operating System',
  },
  'reportMetadataColumns.SiteName': { id: 'reportMetadataColumns.SiteName', defaultMessage: 'Site' },
});
