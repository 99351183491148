import { Model } from './Model';

export class AdSenseMediation extends Model {
  public apiPath = 'preauth/mediation';
  public className = 'preauth/mediation';
  public toggle?: boolean;
  public email?: string;
  public oAuthConnectionStatus?: string;
  public oauthFailureReasons?: OAuthFailureReason[];
  public mediationStatus?: boolean;
  public connectedTimeStamp?: Date;
  public userImageUri?: string;
}

export class AdSenseMediationToggle extends AdSenseMediation {
  public apiPath = 'preauth/mediation';
  public className = 'oauth';
}

export class AdSenseMediationHealthCheck extends AdSenseMediation {
  public apiPath = 'preauth/mediation';
  public className = 'oauth';
}

export class AdSenseMediationChooseGamNetwork extends AdSenseMediation {
  public apiPath = 'preauth/mediation';
  public className = 'oauth';
}

export class AdSenseMediationOptIn extends AdSenseMediation {
  public apiPath = 'preauth/mediation';
}

export class AdSenseMediationOptOut extends AdSenseMediation {
  public apiPath = 'preauth/mediation';
}

export class OAuthFailureReason {
  public errorCode: string;
  public description: string;
  public gamNetworkDetails?: { id: string; displayName: string }[];
}
