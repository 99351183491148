import { orchestrator } from 'satcheljs';
import { getAppStore, onNavigateBack } from '../../../../../../../../@data';
import { FormState } from '../../../../../../../../components/Form';
import { RouteName } from '../../../../../../@data';
import { createReturnUrl } from '../../../../../../@data/services/AdManagementService';
import { onScriptPageFormCleared, onScriptPageSubmitted } from '../actions/onScriptPageActions';

orchestrator(onScriptPageSubmitted, (msg) => {
  const { publisher, account } = getAppStore();

  if (msg.mode !== FormState.InPageView) {
    const returnUrl = createReturnUrl(RouteName.adunit, publisher!.id, account!.id);
    onNavigateBack(msg.routeHistory, returnUrl);
  }

  onScriptPageFormCleared();
  if (msg.onSubmit) {
    msg.onSubmit();
  }
});
