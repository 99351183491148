import messages from '../../SetupCard/SetupCard.messages';
// Since each state in Getting started card has a different title and description, so we will create a dictionary where the key
// is status and value is ['title', 'description', 'button text']
import AdUnitStep1 from '../../SetupCard/resources/AdUnitStep1.svg';
import AdUnitStep2 from '../../SetupCard/resources/AdUnitStep2.svg';
import ProfileStep1 from '../../SetupCard/resources/ProfileStep1.svg';
import ProfileStep2 from '../../SetupCard/resources/ProfileStep2.svg';
import ProfileStep3 from '../../SetupCard/resources/ProfileStep3.svg';
import ProfileStep4 from '../../SetupCard/resources/ProfileStep4.svg';
import SiteStep1 from '../../SetupCard/resources/SiteStep1.svg';
import SiteStep2 from '../../SetupCard/resources/SiteStep2.svg';
import SiteStep3 from '../../SetupCard/resources/SiteStep3.svg';

export const getProfileCardInfo = (formatMessage) => {
  return {
    NotStarted: [
      formatMessage(messages.profileCardTitleNotStarted),
      formatMessage(messages.profileCardDescriptionNotStarted),
      formatMessage(messages.profileCardButtonNotStarted),
      ProfileStep1,
    ],
    AwaitingVerification: [
      formatMessage(messages.profileCardTitleAwaitingForVerification),
      formatMessage(messages.profileCardDescriptionAwaitingForVerification),
      formatMessage(messages.profileCardButtonAwaitingForVerification),
      ProfileStep2,
    ],
    Completed: [
      formatMessage(messages.profileCardTitleCompleted),
      formatMessage(messages.profileCardDescriptionCompleted),
      formatMessage(messages.profileCardButtonCompleted),
      ProfileStep3,
    ],
    Rejected: [
      formatMessage(messages.profileCardTitleNeedsAttention),
      formatMessage(messages.profileCardDescriptionNeedsAttention),
      formatMessage(messages.profileCardButtonNeedsAttention),
      ProfileStep4,
    ],
  };
};

export const getSiteCardInfo = (formatMessage) => {
  return {
    NotStarted: [
      formatMessage(messages.siteCardTitleNotStarted),
      formatMessage(messages.siteCardDescriptionNotStarted),
      formatMessage(messages.siteCardButtonNotStarted),
      SiteStep1,
    ],
    AwaitingVerification: [
      formatMessage(messages.siteCardTitleAwaitingForVerification),
      formatMessage(messages.siteCardDescriptionAwaitingForVerification),
      formatMessage(messages.siteCardButtonAwaitingForVerification),
      SiteStep2,
    ],
    Completed: [
      formatMessage(messages.siteCardTitleCompleted),
      formatMessage(messages.siteCardDescriptionCompleted),
      formatMessage(messages.siteCardButtonCompleted),
      SiteStep3,
    ],
  };
};

export const getAdunitCardInfo = (formatMessage) => {
  return {
    NotStarted: [
      formatMessage(messages.adUnitCardTitleNotStarted),
      formatMessage(messages.adUnitCardDescriptionNotStarted),
      formatMessage(messages.adUnitCardButtonNotStarted),
      AdUnitStep1,
    ],
    Completed: [
      formatMessage(messages.adUnitCardTitleCompleted),
      formatMessage(messages.adUnitCardDescriptionCompleted),
      formatMessage(messages.adUnitCardButtonCompleted),
      AdUnitStep2,
    ],
  };
};

export const getStatusCardsInfo = (formatMessage) => {
  return {
    profileCardInfo: getProfileCardInfo(formatMessage),
    siteCardInfo: getSiteCardInfo(formatMessage),
    adunitCardInfo: getAdunitCardInfo(formatMessage),
  };
};
