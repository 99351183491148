import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import { HomePageStatus } from '../../../@data/store/schema/IHomeViewState';
import messages from '../../SetupCard/SetupCard.messages';
import { ISetupCard, SetupCardType } from '../../SetupCard/SetupCardUtils';

export const getSetupCards = (
  handleProfileCardButtonClick,
  handleCompleteProfileCardButtonClick,
  handleSiteCardButtonClick,
  handleAdUnitCardButtonClick,
  profileCardInfo,
  siteCardInfo,
  adunitCardInfo,
  adUnitStatus: HomePageStatus,
  siteStatus: HomePageStatus,
  profileStatus: HomePageStatus,
  formatMessage
): ISetupCard[] => {
  const gettingStartedCards: ISetupCard[] = [
    {
      title: profileCardInfo[HomePageStatus[profileStatus]][0],
      description: profileCardInfo[HomePageStatus[profileStatus]][1],
      button: (
        <PrimaryButton
          text={profileCardInfo[HomePageStatus[profileStatus]][2]}
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
          onClick={() => (isProfileNotStarted(profileStatus) ? handleCompleteProfileCardButtonClick() : handleProfileCardButtonClick())}
        />
      ),
      image: profileCardInfo[HomePageStatus[profileStatus]][3],
      imageAlt: formatMessage(messages.setupProfile),
      status: profileStatus, // formatMessage(messages[profileStatus])
      steps: 2,
      cardType: SetupCardType.GetStarted,
    },
    {
      title: siteCardInfo[HomePageStatus[siteStatus]][0],
      description: siteCardInfo[HomePageStatus[siteStatus]][1],
      button: (
        <PrimaryButton
          text={siteCardInfo[HomePageStatus[siteStatus]][2]}
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
          onClick={() => handleSiteCardButtonClick()}
        />
      ),
      image: siteCardInfo[HomePageStatus[siteStatus]][3],
      imageAlt: formatMessage(messages.setupSite),
      status: siteStatus, // formatMessage(messages[siteStatus]),
      steps: 2,
      cardType: SetupCardType.GetStarted,
    },
    {
      title: adunitCardInfo[HomePageStatus[adUnitStatus]][0],
      description: adunitCardInfo[HomePageStatus[adUnitStatus]][1],
      button: (
        <PrimaryButton
          text={adunitCardInfo[HomePageStatus[adUnitStatus]][2]}
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
          onClick={() => handleAdUnitCardButtonClick()}
        />
      ),
      image: adunitCardInfo[HomePageStatus[adUnitStatus]][3],
      imageAlt: formatMessage(messages.setupAdunit),
      status: adUnitStatus, // formatMessage(messages[adUnitStatus]),
      steps: 1,
      cardType: SetupCardType.GetStarted,
    },
  ];

  return gettingStartedCards;
};

export const getSetupCardsPlaceholder = (): ISetupCard[] => {
  return [
    {
      title: '',
      description: '',
    },
    {
      title: '',
      description: '',
    },
    {
      title: '',
      description: '',
    },
  ];
};

const isProfileNotStarted = (profileStatus: HomePageStatus): boolean => {
  return HomePageStatus[profileStatus] === HomePageStatus.NotStarted;
};
