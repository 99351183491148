import { createStore } from 'satcheljs';
import { User } from '../../../../../@data';

export interface IUserManagementState {
  roleChangePanelOpen: boolean;
  selectedItem?: User;
  users: User[];
}

export const getUserManagementStore = createStore<IUserManagementState>('UserManagementState', {
  roleChangePanelOpen: false,
  users: [],
});
