import { defineMessages } from 'react-intl';

export default defineMessages({
  createPageTitle: {
    id: 'admanagement.property.createTitle',
    defaultMessage: 'Create property',
  },
  createSitePageTitle: {
    id: 'admanagement.site.createTitle',
    defaultMessage: 'Create site',
  },
  editPageTitle: {
    id: 'admanagement.property.editTitle',
    defaultMessage: 'Update property',
  },
  editSitePageTitle: {
    id: 'admanagement.site.editTitle',
    defaultMessage: 'Update site',
  },
});
