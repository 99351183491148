import { DefaultButton, IButtonProps, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import React from 'react';

export interface IPopupProps {
  popUpTitle?: string;
  popUpDescription?: string;
  children?: JSX.Element;
  customButton?: JSX.Element;
  primaryButtonAriaLabel?: string;
  secondaryButtonAriaLabel?: string;
  isShown: boolean;
  isCloseButtonHidden?: boolean;
  onPopupDismiss: () => void;
  onPrimaryButtonClick?: IButtonProps['onClick'];
  onSecondaryButtonClick?: IButtonProps['onClick'];
  isPrimaryButtonDisabled?: boolean;
}

export const PopupCard = ({
  popUpTitle,
  popUpDescription,
  children,
  customButton,
  primaryButtonAriaLabel,
  secondaryButtonAriaLabel,
  isShown,
  isCloseButtonHidden = false,
  isPrimaryButtonDisabled,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onPopupDismiss,
}: IPopupProps) => {
  return (
    <Dialog
      hidden={isShown === false}
      onDismiss={onPopupDismiss}
      dialogContentProps={{
        type: isCloseButtonHidden === true ? DialogType.normal : DialogType.close,
        title: popUpTitle,
        subText: popUpDescription,
      }}
      modalProps={{
        isBlocking: false,
      }}
      minWidth={500}
    >
      {children}

      <DialogFooter>
        {customButton}
        {primaryButtonAriaLabel && onPrimaryButtonClick && (
          <PrimaryButton
            onClick={onPrimaryButtonClick}
            disabled={isPrimaryButtonDisabled}
            data-test-id={`confirm-popup-${primaryButtonAriaLabel}`}
          >
            {primaryButtonAriaLabel}
          </PrimaryButton>
        )}
        {secondaryButtonAriaLabel && onSecondaryButtonClick && (
          <DefaultButton onClick={onSecondaryButtonClick} data-test-id={`confirm-popup-${secondaryButtonAriaLabel}`}>
            {secondaryButtonAriaLabel}
          </DefaultButton>
        )}
      </DialogFooter>
    </Dialog>
  );
};
