import { IPropertyPickerStyleProps, IPropertyPickerStyles } from './PropertyPicker.types';

export const getStyles = (props: IPropertyPickerStyleProps): IPropertyPickerStyles => {
  const { theme } = props;

  return {
    searchBox: {
      width: 342,
      marginTop: theme.spacing.s1,
    },
    list: {
      flex: 1,
      overflowY: 'auto',
    },
    listItem: {
      padding: theme.spacing.s1,
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.neutralLight,
        },
      },
    },
    listItemLabel: [
      theme.fonts.medium,
      {
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    ],
  };
};
