import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../../../theme';
import { ICommonStyleProps } from '../../../../../../types';

type StyleProperties = 'gridDisplay' | 'flexDisplay' | 'panelSectionContent' | 'panelContent';
type NotificationConfigurationStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): NotificationConfigurationStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;

  const flexDisplay = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'end',
    '& label': {
      wordBreak: 'normal',
    },
  };

  return mergeStyleSets({
    gridDisplay: {
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    flexDisplay: {
      ...flexDisplay,
    },
    panelSectionContent: {
      marginTop: spacing.size4,
    },
    panelContent: {
      marginTop: spacing.size16,
    },
    panelSection: {
      ...flexDisplay,
      marginBottom: spacing.size16,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): NotificationConfigurationStyles {
  return getStyles({ theme: getTheme(), ...props });
}
