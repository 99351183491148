import { defineMessages } from 'react-intl';

export default defineMessages({
  title1: {
    id: 'home.fre.cards.title1',
    defaultMessage: 'Welcome to the new Monetize Now',
  },
  title2: {
    id: 'home.fre.cards.title2',
    defaultMessage: `Bookmark our new site`,
  },
  title3: {
    id: 'home.fre.cards.title3',
    defaultMessage: 'Start earning with beautiful ads',
  },
  description1: {
    id: 'home.fre.cards.description1',
    defaultMessage: `You're seeing our updated look! All the functions you know and love are still here. Enjoy the enhanced experience as you navigate through the familiar features in our new Monetize Now!`,
  },
  description2: {
    id: 'home.fre.cards.description2',
    defaultMessage: `Don't forget to update your bookmarks! Our website has a new URL. Save it now to keep your access quick and easy: {newDomain}.`,
  },
  description3: {
    id: 'home.fre.cards.description3',
    defaultMessage: `Unlock the potential to earn more with Monetize Now. Serve ads that not only drive higher engagement but also blend seamlessly with your site. Get started now and watch your earnings grow!`,
  },
  notNowButton: {
    id: 'home.fre.cards.button',
    defaultMessage: 'Not now',
  },
  dialogAriaLabel: {
    id: 'home.fre.cards.dialogAriaLabel',
    defaultMessage: 'First Run Experience Dialog for users redirected from Pubcenter domain',
  },
});
