import { uiConfig } from '../../config/ui.config';
import { DismissNotificationRequest, Notification, UserContext } from '../store/schema';
import { handleApiErrors, handleError } from '../utils/handleApiErrors';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';
import { ApiError } from './ApiService';

export async function getBackendNotifications(publisherId, accountId, userContext: UserContext): Promise<Notification[]> {
  const url = `${uiConfig.getApiBaseAddress('1.0')}/notifications/publishers/${publisherId}/accounts/${accountId}/users/${
    userContext!.id
  }/panel`;
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      return handleApiErrors<Notification[]>(response, url);
    }

    const responseJson = await response.json();
    if (responseJson.data) {
      return responseJson.data;
    }
    return Promise.resolve([] as Notification[]);
  } catch (err) {
    const error = `getBackendNotifications operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function dismissNotifications(
  publisherId,
  accountId,
  userContext: UserContext,
  body: DismissNotificationRequest
): Promise<string[]> {
  const url = `${uiConfig.getApiBaseAddress('1.0')}/notifications/publishers/${publisherId}/accounts/${accountId}/users/${
    userContext!.id
  }/dismiss`;
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      return handleApiErrors<string[]>(response, url);
    }

    const responseJson = await response.json();
    if (responseJson.data && responseJson.data.notificationIds) {
      return responseJson.data.notificationIds;
    }
    return Promise.resolve([] as string[]);
  } catch (err) {
    const error = `dismiss backend operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
