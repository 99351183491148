import { InjectedIntl } from 'react-intl';
import { domainInitialize, duplicateDomainInitialize, duplicateNameInitialize, nameInitialize } from './DomainStringValidator';
import messages from './validation.messages';

export function validatorFactory(intl: InjectedIntl) {
  domainInitialize(intl.formatMessage(messages.domainStringValidator));
  duplicateDomainInitialize(intl.formatMessage(messages.duplicateDomainStringValidator));
  nameInitialize(intl.formatMessage(messages.nameStringValidator));
  duplicateNameInitialize(intl.formatMessage(messages.duplicateNameStringValidator));
}
