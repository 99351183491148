import { defineMessages } from 'react-intl';

export default defineMessages({
  noPublisherOrAccountSelected: {
    id: 'admanagement.noPublisherOrAccountSelected',
    defaultMessage: 'Select a publisher and account.',
  },
  servingStatusPending: {
    id: 'servingStatus.pending',
    defaultMessage: 'Pending',
  },
  servingStatusNotServing: {
    id: 'servingStatus.notServing',
    defaultMessage: 'Rejected',
  },
  servingStatusServing: {
    id: 'servingStatus.serving',
    defaultMessage: 'Approved',
  },
  servingStatusOnHold: {
    id: 'servingStatus.onHold',
    defaultMessage: 'On hold',
  },
});
