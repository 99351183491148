import { Body1, Body2, Card, FluentProvider, Title2, Title3, webLightTheme } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { FormState } from '../../components/Form/FormState/FormState';
import { injectRouterWithIntl } from '../../utils/ReactUtils';
import {
  getTythonAdUnitCreatePageRoute,
  getTythonAppAdunitCreationRoute,
  getTythonAppInventoryCreationRoute,
  getTythonSiteCreatePageRoute,
} from '../../utils/routeUtils';
import { DetailPage } from '../DetailPage/DetailPage';
import { Footer } from '../Footer/Footer';
import ConnectAppImg from './connectApp.svg';
import ConnectSiteImg from './connectSite.svg';
import messages from './messages';
import { useStyles } from './styles';

export enum EntityTypeCreationType {
  Inventory = 'inventory',
  Ads = 'ads',
}

export enum InventoryType {
  Site = 'site',
  App = 'app',
}

type EntityCreationLayoutProps = {
  entityType: EntityTypeCreationType;
};

type EntityCreationLayoutType = React.FC<EntityCreationLayoutProps & InjectedIntlProps & RouteComponentProps>;

const useEntityCreationLayout = ({ entityType, history }) => {
  const [selectedInventoryType, setSelectedInventoryType] = React.useState<InventoryType | undefined>(undefined);

  const entityCreationUrl = {
    [EntityTypeCreationType.Inventory]: {
      [InventoryType.Site]: getTythonSiteCreatePageRoute(),
      [InventoryType.App]: getTythonAppInventoryCreationRoute(),
    },
    [EntityTypeCreationType.Ads]: {
      [InventoryType.Site]: getTythonAdUnitCreatePageRoute(),
      [InventoryType.App]: getTythonAppAdunitCreationRoute(),
    },
  };

  const detailPageProps = React.useMemo(() => {
    return {
      saving: false,
      loading: false,
      onSubmit: () => history.push(entityCreationUrl[entityType][selectedInventoryType!]),
      submitButtonDisabled: !selectedInventoryType,
      submitLabel: 'Next',
      hasFormTitle: false,
      hideNavButton: false,
      pageMode: FormState.Edit,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventoryType, entityType]);

  return {
    detailPageProps,
    selectedInventoryType,
    setSelectedInventoryType,
  };
};

const EntityCreationLayout: EntityCreationLayoutType = ({ entityType, intl: { formatMessage }, history }) => {
  // TODO: enable help article when content is ready
  // const { CMUIPopupArticleByIdAnchor } = useCMUIHelpExperience();
  const styles = useStyles();
  const { detailPageProps, selectedInventoryType, setSelectedInventoryType } = useEntityCreationLayout({ entityType, history });

  const EntityCreationLayoutTitle = {
    [EntityTypeCreationType.Inventory]: formatMessage(messages.inventoryCreationTitle),
    [EntityTypeCreationType.Ads]: formatMessage(messages.adsCreationTitle),
  };

  const EntityCreationLayoutContentSectionOne = {
    [EntityTypeCreationType.Inventory]: (
      <Body2 block className={styles.contentSection}>
        <FormattedMessage
          {...messages.inventoryCreationContentSectionOne}
          values={{
            helpLinkPlaceholder: formatMessage(messages.inventoryCreationContentSectionOneLink),
            // helpLinkPlaceholder: (
            //   <CMUIPopupArticleByIdAnchor
            //     useV2Link
            //     query="ext07504"
            //     name={formatMessage(messages.inventoryCreationContentSectionOneLink)}
            //   />
            // ),
          }}
        />
      </Body2>
    ),
    [EntityTypeCreationType.Ads]: (
      <Body2 block className={styles.contentSection}>
        {formatMessage(messages.adsCreationContentSectionOne)}
      </Body2>
    ),
  };

  const EntityCreationLayoutContentSectionTwo = {
    [EntityTypeCreationType.Inventory]: (
      <Body2 block className={styles.contentSection}>
        <FormattedMessage
          {...messages.inventoryCreationContentSectionTwo}
          values={{
            helpLinkPlaceholder: formatMessage(messages.inventoryCreationContentSectionTwoLink),
            // helpLinkPlaceholder: (
            //   <CMUIPopupArticleByIdAnchor
            //     useV2Link
            //     query="ext07504"
            //     name={formatMessage(messages.inventoryCreationContentSectionTwoLink)}
            //   />
            // ),
          }}
        />
      </Body2>
    ),
  };
  const EntityCreationLayoutSelectableCards = {
    [EntityTypeCreationType.Ads]: [
      {
        inventoryType: InventoryType.Site,
        cardIcon: <img className={styles.imgWrapper} src={ConnectSiteImg} alt={formatMessage(messages.adsCreationCardTitleSite)} />,
        cardTitle: (
          <Title3 block className={styles.cardTitle}>
            {formatMessage(messages.adsCreationCardTitleSite)}
          </Title3>
        ),
      },
      {
        inventoryType: InventoryType.App,
        cardIcon: <img className={styles.imgWrapper} src={ConnectAppImg} alt={formatMessage(messages.adsCreationCardTitleApp)} />,
        cardTitle: (
          <Title3 block className={styles.cardTitle}>
            {formatMessage(messages.adsCreationCardTitleApp)}
          </Title3>
        ),
      },
    ],
    [EntityTypeCreationType.Inventory]: [
      {
        inventoryType: InventoryType.Site,
        cardIcon: <img className={styles.imgWrapper} src={ConnectSiteImg} alt={formatMessage(messages.inventoryCreationCardTitleSite)} />,
        cardTitle: (
          <Title3 block className={styles.cardTitle}>
            {formatMessage(messages.inventoryCreationCardTitleSite)}
          </Title3>
        ),
        cardDescription: (
          <Body1 block className={styles.cardDescription}>
            <FormattedMessage
              {...messages.inventoryCreationCardDescriptionSite}
              values={{
                MSPolicyPlaceholder: formatMessage(messages.MSPolicyPlaceholder),
                AdRenderingPoliciesPlaceholder: formatMessage(messages.AdRenderingPoliciesPlaceholder),
                SiteApprovalGuidelinesPlaceholder: formatMessage(messages.SiteApprovalGuidelinesPlaceholder),
                /**
                 * MSPolicyPlaceholder: (
                  <CMUIPopupArticleByIdAnchor useV2Link query="ext07504" name={formatMessage(messages.MSPolicyPlaceholder)} />
                ),
                AdRenderingPoliciesPlaceholder: (
                  <CMUIPopupArticleByIdAnchor useV2Link query="ext07504" name={formatMessage(messages.AdRenderingPoliciesPlaceholder)} />
                ),
                SiteApprovalGuidelinesPlaceholder: (
                  <CMUIPopupArticleByIdAnchor useV2Link query="ext07504" name={formatMessage(messages.SiteApprovalGuidelinesPlaceholder)} />
                ),
                 */
              }}
            />
          </Body1>
        ),
      },
      {
        inventoryType: InventoryType.App,
        cardIcon: <img className={styles.imgWrapper} src={ConnectAppImg} alt={formatMessage(messages.inventoryCreationCardTitleApp)} />,
        cardTitle: (
          <Title3 block className={styles.cardTitle}>
            {formatMessage(messages.inventoryCreationCardTitleApp)}
          </Title3>
        ),
        cardDescription: (
          <Body1 block className={styles.cardDescription}>
            <FormattedMessage
              {...messages.inventoryCreationCardDescriptionApp}
              values={{
                MSPolicyPlaceholder: formatMessage(messages.MSPolicyPlaceholder),
                AdRenderingPoliciesPlaceholder: formatMessage(messages.AdRenderingPoliciesPlaceholder),
                AppApprovalGuidelinesPlaceholder: formatMessage(messages.AppApprovalGuidelinesPlaceholder),
                /**
                 *  MSPolicyPlaceholder: (
                  <CMUIPopupArticleByIdAnchor useV2Link query="ext07504" name={formatMessage(messages.MSPolicyPlaceholder)} />
                ),
                AdRenderingPoliciesPlaceholder: (
                  <CMUIPopupArticleByIdAnchor useV2Link query="ext07504" name={formatMessage(messages.AdRenderingPoliciesPlaceholder)} />
                ),
                AppApprovalGuidelinesPlaceholder: (
                  <CMUIPopupArticleByIdAnchor useV2Link query="ext07504" name={formatMessage(messages.AppApprovalGuidelinesPlaceholder)} />
                ),
                 */
              }}
            />
          </Body1>
        ),
      },
    ],
  };

  return (
    <>
      {' '}
      <DetailPage {...detailPageProps}>
        <FluentProvider theme={webLightTheme}>
          <div className={styles.root}>
            <Title2 align="center" block className={styles.title}>
              {EntityCreationLayoutTitle[entityType]}
            </Title2>
            <br />
            {EntityCreationLayoutContentSectionOne[entityType]}
            <br />
            {EntityCreationLayoutContentSectionTwo[entityType]}
            <br />
            <br />
            <div className={styles.cardGroupWrapper}>
              {EntityCreationLayoutSelectableCards[entityType].map((card) => (
                <div className={styles.cardWrapper}>
                  <Card
                    className={styles.card}
                    selected={card.inventoryType === selectedInventoryType}
                    onSelectionChange={() => setSelectedInventoryType(card.inventoryType)}
                  >
                    {card.cardIcon}
                    {card.cardTitle}
                  </Card>
                  <br />
                  {card.cardDescription}
                </div>
              ))}
            </div>
          </div>
        </FluentProvider>
      </DetailPage>
      <Footer />
    </>
  );
};

export default injectRouterWithIntl(EntityCreationLayout);
