import { orchestrator } from 'satcheljs';
import { showErrorToastNotification, User } from '../../../../../@data';
import messages from '../../ProfilePage.messages';
import { fetchUserDetailsAction } from '../actions';
import { setPrimaryUserDetailsAction } from '../actions/mutatorActions';
import { fetchUserDetails } from '../services/fetchUserDetails';

orchestrator(fetchUserDetailsAction, async ({ userId }) => {
  try {
    if (!userId) {
      return;
    }

    const userDetails = await fetchUserDetails(new User(userId));
    setPrimaryUserDetailsAction(userDetails);
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: messages.loadUserDetailsFailed });
  }
});
