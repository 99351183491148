import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'loginPage.title',
    defaultMessage: 'Microsoft pubCenter',
  },
  welcome: {
    id: 'loginPage.welcome',
    defaultMessage: 'Welcome to pubCenter!',
  },
  description: {
    id: 'loginPage.description',
    defaultMessage: `We understand that creating content can be time-consuming. Making it profitable should be simple and hassle-free.`,
  },
  emailPlaceholder: {
    id: 'loginPage.emailPlaceholder',
    defaultMessage: 'Enter your email address',
  },
  signInButton: {
    id: 'loginPage.signInButton',
    defaultMessage: 'Sign in',
  },
  signInButtonAriaLabel: {
    id: 'loginPage.signInButtonAriaLabel',
    defaultMessage: 'Click to sign in',
  },
  imageAlt: {
    id: 'loginPage.imageAlt',
    defaultMessage: 'login background image',
  },
  redirectionMessage: {
    id: 'loginPage.redirectionMessage',
    defaultMessage: 'Redirecting to Monetize Now',
  },
});
