import { isTythonSelfServeUser } from '../../../../../@data';
import { FilterType } from '../../../../../components/GridFilter/GridFilter.types';
import { IFilterItem } from '../../../../../layout/GridPageV2';
import messages from '../messages';

export const getFilterItems = (formatMessage): IFilterItem[] => {
  const tythonFilters: IFilterItem[] = [
    { columnName: 'id', filterName: formatMessage(messages.siteId), filterType: FilterType.relational },
    { columnName: 'name', filterName: formatMessage(messages.siteName), filterType: FilterType.string },
    { columnName: 'url', filterName: formatMessage(messages.url), filterType: FilterType.string },
    // {
    //   columnName: 'status',
    //   filterName: formatMessage(AppMessages.status),
    //   filterType: FilterType.custom,
    //   filterOptions: [
    //     { key: 'Active', text: formatMessage(AppMessages.statusActive) },
    //     { key: 'Inactive', text: formatMessage(AppMessages.statusInactive) },
    //   ] as IDropdownOption[],
    // },
  ];

  const nonTythonFilters: IFilterItem[] = [];

  return isTythonSelfServeUser() ? tythonFilters : nonTythonFilters;
};
