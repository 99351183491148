import { getAppealStore } from '../../pages/ad-management/Profile/@data/store/appealStore';
import { PublisherType } from '../store/schema/enums/PublisherType';
import {
  appealableRejectionCodes,
  EntityEvaluationErrorCode,
  EntityEvaluationStatus,
  unappealableRejectionCodes,
} from '../store/schema/models/EntityEvaluationStatus';
import { getAppStore } from '../store/store';

export function getCurrentPublisher() {
  return getAppStore().publisher;
}

export function getCurrentPublisherId() {
  return getCurrentPublisher()?.id;
}

export function getCurrentPublisherName() {
  return getCurrentPublisher()?.name as string;
}

export function isPublisherProfileDataPresent() {
  return !!getCurrentPublisher()?.address;
}

export function getPublisherEvaluationStatus() {
  return getAppStore().publisherEvaluationStatus;
}

export function getPublisherCountry() {
  return getCurrentPublisher()?.address?.country;
}

export function getPublisherEvaluationApprovalStatus() {
  return getPublisherEvaluationStatus()?.approvalStatus;
}

export function isPublisherEvaluationStatusFetchFailed() {
  return getAppStore().isPublisherEvaluationStatusFetchFailed;
}

export function getPublisherEvaluationRejectionCodes(): EntityEvaluationErrorCode[] {
  return (
    getPublisherEvaluationStatus()
      ?.properties?.rejectionCodes?.split(',')
      .map((code) => code.trim() as unknown as EntityEvaluationErrorCode) ?? []
  );
}

export function getAppealableRejectionCodes() {
  return getPublisherEvaluationRejectionCodes().filter((code) =>
    appealableRejectionCodes.includes(EntityEvaluationErrorCode[code] as unknown as EntityEvaluationErrorCode)
  );
}

export function isPublisherProfileInComplete() {
  return getPublisherEvaluationApprovalStatus() === EntityEvaluationStatus.Pending;
}

export function isPublisherApproved() {
  return getPublisherEvaluationApprovalStatus() === EntityEvaluationStatus.Approved;
}

// NOTE: Currently we are handling any rejection codes.
// If publisher EntityEvaluationStatus is Rejected, then publisher is shown as suspended in UI
export function isPublisherRejected() {
  return getPublisherEvaluationApprovalStatus() === EntityEvaluationStatus.Rejected;
}

export function isPublisherRejectionAppealable() {
  return isPublisherRejected() && !isPublisherRejectionUnappealable();
}

export function isPublisherRejectionUnappealable(): boolean {
  // if publisher is not rejected, then it is not unappealable
  if (!isPublisherRejected()) {
    return false;
  }

  const rejectionCodes = getPublisherEvaluationRejectionCodes();
  // if rejection codes is empty, then publisher rejection is unappealable
  if (!rejectionCodes || rejectionCodes.length === 0) {
    return true;
  }

  // if appeal documents list is empty, then publisher rejection is unappealable
  if (getAppealDocuments().length === 0) {
    return true;
  }

  // if any of the rejection codes is unappealable, then publisher rejection is unappealable
  for (const code of rejectionCodes) {
    if (unappealableRejectionCodes.includes(EntityEvaluationErrorCode[code] as unknown as EntityEvaluationErrorCode)) {
      return true;
    }
  }

  // publisher rejection is appealable if it does not have any unappealable rejection codes
  return false;
}

export function isPublisherUnderReview() {
  const publisherEvaluationApprovalStatus = getPublisherEvaluationApprovalStatus();
  if (publisherEvaluationApprovalStatus && publisherEvaluationApprovalStatus === EntityEvaluationStatus.InReview) {
    return true;
  }

  const appealHistory = getAppealStore().appealHistory ?? [];
  if (isPublisherRejected() && isPublisherRejectionAppealable() && appealHistory.length > 0) {
    for (const appealStatus of appealHistory) {
      // Publisher is not in review and needs to take action, if any of the appeal status is not Pending or UnderConsideration
      if (appealStatus.reviewStatus !== 'Pending' && appealStatus.reviewStatus !== 'UnderConsideration') {
        return false;
      }
    }
    return true;
  }

  // Publisher is not review - if publisherEvaluationApprovalStatus is not InReview
  // - if publisher is not rejected or if their appealHistory doesn't exist
  return false;
}

export function isPublisherActionRequired() {
  return isPublisherRejectionAppealable() && !isPublisherUnderReview();
}

export function getPublisherAccountType() {
  return getCurrentPublisher()?.publisherType;
}

export function isIndividualPublisher() {
  return getPublisherAccountType() === PublisherType.individual;
}

export function isOrganizationPublisher() {
  return getPublisherAccountType() === PublisherType.organization;
}

export function getAppealDocuments() {
  return getAppStore().publisherEvaluationStatus?.properties?.appealDocuments?.split(',') ?? [];
}

export function isAppealInReviewForRejectionCode(rejectionCode: EntityEvaluationErrorCode) {
  const appealStatus = getAppealStatusForRejectionCode(rejectionCode);
  if (appealStatus && (appealStatus.reviewStatus === 'Pending' || appealStatus.reviewStatus === 'UnderConsideration')) {
    return true;
  }
  return false;
}

export function getAppealStatusForRejectionCode(rejectionCode: EntityEvaluationErrorCode) {
  const { appealHistory } = getAppealStore();
  return appealHistory?.find((history) => history.rootRejectionCode === rejectionCode.toString());
}
