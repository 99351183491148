import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import {
  AdUnit,
  AdUnitMarketClassification,
  AdUnitRPSGuarantee,
  AdUnitType,
  ApiError,
  getAppStore,
  IAdUnitClassification,
  IAdUnitFinancialClassification,
  NativeAdTemplate,
  onNavigateBack,
  onNotificationChanged,
  onSavingChanged,
  postEntity,
  putEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../@data';
import { isTythonSelfServeUser } from '../../../../../@data/utils/checkUserType';
import { FormState } from '../../../../../components/Form';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { StepState } from '../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { stringToList, validateSchema } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/services/AdManagementService';
import messages from '../../Create/CreateAdunit.messages';
import { onAdunitErrorChanged, onAdunitIdChanged, onFormCleared, onFormSubmitted, onPanelOpenChanged } from '../actions';
import { setAdUnitsData } from '../mutatorActions';
import { disableAdunitMediationStatus, enableAdunitMediationStatus } from '../services/AdunitService';
import { adunitErrorModel } from '../store/schema/AdunitErrorModel';
import { getAdunitStore } from '../store/store';

orchestrator(onFormSubmitted, async (msg) => {
  const { publisher, userContext, account } = getAppStore();
  const { active, raw, template } = getAdunitStore();

  let notification: INotificationBarItem = {};

  if (publisher && account && raw) {
    const body: AdUnit = {
      ...raw,
      id: active.id,
      name: active.name,
    };

    try {
      await validateSchema(adunitErrorModel(), active);
      onAdunitErrorChanged();
    } catch (e) {
      Log.error(e);
      onAdunitErrorChanged(e);
      showErrorToastNotification({
        textMessageDescriptor: messages.adunitErrorInForm,
        intlMessageValues: { count: Object.keys(e).length },
      });

      return;
    }

    onSavingChanged(true);

    const classification: IAdUnitClassification = {
      marketClassification: active.marketClassification ? active.marketClassification : AdUnitMarketClassification.Syndicated,
      financialClassification: active.existingFinancialClassification
        ? active.existingFinancialClassification
        : ({
            rpsGuarantee: AdUnitRPSGuarantee.None,
          } as IAdUnitFinancialClassification),
    };

    body.accountId = account ? account.id : 0;
    body.adUnitType = AdUnitType.Search;
    body.classification = classification;
    body.attributes = active.businessModel && active.businessModel !== 0 ? { Taxonomy: active.businessModel } : undefined;
    body.channelIds = active.channelsSelected ? active.channelsSelected.map((m) => m.id) : [];
    body.publisherGroupConfiguration = active.publisherGroupConfig;
    body.servingCostTier = active.servingTier;
    body.partnerCodes = stringToList(active.distributionPartnerCodes);
    body.propertyId = active.propertySelected ? active.propertySelected.id : undefined;
    body.requireImpressionFeedbackForBillableTraffic = !!active.isImpressionFeedbackRequired;

    body.storeIds = active.storeIds
      ? active.storeIds
          .split('\n')
          .filter((i) => i !== '')
          .map((id) => +id)
      : [];

    try {
      let entity: AdUnit | undefined;

      if (
        msg.mode === FormState.Edit ||
        msg.mode === FormState.InPageEdit ||
        msg.mode === FormState.MultiStepEdit ||
        (msg.mode === FormState.MultiStepCreation && msg.stepState === StepState.Dirty)
      ) {
        // while adunit is being edited when all accounts is set in manage page
        body.accountId = raw.accountId;
        entity = await putEntity([publisher], body, userContext, AdUnit, new URLSearchParams([['type', 'Search']]));
        showSuccessToastNotification({ textMessageDescriptor: messages.editedAdunitSuccess, intlMessageValues: { id: entity.id } });

        if (entity) {
          const adUnitsGrid = getAdunitStore().adUnitsRaw.data;
          const propertyUrl = adUnitsGrid?.find((adUnit) => adUnit.id === entity?.id)?.propertyUrl;
          entity.propertyUrl = propertyUrl;
        }

        const adUnitsRawData = getAdunitStore().adUnitsRaw.data;
        if (adUnitsRawData && entity) {
          const data = adUnitsRawData.map((adUnit) => (adUnit.id === entity?.id ? entity! : adUnit));
          setAdUnitsData(data);
        }

        try {
          const isBoost = sessionStorage.getItem('IsBoostPublisher');
          if (template && isBoost === 'true' && JSON.stringify(template) !== JSON.stringify(active.BoostTemplate)) {
            entity.className = new AdUnit().className;
            template.className = new NativeAdTemplate().className;
            await putEntity([publisher, entity], template, userContext, NativeAdTemplate);
          }
        } catch (e) {
          Log.error(e);
          const apiErrorObj = e as ApiError;
          const errMsg = apiErrorObj.message;
          notification = {
            text: `Boost Template Upload Failed for adunit "${entity.id} , caught an error "${errMsg}"`,
            messageBarType: MessageBarType.error,
          };
        }
      } else {
        entity = await postEntity([publisher], AdUnit, body, userContext, new URLSearchParams([['type', 'Search']]));

        if (entity && entity.id) {
          onAdunitIdChanged(entity.id);
        }
        showSuccessToastNotification({ textMessageDescriptor: messages.createdAdunitSuccess, intlMessageValues: { id: entity.id } });

        try {
          if (template) {
            entity.className = new AdUnit().className;
            template.className = new NativeAdTemplate().className;
            await postEntity([publisher, entity], NativeAdTemplate, template, userContext);
          }
        } catch (e) {
          Log.error(e);
          const apiErrorObj = e as ApiError;
          const errMsg = apiErrorObj.message;
          notification = {
            text: `Boost Template Upload Failed for adunit "${entity.id}, caught an error "${errMsg}"`,
            messageBarType: MessageBarType.error,
          };
          onNotificationChanged(notification);
        }
      }

      onPanelOpenChanged(false, body.propertyId, entity.id);

      const returnUrl = createReturnUrl(
        RouteName.adunit,
        String(publisher.id),
        String(account.id),
        String(entity.id),
        isTythonSelfServeUser() ? msg.mode : undefined
      );
      // return to manage page if the adunit creation isn't multi-step process (for tython users only)
      onNavigateBack(msg.routeHistory, returnUrl);
      if (!isTythonSelfServeUser()) {
        onFormCleared();
      }

      if (active.adSenseMediationStatus !== active.adSenseMediationToggle && msg.shouldHandleMediation) {
        if (active.adSenseMediationToggle) {
          enableAdunitMediationStatus(publisher, account, [active?.id!]);
        } else {
          disableAdunitMediationStatus(publisher, account, [active?.id!]);
        }
      }

      // proceed to the next step in the creation process (for tython users only)
      if (msg.onSubmit) {
        msg.onSubmit();
      }
    } catch (e) {
      Log.error(e);
      const apiErrorObj = e as ApiError;
      let errMsg = apiErrorObj.message;
      errMsg = errMsg.substring(errMsg.indexOf(`{"errors":`));
      notification = {
        text: errMsg.includes('InvalidPartnerCodes')
          ? 'One of the Search Distribution Partner Codes is already associated with another adunit'
          : `Submitting the adunit form "${FormState[msg.mode]}" caught an error "${errMsg}"`,
        messageBarType: MessageBarType.error,
      };
      onNotificationChanged(notification);
    } finally {
      onSavingChanged(false);
    }
  } else {
    notification = {
      text: `Adunit form "${FormState[msg.mode]}" is invalid id ${active.id}`,
      messageBarType: MessageBarType.warning,
    };
    onNotificationChanged(notification);
  }
});
