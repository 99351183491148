import { classNamesFunction, getTheme, INavLink } from '@fluentui/react';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { getAppStore, LayoutMode, onMenuCollapsedChanged } from '../../@data';
import { completeAccess, hasReadAccess, UserRoleEntity } from '../../@data/services/UserRoleService';
import { NavigationMenu } from '../../@data/store/schema/userrolemodels/NavigationMenu';
import { isTythonSelfServeUser } from '../../@data/utils/checkUserType';
import { getEntityAccessPermissions } from '../../@data/utils/getAccessPermission';
import { DetectAdBlock } from '../../components/DetectAdBlock/DetectAdBlock';
import { Main } from '../../components/Main/Main';
import { ICustomNaveLink, ICustomNavLinkGroup, NavGroupType, NavToggler } from '../../components/Nav';
import { useWindowSize } from '../../hooks';
import { getStore } from '../../hooks/useWindowSize/store/store';
import { RouteName } from '../../pages/ad-management/@data';
import { IRoute } from '../../Routes.types';
import { injectIntlWithObserver } from '../../utils';
import { getAdUnitRoute, getSitesRoute } from '../../utils/routeUtils';
import { Footer } from '../Footer/Footer';
import { InternationalizationLayout } from '../InternationalizationLayout';
import { NotFound } from '../NotFound/NotFound';
import { NotificationLayout } from '../NotificationLayout';
import TythonNav from '../TythonNav';
import messages from './ContentLayout.messages';
import { getStyles } from './ContentLayout.styles';
import { IContentLayoutProps, IContentLayoutStyleProps, IContentLayoutStyles } from './ContentLayout.types';

const getClassNames = classNamesFunction<IContentLayoutStyleProps, IContentLayoutStyles>();
const { windowStoreSerializer } = useWindowSize();
/**
 * ContentLayout component is used to render the snackbar notifications, side-nav menu conditionally
 * and a custom header below the global header.
 */
export const ContentLayout = injectIntlWithObserver(
  class ContentLayoutComponent extends React.Component<IContentLayoutProps> {
    // when layout initial load, define nav manu collapse based on window size
    public componentDidMount(): void {
      const { isWindowRegularSize } = windowStoreSerializer(getStore());
      if (!isWindowRegularSize) {
        onMenuCollapsedChanged(true);
      }
    }

    public render(): JSX.Element {
      const classNames = getClassNames(getStyles, { theme: getTheme() });
      const renderedHeader = this.props.onRenderHeader && this.props.onRenderHeader();

      const isDefaultLayout = getAppStore().layout === LayoutMode.Default;

      let menuRenderer: JSX.Element | undefined;

      if (this.props.menuProps) {
        menuRenderer = (
          <div className={classNames.appNav}>
            {isTythonSelfServeUser() ? (
              <TythonNav {...this.props} accessibleRoutes={this._getNavigationAccess(this.props.menuProps.routes)} />
            ) : (
              <NavToggler
                intl={this.props.intl}
                groups={this._getNavigationAccess(this.props.menuProps.routes)}
                dataHint="LeftNav"
                enableCustomization={true}
                isNavCollapsed={getAppStore().menuCollapsed}
                selectedKey={this.props.location.pathname}
                onNavCollapsedCallback={(isCollapsed) => onMenuCollapsedChanged(isCollapsed)}
                ariaLabel={this.props.intl.formatMessage(messages.mainMenuAriaLabel)}
              />
            )}
          </div>
        );
      }

      return (
        <React.Fragment>
          <DetectAdBlock />
          <div className={classNames.root}>
            <InternationalizationLayout publisher={getAppStore().publisher} userContext={getAppStore().userContext} />
            <NotificationLayout {...this.props} ignoreGlobalAsync={this.props.ignoreNotificationAsync}>
              <div className={classNames.menuAndContent}>
                {isDefaultLayout && menuRenderer}
                <Main className={classNames.appContent}>
                  {isDefaultLayout && renderedHeader}
                  <Switch>
                    {this.props.children}
                    <Route path="*" component={NotFound} />
                  </Switch>
                  <Footer {...this.props} />
                </Main>
              </div>
            </NotificationLayout>
          </div>
        </React.Fragment>
      );
    }

    private _getNavigationAccess(routes: IRoute[]): ICustomNavLinkGroup[] {
      const toggleNavLinkName = '';
      const navAccessPermissions = getEntityAccessPermissions(UserRoleEntity.NavigationMenu) as NavigationMenu | null;

      let navMenuLinks: ICustomNaveLink[] = [];

      routes.forEach((route: IRoute) => {
        const routeName = this._getRouteName(route.key);
        const isTythonRoute = isTythonSelfServeUser() && [RouteName.inventory, RouteName.ads].includes(routeName as RouteName);

        if (hasReadAccess(navAccessPermissions === null ? completeAccess : navAccessPermissions[routeName]) || isTythonRoute) {
          navMenuLinks.push({
            name: route.text,
            url: this.props.match.url + route.key,
            onClick: this._onNavClicked,
            icon: route.icon,
            title: route.text,
            disabled: route.disabled,
            key: this.props.match.url + route.key,
          });
        }
      });

      if (isTythonSelfServeUser()) {
        const tythonExcludedRoutes = [getAdUnitRoute(), getSitesRoute()];
        navMenuLinks = navMenuLinks.filter((link) => !tythonExcludedRoutes.includes(link.url));
      }

      const toggleGroup = isTythonSelfServeUser()
        ? []
        : [
            {
              links: [
                {
                  name: toggleNavLinkName,
                  url: '#',
                  icon: 'GlobalNavButton',
                  key: 'key',
                  tooltipText: toggleNavLinkName,
                },
              ],
              groupType: NavGroupType.ToggleGroup,
            },
          ];

      return [
        ...toggleGroup,
        {
          links: navMenuLinks,
          groupType: NavGroupType.MenuGroup,
        },
      ];
    }

    private _onNavClicked = (e: React.MouseEvent<HTMLElement>, nl: INavLink) => {
      e.preventDefault();

      if (!nl) {
        return;
      }

      this.props.history.push(nl.url);
    };

    private _getRouteName = (key: string) => {
      const keyParts = key.split('/');
      const lastPart = keyParts[keyParts.length - 1];
      return lastPart.split('?')[0];
    };
  }
);
