import { IUserRoleDetail, User, UserRole, UserRoleEntityType } from '../../../../@data';
import UserManagementMessages from '../UserManagement.messages';

export function getUserRoleFromUser(user: User): UserRole | undefined {
  let userRole: UserRole | undefined;
  const roleDetails: IUserRoleDetail[] | undefined = user.userRoleDetails;

  if (roleDetails && roleDetails.length > 0) {
    roleDetails.forEach((roleDetail) => {
      if (roleDetail.entityType === UserRoleEntityType.Customer) {
        userRole = roleDetail.userRole;
      }
    });
  }
  return userRole;
}

export function getUserRoleString(formatMessage, user: User): string {
  const userRole = getUserRoleFromUser(user);

  return getFormattedUserRole(formatMessage, userRole);
}

export function getFormattedUserRole(formatMessage, userRole?: UserRole): string {
  const { superAdmin, accountManager, reportUser } = UserManagementMessages;

  switch (userRole) {
    case UserRole.SuperAdmin:
      return formatMessage(superAdmin);
    case UserRole.AccountManager:
      return formatMessage(accountManager);
    case UserRole.ReportUser:
      return formatMessage(reportUser);
    default:
      return '';
  }
}

export function getUserRoleMapping(roleIds: number[] | undefined, formatMessage?): string {
  if (roleIds && roleIds.length > 0) {
    if (roleIds.find((id) => id === 41)) {
      return formatMessage ? formatMessage(UserManagementMessages.superAdmin) : 'Super Admin';
    }
    if (roleIds.find((id) => id === 43)) {
      return formatMessage ? formatMessage(UserManagementMessages.accountManager) : 'Account Manager';
    }
    if (roleIds.find((id) => id === 49)) {
      return formatMessage ? formatMessage(UserManagementMessages.reportUser) : 'Report User';
    }
  }
  return '';
}

export const userRoleToRoleIdMap = new Map<UserRole, number>([
  [UserRole.SuperAdmin, 41],
  [UserRole.AccountManager, 43],
  [UserRole.ReportUser, 49],
]);

export const userRoleIdToRoleNameMap = new Map<number, string>([
  // user roles scoped to publisher shell
  [41, 'SuperAdmin'],
  [43, 'AccountManager'],
  [49, 'ReportUser'],
  [42, 'PublisherAdmin'],
  [61, 'ListManager'],
  [62, 'AdViewer'],

  // internal user roles
  [91, 'Provisioning Internal'],
  [116, 'Partner Account Manager'],
  [124, 'Yahoo PRM'],
  [125, 'Yahoo Search Account Manager'],
  [156, 'Provisioning External'],
  [169, 'PC Inventory Management'],
  [170, 'PC Support'],
  [171, 'PC Guest Read Only'],
  [172, 'PC Finance Ops'],
  [174, 'PC 3PE Management'],
]);
