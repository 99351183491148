import { isTythonSelfServeUser } from '../../../@data';
import { FormState } from '../../../components/Form';
import { RoutePrefix } from '../../../Routes';

type IdType = number | string;

export function getMultiStepperRoute(
  routeName: string,
  operation: FormState,
  publisherId: IdType,
  accountId?: IdType,
  entityId?: IdType,
  step?: number,
  showExtraBtns?: boolean
): string {
  const page = operation === FormState.MultiStepCreation || operation === FormState.Create ? 'create' : 'edit';
  let base = '';
  if (accountId) {
    base = `/${RoutePrefix.adManagement}/${publisherId}-${accountId}/${routeName}/${page}`;
  } else {
    base = `/${RoutePrefix.adManagement}/${publisherId}/${routeName}/${page}`;
  }

  if (entityId && step !== undefined && showExtraBtns && isTythonSelfServeUser()) {
    return `${base}/${entityId}?step=${step}&showExtraBtns=${showExtraBtns}`;
  } else if (entityId && step !== undefined) {
    return `${base}/${entityId}?step=${step}`;
  } else if (entityId) {
    return `${base}/${entityId}`;
  }
  return base;
}
