import { MessageBarType } from '@fluentui/react';
import {
  AdSenseAdUnitStatus,
  AdSenseDisconnectAdUnit,
  deleteEntity,
  onNotificationChanged,
  showSuccessToastNotification,
} from '../../../../../@data';
import { onAdunitMediationUpdateEnd, onAdunitMediationUpdateStart } from '../../@data/actions';
import { updateAdUnitGrid } from '../../@data/services/AdunitService';
import messages from '../CreateAdunit/messages';

export const disableTythonAdUnitMediation = async (ids: number[], onRequestStart?: () => void): Promise<AdSenseAdUnitStatus[]> => {
  const request: AdSenseDisconnectAdUnit = {
    apiPath: 'preauth/mediation',
    className: 'pubcenterAdUnitsIds',
    pubcenterAdUnitIds: ids,
  };
  const urlParams = new URLSearchParams();
  urlParams.append('routeSuffix', 'api/mediation/admanager/decommissionAdUnitMediation');
  try {
    onRequestStart?.();
    onAdunitMediationUpdateStart(ids);
    const response = (await deleteEntity(
      [],
      AdSenseDisconnectAdUnit,
      undefined,
      urlParams,
      undefined,
      request
    )) as AdSenseDisconnectAdUnit[];
    if (!response) {
      onNotificationChanged({
        messageBarType: MessageBarType.error,
        textMessageDescriptor: messages.removingAdunitMediationFailed,
      });
      return [];
    } else {
      showSuccessToastNotification({ textMessageDescriptor: messages.removingAdunitMediationSuccess });
      // only 1 ad unit will be updated for mediation, so first one in the array is the source of truth
      updateAdUnitGrid(ids[0], false, true);
      return response;
    }
  } catch {
    return [];
  } finally {
    onAdunitMediationUpdateEnd(ids);
  }
};
