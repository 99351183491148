export const adProvidersToNames = {
  1: 'AdYouLike',
  2: 'App Nexus',
  3: 'Baidu',
  4: 'Bing',
  5: 'Dable',
  6: 'EngageYa',
  7: 'MediaNet',
  8: 'MGID',
  9: 'OutBrain',
  10: 'PopIn',
  11: 'PostSquare',
  12: 'RevContent',
  13: 'ShareThrough',
  14: 'Strossle',
  15: 'Taboola',
  16: 'Triple Lift',
  17: 'Yengo',
  18: 'Criteo',
  19: 'Gemini',
  20: 'Taboola NetBidder',
  21: 'Colombia',
  22: 'SongHeng',
  23: 'LockerDome',
  24: 'LinkedIn',
  25: 'Gecko',
  26: 'Qihoo',
};
