import { defineMessages } from 'react-intl';

export default defineMessages({
  selectTextToggleLabel: {
    id: 'dataGrid.selectTextToggleLabel',
    defaultMessage: 'Select text',
  },
  selectTextToggleAriaDesc: {
    id: 'dataGrid.selectTextToggleAriaDesc',
    defaultMessage: 'Turn on the toggle to enable modal selection of grid',
  },
  ariaLabelForSelectAllCheckbox: {
    id: 'dataGrid.ariaLabelForSelectAllCheckbox',
    defaultMessage: 'Toggle selection for all items',
  },
  ariaLabelForSelectionColumn: {
    id: 'dataGrid.ariaLabelForSelectionColumn',
    defaultMessage: 'Toggle selection',
  },
  checkButtonAriaLabel: {
    id: 'dataGrid.checkButtonAriaLabel',
    defaultMessage: 'Row selection checkbox',
  },
  columnAriaLabel: {
    id: 'dataGrid.columnAriaLabel',
    defaultMessage: 'Click to open options for {columnName} column',
  },
  columnSortAscendingAriaLabel: {
    id: 'dataGrid.columnSortAscendingAriaLabel',
    defaultMessage: 'Grid sorted ascending using {columnName} column',
  },
  columnSortDescendingAriaLabel: {
    id: 'dataGrid.columnSortDescendingAriaLabel',
    defaultMessage: 'Grid sorted descending using {columnName} column',
  },
  numberOfItemsAfterFilterLabel: {
    id: 'dataGrid.numberOfItemsAfterFilterLabel',
    defaultMessage: 'Number of items after filter applied: {displayItems}',
  },
  commandBarNavigationHelpArialLabel: {
    id: 'dataGrid.commandBarNavigationHelpArialLabel',
    defaultMessage: 'Use left and right arrow keys to navigate between options',
  },
  columnResizeDialogTitle: {
    id: 'dataGrid.columnResizeDialogTitle',
    defaultMessage: 'Resize column',
  },
  columnWidthFieldLabel: {
    id: 'dataGrid.columnWidthFieldLabel',
    defaultMessage: 'Enter column width',
  },
  columnResizeDialogDescription: {
    id: 'dataGrid.columnWidthFieldLabel',
    defaultMessage: 'Enter desired column width in pixels',
  },
  resizeButtonLabel: {
    id: 'dataGrid.resizeButtonLabel',
    defaultMessage: 'Resize',
  },
  sortButtonLabel: {
    id: 'dataGrid.sortButtonLabel',
    defaultMessage: 'Sort',
  },
});
