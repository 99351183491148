import { IChoiceGroupOption } from '@fluentui/react';
import React from 'react';
import { UserRole } from '../../../../@data';
import UserManagementMessages from '../UserManagement.messages';
import { getClassNames } from '../UserManagement.styles';

export function getUserRoleChoiceGroupOptions(intl) {
  const { formatMessage } = intl;
  const { roleDesc } = getClassNames();
  const { superAdmin, accountManager, reportUser, superAdminDesc, accountManagerDesc, reportUserDesc } = UserManagementMessages;

  function renderChoiceGroupOption(props, render, description) {
    return (
      <React.Fragment>
        {render?.(props)}
        <div className={roleDesc}>{formatMessage(description)}</div>
      </React.Fragment>
    );
  }

  const choiceGroupOptions: IChoiceGroupOption[] = [
    {
      key: UserRole.SuperAdmin,
      text: formatMessage(superAdmin),
      onRenderField: (props, render) => renderChoiceGroupOption(props, render, superAdminDesc),
    },
    {
      key: UserRole.AccountManager,
      text: formatMessage(accountManager),
      onRenderField: (props, render) => renderChoiceGroupOption(props, render, accountManagerDesc),
    },
    {
      key: UserRole.ReportUser,
      text: formatMessage(reportUser),
      onRenderField: (props, render) => renderChoiceGroupOption(props, render, reportUserDesc),
    },
  ];

  return choiceGroupOptions;
}
