import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { onNotificationChanged } from '../../../../../@data';
import messages from '../../AdReview/AdCreativePage.messages';
import { onBlockedAdsContextChanged, onBlockedContextChanged } from '../actions';
import { getBlockImagesData } from '../services/AdBlockService';
import { getBlockedAdContext } from '../services/AdCreativeService';

orchestrator(onBlockedContextChanged, async (msg) => {
  try {
    const blockedAdContext = await getBlockedAdContext(msg.publisherId);
    blockedAdContext.mapAdIdImages = new Map<number, string[]>();
    const blockedImageSummary = await getBlockImagesData(msg.publisherId);
    blockedImageSummary.forEach((element) => {
      if (blockedAdContext.mapAdIdImages) {
        if (blockedAdContext.mapAdIdImages.has(element.adId!)) {
          blockedAdContext.mapAdIdImages.get(element.adId!)!.push(element.image!);
        } else {
          blockedAdContext.mapAdIdImages.set(element.adId!, [element.image!]);
        }
      }
    });
    onBlockedAdsContextChanged(blockedAdContext);
  } catch (e) {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityDownloadAdContextFailed,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
