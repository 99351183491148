import messages from '../pages/ad-management/AdQuality/AdReview/AdCreativePage.messages';

export const blockReasons = {
  0: [
    messages.disallowedDeceptive,
    messages.disallowedMisleading,
    messages.disallowedOffensive,
    messages.disallowedSuffering,
    messages.disallowedDrugs,
    messages.disallowedTobacco,
    messages.disallowedSensitive,
    messages.otherReasonCategory,
  ],
  1: [messages.legalMalware, messages.legalPrivacy, messages.otherReasonCategory],
  2: [messages.mediaAnimation, messages.mediaNudity, messages.otherReasonCategory],
  3: [messages.relevanceLandingPage, messages.otherReasonCategory],
  4: [
    messages.restrictedAdult,
    messages.restrictedAlcohol,
    messages.restrictedBeauty,
    messages.restrictedDating,
    messages.restrictedFinance,
    messages.restrictedGambling,
    messages.restrictedLegalServices,
    messages.restrictedCompetitors,
    messages.restrictedPharmacy,
    messages.restrictedPolitical,
    messages.restrictedSoftware,
    messages.restrictedSolicitation,
    messages.restrictedWeapons,
    messages.otherReasonCategory,
  ],
  5: [messages.textAdFunctionality, messages.textAdLanguage, messages.textGrammar, messages.otherReasonCategory],
};
