import { orchestrator } from 'satcheljs';
import { DismissNotificationRequest, NotificationSeverity, getAppStore, onDismissNotification, onNotificationPanelItemsChanged } from '..';
import { Log } from '../../../logging/src';
import { INotificationCardItem } from '../../components/NotificationCard';
import { SESSION_DISMISSED_NOTIFICATIONS_BACKEND, SESSION_NOTIFICATIONS_UI } from '../../constants/AppConstants';
import { dismissNotifications } from '../services/NotificationService';

orchestrator(onDismissNotification, async (msg) => {
  const store = getAppStore();
  let panelNotifications = store.panelNotifications || [];
  const selectedNotification = msg.notification;
  const indexOfNotification = panelNotifications.findIndex((notification) =>
    selectedNotification.component
      ? notification.id === selectedNotification.id
      : notification.title === selectedNotification.title && notification.id === selectedNotification.id
  );
  panelNotifications = [...panelNotifications.slice(0, indexOfNotification), ...panelNotifications.slice(indexOfNotification + 1)];
  onNotificationPanelItemsChanged(panelNotifications);

  if (selectedNotification.isUIGenerated) {
    const existingNotifications = sessionStorage.getItem(SESSION_NOTIFICATIONS_UI);

    let notifications: INotificationCardItem[] = [];
    if (existingNotifications) {
      notifications = JSON.parse(existingNotifications);
    }

    const indexOfSSNotification = notifications.findIndex((notification) =>
      selectedNotification.component
        ? notification.id === selectedNotification.id
        : notification.title === selectedNotification.title && notification.id === selectedNotification.id
    );
    notifications = [...notifications.slice(0, indexOfSSNotification), ...notifications.slice(indexOfSSNotification + 1)];

    // Store the updated list back to sessionStorage
    const updatedData = JSON.stringify(notifications);
    sessionStorage.setItem(SESSION_NOTIFICATIONS_UI, updatedData);
  } else {
    // dismiss notification in backend if not critical or warning, or else just in UI
    if (selectedNotification.severity === NotificationSeverity.Critical || selectedNotification.severity === NotificationSeverity.Warning) {
      const dismissedNotifications = sessionStorage.getItem(SESSION_DISMISSED_NOTIFICATIONS_BACKEND);

      let dismissedBackendNotificationInUI: string[] = [];
      if (dismissedNotifications) {
        dismissedBackendNotificationInUI = JSON.parse(dismissedNotifications);
      }
      // we donot dismiss backend notification but remove in UI
      dismissedBackendNotificationInUI.push(selectedNotification.id!);
      sessionStorage.setItem(SESSION_DISMISSED_NOTIFICATIONS_BACKEND, JSON.stringify(dismissedBackendNotificationInUI));
    } else {
      try {
        const body: DismissNotificationRequest = { notificationIds: [selectedNotification.id!] };
        await dismissNotifications(store.publisher?.id!, store.account?.id!, store.userContext!, body);
      } catch (e) {
        Log.error(`Failed to dismiss notifications: ${JSON.stringify(e)}`);
      }
    }
  }
});
