import { Model } from '../@data';
import { LifeCycleStatus } from '../@data/store/schema/enums/LifeCycleStatus';
import { adProvidersToNames } from '../config/adProviders';
import { xandrAdProvidersToNames } from '../config/xandrAdProviders';
import { IDataGridColumn, IGridV2Column } from '../layout/GridPageV2';
import { extractPlacement } from '../pages/ad-management/AdQuality/@data/services/utility/utility';
import { getStore } from '../pages/ad-management/AdQuality/@data/store/store';

export class CSVHelper {
  public static escapeStringForCSV(stringToEscape): string {
    // Does some basic handling of escaped characters that could mess up CSV structure.
    // Also handles some special chars that could mess with our URI-based download.
    // e.g. turns quotes to double quotes, newlines into \n, and # into %23.
    return stringToEscape
      ? stringToEscape.replace(/"/g, '""').replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/#/g, '%23')
      : stringToEscape;
  }

  public static extractDate(dateTimeStamp: string): string {
    return dateTimeStamp ? dateTimeStamp.substring(0, 10) : '';
  }

  public static getBlockingStatus(isEnabled: boolean): string {
    return isEnabled ? 'Applied' : 'Disabled';
  }

  public static getBlockRequestSource(val: number): string {
    return val === 0 ? 'N/A' : getStore().active.blockRequestSourceData?.get(val)!;
  }

  public static getRevenue(revenue: string): string {
    return '$' + revenue;
  }

  public static populateNull(val: string): string {
    return val ? `"${CSVHelper.escapeStringForCSV(val)}"` : 'null';
  }

  public static getAdProvider(provider: string): string {
    return provider ? CSVHelper.escapeStringForCSV(adProvidersToNames[provider]) : '';
  }

  public static getXandrAdProvider(provider: string): string {
    return provider ? CSVHelper.escapeStringForCSV(xandrAdProvidersToNames[provider]) : '';
  }

  public static getStatus(status: string): string {
    return status && status === LifeCycleStatus.Inactive ? 'BLOCKED' : '';
  }

  public static getPlacement(dataRow): string {
    return CSVHelper.escapeStringForCSV(extractPlacement(dataRow));
  }

  public static getXandrPlacement(dataRow): string {
    return CSVHelper.escapeStringForCSV(dataRow.locale);
  }

  public static downloadDataAsCSV(csvData, csvHeaders, csvFilename) {
    const csvDataMarker = 'data:text/csv;charset=utf-8,';
    const csvFileData = csvDataMarker + (csvHeaders ? `${csvHeaders}\n` : '') + csvData;
    const encodedCSV = encodeURI(csvFileData);
    const csvLink = document.createElement('a');
    csvLink.setAttribute('download', csvFilename);
    csvLink.setAttribute('href', encodedCSV);
    document.body.appendChild(csvLink);
    csvLink.click();
    document.body.removeChild(csvLink);
  }

  public static getCSVStrings(rowData: Model, columns: IDataGridColumn[] | IGridV2Column[]) {
    // tslint:disable-next-line: no-any
    const cells: any[] = [];

    columns.forEach((col) => {
      let cellData = col.columnName === 'blockRequestSource' ? rowData['blockRequestSourceId'] : rowData[col.columnName];

      if (col.exportFunction) {
        if (col.friendlyName === 'Placement') {
          cellData = col.exportFunction(rowData);
          cellData = `"${CSVHelper.escapeStringForCSV(cellData)}"`;
        } else {
          cellData = col.exportFunction(cellData);
        }
      } else if (isNaN(cellData)) {
        cellData = `"${CSVHelper.escapeStringForCSV(cellData)}"`;
      }

      cells.push(cellData);
    });

    return cells.join(',');
  }

  public static getCSVStringsV2(rowData: Model, columns: IGridV2Column[]) {
    // tslint:disable-next-line: no-any
    const cells: any[] = [];

    columns.forEach((col) => {
      let cellData;

      if (col.exportFunction) {
        cellData = col.exportFunction(rowData);
      } else if (col.fieldName) {
        cellData = rowData[col.fieldName];
      } else {
        cellData = col.sortableValue?.(rowData);
      }

      if (cellData === undefined || cellData === 'undefined') {
        cellData = '';
      } else if (isNaN(cellData)) {
        if (typeof cellData === 'object') {
          cellData = cellData.toString();
        }
        cellData = `"${CSVHelper.escapeStringForCSV(cellData)}"`;
      }

      cells.push(cellData);
    });

    return cells.join(',');
  }
}
