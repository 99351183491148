import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import messages from '../../../../../../../App.messages';
import { PopupCard } from '../../../../../../../components/Popup';
import { mediationPopupMessages } from './DisablingMediationPopup.messages';

export interface IOptOutMediationPopupProps extends InjectedIntlProps {
  popupProps: {
    isShown: boolean;
    onDismiss: () => void;
    onPrimaryClick: () => void;
    onSecondaryClick: () => void;
  };
}

export const OptOutMediationPopup = injectIntl(function DisablingMediationComponent({
  intl: { formatMessage },
  popupProps,
}: IOptOutMediationPopupProps): JSX.Element {
  return (
    <PopupCard
      isShown={popupProps.isShown}
      popUpTitle={formatMessage(mediationPopupMessages.title)}
      popUpDescription={formatMessage(mediationPopupMessages.description)}
      primaryButtonAriaLabel={formatMessage(mediationPopupMessages.turnOff)}
      secondaryButtonAriaLabel={formatMessage(messages.cancel)}
      onPopupDismiss={() => popupProps.onDismiss()}
      onPrimaryButtonClick={() => popupProps.onPrimaryClick()}
      onSecondaryButtonClick={() => popupProps.onSecondaryClick()}
    />
  );
});
