import { action } from 'satcheljs';
import { BlockedContext } from '../store/schema/models';

export const onBlockedContextChanged = action('onBlockedContextChanged', (publisherId: number) => ({ publisherId }));

export const onBlockedAdsContextChanged = action('onBlockedAdsContextChanged', (data?: BlockedContext) => {
  return { data };
});

export const onBlockedImagesContextChanged = action('onBlockedImagesContextChanged', (data?: string[]) => ({ data }));
