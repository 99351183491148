import { object, string } from 'yup';
import { COUNTRY_WISE_ZIP_CODES_REGEX } from './zipcodes';

export const getAddressValidationModel = () =>
  object().shape({
    line1: string().required().label('Address line 1'),
    line2: string().label('Address line 2'),
    city: string().required().label('City'),
    stateOrProvince: string().required().label('State'),
    postalCode: string()
      .required()
      .label('ZIP code')
      .when('country', (country, schema) => {
        return schema.test({
          name: 'zipCode',
          message: 'Invalid ZIP code',
          test: (value) => {
            const regExp = new RegExp(COUNTRY_WISE_ZIP_CODES_REGEX[country].regex);
            if (regExp) {
              return regExp.test(value);
            }
            return true;
          },
        });
      }),
    country: string().required().label('Country'),
  });
