import { LOCAL_STORAGE_LOCALE } from '../../constants';
import { UserType } from '../store/schema';
import { getAppStore } from '../store/store';

export const isTythonSelfServeUser = () => {
  const { userContext } = getAppStore();
  if (userContext && userContext.userType && userContext.userType === UserType.TythonSelfServe) {
    return true;
  }

  return false;
};

export const isNotAuthorizedUser = () => {
  const { publisher, userContext } = getAppStore();
  return publisher === undefined && userContext?.id === undefined;
};

export const isLocalRetrieved = () => {
  return !!localStorage.getItem(LOCAL_STORAGE_LOCALE);
};

export const isInternalUser = (): boolean => {
  const store = getAppStore();
  const defaultPublisherId = store.defaultPublisher ?? 0;

  const publisherId = store.publisher?.id;

  if (publisherId && [3000, 3001, 3002, 3003, 3004, 0].includes(defaultPublisherId)) {
    return true;
  }

  return false;
};
