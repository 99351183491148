import { IUserViewModel } from '..';
import { Account, IUserRoleDetail, listEntity, Publisher, UserContext, UserRole, UserRoleEntityType } from '../../../../../@data';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';

export const fetchAccounts = async (publisher: Publisher, userContext?: UserContext): Promise<Account[]> => {
  return new Promise<Account[]>(async (resolve, reject) => {
    listEntity([publisher], userContext, Account)
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
};

export function getDistinctUserRolesFrom(current: IUserRoleDetail[], fromUserRoles: IUserRoleDetail[]): IUserRoleDetail[] {
  const distinctUserRoles: IUserRoleDetail[] = [];
  current.forEach(currentAccountRole => {
    const exists = fromUserRoles.some(newRole => isUserRoleSame(newRole, currentAccountRole));
    if (!exists) {
      distinctUserRoles.push(currentAccountRole);
    }
  });
  return distinctUserRoles;
}

export function getUserRoleDetails(active: IUserViewModel, publisherId: number): IUserRoleDetail[] {
  const userRoleDetails: IUserRoleDetail[] = [];
  if (active.accountRole) {
    userRoleDetails.push({
      entityId: publisherId,
      userRole: active.accountRole,
      entityType: UserRoleEntityType.Customer
    });

    if ([UserRole.AccountManager, UserRole.AdView, UserRole.ListManager, UserRole.ReportUser].includes(active.accountRole)) {
      let selectedAccounts: IIdNamePair[] = [];
      switch (active.accountRole) {
        case UserRole.AccountManager:
          selectedAccounts = active.AccountManagerAccountsSelected!;
          break;
        case UserRole.AdView:
          selectedAccounts = active.AdViewAccountsSelected!;
          break;
        case UserRole.ListManager:
          selectedAccounts = active.ListManagerAccountsSelected!;
          break;

        case UserRole.ReportUser:
          selectedAccounts = active.ReportUserAccounstSelected!;
          break;
        default:
          break;
      }
      selectedAccounts.forEach(selectedAccount => {
        userRoleDetails.push({
          entityId: selectedAccount.id,
          userRole: active.accountRole!,
          entityType: UserRoleEntityType.Account
        });
      });
    }
  }
  return userRoleDetails;
}

export function isDeepUserRolesEqual(a: IUserRoleDetail[], b: IUserRoleDetail[]): boolean {
  let isSame: boolean = true;
  a.forEach(eachRole => {
    if (b.some(someRole => isUserRoleDifferent(someRole, eachRole))) {
      isSame = false;
    }
  });
  return isSame;
}

function isUserRoleDifferent(a: IUserRoleDetail, b: IUserRoleDetail): boolean {
  return a.entityId !== b.entityId || a.entityType !== b.entityType || a.userRole !== b.userRole;
}

function isUserRoleSame(a: IUserRoleDetail, b: IUserRoleDetail): boolean {
  // tslint:disable:triple-equals
  // eslint-disable-next-line
  return a.entityId == b.entityId && a.entityType == b.entityType && a.userRole == b.userRole;
  // tslint:enable: triple-equals
}
