import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class AdUnit extends Model {
  public className = UserRoleEntity.AdUnit;
  public name: number;
  public id: number;
  public markeplaceClassification: number;
  public businessModel: number;
  public distributionPartnerCodes: number;
  public channelList: number;
  public servingTier: number;
  public publisherGroupConfig: number;
  public inhousePromotions: number;

  constructor(params: AdUnit) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: AdUnit, adunit: AdUnit) {
    // tslint:disable:no-bitwise
    this.accessPermission |= adunit.accessPermission;
    this.businessModel |= adunit.businessModel;
    this.channelList |= adunit.channelList;
    this.id |= adunit.id;
    this.inhousePromotions |= adunit.inhousePromotions;
    this.markeplaceClassification |= adunit.markeplaceClassification;
    this.distributionPartnerCodes |= adunit.distributionPartnerCodes;
    this.name |= adunit.name;
    this.publisherGroupConfig |= adunit.publisherGroupConfig;
    this.servingTier |= adunit.servingTier;
    // tslint:enable:no-bitwise
  }
}
