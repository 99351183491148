import { FilterType, IFilterDetails } from '../../../../../components/GridFilter/GridFilter.types';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { AdQualityFilterNames, IConditionValue } from './schema';
import { getStore } from './store';

export function getInitialFilterList(): IFilterDetails[] {
  const filterListValues: IFilterDetails[] = [];

  const filterListType = [AdQualityFilterNames.AccountName];

  const filterTextType = [
    AdQualityFilterNames.CreativeAdId,
    AdQualityFilterNames.Advertiser,
    AdQualityFilterNames.AdvertiserId,
    AdQualityFilterNames.AdvertiserAccountId,
    AdQualityFilterNames.AdId,
    AdQualityFilterNames.AdTitle,
    AdQualityFilterNames.AdURL,
    AdQualityFilterNames.LongHeadline,
    AdQualityFilterNames.ShortHeadline,
  ];

  filterListType.forEach((elem: string) => {
    const v = getStore().filters.raw?.[elem];
    if (!v?.values) {
      return;
    }
    const arr: IIdNamePair[] = v.values.filter((item: IIdNamePair) => {
      return item.isSelected;
    });

    arr.forEach((e: IIdNamePair, index: number) => {
      const item: IFilterDetails = {
        filterId: `filterList-${index}`,
        columnName: undefined,
        customFunction: undefined,
        filterCondition: '',
        filterName: elem,
        filterOptions: undefined,
        filterText: e.name,
        filterType: FilterType.string,
        includeNulls: undefined,
        showNullsCheckboxLabel: undefined,
      };
      filterListValues.push(item);
    });
  });

  filterTextType.forEach((elem: string) => {
    const v = getStore().filters.raw?.[elem];
    if (!v?.conditions) {
      return;
    }
    const arr: IConditionValue<string>[] = v.conditions.filter((item: IConditionValue<string>) => {
      return item.isApplied;
    });

    arr.forEach((e: IConditionValue<string>, index: number) => {
      const item: IFilterDetails = {
        filterId: `filterText-${index}`,
        columnName: 'id',
        customFunction: undefined,
        filterCondition: e.condition,
        filterName: elem,
        filterOptions: undefined,
        filterText: e.value,
        filterType: FilterType.string,
        includeNulls: undefined,
        showNullsCheckboxLabel: undefined,
      };
      filterListValues.push(item);
    });
  });

  return filterListValues;
}
