import { defineMessages } from 'react-intl';

export default defineMessages({
  statusCmdBtnAriaLabel: {
    id: 'gridPage.clarityStatusCmdBtnAriaLabel',
    defaultMessage: 'Click to display clarity status',
  },
  update: {
    id: 'property.update',
    defaultMessage: 'Update property',
  },
  sidePanelLabel: {
    id: 'property.sidePanelLabel',
    defaultMessage: 'Clarity',
  },
  sidePanelDescription: {
    id: 'property.sidePanelDescription',
    defaultMessage: 'To connect the Clarity project associated with property ID {id}, turn on the toggle to enable Clarity.',
  },
  sidePanelTC: {
    id: 'property.sidePanelTC',
    defaultMessage:
      'Microsoft Clarity is a free behavioral analysis tool that provides insights into how customers interact with your website. By enabling Clarity tracking, you agree to the Clarity terms of use and privacy policy. Clarity should not be used on sites that contain sensitive data, including information related to government, customer health care, or customer financial services.',
  },
  sidePanelUpdateProperty: {
    id: 'property.sidePanelUpdateProperty',
    defaultMessage: 'Update property',
  },
});
