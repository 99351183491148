import { mergeStyleSets } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/style-utilities';
import { BreakPoints, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../theme';
import { ICommonStyleProps } from '../../../../../../types';

type StyleProperties =
  | 'cardContainerStyle'
  | 'cardContentStyle'
  | 'headerStyle'
  | 'titleStyle'
  | 'timeRangeStyle'
  | 'metricsContainerStyle'
  | 'metricStyle'
  | 'iconContainerStyle'
  | 'iconStyle'
  | 'iconContentStyle'
  | 'iconTextStyle';

type PerformanceCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): PerformanceCardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { ResponsiveExtraLarge } = BreakPoints;
  return mergeStyleSets({
    cardContainerStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
      boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.2)',
      transition: '0.3s',
      borderRadius: spacing.size10,
      flex: 1,
      gridColumn: 'span 2',
      minHeight: spacing.size256,
    },
    cardContentStyle: {
      display: 'flex',
      marginLeft: spacing.size20,
      flexDirection: 'column',
      height: '100%',
    },
    headerStyle: {
      display: 'flex',
    },
    titleStyle: {
      fontSize: spacing.size16,
      fontWeight: 600,
    },
    timeRangeStyle: {
      fontSize: spacing.size12,
      fontWeight: 400,
      marginTop: spacing.size20,
      marginLeft: spacing.size10,
    },
    metricsContainerStyle: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(150px, 1fr))`,
      justifyContent: 'space-around',
      gap: spacing.size30,
      [ResponsiveExtraLarge]: {
        gap: spacing.size54,
      },
    },
    metricStyle: {
      display: 'flex',
      alignItems: 'baseline',
      flexDirection: 'column',
      minWidth: spacing.size126,
      width: spacing.size142,
      height: spacing.size52,
    },
    iconContainerStyle: {
      marginTop: 'auto',
      marginBottom: spacing.size12,
    },
    iconStyle: {
      background: 'white',
      border: 'none',
      padding: '0px',
      cursor: 'pointer',
      fontStyle: 'normal',
    },
    iconContentStyle: {
      width: 'auto',
      padding: `0px ${spacing.size12}`,
      height: spacing.size32,
      border: `${spacing.size1} solid ${palette['NeutralStroke1.Rest']}`,
      borderRadius: spacing.size4,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      ':hover': {
        backgroundColor: palette['NeutralBackground3.Rest'],
        cursor: 'pointer',
      },
    },
    iconTextStyle: {
      marginLeft: spacing.size10,
      marginBottom: spacing.size4,
      color: palette['NeutralForeground1.Rest'],
      fontSize: spacing.size14,
      fontWeight: 600,
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
