import { defineMessages } from 'react-intl';

export default defineMessages({
  userDoesNotExist: {
    id: 'InvalidUser.userDoesNotExist',
    defaultMessage: 'There is no {appName} account associated with this Microsoft Account, ',
  },
  userDoesNotExist2: {
    id: 'InvalidUser.userDoesNotExist2',
    defaultMessage: 'Please check that you are signed in with the Microsoft Account for your {appName} account.',
  },
  switchAccountLabel: {
    id: 'InvalidUser.option1',
    defaultMessage: 'Sign in with a different Microsoft Account',
  },
  newAccount: {
    id: 'InvalidUser.option2',
    defaultMessage: 'Create a new Microsoft Account',
  },
  gotoAdvertising: {
    id: 'InvalidUser.option3',
    defaultMessage: 'Go to Microsoft Advertising',
  },
  imageAlt: {
    id: 'InvalidUser.imageAlt',
    defaultMessage: 'User does not exist',
  },
  signupToPubCenter: {
    id: 'InvalidUser.signupToPubCenter',
    defaultMessage: 'Sign up to {appName}',
  },
  unauthorizedInternalUser: {
    id: 'InvalidUser.unauthorizedInternalUser',
    defaultMessage: 'You do not have access to {appName}. Please use the link below to set up your access.',
  },
  requestAccess: {
    id: 'InvalidUser.requestAccess',
    defaultMessage: 'Request access to {appName}',
  },
  signInWithMSA: {
    id: 'InvalidUser.signInWithMSA',
    defaultMessage: 'Sign in with a Microsoft Account',
  },
});
