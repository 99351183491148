import { Spinner } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useI18n } from '../../../i18n/useI18n';
import { getAppStore, isTythonSelfServeUser } from '../../@data';
import { isLocationPubCenter } from '../../utils/UrlUtils';
import { getStore } from '../ad-management/Account/Settings/@data/store/store';
import { onFetchTnC } from './@data';
import { getStore as getTnCStore } from './@data/store/store';

export const TermsNConditionsPage = observer(() => {
  const { content, nonTythonContent, isDataLoaded } = getTnCStore();
  const { userContext, publisher } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });

  const tncContentString = useMemo(() => {
    // when there is no user login, we will render TnC depends on window url
    if (!userContext && isDataLoaded) {
      return isLocationPubCenter() ? nonTythonContent : content;
    }
    // when user is logged in, we will render TnC depends on user tython self serve flag
    if (userContext && isDataLoaded) {
      return isTythonSelfServeUser() ? content : nonTythonContent;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, isDataLoaded]);

  useEffect(() => {
    onFetchTnC(locale);
  }, [locale]);

  return tncContentString ? (
    <div style={{ padding: 12, position: 'absolute' }}>
      <div style={{ padding: 12, border: '1px solid', borderRadius: 3 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: isLocationPubCenter()
              ? tncContentString
                  ?.replaceAll('Monetize Now', 'pubCenter')
                  ?.replaceAll('MONETIZE NOW', 'PUBCENTER')
                  ?.replaceAll('monetizenow', 'pubcenter')
              : tncContentString,
          }}
        />
      </div>
    </div>
  ) : (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
      <Spinner size={3} />
    </div>
  );
});
