import { MessageBarType } from '@fluentui/react';
import { Log } from '../../../../../../logging/src';
import {
  Account,
  NotificationLabel,
  NotificationSeverity,
  Publisher,
  TythonAdUnit,
  UserContext,
  buildRequestHeadersWithAuthToken,
  constructUrl,
  onNotificationCardAdded,
  onNotificationChanged,
  showErrorToastNotification,
} from '../../../../../@data';
import notificationMessages from '../../../../../NotificationsPanel.Types.messages';
import messages from '../../Manage/ManagePage.messages';
import { ITythonAdUnit } from '../CreateAdunit/types';

export const createTythonAdUnit = async (
  publisher: Publisher,
  userContext: UserContext,
  account: Account,
  adunit: ITythonAdUnit,
  updateLoadingStatus?: (isLoading: boolean) => void
): Promise<TythonAdUnit> => {
  updateLoadingStatus?.(true);
  const adUnit = new TythonAdUnit();
  adUnit.className = 'adunits';

  const body = {
    servingConfig: {
      adUnitName: adunit.name,
      adUnitId: adunit.id,
      accountId: account.id,
      propertyId: adunit.propertyId,
      adTypes: adunit.adTypes,
      nativeProperties: adunit.nativeProperties,
      servingStatus: 'Serving',
      enablebCat: false,
      bCat: [],
      xCat: [],
      enablebAdv: false,
      bAdv: [],
    },
  };

  const requestUrl = constructUrl([publisher, adUnit], undefined, undefined, undefined, 'servingConfig');
  const headers = await buildRequestHeadersWithAuthToken(requestUrl, userContext);

  try {
    const response = await fetch(requestUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers,
    });

    if (!response.ok) {
      showErrorToastNotification({ textMessageDescriptor: messages.saveAdunitFailed });
      onNotificationCardAdded({
        // tslint:disable-next-line: no-any
        severity: NotificationSeverity.Error,
        titleMessageDescriptor: notificationMessages.CreateTythonAdUnitFailedTitle,
        // TODO: Need to check scenarios for response status text from backend to be handleded in UI
        content: `Error code: ${response.status}, error status: ${response.statusText}`,
        notificationLabel: NotificationLabel.CreateTythonAdUnitFailed,
      });
      updateLoadingStatus?.(false);
      Log.error(`create ad unit failed: ${response.status} ${response.statusText}`);
      return Promise.reject();
    }

    const data: TythonAdUnit = await response.json();
    updateLoadingStatus?.(false);
    return Promise.resolve(data);
  } catch (e) {
    updateLoadingStatus?.(false);
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.createAdunitFailed,
    });
    Log.error(e);
    return Promise.reject();
  }
};
