import { defineMessages } from 'react-intl';

export const leavePagePopupMessages = defineMessages({
  title: {
    id: 'leavePagePopupMessages.title',
    defaultMessage: 'Are you sure to leave this page?',
  },
  description: {
    id: 'leavePagePopupMessages.description',
    defaultMessage: 'You haven’t finished the entire set up yet. Changes you made may not be saved.',
  },
  stayOnPage: {
    id: 'leavePagePopupMessages.submit',
    defaultMessage: 'Stay on page',
  },
  leavePage: {
    id: 'leavePagePopupMessages.cancel',
    defaultMessage: 'Leave page',
  },
});
