import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../theme';
import { ICommonStyleProps } from '../../../../../../types';

type StyleProperties = 'container';

type SetupCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SetupCardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const fonts = theme.fonts as IPubCenterFonts;

  return mergeStyleSets({
    container: {
      width: '100%',
      display: 'flex',
      boxShadow: `0px 0px ${spacing.size7} 0px rgba(0,0,0,0.2)`,
      borderRadius: spacing.size4,
      transition: 'all 0.5s',
      img: {
        marginLeft: spacing.size12,
      },
      '& i': {
        background: palette.themeLighterAlt,
        color: palette.themePrimary,
        fontSize: spacing.size20,
        padding: spacing.size24,
      },
      '& div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing.size14,
        '& span:nth-child(2)': {
          color: palette.neutralSecondary,
        },
        '& span:nth-child(1)': {
          fontSize: fonts.size16,
          fontWeight: FontWeights.semibold,
        },
      },
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
