export class AdSenseAdUnitStatus {
  public apiPath = 'preauth/mediation';
  public className = 'getAdUnitMediationStatus';
  public pubcenterAdUnitIds?: number[];
  public pubcenterAdUnitId?: number;
  public adSenseAdUnitId?: number;
  public mediationStatus?: boolean;
}

export class AdSenseConnectAdUnit extends AdSenseAdUnitStatus {
  public apiPath = 'preauth/mediation';
  public className = 'pubcenterAdUnitsIds';
}

export class AdSenseDisconnectAdUnit extends AdSenseAdUnitStatus {
  public apiPath = 'preauth/mediation';
  public className = 'pubcenterAdUnitsIds';
}

export class AdSenseUpdateAdUnitStatus extends AdSenseAdUnitStatus {
  public apiPath = 'preauth/mediation';
  public className = 'pubcenterAdUnitsIds';
}