import { mutator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { User } from '../../../../../@data';
import { mergeTypeWithObject } from '../../../../../utils';
import {
  onFetchUserInvitationsLoading,
  onFetchUsersLoading,
  onFetchingUserInvitationsData,
  onFetchingUsersData,
  onRawChanged,
  onUserChanged,
  onUserErrorChanged,
  onUserInvitationExpiryChanged,
  onUserInvitationsChanged,
  onUsersChanged,
} from '../actions';
import { getStore } from '../store/store';

mutator(onRawChanged, (msg) => {
  getStore().raw = mergeTypeWithObject(new User(), msg.value);
});

mutator(onUserChanged, (msg) => {
  getStore().active = msg.user;
});

mutator(onFetchUsersLoading, (msg) => {
  getStore().isFetchingUsersLoading = msg.isLoading;
});

mutator(onFetchingUsersData, (msg) => {
  getStore().isFetchingUsers = msg.isFetchingData;
});

mutator(onUserInvitationsChanged, (msg) => {
  getStore().userInvitations = msg.userInvitations;
});

mutator(onFetchUserInvitationsLoading, (msg) => {
  getStore().isFetchingUserInvitationsLoading = msg.isLoading;
});

mutator(onFetchingUserInvitationsData, (msg) => {
  getStore().isFetchingUserInvitations = msg.isFetchingData;
});

mutator(onUsersChanged, (msg) => {
  getStore().users = msg.users;
});

mutator(onUserErrorChanged, (msg) => {
  getStore().errors = msg.values || {};
});

mutator(onUserInvitationExpiryChanged, ({ id, value: date }) => {
  const { userInvitations } = getStore();
  const userInvitation = userInvitations?.find((ui) => ui.id === id);
  if (userInvitation) {
    userInvitation.expirationDate = date.toISOString();
    getStore().userInvitations = [...userInvitations];
    Log.info(`Updated user invitation expiry date to ${userInvitation.expirationDate}`);
  }
});
