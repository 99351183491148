import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../../theme';
import { ICommonStyleProps } from '../../../../../types';

type StyleProperties = 'cardsContainer' | 'headerSection';
type GettingStartedStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): GettingStartedStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  return mergeStyleSets({
    cardsContainer: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(${spacing.size260}, 1fr))`,
      justifyContent: 'space-between',
      gap: spacing.size15,
    },
    headerSection: {
      margin: `${spacing.size15} 0px`,
      fontSize: spacing.size20,
      fontWeight: '600',
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
