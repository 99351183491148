import { action } from 'satcheljs';
import { LifeCycleStatus, Model } from '../../../../@data';
import { DownloadOption, IGridV2Column } from '../../GridV2.types';

export const onDownloadButtonClicked = action(
  'onDownloadButtonClicked',
  (formatMessage, data: Model[], csvFileName: string, columns?: IGridV2Column[], downloadType?: DownloadOption) => ({
    formatMessage,
    data,
    csvFileName,
    columns,
    downloadType,
  })
);

export const onChangeItemsStatus = action(
  'onChangeItemsStatus',
  (
    selectedItems: Model[],
    status: LifeCycleStatus,
    data: Model[],
    formatMessage: (
      messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
      values?:
        | {
            [key: string]: ReactIntl.MessageValue;
          }
        | undefined
    ) => string,
    setData?: (data?: Model[]) => void,
    onItemsStatusChanged?: (items: Model[], status: LifeCycleStatus) => void
  ) => ({
    selectedItems,
    status,
    formatMessage,
    setData,
    data,
    onItemsStatusChanged,
  })
);

export const onDeleteItem = action(
  'onDeleteItem',
  (
    selectedItem: Model,
    data: Model[],
    formatMessage: (
      messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
      values?:
        | {
            [key: string]: ReactIntl.MessageValue;
          }
        | undefined
    ) => string,
    setData?: (data?: Model[]) => void,
    preDeleteAction?: (items: Model[]) => void,
    deleteAction?: (items: Model[]) => void,
    postDeleteAction?: (items: Model[]) => void
  ) => ({
    selectedItem,
    setData,
    data,
    formatMessage,
    preDeleteAction,
    deleteAction,
    postDeleteAction,
  })
);

export const onDuplicateItem = action(
  'onDuplicateItem',
  (
    selectedItem: Model,
    data: Model[],
    formatMessage: (
      messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
      values?:
        | {
            [key: string]: ReactIntl.MessageValue;
          }
        | undefined
    ) => string,
    setData?: (data?: Model[]) => void,
    onDuplicateAction?: (items: Model[]) => void
  ) => ({
    selectedItem,
    data,
    formatMessage,
    setData,
    onDuplicateAction,
  })
);
