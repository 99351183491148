import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class User extends Model {
  public className = UserRoleEntity.User;
  public userFirstName: number;
  public userLastName: number;
  public userEmail: number;
  public userLanguage: number;
  public userAccountRole: number;
  public userInviteFirstName: number;
  public userInviteLastName: number;
  public userInviteEmail: number;
  public userInviteLanguage: number;
  public userInviteAccountRole: number;

  constructor(params: User) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: User, user: User) {
    // tslint:disable:no-bitwise
    this.accessPermission |= user.accessPermission;
    this.userFirstName |= user.userFirstName;
    this.userLastName |= user.userLastName;
    this.userEmail |= user.userEmail;
    this.userLanguage |= user.userLanguage;
    this.userAccountRole |= user.userAccountRole;
    this.userInviteFirstName |= user.userInviteFirstName;
    this.userInviteLastName |= user.userInviteLastName;
    this.userInviteEmail |= user.userInviteEmail;
    this.userInviteLanguage |= user.userInviteLanguage;
    this.userInviteAccountRole |= user.userInviteAccountRole;
    // tslint:enable:no-bitwise
  }
}
