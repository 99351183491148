import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { AdSource, onNotificationChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import messages from '../../AdReview/AdCreativePage.messages';
import { onBlockedAdsExportClicked } from '../actions';
import { exportCSV } from '../services/utility/utility';
import { getStore } from '../store/store';

orchestrator(onBlockedAdsExportClicked, async (msg) => {
  let notification: INotificationBarItem = {};
  const adSource = getStore().active.selectedAdSource;
  const columns = getStore().columns;

  if (adSource === AdSource.Microsoft) {
    const blockSummaryForCSVFromTable = getStore().active.blockSummaryData;
    if (blockSummaryForCSVFromTable && columns) {
      exportCSV(blockSummaryForCSVFromTable, 'publisher_ad_blocks.csv', columns);

      notification = {
        textMessageDescriptor: messages.adqualityBlockedAdsDataDownloadSuccess,
        messageBarType: MessageBarType.success,
      };
      onNotificationChanged(notification);
    } else {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityNoDataToDownload,
        messageBarType: MessageBarType.severeWarning,
      });
    }
  } else {
    const blockSummaryForCSVFromTable = getStore().active.samBlockSummaryData;
    if (blockSummaryForCSVFromTable && columns) {
      exportCSV(blockSummaryForCSVFromTable, 'publisher_auction_ad_blocks.csv', columns);

      notification = {
        textMessageDescriptor: messages.adqualityBlockedAdsDataDownloadSuccess,
        messageBarType: MessageBarType.success,
      };
      onNotificationChanged(notification);
    } else {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityNoDataToDownload,
        messageBarType: MessageBarType.severeWarning,
      });
    }
  }
});
