import { mutatorAction } from 'satcheljs';
import { CustomPanelType, Model, PopupType } from '../../../../@data';
import { IGridQuery } from '../../GridV2.types';
import { getStore } from '../store/store';

export const setSearchWord = mutatorAction('setSearchWord', (word: string) => {
  getStore().searchWord = word;
});

export const setIsSearchCalloutVisible = mutatorAction('setIsSearchCalloutVisible', (isVisible: boolean) => {
  getStore().isSearchCalloutVisible = isVisible;
});

export const setData = mutatorAction('setData', (data: Model[]) => {
  getStore().data = data;
});

// tslint:disable-next-line: no-any
export const setSelectedItems = mutatorAction('setSelectedItems', (items: any[]) => {
  getStore().selectedItems = items;
});

export const setLoading = mutatorAction('setLoading', (loading: boolean) => {
  getStore().loading = loading;
});

export const setQuery = mutatorAction('setQuery', (query: IGridQuery<Model>) => {
  getStore().query = query;
});

export const setEntity = mutatorAction('setEntity', (entity: new () => Model) => {
  getStore().entity = entity;
});

export const setFilterMenuCalloutStatus = mutatorAction('setFilterMenuCalloutStatus', (isOpen: boolean) => {
  getStore().filterMenuCalloutOpen = isOpen;
});

export const setDialogType = mutatorAction('setDialogType', (dialogType: PopupType) => {
  getStore().dialogType = dialogType;
});

export const setPanelType = mutatorAction('setPanelType', (panelType: CustomPanelType) => {
  getStore().panelType = panelType;
});

export const onPageSizeChanged = mutatorAction('onPageSizeChanged', (value: number) => {
  getStore().paginator.currentPageSize = value;
  const data = getStore().data;
  if (data) {
    getStore().paginator.numberOfPages = Math.ceil(data.length / value);
  }
});

export const onPageNumberChanged = mutatorAction('onPageNumberChanged', (value: number) => {
  getStore().paginator.currentPageNumber = value;
});

export const onNumberOfPagesChanged = mutatorAction('onNumberOfPagesChanged', (value: number) => {
  getStore().paginator.numberOfPages = value;
});
