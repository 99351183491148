import { action, orchestrator } from 'satcheljs';

import {
  AdUnit,
  ClarityStatus,
  PropertyApprovalStatus,
  Publisher,
  getAppStore,
  listEntity,
  onLoadingChanged,
  showErrorToastNotification,
} from '../../../../../../../../@data';
import { FormState } from '../../../../../../../../components/Form';
import { IHierarchicalPickerItem } from '../../../../../../../../components/HierarchicalMultiSelectPicker';
import { fetchPropertyById } from '../../../../../@data/services/PropertyService';
import PropertyMessages from '../../../../../Property.messages';
import { onPropertyChanged, onPropertyErrorChanged, onRawChanged } from '../../../../__data__/mutatorActions';
import { IPropertyViewModel } from '../../../../__data__/store/schema';

export const onFormInitialized = action(
  'onFormInitialized',
  (mode: FormState, publisherId: string, accountId?: string, entityId?: string) => ({
    mode,
    publisherId,
    accountId,
    entityId,
  })
);

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();

  const property: IPropertyViewModel = {};
  property.clarity = {};

  const publisherId = parseInt(msg.publisherId, 10);
  if (!isNaN(publisherId)) {
    const publisher = new Publisher(publisherId);
    try {
      onPropertyErrorChanged();
      onLoadingChanged(true);

      if (msg.mode === FormState.Edit && msg.entityId) {
        const rawProperty = await fetchPropertyById(publisher, msg.entityId, userContext);
        property.id = rawProperty.id;
        property.name = String(rawProperty.name);
        property.url = rawProperty.url;
        property.approvalStatusComments = rawProperty.approvalStatusComments;
        property.approvalStatus = rawProperty.approvalStatus;

        const adunits = await listEntity([publisher], userContext, AdUnit, new URLSearchParams([['extendedInfo', 'true']]));
        property.adunits = adunits.map(
          (a) => ({ id: a.id, name: a.name, parentId: a.accountId, parentName: a.accountName } as IHierarchicalPickerItem)
        );

        property.adunitsSelected = rawProperty.associatedAdUnitIds
          ? property.adunits.filter((f) => f.id && rawProperty.associatedAdUnitIds!.includes(f.id))
          : [];

        property.clarity.clarityId = rawProperty.clarityProjectId;
        property.clarity.originalClarityId = rawProperty.clarityProjectId;
        if (rawProperty.clarityStatus) {
          const status: ClarityStatus = ClarityStatus[rawProperty.clarityStatus];
          property.clarity.clarityLinkingEnabled = status === ClarityStatus.NotConnected ? false : true;
          property.clarity.clarityStatus = status || ClarityStatus.Approved;
          property.clarity.clarityEdit = status === ClarityStatus.Approved || status === undefined ? false : true;
        }

        onRawChanged(rawProperty);
      } else {
        onRawChanged();
        property.approvalStatus = PropertyApprovalStatus.Approved;
      }
      onPropertyChanged(property);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: PropertyMessages.propertyLoadError });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    showErrorToastNotification({
      textMessageDescriptor: PropertyMessages.invalidState,
    });
  }
});
