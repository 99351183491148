export enum PublisherGroupConfig {
  DefaultPlus = 'DefaultPlus',
  Default = 'Default',
  Generalist = 'Generalist',
  YieldOptimize = 'YieldOptimize',
  YieldMaximize = 'YieldMaximize',
  BingYahooOAndO = 'BingYahooOAndO',
  LowQualityDistribution = 'LowQualityDistribution',
  MediumQualityDistribution = 'MediumQualityDistribution',
  HighQualityDistribution = 'HighQualityDistribution',
}
