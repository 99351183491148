import { Icon, Image, Shimmer } from '@fluentui/react';
import { HorizontalBarChart } from '@fluentui/react-charting';
import React from 'react';
import { useI18n } from '../../../../../../../i18n/useI18n';
import { getAppStore } from '../../../../../../@data';
import { injectRouterAndIntlWithObserver } from '../../../../../../utils';
import { getTythonPaymentPageRoute } from '../../../../../../utils/routeUtils';
import { getPaymentsPageBalance } from '../../../../Account/@data/store/selectors';
import { getStore } from '../../../../Account/Settings/@data/store/store';
import balanceImg from '../resources/BalanceImg.svg';
import paymentImg from '../resources/Payment.svg';
import { getBarChartCustomData, mapToBarChartData } from '../utils/balanceHelper';
import { getBalanceHorizontalBarShimmer, getBalanceValueShimmer } from '../utils/getBalanceShimmers';
import { getClassNames } from './BalanceCard.styles';
import messages from './messages';

const CURRENCY = 'USD';

const BalanceCard = injectRouterAndIntlWithObserver(({ intl: { formatMessage }, history }) => {
  const { data, isDataLoaded } = getPaymentsPageBalance();
  const {
    cardContainerStyle,
    contentStyle,
    balanceImageStyle,
    balanceStyle,
    balanceTextStyle,
    currentBalanceStyle,
    lastPaymentInfoStyle,
    imageStyle,
    barChartSectionStyle,
    currentBalanceInfoStyle,
    currentBalanceInfoTextStyle,
    iconSectionStyle,
    iconStyle,
    iconContentStyle,
    iconTextStyle,
  } = getClassNames();

  const { publisher, userContext } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });

  const currentBalance = Number(data?.currBalance).toLocaleString(locale, { style: 'currency', currency: CURRENCY });

  const styles = {
    chartTitle: {
      justifyContent: 'flex-end',
    },
    items: {
      marginBottom: '3px',
    },
  };

  function handleViewPaymentButton() {
    history.push(getTythonPaymentPageRoute());
  }

  return (
    <>
      <div className={cardContainerStyle}>
        <div className={contentStyle}>
          <div className={balanceImageStyle}>
            <div className={balanceStyle}>
              <h3 className={balanceTextStyle}>{formatMessage(messages.balanceCardTitle)}</h3>
              {isDataLoaded ? (
                <>
                  <p className={currentBalanceStyle}>{currentBalance}</p>
                  <p className={lastPaymentInfoStyle}>{formatMessage(messages.lastEarned)}</p>
                </>
              ) : (
                <Shimmer customElementsGroup={getBalanceValueShimmer()} />
              )}
            </div>
            <div className={imageStyle}>
              <Image src={balanceImg} alt={formatMessage(messages.balanceImage)} />
            </div>
          </div>
          <div className={barChartSectionStyle}>
            {isDataLoaded ? (
              <>
                <HorizontalBarChart
                  barHeight={15}
                  data={mapToBarChartData(data)}
                  barChartCustomData={getBarChartCustomData(formatMessage(messages.payout))}
                  styles={{ ...styles }}
                  hideTooltip
                />
                <div className={currentBalanceInfoStyle}>
                  <span className={currentBalanceInfoTextStyle}>{`${currentBalance} / $${data?.threshold}`}</span>
                </div>
              </>
            ) : (
              <Shimmer customElementsGroup={getBalanceHorizontalBarShimmer()} />
            )}
          </div>

          <div className={iconSectionStyle}>
            <Icon className={iconStyle} onClick={() => handleViewPaymentButton()}>
              <div className={iconContentStyle}>
                <Image src={paymentImg} />
                <span className={iconTextStyle}>{formatMessage(messages.viewPayment)}</span>
              </div>
            </Icon>
          </div>
        </div>
      </div>
    </>
  );
});

export default BalanceCard;
