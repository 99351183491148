import { action, orchestrator } from 'satcheljs';
import {
  ApiError,
  ClarityStatus,
  Property,
  getAppStore,
  onLoadingChanged,
  patchEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../../@data';
import { UpdateClarityProject } from '../../../../../../@data/store/schema/models/UpdateClarityProject';
import { onSettingClarityErrorMessage } from '../../../Create/__data__/mutatorActions';
import { getCreatePropertyStore } from '../../../Create/__data__/store/store';
import PropertyMessages from '../../../Property.messages';
import { ClarityErrors } from '../../components/ClarityPanel/types';
import { setClarityPanelVisibility, setPropertyData } from '../mutatorActions';
import { getGridPropertyStore } from '../store/store';

export const onClarityUpdate = action('onClarityUpdate');

orchestrator(onClarityUpdate, async () => {
  const { publisher, userContext } = getAppStore();
  const {
    active: { clarity },
  } = getCreatePropertyStore();
  const { data, selectedPropertyId: propertyId } = getGridPropertyStore();

  if (publisher && propertyId) {
    try {
      onLoadingChanged(true);

      const body: UpdateClarityProject = {
        apiPath: undefined,
        className: 'updateClarityProject',
        propertyId: propertyId.toString(),
        clarityProjectId: clarity?.clarityId,
        enableMicrosoftClarity: clarity?.clarityLinkingEnabled,
      };

      await patchEntity([publisher, new Property()], UpdateClarityProject, body, userContext);

      if (data) {
        const updatedProperty = { ...data.find((property) => property.id.toString() === propertyId) };

        if (updatedProperty) {
          if (clarity?.clarityLinkingEnabled === true) {
            showSuccessToastNotification({
              textMessageDescriptor: PropertyMessages.clarityLinkSuccess,
            });
            updatedProperty.clarityStatus = ClarityStatus.Pending;
            updatedProperty.clarityProjectId = clarity?.clarityId;
          } else {
            showSuccessToastNotification({
              textMessageDescriptor: PropertyMessages.clarityUnlinkSuccess,
            });
            updatedProperty.clarityProjectId = undefined;
            updatedProperty.clarityStatus = ClarityStatus.NotConnected;
          }
        }

        const updatedProperties: Property[] = data.map((property) => {
          if (property.id.toString() === propertyId) {
            return updatedProperty as Property;
          } else {
            return property as Property;
          }
        });

        setPropertyData(updatedProperties);
        setClarityPanelVisibility(false);
      }
    } catch (e) {
      const apiErrorObj = e as ApiError;
      if (apiErrorObj && apiErrorObj.code === 404) {
        onSettingClarityErrorMessage(JSON.parse(apiErrorObj.message)[0].description);
      } else if (apiErrorObj && apiErrorObj.code === 400) {
        const error = ClarityErrors[JSON.parse(apiErrorObj.message)[0].code];
        if (error === ClarityErrors.ClarityProjectNotLinked || error === ClarityErrors.ClarityProjectUnlinkingFailed) {
          showErrorToastNotification({ text: JSON.parse(apiErrorObj.message)[0].description });
          setClarityPanelVisibility(false);
        } else {
          onSettingClarityErrorMessage(JSON.parse(apiErrorObj.message)[0].description);
        }
      } else {
        // Add check for link or unlink request
        showErrorToastNotification({
          textMessageDescriptor: PropertyMessages.clarityLinkFailed,
          intlMessageValues: { projectId: clarity?.clarityId },
        });
      }
    } finally {
      onLoadingChanged(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: PropertyMessages.invalidState });
  }
});
