import { getTheme, MessageBar, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import { IChartDataPoint, IChartProps } from '@fluentui/react-charting';
import React from 'react';
import { IPubcenterPalette, IPubcenterTheme } from '../../../../../../theme';
import { PaymentsPageBalance } from '../../../../../@data';
import { roundNumber } from '../../../../../utils';
import { getClassNames } from './Balance.styles';

export const getChartShimmerCustomElements = () => {
  return (
    <div>
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
        ]}
      />
    </div>
  );
};

export function mapToBarChartData(data?: PaymentsPageBalance): IChartProps[] {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const palette = theme.palette as IPubcenterPalette;

  const chartData: IChartProps[] = [
    {
      chartTitle: 'Balance',
      chartData: [
        {
          legend: 'Balance',
          horizontalBarChartdata: {
            x: data ? (roundNumber(data.currBalance, 2) === 0 ? 0.1 : roundNumber(data.currBalance, 2)) : 0,
            y: data ? data.threshold : 0,
          },
          color: data && roundNumber(data.currBalance, 2) === 0 ? palette.white : palette.blue,
          yAxisCalloutData: `${data ? data.currBalance : 0}$`,
        },
      ],
    },
  ];
  return chartData;
}

export function getBarChartCustomData(currencyCode: string, locale: string) {
  return (props: IChartProps) => {
    const chartData: IChartDataPoint = props!.chartData![0];
    const y = chartData.horizontalBarChartdata!.y;
    return (
      <div>
        <span>{`${formatByCurrencyAndLocale(y, locale, currencyCode)} ${currencyCode}`} </span>
      </div>
    );
  };
}
export function getFooterNotification(message: string): React.ReactNode {
  const { notificationMessage } = getClassNames();
  return <MessageBar className={notificationMessage}>{message}</MessageBar>;
}

export function formatByCurrencyAndLocale(paymentThreshold: number, sysLocale: string, sysCurrency: string) {
  return paymentThreshold.toLocaleString(sysLocale, { style: 'currency', currency: sysCurrency });
}
