import { FontWeights, getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubcenterTheme } from '../../../theme';
import { HEADING_ITEM_HEIGHT, STANDARD_LIST_ITEM_HEIGHT } from '../../constants/StyleConstants';
import { flexWithAlignCenter } from '../../utils/GlobalStyleUtils';

export const BOX_HEIGHT = 288;
export const BOX_WIDTH = 300;

// NOTE: Keep the style types in the same file where the style definitions are present.
// This helps in better DX (Developer Experience) as we needn't switch files to see what's the type.
export interface ICheckboxTreeWithSelectionBoxStyleProps {
  theme: ITheme;
  isError?: boolean;
}

type StyleProperties =
  | 'containerStyle'
  | 'selectionBoxContainerStyle'
  | 'titleContainerStyle'
  | 'selectedItemsContainerStyle'
  | 'selectedItemStyle'
  | 'disabledItemStyle'
  | 'checkboxTreeContainerStyle'
  | 'errorMessageStyle'
  | 'rootStyle'
  | 'removeAllButtonStyle';
type CheckboxTreeWithSelectionBoxStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICheckboxTreeWithSelectionBoxStyleProps): CheckboxTreeWithSelectionBoxStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette, fonts } = theme;
  const { isError = false } = props;
  const containerBorderStyle = {
    border: `1px solid ${isError ? palette.error : palette.neutralTertiaryAlt}`,
  };
  const { ResponsiveSmall } = BreakPoints;

  return mergeStyleSets({
    rootStyle: {
      marginBottom: spacing.size8,
    },
    containerStyle: {
      display: 'flex',
      gap: spacing.size8,
      [ResponsiveSmall]: {
        display: 'block',
      },
    },
    checkboxTreeContainerStyle: {
      ...containerBorderStyle,
      [ResponsiveSmall]: {
        width: '100%',
      },
    },
    selectionBoxContainerStyle: {
      ...containerBorderStyle,
      height: BOX_HEIGHT,
      width: BOX_WIDTH,
      [ResponsiveSmall]: {
        width: '100%',
      },
    },
    selectedItemsContainerStyle: {
      paddingLeft: spacing.size12,
      overflowY: 'auto',
      height: BOX_HEIGHT - HEADING_ITEM_HEIGHT,
    },
    titleContainerStyle: {
      ...flexWithAlignCenter,
      justifyContent: 'space-between',
      ...fonts.size14,
      fontWeight: FontWeights.semibold,
      height: HEADING_ITEM_HEIGHT,
      paddingLeft: spacing.size12,
      borderBottom: `1px solid ${palette.neutralQuaternaryAlt}`,
    },
    disabledItemStyle: {
      color: palette.disabledGray,
      opacity: 1,
      pointerEvents: 'none',
      backgroundColor: palette.white,
    },
    selectedItemStyle: {
      ...fonts.size14,
      ...flexWithAlignCenter,
      justifyContent: 'space-between',
      height: STANDARD_LIST_ITEM_HEIGHT,
    },
    removeAllButtonStyle: {
      color: palette.themePrimary,
    },
    errorMessageStyle: {
      ...fonts.size12,
      marginTop: spacing.size2,
      color: palette.redDark,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): CheckboxTreeWithSelectionBoxStyles {
  return getStyles({ theme: getTheme(), ...props });
}
