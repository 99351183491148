import { defineMessages } from 'react-intl';

export default defineMessages({
  noCodeFound: {
    id: 'nativeAd.scriptPage.noCodeFound',
    defaultMessage: 'No code found',
  },
  setUpTemplate: {
    id: 'nativeAd.scriptPage.setUpTemplate',
    defaultMessage: 'Set up template',
  },
  noScriptDesc: {
    id: 'nativeAd.scriptPage.noScriptDesc',
    defaultMessage: `Seems like you haven't configured template for this ad unit yet, click on the button to set it up first.`,
  },
});
