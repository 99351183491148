import React from 'react';
import EntityCreationLayout, { EntityTypeCreationType } from '../../../../layout/EntityCreationLayout';

const InventoryTypePage = () => {
  // TODO: enhance injectRouterWithIntl type to work with functional components
  // @ts-ignore
  return <EntityCreationLayout entityType={EntityTypeCreationType.Inventory} />;
};

export default InventoryTypePage;
