import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import {
  Account,
  ApiError,
  Channel,
  getAppStore,
  listEntity,
  onAuthLogout,
  onLoadingChanged,
  onNotificationChanged,
} from '../../../../../@data';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import messages from '../../AdReview/AdCreativePage.messages';
import {
  onBlockAdsChannelsAndRevenueChanged,
  onBlockAdsChannelsChanged,
  onBlockAdsListingsChanged,
  onBlockAdsRevenueChanged,
} from '../actions';
import { getAdIdDetails, getAdIdRevenue } from '../services/AdCreativeService';
import { convertDateToString } from '../services/utility/utility';
import { getStore } from '../store/store';

orchestrator(onBlockAdsChannelsAndRevenueChanged, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { adPerfData, endDate, startDate } = getStore().active;
  const selectedAds = msg.value;

  const handleAPIRequestsAndResponses: Promise<void>[] = [];
  selectedAds.forEach((item, index) => {
    onLoadingChanged(true);
    if (publisher && item.accountId && !getStore().blockAdsPage.channels[item.accountId]) {
      const account = new Account(item.accountId);
      const processChannelsResponse = listEntity([publisher, account], userContext, Channel).then((response) => {
        const channels = response ? response.map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];

        if (channels) {
          channels.forEach((channel) => {
            if (channel.name.toString().toUpperCase() === 'Default'.toUpperCase()) {
              onBlockAdsChannelsChanged(item.accountId!, channel.id);
            }
          });
        }
      });

      handleAPIRequestsAndResponses.push(processChannelsResponse);
    } else {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityInvalidState,
        messageBarType: MessageBarType.severeWarning,
      });
    }
    if (publisher && publisher.id && item.adId && adPerfData && adPerfData.length > 0) {
      const today = new Date(Date.now());
      const startDateRevenue = new Date().setDate(today.getDate() - 7);
      const endDateRevenue = new Date().setDate(today.getDate());

      const paramsLastSevenDayRevenue = new URLSearchParams([
        ['startdate', convertDateToString(new Date(startDateRevenue))],
        ['enddate', convertDateToString(new Date(endDateRevenue))],
        ['targetCustomerId', publisher.id.toString()],
      ]);

      const processAdidRevenueResponse = getAdIdRevenue(
        [
          ['publisher', publisher.id!.toString()],
          ['adrevenue', item.adId.toString()],
        ],
        paramsLastSevenDayRevenue
      )
        .then((fetchedRevenue) => {
          if (isNaN(fetchedRevenue)) {
            fetchedRevenue = 0;
          }
          onBlockAdsRevenueChanged(fetchedRevenue, index);
        })
        .catch((e) => {
          onNotificationChanged({
            textMessageDescriptor: messages.adqualityLoadRevenueAdDataFailed,
            messageBarType: MessageBarType.severeWarning,
          });
        });

      handleAPIRequestsAndResponses.push(processAdidRevenueResponse);

      const startDateDetails = startDate ? startDate : new Date(new Date().setDate(today.getDate() - 1));
      const endDateDetails = endDate ? endDate : new Date(new Date().setDate(today.getDate()));

      const paramsAdDetails = new URLSearchParams([
        ['startdate', convertDateToString(startDateDetails)],
        ['enddate', convertDateToString(endDateDetails)],
        ['targetCustomerId', publisher.id.toString()],
      ]);
      const processAdidDetailsRequest = getAdIdDetails(
        [
          ['account', item.accountId!.toString()],
          ['addetails', item.adId.toString()],
        ],
        paramsAdDetails
      )
        .then((fetchedAdDetails) => {
          onBlockAdsListingsChanged(fetchedAdDetails, index);
        })
        .catch((e) => {
          const apiErrorObj = e as ApiError;
          if (apiErrorObj && apiErrorObj.code === 401) {
            Log.info(apiErrorObj.message);
            onAuthLogout();
          } else {
            onNotificationChanged({
              textMessageDescriptor: messages.adqualityLoadListingAdDataFailed,
              messageBarType: MessageBarType.severeWarning,
            });
          }
        });

      handleAPIRequestsAndResponses.push(processAdidDetailsRequest);
    } else if (publisher && publisher.id && item.adId && adPerfData) {
      onBlockAdsListingsChanged({ adListingItems: [], listingsCount: 0, adListingSelectedPage: 0 }, index);
      onBlockAdsRevenueChanged(item.revenue ? item.revenue : 0, index);
    } else {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityInvalidState,
        messageBarType: MessageBarType.severeWarning,
      });
    }
  });

  await Promise.all(handleAPIRequestsAndResponses);
  onLoadingChanged(false);
});
