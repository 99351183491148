import { classNamesFunction, getId } from '@fluentui/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useWindowSize } from '../../../hooks';
import { getStore } from '../../../hooks/useWindowSize/store/store';
import { getScreenReaderTextStyles } from '../../../utils/GlobalStyleUtils';
import { IFormItemProps, IFormItemStyleProps, IFormItemStyles } from './FormItem.types';

const getFormClassNames = classNamesFunction<IFormItemStyleProps, IFormItemStyles>();

// Usage: Can be used for presentation to provide consistent gap between multiple form elements.
// If it's used to wrap multiple elements within a form, role and aria props can be set appropriately

const { windowStoreSerializer } = useWindowSize();

export const FormItemBase = observer((props: IFormItemProps) => {
  const { isWindowSmallSize } = windowStoreSerializer(getStore());
  const {
    id,
    formWidth = isWindowSmallSize ? 324 : 440,
    theme,
    styles,
    role,
    ariaLabel,
    ariaLabelledBy,
    ariaDescription,
    ariaDescribedBy,
  } = props;
  const formClassNames = getFormClassNames(styles, { theme: theme!, formWidth });
  const descId = id ? `${id}desc` : getId('FormItemDesc');

  return (
    <div
      id={id}
      className={formClassNames.root}
      role={role ? role : 'presentation'}
      aria-label={ariaLabel ? ariaLabel : undefined}
      aria-labelledby={ariaLabelledBy ? ariaLabelledBy : undefined}
      aria-describedby={ariaDescribedBy ? ariaDescribedBy : ariaDescription ? descId : undefined}
    >
      {props.children}
      {ariaDescription ? (
        <span id={descId} style={getScreenReaderTextStyles()}>
          {ariaDescription}
        </span>
      ) : null}
    </div>
  );
});
