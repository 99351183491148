import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import {
  getCurrentPublisherId,
  getPublisherCountry,
  isPublisherProfileDataPresent,
  onLoadingChanged,
  showErrorToastNotification,
} from '../../../../../@data';
import { fetchStatesOfCountry } from '../../../../../@data/@metadata/actions';
import { isStatesPresentForCountry } from '../../../../../@data/@metadata/store/selectors';
import { getPrimaryUserId } from '../../../../../@data/store';
import messages from '../../ProfilePage.messages';
import { editProfileAction, fetchPublisherProfileDetailsAction, fetchUserDetailsAction } from '../actions';
import { initializeProfileActiveStateFromRawDataAction, setProfileEditModeAction } from '../actions/mutatorActions';
import { isPrimaryUserDataPresent } from '../store/selectors';

orchestrator(editProfileAction, async () => {
  try {
    onLoadingChanged(true);

    when(
      () => {
        const publisherCountry = getPublisherCountry();
        return !!publisherCountry && !isStatesPresentForCountry(publisherCountry);
      },
      () => fetchStatesOfCountry(getPublisherCountry()!)
    );

    const primaryUserId = getPrimaryUserId();
    if (primaryUserId) {
      fetchUserDetailsAction(primaryUserId);
    }

    fetchPublisherProfileDetailsAction(getCurrentPublisherId());

    await when(() => {
      const publisherCountry = getPublisherCountry();
      return (
        isPublisherProfileDataPresent() && isPrimaryUserDataPresent() && !!publisherCountry && isStatesPresentForCountry(publisherCountry)
      );
    });

    initializeProfileActiveStateFromRawDataAction();
    setProfileEditModeAction(true);
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadProfileDetails });
    Log.error(`Failed to load profile details: ${error.message}`);
  } finally {
    onLoadingChanged(false);
  }
});
