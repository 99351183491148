import { mutatorAction } from 'satcheljs';
import {
  IToastNotificationItem,
  IToastNotificationItemContent,
  ToastType,
} from '../../components/ToastNotification/ToastNotification.types';
import { getAppStore } from '../store/store';

export const showSuccessToastNotification = mutatorAction('showSuccessToastNotification', (content: IToastNotificationItemContent) =>
  appendNewNotification(ToastType.success, content)
);

export const showInfoToastNotification = mutatorAction('showInfoToastNotification', (content: IToastNotificationItemContent) =>
  appendNewNotification(ToastType.info, content)
);

export const showWarningToastNotification = mutatorAction('showWarningToastNotification', (content: IToastNotificationItemContent) =>
  appendNewNotification(ToastType.warning, content)
);

export const showErrorToastNotification = mutatorAction('showErrorToastNotification', (content: IToastNotificationItemContent) =>
  appendNewNotification(ToastType.error, content)
);

export const showLoadingToastNotification = mutatorAction('showLoadingToastNotification', (content: IToastNotificationItemContent) => {
  if (!content.onDismiss) {
    throw new Error('onDismiss should be provided');
  }
  content.suppressAutoDismiss = true;
  content.noDismissButton = true;
  appendNewNotification(ToastType.loading, content);
});

export const removeToastNotification = mutatorAction('removeToastNotification', (notification: IToastNotificationItem) => {
  const store = getAppStore();
  const notifications = store.toastNotifications || [];
  const indexOfNotification = notifications.findIndex((item) => item.id === notification.id);
  store.toastNotifications = [...notifications.slice(0, indexOfNotification), ...notifications.slice(indexOfNotification + 1)];
});

const appendNewNotification = (type: ToastType, content: IToastNotificationItemContent) => {
  const store = getAppStore();
  const notifications = store.toastNotifications || [];
  store.toastNotifications = [
    ...notifications,
    {
      // tslint:disable-next-line: no-any
      id: (crypto as any).randomUUID(),
      type,
      content,
    },
  ];
};
