import { action } from 'satcheljs';

export const onAcceptInvitePageInitialized = action(
  'onAcceptInvitePageInitialized',
  (invitationCode: string, invitationLocale: string) => ({ invitationCode, invitationLocale })
);

export const onAcceptInvitePageSubmitted = action('onAcceptInvitePageSubmitted');

export const validateUserInvitationCode = action('validateUserInvitationCode', (invitationCode: string) => ({ invitationCode }));
