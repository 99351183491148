import { Icon, Shimmer, TooltipHost } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { injectIntlAndObserver } from '../../../../../../utils';
import { getPerformanceShimmer } from '../utils/getPerformanceShimmer';
import { getClassNames } from './MetricItem.styles';

export interface IMetricItemProps extends InjectedIntlProps {
  title: string;
  dataValue: string;
  isDataLoaded?: boolean;
}

const MetricItem = injectIntlAndObserver<IMetricItemProps>((props: IMetricItemProps) => {
  const { titleStyle, valueStyle } = getClassNames();
  return (
    <>
      <span className={titleStyle}>
        {props.title}
        {props.title.length === 0 ? (
          ''
        ) : (
          <TooltipHost content="description">
            <Icon iconName="info" style={{ display: 'none', marginLeft: '5px' }} />
          </TooltipHost>
        )}
      </span>
      {props.isDataLoaded ? (
        <p className={valueStyle}>{props.dataValue ? props.dataValue : '--'}</p>
      ) : (
        <Shimmer customElementsGroup={getPerformanceShimmer()} />
      )}
    </>
  );
});

export default MetricItem;
