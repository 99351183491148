import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { getAppStore } from '../../../../../@data';
import { DetailPage } from '../../../../../layout/DetailPage/DetailPage';
import { injectIntlAndObserver } from '../../../../../utils';
import AppAdUnitPrimaryForm from '../components/AppAdUnitPrimaryForm';
import AppAdUnitResultSummary from '../components/AppAdUnitResultSummary';
import { ICreateTythonAppAdunitProps } from './types';
import useCreateTythonAppAdUnit from './useCreateTythonAppAdUnit';

const CreateTythonAppAdUnit = injectIntlAndObserver<InjectedIntlProps & ICreateTythonAppAdunitProps>(
  ({ intl: { formatMessage }, mode, routeProps }) => {
    const appStore = getAppStore();
    const { detailPageProps, primaryFormProps, resultSummaryProps } = useCreateTythonAppAdUnit({
      mode,
      formatMessage,
      appStore,
      routeProps,
    });

    return (
      <DetailPage {...detailPageProps}>
        <FluentProvider theme={webLightTheme}>
          <AppAdUnitPrimaryForm {...primaryFormProps} />
          <AppAdUnitResultSummary {...resultSummaryProps} />
        </FluentProvider>
      </DetailPage>
    );
  }
);

export default CreateTythonAppAdUnit;
