import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../logging/src';
import { onAccountSelected, onPublisherAccountChanged, onPublisherSelected } from '../actions';
import '../mutators';
import '../orchestrators';
import { setUserRoles } from '../services/UserRoleService';
import { UserContext } from '../store/schema';
import { getAppStore } from '../store/store';
import { isTythonSelfServeUser } from '../utils/checkUserType';

orchestrator(onPublisherAccountChanged, async (msg) => {
  const store = getAppStore();
  if (isTythonSelfServeUser() && !store.publisher && msg.publisherId === '0') {
    /** DefaultPublisherId is not set when scope call had been failed/skipped,
     * as Publisher and Accounts are not set, entities on homepage won't load.
     * If scope call succeeds, page will load, else user will be logged out. */
    const userContext = store.userContext || new UserContext();

    Log.info('Fetching user scope again');
    await setUserRoles(userContext);
    when(
      () => store.defaultPublisher !== 0,
      () => {
        const publisherId = store.defaultPublisher ? store.defaultPublisher.toString() : '0';
        onPublisherSelected(publisherId, msg.routeHistory);
      }
    );
  } else {
    onPublisherSelected(msg.publisherId, msg.routeHistory);
    onAccountSelected(msg.accountId);
  }
});
