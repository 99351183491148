export enum MediaType {
  PaidSearch = 1,
  Contextual = 2,
  MobileSearch = 3,
  MobileContextual = 4,
  Display = 5,
  WindowsApp = 6,
}

export const MediaTypeString = {
  PaidSearch: 'PaidSearch',
  MobilePaidSearch: 'MobilePaidSearch',
  Native: 'Native',
  AudienceAds: 'AudienceAds',
};
