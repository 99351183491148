import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../theme';
import { ICommonStyleProps } from '../types';

type StyleProperties = 'panelTitleStyle' | 'panelFooterButtonsWrapper' | 'panelDescription';
type SettingsPageStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SettingsPageStyles => {
  const { palette } = props.theme;
  const spacing = props.theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    panelTitleStyle: {
      marginTop: spacing.size16,
    },
    panelFooterButtonsWrapper: {
      display: 'flex',
      gap: spacing.size8,
    },
    panelDescription: {
      padding: `${spacing.size16} 0`,
      color: palette.neutralSecondary,
    },
  });
};

export function getPanelClassNames<PropType>(props?: PropType): SettingsPageStyles {
  return getStyles({ theme: getTheme(), ...props });
}
