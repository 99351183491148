import { TextField } from '@fluentui/react';
import * as React from 'react';
import { getAppStore, onNavigateBack } from '../../../../@data';
import { completeAccess, hasUpdateAccess, UserRoleEntity, userRoleKey } from '../../../../@data/services/UserRoleService';
import { Channel as ChannelAccessPermissions } from '../../../../@data/store/schema/userrolemodels/Channel';
import { FormItem, FormState } from '../../../../components/Form';
import { MultiSelectPicker } from '../../../../components/MultiSelectPicker';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { injectIntlWithObserver } from '../../../../utils/ReactUtils';
import { RouteName } from '../../@data';
import { createReturnUrl } from '../../@data/services/AdManagementService';
import { getStore } from '../@data';
import {
  onFormAdunitsChanged,
  onFormCancelled,
  onFormChannelNameChanged,
  onFormFiltersChanged,
  onFormInitialized,
  onFormSubmitted,
} from '../@data/actions';
import messages from './CreateChannel.messages';
import { ICreateChannelProps, IFormInitializationState } from './CreateChannel.types';

export const CreateChannel = injectIntlWithObserver(
  class CreateChannelPage extends React.Component<ICreateChannelProps, IFormInitializationState> {
    constructor(props: ICreateChannelProps) {
      super(props);
      this.state = {
        initializeForm: true,
      };
    }

    public componentDidMount() {
      const publisherId = this.props.match.params.publisherId;
      const accountId = this.props.match.params.accountId;
      const entityId = this.props.match.params.id;
      if (publisherId && entityId && accountId === undefined && this.props.location.pathname.includes('adunit/edit')) {
        onFormInitialized(FormState.InPageCreate, publisherId, '0', entityId);
      }

      this.setState({ id: entityId });
      if (publisherId && accountId) {
        onFormInitialized(this.props.mode, publisherId, accountId, entityId);
      }
    }

    public componentDidUpdate() {
      const publisherId = this.props.match.params.publisherId;
      const accountId = this.props.match.params.accountId;
      const entityId = this.props.match.params.id;
      if (this.state.initializeForm && this.state.id !== entityId) {
        this.setState({ id: entityId });
        if (publisherId && accountId) {
          onFormInitialized(this.props.mode, publisherId, accountId, entityId);
        }
      }
    }

    public render() {
      const { formatMessage } = this.props.intl;
      const store = getStore();
      const { saving, loading } = getAppStore();
      const publisherId = this.props.match.params.publisherId;
      const accountId = this.props.match.params.accountId;

      const channelAccessPermissions = this._getChannelAccessPermission();

      return (
        <DetailPage
          titleLabel={this.props.mode === FormState.Edit ? formatMessage(messages.editChannel) : formatMessage(messages.createChannel)}
          helpId={'pop_PCv4_ChannelsCreate'}
          submitLabel={formatMessage(this.props.mode === FormState.Edit ? messages.updateChannel : messages.createChannel)}
          onSubmit={() => this._onSubmit(publisherId, accountId)}
          onCancel={() => this._onCancel(publisherId, accountId)}
          onNavClick={() => this._onCancel(publisherId, accountId)}
          saving={saving}
          loading={loading}
          pageMode={this.props.mode}
        >
          <FormItem formWidth={440}>
            <TextField
              label={formatMessage(messages.inputChannelName)}
              errorMessage={store.errors.name}
              required={true}
              value={store.active.name}
              onChange={(_, value) => onFormChannelNameChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                channelAccessPermissions === null ? completeAccess : channelAccessPermissions.name
              )}
            />
          </FormItem>

          {store.active.id && (
            <FormItem formWidth={440}>
              <TextField label={formatMessage(messages.channelId)} required={true} disabled={true} value={String(store.active.id)} />
            </FormItem>
          )}

          <FormItem formWidth={580}>
            <MultiSelectPicker
              {...this.props}
              label={formatMessage(messages.inputAdunits)}
              ariaDescription={formatMessage(messages.adunitPickerAriaDesc)}
              required={true}
              errorMessage={store.errors.adunitsSelected}
              items={store.active.adunits || []}
              initialSelectedItems={store.active.adunitsSelected}
              onSelectedItems={(items) => onFormAdunitsChanged(items)}
              disabled={this._isDisabled(
                this.props.mode,
                channelAccessPermissions === null ? completeAccess : channelAccessPermissions.adUnitList
              )}
            />
          </FormItem>

          <FormItem formWidth={600}>
            <MultiSelectPicker
              {...this.props}
              label={formatMessage(messages.inputFilters)}
              ariaDescription={formatMessage(messages.filterPickerAriaDesc)}
              errorMessage={store.errors.filtersSelected}
              items={store.active.filters || []}
              initialSelectedItems={store.active.filtersSelected}
              onSelectedItems={(items) => onFormFiltersChanged(items)}
              disabled={this._isDisabled(
                this.props.mode,
                channelAccessPermissions === null ? completeAccess : channelAccessPermissions.adFilters
              )}
            />
          </FormItem>
        </DetailPage>
      );
    }

    private _onSubmit = (publisherId?: string, accountId?: string): void => {
      this.setState({ initializeForm: false });
      let entityId;
      if (publisherId && !accountId && this.props.location.pathname.includes('adunit/edit')) {
        // to enable channel creation on adunit edit page scoped to 'All accounts', fetch accountId using adunitId from route
        entityId = this.props.match.params.id;
      }
      onFormSubmitted(this.props.mode, this.props.history, entityId);
    };

    private _onCancel = (publisherId?: string, accountId?: string): void => {
      this.setState({ initializeForm: false });
      onFormCancelled(this.props.mode);
      if (this.props.mode !== FormState.InPageCreate) {
        // return to manage page
        const returnUrl = createReturnUrl(RouteName.channel, publisherId, accountId);
        onNavigateBack(this.props.history, returnUrl);
      }
    };

    private _getChannelAccessPermission(): ChannelAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.Channel) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }

    private _isDisabled(mode: FormState, permission: number): boolean {
      if (mode === FormState.Create || mode === FormState.InPageCreate) {
        return false;
      }

      return !hasUpdateAccess(permission);
    }
  }
);
