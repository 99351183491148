import {
  CompactPeoplePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownOption,
  Label,
  PrimaryButton,
  ValidationState,
} from '@fluentui/react';
import React, { useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { injectIntlAndObserver, validateSchema } from '../../../../utils';
import messages from '../../Report.messages';
import { IShareViewModel } from '../@data/schema/IShareViewModel';
import { shareErrorModel } from '../@data/schema/ShareErrorModel';
import { Schedule } from '../@data/schema/ShareSchedule';
import { getClassNames } from './ShareModal.styles';

export interface IShareModalProps extends InjectedIntlProps {
  enabled: boolean;
  disableModal: () => void;
}
export interface IShareModalErrors {
  schedule?: string;
  emails?: string;
}
const MODAL_WIDTH = 560;
export const ShareModal = injectIntlAndObserver<IShareModalProps>(({ enabled, disableModal, intl }) => {
  const classNames = getClassNames();
  const { formatMessage } = intl;
  const [shareSchedule, setShareSchedule] = useState(getScheduleOptions()[0].key);
  const [emails, setEmails] = useState<string[]>([]);
  const [shareErrors, setShareErrors] = useState<IShareModalErrors>({});

  return (
    <Dialog
      minWidth={MODAL_WIDTH}
      hidden={!enabled}
      onDismiss={() => {
        disableModal();
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Share',
      }}
      modalProps={{ isBlocking: false }}
    >
      <Label required>{formatMessage(messages.shareScheduleDropdownLabel)}</Label>
      <Dropdown
        options={getScheduleOptions()}
        defaultSelectedKey={shareSchedule}
        onChange={(_, option) => {
          if (option) {
            setShareSchedule(option.key);
          }
        }}
        ariaLabel={formatMessage(messages.shareScheduleDropdownLabel)}
        errorMessage={shareErrors.schedule}
      />
      <Label required>Send the report to these people</Label>
      <CompactPeoplePicker
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        onChange={(items) => {
          const newEmails = items
            ? items.map((item) => {
                return item.key as string;
              })
            : [];
          setEmails([...newEmails]);
        }}
        className={'ms-PeoplePicker'}
        onValidateInput={(input: string) => {
          if (emails.includes(input)) {
            return ValidationState.invalid;
          }
          return ValidationState.valid;
        }}
        inputProps={{
          'aria-label': 'People Picker',
        }}
        onResolveSuggestions={() => []}
      />
      <span className={classNames.errorMessage} hidden={!shareErrors.emails}>
        Emails Must Have Valid Values
      </span>
      <DialogFooter>
        <PrimaryButton
          onClick={async () => {
            try {
              await validateSchema(shareErrorModel(), { schedule: shareSchedule, emails } as IShareViewModel);
              setShareErrors({});
            } catch (e) {
              setShareErrors({ ...shareErrors, ...e });
              return;
            }
          }}
          text="Save"
        />
        <DefaultButton
          onClick={() => {
            disableModal();
          }}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  );
});
export function getScheduleOptions(): IDropdownOption[] {
  // TODO: Add logic to fetch correct options
  return [
    { key: Schedule.NONE, text: 'None' },
    { key: Schedule.DAILY, text: 'Daily' },
    { key: Schedule.WEEKLY, text: 'Weekly' },
    { key: Schedule.MONTHLY, text: 'Monthly' },
  ];
}
