import { orchestrator } from 'satcheljs';
import { Log } from '../../../../logging/src';
import { getSupportedSubGeographies } from '../../services/MetadataService';
import { fetchStatesOfCountry, setStatesOfCountry } from '../actions';
import { isStatesPresentForCountry } from '../store/selectors';

orchestrator(fetchStatesOfCountry, async ({ country }) => {
  try {
    // Return if the states are already fetched
    // NOTE: This logic is inside the orchestrator to avoid unnecessary network calls,
    // because the states don't change often.
    if (isStatesPresentForCountry(country)) {
      return;
    }

    const states = await getSupportedSubGeographies(country);
    setStatesOfCountry(country, states);
  } catch (error) {
    Log.error(`Failed to fetch states of country ${country}`);
  }
});
