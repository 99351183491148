import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getStore } from '..';
import { Log } from '../../../../../../logging/src';
import {
  getAppStore,
  LifeCycleStatus,
  onNotificationChanged,
  onSavingChanged,
  onUserFirstTime,
  postEntity,
  User,
} from '../../../../../@data';
import { onIsAcceptInviteFlowChanged } from '../../../../../@data/actions/onAcceptInviteActions';
import { UserCreateFromInvitation } from '../../../../../@data/store/schema/models/UserCreateFromInvitation';
import { WELCOME_SEND_FLAG } from '../../../../../constants/AppConstants';
import { validateSchema } from '../../../../../utils';
import { onAcceptInvitePageSubmitted, onFormCleared, onUserErrorChanged } from '../actions';
import { userAcceptInvitationErrorModel } from '../store/schema';

orchestrator(onAcceptInvitePageSubmitted, async (msg) => {
  const { userInvitationCode, userContext} = getAppStore();
  const { active } = getStore();

  if (active && userInvitationCode) {
    const body: UserCreateFromInvitation = {
      apiPath: 'livelogin/userCreateFromInvitation',
      className: 'userCreateFromInvitation',
    };

    try {
      await validateSchema(userAcceptInvitationErrorModel(), active);
      onUserErrorChanged();
    } catch (e) {
      onUserErrorChanged(e);
      onNotificationChanged({
        text: `User Accept Invitation - You have ${Object.keys(e).length} errors in the form, please fix them to continue.`,
        messageBarType: MessageBarType.severeWarning,
      });
      return;
    }

    onSavingChanged(true);

    body.invitationCode = userInvitationCode;
    body.termsAndConditionsAccepted = true;

    const userBody = new User();
    userBody.name = {
      firstName: active.firstName!,
      lastName: active.lastName!,
    };
    userBody.status = LifeCycleStatus.Active;
    userBody.username = active.userName;
    userBody.email = active.emailAddress;
    userBody.contactByEmail = active.contactByEmail;
    userBody.contactByPhone = active.contactByPhone;
    userBody.phoneNumber = active.phoneNumber;
    userBody.language = active.language;

    body.user = userBody;

    try {
      await postEntity([], UserCreateFromInvitation, body, userContext);
      Log.write(`Created new user successfully ${userBody.username}, redirecting user to login page`);
      onNotificationChanged({
        text: `Created new user successfully.`,
        messageBarType: MessageBarType.success,
      });
      sessionStorage.setItem(WELCOME_SEND_FLAG, "true")
      onIsAcceptInviteFlowChanged(false);
      onFormCleared();
      onUserFirstTime(true);
      window.location.replace('/login');
    } catch (e) {
      Log.error(`Accepting the user invitation caught an error "${e}". Please contact admin.`);
      onNotificationChanged({
        text: `Accepting the user invitation caught an error "${e}". Please contact admin.`,
        messageBarType: MessageBarType.error,
      });
    } finally {
      onSavingChanged(false);
    }
  } else {
    onNotificationChanged({
      text: `Accept User Invitation form is invalid`,
      messageBarType: MessageBarType.warning,
    });
  }
});
