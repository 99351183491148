import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../../../../../theme';

export interface IInputFieldStyle {
  note?: string;
}

export const getClassNames = (): IInputFieldStyle => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const fonts = theme.fonts as IPubCenterFonts;

  return mergeStyleSets({
    note: {
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: palette.neutralLighterAlt,
      fontSize: fonts.size12.fontSize,
      fontWeight: FontWeights.light,
      margin: `${spacing.size12} 0`,
      padding: `${spacing.size12} ${spacing.size6}`,
    },
  });
};
