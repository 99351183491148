import { ResourceType } from '../../../../../../@data/store/schema/enums/ResourceType';
import { IGlobalSearchResult } from '../../../../../../@data/store/schema/interfaces/IGlobalSearchResult';
import { SearchResult } from './SearchResult';

export class WebProperty extends SearchResult {
  public publisherName?: string;
  public websiteURL?: string;
  private _item: IGlobalSearchResult;

  constructor(item?: IGlobalSearchResult) {
    super(item && item.resourceName, item && item.resourceId, 'BusinessHoursSign');
    if (item) {
      this.websiteURL = item.propertyBag && item.propertyBag.WebSiteURL;
      this.publisherName = item.propertyBag && item.propertyBag.PublisherName;
      this._item = item;
    }
  }

  public getPublisherId(): string {
    if (this._item && this._item.propertyBag && this._item.propertyBag.PublisherId) {
      return this._item.propertyBag.PublisherId;
    }

    return '';
  }

  public getAccountId(): string {
    if (this._item && this._item.propertyBag && this._item.propertyBag.AccountId) {
      return this._item.propertyBag.AccountId;
    }

    return '';
  }

  public getResourceType(): string {
    return ResourceType[ResourceType.WebProperty];
  }
}
