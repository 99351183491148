import { array, mixed, object, string } from 'yup';
import { StringListSchemaYupType } from '../../../../../../utils';
import { IBlockItemViewModel } from './IBlockItemViewModel';
import { BlockItemType } from './models';

export function blockItemErrorModel() {
  return object<IBlockItemViewModel>().shape({
    blockReason: string().required(),
    blockItemType: mixed().oneOf([BlockItemType.Ads, BlockItemType.Images]),
    adPerfBlockAdsData: array().of(
      object().shape({
        blockedImages: new StringListSchemaYupType().when('blockItemType', {
          is: BlockItemType.Images,
          then: array().required('Please select atleast one image to block'),
          otherwise: array(),
        }),
      })
    ),
    blockDetails: string().max(200),
  });
}
