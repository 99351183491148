import { IDropdownOption } from '@fluentui/react';
import { mutatorAction } from 'satcheljs';
import { FilterCondition } from '../../../../../@data/store/schema/enums/FilterCondition';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { AdQualityFilterNames } from '../store/schema';
import { defaultInitialTextFilterState, getStore } from '../store/store';

export const onDropdownValueChanged = mutatorAction(
  'onDropDownValueChanged',
  (heading: string, value: IDropdownOption<FilterCondition>, index: number) => {
    const t = getStore().filters.active?.[heading];
    t.conditions[index].condition! = value.key;
  }
);

export const onTextBoxValueChanged = mutatorAction('onTextBoxValueChanged', (heading: string, value: string, index: number) => {
  const t = getStore().filters.active?.[heading];
  t.conditions[index].value = value;
});

export const onDeleteTextCondition = mutatorAction(
  'onDeleteTextCondition',
  (heading: string, filterOption: FilterCondition, val: string) => {
    const rawFilters = getStore().filters.raw;

    if (rawFilters && rawFilters[heading] && rawFilters[heading].conditions) {
      const conditions = rawFilters[heading].conditions;
      const index = conditions.findIndex((c) => c.condition === filterOption && c.value === val);
      if (index >= 0) {
        conditions.splice(index, 1);
      }
      if (conditions.length === 0) {
        conditions.push(defaultInitialTextFilterState);
      }
    }
  }
);

export const onListValueChanged = mutatorAction('onListValueChanged', (heading: string, val: string) => {
  const rawFilters = getStore().filters.raw;
  if (rawFilters && rawFilters[heading]) {
    const listValues = rawFilters[heading].values;
    const index = listValues.findIndex((c) => c.name === val);
    listValues[index].isSelected = false;
  }
});

export const onClearAll = mutatorAction('onClearAll', () => {
  const filterListType = [AdQualityFilterNames.AccountName];

  const filterTextType = [
    AdQualityFilterNames.CreativeAdId,
    AdQualityFilterNames.Advertiser,
    AdQualityFilterNames.AdvertiserId,
    AdQualityFilterNames.AdvertiserAccountId,
    AdQualityFilterNames.AdId,
    AdQualityFilterNames.AdTitle,
    AdQualityFilterNames.AdURL,
    AdQualityFilterNames.LongHeadline,
    AdQualityFilterNames.ShortHeadline,
  ];
  const rawFilters = getStore().filters.raw;
  filterTextType.forEach((elem: string) => {
    if (rawFilters && rawFilters[elem].conditions) {
      rawFilters[elem].conditions = [defaultInitialTextFilterState];
    }
  });
  filterListType.forEach((elem: string) => {
    if (rawFilters && rawFilters[elem]) {
      rawFilters[elem].values.forEach((elemList: IIdNamePair) => {
        elemList.isSelected = false;
      });
    }
  });
});
