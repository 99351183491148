import { AuditLog, ServingStatusMap } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import ActionDetailsMessages from '../ActionDetails.messages';
import { getAdUnitTypes, getLifeCycleStatusIdValue } from './convertAttributeValues';
import { ActionDetail } from './getActionDetails';

export function getActionDetailsForAdunit(actionDetail: ActionDetail, auditLog: AuditLog, formatMessage) {
  actionDetail.title = `${formatMessage(ActionDetailsMessages.updated)} ${auditLog.entityName}`;

  if (auditLog.changes) {
    actionDetail.changes = auditLog.changes.map((change) => {
      let actionDetailMessage = '';
      let attributeChanged = change.attributeChanged;
      if (
        ['AdUnitTypes', 'LifeCycleStatusID', 'Font', 'ServingStatus', 'BackgroundColor', 'ButtonBackgroundColor', 'TitleColor'].includes(
          change.attributeChanged
        )
      ) {
        attributeChanged = formatMessage(ActionDetailsMessages[change.attributeChanged]);
      }

      if (change.attributeChanged === 'AdUnitTypes') {
        if (change.newValue && !change.oldValue) {
          actionDetailMessage = `${formatMessage(ActionDetailsMessages.selectedAs, {
            attributeChanged: attributeChanged,
            newValue: getAdUnitTypes(change.newValue),
          })}`;
        } else if (change.newValue && change.oldValue) {
          actionDetailMessage = formatMessage(ActionDetailsMessages.updatedMessageFormat, {
            attributeChanged: attributeChanged,
            oldValue: getAdUnitTypes(change.oldValue),
            newValue: getAdUnitTypes(change.newValue),
          });
        }
      } else if (change.attributeChanged === 'AdUnitName') {
        actionDetailMessage = `${formatMessage(ActionDetailsMessages.nameChangedTo, {
          oldValue: change.oldValue,
          newValue: change.newValue,
        })}`;
      } else if (change.attributeChanged === 'LifeCycleStatusID') {
        actionDetailMessage = `${formatMessage(ActionDetailsMessages.updatedMessageFormat, {
          attributeChanged: attributeChanged,
          oldValue: formatMessage(
            getLifeCycleStatusIdValue(change.oldValue) === 'Active' ? AppMessages.statusActive : AppMessages.statusInactive
          ),
          newValue: formatMessage(
            getLifeCycleStatusIdValue(change.newValue) === 'Active' ? AppMessages.statusActive : AppMessages.statusInactive
          ),
        })}`;
      } else if (change.attributeChanged === 'ServingStatus') {
        actionDetailMessage = `${formatMessage(ActionDetailsMessages.updatedMessageFormat, {
          attributeChanged: attributeChanged,
          oldValue: ServingStatusMap[Number(change.oldValue)],
          newValue: ServingStatusMap[Number(change.newValue)],
        })}`;
      } else {
        if (change.newValue && !change.oldValue) {
          actionDetailMessage = `${formatMessage(ActionDetailsMessages.addedAs, {
            attributeChanged: attributeChanged,
            newValue: change.newValue,
          })}`;
        } else if (change.newValue && change.oldValue) {
          actionDetailMessage = formatMessage(ActionDetailsMessages.updatedMessageFormat, {
            attributeChanged: attributeChanged,
            oldValue: change.oldValue,
            newValue: change.newValue,
          });
        }
      }

      return actionDetailMessage;
    });
  }
}
