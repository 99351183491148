import { InjectedIntl } from 'react-intl';
import {
  ApiError,
  ClarityType,
  Property,
  PropertyApprovalStatus,
  Publisher,
  UserContext,
  postEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../../../../@data';
import PropertyMessages from '../../../../../Property.messages';
import { onSettingClarityErrorMessage } from '../../../../__data__/mutatorActions';
import messages from '../../messages';

export const createProperty = async (
  publisher: Publisher,
  property: { url?: string; name?: string; clarityType?: ClarityType; clarityId?: string },
  formatMessage: InjectedIntl['formatMessage'],
  userContext?: UserContext,
  updateLoadingStatus?: (isLoading: boolean) => void
) => {
  const body = {
    publisherId: publisher.id,
    approvalStatus: PropertyApprovalStatus.Approved, // always approved for tython flow
    url: property.url,
    name: property.name,
    approvalStatusComments: '',
    className: 'properties',
    associatedAdUnitIds: [],
    apiPath: undefined,
    clarityType: property.clarityType !== ClarityType.None ? property.clarityType : undefined,
    clarityProjectIdForTython: property.clarityType === ClarityType.None ? property.clarityId : undefined,
  };

  let data;
  try {
    updateLoadingStatus?.(true);

    data = await postEntity([publisher], Property, (body as unknown) as Property, userContext);
    showSuccessToastNotification({ text: formatMessage(messages.createPropertySuccessNotification, { entityId: data.id }) });
  } catch (e) {
    const getClarityErrorMessage = (e: ApiError) => {
      if (e.code === 404) {
        return formatMessage(messages.clarityProjectDoesNotExistNotification);
      } else {
        return e.message.split(`description":"`)[1].split(`"`)[0];
      }
    };

    const apiErrorObj = e as ApiError;
    if (apiErrorObj.code === 404) {
      onSettingClarityErrorMessage(getClarityErrorMessage(apiErrorObj));
    } else {
      onSettingClarityErrorMessage(getClarityErrorMessage(apiErrorObj));
    }
    showErrorToastNotification({ textMessageDescriptor: PropertyMessages.propertyCreateFailed });
  } finally {
    updateLoadingStatus?.(false);
  }
  return data;
};
