import { InjectedIntlProps } from 'react-intl';
import { AppAdUnitPrimaryFormProps, AppAdUnitResultSummaryProps, AppAdUnitTypeFormProps } from '../CreateAdUnit/types';

export type AppAdUnitPrimaryFormType = (props: AppAdUnitPrimaryFormProps & InjectedIntlProps) => JSX.Element;

export type AppAdUnitTypeFormType = (props: AppAdUnitTypeFormProps & InjectedIntlProps) => JSX.Element;

export type V2SectionalAccordionType = (props: {
  onTitleToggle: (isSelected?: boolean) => void;
  isAccordionSelected: boolean;
  onRenderLeft: () => JSX.Element;
  onRenderRight?: () => JSX.Element;
  titleLabel: string;
  disabled?: boolean;
  isLoading: boolean;
}) => JSX.Element;

export enum AppAdUnitPreviewLayout {
  Horizontal = 0,
  Vertical = 1,
  Square = 2,
}

export type StaticAdPreviewType = (props: { dimension?: string } & InjectedIntlProps) => JSX.Element;

export type AppAdUnitResultSummaryType = (props: AppAdUnitResultSummaryProps & InjectedIntlProps) => JSX.Element;
