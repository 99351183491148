import { createStore } from 'satcheljs';
import { IAccountStatusData } from './schema';

// !NOTE: This is to be added explicitly for the orchestrators to register themselves.
import '../orchestrators';

export const getAccountStatusData = createStore<IAccountStatusData>('AccountStatusData', {
  status: undefined,
  isLoading: false,
});
