import { createStore } from 'satcheljs';
import '../orchestrators';
import { IReportPageData, ReportDataSource } from './schema';

export const ReportsMetadataKeyByType = {
  19: 'app.reports.19.metadata',
};
export const ReportTypeKey = 'app.reports.reportType';
export const getReportPageStore = createStore<IReportPageData>('ReportPageData', {
  metadata: { isMetadataLoaded: false, dimensions: [], grains: [], metrics: [] },
  mediationMetadata: { isMetadataLoaded: false, dimensions: [], grains: [], metrics: [] },
  columnMembers: {},
  reportType: ReportDataSource.TythonV2,
  adUnits: {
    Day: {},
    Hour: {},
    Month: {},
    None: {},
    Week: {},
    Year: {},
    MonthAndYear: {},
  },
  mediation: {
    Day: {},
    Hour: {},
    Month: {},
    None: {},
    Week: {},
    Year: {},
    MonthAndYear: {},
  },
  sites: {
    Day: {},
    Hour: {},
    Month: {},
    None: {},
    Week: {},
    Year: {},
    MonthAndYear: {},
  },
  performance: {
    Day: {},
    Hour: {},
    Month: {},
    None: {},
    Week: {},
    Year: {},
    MonthAndYear: {},
  },
  summary: {
    Day: {},
    Hour: {},
    Month: {},
    None: {},
    Week: {},
    Year: {},
    MonthAndYear: {},
  },
  mediationSummary: {
    Day: {},
    Hour: {},
    Month: {},
    None: {},
    Week: {},
    Year: {},
    MonthAndYear: {},
  },
  country: {
    Day: {},
    Hour: {},
    Month: {},
    None: {},
    Week: {},
    Year: {},
    MonthAndYear: {},
  },
});
