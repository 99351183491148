import { orchestrator } from 'satcheljs';
import { onHelpInfoCalloutOpened, onHelpInfoChanged } from '../actions';
import { getHelpInfo } from '../services/HelpService';

import '../mutators/helpInfoChanged';

orchestrator(onHelpInfoCalloutOpened, async (msg) => {
  onHelpInfoChanged();
  const helpHtml = await getHelpInfo(msg.popupId, msg.locale);
  onHelpInfoChanged(helpHtml);
});
