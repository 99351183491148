import { handleApiErrors, handleError } from '..';
import { uiConfig } from '../../config/ui.config';
import { UserContext } from '../store/schema';
import { IGlobalSearchResultData } from '../store/schema/interfaces/IGlobalSearchResultData';
import { GlobalSearchRequest } from '../store/schema/models/GlobalSearchRequest';
import { GlobalSearchResponse } from '../store/schema/models/GlobalSearchResponse';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';
import { ApiError } from './ApiService';

export async function postSearch(body: GlobalSearchRequest, userContext?: UserContext): Promise<IGlobalSearchResultData> {
  const url = uiConfig.getApiBaseAddress() + '/search';
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      return handleApiErrors<IGlobalSearchResultData>(response, url);
    }

    const responseJson: GlobalSearchResponse = await response.json();
    if (responseJson.data) {
      return responseJson.data;
    }
    return Promise.resolve({} as IGlobalSearchResultData);
  } catch (err) {
    const error = `Search operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
