import { getTheme, mergeStyleSets } from '@fluentui/react';
import { AccessibilityContrast, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../theme';
import { ICommonStyleProps } from '../../../../../types';
import { SetupCardType } from './SetupCardUtils';

type StyleProperties =
  | 'titleStyle'
  | 'statusStyle'
  | 'progressStepsContainerStyle'
  | 'progressStepStyle'
  | 'setupCardContainerStyle'
  | 'imageContainerStyle'
  | 'imageStyle'
  | 'setupContentStyle'
  | 'descriptionStyle'
  | 'setupCardButtonStyle';
type SetupCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps & { cardType?: SetupCardType }): SetupCardStyles => {
  const { theme, cardType } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { Activated } = AccessibilityContrast;

  return mergeStyleSets({
    titleStyle: {
      margin: `${spacing.size10} 0px`,
      fontSize: spacing.size18,
      fontWeight: '600',
    },
    statusStyle: {
      margin: `${spacing.size10} 0px`,
      fontSize: spacing.size14,
      fontWeight: '600',
    },
    progressStepsContainerStyle: {
      height: spacing.size12,
      background: palette.neutralQuaternary,
      boxShadow: `${spacing.size2} ${spacing.size14} ${spacing.size15} -7px rgba(30, 166, 250, 0.36)`,
      borderRadius: spacing.size2,
      transition: 'all 0.5s',
      flex: 1,
      [Activated]: {
        color: 'window',
        backgroundColor: 'windowtext',
        borderColor: 'windowtext',
        forcedColorAdjust: 'none',
      },
    },
    progressStepStyle: {
      display: 'flex',
      flexDirection: 'row',
      width: 'calc(100% - 5%)',
      gap: '5px',
      '& :nth-child(1)': {
        borderTopLeftRadius: spacing.size4,
        borderBottomLeftRadius: spacing.size4,
      },
      '& :nth-child(last)': {
        borderTopRightRadius: spacing.size4,
        borderBottomRightRadius: spacing.size4,
      },
    },
    setupCardContainerStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
      boxShadow: `0px 0px ${spacing.size7} 0px rgba(0,0,0,0.2)`,
      transition: '0.3s',
      borderRadius: spacing.size4,
      flex: 1,
      [Activated]: {
        color: 'windowtext',
        borderColor: 'window',
        forcedColorAdjust: 'none',
      },
    },
    imageContainerStyle: {
      justifyContent: 'center',
      display: 'flex',
      borderRadius: `${spacing.size4} ${spacing.size4} 0px 0px`,
      marginTop: spacing.size6,
    },
    imageStyle: {
      minHeight: spacing.size120,
      borderRadius: `${spacing.size4} ${spacing.size4} 0px 0px`,
    },
    setupContentStyle: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: spacing.size16,
      paddingRight: spacing.size10,
    },
    descriptionStyle: {
      marginTop: '0px',
    },
    setupCardButtonStyle: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingLeft: spacing.size16,
      paddingBottom: spacing.size16,
      marginTop: 'auto',
      '> button': {
        borderRadius: spacing.size4,
        ...(cardType === SetupCardType.GetStarted ? { backgroundColor: palette['BrandBackground.Rest'] } : {}),
      },
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
