import { mutatorAction } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import {
  getAppStore,
  getCurrentPublisher,
  IPhoneVerificationData,
  IStringDictionary,
  PhoneVerificationMediumType,
  Publisher,
  User,
} from '../../../../../@data';
import { DEFAULT_COUNTRY_CODE } from '../../../../../constants/AppConstants';
import { deepClone } from '../../../../../utils';
import { getPrimaryUserActiveData, getPublisherActiveData, getPublisherProfileActiveData } from '../store/selectors';
import { getProfileStore } from '../store/store';

export const setLoadingStateAction = mutatorAction('setLoadingStateAction', (isLoading: boolean) => {
  getPublisherProfileActiveData().isLoading = isLoading;
});

export const setPublisherProfileDetailsAction = mutatorAction('setPublisherProfileDetailsAction', (publisherProfileDetails: Publisher) => {
  const appStore = getAppStore();
  appStore.publisher = { ...appStore.publisher, ...publisherProfileDetails };

  /** NOTE: When publisher is signed up, we set some dummy address as it is a mandatory field in CCMT.
   *  As it can cause confusion to users in UI, we are resetting the address fields, in case
   *  Address has dummy field values
   *  Publisher profileComplete is false
   */
  const { publisher } = getAppStore();
  const isDefaultAddress = publisher?.address?.line1 === 'Address line 1' && publisher.address.city === 'City';
  const { id, lastUpdatedTimestamp, eTag, country } = publisher?.address || {};
  if (publisher && publisher.address && isDefaultAddress && !publisher.profileComplete) {
    Log.info('Resetting dummy address as publisher profile is incomplete');

    publisher.address = {
      id,
      lastUpdatedTimestamp,
      eTag,
      country,
    };
  }
});

export const setPrimaryUserDetailsAction = mutatorAction('setPrimaryUserDetailsAction', (primaryUserDetails: User) => {
  // This is necessary because only "users" API endpoints don't return the className, others do.
  primaryUserDetails.className = new User().className;
  getProfileStore().raw.primaryUser = primaryUserDetails;
});

export const setPrimaryUserDetailsETagAction = mutatorAction('setPrimaryUserDetailsETagAction', (userETag: string) => {
  const { raw } = getProfileStore();
  const { primaryUser } = raw;
  if (primaryUser?.eTag) {
    primaryUser.eTag = userETag;
  }
});

export const setProfileEditModeAction = mutatorAction('setProfileEditModeAction', (isInEditMode: boolean) => {
  getPublisherProfileActiveData().isInEditMode = isInEditMode;
});

export const initializeProfileActiveStateFromRawDataAction = mutatorAction('initializeProfileActiveStateFromRawDataAction', () => {
  const { raw } = getProfileStore();
  const { primaryUser } = raw;
  const publisher = getCurrentPublisher();
  const active = getPublisherProfileActiveData();
  active.publisher = deepClone(publisher);

  // NOTE: This is to keep the eTag updated with the server data
  if (active.publisher && publisher?.eTag) {
    active.publisher.eTag = publisher?.eTag;
  }
  active.user = deepClone(primaryUser);
  if (active.user && primaryUser?.eTag) {
    active.user.eTag = primaryUser.eTag;
  }

  // Initialize the country with the default country code if not set already
  if (active.user?.postalAddress && !active.user?.postalAddress?.country) {
    active.user.postalAddress.country = DEFAULT_COUNTRY_CODE;
  }
  if (active.publisher?.address && !active.publisher?.address?.country) {
    active.publisher.address.country = DEFAULT_COUNTRY_CODE;
  }
});

export const setIsAddressValid = mutatorAction('setIsAddressValid', (isAddressValid?: boolean) => {
  const active = getPublisherProfileActiveData();
  active.isAddressValid = isAddressValid;
});

export const setIsOrgAddressValid = mutatorAction('setIsOrgAddressValid', (isOrgAddressValid?: boolean) => {
  const active = getPublisherProfileActiveData();
  active.isOrgAddressValid = isOrgAddressValid;
});

export const setPrimaryUserFieldAction = mutatorAction('setPrimaryUserFieldAction', (fieldName: string, fieldValue?: string) => {
  const primaryUser = getPrimaryUserActiveData();
  if (!primaryUser) {
    return;
  }

  if (fieldName === 'phoneNumber') {
    primaryUser.contactInfo = primaryUser.contactInfo || {};
    primaryUser.contactInfo.phone1 = fieldValue;
    return;
  }

  primaryUser.postalAddress = primaryUser.postalAddress || {};
  primaryUser.postalAddress[fieldName] = fieldValue;
});

export const setPublisherFieldAction = mutatorAction('setPublisherFieldAction', (fieldName: string, fieldValue?: string) => {
  const publisher = getPublisherActiveData();
  if (!publisher) {
    return;
  }

  if (['organizationEmailAddress', 'organizationUrl', 'ceoName'].includes(fieldName)) {
    publisher.extendedProperties = publisher.extendedProperties || {};
    publisher.extendedProperties[fieldName] = fieldValue;
    return;
  }

  if (fieldName === 'name') {
    publisher.name = fieldValue;
    return;
  }

  publisher.address = publisher.address || {};
  publisher.address[fieldName] = fieldValue;
});

export const setProfileErrorsAction = mutatorAction('setProfileErrorsAction', (errors?: IStringDictionary) => {
  getProfileStore().errors = errors || {};
});

export const setIsSubmittedAction = mutatorAction('setIsSubmittedAction', (isSubmitted: boolean) => {
  getProfileStore().active.isSubmitted = isSubmitted;
});

export const setIsPreviewShownAction = mutatorAction('setIsPreviewShownAction', (isPreviewShown: boolean) => {
  getProfileStore().active.isPreviewShown = isPreviewShown;
});

export const setIsPhoneVerificationMethodsShown = mutatorAction(
  'setIsPhoneVerificationMethodsShown',
  (isPhoneVerificationMethodsShown: boolean) => {
    getProfileStore().active.isPhoneVerificationMethodsShown = isPhoneVerificationMethodsShown;
  }
);

export const setIsPhoneVerificationCodeShown = mutatorAction('setIsPhoneVerificationCodeShown', (isPhoneVerificationCodeShown: boolean) => {
  getProfileStore().active.isPhoneVerificationCodeShown = isPhoneVerificationCodeShown;
});

export const setIsVerificationSuccess = mutatorAction('setIsPhoneVerificationSuccess', (isVerificationSuccess: boolean) => {
  getProfileStore().active.isVerificationSuccess = isVerificationSuccess;
});
export const setIsCodeBeingVerified = mutatorAction('setIsCodeBeingVerified', (isCodeBeingVerified: boolean) => {
  getProfileStore().active.isCodeBeingVerified = isCodeBeingVerified;
});

export const setPhoneVerificationMediumType = mutatorAction('setPhoneVerificationMediumType', (mediumType: PhoneVerificationMediumType) => {
  getProfileStore().active.phoneVerificationGenerateCode.verificationMediumType = mediumType;
});

export const setPhoneVerificationCode = mutatorAction('setPhoneVerificationCode', (code: string) => {
  getProfileStore().active.phoneVerificationVerifyCode.code = code;
});

export const setPhoneVerificationRequestId = mutatorAction('setPhoneVerificationRequestId', (requestId: string) => {
  getProfileStore().active.phoneVerificationVerifyCode.requestId = requestId;
});

export const setPhoneVerificationResp = mutatorAction('setPhoneVerificationResp', (respData: IPhoneVerificationData) => {
  getProfileStore().active.phoneVerificationResp = respData;
});
export const setVerificationCodeGenerationLimitReached = mutatorAction(
  'setVerificationCodeGenerationLimitReached',
  (limitReached: boolean) => {
    getProfileStore().active.verificationCodeGenerationLimitReached = limitReached;
  }
);
