import { mutator } from 'satcheljs';
import { Log } from '../../../logging/src';
import { onAccessiblePublisherListChanged, onDefaultPublisherSet, onPublisherChanged } from '../actions';
import { Publisher } from '../store';
import { getAppStore } from '../store/store';

mutator(onPublisherChanged, (msg) => {
  const store = getAppStore();
  if (msg.publisher) {
    msg.publisher.className = new Publisher().className;
  }

  store.publisher = msg.publisher;
});

mutator(onDefaultPublisherSet, (msg) => {
  Log.debug(`Setting default publisher, ${msg.id}`);

  const store = getAppStore();
  store.defaultPublisher = msg.id;
});

mutator(onAccessiblePublisherListChanged, (msg) => {
  const store = getAppStore();
  store.accessiblePublishers = msg.value;
});
