import React, { useEffect } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { LayoutMode, onLayoutChanged } from '../../../@data';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import { ManagePage } from './Manage/ManagePage';

export const AccountPage = injectIntl(function AccountPageComponent(props: RouteComponentProps & InjectedIntlProps): JSX.Element {
  useEffect(() => {
    onLayoutChanged(LayoutMode.Default);
    onCurrentRouteChanged(RouteName.publisherProfile, props.intl.formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ManagePage {...props} />;
});
