// tslint:disable: max-line-length

import { TemplateType } from '..';
import { Model } from './Model';

export class NativeAdTemplate extends Model {
  public apiPath = undefined;
  public className = 'templates';

  public type: TemplateType;
  public adUnitId: number;
  public numberOfAds: number;
  public assets: Asset[];
  public renderingConfig: RenderingConfig;
}

export class Asset {
  public required: number;
  public title?: Title;
  public img?: Image;
  public data?: Data;
}

export class RenderingConfig {
  public customHtml?: string;
  public customCss?: string;
  public simplifiedHtml?: string;
  public drConfig: string;
  public gridRowCount: number;
  public gridColumnCount: number;
  public imageWidthPercentage?: number;
  public adCardWidth?: number;
  public gamAdUnit: GamAdUnit;
}

export class GamAdUnit {
  public path?: string
  public isActive?: boolean
  public keyValuesName?: string
  public creativeId?: number
}

export class Title {
  public len: number;
}

export class Image {
  public type: ImageAssetType;
  public w: number;
  public h: number;
}

export class Data {
  public type: DataAssetType;
  // len is an optional field based on openRTB spec
  public len?: number;
}

export enum ImageAssetType {
  /**
   *  Values from https://msasg.visualstudio.com/ContentServices/_git/ICE.Service.Monetization?path=/src/ICE.Service.Monetization.Rtb.Contracts/AssetImageType.cs&_a=contents&version=GBmaster
   */

  Thumbnail = 1,
  Large = 3,

  // TODO: Deprecated post-MSX to Xandr migration?
  TransparentImage = 500,

  /** Used to request unbounded number of images */
  // TODO: Deprecated post-MSX to Xandr migration?
  MultipleImages = 600,
}

export enum DataAssetType {
  /**
   * Values from: https://msasg.visualstudio.com/ContentServices/_git/ICE.Service.Monetization?path=/src/ICE.Service.Monetization.Rtb.Contracts/AssetDataType.cs&_a=contents&version=GBmaster
   */

  /**  Sponsored By message where response should contain the brand name of the sponsor. */
  Sponsored = 1,

  /**
   * Descriptive text associated with the product  or service being  advertised.
   * Longer length of text in response may be truncated or ellipsed by the exchange.
   */
  Description = 2,

  /**
   * Rating of the product being offered to the user.
   * For example an app’s rating in an app store from 0-5.
   */
  Rating = 3,

  /**  Number of social ratings or “likes” of the product being offered to the user */
  Likes = 4,

  /**  Number downloads/installs of this product */
  Downloads = 5,

  /**
   * Price for product / app / in-app purchase.
   * Value  should include currency symbol in localised format.
   */
  Price = 6,

  /**
   * Sale price that can be used together with price to indicate a discounted price compared to a regular price.
   * Value  should include currency symbol in localised format.
   */
  Saleprice = 7,

  /**  Phone number */
  Phone = 8,

  /**  Address */
  Address = 9,

  /**  Additional descriptive text associated with the product or service being advertised */
  Description2 = 10,

  /**
   * Display URL for the text ad. To be used when sponsoring entity doesn’t own the content.
   * IE sponsored by BRAND on SITE (where SITE is transmitted in this field).
   */
  DisplayUrl = 11,

  /**  CTA description - descriptive text describing a ‘call to action’ button for the destination URL. */
  CTAText = 12,

  /**  Installment Price */
  // TODO: Deprecated post-MSX to Xandr migration?
  InstallmentPrice = 500,

  /**  Price Drop */
  // TODO: Deprecated post-MSX to Xandr migration?
  PriceDrop = 501,

  /**
   * Presence of this asset means the decoration is available, no check on its value details.
   * Value is set to "true" when freeshipping is available.
   */
  // TODO: Deprecated post-MSX to Xandr migration?
  FreeShipping = 502,

  /**  Top Viewed */
  // TODO: Deprecated post-MSX to Xandr migration?
  TopViewed = 503,

  /**  Sold */
  // TODO: Deprecated post-MSX to Xandr migration?
  Sold = 504,

  /**  Special Offer */
  // TODO: Deprecated post-MSX to Xandr migration?
  SpecialOffer = 505,

  /**  Elite Badge */
  // TODO: Deprecated post-MSX to Xandr migration?
  EliteBadge = 506,

  /**  Condition */
  // TODO: Deprecated post-MSX to Xandr migration?
  Condition = 507,

  /**  Local Inventory Ad */
  // TODO: Deprecated post-MSX to Xandr migration?
  LIA = 508,

  /**  Curbside Pickup */
  // TODO: Deprecated post-MSX to Xandr migration?
  CurbsidePickup = 509,

  /**  Review */
  // TODO: Deprecated post-MSX to Xandr migration?
  Review = 510,

  /**  Short Title */
  // TODO: Deprecated post-MSX to Xandr migration?
  ShortTitle = 511,

  /**  Return Policy */
  // TODO: Deprecated post-MSX to Xandr migration?
  ReturnPolicy = 512,

  /**  Disclaimer Text */
  // TODO: Deprecated post-MSX to Xandr migration?
  DisclaimerText = 513,
}
