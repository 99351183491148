import {
  BaseComponent,
  BasePicker,
  classNamesFunction,
  getId,
  IBasePickerProps,
  Icon,
  IProcessedStyleSet,
  ITag,
  Label,
} from '@fluentui/react';
import * as React from 'react';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import {
  IListItem,
  IListPickerItemProps,
  IListPickerProps,
  IListPickerState,
  IListPickerStyleProps,
  IListPickerStyles,
} from './ListPicker.types';

const getClassNames = classNamesFunction<IListPickerStyleProps, IListPickerStyles>();
let classNames: IProcessedStyleSet<IListPickerStyles>;

class ListPickerLegacy extends BasePicker<IListItem, IBasePickerProps<IListItem>> {
  protected static defaultProps: Partial<IBasePickerProps<IListItem>> = {
    resolveDelay: 300,
    itemLimit: 1,

    onRenderItem: (props: IListPickerItemProps) => (
      <div className={classNames.selectedInput} key={props.item.key}>
        <span className={classNames.selectedText} role="listitem">
          {props.item.name}
        </span>

        <span
          className={classNames.selectedClose}
          role="button"
          tabIndex={0}
          onClick={props.onRemoveItem}
          onKeyDown={(ev) => isButtonPressKey(ev.key) && props.onRemoveItem && props.onRemoveItem()}
          aria-label={props.removeButtonAriaLabel}
        >
          <Icon iconName="Cancel" />
        </span>
      </div>
    ),
    onRenderSuggestionsItem: (props: ITag) => (
      <div className={classNames.item} key={props.key}>
        {props.name + ' (ID: ' + props.key + ')'}
      </div>
    ),
  };
}

export class ListPickerBase extends BaseComponent<IListPickerProps, IListPickerState> {
  private _id: string;

  constructor(props) {
    super(props);

    this._id = props.id || getId('ListPicker');

    this.state = {
      selectedItems: this.props.selectedItem ? [this.props.selectedItem] : undefined,
      pickerIsDisabled: this.props.disabled,
    };
  }

  public componentDidUpdate(prevProps: IListPickerProps, prevState: IListPickerState) {
    if (this.props.selectedItem === prevProps.selectedItem) {
      return;
    }

    this.setState({
      selectedItems: this.props.selectedItem ? [this.props.selectedItem] : [],
    });
  }

  public render(): JSX.Element {
    const { styles, theme, onRenderLabel = this._onRenderLabel } = this.props;
    classNames = getClassNames(styles, { theme: theme!, closeButtonIsDisabled: this.state.pickerIsDisabled });

    const basePickerProps: IBasePickerProps<IListItem> = {
      onResolveSuggestions: (filter, selectedItems) => {
        let selectedItem;
        if (selectedItems && selectedItems.length) {
          selectedItem = selectedItems[0];
        }

        return this.props.onResolveSuggestions(filter, selectedItem);
      },
      inputProps: {
        placeholder: this.props.inputPlaceholder,
      },
      pickerSuggestionsProps: {
        noResultsFoundText: this.props.noResultsFoundText,
      },
      selectedItems: this.state.selectedItems,
      getTextFromItem: this.props.getTextFromItem,
      disabled: this.props.disabled,
      onChange: (selectedItems: IListItem[]) => {
        this.setState({
          selectedItems: selectedItems,
        });

        if (this.props.onChange) {
          this.props.onChange(selectedItems.length ? selectedItems[0] : undefined);
        }
      },
      pickerCalloutProps: {
        calloutMaxHeight: 320,
      },
      removeButtonAriaLabel: this.props.removeButtonAriaLabel,
    };

    return (
      <div className={classNames.root}>
        {onRenderLabel(this.props, this._onRenderLabel)}

        <ListPickerLegacy className={classNames.basePicker} {...basePickerProps} />
      </div>
    );
  }

  private _onRenderLabel = (props: IListPickerProps): JSX.Element | null => {
    const { label, required } = props;
    if (label) {
      return (
        <Label className={classNames.label} required={required} htmlFor={this._id}>
          {props.label}
        </Label>
      );
    }
    return null;
  };
}
