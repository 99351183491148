import { orchestrator } from 'satcheljs';
import { FormState } from '../../../../../components/Form';
import { onPanelOpenChanged } from '../../../@data/actions';
import { onFormCancelled, onFormCleared } from '../actions';

orchestrator(onFormCancelled, async msg => {
  onFormCleared();
  if (msg.mode === FormState.InPageCreate) {
    onPanelOpenChanged(false);
  }
});
