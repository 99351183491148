import { defineMessages } from 'react-intl';

export default defineMessages({
  backButtonAriaDesc: {
    id: 'detailPageWithStepper.backButtonAriaDesc',
    defaultMessage: 'Click to go back to manage page'
  },
  breadcrumbAriaLabel: {
    id: 'detailPageWithStepper.breadcrumbAriaLabel',
    defaultMessage: 'Bread crumb showing the heirarchy of subpages'
  },
  stepperGoToFutureStepError: {
    id: 'detailPageWithStepper.stepperGoToFutureStepError',
    defaultMessage: 'Can not move directly to this step'
  }
});