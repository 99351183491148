import { CommandBarButton } from '@fluentui/react';
import React, { RefObject } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { showLoadingToastNotification } from '../../../../@data';
import { breakdownDate } from '../../../../utils';
import ReportMessages from '../../Report.messages';
import { ICustomCalenderDateRange } from '../../components/CustomCalender/CustomCalender.types';
import { constructName, exportToPNG } from '../../utils/exportHelper';
import { IMetadataElement, IReportPageReportData, TythonReportingDataRow } from '../@data/store/schema';
import { ChartTypeSelector } from '../ChartTypeSelector/ChartTypeSelector';
import { exportReport, getTopData } from './ReportCard.helpers';
import { ChartGrain, ChartTypes, IChartButton } from './ReportCard.types';

export interface ITopBarActionsOptionsProps extends InjectedIntlProps {
  dateRange: ICustomCalenderDateRange;
  currentChartGrain: ChartGrain;
  currentChartType: ChartTypes;
  onChartTypeChange: (type: ChartTypes) => void;
  setCurrentChartGrain: (type: ChartGrain) => void;
  setEnableShare: (enable: boolean) => void;
  allowedChartTypes: IChartButton[];
  reportData: IReportPageReportData<TythonReportingDataRow>;
  mainDimension?: string;
  selectedMetric: string;
  columnsMetadata: IMetadataElement[];
  reportName: string;
  enableHideChartsButton?: boolean;
  onChartsHideClicked: (hidden: boolean) => void;
  isDetailedView: boolean;
  cardRef: RefObject<HTMLDivElement>;
  sysLocale: string;
}

export const TopBarActions = injectIntl(
  ({
    onChartTypeChange,
    setCurrentChartGrain,
    dateRange,
    currentChartType,
    allowedChartTypes,
    setEnableShare,
    reportData,
    selectedMetric,
    mainDimension,
    columnsMetadata,
    reportName,
    enableHideChartsButton,
    onChartsHideClicked,
    currentChartGrain,
    isDetailedView,
    cardRef,
    intl: { formatMessage },
    sysLocale,
  }: ITopBarActionsOptionsProps) => {
    const brokenDownStartDate = breakdownDate(dateRange.startDate, sysLocale);
    const brokenDownEndDate = breakdownDate(dateRange.endDate, sysLocale);
    const startDateString = `${brokenDownStartDate.month}-${brokenDownStartDate.day}-${brokenDownStartDate.year}`;
    const endDateString = `${brokenDownEndDate.month}-${brokenDownEndDate.day}-${brokenDownEndDate.year}`;
    const exportedFileName = constructName(reportName, currentChartGrain, startDateString, endDateString);
    const disableExport = !reportData.isDataLoaded || !reportData.data || reportData.data.length <= 0;
    return (
      <>
        {allowedChartTypes.length > 1 && (
          <ChartTypeSelector
            allowedChartTypes={allowedChartTypes}
            currentChartType={currentChartType}
            onChartTypeChange={onChartTypeChange}
            setCurrentChartGrain={setCurrentChartGrain}
            enableHideChartsButton={enableHideChartsButton}
            onChartsHideClicked={onChartsHideClicked}
          />
        )}
        {!isDetailedView && (
          <>
            <CommandBarButton
              iconProps={{ iconName: 'More' }}
              onRenderMenuIcon={() => null}
              menuProps={{
                items: [
                  {
                    key: 'exportCSV',
                    text: formatMessage(ReportMessages.exportCSV),
                    onClick: () => {
                      const dataToExport: TythonReportingDataRow[] = getDataToExport(selectedMetric, reportData.data, mainDimension);
                      exportReport(dataToExport, columnsMetadata, exportedFileName, formatMessage);
                    },
                    disabled: disableExport,
                  },
                  {
                    key: 'exportPNG',
                    text: formatMessage(ReportMessages.exportPNG),
                    onClick: async () => {
                      showLoadingToastNotification({
                        text: formatMessage(ReportMessages.topBarExportPNG),
                        onDismiss: async () => {
                          cardRef.current && (await exportToPNG(cardRef.current, exportedFileName));
                        },
                      });
                    },
                    disabled: disableExport,
                  },
                  // {
                  //   key: 'share',
                  //   text: 'Share',
                  //   onClick: () => {
                  //     setEnableShare(true);
                  //   },
                  // },
                ],
              }}
            />
          </>
        )}
      </>
    );
  }
);

export function getDataToExport(selectedMetric: string, data?: TythonReportingDataRow[], mainDimension?: string) {
  let dataToExport: TythonReportingDataRow[];
  if (mainDimension) {
    dataToExport = getTopData(data || [], selectedMetric, mainDimension).topData.map((row) => {
      return {
        [mainDimension]: row[mainDimension],
        [selectedMetric]: row[selectedMetric],
      } as TythonReportingDataRow;
    });
  } else {
    dataToExport = data || [];
  }
  return dataToExport;
}
