import { getTheme, IButtonStyles, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterTheme } from '../../../theme';
import { ICommonStyleProps } from '../../types';

type StyleProperties =  'confirmation';
type PopupCardStyles = Record<StyleProperties, string>;
export const getStyles = (props: ICommonStyleProps): PopupCardStyles => {
  const theme = props.theme as unknown as IPubcenterTheme;
  const { spacing } = theme;

  return mergeStyleSets({
    confirmation: {
      paddingBottom: spacing.size4,
    },
  });
};
export function getClassNames<PropType>(props?: PropType): PopupCardStyles {
  return getStyles({ theme: getTheme(), ...props });
}

export const getPermanentDeleteButtonStyles = (): IButtonStyles => {
  const palette = getTheme().palette as IPubcenterPalette;
  const customStyles = {
    backgroundColor: palette.redDark,
    borderColor: palette.redDark,
  };

  return {
    root: {
      ...customStyles,
    },
    rootHovered: {
      ...customStyles,
    },
    rootFocused: {
      ...customStyles,
    },
    rootPressed: {
      ...customStyles,
    },
  };
};
