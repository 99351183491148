import { ActionButton, Checkbox, classNamesFunction, getId, getTheme, nullRender } from '@fluentui/react';
import * as React from 'react';
import { getAppStore, onNavigateBack } from '../../../@data';
import { ResourceType } from '../../../@data/store/schema/enums/ResourceType';
import AppMessages from '../../../App.messages';
import { Main } from '../../../components/Main/Main';
import { injectIntlWithObserver } from '../../../utils';
import { getScreenReaderTextStyles } from '../../../utils/GlobalStyleUtils';
import { clearStore, onSearchTriggered, setSearchParams } from '../@data/actions/onSearchTriggered';
import '../@data/mutators/searchChanged';
import '../@data/orchestrators/searchTriggered';
import { getStore } from '../@data/store/store';
import { GlobalSearchResultList } from '../GlobalSearchResultList/GlobalSearchResultList';
import GlobalSearchResultPageMessages from './GlobalSearchResultPage.messages';
import { getStyles } from './GlobalSearchResultPage.styles';
import {
  IFilter,
  IGlobalSearchResultPageProps,
  IGlobalSearchResultPageState,
  IGlobalSearchResultPageStyleProps,
  IGlobalSearchResultPageStyles,
} from './GlobalSearchResultPage.types';

const getClassNames = classNamesFunction<IGlobalSearchResultPageStyleProps, IGlobalSearchResultPageStyles>();

export const GlobalSearchResultPage = injectIntlWithObserver(
  class GlobalSearchResultPageComponent extends React.Component<IGlobalSearchResultPageProps, IGlobalSearchResultPageState> {
    private _classNames = getClassNames(getStyles, { theme: getTheme() });
    private _filters: Map<string, IFilter> = new Map<string, IFilter>([
      [ResourceType[ResourceType.AdUnit], { text: this.props.intl.formatMessage(AppMessages.adUnits), isSelected: true }],
      [ResourceType[ResourceType.Filter], { text: this.props.intl.formatMessage(AppMessages.adFilters), isSelected: true }],
      [ResourceType[ResourceType.Channel], { text: this.props.intl.formatMessage(AppMessages.channels), isSelected: true }],
      [ResourceType[ResourceType.Publisher], { text: this.props.intl.formatMessage(AppMessages.publishers), isSelected: true }],
      [ResourceType[ResourceType.Reporting], { text: this.props.intl.formatMessage(AppMessages.reporting), isSelected: true }],
      [ResourceType[ResourceType.WebProperty], { text: this.props.intl.formatMessage(AppMessages.properties), isSelected: true }],
      [ResourceType[ResourceType.Account], { text: this.props.intl.formatMessage(AppMessages.accounts), isSelected: true }],
      [ResourceType[ResourceType.User], { text: this.props.intl.formatMessage(AppMessages.users), isSelected: true }],
    ]);

    constructor(props: IGlobalSearchResultPageProps) {
      super(props);
      this.state = {
        filters: this._filters,
      };
    }

    public componentDidMount() {
      const params = new URLSearchParams(this.props.location.search);
      const query = params.get('q') || '';
      const sourceRef = params.get('sourceRef') || '';
      const filter = params.get('filter') || '';

      setSearchParams(query, sourceRef);
      this._updateFilters(filter);
      onSearchTriggered();
    }

    public componentDidUpdate() {
      const params = new URLSearchParams(this.props.location.search);
      const store = getStore();
      const query = params.get('q') || '';
      const sourceRef = params.get('sourceRef') || '';
      const filter = params.get('filter') || '';

      if (query !== '' && store.searchQuery !== query) {
        clearStore();
        setSearchParams(query, sourceRef);
        this._updateFilters(filter);
        onSearchTriggered();
      }
    }

    public componentWillUnmount() {
      clearStore();
    }

    public render(): JSX.Element | null {
      const classNames = this._classNames;
      const { formatMessage } = this.props.intl;
      const appStore = getAppStore();

      if (appStore.loading === true) {
        return nullRender();
      }

      return (
        <div className={classNames.root}>
          <ActionButton
            className={classNames.navBackButton}
            iconProps={{
              iconName: 'Back',
            }}
            onClick={() => onNavigateBack()}
          >
            {formatMessage(AppMessages.back)}
          </ActionButton>
          <div className={classNames.scrollablePanel}>
            <Main className={classNames.pageContent} aria-labelledby="searchResultHeading">
              <div id="searchResultHeading" className={classNames.header} role="heading" aria-level={1}>
                {formatMessage(GlobalSearchResultPageMessages.searchResults)}
              </div>
              <div className={classNames.filter} role="region" aria-labelledby="filtersLabel">
                <div id="filtersLabel" className={classNames.filterContent}>
                  {formatMessage(GlobalSearchResultPageMessages.filterSearchBy)}
                </div>
                {this._getFilters()}
                <ActionButton className={classNames.clearFilterButton} onClick={() => this._clearFilters()}>
                  {formatMessage(GlobalSearchResultPageMessages.clearFilters)}
                </ActionButton>
              </div>
              {this._getSearchResultLists()}
            </Main>
          </div>
        </div>
      );
    }

    private _getFilters(): JSX.Element {
      const element: JSX.Element[] = [];
      const classNames = getClassNames(getStyles, { theme: getTheme() });
      const store = getStore();
      store.resourceTypes.forEach((value: number, key: string) => {
        const filterState = this.state.filters.get(key);
        const checkboxAriaDescId = getId('checkBoxAriaDesc-');

        if (filterState && value > 0) {
          element.push(
            <>
              <Checkbox
                className={classNames.filterContent}
                key={key}
                label={filterState.text}
                ariaLabel={filterState.text}
                checked={filterState.isSelected}
                onChange={() => this._checkBoxClicked(key)}
                ariaDescribedBy={checkboxAriaDescId}
              />
              <span id={checkboxAriaDescId} style={getScreenReaderTextStyles()}>
                {this.props.intl.formatMessage(GlobalSearchResultPageMessages.filterCheckboxAriaDesc, { entity: filterState.text })}
              </span>
            </>
          );
        }
      });
      return <React.Fragment>{element}</React.Fragment>;
    }

    private _getSearchResultLists(): JSX.Element {
      const element: JSX.Element[] = [];
      const store = getStore();
      store.resourceTypes.forEach((value: number, key: string) => {
        const filterState = this.state.filters.get(key);
        if (filterState && filterState.isSelected && value > 0) {
          element.push(<GlobalSearchResultList {...this.props} resourceType={key} headerText={filterState.text} key={key} />);
        }
      });
      return <React.Fragment>{element}</React.Fragment>;
    }

    private _clearFilters() {
      const filters = this.state.filters;

      filters.forEach((filter: IFilter) => {
        filter.isSelected = false;
      });

      this.setState({ filters: filters });
    }

    private _checkBoxClicked(resourceType: string) {
      const filters = this.state.filters;
      const filterState = filters.get(resourceType);
      if (filterState) {
        filterState.isSelected = !filterState.isSelected;
      }
      this.setState({ filters: filters });
    }

    private _updateFilters(resourceType: string) {
      const filterResource = this._filters.get(resourceType);

      if (filterResource) {
        const filters = this.state.filters;

        filters.forEach((filter: IFilter) => {
          if (filter !== filterResource) {
            filter.isSelected = false;
          } else {
            filter.isSelected = true;
          }
        });

        this.setState({ filters: filters });
      }
    }
  }
);
