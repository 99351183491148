import { defineMessages } from 'react-intl';

export default defineMessages({
  account: {
    id: 'app.account',
    defaultMessage: 'Account',
  },
  activate: {
    id: 'app.activate',
    defaultMessage: 'Activate',
  },
  delete: {
    id: 'app.delete',
    defaultMessage: 'Delete',
  },
  deactivate: {
    id: 'app.deactivate',
    defaultMessage: 'Deactivate',
  },
  duplicate: {
    id: 'app.duplicate',
    defaultMessage: 'Duplicate',
  },
  edit: {
    id: 'app.edit',
    defaultMessage: 'Edit',
  },
  code: {
    id: 'app.code',
    defaultMessage: 'Code',
  },
  information: {
    id: 'app.information',
    defaultMessage: 'Information',
  },
  atleastSelectOneAccount: {
    id: 'app.atleastSelectOneAccount',
    defaultMessage: 'At least select 1 account',
  },
  deactivateLowercase: {
    id: 'grid.deactivate.deactivateLowercase',
    defaultMessage: 'deactivate',
  },
  activateLowercase: {
    id: 'app.activateLowercase',
    defaultMessage: 'activate',
  },
  enabledUppercase: {
    id: 'app.enabledUppercase',
    defaultMessage: 'Enabled',
  },
  disabledUppercase: {
    id: 'app.disabledUppercase',
    defaultMessage: 'Disabled',
  },
  export: {
    id: 'app.export',
    defaultMessage: 'Export',
  },
  download: {
    id: 'app.download',
    defaultMessage: 'Download',
  },
  downloadSelected: {
    id: 'app.downloadSelected',
    defaultMessage: 'Download Selected',
  },
  downloadPage: {
    id: 'app.downloadPage',
    defaultMessage: 'Download Page',
  },
  downloadAll: {
    id: 'app.downloadAll',
    defaultMessage: 'Download All',
  },
  downloadSelectedCmdBtnAriaDesc: {
    id: 'app.downloadSelectedCmdBtnAriaDesc',
    defaultMessage: 'Download Selected',
  },
  downloadPageCmdBtnAriaDesc: {
    id: 'app.downloadPageCmdBtnAriaDesc',
    defaultMessage: 'Download Page',
  },
  downloadAllCmdBtnAriaDesc: {
    id: 'app.downloadAllCmdBtnAriaDesc',
    defaultMessage: 'Download All',
  },
  downloadCmdBtnAriaDesc: {
    id: 'grid.downloadCmdBtnAriaDesc',
    defaultMessage: 'Click to download grid data',
  },
  addFilter: {
    id: 'app.addFilter',
    defaultMessage: 'Add filter',
  },
  filters: {
    id: 'app.filters',
    defaultMessage: 'Filters',
  },
  filtersDescription: {
    id: 'app.filtersDescription',
    defaultMessage: 'Select the filters you want.',
  },
  updateFilters: {
    id: 'app.updateFilters',
    defaultMessage: 'Update filters',
  },
  addMetrics: {
    id: 'app.addMetrics',
    defaultMessage: 'Add metrics',
  },
  modifyColumns: {
    id: 'app.modifyColumns',
    defaultMessage: 'Modify Columns',
  },
  modifyColumnsDescription: {
    id: 'app.modifyColumnsDescription',
    defaultMessage: 'Select the columns you want. {appName} will set the order.',
  },
  updateColumns: {
    id: 'app.updateColumns',
    defaultMessage: 'Update columns',
  },
  publisher: {
    id: 'app.publisher',
    defaultMessage: 'Publisher',
  },
  noPublisherSelected: {
    id: 'app.noPublisherSelected',
    defaultMessage: 'A publisher isn’t selected yet. To start managing {entity}, search for a publisher and then if available, an account.',
  },
  noAccountSelected: {
    id: 'app.noAccountSelected',
    defaultMessage: 'The publisher does not have an account associated with it. To get started, create an account.',
  },
  customerInvalid: {
    id: 'app.customerInvalid',
    defaultMessage: `This publisher can't be accessed in {appName}. Please search for another one.`,
  },
  upload: {
    id: 'app.upload',
    defaultMessage: 'Upload',
  },
  previous: {
    id: 'app.previous',
    defaultMessage: 'Previous',
  },
  continue: {
    id: 'app.continue',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'app.cancel',
    defaultMessage: 'Cancel',
  },
  apply: {
    id: 'app.apply',
    defaultMessage: 'Apply',
  },
  search: {
    id: 'app.search',
    defaultMessage: 'Search',
  },
  finish: {
    id: 'app.finish',
    defaultMessage: 'Finish',
  },
  required: {
    id: 'app.required',
    defaultMessage: 'Required',
  },
  optional: {
    id: 'app.optional',
    defaultMessage: 'Optional',
  },
  support: {
    id: 'app.support',
    defaultMessage: 'support',
  },
  default: {
    id: 'app.default',
    defaultMessage: 'Default',
  },
  status: {
    id: 'app.status',
    defaultMessage: 'Status',
  },
  partnerCodes: {
    id: 'app.partnerCodes',
    defaultMessage: 'Partner Codes',
  },
  removeAll: {
    id: 'app.removeAll',
    defaultMessage: 'Remove all',
  },
  addAll: {
    id: 'app.addAll',
    defaultMessage: 'Add all',
  },
  add: {
    id: 'app.add',
    defaultMessage: 'Add',
  },
  replace: {
    id: 'app.replace',
    defaultMessage: 'Replace',
  },
  append: {
    id: 'app.append',
    defaultMessage: 'Append',
  },
  name: {
    id: 'app.name',
    defaultMessage: 'Name',
  },
  domain: {
    id: 'app.domain',
    defaultMessage: 'Domain',
  },
  multiSelectPickerSelectedItemsLabel: {
    id: 'multiSelectPicker.selectedItemsLabel',
    defaultMessage: 'Selected: {count}',
  },
  timezoneLabel: {
    id: 'timezoneLabel',
    defaultMessage: ' Time zone',
  },
  timezoneEST: {
    id: 'timezoneEST',
    defaultMessage: '(GMT-05:00) Eastern Time (US & Canada)',
  },
  timezoneIDL: {
    id: 'timezoneIDL',
    defaultMessage: '(GMT-12:00) International Date Line West',
  },
  address: {
    id: 'address',
    defaultMessage: 'Address',
  },
  city: {
    id: 'city',
    defaultMessage: 'City',
  },
  countryOrRegion: {
    id: 'countryOrRegion',
    defaultMessage: 'Country or region',
  },
  stateOrProvince: {
    id: 'stateOrProvince',
    defaultMessage: 'State or province',
  },
  zipcode: {
    id: 'zipcode',
    defaultMessage: 'Zip or postal code',
  },
  language: {
    id: 'language',
    defaultMessage: 'Language',
  },
  country: {
    id: 'country',
    defaultMessage: 'Country',
  },
  currency: {
    id: 'currency',
    defaultMessage: 'Currency',
  },
  deactivateGenericTitle: {
    id: 'deactivateGenericTitle',
    defaultMessage: 'Are you sure you want to deactivate?',
  },
  deleteGenericTitle: {
    id: 'deleteGenericTitle',
    defaultMessage: 'Are you sure you want to delete?',
  },
  neverMind: {
    id: 'neverMind',
    defaultMessage: 'Never Mind',
  },
  all: {
    id: 'app.all',
    defaultMessage: 'All',
  },
  adUnits: {
    id: 'app.adunits',
    defaultMessage: 'Ad units',
  },
  adFilters: {
    id: 'app.adfilters',
    defaultMessage: 'Ad filters',
  },
  channels: {
    id: 'app.channels',
    defaultMessage: 'Channels',
  },
  publishers: {
    id: 'app.publishers',
    defaultMessage: 'Publishers',
  },
  reporting: {
    id: 'app.reporting',
    defaultMessage: 'Reporting',
  },
  properties: {
    id: 'app.properties',
    defaultMessage: 'Properties',
  },
  site: {
    id: 'app.site',
    defaultMessage: 'Site',
  },
  accounts: {
    id: 'app.accounts',
    defaultMessage: 'Accounts',
  },
  users: {
    id: 'app.users',
    defaultMessage: 'Users',
  },
  userInvitations: {
    id: 'app.userInvitations',
    defaultMessage: 'User Invitations',
  },
  showMore: {
    id: 'app.showmore',
    defaultMessage: 'Show more',
  },
  back: {
    id: 'app.navBack',
    defaultMessage: 'Back',
  },
  loadingLabel: {
    id: 'app.loadingLabel',
    defaultMessage: 'Loading...',
  },
  loadingStatusLabel: {
    id: 'app.loadingStatusLabel',
    defaultMessage: 'Loading...',
  },
  loadingWaitingLabel: {
    id: 'app.loadingWaitingLabel',
    defaultMessage: "One moment please, this won't take long...",
  },
  submit: {
    id: 'app.submit',
    defaultMessage: 'Submit',
  },
  save: {
    id: 'app.save',
    defaultMessage: 'Save',
  },
  saving: {
    id: 'app.saving',
    defaultMessage: 'Saving...',
  },
  createChannel: {
    id: 'app.createChannel',
    defaultMessage: 'Create channel',
  },
  close: {
    id: 'app.close',
    defaultMessage: 'Close',
  },
  on: {
    id: 'app.on',
    defaultMessage: 'On',
  },
  off: {
    id: 'app.off',
    defaultMessage: 'Off',
  },
  infoDescription: {
    id: 'app.infoDescription',
    defaultMessage: 'Click here for more information on {label}',
  },
  infoLabel: {
    id: 'app.infoLabel',
    defaultMessage: '{label} Help',
  },
  here: {
    id: 'app.here',
    defaultMessage: 'here',
  },
  learnMore: {
    id: 'app.learnMore',
    defaultMessage: 'Learn more',
  },
  statusActive: {
    id: 'app.statusActive',
    defaultMessage: 'Active',
  },
  statusInactive: {
    id: 'app.statusInactive',
    defaultMessage: 'Inactive',
  },
  skipToMainContent: {
    id: 'app.skipToMainContentLabel',
    defaultMessage: 'Skip to main content',
  },
  next: {
    id: 'app.next',
    defaultMessage: 'Next',
  },
  yes: {
    id: 'app.yes',
    defaultMessage: 'Yes',
  },
  performanceCardTitle: {
    id: 'app.performanceCardTitle',
    defaultMessage: 'Performance',
  },
  sitesCardTitle: {
    id: 'app.sitesCardTitle',
    defaultMessage: 'Sites',
  },
  adUnitsCardTitle: {
    id: 'app.adUnitsCardTitle',
    defaultMessage: 'Ad unit',
  },
  mediationCardTitle: {
    id: 'app.mediationCardTitle',
    defaultMessage: 'Mediation',
  },
  countryCardTitle: {
    id: 'app.countryCardTitle',
    defaultMessage: 'Country',
  },
  moreOptionsButtonAriaLabel: {
    id: 'dataGrid.moreOptionsButtonAriaLabel',
    defaultMessage: 'More options',
  },
  selectedCount: {
    id: 'selectedCount',
    defaultMessage: `{count} selected`,
  },
  sortByColumnHeaderTooltipLabel: {
    id: 'sortByColumnHeaderTooltipLabel',
    defaultMessage: 'Sort by {columnName}',
  },
  panelCloseButtonAriaDesc: {
    id: 'panelCloseButtonAriaDesc',
    defaultMessage: 'Click to dismiss the panel',
  },
  userDeleteWarning: {
    id: 'userDeleteWarning',
    defaultMessage: 'This action cannot be undone. This will permanently delete this account and all of the data.',
  },
  confirmActionMessage: {
    id: 'confirmActionMessage',
    defaultMessage: 'Confirm this action by typing "yes" below',
  },
  permanentDelete: {
    id: 'permanentDelete',
    defaultMessage: 'Permanently delete',
  },
  ariaLabelForSave: {
    id: 'ariaLabelForSave',
    defaultMessage: 'Save Changes',
  },
  ariaLabelForGrid: {
    id: 'ariaLabelForGrid',
    defaultMessage: 'Grid',
  },
  ariaLabelForListHeader: {
    id: 'ariaLabelForListHeader',
    defaultMessage: 'List Header',
  },
  ariaLabelForSelectionColumn: {
    id: 'ariaLabelForSelectionColumn',
    defaultMessage: 'Selection Column',
  },
  ariaLabelForSelectAllCheckBox: {
    id: 'ariaLabelForSelectAllCheckbox',
    defaultMessage: 'Select all rows',
  },
  checkButtonAriaLabel: {
    id: 'dataGrid.checkButtonAriaLabel',
    defaultMessage: 'Row selection checkbox',
  },
  ariaLabelForShimmer: {
    id: 'ariaLabelForShimmer',
    defaultMessage: 'Shimmer',
  },
  checkButtonGroupAriaLabel: {
    id: 'checkButtonGroupAriaLabel',
    defaultMessage: 'Check Button Group',
  },
  checkBoxLabel: {
    id: 'checkBoxLabel',
    defaultMessage: 'CheckBox',
  },
  servingStatus: {
    id: 'app.servingStatus',
    defaultMessage: 'Serving Status',
  },
  navAdFilters: {
    id: 'app.nav.adfilters',
    defaultMessage: 'Ad Filters',
  },
  navChannels: {
    id: 'app.nav.channels',
    defaultMessage: 'Channels',
  },
  navAdUnits: {
    id: 'app.nav.adunits',
    defaultMessage: 'Ad Units',
  },
  navSites: {
    id: 'app.nav.sites',
    defaultMessage: 'Sites',
  },
  navProperties: {
    id: 'app.nav.properties',
    defaultMessage: 'Properties',
  },
  navAdQuality: {
    id: 'app.nav.AdQuality',
    defaultMessage: 'Ad Quality',
  },
  navHome: {
    id: 'app.nav.home',
    defaultMessage: 'Home',
  },
  navAdBlocks: {
    id: 'app.nav.adBlocks',
    defaultMessage: 'Ad Blocks',
  },
  navReports: {
    id: 'app.nav.reports',
    defaultMessage: 'Reports',
  },
  navAccount: {
    id: 'app.nav.account',
    defaultMessage: 'Account',
  },
  navPublisherManagement: {
    id: 'app.nav.publisherManagement',
    defaultMessage: 'Publisher management',
  },
  navAccountManagement: {
    id: 'app.nav.accountManagement',
    defaultMessage: 'Account management',
  },
  navUserManagement: {
    id: 'app.nav.userManagement',
    defaultMessage: 'User management',
  },
  actions: {
    id: 'app.actions',
    defaultMessage: 'Actions',
  },
  fetchNotificationsError: {
    id: 'app.FetchNotificationsError',
    defaultMessage: 'Could not fetch the backend notifications',
  },
  dismissNotificationsError: {
    id: 'app.dismissNotificationsError',
    defaultMessage: 'Could not dismiss backend notifications',
  },
  detailPageFooterArialLabel: {
    id: 'app.detailPage.footer.arialLabel',
    defaultMessage: 'Detail page footer',
  },
  of: {
    id: 'of',
    defaultMessage: 'of',
  },
  readMore: {
    id: 'readMore',
    defaultMessage: 'Read more',
  },
  adUnit: {
    id: 'app.adunit',
    defaultMessage: 'Ad unit',
  },
  adFilter: {
    id: 'app.adfilter',
    defaultMessage: 'Ad filter',
  },
  channel: {
    id: 'app.channel',
    defaultMessage: 'Channel',
  },
  property: {
    id: 'app.property',
    defaultMessage: 'Property',
  },
  user: {
    id: 'app.user',
    defaultMessage: 'User',
  },
  deleteNotificationSingleItem: {
    id: 'app.deleteNotificationSingleItem',
    defaultMessage: 'Deleted! Your {name} ',
  },
  deleteNotificationSingleItemCont: {
    id: 'app.deleteNotificationSingleItemCont',
    defaultMessage: 'has been deleted. ',
  },
  deleteNotificationPlural: {
    id: 'app.deleteNotificationPlural',
    defaultMessage: 'Deleted! Selected ',
  },
  deleteNotificationPluralCont: {
    id: 'app.deleteNotificationPluralCont',
    defaultMessage: 'have been deleted. ',
  },
  duplicateNotificationSingleItem: {
    id: 'app.duplicateNotificationSingleItem',
    defaultMessage: 'Duplicated! Your {name} ',
  },
  duplicateNotificationSingleItemCont: {
    id: 'app.duplicateNotificationSingleItemCont',
    defaultMessage: 'has been duplicated. ',
  },
  duplicateNotificationPlural: {
    id: 'app.duplicateNotificationPlural',
    defaultMessage: 'Duplicated! Selected ',
  },
  duplicateNotificationPluralCont: {
    id: 'app.duplicateNotificationPluralCont',
    defaultMessage: 'have been duplicated. ',
  },
  listId: {
    id: 'app.listId',
    defaultMessage: ' ID: {id}',
  },
  listIds: {
    id: 'app.listIds',
    defaultMessage: ' IDs: {IDs}',
  },
  activatedUpperCase: {
    id: 'app.activatedUpperCase',
    defaultMessage: 'Activated!',
  },
  deactivatedUpperCase: {
    id: 'app.deactivatedUpperCase',
    defaultMessage: 'Deactivated!',
  },
  activatedLowerCase: {
    id: 'app.activatedLowerCase',
    defaultMessage: 'activated. ',
  },
  deactivatedLowerCase: {
    id: 'app.deactivatedLowerCase',
    defaultMessage: 'deactivated. ',
  },
  changingStatusItemName: {
    id: 'app.changingStatusItemName',
    defaultMessage: ' Your {name} ',
  },
  changingStatusItemsSelection: {
    id: 'app.changingStatusItemsSelection',
    defaultMessage: ' Selected ',
  },
  changingStatusSingular: {
    id: 'app.changingStatusSingular',
    defaultMessage: ' has been ',
  },
  changingStatusPlural: {
    id: 'app.changingStatusPlural',
    defaultMessage: ' have been ',
  },
  failedToFetchUserScope: {
    id: 'app.failedToFetchUserScope',
    defaultMessage: 'Failed to fetch user scopes, please logout and re-login',
  },
  sessionExpiredLoginAgain: {
    id: 'app.sessionExpiredLoginAgain',
    defaultMessage: 'Your session has expired. Please login again to continue.',
  },
  contactSupport: {
    id: 'app.contactSupport',
    defaultMessage: 'Contact Support',
  },
  navInventory: {
    id: 'app.nav.inventory',
    defaultMessage: 'Inventory',
  },
  navAds: {
    id: 'app.nav.ads',
    defaultMessage: 'Ads',
  },
  organization: {
    id: 'app.accountType.organization',
    defaultMessage: 'Business',
  },
  individual: {
    id: 'app.accountType.individual',
    defaultMessage: 'Individual',
  },
});
