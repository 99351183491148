import {
  classNamesFunction,
  ICalloutContentStyleProps,
  ICalloutContentStyles,
  IStyle,
  IStyleFunctionOrObject,
  ITheme,
} from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';

export interface IHoverStyleProps {
  theme: ITheme;
}

export interface IHoverStyles {
  hoverCard: IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles>;
  footer: IStyle;
  primaryButton: IStyle;
  defaultButton: IStyle;
}

export const getStyles = (props: IHoverStyleProps): IHoverStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { palette, spacing } = theme;

  return {
    hoverCard: {
      beakCurtain: {
        background: palette.themePrimary,
      },
      root: {
        color: palette.white,
        padding: spacing.size18,
      },
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: spacing.size12,
    },
    primaryButton: {
      backgroundColor: palette.white,
      color: palette.themePrimary,
      '&:hover': {
        backgroundColor: palette.themePrimary,
        color: palette.white,
        borderColor: palette.white,
      },
      '&:focus': {
        backgroundColor: palette.themePrimary,
        color: palette.white,
        borderColor: palette.white,
      },
    },
    defaultButton: {
      backgroundColor: palette.themePrimary,
      color: palette.white,
      borderColor: palette.white,
      marginRight: spacing.size8,
      '&:hover': {
        backgroundColor: palette.white,
        color: palette.themePrimary,
      },
      '&:focus': {
        backgroundColor: palette.white,
        color: palette.themePrimary,
      },
    },
  };
};

export const getClassNames = classNamesFunction<IHoverStyleProps, IHoverStyles>();
