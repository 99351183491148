import { mutator } from 'satcheljs';
import { onClarityProjectChanged, onCopyScriptIconChanged, onFormCleared } from '../actions/onScriptPageActions';
import { getPropertyScriptPageStore } from '../store/store';

mutator(onCopyScriptIconChanged, (msg) => {
  getPropertyScriptPageStore().copyScriptIcon = msg.iconName;
});

mutator(onFormCleared, () => {
  getPropertyScriptPageStore().clarityProjectId = '';
});

mutator(onClarityProjectChanged, (msg) => {
  getPropertyScriptPageStore().clarityProjectId = msg.id;
});
