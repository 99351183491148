import { GridSelectionMode } from '../../../GridV2.types';

export function getSelectionMode(selectionCount: Number): GridSelectionMode {
  switch (selectionCount) {
    case 0:
      return GridSelectionMode.None;
    case 1:
      return GridSelectionMode.Single;
    default:
      return GridSelectionMode.Multiple;
  }
}
