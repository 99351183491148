import { FontWeights, ITheme, getTheme, mergeStyleSets } from '@fluentui/react';
import { TagSize } from '.';
import { IPubCenterFonts, IPubcenterTheme } from '../../../theme';
import { ICommonStyleProps } from '../../types';

type StyleProperties = 'success' | 'pending' | 'fail' | 'widthFit';

type TabStyles = Record<StyleProperties, string>;

export interface ITagStyleProps extends ICommonStyleProps {
  theme: ITheme;
  size?: TagSize;
  hoverAble?: boolean;
}

export const getStyles = (props: ITagStyleProps): TabStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette } = theme;
  const fonts = theme.fonts as IPubCenterFonts;

  const LARGE_SIZE = fonts.size14.fontSize;
  const MEDIUM_SIZE = fonts.size12.fontSize;
  const SMALL_SIZE = fonts.size10.fontSize;

  const SizeMapper = {
    0: LARGE_SIZE,
    1: MEDIUM_SIZE,
    2: SMALL_SIZE,
  };

  const statusIdentifier = {
    display: 'flex',
    gap: spacing.size4,
    width: 'fit-content',
    borderRadius: spacing.size4,
    padding: `${spacing.size2} ${spacing.size6}`,
    fontSize: SizeMapper[props.size || 1],
    cursor: props.hoverAble ? 'pointer' : 'default',
    fontWeight: FontWeights.semibold,
  };

  return mergeStyleSets({
    widthFit: {
      width: 'fit-content',
    },
    success: {
      backgroundColor: palette.honeydew,
      color: palette.deepGreen,
      border: `1px solid ${palette.grannySmithApple}`,
      ...statusIdentifier,
      '&:focus-visible': {
        border: `1px solid black`,
      },
    },
    pending: {
      backgroundColor: palette.neutralLighter,
      color: palette.disabledGray,
      border: `1px solid ${palette.grayX11}`,
      ...statusIdentifier,
      '&:focus-visible': {
        border: `1px solid black`,
      },
    },
    fail: {
      backgroundColor: palette.lavenderBlush,
      color: palette.venetianRed,
      border: `1px solid ${palette.paleChestnut}`,
      ...statusIdentifier,
      '&:focus-visible': {
        border: `1px solid black`,
      },
    },
  });
};

export function getClassNames(props?: ITagStyleProps): TabStyles {
  return getStyles({ theme: getTheme(), ...props });
}
