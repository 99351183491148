import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { Account, getAppStore, listEntity, onNotificationChanged } from '../../../../../@data';
import { onAccountsFetched, onFetchAccountsLoading, onFetchingAccountsData } from '../actions';
import { onManagePageInitialized } from '../actions/onManagePageInitialized';
import { getStore } from '../store/store';

orchestrator(onManagePageInitialized, async () => {
  try {
    const { publisher, userContext } = getAppStore();
    const accounts = getStore().accounts;

    if (publisher) {
      if (accounts === undefined || accounts.length === 0) {
        onFetchAccountsLoading(true);
      }
      onFetchingAccountsData(true);

      const params = new URLSearchParams([['details', 'true']]);
      const data = await listEntity([publisher], userContext, Account, params);
      data.map((account) => {
        const item = account as Account;
        if (!!item.createdDateTime) {
          item.createdDateTime = new Date(item.createdDateTime).toISOString();
        }
        return item;
      });
      onAccountsFetched(data);
    }
  } catch (error) {
    Log.error(error);
    onNotificationChanged({ messageBarType: MessageBarType.error, text: 'Failed to load accounts' });
  } finally {
    onFetchAccountsLoading(false);
    onFetchingAccountsData(false);
  }
});
