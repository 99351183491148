import { IPRange } from '.';
import { EntityLimitTier, FeatureOverideTier, NetworkAffiliation, Partner, PublisherTier, ServiceLevel } from '../enums';
import { PublisherType } from '../enums/PublisherType';
import { IAddress, IAllowedMarket } from '../interfaces';
import { Model } from './Model';

export interface IPublisherExtendedProperties {
  primaryContactUserId?: number;
  organizationEmailAddress?: string;
  organizationUrl?: string;
  ceoName?: string;
}

export class Publisher extends Model {
  public apiPath = undefined;
  public className = 'publishers';
  public address?: IAddress;
  public allowDistributionReporting?: boolean;
  public allowDistributionV2Reporting?: boolean;
  public allowStartDistributionReporting?: boolean;
  public allowStartDistributionContentReporting?: boolean;
  public allowEndorReporting?: boolean;
  public allowMediationReporting?: boolean;
  public allowedMarkets?: IAllowedMarket[];
  public allowMobileSearchAdUnit?: boolean;
  public allowNativeReporting?: boolean;
  public allowSyndicationReporting?: boolean;
  public allowWebAdUnit?: boolean;
  public allowPaidSearchReportingApi?: boolean;
  public allowPaidSearchReportingDashboard?: boolean;
  public allowDynamicPropertyRegistration?: boolean;
  public enableDefaultTythonV2Reporting?: boolean;
  public createdDateTime?: string;
  public defaultAccountId?: number;
  public entityLimitTier?: EntityLimitTier;
  public eTag?: string;
  public financialStatus?: string;
  public ipRanges?: IPRange[];
  public lastModifiedBy?: string;
  public lastModifiedDateTime?: string;
  public networkAffiliation?: NetworkAffiliation;
  public overrideFeatureTier?: FeatureOverideTier;
  public partner?: Partner;
  public propertyAutoApproved?: boolean;
  public publisherMarket?: string;
  public publisherTier?: PublisherTier;
  public serviceLevel?: ServiceLevel;
  public extendedProperties?: IPublisherExtendedProperties;
  public publisherType?: PublisherType;

  // NOTE: Does not sound right for API to expose this field. This should have been a derived/computed field.
  // Pending on @srivatsa
  public profileComplete?: boolean;
}
