import { uiConfig } from '../../config/ui.config';
import { onLoadingSetting, onLoadingWaiting } from '../actions';
import {
  Account,
  AccountSKU,
  FilterDownloader,
  IRequest,
  IResponse,
  Model,
  NativeAdTemplate,
  NotificationPreferencesUpdate,
  PhoneVerificationGenerateCode,
  PhoneVerificationVerifyCode,
  Publisher,
  UpdateUserContactDetails,
  User,
  UserContext,
  UserInvitation,
  UserRoleUpdate,
  UserUpdateLanguage,
} from '../store/schema';
import {
  AdSenseAdUnitStatus,
  AdSenseConnectAdUnit,
  AdSenseDisconnectAdUnit,
  AdSenseUpdateAdUnitStatus,
} from '../store/schema/models/AdSenseAdUnitStatus';
import {
  AdSenseMediation,
  AdSenseMediationChooseGamNetwork,
  AdSenseMediationHealthCheck,
  AdSenseMediationOptIn,
  AdSenseMediationOptOut,
  AdSenseMediationToggle,
} from '../store/schema/models/AdSenseMediation';
import { AddressValidation } from '../store/schema/models/AddressValidation';
import { Feedback } from '../store/schema/models/Feedback';
import { PublisherAccountUserCreate } from '../store/schema/models/PublisherAccountUserCreate';
import { ServingConfig } from '../store/schema/models/ServingConfigs';
import { UpdateClarityProject } from '../store/schema/models/UpdateClarityProject';
import { UserCreateFromInvitation } from '../store/schema/models/UserCreateFromInvitation';
import { handleApiErrors, handleError } from '../utils/handleApiErrors';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';

export class ApiError extends Error {
  public trackingId?: string;

  constructor(public code: number, message: string, trackingId?: string) {
    super(message);
    this.trackingId = trackingId;
  }
}

export async function getEntity<T extends Model>(
  models: Model[],
  userContext?: UserContext,
  entity?: new () => T,
  params?: URLSearchParams,
  apiVersion?: string
): Promise<T> {
  const url = constructUrl(models, entity, params, apiVersion);
  onLoadingSetting(false);
  return await wrapSendRequest<T>(url, 'GET', userContext);
}

export async function listEntity<T extends Model>(
  models: Model[],
  userContext?: UserContext,
  entity?: new () => T,
  params?: URLSearchParams,
  apiVersion?: string
): Promise<T[]> {
  const url = constructUrl(models, entity, params, apiVersion);
  onLoadingSetting(false);
  const response = await wrapSendRequest<T[]>(url, 'GET', userContext);
  return response;
}

const SINGULAR_RESOURCE_ENTITIES_FOR_POST = [
  new AccountSKU().className,
  new UserInvitation().className,
  new Publisher().className,
  new Account().className,
  new NativeAdTemplate().className,
  new ServingConfig().className,
  new User().className,
  new UserCreateFromInvitation().className,
  new PublisherAccountUserCreate().className,
  new AdSenseMediationOptIn().className,
  new Feedback().className,
  new AddressValidation().className,
  new PhoneVerificationGenerateCode().className,
  new PhoneVerificationVerifyCode().className,
];

export async function postEntity<T extends Model>(
  models: Model[],
  entity: new () => T,
  body?: T,
  userContext?: UserContext,
  params?: URLSearchParams,
  apiVersion?: string
): Promise<T> {
  const url = constructUrl(models, entity, params, apiVersion);
  if (body && SINGULAR_RESOURCE_ENTITIES_FOR_POST.includes(body.className)) {
    onLoadingSetting(false);
    try {
      const response = await wrapSendRequest<T>(url, 'POST', userContext, body);
      return response ? response : Promise.reject('Invalid response for POST');
    } catch (err) {
      if (err.code === 400 || err.code === 403) {
        return handleError(new ApiError(err.code, err.message), url);
      }
      const error = `POST operation failed for ${url}. Errors: ${err}`;
      return handleError(new ApiError(500, error), url);
    }
  } else {
    onLoadingSetting(false);
    try {
      const response = await wrapSendRequest<T[]>(url, 'POST', userContext, body);
      return response && response.length === 1 ? response[0] : Promise.reject('Invalid response for POST');
    } catch (err) {
      const error = `POST operation failed for ${url}. Errors: ${err}`;
      if (err.code === 404) {
        return handleError(new ApiError(404, error), url);
      }
      return handleError(new ApiError(500, error), url);
    }
  }
}

const SINGULAR_RESOURCE_ENTITIES_FOR_PUT = [
  new AccountSKU().className,
  new UserInvitation().className,
  new Publisher().className,
  new Account().className,
  new NativeAdTemplate().className,
  new ServingConfig().className,
  new User().className,
  new AdSenseMediationToggle().className,
];

export async function putEntity<T extends Model>(
  models: Model[],
  body: T,
  userContext?: UserContext,
  entity?: new () => T,
  params?: URLSearchParams,
  apiVersion?: string
): Promise<T> {
  let url = constructUrl(models, entity, params, apiVersion);
  if (body.className === new NativeAdTemplate().className) {
    url = url + '/' + encodeURIComponent(body.id);
  }

  if (SINGULAR_RESOURCE_ENTITIES_FOR_PUT.includes(body.className)) {
    const response = await sendRequest<T>(url, 'PUT', userContext, body);
    return response ? (response as T) : Promise.reject('Invalid response for PUT');
  } else {
    const response = await sendRequest<T[]>(url, 'PUT', userContext, body);
    if (body.className === new AdSenseAdUnitStatus().className) {
      if (!response['data'] && Array.isArray(response)) {
        // tslint:disable-next-line: no-any
        return response as any;
      }
      return response['data'];
    }

    return response && response.length === 1 ? response[0] : Promise.reject('Invalid response for PUT');
  }
}

export async function patchEntity<T extends Model>(
  models: Model[],
  entity: new () => T,
  body?: T,
  userContext?: UserContext,
  params?: URLSearchParams,
  apiVersion?: string
): Promise<T> {
  const url = constructUrl(models, entity, params, apiVersion);
  const response = await sendRequest<T[]>(url, 'PATCH', userContext, body);
  if (body && body.className === new UserRoleUpdate().className) {
    // tslint:disable-next-line: no-any
    return response as any;
  } else if (body && body.className === new UserUpdateLanguage().className) {
    return response['data'];
  }
  return response && response.length === 1 ? response[0] : (([] as unknown) as T);
}

export async function deleteEntity<T extends Model>(
  models: Model[],
  entity?: new () => T,
  userContext?: UserContext,
  params?: URLSearchParams,
  apiVersion?: string,
  body?: T
): Promise<T | T[]> {
  const url = constructUrl(models, entity, params, apiVersion);
  if (body && body.className === new AdSenseDisconnectAdUnit().className) {
    return await wrapSendRequest<T[]>(url, 'DELETE', userContext, body);
  } else {
    return await sendRequest<T>(url, 'DELETE', userContext, body);
  }
}

function wrapRequestBody<T extends Model>(body?: T) {
  if (!body) {
    return null;
  }

  const bodyWrapper: IRequest<T> = {};

  if (
    body.className === new AccountSKU().className ||
    body.className === new NativeAdTemplate().className ||
    body.className === new ServingConfig().className ||
    body.className === new Feedback().className ||
    body.className === new AddressValidation().className ||
    body.className === new PhoneVerificationGenerateCode().className ||
    body.className === new PhoneVerificationVerifyCode().className ||
    body.className === new AddressValidation().className
  ) {
    bodyWrapper[body.className] = body;
  } else {
    bodyWrapper[body.className] = [body];
  }

  return JSON.stringify(bodyWrapper);
}

// tslint:disable-next-line: max-line-length
async function wrapSendRequest<T extends Model | Model[]>(
  url: string,
  method: string,
  userContext?: UserContext,
  body?: Model
): Promise<T> {
  const timeout = 30000;
  const id = setTimeout(() => onLoadingWaiting(true), timeout);

  const response = await sendRequest(url, method, userContext, body);
  clearTimeout(id);
  return response as T;
}

const SINGULAR_RESOURCE_ENTITIES = [
  new Publisher().className,
  new Account().className,
  new UserInvitation().className,
  new UserCreateFromInvitation().className,
  new UserRoleUpdate().className,
  new User().className,
  new UpdateUserContactDetails().className,
  new UpdateClarityProject().className,
  new PublisherAccountUserCreate().className,
  new AdSenseAdUnitStatus().className,
  new AdSenseConnectAdUnit().className,
  new AdSenseDisconnectAdUnit().className,
  new AdSenseMediationToggle().className,
  new NotificationPreferencesUpdate().className,
  new UserUpdateLanguage().className,
  new PhoneVerificationGenerateCode().className,
  new PhoneVerificationVerifyCode().className,
];

async function sendRequest<T extends Model | Model[]>(url: string, method: string, userContext?: UserContext, body?: Model): Promise<T> {
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: method,
      headers: headers,
      body: body && SINGULAR_RESOURCE_ENTITIES.includes(body.className) ? JSON.stringify(body) : wrapRequestBody(body),
    });

    if (url.includes('filterdownloader')) {
      const responseJson = await response.json();
      const magicValue1 = Object.keys(responseJson.data)[0];

      const FilterContent = new FilterDownloader();
      FilterContent.downloadUrl = responseJson.data[magicValue1] as string;
      return Promise.resolve(FilterContent as T);
    } else {
      // Handle error responses from the API
      if (!response.ok) {
        return handleApiErrors<T>(response, url);
      }
      // Successful API response
      const basicResponse = await response.json();

      let responseJson;

      if (url.includes('preauth/mediation')) {
        responseJson = basicResponse as T;
      } else {
        responseJson = basicResponse as IResponse<T>;
      }

      if (responseJson.data) {
        if (url.includes('preauth/mediation') || url.includes('entitySummary') || url.includes('metadata/xandr')) {
          return responseJson.data;
        }

        if ((body && body.className === new UserRoleUpdate().className) || url.includes('preauth/mediation')) {
          return (responseJson.data as unknown) as T;
        }

        if (
          body &&
          (body.className === new PhoneVerificationGenerateCode().className ||
            body.className === new PhoneVerificationVerifyCode().className ||
            body.className === new UserUpdateLanguage().className)
        ) {
          return responseJson as T;
        }

        const magicValue = Object.keys(responseJson.data)[0];
        return responseJson.data[magicValue];
      }
    }
    return Promise.resolve({} as T);
  } catch (err) {
    // Need to figure out where to send the logs
    const error = `${method} operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export function constructUrl(
  models: Model[],
  entity?: new () => Model,
  params?: URLSearchParams,
  apiVersion?: string,
  path?: string
): string {
  let requestUrl = uiConfig.getApiBaseAddress(apiVersion);
  if (
    entity === AdSenseConnectAdUnit ||
    entity === AdSenseMediation ||
    entity === AdSenseDisconnectAdUnit ||
    entity === AdSenseMediationOptIn ||
    entity === AdSenseMediationToggle ||
    entity === AdSenseMediationHealthCheck ||
    entity === AdSenseMediationChooseGamNetwork ||
    entity === AdSenseAdUnitStatus ||
    entity === AdSenseUpdateAdUnitStatus ||
    entity === AdSenseMediationOptOut
  ) {
    requestUrl = requestUrl.slice(0, -5);
  }

  const urlArray = [requestUrl];
  models.forEach((model: Model) => {
    const modelClassName = model.className;

    if (!(modelClassName === 'accounts' && model.id === 0)) {
      urlArray.push(modelClassName);

      if (model.id !== undefined) {
        urlArray.push(model.id.toString());
      }
    }
  });

  if (entity) {
    const entityInstance = new entity();
    const overrideApiPath = entityInstance.apiPath;

    if (overrideApiPath) {
      urlArray.push(overrideApiPath);
    } else {
      const entityClassName = entityInstance.className;
      urlArray.push(entityClassName);
    }
  }

  if (path) {
    urlArray.push(path);
  }

  let url = urlArray.join('/');

  if (params) {
    url = url.concat('?' + params.toString());
  }

  return url;
}
