import React, { useEffect } from 'react';
import { getAppStore } from '../../../../@data';
import EntityTypeSwitch from '../../../../components/EntityTypeSwitch';
import { InventoryPageTabs, useEntityTypeManage } from '../../../../hooks/useEntityTypeManage';
import { getStore as getWindowsAppFeatureFlagStore } from '../../../../hooks/useWindowsAppFeatureFlag/store/store';
import { GridPageLayout } from '../../../../layout/GridPageLayout/GridPageLayout';
import { injectRouterWithIntl } from '../../../../utils';
import { onManagePageInitialized } from '../../Adunit/@data/actions';
import { ManagePage } from '../../Adunit/Manage/ManagePage';
import AppAdsManagePage from './AppAdsManagePage';
import messages from './messages';

export const TythonAppManagePage = injectRouterWithIntl(({ history, match, location, intl: { formatMessage } }) => {
  const { isFeatureEnabled } = getWindowsAppFeatureFlagStore();
  const { activeSubTab, setActiveSubTab } = useEntityTypeManage({ isFeatureEnabled });
  const SiteAdsManageView = <ManagePage withParentLayout history={history} match={match} location={location} abortDataFetch />;
  const AppAdsManageView = <AppAdsManagePage />;
  const { publisher, account } = getAppStore();

  useEffect(() => {
    if (publisher && account) {
      onManagePageInitialized();
    }
  }, [publisher, account]);

  return (
    <GridPageLayout withSubHeader>
      <EntityTypeSwitch
        selectedValue={activeSubTab}
        onTabSelect={setActiveSubTab}
        siteTabLabel={formatMessage(messages.siteTabLabel)}
        appTabLabel={formatMessage(messages.appTabLabel)}
        isFeatureEnabled={isFeatureEnabled}
      />
      {activeSubTab === InventoryPageTabs.Sites ? SiteAdsManageView : <></>}
      {activeSubTab === InventoryPageTabs.Apps ? AppAdsManageView : <></>}
    </GridPageLayout>
  );
});
