import { FormattedMessage, InjectedIntl } from 'react-intl';
import { action, mutator, mutatorAction } from 'satcheljs';
import { RouteName, getAdManagementStore } from '../store/store';

export const onCurrentRouteChanged = action(
  'onCurrentRouteChanged',
  (currentRoute: RouteName, formatMessage: InjectedIntl['formatMessage'], intlMessageId?: FormattedMessage.MessageDescriptor) => ({
    currentRoute,
    formatMessage,
    intlMessageId,
  })
);

mutator(onCurrentRouteChanged, ({ currentRoute }) => {
  getAdManagementStore().currentRoute = currentRoute;
});

export const onPanelOpenChanged = mutatorAction('onPanelOpenChanged', (panelIsOpen: boolean) => {
  getAdManagementStore().panelIsOpen = panelIsOpen;
});

export const onDoNotRenderDefaultPageChanged = mutatorAction('onDoNotRenderDefaultPageChanged', (doNotRenderDefaultPage: boolean) => {
  getAdManagementStore().doNotRenderDefaultPage = doNotRenderDefaultPage;
});
