import { IComboBoxOption, MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, onNotificationChanged } from '../../../../../@data';
import messages from '../../AdReview/AdCreativePage.messages';
import { onAdCreativeAccountChanged, onAdCreativeMarketChanged, onAdProviderChanged } from '../actions/onAdCreativeChanged';

orchestrator(onAdProviderChanged, async (msg) => {
  const { publisher } = getAppStore();
  if (msg.adProvider && publisher) {
    try {
      onAdCreativeAccountChanged(undefined);
      // en-us market
      onAdCreativeMarketChanged({ key: 1033 } as IComboBoxOption);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adCreativeServiceLoadEntityFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    }
  }
});
