import { action, mutatorAction } from 'satcheljs';
import { ISubGeography } from '../..';
import { getMetaDataStore } from '../store/store';

export const fetchStatesOfCountry = action('fetchStatesOfCountry', (country: string) => ({ country }));

export const setStatesOfCountry = mutatorAction('setStatesOfCountry', (country: string, states: ISubGeography[]) => {
  const metadataStore = getMetaDataStore();
  if (!metadataStore.raw?.states) {
    metadataStore.raw.states = {};
  }

  metadataStore.raw.states[country] = states;
});
