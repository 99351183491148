import { MessageBarType } from '@fluentui/react';
import { InjectedIntl } from 'react-intl';
import { Log } from '../../../../../../logging/src';
import { Publisher, TythonAdUnit, TythonAdUnitServingConfig, UserContext, listEntity, onNotificationChanged } from '../../../../../@data';
import messages from '../CreateAdunit/messages';

export const fetchTythonAdUnitById = async (
  publisher: Publisher,
  userContext: UserContext,
  id: string,
  formatMessage: InjectedIntl['formatMessage'],
  updateLoadingStatus?: (isLoading: boolean) => void
): Promise<TythonAdUnitServingConfig> => {
  updateLoadingStatus?.(true);
  try {
    // Instantiate a new TythonAdUnit and assign the id
    const adUnit = new TythonAdUnit(id);
    adUnit.className = `adunits`;
    // Construct the URL parameters
    const urlParams = new URLSearchParams([['extendedinfo', 'true']]);

    // Call listEntity with the publisher and AdUnit models, passing `undefined` for the `entity` parameter
    const data = await listEntity([publisher, adUnit], userContext, TythonAdUnit, urlParams);

    updateLoadingStatus?.(false);

    return (data as unknown) as TythonAdUnitServingConfig;
  } catch (e) {
    updateLoadingStatus?.(false);
    onNotificationChanged({ messageBarType: MessageBarType.error, text: formatMessage(messages.failedLoadAdUnitNotification) });
    Log.error(`Load tython adunit by id(${id}) failed - ${e}`);
    return Promise.reject();
  }
};
