/* Helper class for authentication. Mostly used today for MSA authentication.
   Can also be extended for AAD authentication if not using adal.js.
 */
export class AuthenticationUtils {
  public static createNewGuid(): string {
    const guidHolder: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    const hex: string = '0123456789abcdef';
    let r: number = 0;
    let guidResponse: string = '';
    for (let i: number = 0; i < 36; i++) {
      if (guidHolder[i] !== '-' && guidHolder[i] !== '4') {
        // each x and y needs to be random
        // tslint:disable-next-line: no-bitwise
        r = (Math.random() * 16) | 0;
      }
      if (guidHolder[i] === 'x') {
        guidResponse += hex[r];
      } else if (guidHolder[i] === 'y') {
        // clock-seq-and-reserved first hex is filtered and remaining hex values are random
        // tslint:disable-next-line: no-bitwise
        r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
        // tslint:disable-next-line: no-bitwise
        r |= 0x8; // set pos 3 to 1 as 1???
        guidResponse += hex[r];
      } else {
        guidResponse += guidHolder[i];
      }
    }
    return guidResponse;
  }
}
