import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import {
  IPhoneVerificationData,
  PhoneVerificationSubStatus,
  PhoneVerificationVerifyCode,
  User,
  getCurrentPublisher,
  showErrorToastNotification,
} from '../../../../../@data';
import { deepClone } from '../../../../../utils';
import messages from '../../ProfilePage.messages';
import {
  setIsCodeBeingVerified,
  setIsVerificationSuccess,
  setPhoneVerificationResp,
  setProfileErrorsAction,
  verifyGeneratedCode,
} from '../actions';
import { verifyGeneratedCodeAsync } from '../services/verifyGeneratedCodeAsync';
import { getProfileStore } from '../store/store';

orchestrator(verifyGeneratedCode, async ({ profile, formatMessage }) => {
  try {
    setIsCodeBeingVerified(true);

    const publisher = getCurrentPublisher();

    if (!publisher) {
      return Promise.reject('No publisher found');
    }

    const newUserDetails: User = deepClone(profile.user);
    if (!newUserDetails) {
      throw new Error('User details are missing');
    }

    const phoneVerificationVerifyCode: PhoneVerificationVerifyCode = profile.phoneVerificationVerifyCode;

    // code
    // verification method

    const result: IPhoneVerificationData = await verifyGeneratedCodeAsync(phoneVerificationVerifyCode, publisher);
    setPhoneVerificationResp(result);

    // need to check subStatus other options
    await when(
      () => {
        const subStatus = getProfileStore().active.phoneVerificationResp?.subStatus;

        return (
          subStatus === PhoneVerificationSubStatus.codeMatched ||
          subStatus === PhoneVerificationSubStatus.codeMismatched ||
          subStatus === PhoneVerificationSubStatus.expired
        );
      },
      () => {
        const subStatus = getProfileStore().active.phoneVerificationResp?.subStatus;
        if (subStatus === PhoneVerificationSubStatus.codeMatched) {
          setIsCodeBeingVerified(false);
          setIsVerificationSuccess(true);
        } else if (subStatus === PhoneVerificationSubStatus.codeMismatched) {
          setProfileErrorsAction({ 'user.verification.code': formatMessage(messages.generatedCodeInvalid) });
        } else {
          setProfileErrorsAction({ 'user.verification.code': formatMessage(messages.generatedCodeExpired) });
        }
      }
    );
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: messages.verifyingGeneratedCodeFailed });
    Log.error(error);
  } finally {
    setIsCodeBeingVerified(false);
  }
});
