import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { injectIntlAndObserver } from '../../../../../../utils';
import { isButtonPressKey } from '../../../../../../utils/AccessibilityUtils';
import { getClassNames } from './ResourceCard.styles';

export interface IResourceCardProps {
  icon?: string;
  iconAlt?: string;
  title: string;
  description: string;
  onClick?: () => void;
  wrapperRef?: React.RefObject<HTMLDivElement>;
  handleIconClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ResourceCard = injectIntlAndObserver<InjectedIntlProps & IResourceCardProps>(({ intl, ...props }) => {
  const { icon, iconAlt, title, description, onClick, handleIconClick, wrapperRef } = props;
  const { container } = getClassNames();

  return (
    <div
      ref={wrapperRef}
      className={container}
      onClick={onClick}
      onKeyDown={(ev) => isButtonPressKey(ev.key) && onClick?.()}
      tabIndex={onClick ? 0 : -1}
      aria-label={title + ' ' + description}
    >
      {(!!icon || !!iconAlt) && <img src={icon} alt={iconAlt} onClick={handleIconClick} />}

      <div>
        <span>{description}</span>
        <span>{title}</span>
      </div>
    </div>
  );
});

export default ResourceCard;
