import { useEffect, useState } from 'react';

export enum InventoryPageTabs {
  Sites = 'sites',
  Apps = 'apps',
}

type UseEntityTypeManage = ({
  isFeatureEnabled,
}: {
  isFeatureEnabled?: boolean;
}) => {
  activeSubTab: InventoryPageTabs;
  setActiveSubTab: (tab: InventoryPageTabs) => void;
};

export const useEntityTypeManage: UseEntityTypeManage = ({ isFeatureEnabled }) => {
  const [activeSubTab, setActiveSubTab] = useState<InventoryPageTabs>(InventoryPageTabs.Sites);

  useEffect(() => {
    if (window.location.search.includes('view=app')) {
      setActiveSubTab(InventoryPageTabs.Apps);
    }
  }, []);

  return {
    activeSubTab: isFeatureEnabled ? activeSubTab : InventoryPageTabs.Sites,
    setActiveSubTab,
  };
};
