import { IContextualMenuProps } from '@fluentui/react';
import { getAppStore } from '../../../../../@data';
import AppMessages, { default as appMessages } from '../../../../../App.messages';
import { onDownloadButtonClicked } from '../../../@data/actions/commandBarActions';
import '../../../@data/mutatorActions';
import '../../../@data/orchestrators';
import { getStore } from '../../../@data/store/store';
import { CommandBarActions, DownloadOption, GridSelectionMode, IDataGridCommandBarItemProps } from '../../../GridV2.types';
import { ICustomCommandBarProps } from '../../../components/CustomCommandBar/CustomCommandBar';
import { getButtonStyles } from '../CustomCommandBar.styles';

export function getDefaultCommandBarFarItems(props: ICustomCommandBarProps): IDataGridCommandBarItemProps[] {
  if (!props.commandBarProps) {
    return [];
  }
  const { formatMessage } = props.intl;
  const { isGridReady, columns, entity } = props;

  const farItems: IDataGridCommandBarItemProps[] = [];

  const downloadItems = (type: DownloadOption) => {
    const entityInstance = new entity();
    const publisherName = getAppStore().publisher?.name ?? '';

    const filename = `${publisherName}-${entityInstance.className}.csv`;
    onDownloadButtonClicked(formatMessage, getStore().data || [], filename, columns, type);
  };
  const downloadSubMenuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: 'selected',
        text: formatMessage(appMessages.downloadSelected),
        onClick: () => downloadItems(DownloadOption.selection),
        ariaDescription: formatMessage(appMessages.downloadSelectedCmdBtnAriaDesc),
      },
      {
        key: 'page',
        text: formatMessage(appMessages.downloadPage),
        onClick: () => downloadItems(DownloadOption.page),
        ariaDescribedBy: formatMessage(appMessages.downloadPageCmdBtnAriaDesc),
      },
      {
        key: 'all',
        text: formatMessage(appMessages.downloadAll),
        onClick: () => downloadItems(DownloadOption.all),
        ariaDescribedBy: formatMessage(appMessages.downloadAllCmdBtnAriaDesc),
      },
    ],
  };

  farItems.push(
    ...[
      {
        id: 'CancelButton',
        key: 'Cancel',
        name: formatMessage(AppMessages.cancel),
        iconProps: {
          iconName: 'Cancel',
        },
        buttonStyles: getButtonStyles(),
        onClick: () => props.cancelSelection(),
        selectionModes: [GridSelectionMode.Single, GridSelectionMode.Multiple],
      },
      {
        key: CommandBarActions.download,
        name: formatMessage(appMessages.download),
        iconProps: {
          iconName: 'Download',
        },
        subMenuProps: downloadSubMenuProps,
        ariaDescription: formatMessage(appMessages.downloadCmdBtnAriaDesc),
        disabled: !isGridReady,
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        selectionModes: [GridSelectionMode.Single, GridSelectionMode.Multiple],
      },
    ]
  );

  return farItems;
}
