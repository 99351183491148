import { mutator } from 'satcheljs';
import {
  onBlockAdsDataChanged,
  onBlockedAdsExportClicked,
  onBlockedImagesExportClicked,
  onBlockSummaryEndDateChanged,
  onBlockSummaryStartDateChanged,
  onSAMBlockAdsDataChanged,
} from '../actions';
import { getStore } from '../store/store';

mutator(onBlockAdsDataChanged, (msg) => {
  getStore().active.blockSummaryData = msg.data;
});

mutator(onSAMBlockAdsDataChanged, (msg) => {
  getStore().active.samBlockSummaryData = msg.data;
});

mutator(onBlockSummaryStartDateChanged, (msg) => {
  getStore().active.blockSummaryStartDate = msg.startDate;
});

mutator(onBlockSummaryEndDateChanged, (msg) => {
  if (msg.endDate) {
    getStore().active.blockSummaryEndDate = new Date(
      msg.endDate.getFullYear(),
      msg.endDate.getMonth(),
      msg.endDate.getDate(),
      23,
      59,
      59,
      999
    );
  }
});

mutator(onBlockedAdsExportClicked, (msg) => {
  getStore().columns = msg.columns;
});

mutator(onBlockedImagesExportClicked, (msg) => {
  getStore().columns = msg.columns;
});
