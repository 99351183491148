import * as React from 'react';
import { useEffect } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { isTythonSelfServeUser } from '../../../@data';
import { RoutePrefix } from '../../../Routes';
import { FormState } from '../../../components/Form';
import { injectIntlWithObserver } from '../../../utils';
import { getTythonAppAdunitCreationRoute } from '../../../utils/routeUtils';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import './@data/mutatorActions';
import './@data/mutators';
import './@data/orchestrators';
import { CreateAdunit } from './Create/CreateAdunit';
import { ManagePage } from './Manage/ManagePage';
import CreateTythonAdunit from './TythonAdunit/CreateAdunit';
import CreateTythonAppAdUnit from './TythonAppAdUnit/CreateAdUnit';

enum Operation {
  Create,
  Edit,
}

export const AdunitPage = injectIntlWithObserver(function AdUnitPageComponent(props: RouteComponentProps & InjectedIntlProps) {
  const _tythonUser = isTythonSelfServeUser();

  const getRedirectionPage = (operation: Operation, componentProps: RouteComponentProps, isAppAdunit = false) => {
    const mode = operation === Operation.Create ? FormState.Create : FormState.Edit;
    if (_tythonUser) {
      if (isAppAdunit) {
        return <CreateTythonAppAdUnit routeProps={componentProps} mode={mode} />;
      }
      return <CreateTythonAdunit routeProps={componentProps} mode={mode} />;
    } else {
      return <CreateAdunit {...componentProps} mode={mode} />;
    }
  };

  useEffect(() => {
    onCurrentRouteChanged(RouteName.adunit, props.intl.formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Switch>
        {/* TODO: use the new TythonManagePage when ad unit serving config api is fully migrated */}
        {/* <Route exact path={`${props.match.url}`} component={_tythonUser ? TythonManagePage : ManagePage} /> */}
        <Route exact path={`${props.match.url}`} component={ManagePage} />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.adunit]}/create`}
          render={(componentProps) => getRedirectionPage(Operation.Create, componentProps)}
        />
        <Route
          exact
          path={getTythonAppAdunitCreationRoute()}
          render={(componentProps) => getRedirectionPage(Operation.Create, componentProps, true)}
        />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName.adunit}/editApp/:id`}
          render={(componentProps) => getRedirectionPage(Operation.Edit, componentProps, true)}
        />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId/${RouteName.adunit}/editApp/:id`}
          render={(componentProps) => getRedirectionPage(Operation.Edit, componentProps, true)}
        />
        <Route
          path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.adunit]}/create/:id`}
          render={(componentProps) => getRedirectionPage(Operation.Create, componentProps)}
        />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.adunit]}/edit/:id`}
          render={(componentProps) => getRedirectionPage(Operation.Edit, componentProps)}
        />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId/${RouteName[RouteName.adunit]}/edit/:id`}
          render={(componentProps) => getRedirectionPage(Operation.Edit, componentProps)}
        />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.adunit]}/edit/:id?step=:step`}
          render={(componentProps) => getRedirectionPage(Operation.Edit, componentProps)}
        />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${
            RouteName[RouteName.adunit]
          }/edit/:id?step=:step&showExtraBtns/:showExtraBtns`}
          render={(componentProps) => getRedirectionPage(Operation.Edit, componentProps)}
        />
      </Switch>
    </React.Fragment>
  );
});
