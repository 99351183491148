import { IDictionary } from './countries';

export const TIMEZONE_EN: IDictionary<string> = {
  75: '(GMT-12:00) International Date Line West',
  74: '(GMT-11:00) Midway Island',
  73: '(GMT-10:00) Hawaii',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Pacific Time (US & Canada); Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Mountain Time (US & Canada)',
  64: '(GMT-06:00) Central America',
  65: '(GMT-06:00) Central Time (US & Canada)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogota',
  62: '(GMT-05:00) Eastern Time (US & Canada)',
  63: '(GMT-05:00) Indiana (East)',
  58: '(GMT-04:00) Atlantic Time (Canada)',
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Newfoundland',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilia',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Greenland',
  53: '(GMT-02:00) Mid-Atlantic',
  51: '(GMT-01:00) Azores',
  52: '(GMT-01:00) Cape Verde Is.',
  49: '(GMT) Casablanca',
  50: '(GMT) Greenwich Mean Time ',
  44: '(GMT+01:00) West Central Africa',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Brussels',
  47: '(GMT+01:00) Belgrade',
  48: '(GMT+01:00) Amsterdam',
  38: '(GMT+02:00) Jerusalem',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) Cairo',
  42: '(GMT+02:00) Bucharest',
  43: '(GMT+02:00) Athens',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Moscow',
  36: '(GMT+03:00) Kuwait',
  37: '(GMT+03:00) Baghdad',
  33: '(GMT+03:30) Tehran',
  31: '(GMT+04:00) Baku',
  32: '(GMT+04:00) Abu Dhabi',
  30: '(GMT+04:30) Kabul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Ekaterinburg',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Kathmandu',
  23: '(GMT+06:00) Sri Jayawardenepura',
  24: '(GMT+06:00) Astana',
  25: '(GMT+06:00) Almaty',
  22: '(GMT+06:30) Rangoon',
  20: '(GMT+07:00) Krasnoyarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipei',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkutsk',
  19: '(GMT+08:00) Beijing',
  12: '(GMT+09:00) Yakutsk',
  13: '(GMT+09:00) Seoul',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adelaide',
  5: '(GMT+10:00) Vladivostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadan',
  2: '(GMT+12:00) Fiji',
  3: '(GMT+12:00) Auckland',
  1: "(GMT+13:00) Nuku'alofa",
};

export const TIMEZONE_EN_GB: IDictionary<string> = {
  75: '(GMT-12:00) International Date Line West',
  74: '(GMT-11:00) Midway Island',
  73: '(GMT-10:00) Hawaii',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Pacific Time (US & Canada); Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Mountain Time (US & Canada)',
  64: '(GMT-06:00) Central America',
  65: '(GMT-06:00) Central Time (US & Canada)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogota',
  62: '(GMT-05:00) Eastern Time (US & Canada)',
  63: '(GMT-05:00) Indiana (East)',
  58: '(GMT-04:00) Atlantic Time (Canada)',
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Newfoundland',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilia',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Greenland',
  53: '(GMT-02:00) Mid-Atlantic',
  51: '(GMT-01:00) Azores',
  52: '(GMT-01:00) Cape Verde Is.',
  49: '(GMT) Casablanca',
  50: '(GMT) Greenwich Mean Time',
  44: '(GMT+01:00) West Central Africa',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Brussels',
  47: '(GMT+01:00) Belgrade',
  48: '(GMT+01:00) Amsterdam',
  38: '(GMT+02:00) Jerusalem',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) Cairo',
  42: '(GMT+02:00) Bucharest',
  43: '(GMT+02:00) Athens',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Moscow',
  36: '(GMT+03:00) Kuwait',
  37: '(GMT+03:00) Baghdad',
  33: '(GMT+03:30) Tehran',
  31: '(GMT+04:00) Baku',
  32: '(GMT+04:00) Abu Dhabi',
  30: '(GMT+04:30) Kabul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Ekaterinburg',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Kathmandu',
  23: '(GMT+06:00) Sri Jayawardenepura',
  24: '(GMT+06:00) Astana',
  25: '(GMT+06:00) Almaty',
  22: '(GMT+06:30) Rangoon',
  20: '(GMT+07:00) Krasnoyarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipei',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkutsk',
  19: '(GMT+08:00) Beijing',
  12: '(GMT+09:00) Yakutsk',
  13: '(GMT+09:00) Seoul',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adelaide',
  5: '(GMT+10:00) Vladivostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadan',
  2: '(GMT+12:00) Fiji',
  3: '(GMT+12:00) Auckland',
  1: "(GMT+13:00) Nuku'alofa",
};

export const TIMEZONE_FR: IDictionary<string> = {
  75: '(GMT-12:00) Ligne de changement de date international Ouest',
  74: '(GMT-11:00) Île Midway',
  73: '(GMT-10:00) Hawaï',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Heure du Pacifique (États-Unis et Canada) ; Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Heure des Rocheuses (États-Unis et Canada)',
  64: '(GMT-06:00) Amérique centrale',
  65: '(GMT-06:00) Heure du Centre (États-Unis et Canada)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogota',
  62: '(GMT-05:00) Heure de l’Est (États-Unis et Canada)',
  63: '(GMT-05:00) Indiana (Est)',
  58: '(GMT-04:00) Heure de l’Atlantique (Canada)',
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Terre-Neuve',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilia',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Groenland',
  53: '(GMT-02:00) Centre de l’Atlantique',
  51: '(GMT-01:00) Açores',
  52: '(GMT-01:00) Le Cap-Vert est',
  49: '(GMT) Casablanca',
  50: '(GMT) Méridien de Greenwich',
  44: '(GMT+01:00) Afrique de l’Ouest et du Centre',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Bruxelles',
  47: '(GMT+01:00) Belgrade',
  48: '(GMT+01:00) Amsterdam',
  38: '(GMT+02:00) Jérusalem',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) Le Caire',
  42: '(GMT+02:00) Bucharest',
  43: '(GMT+02:00) Athènes',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Moscou',
  36: '(GMT+03:00) Koweït',
  37: '(GMT+03:00) Bagdad',
  33: '(GMT+03:30) Téhéran',
  31: '(GMT+04:00) Bakou',
  32: '(GMT+04:00) Abou Dhabi',
  30: '(GMT+04:30) Kaboul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Ekaterinburg',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Katmandou',
  23: '(GMT+06:00) Sri Jayawardenepura',
  24: '(GMT+06:00) Astana',
  25: '(GMT+06:00) Almaty',
  22: '(GMT+06:30) Rangoon',
  20: '(GMT+07:00) Krasnoïarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipei',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkoutsk',
  19: '(GMT+08:00) Beijing',
  12: '(GMT+09:00) Yakoutsk',
  13: '(GMT+09:00) Séoul',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adélaïde',
  5: '(GMT+10:00) Vladivostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadan',
  2: '(GMT+12:00) Fidji',
  3: '(GMT+12:00) Auckland',
  1: "(GMT+13:00) Nuku'alofa",
};

export const TIMEZONE_DE: IDictionary<string> = {
  75: '(GMT-12:00) Internationale Datumsgrenze West',
  74: '(GMT-11:00) Midway-Insel',
  73: '(GMT-10:00) Hawaii',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Pazifikzeit (USA & Kanada); Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Gebirgszeit (USA & Kanada)',
  64: '(GMT-06:00) Zentralamerika',
  65: '(GMT-06:00) Zentralzeit (USA & Kanada)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogota',
  62: '(GMT-05:00) Ostzeit (USA & Kanada)',
  63: '(GMT-05:00) Indiana (Ost)',
  58: '(GMT-04:00) Atlantikzeit (Kanada)',
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Neufundland',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilien',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Grönland',
  53: '(GMT-02:00) Mittelatlantik',
  51: '(GMT-01:00) Azoren',
  52: '(GMT-01:00) Kap Verde',
  49: '(GMT) Casablanca',
  50: '(GMT) Greenwich Mean Time',
  44: '(GMT+01:00) Westafrika',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Brüssel',
  47: '(GMT+01:00) Belgrad',
  48: '(GMT+01:00) Amsterdam',
  38: '(GMT+02:00) Jerusalem',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) Kairo',
  42: '(GMT+02:00) Bukarest',
  43: '(GMT+02:00) Athen',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Moskau',
  36: '(GMT+03:00) Kuwait',
  37: '(GMT+03:00) Bagdad',
  33: '(GMT+03:30) Teheran',
  31: '(GMT+04:00) Baku',
  32: '(GMT+04:00) Abu Dhabi',
  30: '(GMT+04:30) Kabul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Jekaterinburg',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Kathmandu',
  23: '(GMT+06:00) Sri Jayawardenepura',
  24: '(GMT+06:00) Astana',
  25: '(GMT+06:00) Almaty',
  22: '(GMT+06:30) Rangun',
  20: '(GMT+07:00) Krasnojarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipeh',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkutsk',
  19: '(GMT+08:00) Peking',
  12: '(GMT+09:00) Jakutsk',
  13: '(GMT+09:00) Seoul',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adelaide',
  5: '(GMT+10:00) Wladiwostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadan',
  2: '(GMT+12:00) Fiji',
  3: '(GMT+12:00) Auckland',
  1: "(GMT+13:00) Nuku'alofa",
};

export const TIMEZONE_IT: IDictionary<string> = {
  75: '(GMT-12:00) Linea internazionale del cambio di data ovest',
  74: '(GMT-11:00) Isola di Midway',
  73: '(GMT-10:00) Hawaii',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Ora del Pacifico (USA e Canada); Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Ora delle Montagne (USA e Canada)',
  64: '(GMT-06:00) America centrale',
  65: '(GMT-06:00) Ora centrale (USA e Canada)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogotà',
  62: '(GMT-05:00) Ora orientale (USA e Canada)',
  63: '(GMT-05:00) Indiana (Est)',
  58: '(GMT-04:00) Ora dell’Atlantico (Canada)',
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Terranova',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilia',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Groenlandia',
  53: '(GMT-02:00) Atlantico centrale',
  51: '(GMT-01:00) Azzorre',
  52: '(GMT-01:00) Capo Verde',
  49: '(GMT) Casablanca',
  50: '(GMT) Ora media di Greenwich',
  44: '(GMT+01:00) Africa occidentale centrale',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Bruxelles',
  47: '(GMT+01:00) Belgrado',
  48: '(GMT+01:00) Amsterdam',
  38: '(GMT+02:00) Gerusalemme',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) Il Cairo',
  42: '(GMT+02:00) Bucarest',
  43: '(GMT+02:00) Atene',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Mosca',
  36: '(GMT+03:00) Kuwait',
  37: '(GMT+03:00) Baghdad',
  33: '(GMT+03:30) Teheran',
  31: '(GMT+04:00) Baku',
  32: '(GMT+04:00) Abu Dhabi',
  30: '(GMT+04:30) Kabul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Ekaterinburg',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Kathmandu',
  23: '(GMT+06:00) Sri Jayawardenepura',
  24: '(GMT+06:00) Astana',
  25: '(GMT+06:00) Almaty',
  22: '(GMT+06:30) Rangoon',
  20: '(GMT+07:00) Krasnojarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipei',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkutsk',
  19: '(GMT+08:00) Pechino',
  12: '(GMT+09:00) Yakutsk',
  13: '(GMT+09:00) Seoul',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adelaide',
  5: '(GMT+10:00) Vladivostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadan',
  2: '(GMT+12:00) Figi',
  3: '(GMT+12:00) Auckland',
  1: "(GMT+13:00) Nuku'alofa",
};

export const TIMEZONE_FR_CA: IDictionary<string> = {
  75: "(GMT-12:00) Ligne de changement de date internationale à l'ouest",
  74: '(GMT-11:00) Île de Midway',
  73: '(GMT-10:00) Hawaï',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Heure du Pacifique (États-Unis et Canada); Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Heure des Rocheuses (États-Unis et Canada)',
  64: '(GMT-06:00) Amérique centrale',
  65: '(GMT-06:00) Heure centrale (États-Unis et Canada)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogota',
  62: "(GMT-05:00) Heure de l'Est (États-Unis et Canada)",
  63: '(GMT-05:00) Indiana (Est)',
  58: "(GMT-04:00) Heure de l'Atlantique (Canada)",
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Terre-Neuve',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilia',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Groenland',
  53: '(GMT-02:00) Atlantique-Centre',
  51: '(GMT-01:00) Açores',
  52: '(GMT-01:00) Îles du Cap-Vert',
  49: '(GMT) Casablanca',
  50: '(GMT) Temps moyen de Greenwich',
  44: '(GMT+01:00) Afrique centrale occidentale',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Bruxelles',
  47: '(GMT+01:00) Belgrade',
  48: '(GMT+01:00) Amsterdam',
  38: '(GMT+02:00) Jérusalem',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) Le Caire',
  42: '(GMT+02:00) Bucarest',
  43: '(GMT+02:00) Athènes',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Moscou',
  36: '(GMT+03:00) Koweït',
  37: '(GMT+03:00) Bagdad',
  33: '(GMT+03:30) Téhéran',
  31: '(GMT+04:00) Bakou',
  32: '(GMT+04:00) Abou Dabi',
  30: '(GMT+04:30) Kaboul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Ekaterinbourg',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Katmandou',
  23: '(GMT+06:00) Sri Jayawardenapura',
  24: '(GMT+06:00) Astana',
  25: '(GMT+06:00) Almaty',
  22: '(GMT+06:30) Rangoun',
  20: '(GMT+07:00) Krasnoïarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipei',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkoutsk',
  19: '(GMT+08:00) Pékin',
  12: '(GMT+09:00) Iakoutsk',
  13: '(GMT+09:00) Séoul',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adélaïde',
  5: '(GMT+10:00) Vladivostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadan',
  2: '(GMT+12:00) Fidji',
  3: '(GMT+12:00) Auckland',
  1: "(GMT+13:00) Nuku'alofa",
};

export const TIMEZONE_ES_ES: IDictionary<string> = {
  75: '(GMT-12:00) Línea Internacional de Cambio de Fecha (Oeste)',
  74: '(GMT-11:00) Isla Midway',
  73: '(GMT-10:00) Hawái',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Hora del Pacífico (EE. UU. y Canadá); Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Hora de la Montaña (EE. UU. y Canadá)',
  64: '(GMT-06:00) América Central',
  65: '(GMT-06:00) Hora Central (EE. UU. y Canadá)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogotá',
  62: '(GMT-05:00) Hora del Este (EE. UU. y Canadá)',
  63: '(GMT-05:00) Indiana (Este)',
  58: '(GMT-04:00) Hora del Atlántico (Canadá)',
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Terranova',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilia',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Groenlandia',
  53: '(GMT-02:00) Atlántico Medio',
  51: '(GMT-01:00) Azores',
  52: '(GMT-01:00) Isla de Cabo Verde',
  49: '(GMT) Casablanca',
  50: '(GMT) Hora del Meridiano de Greenwich',
  44: '(GMT+01:00) África Occidental Central',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Bruselas',
  47: '(GMT+01:00) Belgrado',
  48: '(GMT+01:00) Ámsterdam',
  38: '(GMT+02:00) Jerusalén',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) El Cairo',
  42: '(GMT+02:00) Bucarest',
  43: '(GMT+02:00) Atenas',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Moscú',
  36: '(GMT+03:00) Kuwait',
  37: '(GMT+03:00) Bagdad',
  33: '(GMT+03:30) Teherán',
  31: '(GMT+04:00) Bakú',
  32: '(GMT+04:00) Abu Dabi',
  30: '(GMT+04:30) Kabul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Ekaterimburgo',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Katmandú',
  23: '(GMT+06:00) Sri Jayawardenepura',
  24: '(GMT+06:00) Astaná',
  25: '(GMT+06:00) Almaty',
  22: '(GMT+06:30) Rangún',
  20: '(GMT+07:00) Krasnoyarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipéi',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkutsk',
  19: '(GMT+08:00) Pekín',
  12: '(GMT+09:00) Yakutsk',
  13: '(GMT+09:00) Seúl',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adelaida',
  5: '(GMT+10:00) Vladivostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadán',
  2: '(GMT+12:00) Fiyi',
  3: '(GMT+12:00) Auckland',
  1: '(GMT+13:00) Nukualofa',
};

export const TIMEZONE_ES_MX: IDictionary<string> = {
  75: '(GMT-12:00) Línea Internacional de Cambio de Fecha Oeste',
  74: '(GMT-11:00) Isla Midway',
  73: '(GMT-10:00) Hawái',
  72: '(GMT-09:00) Alaska',
  71: '(GMT-08:00) Hora del Pacífico (EE. UU. y Canadá); Tijuana',
  68: '(GMT-07:00) Arizona',
  69: '(GMT-07:00) Chihuahua',
  70: '(GMT-07:00) Hora de la Montaña (EE. UU. y Canadá)',
  64: '(GMT-06:00) Centroamérica',
  65: '(GMT-06:00) Hora Central (EE. UU. y Canadá)',
  66: '(GMT-06:00) Guadalajara',
  67: '(GMT-06:00) Saskatchewan',
  61: '(GMT-05:00) Bogotá',
  62: '(GMT-05:00) Hora del Este (EE. UU. y Canadá)',
  63: '(GMT-05:00) Indiana (Este)',
  58: '(GMT-04:00) Hora Atlántica (Canadá)',
  59: '(GMT-04:00) Caracas',
  57: '(GMT-03:30) Terranova',
  60: '(GMT-03:00) Santiago',
  54: '(GMT-03:00) Brasilia',
  55: '(GMT-03:00) Buenos Aires',
  56: '(GMT-03:00) Groenlandia',
  53: '(GMT-02:00) Atlántico Medio',
  51: '(GMT-01:00) Azores',
  52: '(GMT-01:00) Islas de Cabo Verde',
  49: '(GMT) Casablanca',
  50: '(GMT) Tiempo Medio de Greenwich',
  44: '(GMT+01:00) África Central Occidental',
  45: '(GMT+01:00) Sarajevo',
  46: '(GMT+01:00) Bruselas',
  47: '(GMT+01:00) Belgrado',
  48: '(GMT+01:00) Ámsterdam',
  38: '(GMT+02:00) Jerusalén',
  39: '(GMT+02:00) Helsinki',
  40: '(GMT+02:00) Harare',
  41: '(GMT+02:00) El Cairo',
  42: '(GMT+02:00) Bucarest',
  43: '(GMT+02:00) Atenas',
  34: '(GMT+03:00) Nairobi',
  35: '(GMT+03:00) Moscú',
  36: '(GMT+03:00) Kuwait',
  37: '(GMT+03:00) Bagdad',
  33: '(GMT+03:30) Teherán',
  31: '(GMT+04:00) Bakú',
  32: '(GMT+04:00) Abu Dabi',
  30: '(GMT+04:30) Kabul',
  28: '(GMT+05:00) Islamabad',
  29: '(GMT+05:00) Ekaterimburgo',
  27: '(GMT+05:30) Chennai',
  26: '(GMT+05:45) Katmandú',
  23: '(GMT+06:00) Sri Jayawardenepura',
  24: '(GMT+06:00) Astaná',
  25: '(GMT+06:00) Almatý',
  22: '(GMT+06:30) Rangún',
  20: '(GMT+07:00) Krasnoyarsk',
  21: '(GMT+07:00) Bangkok',
  15: '(GMT+08:00) Taipéi',
  16: '(GMT+08:00) Perth',
  17: '(GMT+08:00) Kuala Lumpur',
  18: '(GMT+08:00) Irkutsk',
  19: '(GMT+08:00) Pekín',
  12: '(GMT+09:00) Yakutsk',
  13: '(GMT+09:00) Seúl',
  14: '(GMT+09:00) Osaka',
  10: '(GMT+09:30) Darwin',
  11: '(GMT+09:30) Adelaida',
  5: '(GMT+10:00) Vladivostok',
  6: '(GMT+10:00) Hobart',
  7: '(GMT+10:00) Guam',
  8: '(GMT+10:00) Canberra',
  9: '(GMT+10:00) Brisbane',
  4: '(GMT+11:00) Magadán',
  2: '(GMT+12:00) Fiyi',
  3: '(GMT+12:00) Auckland',
  1: '(GMT+13:00) Nukualofa',
};

export const TIMEZONE_LANGUAGE_MAPPING = {
  'en-us': TIMEZONE_EN,
  'en-gb': TIMEZONE_EN_GB,
  'fr-fr': TIMEZONE_FR,
  'de-de': TIMEZONE_DE,
  'it-it': TIMEZONE_IT,
  'fr-ca': TIMEZONE_FR_CA,
  'es-es': TIMEZONE_ES_ES,
  'es-mx': TIMEZONE_ES_MX,
};
