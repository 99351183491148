import { Model, getAppStore } from '../../../../../@data';
import { CommandBarActions, IBuildCommandBarProps } from '../../../../../layout/GridPageV2';
import { getStore } from '../../@data/store';
import messages from '../ManagePage.messages';

export function getCommandBarItems(): IBuildCommandBarProps<Model> | undefined {
  const { publisher } = getAppStore();
  const { publishers, isLoading } = getStore();

  const commandBarItems: IBuildCommandBarProps<Model> | undefined =
    (publishers && publishers.length !== 0 && publisher) || isLoading
      ? {
          primaryButtonLabel: messages.create,
          includeActions: [CommandBarActions.new],
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.edit,
            CommandBarActions.deactivate,
            CommandBarActions.download,
            CommandBarActions.modifyColumns,
          ],
          deactivateLabels: {
            title: messages.deactivateTitle,
          },
        }
      : undefined;

  return commandBarItems;
}
