import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { getAppStore, getEntity, onNotificationChanged, Publisher } from '../../../../../@data';
import { onFetchingPublishersData, onFetchPublishersLoading, onPublishersFetched } from '../actions';
import { onManagePageInitialized } from '../actions/onManagePageInitialized';
import { getStore } from '../store';

orchestrator(onManagePageInitialized, async () => {
  try {
    const { publisher, userContext } = getAppStore();
    const publishers = getStore().publishers;

    if (publishers === undefined || publishers.length === 0) {
      onFetchPublishersLoading(true);
    }
    onFetchingPublishersData(true);

    if (userContext && publisher) {
      const data = await getEntity([publisher], userContext);
      const fetchedPublisher: Publisher = {
        apiPath: undefined,
        className: 'publishers',
        id: data.id,
        name: data.name?.toString(),
        status: data.status,
      };
      onPublishersFetched([fetchedPublisher]);
    } else {
      onPublishersFetched([]);
    }
  } catch (error) {
    Log.error(error);
    onNotificationChanged({ messageBarType: MessageBarType.error, text: 'Failed to load publishers' });
  } finally {
    onFetchPublishersLoading(false);
    onFetchingPublishersData(false);
  }
});
