import { getTheme, IStackTokens, ITheme, mergeStyleSets } from '@fluentui/react';
import { AccessibilityContrast, IPubcenterPalette, IPubcenterTheme } from '../../../../../theme';

export interface IReportCardStyleProps {
  theme: ITheme;
}

type StyleProperties = 'active' | 'metricsDropdown' | 'cardWrapper' | 'buttonBorder' | 'cardsTitleBarStyle' | 'linkStyle';
type ReportCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: IReportCardStyleProps): ReportCardStyles => {
  const { theme } = props;
  const palette = theme.palette as IPubcenterPalette;
  const { Activated } = AccessibilityContrast;

  return mergeStyleSets({
    active: {
      backgroundColor: palette.themePrimary,
      color: palette.themeLight,
      ':hover': {
        backgroundColor: palette.themePrimary,
        color: palette.themeLight,
      },
      ':active': {
        backgroundColor: palette.themePrimary,
        color: palette.themeLight,
      },
      [Activated]: {
        color: 'window',
        backgroundColor: 'windowtext',
        borderColor: 'windowtext',
        forcedColorAdjust: 'none',
      },
    },
    metricsDropdown: {
      width: '30%',
      paddingTop: '15px',
      paddingBottom: '15px',
    },
    cardWrapper: {
      height: '100%',
    },
    buttonBorder: {
      border: '1px solid',
      borderRadius: '2px',
      borderColor: palette.neutralLight,
    },
    cardsTitleBarStyle: {
      borderBottom: `1px solid ${palette.neutralLight}`,
      boxSizing: 'border-box',
    },
    linkStyle: {
      color: theme.palette.themePrimary,
      textDecoration: 'none',
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
export function getMetricPickerStackTokens(): IStackTokens {
  return { childrenGap: `40px 20px` };
}
