import { AWTEventPriority, AWTEventProperties, AWTLogManager, AWTSessionState } from '@aria/webjs-sdk';
import { ariaConfig } from './Aria.Config';
import { Environment } from './Environment';
import { ILogManager } from "./ILogManager";
import { ILogUserContext } from "./ILogUserContext";
import { LoggingUtils } from "./LoggingUtils";
import { LogType } from "./LogType";
import { UserConstants } from "./UserConstants";

export class AriaLoggerManager implements ILogManager {
  private static AriaLogger;

  private static _getAriaToken(logParams): string {
    let ariaToken = '';
    // Read aria token from logParams, if its non empty
    if (Object.keys(logParams).length !== 0) {
      ariaToken = logParams.ariaToken;
      return ariaToken;
    }

    const environment = LoggingUtils.getEnvironment();

    switch (environment) {
      case Environment.TEST:
        ariaToken = ariaConfig.ariaTokenTest;
        break;

      case Environment.SI:
        ariaToken = ariaConfig.ariaTokenSI;
        break;

      case Environment.BCP:
        ariaToken = ariaConfig.ariaTokenBCP;
        break;

      case Environment.PROD:
        ariaToken = ariaConfig.ariaTokenProd;
        break;

      default:
        ariaToken = ariaConfig.ariaTokenSI;
        break;
    }

    return ariaToken;
  }

  private static _initalizeAriaEnv(ariaToken, AriaLogger) {
    try{
      AWTLogManager.initialize(ariaToken);
      AWTLogManager.setTransmitProfile(ariaConfig.transmitProfile);

      if(AriaLogger === undefined) {
        AriaLogger = AWTLogManager.getLogger(ariaToken);
      }

      // Initialize the session
      // This enables populating 'sessionID' into the ARIA logs
      AriaLogger.logSession(AWTSessionState.Started);
    } catch (e) {
      console.log('Failed to initialize ARIA environment with error ', e);
    }

    return AriaLogger;
  }

  constructor(logParams) {
    const ariaToken = AriaLoggerManager._getAriaToken(logParams);
    AriaLoggerManager.AriaLogger = AriaLoggerManager._initalizeAriaEnv(ariaToken, AriaLoggerManager.AriaLogger);
  }

  public log(logType: LogType, logMessage: string | number | object, logUserContext: ILogUserContext): void {
    this._ariaLog(logType, logMessage, logUserContext);
  }

  private _setProperties(logUserContext: ILogUserContext,  eventProperties: AWTEventProperties): void {
    eventProperties.setProperty(UserConstants.publisherId, logUserContext.publisherId);
    eventProperties.setProperty(UserConstants.publisherName, logUserContext.publisherName);
    eventProperties.setProperty(UserConstants.userName, logUserContext.userName);
    eventProperties.setProperty(UserConstants.userId, logUserContext.userId);
    eventProperties.setProperty(UserConstants.userEmail, logUserContext.userEmail);
  }

  private _ariaLog(logType: LogType, logMessage: string | number | object, logUserContext: ILogUserContext): void {
    const eventProperties = new AWTEventProperties(logType);
    this._setProperties(logUserContext, eventProperties);
    eventProperties.setProperty(UserConstants.logMessage, JSON.stringify(logMessage));

    switch(logType) {

      case 'DEBUG': {
        eventProperties.setEventPriority(AWTEventPriority.Normal);
        eventProperties.setProperty(UserConstants.logLevel, 'DEBUG');
        break;
      }

      case 'INFO': {
        eventProperties.setEventPriority(AWTEventPriority.Normal);
        eventProperties.setProperty(UserConstants.logLevel, 'INFO');
        break;
      }

      case 'WARN': {
        eventProperties.setEventPriority(AWTEventPriority.High);
        eventProperties.setProperty(UserConstants.logLevel, 'WARN');
        break;
      }

      case 'ERROR': {
        eventProperties.setEventPriority(AWTEventPriority.Immediate_sync);
        eventProperties.setProperty(UserConstants.logLevel, 'ERROR');
        break;
      }

      default: {
        eventProperties.setEventPriority(AWTEventPriority.Normal);
        break;
      }
    }

      AriaLoggerManager.AriaLogger.logEvent(eventProperties);
    };
}