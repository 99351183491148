import { mutator } from 'satcheljs';
import { onDevTokenPanelHide, onDevTokenPanelShown } from '../actions';
import { getStore } from '../store/store';

mutator(onDevTokenPanelShown, (msg) => {
  const store = getStore();
  store.devTokenPanelParams = msg.devTokenPanelParams;
});

mutator(onDevTokenPanelHide, () => {
  const store = getStore();
  const devtokenparam = store.devTokenPanelParams;
  if (devtokenparam) {
    devtokenparam.panelClosed = true;
    devtokenparam.SpinnerText = 'Fetching Developer Token...';
  }
  store.devTokenPanelParams = devtokenparam;
});
