import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import {
  EntityType,
  getAppStore,
  NotificationPreference,
  NotificationPreferencesUpdate,
  NotificationType,
  onLoadingChanged,
  onNotificationChanged,
  patchEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
  User,
} from '../../../../../../@data';
import messages from '../../SettingsPage.messages';
import { onUpdateNotificationPreferences } from '../actions/onNotificationPreferencesChanged';
import { setNotificationPreferences } from '../mutatorActions';

orchestrator(onUpdateNotificationPreferences, async ({ entityType, np }) => {
  Log.debug('Updating notification preferences');
  const { publisher, userContext } = getAppStore();

  if (publisher && userContext) {
    const notifications: NotificationPreference[] = [];

    if (np.billingAlerts !== undefined) {
      notifications.push({ type: NotificationType.BillingAlert, enabled: np.billingAlerts });
    }
    if (np.servingStatus !== undefined) {
      notifications.push({ type: NotificationType.ServingStatus, enabled: np.servingStatus });
    }
    if (np.profileReview !== undefined) {
      notifications.push({ type: NotificationType.ProfileReview, enabled: np.profileReview });
    }
    if (np.invitedUsers !== undefined) {
      notifications.push({ type: NotificationType.InvitedUser, enabled: np.invitedUsers });
    }

    const notificationPreferencesUpdate: NotificationPreferencesUpdate = new NotificationPreferencesUpdate();
    notificationPreferencesUpdate.notificationPreferences = {
      notifications: notifications,
    };

    try {
      onLoadingChanged(true);
      const pathModels = [publisher];

      if (entityType === EntityType.User) {
        const user = new User(userContext.id);
        pathModels.push(user);
      }
      await patchEntity(pathModels, NotificationPreferencesUpdate, notificationPreferencesUpdate, userContext);

      setNotificationPreferences(np);

      showSuccessToastNotification({ textMessageDescriptor: messages.updatedNotificationPreferencesSuccessfully });
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.notificationPreferencesFailed });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.invalidStateLoginOrReport,
    });
  }
});
