import { useEffect, useState } from 'react';

type MeasureStyle = {
  fontStyle?: string;
  fontVariant?: string;
  fontWeight?: string | number;
  fontSize?: string;
  lineHeight?: string;
  fontFamily?: string;
};

export const defaultGridCellTextStyle: MeasureStyle = {
  fontSize: '14px',
  fontWeight: 400,
};

/**
 *  const longestNameLength = useCanvasTextWidth(
    'Ad Unit lonnnnnnnnnnnnnnnnnnnnnnnnnnnnnnng name', // longest string or any string you want to measure
    {
      fontSize: '14px',
      fontWeight: 400,
    },
    20 // boundaries to add in px, this can used to add padding/margin to the text
  );
  console.log(longestNameLength)
 */

export const useCanvasTextWidth = (text: string, style: MeasureStyle = defaultGridCellTextStyle, boundary: number = 0) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      // Construct the font string from the style object
      const fontParts = [
        style.fontStyle || '',
        style.fontVariant || '',
        style.fontWeight || '',
        style.fontSize || '16px',
        style.lineHeight || '',
        style.fontFamily ||
          '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      ];
      context.font = fontParts.filter(Boolean).join(' ');

      const metrics = context.measureText(text);
      setWidth(metrics.width);
    }
  }, [text, style]);

  // TODO: make it a util function as well
  return width + boundary;
};
