import { defineMessages } from 'react-intl';

export default defineMessages({
  accordionCheckBoxArialLabelChecked: {
    id: 'accordion.checkbox.arial.checked',
    defaultMessage: 'Toggle checkbox to uncheck and collapse accordion for {title}',
  },
  accordionCheckBoxArialLabelUnchecked: {
    id: 'accordion.checkbox.arial.unchecked',
    defaultMessage: 'Toggle checkbox to enable and expand accordion for {title}',
  },
});
