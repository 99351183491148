import { defineMessages } from 'react-intl';

export default defineMessages({
  sites: {
    id: 'app.components.EntityTypeSwitch.sites',
    defaultMessage: 'Sites',
  },
  apps: {
    id: 'app.components.EntityTypeSwitch.apps',
    defaultMessage: 'Apps',
  },
});
