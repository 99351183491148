import { action } from 'satcheljs';
import { Filter, IStringDictionary } from '../../../../../@data';
import { IFilterViewModel } from '../store/schema';

export const onRawChanged = action('onRawChanged', (value?: Filter) => ({ value }));

export const onFilterChanged = action('onFilterChanged', (filter: IFilterViewModel) => ({ filter }));

export const onFilterErrorChanged = action('onFilterErrorChanged', (values?: IStringDictionary) => ({ values }));

export const onFetchFiltersLoading = action('onFetchFiltersLoading', (isLoading: boolean) => ({ isLoading }));

export const onFetchingFiltersData = action('onFetchingFiltersData', (isFetchingData: boolean) => ({ isFetchingData }));

export const onFiltersFetched = action('onFiltersFetched', (items: Filter[]) => ({ items }));
