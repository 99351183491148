import { TextField } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { FormItem } from '../../../../../../components/Form';
import { useWindowSize } from '../../../../../../hooks';
import { getStore } from '../../../../../../hooks/useWindowSize/store/store';
import { injectIntlAndObserver } from '../../../../../../utils';
import messages from '../../CreateAdunit/messages';

export interface IAdUnitNameInputFieldProps {
  errorMsg?: string;
  value?: string;
  onChange: (_, value: string) => void;
  disabled: boolean;
  shouldRenderComponent: boolean;
}

const { windowStoreSerializer } = useWindowSize();

const AdUnitNameInputField = injectIntlAndObserver<InjectedIntlProps & IAdUnitNameInputFieldProps>(
  ({ intl: { formatMessage }, errorMsg, value, onChange, disabled, shouldRenderComponent }) => {
    const { isWindowSmallSize } = windowStoreSerializer(getStore());

    if (!shouldRenderComponent) {
      return null;
    }
    return (
      <FormItem formWidth={isWindowSmallSize ? 324 : 440}>
        <TextField
          label={formatMessage(messages.adunitNameLabel)}
          required={true}
          errorMessage={errorMsg}
          value={value}
          onChange={onChange}
          disabled={disabled}
          data-test-id="adunit-name-input"
        />
      </FormItem>
    );
  }
);

export default AdUnitNameInputField;
