import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing, IPubcenterTheme } from '../../../../theme';
import { IListFilterClassNames } from './ListFilter.types';

export const getClassNames = (): IListFilterClassNames => {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    content: {
      display: 'flex',
      flexDirection: 'row',
    },
    buttonSection: {
      marginLeft: 'auto',
    },
    checkBoxRow: {
      marginTop: spacing.size10,
      marginBottom: spacing.size10,
    },
  });
};
