import { defineMessages } from 'react-intl';
export default defineMessages({
  domainStringValidator: {
    id: 'yup.domain',
    defaultMessage: 'Invalid domain name',
  },
  duplicateDomainStringValidator: {
    id: 'yup.duplicateDomain',
    defaultMessage: 'Site domain name already exists',
  },
  nameStringValidator: {
    id: 'yup.name',
    defaultMessage: 'Invalid name',
  },
  duplicateNameStringValidator: {
    id: 'yup.duplicateName',
    defaultMessage: 'Site name already exists',
  },
});
