import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../../../logging/src';
import { getAppStore, onNotificationChanged, postEntity } from '../../../../../../../../@data';
import { onPopupOpenChanged, showErrorToastNotification, showSuccessToastNotification } from '../../../../../../../../@data/mutatorActions';
import { AdSenseOAuthStatus } from '../../../../../../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { PopupType } from '../../../../../../../../@data/store/schema/enums/PopupType';
import { AdSenseMediationOptIn } from '../../../../../../../../@data/store/schema/models/AdSenseMediation';
import { fetchAccountById } from '../../../../../../../partner-management/Account/@data/services/AccountService';
import { googleConnectorMessages } from '../../GoogleConnector.messages';
import { onConnectGoogleAccount } from '../actions';
import { NOTIFICATION_FAILED_WITH_REASON_ERROR_CODE } from '../constants';
import { onChangeConnection } from '../mutatorActions';

orchestrator(onConnectGoogleAccount, async ({ code }) => {
  const { publisher, account, userContext } = getAppStore();

  try {
    if (publisher && account && userContext) {
      const urlParams = new URLSearchParams();
      const accountDetails = await fetchAccountById(publisher!, account?.id, userContext);
      urlParams.append(
        'routeSuffix',
        `api/mediation/oauth/optIn?oneTimeAuthorizationCode=${code}&currencyCode=${accountDetails.currencyCode}`
      );
      const response = await postEntity([], AdSenseMediationOptIn, new AdSenseMediationOptIn(), undefined, urlParams, undefined);

      if (
        response &&
        response?.oAuthConnectionStatus &&
        AdSenseOAuthStatus[response?.oAuthConnectionStatus] === AdSenseOAuthStatus.Success
      ) {
        showSuccessToastNotification({ textMessageDescriptor: googleConnectorMessages.googleAccountConnectedSuccessfully });

        onChangeConnection({
          email: response?.email,
          isMediationEnabled: response?.mediationStatus,
          connectionStatus: AdSenseOAuthStatus[response?.oAuthConnectionStatus],
          connectedDate: response?.connectedTimeStamp,
          connectedImageSrc: response?.userImageUri,
        });
      } else if (response?.oAuthConnectionStatus && AdSenseOAuthStatus[response?.oAuthConnectionStatus] !== AdSenseOAuthStatus.Success) {
        onNotificationChanged({
          messageBarType: MessageBarType.error,
          text: response.oauthFailureReasons?.[0].description || 'Connecting to your Google account failed.',
          id: NOTIFICATION_FAILED_WITH_REASON_ERROR_CODE,
        });
        onChangeConnection({
          email: response.email,
          isMediationEnabled: response.mediationStatus,
          connectionStatus: AdSenseOAuthStatus[response.oAuthConnectionStatus],
          connectedDate: response.connectedTimeStamp,
          connectionStatusTip: response.oauthFailureReasons?.[0].description,
          actionItems: response.oauthFailureReasons,
        });
      } else {
        onNotificationChanged({
          messageBarType: MessageBarType.error,
          text: response.oauthFailureReasons?.[0].description || 'Connecting to your Google account failed.',
        });
        onChangeConnection({
          connectionStatus: AdSenseOAuthStatus.None,
        });
      }
    }
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: googleConnectorMessages.connectGoogleAccountFailed });
    Log.error(`Error while connecting google account ${error}`);
  } finally {
    onPopupOpenChanged(PopupType.None);
  }
});
