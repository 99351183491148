import { classNamesFunction, getTheme, Icon, Stack } from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { getAppStore, LayoutMode, onLayoutChanged } from '../../@data';
import { isTythonSelfServeUser } from '../../@data/utils/checkUserType';
import { FormTitle } from '../../components/Form';
import { REPORTING_DATE_RANGE_PICKER } from '../../constants/DataTestIdConstants';
import { EmptyLayout } from '../../layout/EmptyLayout/EmptyLayout';
import { injectIntlWithObserver } from '../../utils';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { onCurrentRouteChanged as routeChanged } from '../ad-management/@data/actions';
import '../ad-management/@data/orchestrators';
import { onCurrentRouteChanged, onReportLinkClicked, onSetPublisherReporting } from './@data/actions';
import './@data/mutators';
import './@data/orchestrators';
import { ReportsQueryStoreKeys } from './@data/store/schema/ReportsQueryStoreKeys';
import { getPageStore, RouteName } from './@data/store/store';
import { setDataInURL } from './@data/store/UrlQueryStore';
import { CustomCalender } from './components/CustomCalender/CustomCalender';
import { ICustomCalenderDateRange, RangeType } from './components/CustomCalender/CustomCalender.types';
import { TythonReportCards } from './DashboardCards/TythonReportCards';
import messages from './Report.messages';
import { getStyles } from './ReportPage.styles';
import { IReportPageProps, IReportPageStyleProps, IReportPageStyles } from './ReportPage.types';
import { getInitialDateRange } from './utils/getInitialDateRange';
const getClassNames = classNamesFunction<IReportPageStyleProps, IReportPageStyles>();

export const ReportPage = injectIntlWithObserver<IReportPageProps>((props: IReportPageProps) => {
  const params = props.match.params;

  const publisherId = params.publisherId;
  const { publisher } = getAppStore();
  const { isReportEnabled } = getPageStore();

  useEffect(() => {
    onCurrentRouteChanged(RouteName.dashboard, props.intl.formatMessage);
    onLayoutChanged(LayoutMode.Default);

    if (isTythonSelfServeUser()) {
      // Since ReportPage is rendered inside ad-management for Tython users, updating current route
      routeChanged(RouteName.dashboard, props.intl.formatMessage);
    }
    if (isReportEnabled === undefined) {
      if (publisherId && publisher) {
        onSetPublisherReporting(publisher);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherId, publisher, isReportEnabled]);
  const {
    dateRange: { initialStartDate, initialEndDate },
    initialDateRangeType,
  } = getInitialDateRange();

  const [dateRange, setDateRange] = React.useState<ICustomCalenderDateRange>({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });
  const classNames = getClassNames(getStyles, { theme: getTheme() });
  const { formatMessage } = props.intl;

  const onSelectedDatesChanged = (selectedDateRange: ICustomCalenderDateRange, rangeType: RangeType) => {
    setDataInURL(ReportsQueryStoreKeys.DATE_RANGE, selectedDateRange);
    setDataInURL(ReportsQueryStoreKeys.DATE_RANGE_TYPE, rangeType);
    setDateRange({ ...selectedDateRange });
  };

  return (
    <EmptyLayout>
      <div className={classNames.root}>
        <Stack styles={classNames.subComponentStyles.formTitle} horizontal horizontalAlign="space-between">
          <FormTitle title={formatMessage(messages.reportTitle)} noHelpPopup={true} intl={props.intl} />
          {isTythonSelfServeUser() && (
            <div>
              <CustomCalender
                dataTestId={REPORTING_DATE_RANGE_PICKER}
                onSelectedDatesChanged={onSelectedDatesChanged}
                initialRangeType={initialDateRangeType}
                initialDateRange={{ startDate: initialStartDate, endDate: initialEndDate }}
              />
            </div>
          )}
        </Stack>
        {isTythonSelfServeUser() ? (
          <TythonReportCards dateRange={dateRange} history={props.history} location={props.location} match={props.match} />
        ) : (
          <div>
            <div id="reportDescription" className={classNames.description}>
              {publisher
                ? isReportEnabled
                  ? _getHighlightedText(formatMessage(messages.reportDescriptionForPublisherSelected), formatMessage(messages.reportLink))
                  : formatMessage(messages.reportDescriptionForReportsNotApplicable)
                : formatMessage(messages.reportDescriptionForPublisherNotSelected)}
            </div>
            {publisher && isReportEnabled && (
              <div
                className={classNames.link}
                role="button"
                tabIndex={0}
                onClick={() => onReportLinkClicked()}
                onKeyDown={(ev) => isButtonPressKey(ev.key) && onReportLinkClicked()}
                aria-describedby="reportDescription"
              >
                <Icon iconName={'OpenInNewWindow'} />
                <span className={classNames.linkTitle}>{formatMessage(messages.reportLink)}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </EmptyLayout>
  );
});
function _getHighlightedText(text: string, highlight: string): JSX.Element {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
          {part}
        </span>
      ))}{' '}
    </span>
  );
}
