import { boolean, object, string } from 'yup';
import { IUserViewModel } from './IUserViewModel';

export function userAcceptInvitationErrorModel() {
  return object<IUserViewModel>().shape({
    firstName: string()
      .trim()
      .required(),
    lastName: string()
      .trim()
      .required(),
    emailAddress: string()
      .email()
      .required(),
    language: string().required(),
    contactByPhone: boolean(),
    contactByEmail: boolean(),
    phoneNumber: string().when('contactByPhone', {
      is: true,
      then: string().required()
    })
  });
}
