import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { User, getAppStore, postEntity } from '../../../../../@data';
import { WelcomeToPubcenterEmail } from '../../../../../@data/store/schema/models/welcomeToPubcenterEmail';
import { sendWelcomeToPubcenterEmail } from '../actions';

orchestrator(sendWelcomeToPubcenterEmail, async () => {


  const { userContext, publisher} = getAppStore();
  if (!publisher || ! userContext) {
    return;
  }
  try {
    const user = new User(userContext.id);
    await postEntity([publisher!, user], WelcomeToPubcenterEmail, undefined, userContext);
  } catch (e) {
    Log.error(e);
  }
});