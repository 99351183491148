import { DefaultButton, getTheme } from '@fluentui/react';
import React from 'react';
import { IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../../../../../theme';
import { getEntityAccessPermissions } from '../../../../../../@data';
import { UserRoleEntity, completeAccess, hasReadAccess } from '../../../../../../@data/services/UserRoleService';
import { PublisherProfileTabs } from '../../../../../../@data/store/schema/userrolemodels/PublisherProfileTabs';
import { APP_NAME_NEW } from '../../../../../../constants/AppConstants';
import { getAccountStatus } from '../../../../Account/@data/store/selectors';
import paymentImg from '../../PerformanceSection/resources/Payment.svg';
import { ISetupCard, SetupCardType } from '../../SetupCard/SetupCardUtils';
import messages from '../RecommendationsSection.messages';
import adBlock from '../resources/adBlock.svg';
import adsTxt from '../resources/adsTxt.svg';
import mediationImage from '../resources/mediation.svg';
import mediationIcon from '../resources/mediationIcon.svg';
import paymentImage from '../resources/payment.svg';

export const getRecommendationsCardsPlaceholder = (): ISetupCard[] => {
  return [
    {
      title: '',
      description: '',
    },
    {
      title: '',
      description: '',
    },
    {
      title: '',
      description: '',
    },
  ];
};

export const getRecommendationsCards = (
  formatMessage,
  handlePaymentCardButtonClick,
  handleMediationButtonClick,
  handleAdsTxtCardButtonClick,
  handleAdBlockCardButtonClick,
  isMediationEnabeled
): ISetupCard[] => {
  const NOT_CREATED_ACCOUNT_STATUS = 'NotCreated';
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const palette = theme.palette as IPubcenterPalette;
  const spacing = theme.spacing as IPubcenterSpacing;
  const actionButtonStyle = {
    justifyContent: 'center',
    display: 'flex',
    border: `${spacing.size1} solid ${palette['NeutralStroke1.Rest']}`,
    borderRadius: spacing.size4,
  };
  const actionButtonTextStyle = { lineHeight: spacing.size12, fontWeight: 600, marginLeft: spacing.size6 };
  const accountStatus = getAccountStatus();
  const publisherProfileAccess = getEntityAccessPermissions(UserRoleEntity.PublisherProfileTabs) as PublisherProfileTabs | null;
  const hasPaymentAccess = hasReadAccess(publisherProfileAccess === null ? completeAccess : publisherProfileAccess.payments);
  const gettingStartedCards: ISetupCard[] = [
    {
      title: formatMessage(messages.setupPaymentTitle),
      description: formatMessage(messages.setupPaymentDescription),
      button: (
        <DefaultButton style={actionButtonStyle} onClick={() => handlePaymentCardButtonClick()}>
          <img src={paymentImg} alt={formatMessage(messages.setupPaymentButton)} />
          <span style={actionButtonTextStyle}>{formatMessage(messages.setupPaymentButton)}</span>
        </DefaultButton>
      ),
      image: paymentImage,
      imageAlt: formatMessage(messages.paymentImage),
      hidden: !hasPaymentAccess || accountStatus !== NOT_CREATED_ACCOUNT_STATUS,
      cardType: SetupCardType.Recommendations,
    },
    {
      title: formatMessage(messages.setupMediationTitle),
      description: formatMessage(messages.setupMediationDescription, { appName: APP_NAME_NEW }),
      button: (
        <DefaultButton style={actionButtonStyle} onClick={() => handleMediationButtonClick()}>
          <img src={mediationIcon} alt={formatMessage(messages.setupMediationButton)} />
          <span style={actionButtonTextStyle}>{formatMessage(messages.setupMediationButton)}</span>
        </DefaultButton>
      ),
      image: mediationImage,
      imageAlt: formatMessage(messages.mediationImage),
      hidden: isMediationEnabeled,
      cardType: SetupCardType.Recommendations,
    },
    {
      title: formatMessage(messages.setupAdsTxtTitle),
      description: formatMessage(messages.setupAdsTxtDescription),
      button: (
        <DefaultButton style={actionButtonStyle} onClick={() => handleAdsTxtCardButtonClick()}>
          <img src={paymentImg} alt={formatMessage(messages.setupAdsTxtButton)} />
          <span style={actionButtonTextStyle}>{formatMessage(messages.setupAdsTxtButton)}</span>
        </DefaultButton>
      ),
      image: adsTxt,
      imageAlt: formatMessage(messages.adsTxtImage),
      cardType: SetupCardType.Recommendations,
    },
    {
      title: formatMessage(messages.adBlockCardTitle),
      description: formatMessage(messages.adBlockCardDescription),
      button: (
        <DefaultButton style={actionButtonStyle} onClick={() => handleAdBlockCardButtonClick()}>
          <img src={paymentImg} alt={formatMessage(messages.adBlockCardAction)} />
          <span style={actionButtonTextStyle}>{formatMessage(messages.adBlockCardAction)}</span>
        </DefaultButton>
      ),
      image: adBlock,
      imageAlt: formatMessage(messages.adBlockCardAction),
      cardType: SetupCardType.Recommendations,
    },
  ];

  return gettingStartedCards;
};
