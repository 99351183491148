import { getAppStore } from '../../../../@data';
import { IGridV2Column } from '../../GridV2.types';
import { getStore } from '../store/store';

export const getGridColumns = (): IGridV2Column[] => {
  const { entityGridColumns } = getAppStore();
  const { entity } = getStore();

  if (entityGridColumns && entity) {
    const entityColumns = entityGridColumns.find((entityGridColumn) => entityGridColumn.entity === entity);
    return entityColumns ? (entityColumns.gridColumns as IGridV2Column[]) : [];
  }
  return [];
};
