import { SelectionMode, Stack } from '@fluentui/react';
import React, { useEffect, useMemo } from 'react';
import { useI18n } from '../../../../i18n/useI18n';
import { AuditLog, LayoutMode, getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged, onLayoutChanged } from '../../../@data';
import { FormTitle } from '../../../components/Form';
import { GridPageLayout } from '../../../layout/GridPageLayout/GridPageLayout';
import { GridV2 } from '../../../layout/GridPageV2';
import { Pagination } from '../../../layout/GridPageV2/components/Pagination';
import { PaginationDisplay } from '../../../layout/GridPageV2/components/Pagination/Pagination.types';
import ProfilePanelMessages from '../../../layout/PageLayout/header-panels/ProfilePanel/ProfilePanel.messages';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../utils';
import { CustomCalender } from '../../report/components/CustomCalender/CustomCalender';
import { RangeType } from '../../report/components/CustomCalender/CustomCalender.types';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import { getStore } from '../Account/Settings/@data/store/store';
import { onAuditHistoryPageInitialized, onSelectedDatesChanged, resetFilters, setPageNumber, setPageSize } from './@data/actions';
import './@data/orchestrators';
import { getAuditHistoryStore } from './@data/store/store';
import AuditHistoryMessages from './AuditHistory.messages';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

export interface IAuditHistoryPageProps extends IRouterWithIntl {}

export const AuditHistoryPage = injectIntlAndObserver(function AuditHistoryPageComp(props: IAuditHistoryPageProps) {
  const { formatMessage } = props.intl;
  const { auditHistory, noAuditsFound, noPublisherSelected } = AuditHistoryMessages;
  const { data, startDate, endDate, totalPages, isDataLoading, pageNumber, pageSize, isShowAllDetailsEnabled } = getAuditHistoryStore();
  const { publisher, userContext, helpInfo, locale: appLocale } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });

  useEffect(() => {
    if (getAppStore().layout !== LayoutMode.Default) {
      onLayoutChanged(LayoutMode.Default);
    }

    onCurrentRouteChanged(RouteName.auditHistory, props.intl.formatMessage, ProfilePanelMessages.auditHistory);

    // resetting filters
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridColumns = useMemo(() => {
    return getGridColumns(formatMessage, locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowAllDetailsEnabled, locale]);

  useEffect(() => {
    onAuditHistoryPageInitialized();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, startDate, endDate, pageNumber, pageSize]);

  return (
    <GridPageLayout>
      <Stack horizontal horizontalAlign="space-between">
        <FormTitle
          intl={props.intl}
          title={formatMessage(auditHistory)}
          calloutContent={helpInfo}
          iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AuditHistory', appLocale)}
          calloutOnDismiss={() => onHelpInfoChanged()}
        />
        <div>
          <CustomCalender
            initialDateRange={{ startDate: startDate, endDate: endDate }}
            disableFutureDays
            onSelectedDatesChanged={onSelectedDatesChanged}
            initialRangeType={RangeType.Last7ExcludingToday}
          />
        </div>
      </Stack>
      <GridV2
        {...props}
        entity={AuditLog}
        isGridReady={!isDataLoading}
        commandBarProps={getCommandBarItems(formatMessage, locale)}
        dataGridProps={{
          data: data || [],
          gridV2Columns: gridColumns,
          selectionMode: SelectionMode.none,
          noEntityFound: publisher ? noAuditsFound : noPublisherSelected,
        }}
      >
        <Pagination
          intl={props.intl}
          display={PaginationDisplay.Navigation}
          totalPageCount={totalPages}
          currentPageNumber={pageNumber ?? 1}
          pageSize={pageSize ?? 10}
          onPageNumberChange={setPageNumber}
          onPageSizeChange={setPageSize}
        />
      </GridV2>
    </GridPageLayout>
  );
});
