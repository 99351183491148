import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Log } from '../../../../logging/src';
import { getAppStore, onUserContextChanged } from '../../../@data';
import { SignUpPageRoute } from '../../../Routes';
import { APP_NAME_NEW, APP_NAME_PUBCENTER, PUBCENTER_TYTHON_DOMAIN_MAPPING } from '../../../constants';
import { GlobalSearchHeader } from '../../../layout/GlobalSearchHeader/GlobalSearchHeader';
import { isLocationPubCenter } from '../../../utils';
import { onSwitchAccount } from '../../login/@data';
import '../../login/@data/orchestrators';
import { InvalidUserErrorPage } from './InvalidUser';
import messages from './InvalidUser.messages';

interface IUserDoesNotExistProps extends InjectedIntlProps, RouteComponentProps {}

export const UserDoesNotExistErrorPage = injectIntl(function UserDoesNotExistErrorPageComp(props: IUserDoesNotExistProps): JSX.Element {
  const { formatMessage } = props.intl;
  const { userContext } = getAppStore();

  const onSwitchAccountClicked = (): void => {
    if (userContext) {
      onUserContextChanged({ ...userContext });
    }
    onSwitchAccount();
  };

  const onRedirectToSignupPage = (): void => {
    let signupPageRoute = window.location.origin + SignUpPageRoute;

    if (isLocationPubCenter()) {
      const newDomainOrigin = PUBCENTER_TYTHON_DOMAIN_MAPPING[window.location.host];
      signupPageRoute = `${newDomainOrigin}${SignUpPageRoute}`;
    }
    Log.write('Redirecting to new domain: ' + signupPageRoute);
    window.location.replace(signupPageRoute);
  };

  const isInternalUser = userContext?.email && userContext?.email.indexOf('@microsoft.com') !== -1;

  return (
    <>
      <GlobalSearchHeader />
      <InvalidUserErrorPage
        {...props}
        errorImageAltText={formatMessage(messages.imageAlt)}
        title={formatMessage(messages.userDoesNotExist, { appName: isInternalUser ? APP_NAME_PUBCENTER : APP_NAME_NEW })}
        titleSubText={formatMessage(messages.userDoesNotExist2, { appName: isInternalUser ? APP_NAME_PUBCENTER : APP_NAME_NEW })}
        email={userContext?.email}
        primaryButtonLabel={formatMessage(messages.signupToPubCenter, { appName: APP_NAME_NEW })}
        defaultButtonLabel={formatMessage(messages.switchAccountLabel)}
        onPrimaryButtonClick={onRedirectToSignupPage}
        onDefaultButtonClick={onSwitchAccountClicked}
      />
    </>
  );
});
