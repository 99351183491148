import { PhoneVerificationGenerateCode, getAppStore, postEntity } from '../../../../../@data';
import { EvaluationStatus, Publisher } from '../../../../../@data/store';

export async function generateCodeAsync(body: PhoneVerificationGenerateCode, publisher: Publisher) {
  const { userContext } = getAppStore();
  const phoneVerificationResp = await postEntity<PhoneVerificationGenerateCode>(
    [new EvaluationStatus(), publisher],
    PhoneVerificationGenerateCode,
    body,
    userContext
  );
  return phoneVerificationResp.data;
}
