import { Model } from '.';
import { IUserRoleDetail } from '..';
import { UserInvitationType } from '../enums/UserInvitationType';

export class UserInvitation extends Model {
  public apiPath = undefined;
  public className = 'userInvitations';
  public accountIds?: number[];
  public publisherId?: number;
  public email?: string;
  public expirationDate?: Date | string;
  public firstName?: string;
  public lastName?: string;
  public lcid?: number;
  public roleIds?: number[];
  public entityRoles?: IUserRoleDetail[];
  public get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }
  public userInvitationType?: UserInvitationType;
}
