import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, onLoadingChanged, onNotificationChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import messages from '../../AdReview/AdCreativePage.messages';
import { onBlockedImagesExportClicked } from '../actions';
import { getBlockImagesData } from '../services/AdBlockService';
import { exportCSV } from '../services/utility/utility';
import { getStore } from '../store/store';

orchestrator(onBlockedImagesExportClicked, async (msg) => {
  const { publisher, userContext } = getAppStore();

  let notification: INotificationBarItem = {};

  if (publisher && publisher.id && userContext) {
    onLoadingChanged(true);
    try {
      const blockImagesSummary = await getBlockImagesData(publisher.id);
      const columns = getStore().columns;
      if (columns) {
        exportCSV(blockImagesSummary, 'publisher_ad_image_blocks.csv', columns);
      }

      notification = {
        textMessageDescriptor: messages.adqualityDownloadBlockedImagesSuccess,
        messageBarType: MessageBarType.success,
      };
      onNotificationChanged(notification);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityDownloadBlockedImagesFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
