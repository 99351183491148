import {
  ActionButton,
  BaseComponent,
  classNamesFunction,
  DelayedRender,
  FocusZone,
  FocusZoneDirection,
  getId,
  Icon,
  IProcessedStyleSet,
  Label,
  Link,
  nullRender,
  SearchBox,
  css,
} from '@fluentui/react';
import * as React from 'react';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import messages from './MultiSelectPicker.messages';
import { IIdNamePair, IMultiSelectPickerProps, IMultiSelectPickerStyleProps, IMultiSelectPickerStyles } from './MultiSelectPicker.types';

const getClassNames = classNamesFunction<IMultiSelectPickerStyleProps, IMultiSelectPickerStyles>();

export interface IMultiSelectPickerState {
  filteredItems: IIdNamePair[];
  selectedItems: IIdNamePair[];
}

export class MultiSelectPickerBase extends BaseComponent<IMultiSelectPickerProps, IMultiSelectPickerState> {
  private _classNames: IProcessedStyleSet<IMultiSelectPickerStyles>;
  private _label: string = getId('multiSelectPickerLabel');

  constructor(props: IMultiSelectPickerProps) {
    super(props);

    const availableItems = [...this.props.items];
    const selectedItems = this._getIntersection(this.props.items, this.props.initialSelectedItems || []);
    selectedItems.forEach((item) => {
      availableItems.find((i) => i.id === item.id)!!.isSelected = true;
    });

    this.state = {
      filteredItems: availableItems,
      selectedItems: selectedItems,
    };
  }

  public componentDidUpdate(prevProps: IMultiSelectPickerProps) {
    let initialSelectedItems = this.state.selectedItems;

    if (this.props.initialSelectedItems !== prevProps.initialSelectedItems) {
      initialSelectedItems = this.props.initialSelectedItems || [];
    }

    if (this.props.items !== prevProps.items || this.props.initialSelectedItems !== prevProps.initialSelectedItems) {
      // Make sure only the selected ones which still exists remain selected.
      const intersectedSelectedItems = this._getIntersection(initialSelectedItems, this.props.items);
      const availableItems = [...this.props.items];

      intersectedSelectedItems.forEach((item) => {
        availableItems.find((i) => i.id === item.id)!!.isSelected = true;
      });

      this.setState({
        filteredItems: availableItems,
        selectedItems: intersectedSelectedItems,
      });
    }
  }

  public render(): JSX.Element {
    const {
      styles,
      theme,
      errorMessage,
      label,
      onRenderLabel = this._onRenderLabel,
      ariaDescription,
      enableCreateButton,
      createButtonLabel,
      onCreateButtonClick,
      disabled,
    } = this.props;
    this._classNames = getClassNames(styles, { theme: theme!, hasErrorMessage: !!errorMessage });
    const pickerAriaDescriptionId = getId('pickerAriaDescription');

    const { formatMessage } = this.props.intl;

    return (
      <div className={this._classNames.root}>
        {onRenderLabel(this.props, this._onRenderLabel)}
        {enableCreateButton && (
          <ActionButton
            iconProps={{ iconName: 'add' }}
            onClick={onCreateButtonClick}
            disabled={disabled}
            ariaDescription={createButtonLabel ? formatMessage(messages.createButtonAriaDesciption, { label: createButtonLabel }) : ''}
          >
            {createButtonLabel}
          </ActionButton>
        )}
        <div
          className={this._classNames.picker}
          role="form"
          {...(label ? { 'aria-labelledby': this._label } : {})}
          aria-describedby={pickerAriaDescriptionId}
        >
          {this._renderItemsPane()}
          {this._renderSelectedItemsPane()}
          <span id={pickerAriaDescriptionId} style={getScreenReaderTextStyles()}>
            {ariaDescription}
          </span>
        </div>
        {errorMessage && (
          <div role="alert">
            <DelayedRender>
              <p className={this._classNames.errorMessage}>
                <span data-automation-id="error-message">{errorMessage}</span>
              </p>
            </DelayedRender>
          </div>
        )}
      </div>
    );
  }

  private _onRenderLabel = (props: IMultiSelectPickerProps): JSX.Element | null => {
    const { label, required } = props;

    if (label) {
      return (
        <Label id={this._label} required={required}>
          {props.label}
        </Label>
      );
    }

    return null;
  };

  private _renderSelectedItemsPane = (): JSX.Element => {
    const { selectedItems } = this.state;
    const { disabled } = this.props;
    const selectedCount = selectedItems.length;
    const { formatMessage } = this.props.intl;
    const removeAllAriaDescriptionId = getId('removeAllAriaDescription');

    let renderRemoveAll = nullRender();
    if (selectedCount > 0) {
      renderRemoveAll = (
        <>
          <Link
            className={this._classNames.textButton}
            onClick={this._onRemoveAll}
            disabled={this.props.disabled}
            aria-describedby={removeAllAriaDescriptionId}
          >
            {formatMessage(messages.removeAllButtonLabel)}
          </Link>

          <span id={removeAllAriaDescriptionId} style={getScreenReaderTextStyles()}>
            {this.props.intl.formatMessage(messages.removeAllButtonAriaDescription)}
          </span>
        </>
      );
    }

    return (
      <div className={css(this._classNames.itemsPane, disabled ? this._classNames.disabledPanel : '')} role="presentation">
        <Label className={css(this._classNames.headerLabel, this._classNames.header)}>
          {formatMessage(messages.selectedItemsLabel, { count: selectedCount })}
        </Label>
        {renderRemoveAll}

        <FocusZone
          role="listbox"
          className={this._classNames.list}
          aria-label={formatMessage(messages.selectedItemsPaneAriaLabel)}
          direction={FocusZoneDirection.vertical}
          isCircularNavigation={true}
        >
          {selectedItems.map((item: IIdNamePair, index: number) => (
            <div
              className={this._classNames.listItem}
              id={getId('multiSelectPickerSelItem')}
              key={'multiSelectPickerItem' + index}
              role="option"
              aria-selected={true}
              onClick={!disabled ? this._onItemRemovedClick : undefined}
              onKeyDown={(ev) => isButtonPressKey(ev.key) && !disabled && this._onItemRemovedClick(ev)}
              data-list-index={index}
              data-is-focusable={!disabled}
              tabIndex={!disabled ? 0 : -1}
            >
              <span className={this._classNames.listItemLabel}>{item.name}</span>
              <Icon
                iconName={'remove'}
                ariaLabel={formatMessage(messages.removeIconAriaLabel)}
                styles={this._classNames.subComponentStyles.iconButton}
              />
            </div>
          ))}
        </FocusZone>
      </div>
    );
  };

  private _renderItemsPane = (): JSX.Element => {
    const { disabled } = this.props;
    const { formatMessage } = this.props.intl;
    const { filteredItems } = this.state;
    const addAllAriaDescriptionId = getId('addAllAriaDescription');

    let renderAddAll = nullRender();
    if (filteredItems.length > 0) {
      renderAddAll = (
        <>
          <Link
            className={this._classNames.textButton}
            onClick={this._onAddAll}
            disabled={disabled}
            aria-describedby={addAllAriaDescriptionId}
          >
            {formatMessage(messages.addAllButtonLabel)}
          </Link>

          <span id={addAllAriaDescriptionId} style={getScreenReaderTextStyles()}>
            {this.props.intl.formatMessage(messages.addAllButtonAriaDescription)}
          </span>
        </>
      );
    }

    return (
      <div className={css(this._classNames.itemsPane, disabled ? this._classNames.disabledPanel : '')} role="presentation">
        <SearchBox
          styles={this._classNames.subComponentStyles.search}
          underlined={true}
          placeholder={formatMessage(messages.searchBoxPlaceholder)}
          onChange={(_, value: string) => this._onSearchChange(value)}
        />
        <div className={this._classNames.header}>
          <Label className={this._classNames.headerLabel}>
            {formatMessage(messages.filteredItemsLabel, { count: filteredItems.length })}
          </Label>
          {renderAddAll}
        </div>
        <FocusZone
          className={this._classNames.list}
          role="listbox"
          aria-multiselectable={true}
          aria-label={formatMessage(messages.availableItemsListBoxAriaLabel)}
          direction={FocusZoneDirection.vertical}
          isCircularNavigation={true}
        >
          {filteredItems.map((item: IIdNamePair, index: number) => (
            <div
              className={css(this._classNames.listItem, item.isSelected ? this._classNames.disabledListItem : '')}
              id={getId('multiSelectPickerItem')}
              key={'multiSelectPickerItem' + index}
              role="option"
              aria-selected={item.isSelected}
              onClick={!disabled && !item.isSelected ? this._onItemAddedClick : undefined}
              onKeyDown={(ev) => {
                if (isButtonPressKey(ev.key)) {
                  if (!disabled && !item.isSelected) {
                    this._onItemAddedClick(ev);
                  }
                }
              }}
              data-is-focusable={!disabled && !item.isSelected}
              data-list-index={index}
              tabIndex={!disabled ? 0 : -1}
            >
              <span className={this._classNames.listItemLabel}>{item.name}</span>
              {!item.isSelected && (
                <Icon
                  iconName={'add'}
                  ariaLabel={formatMessage(messages.addIconAriaLabel)}
                  styles={this._classNames.subComponentStyles.iconButton}
                />
              )}
            </div>
          ))}
        </FocusZone>
      </div>
    );
  };

  private _onSearchChange = (query: string): void => {
    const queryName = query.toLocaleLowerCase();
    this.setState({
      filteredItems: this.props.items.filter((item) => {
        const name = item.name;
        return name.toLocaleLowerCase().indexOf(queryName) !== -1;
      }),
    });
  };

  private _getItemAtElement(
    item: IIdNamePair[],
    ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ): IIdNamePair | undefined {
    const el = ev.currentTarget as HTMLDivElement;

    if (el.getAttribute('data-list-index')) {
      const index = Number(el.getAttribute('data-list-index'));
      return item[index];
    }

    return undefined;
  }

  private _onItemRemovedClick = (ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): void => {
    const selectedItems = [...this.state.selectedItems];
    const filteredItems = [...this.state.filteredItems];
    const item = this._getItemAtElement(selectedItems, ev);

    if (item) {
      const remainingSelectedItems = selectedItems.filter((i) => i !== item);

      filteredItems.forEach((i) => {
        if (i.id === item.id) {
          i.isSelected = false;
        }
      });

      this.setState({
        selectedItems: remainingSelectedItems,
        filteredItems: filteredItems,
      });

      if (this.props.onSelectedItems) {
        this.props.onSelectedItems(remainingSelectedItems);
      }
    }
  };

  private _onItemAddedClick = (ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): void => {
    const filterItems = [...this.state.filteredItems];
    const item = this._getItemAtElement(filterItems, ev);

    if (item && !this.state.selectedItems.find((i) => i.id === item.id)) {
      const selectedItems = [...this.state.selectedItems, item];

      item.isSelected = true;

      this.setState({
        selectedItems: selectedItems,
        filteredItems: filterItems,
      });

      if (this.props.onSelectedItems) {
        this.props.onSelectedItems(selectedItems);
      }
    }
  };

  private _getIntersection = (collectionA: IIdNamePair[], collectionB: IIdNamePair[]) => {
    return collectionA.filter((selectedItem) => collectionB.some((item) => selectedItem.id === item.id));
  };

  private _onRemoveAll = (ev: React.MouseEvent<HTMLElement>): void => {
    const filterItems = [...this.state.filteredItems];
    filterItems.map((item) => (item.isSelected = false));

    // reset items isSelected props on removeAll clicked;
    this.props.items.map((item) => (item.isSelected = false));
    this.setState({
      selectedItems: [],
      filteredItems: filterItems,
    });

    if (this.props.onSelectedItems) {
      this.props.onSelectedItems([]);
    }
  };

  private _onAddAll = (): void => {
    const selectedItems = [...this.state.selectedItems];
    const filterItems = [...this.state.filteredItems];

    filterItems.forEach((item) => {
      if (!item.isSelected) {
        selectedItems.push(item);
      }
      item.isSelected = true;
    });

    this.setState({
      selectedItems: selectedItems,
      filteredItems: filterItems,
    });

    if (this.props.onSelectedItems) {
      this.props.onSelectedItems(selectedItems);
    }
  };
}
