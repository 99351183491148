import { Announced, IconButton, Panel, PanelType, getTheme } from '@fluentui/react';
import React, { useEffect } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { ClarityType } from '../../../../../../@data';
import AppMessages from '../../../../../../App.messages';
import { FormItem, FormState } from '../../../../../../components/Form';
import { useWindowSize } from '../../../../../../hooks';
import { getStore } from '../../../../../../hooks/useWindowSize/store/store';
import { IPropertyPickerItem } from '../../../../../../layout/PropertyPicker/PropertyPicker.types';
import { PropertySitePicker } from '../../../../../../layout/PropertySitePicker/PropertySitePicker';
import { IPropertySitePickerItem } from '../../../../../../layout/PropertySitePicker/PropertySitePicker.types';
import { injectIntlAndObserver } from '../../../../../../utils';
import propertyMessages from '../../../../Property/Create/variations/CreateNonTythonProperty/messages';
import { CreateTythonProperty } from '../../../../Property/Create/variations/CreateTythonProperty';
import { getClassNames } from '../../../Create/CreateAdunit';
import messages from '../../CreateAdunit/messages';
import { getStyles } from '../../CreateAdunit/styles';

export interface IAdUnitSiteSelectorProps {
  options: IPropertyPickerItem[];
  onAddSiteClick: () => void;
  searchBoxPlaceholder: string;
  onSiteChange: (item?: IPropertySitePickerItem) => void;
  isPropertyPanelActive: boolean;
  onCloseNewPropertyPanel: () => void;
  onCreatingNewProperty: ({
    name,
    url,
    clarityType,
    clarityId,
  }: {
    name: string;
    url: string;
    clarityType?: ClarityType;
    clarityId?: string;
  }) => void;
  isLoading?: boolean;
  isSaving?: boolean;
  isDisabled?: boolean;
  shouldRenderComponent: boolean;
}

const { windowStoreSerializer } = useWindowSize();

const AdUnitSiteSelector = injectIntlAndObserver<InjectedIntlProps & IAdUnitSiteSelectorProps & RouteComponentProps>(
  ({
    intl: { formatMessage },
    onCloseNewPropertyPanel,
    isPropertyPanelActive,
    options,
    searchBoxPlaceholder,
    onSiteChange,
    onAddSiteClick,
    onCreatingNewProperty,
    isLoading,
    isSaving,
    isDisabled,
    shouldRenderComponent,
    ...routeProps
  }) => {
    const { isWindowSmallSize } = windowStoreSerializer(getStore());

    const theme = getTheme();
    const classNames = getClassNames(getStyles, { theme });

    /**
     * we passed type = custom to Panel component, by default this will not focus to close button
     * when panel expanded, based on a11y requirement we need to focus to close button when panel expanded
     * so added this useEffect to focus to close button when panel expanded
     */
    useEffect(() => {
      if (isPropertyPanelActive) {
        // Adding a small delay to ensure the element is available
        const timer = setTimeout(() => {
          const closeButton = document.getElementById('adunit-site-panel-close-button');
          if (closeButton) {
            closeButton.focus();
          }
        }, 0); // Delay is set to 0, but it ensures it runs after the DOM update

        // Cleanup the timeout if the component unmounts
        return () => clearTimeout(timer);
      }
    }, [isPropertyPanelActive]);

    return shouldRenderComponent ? (
      <>
        <FormItem formWidth={isWindowSmallSize ? 328 : 546}>
          <div className={classNames.siteSection}>
            <PropertySitePicker
              title={formatMessage(messages.site)}
              items={options}
              searchBoxPlaceholder={searchBoxPlaceholder}
              propertyOptionsAriaLabel={formatMessage(messages.propertyOptionsAriaLabel)}
              onListItemClicked={onSiteChange}
              emptyOptionLabel={formatMessage(messages.propertyPickerEmptyOptionLabel)}
              searchBoxAriaDescription={formatMessage(messages.propertyPickerSearchBoxAriaDesc)}
              itemSelectedAnnounceMessage={formatMessage(messages.propertyPickerItemSelectedAnnounceMsg)}
              isLoading={isLoading}
              isDisabled={isDisabled}
              clearSearchOnSelect
              formatMessage={formatMessage}
            />
            {!isDisabled && (
              <button onClick={onAddSiteClick} className={classNames.button}>
                {formatMessage(messages.siteButton)}
              </button>
            )}
          </div>
          <Panel
            isOpen={isPropertyPanelActive}
            type={PanelType.custom}
            onDismiss={onCloseNewPropertyPanel}
            onRenderNavigation={() => (
              <IconButton
                className={classNames.newPropertyPanelHeader}
                iconProps={{ iconName: 'Cancel' }}
                aria-label={formatMessage(AppMessages.close)}
                onClick={onCloseNewPropertyPanel}
                id="adunit-site-panel-close-button"
              />
            )}
            headerText={formatMessage(propertyMessages.createSitePageTitle)}
            headerTextProps={{ hidden: true }}
            closeButtonAriaLabel={formatMessage(messages.panelCloseButtonAriaLabel)}
          >
            <Announced message={formatMessage(messages.createSitePanelAccessibilityAnnouncement)} />
            {isPropertyPanelActive && (
              <CreateTythonProperty
                {...routeProps}
                onSubmit={onCreatingNewProperty}
                mode={FormState.InPageCreate}
                saving={isSaving}
                onCancel={onCloseNewPropertyPanel}
              />
            )}
          </Panel>
        </FormItem>
      </>
    ) : null;
  }
);

export default AdUnitSiteSelector;
