import { IStyle } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../theme';

export interface IInternationalizationStyles {
  supportButtonWrapper?: IStyle;
  supportButton?: IStyle;
}

export const getStyles = (props) => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    supportButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    supportButton: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing.size12,
      img: {
        marginRight: spacing.size6,
      },
    },
  };
};
