export enum FilterCondition {
  Equals = 'equals',
  DoesNotEqual = 'does not equal',
  GreaterThan = 'greater than',
  GreaterThanEqual = 'greater than or equals',
  LessThan = 'less than',
  LessThanEqual = 'less than or equals',
  Contains =  'contains',
  DoesNotContain = 'does not contain',
  BeginsWith = 'begins with',
  EndsWith = 'ends with',
}