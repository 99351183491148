import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'channel.create',
    defaultMessage: 'Create Channel',
  },
  notFound: {
    id: 'channel.notFound',
    defaultMessage: 'No channels found for this account, create one.',
  },
  deactivateTitle: {
    id: 'channel.deactivateTitle',
    defaultMessage: 'Do you want to deactivate channel(s)?',
  },
  channelName: {
    id: 'channel.channelName',
    defaultMessage: 'Channel name',
  },
  channelId: {
    id: 'channel.channelId',
    defaultMessage: 'Channel ID',
  },
  adunitsNumber: {
    id: 'channel.adunitsNumber',
    defaultMessage: '# of ad units',
  },
  adunitNames: {
    id: 'channel.adunitNames',
    defaultMessage: 'Ad unit names',
  },
  adfiltersNumber: {
    id: 'channel.adfiltersNumber',
    defaultMessage: '# of ad filters',
  },
  adfilterNames: {
    id: 'channel.adfilterNames',
    defaultMessage: 'Ad filter names',
  },
  channels: {
    id: 'channels',
    defaultMessage: 'Channels',
  },
  actions: {
    id: 'property.actions',
    defaultMessage: 'Actions',
  },
  zeroResultTitle: {
    id: 'channel.zeroResultTitle',
    defaultMessage: 'No channels yet',
  },
  zeroResultDescription: {
    id: 'channel.zeroResultDescription',
    defaultMessage: 'You haven’t created any channel yet',
  },
  noPublisherSelected: {
    id: 'property.noPublisherSelected',
    defaultMessage: 'A publisher isn’t selected yet. To start managing channels, search for a publisher..',
  },
});
