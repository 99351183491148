import { IPubcenterPalette, IPubcenterSpacing } from '../../../theme';
import { TythonNavStyleProps, TythonNavStyles } from './types';

const renderNormalIcon = {
  '.fui-Tab__icon': {
    '.navIconActive': {
      display: 'none',
    },
    '.navIcon': {
      display: 'block',
    },
  },
};
const renderActiveIcon = {
  '.fui-Tab__icon': {
    '.navIconActive': {
      display: 'block',
    },
    '.navIcon': {
      display: 'none',
    },
  },
};

export const getStyles = (props: TythonNavStyleProps): TythonNavStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return {
    container: {
      background: palette['NeutralBackground4.Rest'],
      '.fui-TabList': {
        height: '100%',
        width: spacing.size64,
      },
      '.side-navigation-item-button': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: `${spacing.size12} ${spacing.size6}`,
        '.fui-Tab__content': {
          fontWeight: 400,
          fontSize: spacing.size10,
          lineHeight: spacing.size14,
          color: palette['NeutralForeground2.Rest'],
        },
        '.side-navigation-item-text': {
          display: 'block',
          maxWidth: spacing.size56,
        },
        ...renderNormalIcon,
      },
      '.side-navigation-item-button:hover': {
        background: palette['NeutralBackground3.Rest'],
        borderLeft: `${spacing.size2} solid ${palette['NeutralStroke1.Rest']}`,
        '.fui-Tab__content': {
          color: palette['CompoundBrandForeground1.Rest'],
        },
        ...renderActiveIcon,
      },
      '.side-navigation-item-button[aria-selected="true"]:hover': {
        '.fui-Tab__content': {
          color: palette['CompoundBrandForeground1.Hover'],
        },
      },
      '.side-navigation-item-button[aria-selected="true"]': {
        borderLeft: `${spacing.size2} solid ${palette['CompoundBrandForeground1.Rest']}`,
        '.fui-Tab__content': {
          color: palette['CompoundBrandForeground1.Rest'],
        },
        ...renderActiveIcon,
      },
      '.fui-Tab__content--reserved-space': {
        height: 0,
      },
    },
  };
};
