import { Panel, PanelType } from '@fluentui/react';
import React from 'react';
import AppMessages from '../../../../../../App.messages';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../../../utils';
import { PropertyScriptPage } from '../../../Create/variations/CreateTythonProperty/components/ScriptPage';
import { setCodePanelVisibility } from '../../__data__/mutatorActions';
import { getGridPropertyStore } from '../../__data__/store/store';

export interface ICodePanelProps extends IRouterWithIntl {}

export const CodePanel = injectIntlAndObserver(function CodePanelComponent(props: ICodePanelProps) {
  const { isCodePanelOpen, selectedPropertyId, clarityPanel } = getGridPropertyStore();

  return (
    <Panel
      isOpen={isCodePanelOpen}
      type={PanelType.custom}
      customWidth={'900px'}
      onDismiss={() => {
        setCodePanelVisibility(false);
      }}
      isLightDismiss
      closeButtonAriaLabel={props.intl.formatMessage(AppMessages.close)}
    >
      <PropertyScriptPage {...props} isShownInPanel={true} siteId={selectedPropertyId} clarityStatus={clarityPanel?.clarityStatus} />
    </Panel>
  );
});
