import { defineMessages } from 'react-intl';

export default defineMessages({
  selectAll: {
    id: 'listFilter.selectAll',
    defaultMessage: 'Select all',
  },
  deselectAll: {
    id: 'listFilter.deselectAll',
    defaultMessage: 'Deselect all',
  },
});
