import { useCallback, useRef, useState } from 'react';

export function useIntersectionObserverWithRefCallback(
  { threshold = 0, root = null, rootMargin = '20px' }: IntersectionObserverInit,
  unobserve: boolean = false
) {
  const ref = useRef(null);
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const updateEntry = ([intersectionObserverEntry]: IntersectionObserverEntry[]): void => {
    setEntry(intersectionObserverEntry);
  };
  const updateEntryAndUnobserve = ([intersectionObserverEntry]: IntersectionObserverEntry[], observer): void => {
    setEntry(intersectionObserverEntry);
    !!intersectionObserverEntry?.isIntersecting && ref.current && observer.unobserve(ref.current);
  };
  const setRef = useCallback(
    (node) => {
      if (node) {
        const observerOptions = { threshold, root, rootMargin };
        const observer = new IntersectionObserver(unobserve ? updateEntryAndUnobserve : updateEntry, observerOptions);
        observer.observe(node);
      }
      ref.current = node;
    },
    [threshold, root, rootMargin, unobserve]
  );
  return [entry, setRef] as const;
}
