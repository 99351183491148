import { getTheme, IStackTokens, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing, IPubcenterTheme } from '../../../../../../theme';

interface IMetricCardStyleProps {
  theme: ITheme;
}
type StyleProperties = 'metricCard' | 'titleStyle' | 'metricStyle';
type MetricCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: IMetricCardStyleProps): MetricCardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    metricCard: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    titleStyle: {
      whiteSpace: 'nowrap',
    },
    metricStyle: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: spacing.size32,
      paddingBottom: spacing.size16,
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
