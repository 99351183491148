import { nullRender } from '@fluentui/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getAppStore, onAccountChanged, onPublisherChanged, onPublisherSelected, Publisher } from '../../@data';
import { PublisherAccountPicker } from '../../layout/PublisherAccountPicker/PublisherAccountPicker';
import { defaultRoute, getPageStore, RouteName } from './@data';
import { IPartnerManagementHeaderProps } from './PartnerManagement.types';

export const PartnerManagementHeader = observer(
  class extends React.Component<IPartnerManagementHeaderProps> {
    public componentDidMount() {
      const defaultPublisherId = getAppStore().defaultPublisher;
      const publisherId = [0, 3000, 3001, 3002, 3003, 3004].includes(defaultPublisherId === undefined ? 0 : defaultPublisherId)
        ? this.props.match.params.publisherId
        : String(defaultPublisherId);
      onPublisherSelected(publisherId);
    }

    public render(): JSX.Element | null {
      const appStore = getAppStore();
      const store = getPageStore();
      const accessiblePublishers = appStore.accessiblePublishers;

      if (store.loading) {
        return nullRender();
      }

      return (
        <PublisherAccountPicker
          {...this.props}
          onChange={this._onPublisherAccountChanged}
          publisher={appStore.publisher}
          isAccountHidden={this.props.isAccountHidden}
          isAccountSelectionDisabled={this.props.isAccountSelectionDisabled}
          isPublisherSelectionDisabled={accessiblePublishers && accessiblePublishers.length === 1}
        />
      );
    }

    private _onPublisherAccountChanged = (publisher?: Publisher) => {
      const store = getPageStore();
      const currentRoute = RouteName[store.currentRoute || defaultRoute];

      const publisherId = publisher ? publisher.id : 0;

      if (publisherId !== 0) {
        this.props.history.push(`/partner-management/${publisherId}/${currentRoute}`);
      } else {
        this.props.history.push(`/partner-management/${currentRoute}`);
      }
      onPublisherChanged(publisher);

      if (publisher === undefined) {
        // reset account in store. To avoid API calls with the account of old publisher
        onAccountChanged(undefined);
      }
    };
  }
);
