import { Selection, SelectionMode } from '@fluentui/react';
import { useMemo } from 'react';
import { Model } from '../../../@data';
import { setSelectedItems } from '../@data/mutatorActions';
import { getStore } from '../@data/store/store';

export const useSelectionGrid = (
  id: string,
  mode?: SelectionMode,
  setGridRows?,
  onSelectionRefChanged?: (selectedItems: Model[]) => void
) => {
  // tslint:disable-next-line: no-any
  const getKey = (item: any): string => item?.[id];

  const selection: Selection = useMemo(
    () =>
      new Selection({
        onItemsChanged() {
          selection?.getItems().forEach((row) => selection?.setKeySelected(row?.[id], row?.['isSelected'], true));
        },
        onSelectionChanged() {
          if (mode === SelectionMode.single) {
            let selectedRow: Model | undefined = selection.getSelection()[0] as Model;
            if (selection.getSelection()[0] === undefined && getStore().selectedItems[0] !== undefined) {
              if (selection.getItems().find((row) => row?.[id] === getStore().selectedItems[0]?.[id]) !== undefined) {
                selectedRow = undefined;
              } else {
                selectedRow = getStore().selectedItems[0];
              }
            }
            setGridRows((gridRows) => {
              const rowsWithUpdatedSelectionStatus = gridRows.map((row) => {
                return row?.[id] === selectedRow?.[id] ? { ...row, isSelected: true } : { ...row, isSelected: false };
              });
              setSelectedItems(rowsWithUpdatedSelectionStatus.filter((row) => row?.['isSelected'] === true));
              onSelectionRefChanged?.(rowsWithUpdatedSelectionStatus);
              return rowsWithUpdatedSelectionStatus;
            });
          } else {
            const activeRows = selection.getItems().map((row) => {
              return { ...row, isSelected: selection.getSelection().includes(row) };
            });

            setGridRows((gridRows) => {
              const rowsWithUpdatedSelectionStatus = gridRows.map((row) => {
                const rowToBeSwapped = activeRows.find((r) => r?.[id] === row?.[id]);
                if (rowToBeSwapped) {
                  return rowToBeSwapped;
                }
                return row;
              });
              setSelectedItems(rowsWithUpdatedSelectionStatus.filter((row) => row?.['isSelected'] === true));
              onSelectionRefChanged?.(rowsWithUpdatedSelectionStatus);
              return rowsWithUpdatedSelectionStatus;
            });
          }
        },
        getKey,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { selection, getKey };
};
