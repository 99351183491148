import { Card, Divider, Radio, Subtitle2, mergeClasses } from '@fluentui/react-components';
import { ChevronDown24Regular, ChevronUp24Regular } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { isButtonPressKey } from '../../../../../utils/AccessibilityUtils';
import { useV2SectionalAccordionStyles } from './styles';
import { V2SectionalAccordionType } from './types';

/**
 * this is same accordion as in ad unit detail page but build on top of fluent 2
 */

const V2SectionalAccordion: V2SectionalAccordionType = ({
  onTitleToggle,
  isAccordionSelected,
  onRenderLeft,
  onRenderRight,
  titleLabel,
  disabled,
  isLoading,
}) => {
  const classNames = useV2SectionalAccordionStyles();
  const [isComponentExpanded, setIsComponentExpanded] = React.useState(false);
  const shouldComponentExpand = isComponentExpanded && !isLoading;
  const onTitleChecked = (checked) => {
    onTitleToggle();
    setIsComponentExpanded(checked);
  };

  useEffect(() => {
    setIsComponentExpanded(isAccordionSelected);
  }, [isAccordionSelected]);

  return (
    <Card className={classNames.card}>
      <div className={classNames.titleWrapper}>
        <Radio
          disabled={isLoading ? true : disabled}
          checked={isLoading ? false : isAccordionSelected}
          onChange={(_, data) => onTitleChecked(data.value)}
          label={<Subtitle2>{titleLabel}</Subtitle2>}
          onKeyDown={(ev) => {
            if (isButtonPressKey(ev.key)) {
              onTitleChecked(isAccordionSelected);
            }
          }}
        />
        {shouldComponentExpand ? (
          <ChevronUp24Regular
            tabIndex={0}
            onClick={() => setIsComponentExpanded(false)}
            className={mergeClasses(classNames.chevronIcon, disabled ? classNames.disabled : '')}
            onKeyDown={(ev) => {
              if (isButtonPressKey(ev.key)) {
                setIsComponentExpanded(false);
              }
            }}
          />
        ) : (
          <ChevronDown24Regular
            tabIndex={0}
            onClick={() => setIsComponentExpanded(true)}
            className={mergeClasses(classNames.chevronIcon, disabled ? classNames.disabled : '')}
            onKeyDown={(ev) => {
              if (isButtonPressKey(ev.key)) {
                setIsComponentExpanded(true);
              }
            }}
          />
        )}
      </div>
      {shouldComponentExpand && (
        <>
          <Divider className={classNames.horizontalDivider} />
          <div className={classNames.contentWrapper}>
            <div className={classNames.contentLeft}>{onRenderLeft()}</div>
            {onRenderRight ? <Divider className={classNames.verticalDivider} vertical /> : <></>}
            {onRenderRight ? <div className={classNames.contentRight}>{onRenderRight()}</div> : <></>}
          </div>
        </>
      )}
    </Card>
  );
};

export default V2SectionalAccordion;
