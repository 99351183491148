import { mutatorAction } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import { EntityPreferences, EntityType, getAppStore } from '../../../../../../@data';
import { LOCAL_STORAGE_LOCALE } from '../../../../../../constants/AppConstants';
import { INotificationPreferences, ISettingsViewModel } from '../store/schema/ISettingsViewModel';
import { getStore } from '../store/store';

export const saveRawSettings = mutatorAction('saveRawSettings', (raw: EntityPreferences) => {
  getStore().raw = raw;
});

export const onSettingsChanged = mutatorAction('onSettingsChanged', (active: ISettingsViewModel) => {
  getStore().active = active;
  if (active.systemPreferences?.locale) {
    localStorage.setItem(LOCAL_STORAGE_LOCALE, active.systemPreferences.locale);
    getAppStore().locale = active.systemPreferences?.locale;
  }
});

export const setEntityType = mutatorAction('setEntityType', (entityType: EntityType) => {
  Log.debug(`Setting entityType: ${entityType}`);
  getStore().active.entityType = entityType;
});

export const setNotificationPreferences = mutatorAction('setNotificationPreferences', (np: INotificationPreferences) => {
  getStore().active.notificationPreferences = np;
});
