import { Checkbox, DefaultButton, List, mergeStyleSets, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { CustomPanelType, isTythonSelfServeUser } from '../../../@data';
import AppMessages from '../../../App.messages';
import { IconTooltip } from '../../../components/IconTooltip/IconTooltip';
import { APP_NAME_NEW, APP_NAME_PUBCENTER } from '../../../constants/AppConstants';
import { injectIntlAndObserver } from '../../../utils';
import { setPanelType } from '../@data/mutatorActions';
import { getStore } from '../@data/store/store';
import messages from '../GridV2.messages';
import { getClassNames } from '../GridV2.styles';
import { IColumnVisibilityMapping, IGridV2Column } from '../GridV2.types';

export interface IModifyColumnsPanelProps extends InjectedIntlProps {
  columns: IGridV2Column[];
  hideDescription?: boolean;
  changeColumnsVisibility: (changeColumnsVisibility: IColumnVisibilityMapping[]) => void;
}

export const ModifyColumnsPanel = injectIntlAndObserver(function ModifyColumnsPanelComponent({
  intl,
  columns,
  hideDescription,
  changeColumnsVisibility,
}: IModifyColumnsPanelProps) {
  const classNames = mergeStyleSets(getClassNames());

  const { formatMessage } = intl;
  const columnVisibilityMappings: IColumnVisibilityMapping[] = [];
  const { panelType } = getStore();

  const columnVisibilityChange = (column: IGridV2Column, value?: boolean) => {
    columnVisibilityMappings.push({ columnKey: column.key, isVisible: !!value });
  };

  const onModifyColumnsPanelHide = () => setPanelType(CustomPanelType.None);

  const handleModifyColumnsClicked = () => {
    changeColumnsVisibility(columnVisibilityMappings);
    onModifyColumnsPanelHide();
  };

  return (
    <Panel
      isOpen={panelType === CustomPanelType.ModifyColumns}
      type={PanelType.smallFixedFar}
      isLightDismiss
      onDismiss={onModifyColumnsPanelHide}
      headerText={formatMessage(AppMessages.modifyColumns)}
      closeButtonAriaLabel={formatMessage(AppMessages.panelCloseButtonAriaDesc)}
      styles={classNames.subComponentStyles.modifyColumnPanel}
      isFooterAtBottom={true}
      onRenderFooterContent={() => {
        return (
          <div className={classNames.panelButton}>
            <PrimaryButton
              onClick={handleModifyColumnsClicked}
              text={formatMessage(AppMessages.updateColumns)}
              ariaDescription={formatMessage(messages.modifyColumnsPanelApplyButtonAriaDesc)}
            />
            <DefaultButton onClick={onModifyColumnsPanelHide} text={formatMessage(AppMessages.cancel)} />
          </div>
        );
      }}
    >
      {!hideDescription && (
        <span className={classNames.modifyColumnDescription}>
          {formatMessage(AppMessages.modifyColumnsDescription, { appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER })}
        </span>
      )}
      <List
        items={columns}
        onRenderCell={(column: IGridV2Column) => {
          return (
            <div className={classNames.columnCheckboxWrapper}>
              <Checkbox
                label={column.name}
                defaultChecked={column.isVisible}
                onChange={(_, value) => columnVisibilityChange(column, value)}
                disabled={column.isShowingDisabled}
                styles={classNames.subComponentStyles.columnCheckbox}
              />
              {column.incompatibleProps?.isIncompatible && (
                <div className={classNames.columnTooltip}>
                  <IconTooltip tooltipMessage={column.incompatibleProps.incompatibleWontMessage || ''} iconName={'warning'} />
                </div>
              )}
            </div>
          );
        }}
      />
    </Panel>
  );
});
