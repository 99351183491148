import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'nativeAd.scriptPage.title',
    defaultMessage: 'Almost there! Time to place the code',
  },
  tips: {
    id: 'nativeAd.scriptPage.tips',
    defaultMessage: `Make sure you place the ad inside your feed. You can place it in between the content of your feed, or where the feed begins or ends. However, don’t place the ad in the sidebar as this can negatively affect performance. The container you place your ad in should have variable height. Fixed height containers might result in distorted ads.`,
  },
  subTitle1: {
    id: 'nativeAd.scriptPage.subTitle1',
    defaultMessage: 'Load the following script in your header tag',
  },
  scriptPlacementInfo: {
    id: 'nativeAd.scriptPage.scriptPlacementInfo',
    defaultMessage:
      'As of July 5, 2023, we launched a new version of our ad code. You will need to replace both the header script and ad code with the below if you placed them before July 5, 2023.',
  },
  subTitle2: {
    id: 'nativeAd.scriptPage.subTitle2',
    defaultMessage: 'Place the following code in your webpage where you want to render the ads.',
  },
  copyCodeButton: {
    id: 'nativeAd.scriptPage.copyCodeButton',
    defaultMessage: 'Copy code snippet',
  },
  copyScriptImageAlt: {
    id: 'nativeAd.scriptPage.copyScriptImageAlt',
    defaultMessage: 'Paste this code snippet inside head tag of your webpage.',
  },
  scriptCodePasteGuideline: {
    id: 'nativeAd.scriptPage.scriptCodePasteGuideline',
    defaultMessage: 'Click to copy script and paste it inside head tag of your webpage.',
  },
  copyCodeButtonDescription: {
    id: 'nativeAd.scriptPage.copyCodeButtonDescription',
    defaultMessage: 'Click to copy ad code and paste it in between your feed, to render in-feed ad.',
  },
  doneButtonLabel: {
    id: 'nativeAd.scriptPage.doneButtonLabel',
    defaultMessage: `I'm done`,
  },
  backButtonLabel: {
    id: 'nativeAd.scriptPage.backButtonLabel',
    defaultMessage: 'Back',
  },
  failedToFetchScript: {
    id: 'nativeAd.scriptPage.failedToFetchScript',
    defaultMessage: 'Failed to fetch script',
  },
  inValidStateInScriptPage: {
    id: 'nativeAd.scriptPage.inValidStateScriptPage',
    defaultMessage: 'You have arrived in an invalid state, please report.',
  },
});
