import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing } from '../../../theme';
import { ICommonStyleProps } from '../../types';
import aboutUsBG from './resources/About-us-background-image.jpg';

type StyleProperties =
  | 'introductionContainer'
  | 'imageContainer'
  | 'signInContainer'
  | 'header'
  | 'smaller'
  | 'font18'
  | 'font16'
  | 'font14'
  | 'bodyContainer'
  | 'imageWithTextOnLeft'
  | 'imageWithTextOnRight'
  | 'iconsContainer'
  | 'iconContainer'
  | 'stepsContainer'
  | 'textFieldTitle'
  | 'textFieldGroup'
  | 'centerTextAlign'
  | 'iconsContainerPrimary'
  | 'monetizationContainer';

export type AboutUsPageStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): AboutUsPageStyles => {
  const { theme } = props;
  const fonts = theme.fonts as IPubCenterFonts;
  const palette = theme.palette as IPubcenterPalette;
  const space = theme.spacing as IPubcenterSpacing;

  const imageWithTextGrid = {
    display: 'grid',
    padding: '40px 0',
    gap: '30px',
    alignItems: 'center',
    '@media(max-width: 1050px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media(max-width: 850px)': {
      display: 'block',
    },
  };

  return mergeStyleSets({
    introductionContainer: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      color: palette.white,
      '@media(max-width: 1000px)': {
        gridTemplateColumns: '1fr 1fr',
      },
      '@media(max-width: 850px)': {
        display: 'block',
      },
    },
    textFieldTitle: {
      color: palette.white,
    },
    textFieldGroup: {
      height: space.size50,
    },
    centerTextAlign: {
      textAlign: 'center',
    },
    imageContainer: {
      position: 'relative',
      background: `url(${aboutUsBG}), rgba(0,0,0,0.5)`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundBlendMode: 'multiply',
      backgroundRepeat: 'no-repeat',
      boxSizing: 'border-box',
      padding: '50px',
    },
    signInContainer: {
      background: '#10152E',
      boxSizing: 'border-box',
      padding: '50px',
      '> button': {
        float: 'right',
        marginTop: space.size6,
      },
    },
    bodyContainer: {
      margin: '0 auto',
      padding: '0 20px',
      '@media(min-width: 1200px)': {
        width: '1170px',
      },
    },
    imageWithTextOnLeft: {
      ...imageWithTextGrid,
      gridTemplateColumns: '2fr 1fr',
    },
    imageWithTextOnRight: {
      ...imageWithTextGrid,
      gridTemplateColumns: '1fr 2fr',
    },
    iconsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '25px',
      alignItems: 'baseline',
      textAlign: 'center',
      '@media(max-width: 850px)': {
        gridTemplateColumns: '1fr',
      },
    },
    iconsContainerPrimary: {
      padding: `${space.size40} 0`,
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    stepsContainer: {
      background: '#f2f2f2',
      padding: '40px 0',
    },
    monetizationContainer: {
      background: '#10152E',
      padding: '40px 0',
      color: 'white',
    },
    header: {
      fontSize: 38,
    },
    smaller: {
      ...fonts.size28,
    },
    font18: {
      ...fonts.size18,
      fontWeight: FontWeights.semibold,
      marginTop: '10px',
    },
    font16: {
      ...fonts.size16,
    },
    font14: {
      ...fonts.size14,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): AboutUsPageStyles {
  return getStyles({ theme: getTheme(), ...props });
}
