export enum PaymentInstrumentLifeCycleStatus {
  Created = 'Created',
  Submitted = 'Submitted',
  Pending = 'Pending',
  Failed = 'Failed',
  PinFailed = 'PinFailed',
  Blacklisted = 'Blacklisted',
  Success = 'Success',
  Validated = 'Validated'
}
