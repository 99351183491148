import { mutator } from 'satcheljs';
import { onNotificationChanged, onNotificationRemoved } from '../actions';
import { getAppStore } from '../store/store';

mutator(onNotificationChanged, (msg) => {
  const store = getAppStore();
  const notifications = store.notifications || [];
  store.notifications = [...notifications, msg.notification];
});

mutator(onNotificationRemoved, (msg) => {
  const store = getAppStore();
  const notifications = store.notifications || [];
  const indexOfNotification = notifications.findIndex((notification) =>
    msg.notification.component
      ? notification.id === msg.notification.id
      : notification.text === msg.notification.text || notification.id === msg.notification.id
  );
  store.notifications = [...notifications.slice(0, indexOfNotification), ...notifications.slice(indexOfNotification + 1)];
});
