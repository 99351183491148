import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { ApiError, getAppStore, onLoadingChanged, onNotificationChanged } from '../../../../../@data';
import messages from '../../AdReview/AdCreativePage.messages';
import { onAdCreativeAdPerfDataChanged, onAdQualityPanelApplyButtonClicked, onFilterPanelHide } from '../actions';
import { onRawFilterValuesChanged, onSetIsAppliedForAllConditions } from '../mutatorActions';
import { getAdPerfData } from '../services/AdCreativeService';
import { TARGET_CUSTOMER_ID } from '../services/utility/constants';
import { constructAdPerfRequest } from '../services/utility/utility';
import { converterIn } from '../store/adQualityConverter';
import { getStore } from '../store/store';

orchestrator(onAdQualityPanelApplyButtonClicked, async (msg) => {
  const { publisher } = getAppStore();
  const { active } = getStore();
  if (publisher && publisher.id && active.selectedAdSource) {
    try {
      onLoadingChanged(true);
      onAdCreativeAdPerfDataChanged([]);
      const data = converterIn(active.selectedAdSource, false);
      const param = new URLSearchParams([[TARGET_CUSTOMER_ID, publisher.id.toString()]]);
      const adPerfDataEnhanced = await getAdPerfData(constructAdPerfRequest(active, data, publisher), param);
      onAdCreativeAdPerfDataChanged(adPerfDataEnhanced);
      const activeFilters = getStore().filters.active;
      if (activeFilters) {
        onSetIsAppliedForAllConditions(activeFilters);
        onRawFilterValuesChanged(activeFilters);
      }
      onFilterPanelHide();
    } catch (e) {
      const apiErrorObj = e as ApiError;
      if (apiErrorObj && apiErrorObj.code === 500) {
        onNotificationChanged({
          textMessageDescriptor: messages.adqualityTimeoutQueringForData,
          intlMessageValues: { trackingId: apiErrorObj.trackingId },
          messageBarType: MessageBarType.severeWarning,
        });
      } else {
        onNotificationChanged({
          textMessageDescriptor: messages.adCreativeServiceLoadEntityFailed,
          messageBarType: MessageBarType.severeWarning,
        });
      }
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
