import { defineMessages } from 'react-intl';

export default defineMessages({
  appAdunitCodePanelTitle: {
    id: 'appAdunit.codePanelTitle',
    defaultMessage: 'Ad unit code template',
  },
  appAdunitManagePageGridTitle: {
    id: 'appAdunit.managePageGridTitle',
    defaultMessage: 'App ad units',
  },
  zeroAdUnitsTitle: {
    id: 'appAdunit.zeroAdUnitsTitle',
    defaultMessage: 'No ad units found by apps',
  },
  zeroAdUnitsDescription: {
    id: 'appAdunit.zeroAdUnitsDescription',
    defaultMessage: 'Create an app ad unit to start monetizing your app',
  },
  zeroAdUnitsCallToAction: {
    id: 'appAdunit.zeroAdUnitsCallToAction',
    defaultMessage: 'Create an App Ad Unit',
  },
  gridColumnTitleAppName: {
    id: 'appAdunit.gridColumnTitleAppName',
    defaultMessage: 'App Name',
  },
  gridColumnTitleAppId: {
    id: 'appAdunit.gridColumnTitleAppId',
    defaultMessage: 'App ID',
  },
  gridColumnTitleConnectedApp: {
    id: 'appAdunit.gridColumnTitleConnectedApp',
    defaultMessage: 'App connected',
  },
  gridColumnTitleAdUnitPlatform: {
    id: 'appAdunit.gridColumnTitleAdUnitPlatform',
    defaultMessage: 'Ad unit platform',
  },
  gridColumnTitleAppAdUnitStatus: {
    id: 'appAdunit.gridColumnTitleAppAdUnitStatus',
    defaultMessage: 'Status',
  },
  gridColumnTitleIssues: {
    id: 'appAdunit.gridColumnTitleIssues',
    defaultMessage: 'Issues',
  },
  gridColumnTitleActions: {
    id: 'appAdunit.gridColumnTitleActions',
    defaultMessage: 'Actions',
  },
  gridCreateButtonLabel: {
    id: 'appAdunit.gridCreateButtonLabel',
    defaultMessage: 'Create App Ad Unit',
  },
  propertyInventoryTypeLabelUWP: {
    id: 'appAdunit.propertyInventoryTypeLabelUWP',
    defaultMessage: 'Windows UWP',
  },
  siteTabLabel: {
    id: 'appAdunit.siteTabLabel',
    defaultMessage: 'By sites',
  },
  appTabLabel: {
    id: 'appAdunit.appTabLabel',
    defaultMessage: 'By apps',
  },
});
