import { History } from 'history';
import { action } from 'satcheljs';
import { FormState } from '../../../../../../../../components/Form';

export const onScriptPageInitialized = action('onScriptPageInitialized', (publisherId: string, adunitId: string) => ({
  publisherId,
  adunitId,
}));

export const onScriptChanged = action('onScriptChanged', (script: string | null) => ({ script }));

export const onCopyScriptIconChanged = action('onCopyScriptIconChanged', (iconName: string) => ({ iconName }));

export const onCopyCodeIconChanged = action('onCopyCodeIconChanged', (iconName: string) => ({ iconName }));

export const onScriptPageSubmitted = action('onScriptPageSubmitted', (mode: FormState, routeHistory: History, onSubmit?: () => void) => ({
  mode,
  routeHistory,
  onSubmit,
}));

export const onScriptPageFormCleared = action('onScriptPageFormCleared');
