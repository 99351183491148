import React, { ReactNode, Suspense } from 'react';
import { PageSpinner } from '../Spinners';

export interface ILazyLoadedComponentProps {
  fallback?: JSX.Element;
  lazyComponent: ReactNode;
}

export const LazyComponent = ({ fallback, lazyComponent }: ILazyLoadedComponentProps): JSX.Element => {
  if (!fallback) {
    fallback = <PageSpinner />;
  }

  return <Suspense fallback={fallback}>{lazyComponent}</Suspense>;
};
