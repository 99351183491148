import { mutatorAction } from 'satcheljs';
import { IStringDictionary, Property } from '../../../../../../@data';
import { mergeTypeWithObject } from '../../../../../../utils';
import { IClarityParams } from '../../../Manage/components/ClarityPanel/types';
import { IPropertyViewModel } from '../store/schema';
import { getCreatePropertyStore } from '../store/store';

export const onRawChanged = mutatorAction('onRawChanged', (property?: Property) => {
  getCreatePropertyStore().raw = mergeTypeWithObject(new Property(), property);
});

export const onPropertyChanged = mutatorAction('onPropertyChanged', (property: IPropertyViewModel) => {
  getCreatePropertyStore().active = property;
});

export const onPropertyErrorChanged = mutatorAction('onPropertyErrorChanged', (values?: IStringDictionary) => {
  const store = getCreatePropertyStore();
  if (!store.errors.name && !store.errors.url && !store.errors.approvalStatus && !store.errors.approvalStatusComments && !!values) {
    getCreatePropertyStore().errors = values;
  } else if (
    !!getCreatePropertyStore().errors.name ||
    !!getCreatePropertyStore().errors.url ||
    !!getCreatePropertyStore().errors.approvalStatus ||
    !!getCreatePropertyStore().errors.approvalStatusComments
  ) {
    getCreatePropertyStore().errors = values || {};
  }
});

export const onFormCleared = mutatorAction('onFormCleared', () => {
  getCreatePropertyStore().active = {};
});

export const onFormSubmittable = mutatorAction('onFormSubmittable', (isSubmittable: boolean) => {
  getCreatePropertyStore().isSubmittable = isSubmittable;
});

export const onIdChanged = mutatorAction('onIdChanged', (id: number) => {
  getCreatePropertyStore().active.id = id;
});

export const onNameChanged = mutatorAction('onNameChanged', (name: string) => {
  getCreatePropertyStore().active.name = name;
});

export const onUrlChanged = mutatorAction('onUrlChanged', (url: string) => {
  getCreatePropertyStore().active.url = url.trim();
});

export const onClarityChanged = mutatorAction('onClarityChanged', (clarity: IClarityParams) => {
  getCreatePropertyStore().active.clarity = clarity;
});

export const onClarityIdChanged = mutatorAction('onClarityIdChanged', (id?: string) => {
  const clarity = getCreatePropertyStore().active.clarity;
  if (clarity !== undefined) {
    clarity.clarityId = id?.trim();
  }
});

export const onEditClarityId = mutatorAction('onEditClarityId', (edit: boolean) => {
  const clarity = getCreatePropertyStore().active.clarity;
  if (clarity) {
    clarity.clarityEdit = edit;
  }
});

export const onSettingClarityErrorMessage = mutatorAction('onSettingClarityErrorMessage', (error: string) => {
  const store = getCreatePropertyStore();
  store.errors.clarity = error;
  const clarity = store.active.clarity;
  if (clarity) {
    clarity.clarityErrorMessage = error;
  }
});
