import { mutatorAction } from 'satcheljs';
import { PublisherTier } from '../store';

export const onSetFirstPartyBoost = mutatorAction('onIsFirstPartyBoost', (publisherTier?: PublisherTier) => {
  if (publisherTier === PublisherTier.SelfServe1stPartyBoost) {
    sessionStorage.setItem('IsBoostPublisher', 'true');
  } else {
    sessionStorage.setItem('IsBoostPublisher', 'false');
  }
});
