import { Checkbox, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Log } from '../../../../logging/src';
import { getAppStore } from '../../../@data';
import { FormItem } from '../../../components/Form';
import { PopupCard } from '../../../components/Popup';
import { APP_NAME_NEW, WAITLIST_FORM_LINK } from '../../../constants/AppConstants';
import { injectIntlAndObserver } from '../../../utils';
import { getSignUpStore } from '../@data';
import SignUpPageMessages from '../SignUpPage.messages';
import waitlistImage from '../resources/joinWaitlistImage.svg';

export interface INonUsSignupPopupProps extends InjectedIntlProps {}

export const NonUsSignupPopup = injectIntlAndObserver(function NonUsSignupPopupComp({
  intl: { formatMessage },
}: INonUsSignupPopupProps): JSX.Element | null {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const { fetchingOvdfStatus } = getSignUpStore() || {};
  const reviewStatus = sessionStorage.getItem('review_status');

  if (fetchingOvdfStatus || reviewStatus !== 'review') {
    return null;
  }

  const onTermsAccepted = (_, checked) => {
    setIsTermsAccepted(!!checked);
    Log.info(`User email: ${getAppStore().userContext?.email} have ${!checked ? 'not ' : ''}accepted the terms to join waitlist.`);
  };

  return (
    <PopupCard
      popUpTitle={formatMessage(SignUpPageMessages.joinTheWaitlist)}
      isShown
      onPopupDismiss={() => console.log('popup cannot be dismissed')}
      primaryButtonAriaLabel={formatMessage(SignUpPageMessages.joinWaitlistButtonName)}
      onPrimaryButtonClick={() => {
        Log.info(
          `User email : ${getAppStore().userContext?.email} have shown interest in joining Microsoft pubCenter and have ${
            !isTermsAccepted ? 'not ' : ''
          }accepted the terms to join waitlist.`
        );

        window.location.replace(WAITLIST_FORM_LINK);
      }}
      isCloseButtonHidden
    >
      <React.Fragment>
        <Stack horizontal>
          <img src={waitlistImage} alt={formatMessage(SignUpPageMessages.joinTheWaitlist)} />
          <div>
            <FormItem formWidth={360}>{formatMessage(SignUpPageMessages.waitlistPopupDesc, { appName: APP_NAME_NEW })}</FormItem>
            <Checkbox
              checked={isTermsAccepted}
              onChange={onTermsAccepted}
              label={formatMessage(SignUpPageMessages.waitlistTermsMessage, { appName: APP_NAME_NEW })}
              required
            />
          </div>
        </Stack>
      </React.Fragment>
    </PopupCard>
  );
});
