import { PrimaryButton } from '@fluentui/react';
import React, { useEffect, useMemo } from 'react';
import { UserInvitation, getAppStore } from '../../../../@data';
import { useCanvasTextWidth } from '../../../../hooks';
import {
  CommandBarActions,
  Direction,
  GridSelectionMode,
  GridV2,
  IBuildCommandBarProps,
  IGridPageCommandBarItemProps,
} from '../../../../layout/GridPageV2';
import { IRouterWithIntl, injectRouterAndIntlWithObserver } from '../../../../utils';
import { loadUserInvitations } from './@data/actions/userInvitationActions';
import './@data/mutatorActions';
import { setUserInvitationPanelStatus, setUserInvitations } from './@data/mutatorActions';
import './@data/orchestrators';
import { getUserInvitationStore } from './@data/store';
import { getColumns } from './UserInvitationColumns';
import UserInvitationsMessages from './UserInvitations.messages';
import { UserInvitePanel } from './UserInvitePanel';
import { ZeroInvitations } from './ZeroInvitations';

export interface IUserInvitationProps extends IRouterWithIntl {}

export const UserInvitationsTab = injectRouterAndIntlWithObserver(function UserInvitationsTabComponent(props: IUserInvitationProps) {
  const { formatMessage } = props.intl;
  const { publisher } = getAppStore();
  const { userInvitations } = getUserInvitationStore();

  const longestEmail = useMemo(
    () => userInvitations?.map((user) => user.email || '').reduce((a, b) => (a.length > b.length ? a : b), '') || '',
    [userInvitations]
  );

  useEffect(() => {
    loadUserInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commandBarProps: IBuildCommandBarProps<UserInvitation> = {
    includeActions: [CommandBarActions.searchBox],
    getCustomCommandBarItems: () => {
      const commandBarItems: IGridPageCommandBarItemProps[] = [
        {
          key: 'inviteUser',
          placement: { direction: Direction.before },
          commandBarButtonAs: () => (
            <PrimaryButton
              iconProps={{ iconName: 'Add' }}
              text={formatMessage(UserInvitationsMessages.invite)}
              onClick={() => setUserInvitationPanelStatus(true)}
            />
          ),
          selectionModes: [GridSelectionMode.None],
        },
      ];

      return commandBarItems;
    },
  };

  return (
    <React.Fragment>
      <GridV2
        {...props}
        isGridReady={!!publisher}
        entity={UserInvitation}
        commandBarProps={userInvitations.length > 0 ? commandBarProps : undefined}
        dataGridProps={{
          gridV2Columns: getColumns(props.intl, useCanvasTextWidth(longestEmail)),
          data: userInvitations,
          setData: setUserInvitations,
          renderZeroItemsComponent: <ZeroInvitations />,
        }}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
      <UserInvitePanel {...props} />
    </React.Fragment>
  );
});
