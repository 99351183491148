import { number, object, string } from 'yup';
import { IAccountViewModel } from '.';
import { StringListSchemaYupType } from '../../../../../../utils/validation';

export function accountErrorModel() {
  return object<IAccountViewModel>().shape({
    name: string().trim().required(),
    storeIds: new StringListSchemaYupType().of(number().integer()),
    partnerCodes: new StringListSchemaYupType().of(string()),
    formCodes: new StringListSchemaYupType().of(string()),
    startDistributionPlacementCodes: new StringListSchemaYupType().of(string()),
    startDistributionOCIDL2s: new StringListSchemaYupType().of(string()),
    operationRatio: number().integer(),
    revenueSharePercentage: number().integer(),
    startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage: number().max(100.0).min(0.0)
                                      .when("startDistributionPlacementCodes", (placementCodes, schema) => {
                                      // Revenue share % is only required when placement codes are selected.
                                      if (placementCodes !== null && placementCodes !== undefined && placementCodes.length > 0) {
                                        return schema.required();
                                      } else {
                                        return schema;
                                      }}),
  });
}
