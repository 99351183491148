import { mutatorAction } from 'satcheljs';
import { User } from '../../../../../../@data';
import { getUserManagementStore } from '../store';

export const setUserRoleChangePanelStatus = mutatorAction('setUserRoleChangePanelStatus', (isPanelOpen: boolean, selectedItem?: User) => {
  getUserManagementStore().roleChangePanelOpen = isPanelOpen;
  getUserManagementStore().selectedItem = selectedItem;
});

export const setSelectedItem = mutatorAction('setSelectedItem', (selectedItem: User) => {
  getUserManagementStore().selectedItem = selectedItem;
});

export const setUsers = mutatorAction('setUsers', (users?: User[]) => {
  getUserManagementStore().users = users || [];
});

// tslint:disable-next-line: no-any
export const updateUserRole = mutatorAction('updateUserRole', (user: User, updatedUser: any) => {
  const { users } = getUserManagementStore();
  const userToUpdate = users.find((item) => user.id === item.id);

  if (userToUpdate) {
    userToUpdate.userRoleDetails = updatedUser.userRoleDetails;
    userToUpdate.userRoleDetailsETag = updatedUser.eTag;
  }
});
