import { getTheme, IStackTokens, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterTheme } from '../../../../../theme';

interface IShareModalStyleProps {
  theme: ITheme;
}
type StyleProperties = 'errorMessage';
type ShareModalStyles = Record<StyleProperties, string>;

export const getStyles = (props: IShareModalStyleProps): ShareModalStyles => {
  const { theme } = props;
  const palette = theme.palette as IPubcenterPalette;
  return mergeStyleSets({
    errorMessage: {
      color: palette.error,
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
