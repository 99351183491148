import { Model } from '.';
import { IAddress } from '../interfaces';

export class UpdateUserContactDetails extends Model {
  public apiPath = undefined;
  public className = 'updateUserContact';
  public postalAddress?: IAddress;
  public userId: number;
  public phone?: string;
}
