import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../../../theme';
import { flexWithAlignCenter, flexWithCenter, noPointerEvents } from '../../../../../../utils/GlobalStyleUtils';

interface INavigatedPaginationStyleProps {
  theme: ITheme;
}

type StyleProperties = 'containerStyle' | 'itemStyle' | 'dropdownStyle' | 'disabledItemStyle';
export type NavigatedPaginationStyles = Record<StyleProperties, string>;

export const getStyles = (props: INavigatedPaginationStyleProps): NavigatedPaginationStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette } = theme;

  const buttonStyles = {
    ...flexWithCenter,
    width: 32,
    height: 32,
    minWidth: 32,
    margin: spacing.size4,
    color: palette.black,
  };

  return mergeStyleSets({
    containerStyle: {
      ...flexWithAlignCenter,
    },
    itemStyle: {
      ...buttonStyles,
      ':hover': {
        backgroundColor: palette.neutralLighter,
        color: palette.blackTranslucent40,
      },
    },
    dropdownStyle: {
      width: 80,
      margin: `0 ${spacing.size12}`,
    },
    disabledItemStyle: {
      opacity: 0.5,
      color: palette.blackTranslucent40,
      ...noPointerEvents,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): NavigatedPaginationStyles {
  return getStyles({ theme: getTheme(), ...props });
}
