import { Checkbox, ChoiceGroup, Icon, IconButton, Image, Link, TextField, getTheme } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { ClarityStatus, ClarityType } from '../../../../../../../../../../@data';
import AppMessages from '../../../../../../../../../../App.messages';
import { CLARITY_HOME_WEB_APP, CLARITY_PRIVACY_STATEMENT, CLARITY_WEB_APP } from '../../../../../../../../../../constants/AppConstants';
import {
  CLARITY_BASIC_RADIO_BUTTON,
  CLARITY_BEHAVIORAL_RADIO_BUTTON,
  CLARITY_EXISTING_RADIO_BUTTON,
} from '../../../../../../../../../../constants/DataTestIdConstants';
import { useWindowSize } from '../../../../../../../../../../hooks';
import { getStore as getWindowStore } from '../../../../../../../../../../hooks/useWindowSize/store/store';
import { StepState } from '../../../../../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../../../../../../../utils';
import { onClarityIdChanged, onEditClarityId } from '../../../../../../__data__/mutatorActions';
import { getCreatePropertyStore } from '../../../../../../__data__/store/store';
import { onApprovalBehavioralTC, onClarityTypeChanged } from '../../../../__data__/mutatorActions';
import messages from './messages';
import codeFree from './resources/codeFree.svg';
import existingClarity from './resources/existingClarity.svg';
import richBehavior from './resources/richBehavior.svg';
import { getClassNames, getStyles } from './styles';
export interface IClarityOptionsProps extends IRouterWithIntl {
  stepState?: StepState;
}

const { windowStoreSerializer } = useWindowSize();

export const ClarityOptions = injectIntlAndObserver<InjectedIntlProps & IClarityOptionsProps>((props: IClarityOptionsProps) => {
  const { intl } = props;
  const { formatMessage } = intl;
  const {
    active: { clarity },
  } = getCreatePropertyStore();
  const {
    clarityType,
    approvalBehavioralOption,
    clarityId,
    clarityEdit,
    originalClarityType,
    originalClarityId,
    clarityStatus,
    clarityErrorMessage,
  } = clarity || {};
  const chosenType = clarityType !== undefined ? clarityType.toString() : '';
  const isChosen = approvalBehavioralOption || false;

  const {
    wrapper,
    wrapperTitle,
    section,
    sectionDetails,
    disabledSection,
    sectionSelector,
    title,
    desc,
    toggle,
    advantageWrapper,
    advantageSection,
    imageWrapper,
    recommendationTag,
    advantageHeader,
    panelPending,
    panelProjectIdApprovedSection,
    errorMessage,
  } = getClassNames(getStyles, {
    theme: getTheme(),
  });
  const checkMark = <span>&#10003;</span>;
  const { isWindowRegularSize } = windowStoreSerializer(getWindowStore());

  const getAdvantage = (header: string, description: string) => {
    return (
      <div className={advantageSection}>
        {checkMark}
        <div>
          <p>{header}</p>
          <span>{description}</span>
        </div>
      </div>
    );
  };

  const getChoiceImage = (imgSrc: string, imgAlt: FormattedMessage.MessageDescriptor, onClick: (e) => void) => {
    return isWindowRegularSize ? (
      <Image className={imageWrapper} onClick={onClick} src={imgSrc} imageFit={1} alt={formatMessage(imgAlt)} />
    ) : (
      <div />
    );
  };

  const getInformationMessage = (status?: ClarityStatus) => {
    switch (status) {
      case ClarityStatus.Pending:
        return messages.sidePanelPending;
      case ClarityStatus.Declined:
        return messages.sidePanelDeclined;
      case ClarityStatus.Expired:
        return messages.sidePanelExpired;
      case ClarityStatus.Cancelled:
        return messages.sidePanelCancelled;
      default:
        return messages.sidePanelPending;
    }
  };

  const onOptionClick = (e, value) => {
    if (props.stepState === StepState.Dirty && clarityType === value) {
      return;
    }
    if (clarityType === value) {
      onClarityTypeChanged(undefined);
    } else {
      onClarityTypeChanged(value);
    }

    e.preventDefault();
  };

  return (
    <div className={wrapper}>
      <span className={wrapperTitle} title={formatMessage(AppMessages.required)}>
        {formatMessage(messages.microsoftClarity)}
      </span>
      <span>
        {formatMessage(messages.microsoftClarityInformation)}
        <Link href={CLARITY_HOME_WEB_APP} target="_blank" rel="noreferrer noopener" underline>
          {formatMessage(messages.microsoftClarityTermsOfUse)}
        </Link>
      </span>
      <div className={section}>
        {(originalClarityType === ClarityType.None || originalClarityType === ClarityType.Fraud) && <div className={disabledSection} />}

        <ChoiceGroup
          ariaLabelledBy={formatMessage(messages.advancedOptionHeader)}
          selectedKey={chosenType}
          className={sectionSelector}
          options={[
            {
              key: '1',
              text: '',
              ariaLabel: formatMessage(messages.advancedOptionHeader),
              onClick: (e) => onOptionClick(e, ClarityType.Behavioral),
              onKeyDown: (e) => e.key === 'Enter' && onOptionClick(e, ClarityType.Behavioral),
            },
          ]}
          disabled={originalClarityType === ClarityType.None || originalClarityType === ClarityType.Fraud}
          data-test-id={CLARITY_BEHAVIORAL_RADIO_BUTTON}
        />

        {getChoiceImage(
          richBehavior,
          messages.advancedOptionHeader,
          (e) => originalClarityType !== ClarityType.None && onOptionClick(e, ClarityType.Behavioral)
        )}
        <div className={sectionDetails}>
          <div>
            <div className={advantageHeader}>
              <p className={title}>{formatMessage(messages.advancedOptionHeader)}</p>
              <p className={recommendationTag}>{formatMessage(messages.recommendationTag)}</p>
            </div>
            <span className={desc}>{formatMessage(messages.advancedOptionDesc)}</span>
          </div>
          <div className={advantageWrapper}>
            {getAdvantage(formatMessage(messages.advantageOneHeader), formatMessage(messages.advantageOneDesc))}
            {getAdvantage(formatMessage(messages.advantageTwoHeader), formatMessage(messages.advantageTwoDesc))}
            {getAdvantage(formatMessage(messages.advantageThreeHeader), formatMessage(messages.advantageThreeDesc))}
          </div>
          {chosenType === ClarityType.Behavioral.toString() && (
            <Checkbox
              checked={isChosen}
              className={toggle}
              onChange={(_, state) => onApprovalBehavioralTC(state || false)}
              title={formatMessage(messages.advancedOptionApproval)}
              aria-hidden={true}
              disabled={originalClarityType === ClarityType.Behavioral}
              onRenderLabel={() => (
                <FormattedMessage
                  {...messages.microsoftClarityTC}
                  values={{
                    privacy: (
                      <Link href={CLARITY_PRIVACY_STATEMENT} target="_blank" rel="noreferrer noopener" underline>
                        {formatMessage(messages.microsoftClarityPrivacy)}
                      </Link>
                    ),
                  }}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className={section}>
        {(originalClarityType === ClarityType.None || originalClarityType === ClarityType.Behavioral) && (
          <div className={disabledSection} />
        )}

        <ChoiceGroup
          ariaLabelledBy={formatMessage(messages.basicOptionHeader)}
          selectedKey={chosenType}
          className={sectionSelector}
          options={[{ key: '0', text: '', ariaLabel: formatMessage(messages.basicOptionHeader) }]}
          onClick={(e) => onOptionClick(e, ClarityType.Fraud)}
          onKeyDown={(e) => e.key === 'Enter' && onOptionClick(e, ClarityType.Fraud)}
          disabled={originalClarityType === ClarityType.Behavioral || originalClarityType === ClarityType.None}
          data-test-id={CLARITY_BASIC_RADIO_BUTTON}
        />
        {getChoiceImage(
          codeFree,
          messages.basicOptionHeader,
          (e) =>
            originalClarityType !== ClarityType.Behavioral &&
            originalClarityType !== ClarityType.None &&
            onOptionClick(e, ClarityType.Fraud)
        )}
        <div className={sectionDetails}>
          <div>
            <p className={title}>{formatMessage(messages.basicOptionHeader)}</p>
            <span className={desc}>{formatMessage(messages.basicOptionDesc)}</span>
          </div>
          <div className={advantageWrapper}>
            {getAdvantage(formatMessage(messages.advantageOneHeader), formatMessage(messages.advantageOneDesc))}
          </div>
        </div>
      </div>

      <div className={section}>
        <ChoiceGroup
          ariaLabelledBy={formatMessage(messages.existingClarityOptionHeader)}
          selectedKey={chosenType}
          className={sectionSelector}
          options={[{ key: '2', text: '', ariaLabel: formatMessage(messages.existingClarityOptionHeader) }]}
          onClick={(e) => onOptionClick(e, ClarityType.None)}
          onKeyDown={(e) => e.key === 'Enter' && onOptionClick(e, ClarityType.None)}
          data-test-id={CLARITY_EXISTING_RADIO_BUTTON}
        />
        {getChoiceImage(existingClarity, messages.existingClarityOptionHeader, (e) => onOptionClick(e, ClarityType.None))}
        <div className={sectionDetails}>
          <div>
            <p className={title}>{formatMessage(messages.existingClarityOptionHeader)}</p>
            <span className={desc}>
              <FormattedMessage {...messages.existingClarityOptionDesc} values={{ br: <br /> }} />
            </span>
          </div>
          <div>
            {originalClarityId === undefined || clarityEdit ? (
              <div className={panelProjectIdApprovedSection}>
                <TextField
                  iconProps={{
                    iconName: clarityEdit ? 'Cancel' : '',
                    style: {
                      pointerEvents: 'auto',
                      cursor: 'pointer',
                    },
                    onClick: (e) => {
                      onEditClarityId(!clarityEdit);
                      if (originalClarityId) {
                        onClarityIdChanged(originalClarityId);
                      }
                    },
                  }}
                  ariaLabel={formatMessage(messages.clarityId)}
                  disabled={chosenType !== ClarityType.None.toString()}
                  errorMessage={clarityErrorMessage}
                  value={clarityId}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(_, value: string) => onClarityIdChanged(value)}
                />
              </div>
            ) : (
              <>
                <div className={panelProjectIdApprovedSection}>
                  {clarityStatus === ClarityStatus.Approved ? (
                    <Link href={CLARITY_WEB_APP.replace('{id}', originalClarityId)} target="_blank" rel="noreferrer noopener">
                      {originalClarityId}
                    </Link>
                  ) : (
                    <>
                      <span>{originalClarityId}</span>
                    </>
                  )}
                  <IconButton iconProps={{ iconName: 'Edit' }} onClick={() => onEditClarityId(!clarityEdit)} />
                </div>
                {clarityErrorMessage !== undefined && <span className={errorMessage}>{clarityErrorMessage}</span>}
              </>
            )}
            {clarityStatus !== ClarityStatus.Approved && !!originalClarityId && (
              <div className={panelPending}>
                <Icon iconName={'Info'} aria-label={formatMessage(AppMessages.infoLabel)} />
                {formatMessage(getInformationMessage(clarityStatus))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
