import React, { useEffect, useRef } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import messages from '../../CreateAdunit/messages';

type AdPreviewType = (container: HTMLDivElement | null) => void;
declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    AdPreview: AdPreviewType;
  }
}

export interface IAdPreviewConfig {
  adWidth: number;
  adHeight: number;
  background?: string;
  fontFamily?: string;
  titleColor?: string;
  buttonBackground?: string;
}

interface IAdPreviewProps {
  config: IAdPreviewConfig;
}

const AdPreview: React.FC<IAdPreviewProps & { intl: InjectedIntl }> = ({ config, intl: { formatMessage } }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const adPreviewRef = useRef<null | {
    render: (
      config,
      secondaryConfigs?: { image: { src: string; width: number; height: number }; title: string; providerName: string; ctaText: string }
    ) => void;
  }>(null); // new ref to store adPreview instance

  useEffect(() => {
    const container = containerRef.current;
    if (container && window.AdPreview) {
      adPreviewRef.current = new window.AdPreview(container);
    }
  }, []); // Empty array to only run once

  useEffect(() => {
    if (adPreviewRef.current) {
      adPreviewRef.current.render(config, {
        image: {
          src: 'https://adsdk.microsoft.com/assets/images/ad-preview.png',
          width: config.adWidth,
          height: config.adHeight,
        },
        title: formatMessage(messages.nativeAdPreviewPreviewTitle),
        providerName: formatMessage(messages.nativeAdPreviewPreviewUrl),
        ctaText: formatMessage(messages.displayAdPreviewLearnMoreButton),
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]); // Run every time config changes

  return <div ref={containerRef} />;
};

export default injectIntl(AdPreview);
