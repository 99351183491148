export enum ServingStatus {
  Pending = 'Pending',
  NotServing = 'Rejected',
  Serving = 'Approved',
  OnHold = 'On hold',
}

export type ServingStatusKey = keyof typeof ServingStatus;

export const ServingStatusLabel: { [key in ServingStatusKey]: string } = {
  Pending: 'servingStatusPending',
  NotServing: 'servingStatusNotServing',
  Serving: 'servingStatusServing',
  OnHold: 'servingStatusOnHold',
};

export const ServingStatusMap = {
  0: ServingStatus.Pending,
  1: ServingStatus.NotServing,
  2: ServingStatus.Serving,
  3: ServingStatus.OnHold,
};
