import { ActionButton, Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { onNavigateBack } from '../../../../../@data';
import AppMessages from '../../../../../App.messages';
import { breadcrumbStyles, getClassNames } from './SubPageNavbar.styles';

interface ISubPageNavBarProps extends InjectedIntlProps {
  backAriaDescription: string;
  breadcrumbAriaLabel?: string;
  breadcrumbItems?: IBreadcrumbItem[];
}

export const SubPageNavBar = injectIntl(({ intl, backAriaDescription, breadcrumbAriaLabel, breadcrumbItems }: ISubPageNavBarProps) => {
  const { navBackButton } = getClassNames();
  return (
    <div className={navBackButton}>
      <ActionButton
        iconProps={{
          iconName: 'Back',
        }}
        onClick={() => onNavigateBack()}
        ariaDescription={backAriaDescription}
      >
        {intl.formatMessage(AppMessages.back)}
      </ActionButton>
      {breadcrumbItems && <Breadcrumb items={breadcrumbItems} ariaLabel={breadcrumbAriaLabel} styles={breadcrumbStyles} />}
    </div>
  );
});
