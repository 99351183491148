import { Model } from '.';
import { IUserRoleDetail } from '..';

export class UserRoleUpdate extends Model {
  public apiPath = undefined;
  public className = 'updateRoles';
  public addedRoles?: IUserRoleDetail[];
  public removedRoles?: IUserRoleDetail[];
  public eTag?: string;
}

export class FetchDevTokenStatus extends Model {
  public apiPath = undefined;
  public className = 'fetchApiDevTokenStatus';
  public TokenExists?: boolean;
  public ApiDevToken?: string;
}

export class GenerateDevToken extends Model {
  public apiPath = undefined;
  public className = 'generateApiDevToken';
  public ApiDevToken?: string;
}
