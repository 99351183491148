import { ChoiceGroup, classNamesFunction, getTheme, IProcessedStyleSet } from '@fluentui/react';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { IIdNamePair, MultiSelectPicker } from '../../../../components/MultiSelectPicker';
import { getStore } from '../@data';
import messages from '../UserPage.messages';
import { getStyles } from './AccountRole.styles';
import { IAccountRoleProps, IAccountRoleStyleProps, IAccountRoleStyles } from './AccountRole.types';

const getClassNames = classNamesFunction<IAccountRoleStyleProps, IAccountRoleStyles>();

export const AccountRole = injectIntl(
  class extends React.Component<IAccountRoleProps> {
    private _classNames: IProcessedStyleSet<IAccountRoleStyles>;
    public render() {
      this._classNames = getClassNames(getStyles, { theme: getTheme() });
      const { formatMessage } = this.props.intl;
      const { defaultSelectedKey, onChange } = this.props;
      const { active } = getStore();

      let initialSelectedItems: IIdNamePair[] | undefined;
      let isAccountLevelRole = false;
      switch (defaultSelectedKey) {
        case 'AccountManager':
          initialSelectedItems = active.AccountManagerAccountsSelected;
          isAccountLevelRole = true;
          break;
        case 'ReportUser':
          initialSelectedItems = active.ReportUserAccounstSelected;
          isAccountLevelRole = true;
          break;
        case 'AdViewer':
          initialSelectedItems = active.AdViewAccountsSelected;
          isAccountLevelRole = true;
          break;
        case 'ListManager':
          initialSelectedItems = active.ListManagerAccountsSelected;
          isAccountLevelRole = true;
          break;
        default:
          initialSelectedItems = undefined;
      }
      return (
        <>
          <ChoiceGroup
            label={formatMessage(messages.accountRole)}
            required={true}
            options={[
              {
                key: 'AccountManager',
                text: formatMessage(messages.accountManager),
                ariaLabel: formatMessage(messages.accountManager),
                onRenderField: (props, render) => {
                  return (
                    <div className={this._classNames.option}>
                      <div className={this._classNames.optionTitle}>{render!(props)}</div>
                      <span>{formatMessage(messages.accountManagerDescription)}</span>
                    </div>
                  );
                },
              },
              {
                key: 'ReportUser',
                text: formatMessage(messages.reportUser),
                ariaLabel: formatMessage(messages.reportUser),
                onRenderField: (props, render) => {
                  return (
                    <div className={this._classNames.option}>
                      <div className={this._classNames.optionTitle}>{render!(props)}</div>
                      <span>{formatMessage(messages.reportUserDescription)}</span>
                    </div>
                  );
                },
              },
              {
                key: 'ListManager',
                text: formatMessage(messages.listManager),
                ariaLabel: formatMessage(messages.listManager),
                onRenderField: (props, render) => {
                  return (
                    <div className={this._classNames.option}>
                      <div className={this._classNames.optionTitle}>{render!(props)}</div>
                      <span>{formatMessage(messages.listManagerDescription)}</span>
                    </div>
                  );
                },
              },
              {
                key: 'AdViewer',
                text: formatMessage(messages.adViewer),
                ariaLabel: formatMessage(messages.adViewer),
                onRenderField: (props, render) => {
                  return (
                    <div className={this._classNames.option}>
                      <div className={this._classNames.optionTitle}> {render!(props)}</div>
                      <span>{formatMessage(messages.adViewerDescription)}</span>
                    </div>
                  );
                },
              },
              {
                key: 'PublisherAdmin',
                text: formatMessage(messages.administrator),
                ariaLabel: formatMessage(messages.administrator),
                onRenderField: (props, render) => {
                  return (
                    <div className={this._classNames.option}>
                      <div className={this._classNames.optionTitle}>{render!(props)}</div>
                      <span>{formatMessage(messages.administratorDescription)}</span>
                    </div>
                  );
                },
              },
              {
                key: 'SuperAdmin',
                text: formatMessage(messages.superAdmin),
                ariaLabel: formatMessage(messages.superAdmin),
                onRenderField: (props, render) => {
                  return (
                    <div className={this._classNames.option}>
                      <div className={this._classNames.optionTitle}>{render!(props)}</div>
                      <span>{formatMessage(messages.superAdminDescription)}</span>
                    </div>
                  );
                },
              },
            ]}
            selectedKey={defaultSelectedKey || 'AdViewer'}
            onChange={onChange}
          />
          {isAccountLevelRole && (
            <MultiSelectPicker
              {...this.props}
              ariaDescription={formatMessage(messages.accountMultiSelectPickerAriaDesc)}
              items={this.props.accountList || []}
              disabled={false}
              initialSelectedItems={initialSelectedItems}
              errorMessage={this.props.errorMessage}
              onSelectedItems={(items) => this.props.onSelectedItems(items)}
            />
          )}
        </>
      );
    }
  }
);
