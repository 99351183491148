import { RedirectRequest } from '@azure/msal-browser';
import { orchestrator } from 'satcheljs';
import { getLoginPageStore } from '..';
import { envBasedConfig } from '../../../../config/pubCenterAAD.config';
import { UNAUTHORIZED_LOCAL_STORAGE_LOCALE } from '../../../../constants';
import { validateSchema } from '../../../../utils';
import { onErrorsChanged, onLoginButtonClicked, onPubCenterAADLogin } from '../actions';
import { loginPageErrorModel } from '../store/schema/LoginPageErrorModel';

orchestrator(onLoginButtonClicked, async () => {
  /**
   * make sure browser storage is clean before firing the login mechanism
   * https://msasg.visualstudio.com/Bing_Ads/_sprints/taskboard/AdSyndication%20Team/Bing_Ads/2023/05-May?workitem=5233694
   */
  const localStorageUnAuthLocale = window.localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE);
  window?.localStorage.clear();
  window?.sessionStorage.removeItem('msal.interaction.status');
  sessionStorage.removeItem(`msal.${envBasedConfig.clientId}.interaction.status`);
  if (localStorageUnAuthLocale) {
    window.localStorage.setItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE, localStorageUnAuthLocale);
  }

  const { active } = getLoginPageStore();

  try {
    await validateSchema(loginPageErrorModel(), active);
    onErrorsChanged();

    const requestParams: Partial<RedirectRequest> = { loginHint: active.email };
    onPubCenterAADLogin(requestParams);
  } catch (e) {
    onErrorsChanged(e);
    return;
  }
});
