import { FeedbackType } from '../../../../layout/PageLayout/header-panels/FeedbackPanel/@data/store/schema/enums/FeedbackType';
import { Model } from './Model';

export class Feedback extends Model {
  public apiPath = undefined;
  public className = 'feedback';
  public type?: FeedbackType;
  public title?: string;
  public description?: string;
  public email?: string;
}
