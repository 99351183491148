export enum ApiErrorCodes {
  AdUnitDoesNotHaveTemplate = 'AdUnitDoesNotHaveTemplate',
  /** When API call fails due to token exiry or other authentication failure below error codes are returned */
  InvalidOAuthToken = 'InvalidOAuthToken',
  InvalidUserCredentials = 'InvalidUserCredentials',
  InvalidCCAuthToken = 'InvalidClientCenterAuthenticationToken',
  UserDoesNotExist = 'UserDoesNotExist',
  UserAlreadyMigrated = 'UserAlreadyMigrated',
  UserIdNotFound = 'UserIdNotFound',
}
