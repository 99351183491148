import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { onGridPageV2Initialized } from '../actions/gridActions';
import { setData, setEntity, setQuery } from '../mutatorActions';
import { getStore } from '../store/store';

orchestrator(onGridPageV2Initialized, ({ data, entity, query }) => {
  const store = getStore();
  let initialize = false;

  if (query && store.query !== query) {
    setQuery(query);
    initialize = true;
  }

  if (store.entity !== entity) {
    initialize = true;
    setEntity(entity);
  }

  if (JSON.stringify(store.data) !== JSON.stringify(data)) {
    setData(data);
    initialize = true;
  }

  if (initialize) {
    Log.debug('onGridPageV2Initialized');
  }
});
