import React from 'react';
import EntityCreationLayout, { EntityTypeCreationType } from '../../../../layout/EntityCreationLayout';

const AdTypePage = () => {
  // TODO: enhance injectRouterWithIntl type to work with functional components
  // @ts-ignore
  return <EntityCreationLayout entityType={EntityTypeCreationType.Ads} />;
};

export default AdTypePage;
