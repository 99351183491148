import { DataVizPalette, getColorFromToken } from '@fluentui/react-charting';
import { TythonColumnsEnum } from '../@data/store/schema/ColumnsEnum';

export const CHART_COLOR_TOKENS = [
  DataVizPalette.color1,
  DataVizPalette.color2,
  DataVizPalette.color3,
  DataVizPalette.color4,
  DataVizPalette.color5,
  DataVizPalette.color6,
  DataVizPalette.color7,
  DataVizPalette.color8,
  DataVizPalette.color9,
  DataVizPalette.color10,
  DataVizPalette.color11,
];
export const CHART_COLOR_TOKENS_GRADIENTS = CHART_COLOR_TOKENS.map((token) => getColorFromToken(token));

export const METRICS_COLORS = {
  [TythonColumnsEnum.Revenue]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[0],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.ImpressionsServed]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[1],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.Impressions]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[1],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.ImpressionsViewed]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[2],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.ViewableImpressions]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[2],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.eCPM]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[3],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.AdECPM]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[3],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.Clicks]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[4],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.AdClicks]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[4],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.Viewability]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[5],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.ImpressionsRate]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[5],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.CTR]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[6],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.AdCTR]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[6],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },

  [TythonColumnsEnum.FillRate]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[7],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.MeasurableImpressionsRate]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[7],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.MeasurableImpressions]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[8],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.EligibleImpressions]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[9],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
  [TythonColumnsEnum.ResponsesServed]: {
    main: CHART_COLOR_TOKENS_GRADIENTS[10],
    gradients: CHART_COLOR_TOKENS_GRADIENTS,
  },
};
