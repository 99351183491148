import { IconButton, Link } from '@fluentui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { CLARITY_WEB_APP } from '../../../../../../../../../../constants/AppConstants';
import { IClarityParams } from '../../../../../../../Manage/components/ClarityPanel/types';
import { onClarityIdChanged, onEditClarityId } from '../../../../../../__data__/mutatorActions';

interface IConnectedClarityFieldProps {
  configs?: IClarityParams;
}

const ConnectedClarityField = observer(({ configs }: IConnectedClarityFieldProps) => {
  const { originalClarityId: existingId, clarityId: id, clarityEdit: isEditable } = configs || {};

  return (
    <>
      {id && (
        <Link href={CLARITY_WEB_APP.replace('{id}', id)} target="_blank" rel="noreferrer noopener">
          {id}
        </Link>
      )}
      <IconButton
        iconProps={{ iconName: 'Edit' }}
        onClick={() => {
          onEditClarityId(!isEditable);
          if (existingId) {
            onClarityIdChanged(existingId);
          }
        }}
      />
    </>
  );
});

export default ConnectedClarityField;
