import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { FilterDownloader, getAppStore, getEntity, onNotificationChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import {
  onFormDownloadClicked,
  onFormDownloadExcludedAdkeywordLoading,
  onFormDownloadExcludedQuerykeywordLoading,
  onFormDownloadExcludedUrlLoading,
} from '../actions';
import { getFilterStore } from '../store/store';

orchestrator(onFormDownloadClicked, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { active } = getFilterStore();

  // TODO: Figure out how to internationalize orchestrators. #1632176
  let notification: INotificationBarItem = {};

  if (publisher) {
    try {
      const params = new URLSearchParams([['blockType', msg.value]]);

      const filterDownloader: FilterDownloader = {
        apiPath: undefined,
        className: 'filterdownloader',
        id: active.id,
      };
      switch (msg.value) {
        case 'ExcludedURL':
          onFormDownloadExcludedUrlLoading(true);
          break;
        case 'ExcludedAdKeyword':
          onFormDownloadExcludedAdkeywordLoading(true);
          break;
        case 'ExcludedQueryKeyword':
          onFormDownloadExcludedQuerykeywordLoading(true);
          break;
      }
      const response = await getEntity<FilterDownloader>([publisher, filterDownloader], userContext, undefined, params);
      const filterItems = response;

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = filterItems.downloadUrl!;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      switch (msg.value) {
        case 'ExcludedURL':
          onFormDownloadExcludedUrlLoading(false);
          break;
        case 'ExcludedAdKeyword':
          onFormDownloadExcludedAdkeywordLoading(false);
          break;
        case 'ExcludedQueryKeyword':
          onFormDownloadExcludedQuerykeywordLoading(false);
          break;
      }

      notification = {
        text: `Download Filter_${active.id}_${msg.value}  success`,
        messageBarType: MessageBarType.success,
      };
    } catch (e) {
      notification = {
        text: `Download Filter_${active.id}_${msg.value} caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
    }
  } else {
    notification = {
      text: `No publisher is selected.`,
      messageBarType: MessageBarType.error,
    };
  }

  onNotificationChanged(notification);
});
