import { ImageMetaData, LifeCycleStatus, Model } from '../../../../../../../@data';
import '../../../../../../../@data/store/schema/models/ImageMetaData';

export class AdPerformanceData extends Model {
  public apiPath = undefined;
  public className = 'adPerformanceData';
  public publisherId?: number;
  public accountId?: number;
  public accountName?: string;
  public adUnitId?: number;
  public adUnitName?: string;
  public advertiserId?: number;
  public advertiserAccountId?: number;
  public advertiserName?: string;
  public adId?: number;
  public uniqueCreativeAdId?: string;
  public adTitle?: string;
  public adDescription?: string;
  public adURL?: string;
  public adDestUrl?: string;
  public adImpressions?: number;
  public images?: string[];
  public revenue?: number;
  public adType?: string;
  public adSource?: string;
  public adunitIdAdId?: string;
  public imagesContext?: ImageMetaData[];
  public blockedAdIdImages?: string[];
  public blockedImages: string[];
  public pagetypes?: string[];
  public regions?: string[];
  public adProvider?: number;
  public status?: LifeCycleStatus;
}
