import { Body1Strong, InteractionTag, InteractionTagPrimary } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import messages from '../messages';
import AdPreviewDynamicRenderer from './AdPreviewDynamicRenderer';
import { useAppAdUnitPreviewStyles } from './styles';
import { AppAdUnitPreviewLayout, StaticAdPreviewType } from './types';

const StaticAdPreview: StaticAdPreviewType = ({ intl: { formatMessage }, dimension }) => {
  const [previewLayout, setPreviewLayout] = useState<AppAdUnitPreviewLayout>(AppAdUnitPreviewLayout.Horizontal);

  const classNames = useAppAdUnitPreviewStyles();

  const shouldRenderSquareAdPreview = true; // TODO: update this

  useEffect(() => {
    setPreviewLayout(AppAdUnitPreviewLayout.Horizontal);
  }, []);

  return (
    <div className={classNames.root}>
      <div className={classNames.tittleWrapper}>
        <Body1Strong>{formatMessage(messages.adTypePreviewTitle)}</Body1Strong>
        {!dimension && (
          <div className={classNames.layoutTagsWrapper}>
            <InteractionTag
              onClick={() => setPreviewLayout(AppAdUnitPreviewLayout.Horizontal)}
              appearance={previewLayout === AppAdUnitPreviewLayout.Horizontal ? 'brand' : 'filled'}
            >
              <InteractionTagPrimary>{formatMessage(messages.adTypePreviewLayoutHorizontal)}</InteractionTagPrimary>
            </InteractionTag>
            {shouldRenderSquareAdPreview && (
              <InteractionTag
                onClick={() => setPreviewLayout(AppAdUnitPreviewLayout.Square)}
                appearance={previewLayout === AppAdUnitPreviewLayout.Square ? 'brand' : 'filled'}
              >
                <InteractionTagPrimary>{formatMessage(messages.adTypePreviewLayoutSquare)}</InteractionTagPrimary>
              </InteractionTag>
            )}
            <InteractionTag
              onClick={() => setPreviewLayout(AppAdUnitPreviewLayout.Vertical)}
              appearance={previewLayout === AppAdUnitPreviewLayout.Vertical ? 'brand' : 'filled'}
            >
              <InteractionTagPrimary>{formatMessage(messages.adTypePreviewLayoutVertical)}</InteractionTagPrimary>
            </InteractionTag>
          </div>
        )}
      </div>
      <div className={classNames.previewWrapper}>
        <AdPreviewDynamicRenderer layout={previewLayout} />
      </div>
    </div>
  );
};

export default injectIntl(StaticAdPreview);
