import { defineMessages } from 'react-intl';

export default defineMessages({
  auditHistory: {
    id: 'auditHistory',
    defaultMessage: 'Audit history',
  },
  dateTime: {
    id: 'auditHistory.dateTime',
    defaultMessage: 'Date/Time (UTC)',
  },
  userEmail: {
    id: 'auditHistory.userEmail',
    defaultMessage: 'User email',
  },
  userRole: {
    id: 'auditHistory.userRole',
    defaultMessage: 'User role',
  },
  actionType: {
    id: 'auditHistory.actionType',
    defaultMessage: 'Action type',
  },
  actionOn: {
    id: 'auditHistory.actionOn',
    defaultMessage: 'Entity',
  },
  actionDetails: {
    id: 'auditHistory.actionDetails',
    defaultMessage: 'Action details',
  },
  showAll: {
    id: 'auditHistory.showAll',
    defaultMessage: 'Show all details',
  },
  hideAll: {
    id: 'auditHistory.hideAll',
    defaultMessage: 'Hide all details',
  },
  showDetails: {
    id: 'auditHistory.showDetails',
    defaultMessage: 'Show details',
  },
  hideDetails: {
    id: 'auditHistory.hideDetails',
    defaultMessage: 'Hide details',
  },
  create: {
    id: 'auditHistory.create',
    defaultMessage: 'Create',
  },
  update: {
    id: 'auditHistory.update',
    defaultMessage: 'Update',
  },
  delete: {
    id: 'auditHistory.delete',
    defaultMessage: 'Delete',
  },
  export: {
    id: 'auditHistory.export',
    defaultMessage: 'Export',
  },
  download: {
    id: 'auditHistory.download',
    defaultMessage: 'Download',
  },
  invite: {
    id: 'auditHistory.invite',
    defaultMessage: 'Invite',
  },
  inviteAccept: {
    id: 'auditHistory.inviteAccept',
    defaultMessage: 'Invite accept',
  },
  report: {
    id: 'auditHistory.report',
    defaultMessage: 'Report',
  },
  payment: {
    id: 'auditHistory.payment',
    defaultMessage: 'Payment',
  },
  adunit: {
    id: 'auditHistory.adunit',
    defaultMessage: 'Ad Unit',
  },
  user: {
    id: 'auditHistory.user',
    defaultMessage: 'User',
  },
  noAuditsFound: {
    id: 'auditHistory.noAuditsFound',
    defaultMessage: 'No logs found',
  },
  noPublisherSelected: {
    id: 'auditHistory.noPublisherSelected',
    defaultMessage: `A publisher isn't selected yet. To see logs, search for a publisher..`,
  },
  apiCallFailedNotification: {
    id: 'auditHistory.apiCallFailedNotification',
    defaultMessage: 'Failed to fetch audit history',
  },
  startingDownloadNotification: {
    id: 'auditHistory.startingDownloadNotification',
    defaultMessage: 'Fetching all data and starting download',
  },
});
