import { styled } from '@fluentui/react';
import { MultiSelectPickerBase } from './MultiSelectPicker.base';
import { getStyles } from './MultiSelectPicker.styles';
import { IMultiSelectPickerProps, IMultiSelectPickerStyleProps, IMultiSelectPickerStyles } from './MultiSelectPicker.types';

// Use Fabric Base guidelines since this might be contributed back to Fabric.
export const MultiSelectPicker = styled<IMultiSelectPickerProps, IMultiSelectPickerStyleProps, IMultiSelectPickerStyles>(
  MultiSelectPickerBase,
  getStyles,
  undefined,
  {
    scope: 'MultiSelectPicker',
  }
);
