import { getTheme, IStackTokens, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubcenterTheme } from '../../../../../theme';

const { ResponsiveMedium, ResponsiveSmall } = BreakPoints;

export interface ITopMetricsStyleProps {
  evenCards?: boolean;
}
type StyleProperties = 'metricsWrapper' | 'smallCards' | 'cardsColumn';

type TopMetricsStyles = Record<StyleProperties, string>;

export const getStyles = ({ evenCards }: ITopMetricsStyleProps): TopMetricsStyles => {
  return mergeStyleSets({
    metricsWrapper: {
      display: 'grid',
      columnGap: '1%',
      rowGap: '1%',
      gridTemplateColumns: evenCards ? '1fr' : '1fr 3fr',
      [ResponsiveMedium]: {
        gridTemplateColumns: '1fr',
      },
      [ResponsiveSmall]: {
        gridTemplateColumns: '1fr',
      },
    },
    smallCards: {
      display: 'grid',
      columnGap: '1%',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
      [ResponsiveSmall]: {
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'initial',
        gridAutoColumns: 'initial',
      },
    },
    cardsColumn: {
      display: 'grid',
      rowGap: '2%',
      gridTemplateRows: '49% 49%',
      [ResponsiveSmall]: {
        gridTemplateColumns: '49% 49%',
        columnGap: '2%',
        gridTemplateRows: '1fr',
      },
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ ...props });
}

export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
