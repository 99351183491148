import { MessageBarType } from '@fluentui/react';
import { action } from 'mobx';
import {
  Account,
  AdUnit,
  deleteEntity,
  LifeCycleStatus,
  listEntity,
  onNotificationChanged,
  postEntity,
  Publisher,
  putEntity,
  showSuccessToastNotification,
  TythonAdUnit,
  UserContext,
} from '../../../../../@data';
import {
  AdSenseAdUnitStatus,
  AdSenseConnectAdUnit,
  AdSenseDisconnectAdUnit,
  AdSenseUpdateAdUnitStatus,
} from '../../../../../@data/store/schema/models/AdSenseAdUnitStatus';
import messages from '../../TythonAdunit/CreateAdunit/messages';
import { onAdunitMediationUpdateEnd, onAdunitMediationUpdateStart } from '../actions';
import { onAdSenseMediationChanged, setAdUnitsData } from '../mutatorActions';
import { getAdunitStore } from '../store/store';

export const fetchAdunitById = async (publisher: Publisher, id: string, userContext?: UserContext): Promise<AdUnit> => {
  const list = await listEntity(
    [publisher],
    userContext,
    AdUnit,
    new URLSearchParams([
      ['type', 'Search'],
      ['ids', id],
      ['details', 'true'],
    ])
  );

  if (!list || !list.length) {
    throw Error('Cannot find adunit Id');
  }

  return list[0];
};

export const fetchAdunitMediationStatus = async (publisher: Publisher, account: Account, id: string): Promise<AdSenseAdUnitStatus[]> => {
  const urlParams = new URLSearchParams();
  urlParams.append('routeSuffix', 'api/mediation/admanager/getAdUnitMediationStatus');
  const request: AdSenseAdUnitStatus = {
    apiPath: 'preauth/mediation',
    className: 'getAdUnitMediationStatus',
    pubcenterAdUnitIds: [Number.parseInt(id, 10)],
  };

  const adUnitNotConnectedStatus: AdSenseAdUnitStatus = {
    apiPath: 'preauth/mediation',
    className: 'getAdUnitMediationStatus',
    mediationStatus: false,
  };

  try {
    // Set the type as any because the PUT operation in ApiService only
    // returns singular item and this request for AdUnits mediation status returns array
    // TODO: this call shouldn't be put call
    // TODO: put operation should support array of objects as a response
    // tslint:disable-next-line: no-any
    const response = (await putEntity([], request, undefined, AdSenseAdUnitStatus, urlParams)) as any;

    if (!response) {
      return [adUnitNotConnectedStatus];
    }

    return response;
  } catch {
    return [adUnitNotConnectedStatus];
  }
};

export const updateAdUnitGrid = (id: number, isMediated: boolean, shouldAvoidAdUnitViewState?: boolean) => {
  const adUnitsRawData = getAdunitStore().adUnitsRaw.data;
  const adunit = adUnitsRawData?.find((adUnit) => adUnit.id === id);

  !shouldAvoidAdUnitViewState && onAdSenseMediationChanged(isMediated);
  if (adunit?.id) {
    action(() => {
      adunit!.mediatedWithAdSense = {
        apiPath: 'preauth/mediation',
        className: 'getAdUnitMediationStatus',
        mediationStatus: isMediated,
      };
    })();
    const data = adUnitsRawData!.map((adUnit) => (adUnit.id === adunit?.id ? adunit! : adUnit));
    setAdUnitsData(data);
  }
};

export const enableAdunitMediationStatus = async (publisher: Publisher, account: Account, ids: number[]): Promise<AdSenseAdUnitStatus> => {
  const request: AdSenseConnectAdUnit = {
    apiPath: 'preauth/mediation',
    className: 'pubcenterAdUnitsIds',
    pubcenterAdUnitIds: ids,
  };

  const urlParamsConnect = new URLSearchParams();
  urlParamsConnect.append('routeSuffix', 'api/mediation/admanager/setupAdUnitMediation');

  const adUnitNotConnectedStatus: AdSenseAdUnitStatus = {
    apiPath: 'preauth/mediation',
    className: 'getAdUnitMediationStatus',
    mediationStatus: false,
  };
  const urlParamsNotConnected = new URLSearchParams();
  urlParamsNotConnected.append('routeSuffix', 'api/mediation/admanager/getAdUnitMediationStatus');

  onAdunitMediationUpdateStart(ids);

  try {
    const response = (await postEntity([], AdSenseConnectAdUnit, request, undefined, urlParamsConnect, undefined)) as AdSenseConnectAdUnit;
    onAdunitMediationUpdateEnd(ids);

    if (!response) {
      onNotificationChanged({ messageBarType: MessageBarType.error, textMessageDescriptor: messages.settingUpAdunitMediationFailed });
      return adUnitNotConnectedStatus;
    } else {
      showSuccessToastNotification({ textMessageDescriptor: messages.settingUpAdunitMediationSuccess });
      updateAdUnitGrid(ids[0], true);
    }

    return response;
  } catch {
    onAdunitMediationUpdateEnd(ids);

    return adUnitNotConnectedStatus;
  }
};

export const disableAdunitMediationStatus = async (
  publisher: Publisher,
  account: Account,
  ids: number[]
): Promise<AdSenseAdUnitStatus[]> => {
  const request: AdSenseDisconnectAdUnit = {
    apiPath: 'preauth/mediation',
    className: 'pubcenterAdUnitsIds',
    pubcenterAdUnitIds: ids,
  };

  try {
    onAdunitMediationUpdateStart(ids);
    const urlParams = new URLSearchParams();
    urlParams.append('routeSuffix', 'api/mediation/admanager/decommissionAdUnitMediation');
    const list = (await deleteEntity([], AdSenseDisconnectAdUnit, undefined, urlParams, undefined, request)) as AdSenseDisconnectAdUnit[];
    onAdunitMediationUpdateEnd(ids);
    if (!list || !list.length) {
      onNotificationChanged({ messageBarType: MessageBarType.error, textMessageDescriptor: messages.removingAdunitMediationFailed });
      return [];
    } else {
      showSuccessToastNotification({ textMessageDescriptor: messages.removingAdunitMediationSuccess });
      updateAdUnitGrid(ids[0], false);
    }

    return list;
  } catch {
    onAdunitMediationUpdateEnd(ids);

    return [];
  }
};

export const updateAdunitMediationStatus = async (
  publisher: Publisher,
  account: Account,
  adunits: (AdUnit | TythonAdUnit)[],
  state: LifeCycleStatus
): Promise<AdSenseAdUnitStatus> => {
  const operation = state === LifeCycleStatus.Active ? 'true' : 'false';

  const adUnitOriginalStatus: AdSenseAdUnitStatus = {
    apiPath: 'preauth/mediation',
    className: 'getAdUnitMediationStatus',
    mediationStatus: false,
  };

  const request: AdSenseUpdateAdUnitStatus = {
    apiPath: 'preauth/mediation',
    className: 'pubcenterAdUnitsIds',
    pubcenterAdUnitIds: adunits.map((adunit) => adunit.id),
  };

  try {
    const params = new URLSearchParams();
    params.append('routeSuffix', `api/mediation/admanager/adunits/updateStatus?activate=${operation}`);
    const response = await putEntity([], request, undefined, AdSenseUpdateAdUnitStatus, params, undefined);

    if (!response) {
      return adUnitOriginalStatus;
    }

    return response;
  } catch {
    return adUnitOriginalStatus;
  }
};
