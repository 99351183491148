import { Image, Link, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { IChildrenProp } from '../../types';
import { SummaryCardStyles, getClassNames } from './SummaryCard.styles';

export interface ISummaryCardProps extends IChildrenProp {}
export function SummaryCard({ children }: ISummaryCardProps) {
  const { containerStyle } = getClassNames();

  return <div className={containerStyle}>{children}</div>;
}

export interface IHeadingRowProps extends IChildrenProp {}
SummaryCard.HeadingRow = function HeadingRow({ children }: IHeadingRowProps) {
  const { headerRowStyle } = getClassNames();
  return <div className={headerRowStyle}>{children}</div>;
};

export interface IHeadingTitleProps {
  text: string;
  icon?: string;
}
SummaryCard.HeadingTitle = function HeadingTitle({ text, icon }: IHeadingTitleProps) {
  const { headingTitleStyle, headingIconStyle } = getClassNames();
  return (
    <h2 className={headingTitleStyle}>
      <Image src={icon} shouldFadeIn alt={text} className={headingIconStyle} />
      {text}
    </h2>
  );
};

SummaryCard.ActionsContainer = function ActionsContainer({ children }: IChildrenProp) {
  return <div>{children}</div>;
};

export interface IActionProps extends IChildrenProp {
  disabled?: boolean;
  onClick: () => void;
  styleProps?: Partial<Pick<SummaryCardStyles, 'actionStyle'>>;
}
SummaryCard.Action = function Action({ children, disabled, onClick, styleProps }: IActionProps) {
  const { actionStyle } = mergeStyleSets(getClassNames(), styleProps);

  return (
    <Link disabled={disabled} onClick={onClick} className={actionStyle}>
      {children}
    </Link>
  );
};

export interface IBodyProps extends Partial<IChildrenProp> {
  styleProps?: Partial<Pick<SummaryCardStyles, 'bodyContainerStyle'>>;
}
SummaryCard.Body = function Body({ children, styleProps }: IBodyProps) {
  const { bodyContainerStyle } = mergeStyleSets(getClassNames(), styleProps);
  return <div className={bodyContainerStyle}>{children}</div>;
};

export interface ISectionProps extends Partial<IChildrenProp> {
  styleProps?: Partial<Pick<SummaryCardStyles, 'sectionStyle'>>;
}
SummaryCard.Section = function Section({ children, styleProps }: ISectionProps) {
  const { sectionStyle } = mergeStyleSets(getClassNames(), styleProps);
  return <div className={sectionStyle}>{children}</div>;
};

export interface ISectionTitleProps {
  text: string;
}
SummaryCard.SectionTitle = function SectionTitle({ text }: ISectionTitleProps) {
  const { sectionTitleStyle } = getClassNames();
  return <h3 className={sectionTitleStyle}>{text}</h3>;
};

export interface IContentRowProps {
  label?: string;
  value?: string;
  styleProps?: Partial<SummaryCardStyles>;
  children?: React.ReactNode;
}
SummaryCard.ContentRow = function ContentRow({ label, value, children, styleProps }: IContentRowProps) {
  const { contentRowStyle, contentLabelStyle, contentLabelStyleWithColor } = mergeStyleSets(getClassNames(), styleProps);
  return (
    <div className={contentRowStyle}>
      {label && <div className={contentLabelStyleWithColor}>{label}</div>}
      {value && <div className={contentLabelStyle}>{value}</div>}
      {children && <div className={contentLabelStyle}>{children}</div>}
    </div>
  );
};

export interface ISectionValueProps extends Partial<IChildrenProp> {
  text?: string;
  styleProps?: Partial<Pick<SummaryCardStyles, 'sectionContentStyle'>>;
}
SummaryCard.SectionContent = function SectionValue({ text, children, styleProps }: ISectionValueProps) {
  const { sectionContentStyle } = mergeStyleSets(getClassNames(), styleProps);
  return (
    <div>
      <p className={sectionContentStyle}>{text}</p>
      {children}
    </div>
  );
};
