import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing } from '../../../../theme';

export interface IUserManagementStyles {
  root: string;
  actionButtonsWrapper: string;
  roleDesc: string;
}

export const getClassNames = (): IUserManagementStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const fonts = theme.fonts as IPubCenterFonts;

  return mergeStyleSets({
    root: {
      marginTop: spacing.size32,
    },
    actionButtonsWrapper: {
      display: 'flex',
      gap: spacing.size8,
      flexWrap: 'wrap',
    },
    roleDesc: {
      paddingLeft: spacing.size24,
      paddingTop: spacing.size4,
      ...fonts.size12,
      color: theme.palette.neutralSecondary,
    },
  });
};
