import { History } from 'history';
import { action } from 'satcheljs';
import { Publisher } from '../store/schema';
import { IUserAccessiblePublisher } from '../store/schema/interfaces/IUserRoleData';

export const onPublisherChanged = action('onPublisherChanged', (publisher?: Publisher) => ({ publisher }));
export const onPublisherSelected = action('onPublisherSelected', (id: string, routeHistory?: History) => ({ id, routeHistory }));
export const onDefaultPublisherSet = action('onDefaultPublisherSet', (id: number) => ({ id }));
export const onAccessiblePublisherListChanged = action('onAccessiblePublisherListChanged', (value: IUserAccessiblePublisher[]) => ({
  value,
}));
export const onPublisherAccountChanged = action('onPublisherAccountChanged', (publisherId: string, accountId: string, routeHistory) => ({
  publisherId,
  accountId,
  routeHistory,
}));
