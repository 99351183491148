import { getTheme, IStackTokens, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../../../theme';
import { IMetricMarkerProps } from './MetricMarker.types';

type StyleProperties = 'marker' | 'contentStyle' | 'markerName' | 'markerData' | 'markerWrapper' | 'tooltip';
type MetricMarkerStyles = Record<StyleProperties, string>;

export const getStyles = (props: Partial<IMetricMarkerProps>): MetricMarkerStyles => {
  const { isActive, color, isIncompatible } = props;
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  return mergeStyleSets({
    marker: {
      height: '40px',
      minHeight: '5px',
      maxWidth: '5px',
      minWidth: '5px',
      backgroundColor: isActive ? color : palette.neutralLight,
      ...(isIncompatible && { backgroundColor: palette.neutralTertiary }),
    },
    contentStyle: { display: 'flex', flexDirection: 'column', justifyContent: 'center' },
    markerName: { fontSize: theme.fonts.medium },
    markerData: { fontWeight: 'bold', fontSize: theme.fonts.large.fontSize, gap: '8px', display: 'flex' },
    tooltip: { fontSize: theme.fonts.medium.fontSize, pointerEvents: 'auto' },
    markerWrapper: {
      display: 'flex',
      gap: spacing.size12,
      paddingRight: spacing.size12,
      boxSizing: 'border-box',
      ':hover': {
        backgroundColor: palette.neutralLighter,
      },
      minWidth: '160px',
      ...(isIncompatible && { color: palette.neutralTertiary, pointerEvents: 'none' }),
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ ...props });
}
export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
