import { orchestrator } from 'satcheljs';
import { getAppStore, isTythonSelfServeUser, onNavigateBack } from '../../../../../../../../../../@data';
import { getTythonInventoryPage } from '../../../../../../../../../../utils/routeUtils';
import { RouteName } from '../../../../../../../../@data';
import { createReturnUrl } from '../../../../../../../../@data/services/AdManagementService';
import { onFormCleared, onScriptPageSubmitted } from '../actions/onScriptPageActions';

orchestrator(onScriptPageSubmitted, (msg) => {
  const { publisher } = getAppStore();

  const returnUrl = createReturnUrl(RouteName.property, publisher!.id, undefined);
  onNavigateBack(msg.routeHistory, isTythonSelfServeUser() ? getTythonInventoryPage() : returnUrl);

  onFormCleared();
  if (msg.onSubmit) {
    msg.onSubmit();
  }
});
