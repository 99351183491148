import { getTheme, mergeStyleSets } from '@fluentui/react';
import { ICommonStyleProps } from '../../types';

type StyleProperties = 'overlayStyle' | 'spinnerStyle';
export type PageSpinnerStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): PageSpinnerStyles => {
  return mergeStyleSets({
    overlayStyle: {
      flex: 1,
      display: 'flex',
      position: 'fixed',
      zIndex: 9999999, // This should be of largest z-index to always appear at the top
    },
    spinnerStyle: {
      flex: 1,
      display: 'flex',
      selectors: {
        '.ms-Spinner-circle': {
          height: 67,
          width: 67,
        },
      },
    },
  });
};

export function getClassNames<PropType>(props?: PropType): PageSpinnerStyles {
  return getStyles({ theme: getTheme(), ...props });
}
