import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';

export const NotFound = (props: RouteComponentProps) => (
  <h1>
    <FormattedMessage
      id="notfound"
      defaultMessage="No match for {pathname}"
      values={{
        pathname: <code>{props.location.pathname}</code>
      }}
    />
  </h1>
);
