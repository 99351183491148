import {
  Checkbox,
  ChoiceGroup,
  classNamesFunction,
  ComboBox,
  Dropdown,
  getTheme,
  IComboBoxOption,
  IDropdownOption,
  IProcessedStyleSet,
  nullRender,
  Panel,
  PanelType,
  TextField,
} from '@fluentui/react';
import * as React from 'react';
import {
  AccountSKU,
  getAppStore,
  onHelpInfoCalloutOpened,
  onHelpInfoChanged,
  onNavigateBack,
  PaymentInstrumentLifeCycleStatus,
  PaymentOption,
  PrivateMarketplace,
  User,
} from '../../../../@data';
import { completeAccess, hasReadAccess, hasUpdateAccess, UserRoleEntity, userRoleKey } from '../../../../@data/services/UserRoleService';
import { FinancialStatus } from '../../../../@data/store/schema/enums/FinancialStatus';
import { Account as AccountAccessPermissions } from '../../../../@data/store/schema/userrolemodels/Account';
import AppMessages from '../../../../App.messages';
import { DataGrid, DataGridSelectionMode, IDataGrid, IDataGridCommandBarProps } from '../../../../components/DataGrid';
import { FormItem, FormSection, FormState } from '../../../../components/Form';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { countryToCurrencyMap } from '../../../../config/countryToCurrencyMapper.config';

import {
  COUNTRIES_LANGUAGE_MAPPING,
  CURRENCIES_LANGUAGE_MAPPING,
  LANGUAGES_LANGUAGE_MAPPING,
  TIMEZONE_LANGUAGE_MAPPING,
} from '../../../../globalization';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { IDataGridColumn } from '../../../../layout/GridPageV2';
import MediaTypeMessages from '../../../../MediaType.messages';
import { injectIntlWithObserver } from '../../../../utils';
import { getPageStore, RouteName } from '../../@data';
import { onPanelOpenChanged } from '../../@data/actions';
import { createReturnUrl } from '../../@data/service/PartnerManagementService';
import { IAccountViewModel } from '../@data';
import {
  onAccountSkuFormMediumChanged,
  onAccountSkuFormSkuChanged,
  onAccountSkuFormSubmitted,
  onAccountSkuSidePanelInitialized,
  onFormAccountAllowAdultAdsChanged,
  onFormAccountAllowCallExtensionsChanged,
  onFormAccountAllowCrawlingChanged,
  onFormAccountAllowGlobalDefaultKeywordsChanged,
  onFormAccountAllowLocalExtensionsChanged,
  onFormAccountAllowLocationExtensionsChanged,
  onFormAccountAllowMerchantRatingExtensionsChanged,
  onFormAccountAllowMeteredCallExtensionsChanged,
  onFormAccountAllowPassedKeywordChanged,
  onFormAccountAllowPassedURLChanged,
  onFormAccountAllowPhoneExtensionsChanged,
  onFormAccountAllowSiteLinkExtensionsChanged,
  onFormAccountCountryChanged,
  onFormAccountCurrencyChanged,
  onFormAccountFinancialStatusChanged,
  onFormAccountFormCodesChanged,
  onFormAccountLanguageChanged,
  onFormAccountNameChanged,
  onFormAccountOperationRatioChanged,
  onFormAccountPartnerCodesChanged,
  onFormAccountPaymentLifeCycleStatusChanged,
  onFormAccountPaymentOptionChanged,
  onFormAccountPrimaryContactChanged,
  onFormAccountStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageChanged,
  onFormAccountStartDistributionOCIDL2sChanged,
  onFormAccountStartDistributionPlacementCodesChanged,
  onFormAccountStoreIdsChanged,
  onFormAccountTimezoneChanged,
  onFormCancelled,
  onFormInitialized,
  onFormSubmitted,
} from '../@data/actions';
import { onAccountPrivateMarketplaceChanged } from '../@data/mutatorActions';
import { getStore } from '../@data/store/store';
import messages from './CreateAccount.messages';
import { getStyles } from './CreateAccount.styles';
import {
  ICreateAccountProps,
  ICreateAccountStyleProps,
  ICreateAccountStyles,
  IFormInitializationState,
  ISkuSidePanelProps,
} from './CreateAccount.types';

const getClassNames = classNamesFunction<ICreateAccountStyleProps, ICreateAccountStyles>();

export const CreateAccount = injectIntlWithObserver(
  class extends React.Component<ICreateAccountProps, IFormInitializationState> {
    private _classNames: IProcessedStyleSet<ICreateAccountStyles>;
    private _datagrid = React.createRef<IDataGrid>();

    constructor(props: ICreateAccountProps) {
      super(props);
      this.state = {
        initializeForm: true,
        skuPanelFormState: FormState.InPageCreate,
      };
    }

    public componentDidMount() {
      const publisherId = this.props.match.params.publisherId;
      const entityId = this.props.match.params.accountId;
      this.setState({ id: entityId });
      if (publisherId) {
        onFormInitialized(this.props.mode, publisherId, entityId);
      }
    }

    public componentDidUpdate() {
      const publisherId = this.props.match.params.publisherId;
      const entityId = this.props.match.params.accountId;
      if (this.state.initializeForm && this.state.id !== entityId) {
        this.setState({ id: entityId });
        if (publisherId) {
          onFormInitialized(this.props.mode, publisherId, entityId);
        }
      }
    }

    public render() {
      const theme = getTheme();
      this._classNames = getClassNames(getStyles, { theme });
      const appStore = getAppStore();
      const store = getStore();
      const activeAccount = store.active;
      const activePublisher = appStore.publisher;
      const publisherId = this.props.match.params.publisherId;
      const { formatMessage } = this.props.intl;
      const accountAccessPermissions = this._getAccountAccessPermission();
      const CURRENCY = CURRENCIES_LANGUAGE_MAPPING[appStore.locale];
      return (
        <DetailPage
          titleLabel={this.props.mode === FormState.Edit ? formatMessage(messages.editPageTitle) : formatMessage(messages.createPageTitle)}
          helpId={'pop_PCv4_AccountCreate'}
          submitLabel={formatMessage(this.props.mode === FormState.Edit ? messages.updateAccount : messages.createPageTitle)}
          onSubmit={() => this._onSubmit(publisherId)}
          onCancel={() => this._onCancel(publisherId)}
          onNavClick={() => this._onCancel(publisherId)}
          saving={getAppStore().saving}
          pageMode={this.props.mode}
        >
          <FormItem>
            <TextField
              label={formatMessage(messages.publisherName)}
              ariaLabel={formatMessage(messages.publisherName)}
              required={true}
              value={activePublisher && String(activePublisher.name)}
              placeholder=""
              disabled={true}
            />
          </FormItem>
          {this.props.mode === FormState.Edit && (
            <FormItem>
              <TextField
                label={formatMessage(messages.accountNumber)}
                ariaLabel={formatMessage(messages.accountNumber)}
                required={true}
                value={activeAccount.number ? String(activeAccount.number) : ''}
                placeholder=""
                disabled={true}
              />
            </FormItem>
          )}
          <FormItem>
            <TextField
              label={formatMessage(messages.accountName)}
              ariaLabel={formatMessage(messages.accountName)}
              errorMessage={store.errors.name}
              value={activeAccount.name}
              required={true}
              onChange={(_, value) => onFormAccountNameChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountName
              )}
            />
          </FormItem>
          <FormItem>
            <Dropdown
              label={formatMessage(messages.primaryContact)}
              ariaLabel={formatMessage(messages.primaryContact)}
              options={this._createPrimaryContactDropDown(activeAccount.availableUsers!)}
              required={true}
              defaultSelectedKey={activeAccount.primaryContactUserId}
              onChange={(_, value) => onFormAccountPrimaryContactChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.primaryContact
              )}
            />
          </FormItem>
          <FormItem>
            <Dropdown
              label={formatMessage(AppMessages.language)}
              ariaLabel={formatMessage(AppMessages.language)}
              options={this._createLanguageDropDown(activeAccount.availableLanguages!)}
              required={true}
              defaultSelectedKey={activeAccount.languageCode}
              onChange={(_, value) => onFormAccountLanguageChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.lanuage
              )}
            />
          </FormItem>
          <FormItem>
            <ComboBox
              useComboBoxAsMenuWidth={true}
              autoComplete="on"
              label={formatMessage(AppMessages.countryOrRegion)}
              ariaLabel={formatMessage(AppMessages.countryOrRegion)}
              options={this._createCountryDropDown(activeAccount.availableCountries!)}
              required={true}
              selectedKey={activeAccount.countryCode}
              onChange={(_, value) => onFormAccountCountryChanged(value!)}
              disabled={this.props.mode === FormState.Edit}
              tabIndex={this.props.mode === FormState.Edit ? -1 : undefined}
            />
          </FormItem>
          <FormItem>
            <TextField
              label={formatMessage(AppMessages.currency)}
              ariaLabel={formatMessage(AppMessages.currency)}
              required={true}
              value={activeAccount.countryCode && CURRENCY[countryToCurrencyMap[activeAccount.countryCode]]}
              placeholder=""
              disabled={true}
              onChange={(_, value) => onFormAccountCurrencyChanged(value!)}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <Dropdown
              label={formatMessage(AppMessages.timezoneLabel)}
              ariaLabel={formatMessage(AppMessages.timezoneLabel)}
              required={true}
              options={this._createTimezoneDropDown(activeAccount.availableTimezones!)}
              defaultSelectedKey={activeAccount.timeZoneId}
              onChange={(_, value) => onFormAccountTimezoneChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.timeZone
              )}
            />
          </FormItem>
          {hasReadAccess(accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute) && (
            <FormSection title={formatMessage(messages.advancedAccountTitle)}>
              {this._advancedAccountAttributes(activeAccount, accountAccessPermissions)}
            </FormSection>
          )}

          {this.props.mode === FormState.Edit &&
            hasReadAccess(accountAccessPermissions === null ? completeAccess : accountAccessPermissions.SKUs) && (
              <>
                <FormSection title={formatMessage(messages.skuTitle)}>{this._SKUs(activeAccount, accountAccessPermissions)}</FormSection>
                <Panel
                  isOpen={getPageStore().panelIsOpen}
                  type={PanelType.medium}
                  onDismiss={() => onPanelOpenChanged(false)}
                  closeButtonAriaLabel={formatMessage(AppMessages.close)}
                >
                  {this._createSkuPanel(this.state.skuPanelFormState, activeAccount.id!)}
                </Panel>
              </>
            )}
        </DetailPage>
      );
    }

    private _createSkuPanel = (mode: FormState, accountId: number): JSX.Element | null => {
      const { formatMessage } = this.props.intl;
      const accountSku = getStore().accountSku!;
      if (accountSku) {
        return (
          <DetailPage
            helpId={'pop_PCv4_SkuCreate'}
            titleLabel={formatMessage(mode === FormState.InPageEdit ? messages.editSku : messages.createSku)}
            submitLabel={formatMessage(mode === FormState.InPageEdit ? messages.editSku : messages.createSku)}
            onSubmit={() => this._onAccountSkuSubmit(mode, accountId)}
            onCancel={() => onPanelOpenChanged(false)}
            saving={getAppStore().saving}
            pageMode={mode}
          >
            <FormItem>
              <Dropdown
                label={formatMessage(messages.skuCategory)}
                ariaLabel={formatMessage(messages.skuCategory)}
                required={true}
                options={this._createMediaTypeDropdown(accountSku.listOfMediums!)}
                defaultSelectedKey={accountSku.medium!}
                disabled={mode === FormState.InPageEdit}
                onChange={(_, value) => onAccountSkuFormMediumChanged(value!)}
              />
            </FormItem>
            <FormItem>
              <Dropdown
                label={formatMessage(messages.skuCode)}
                ariaLabel={formatMessage(messages.skuCode)}
                required={true}
                options={this._createSkuDropDown(accountSku.availableSkus!)}
                defaultSelectedKey={accountSku.sku!}
                onChange={(_, value) => onAccountSkuFormSkuChanged(value!)}
              />
            </FormItem>
          </DetailPage>
        );
      }
      return nullRender();
    };

    private _onAccountSkuSubmit(mode: FormState, accountId: number): void {
      onAccountSkuFormSubmitted(mode, accountId);
      onPanelOpenChanged(false);
    }

    private _createSkuDropDown(skus: string[]): IDropdownOption[] {
      const availableAccountSkus: IDropdownOption[] = [];
      if (skus) {
        skus.forEach((sku) => {
          availableAccountSkus!.push({
            key: sku,
            text: sku,
            ariaLabel: sku,
          });
        });
      }
      return availableAccountSkus;
    }

    private _advancedAccountAttributes = (
      activeAccount: IAccountViewModel,
      accountAccessPermissions: AccountAccessPermissions | null
    ): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const helpInfo = getAppStore().helpInfo;

      const paymentLifeCycleStatusOptions: IDropdownOption[] = [
        {
          key: PaymentInstrumentLifeCycleStatus.Blacklisted,
          text: formatMessage(messages.lifecycleStatusBlacklisted),
          ariaLabel: formatMessage(messages.lifecycleStatusBlacklisted),
        },
        {
          key: PaymentInstrumentLifeCycleStatus.Created,
          text: formatMessage(messages.lifecycleStatusCreated),
          ariaLabel: formatMessage(messages.lifecycleStatusCreated),
        },
        {
          key: PaymentInstrumentLifeCycleStatus.PinFailed,
          text: formatMessage(messages.lifecycleStatusPinFailed),
          ariaLabel: formatMessage(messages.lifecycleStatusPinFailed),
        },
        {
          key: PaymentInstrumentLifeCycleStatus.Failed,
          text: formatMessage(messages.lifecycleStatusFailed),
          ariaLabel: formatMessage(messages.lifecycleStatusFailed),
        },
        {
          key: PaymentInstrumentLifeCycleStatus.Pending,
          text: formatMessage(messages.lifecycleStatusPending),
          ariaLabel: formatMessage(messages.lifecycleStatusPending),
        },
        {
          key: PaymentInstrumentLifeCycleStatus.Success,
          text: formatMessage(messages.lifecycleStatusSuccess),
          ariaLabel: formatMessage(messages.lifecycleStatusSuccess),
        },
        {
          key: PaymentInstrumentLifeCycleStatus.Submitted,
          text: formatMessage(messages.lifecycleStatusSubmitted),
          ariaLabel: formatMessage(messages.lifecycleStatusSubmitted),
        },
        {
          key: PaymentInstrumentLifeCycleStatus.Validated,
          text: formatMessage(messages.lifecycleStatusValidated),
          ariaLabel: formatMessage(messages.lifecycleStatusValidated),
        },
      ];

      const paymentOptionOptions: IDropdownOption[] = [
        {
          key: PaymentOption.MonthlyThreshold,
          text: formatMessage(messages.paymentOptionMonthlyThreshold),
          ariaLabel: formatMessage(messages.paymentOptionMonthlyThreshold),
        },
        {
          key: PaymentOption.NoPay,
          text: formatMessage(messages.paymentOptionNoPay),
          ariaLabel: formatMessage(messages.paymentOptionNoPay),
        },
      ];
      return (
        <FormItem formWidth={440}>
          <FormItem>
            <Dropdown
              label={formatMessage(messages.paymentOption)}
              ariaLabel={formatMessage(messages.paymentOption)}
              required={true}
              options={paymentOptionOptions}
              defaultSelectedKey={activeAccount.paymentOption}
              onChange={(_, value) => onFormAccountPaymentOptionChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.paymentOption
              )}
            />
          </FormItem>
          <FormItem>
            <Dropdown
              label={formatMessage(messages.financialStatusLabel)}
              ariaLabel={formatMessage(messages.financialStatusLabel)}
              required={true}
              options={[
                {
                  key: FinancialStatus.NoHold,
                  text: formatMessage(messages.financialStatusNoHold),
                  ariaLabel: formatMessage(messages.financialStatusNoHold),
                },
                {
                  key: FinancialStatus.Hold,
                  text: formatMessage(messages.financialStatusHold),
                  ariaLabel: formatMessage(messages.financialStatusHold),
                },
                {
                  key: FinancialStatus.TaxOnHold,
                  text: formatMessage(messages.financialStatusTaxOnHold),
                  ariaLabel: formatMessage(messages.financialStatusTaxOnHold),
                },
                {
                  key: FinancialStatus.UserHold,
                  text: formatMessage(messages.financialStatusUserHold),
                  ariaLabel: formatMessage(messages.financialStatusUserHold),
                },
              ]}
              defaultSelectedKey={activeAccount.financialStatus ? activeAccount.financialStatus : FinancialStatus.TaxOnHold}
              onChange={(_, value) => onFormAccountFinancialStatusChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.financialStatus
              )}
            />
          </FormItem>
          <FormItem>
            <Dropdown
              label={formatMessage(messages.lifecycleStatusLabel)}
              ariaLabel={formatMessage(messages.lifecycleStatusLabel)}
              required={true}
              options={paymentLifeCycleStatusOptions}
              defaultSelectedKey={
                activeAccount.paymentLifeCycleStatus ? activeAccount.paymentLifeCycleStatus : PaymentInstrumentLifeCycleStatus.Validated
              }
              onChange={(_, value) => onFormAccountPaymentLifeCycleStatusChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.paymentLifecycle
              )}
            />
          </FormItem>
          <FormItem>
            <TextField
              defaultValue={activeAccount.revenueSharePercentage !== undefined ? String(activeAccount.revenueSharePercentage) : '100'}
              label={formatMessage(messages.revenueSharePercentageLabel)}
              ariaLabel={formatMessage(messages.revenueSharePercentageLabel)}
              errorMessage={getStore().errors.revenueSharePercentage}
              required={true}
              disabled={true}
            />
            <span>%</span>
          </FormItem>
          <FormItem>
            <TextField
              defaultValue={activeAccount.operationRatio ? String(activeAccount.operationRatio) : '0'}
              label={formatMessage(messages.operationCostRationLabel)}
              ariaLabel={formatMessage(messages.operationCostRationLabel)}
              errorMessage={getStore().errors.operationRatio}
              onChange={(_, value) => onFormAccountOperationRatioChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.operationCostRation
              )}
            />
          </FormItem>

          <FormItem role="region" ariaLabelledBy="accountLvlattrLabel" ariaDescription={formatMessage(messages.accountLevelattributesDesc)}>
            <FormLabel
              id="accountLvlattrLabel"
              labelContent={formatMessage(messages.accountLevelattributesLabel)}
              noInfoButton={true}
              intl={this.props.intl}
            />
            <Checkbox
              label={formatMessage(messages.enablePassedURLs)}
              ariaLabel={formatMessage(messages.enablePassedURLs)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowPassedURL}
              onChange={(_, value) => onFormAccountAllowPassedURLChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.enablePassedKeywords)}
              ariaLabel={formatMessage(messages.enablePassedKeywords)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowPassedKeyword}
              onChange={(_, value) => onFormAccountAllowPassedKeywordChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.donotCrawl)}
              ariaLabel={formatMessage(messages.donotCrawl)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowCrawling}
              onChange={(_, value) => onFormAccountAllowCrawlingChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.enableAdultAds)}
              ariaLabel={formatMessage(messages.enableAdultAds)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowAdultAds}
              onChange={(_, value) => onFormAccountAllowAdultAdsChanged(value)}
            />
            <Checkbox
              label={formatMessage(messages.enableGlobalDefaultKeywords)}
              ariaLabel={formatMessage(messages.enableGlobalDefaultKeywords)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowGlobalDefaultKeywords}
              onChange={(_, value) => onFormAccountAllowGlobalDefaultKeywordsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.enableSiteLink)}
              ariaLabel={formatMessage(messages.enableSiteLink)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowSiteLinkExtensions}
              onChange={(_, value) => onFormAccountAllowSiteLinkExtensionsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.meteredCallExtensions)}
              ariaLabel={formatMessage(messages.meteredCallExtensions)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowMeteredCallExtensions}
              onChange={(_, value) => onFormAccountAllowMeteredCallExtensionsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.locationExtension)}
              ariaLabel={formatMessage(messages.locationExtension)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowLocationExtensions}
              onChange={(_, value) => onFormAccountAllowLocationExtensionsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.phoneExtension)}
              ariaLabel={formatMessage(messages.phoneExtension)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowPhoneExtensions}
              onChange={(_, value) => onFormAccountAllowPhoneExtensionsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.meteredRatingExtensions)}
              ariaLabel={formatMessage(messages.meteredRatingExtensions)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowMerchantRatingExtensions}
              onChange={(_, value) => onFormAccountAllowMerchantRatingExtensionsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.localExtension)}
              ariaLabel={formatMessage(messages.localExtension)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowLocalExtensions}
              onChange={(_, value) => onFormAccountAllowLocalExtensionsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
            <Checkbox
              label={formatMessage(messages.callExtension)}
              ariaLabel={formatMessage(messages.callExtension)}
              styles={this._classNames.subComponentStyles.checkbox}
              checked={!!activeAccount.allowCallExtensions}
              onChange={(_, value) => onFormAccountAllowCallExtensionsChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
          </FormItem>
          <FormItem role="region" ariaLabel={formatMessage(messages.privateMarketplace)}>
            <FormLabel
              id="privateMarketplaceLabel"
              labelContent={formatMessage(messages.privateMarketplace)}
              intl={this.props.intl}
              calloutContent={helpInfo}
              iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AdUnitsPromotions')}
              calloutOnDismiss={() => onHelpInfoChanged()}
            />
            <ChoiceGroup
              aria-activedescendant={formatMessage(messages.inHousePromotions)}
              ariaLabelledBy={'privateMarketplaceLabel'}
              selectedKey={getStore().privateMarketplace}
              options={[
                {
                  id: formatMessage(messages.inHousePromotions),
                  key: PrivateMarketplace.InHouse,
                  text: formatMessage(messages.inHousePromotions),
                },
                {
                  id: formatMessage(messages.boost),
                  key: PrivateMarketplace.Boost,
                  text: formatMessage(messages.boost),
                },
              ]}
              onChange={(_, value) => onAccountPrivateMarketplaceChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                accountAccessPermissions === null ? completeAccess : accountAccessPermissions.accountLevelAttribute
              )}
            />
          </FormItem>
          {getStore().enablePrivateMarketplace && (
            <FormItem formWidth={440}>
              <TextField
                multiline={true}
                placeholder={formatMessage(this._getAdvertisersWhitelistTextFieldPlaceholder())}
                errorMessage={getStore().errors.storeIds}
                aria-labelledby="storeIdLabel"
                onRenderLabel={() => {
                  return (
                    <FormLabel
                      id="storeIdLabel"
                      labelContent={formatMessage(this._getAdvertisersWhitelistTextFieldLabel())}
                      intl={this.props.intl}
                      noInfoButton={true}
                    />
                  );
                }}
                value={activeAccount.storeIds}
                onChange={(_, value) => onFormAccountStoreIdsChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  accountAccessPermissions === null ? completeAccess : accountAccessPermissions.inHousePromotion
                )}
              />
            </FormItem>
          )}

          {getStore().active.isDistributionReportingApplicable && (
            <FormItem formWidth={440}>
              <TextField
                multiline={true}
                placeholder={formatMessage(messages.partnerCodeText)}
                errorMessage={getStore().errors.partnerCodes}
                aria-labelledby="partnerCodeLabel"
                onRenderLabel={() => {
                  return (
                    <FormLabel
                      id="partnerCodeLabel"
                      labelContent={formatMessage(messages.partnerCodeLabel)}
                      noInfoButton={true}
                      intl={this.props.intl}
                    />
                  );
                }}
                value={activeAccount.partnerCodes}
                onChange={(_, value) => onFormAccountPartnerCodesChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  accountAccessPermissions === null ? completeAccess : accountAccessPermissions.partnerCodesforDistributionReport
                )}
              />
            </FormItem>
          )}

          {getStore().active.isDistributionReportingApplicable && (
            <FormItem formWidth={440}>
              <TextField
                multiline={true}
                placeholder={formatMessage(messages.formCodeText)}
                errorMessage={getStore().errors.formCodes}
                aria-labelledby="formCodeLabel"
                onRenderLabel={() => {
                  return (
                    <FormLabel
                      id="formCodeLabel"
                      labelContent={formatMessage(messages.formCodeLabel)}
                      noInfoButton={true}
                      intl={this.props.intl}
                    />
                  );
                }}
                value={activeAccount.formCodes}
                onChange={(_, value) => onFormAccountFormCodesChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  accountAccessPermissions === null ? completeAccess : accountAccessPermissions.formCodesforDistributionReport
                )}
              />
            </FormItem>
          )}

          {getStore().active.isStartDistributionReportingApplicable && (
            <FormItem formWidth={440}>
              <TextField
                multiline={true}
                placeholder={formatMessage(messages.startDistributionPlacementCodeText)}
                errorMessage={getStore().errors.startDistributionPlacementCodes}
                aria-labelledby="startDistributionPlacementCodeLabel"
                onRenderLabel={() => {
                  return (
                    <FormLabel
                      id="startDistributionPlacementCodeLabel"
                      labelContent={formatMessage(messages.startDistributionPlacementCodeLabel)}
                      calloutContent={helpInfo}
                      iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AdUnitsPromotions')}
                      calloutOnDismiss={() => onHelpInfoChanged()}
                      intl={this.props.intl}
                    />
                  );
                }}
                value={activeAccount.startDistributionPlacementCodes}
                onChange={(_, value) => onFormAccountStartDistributionPlacementCodesChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  accountAccessPermissions === null ? completeAccess : accountAccessPermissions.startDistributionReporting
                )}
              />
            </FormItem>
          )}

          {getStore().active.isStartDistributionReportingApplicable && (
            <FormItem>
              <TextField
                defaultValue={
                  activeAccount.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage !== undefined
                    ? String(activeAccount.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage)
                    : ''
                }
                label={formatMessage(messages.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageText)}
                ariaLabel={formatMessage(messages.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageText)}
                errorMessage={getStore().errors.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage}
                onChange={(_, value) => onFormAccountStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageChanged(value)}
                required={this._isStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageRequired()}
                disabled={this._isStartDistributionNativeAdsInFeedsAndHeadlinesRevenuePercentageShareDisabled()}
              />
              <span>%</span>
            </FormItem>
          )}
          {getStore().active.isStartDistributionContentReportingApplicable && (
            <FormItem formWidth={440}>
              <TextField
                multiline={true}
                placeholder={formatMessage(messages.startDistributionOCIDL2Text)}
                errorMessage={getStore().errors.startDistributionOCIDL2s}
                aria-labelledby="startDistributionOCIDL2Label"
                onRenderLabel={() => {
                  return (
                    <FormLabel
                      id="startDistributionOCIDL2Label"
                      labelContent={formatMessage(messages.startDistributionOCIDL2Label)}
                      calloutContent={helpInfo}
                      iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AdUnitsPromotions')}
                      calloutOnDismiss={() => onHelpInfoChanged()}
                      intl={this.props.intl}
                    />
                  );
                }}
                value={activeAccount.startDistributionOCIDL2s}
                onChange={(_, value) => onFormAccountStartDistributionOCIDL2sChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  accountAccessPermissions === null ? completeAccess : accountAccessPermissions.startDistributionReporting
                )}
              />
            </FormItem>
          )}
        </FormItem>
      );
    };
    private _getAdvertisersWhitelistTextFieldLabel = () => {
      const store = getStore();
      return store.privateMarketplace === PrivateMarketplace.InHouse
        ? messages.storeIdLabel
        : store.privateMarketplace === PrivateMarketplace.Boost
        ? messages.boostIdsLabel
        : messages.privateMarketplaceIdsLabel;
    };
    private _getAdvertisersWhitelistTextFieldPlaceholder = () => {
      const store = getStore();
      return store.privateMarketplace === PrivateMarketplace.InHouse
        ? messages.storeIdExampleText
        : store.privateMarketplace === PrivateMarketplace.Boost
        ? messages.boostIdsExampleText
        : messages.privateMarketplaceExampleText;
    };
    private _createMediaTypeDropdown(existingMediums: string[]): IDropdownOption[] {
      const { formatMessage } = this.props.intl;
      const availableMediaTypes: IDropdownOption[] = [];

      existingMediums.forEach((mediaType) => {
        if (MediaTypeMessages[mediaType]) {
          availableMediaTypes!.push({
            key: mediaType,
            text: formatMessage(MediaTypeMessages[mediaType]),
            ariaLabel: formatMessage(MediaTypeMessages[mediaType]),
          });
        }
      });

      return availableMediaTypes;
    }

    private _createTimezoneDropDown(timezones: number[]): IDropdownOption[] {
      const availableTimezones: IDropdownOption[] = [];
      const TIMEZONE = TIMEZONE_LANGUAGE_MAPPING[getAppStore().locale];

      if (timezones) {
        timezones.forEach((timezoneId) => {
          if (TIMEZONE[timezoneId]) {
            availableTimezones!.push({
              key: timezoneId,
              text: TIMEZONE[timezoneId],
              ariaLabel: TIMEZONE[timezoneId],
            });
          }
        });
      }

      return availableTimezones;
    }

    private _createLanguageDropDown(languages: string[]): IDropdownOption[] {
      const availableLanguages: IDropdownOption[] = [];
      const LANGUAGES = LANGUAGES_LANGUAGE_MAPPING[getAppStore().locale];

      if (languages) {
        languages.forEach((languageCode) => {
          if (LANGUAGES[languageCode]) {
            availableLanguages!.push({
              key: languageCode,
              text: LANGUAGES[languageCode],
              ariaLabel: LANGUAGES[languageCode],
            });
          }
        });
      }

      return availableLanguages;
    }

    private _createPrimaryContactDropDown(users: User[]): IDropdownOption[] {
      const availableUsers: IDropdownOption[] = [];

      if (users) {
        users.forEach((user) => {
          availableUsers!.push({
            key: user.id!,
            text: user.username as string,
            ariaLabel: user.username as string,
          });
        });
      }

      return availableUsers;
    }

    private _createCountryDropDown(countries: string[]): IComboBoxOption[] {
      const availableCountrys: IComboBoxOption[] = [];
      const countriesWithLocalLang = COUNTRIES_LANGUAGE_MAPPING[getAppStore().locale];

      if (countries) {
        countries.forEach((countryCode) => {
          if (countriesWithLocalLang[countryCode]) {
            availableCountrys!.push({
              key: countryCode,
              text: countriesWithLocalLang[countryCode],
              ariaLabel: countriesWithLocalLang[countryCode],
            });
          }
        });
      }
      availableCountrys.sort((a, b) => {
        const firstValue = a.text;
        const secondValue = b.text;

        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      });
      return availableCountrys;
    }

    private _SKUs = (activeAccount: IAccountViewModel, accountAccessPermissions: AccountAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const appStore = getAppStore();
      const _gridDefaultColumns: IDataGridColumn[] = [
        { columnName: 'medium', friendlyName: 'Category', isVisible: true },
        { columnName: 'sku', friendlyName: 'SKU code', isVisible: true },
      ];
      const _commandBarProps: IDataGridCommandBarProps = {
        items: [
          {
            key: 'new',
            name: formatMessage(messages.createSku),
            ariaLabel: formatMessage(messages.createSku),
            iconProps: {
              iconName: 'Add',
              ariaLabel: 'Add',
            },
            selectionModes: [DataGridSelectionMode.None],
            disabled: activeAccount.nonSKUMappedMediums && activeAccount.nonSKUMappedMediums.length === 0,
            onClick: () => {
              this.setState({ skuPanelFormState: FormState.InPageCreate });
              const skuSidePanel: ISkuSidePanelProps = {
                mode: FormState.InPageCreate,
                accountSkus: activeAccount.skus,
                nonSKUMappedMediums: activeAccount.nonSKUMappedMediums,
              };
              onAccountSkuSidePanelInitialized(skuSidePanel);
            },
          },
          {
            key: 'edit',
            name: formatMessage(AppMessages.edit),
            ariaLabel: formatMessage(AppMessages.edit),
            iconProps: {
              iconName: 'Edit',
              ariaLabel: 'Edit',
            },
            onClick: () => {
              const item = this._getSelectionItems();
              if (item) {
                this.setState({ skuPanelFormState: FormState.InPageEdit });
                const skuSidePanel: ISkuSidePanelProps = {
                  mode: FormState.InPageEdit,
                  selectedAccountSku: item[0],
                };
                onAccountSkuSidePanelInitialized(skuSidePanel);
              }
            },
            selectionModes: [DataGridSelectionMode.Single],
          },
        ],
      };
      return (
        <div className={this._classNames.datagrid}>
          <DataGrid
            intl={this.props.intl}
            helpInfo={appStore.helpInfo}
            componentRef={this._datagrid}
            commandBarProps={_commandBarProps}
            dataIsLoading={false}
            detailListProps={{
              items: activeAccount.skus !== undefined ? activeAccount.skus!.filter((accountSku) => accountSku.medium !== undefined) : [],
              columns: _gridDefaultColumns,
            }}
          />
        </div>
      );
    };

    private _getSelectionItems() {
      if (this._datagrid.current) {
        const currentSelection = this._datagrid.current.getSelection();
        const selectedIndices = currentSelection.getSelectedIndices();
        const items = currentSelection.getItems();

        const selectedItems: AccountSKU[] = [];
        selectedIndices.map((selectedIndex) => selectedItems.push(items[selectedIndex] as AccountSKU));
        return selectedItems;
      }
      return null;
    }

    private _onSubmit = (publisherId?: string): void => {
      this.setState({ initializeForm: false });
      onFormSubmitted(this.props.mode, this.props.history);
    };

    private _onCancel = (publisherId?: string): void => {
      this.setState({ initializeForm: false });
      onFormCancelled();
      const returnUrl = createReturnUrl(RouteName.account, publisherId);
      onNavigateBack(this.props.history, returnUrl);
    };

    private _getAccountAccessPermission(): AccountAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.Account) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }

    private _isStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageRequired(): boolean {
      const placementCodeText = getStore().active.startDistributionPlacementCodes;
      const hasStartDistributionPlacementCodes =
        placementCodeText !== null && placementCodeText !== undefined && placementCodeText.trim().length > 0;
      const isRevShareDisabled = this._isStartDistributionNativeAdsInFeedsAndHeadlinesRevenuePercentageShareDisabled();
      return hasStartDistributionPlacementCodes && !isRevShareDisabled;
    }

    private _isStartDistributionNativeAdsInFeedsAndHeadlinesRevenuePercentageShareDisabled(): boolean {
      const accountAccessPermissions = this._getAccountAccessPermission();
      return this._isDisabled(
        this.props.mode,
        accountAccessPermissions === null ? completeAccess : accountAccessPermissions.startDistributionReporting
      );
    }

    private _isDisabled(mode: FormState, permission: number): boolean {
      if (mode === FormState.Create || mode === FormState.InPageCreate) {
        return false;
      }

      return !hasUpdateAccess(permission);
    }
  }
);
