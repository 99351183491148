import { CommandButton, IBreadcrumbItem, Stack } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { useI18n } from '../../../../../i18n/useI18n';
import { LayoutMode, getAppStore, isTythonSelfServeUser, onLayoutChanged, showLoadingToastNotification } from '../../../../@data';
import appMessages from '../../../../App.messages';
import { FormTitle } from '../../../../components/Form';
import { EmptyLayout } from '../../../../layout/EmptyLayout/EmptyLayout';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../utils';
import { getStore } from '../../../ad-management/Account/Settings/@data/store/store';
import { getDataFromURL, setDataInURL } from '../../@data/store/UrlQueryStore';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { ReportsQueryStoreKeys } from '../../@data/store/schema/ReportsQueryStoreKeys';
import ReportMessages from '../../Report.messages';
import { ICustomCalenderDateRange, RangeType } from '../../components/CustomCalender/CustomCalender.types';
import {
  DETAILED_ADUNIT_ALLOWED_CHARTS,
  DETAILED_COUNTRY_ALLOWED_CHARTS,
  DETAILED_MEDIATION_ALLOWED_CHARTS,
  DETAILED_PERFORMANCE_ALLOWED_CHARTS,
  DETAILED_SITE_ALLOWED_CHARTS,
} from '../../constants/allowedChartsType';
import { ALLOWED_MEDIATION_FILTER_COLUMNS } from '../../constants/common';
import { useMediationAvailable } from '../../hooks/useMediationAvailable';
import { useMediationReportMetadata, useMediationReportQuery, useReportMetadata, useReportQuery } from '../../hooks/useReportQuery';
import { exportToPNG, getExportFileName } from '../../utils/exportHelper';
import { getInitialDateRange } from '../../utils/getInitialDateRange';
import { AdUnitsReportCard } from '../AdUnitsReportCard/AdUnitsReportCard';
import { CountryReportCard } from '../CountryReportCard/CountryReportCard';
import { MediationReportCard } from '../MediationReportCard/MediationReportCard';
import { PerformanceReportCard } from '../PerformanceReportCard/PerformanceReportCard';
import { constructMediationRequestBody, constructRequestBody, exportReport, getStartingBreakdown } from '../ReportCard/ReportCard.helpers';
import { ActiveMetrics, ChartGrain, IReportDetailsPageParams, ReportCardType } from '../ReportCard/ReportCard.types';
import { SitesReportCard } from '../SitesReportCard/SitesReportCard';
import { ActionBar, IActiveFilter } from './ActionBar/ActionBar';
import messages from './ReportDetailsPage.messages';
import { formTitleStyles, getClassNames, getStackTokens } from './ReportDetailsPage.styles';
import { SubPageNavBar } from './SubPageNavbar/SubPageNavbar';

interface IReportDetailsPageProps extends IRouterWithIntl<IReportDetailsPageParams>, InjectedIntlProps {}

export const ReportDetailsPage = injectIntlWithObserver(({ intl, match, location, history }: IReportDetailsPageProps) => {
  const reportCardType = match.params.type;
  const { formatMessage } = intl;
  const { isMediationAvailable } = useMediationAvailable();
  const { data: reportMetadata } = useReportMetadata();
  const { data: mediationReportMetadata } = useMediationReportMetadata(!!isMediationAvailable);
  const {
    dateRange: { initialStartDate, initialEndDate },
    initialDateRangeType,
  } = getInitialDateRange();
  const [calendarDateRange, setCalendarDateRange] = useState<ICustomCalenderDateRange>({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });
  const storedFilters = getDataFromURL<IActiveFilter[]>(ReportsQueryStoreKeys.FILTERS) || [];
  const [activeFilters, setActiveFilters] = useState<IActiveFilter[]>(storedFilters);
  const [chartGrain, setChartGrain] = useState<ChartGrain>(ChartGrain.DAILY);
  const [selectedMetric, setSelectedMetric] = useState<string>();
  const [activeMetrics, setActiveMetrics] = useState<ActiveMetrics>();
  const [currentBreakdown, setCurrentBreakdown] = useState(getStartingBreakdown(getMainDimension(reportCardType)));
  const reportCardRef = useRef<HTMLDivElement>(null);
  const reportTitle = getReportTitle(reportCardType, formatMessage);
  const isMediationReport = reportCardType === ReportCardType.MEDIATION;
  const mediationMetrics = mediationReportMetadata?.metrics.map((metric) => metric.key);
  const canFetchDetails = !!reportMetadata && !!selectedMetric && !!chartGrain && !!currentBreakdown;
  const canFetchMediationDetails = !!isMediationAvailable && isMediationReport;
  const { publisher, userContext } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });

  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      key: 'Report',
      text: 'Report',
    },
    {
      key: 'reportCardType',
      text: reportTitle,
    },
  ];
  const { root } = getClassNames();
  const availableMediationFilters = mediationReportMetadata?.dimensions.filter((dimension) =>
    ALLOWED_MEDIATION_FILTER_COLUMNS.includes(TythonColumnsEnum[dimension.key])
  );
  const exportMetadata = isMediationReport ? mediationReportMetadata : reportMetadata;
  const { data: summaryData } = useReportQuery(
    constructRequestBody(ReportCardType.SUMMARY, calendarDateRange, ChartGrain.NONE, activeFilters, [], reportMetadata?.metrics || []),
    false,
    !!reportMetadata
  );
  const { data: detailedData } = useReportQuery(
    constructRequestBody(reportTitle, calendarDateRange, chartGrain, activeFilters, currentBreakdown, reportMetadata?.metrics || []),
    true,
    canFetchDetails && !canFetchMediationDetails
  );
  const { data: mediationReportDetailedData } = useMediationReportQuery(
    constructMediationRequestBody(calendarDateRange, chartGrain, activeFilters, currentBreakdown, mediationMetrics),
    canFetchDetails && canFetchMediationDetails
  );
  const exportData = isMediationReport ? mediationReportDetailedData : detailedData;

  const canExport = !!selectedMetric && !!exportMetadata && !!exportData;
  const exportedFileName = getExportFileName(calendarDateRange, chartGrain, reportTitle, locale);

  const onSelectedDatesChanged = (selectedDateRange: ICustomCalenderDateRange, rangeType: RangeType) => {
    setDataInURL(ReportsQueryStoreKeys.DATE_RANGE, selectedDateRange);
    setDataInURL(ReportsQueryStoreKeys.DATE_RANGE_TYPE, rangeType);
    setCalendarDateRange({ ...selectedDateRange });
  };
  const onChartGrainChanged = (grain: ChartGrain) => {
    setChartGrain(grain);
  };
  const onSelectedMetricChanged = (metric: string) => {
    setSelectedMetric(metric);
  };
  const onActiveMetricsChanged = (changedMetrics: ActiveMetrics) => {
    setActiveMetrics({ ...changedMetrics });
  };
  const onExportCSVClicked = () => {
    if (!canExport) {
      return;
    }
    exportReport(exportData, [...exportMetadata?.dimensions, ...exportMetadata?.metrics], exportedFileName, formatMessage);
  };
  const onExportPNGClicked = () => {
    if (!canExport) {
      return;
    }
    showLoadingToastNotification({
      textMessageDescriptor: messages.generatingAndExportingPNG,
      onDismiss: async () => {
        reportCardRef.current && (await exportToPNG(reportCardRef.current, exportedFileName));
      },
    });
  };

  useEffect(() => {
    onLayoutChanged(LayoutMode.ContentOnly);
  }, []);

  return (
    <div className={root}>
      <SubPageNavBar
        breadcrumbAriaLabel={intl.formatMessage(messages.breadcrumbAriaLabel)}
        breadcrumbItems={breadcrumbItems}
        backAriaDescription={intl.formatMessage(messages.backButtonAriaDesc)}
      />

      <EmptyLayout>
        {isTythonSelfServeUser() ? (
          <>
            <Stack styles={formTitleStyles} horizontal horizontalAlign="space-between">
              <FormTitle title={reportTitle} noHelpPopup={true} intl={intl} />
              <div>
                <CommandButton
                  iconProps={{ iconName: 'export' }}
                  text={intl.formatMessage(messages.exportButton)}
                  menuProps={{
                    items: [
                      {
                        key: 'exportCSV',
                        text: intl.formatMessage(ReportMessages.exportCSV),
                        onClick: onExportCSVClicked,
                        disabled: !canExport,
                      },
                      {
                        key: 'exportPNG',
                        text: intl.formatMessage(ReportMessages.exportPNG),
                        onClick: onExportPNGClicked,
                        disabled: !canExport,
                      },
                    ],
                  }}
                />
              </div>
            </Stack>
            <Stack tokens={getStackTokens()}>
              <Stack.Item grow basis={'100%'}>
                <ActionBar
                  onSelectedDatesChanged={onSelectedDatesChanged}
                  initialDateRange={{ startDate: initialStartDate, endDate: initialEndDate }}
                  initialDateRangeType={initialDateRangeType}
                  onFilterChanged={(changedFilters: IActiveFilter[]) => {
                    setActiveFilters([...changedFilters]);
                  }}
                  dimensions={(isMediationReport ? availableMediationFilters : reportMetadata?.dimensions) || []}
                  isMetadataLoaded={isMediationReport ? !!mediationReportMetadata : !!reportMetadata}
                  reportCardType={reportCardType}
                  activeMetrics={activeMetrics}
                />
              </Stack.Item>
              <Stack.Item grow basis={'100%'}>
                {reportCardType === ReportCardType.PERFORMANCE && (
                  <PerformanceReportCard
                    allowedChartTypes={DETAILED_PERFORMANCE_ALLOWED_CHARTS}
                    showDetailsReportLink={false}
                    enableHideChartsButton={true}
                    dateRange={calendarDateRange}
                    isDetailedView={true}
                    onChartGrainChanged={onChartGrainChanged}
                    onSelectedMetricChanged={onSelectedMetricChanged}
                    onBreakdownChanged={(updatedBreakdown) => {
                      setCurrentBreakdown(updatedBreakdown);
                    }}
                    reportDetailedData={detailedData}
                    filters={activeFilters}
                    history={history}
                    location={location}
                    match={match}
                    reportCardRef={reportCardRef}
                    onActiveMetricsChanged={onActiveMetricsChanged}
                    reportMetadata={reportMetadata}
                    summaryData={summaryData}
                  />
                )}
                {reportCardType === ReportCardType.ADUNIT && (
                  <AdUnitsReportCard
                    allowedChartTypes={DETAILED_ADUNIT_ALLOWED_CHARTS}
                    showDetailsReportLink={false}
                    enableHideChartsButton={true}
                    dateRange={calendarDateRange}
                    showMetricsMarkers={true}
                    isDetailedView={true}
                    onChartGrainChanged={onChartGrainChanged}
                    onSelectedMetricChanged={onSelectedMetricChanged}
                    onBreakdownChanged={(updatedBreakdown) => {
                      setCurrentBreakdown(updatedBreakdown);
                    }}
                    reportDetailedData={detailedData}
                    filters={activeFilters}
                    history={history}
                    location={location}
                    match={match}
                    reportCardRef={reportCardRef}
                    onActiveMetricsChanged={onActiveMetricsChanged}
                    reportMetadata={reportMetadata}
                    summaryData={summaryData}
                  />
                )}
                {reportCardType === ReportCardType.SITE && (
                  <SitesReportCard
                    allowedChartTypes={DETAILED_SITE_ALLOWED_CHARTS}
                    showDetailsReportLink={false}
                    enableHideChartsButton={true}
                    dateRange={calendarDateRange}
                    showMetricsMarkers={true}
                    isDetailedView={true}
                    onChartGrainChanged={onChartGrainChanged}
                    onSelectedMetricChanged={onSelectedMetricChanged}
                    onBreakdownChanged={(updatedBreakdown) => {
                      setCurrentBreakdown(updatedBreakdown);
                    }}
                    reportDetailedData={detailedData}
                    filters={activeFilters}
                    history={history}
                    location={location}
                    match={match}
                    reportCardRef={reportCardRef}
                    onActiveMetricsChanged={onActiveMetricsChanged}
                    reportMetadata={reportMetadata}
                    summaryData={summaryData}
                  />
                )}
                {reportCardType === ReportCardType.COUNTRY && (
                  <CountryReportCard
                    allowedChartTypes={DETAILED_COUNTRY_ALLOWED_CHARTS}
                    showDetailsReportLink={false}
                    enableHideChartsButton={true}
                    dateRange={calendarDateRange}
                    showMetricsMarkers={true}
                    isDetailedView={true}
                    onChartGrainChanged={onChartGrainChanged}
                    onSelectedMetricChanged={onSelectedMetricChanged}
                    onBreakdownChanged={(updatedBreakdown) => {
                      setCurrentBreakdown(updatedBreakdown);
                    }}
                    reportDetailedData={detailedData}
                    filters={activeFilters}
                    history={history}
                    location={location}
                    match={match}
                    reportCardRef={reportCardRef}
                    onActiveMetricsChanged={onActiveMetricsChanged}
                    reportMetadata={reportMetadata}
                    summaryData={summaryData}
                  />
                )}
                {isMediationAvailable && reportCardType === ReportCardType.MEDIATION && (
                  <MediationReportCard
                    dateRange={calendarDateRange}
                    enableHideChartsButton={true}
                    showMetricsMarkers={true}
                    allowedChartTypes={DETAILED_MEDIATION_ALLOWED_CHARTS}
                    showDetailsReportLink={false}
                    isDetailedView={true}
                    onChartGrainChanged={onChartGrainChanged}
                    onSelectedMetricChanged={onSelectedMetricChanged}
                    onBreakdownChanged={(updatedBreakdown) => {
                      setCurrentBreakdown(updatedBreakdown);
                    }}
                    reportDetailedData={mediationReportDetailedData}
                    filters={activeFilters}
                    history={history}
                    location={location}
                    match={match}
                    reportCardRef={reportCardRef}
                    reportMetadata={mediationReportMetadata}
                  />
                )}
              </Stack.Item>
            </Stack>
          </>
        ) : (
          <div>{formatMessage(ReportMessages.reportDescriptionForReportsNotApplicable)}</div>
        )}
      </EmptyLayout>
    </div>
  );
});

function getReportTitle(
  reportCardType: ReportCardType,
  formatMessage: (
    messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
    values?: { [key: string]: ReactIntl.MessageValue } | undefined
  ) => string
) {
  switch (reportCardType) {
    case ReportCardType.PERFORMANCE:
      return formatMessage(appMessages.performanceCardTitle);
    case ReportCardType.ADUNIT:
      return formatMessage(appMessages.adUnitsCardTitle);
    case ReportCardType.MEDIATION:
      return formatMessage(appMessages.mediationCardTitle);
    case ReportCardType.COUNTRY:
      return formatMessage(appMessages.countryCardTitle);
    case ReportCardType.SITE:
      return formatMessage(appMessages.sitesCardTitle);
    default:
      return '';
  }
}
function getMainDimension(reportCardType: ReportCardType) {
  switch (reportCardType) {
    case ReportCardType.ADUNIT:
      return TythonColumnsEnum.AdUnitName;
    case ReportCardType.MEDIATION:
      return TythonColumnsEnum.AdUnitName;
    case ReportCardType.COUNTRY:
      return TythonColumnsEnum.Country;
    case ReportCardType.SITE:
      return TythonColumnsEnum.PropertyUrl;
    default:
      return '';
  }
}
