import { makeStyles, shorthands, tokens, typographyStyles } from '@fluentui/react-components';
import { BreakPoints } from '../../../../../../theme';
import AdPreviewBackground from './resources/adPreviewBackground.png';

const { ResponsiveSmall } = BreakPoints;

export const useAppAdUnitPrimaryFormStyles = makeStyles({
  previewSDKWrapper: {
    [ResponsiveSmall]: {
      maxWidth: '100%',
      overflowX: 'auto',
    },
    '& > div > div[data-template="horizontal"]': {
      boxShadow: tokens.shadow4Brand,
    },
  },
  dimensionInputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: tokens.spacingVerticalM,
    marginBottom: tokens.spacingVerticalM,
    ...shorthands.gap(tokens.spacingHorizontalM),
  },
  dimensionInput: {
    width: '100px',
  },
  colorSelectorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: tokens.spacingVerticalM,
  },
  sectionTitle: {
    ...typographyStyles.title3,
  },
  applicationDropdown: {
    width: '100%',
  },
  cardsWrapper: {
    display: 'flex',
  },
  card: {
    height: '388px',
    width: '187px',
    marginRight: tokens.spacingHorizontalL,
  },
  cardTitleRadioCheckbox: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: '0 !important',
    marginTop: tokens.spacingHorizontalM,
  },
  subTitle: {
    color: tokens.colorNeutralForeground2,
    marginTop: '-24px',
    marginBottom: tokens.spacingVerticalM,
  },
  body1Storng: {
    ...typographyStyles.body1Strong,
  },
  dimensionDropdown: {
    width: '100%',
  },
  msgBar: {
    backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
    marginTop: tokens.spacingHorizontalM,
  },
  required: {
    '::after': {
      content: `" *"`,
      color: tokens.colorPaletteRedForeground3,
      paddingRight: tokens.spacingHorizontalM,
    },
  },
  infoButton: {
    width: tokens.spacingHorizontalL,
    height: tokens.spacingVerticalL,
    display: 'contents',
    '::before': {
      content: `" *"`,
      color: tokens.colorPaletteRedForeground3,
    },
  },
  infoBubbleNoRequire: {
    width: tokens.spacingHorizontalL,
    height: tokens.spacingVerticalL,
    display: 'contents',
  },
  propertyOption: {
    ...shorthands.padding(tokens.spacingVerticalSNudge),
    '> div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  },
});

export const useAppAdUnitTypeFormStyles = makeStyles({
  body1Storng: {
    ...typographyStyles.body1Strong,
  },
  dimensionDropdown: {
    width: '100%',
  },
  msgBar: {
    backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
    marginTop: tokens.spacingHorizontalM,
  },
  required: {
    '::after': {
      content: `" *"`,
      color: tokens.colorPaletteRedForeground3,
      paddingRight: tokens.spacingHorizontalM,
    },
  },
});

export const useV2SectionalAccordionStyles = makeStyles({
  card: {
    width: 'calc(100% - 48px)',
    maxWidth: '1360px',
  },
  horizontalDivider: {
    width: 'calc(100% + 24px)',
    marginLeft: '-12px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '400px',
  },
  verticalDivider: {
    height: 'calc(100% + 24px)',
    marginTop: `-12px`,
    maxWidth: tokens.spacingHorizontalXXS,
  },
  contentLeft: {
    width: '368px',
    minWidth: '368px',
    paddingRight: tokens.spacingHorizontalM,
  },
  contentRight: {
    paddingLeft: tokens.spacingHorizontalM,
    width: '100%',
    maxWidth: 'calc(100% - 380px - 12px)', // 100% - left content width - right side padding
  },
  chevronIcon: {
    cursor: 'pointer',
    ...shorthands.padding(tokens.spacingVerticalSNudge),
  },
  disabled: {
    display: 'none',
  },
});

export const useAppAdUnitResultSummaryStyles = makeStyles({
  root: {
    marginTop: '48px',
  },
  stepAvatar: {
    marginRight: tokens.spacingHorizontalM,
  },
  checkMarkLogo: {
    marginLeft: 'calc((100% - 256px)/2)',
  },
  codeCard: {
    maxWidth: '600px',
    height: '88px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  codeIcon: {
    width: '64px',
    flexGrow: '0 !important',
  },
  codeCardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 64px)',
    height: '52px',
    backgroundColor: tokens.colorNeutralBackground3,
    ...shorthands.padding(0, tokens.spacingHorizontalM),
  },
  codeCardWrapper: {
    paddingLeft: tokens.spacingHorizontalXXL,
    marginLeft: tokens.spacingHorizontalS,
    marginTop: tokens.spacingHorizontalS,
    paddingBottom: tokens.spacingVerticalMNudge,
    marginBottom: tokens.spacingVerticalSNudge,
    ...shorthands.borderLeft(tokens.strokeWidthThicker, 'solid', tokens.colorNeutralBackground1Selected),
  },
  codeHelpText: {
    ...shorthands.margin(tokens.spacingVerticalS, 0),
  },
  codePanelButton: {
    marginBottom: tokens.spacingVerticalSNudge,
  },
});

export const useAppAdUnitPreviewStyles = makeStyles({
  root: {
    height: '100%',
  },
  tittleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  layoutTagsWrapper: {
    display: 'flex',
    ...shorthands.gap(tokens.spacingHorizontalXXS),
  },
  previewWrapper: {
    marginTop: tokens.spacingVerticalM,
    position: 'relative',
    height: 'calc(100% - 48px + 12px)', // 100% - title height + 12px margin
    overflowX: 'scroll',
    paddingLeft: tokens.spacingHorizontalM,
  },
  HorizontalBottomAdPreview: {
    width: '768px',
    marginLeft: '-5px',
  },
  SquareBottomAdPreview: {
    width: '294px',
    position: 'absolute',
    top: '-5px',
  },
  VerticalLeftAdPreview: {
    width: '134px',
    position: 'absolute',
  },
  VerticalBottomAdPreview: {
    width: '137px',
    position: 'absolute',
    left: '139px',
    top: '-5px',
  },
});

const squareAdPreviewContainerSize = {
  width: '286px',
  height: '286px',
  minWidth: '286px',
  minHeight: '286px',
  maxWidth: '286px',
  maxHeight: '286px',
};

const horizontalAdPreviewContainerSize = {
  width: '766px',
  height: '122px',
};

const flowerBackgroundImg = {
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(${AdPreviewBackground})`,
  backgroundSize: 'cover',
};

const verticalAdPreviewContainerSize = {
  width: '134px',
  height: '305px',
};

const horizontalAdIconPosition = {
  right: tokens.spacingVerticalL,
  top: tokens.spacingVerticalL,
};

const adIconSize = {
  width: tokens.spacingVerticalL,
};

const adBadgeStyle = {
  backgroundColor: tokens.colorNeutralForegroundInverted,
  color: tokens.colorNeutralForeground3,
  fontSize: tokens.fontSizeBase200,
  lineHeight: tokens.lineHeightBase200,
  cursor: 'default',
  ...shorthands.borderRadius(tokens.borderRadiusSmall),
  ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground2Pressed),
  ...shorthands.padding(0, tokens.spacingHorizontalM),
};

const secondarySubtitleStyles = {
  textShadow: tokens.shadow64Brand,
  color: tokens.colorNeutralBackground1,
  fontWeight: tokens.fontWeightBold,
};

export const useAdPreviewRendererStyles = makeStyles({
  nonSelectableText: {
    userSelect: 'none',
    '-ms-user-select': 'none',
    '-moz-user-select': 'none',
    '-webkit-user-select': 'none',
  },
  primaryAdWrapperHorizontal: {
    ...horizontalAdPreviewContainerSize,
    position: 'relative',
  },
  primaryAdWrapperVertical: {
    ...verticalAdPreviewContainerSize,
    position: 'relative',
    justifyContent: 'flex-start',
    backgroundImage: `linear-gradient(to top, ${tokens.colorNeutralBackground5} 40px, transparent 56px)`,
  },
  primaryAdWrapperSquare: {
    ...squareAdPreviewContainerSize,
    position: 'relative',
    justifyContent: 'flex-start',
    backgroundImage: `linear-gradient(to top, ${tokens.colorNeutralBackground5} 40px, transparent 56px)`,
  },
  secondaryAdWrapperHorizontal: {
    ...horizontalAdPreviewContainerSize,
    ...flowerBackgroundImg,
    position: 'relative',
    backgroundPositionY: 'center',
  },
  secondaryAdWrapperSquare: {
    ...squareAdPreviewContainerSize,
    ...flowerBackgroundImg,
    position: 'relative',
    backgroundPositionY: 'center',
  },
  secondaryAdWrapperVertical: {
    ...verticalAdPreviewContainerSize,
    ...flowerBackgroundImg,
    position: 'relative',
    backgroundPositionX: 'center',
    justifyContent: 'flex-start',
  },
  previewCardWrapperHorizontal: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap(tokens.spacingHorizontalS),
  },
  previewCardWrapperVertical: {
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap(tokens.spacingHorizontalS),
  },
  previewCardWrapperSquare: {
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap(tokens.spacingHorizontalS),
  },
  primaryAdIconHorizontal: {
    ...horizontalAdIconPosition,
    ...adIconSize,
    position: 'absolute',
  },
  primaryAdIconVertical: {
    ...adIconSize,
    position: 'absolute',
    right: tokens.spacingVerticalXXS,
    top: tokens.spacingVerticalXXS,
  },
  primaryAdIconSquare: {
    ...adIconSize,
    position: 'absolute',
    right: tokens.spacingVerticalXXS,
    top: tokens.spacingVerticalXXS,
  },
  secondaryAdIconHorizontal: {
    ...horizontalAdIconPosition,
    ...adIconSize,
    position: 'absolute',
  },
  secondaryAdIconSquare: {
    ...adIconSize,
    position: 'absolute',
    right: tokens.spacingVerticalXXS,
    bottom: tokens.spacingVerticalXXS,
  },
  secondaryAdIconVertical: {
    ...adIconSize,
    position: 'absolute',
    right: tokens.spacingVerticalXXS,
    bottom: tokens.spacingVerticalXXS,
  },
  primaryAdButtonHorizontal: {
    position: 'absolute',
    right: '36px',
    top: '46px',
  },
  primaryAdButtonVertical: {
    position: 'absolute',
    bottom: tokens.spacingVerticalM,
    left: tokens.spacingHorizontalL,
  },
  primaryAdButtonSquare: {
    position: 'absolute',
    bottom: tokens.spacingVerticalM,
    left: '92px',
  },
  secondaryAdButtonHorizontal: {
    position: 'absolute',
    right: '64px',
    top: '46px',
    backgroundColor: tokens.colorStatusDangerBackground2,
    color: tokens.colorNeutralBackground1,
    shadow: tokens.shadow64,
  },
  secondaryAdButtonSquare: {
    position: 'absolute',
    right: '96px',
    bottom: '46px',
    backgroundColor: tokens.colorStatusDangerBackground2,
    color: tokens.colorNeutralBackground1,
    shadow: tokens.shadow64,
  },
  secondaryAdButtonVertical: {
    position: 'absolute',
    bottom: '40px',
    left: tokens.spacingHorizontalL,
    backgroundColor: tokens.colorStatusDangerBackground2,
    color: tokens.colorNeutralBackground1,
    shadow: tokens.shadow64,
  },
  primaryAdBadgeHorizontal: {
    position: 'absolute',
    right: '36px',
    top: '15px',
    ...adBadgeStyle,
  },
  primaryAdBadgeSquare: {
    position: 'absolute',
    right: tokens.spacingHorizontalXXL,
    top: '1px',
    ...adBadgeStyle,
  },
  primaryAdBadgeVertical: {
    position: 'absolute',
    right: tokens.spacingVerticalXL,
    top: tokens.spacingVerticalXXS,
    ...adBadgeStyle,
  },
  secondaryBadgeHorizontal: {
    position: 'absolute',
    bottom: tokens.spacingVerticalM,
    left: tokens.spacingVerticalXXL,
    ...adBadgeStyle,
  },
  secondaryBadgeSquare: {
    position: 'absolute',
    bottom: tokens.spacingVerticalXXS,
    left: tokens.spacingVerticalXXS,
    ...adBadgeStyle,
  },
  secondaryBadgeVertical: {
    position: 'absolute',
    bottom: tokens.spacingVerticalXXS,
    left: tokens.spacingVerticalXXS,
    ...adBadgeStyle,
  },
  secondarySubtitleHorizontal: {
    ...secondarySubtitleStyles,
    marginTop: tokens.spacingHorizontalM,
    marginLeft: tokens.spacingHorizontalS,
  },
  secondarySubtitleSquare: {
    ...secondarySubtitleStyles,
    marginTop: '48px',
    marginLeft: tokens.spacingHorizontalS,
  },
  secondarySubtitleVertical: {
    ...secondarySubtitleStyles,
    textAlign: 'center',
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase300,
    marginTop: tokens.spacingHorizontalXXL,
  },
  primaryTitleVertical: {
    fontSize: tokens.fontSizeBase400,
    lineHeight: tokens.lineHeightBase400,
  },
  primaryTitleSquare: {
    marginTop: tokens.spacingVerticalS,
  },
  displayNone: {
    display: 'none',
  },
  primaryBodyVertical: {
    marginTop: '-60px',
    lineHeight: tokens.spacingVerticalL,
  },
  primaryBodySquare: {
    marginTop: '-34px',
  },
});
