import { DefaultButton, Image, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Footer } from '../../../layout/Footer/Footer';
import UserNotFound from '../resources/UserNotFound.svg';
import { getClassNames } from './InvalidUser.styles';

export interface IInvalidUserErrorPageProps extends InjectedIntlProps, RouteComponentProps {
  errorImageAltText?: string;
  imageSrc?: string;
  renderTitle?: () => JSX.Element;
  title?: string;
  email?: string;
  titleSubText?: string;
  children?: React.ReactNode;
  primaryButtonLabel?: string;
  defaultButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  onDefaultButtonClick?: () => void;
}

export const InvalidUserErrorPage = function ErrorPageComponent(props: IInvalidUserErrorPageProps): JSX.Element {
  const {
    errorImageAltText,
    imageSrc,
    renderTitle,
    title,
    email,
    titleSubText,
    primaryButtonLabel,
    defaultButtonLabel,
    onPrimaryButtonClick,
    onDefaultButtonClick,
  } = props;
  const { root, main, title: titleStyle, email: emailStyle, button: buttonStyle } = getClassNames();

  return (
    <div className={root}>
      <div className={main}>
        <Image src={imageSrc || UserNotFound} alt={errorImageAltText} />
        {renderTitle ? (
          renderTitle()
        ) : (
          <h1 className={titleStyle}>
            {title}
            <span className={emailStyle}>{email}</span>
            <br />
            {titleSubText}
          </h1>
        )}
        {primaryButtonLabel && onPrimaryButtonClick && (
          <PrimaryButton className={buttonStyle} text={primaryButtonLabel} onClick={onPrimaryButtonClick} />
        )}
        {defaultButtonLabel && onDefaultButtonClick && (
          <DefaultButton className={buttonStyle} text={defaultButtonLabel} onClick={onDefaultButtonClick} />
        )}
        {props.children}
      </div>
      <Footer {...props} />
    </div>
  );
};
