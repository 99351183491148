import { AdSource } from '../../../../../../@data';
import { FilterCondition } from '../../../../../../@data/store/schema/enums/FilterCondition';
import { IIdNamePair } from '../../../../../../components/MultiSelectPicker';
import { AdPerformanceData, BlockAdsData, SAMBlockSummaryData } from './models';

export enum FilterType {
  Text,
  Numerical,
  List,
}

export enum AdQualityFilterNames {
  AccountName = 'Account Name',
  AdProviders = 'Ad Providers',
  Markets = 'Markets',
  Advertiser = 'Advertiser',
  AdvertiserId = 'Advertiser Id',
  AdvertiserAccountId = 'Advertiser Account Id',
  AdId = 'Ad Id',
  AdTitle = 'Ad Title',
  AdURL = 'Ad URL',
  CreativeAdId = 'Creative Ad Id',
  LongHeadline = 'Long Headline',
  ShortHeadline = 'Short Headline',
}

export interface IConditionValue<ValueType> {
  condition?: FilterCondition;
  value?: ValueType;
  isApplied?: boolean;
}

export interface ITextFilter {
  filterType?: FilterType.Text;
  conditions?: IConditionValue<string>[];
}

export interface INumericalFilter {
  filterType?: FilterType.Numerical;
  conditions?: IConditionValue<string>[];
}

export interface IListFilter {
  filterType?: FilterType.List;
  values: IIdNamePair[];
}

export interface IAdQualityFilter {
  [AdQualityFilterNames.AccountName]?: IListFilter;
  [AdQualityFilterNames.AdProviders]?: IListFilter;
  [AdQualityFilterNames.Markets]?: IListFilter;
  [AdQualityFilterNames.Advertiser]?: ITextFilter;
  [AdQualityFilterNames.AdvertiserId]?: INumericalFilter;
  [AdQualityFilterNames.AdvertiserAccountId]?: INumericalFilter;
  [AdQualityFilterNames.AdId]?: INumericalFilter;
  [AdQualityFilterNames.AdTitle]?: ITextFilter;
  [AdQualityFilterNames.AdURL]?: ITextFilter;
  [AdQualityFilterNames.CreativeAdId]?: ITextFilter;
  [AdQualityFilterNames.LongHeadline]?: ITextFilter;
  [AdQualityFilterNames.ShortHeadline]?: ITextFilter;
}

export interface IAdCreativeViewModel {
  accounts?: IIdNamePair[];
  adProviders?: IIdNamePair[];
  markets?: IIdNamePair[];
  selectedAccount?: number;
  selectedMarket?: string;
  searchBoxValue?: string;
  startDate?: Date;
  endDate?: Date;
  adPerfData?: AdPerformanceData[];
  blockRequestSourceData?: Map<number, string>;
  blockSummaryStartDate?: Date;
  blockSummaryEndDate?: Date;
  blockSummaryData?: BlockAdsData[];
  samBlockSummaryData?: SAMBlockSummaryData[];
  selectedAdSource?: AdSource;
  selectedAdProviderId?: number;
  selectedBlockedMarketId?: number;
  active?: IAdQualityFilter;
  raw?: IAdQualityFilter;
}
