import { DefaultButton, Image, Link, getTheme } from '@fluentui/react';
import React, { useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { getAppStore } from '../../../../../../../../@data';
import AppMessages from '../../../../../../../../App.messages';
import { FormSection } from '../../../../../../../../components/Form';
import { APP_NAME_NEW, COPY_BUTTON_TIMEOUT } from '../../../../../../../../constants/AppConstants';
import { injectIntlWithObserver } from '../../../../../../../../utils';
import fileIcon from '../../resources/fileIcon.svg';
import fishpool from '../../resources/fishpool.svg';
import messages from './messages';
import { getClassNames, getStyles } from './styles';

export interface IAdsTextPageProps extends InjectedIntlProps {}

export const AdsTextPage = injectIntlWithObserver(function AdsTextPageComponent(props: IAdsTextPageProps) {
  const { formatMessage } = props.intl;
  const { publisher } = getAppStore();
  const {
    container,
    scriptBox,
    scriptHolder,
    stepHeader,
    subComponentStyles,
    fileUploadContainerStyle,
    fileUploadedStyle,
    actionContainerStyle,
    fileUploadLabelStyle,
    poolImage,
  } = getClassNames(getStyles, { theme: getTheme() });
  const [copyScriptIcon, onCopyScriptIconChanged] = useState('Copy');

  const content = `appnexus.com, 14163, RESELLER \nmonetizenow.microsoft.com, ${publisher?.id}, DIRECT`;
  const file = new Blob([content], { type: 'text/plain' });

  const onCopyScriptClicked = (text: string) => {
    navigator.clipboard.writeText(text);
    // This change of icon will indicate the user that script copy was successful
    onCopyScriptIconChanged('Accept');
    // After 3000ms we change the icon back to 'Copy'
    setTimeout(() => onCopyScriptIconChanged('Copy'), COPY_BUTTON_TIMEOUT);
  };

  return (
    <div className={container}>
      <div>
        <p>{formatMessage(messages.adsTextExplanation, { appName: APP_NAME_NEW })}</p>
        <FormSection styles={subComponentStyles.formSection} title={formatMessage(messages.createAdsTxtStepTitle)}>
          <label className={stepHeader}>{formatMessage(messages.stepOne)}</label>
          <div className={fileUploadContainerStyle}>
            <div className={fileUploadedStyle}>
              <div>
                <Image src={fileIcon} alt={formatMessage(messages.adsText)} />
                {formatMessage(messages.adsText)}
              </div>
              <div className={actionContainerStyle}>
                <label className={fileUploadLabelStyle} tabIndex={0}>
                  <Link href={URL?.createObjectURL?.(file)} download="ads.txt">
                    {formatMessage(AppMessages.download)}
                  </Link>
                </label>
              </div>
            </div>
          </div>
          <label className={stepHeader}>{formatMessage(messages.stepTwo)}</label>
          <p>{formatMessage(messages.addingFileToRootDirectoryStep)}</p>
          <label className={stepHeader}>{formatMessage(messages.stepThree)}</label>
          <p>{formatMessage(messages.verifyingFileStep, { appName: APP_NAME_NEW })}</p>
        </FormSection>
        <FormSection
          styles={subComponentStyles.formSection}
          title={formatMessage(messages.addpubCenterToFileStepTitle, { appName: APP_NAME_NEW })}
        >
          <label className={stepHeader}>{formatMessage(messages.stepOne)}</label>
          <p>{formatMessage(messages.addpubCenterToFileStep)}</p>
          <div className={scriptBox}>
            <code className={scriptHolder}>
              <span>{content}</span>
            </code>
            <DefaultButton
              text={formatMessage(messages.copyCodeButton)}
              iconProps={{ iconName: copyScriptIcon }}
              onClick={() => onCopyScriptClicked(content)}
            />
          </div>
          <label className={stepHeader}>{formatMessage(messages.stepTwo)}</label>
          <p>{formatMessage(messages.addingFileToRootDirectoryStep)}</p>
        </FormSection>
      </div>
      <Image className={poolImage} src={fishpool} imageFit={1} alt={formatMessage(messages.adsText)} />
    </div>
  );
});
