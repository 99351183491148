import { defineMessages } from 'react-intl';

export default defineMessages({
  performanceSectionTitle: {
    id: 'performanceSection.title',
    defaultMessage: 'Your Performance Snapshot',
  },
  viewPayment: {
    id: 'viewPayment.button',
    defaultMessage: 'View Payment',
  },
  lastEarned: {
    id: 'lastEarned.text',
    defaultMessage: 'earned since last payment',
  },
  balanceCardTitle: {
    id: 'balanceCard.title',
    defaultMessage: 'Balance',
  },
  payout: {
    id: 'payout.popup',
    defaultMessage: 'Payout!',
  },
  balanceImage: {
    id: 'balance.image',
    defaultMessage: 'Balance Image',
  },
  performanceCardTitle: {
    id: 'performanceCard.title',
    defaultMessage: 'Performance',
  },
  metricsDaterange: {
    id: 'metric.daterange',
    defaultMessage: 'Last 30 days',
  },
  viewReports: {
    id: 'viewReports.button',
    defaultMessage: 'View Reports',
  },
});
