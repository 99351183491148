import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { ICommonStyleProps } from '../../types';

type StyleProperties = 'gridContainer';

type GridPageLayoutStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): GridPageLayoutStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing } = theme;
  const { withSubHeader } = props;

  return mergeStyleSets({
    gridContainer: {
      marginLeft: spacing.size32,
      marginTop: withSubHeader ? 0 : spacing.size32,
      marginRight: spacing.size32,
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      flex: 1,
    },
  });
};

export function getClassNames<PropType>(props: PropType): GridPageLayoutStyles {
  return getStyles({ theme: getTheme(), ...props });
}
