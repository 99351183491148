import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing } from '../../../theme';
import { ICommonStyleProps } from '../../types';

type StyleProperties = 'actionButtonsWrapper' | 'gridActionButton' | 'gridCell';
type GridCommonStyles = Record<StyleProperties, string>;

const getStyles = (props: ICommonStyleProps): GridCommonStyles => {
  const spacing = props.theme.spacing as IPubcenterSpacing;
  const { semanticColors: s } = props.theme;
  const { size14 } = props.theme.fonts as IPubCenterFonts;

  return mergeStyleSets({
    actionButtonsWrapper: {
      display: 'flex',
      gap: spacing.size8,
    },
    gridActionButton: {
      root: {
        color: s.primaryButtonBackground,
        ...size14,
        height: spacing.size36,
      },
      rootHovered: {
        color: s.primaryButtonBackgroundHovered,
      },
      rootPressed: {
        color: s.primaryButtonBackgroundPressed,
      },
    },
    gridCell: {
      userSelect: 'text',
      cursor: 'text',
      width: 'fit-content',
      lineHeight: spacing.size20,
      margin: spacing.size8,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
};

export function getGridClassNames<PropType>(props?: PropType): GridCommonStyles {
  return getStyles({ theme: getTheme(), ...props });
}
