import { mutator } from 'satcheljs';
import AppMessages from '../../App.messages';
import { onLoadingChanged, onLoadingSetting, onLoadingWaiting } from '../actions/onLoadingChanged';
import { getAppStore } from '../store/store';

mutator(onLoadingChanged, msg => {
  getAppStore().loading = msg.loading;
  AppMessages.loadingLabel.defaultMessage = AppMessages.loadingStatusLabel.defaultMessage;
});

mutator(onLoadingSetting, msg => {
  getAppStore().waiting = msg.waiting;
  AppMessages.loadingLabel.defaultMessage = AppMessages.loadingStatusLabel.defaultMessage;
});

mutator(onLoadingWaiting, msg => {
  getAppStore().waiting = msg.waiting;
  AppMessages.loadingLabel.defaultMessage = AppMessages.loadingWaitingLabel.defaultMessage;
});