export enum FormState {
  Create,
  View,
  Edit,
  InPageCreate,
  InPageView,
  InPageEdit,
  Delete,
  Resend,
  AcceptInvite,
  MultiStepCreation,
  MultiStepEdit,
}
