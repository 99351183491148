import {
  BaseComponent,
  classNamesFunction,
  ComboBox,
  DatePicker,
  getTheme,
  IComboBoxOption,
  IDatePickerStrings,
  IProcessedStyleSet,
  TextField,
} from '@fluentui/react';
import * as React from 'react';
import messages from './DateTimePicker.messages';
import { getStyles } from './DateTimePicker.styles';
import { DayPickerStrings, IDateTimePickerProps, IDateTimePickerStyleProps, IDateTimePickerStyles } from './DateTimePicker.types';

interface IDateTimePickerState {
  date?: number;
  month?: number;
  year?: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const getClassNames = classNamesFunction<IDateTimePickerStyleProps, IDateTimePickerStyles>();

export class DateTimePicker extends BaseComponent<IDateTimePickerProps, IDateTimePickerState> {
  private _classNames: IProcessedStyleSet<IDateTimePickerStyles>;

  constructor(props: IDateTimePickerProps) {
    super(props);

    const { value } = this.props;
    if (value) {
      this.state = {
        date: value.getDate(),
        month: value.getMonth(),
        year: value.getFullYear(),
        hours: value.getHours(),
        minutes: value.getMinutes(),
        seconds: value.getSeconds(),
      };
    } else {
      this.state = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }

  public componentDidUpdate(prevProps: IDateTimePickerProps, prevState: IDateTimePickerState): void {
    if (prevProps !== this.props) {
      const { value } = this.props;
      if (value) {
        this.setState({
          date: value.getDate(),
          month: value.getMonth(),
          year: value.getFullYear(),
          hours: value.getHours(),
          minutes: value.getMinutes(),
          seconds: value.getSeconds(),
        });
      }
    }
  }

  public render(): JSX.Element {
    const { formatMessage } = this.props.intl;
    const {
      datePickerWidth,
      datePlaceholder,
      label,
      minDateTime,
      maxDateTime,
      onDateTimeChanged,
      allowSecondsField,
      disabled,
      isOutOfBoundsErrorMessage,
    } = this.props;
    const { year, month, date, hours, minutes, seconds } = this.state;

    const theme = getTheme();
    const styles = getStyles({ theme, datePickerWidth });
    this._classNames = getClassNames(styles);

    const hourComboBoxOption: IComboBoxOption[] = [];

    hourComboBoxOption.push({ key: 0, text: `12 AM` });
    for (let i = 1; i < 12; i++) {
      hourComboBoxOption.push({ key: i, text: `${i} AM` });
    }
    hourComboBoxOption.push({ key: 12, text: `12 PM` });
    for (let i = 1; i < 12; i++) {
      hourComboBoxOption.push({ key: 12 + i, text: `${i} PM` });
    }

    // Disabling out of bound hour options
    if (this.props.value && minDateTime && this.props.value!.toDateString() === minDateTime!.toDateString()) {
      hourComboBoxOption.forEach((option) => {
        if (option.key < minDateTime!.getHours()) {
          option.disabled = true;
        }
      });
    }
    if (this.props.value && maxDateTime && this.props.value!.toDateString() === maxDateTime!.toDateString()) {
      hourComboBoxOption.forEach((option) => {
        if (option.key > maxDateTime!.getHours()) {
          option.disabled = true;
        }
      });
    }

    // We are only providing these options but using comboBox user can choose to add a custom value as well
    const minutesComboBoxOption: IComboBoxOption[] = [
      { key: 0, text: '00' },
      { key: 15, text: '15' },
      { key: 30, text: '30' },
      { key: 45, text: '45' },
    ];

    // Adding a new options for the custom minute from input value
    if (minutes && !minutesComboBoxOption.find((option) => option.key === minutes)) {
      minutesComboBoxOption.push({ key: minutes, text: minutes.toString() });
    }

    const strings: IDatePickerStrings = DayPickerStrings;
    strings.isOutOfBoundsErrorMessage = isOutOfBoundsErrorMessage;

    return (
      <div className={this._classNames.root}>
        <DatePicker
          strings={strings}
          allowTextInput={true}
          label={label ? label : formatMessage(messages.label)}
          placeholder={datePlaceholder ? datePlaceholder : formatMessage(messages.datePlaceholder)}
          minDate={minDateTime}
          maxDate={maxDateTime}
          value={this.props.value}
          onSelectDate={(val: Date) => {
            if (val) {
              const _date = new Date(
                val.getFullYear(),
                val.getMonth(),
                val.getDate(),
                this.state.hours,
                this.state.minutes,
                this.state.seconds,
                0
              );
              onDateTimeChanged(_date);
              this.setState({
                date: val.getDate(),
                month: val.getMonth(),
                year: val.getFullYear(),
              });
            }
          }}
          disabled={disabled}
          styles={this._classNames.subComponentStyles.datePicker}
        />
        <ComboBox
          selectedKey={hours}
          options={hourComboBoxOption}
          styles={styles.hourPicker}
          disabled={disabled}
          onChange={(_, option) => {
            if (option) {
              const _hours = parseInt(option.key.toString(), 10);

              if (year && month && date) {
                const _date = new Date(year, month, date, _hours, minutes, seconds, 0);
                onDateTimeChanged(_date);
              }
              this.setState({ hours: _hours });
            }
          }}
        />
        <span className={this._classNames.colonSeperator}>{':'}</span>
        <ComboBox
          selectedKey={minutes}
          options={minutesComboBoxOption}
          allowFreeform
          autoComplete="on"
          styles={styles.minutesPicker}
          onChange={(_, option, index, value) => {
            let _minutes;
            if (option) {
              _minutes = parseInt(option.key.toString(), 10);
            } else if (!option && value) {
              // since allowfreeform is true, here we are adding custom option
              _minutes = parseInt(value.toString(), 10);
              minutesComboBoxOption.push({ key: _minutes, text: value });
            }
            if (year && month && date) {
              // if date is already selected, then update the datetime
              const _date = new Date(year, month, date, hours, _minutes, seconds, 0);
              onDateTimeChanged(_date);
            }
            this.setState({ minutes: _minutes });
          }}
          errorMessage={minutes && minutes >= 60 ? formatMessage(messages.invalidMinuteErrorMessage) : undefined}
          disabled={disabled}
        />
        {allowSecondsField ? (
          <>
            <span className={this._classNames.colonSeperator}>{':'}</span>
            <TextField
              value={seconds ? seconds.toString() : '00'}
              className={this._classNames.secondsPicker}
              onChange={(_, text) => {
                if (text) {
                  const _seconds = parseInt(text, 10);

                  if (year && month && date) {
                    const _date = new Date(year, month, date, hours, minutes, _seconds, 0);
                    onDateTimeChanged(_date);
                  }
                  this.setState({ seconds: _seconds });
                }
              }}
              errorMessage={seconds && seconds >= 60 ? formatMessage(messages.invalidSecondsErrorMessage) : undefined}
              disabled={disabled}
            />
          </>
        ) : null}
      </div>
    );
  }
}
