import * as React from 'react';
import { useEffect } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Route, RouteComponentProps } from 'react-router-dom';
import { RoutePrefix } from '../../../Routes';
import { FormState } from '../../../components/Form';
import { injectIntlWithObserver } from '../../../utils';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import './@data/mutators';
import './@data/orchestrators';
import { CreateChannel } from './Create/CreateChannel';
import { ManagePage } from './Manage/ManagePage';

export const ChannelPage = injectIntlWithObserver(function ChannelPageComponent(props: RouteComponentProps & InjectedIntlProps) {
  useEffect(() => {
    onCurrentRouteChanged(RouteName.channel, props.intl.formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Route exact path={`${props.match.url}`} component={ManagePage} />
      <Route
        path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.channel]}/create`}
        render={(propsToRender) => <CreateChannel {...propsToRender} mode={FormState.Create} />}
      />
      <Route
        path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.channel]}/edit/:id`}
        render={(propsToRender) => <CreateChannel {...propsToRender} mode={FormState.Edit} />}
      />
    </React.Fragment>
  );
});
