import { BaseComponent, ICalloutProps, classNamesFunction } from '@fluentui/react';
import * as React from 'react';
import AppMessages from '../../../App.messages';
import { InfoButton } from '../../InfoButton';
import { IFormTitleProps, IFormTitleStyleProps, IFormTitleStyles } from './FormTitle.types';

const getClassNames = classNamesFunction<IFormTitleStyleProps, IFormTitleStyles>();

export class FormTitleBase extends BaseComponent<IFormTitleProps> {
  public render(): JSX.Element {
    const {
      articleQuery,
      id,
      styles,
      theme,
      title,
      calloutContent,
      calloutOnDismiss,
      iconButtonOnClick,
      noHelpPopup,
      intl,
      titleHeadingLevel,
    } = this.props;

    const { formatMessage } = intl;
    const classNames = getClassNames(styles, { theme: theme! });
    const calloutProps: ICalloutProps = {
      calloutWidth: 300,
    };

    return (
      <div className={classNames.root}>
        <div id={id} className={classNames.title} role="heading" aria-level={titleHeadingLevel ?? 1}>
          {title}
        </div>
        {!noHelpPopup && (
          <InfoButton
            ariaLabel={formatMessage(AppMessages.infoLabel, { label: title })}
            ariaDescription={formatMessage(AppMessages.infoDescription, { label: title })}
            iconTitle="Information"
            calloutContent={calloutContent}
            calloutProps={calloutProps}
            calloutOnDismiss={calloutOnDismiss}
            iconButtonOnClick={iconButtonOnClick}
            articleQuery={articleQuery}
          />
        )}
      </div>
    );
  }
}
