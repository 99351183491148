import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, getEntity, onLoadingChanged, onNotificationChanged, Publisher } from '../../../../@data';
import messages from '../../Report.messages';
import { onReportingEnabled, onSetPublisherReporting } from '../actions';

orchestrator(onSetPublisherReporting, async (msg) => {
  const { userContext } = getAppStore();
  const publisher = msg.publisher;

  if (publisher && publisher.id && userContext) {
    try {
      onLoadingChanged(true);
      const rawPublisher = await getEntity<Publisher>([publisher], userContext);
      if (rawPublisher.allowPaidSearchReportingDashboard) {
        onReportingEnabled(rawPublisher.allowPaidSearchReportingDashboard);
      } else {
        onReportingEnabled(false);
      }
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.failedToFetchPublisherReportDetails,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.reportInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
