import { orchestrator } from 'satcheljs';
import { AdSource } from '../../../../../@data';
import { onBlockAdsSubmitted, onMSANBlockAdsSubmitted, onSAMBlockAdsSubmitted } from '../actions';
import { getStore } from '../store/store';

orchestrator(onBlockAdsSubmitted, async (msg) => {
  const { active } = getStore();

  if (active.selectedAdSource === AdSource.Microsoft) {
    onMSANBlockAdsSubmitted();
  } else {
    onSAMBlockAdsSubmitted();
  }
});
