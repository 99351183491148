import { Callout, DefaultButton, PrimaryButton, getTheme } from '@fluentui/react';
import React, { useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import AppMessages from '../../App.messages';
import { getClassNames, getStyles } from './styles';

export interface ITutorialCalloutProps {
  steps: ITutorialCalloutSteps[];
  isOpen: boolean;
  onChangeState: () => void;
}

export interface ITutorialCalloutSteps {
  containerId: string;
  isContentReady: boolean;
  content: JSX.Element;
  contentWidth?: number;
  backgroundColor?: string;
}

const TutorialCallout = injectIntl((props: ITutorialCalloutProps & InjectedIntlProps) => {
  const [tutorialStep, setTutorialStep] = useState(0);
  const {
    isOpen,
    onChangeState,
    steps,
    intl: { formatMessage },
  } = props;
  const { containerId, content, contentWidth, backgroundColor } = steps[tutorialStep];
  const { footer, primaryButton, defaultButton } = getClassNames(getStyles, { theme: getTheme() });

  if (!isOpen) {
    return <></>;
  }

  const onUpdateTutorialStep = (step: number) => {
    if (steps[step].isContentReady === true) {
      setTutorialStep(step);
    }
  };

  const onDismissTutorialCallout = () => {
    onChangeState();
    setTutorialStep(0);
  };

  return (
    <Callout
      role="dialog"
      gapSpace={0}
      backgroundColor={backgroundColor ?? '#0078D4'}
      calloutWidth={contentWidth ?? 400}
      target={`#${containerId}`}
      shouldDismissOnWindowFocus={true}
      onDismiss={() => onDismissTutorialCallout()}
      setInitialFocus
      styles={getStyles({ theme: getTheme() }).hoverCard}
    >
      {content}
      {steps.length > 1 && (
        <div className={footer}>
          <span>
            {tutorialStep + 1} {formatMessage(AppMessages.of)} {steps.length}
          </span>
          <div>
            {tutorialStep > 0 && (
              <DefaultButton className={defaultButton} onClick={() => onUpdateTutorialStep(tutorialStep - 1)}>
                {formatMessage(AppMessages.previous)}
              </DefaultButton>
            )}
            {tutorialStep === steps.length - 1 && (
              <PrimaryButton className={primaryButton} onClick={() => onDismissTutorialCallout()}>
                {formatMessage(AppMessages.finish)}
              </PrimaryButton>
            )}
          </div>
          {tutorialStep < steps.length - 1 && (
            <PrimaryButton className={primaryButton} onClick={() => onUpdateTutorialStep(tutorialStep + 1)}>
              {formatMessage(AppMessages.next)}
            </PrimaryButton>
          )}
        </div>
      )}
    </Callout>
  );
});

export default TutorialCallout;
