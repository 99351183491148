import { AdUnit } from '../../../../../@data';
import { MediationReportingDataResponse, MediationReportingDataRow } from '../store/schema';

export function mapAdUnitName(data: MediationReportingDataResponse, adUnitsData: AdUnit[] = []): MediationReportingDataRow[] {
  return data.data.map((item) => {
    const matchingAdUnit = adUnitsData?.find((adUnit) => adUnit.id.toString() === item.PubCenterAdUnitId);
    return {
      ...item,
      AdUnitName: matchingAdUnit ? String(matchingAdUnit.name) : item.AdUnitName,
    };
  });
}
