import { action } from 'satcheljs';
import { ISearchResultInfo } from '../store/schema/IGlobalSearchResultPageState';

export const onSearchTriggered = action('onSearchTriggered', (resourceType?: string) => ({ resourceType }));

export const setData = action('setData', (results: Map<string, ISearchResultInfo>, resourceTypes: Map<string, number>) => ({
  results,
  resourceTypes
}));

export const setSearchParams = action('setSearchParams', (query: string, sourceRef?: string) => ({ query, sourceRef }));

export const showMoreLoading = action('showMoreLoading', (resourceType: string, value: boolean) => ({ resourceType, value }));

export const clearStore = action('clearStore');
