import { mergeStyleSets } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/style-utilities';
import { IPubcenterSpacing } from '../../../../../../../theme';
import { ICommonStyleProps } from '../../../../../../types';

type StyleProperties = 'titleStyle' | 'valueStyle';

type MetricItemStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): MetricItemStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    titleStyle: {
      flex: '1 1 auto',
      borderBottom: '1px dotted black',
      fontSize: spacing.size12,
      fontWeight: 400,
      margin: `0px 0px ${spacing.size4} 0px`,
    },
    valueStyle: {
      fontSize: spacing.size24,
      fontWeight: 600,
      margin: '0px',
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
