import { IAccountRoleStyleProps, IAccountRoleStyles } from './AccountRole.types';

export const getStyles = (props: IAccountRoleStyleProps): IAccountRoleStyles => {
  return {
    option: {
      display: 'flex',
      flexDirection: 'row'
    },
    optionTitle: {
      width: 220
    }
  };
};
