import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import './@data/mutators';
import './@data/orchestrators';
import appMessages from './App.messages';
import { getClassNames } from './App.styles';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import {
  DefaultRoute,
  LandingPageRoute,
  LogInPageRoute,
  NoAccessErrorPageRoute,
  SignUpPageRoute,
  SupportPageRoute,
  UnauthorizedInternalUserPageRoute,
  UserDoesNotExistErrorPageRoute,
  getGlobalRoutes,
} from './Routes';
import { IRoute } from './Routes.types';
import { TNC_HREF_PATH } from './constants/AppConstants';
import { Page } from './layout/PageLayout/PageLayout';
import { GlobalSearchResultRouter } from './pages/GlobalSearch/GlobalSearchResultRouter';
import { AboutUsPage } from './pages/about-us/AboutUsPage';
import AdTypePage from './pages/ad-management/Ads/AdTypePage';
import InventoryTypePage from './pages/ad-management/Inventory/InventoryTypePage';
import { UnauthorizedInternalUserPage, UserDoesNotExistErrorPage } from './pages/error-pages/InvalidUser';
import { NoAccessErrorPage } from './pages/error-pages/InvalidUser/NoAccessPage/NoAccesPage';
import { LandingPage } from './pages/landing-page/LandingPage';
import { LoginPage } from './pages/login/LoginPage';
import { LoginPageView } from './pages/login/components/LoginPageView';
import { AcceptInvitePage } from './pages/partner-management/User/AcceptInvite/AcceptInvite';
import { SignUpPage } from './pages/signup';
import { SupportPage } from './pages/support';
import { TermsNConditionsPage } from './pages/terms-and-conditions/TermsNConditionsPage';
import { handleSkipToMainContentClick } from './utils/DomUtils';
import { isLocationPubCenter } from './utils/UrlUtils';
import { getTythonAdsCreationPageRoute, getTythonInventoryCreationPageRoute } from './utils/routeUtils';
import { validatorFactory } from './utils/validation/ValidatorFactory';

export interface IAppProps extends RouteComponentProps, InjectedIntlProps {}

export const ApplicationRoute = withRouter(
  injectIntl(
    // NOTE: Use named classes for easier debugging. This name would appear in the React DevTools and error traces too.
    class AppRoute extends React.Component<IAppProps> {
      constructor(props: IAppProps) {
        super(props);
        validatorFactory(this.props.intl);
      }

      public render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const classNames = getClassNames();

        return (
          <>
            <DefaultButton
              onClick={handleSkipToMainContentClick}
              text={formatMessage(appMessages.skipToMainContent)}
              className={classNames.skipButton}
            />
            <Switch>
              {isLocationPubCenter() ? <Redirect exact path="/" to={DefaultRoute} /> : <Route exact path="/" component={AboutUsPage} />}
              <Route path={LogInPageRoute} component={LoginPage} />
              <Route path={'/loginV2'} component={LoginPageView} />
              <Route path={SignUpPageRoute} component={SignUpPage} />
              <Route path={LandingPageRoute} component={LandingPage} />
              <Route path={UserDoesNotExistErrorPageRoute} component={UserDoesNotExistErrorPage} />
              <Route path={NoAccessErrorPageRoute} component={NoAccessErrorPage} />
              <Route path={UnauthorizedInternalUserPageRoute} component={UnauthorizedInternalUserPage} />
              <Route path={TNC_HREF_PATH} component={TermsNConditionsPage} />
              <Page {...this.props}>
                <Switch>
                  <Route path="/acceptUserInvite/code=:invitationcode/locale=:invitationLocale" component={AcceptInvitePage} />
                  <Route path="/acceptUserInvite/code=:invitationcode" component={AcceptInvitePage} />
                  <AuthenticatedRoute
                    path={getTythonInventoryCreationPageRoute()}
                    key={getTythonInventoryCreationPageRoute()}
                    component={InventoryTypePage}
                    routeProps={this.props}
                  />
                  <AuthenticatedRoute
                    path={getTythonAdsCreationPageRoute()}
                    key={getTythonAdsCreationPageRoute()}
                    component={AdTypePage}
                    routeProps={this.props}
                  />
                  {getGlobalRoutes().map((route: IRoute) => (
                    <AuthenticatedRoute key={route.key} path={route.key} component={route.component} routeProps={this.props} />
                  ))}
                  <AuthenticatedRoute
                    key={SupportPageRoute}
                    path={SupportPageRoute}
                    component={SupportPage as React.ComponentType<{}>}
                    routeProps={this.props}
                  />
                  <AuthenticatedRoute key="/search" path="/search" component={GlobalSearchResultRouter} routeProps={this.props} />
                  <Redirect path="*" to={DefaultRoute} />
                </Switch>
              </Page>
            </Switch>
          </>
        );
      }
    }
  )
);
