import { IPubcenterPalette, IPubcenterSpacing } from '../../../theme';
import { IGlobalSearchHeaderStyleProps, IGlobalSearchHeaderStyles } from './GlobalSearchHeader.types';

export const getStyles = (props: IGlobalSearchHeaderStyleProps): IGlobalSearchHeaderStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const pallete = theme.palette as IPubcenterPalette;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      overflow: 'hidden'
    },
    wrapper: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit'
    },
    searchResultHeader: [
      'ms-fontWeight-semibold',
      {
        height: theme.spacing.l2,
        fontSize: theme.fonts.medium.fontSize,
        paddingLeft: theme.spacing.m,
        color: pallete.themePrimary,
        selectors: {
          ':hover': {
            cursor: 'pointer'
          }
        }
      }
    ],
    categorySeparator: {
      display: 'inline-block',
      height: spacing.size12,
      borderTop: '1px',
      borderTopColor: pallete.neutralLighter,
      width: '95%',
      marginTop: '0px'
    },
    entityContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: spacing.size48,
      selectors: {
        ':hover': {
          cursor: 'pointer'
        }
      }
    },
    iconContainer: {
      width: spacing.size64
    },
    entityIcon: {
      fontSize: '28px',
      color: theme.palette.neutralDark,
      alignItems: 'center',
      paddingLeft: theme.spacing.m,
      paddingRight: theme.spacing.m,
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    entityInfo: [
      'ms-fontSize-Body14',
      {
        paddingTop: '13px',
        paddingBottom: '16px',
        color: pallete.neutralPrimary,
        height: '19px',
        lineHeight: '19px',
        alignItems: 'center'
      }
    ],
    textLinkFooter: [
      'ms-fontSize-Body14',
      {
        display: 'flex',
        alignItems: 'center',
        height: spacing.size48,
        justifyContent: 'center',
        color: pallete.themePrimary,
        selectors: {
          ':hover': {
            cursor: 'pointer'
          }
        }
      }
    ],
    footer: [
      'ms-fontSize-Body14',
      {
        display: 'flex',
        justifyContent: 'center',
        color: pallete.neutralPrimary
      }
    ],
  };
};
