import { createStore } from 'satcheljs';
import { FeedbackType } from './schema/enums/FeedbackType';
import { IFeedbackPanelViewState } from './schema/IFeedbackPanelViewState';

export const getFeedbackPanelStore = createStore<IFeedbackPanelViewState>('FeedbackPanelState', {
  active: {
    type: FeedbackType.Appreciation,
  },
  errors: {},
  isPanelOpen: false,
  isContactMeChecked: false,
});
