import { ITheme, classNamesFunction, css, getTheme } from '@fluentui/react';
import React, { useEffect, useRef } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { useI18n } from '../../../i18n/useI18n';
import { Publisher, UserContext, getEntityAccessPermissions, isTythonSelfServeUser } from '../../@data';
import { UserRoleEntity, completeAccess, hasReadAccess } from '../../@data/services/UserRoleService';
import { PublisherSettings } from '../../@data/store/schema/userrolemodels/PublisherSettings';
import { SupportPageRoute } from '../../Routes';
import { useCMUIHelpExperience } from '../../hooks';
import SupportBotton from '../../hooks/useCMUIHelpExperience/supportButton.svg';
import { onFetchPublisherPreferences } from '../../pages/ad-management/Account/Settings/@data/actions';
import { getStore } from '../../pages/ad-management/Account/Settings/@data/store/store';
import { onInitializeSystemPreferences } from '../../pages/ad-management/Home/@data/actions';
import supportMessages from '../../pages/ad-management/Home/components/ResourcesSection/ResourcesSection.messages';
import { injectIntlAndObserver } from '../../utils';
import { IInternationalizationStyles, getStyles } from './InternationalizationLayout.styles';

export interface IInternationalizationLayoutProps extends InjectedIntlProps {
  children?: React.ReactNode;
  publisher?: Publisher;
  userContext?: UserContext;
}

const getClassNames = classNamesFunction<{ theme: ITheme }, IInternationalizationStyles>();

export const InternationalizationLayout = injectIntlAndObserver(({ publisher, userContext, intl }: IInternationalizationLayoutProps) => {
  const accessPermissions = getEntityAccessPermissions(UserRoleEntity.PublisherSettings) as PublisherSettings | null;
  const hasPublisherPreferencesAccess = hasReadAccess(accessPermissions ? accessPermissions.publisherPreferences : completeAccess);
  const systemPreferences = getStore().active.systemPreferences;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });
  const handledPreference = useRef<boolean>(false);
  const handledSystemPreference = useRef<boolean>(false);
  const { init: initCMUIExperience, initCMUIHelpButtonEventListener, removeCMUIHelpButtonEventListener } = useCMUIHelpExperience();
  const classNames = getClassNames(getStyles, { theme: getTheme() });

  useEffect(() => {
    if (hasPublisherPreferencesAccess && publisher && userContext && !handledPreference.current && isTythonSelfServeUser()) {
      onFetchPublisherPreferences();
      handledPreference.current = true;
    }
  }, [publisher, userContext, hasPublisherPreferencesAccess]);

  useEffect(() => {
    if (publisher && userContext && !handledSystemPreference.current && isTythonSelfServeUser()) {
      onInitializeSystemPreferences();
      handledSystemPreference.current = true;
    }
  }, [publisher, userContext]);

  useEffect(() => {
    initCMUIExperience(constructSupportButton(), locale);
    initCMUIHelpButtonEventListener();
    return () => {
      removeCMUIHelpButtonEventListener();
    };
    // eslint-disable-next-line
  }, [locale]);

  const constructSupportButton = () => {
    return `
      <div class="${css(classNames.supportButtonWrapper)}">
        <a href="${SupportPageRoute}" target="_blank">
          <button class="${css(classNames.supportButton)}">
            <img src="${SupportBotton}" alt="${intl.formatMessage(supportMessages.assistanceDescription)}" /> ${intl.formatMessage(
      supportMessages.assistanceDescription
    )}
          </button>
        </a>
      </div>
    `;
  };

  return <></>;
});
