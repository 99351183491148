import { Model } from '../../../../../../../@data';
import { ClarityStatus } from '../../../../../../../@data/store/schema/enums/ClarityStatus';
import { isTythonSelfServeUser } from '../../../../../../../@data/utils/checkUserType';
import { CommandBarActions, Direction, GridSelectionMode, IGridPageCommandBarItemProps } from '../../../../../../../layout/GridPageV2';
import { onClarityChanged } from '../../../../Create/__data__/mutatorActions';
import { setClarityPanelVisibility, setSelectedPropertyId } from '../../../__data__/mutatorActions';
import messages from '../messages';
import { IClarityParams } from '../types';

export const getClarityCommandBarItems = (selectedItems: () => Model[], formatMessage): IGridPageCommandBarItemProps[] => {
  if (isTythonSelfServeUser()) {
    return [];
  }

  const clarityItem = {
    key: CommandBarActions.clarity,
    name: formatMessage(messages.sidePanelLabel),
    iconProps: {
      iconName: 'AddLink',
    },
    ariaLabel: formatMessage(messages.statusCmdBtnAriaLabel),
    placement: { direction: Direction.after },
    onClick: () => {
      const selectedItem = selectedItems();
      /* tslint:disable:no-string-literal */
      if (selectedItem && selectedItem[0]['id']) {
        const status: ClarityStatus = ClarityStatus[selectedItem[0]['clarityStatus']];
        const clarityId: string = selectedItem[0]['clarityProjectId'];

        const clarityPanelDisplayParams: IClarityParams = {
          clarityLinkingEnabled: status === ClarityStatus.NotConnected ? false : true,
          clarityStatus: status || ClarityStatus.Approved,
          clarityId: clarityId ? clarityId : '',
          originalClarityId: clarityId ? clarityId : '',
          clarityEdit: status === ClarityStatus.Approved || status === undefined ? false : true,
        };
        setSelectedPropertyId(selectedItem[0]['id'].toString());
        onClarityChanged(clarityPanelDisplayParams);
        setClarityPanelVisibility(true);
      }
    },
    selectionModes: [GridSelectionMode.Single],
    // disabled: !hasCreateAccess(params.accessPermission),
  };

  return [clarityItem];
};
