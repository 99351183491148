import React from 'react';
import { IDataMetric } from '../../../types';
import { HighCharts, HighChartsMap, HighChartsWrapper } from '../HighChartsLibrary';

export interface IHighChartProps extends IDataMetric {
  options?: HighCharts.Options;
  chartComponentRef: React.RefObject<HighChartsWrapper.RefObject>;
}
export const CustomChart = ({ options, dataMetric, chartComponentRef }: IHighChartProps) => {
  const finalOptions: HighCharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      scrollablePlotArea: {
        minWidth: 700,
      },
      reflow: true,
      events: {
        render: function () {
          this.reflow();
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: true,
    },
    ...options,
  };
  return <HighChartsWrapper ref={chartComponentRef} highcharts={HighCharts} options={finalOptions} constructorType={'chart'} />;
};
export const CustomMapChart = ({ options, dataMetric, chartComponentRef }: IHighChartProps) => {
  const finalOptions: HighCharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      scrollablePlotArea: {
        minWidth: 700,
      },
      reflow: true,
      events: {
        render: function () {
          this.reflow();
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: true,
    },
    ...options,
  };
  return <HighChartsWrapper ref={chartComponentRef} highcharts={HighChartsMap} options={finalOptions} constructorType={'mapChart'} />;
};
