import { orchestrator } from 'satcheljs';
import { getAppStore, UserRole, UserType } from '../../../../@data';
import { setUserContext } from '../../../../@data/services/UserRoleService';
import { IUserRoleScope } from '../../../../@data/store/schema/interfaces/IUserRoleData';
import { onTythonPublisherSignup } from '../actions';

orchestrator(onTythonPublisherSignup, ({ publisherId, userId }) => {
  const { userContext } = getAppStore();

  if (userContext) {
    const scope: IUserRoleScope = {
      id: userId,
      roleIds: [41], // User who signed up the publisher will always have super admin role
      parentCustomer: publisherId,
      accessiblePublishers: [{ id: publisherId, userRoleType: UserRole.SuperAdmin }],
    };

    setUserContext(userContext, scope, UserType.TythonSelfServe);
  }
});
