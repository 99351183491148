import { AuditLog, ServingStatusLabel } from '../../../../@data';
import adManagementMessages from '../../AdManagement.messages';
import messages from '../ActionDetails.messages';
import { getAddedRemovedCount } from './convertAttributeValues';
import { ActionDetail } from './getActionDetails';

export function getActionDetailsForProperty(actionDetail: ActionDetail, auditLog: AuditLog, formatMessage) {
  actionDetail.title = `${formatMessage(messages.updated)} ${auditLog.entityName}`;
  const adblockMessages: string[] = [];

  if (auditLog.changes) {
    actionDetail.changes = [];
    actionDetail.changes = auditLog.changes.map((change) => {
      if (change.attributeChanged === 'ServingStatus') {
        if (change.newValue && !change.oldValue) {
          return `${formatMessage(messages.ServingStatus)} ${formatMessage(adManagementMessages[ServingStatusLabel[change.newValue]])}`;
        } else if (change.newValue && change.oldValue) {
          return formatMessage(messages.updatedMessageFormat, {
            attributeChanged: formatMessage(messages.ServingStatus),
            oldValue: change.oldValue,
            newValue: change.newValue,
          });
        }
      }
      if (change.attributeChanged === 'ClarityStatus' && change.newValue?.includes('Approved')) {
        return formatMessage(messages.ClarityStatus);
      }
      if (change.attributeChanged === 'ClarityProjectID' && change.newValue) {
        return formatMessage(messages.ClarityProjectID, { id: change.newValue });
      }
      if (change.attributeChanged === 'AdBlockStatus' && change.newValue) {
        return change.newValue.includes('true')
          ? formatMessage(messages.AdBlockStatusEnabled)
          : formatMessage(messages.AdBlockStatusDisabled);
      }
      if (change.attributeChanged === 'PropertyName') {
        if (change.newValue && change.oldValue) {
          return formatMessage(messages.updatedMessageFormat, {
            attributeChanged: formatMessage(messages.PropertyName),
            oldValue: change.oldValue,
            newValue: change.newValue,
          });
        }
      }

      if (change.log) {
        actionDetail.title = formatMessage(messages.AdblocksUpdated);
        const [addCount, removeCount] = getAddedRemovedCount(change.log);
        if (!isNaN(addCount) && addCount > 0) {
          adblockMessages.push(
            `${addCount} ${formatMessage(addCount > 1 ? messages.AdBlockCategoriesPlural : messages.AdBlockCategories)} ${formatMessage(
              messages.added
            )}`
          );
        }
        if (!isNaN(removeCount) && removeCount > 0) {
          adblockMessages.push(
            `${removeCount} ${formatMessage(
              removeCount > 1 ? messages.AdBlockCategoriesPlural : messages.AdBlockCategories
            )} ${formatMessage(messages.removed)}`
          );
        }
      }

      return '';
    });
    actionDetail.changes.push(...adblockMessages);
  }
}
