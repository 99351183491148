import React, { RefObject, useState } from 'react';
import appMessages from '../../../../App.messages';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../utils';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { ICustomCalenderDateRange } from '../../components/CustomCalender/CustomCalender.types';
import { MEDIATION_DEFAULT_COLUMNS, MEDIATION_DEFAULT_METRICS } from '../../constants/common';
import { useMediationReportQuery } from '../../hooks/useReportQuery';
import { IReportsMetadata, MediationReportingDataRow } from '../@data/store/schema';
import { ReportCard } from '../ReportCard/ReportCard';
import { constructMediationRequestBody } from '../ReportCard/ReportCard.helpers';
import { ChartGrain, IChartButton, ReportCardType } from '../ReportCard/ReportCard.types';
import { IActiveFilter } from '../ReportDetailsPage/ActionBar/ActionBar';

const CHART_DATA_METRIC_ID = 'mediation-chart';

export interface IMediationReportCardProps extends IRouterWithIntl {
  dateRange: ICustomCalenderDateRange;
  filters: IActiveFilter[];
  allowedChartTypes: IChartButton[];
  showDetailsReportLink: boolean;
  enableHideChartsButton?: boolean;
  showMetricsMarkers?: boolean;
  isDetailedView: boolean;
  onChartGrainChanged?: (grain: ChartGrain) => void;
  onSelectedMetricChanged?: (metric: string) => void;
  onBreakdownChanged?: (breakdown: string[]) => void;
  reportDetailedData?: MediationReportingDataRow[];
  reportCardRef: RefObject<HTMLDivElement>;
  reportMetadata?: IReportsMetadata;
}

export const MediationReportCard = injectIntlAndObserver<IMediationReportCardProps>(
  ({
    dateRange,
    filters,
    allowedChartTypes,
    enableHideChartsButton,
    showDetailsReportLink,
    showMetricsMarkers = false,
    isDetailedView,
    onChartGrainChanged,
    onSelectedMetricChanged,
    onBreakdownChanged,
    reportDetailedData,
    reportCardRef,
    intl: { formatMessage },
    reportMetadata,
    history,
    location,
    match,
  }: IMediationReportCardProps) => {
    const mainDimension = TythonColumnsEnum.AdUnitName;
    const [currentChartGrain, setCurrentChartGrain] = useState(ChartGrain.DAILY);
    const metrics = reportMetadata?.metrics.map((metric) => metric.key);
    const dimensions = [mainDimension];
    if (currentChartGrain !== ChartGrain.NONE) {
      dimensions.push(TythonColumnsEnum.Date);
    }
    const { data: reportData } = useMediationReportQuery(
      constructMediationRequestBody(dateRange, currentChartGrain, isDetailedView ? filters : [], dimensions, metrics),
      !!reportMetadata
    );

    return (
      <ReportCard
        title={formatMessage(appMessages.mediationCardTitle)}
        reportData={reportData}
        reportDetailedData={reportDetailedData}
        allowedChartTypes={allowedChartTypes}
        dataMetrics={{ chartDataMetrics: CHART_DATA_METRIC_ID }}
        mainDimension={mainDimension}
        type={ReportCardType.MEDIATION}
        enableHideChartsButton={enableHideChartsButton}
        showDetailsReportLink={showDetailsReportLink}
        dateRange={dateRange}
        showMetricsMarkers={showMetricsMarkers}
        isDetailedView={isDetailedView}
        filters={filters}
        history={history}
        location={location}
        match={match}
        defaultColumns={MEDIATION_DEFAULT_COLUMNS}
        defaultMetrics={MEDIATION_DEFAULT_METRICS}
        hasMinimumBreakdown={true}
        reportMetadata={reportMetadata}
        allowedChartGrains={reportMetadata?.grains || []}
        dateDimension={TythonColumnsEnum.Date}
        onChartGrainChanged={(updatedGrain) => {
          onChartGrainChanged && onChartGrainChanged(updatedGrain);
          setCurrentChartGrain(updatedGrain);
        }}
        onBreakDownChanged={onBreakdownChanged}
        onSelectedMetricChanged={onSelectedMetricChanged}
        reportCardRef={reportCardRef}
      />
    );
  }
);
