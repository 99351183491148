import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { getEntityAccessPermissions } from '../../../../../@data';
import { UserRoleEntity, completeAccess, hasReadAccess } from '../../../../../@data/services/UserRoleService';
import { PublisherProfileTabs } from '../../../../../@data/store/schema/userrolemodels/PublisherProfileTabs';
import { injectIntlAndObserver } from '../../../../../utils';
import { getStore } from '../../@data/store/store';
import { getClassNames } from './PerformanceSection.styles';
import BalanceCard from './components/BalanceCard';
import PerformanceCard from './components/PerformanceCard';
import messages from './components/messages';

const PerformanceSection = injectIntlAndObserver<InjectedIntlProps>(({ intl: { formatMessage } }) => {
  const { isSetupDone } = getStore();
  const { titleStyle, sectionContainerStyle } = getClassNames();
  const publisherProfileAccess = getEntityAccessPermissions(UserRoleEntity.PublisherProfileTabs) as PublisherProfileTabs | null;
  const hasPaymentAccess = hasReadAccess(publisherProfileAccess === null ? completeAccess : publisherProfileAccess.payments);
  return (
    <>
      {isSetupDone ? (
        <div>
          <h2 className={titleStyle}>{formatMessage(messages.performanceSectionTitle)}</h2>
          <div className={sectionContainerStyle}>
            {hasPaymentAccess && <BalanceCard />}
            <PerformanceCard />
          </div>
        </div>
      ) : null}
    </>
  );
});

export default PerformanceSection;
