import { MediationReportingDataRow, TythonReportingDataRow } from '../../../../../../@data/store/schema/models/ReportingDataResponse';
import { ChartGrain } from '../../../ReportCard/ReportCard.types';

export interface IReportPageReportData<DataType> {
  isDataLoaded?: boolean;
  isDetailedDataLoaded?: boolean;
  data?: DataType[];
  detailedData?: DataType[];
}
export interface IReportsMetadata {
  dimensions: IMetadataElement[];
  metrics: IMetadataElement[];
  grains: IMetadataElement[];
  isMetadataLoaded: boolean;
}
export interface IMetadataElement {
  key: string;
  displayName: string;
  filterKey: string;
  incompatibleFields: string[];
}
export interface IGrainedReportData<DataType> {
  [ChartGrain.NONE]: IReportPageReportData<DataType>;
  [ChartGrain.HOURLY]: IReportPageReportData<DataType>;
  [ChartGrain.DAILY]: IReportPageReportData<DataType>;
  [ChartGrain.WEEKLY]: IReportPageReportData<DataType>;
  [ChartGrain.MONTHLY]: IReportPageReportData<DataType>;
  [ChartGrain.YEARLY]: IReportPageReportData<DataType>;
  [ChartGrain.MONTH_AND_YEAR]: IReportPageReportData<DataType>;
}

export type ColumnMembers = Record<string, IColumnMembersData>;
export interface IColumnMembersData {
  isDataLoaded?: boolean;
  data?: IColumnMemberDetails[];
}
export interface IColumnMemberDetails {
  Id: string;
  Value: string;
  DisplayName: string;
}

export interface IReportPageData {
  reportType: string;
  metadata: IReportsMetadata;
  mediationMetadata: IReportsMetadata;
  columnMembers: ColumnMembers;
  summary: IGrainedReportData<TythonReportingDataRow>;
  mediationSummary: IGrainedReportData<MediationReportingDataRow>;
  sites: IGrainedReportData<TythonReportingDataRow>;
  country: IGrainedReportData<TythonReportingDataRow>;
  adUnits: IGrainedReportData<TythonReportingDataRow>;
  mediation: IGrainedReportData<MediationReportingDataRow>;
  performance: IGrainedReportData<TythonReportingDataRow>;
}

export enum ReportDataSource {
  MSXTython = 'MSXTython',
  TythonV2 = 'TythonV2',
}
