import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../../../theme';
import { flexWithAlignCenter, flexWithJustifyBetween } from '../../../../../../utils/GlobalStyleUtils';

export interface IBoostTemplateUploadStyleProps {
  theme: ITheme;
  isError?: boolean;
}

type StyleProperties =
  | 'fileUploadContainerStyle'
  | 'fileUploadLabelStyle'
  | 'fileUploadDescriptionStyle'
  | 'fileInputStyle'
  | 'uploadStatusStyle'
  | 'errorStyle'
  | 'progressContainerStyle'
  | 'actionContainerStyle'
  | 'fileUploadedStyle'
  | 'csvTemplateLinkStyle';
type BoostTemplateUploadStyles = Record<StyleProperties, string>;

export const getStyles = (props: IBoostTemplateUploadStyleProps): BoostTemplateUploadStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette, fonts } = theme;

  return mergeStyleSets({
    fileUploadContainerStyle: {
      ...flexWithAlignCenter,
      justifyContent: 'center',
      flexDirection: 'column',
      background: palette.neutralLighterAlt,
      border: `1px dashed ${props.isError ? palette.red : palette.neutralQuaternaryAlt}`,
      padding: spacing.size24,
      marginBottom: spacing.size2,
      borderRadius: spacing.size4,
    },
    fileInputStyle: {
      display: 'none',
    },
    fileUploadLabelStyle: {
      color: palette.highContrastPrimary,
      cursor: 'pointer',
    },
    csvTemplateLinkStyle: {
      color: palette.highContrastLink,
    },
    fileUploadDescriptionStyle: {
      ...fonts.size12,
      marginTop: spacing.size4,
      opacity: '0.8',
    },
    fileUploadedStyle: {
      ...flexWithJustifyBetween,
      width: '100%',
    },
    uploadStatusStyle: {
      ...fonts.size12,
      opacity: 0.8,
      marginTop: spacing.size8,
    },
    actionContainerStyle: {
      display: 'flex',
      gap: spacing.size12,
    },
    errorStyle: {
      color: palette.red,
    },
    progressContainerStyle: {
      flexGrow: 1,
      marginRight: spacing.size12,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): BoostTemplateUploadStyles {
  return getStyles({ theme: getTheme(), ...props });
}
