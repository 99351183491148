import { defineMessages } from 'react-intl';

export default defineMessages({
  appInventoryTableTitle: {
    id: 'inventory.manage.app.title',
    defaultMessage: 'Apps',
  },
  appsCannotServeMessage: {
    id: 'inventory.manage.app.appsCannotServeMessage',
    defaultMessage: 'Apps cannot serve ads until your profile is submitted and approved {learn}',
  },
  learnMore: {
    id: 'app.learnMore',
    defaultMessage: 'Learn more',
  },
  appNameColumnLabel: {
    id: 'inventory.app.appNameColumnLabel',
    defaultMessage: 'App Name',
  },
  appIdColumnLabel: {
    id: 'inventory.app.appIdColumnLabel',
    defaultMessage: 'App ID',
  },
  appUrlColumnLabel: {
    id: 'inventory.app.appUrlColumnLabel',
    defaultMessage: 'App URL',
  },
  appAdUnitPlatformColumnLabel: {
    id: 'inventory.app.appAdUnitPlatformColumnLabel',
    defaultMessage: 'Ad unit platform',
  },
  appAdUnitStatusColumnLabel: {
    id: 'inventory.app.appAdUnitStatusColumnLabel',
    defaultMessage: 'Status',
  },
  appAdUnitActionsColumnLabel: {
    id: 'inventory.app.appAdUnitActionsColumnLabel',
    defaultMessage: 'Actions',
  },
  appAdunitGridCreateButtonLabel: {
    id: 'inventory.app.adunitGridCreateButtonLabel',
    defaultMessage: 'Create An App',
  },
  appInventoryIssueColumnLabel: {
    id: 'inventory.app.appInventoryIssueColumnLabel',
    defaultMessage: 'Issues',
  },
  appInventoryZeroResultTitle: {
    id: 'inventory.app.appInventoryZeroResultTitle',
    defaultMessage: 'No inventory found by apps',
  },
  appInventoryZeroResultDescription: {
    id: 'inventory.app.appInventoryZeroResultDescription',
    defaultMessage: 'Create an app to start serving ads',
  },
  appInventoryZeroResultCallToAction: {
    id: 'inventory.app.appInventoryZeroResultCallToAction',
    defaultMessage: 'Create an App',
  },
  appInventoryDeletePopupTitle: {
    id: 'inventory.app.appInventoryDeletePopupTitle',
    defaultMessage: 'Are you sure you want to delete this app?',
  },
  appInventoryDeletePopupDescription: {
    id: 'inventory.app.appInventoryDeletePopupDescription',
    defaultMessage: 'By deleting this app, you’ll unlink the app ad units associated with it',
  },
});
