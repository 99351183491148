import { orchestrator } from 'satcheljs';
import { getAppStore, onLoadingChanged } from '../../../../../@data';
import {
  onAdCreativeBlockPanelChanged,
  onBlockAdsImagesChanged,
  onBlockImageAdsChanged,
  onSAMBlockSummaryViewImagesClicked,
} from '../actions';
import { getSAMBlockImageData } from '../services/AdBlockService';
import { BlockItemType } from '../store/schema/models';

orchestrator(onSAMBlockSummaryViewImagesClicked, async (msg) => {
  if (msg.item.creativeAdId) {
    onLoadingChanged(true);
    const images = await getSAMBlockImageData(msg.item.creativeAdId, getAppStore().publisher!.id);
    onBlockImageAdsChanged([
      { apiPath: msg.item.apiPath, className: msg.item.className, blockedImages: [], uniqueCreativeAdId: msg.item.creativeAdId },
    ]);
    onBlockAdsImagesChanged(images, 0, false, true);
    onLoadingChanged(false);
    onAdCreativeBlockPanelChanged(true, BlockItemType.Images);
  }
});
