import { createStore } from 'satcheljs';
import { DEFAULT_PUBLISHER_CACHE_KEY, EN_LOCALE_KEY, LOCAL_STORAGE_LOCALE, SESSION_NOTIFICATIONS_UI } from '../../constants/AppConstants';
import { restoreTypeWithObject } from '../../utils/ObjectUtils';
import { Account, CustomPanelType, PopupType, Publisher, UserContext } from './schema';
import { IAppState } from './schema/IAppState';

export const StoragePublisherKey = 'app.publisher';
export const StorageAccountKey = 'app.account';
export const StorageTokenKey = 'token';
export const StorageUserContextKey = 'app.userContext';
export const StorageIsUserInviteFlowKey = 'app.isUserInviteFlow';
export const StorageUserInvitationCodeFlowKey = 'app.UserInvitationCode';
const storedNotifications = sessionStorage.getItem(SESSION_NOTIFICATIONS_UI);

export const getAppStore = createStore<IAppState>('AppViewState', {
  account: restoreTypeWithObject(new Account(), localStorage.getItem(StorageAccountKey)) || undefined,
  loading: false,
  waiting: false,
  saving: false,
  openedPopup: PopupType.None,
  openedPanel: CustomPanelType.None,
  isFirstTimeUser: false,
  isUserSuspended: false,
  menuCollapsed: false,
  isInviteUserFlow: localStorage.getItem(StorageIsUserInviteFlowKey) === 'true' ? true : false,
  userInvitationCode: localStorage.getItem(StorageUserInvitationCodeFlowKey) || undefined,
  notifications: [],
  toastNotifications: [],
  notificationPanelCollapsed: false,
  panelNotifications: storedNotifications ? JSON.parse(storedNotifications) : [],
  publisher: restoreTypeWithObject(new Publisher(), localStorage.getItem(StoragePublisherKey)) || undefined,
  userContext: restoreTypeWithObject(new UserContext(), localStorage.getItem(StorageUserContextKey)) || undefined,
  helpInfo: '',
  entityGridColumns: [],
  // Removing default LayoutMode setting as it increases the loading time for pages with other layout modes,
  // Since API call is made to fetch publisher details and accounts list for the account picker
  // layout: LayoutMode.Default,
  defaultPublisher: Number(localStorage.getItem(DEFAULT_PUBLISHER_CACHE_KEY)) || 0,
  accessiblePublishers: [],
  deleteAdUnitTemplateOnSubmit: false,
  locale: localStorage.getItem(LOCAL_STORAGE_LOCALE) ?? EN_LOCALE_KEY,
});
