import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  getAppStore,
  getEntity,
  onNavigateBack,
  onNotificationChanged,
  onSavingChanged,
  patchEntity,
  postEntity,
  Publisher,
  PublisherTier,
  UserInvitation,
  UserRoleEntityType,
  UserRoleUpdate,
} from '../../../../../@data';
import { UserInvitationType } from '../../../../../@data/store/schema/enums/UserInvitationType';
import { FormState } from '../../../../../components/Form';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { validateSchema } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/service/PartnerManagementService';
import { onFormCleared, onFormIdChanged, onFormSubmitted, onUserErrorChanged } from '../actions';
import { getDistinctUserRolesFrom, getUserRoleDetails, isDeepUserRolesEqual } from '../services/UserService';
import { userRoleErrorModel } from '../store/schema/UserErrorModel';
import { userInvitationErrorModel } from '../store/schema/UserInvitationErrorModel';
import { getStore } from '../store/store';

orchestrator(onFormSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { active, raw } = getStore();
  let notification: INotificationBarItem = {};
  if (publisher && raw) {
    try {
      if (msg.mode === FormState.Edit) {
        try {
          await validateSchema(userRoleErrorModel(), active);
          onUserErrorChanged();
        } catch (e) {
          onUserErrorChanged(e);
          return;
        }
        onSavingChanged(true);

        let entity: UserRoleUpdate | undefined;
        entity = new UserRoleUpdate();

        entity.eTag = raw.userRoleDetailsETag;
        const existingUserRoles = raw.userRoleDetails;
        const editedUserRoles = getUserRoleDetails(active, publisher.id!);

        if (existingUserRoles && !isDeepUserRolesEqual(existingUserRoles, editedUserRoles)) {
          entity.addedRoles = [];
          entity.removedRoles = [];
          // there will always be only one customer level role
          const newCustomerLevelRole = editedUserRoles.filter((e) => e.entityType === UserRoleEntityType.Customer);

          // there could be many accountLevelRoles
          const newAccountLevelRoles = editedUserRoles.filter((e) => e.entityType === UserRoleEntityType.Account);

          // there existed some role earlier : will always be the case but sanity checking
          if (existingUserRoles && existingUserRoles.length > 0) {
            const existingCustomerRole = existingUserRoles.filter((e) => e.entityType === UserRoleEntityType.Customer);

            // if new customer role is same as than current role
            // 1. no need of adding customer role
            // 2. we need to see which account role is missing in old from new : add
            // 3. we need to see which account role is missing in new from old: remove
            // else
            // 1. remove old customer
            // 2. add new customer
            // 3. remove all old account level (since customer level is changed now)
            // 4. add all new account
            if (isDeepUserRolesEqual(newCustomerLevelRole, existingCustomerRole)) {
              const existingAccountRoles = existingUserRoles.filter((e) => e.entityType === UserRoleEntityType.Account);
              entity.removedRoles = getDistinctUserRolesFrom(existingAccountRoles, newAccountLevelRoles);
              entity.addedRoles = getDistinctUserRolesFrom(newAccountLevelRoles, existingAccountRoles);
            } else {
              entity.removedRoles = existingUserRoles;
              entity.addedRoles = editedUserRoles;
            }
          } else {
            entity.addedRoles = editedUserRoles;
          }

          entity = await patchEntity([publisher, raw], UserRoleUpdate, entity, userContext);
          if (entity && entity.id) {
            onFormIdChanged(entity.id);
          }

          notification = {
            text: `Edited user role for user ${raw.id} successfully`,
            messageBarType: MessageBarType.success,
          };
        }
      } else {
        try {
          await validateSchema(userInvitationErrorModel(), active);

          onUserErrorChanged();
        } catch (e) {
          onUserErrorChanged(e);
          onNotificationChanged({
            text: `User Invitation - You have ${Object.keys(e).length} errors in the form, please fix them to continue.`,
            messageBarType: MessageBarType.severeWarning,
          });
          return;
        }
        onSavingChanged(true);

        let entity: UserInvitation;
        entity = new UserInvitation();
        entity.firstName = active.firstName;
        entity.lastName = active.lastName;
        entity.email = active.emailAddress;
        entity.entityRoles = [];
        entity.apiPath = undefined;
        entity.lcid = active.localeId;

        const publisherDetails = await getEntity<Publisher>([publisher], userContext);
        entity.userInvitationType = UserInvitationType.Default;
        if (
          publisherDetails.publisherTier === PublisherTier.SelfServe3rdParty ||
          publisherDetails.publisherTier === PublisherTier.SelfServeStandard
        ) {
          entity.userInvitationType = UserInvitationType.PubCenterSelfServeUser;
        }

        entity.entityRoles = getUserRoleDetails(active, publisher.id!);
        entity = await postEntity([publisher], UserInvitation, entity, userContext);

        if (entity && entity.id) {
          onFormIdChanged(entity.id);
        }

        notification = {
          text: `Invite new user successfully!`,
          messageBarType: MessageBarType.success,
        };
      }
      const returnUrl = createReturnUrl(RouteName.user, String(publisher.id));
      onNavigateBack(msg.routeHistory, returnUrl);
      onFormCleared();
    } catch (e) {
      notification = {
        text: `Submitting the user form "${FormState[msg.mode]}" caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
    } finally {
      onSavingChanged(false);
    }
  } else {
    notification = {
      text: `User form "${FormState[msg.mode]}" is invalid id ${active.id}`,
      messageBarType: MessageBarType.warning,
    };
  }
  onNotificationChanged(notification);
});
