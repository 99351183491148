import { getTheme } from '@fluentui/react';
import { IChartProps } from '@fluentui/react-charting';
import React from 'react';
import { IPubcenterPalette, IPubcenterTheme } from '../../../../../../../theme';
import { PaymentsPageBalance } from '../../../../../../@data';
import { roundNumber } from '../../../../../../utils';

export function mapToBarChartData(data?: PaymentsPageBalance): IChartProps[] {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const palette = theme.palette as IPubcenterPalette;

  const chartData: IChartProps[] = [
    {
      chartData: [
        {
          horizontalBarChartdata: {
            x: data ? (roundNumber(data.currBalance, 2) === 0 ? 0.1 : roundNumber(data.currBalance, 2)) : 0,
            y: data ? data.threshold : 0,
          },
          color: data && roundNumber(data.currBalance, 2) === 0 ? palette.white : palette.blue,
          yAxisCalloutData: `${data ? data.currBalance : 0}$`,
        },
      ],
    },
  ];
  return chartData;
}

export function getBarChartCustomData(payout: string) {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const palette = theme.palette as IPubcenterPalette;
  return (props: IChartProps) => {
    return (
      <div
        style={{
          display: 'flex',
          height: '30px',
          width: '63px',
          background: palette['NeutralBackground3.Rest'],
          marginBottom: '5px',
          justifyContent: 'center',
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 90% 80%, 0% 80%)',
          borderRadius: '5px',
        }}
      >
        <span style={{ fontSize: '14px', fontWeight: 400 }}>{payout}</span>
      </div>
    );
  };
}
