import React, { useEffect } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { EntityType, LayoutMode, getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged, onLayoutChanged } from '../../../../@data';
import { FormTitle } from '../../../../components/Form';
import ProfilePanelMessages from '../../../../layout/PageLayout/header-panels/ProfilePanel/ProfilePanel.messages';
import { injectRouterAndIntlWithObserver } from '../../../../utils';
import { RouteName } from '../../@data';
import { onCurrentRouteChanged } from '../../@data/actions';
import { onFetchUserPreferences } from './@data/actions/onSettingsPageChanged';
import { setEntityType } from './@data/mutatorActions/settingsPageChanged';
import messages from './SettingsPage.messages';
import { getClassNames } from './SettingsPage.styles';
import { NotificationConfiguration } from './components/NotificationConfiguration';

export interface ISettingsPageProps extends RouteComponentProps<{ publisherId: string }>, InjectedIntlProps {}

export const UserSettingsPage = injectRouterAndIntlWithObserver(function UserSettingsPageComponent({
  intl,
}: ISettingsPageProps): JSX.Element {
  const { pageTitleStyle, root } = getClassNames();
  const { formatMessage } = intl;
  const { helpInfo, locale } = getAppStore();

  // const hasUserDeleteAccess = hasDeleteAccess(accessPermissions ? accessPermissions.userDelete : completeAccess);

  useEffect(() => {
    onCurrentRouteChanged(RouteName.userSettings, intl.formatMessage, ProfilePanelMessages.userSettings);
    onLayoutChanged(LayoutMode.Default);
    setEntityType(EntityType.User);
    onFetchUserPreferences(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={root}>
      <FormTitle
        intl={intl}
        title={formatMessage(messages.userSettings)}
        styles={{ root: pageTitleStyle }}
        id="pop_PCv4_Profilesettings"
        calloutContent={helpInfo}
        iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_Profilesettings', locale)}
        calloutOnDismiss={() => onHelpInfoChanged()}
      />
      <NotificationConfiguration />
      {/* {hasUserDeleteAccess && <UserDeletion />} */}
    </div>
  );
});
