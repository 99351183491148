import { mutatorAction } from 'satcheljs';
import { AdUnit } from '../../../../../@data';
import { getAdunitStore } from '../store/store';

export const setAdUnitsData = mutatorAction('setAdUnitsData', (data?: AdUnit[]) => {
  const store = getAdunitStore();
  store.adUnitsRaw.data = data;
});

export const setAdUnitsLoading = mutatorAction('setAdUnitsLoading', (loading: boolean) => {
  const store = getAdunitStore();
  store.adUnitsRaw.isLoading = loading;
});

export const setAdUnitsDataFetching = mutatorAction('setAdUnitsDataFetching', (loading: boolean) => {
  const store = getAdunitStore();
  store.adUnitsRaw.isFetchingData = loading;
});

export const setAdUnitsMediationFetching = mutatorAction('setAdUnitsMediationFetching', (isFetching: boolean) => {
  const store = getAdunitStore();
  store.isFetchingAdUnitStatus = isFetching;
});
