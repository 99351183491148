import { orchestrator } from 'satcheljs';
import { Log } from '../../../logging/src';
import { DOMAIN_REDIRECTION_QUERY_PARAM, PUBCENTER_TYTHON_DOMAIN_MAPPING, TYTHON_DOMAIN_REDIRECTION_CACHE_KEY } from '../../constants';
import { onDoNotRenderDefaultPageChanged } from '../../pages/ad-management/@data/actions';
import { handleDomainRedirection } from '../actions';

orchestrator(handleDomainRedirection, ({ username, pathname }) => {
  onDoNotRenderDefaultPageChanged(true);
  Log.write('Initiating domain redirection for loginHint ' + username);

  sessionStorage.setItem(TYTHON_DOMAIN_REDIRECTION_CACHE_KEY, 'true');

  const newDomainOrigin = PUBCENTER_TYTHON_DOMAIN_MAPPING[window.location.host];
  let redirectUrl = `${newDomainOrigin}/login?${DOMAIN_REDIRECTION_QUERY_PARAM}=${username}`;
  if (pathname) {
    pathname = pathname.replace(/\//, '');
    redirectUrl = `${newDomainOrigin}/${pathname}`;
  }
  Log.write('Redirecting to new domain: ' + redirectUrl);

  const iframe = document.getElementById('redirectionIframe') as HTMLIFrameElement;
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow?.postMessage(JSON.stringify(localStorage), newDomainOrigin);
  }

  setTimeout(() => {
    window.location.href = redirectUrl;
  }, 4000);
});
