import { defineMessages } from 'react-intl';

export default defineMessages({
  homeButtonAriaLabel: {
    id: 'header.homeButtonAriaLabel',
    defaultMessage: '{appName} home',
  },
  dropdownAriaLabel: {
    id: 'header.dropdownAriaLabel',
    defaultMessage: 'Module Selector',
  },
  myAccountDescribedByLabel: {
    id: 'header.myAccountDescribedByLabel',
    defaultMessage: 'Click on the icon to view more details and get a link to sign out',
  },
  myAccountStatusLabel: {
    id: 'header.myAccountStatusLabel',
    defaultMessage: 'Your profile picture with status set as online',
  },
  defaultRouteDirection: {
    id: 'header.defaultRouteDirection',
    defaultMessage: 'At {appName} Home',
  }
});
