import { getId, IconButton, IIconProps, Image, mergeStyleSets, Shimmer, Stack } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import appMessages from '../../App.messages';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { getClassNames, getImageStyle } from './Card.styles';
import { ICardProps } from './Card.types';

const closeIcon: IIconProps = { iconName: 'clear', styles: { root: { fontSize: 12 } } };

export const Card = injectIntl<ICardProps>(function CardComponent({
  titleText,
  onDismiss,
  showDismissButton,
  children,
  cardActions,
  styleProps,
  intl,
  cardTopBarActions,
  footerComponents,
  isDataLoaded = true,
  shimmerCustomElement,
  imageUrl,
  imageAlt,
  role,
  titleIconTooltipProps,
}) {
  const { formatMessage } = intl;
  const {
    rootStyle,
    titleStyle,
    tooltipTitle,
    titleBar,
    bodyStyle,
    dismissButtonStyle,
    footerStyle,
    footerWrapper,
    imageStyle,
  } = mergeStyleSets(getClassNames({ imageUrl }), styleProps);
  const { close } = appMessages;
  const titleId = getId('cardTitle');

  const onDismissClick = (e) => {
    onDismiss?.(e);
  };
  return (
    <Stack verticalAlign="space-between" role={role || 'region'} className={rootStyle} aria-labelledby={titleId}>
      {imageUrl && <Image src={imageUrl} shouldFadeIn className={imageStyle} styles={{ image: getImageStyle }} alt={imageAlt} />}
      {showDismissButton && (
        <IconButton
          iconProps={closeIcon}
          title={formatMessage(close)}
          ariaLabel={formatMessage(close)}
          onClick={onDismissClick}
          className={dismissButtonStyle}
        />
      )}
      <Stack className={titleBar} enableScopedSelectors horizontal wrap horizontalAlign="space-between" verticalAlign="center">
        <div className={tooltipTitle}>
          <h2 className={titleStyle} id={titleId}>
            {titleText}
          </h2>
          {titleIconTooltipProps && (
            <IconTooltip iconName={titleIconTooltipProps.iconName} tooltipMessage={titleIconTooltipProps.tooltipText} />
          )}
        </div>
        <Stack enableScopedSelectors horizontal wrap>
          {cardTopBarActions}
        </Stack>
      </Stack>
      <Stack className={bodyStyle}>
        {!isDataLoaded && <Shimmer isDataLoaded={isDataLoaded} customElementsGroup={shimmerCustomElement} />}
        {isDataLoaded && children}
      </Stack>
      {isDataLoaded && cardActions}
      {isDataLoaded && footerComponents && (
        <div className={footerWrapper}>
          <div className={footerStyle}>{footerComponents}</div>
        </div>
      )}
    </Stack>
  );
});
