import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'scriptPage.title',
    defaultMessage: 'Finish creating your site by placing the code in your header tag.',
  },
  tips: {
    id: 'scriptPage.tips',
    defaultMessage: `Make sure that you insert the ad inside your feed. You can choose to place it in your feed's content or at the beginning or end of your feed. Avoid placing the ad in the sidebar, as it may negatively impact performance. The container you use to place your ad should have a variable height, as fixed height containers may cause the ad to appear distorted.`,
  },
  subTitle: {
    id: 'scriptPage.subTitle',
    defaultMessage: 'Load the following script in your header tag',
  },
  copyCodeButton: {
    id: 'scriptPage.copyCodeButton',
    defaultMessage: 'Copy code snippet',
  },
  microsoftClarity: {
    id: 'scriptPage.microsoftClarity',
    defaultMessage: 'Microsoft Clarity',
  },
  microsoftConnectedClarity: {
    id: 'scriptPage.microsoftConnectedClarity',
    defaultMessage: 'Your Clarity project ID is: {projectId}. To get the analytical data please visit ',
  },
  microsoftClarityConnectedAccountNotice: {
    id: 'scriptPage.microsoftClarityConnectedAccountNotice',
    defaultMessage: 'Note: Please use same MSA account to log in.',
  },
  microsoftClarityEmailSentNotice: {
    id: 'scriptPage.microsoftClarityEmailSentNotice',
    defaultMessage: 'Email sent, please go check and approve the connection of the Clarity project.',
  },
  scriptPageInvalidState: {
    id: 'scriptPage.scriptPageInvalidState',
    defaultMessage: 'You have arrived in an invalid state, please report.',
  },
  scriptPageFailedToLoadClarityProject: {
    id: 'scriptPage.scriptPageFailedToLoadClarityProject',
    defaultMessage: 'Failed to fetch clarity project',
  },
});
