import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'support.form.title',
    defaultMessage: 'Contact Support',
  },
  formTitleInputHeader: {
    id: 'support.form.title.input.header',
    defaultMessage: 'Title',
  },
  formEmailInputHeader: {
    id: 'support.form.email.input.header',
    defaultMessage: 'Email',
  },
  formEmailInputPlaceholder: {
    id: 'support.form.email.input.placeholder',
    defaultMessage: 'Enter email',
  },
  formCreationFailedTitle: {
    id: 'support.form.creation.failed.title',
    defaultMessage: 'Could not be sent',
  },
  formCreationFailedDetails: {
    id: 'support.form.creation.failed.details',
    defaultMessage: '{msgTitle} This request could not be created. Please try again.',
  },
  formCreationSuccessTitle: {
    id: 'support.form.creation.success.title',
    defaultMessage: 'Your support request is sent',
  },
  formCreationSuccessDetails: {
    id: 'support.form.creation.success.details',
    defaultMessage:
      'We’re reviewing your request: {ticketNumber}. You’ll receive an email confirmation as soon as possible. You can close this tab at any time.',
  },
  formTitleInputPlaceholder: {
    id: 'support.form.title.input.placeholder',
    defaultMessage: 'Subject',
  },
  formDescriptionInputHeader: {
    id: 'support.form.description.input.header',
    defaultMessage: 'Description',
  },
  formDescriptionInputPlaceholder: {
    id: 'support.form.description.input.placeholder',
    defaultMessage: 'Please share your feedback or describe your experience.',
  },
  formSubmitButton: {
    id: 'support.form.submit.button',
    defaultMessage: 'Submit',
  },
  formSubmitButtonLoading: {
    id: 'support.form.submit.button.loading',
    defaultMessage: 'Submitting Form',
  },
  formDescriptionWordsCount: {
    id: 'support.form.description.words.count',
    defaultMessage: '{count} words remaining',
  },
  formDescriptionWordCount: {
    id: 'support.form.description.word.count',
    defaultMessage: '{count} word remaining',
  },
});
