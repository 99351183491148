import {
  ActionButton,
  BaseComponent,
  classNamesFunction,
  css,
  DelayedRender,
  FocusZone,
  FocusZoneDirection,
  getId,
  Icon,
  IGroup,
  IProcessedStyleSet,
  Label,
  Link,
  nullRender,
  SearchBox,
  VerticalDivider,
} from '@fluentui/react';
import * as React from 'react';
import AppMessages from '../../App.messages';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import messages from './HierarchicalMultiSelectPicker.messages';
import {
  IHierarchicalMultiSelectPickerProps,
  IHierarchicalMultiSelectPickerStyleProps,
  IHierarchicalMultiSelectPickerStyles,
  IHierarchicalPickerItem,
} from './HierarchicalMultiSelectPicker.types';

const getClassNames = classNamesFunction<IHierarchicalMultiSelectPickerStyleProps, IHierarchicalMultiSelectPickerStyles>();

export interface IHierarchicalMultiSelectPickerState {
  filteredItems: IHierarchicalPickerItem[];
  subItems: IHierarchicalPickerItem[];
  selectedItems: IHierarchicalPickerItem[];
  expandedGroup?: IGroup;
}

export class HierarchicalMultiSelectPickerBase extends BaseComponent<
  IHierarchicalMultiSelectPickerProps,
  IHierarchicalMultiSelectPickerState
> {
  private _id: string;
  private _classNames: IProcessedStyleSet<IHierarchicalMultiSelectPickerStyles>;

  constructor(props: IHierarchicalMultiSelectPickerProps) {
    super(props);

    this._id = props.id || getId('HierarchicalMultiSelectPicker');

    const allItems = this.props.items;
    const selectedItems = this._getIntersection(allItems, this.props.initialSelectedItems || []);
    selectedItems.forEach((item) => {
      if (item && item.id) {
        allItems.find((i) => i.id === item.id)!.isSelected = true;
      }
    });

    this.state = {
      filteredItems: [...allItems],
      subItems: [],
      selectedItems: selectedItems,
    };
  }

  public componentDidUpdate(prevProps: IHierarchicalMultiSelectPickerProps) {
    let initialSelectedItems = this.state.selectedItems;

    if (this.props.initialSelectedItems !== prevProps.initialSelectedItems) {
      initialSelectedItems = this.props.initialSelectedItems || [];
    }

    if (this.props.items !== prevProps.items || this.props.initialSelectedItems !== prevProps.initialSelectedItems) {
      const intersectedSelectedItems = this._getIntersection(initialSelectedItems, this.props.items);
      const availableItems = [...this.props.items];

      intersectedSelectedItems.forEach((item) => {
        availableItems.find((i) => i.id === item.id)!.isSelected = true;
      });

      this.setState({
        filteredItems: [...this.props.items],
        selectedItems: intersectedSelectedItems,
      });
    }
  }

  public render(): JSX.Element {
    const {
      styles,
      theme,
      errorMessage,
      label,
      onRenderLabel = this._onRenderLabel,
      enableCreateButton,
      createButtonLabel,
      onCreateButtonClick,
      disabled,
      intl,
    } = this.props;
    this._classNames = getClassNames(styles, { theme: theme!, hasErrorMessage: !!errorMessage });

    return (
      <div className={this._classNames.root}>
        {onRenderLabel(this.props, this._onRenderLabel)}
        {enableCreateButton && (
          <ActionButton
            iconProps={{ iconName: 'add' }}
            onClick={onCreateButtonClick}
            disabled={disabled}
            ariaDescription={intl.formatMessage(messages.createButtonAriaDesc, { createElement: createButtonLabel })}
          >
            {createButtonLabel}
          </ActionButton>
        )}
        <div
          className={this._classNames.picker}
          role="form"
          {...(label ? { 'aria-labelledy': 'hierarchicalMultiSelectPickerLabel' } : {})}
          aria-describedby="hierarchicalPickerAriaDescription"
        >
          {this._renderItemsPane()}
          {this._renderSelectedItemsPane()}
        </div>
        {errorMessage && (
          <div role="alert">
            <DelayedRender>
              <p className={this._classNames.errorMessage}>
                <span data-automation-id="error-message">{errorMessage}</span>
              </p>
            </DelayedRender>
          </div>
        )}

        <span id="hierarchicalPickerAriaDescription" style={getScreenReaderTextStyles()}>
          {intl.formatMessage(messages.pickerAriaDescription)}
        </span>
      </div>
    );
  }

  private _onRenderLabel = (props: IHierarchicalMultiSelectPickerProps): JSX.Element | null => {
    const { label, required } = props;

    if (label) {
      return (
        <Label id="hierarchicalMultiSelectPickerLabel" required={required} htmlFor={this._id}>
          {props.label}
        </Label>
      );
    }

    return null;
  };

  private _renderItemsPane = (): JSX.Element => {
    const { searchBoxPlaceHolder, initialSearchBoxPlaceHolder, disabled } = this.props;
    const { expandedGroup } = this.state;
    const _searchBoxPlaceHolder = expandedGroup || !initialSearchBoxPlaceHolder ? searchBoxPlaceHolder : initialSearchBoxPlaceHolder;

    return (
      <div className={css(this._classNames.itemsPane, disabled ? this._classNames.disabledPanel : '')}>
        <SearchBox
          styles={this._classNames.subComponentStyles.search}
          underlined={true}
          placeholder={_searchBoxPlaceHolder}
          onChange={(_, value: string) => this._onSearchChange(value)}
          ariaLabel={_searchBoxPlaceHolder}
        />

        <div className={this._classNames.subPane} role="tree">
          {this._renderGroupsPane()}
          <VerticalDivider />
          {this._renderSubItemsPane()}
        </div>
      </div>
    );
  };

  private _renderGroupsPane = (): JSX.Element => {
    const { onFilteredItemsLabel, disabled } = this.props;
    const { filteredItems, expandedGroup } = this.state;
    const groups = this._createGroups(filteredItems);

    return (
      <div className={this._classNames.subItemsPane} role="presentation">
        <span className={css(this._classNames.header, this._classNames.headerLabel)}>{onFilteredItemsLabel(groups.length)}</span>
        <FocusZone className={this._classNames.list} isCircularNavigation={true} direction={FocusZoneDirection.vertical}>
          {groups.map((group: IGroup, index: number) => (
            <div
              className={css(
                this._classNames.listItem,
                expandedGroup && expandedGroup.name === group.name ? this._classNames.disabledListItem : ''
              )}
              id={`hierarchicalPickerGroup-${index}`}
              key={`hierarchicalPickerGroup-${index}`}
              role="treeitem"
              aria-expanded={expandedGroup && expandedGroup.name === group.name ? true : false}
              aria-selected={expandedGroup && expandedGroup.name === group.name ? true : false}
              tabIndex={disabled ? -1 : 0}
              onClick={!disabled ? this._onGroupExpandedClick : undefined}
              onKeyDown={(ev) => isButtonPressKey(ev.key) && !disabled && this._onGroupExpandedClick(ev)}
              data-list-index={index}
              data-is-focusable={disabled || (expandedGroup && expandedGroup.name === group.name) ? false : true}
            >
              <span className={this._classNames.listItemLabel}>{group.name}</span>
              <Icon
                iconName={'ChevronRight'}
                styles={this._classNames.subComponentStyles.iconButton}
                aria-label={this.props.intl.formatMessage(messages.expandGroupAriaLabel)}
              />
            </div>
          ))}
        </FocusZone>
      </div>
    );
  };

  private _renderSubItemsPane = (): JSX.Element => {
    const { subItems, expandedGroup } = this.state;
    const { disabled, intl } = this.props;

    let renderAddAll = nullRender();
    if (subItems.length > 0) {
      renderAddAll = (
        <>
          <Link className={this._classNames.textButton} onClick={this._onAddAll} disabled={disabled} aria-describedby="addAllAriaDesc">
            {intl.formatMessage(AppMessages.addAll)}{' '}
          </Link>

          <span id="addAllAriaDesc" style={getScreenReaderTextStyles()}>
            {intl.formatMessage(messages.addAllAriaDescription, { groupName: expandedGroup ? expandedGroup.name : '' })}
          </span>
        </>
      );
    }

    return (
      <div className={this._classNames.subItemsPane} role="presentation">
        <div className={this._classNames.header}>
          <span className={this._classNames.headerLabel}>{this.props.onSubItemsLabel(subItems.length)}</span>
          {renderAddAll}
        </div>
        <FocusZone className={this._classNames.list} role="group" direction={FocusZoneDirection.vertical} isCircularNavigation={true}>
          {subItems.map((item: IHierarchicalPickerItem, index: number) => (
            <div
              className={css(this._classNames.listItem, item.isSelected ? this._classNames.disabledListItem : '')}
              id={`hierarchicalPickerItem-${index}`}
              key={`hierarchicalPickerItem-${index}`}
              role="treeitem"
              aria-selected={item.isSelected}
              tabIndex={!disabled ? 0 : -1}
              onClick={!disabled && !item.isSelected ? this._onItemAddedClick : undefined}
              onKeyDown={(ev) => {
                if (isButtonPressKey(ev.key) && !disabled && !item.isSelected) {
                  this._onItemAddedClick(ev);
                }
              }}
              data-list-index={index}
              data-is-focusable={!disabled && !item.isSelected}
            >
              <span className={this._classNames.listItemLabel}>{item.name}</span>
              {!item.isSelected ? (
                <Icon
                  iconName={'Add'}
                  aria-label={intl.formatMessage(messages.addIconAriaLabel)}
                  styles={this._classNames.subComponentStyles.iconButton}
                />
              ) : null}
            </div>
          ))}
        </FocusZone>
      </div>
    );
  };

  private _renderSelectedItemsPane = (): JSX.Element => {
    const { selectedItems } = this.state;
    const { onSelectedItemsLabel, disabled, intl } = this.props;
    const selectedCount = selectedItems.length;

    let renderRemoveAll = nullRender();
    if (selectedCount > 0) {
      renderRemoveAll = (
        <>
          <Link
            className={this._classNames.textButton}
            onClick={this._onRemoveAll}
            disabled={disabled}
            aria-describedby="removeAllAriaDesc"
          >
            {intl.formatMessage(AppMessages.removeAll)}
          </Link>

          <span id="removeAllAriaDesc" style={getScreenReaderTextStyles()}>
            {intl.formatMessage(messages.removeAllAriaDescription)}
          </span>
        </>
      );
    }
    const groups = this._createGroups(selectedItems);

    return (
      <div className={css(this._classNames.itemsPane, disabled ? this._classNames.disabledPanel : '')} role="presentation">
        <span className={css(this._classNames.header, this._classNames.headerLabel)}>{onSelectedItemsLabel(selectedCount)}</span>
        {renderRemoveAll}

        <FocusZone
          className={this._classNames.list}
          role="listbox"
          aria-label={intl.formatMessage(messages.selectedListAriaLabel)}
          direction={FocusZoneDirection.vertical}
          isCircularNavigation={true}
        >
          {groups.map((group: IGroup) => {
            const groupSubItems = selectedItems.slice(group.startIndex, group.startIndex + group.count);
            return (
              <>
                <span className={this._classNames.groupHeader}>
                  {group.name}
                  <Icon role="presentation" className={this._classNames.groupHeaderIcon} iconName={'ChevronRight'} />
                </span>

                {groupSubItems.map((item: IHierarchicalPickerItem, index: number) => (
                  <div
                    className={css(this._classNames.listItem, this._classNames.selectedPaneItem)}
                    id={`hierarchicalPickerSelItem-${index}`}
                    role="option"
                    aria-selected={true}
                    tabIndex={!disabled ? 0 : -1}
                    onClick={!disabled ? this._onItemRemovedClick : undefined}
                    onKeyDown={(ev) => isButtonPressKey(ev.key) && !disabled && this._onItemRemovedClick(ev)}
                    data-list-index={index}
                    data-is-focusable={!disabled}
                  >
                    <span className={this._classNames.listItemLabel}>{item.name}</span>
                    <Icon
                      iconName={'remove'}
                      styles={this._classNames.subComponentStyles.iconButton}
                      aria-label={intl.formatMessage(messages.removeIconAriaLabel)}
                    />
                  </div>
                ))}
              </>
            );
          })}
        </FocusZone>
      </div>
    );
  };

  private _onGroupExpandedClick = (ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): void => {
    const filteredItems = [...this.state.filteredItems];
    const groups = this._createGroups(filteredItems);

    const index = this._getIndexOfItemAtElement(ev);

    if (index !== undefined) {
      const group = groups[index];
      const subItems = filteredItems.slice(group.startIndex, group.startIndex + group.count);

      this.setState({
        subItems: subItems,
        expandedGroup: group,
      });
    }
  };

  private _onItemAddedClick = (ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): void => {
    const subItems = [...this.state.subItems];
    const filteredItems = [...this.state.filteredItems];

    const index = this._getIndexOfItemAtElement(ev);

    if (index !== undefined) {
      const item = subItems[index];
      item.isSelected = true;
      const selectedItems = [...this.state.selectedItems, item];

      filteredItems.forEach((i) => {
        if (i.id === item.id) {
          i.isSelected = true;
        }
      });

      this.setState({
        filteredItems: filteredItems,
        subItems: subItems,
        selectedItems: selectedItems,
      });

      if (this.props.onSelectedItems) {
        this.props.onSelectedItems(selectedItems);
      }
    }
  };

  private _onItemRemovedClick = (ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): void => {
    const selectedItems = [...this.state.selectedItems];
    const filteredItems = [...this.state.filteredItems];
    const subItems = [...this.state.subItems];

    const index = this._getIndexOfItemAtElement(ev);

    if (index !== undefined) {
      const item = selectedItems[index];
      const remainingSelectedItems = selectedItems.filter((i) => i !== item);

      filteredItems.forEach((i) => {
        if (i.id === item.id) {
          i.isSelected = false;
        }
      });

      subItems.forEach((i) => {
        if (i.id === item.id) {
          i.isSelected = false;
        }
      });

      this.setState({
        selectedItems: remainingSelectedItems,
        subItems: subItems,
        filteredItems: filteredItems,
      });

      if (this.props.onSelectedItems) {
        this.props.onSelectedItems(remainingSelectedItems);
      }
    }
  };

  private _getIndexOfItemAtElement(ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): number | undefined {
    const el = ev.currentTarget as HTMLDivElement;

    if (el.getAttribute('data-list-index')) {
      const index = Number(el.getAttribute('data-list-index'));
      return index;
    }

    return undefined;
  }

  private _onSearchChange = (query: string): void => {
    const queryName = query.toLocaleLowerCase();
    const allItems = this.props.items;
    let subItems: IHierarchicalPickerItem[] = [];

    const { expandedGroup } = this.state;
    if (expandedGroup) {
      allItems.sort((a, b) => (a.parentName && b.parentName && a.parentName.toLowerCase() < b.parentName.toLowerCase() ? -1 : 1));
      subItems = allItems.slice(expandedGroup.startIndex, expandedGroup.startIndex + expandedGroup.count);
    }

    this.setState({
      filteredItems: allItems.filter((item) => {
        const name = item.parentName;
        return name!.toString().toLocaleLowerCase().indexOf(queryName) !== -1;
      }),
      subItems: subItems.filter((item) => {
        const name = item.name;
        return name!.toString().toLocaleLowerCase().indexOf(queryName) !== -1;
      }),
    });
  };

  private _getIntersection = (collectionA: IHierarchicalPickerItem[], collectionB: IHierarchicalPickerItem[]) => {
    return collectionA.filter((selectedItem) => collectionB.some((item) => selectedItem.id === item.id));
  };

  private _onRemoveAll = (ev: React.MouseEvent<HTMLElement>): void => {
    const filterItems = [...this.state.filteredItems];
    const subItems = [...this.state.subItems];
    filterItems.map((item) => (item.isSelected = false));
    subItems.map((item) => (item.isSelected = false));

    // reset items isSelected props on removeAll clicked;
    this.props.items.map((item) => (item.isSelected = false));

    this.setState({
      selectedItems: [],
      subItems: subItems,
      filteredItems: filterItems,
    });

    if (this.props.onSelectedItems) {
      this.props.onSelectedItems([]);
    }
  };

  private _onAddAll = (): void => {
    const selectedItems = [...this.state.selectedItems];
    const filteredItems = [...this.state.filteredItems];
    const subItems = [...this.state.subItems];

    subItems.forEach((item) => {
      if (!item.isSelected) {
        selectedItems.push(item);
      }
      item.isSelected = true;
    });

    selectedItems.forEach((item) => {
      filteredItems.find((i) => i.id === item.id)!.isSelected = true;
    });

    this.setState({
      selectedItems: selectedItems,
      subItems: subItems,
      filteredItems: filteredItems,
    });

    if (this.props.onSelectedItems) {
      this.props.onSelectedItems(selectedItems);
    }
  };

  private _createGroups = (items: IHierarchicalPickerItem[]): IGroup[] => {
    items.sort((a, b) => (a.parentName && b.parentName && a.parentName.toLowerCase() < b.parentName.toLowerCase() ? -1 : 1));

    const groups = items.reduce((current: IGroup[], item: IHierarchicalPickerItem, index: number) => {
      const currentGroup = current[current.length - 1];
      if (item.parentId) {
        const itemColumnValue = item.parentId.toString();

        if (!currentGroup || currentGroup.key !== itemColumnValue) {
          current.push({
            key: itemColumnValue,
            name: item.parentName || '',
            startIndex: index,
            count: 1,
            level: 0,
          });
        } else {
          currentGroup.count++;
        }
      }
      return current;
    }, [] as IGroup[]);
    return groups;
  };
}
