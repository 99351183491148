/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
    ActionButton,
    Announced,
    Callout,
    classNamesFunction,
    DirectionalHint,
    FocusZone,
    FocusZoneDirection,
    getTheme,
    IProcessedStyleSet,
    SearchBox
} from '@fluentui/react';
import React from 'react';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import { getStyles } from './PropertyPicker.styles';
import { IPropertyPickerItem, IPropertyPickerProps, IPropertyPickerStyleProps, IPropertyPickerStyles } from './PropertyPicker.types';

interface IPropertyPickerState {
  filteredItems: IPropertyPickerItem[];
  isCalloutVisible: boolean;
  announceItemSelected: boolean;
}

const getClassNames = classNamesFunction<IPropertyPickerStyleProps, IPropertyPickerStyles>();

export class PropertyPicker extends React.Component<IPropertyPickerProps, IPropertyPickerState> {
  private _classNames: IProcessedStyleSet<IPropertyPickerStyles>;

  constructor(props: IPropertyPickerProps) {
    super(props);

    this.state = {
      filteredItems: props.items,
      isCalloutVisible: false,
      announceItemSelected: false,
    };
  }

  public componentDidUpdate(prevProps: IPropertyPickerProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        filteredItems: this.props.items,
      });
    }
  }

  public render(): JSX.Element {
    const theme = getTheme();
    this._classNames = getClassNames(getStyles, { theme });
    const {
      createButtonLabel,
      onCreateButtonClick,
      isCreateButtonDisabled,
      searchBoxPlaceholder,
      onListItemClicked,
      propertyOptionsAriaLabel,
      createButtonAriaDescription,
      searchBoxAriaDescription,
      itemSelectedAnnounceMessage,
    } = this.props;

    const listItems: JSX.Element[] = [];

    // add empty item to allow deselecting a selected property
    listItems.push(
      <div
        className={this._classNames.listItem}
        key={0}
        onClick={() => {
          onListItemClicked(undefined);
          this.setState({ isCalloutVisible: false });
        }}
        onKeyDown={(ev) => {
          if (isButtonPressKey(ev.key)) {
            onListItemClicked(undefined);
            this.setState({ isCalloutVisible: false });
          }
        }}
        role="option"
        aria-selected={false}
        data-is-focusable={true}
      >
        {this.props.emptyOptionLabel ? this.props.emptyOptionLabel : ''}
      </div>
    );

    this.state.filteredItems.forEach((item: IPropertyPickerItem, index: number) => {
      listItems.push(
        <div
          className={this._classNames.listItem}
          key={item.id}
          onClick={() => {
            onListItemClicked(item);
            this.setState({ isCalloutVisible: false, announceItemSelected: true });
          }}
          onKeyDown={(ev) => {
            if (isButtonPressKey(ev.key)) {
              onListItemClicked(item);
              this.setState({ isCalloutVisible: false, announceItemSelected: true });
            }
          }}
          role="option"
          aria-selected={false}
          data-is-focusable={true}
          data-item-index={index}
        >
          <span className={this._classNames.listItemLabel}>{item.id + ' - (' + item.url + ')'}</span>
        </div>
      );
    });

    return (
      <>
        {this.state.announceItemSelected && <Announced message={itemSelectedAnnounceMessage} aria-live="assertive" />}
        <ActionButton
          iconProps={{ iconName: 'add' }}
          onClick={onCreateButtonClick}
          disabled={isCreateButtonDisabled}
          ariaDescription={createButtonAriaDescription}
        >
          {createButtonLabel}
        </ActionButton>

        <div id={'searchBox'} className={this._classNames.searchBox}>
          <SearchBox
            placeholder={searchBoxPlaceholder}
            underlined={true}
            onChange={(_, filter: string) => this._onCallSuggestionsChanged(filter)}
            onSearch={() => this.setState({ isCalloutVisible: true })}
            aria-describedby={'searchBoxAriaDesc'}
          />
        </div>
        {this.state.isCalloutVisible ? (
          <Callout
            target={'#searchBox'}
            directionalHint={DirectionalHint.bottomLeftEdge}
            gapSpace={0}
            isBeakVisible={false}
            calloutWidth={400}
            preventDismissOnScroll={true}
            setInitialFocus={true}
            onDismiss={() => this.setState({ isCalloutVisible: false })}
            onKeyDown={(ev: React.KeyboardEvent<HTMLDivElement>) => {
              // Earlier clicking tab was taking focus out of context and hence breaking the navigation order
              // Dismissing the callout makes sure that it will focus on the next active element in the tab order.
              if (ev.key === 'Tab' || (ev.shiftKey && ev.key === 'Tab')) {
                this.setState({ isCalloutVisible: false });
              }
            }}
          >
            <FocusZone
              direction={FocusZoneDirection.vertical}
              isCircularNavigation={true}
              role="listbox"
              aria-labelledby={'propertyOptionsAriaLabel'}
            >
              {listItems}
            </FocusZone>
          </Callout>
        ) : null}
        <span id="propertyOptionsAriaLabel" style={getScreenReaderTextStyles()}>
          {propertyOptionsAriaLabel}
        </span>
        <span id="searchBoxAriaDesc" style={getScreenReaderTextStyles()}>
          {searchBoxAriaDescription}
        </span>
      </>
    );
  }

  private _onCallSuggestionsChanged = (filter: string) => {
    const filteredItems = this.props.items.filter((p) => {
      const idUrlString = p.id.toString() + p.url.toLocaleLowerCase();
      return idUrlString.indexOf(filter.toLocaleLowerCase()) !== -1;
    });

    this.setState({ filteredItems: filteredItems });
  };
}
