import {
  EntityLimitTier,
  FeatureOverideTier,
  IAddress,
  IAllowedMarket,
  NetworkAffiliation,
  Partner,
  PublisherPromotionalEmail,
  PublisherTier,
  ServiceLevel,
} from '..';
import { PublisherType } from '../enums/PublisherType';
import { Model } from './Model';

export class PublisherCreate extends Model {
  public apiPath = 'support/publishers/register';
  public className = 'publishers';
  public address?: IAddress;
  public allowDistributionReporting?: boolean;
  public allowDistributionV2Reporting?: boolean;
  public allowStartDistributionReporting?: boolean;
  public allowStartDistributionContentReporting?: boolean;
  public allowSelectTierReporting?: boolean;
  public allowMediationReporting?: boolean;
  public allowedMarkets?: IAllowedMarket[];
  public allowMobileSearchAdUnit?: boolean;
  public allowNativeReporting?: boolean;
  public allowSyndicationReporting?: boolean;
  public allowPaidSearchReportingApi?: boolean;
  public allowPaidSearchReportingDashboard?: boolean;
  public enableDefaultTythonV2Reporting?: boolean;
  public allowWebAdUnit?: boolean;
  public entityLimitTier?: EntityLimitTier;
  public networkAffiliation?: NetworkAffiliation;
  public overrideFeatureTier?: FeatureOverideTier;
  public partner?: Partner;
  public propertyAutoApproved?: boolean;
  public publisherTier?: PublisherTier;
  public serviceLevel?: ServiceLevel;
  public publisherMarket?: string;
  public financialStatus?: string;
  public publisherType?: PublisherType;
  public promotionalEmail?: typeof PublisherPromotionalEmail[keyof typeof PublisherPromotionalEmail];
}
