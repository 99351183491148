import { AdTypes, NativeAdProperties } from '../../../../pages/ad-management/Adunit/TythonAdunit/CreateAdunit/types';
import { Model } from './Model';

export type TythonAdUnitServingConfig = {
  adUnitId?: number;
  accountId: number;
  propertyId: number;
  adUnitName: string;
  adTypes: AdTypes;
  nativeProperties: NativeAdProperties;
};

export class TythonAdUnit extends Model {
  public apiPath = '';
  public className = 'servingConfig';
  public servingConfig?: TythonAdUnitServingConfig;
  /**
   * data for api return type in ad unit serving config endpoint
   */
  public data: { servingConfig: TythonAdUnitServingConfig };
}
