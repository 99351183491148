import React, { useEffect, useMemo } from 'react';
import { Log } from '../../../../../logging/src';
import { User, getAppStore } from '../../../../@data';
import { onPopupOpenChanged } from '../../../../@data/mutatorActions';
import { getPrimaryUserId } from '../../../../@data/store';
import { PopupType } from '../../../../@data/store/schema/enums/PopupType';
import AppMessages from '../../../../App.messages';
import { DeleteConfirmationPopup } from '../../../../components/Popup/DeleteConfirmationPopup';
import { useCanvasTextWidth } from '../../../../hooks';
import {
  CommandBarActions,
  Direction,
  GridSelectionMode,
  GridV2,
  IGridPageCommandBarItemProps,
  IGridV2Column,
} from '../../../../layout/GridPageV2';
import { IRouterWithIntl, injectRouterAndIntlWithObserver } from '../../../../utils';
import messages from '../UserManagement.messages';
import { loadUsers, onUsersDeleted } from './@data/actions/usersTabActions';
import './@data/mutatorActions';
import { setSelectedItem, setUserRoleChangePanelStatus } from './@data/mutatorActions';
import './@data/orchestrators';
import { getUserManagementStore } from './@data/store';
import { UserRoleChangePanel } from './UserRoleChangePanel';
import { getColumns, getUserName } from './UsersTabColumns';

const USER_ROLE_CHANGE_KEY = 'userRoleChange';

export interface IUserPageProps extends IRouterWithIntl {}

export const UsersTab = injectRouterAndIntlWithObserver(function UsersTabComponent(props: IUserPageProps) {
  const { formatMessage } = props.intl;
  const { publisher, openedPopup } = getAppStore();
  const { users } = getUserManagementStore();
  const primaryUserId = getPrimaryUserId();

  const longestEmail = useMemo(() => users?.map((user) => user.email || '').reduce((a, b) => (a.length > b.length ? a : b), '') || '', [
    users,
  ]);
  const longestName = useMemo(
    () => users?.map((user) => getUserName(user.name, user.username)).reduce((a, b) => (a.length > b.length ? a : b), '') || '',
    [users]
  );
  const gridDefaultColumns: IGridV2Column[] = getColumns(props.intl, useCanvasTextWidth(longestName), useCanvasTextWidth(longestEmail));

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <GridV2
        {...props}
        notGridReadyLabel={formatMessage(messages.gridNotReadyLabel)}
        isGridReady={!!publisher}
        entity={User}
        commandBarProps={{
          searchBoxPlaceholder: messages.searchByNamePlaceholder,
          includeFarActions: [CommandBarActions.searchBox, CommandBarActions.modifyColumns, CommandBarActions.download],
          getCustomCommandBarItems: (getSelectedItems) => {
            if (getSelectedItems().some((user) => user.id === primaryUserId)) {
              return [];
            }
            const commandBarItems: IGridPageCommandBarItemProps[] = [
              {
                key: CommandBarActions.delete,
                name: formatMessage(AppMessages.delete),
                iconProps: {
                  iconName: 'Delete',
                },
                placement: { direction: Direction.before },
                onClick: () => {
                  const selectedItems = getSelectedItems() as User[];
                  Log.debug(`User command bar delete clicked: ${selectedItems.map((user) => user.username).toString()}`);

                  if (selectedItems.length > 0) {
                    onPopupOpenChanged(PopupType.DeleteConfirmation);
                    setSelectedItem(selectedItems[0]);
                  }
                },
                selectionModes: [GridSelectionMode.Single],
              },
              {
                key: USER_ROLE_CHANGE_KEY,
                name: formatMessage(messages.role),
                iconProps: { iconName: 'PeopleRepeat' },
                placement: { direction: Direction.before },
                onClick: () => {
                  const selectedItems = getSelectedItems() as User[];
                  Log.debug(`User command bar role change clicked: ${JSON.stringify(selectedItems)}`);

                  if (selectedItems.length > 0) {
                    setUserRoleChangePanelStatus(true, selectedItems[0]);
                  }
                },
                selectionModes: [GridSelectionMode.Single],
              },
            ];

            return commandBarItems;
          },
        }}
        dataGridProps={{
          gridV2Columns: gridDefaultColumns,
          data: users,
          setData: loadUsers,
          noEntityFound: messages.noEntityFound,
        }}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
      <UserRoleChangePanel {...props} />
      <DeleteConfirmationPopup
        onDeleteClicked={() => onUsersDeleted(formatMessage)}
        isOpen={openedPopup === PopupType.DeleteConfirmation}
        onDialogDismissed={() => onPopupOpenChanged(PopupType.None)}
        deleteLabels={{ title: messages.deleteConfirmationDialogTitle, description: messages.deleteConfirmationDialogDesc }}
        confirmActionMessage={messages.confirmActionMessage}
        deleteButtonText={messages.deleteButtonText}
      />
    </React.Fragment>
  );
});
