import { orchestrator } from 'satcheljs';
import { setAdUnitsData } from '../../pages/ad-management/Adunit/@data/mutatorActions';
import { setPropertyData } from '../../pages/ad-management/Property/Manage/__data__/mutatorActions';
import { onPublisherChanged } from '../actions';
import { Publisher } from '../store/schema';
import { StoragePublisherKey } from '../store/store';

orchestrator(onPublisherChanged, async (msg) => {
  // Clear ad units and properties cached data when publisher changes
  setAdUnitsData();
  setPropertyData();

  let publisher = msg.publisher;

  if (!publisher) {
    localStorage.removeItem(StoragePublisherKey);
  } else {
    // Create a new instance just with name and id, to avoid exposing sensitive publisher information through cache
    publisher = new Publisher(publisher.id, publisher.name);
    publisher.publisherTier = msg.publisher?.publisherTier;
    publisher.enableDefaultTythonV2Reporting = msg.publisher?.enableDefaultTythonV2Reporting;
    localStorage.setItem(StoragePublisherKey, JSON.stringify(publisher));
  }
});
