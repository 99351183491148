import { ClarityStatus, ClarityType, PropertyApprovalStatus } from '../enums';
import { Model } from './Model';
import { PropertyServingConfigs } from './ServingConfigs';

export enum InventoryPlatformType {
  Web = 'Web',
  UWP = 'UWP',
}
export class Property extends Model {
  public apiPath = undefined;
  public className = 'properties';
  public approvalStatus: PropertyApprovalStatus;
  public approvalStatusComments: string;
  public disapprovalReasonId?: number;
  public lastStatusChangedTime: string;
  public lastUpdatedTimeStamp: string;
  public propertyId?: number;
  public propertyName: string;
  public publisherId: number;
  public url: string;
  public eTag?: string;
  public associatedAdUnitIds?: number[];
  public enableMicrosoftClarity?: boolean;
  public clarityProjectId?: string;
  public clarityProjectIdForTython?: string;
  public clarityStatus?: ClarityStatus;
  public clarityType?: ClarityType;
  public servingConfig?: PropertyServingConfigs;
  public MicrosoftStoreAppId?: string | null;
  public PartnerCenterSellerId?: string | null;
  public COPPAAgreement?: boolean | null;
  public inventoryPlatformType?: InventoryPlatformType;
}
