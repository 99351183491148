import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'adunit.create',
    defaultMessage: 'Create Ad Unit',
  },
  notFound: {
    id: 'adunit.notFound',
    defaultMessage: 'No adunits found for this account, create one.',
  },
  deactivateTitle: {
    id: 'adunit.deactivateTitle',
    defaultMessage: 'Do you want to deactivate ad unit(s)?',
  },
  name: {
    id: 'adunit.name',
    defaultMessage: 'Ad unit name',
  },
  pending: {
    id: 'adunit.pending',
    defaultMessage: 'Pending',
  },
  id: {
    id: 'adunit.id',
    defaultMessage: 'Ad unit ID',
  },
  channelNames: {
    id: 'adunit.channelNames',
    defaultMessage: 'Channel names',
  },
  adUnits: {
    id: 'adunit',
    defaultMessage: 'Ad units',
  },
  accountName: {
    id: 'accountName',
    defaultMessage: 'Account name',
  },
  accountId: {
    id: 'accountId',
    defaultMessage: 'Account ID',
  },
  propertyId: {
    id: 'propertyId',
    defaultMessage: 'Property ID',
  },
  siteId: {
    id: 'siteId',
    defaultMessage: 'Site ID',
  },
  propertyUrl: {
    id: 'propertyUrl',
    defaultMessage: 'Website URL',
  },
  adUnitCreationDate: {
    id: 'adUnitCreationDate',
    defaultMessage: 'Creation date',
  },
  higherMonetization: {
    id: 'higherMonetization',
    defaultMessage: 'Enable mediation',
  },
  higherMonetizationEnabled: {
    id: 'higherMonetizationEnabled',
    defaultMessage: 'Enabled',
  },
  higherMonetizationNotSetup: {
    id: 'higherMonetizationNotSetup',
    defaultMessage: 'Not setup',
  },
  businessModel: {
    id: 'businessModel',
    defaultMessage: 'Business model',
  },
  marketClassification: {
    id: 'marketClassification',
    defaultMessage: 'Market Classification',
  },
  ownedAndOperatedCore: {
    id: 'marketClassification.ownedAndOperatedCore',
    defaultMessage: 'OwnedAndOperatedCore',
  },
  ownedAndOperated: {
    id: 'marketClassification.ownedAndOperated',
    defaultMessage: 'OwnedAndOperated',
  },
  syndicated: {
    id: 'marketClassification.syndicated',
    defaultMessage: 'Syndicated',
  },
  type: {
    id: 'adUnit.type',
    defaultMessage: 'Ad unit type',
  },
  format: {
    id: 'adUnit.format',
    defaultMessage: 'Ad format',
  },
  servingStatus: {
    id: 'adUnit.servingStatus',
    defaultMessage: 'Serving Status',
  },
  actions: {
    id: 'adUnit.actions',
    defaultMessage: 'Actions',
  },
  selectTier: {
    id: 'marketClassification.selectTier',
    defaultMessage: 'SelectTier',
  },
  sponsored: {
    id: 'marketClassification.sponsored',
    defaultMessage: 'Sponsored',
  },
  sponsoredEnhanced: {
    id: 'marketClassification.sponsoredEnhanced',
    defaultMessage: 'SponsoredEnhanced',
  },
  mediationCardNoConnectionHeader: {
    id: 'mediationCard.mediationCardNoConnectionHeader',
    defaultMessage: 'Enable mediation',
  },
  mediationCardNoConnectionDesc: {
    id: 'mediationCard.mediationCardNoConnectionDesc',
    defaultMessage: `Connect your ad unit to Google demand and ensure you are getting the highest bid possible for your ad inventory. We'll only serve our ads if we can deliver a higher payment compared to Google.`,
  },
  mediationCardNoConnectionC2A: {
    id: 'mediationCard.mediationCardNoConnectionC2A',
    defaultMessage: 'Set up now',
  },
  mediationCardConnectedHeader: {
    id: 'mediationCard.mediationCardConnectedHeader',
    defaultMessage: 'Mediation is enabled',
  },
  mediationCardConnectedDesc: {
    id: 'mediationCard.mediationCardNoConnectionDesc',
    defaultMessage:
      'You are generating higher monetization fot this ad unit. If you would like to disable mediation, please go to click on edit mediation below',
  },
  mediationCardConnectedC2A: {
    id: 'mediationCard.mediationCardConnectedC2A',
    defaultMessage: 'Edit mediation',
  },
  changeName: {
    id: 'mediationCard.changeName',
    defaultMessage: 'Edit name',
  },
  changeTemplate: {
    id: 'mediationCard.changeTemplate',
    defaultMessage: 'Edit template',
  },
  zeroAdUnitsTitle: {
    id: 'adunitGrid.zeroAdUnitsTitle',
    defaultMessage: 'No ad units yet',
  },
  zeroAdUnitsDescription: {
    id: 'adunitGrid.description',
    defaultMessage: 'You haven’t created any ad unit yet',
  },
  zeroAdUnitsCallToAction: {
    id: 'adunitGrid.callToAction',
    defaultMessage: `Create Ad Unit`,
  },
  noPublisherSelected: {
    id: 'adunitGrid.noPublisherSelected',
    defaultMessage: 'A publisher and account isn’t selected yet. To start managing ad units, search for a publisher and choose account..',
  },
  prepareAdUnitsGrid: {
    id: 'adunits.grid.loading.text',
    defaultMessage: 'Fetching Ad Units Data...',
  },
  nativeAdOnboardingMsg: {
    id: 'adunits.grid.tython.nativeAd.onBoarding',
    defaultMessage:
      "We've streamlined the settings for native ads, making it easier to create ad units and earn with your website. {learnMore}",
  },
  learnMore: {
    id: 'adunits.grid.tython.nativeAd.onBoarding.learnMore',
    defaultMessage: 'Learn more',
  },
  saveAdunitFailed: {
    id: 'adunits.grid.saveAdunitFailed',
    defaultMessage: 'Save ad unit failed, please see notification center for error details',
  },
  adUnitInvalidId: {
    id: 'adunits.adUnitInvalidId',
    defaultMessage: 'Adunit has invalid id {id}',
  },
  adunitMediationUpdateStatusFailed: {
    id: 'adunits.adunitMediationUpdateStatusFailed',
    defaultMessage: "Failed to update adUnits' mediation status. Please try again!",
  },
  adunitDetailsFailedToLoad: {
    id: 'adunits.adunitDetailsFailedToLoad',
    defaultMessage: 'Failed to load adunit details, please try again!',
  },
  adunitTemplateFailedToLoad: {
    id: 'adunits.adunitTemplateFailedToLoad',
    defaultMessage: 'Failed to load adunit template',
  },
  failedToLoadAdunits: {
    id: 'adunits.failedToLoadAdunits',
    defaultMessage: 'Failed to load adunits',
  },
  failedToLoadAdunitsMediationStatus: {
    id: 'adunits.failedToLoadAdunitsMediationStatus',
    defaultMessage: 'Failed to load adunits mediation status',
  },
  failedToLoadAdunit: {
    id: 'adunits.failedToLoadAdunit',
    defaultMessage: 'Failed to load adunit',
  },
  failedToFetchAdSenseAdunit: {
    id: 'adunits.failedToFetchAdSenseAdunit',
    defaultMessage: "Failed to fetch connected adSense's adUnit. Please connect and try again!",
  },
  noConnectionWithAdSense: {
    id: 'adunits.noConnectionWithAdSense',
    defaultMessage: 'No connection with AdSense. Please connect and try again!',
  },
  editedAdunitSuccess: {
    id: 'adunit.editedAdunitSuccess',
    defaultMessage: 'Edited adunit {id} success',
  },
  adunitNameInvalid: {
    id: 'adunit.adunitNameInvalid',
    defaultMessage: 'Please enter valid name',
  },
  createAdunitFailed: {
    id: 'adunit.createAdunitFailed',
    defaultMessage: 'Create ad unit failed, please try again later.',
  },
  createAdunitInvalidState: {
    id: 'adunit.createAdunitInvalidState',
    defaultMessage: 'You have arrived in an invalid state, please report.',
  },
});
