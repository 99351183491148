import { IComboBoxOption } from '@fluentui/react';
import { action } from 'satcheljs';
import { IDataGridColumn } from '../../../../../layout/GridPageV2';
import { IAdCreativeViewModel } from '../store/schema';
import { AdPerformanceData, BlockItemType } from '../store/schema/models';

export const onAdCreativeChanged = action('onAdCreativeChanged', (adcreative: IAdCreativeViewModel) => ({ adcreative }));

export const onAdSourceChanged = action('onAdSourceChanged', (adSource?: IComboBoxOption) => ({ adSource }));

export const onAdProviderChanged = action('onAdProviderChanged', (adProvider?: IComboBoxOption) => ({ adProvider }));

export const onAdCreativeAccountChanged = action('onAdCreativeAccountChanged', (account?: IComboBoxOption) => ({ account }));

export const onAdCreativeMarketChanged = action('onAdCreativeMarketChanged', (market?: IComboBoxOption) => ({ market }));

export const onAdCreativeBlockedMarketChanged = action('onAdCreativeBlockedMarketChanged', (market?: IComboBoxOption) => ({ market }));

export const onAdCreativeSearchBoxValueChanged = action('onAdCreativeSearchBoxValueChanged', (value?: string) => ({ value }));

export const onAdCreativeStartDateChanged = action('onAdCreativeStartDateChanged', (startDate?: Date) => ({ startDate }));

export const onAdCreativeEndDateChanged = action('onAdCreativeEndDateChanged', (endDate?: Date) => ({ endDate }));

export const onAdCreativeRefreshDataChanged = action('onAdCreativeRefreshDataChanged', () => ({}));

export const onAdCreativeAdPerfDataChanged = action('onAdCreativeAdPerfDataChanged', (data?: AdPerformanceData[]) => ({ data }));

export const onAdCreativeBlockPanelChanged = action('onAdCreativeBlockPanelChanged', (value: boolean, item?: BlockItemType) => ({
  value,
  item,
}));

export const onAdCreativeExportClicked = action('onAdCreativeExportClicked', (columns?: IDataGridColumn[]) => ({ columns }));

export const onAdQualityPanelApplyButtonClicked = action('onAdQualityPanelApplyButtonClicked', () => ({}));
