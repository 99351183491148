import { createStore } from 'satcheljs';
import { IAuditHistoryState } from './AuditHistoryState';

export const getAuditHistoryStore = createStore<IAuditHistoryState>('AuditHistoryState', {
  startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  endDate: new Date(),
  filters: [],
  entityTypes: [],
  auditTypes: [],
  userRoles: [],
  isDataLoading: false,
  data: [],
  isShowAllDetailsEnabled: false,
});
