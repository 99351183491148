import { createStore } from 'satcheljs';
import { AdQualityFilterNames, IAdCreativeViewState } from './schema';

export const defaultInitialTextFilterState = {
  condition: undefined,
  value: '',
  isApplied: false,
};

export const getStore = createStore<IAdCreativeViewState>('AdCreativeState', {
  active: {},
  loading: false,
  panelIsOpen: false,
  panelItemType: undefined,
  filterPanel: undefined,
  blockAdsPage: { adPerfBlockAdsData: [], blockReasonsL2: [], channels: {} },
  pageType: 'AdCreative',
  errors: {},
  columns: [],
  blockedAdsContext: undefined,
  blockedImagesContext: undefined,
  teachingBubbleStartDate: false,
  teachingBubbleEndDate: false,
  adDescription: false,
  filters: {
    active: {
      [AdQualityFilterNames.AccountName]: { values: [] },
      [AdQualityFilterNames.AdProviders]: { values: [] },
      [AdQualityFilterNames.Markets]: { values: [] },
      [AdQualityFilterNames.CreativeAdId]: { conditions: [] },
      [AdQualityFilterNames.Advertiser]: { conditions: [] },
      [AdQualityFilterNames.AdvertiserId]: { conditions: [] },
      [AdQualityFilterNames.AdvertiserAccountId]: { conditions: [] },
      [AdQualityFilterNames.AdId]: { conditions: [] },
      [AdQualityFilterNames.AdTitle]: { conditions: [] },
      [AdQualityFilterNames.AdURL]: { conditions: [] },
      [AdQualityFilterNames.LongHeadline]: { conditions: [] },
      [AdQualityFilterNames.ShortHeadline]: { conditions: [] },
    },
    raw: {
      [AdQualityFilterNames.AccountName]: { values: [] },
      [AdQualityFilterNames.AdProviders]: { values: [] },
      [AdQualityFilterNames.Markets]: { values: [] },
      [AdQualityFilterNames.CreativeAdId]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.Advertiser]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.AdvertiserId]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.AdvertiserAccountId]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.AdId]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.AdTitle]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.AdURL]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.LongHeadline]: { conditions: [defaultInitialTextFilterState] },
      [AdQualityFilterNames.ShortHeadline]: { conditions: [defaultInitialTextFilterState] },
    },
  },
});
