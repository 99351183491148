import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'paymentsPage.pageTitle',
    defaultMessage: 'Payments',
  },
  estimatedEarningsNoDataText: {
    id: 'paymentsPage.estimatedEarningsNoDataText',
    defaultMessage: 'There is no data available for the selected date range.',
  },
  estimatedEarningsNoDataMessage: {
    id: 'paymentsPage.estimatedEarningsNoDataMessage',
    defaultMessage:
      'To update the graph, please select a different date range. If the graph still appears blank, your account may not have data available for the selected date range.',
  },
  estimatedEarningsTitle: {
    id: 'paymentsPage.estimatedEarningsTitle',
    defaultMessage: 'Estimated earnings',
  },
  balanceTitle: {
    id: 'paymentsPage.balanceTitle',
    defaultMessage: 'Balance',
  },
  thresholdNotification: {
    id: 'paymentsPage.thresholdNotification',
    defaultMessage: 'You can withdraw your earnings once you reach the {threshold} minimum payout threshold.',
  },
  nextPayout: {
    id: 'paymentsPage.nextPayout',
    defaultMessage: 'You’ll receive your next payout once you reach the threshold amount',
  },
  nextPayoutDate: {
    id: 'paymentsPage.nextPayoutDate',
    defaultMessage: 'You’ve reached your payout threshold and your payout will occur on',
  },
  lastPayoutDate: {
    id: 'paymentsPage.nextPayoutDate',
    defaultMessage: 'Your last payout was on',
  },
  yourThreshold: {
    id: 'paymentsPage.yourThreshold',
    defaultMessage: 'Your Payment Threshold is',
  },
  reachedThreshold: {
    id: 'paymentsPage.reachedThreshold',
    defaultMessage: 'You’ve reached your payout threshold.',
  },
  genericNextPayout: {
    id: 'paymentsPage.genericNextPayout',
    defaultMessage: 'Earnings will be paid out on the 15th of the second month after you reach the minimum payout threshold.',
  },
  estimatedEarningMonthly: {
    id: 'paymentPage.estimatedEarningMonthly',
    defaultMessage: 'Monthly',
  },
  estimatedEarningYearly: {
    id: 'paymentPage.estimatedEarningYearly',
    defaultMessage: 'Yearly',
  },
});
