import { orchestrator } from 'satcheljs';
import { Account, getEntity, isInternalUser, isTythonSelfServeUser, onAccountChanged, onUserContextChanged, PublisherTier } from '..';
import { Log } from '../../../logging/src/Log';
import { RouteName } from '../../pages/ad-management/@data';
import { onDoNotRenderDefaultPageChanged } from '../../pages/ad-management/@data/actions';
import { RoutePrefix } from '../../Routes';
import { isLocationPubCenter } from '../../utils/UrlUtils';
import {
  handleDomainRedirection,
  onLoadingChanged,
  onPublisherChanged,
  onPublisherSelected,
  redirectToReportingDashboard,
} from '../actions';
import { setUserRolesInCache } from '../services/UserRoleService';
import { Publisher } from '../store/schema';
import { UserType } from '../store/schema/enums/UserType';
import { getAppStore } from '../store/store';

orchestrator(onPublisherSelected, async (msg) => {
  const { userContext, defaultPublisher } = getAppStore();
  const publisherId = parseInt(msg.id, 10);

  const isInternalUserLogin = isInternalUser();

  Log.debug(`Setting publisher context in store for Publisher: ${publisherId}`);

  if (!userContext || isNaN(publisherId)) {
    return;
  }

  let { publisher } = getAppStore();

  if (isInternalUserLogin && publisher && publisher.id === publisherId) {
    console.log('OnPublisherSelected - internal user, publisher context correctly set');
    return;
  }

  if (publisher && publisher.id === publisherId && publisher.publisherTier) {
    // If publisherTier not equal to SelfServe3rdParty, redirect to reporting dashboard
    if (publisher.publisherTier !== PublisherTier.SelfServe3rdParty) {
      console.log('OnPublisherSelected - Non-tython user, redirecting to reporting dashboard');
      redirectToReportingDashboard();
      return;
    }

    // If it's tython publisher and if userContext is correctly set, no need to reset it.
    if (publisher.publisherTier === PublisherTier.SelfServe3rdParty && isTythonSelfServeUser()) {
      Log.write('OnPublisherSelected - Tython user, publisher and user context correctly set');

      if (isLocationPubCenter()) {
        console.log('OnPublisherSelected - Tython user, redirecting to new domain');
        handleDomainRedirection(userContext?.userName ?? userContext.email);
      }
      return;
    }
  }

  onLoadingChanged(true);
  onDoNotRenderDefaultPageChanged(true);

  publisher = new Publisher(publisherId);

  try {
    publisher = (await getEntity([publisher], userContext)) as Publisher;
    publisher.className = new Publisher().className;

    if (publisher) {
      onPublisherChanged(publisher);

      /* To provide different user experience for Tython/self-serve publishers, leveraging the PublisherTier value.
      TODO post MVP:  Remove this dependency on PublisherTier and set the userType flag based on user role
      defaultPublisher value is defined for external users who are mostly scoped to perform operations under a publisher shell */
      if (defaultPublisher && publisher.id === defaultPublisher && publisher.publisherTier === PublisherTier.SelfServe3rdParty) {
        if (isLocationPubCenter()) {
          handleDomainRedirection(userContext?.userName ?? userContext.email);
        }
        Log.write(
          `Recognised Tython self serve user. Updating userType to ${UserType.TythonSelfServe} and setting userRoles in cache, if not already set`
        );
        onUserContextChanged({ ...userContext, userType: UserType.TythonSelfServe });

        // Tython user is not recognised before this hence tython specific user role permissions are not set
        setUserRolesInCache(userContext.userRoles || [41]);

        if (publisher.defaultAccountId !== undefined) {
          const account = new Account(publisher.defaultAccountId);
          Log.write(`Setting the account to default account: ${publisher.defaultAccountId} `);
          onAccountChanged(account);
        }

        if (msg.routeHistory) {
          const homePageUrl = `/${RoutePrefix.adManagement}/${publisherId}/${RouteName.home}`;
          Log.write('Redirecting self-serve user to homepage');
          msg.routeHistory.push(homePageUrl);
        }
      } else if (defaultPublisher && publisher.id === defaultPublisher) {
        redirectToReportingDashboard();
      }
    } else {
      onPublisherChanged(undefined);
    }
  } catch (e) {
    Log.error('TODO: Error Handling for API Requests');
  } finally {
    onLoadingChanged(false);
    onDoNotRenderDefaultPageChanged(false);
  }
});
