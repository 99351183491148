import { IconButton, Link, TextField } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { ClarityStatus } from '../../../../../../../../../../@data';
import AppMessages from '../../../../../../../../../../App.messages';
import { FormItem } from '../../../../../../../../../../components/Form';
import { useWindowSize } from '../../../../../../../../../../hooks';
import { getStore } from '../../../../../../../../../../hooks/useWindowSize/store/store';
import { injectIntlAndObserver } from '../../../../../../../../../../utils';
import { onClarityUpdate } from '../../../../../../../Manage/__data__/orchestrators';
import { IClarityParams } from '../../../../../../../Manage/components/ClarityPanel/types';
import { onClarityIdChanged, onEditClarityId } from '../../../../../../__data__/mutatorActions';
import { onResendClarityLink } from '../../../../../../__data__/orchestrators';
import { getCreatePropertyStore } from '../../../../../../__data__/store/store';
import messages from './messages';
import { getClassNames } from './styles';

interface IClarityInputFieldProps {
  configs?: IClarityParams;
  isShownInPanel?: boolean;
}

const { windowStoreSerializer } = useWindowSize();

const ClarityInputField = injectIntlAndObserver<InjectedIntlProps & IClarityInputFieldProps>(
  ({ intl, configs, isShownInPanel = false }) => {
    const {
      originalClarityId: existingId,
      clarityId: id,
      clarityStatus: status,
      clarityEdit: isEditable,
      clarityErrorMessage: error = '',
      clarityLinkingEnabled,
    } = configs || {};

    const { formatMessage } = intl;
    const { note } = getClassNames();
    const { isWindowSmallSize } = windowStoreSerializer(getStore());
    const { active } = getCreatePropertyStore();

    const shouldShowNote: boolean =
      status === ClarityStatus.Pending ||
      status === ClarityStatus.Declined ||
      status === ClarityStatus.Expired ||
      status === ClarityStatus.Cancelled;

    const getLinkStatusNote = (): string => {
      switch (status) {
        case ClarityStatus.Pending:
          return formatMessage(messages.pendingStatus);
        case ClarityStatus.Declined:
          return formatMessage(messages.declinedStatus);
        case ClarityStatus.Cancelled:
          return formatMessage(messages.cancelledStatus);
        case ClarityStatus.Expired:
          return formatMessage(messages.expiredStatus);
        default:
          return '';
      }
    };

    return (
      <FormItem formWidth={isWindowSmallSize ? 324 : 440}>
        <TextField
          iconProps={{
            iconName: status === ClarityStatus.Approved ? 'Cancel' : '',
            style: {
              pointerEvents: 'auto',
              cursor: 'pointer',
            },
            onClick: () => onEditClarityId(!isEditable),
          }}
          required={true}
          value={id}
          errorMessage={error}
          onChange={(_, value) => onClarityIdChanged(value)}
        />
        {existingId === id && shouldShowNote && (
          <div className={note}>
            <IconButton iconProps={{ iconName: 'Info' }} />
            <FormattedMessage
              {...messages.linkingNote}
              values={{
                here: (
                  <Link
                    onClick={() =>
                      isShownInPanel ? onClarityUpdate() : onResendClarityLink(active.id?.toString()!, existingId!, clarityLinkingEnabled)
                    }
                  >
                    {formatMessage(AppMessages.here)}
                  </Link>
                ),
                status: getLinkStatusNote(),
              }}
            />
          </div>
        )}
      </FormItem>
    );
  }
);

export default ClarityInputField;
