import { AriaLoggerManager } from "./AriaLoggerManager";
import { ConsoleLoggerManager } from "./ConsoleLoggerManager";
import { DefaultLogUserContext } from "./DefaultLogUserContext";
import { Environment } from "./Environment";
import { ILogManager } from "./ILogManager";
import { ILogUserContext } from "./ILogUserContext";
import { LoggingUtils } from "./LoggingUtils";
import { LogType } from "./LogType";

/**
 * This class uses the singleton design pattern
 * 1. Ensures that ONLY 1 instance of class exists, all through the session
 * 2. Provides global access to that single instance
 *
 * This is facilitated through (a) private constructor and (b) providing a static
 * method that returns a reference to the instance
 * Ref: https://en.wikipedia.org/wiki/Singleton_pattern
 */

export class Log {
  get DefaultLogUserContext(): ILogUserContext {
    return Log.logUserContext;
  }
  set DefaultLogUserContext(context: ILogUserContext) {
    Log.logUserContext = context;
  }

  public static Instance: Log;
  private static logParams={};
  private static logManagers: ILogManager[] = [];
  private static logUserContext: ILogUserContext;

  public static getInstance(logParams={}): Log {
    if (Log.Instance === undefined ) {
      Log.Instance = new Log();
    }
    Log.logParams = logParams;
    return Log.Instance;
  }

  public static write(logMessage: string | number | object, logtype=LogType.INFO): void {
    for (const logManager of Log.logManagers) {
      logManager.log(logtype, logMessage, new DefaultLogUserContext());
    }
  }

  public static info(logMessage: string | number | object): void {
    this.write(logMessage, LogType.INFO)
  }

  public static debug(logMessage: string | number | object): void {
    this.write(logMessage, LogType.DEBUG)
  }

  public static warn(logMessage: string | number | object): void {
    this.write(logMessage, LogType.WARN)
  }

  public static error(logMessage: string | number | object): void {
    this.write(logMessage, LogType.ERROR)
  }

  private constructor() {
    const environment = LoggingUtils.getEnvironment();
    Log.logManagers = new Array<ILogManager>();

    // If the app is running on local host, just write to console
    // For all other envs(based on trace level), write to both ARIA and console
    if (environment !== Environment.TEST) {
      Log.logManagers.push(new AriaLoggerManager(Log.logParams));
    }
    Log.logManagers.push(new ConsoleLoggerManager());
  }
}
