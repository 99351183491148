import React, { useEffect, useState } from 'react';
import { ICustomColumn } from '../types';
import { sortItemsBasedOnColumn } from '../utils/DataGridUtils/SortingUtils';

export function useSortableGridColumns<ItemType>(
  columns: ICustomColumn<ItemType>[],
  items: ItemType[],
  onSortItems?: (items: ItemType[]) => void,
  onCleanupSortItems?: () => void
) {
  const [gridColumns, setGridColumns] = useState<ICustomColumn<ItemType>[]>(columns);

  useEffect(() => {
    setGridColumns(columns);
  }, [columns]);

  useEffect(() => {
    return () => onCleanupSortItems?.();
  }, [onCleanupSortItems]);

  function handleColumnHeaderClick(ev?: React.MouseEvent<HTMLElement>, column?: ICustomColumn) {
    if (!column || column.suppressSorting) {
      return;
    }
    ev?.stopPropagation();

    const newColumns = gridColumns.slice();
    const currColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    newColumns.forEach((col: ICustomColumn) => {
      if (col === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        col.isSorted = false;
        col.isSortedDescending = undefined;
      }
    });

    setGridColumns(newColumns);
    const sortedItems = sortItemsBasedOnColumn(items, currColumn);
    onSortItems?.(sortedItems);
  }

  return { gridColumns, handleColumnHeaderClick };
}
