import { ICommandBarItemProps, ICommandBarProps, IDropdownOption, IShimmeredDetailsListProps, MessageBarType } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { LifeCycleStatus, Model } from '../../@data';
import { IFormTitleStyles } from '../../components/Form';
import { FilterType, IFilterDetails } from '../../components/GridFilter/GridFilter.types';
import { ICustomColumn } from '../../types';
import { IRouterWithIntl } from '../../utils';
import { IGridStyles } from './GridV2.styles';

export interface ISelectableRow extends Model {
  isSelected: boolean;
  isDisabled?: boolean;
}

export interface IGridV2Props<T extends Model> extends IRouterWithIntl {
  entity: new () => T;
  pageTitle?: IPageTitleProps;
  notGridReadyLabel?: string;
  isGridReady?: boolean;
  isDataLoading?: boolean;
  commandBarProps?: IBuildCommandBarProps<T>;
  dataGridProps: Omit<IDataGridProps, 'columns' | 'items'>;
  paginationProps?: IPaginationProps;
  styleProps?: Partial<IGridStyles>;
  msgBarProps?: {
    className?: string;
    messageBarType: MessageBarType;
    children: JSX.Element;
    onDismiss?: () => void;
  };
  children?: React.ReactNode;
  bypassGridResetting?: boolean;
  bypassFiltrationAndSearching?: boolean;
}

export interface IPageTitleProps {
  title: string;
  helpId?: string;
  styleProps?: IFormTitleStyles;
}

export interface IGridV2Column extends ICustomColumn<Model> {
  columnOrder?: number;
  isVisible?: boolean;
  // If true, disable the checkbox for this column in the modify columns panel
  isShowingDisabled?: boolean;
  isExportable?: boolean;
  // to show shimmer on the column items is not ready
  isLoading?: boolean;
  // tslint:disable-next-line: no-any
  exportFunction?: (rowItem: Model) => any;
  /* in case column is rendering custom/derived data */
  searchFunction?: (rowItem: Model, searchWord: string) => boolean;
  // use this instead of onRender for custom rendering
  onRenderData?: (rowItem: Model) => JSX.Element;
  csvHeaderName?: string;
  incompatibleProps?: {
    isIncompatible?: boolean;
    incompatibleCantMessage?: string;
    incompatibleWontMessage?: string;
  };
}

export interface IBuildCommandBarProps<T extends Model> {
  hasFilter?: boolean;
  includeActions?: CommandBarActions[];
  includeFarActions?: CommandBarActions[];
  excludeActions?: CommandBarActions[];
  getCustomCommandBarItems?: (getSelectedItems: () => Model[]) => IGridPageCommandBarItemProps[];
  filterItems?: IFilterItem[];
  dataQueryProps?: IGridQuery<T>;
  primaryButtonLabel?: FormattedMessage.MessageDescriptor;
  primaryButtonUrl?: string;
  deactivateLabels?: IDestructiveDialogLabelsProps;
  deleteLabels?: IDestructiveDialogLabelsProps;
  searchBoxPlaceholder?: FormattedMessage.MessageDescriptor;
  hideModifyColumnsDescription?: boolean;
  onDeactivateDialogDismiss?: () => void;
  // Returns the deleted items
  onPreDeleteItems?: (items: Model[]) => void;
  // Returns the remaining items after deletion
  onDeleteItems?: (items: Model[]) => void;
  // Returns the deleted items
  onPostDeleteItems?: (items: Model[]) => void;
  onItemsStatusChanged?: (items: Model[], status: LifeCycleStatus) => void;
  onDuplicateItems?: (items: Model[]) => void;
  // tslint:disable-next-line: no-any
  customApplyFilterFn?: (filterDetails: IFilterDetails[], items: any[]) => any[];
}

export interface IDataGridProps extends IShimmeredDetailsListProps {
  gridV2Columns: IGridV2Column[];
  onColumnsChanged?: (columns: IGridV2Column[]) => void;
  onSelectionChanged?: (selectedItems: Model[]) => void;
  identifier?: string;
  defaultSortBy?: string;
  data: Model[];
  selectedRows?: Model[];
  setData?: (data?: Model[]) => void;
  renderZeroItemsComponent?: JSX.Element;
  noEntityFound?: FormattedMessage.MessageDescriptor;
  renderEmptyGrid?: boolean;
  hideDisableStyle?: boolean;
}

export interface IPaginationProps {
  numberOfPages?: number;
  onPageChange?: (pageNumber: number, pageSize: number) => void;
  shouldGridHandlePagination?: boolean;
  showPagination?: boolean;
}

export interface IColumnVisibilityMapping {
  columnKey: string;
  isVisible: boolean;
}

export interface IDestructiveDialogLabelsProps {
  title: FormattedMessage.MessageDescriptor;
  description?: FormattedMessage.MessageDescriptor;
}

export interface IGridQuery<T extends Model> {
  models?: (Model | undefined)[];
  entity: new () => T;
  params?: URLSearchParams;
}

export enum CommandBarActions {
  new = 'new',
  edit = 'edit',
  code = 'code',
  delete = 'delete',
  activate = 'activate',
  deactivate = 'deativate',
  modifyColumns = 'modifyColumns',
  export = 'export',
  duplicate = 'duplicate',
  upload = 'upload',
  download = 'download',
  addFilter = 'addFilter',
  tokenStatus = 'tokenStatus',
  clarity = 'clarity',
  searchBox = 'searchBox',
  custom = 'custom',
}

export interface IGridPageCommandBarItemProps extends IDataGridCommandBarItemProps {
  placement: IGridPageCommandBarPlacement; // relative placement of the command bar item
}

export interface IGridPageCommandBarItemsProps {
  includes?: IGridPageCommandBarItemProps[];
  excludes?: CommandBarActions[];
}

export interface IGridPageCommandBarPlacement {
  direction: Direction;
  key?: CommandBarActions;
  alignment?: CommandBarItemAlignment;
}

export enum Direction {
  before,
  after,
}

export enum CommandBarItemAlignment {
  left,
  right, // will be added to far actions
}

export interface IDataGridCommandBarProps extends ICommandBarProps {
  items: IDataGridCommandBarItemProps[];
}

export interface IDataGridCommandBarItemProps extends ICommandBarItemProps {
  selectionModes?: GridSelectionMode[];
  selectionStatus?: LifeCycleStatus;
}

export enum GridSelectionMode {
  None,
  Single,
  Multiple,
  All,
}

export interface IFilterItem {
  filterName: string;
  /**
   * column name in the API (JSON) response data corresponding to this filter item,
   * Note: Either customFunction or columnName should be passed for filter to be applied
   */
  columnName?: string;
  filterType: FilterType;
  filterOptions?: IDropdownOption[];
  /** Define this function to filter grid using a custom defined function */
  customFunction?: (dataRow: Model, filterDetails: IFilterDetails) => boolean;
  /**
   * If checkbox label is provided then user would see a checkbox while applying the filter.
   * When checkbox is checked filtered results will include rows with NULL/undefined values
   */
  showNullsCheckboxLabel?: string;
  /**
   * Default is false.
   * If true, rows with undefined/null values for this column will also be included in the filtered results
   */
  includeNullsInFilterResults?: boolean;
}

// To be removed -- Used by AdCreativePage
export interface IDataGridColumn {
  columnName: string;
  friendlyName: string;
  headerCSV?: string;
  isVisible: boolean;
  // tslint:disable-next-line: no-any
  exportFunction?: (val: any) => any;
  isExportColumn?: boolean;
  /**
   * (Optional) Provide this function to modify the column data.
   */
  // tslint:disable-next-line: no-any
  displayFunction?: (val: any, item?: any) => any;
}

export enum DownloadOption {
  page,
  selection,
  all,
}
