import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import { getAppStore } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { PageSpinner } from '../../../../components/Spinners';
import { APP_NAME_NEW } from '../../../../constants/AppConstants';
import { injectIntlWithObserver } from '../../../../utils';
import { getSignUpStore } from '../../@data';
import { isAccountTypeOrganization } from '../../@data/store/selectors';
import { NonUsSignupPopup } from '../NonUsSignupPopup';
import messages from './Finish.messages';
import { getClassNames } from './Finish.styles';
import { IFinishProps } from './Finish.types';

export const Finish = injectIntlWithObserver(function FinishComponent({ styleProps, intl }: IFinishProps) {
  const { rootStyle, titleStyle, contentStyle, subcontentStyle } = mergeStyleSets(getClassNames(), styleProps);
  const { formatMessage } = intl;
  const store = getSignUpStore();
  const { userContext } = getAppStore();
  const { email } = userContext || {};
  const { userFirstName, userLastName } = store.active;
  const userFullName = `${userFirstName} ${userLastName}`;
  const { fetchingOvdfStatus, optInPromotionalEmail } = store;

  return (
    <React.Fragment>
      <Stack verticalAlign="space-between" className={rootStyle}>
        <div>
          <div className={titleStyle}>{formatMessage(messages.finishTitle)}</div>
          <div className={subcontentStyle}>{formatMessage(messages.infoTitle)}</div>
        </div>
        <div>
          <div className={contentStyle}>{formatMessage(messages.email)}</div>
          <div className={subcontentStyle}>{email}</div>
        </div>
        <div>
          <div className={contentStyle}>{formatMessage(messages.accountType)}</div>
          <div className={subcontentStyle}>{store.active.accountType}</div>
        </div>
        {isAccountTypeOrganization() && (
          <div>
            <div className={contentStyle}>{formatMessage(messages.orgName)}</div>
            <div className={subcontentStyle}>{store.active.organizationName}</div>
          </div>
        )}
        <div>
          <div className={contentStyle}>{formatMessage(messages.userName)}</div>
          <div className={subcontentStyle}>{userFullName}</div>
        </div>
        <div>
          <div className={contentStyle}>{formatMessage(messages.customizedHelp, { appName: APP_NAME_NEW })}</div>
          <div className={subcontentStyle}>
            {optInPromotionalEmail
              ? formatMessage(messages.customizedHelpYes, { appName: APP_NAME_NEW })
              : formatMessage(messages.customizedHelpNo, { appName: APP_NAME_NEW })}
          </div>
        </div>
        <div>
          <div className={contentStyle}>{formatMessage(messages.paymentTerritory)}</div>
          <div className={subcontentStyle}>{formatMessage(messages.paymentTerritoryDefault)}</div>
        </div>
      </Stack>
      <NonUsSignupPopup />
      {fetchingOvdfStatus && <PageSpinner label={formatMessage(AppMessages.loadingLabel)} />}
    </React.Fragment>
  );
});
