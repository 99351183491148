import { createStore } from 'satcheljs';
import { IAdunitViewState } from './schema/IAdunitViewState';

export const getAdunitStore = createStore<IAdunitViewState>('AdunitState', {
  active: {},
  enablePrivateMarketplace: false,
  errors: {},
  isPanelOpen: false,
  adUnitsRaw: {},
  isSubmittable: false,
  isFetchingAdUnitStatus: false,
  mediationUpdatePool: [],
  scriptProps: undefined,
});
