import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { PublisherTier, getAppStore, onUserFirstTime } from '../../../../@data';
import { APP_NAME_NEW, TYTHON_DOMAIN_REDIRECTION_CACHE_KEY, WELCOME_SEND_FLAG } from '../../../../constants/AppConstants';
import { EmptyLayout } from '../../../../layout/EmptyLayout/EmptyLayout';
import { injectIntlWithObserver } from '../../../../utils';
import { sendWelcomeToPubcenterEmail } from '../../../partner-management/User/@data/actions';
import { triggerOneVetAPIs } from '../@data/actions';
import { setIsFRECardShown } from '../@data/mutatorActions';
import { getStore } from '../@data/store/store';
import { Announcements } from '../components/Announcements/Announcements';
import FAQSection from '../components/FAQSection/FAQSection';
import { FREContainer } from '../components/FRECards/FREContainer';
import { GettingStarted } from '../components/GettingStarted/GettingStarted';
import PerformanceSection from '../components/PerformanceSection/PerformanceSection';
import { RecommendationsSection } from '../components/RecommendationsSection/RecommendationsSection';
import ResourceSection from '../components/ResourcesSection/ResourcesSection';
import messages from './ManagePage.messages';
import { getStackTokens } from './ManagePage.styles';

export const ManagePage = injectIntlWithObserver(function ManagePageComponent(props) {
  const { formatMessage } = props.intl;
  const { isFirstTimeUser, publisher } = getAppStore();

  useEffect(() => {
    if (sessionStorage.getItem(WELCOME_SEND_FLAG) === 'true' && publisher && publisher.publisherTier === PublisherTier.SelfServe3rdParty) {
      sendWelcomeToPubcenterEmail();
    }
  }, [publisher]);

  useEffect(() => {
    triggerOneVetAPIs(isFirstTimeUser ? 'signup' : 'login');
    setIsFRECardShown(isFirstTimeUser || sessionStorage.getItem(TYTHON_DOMAIN_REDIRECTION_CACHE_KEY) === 'true');
    setTimeout(() => {
      sessionStorage.setItem(TYTHON_DOMAIN_REDIRECTION_CACHE_KEY, 'false');
      onUserFirstTime(false);
    }, 1000);
  }, [isFirstTimeUser]);

  return (
    <>
      {getStore().isFRECardShown && <FREContainer />}
      <EmptyLayout headingTitle={formatMessage(messages.welcome, { appName: APP_NAME_NEW })}>
        <Stack enableScopedSelectors tokens={getStackTokens()}>
          <GettingStarted />
          <PerformanceSection />
          <RecommendationsSection />
          <Announcements />
          <FAQSection />
          <ResourceSection />
        </Stack>
      </EmptyLayout>
    </>
  );
});
