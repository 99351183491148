import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { FilterCondition } from '../../../@data/store/schema/enums/FilterCondition';
import { FormItem } from '../../Form';
import messages from './TextFilter.messages';
import { ITextFilterProps } from './TextFilter.types';

export const TextFilter = injectIntl(function TextFilterComponent(props: ITextFilterProps): JSX.Element {
  const { formatMessage } = props.intl;
  const { contains, doesNotContain, beginsWith, equals, doesNotEqual, selectFilter, inputValue } = messages;
  const { condition, value, index, heading, onSelectFunc, onChangeFunc } = props;

  const simpleStringFilterTypeOptions: IDropdownOption[] = [
    { key: FilterCondition.Equals, text: formatMessage(equals) },
    { key: FilterCondition.DoesNotEqual, text: formatMessage(doesNotEqual) },
  ];

  const allStringFilterTypeOptions: IDropdownOption[] = [
    { key: FilterCondition.Contains, text: formatMessage(contains) },
    { key: FilterCondition.DoesNotContain, text: formatMessage(doesNotContain) },
    { key: FilterCondition.BeginsWith, text: formatMessage(beginsWith) },
    { key: FilterCondition.Equals, text: formatMessage(equals) },
    { key: FilterCondition.DoesNotEqual, text: formatMessage(doesNotEqual) },
  ];

  const isSimpleFilter = (name: string) => {
    return name.includes('Id');
  };

  const optionType = isSimpleFilter(heading) ? simpleStringFilterTypeOptions : allStringFilterTypeOptions;

  return (
    <div>
      <React.Fragment key={'activePair' + index}>
        <FormItem formWidth={300}>
          <Dropdown
            placeholder={formatMessage(selectFilter)}
            defaultSelectedKey={condition}
            options={optionType}
            onChange={(_, val) => {
              if (val) {
                onSelectFunc(heading, val, index);
              }
            }}
          />
        </FormItem>
        <FormItem formWidth={300}>
          <TextField
            placeholder={formatMessage(inputValue)}
            defaultValue={value}
            onChange={(_, val) => {
              onChangeFunc(heading, val || '', index);
            }}
          />
        </FormItem>
      </React.Fragment>
    </div>
  );
});
