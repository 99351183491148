import { PUBCENTER_DOMAINS, PUBCENTER_TYTHON_DOMAIN_MAPPING } from '../../constants';
import { isLocationPubCenter } from '../../utils';

export function tythonRedirectionUtils() {
  if (!isLocationPubCenter()) {
    window.addEventListener('message', function (e) {
      if (PUBCENTER_DOMAINS.includes(e.origin) && e.data) {
        const localStorageCache = JSON.parse(e.data);

        Object.keys(localStorageCache)?.forEach((key) => {
          localStorage.setItem(key, localStorageCache[key]);
        });
      }
    });
  } else {
    const newDomainOrigin = PUBCENTER_TYTHON_DOMAIN_MAPPING[window.location.host];
    const iframe = document.createElement('iframe');
    iframe.id = 'redirectionIframe';
    iframe.src = newDomainOrigin;
    iframe.style.display = 'none';
    iframe.style.width = '0';
    iframe.style.height = '0';

    document.body.appendChild(iframe);
  }
}
