/* tslint:disable */
import { AnimationClassNames, getFocusStyle, IStyle } from '@fluentui/react';
import { BreakPoints } from '../../../theme';
import { noPointerEvents } from '../../utils/GlobalStyleUtils';
import { INavStyleProps, INavStyles } from './Nav.types';

export type INavItemStyle = {
  root?: IStyle;
  iconColumn?: IStyle;
  nameColumn?: IStyle;
};

export type IFloatingNavStyle = IStyle & {
  root?: IStyle;
  withChild?: IStyle;
};

const { ResponsiveSmall } = BreakPoints;

const navFontSize = 14;
const navTextColor = '#FFF';
const navWidth = 228;
const navCollapsedWidth = 48;
const shortenedIconWidth = 32;
const navFloatingWidth = 230;
const navItemHeight = 48;
const navChildItemHeight = 32;
const navBackgroundColor = '#EDEBE9';
const floatingNavBackgroundColor = 'rgba(255,255,255,1)';
const navItemHoverColor = '#CCCCCC';
const navGroupSeparatorItemHeight = 40;
const navGroupSeparatorWithGroupNameHeight = 70;
const navItemWithChildBgColor = '#CCCCCC';
const navItemSelectedColor = '#B7B7B7';
const navItemIndentSize = 50;
const navFloatingItemIndentSize = 20;
const BackDropSelector = '@supports (backdrop-filter: blur(20px)) or (-webkit-backdrop-filter: blur(20px))';
const navItemContainerSelector = 'ul';
const navFocusZoneInnerContainerSelector = 'div#navFocusZone>nav';
const navExpandCollapseButtonInnerContainerSelector = 'a#expandCollapseButton';
const navExpandCollapseButtonBackgroundContainerSelector = 'i[data-icon-name="GlobalNavButton"]';

const getResponsiveNavStyles = (isCollapsed = false) => ({
  [ResponsiveSmall]: {
    width: isCollapsed ? 0 : navWidth,
    ...(isCollapsed
      ? {}
      : {
          position: 'absolute',
          height: `calc(100vh - ${navItemHeight}px)`,
        }),
    [navItemContainerSelector]: {
      ...(isCollapsed ? { display: 'none' } : {}),
    },
    [navFocusZoneInnerContainerSelector]: {
      top: 0,
      position: 'fixed',
      width: navWidth,
    },
    [navExpandCollapseButtonInnerContainerSelector]: {
      ...(isCollapsed ? {} : { backgroundColor: 'rgb(237, 235, 233)' }),
      width: isCollapsed ? navCollapsedWidth : navWidth,
    },
    [navExpandCollapseButtonBackgroundContainerSelector]: {
      ...(isCollapsed ? { color: '#ffffff' } : {}),
    },
  },
});

export const getStyles = (props: INavStyleProps): INavStyles => {
  const { isSelected, hasChildren, nestingLevel, isCollapsed, scrollTop, isChildLinkSelected, hasGroupName, theme, groupIndex } = props;

  const isFirstNavGroup = groupIndex === 0;

  return {
    root: {
      width: isCollapsed ? navCollapsedWidth : navWidth,
      backgroundColor: navBackgroundColor,
      color: navTextColor,
      selectors: {
        ul: {
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          fontSize: navFontSize,
          selectors: {
            'li:hover >div': {
              display: 'block',
            },
          },
        },
        a: {
          color: `${navTextColor} !important`,
          outline: 'none',
        },
      },
      ...getResponsiveNavStyles(isCollapsed),
    },
    overlay: {
      [ResponsiveSmall]: {
        ...(isCollapsed
          ? {}
          : {
              width: `calc(100vw - ${navWidth}px)`,
              height: `calc(100vh - ${navItemHeight}px)`,
              backgroundColor: '#000000',
              opacity: 0.3,
              marginLeft: navWidth,
              position: 'absolute',
            }),
      },
    },
    navItemRoot: {
      height: !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      cursor: 'pointer',
      paddingLeft: !!nestingLevel && nestingLevel > 0 ? nestingLevel * navItemIndentSize : 'inherit',
      selectors: {
        ':hover': {
          backgroundColor: hasChildren ? navItemWithChildBgColor : navItemHoverColor,
        },
        ':active': {
          backgroundColor: navItemSelectedColor,
        },
      },
    },
    navItemBarMarker: {
      marginLeft: !!nestingLevel && nestingLevel > 0 && !hasChildren ? '-10px' : '6px',
      marginRight: !!nestingLevel && nestingLevel > 0 && !hasChildren ? '8px' : '0px',
      marginTop: !!nestingLevel && nestingLevel > 0 ? '7px' : '12px',
      width: '2px',
      height: !!nestingLevel && nestingLevel > 0 ? '18px' : '24px',
      backgroundColor: '#0078D4',
      display: isSelected || isChildLinkSelected ? 'inline-block' : 'none',
      borderWidth: 0,
    },
    navItemIconColumn: {
      width: isSelected || isChildLinkSelected ? shortenedIconWidth : navCollapsedWidth,
      fontSize: '16px',
      lineHeight: !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      textAlign: 'center',
      color: '#000000',
      verticalAlign: 'top',
    },
    navItemNameColumn: {
      width: '100%',
      marginLeft: isChildLinkSelected || (!hasChildren && isSelected && !(nestingLevel && nestingLevel > 0)) ? '8px' : '0px',
      lineHeight: !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      verticalAlign: 'top',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: '#000000',
    },
    navSlimItemRoot: {
      selectors: {
        ':hover': {
          backgroundColor: hasChildren ? navItemWithChildBgColor : navItemHoverColor,
        },
      },
    },
    navFloatingRoot: [
      {
        display: 'none',
        zIndex: 1901,
        position: 'absolute',
        marginLeft: navCollapsedWidth,
        marginTop: -navItemHeight - (!!scrollTop && scrollTop > 0 ? scrollTop : 0),
        width: navFloatingWidth,
        color: navTextColor,
        boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.18), 0px 6.4px 14.4px rgba(0, 0, 0, 0.22)',
        backgroundColor: floatingNavBackgroundColor,
        opacity: '0.6',
        selectors: {
          [BackDropSelector]: {
            webkitBackdropFilter: 'blur(20px) saturate(125%)',
            backdropFilter: 'blur(20px) saturate(125%)',
            backgroundColor: 'rgba(255,255,255,.6)',
          },
          a: {
            width: '100%',
            backgroundColor: 'inherit',
          },
        },
      },
      AnimationClassNames.slideRightIn20,
    ],
    navFloatingItemRoot: {
      height: !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      cursor: 'pointer',
      backgroundColor: !(nestingLevel && nestingLevel > 0) ? navItemHoverColor : floatingNavBackgroundColor,
      paddingLeft: navFloatingItemIndentSize,
      selectors: {
        ':hover': {
          backgroundColor: !!nestingLevel && nestingLevel > 0 ? navItemHoverColor : 'unset',
        },
        ':active': {
          backgroundColor: navItemSelectedColor,
        },
      },
    },
    navGroupSeparatorRoot: isFirstNavGroup
      ? {
          width: '100%',
          height: hasGroupName ? navGroupSeparatorItemHeight : undefined,
          textAlign: 'center',
        }
      : {
          width: '100%',
          height: hasGroupName ? navGroupSeparatorWithGroupNameHeight : navGroupSeparatorItemHeight,
          textAlign: 'center',
        },
    navGroupSeparatorHrLine: isFirstNavGroup
      ? undefined
      : {
          position: 'relative',
          height: '20px',
          borderBottom: `1px solid ${navItemWithChildBgColor}`,
        },
    navGroupSeparatorHeaderGroupName: {
      position: 'absolute',
      marginTop: isFirstNavGroup || !hasGroupName ? undefined : '40px',
      left: '16px',
      color: '#000000',
      fontWeight: 'bold',
    },
    navToggler: {
      height: navItemHeight,
      cursor: 'pointer',
      selectors: {
        ':hover': {
          backgroundColor: navItemHoverColor,
        },
      },
      textAlign: 'left',
    },
    disabledStyle: {
      ...noPointerEvents,
      opacity: 0.5,
    },
    focusedStyle: [
      getFocusStyle(theme, undefined, undefined, undefined, 'transparent', undefined),
      {
        display: 'block',
        position: 'relative',
        height: !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
        width: '100%',
        lineHeight: !!nestingLevel && nestingLevel > 0 ? navChildItemHeight : navItemHeight,
      },
    ],
  };
};

/* tslint:enable */
