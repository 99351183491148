import { FormattedMessage, InjectedIntl, MessageValue } from 'react-intl';

export interface IToastNotificationProps {
  items?: IToastNotificationItem[];
  dismissButtonAriaLabel?: string;
  intl: InjectedIntl;
  onRemoved?: (notification: IToastNotificationItem) => void;
}

export interface IToastNotificationItemProps {
  item: IToastNotificationItem;
  intl: InjectedIntl;
}

export interface IToastNotificationItem {
  id: string;
  type: ToastType;
  content: IToastNotificationItemContent;
}

export interface IToastNotificationItemContent {
  text?: string;
  textMessageDescriptor?: FormattedMessage.MessageDescriptor;
  intlMessageValues?: { [key: string]: MessageValue };
  suppressAutoDismiss?: boolean;
  animationTime?: number;
  dismissButtonAriaLabel?: string;
  noDismissButton?: boolean;
  onDismiss?: () => void;
}

export enum ToastType {
  info = 'info',
  error = 'error',
  warning = 'warning',
  success = 'success',
  loading = 'loading',
}

export enum ToastIcon {
  info = 'Info',
  error = 'ErrorBadge',
  warning = 'Info',
  success = 'Completed',
}
