import { action, orchestrator } from 'satcheljs';

import { Log } from '../../../../../../../../../logging/src';
import {
  ApiError,
  NotificationLabel,
  NotificationSeverity,
  Property,
  getAppStore,
  listEntity,
  onNavigateBack,
  onNotificationCardAdded,
  onSavingChanged,
  postEntity,
  putEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../../../../@data';
import notificationMessages from '../../../../../../../../NotificationsPanel.Types.messages';
import { FormState } from '../../../../../../../../components/Form';
import { validateSchema } from '../../../../../../../../utils';
import { RouteName } from '../../../../../../@data';
import { createReturnUrl } from '../../../../../../@data/services/AdManagementService';
import { getAdunitStore } from '../../../../../../Adunit/@data';
import { onAdunitPropertyListChanged } from '../../../../../../Adunit/@data/actions';
import { fetchPropertyById } from '../../../../../@data/services/PropertyService';
import { getGridPropertyStore } from '../../../../../Manage/__data__/store/store';
import PropertyMessages from '../../../../../Property.messages';
import { onFormCleared, onIdChanged, onPropertyErrorChanged, onSettingClarityErrorMessage } from '../../../../__data__/mutatorActions';
import { nonTythonPropertyErrorModel } from '../../../../__data__/store/schema';
import { getCreatePropertyStore } from '../../../../__data__/store/store';

export const onFormSubmitted = action('onFormSubmitted', (mode: FormState, routeHistory?, onSubmit?: () => void) => ({
  mode,
  routeHistory,
  onSubmit,
}));

/**
 * 1) Set Loading to true
 * 2) Submit API call to Submit Data to Store
 * 3) Check errors, if any, send action to notification that error exists
 * 4) Clear the form
 * 5) Navigate back to property list
 */
orchestrator(onFormSubmitted, async ({ mode, routeHistory, onSubmit }) => {
  const { publisher, account, userContext } = getAppStore();
  const { active, raw } = getCreatePropertyStore();

  if (publisher && raw) {
    const body: Property = {
      ...raw,
      id: active.id,
      name: active.name,
    };

    onSavingChanged(true);

    try {
      let properties = getGridPropertyStore().data;

      if (properties === undefined || properties.length === 0) {
        properties = await listEntity([publisher], userContext, Property);
      }

      await validateSchema(nonTythonPropertyErrorModel(active, mode === FormState.Edit, properties), active);
      onPropertyErrorChanged();
    } catch (e) {
      onSavingChanged(false);
      onPropertyErrorChanged(e);
      showErrorToastNotification({
        textMessageDescriptor: PropertyMessages.propertyFormError,
        intlMessageValues: { errorCount: Object.keys(e).length },
      });
      return;
    }

    if (publisher.id) {
      body.publisherId = publisher.id;
    }

    if (active.approvalStatus) {
      body.approvalStatus = active.approvalStatus;
    }

    if (active.url) {
      body.url = active.url;
    }

    body.enableMicrosoftClarity = active.clarity?.clarityLinkingEnabled;
    if (active.clarity?.clarityId) {
      body.clarityProjectId = active.clarity.clarityId;
    }

    body.approvalStatusComments = active.approvalStatusComments ? active.approvalStatusComments : '';
    body.associatedAdUnitIds = active.adunitsSelected ? active.adunitsSelected.map((a) => a.id!) : [];

    try {
      let entity: Property | undefined;

      if (mode === FormState.Edit) {
        entity = await putEntity([publisher], body, userContext, Property);
        showSuccessToastNotification({
          textMessageDescriptor: PropertyMessages.propertyEditSuccess,
          intlMessageValues: { entityId: entity.id },
        });
      } else {
        entity = await postEntity([publisher], Property, body, userContext);
        if (entity && entity.id) {
          onIdChanged(entity.id);

          if (mode === FormState.InPageCreate) {
            const adunitStore = getAdunitStore();

            const property = await fetchPropertyById(publisher, entity.id + '', userContext);
            const adunitPropertyList = [] as Property[];

            if (adunitStore.active.properties) {
              adunitPropertyList.push(...adunitStore.active.properties);
            }
            adunitPropertyList.push(property);
            onAdunitPropertyListChanged(adunitPropertyList);
          }
        }
        showSuccessToastNotification({
          textMessageDescriptor: PropertyMessages.propertyCreateSuccess,
          intlMessageValues: { entityId: entity.id },
        });
      }

      if (mode !== FormState.InPageCreate) {
        const returnUrl = createReturnUrl(
          RouteName.property,
          String(publisher.id),
          (account && String(account.id)) || undefined,
          String(entity.id),
          mode
        );
        if (routeHistory) {
          onNavigateBack(routeHistory, returnUrl);
        }
      }

      onFormCleared();
      onSubmit?.();
    } catch (e) {
      const getClarityErrorMessage = (e: ApiError) => {
        if (e.code === 404) {
          return 'Clarity project ID does not exist';
        } else {
          return e.message.split(`description":"`)[1].split(`"`)[0];
        }
      };

      Log.error(e);
      const apiErrorObj = e as ApiError;
      if (apiErrorObj.code === 404) {
        onSettingClarityErrorMessage(getClarityErrorMessage(apiErrorObj));
      } else {
        onSettingClarityErrorMessage(getClarityErrorMessage(apiErrorObj));
      }

      onNotificationCardAdded({
        // tslint:disable-next-line: no-any
        severity: NotificationSeverity.Error,
        titleMessageDescriptor: notificationMessages.PropertyEditFailedNotificationTitle,
        contentMessageDescriptor: notificationMessages.PropertyEditFailedNotificationContent,
        notificationLabel: NotificationLabel.PropertyEditFailedNotification,
      });
    } finally {
      onSavingChanged(false);
    }
  } else {
    showErrorToastNotification({
      textMessageDescriptor: PropertyMessages.invalidForm,
      intlMessageValues: { formOperation: FormState[mode], entityId: active.id },
    });
  }
});
