import { defineMessages } from 'react-intl';

export default defineMessages({
  acceptInvite: {
    id: 'userpage.acceptInvite',
    defaultMessage: 'Accept invite',
  },
  createUser: {
    id: 'userpage.createUser',
    defaultMessage: 'Create user',
  },
  firstName: {
    id: 'userpage.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'userpage.lastName',
    defaultMessage: 'Last name',
  },
  emailAddress: {
    id: 'userpage.emailAddress',
    defaultMessage: 'E-mail address',
  },
  preferredLanguage: {
    id: 'userpage.preferredLanguage',
    defaultMessage: 'Preferred language',
  },
  accountRole: {
    id: 'userpage.accountRole',
    defaultMessage: 'Account role',
  },
  accountManager: {
    id: 'userpage.accountManager',
    defaultMessage: 'Account manager',
  },
  accountManagerDescription: {
    id: 'userpage.accountManagerDescription',
    defaultMessage: 'Typical pubCenter user',
  },
  reportUser: {
    id: 'userpage.reportUser',
    defaultMessage: 'Report user',
  },
  reportUserDescription: {
    id: 'userpage.reportUserDescription',
    defaultMessage: 'View account information and reporting',
  },
  listManager: {
    id: 'userpage.listManager',
    defaultMessage: 'List manager',
  },
  listManagerDescription: {
    id: 'userpage.listManagerDescription',
    defaultMessage: 'Manage competitive exclusions',
  },
  adViewer: {
    id: 'userpage.adViewer',
    defaultMessage: 'Ad viewer',
  },
  adViewerDescription: {
    id: 'userpage.adViewerDescription',
    defaultMessage: 'Manage properties and competitive exclusions, and view ad units',
  },
  administrator: {
    id: 'userpage.administrator',
    defaultMessage: 'Administrator',
  },
  administratorDescription: {
    id: 'userpage.administratorDescription',
    defaultMessage: 'Manage all aspects of a pubCenter account',
  },
  superAdmin: {
    id: 'userpage.superAdmin',
    defaultMessage: 'Super administrator',
  },
  superAdminDescription: {
    id: 'userpage.superAdminDescription',
    defaultMessage: 'Full access and control of all accounts',
  },
  createPageTitle: {
    id: 'inviteUser.createPageTitle',
    defaultMessage: 'Invite user',
  },
  editPageTitle: {
    id: 'inviteUser.editPageTitle',
    defaultMessage: 'Edit user',
  },
  pageDescription: {
    id: 'inviteUser.pageDescription',
    defaultMessage:
      // tslint:disable-next-line:max-line-length
      'An email invitation will be sent to the identified user.  User will be asked to provide additional information to complete their profile.',
  },
  telephoneNumber: {
    id: 'partnermanagement.editUser.telephoneNumber',
    defaultMessage: 'Telephone number',
  },
  faxNumber: {
    id: 'partnermanagement.editUser.faxNumber',
    defaultMessage: 'Fax number',
  },
  userAccountInfo: {
    id: 'partnermanagement.editUser.userAccountInfo',
    defaultMessage: 'User account information',
  },
  userName: {
    id: 'partnermanagement.editUser.userName',
    defaultMessage: 'User name',
  },
  password: {
    id: 'partnermanagement.editUser.password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'partnermanagement.editUser.confirmPassword',
    defaultMessage: 'Confirm password',
  },
  secretQuestion: {
    id: 'partnermanagement.editUser.secretQuestion',
    defaultMessage: 'Secret question',
  },
  secretAnswer: {
    id: 'partnermanagement.editUser.secretAnswer',
    defaultMessage: 'Secret answer',
  },
  marketingContactSetting: {
    id: 'partnermanagement.editUser.marketingContactSetting',
    defaultMessage: 'Marketing contact settings',
  },
  email: {
    id: 'partnermanagement.editUser.email',
    defaultMessage: 'E-mail',
  },
  phone: {
    id: 'partnermanagement.editUser.phone',
    defaultMessage: 'Phone',
  },
  postalMail: {
    id: 'partnermanagement.editUser.postalMail',
    defaultMessage: 'Postal mail',
  },
  updateUser: {
    id: 'partnermanagement.editUser.updateUser',
    defaultMessage: 'Update user',
  },
  accountMultiSelectPickerAriaDesc: {
    id: 'partnermanagement.editUser.accountMultiSelectPickerAriaDesc',
    defaultMessage: 'Select Accounts',
  },
  useDifferentAccountMessage: {
    id: 'acceptInvite.useDifferentAccountMessage',
    defaultMessage: 'You are signing up with {email}. Need to go back and sign in with another Microsoft account?',
  },
  switchNow: {
    id: 'acceptInvite.switchNow',
    defaultMessage: 'Switch now',
  },
  resendingUserInvitation: {
    id: 'userManagement.resendingUserInvitation',
    defaultMessage: 'Resending user invitation to {email}...',
  },
  resendUserInvitationSuccess: {
    id: 'userManagement.resendUserInvitationSuccess',
    defaultMessage: 'Resend user invitation to {email} successfully',
  },
  deletedUserInvitationSuccess: {
    id: 'userManagement.deletedUserInvitationSuccess',
    defaultMessage: 'Deleted invitation for {firstName} successfully',
  },
});
