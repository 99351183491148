import { createStore } from 'satcheljs';
import { ILoginPageState } from './schema/ILoginPageState';

export const getLoginPageStore = createStore<ILoginPageState>('LoginPageState', {
  errors: {},
  active: {
    email: '',
  },
  loading: true,
  devToken: undefined,
});
