import { classNamesFunction, getId, getTheme, Icon, IProcessedStyleSet, nullRender } from '@fluentui/react';
import * as React from 'react';
import { getAppStore, IGlobalSearchResultItem, onLoadingChanged } from '../../@data';
import { completeAccess, hasReadAccess, UserRoleEntity, userRoleKey } from '../../@data/services/UserRoleService';
import { ResourceType } from '../../@data/store/schema/enums/ResourceType';
import { ResourceTypeDistribution } from '../../@data/store/schema/enums/ResourceTypeDistribution';
import { IGlobalSearchResultData } from '../../@data/store/schema/interfaces/IGlobalSearchResultData';
import { DropdownMenu as MenuAccessPermissions } from '../../@data/store/schema/userrolemodels/DropdownMenu';
import { isTythonSelfServeUser } from '../../@data/utils/checkUserType';
import { Header } from '../../components/Header/Header';
import { IHeaderItem, PanelTypes } from '../../components/Header/Header.types';
import { ISearchProps, ISearchResultList } from '../../components/Search/Search.types';
import { APP_NAME_NEW, APP_NAME_PUBCENTER } from '../../constants/AppConstants';
import { debounce, useCMUIHelpExperience } from '../../hooks';
import { AdManagementRouter } from '../../pages/ad-management/AdManagementRouter';
import { PartnerManagementRouter } from '../../pages/partner-management/PartnerManagementRouter';
import { ReportRouter } from '../../pages/report/ReportRouter';
import { DefaultRoute, RoutePrefix } from '../../Routes';
import { IRoute } from '../../Routes.types';
import { groupBy, injectRouterAndIntlWithObserver, pluralize } from '../../utils';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import {
  FeedbackPanel,
  NotificationsPanel,
  ProfilePanel,
  renderFooterContent,
  renderNotificationFooterContent,
} from '../PageLayout/header-panels';
import { onSearchTriggered, setGlobalSearchResult, setSearchingStatus, setSearchQuery } from './@data/actions/onSearchTriggered';
import './@data/mutators/searchTriggered';
import './@data/orchestrators/searchTriggered';
import { getGlobalSearchCalloutStore } from './@data/store/store';
import messages from './GlobalSearchHeader.messages';
import { getStyles } from './GlobalSearchHeader.styles';
import { IGlobalSearchHeaderProps, IGlobalSearchHeaderStyleProps, IGlobalSearchHeaderStyles } from './GlobalSearchHeader.types';

const theme = getTheme();
const getClassNames = classNamesFunction<IGlobalSearchHeaderStyleProps, IGlobalSearchHeaderStyles>();
const defaultMinLettersToSearch = 3;
const globalSearchBaseRoute = '/search';

const EntityIconMap = {
  Publisher: 'EMI',
  Account: 'Work',
  User: 'Contact',
  AdUnit: 'AdUnit',
  Channel: 'AdUnitChannel',
  Filter: 'Filter',
  WebProperty: 'Favicon',
  PublisherByAccount: 'EMI',
  PublisherByUser: 'EMI',
  PublisherByAdUnit: 'EMI',
  PublisherByWebProperty: 'EMI',
  PublisherByChannel: 'EMI',
  PublisherByFilter: 'EMI',
};

export const GlobalSearchHeader = injectRouterAndIntlWithObserver(
  class extends React.Component<IGlobalSearchHeaderProps> {
    private _classNames: IProcessedStyleSet<IGlobalSearchHeaderStyles>;

    public render(): JSX.Element {
      this._classNames = getClassNames(getStyles, { theme });
      const userContext = getAppStore().userContext;
      let _searchProps: ISearchProps<IGlobalSearchResultItem>;
      const layoutStore = getGlobalSearchCalloutStore();
      let searchResults: ISearchResultList<IGlobalSearchResultItem>[] = [];
      const searchInProgress = layoutStore.searching;
      if (layoutStore.data) {
        searchResults = this._searchResultToCategories(layoutStore.data);
      }
      const notifications = getAppStore().panelNotifications;
      const headerItems: IHeaderItem[] = [
        // {
        //   key: 'settings',
        //   icon: 'Settings',
        //   title: this.props.intl.formatMessage(messages.settings),
        //   panel: SettingsPanel
        // },
        // {
        //   key: 'help',
        //   icon: 'Help',
        //   title: this.props.intl.formatMessage(messages.help),
        //   panel: HelpPanel
        // }
      ];
      if (isTythonSelfServeUser()) {
        headerItems.push({
          key: 'feedback',
          icon: 'Feedback',
          title: this.props.intl.formatMessage(messages.feedback, { appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER }),
          type: PanelTypes.Feedback,
          panel: FeedbackPanel,
          panelProps: {
            isFooterAtBottom: true,
            onRenderFooterContent: renderFooterContent,
          },
          shouldOverflowUnderExtraSmallScreen: true,
        });
      }

      if (userContext && userContext.token) {
        // show notifications only if user is logged in. Icon shouldn't be shown on other login page
        headerItems.push({
          key: 'notifications',
          icon: 'Notifications',
          title: this.props.intl.formatMessage(messages.notifications),
          type: PanelTypes.Notifications,
          panel: NotificationsPanel,
          panelProps: {
            isFooterAtBottom: true,
            onRenderFooterContent: renderNotificationFooterContent,
          },
          shouldOverflowUnderExtraSmallScreen: true,
          showRedDot: notifications && notifications.length > 0,
        });

        headerItems.push({
          key: 'profile',
          title: this.props.intl.formatMessage(messages.account),
          identity: {
            name: userContext?.name ?? '',
            email: userContext?.email ?? '',
          },
          type: PanelTypes.Profile,
          // @ts-ignore
          panel: ProfilePanel,
          shouldOverflowUnderExtraSmallScreen: false,
        });
      }

      const menuAccessPermissions = this._getMenuAccessPermission();

      const newRoutes: IRoute[] = [];

      if (hasReadAccess(menuAccessPermissions === null ? completeAccess : menuAccessPermissions.adManagement)) {
        newRoutes.push({
          key: `/${RoutePrefix.adManagement}`,
          text: 'Ad Management',
          icon: 'DocumentManagement',
          component: AdManagementRouter,
        } as IRoute);
      }

      if (hasReadAccess(menuAccessPermissions === null ? completeAccess : menuAccessPermissions.partnerManagment)) {
        newRoutes.push({
          key: `/${RoutePrefix.partnerManagement}`,
          text: 'Partner Management',
          icon: 'AccountManagement',
          component: PartnerManagementRouter,
        } as IRoute);
      }

      if (hasReadAccess(menuAccessPermissions === null ? completeAccess : menuAccessPermissions.reporting)) {
        newRoutes.push({
          key: `/${RoutePrefix.report}`,
          text: 'Reporting',
          icon: 'CRMReport',
          component: ReportRouter,
        } as IRoute);
      }

      _searchProps = {
        searchBoxProps: {
          placeholder: this.props.intl.formatMessage(messages.searchPlaceHolder, {
            appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER,
          }),
          onChange: debounce(this._onSearchQueryChange, 300),
          onClear: this._onClearSearch,
          searchBoxAriaLabel: this.props.intl.formatMessage(messages.searchBoxAriaLabel),
        },
        minLettersToSearch: defaultMinLettersToSearch,
        searchInProgress: searchInProgress,
        onRenderSearchResult: this._onRenderSearchResult,
        onRenderSearchResultFooter: this._onRenderSearchCalloutFooter,
        searchResultList: searchResults,
        searchResultListAriaLabel: this.props.intl.formatMessage(messages.searchResultListAriaLabel),
      };

      return (
        <Header
          intl={this.props.intl}
          items={headerItems}
          searchProps={_searchProps}
          dropdownItems={newRoutes}
          onNavigate={(path) => this.props.history.push(path)}
          renderLeftIcon={this.props.renderLeftMost}
          useCMUIHelpExperience={useCMUIHelpExperience()}
        />
      );
    }

    private _searchResultToCategories = (searchResult: IGlobalSearchResultData): ISearchResultList<IGlobalSearchResultItem>[] => {
      const retVal: ISearchResultList<IGlobalSearchResultItem>[] = [];
      if (searchResult.results.length > 0) {
        // Group all search results into bucket of entity type
        const searchResultByCategory = groupBy(searchResult.results, (item) => item.resourceType);
        searchResultByCategory.forEach((item) => {
          const entityType = item[0];
          // Create a category of the entity type
          const searchCategory: ISearchResultList<IGlobalSearchResultItem> = {
            categoryName: entityType,
            totalCount: searchResult.resultMetadata ? searchResult.resultMetadata.ResultCountPerResourceType[entityType] : 0,
            searchResults: [],
          };
          const categoryItems = item[1];
          // Create entities and push them to list
          categoryItems.forEach((resultItem) => {
            const searchResultItem: IGlobalSearchResultItem = {
              name: resultItem.resourceName,
              id: resultItem.resourceId,
              icon: EntityIconMap[resultItem.resourceType],
            };
            const publisherId: string = resultItem.propertyBag ? resultItem.propertyBag.PublisherId : '';
            const accountId: string = resultItem.propertyBag ? resultItem.propertyBag.AccountId : '';
            searchResultItem.route = this._createRoutePath(entityType, resultItem.resourceId, publisherId, accountId);
            searchResultItem.onClick = (url: string) => {
              if (resultItem.propertyBag) {
                onLoadingChanged(true);
                this.props.history.push(url);
              }
            };
            searchCategory.searchResults && searchCategory.searchResults.push(searchResultItem);
          });
          retVal.push(searchCategory);
        });
      }
      return retVal;
    };

    private _createRoutePath = (entityType: string, entityId: string, publisherId: string, accountId: string): string => {
      let route = '';

      switch (entityType) {
        case 'AdUnit':
        case 'AdUnitByProperty': {
          if (publisherId && accountId) {
            route = `${DefaultRoute}/${publisherId}-${accountId}/adunit/edit/${entityId}`;
          }
          break;
        }
        case 'Channel': {
          if (publisherId && accountId) {
            route = `${DefaultRoute}/${publisherId}-${accountId}/channel/edit/${entityId}`;
          }
          break;
        }
        case 'WebProperty':
        case 'PropertyByAdUnit': {
          if (publisherId) {
            route = `${DefaultRoute}/${publisherId}/property/edit/${entityId}`;
          }
          break;
        }
        case 'Filter': {
          if (publisherId && accountId) {
            route = `${DefaultRoute}/${publisherId}-${accountId}/adfilter/edit/${entityId}`;
          }
          break;
        }
        case 'Publisher':
        case 'PublisherByAccount':
        case 'PublisherByUser':
        case 'PublisherByAdUnit':
        case 'PublisherByWebProperty':
        case 'PublisherByChannel':
        case 'PublisherByFilter': {
          route = `/${RoutePrefix.partnerManagement}/${entityId}/publisher/edit/${entityId}`;
          break;
        }
        case 'Account': {
          route = `/${RoutePrefix.partnerManagement}/${publisherId}/account/edit/${entityId}`;
          break;
        }
        case 'User': {
          route = `/${RoutePrefix.partnerManagement}/${publisherId}/user/edit/${entityId}`;
          break;
        }
        default: {
          route = `${DefaultRoute}`;
        }
      }
      return route;
    };

    private _onSearchQueryChange = (value: string, minLettersToSearch: number): void => {
      if (value.length >= minLettersToSearch) {
        setSearchQuery(value);
        setSearchingStatus(true);
        const resourceTypes: ResourceType[] = [
          ResourceType.AdUnit,
          ResourceType.Filter,
          ResourceType.Channel,
          ResourceType.WebProperty,
          ResourceType.Publisher,
          ResourceType.Account,
          ResourceType.User,
        ];
        onSearchTriggered(value, resourceTypes, undefined, ResourceTypeDistribution.DynamicBucketSize);
      } else {
        setSearchQuery(value);
        const emptyResult: IGlobalSearchResultData = { results: [] };
        setGlobalSearchResult(emptyResult);
      }
    };

    private _onClearSearch = (): void => {
      setSearchQuery('');
      setSearchingStatus(false);
      const emptyResult: IGlobalSearchResultData = { results: [] };
      setGlobalSearchResult(emptyResult);
    };

    private _onViewMoreResultClicked = (searchText: string, filter?: string): void => {
      let sourceRef = '';
      for (const route in RoutePrefix) {
        if (this.props.location.pathname.search(RoutePrefix[route]) >= 0) {
          sourceRef = RoutePrefix[route];
          break;
        }
      }
      this.props.history.push(encodeURI(`${globalSearchBaseRoute}?q=${searchText}&filter=${filter}&sourceRef=${sourceRef}`));
    };

    private _onRenderSearchResult = (searchResultCategory?: ISearchResultList<IGlobalSearchResultItem>): JSX.Element | null => {
      const searchQuery = getGlobalSearchCalloutStore().searchText;
      const searchResultHeaderDescId = getId('searchResultHeader');

      if (searchResultCategory) {
        return (
          <>
            <div
              className={this._classNames.searchResultHeader}
              tabIndex={-1}
              role="option"
              aria-selected={false}
              onClick={() => this._onViewMoreResultClicked(searchQuery, searchResultCategory.categoryName)}
              onKeyDown={(ev) => {
                if (isButtonPressKey(ev.key)) {
                  this._onViewMoreResultClicked(searchQuery, searchResultCategory.categoryName);
                }
              }}
              aria-describedby={searchResultHeaderDescId}
              data-is-focusable={true}
            >
              {this.props.intl.formatMessage(messages.viewCategoryResult, {
                category: pluralize(searchResultCategory.categoryName),
                totalCount: searchResultCategory.totalCount,
              })}
            </div>
            {searchResultCategory.searchResults
              ? searchResultCategory.searchResults.map((item: IGlobalSearchResultItem, index: number) =>
                  this._onRenderCell(item, searchQuery, index)
                )
              : []}
            <hr className={this._classNames.categorySeparator} />

            <span id={searchResultHeaderDescId} style={getScreenReaderTextStyles()}>
              {this.props.intl.formatMessage(messages.viewCategoryResultsAriaDescription, {
                categoryName: searchResultCategory.categoryName,
              })}
            </span>
          </>
        );
      } else {
        return nullRender();
      }
    };

    private _onRenderCell = (item: IGlobalSearchResultItem, searchQuery: string, index: number): JSX.Element => {
      const cellDescId = getId('searchResult');
      return (
        <div
          className={this._classNames.entityContainer}
          key={'searchResultOption-' + index}
          role="option"
          aria-selected={false}
          tabIndex={-1}
          onClick={() => this._onItemClicked(item)}
          onKeyDown={(ev) => isButtonPressKey(ev.key) && this._onItemClicked(item)}
          aria-describedby={cellDescId}
          data-is-focusable={true}
        >
          <Icon className={this._classNames.entityIcon} iconName={item.icon} />
          <span className={this._classNames.entityInfo}>{this._getHighlightedText(item.name + ' - ' + item.id, searchQuery)}</span>

          <span id={cellDescId} style={getScreenReaderTextStyles()}>
            {this.props.intl.formatMessage(messages.entityInfoButtonAriaDescription)}
          </span>
        </div>
      );
    };

    private _getHighlightedText = (text: string, highlight: string): JSX.Element => {
      const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
      return (
        <span>
          {' '}
          {parts.map((part, i) => (
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
              {part}
            </span>
          ))}{' '}
        </span>
      );
    };

    private _onItemClicked = (item: IGlobalSearchResultItem): void => {
      if (item.onClick && item.route) {
        item.onClick(item.route);
      }
    };

    private _onRenderSearchCalloutFooter = (): JSX.Element => {
      const searchQuery = getGlobalSearchCalloutStore().searchText;
      const searchResult = getGlobalSearchCalloutStore().data;
      const elementId = getId('searchCalloutFooter');

      return (
        <>
          {!!searchResult && searchResult.results!.length > 0 && (
            <div
              className={this._classNames.textLinkFooter}
              role="button"
              tabIndex={-1}
              onClick={() => this._onViewMoreResultClicked(searchQuery, 'all')}
              onKeyDown={(ev) => isButtonPressKey(ev.key) && this._onViewMoreResultClicked(searchQuery, 'all')}
              aria-describedby={elementId}
              data-is-focusable={true}
            >
              {this.props.intl.formatMessage(messages.viewAllResult, { searchQuery: searchQuery })}
            </div>
          )}
          {!searchResult ||
            (searchResult.results!.length === 0 && searchQuery.length >= defaultMinLettersToSearch && (
              <div className={this._classNames.footer} data-is-focusable>
                {this.props.intl.formatMessage(messages.viewNoResult, { searchQuery: searchQuery })}
              </div>
            ))}
          <span id={elementId} style={getScreenReaderTextStyles()}>
            {this.props.intl.formatMessage(messages.footerAriaDescription)}
          </span>
        </>
      );
    };

    private _getMenuAccessPermission(): MenuAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.DropdownMenu) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }
  }
);
