import { defineMessages } from 'react-intl';

export default defineMessages({
  approvalStatus: {
    id: 'admanagement.property.approvalStatus',
    defaultMessage: 'Approval status',
  },
  approved: {
    id: 'admanagement.property.approved',
    defaultMessage: 'Approved',
  },
  autoApproved: {
    id: 'admanagement.property.autoApproved',
    defaultMessage: 'Auto-approved',
  },
  rejected: {
    id: 'admanagement.property.rejected',
    defaultMessage: 'Rejected',
  },
  manualreview: {
    id: 'admanagement.property.manualreview',
    defaultMessage: 'Under manual review',
  },
});
