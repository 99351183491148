import { Icon } from '@fluentui/react';
import React, { useEffect, useMemo } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { AuditLog } from '../../../@data';
import { injectIntlAndObserver } from '../../../utils';
import { isButtonPressKey } from '../../../utils/AccessibilityUtils';
import { getAuditHistoryStore } from './@data/store/store';
import messages from './AuditHistory.messages';
import { getStyles } from './AuditHistory.styles';
import { getActionDetails } from './utils';

export interface IActionDetailProps extends InjectedIntlProps {
  auditLog: AuditLog;
  index: number;
}

export const ActionDetail = injectIntlAndObserver(({ intl: { formatMessage }, auditLog, index }: IActionDetailProps) => {
  const { isShowAllDetailsEnabled } = getAuditHistoryStore();
  const styles = getStyles();

  // eslint-disable-next-line
  const actionDetails = useMemo(() => getActionDetails(auditLog, formatMessage), [auditLog]);
  const [showDetails, setShowDetails] = React.useState(isShowAllDetailsEnabled);

  useEffect(() => setShowDetails(isShowAllDetailsEnabled), [isShowAllDetailsEnabled]);

  return (
    <div>
      <span>
        {actionDetails.title || ''}{' '}
        {actionDetails.changes?.length > 0 && (
          <span
            onClick={() => setShowDetails(!showDetails)}
            onKeyDown={(ev) => isButtonPressKey(ev.key) && setShowDetails(!showDetails)}
            tabIndex={0}
          >
            <span className={styles.showDetails} role="button">
              {formatMessage(showDetails ? messages.hideDetails : messages.showDetails)}
            </span>
            <span className={styles.showDetailsIcon}>
              {showDetails ? <Icon iconName="ChevronDown" /> : <Icon iconName="ChevronRight" />}
            </span>
          </span>
        )}
      </span>
      {showDetails && actionDetails.changes?.length > 0 && (
        <ul className={styles.actionDetailsList}>
          {actionDetails.changes?.map((change, index2) => (
            <li className={styles.actionDetailsListItem} key={`actionDetailRow-${index}-${index2}`}>
              {change}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});
