import { defineMessages } from 'react-intl';

export default defineMessages({
  loadAccountStatusFailed: {
    id: 'loadAccountStatus.failed',
    defaultMessage: 'We’re unable to load your account status.',
  },
  failedToLoadPublisherStatus: {
    id: 'accountData.failedToLoadPublisherStatus',
    defaultMessage: 'Failed to fetch publisher status. Please retry after sometime.',
  },
});
