import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class Channel extends Model {
  public className = UserRoleEntity.Channel;
  public name: number;
  public id: number;
  public adUnitList: number;
  public adFilters: number;

  constructor(params: Channel) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: Channel, channel: Channel) {
    // tslint:disable:no-bitwise
    this.accessPermission |= channel.accessPermission;
    this.adFilters |= channel.adFilters;
    this.adUnitList |= channel.adUnitList;
    this.id |= channel.id;
    this.name |= channel.name;
    // tslint:enable:no-bitwise
  }
}
