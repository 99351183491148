import { FormState } from './FormState';

const { Create, View, Edit, InPageCreate, InPageView, InPageEdit, MultiStepCreation, MultiStepEdit } = FormState;

export function isEditing(formState: FormState): boolean {
  return [Edit, InPageEdit, MultiStepEdit].includes(formState);
}

export function isCreating(formState: FormState): boolean {
  return [Create, InPageCreate, MultiStepCreation].includes(formState);
}

export function isViewing(formState: FormState): boolean {
  return [View, InPageView].includes(formState);
}

export function isInPageForm(formState: FormState): boolean {
  return [InPageCreate, InPageView, InPageEdit].includes(formState);
}
