import { TextField } from '@fluentui/react';
import React, { useEffect, useRef } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { isTythonSelfServeUser } from '../../../../../../@data';
import AppMessages from '../../../../../../App.messages';
import { FormItem, FormState } from '../../../../../../components/Form';
import { PROPERTY_DOMAIN_INPUT } from '../../../../../../constants/DataTestIdConstants';
import { StepState } from '../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { injectIntlAndObserver } from '../../../../../../utils';
import { onPropertyErrorChanged, onUrlChanged } from '../../__data__/mutatorActions';
import { getCreatePropertyStore } from '../../__data__/store/store';
import messages from './messages';

export interface IPropertyUrlInputFieldProps {
  mode: FormState;
  stepState?: StepState;
}

const PropertyUrlInputField = injectIntlAndObserver<InjectedIntlProps & IPropertyUrlInputFieldProps>(
  ({ intl: { formatMessage }, mode, stepState }) => {
    const {
      active: { url },
      errors,
    } = getCreatePropertyStore();
    const { url: error } = errors;
    const inputRef = useRef(null);
    const pattern = new RegExp(/^(?!https?:\/\/)(?!.*\/)[\w.-]+\.[\w-]{2,}$/i);
    const prefixStyle = { prefix: { padding: '0px 5px' }, field: { padding: '0px 2px' } };

    useEffect(() => {
      if (inputRef.current === null) {
        return;
      }
      const container = document.getElementById('propertyUrl') as HTMLElement;
      const label = container.getElementsByClassName('ms-Label')[0] as HTMLElement;
      if (label) {
        label.title = formatMessage(AppMessages.required);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef.current]);

    return (
      <FormItem id="propertyUrl">
        <TextField
          label={formatMessage(isTythonSelfServeUser() ? messages.siteUrl : messages.websiteUrl)}
          required={true}
          errorMessage={error}
          value={url}
          disabled={
            [FormState.Edit, FormState.MultiStepEdit].includes(mode) ||
            (stepState === StepState.Dirty && mode === FormState.MultiStepCreation)
          }
          placeholder={formatMessage(messages.websiteUrlExample)}
          onChange={(_, value) => {
            onUrlChanged(value!);
            if (value) {
              onPropertyErrorChanged({
                ...errors,
                url: pattern.test(value) ? undefined : formatMessage(messages.websiteUrlInvalidMsg),
              });
            }
          }}
          componentRef={inputRef}
          prefix="https://"
          styles={prefixStyle}
          data-test-id={PROPERTY_DOMAIN_INPUT}
        />
      </FormItem>
    );
  }
);

export default PropertyUrlInputField;
