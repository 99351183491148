import { IDropdownStyles } from '@fluentui/react';
import { BreakPoints, IPubcenterSpacing } from '../../../theme';
import { IPublisherAccountPickerStyleProps, IPublisherAccountPickerStyles } from './PublisherAccountPicker.types';

const { ResponsiveMedium, ResponsiveSmall } = BreakPoints;

export const getStyles = (props: IPublisherAccountPickerStyleProps): IPublisherAccountPickerStyles => {
  const { theme, isAccountDisabled } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    root: [
      'ms-PublisherAccountPicker',
      {
        display: 'flex',
        paddingLeft: spacing.size32,
        backgroundColor: theme.palette.neutralLight,
        height: 47,
        // minWidth: 1100, // This causes horizontal scrollbar to appear in smaller desktop screens
        [ResponsiveSmall]: {
          flexDirection: 'column',
          height: 96,
          paddingLeft: 0,
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    ],
    listGroup: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing.l1,
      width: '38vw',
      [ResponsiveMedium]: {
        width: '28vw',
      },
      [ResponsiveSmall]: {
        marginRight: 0,
        width: '100vw',
        alignItems: 'center',
      },
    },
    publisherPicker: ['ms-PublisherPicker'],
    accountPicker: ['ms-AccountPicker'],
    subComponentStyles: {
      dropdown: ({
        root: [
          {
            display: 'flex',
            flexDirection: 'row',
            height: 46,
            width: 'auto',
            color: theme.palette.neutralQuaternary,
          },
        ],
        dropdown: [
          {
            selectors: {
              '&:focus $title': {
                borderColor: theme.palette.neutralQuaternary,
              },
              '&:hover $ms-Dropdown-title': {
                borderColor: theme.palette.neutralQuaternary,
                backgroundColor: 'transparent',
              },
            },
          },
          !isAccountDisabled && {
            selectors: {
              '&:hover $title': {
                borderColor: theme.palette.themePrimary,
                backgroundColor: theme.palette.white,
              },
              '&:focus $title': {
                borderColor: theme.palette.themePrimary,
                backgroundColor: theme.palette.white,
              },
            },
          },
        ],
        label: [
          {
            marginRight: spacing.size8,
            paddingBottom: spacing.size12,
            paddingTop: spacing.size12,
          },
        ],
        title: [
          {
            flex: 1,
            backgroundColor: 'transparent',
            width: 320,
            height: '100%',
            border: 0,
            borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
            paddingBottom: 13,
            paddingTop: spacing.size8,
          },
        ],
        caretDownWrapper: [
          {
            height: 44,
            width: 44,
            textAlign: 'center',
            lineHeight: 44,
            cursor: 'pointer',
          },
        ],
      } as unknown) as IDropdownStyles,
    },
  };
};
