import { defineMessages } from 'react-intl';

export default defineMessages({
  createPageTitle: {
    id: 'partnermanagement.publisher.createPageTitle',
    defaultMessage: 'Create publisher',
  },
  editPageTitle: {
    id: 'partnermanagement.publisher.editPageTitle',
    defaultMessage: 'Edit publisher',
  },
  updatePublisher: {
    id: 'partnermanagement.publisher.updatePublisher',
    defaultMessage: 'Update publisher',
  },
  partner: {
    id: 'partnermanagement.publisher.partner',
    defaultMessage: 'Partner',
  },
  publisherName: {
    id: 'partnermanagement.publisher.publisherName',
    defaultMessage: 'Publisher name',
  },
  publisherId: {
    id: 'partnermanagement.publisher.publisherId',
    defaultMessage: 'Publisher id',
  },
  networkAffiliationLabel: {
    id: 'partnermanagement.publisher.networkAffiliationLabel',
    defaultMessage: 'Network affiliation',
  },
  networkAffiliationOptionPartnerOwned: {
    id: 'partnermanagement.publisher.networkAffiliationOptionPartnerOwned',
    defaultMessage: 'Partner owned (O&O)',
  },
  networkAffiliationOptionSyndicated: {
    id: 'partnermanagement.publisher.networkAffiliationOptionSyndicated',
    defaultMessage: 'Syndicated partner',
  },
  publisherAddressTitle: {
    id: 'partnermanagement.publisher.publisherAddressTitle',
    defaultMessage: 'Publisher address',
  },
  publisherAddressLine2: {
    id: 'partnermanagement.publisher.publisherAddressLine2',
    defaultMessage: 'Publisher address line 2',
  },
  allowedMarketsForAllTitle: {
    id: 'partnermanagement.publisher.allowedMarketsForAllTitle',
    defaultMessage: 'Allowed markets for all accounts',
  },
  allowedMarketsForSelectedTitle: {
    id: 'partnermanagement.publisher.allowedMarketsForSelectedTitle',
    defaultMessage: 'Allowed markets for selected accounts',
  },
  createAnAccountTitle: {
    id: 'partnermanagement.publisher.createAnAccountTitle',
    defaultMessage: 'Create an account',
  },
  createAnAccountInfo: {
    id: 'partnermanagement.publisher.createAnAccountInfo',
    defaultMessage: 'At least one account is required for all new publishers.  A new Account ID will be created for this account.',
  },
  accountName: {
    id: 'partnermanagement.publisher.accountName',
    defaultMessage: 'Account Name',
  },
  contentDeliverySetting: {
    id: 'partnermanagement.publisher.contentDeliverySetting',
    defaultMessage: 'Account content delivery settings',
  },
  adCountryPolicy: {
    id: 'partnermanagement.publisher.adCountryPolicy',
    defaultMessage: 'Ad country/region policy',
  },
  adLanguage: {
    id: 'partnermanagement.publisher.adLanguage',
    defaultMessage: 'Ad Language',
  },
  createPublisher: {
    id: 'partnermanagement.publisher.createPublisher',
    defaultMessage: 'Create publisher',
  },
  editPublisher: {
    id: 'partnermanagement.publisher.editPublisher',
    defaultMessage: 'Save changes',
  },
  microsoft: {
    id: 'partnermanagement.publisher.microsoft',
    defaultMessage: 'Microsoft',
  },
  yahoo: {
    id: 'partnermanagement.publisher.yahoo',
    defaultMessage: 'Yahoo',
  },
  advancedpublihserattributes: {
    id: 'partnermanagement.publisher.advancedpublisherattributes',
    defaultMessage: 'Advanced publisher attributes',
  },
  publishertier: {
    id: 'partnermanagement.publisher.publisherTier',
    defaultMessage: 'Publisher tier',
  },
  publisherTierInternalPremium: {
    id: 'partnermanagement.publisher.publisherTier.internalPremium',
    defaultMessage: 'Internal premium',
  },
  publisherTierManagedPremium: {
    id: 'partnermanagement.publisher.publisherTier.managedPremium',
    defaultMessage: 'Managed premium',
  },
  publishertierSelfServeStandard: {
    id: 'partnermanagement.publisher.publisherTier.selfServeStandard',
    defaultMessage: 'Self-serve standard',
  },
  publishertierSelfServe3rdParty: {
    id: 'partnermanagement.publisher.publisherTier.SelfServe3rdParty',
    defaultMessage: 'Self Serve 3rd Party',
  },
  publishertierSelfServe1stParty: {
    id: 'partnermanagement.publisher.publisherTier.SelfServe1stParty',
    defaultMessage: 'Self Serve 1st Party',
  },
  publishertierSelfServe1stPartyBoost: {
    id: 'partnermanagement.publisher.publisherTier.SelfServe1stPartyBoost',
    defaultMessage: 'Self Serve 1st Party Boost',
  },
  publisherFeatureOverrideTier: {
    id: 'partnermanagement.publisher.featureOverrideTier',
    defaultMessage: 'Publisher override tier',
  },
  publisherFeatureOverrideTierInternalPremium: {
    id: 'partnermanagement.publisher.featuretier.internalPremium',
    defaultMessage: 'Internal premium',
  },
  publisherFeatureOverrideTierManagedPremium: {
    id: 'partnermanagement.publisher.featuretier.managedPremium',
    defaultMessage: 'Managed premium',
  },
  publisherFeatureOverrideTierSelfServeStandard: {
    id: 'partnermanagement.publisher.featuretier.selfServeStandard',
    defaultMessage: 'Self-serve standard',
  },
  publisherFeatureOverrideTierAPI: {
    id: 'partnermanagement.publisher.featuretier.API',
    defaultMessage: 'API',
  },
  publisherFeatureOverrideTierSelfServeExperiment: {
    id: 'partnermanagement.publisher.featuretier.selfserveexperiment',
    defaultMessage: 'Self-serve experiment',
  },
  publisherFeatureOverrideTierSelfServeSearch: {
    id: 'partnermanagement.publisher.featuretier.selfserveSearch',
    defaultMessage: 'Self-serve search',
  },
  publisherEntityLimit: {
    id: 'partnermanagement.publisher.entitylimit',
    defaultMessage: 'Select entity limit tier',
  },
  publisherEntityLimitInfinite: {
    id: 'partnermanagement.publisher.entitylimit.infinite',
    defaultMessage: 'Infinite',
  },
  publisherEntityLimitSmall: {
    id: 'partnermanagement.publisher.entitylimit.small',
    defaultMessage: 'Small',
  },
  publisherEntityLimitMedium: {
    id: 'partnermanagement.publisher.entitylimit.medium',
    defaultMessage: 'Medium',
  },
  publisherEntityLimitLarge: {
    id: 'partnermanagement.publisher.entitylimit.large',
    defaultMessage: 'Large',
  },
  publisherEntityLimitNetwork: {
    id: 'partnermanagement.publisher.entitylimit.network',
    defaultMessage: 'Network',
  },
  publisherServiceLevel: {
    id: 'partnermanagement.publisher.servicelevel',
    defaultMessage: 'Service level',
  },
  publisherServiceLevelPremium: {
    id: 'partnermanagement.publisher.servicelevel.premium',
    defaultMessage: 'Premium',
  },
  publisherServiceLevelSelfServe: {
    id: 'partnermanagement.publisher.servicelevel.selfserve',
    defaultMessage: 'Self-serve',
  },
  publisherServiceLevelSelfServeTrusted: {
    id: 'partnermanagement.publisher.servicelevel.selfservetrusted',
    defaultMessage: 'Self-serve trusted',
  },
  publisherServiceLevelInternal: {
    id: 'partnermanagement.publisher.servicelevel.internal',
    defaultMessage: 'Internal',
  },
  publisherServiceLevelSelect: {
    id: 'partnermanagement.publisher.servicelevel.select',
    defaultMessage: 'Select',
  },
  publisherAllowedFeatures: {
    id: 'partnermanagement.publisher.allowedfeatures',
    defaultMessage: 'Allowed for this publisher',
  },
  publisherAllowedFeaturesWebAdUnits: {
    id: 'partnermanagement.publisher.allowedfeatures.webadunits',
    defaultMessage: 'Web ad units',
  },
  publisherAllowedFeaturesMobileSearchAdunit: {
    id: 'partnermanagement.publisher.allowedfeatures.MobileSearchAdunit',
    defaultMessage: 'Mobile search ad units',
  },
  publisherAllowedFeaturesDistributionReport: {
    id: 'partnermanagement.publisher.allowedfeatures.DistributionReport',
    defaultMessage: 'Distribution reporting',
  },
  publisherAllowedFeaturesDistributionV2Report: {
    id: 'partnermanagement.publisher.allowedfeatures.DistributionV2Report',
    defaultMessage: 'Distribution V2 reporting',
  },
  publisherAllowedFeaturesStartDistributionReport: {
    id: 'partnermanagement.publisher.allowedfeatures.StartDistributionReport',
    defaultMessage: 'Start Distribution reporting',
  },
  publisherAllowedFeaturesStartDistributionContentReport: {
    id: 'partnermanagement.publisher.allowedfeatures.StartDistributionContentReport',
    defaultMessage: 'Start Distribution Content reporting',
  },
  publisherEnableDefaultTythonV2Report: {
    id: 'partnermanagement.publisher.allowedfeatures.EnableDefaultTythonV2Report',
    defaultMessage: 'Default Tython V2 reporting',
  },
  publisherAllowedFeaturesSelectTierReport: {
    id: 'partnermanagement.publisher.allowedfeatures.SelectTierReport',
    defaultMessage: 'Select Tier reporting',
  },
  publisherAllowedFeaturesNativeReport: {
    id: 'partnermanagement.publisher.allowedfeatures.NativeReport',
    defaultMessage: 'Native reporting',
  },
  publisherAllowedFeaturesMediationReport: {
    id: 'partnermanagement.publisher.allowedfeatures.MediationReport',
    defaultMessage: 'RTB/Mediation reporting',
  },
  publisherAllowedFeaturesSyndicationReport: {
    id: 'partnermanagement.publisher.allowedfeatures.SyndicationReport',
    defaultMessage: 'Syndication reporting',
  },
  publisherAllowedFeaturesPropertyAutoApproved: {
    id: 'partnermanagement.publisher.allowedfeatures.PropertyAutoApproved ',
    defaultMessage: 'Property auto approved',
  },
  publisherAllowedFeaturesPaidSearchReportingApi: {
    id: 'partnermanagement.publisher.allowedfeatures.PaidSearchReportingApi ',
    defaultMessage: 'PaidSearch Reporting API',
  },
  publisherAllowedFeaturesPaidSearchDashboard: {
    id: 'partnermanagement.publisher.allowedfeatures.PaidSearchDashboard ',
    defaultMessage: 'PaidSearch Dashboard',
  },
  publisherAllowedFeaturesDynamicSites: {
    id: 'partnermanagement.publisher.allowedfeatures.DynamicSites',
    defaultMessage: 'Dynamic sites / app registration',
  },
  publisherIPAddress: {
    id: 'partnermanagement.publisher.ipaddress',
    defaultMessage: 'IP address ranges for API authentication',
  },
  publisherIPAddressRange: {
    id: 'partnermanagement.publisher.ipaddressrange',
    defaultMessage: 'Create IP address range',
  },
  publisherAdCallFeatures: {
    id: 'partnermanagement.publisher.adcallfeatures',
    defaultMessage: 'Allowed ad call features',
  },
  publisherAdCallFeaturesAskPrice: {
    id: 'partnermanagement.publisher.adcallfeatures.askprice',
    defaultMessage: 'Ask Price',
  },
  publisherAdCallFeaturesOnBehalf: {
    id: 'partnermanagement.publisher.adcallfeatures.onbehalf',
    defaultMessage: 'On behalf',
  },
  publisherAdCallFeaturesReferrer: {
    id: 'partnermanagement.publisher.adcallfeatures.referer',
    defaultMessage: 'Referrer',
  },
  publisherAdCallFeaturesPaidSearchAPI: {
    id: 'partnermanagement.publisher.adcallfeatures.paidsearchAPI',
    defaultMessage: 'Paid search API',
  },
  publisherAdCallFeaturesOutputType: {
    id: 'partnermanagement.publisher.adcallfeatures.outputtype',
    defaultMessage: 'Output type',
  },
  publisherAdCallFeaturesLogImpression: {
    id: 'partnermanagement.publisher.adcallfeatures.logimpression',
    defaultMessage: 'Log impression',
  },
  publisherAdCallFeaturesIPForwording: {
    id: 'partnermanagement.publisher.adcallfeatures.ipforwarding',
    defaultMessage: 'IP Forwarding',
  },
  publisherAdCallFeaturesNetworkOptimizer: {
    id: 'partnermanagement.publisher.adcallfeatures.networkoptimizer',
    defaultMessage: 'Network optimizer',
  },
  publisherAdCallFeaturesAdECN: {
    id: 'partnermanagement.publisher.adcallfeatures.adecn',
    defaultMessage: 'AdECN',
  },
  publisherAdCallFeaturesContextualAPI: {
    id: 'partnermanagement.publisher.adcallfeatures.contextualapi',
    defaultMessage: 'Contextual API',
  },
  IPAddressCreateTitle: {
    id: 'partnermanagement.publisher.IPAddressCreateTitle',
    defaultMessage: 'Create IP address range',
  },
  IPAddressEditTitle: {
    id: 'partnermanagement.publisher.IPAddressEditTitle',
    defaultMessage: 'Edit IP address range',
  },
  IPAddressRangeName: {
    id: 'partnermanagement.publisher.IPAddressRangeName',
    defaultMessage: 'IP address range name',
  },
  IPAddressMinimum: {
    id: 'partnermanagement.publisher.IPAddressMinimum',
    defaultMessage: 'Minimum IP address',
  },
  IPAddressMaximum: {
    id: 'partnermanagement.publisher.IPAddressMaximum',
    defaultMessage: 'Maximum IP address',
  },
  IPAddressType: {
    id: 'partnermanagement.publisher.IPAddressType',
    defaultMessage: 'Type',
  },
  IPAddressIPV4: {
    id: 'partnermanagement.publisher.IPAddressIPV4',
    defaultMessage: 'IPV4',
  },
  IPAddressIPV6: {
    id: 'partnermanagement.publisher.IPAddressIPV6',
    defaultMessage: 'IPV6',
  },
  publisherAllowedFeaturesDesc: {
    id: 'partnermanagement.publisher.publisherAllowedFeaturesDesc',
    defaultMessage: 'Select the features to be allowed for publisher',
  },
});
