import { Dropdown } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { PropertyApprovalStatus } from '../../../../../../../../@data';
import { completeAccess, hasReadAccess } from '../../../../../../../../@data/services/UserRoleService';
import { FormItem, FormState } from '../../../../../../../../components/Form';
import { injectIntlAndObserver } from '../../../../../../../../utils';
import { getCreatePropertyStore } from '../../../../__data__/store/store';
import { onApprovalStatusChanged } from '../../__data__/mutatorActions';
import { GetPropertyAccessPermission } from '../../utils/GetPropertyAccessPermissions';
import { IsDisabled } from '../../utils/IsFieldDisabled';
import messages from './messages';
import { GetApprovalStatusOptions } from './utils/GetApprovalStatusOptions';

export interface IPropertyApprovalStateFieldProps {
  mode: FormState;
}

const PropertyApprovalStateField = injectIntlAndObserver<InjectedIntlProps & IPropertyApprovalStateFieldProps>(
  ({ intl: { formatMessage }, mode }): JSX.Element | null => {
    const { active, errors } = getCreatePropertyStore();
    const propertyAccessPermissions = GetPropertyAccessPermission();
    const accessLevel = propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.approvalStatus;

    if (!hasReadAccess(accessLevel)) {
      return null;
    }

    return (
      <FormItem>
        <Dropdown
          label={formatMessage(messages.approvalStatus)}
          required={true}
          defaultSelectedKey={active.approvalStatus}
          selectedKey={active.approvalStatus}
          errorMessage={errors.approvalStatus}
          options={GetApprovalStatusOptions(formatMessage)}
          onChange={(_, value) => onApprovalStatusChanged(value?.key as PropertyApprovalStatus)}
          disabled={IsDisabled(mode, accessLevel)}
        />
      </FormItem>
    );
  }
);

export default PropertyApprovalStateField;
