import { classNamesFunction } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../theme';
import { IManagePageStyleProps, IManagePageStyles } from './ManagePage.types';

export const getStyles = (props: IManagePageStyleProps): IManagePageStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { fonts, spacing, palette } = theme;

  return {
    gridContainer: {
      marginLeft: spacing.size32,
      marginTop: spacing.size32,
      marginRight: spacing.size32,
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      flex: 1,
    },
    loadingGrid: {
      margin: 'auto',
    },
    title: {
      ...fonts.size20,
    },
    hoverCardHost: {
      display: 'flex',
      alignItems: 'center',
    },
    actionButtonsContainer: {
      display: 'flex',
      gap: '12px',
      height: '100%',
    },
    monetizationUnderline: {
      borderBottom: `1px dotted ${palette.themePrimary}`,
      width: 'fit-content',
      '&:focus, &:hover': {
        textDecoration: 'underline',
        display: 'block',
        border: '1px solid #A9A9A9',
      },
    },
    mediationHelpIcon: {
      color: palette.themePrimary,
      padding: `0px ${spacing.size8} px`,
      cursor: 'pointer',
    },
    onBoardingMsg: {
      background: palette.themeLighterAlt,
    },
  };
};

export const getClassNames = classNamesFunction<IManagePageStyleProps, IManagePageStyles>();
