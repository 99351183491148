import { Environment } from "./Environment";

export class LoggingUtils {
  public static getEnvironment(): string {
    const environment = process.env.REACT_APP_ENV;
    if (environment !== undefined) {
      return environment;
    }
    return Environment.TEST;
  }
}
