import { orchestrator } from 'satcheljs';
import {
  NotificationLabel,
  NotificationSeverity,
  getAppStore,
  onNotificationCardAdded,
  onSavingChanged,
  postEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../../@data';
import { Feedback } from '../../../../../../@data/store/schema/models/Feedback';
import notificationMessages from '../../../../../../NotificationsPanel.Types.messages';
import ToastMessages from '../../../../../../Toasts.messages';
import { validateSchema } from '../../../../../../utils';
import { onFormSubmitted } from '../actions';
import { onFeedbackPanelErrorChanged, onFormCleared } from '../mutatorActions';
import { feedbackPanelErrorModel } from '../store/schema/FeedbackPanelErrorModel';
import { getFeedbackPanelStore } from '../store/store';

orchestrator(onFormSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { active } = getFeedbackPanelStore();

  if (publisher) {
    // check if we need to create body
    const body: Feedback = {
      ...active,
      apiPath: undefined,
      className: 'feedback',
    };
    try {
      await validateSchema(feedbackPanelErrorModel(), active);
      onFeedbackPanelErrorChanged();
    } catch (e) {
      onFeedbackPanelErrorChanged(e);
      return;
    }
    onSavingChanged(true);
    try {
      await postEntity([publisher], Feedback, body, userContext);

      showSuccessToastNotification({ textMessageDescriptor: ToastMessages.feedBackSuccessMessage });

      onFormCleared();
      msg.hidePanel?.();
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: notificationMessages.FeedbackErrorMessageContent });
      onNotificationCardAdded({
        // tslint:disable-next-line: no-any
        severity: NotificationSeverity.Error,
        titleMessageDescriptor: notificationMessages.FeedbackErrorMessageTitle,
        contentMessageDescriptor: notificationMessages.FeedbackErrorMessageContent,
        notificationLabel: NotificationLabel.FeedbackErrorMessage,
      });
    } finally {
      onSavingChanged(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: notificationMessages.FeedBackInvalidFormMessageContent });
    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      titleMessageDescriptor: notificationMessages.FeedBackInvalidFormMessageTitle,
      contentMessageDescriptor: notificationMessages.FeedBackInvalidFormMessageContent,
      notificationLabel: NotificationLabel.FeedBackInvalidFormMessage,
    });
  }
});
