import { MessageBarType } from '@fluentui/react';
import {
  AdSenseAdUnitStatus,
  AdSenseConnectAdUnit,
  onNotificationChanged,
  postEntity,
  showSuccessToastNotification,
} from '../../../../../@data';
import { onAdunitMediationUpdateEnd, onAdunitMediationUpdateStart } from '../../@data/actions';
import { updateAdUnitGrid } from '../../@data/services/AdunitService';
import messages from '../CreateAdunit/messages';

const adUnitNotConnectedStatus: AdSenseAdUnitStatus = {
  apiPath: 'preauth/mediation',
  className: 'getAdUnitMediationStatus',
  mediationStatus: false,
};

export const enableTythonAdUnitMediation = async (ids: number[], onRequestSuccess?: () => void): Promise<AdSenseAdUnitStatus> => {
  const request: AdSenseConnectAdUnit = {
    apiPath: 'preauth/mediation',
    className: 'pubcenterAdUnitsIds',
    pubcenterAdUnitIds: ids,
  };
  const urlParamsConnect = new URLSearchParams();
  urlParamsConnect.append('routeSuffix', 'api/mediation/admanager/setupAdUnitMediation');
  try {
    onAdunitMediationUpdateStart(ids);
    const response = await postEntity([], AdSenseConnectAdUnit, request, undefined, urlParamsConnect, undefined);
    if (!response) {
      onNotificationChanged({ messageBarType: MessageBarType.error, textMessageDescriptor: messages.settingUpAdunitMediationFailed });
      return adUnitNotConnectedStatus;
    } else {
      showSuccessToastNotification({ textMessageDescriptor: messages.settingUpAdunitMediationSuccess });
      // only 1 ad unit will be updated for mediation, so first one in the array is the source of truth
      updateAdUnitGrid(ids[0], true, true);
      onRequestSuccess?.();
      return response;
    }
  } catch {
    return adUnitNotConnectedStatus;
  } finally {
    onAdunitMediationUpdateEnd(ids);
  }
};
