import { orchestrator } from 'satcheljs';
import {
  NotificationLabel,
  NotificationSeverity,
  getAppStore,
  onNotificationCardAdded,
  showErrorToastNotification,
} from '../../../../../@data';
import { getEstimatedEarnings } from '../../../../../@data/services/PaymentsService';
import { PaymentsPageEstimatedEarningsRequestParams } from '../../../../../@data/store/schema/models/PaymentPageEstimatedEarningsRequestParams';
import notificationMessages from '../../../../../NotificationsPanel.Types.messages';
import { ChartGrain } from '../../Payments/EstimatedEarnings/EstimatedEarnings.types';
import { setEstimatedEarningsDataAction, setEstimatedEarningsIsDataLoadedAction } from '../actions';
import { fetchPaymentsPageEstimatedEarnings } from '../actions/actions';

orchestrator(fetchPaymentsPageEstimatedEarnings, async ({ grain }) => {
  const userContext = getAppStore().userContext;
  const publisher = getAppStore().publisher;

  try {
    const localeDate = new Date();
    const startYear = grain === ChartGrain.YEARLY ? localeDate.getUTCFullYear() - 6 : localeDate.getUTCFullYear();
    const startMonth = grain === ChartGrain.MONTHLY ? localeDate.getUTCMonth() - 6 : localeDate.getUTCMonth();
    const startDate = new Date(startYear, startMonth, localeDate.getUTCDate(), 0, 0, 0);
    const endDate = new Date(localeDate.getUTCFullYear(), localeDate.getUTCMonth(), localeDate.getUTCDate(), 0, 0, 0);
    const startDateString = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
    const endDateString = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
    const requestParams: PaymentsPageEstimatedEarningsRequestParams = {
      grain,
      startDate: startDateString,
      endDate: endDateString,
    };
    const data = await getEstimatedEarnings(publisher?.id!, requestParams, userContext!);
    setEstimatedEarningsDataAction(data, grain);
    setEstimatedEarningsIsDataLoadedAction(true, grain);
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: notificationMessages.PayoutInformationFailedContent });
    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      titleMessageDescriptor: notificationMessages.PayoutInformationFailedTitle,
      contentMessageDescriptor: notificationMessages.PayoutInformationFailedContent,
      notificationLabel: NotificationLabel.PayoutInformationFailed,
    });
  }
});
