import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { PaginationDisplay } from './Pagination.types';
import { DottedPagination } from './Themes/DottedPagination/DottedPagination';
import { IUsePaginationProps } from './Themes/DottedPagination/useDottedPagination';
import { NavigatedPagination } from './Themes/NavigatedPagination/NavigatedPagination';

export interface IPaginationProps extends IUsePaginationProps, InjectedIntlProps {
  display?: PaginationDisplay;
  className?: string;
  styleProps?: React.CSSProperties;
  onPageNumberChange?: (page: number) => void;
  onPageSizeChange?: (page: number) => void;
  onPageChange?: (page: number) => void;
}

export const Pagination = (props: IPaginationProps) => {
  const getPagination = () => {
    switch (props.display) {
      case PaginationDisplay.Navigation:
        return <NavigatedPagination {...props} />;
      default:
        return <DottedPagination {...props} />;
    }
  };
  return getPagination();
};
