import { getTheme } from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { LayoutMode, onLayoutChanged } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { Tabs } from '../../../../components/Tabs';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../utils/ReactUtils';
import { getClassNames, getStyles } from './ManagePage.styles';
import { UserInvitationsGrid } from './UserInvitations/UserInvitationsGrid';
import { UsersGrid } from './Users/UsersGrid';

export const ManagePage = injectIntlWithObserver((props: IRouterWithIntl) => {
  const { formatMessage } = props.intl;
  const classNames = getClassNames(getStyles, { theme: getTheme() });

  useEffect(() => {
    onLayoutChanged(LayoutMode.Default);
  }, []);

  return (
    <div className={classNames.root}>
      <Tabs
        tabs={[
          {
            key: 'users',
            headerText: formatMessage(AppMessages.users),
            component: <UsersGrid {...props} />,
          },
          {
            key: 'userInvitations',
            headerText: formatMessage(AppMessages.userInvitations),
            component: <UserInvitationsGrid {...props} />,
          },
        ]}
      />
    </div>
  );
});
