import React from 'react';
import { AuditLog, UserRole } from '../../../../@data';
import { IGridV2Column } from '../../../../layout/GridPageV2';
import { getFormattedUserRole } from '../../UserManagement/utils/userRoleUtils';
import { getAuditHistoryStore } from '../@data/store/store';
import { ActionDetail } from '../ActionDetails';
import messages from '../AuditHistory.messages';
import { getEntityTypeDisplayMap } from './convertAttributeValues';
import { getActionDetails } from './getActionDetails';

export function getGridColumns(formatMessage, locale): IGridV2Column[] {
  const { isShowAllDetailsEnabled } = getAuditHistoryStore();
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });

  return [
    {
      columnOrder: 1,
      name: formatMessage(messages.dateTime),
      key: 'dateTime',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isSorted: true,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      suppressSorting: false,
      onRender: (item: AuditLog) => (item && item.modifiedAt ? formatter.format(new Date(item.modifiedAt)) : ''),
      exportFunction: (item: AuditLog) => (item && item.modifiedAt ? formatter.format(new Date(item.modifiedAt)) : ''),
    },
    {
      columnOrder: 2,
      fieldName: 'userEmail',
      name: formatMessage(messages.userEmail),
      key: 'userEmail',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSorted: true,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 3,
      name: formatMessage(messages.userRole),
      key: 'userRole',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isSorted: true,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      suppressSorting: false,
      onRender: (item: AuditLog) => {
        return item?.userRoles?.map((role) => getFormattedUserRole(formatMessage, role as UserRole)).join(', ') ?? '';
      },
      exportFunction: (item: AuditLog) => {
        return item?.userRoles?.map((role) => getFormattedUserRole(formatMessage, role as UserRole)).join(', ') ?? '';
      },
    },
    {
      columnOrder: 4,
      name: formatMessage(messages.actionType),
      key: 'actionType',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isSorted: true,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      suppressSorting: false,
      onRender: (item: AuditLog) => {
        return item ? formatMessage(messages[item.auditType.toString().toLowerCase()]) : '';
      },
      exportFunction: (item: AuditLog) => {
        return item ? formatMessage(messages[item.auditType.toString().toLowerCase()]) : '';
      },
    },
    {
      columnOrder: 5,
      name: formatMessage(messages.actionOn),
      key: 'actionOn',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isSorted: true,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      suppressSorting: false,
      onRender: (item: AuditLog) => (item.entityType ? getEntityTypeDisplayMap(item.entityType, formatMessage) : ''),
      exportFunction: (item: AuditLog) => (item.entityType ? getEntityTypeDisplayMap(item.entityType, formatMessage) : ''),
    },
    {
      columnOrder: 6,
      name: formatMessage(messages.actionDetails),
      key: 'actionDetails',
      minWidth: 300,
      maxWidth: isShowAllDetailsEnabled ? 1000 : 300,
      isResizable: true,
      isSorted: true,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      suppressSorting: false,
      onRender: (item: AuditLog, index: number) => {
        return <ActionDetail auditLog={item} index={index} />;
      },
      searchFunction: (item: AuditLog, searchText: string) => {
        const actionDetails = getActionDetails(item, formatMessage);
        return JSON.stringify(actionDetails).toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      },
      exportFunction: (item: AuditLog) => {
        const actionDetails = getActionDetails(item, formatMessage);
        return JSON.stringify(actionDetails);
      },
    },
  ];
}
