export const xandrAdProvidersToNames = {
  2: 'Xandr Invest',
  8: 'MediaMath',
  13: 'OwnerIQ',
  17: 'Roku OneView',
  18: 'Zeta DSP',
  20: 'Amobee',
  33: 'Peer39',
  34: 'Epsilon',
  44: 'Quantcast',
  48: 'Proximic',
  52: 'Criteo',
  54: 'iPromote',
  65: 'eyeReturn',
  66: 'Simpli.fi',
  74: 'ClickDistrict (Platform 161 / Verve DSP)',
  78: 'Accordant Media, LLC',
  82: 'The Trade Desk',
  87: 'Buzzlogic',
  89: 'Grapeshot',
  91: 'AdForm',
  94: 'AdGear',
  96: 'Media.Net',
  98: 'DoubleVerify',
  100: 'sociomantic labs GmbH',
  101: 'Display & Video 360',
  107: 'Bidtheatre',
  112: 'Delta Projects',
  114: 'AcuityAds',
  115: 'Bering Data Provider',
  120: 'Integral Ad Science',
  122: 'Dealer.com',
  123: 'Vizury Interactive Solutions Pvt. Ltd.',
  125: 'FOutClient01Bidder',
  129: 'Platform Services Test Bidder',
  133: 'Basis Technologies',
  137: 'Semcasting',
  139: 'adtheorent_bidder',
  145: 'AdPilot',
  158: 'Adobe - Display',
  160: 'AppNexus Testing Data Provider Bidder',
  164: 'LiquidM Technology GmbH',
  172: 'NextRoll',
  177: 'Adloox (Data Provider)',
  179: 'El Toro (Data Provider)',
  180: 'Crimson Tangerine Ltd.',
  182: 'shong',
  183: 'Yahoo DSP',
  188: 'Amazon Advertising',
  194: 'Mediarithmics',
  197: 'mbr targeting GmbH',
  216: 'Mobile Listening Bidder',
  218: 'The Weather Channel, LLC (Data Provider)',
  222: 'Rockerbox, Inc',
  225: 'MaxPoint Interactive',
  226: '4Info, Inc.',
  228: 'BlueCava, Inc.',
  230: 'TradeLab (Data Provider)',
  236: 'Opt Out Advertising B.V.',
  240: 'Ubimo',
  245: 'Media IQ Digital Ltd',
  247: 'Addictive Mobility',
  254: 'Active Agent',
  256: 'Platform Integrations Test RTDP (Data Provider)',
  259: 'dw_bidder_capacity',
  260: 'Triapodi LTD',
  264: 'Samba RDP',
  268: 'Civolution B.V. (Data Provider)',
  271: 'Accordant Media, LLC - 2',
  276: 'So-net Media Networks',
  283: 'adsquare GmbH',
  290: 'AppNexus Floor Optimization',
  291: 'Deqwas Inc.',
  297: 'LDMobile (netADge)',
  301: 'Adserver Test (Data Provider)',
  302: 'remerge bidder',
  304: 'Factual Inc (Data Provider)',
  305: 'Exponential Interactive, Inc.',
  306: 'AdDaptive Intelligence, Inc.',
  313: 'TradeLab2',
  315: 'RTB House',
  319: 'A.Mob',
  320: 'Travel Audience GmbH',
  326: 'Integral Ad Science (Data Provider 2)',
  327: 'AppNexus Programmable Data (Data Provider)',
  328: 'StackAdapt',
  329: 'Accordant Media, LLC',
  331: 'Beeswax',
  332: 'Mediasmart Mobile S.L.',
  333: 'Bidtellect',
  335: 'Emerse Sverige AB',
  337: 'Schibsted',
  341: 'Mindlytix SAS',
  347: 'Digital To Store Limited',
  349: 'apd_scylla',
  351: 'Adelphic',
  353: 'Jaduda GmbH',
  359: 'Biga Bid Media Ltd',
  360: 'Chalk Digital, Inc.',
  363: 'Kobler AS',
  368: 'TAPTAP Networks, S.L',
  370: 'Blis Media Ltd.',
  373: 'LinkedIn Bidder',
  375: 'RhythmOne LLC',
  376: 'Mobile Professionals B.V.',
  384: 'Appier Inc.',
  385: 'Kontekstno-mediynye tekhnologii',
  386: 'Nanigans Bidder',
  388: 'Bidswitch',
  389: 'AdElement, Inc.',
  390: 'Adello Group AG',
  394: 'Localsensor B.V.',
  397: 'Adtube AS',
  399: 'Zemanta',
  402: 'Lerta Business Inc.',
  405: 'Hawk (TabMo SAS)',
  408: 'Smadex SL',
  421: 'AN Data Science Internal 1',
  423: 'Smartology',
  425: 'Mediakeys Platform',
  427: 'Adobe - Video',
  430: 'Yandex Europe AG',
  431: 'Kika Tech Inc',
  433: 'ZypMedia, Inc. (Compulse)',
  435: 'ReadPeak',
  437: 'SundaySky, Inc.',
  438: 'M1D (BidSwitch)',
  440: 'YDN',
  442: 'Knorex Pte. Ltd.',
  443: 'Prebid Server',
  446: 'Dynalyst',
  448: 'US3',
  449: 'OC GROUP TECNOLOGIA DA INFORMACAO LTDA',
  451: 'National Media Connection',
  453: 'Pocketmath, Inc.',
  454: 'digiseg apn gateway',
  457: 'YJ Test',
  461: 'Beemray OY (Data Provider)',
  462: 'appnexus-video',
  463: 'DeepIntent, Inc.',
  464: 'SMARTSTREAM.TV GmbH',
  465: 'ITN Holdings, LLC',
  466: 'Craid Inc.',
  467: 'NetSeer, Inc. (Data Provider)',
  468: 'Eyeview Inc.',
  471: 'SQUARED (Data Provider Test 12)',
  472: 'Semasio GmbH (Data Provider)',
  473: 'Axel Springer Teaser Ad GmbH',
  474: "DO NOT USE - Kayla's test",
  480: 'Mnet_bidder_2.0',
  481: 'PLAYGROUND XYZ (Data Provider)',
  482: 'MadHive Inc.',
  483: 'MSAN',
  484: 'AdLede (Data Provider)',
  488: 'Emetriq GmbH RT (Data Provider)',
  490: 'test-1',
  492: 'test (can be deleted)',
  493: 'test2 (can be deleted)',
  494: 'PulsePoint, Inc.',
  508: 'Nano Interactive GmbH (Data Provider)',
  512: 'Test1Bdr',
  514: 'Test2',
  522: 'Silver Bullet Media Services (Data Provider)',
  523: 'Inmar-OIQ, LLC',
  524: 'GCN INC',
  526: 'GUM GUM Inc (Data Provider)',
  528: 'Qwarry (Data Provider)',
  529: 'Baidu (Hong Kong) Limited',
  530: 'Cognitiv',
  534: 'Ericson Emodo',
  535: 'Stirista_Bidder',
  537: 'OctillionMediaLLC',
  539: 'Sirdata SAS (Data Provider)',
  541: 'Seedtag Advertising SL (Data Provider)',
  542: 'ALLIANCE GRAVITY DATA MEDIA (Data Provider)',
  543: 'Adelaide Metrics Inc. (Data Provider)',
  544: 'MGID Inc',
  545: 'Audigent (Data Provider)',
  546: 'Cognitiv Corp, a Delaware corp (Data Provider)',
  548: 'MSAN Test',
  550: 'Proxistore (Data Provider)',
  551: 'CTV AppNexus Programmable Data (Data Provider)',
  552: 'non CTV AppNexus Programmable Data (Data Provider)',
  553: 'GDMServices, Inc. d/b/a Bidmind',
  554: 'DealerX',
  555: 'Predictive Audiences RealTime APD',
  556: 'MNTN',
  557: 'Ravel Technologies (Data Provider)',
  558: 'Audigent (Test)',
  559: 'Choreograph RTDP (Data Provider)',
  560: 'Decide',
  561: 'Goldfish Ads, LLC (Data Provider)',
};
