import { createStore } from 'satcheljs';
export interface IScriptPageState {
  copyScriptIcon: string;
  clarityProjectId: string;
}

export const getPropertyScriptPageStore = createStore<IScriptPageState>('PropertyScriptPageState', {
  copyScriptIcon: 'Copy',
  clarityProjectId: '',
});
