import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Filter,
  FilterScope,
  getAppStore,
  onNavigateBack,
  onNotificationChanged,
  onSavingChanged,
  postEntity,
  putEntity,
} from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { stringToList, validateSchema } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/services/AdManagementService';
import { onFilterErrorChanged, onFormCleared, onFormIdChanged, onFormSubmitted } from '../actions';
import { filterErrorModel } from '../store/schema/FilterErrorModel';
import { getFilterStore } from '../store/store';

orchestrator(onFormSubmitted, async (msg) => {
  const { publisher, userContext, account } = getAppStore();
  const { active, raw } = getFilterStore();

  // TODO: Figure out how to internationalize orchestrators. #1632176
  let notification: INotificationBarItem = {};

  if (publisher && account && raw) {
    const body: Filter = {
      ...raw,
      id: active.id,
      name: active.name,
    };

    let excludedUrls, excludedAdKeywords, excludedQueryKeywords;
    if (active.filterItemsEntryType === 'manualEntry') {
      excludedUrls = active.excludedUrls;
      excludedAdKeywords = active.excludedAdKeywords;
      excludedQueryKeywords = active.excludedQueryKeywords;
    } else {
      excludedUrls = active.appendExcludedUrls;
      excludedAdKeywords = active.appendExcludedAdKeywords;
      excludedQueryKeywords = active.appendExcludedQueryKeywords;
    }

    try {
      await validateSchema(filterErrorModel(), active);
      onFilterErrorChanged();
    } catch (e) {
      onFilterErrorChanged(e);
      onNotificationChanged({
        text: `Filter - You have ${Object.keys(e).length} missing fields, please fill them to continue.`,
        messageBarType: MessageBarType.severeWarning,
      });
      return;
    }
    onSavingChanged(true);

    body.filterScope = active.filterType;
    body.scopeId = active.filterType === FilterScope.Account ? account.id : publisher.id;
    body.associatedChannelIds =
      active.channelsSelected && active.filterType === FilterScope.Account ? active.channelsSelected.map((m) => m.id) : [];

    const blockedAdKeywordsList: string[] = stringToList(excludedAdKeywords);
    body.blockedAdKeywords = blockedAdKeywordsList;

    const excludedQueryKeywordsList: string[] = stringToList(excludedQueryKeywords);
    body.blockedQueryKeywords = excludedQueryKeywordsList;

    const excludedUrlsList: string[] = stringToList(excludedUrls);
    body.blockedUrls = excludedUrlsList;

    body.appendOnly = active.appendOnly;

    const timeZoneOffset = new Date().getTimezoneOffset();
    body.startTimeStamp =
      active.filterStartTime !== undefined
        ? Math.floor((active.filterStartTime!.getTime() - timeZoneOffset * 60 * 1000) / 1000)
        : undefined;
    body.endTimeStamp =
      active.filterEndTime !== undefined ? Math.floor((active.filterEndTime!.getTime() - timeZoneOffset * 60 * 1000) / 1000) : undefined;

    try {
      let entity: Filter | undefined;

      if (msg.mode === FormState.Edit) {
        entity = await putEntity([publisher], body, userContext, Filter);

        notification = {
          text: `Edited ad filter ${entity.id} success`,
          messageBarType: MessageBarType.success,
        };
      } else {
        entity = await postEntity([publisher], Filter, body, userContext);

        if (entity && entity.id) {
          onFormIdChanged(entity.id);
        }

        notification = {
          text: `Created new ad filter ${entity.id} success`,
          messageBarType: MessageBarType.success,
        };
      }

      // return to manage page
      const returnUrl = createReturnUrl(RouteName.adfilter, String(publisher.id), String(account.id));
      onNavigateBack(msg.routeHistory, returnUrl);
      onFormCleared();
    } catch (e) {
      notification = {
        text: `Submitting the adfilter form "${FormState[msg.mode]}" caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
    } finally {
      onSavingChanged(false);
    }
  } else {
    notification = {
      text: `Adfilter form "${FormState[msg.mode]}" is invalid id ${active.id}`,
      messageBarType: MessageBarType.warning,
    };
  }

  onNotificationChanged(notification);
});
