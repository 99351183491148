import { AppealHistory } from '../../../../../../@data';

export interface IAppealStateState {
  isDocumentUploadPanelOpen?: boolean;
  documentUploadStatus: DocumentUploadStatus[];
  appealHistory?: AppealHistory[];
}

export enum DocumentUploadStatus {
  NotStarted = 1,
  InProgress,
  Uploaded,
  ActionRequired,
}
