import { IPropertySitePickerStyleProps, IPropertySitePickerStyles } from './PropertySitePicker.types';

export const getStyles = (props: IPropertySitePickerStyleProps): IPropertySitePickerStyles => {
  const { theme } = props;

  return {
    searchBox: {
      width: 440,
      height: 32,
      marginTop: theme.spacing.s1,
      borderRadius: '2px',
      textDecorationColor: theme.palette.green,
    },
    list: {
      flex: 1,
      overflowY: 'auto',
    },
    listItem: {
      padding: theme.spacing.s1,
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.neutralLight,
        },
      },
    },
    listItemLabel: [
      theme.fonts.medium,
      {
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    ],
    searchContent: [
      {
        icon: {
          display: 'none',
        },
        iconContainer: {
          display: 'none',
        },
        field: {
          padding: '0px 4px',
        },
      },
    ],
  };
};
