import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class AdBlock extends Model {
  public className = UserRoleEntity.AdBlock;
  public enablePublisherBlocks: number;
  public enableSiteBlocks: number;
  public editDomainBlocks: number;
  public editCategoryBlocks: number;

  constructor(params: AdBlock) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: AdBlock, adBlock: AdBlock) {
    // tslint:disable:no-bitwise
    this.accessPermission |= adBlock.accessPermission;
    this.enablePublisherBlocks |= adBlock.enablePublisherBlocks;
    this.enableSiteBlocks |= adBlock.enableSiteBlocks;
    this.editDomainBlocks |= adBlock.editDomainBlocks;
    this.editCategoryBlocks |= adBlock.editCategoryBlocks;
    // tslint:enable:no-bitwise
  }
}
