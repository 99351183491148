import { defineMessages } from 'react-intl';

export default defineMessages ({
  addAllButtonAriaDescription: {
    id: 'multiSelectPicker.addAllButtonDescription',
    defaultMessage: 'Click to add all available items'
  },
  removeAllButtonAriaDescription: {
    id: 'multiSelectPicker.removeAllButtonAriaDescription',
    defaultMessage: 'Click to remove all selected items'
  },
  filteredItemsLabel: {
    id: 'multiSelectPicker.filteredItemsLabel',
    defaultMessage: 'Available items: {count}',
  },
  selectedItemsLabel: {
    id: 'multiSelectPicker.selectedItemsLabel',
    defaultMessage: 'Selected: {count}',
  },
  createButtonAriaDesciption: {
    id: 'multiSelectPicker.CreateButtonAriaDescription',
    defaultMessage: 'Click this button to {label} within this page'
  },
  searchBoxPlaceholder: {
    id: 'multiSelectPicker.seachBoxPlaceholder',
    defaultMessage: 'Search available items',
  },
  addIconAriaLabel:{
    id: 'multiSelectPicker.addIconAriaLabel',
    defaultMessage: 'Add item',
  },
  removeIconAriaLabel: {
    id: 'multiSelectPicker.removeIconAriaLabel',
    defaultMessage: 'Remove item',
  },
  removeAllButtonLabel: {
    id: 'multiSelectPicker.removeAll',
    defaultMessage: 'Remove all',
  },
  addAllButtonLabel: {
    id: 'multiSelectPicker.addAll',
    defaultMessage: 'Add all',
  },
  availableItemsListBoxAriaLabel: {
    id:'multiSelectPicker.availableItemsListBoxAriaLabel',
    defaultMessage: 'Available items',
  },
  selectedItemsPaneAriaLabel: {
    id: 'multiSelectPicker.selectedItemsPaneAriaLabel',
    defaultMessage: 'Selected items'
  }
});