import { History } from 'history';
import { action } from 'satcheljs';
import { FormState } from '../../../../../../../../../../components/Form';

export const onScriptPageInitialized = action('onScriptPageInitialized', (publisherId: string, propertyId: string) => ({
  publisherId,
  propertyId,
}));

export const onScriptPageSubmitted = action('onScriptPageSubmitted', (mode: FormState, routeHistory: History, onSubmit?: () => void) => ({
  mode,
  routeHistory,
  onSubmit,
}));

export const onFormCleared = action('onFormCleared');

export const onClarityProjectChanged = action('onClarityProjectChanged', (id: string) => ({ id }));

export const onCopyScriptIconChanged = action('onCopyScriptIconChanged', (iconName: string) => ({ iconName }));
