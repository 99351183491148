import { FontWeights, getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../../../theme';
import { flexWithCenter, noPointerEvents } from '../../../../../../utils/GlobalStyleUtils';

interface IPaginationStyleProps {
  theme: ITheme;
}

type StyleProperties = 'containerStyle' | 'itemStyle' | 'dotsStyle' | 'selectedItemStyle' | 'disabledItemStyle';
export type PaginationStyles = Record<StyleProperties, string>;

export const getStyles = (props: IPaginationStyleProps): PaginationStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette } = theme;

  const buttonStyles = {
    ...flexWithCenter,
    width: 32,
    height: 32,
    minWidth: 32,
    margin: spacing.size4,
    fontWeight: FontWeights.semibold,
  };

  return mergeStyleSets({
    containerStyle: {
      ...flexWithCenter,
      margin: spacing.size16,
    },
    itemStyle: {
      ...buttonStyles,
      ':hover': {
        background: palette.neutralLighter,
      },
    },
    dotsStyle: {
      ...buttonStyles,
      ...noPointerEvents,
    },
    selectedItemStyle: {
      background: palette.themePrimary,
      color: palette.white,
      ...noPointerEvents,
    },
    disabledItemStyle: {
      opacity: 0.5,
      ...noPointerEvents,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): PaginationStyles {
  return getStyles({ theme: getTheme(), ...props });
}
