import { Property, showErrorToastNotification } from '../../../../../../../@data';
import { validateSchema } from '../../../../../../../utils';
import PropertyMessages from '../../../../Property.messages';
import { onPropertyErrorChanged } from '../../../__data__/mutatorActions';
import { IPropertyViewModel, tythonPropertyErrorModel } from '../../../__data__/store/schema';
import { getCreatePropertyStore } from '../../../__data__/store/store';

export const isFormValid = async (
  properties: Property[],
  isEditMode: boolean,
  active: IPropertyViewModel = getCreatePropertyStore().active
): Promise<boolean> => {
  try {
    await validateSchema(tythonPropertyErrorModel(active, isEditMode, properties), active);
    onPropertyErrorChanged();
    return true;
  } catch (e) {
    onPropertyErrorChanged(e);
    showErrorToastNotification({
      textMessageDescriptor: PropertyMessages.propertyFormError,
      intlMessageValues: { errorCount: Object.keys(e).length },
    });
    return false;
  }
};
