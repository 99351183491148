import { Model, getAccessPermission, getAppStore } from '../../../../../../@data';
import { hasCreateAccess } from '../../../../../../@data/services/UserRoleService';
import { CommandBarActions, Direction, GridSelectionMode, IBuildCommandBarProps } from '../../../../../../layout/GridPageV2';
import { getStore } from '../../../@data';
import { onDevTokenPanelShown, onFetchDevTokenStatus, onGenerateDevTokenStatus } from '../../../@data/actions';
import messages from '../../ManagePage.messages';
import { IDevTokenPanelParams } from '../../ManagePage.types';

export function getCommandBarItems(formatMessage): IBuildCommandBarProps<Model> | undefined {
  const { publisher } = getAppStore();
  const { users, isFetchingUsersLoading } = getStore();

  const commandBarItems =
    (users && users.length !== 0 && publisher) || isFetchingUsersLoading
      ? {
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.edit,
            CommandBarActions.delete,
            CommandBarActions.tokenStatus,
            CommandBarActions.download,
            CommandBarActions.modifyColumns,
          ],
          getCustomCommandBarItems: (getSelectedItems) => {
            const accessPermission = getAccessPermission(getSelectedItems[0]);

            return [
              {
                key: CommandBarActions.tokenStatus,
                name: formatMessage(messages.tokenStatusLabel),
                ariaLabel: formatMessage(messages.tokenStatusLabel),
                iconProps: {
                  iconName: 'BranchCompare',
                },
                placement: { direction: Direction.after },
                onClick: () => {
                  const items = getSelectedItems();
                  if (items) {
                    onFetchDevTokenStatus(items[0]);
                    const devTokenPanelDisplayParams: IDevTokenPanelParams = {
                      /* tslint:disable:no-string-literal */
                      userName: items[0]['username'],
                      email: items[0]['email'],
                      developerToken: items[0]['developerToken'],
                      panelClosed: false,
                      SpinnerText: 'Fetching Developer token...',
                      onGenerateDevToken: () => onGenerateDevTokenStatus(items[0]),
                    };
                    onDevTokenPanelShown(devTokenPanelDisplayParams);
                  }
                },
                selectionModes: [GridSelectionMode.Single],
                disabled: !hasCreateAccess(accessPermission),
              },
            ];
          },
        }
      : undefined;

  return commandBarItems;
}
