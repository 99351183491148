import { BreakPoints, IPubcenterSpacing } from '../../../theme';
import { IListPickerStyleProps, IListPickerStyles } from './ListPicker.types';

const { ResponsiveMedium } = BreakPoints;

export const getStyles = (props: IListPickerStyleProps): IListPickerStyles => {
  const { theme, closeButtonIsDisabled } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    root: [
      'ms-ListPicker',
      {
        display: 'flex',
        flexDirection: 'row',
      },
    ],
    basePicker: {
      backgroundColor: 'transparent',
      selectors: {
        '.ms-BasePicker-text': {
          height: 46,
          position: 'relative',
          border: 0,
          borderBottom: `1px solid`,
          borderColor: theme.palette.neutralQuaternary,
          width: 320,
          selectors: {
            ':hover': {
              borderColor: theme.palette.neutralQuaternary,
            },
          },
          [ResponsiveMedium]: {
            width: 'calc(28vw - 60px)',
          },
        },
        '.ms-BasePicker-input': {
          backgroundColor: theme.palette.neutralLight,
          height: '100%',
          border: 0,
          borderBottom: `1px solid`,
          borderColor: theme.palette.neutralQuaternary,
          selectors: {
            ':hover': {
              borderColor: theme.palette.themePrimary,
              backgroundColor: theme.palette.white,
            },
            ':focus': {
              backgroundColor: theme.palette.white,
              borderColor: theme.palette.themePrimary,
            },
          },
        },
        '.ms-Callout-container': {
          minWidth: 320,
          width: 320,
        },
      },
    },
    item: {
      padding: theme.spacing.s1,
    },
    selectedInput: {
      position: 'relative',
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      width: 320,
      [ResponsiveMedium]: {
        width: 'calc(28vw - 60px)',
      },
    },
    selectedText: {
      flex: 1,
      paddingRight: spacing.size8,
      paddingLeft: spacing.size8,
      paddingTop: spacing.size12,
      paddingBottom: spacing.size12,
      width: 320,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    selectedClose: {
      display: closeButtonIsDisabled ? 'none' : 'block',
      width: spacing.size44,
      textAlign: 'center',
      lineHeight: spacing.size44,
      cursor: 'pointer',
    },
    label: {
      marginRight: spacing.size8,
      paddingBottom: spacing.size12,
      paddingTop: spacing.size12,
    },
  };
};
