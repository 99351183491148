import { getAppStore, getCurrentPublisherId } from '../@data';
import { RouteName } from '../pages/ad-management/@data';
import { PAYMENTS_TAB_KEY, PROFILE_TAB_KEY, REPORT_DASHBOARD_PAGE, SETTINGS_TAB_KEY } from '../pages/ad-management/Account';
import { RoutePrefix } from '../Routes';

export function getAdUnitRoute(): string {
  const { account } = getAppStore();
  const publisherId = getCurrentPublisherId();

  return `/${RoutePrefix.adManagement}/${publisherId}-${account?.id}/${RouteName.adunit}`;
}

export function getSitesRoute(): string {
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}/${RouteName.property}`;
}

export function getTythonPublisherRoute() {
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}`;
}

export function getTythonProfilePageRoute() {
  return `${getTythonPublisherRoute()}/${RouteName.publisherProfile}#${PROFILE_TAB_KEY}`;
}

export function getTythonPaymentPageRoute() {
  return `${getTythonPublisherRoute()}/${RouteName.publisherProfile}#${PAYMENTS_TAB_KEY}`;
}

export function getTythonAccountSettingPageRoute() {
  return `${getTythonPublisherRoute()}/${RouteName.publisherProfile}#${SETTINGS_TAB_KEY}`;
}

export function getTythonHomePageRoute() {
  return `${getTythonPublisherRoute()}/${RouteName.home}`;
}

export function getTythonAdUnitCreatePageRoute() {
  return `${getAdUnitRoute()}/create`;
}

export function getTythonSiteCreatePageRoute() {
  return `${getSitesRoute()}/create`;
}

export function getTythonInventoryPage(renderAppView = false) {
  const { account } = getAppStore();
  const publisherId = getCurrentPublisherId();
  if (renderAppView) {
    return `/${RoutePrefix.adManagement}/${publisherId}-${account?.id}/${RouteName.inventory}?view=app`;
  }
  return `/${RoutePrefix.adManagement}/${publisherId}-${account?.id}/${RouteName.inventory}`;
}

export function getTythonInventoryCreationPageRoute() {
  return `${getTythonInventoryPage()}/create`;
}

export function getTythonAdsCreationPageRoute() {
  const { account } = getAppStore();
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}-${account?.id}/${RouteName.ads}/create`;
}

export function getTythonAppAdunitCreationRoute() {
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}/${RouteName.adunit}/createApp`;
}

export function getTythonAppAdunitEditionRoute(id: string) {
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}/${RouteName.adunit}/editApp/${id}`;
}

export function getTythonAppInventoryCreationRoute() {
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}/${RouteName.property}/createApp`;
}

export function getTythonAppInventoryEditionRoute(id: string) {
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}/${RouteName.property}/editApp/${id}`;
}

export function getTythonReportDashboardPageRoute() {
  return `${getTythonPublisherRoute()}/${REPORT_DASHBOARD_PAGE}`;
}

export function getAdBlocksPageRoute() {
  const publisherId = getCurrentPublisherId();
  return `/${RoutePrefix.adManagement}/${publisherId}/${RouteName.adblock}`;
}

export function getTythonAdsManageRoute(renderAppView = false) {
  const { account } = getAppStore();
  const publisherId = getCurrentPublisherId();
  if (renderAppView) {
    return `/${RoutePrefix.adManagement}/${publisherId}-${account?.id}/${RouteName.ads}?view=app`;
  }
  return `/${RoutePrefix.adManagement}/${publisherId}-${account?.id}/${RouteName.ads}`;
}
