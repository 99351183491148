import { Icon, Stack } from '@fluentui/react';
import { HorizontalBarChart } from '@fluentui/react-charting';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { useI18n } from '../../../../../../i18n/useI18n';
import { getAppStore } from '../../../../../@data';
import { Card } from '../../../../../components/Card/Card';
import { DEFAULT_CURRENCY_CODE } from '../../../../../constants/AppConstants';
import { injectIntlAndObserver } from '../../../../../utils';
import { getPaymentsPageBalance } from '../../@data/store/selectors';
import { getStore } from '../../Settings/@data/store/store';
import messages from '../PaymentsPage.messages';
import { getClassNames as getPaymentsCommonClassNames } from '../PaymentsPage.styles';
import {
  formatByCurrencyAndLocale,
  getBarChartCustomData,
  getChartShimmerCustomElements,
  getFooterNotification,
  mapToBarChartData,
} from './Balance.helpers';
import { getClassNames, getStackTokens } from './Balance.styles';

const DATA_METRIC_ID = 'balance-card';
const CHART_DATA_METRIC_ID = 'balance-chart';
export interface IBalanceProps extends InjectedIntlProps {}
export const Balance = injectIntlAndObserver<IBalanceProps>((props) => {
  const { cardContainer } = getClassNames();
  const { intl } = props;
  const { formatMessage } = intl;
  const classNames = getClassNames(props);
  const { cardsTitleBarStyle } = getPaymentsCommonClassNames(props);
  const { data, isDataLoaded } = getPaymentsPageBalance();
  const paymentThreshold = data?.threshold ?? 0;
  const passedThreshold = data && data?.currBalance >= paymentThreshold;
  const { systemPreferences } = getStore().active;
  const { currency: sysCurrency } = systemPreferences ?? { currency: DEFAULT_CURRENCY_CODE };
  const { publisher, userContext } = getAppStore();
  const { locale } = useI18n({ systemPreferences, publisher, userContext });

  const formattedThreshold = formatByCurrencyAndLocale(paymentThreshold, locale, sysCurrency ?? DEFAULT_CURRENCY_CODE);
  return (
    <div className={cardContainer} data-metric={DATA_METRIC_ID}>
      <Card
        titleText={formatMessage(messages.balanceTitle)}
        shimmerCustomElement={getChartShimmerCustomElements()}
        isDataLoaded={!!isDataLoaded}
        footerComponents={
          !passedThreshold ? getFooterNotification(formatMessage(messages.thresholdNotification, { threshold: formattedThreshold })) : ''
        }
        styleProps={{ titleBar: cardsTitleBarStyle }}
      >
        <Stack data-metric={CHART_DATA_METRIC_ID} enableScopedSelectors tokens={getStackTokens()}>
          <Stack enableScopedSelectors>
            <div className={classNames.paymentThreshold}>
              {!passedThreshold
                ? `${formatMessage(messages.yourThreshold)} ${formattedThreshold}`
                : formatMessage(messages.reachedThreshold)}
            </div>
            <h1>
              {Number(data?.currBalance).toLocaleString(locale, {
                style: 'currency',
                currency: sysCurrency ?? DEFAULT_CURRENCY_CODE,
              })}
            </h1>
            <HorizontalBarChart
              barHeight={30}
              data={mapToBarChartData(data)}
              barChartCustomData={getBarChartCustomData(sysCurrency ?? DEFAULT_CURRENCY_CODE, locale)}
              hideTooltip
            />
            <div>
              <Icon className={classNames.iconStyle} iconName="Calendar" />
              <span>{formatMessage(messages.genericNextPayout)}</span>
            </div>
          </Stack>
        </Stack>
      </Card>
    </div>
  );
});
