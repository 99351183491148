import { getTheme, IStackTokens, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterTheme } from '../../../../../../theme';

export interface IBalanceStyleProps {
  theme: ITheme;
}

type StyleProperties = 'paymentThreshold' | 'iconStyle' | 'notificationMessage' | 'cardContainer';
type BalanceStyles = Record<StyleProperties, string>;

export const getStyles = (props: IBalanceStyleProps): BalanceStyles => {
  const { theme } = props;
  const palette = theme.palette as IPubcenterPalette;
  return mergeStyleSets({
    paymentThreshold: {
      paddingTop: 40,
      fontSize: 18,
    },
    iconStyle: {
      marginRight: '10px',
    },
    notificationMessage: {
      backgroundColor: palette.themeLighter,
    },
    cardContainer: {
      height: '100%',
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}

export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
