import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  getAppStore,
  onNotificationChanged,
  onSavingChanged,
  postEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
  UserInvitation,
  UserInvitationType,
  UserRole,
  UserRoleEntityType,
} from '../../../../../../@data';
import { reverseLocales } from '../../../../../../config/locale';
import { validateSchema } from '../../../../../../utils';
import { userRoleToRoleIdMap } from '../../../utils/userRoleUtils';
import messages from '../../UserInvitations.messages';
import { onUserInvited } from '../actions/userInvitationActions';
import { onFormCleared, setUserInvitationErrors, setUserInvitationPanelStatus, setUserInvitations } from '../mutatorActions';
import { UserInviteErrorModel } from '../schema/UserInviteErrorModel';
import { getUserInvitationStore } from '../store';

orchestrator(onUserInvited, async () => {
  const { publisher, account, userContext } = getAppStore();
  const userInvite = getUserInvitationStore().userInviteViewModel;

  if (publisher && userContext) {
    try {
      onSavingChanged(true);
      try {
        await validateSchema(UserInviteErrorModel(), userInvite);

        setUserInvitationErrors();
      } catch (e) {
        setUserInvitationErrors(e);
        onNotificationChanged({
          messageBarType: MessageBarType.error,
          textMessageDescriptor: messages.userInviteFormFailed,
          intlMessageValues: { count: Object.keys(e).length },
        });
        return;
      }

      if (userInvite.userRole) {
        const entity = new UserInvitation();
        entity.firstName = userInvite.firstName;
        entity.lastName = userInvite.lastName;
        entity.email = userInvite.email;
        entity.userInvitationType = UserInvitationType.PubCenterSelfServeUser;
        entity.lcid = userInvite.recipientPreferredLang ? reverseLocales[userInvite.recipientPreferredLang] : 1033;
        entity.entityRoles = [
          {
            entityId: publisher.id,
            userRole: userInvite.userRole,
            entityType: UserRoleEntityType.Customer,
          },
        ];

        if ([UserRole.AccountManager, UserRole.ReportUser].includes(userInvite.userRole) && account) {
          entity.entityRoles.push({
            entityId: account.id,
            userRole: userInvite.userRole,
            entityType: UserRoleEntityType.Account,
          });
        }

        const response = await postEntity([publisher], UserInvitation, entity, userContext);

        if (response) {
          const date = new Date();
          // Updating the expiry date to 30 days from the time of invitaion sent
          date.setDate(date.getDate() + 30);
          entity.expirationDate = date;
          const userRoleId = userInvite.userRole ? userRoleToRoleIdMap.get(userInvite.userRole) : undefined;
          entity.roleIds = userRoleId ? [userRoleId] : [];
          entity.id = response;

          const { userInvitations } = getUserInvitationStore();
          setUserInvitations([...userInvitations, entity]);

          setUserInvitationPanelStatus(false);
          onFormCleared();
        }

        showSuccessToastNotification({ textMessageDescriptor: messages.userInvitedNewUserSuccessfully });
      }
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.userInvitedNewUserFailed, intlMessageValues: { error: e } });
    } finally {
      onSavingChanged(false);
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.userInvitationInvalidStateReportOrLogin,
    });
  }
});
