import { Checkbox, classNamesFunction, css, getTheme, Icon, Overlay, Stack, StackItem, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { getStyles, IAccordionStyleProps, IAccordionStyles } from './Accordion.styles';
import messages from './messages';

interface IAccordionProps {
  isActive: boolean;
  onToggle: (active: boolean) => void;
  id: string;
  label: string;
  children: React.ReactNode;
  subContent?: string;
  wrapperClassName: string;
  disableMsg?: string;
  defaultExpanded?: boolean;
}

const Accordion = injectIntl<InjectedIntlProps & IAccordionProps>(
  ({ isActive, onToggle, id, label, children, subContent, wrapperClassName, disableMsg, defaultExpanded, intl: { formatMessage } }) => {
    const theme = getTheme();
    const getClassNames = classNamesFunction<IAccordionStyleProps, IAccordionStyles>();
    const [isExpanded, { toggle: toggleExpand, setFalse: collapseAccordion, setTrue: expandAccordion }] = useBoolean(isActive);
    const classNames = getClassNames(getStyles, { theme, isExpanded, isActive });
    const onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      onToggle(!!checked);
      if (checked) {
        expandAccordion();
      } else {
        collapseAccordion();
      }
    };

    /**
     * If defaultExpanded is true, expand the accordion
     */
    useEffect(() => {
      if (defaultExpanded) {
        expandAccordion();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={wrapperClassName} tabIndex={0} aria-label={label + ' ' + (subContent ?? '')}>
        {disableMsg && <Overlay className={wrapperClassName}>{disableMsg}</Overlay>}
        <Stack enableScopedSelectors horizontal horizontalAlign="space-between" className={classNames.accordionControlWrapper}>
          <StackItem>
            <Checkbox
              className={classNames.accordionToggleCheckboxLabel}
              id={`accordion-${id}`}
              checked={isActive}
              label={label}
              onChange={onChange}
              disabled={!!disableMsg}
              ariaLabel={formatMessage(
                isActive ? messages.accordionCheckBoxArialLabelChecked : messages.accordionCheckBoxArialLabelUnchecked,
                { title: label }
              )}
            />
          </StackItem>
          <StackItem>
            <Icon
              onClick={toggleExpand}
              onKeyDown={(ev) => isButtonPressKey(ev.key) && toggleExpand()}
              iconName={isExpanded ? 'ChevronUpMed' : 'ChevronDownMed'}
              aria-label={isExpanded ? 'Expanded' : 'Collapsed'}
              className={css(classNames.accordionChevron, 'triggerIcon')}
              tabIndex={0}
            />
          </StackItem>
        </Stack>
        <Stack enableScopedSelectors horizontal className={classNames.accordionContentWrapper}>
          {isExpanded && children}
          {!isExpanded && <Text className={classNames.subContentText}>{subContent}</Text>}
        </Stack>
      </div>
    );
  }
);

export default Accordion;
