import { nullRender } from '@fluentui/utilities';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { Log } from '../logging/src';
import { getAppStore, onAuthLogout } from './@data';
import { checkInternalUserAccess } from './@data/services/AuthorizationService';
import { isLocationPubCenter } from './utils/UrlUtils';

interface IAuthenticatedRouteProps {
  key: string;
  path: string;
  component: React.ComponentType<RouteComponentProps> | React.ComponentType;
  routeProps: RouteComponentProps;
}

export const AuthenticatedRoute = observer(
  class extends React.Component<IAuthenticatedRouteProps> {
    public render(): JSX.Element | null {
      const { path, component, routeProps } = this.props;
      const { userContext, isInviteUserFlow } = getAppStore();

      const redirectToOldLoginPage = {
        pathname: '/loginV2',
        state: { from: routeProps.location },
      };
      const redirectToNewLoginPage = {
        pathname: '/',
        state: { from: routeProps.location },
      };

      if (!userContext) {
        return <Redirect to={isLocationPubCenter() ? redirectToOldLoginPage : redirectToNewLoginPage} />;
      }

      checkInternalUserAccess(userContext?.userRoles);
      if (!isInviteUserFlow && userContext?.token && userContext?.userRoles) {
        return <Route path={path} component={component} />;
      } else {
        Log.error(`User not authenticated logging out ${userContext?.email}`);
        onAuthLogout();
        return nullRender();
      }
    }
  }
);
