import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  constructUrl,
  EntityLimitTier,
  FeatureOverideTier,
  getAppStore,
  LifeCycleStatus,
  NetworkAffiliation,
  onLoadingChanged,
  onNotificationChanged,
  onPublisherSelected,
  Partner,
  PaymentInstrumentLifeCycleStatus,
  PaymentOption,
  postEntity,
  Publisher,
  PublisherCreate,
  PublisherTier,
  ServiceLevel,
  upperCaseCountryCode,
  User,
} from '../../../../@data';
import { FinancialStatus } from '../../../../@data/store/schema/enums/FinancialStatus';
import { PublisherAccountUserCreate } from '../../../../@data/store/schema/models/PublisherAccountUserCreate';
import { EN_LOCALE_KEY, USER_SIGNUP_KEY } from '../../../../constants/AppConstants';
import { getStore as getTnCStore } from '../../../terms-and-conditions/@data/store/store';
import { patchAcceptTnC, preSignTnC } from '../../../terms-and-conditions/useTnC';
import { onFormSubmitted, onTythonPublisherSignup } from '../actions';
import { getPublisherName } from '../store/selectors';
import { getSignUpStore } from '../store/store';

orchestrator(onFormSubmitted, async (msg) => {
  onLoadingChanged(true);
  const { userContext } = getAppStore();
  const publisherBody = new PublisherCreate();
  const { active: activeAccount, optInPromotionalEmail } = getSignUpStore();
  const { latestTnCVersion } = getTnCStore();

  const accountBody = new Account();

  const user = new User();
  user.email = userContext?.email;
  user.username = userContext?.userName;
  user.name = {
    firstName: activeAccount.userFirstName || '',
    lastName: activeAccount.userLastName || '',
  };
  user.status = LifeCycleStatus.Active;
  user.language = upperCaseCountryCode(activeAccount.preferredLanguageCode ?? EN_LOCALE_KEY);

  /*  CustomerHelp
   *   CPM promotional email opt-in/out
   */
  user.contactByEmail = optInPromotionalEmail;

  const publisherName = getPublisherName();
  /**
   * Source for these values
   * https://microsoft.sharepoint.com/:o:/r/teams/AdSyndication/Shared%20Documents/
   * PubCenter_WikiBook?d=w99e7a2bf991d48f4b7daba558718235f&csf=1&web=1
   */
  publisherBody.partner = Partner.Microsoft;
  publisherBody.networkAffiliation = NetworkAffiliation.Syndicated;
  publisherBody.name = publisherName;
  publisherBody.publisherTier = PublisherTier.SelfServe3rdParty;
  publisherBody.overrideFeatureTier = FeatureOverideTier.SelfServeStandard;
  publisherBody.entityLimitTier = EntityLimitTier.Medium;
  publisherBody.serviceLevel = ServiceLevel.SelfServe;
  publisherBody.allowWebAdUnit = true;
  publisherBody.allowMobileSearchAdUnit = false;
  publisherBody.propertyAutoApproved = true;
  publisherBody.allowDistributionReporting = false;
  publisherBody.allowNativeReporting = false;
  publisherBody.allowPaidSearchReportingApi = false;
  publisherBody.allowPaidSearchReportingDashboard = true;
  publisherBody.allowSyndicationReporting = false;
  publisherBody.allowDistributionV2Reporting = false;
  publisherBody.allowStartDistributionReporting = false;
  publisherBody.allowMediationReporting = false;
  publisherBody.allowSelectTierReporting = false;
  publisherBody.financialStatus = FinancialStatus.NoHold;
  publisherBody.publisherType = activeAccount.accountType;
  // re-enable this when we have CCMT unblocked for promo email
  // publisherBody.promotionalEmail = optInPromotionalEmail ? PublisherPromotionalEmail.OptIn : PublisherPromotionalEmail.OptOut;

  accountBody.name = publisherName;
  accountBody.languageCode = activeAccount.preferredLanguageCode?.split('-')[0].toUpperCase(); // 'EN'
  accountBody.countryCode = activeAccount.countryCode;
  accountBody.currencyCode = activeAccount.currencyCode;
  accountBody.timeZoneId = 50; // GMT timezone id TODO : API call
  accountBody.status = LifeCycleStatus.Active;
  accountBody.primaryContactUsername = userContext!.email;

  // TODO: Remove after update,
  // need to pass correct enable features and ad extension options

  accountBody.paymentInformation = {
    financialStatus: FinancialStatus.TaxOnHold,
    revenueSharePercentage: 100,
    paymentOption: PaymentOption.MonthlyThreshold,
    paymentInstrumentationLifeCycleStatus: PaymentInstrumentLifeCycleStatus.Validated,
    operationCostRatio: 0,
  };

  accountBody.status = LifeCycleStatus.Active;

  const registerDetails = new PublisherAccountUserCreate();

  registerDetails.publisher = publisherBody;
  registerDetails.account = accountBody;
  registerDetails.user = user;

  try {
    // tslint:disable-next-line: no-any
    const response = (await postEntity([], PublisherAccountUserCreate, registerDetails, userContext)) as any;
    const publisherId = Number(response.publisherId).toString();

    /**
     * sign latest TnC when user submit signup form
     * not to update tnc status until PM sign off on this feature!, remove the if condition to re-enable this mechanism
     */
    // if (!HARD_CODE_DISABLE_TNC) {
    const acceptTnCUrl = constructUrl([new Publisher(publisherId), new User(response.userId)]) + `/accept-terms/${latestTnCVersion}`;
    await patchAcceptTnC({
      userContext: userContext,
      latestTnCVersion: latestTnCVersion,
      patchUrl: acceptTnCUrl,
      onSuccess: () => {
        preSignTnC(true);
      },
    });
    // }

    onTythonPublisherSignup(Number(response.publisherId), Number(response.userId));

    onPublisherSelected(publisherId, msg.routeHistory);

    localStorage.setItem(USER_SIGNUP_KEY, 'false');
  } catch (e) {
    onNotificationChanged({
      text: `Submitting the sign up flow got the following error: "${e}"`,
      messageBarType: MessageBarType.severeWarning,
    });
  } finally {
    onLoadingChanged(false);
  }
});
