import {
  IChoiceGroupStyleProps,
  IChoiceGroupStyles,
  ILabelStyleProps,
  ILabelStyles,
  IPanelStyleProps,
  IPanelStyles,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITheme,
} from '@fluentui/react';
import { IFormEditParams, IFormStateProps } from '../../../../components/Form';
import { IRouterWithIntl } from '../../../../utils/ReactUtils';

export interface IFormInitializationState {
  initializeForm: boolean;
  id?: string;
  textFileLoading?: boolean;
}

export interface ICreateAdFilterProps extends IRouterWithIntl<IFormEditParams>, IFormStateProps {
  styles?: IStyleFunction<ICreateAdFilterStyleProps, ICreateAdFilterStyles>;
  theme?: ITheme;
}

export interface ICreateAdFilterStyleProps {
  theme: ITheme;
}

export interface ICreateAdFilterStyles {
  description?: IStyle;
  excludedItemsTextField?: IStyle;
  exportButton?: IStyle;
  panelHeader?: IStyle;
  subComponentStyles: {
    inPagePanel?: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles>;
    excludedItemsLabel?: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>;
    excludedDescription?: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>;
    choiceGroup?: IStyleFunctionOrObject<IChoiceGroupStyleProps, IChoiceGroupStyles>;
  };
}

export enum FilterExcludedType {
  Url,
  AdKeyword,
  QueryKeyword,
}
