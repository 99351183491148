import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../theme';
import { FormState } from '../../components/Form';
import { IDetailPageStyleProps, IDetailPageStyles } from './DetailPage.types';

export const getStyles = (props: IDetailPageStyleProps): IDetailPageStyles => {
  const { theme, pageMode, hasFormTitle = false, additionalHeightBoundary, overlayHeight } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette;
  const renderedOnPanel =
    pageMode === FormState.InPageCreate || pageMode === FormState.InPageEdit || pageMode === FormState.InPageView ? true : false;
  const marginLeft = !renderedOnPanel ? spacing.size48 : 0;
  const titleMarginLeft = !renderedOnPanel ? spacing.size32 : 1;
  const buttonMarginBottom = !renderedOnPanel ? (hasFormTitle ? spacing.size16 : spacing.size32) : 0;
  // we have marginTop32 + marginBottom24 + actual form title lineHeight32, together is 88px is the cumulative height for the form title
  const formTitleHeight = 88;
  return mergeStyleSets({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: 1,
    },
    navBackButton: [
      'ms-navBackButton',
      {
        display: 'flex',
        backgroundColor: theme.palette.neutralLighter,
        height: spacing.size44,
        alignItems: 'center',
      },
    ],
    scrollablePanel: {
      borderBottomStyle: 'solid',
      borderColor: theme.palette.neutralLight,
      borderBottomWidth: renderedOnPanel ? 0 : 1,
      overflowY: 'auto',
      flex: 1,
      maxHeight: `calc(100% - 5rem)`,
      width: '100%',
      ...(additionalHeightBoundary
        ? {
            paddingBottom: spacing.size64,
          }
        : {}),
    },
    content: {
      marginLeft: marginLeft,
      position: 'relative',
      height: hasFormTitle ? `calc(100% - ${formTitleHeight}px)` : '100%',
    },
    buttons: {
      zIndex: 9999,
      position: renderedOnPanel ? 'absolute' : 'static',
      bottom: renderedOnPanel ? '1rem' : '0',
      display: 'flex',
      marginLeft: marginLeft,
      marginTop: spacing.size16,
      marginBottom: buttonMarginBottom,
      '@media(max-height: 360px)': {
        position: 'static',
      },
      selectors: {
        '.ms-Button': {
          marginRight: spacing.size8,
        },
      },
      ...(additionalHeightBoundary
        ? {
            background: palette.white,
            width: '100%',
            bottom: 0,
            paddingBottom: spacing.size16,
            paddingTop: spacing.size16,
          }
        : {}),
    },
    subComponentStyles: {
      formTitle: {
        root: {
          marginLeft: marginLeft,
          marginTop: titleMarginLeft,
          marginBottom: spacing.size24,
        },
      },
      breadcrumb: {
        root: {
          margin: 0,
          alignItems: 'center',
        },
        item: {
          lineHeight: '22px',
          fontSize: theme.fonts.mediumPlus.fontSize,
          color: theme.palette.neutralPrimary,
        },
        chevron: {
          lineHeight: '22px',
          color: theme.palette.neutralPrimary,
        },
      },
    },
    overlayStyles: {
      ...(overlayHeight ? { height: overlayHeight } : {}),
    },
  });
};

export function getClassNames<PropType>(props?: PropType): IDetailPageStyles {
  return getStyles({ theme: getTheme(), ...props });
}
