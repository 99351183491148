import { FontWeights, getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { flexWithCenter } from '../../utils/GlobalStyleUtils';

export interface IZeroResultsStyleProps {
  theme: ITheme;
}

export interface IZeroResultsStyles {
  rootStyle?: string;
  imageContainerStyle?: string;
  descriptionStyle?: string;
  titleStyle?: string;
  imageStyle?: string;
}

const IMAGE_HEIGHT = 200;

export const getStyles = (props: IZeroResultsStyleProps): IZeroResultsStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, fonts, palette } = theme;

  return mergeStyleSets({
    rootStyle: {
      ...flexWithCenter,
      flexDirection: 'column',
      margin: '0 auto 42px auto',
      height: 'calc(100vh - 300px)',
      width: '100%',
    },
    imageStyle: {
      margin: '0 auto',
    },
    imageContainerStyle: {
      width: IMAGE_HEIGHT,
      height: IMAGE_HEIGHT,
    },
    descriptionStyle: {
      ...fonts.size16,
      marginTop: 0,
    },
    titleStyle: {
      ...fonts.size20,
      fontWeight: FontWeights.semibold,
      color: palette.neutralSecondary,
      marginBottom: spacing.size16,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): IZeroResultsStyles {
  return getStyles({ theme: getTheme(), ...props });
}
