import { MessageBarType } from '@fluentui/react';
import { runInAction } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { FetchDevTokenStatus, Model, User, getAppStore, getEntity, onNotificationChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { DevTokenFetch } from '../../Manage/ManagePage.types';
import { onFetchDevTokenStatus } from '../actions';
import { getStore } from '../store/store';

orchestrator(onFetchDevTokenStatus, async (msg) => {
  const store = getStore();
  const selectedItem = msg.selectedItem as Model;
  let notification: INotificationBarItem = {};

  if (store && selectedItem) {
    const { userContext, publisher } = getAppStore();
    if (userContext && publisher) {
      try {
        const userId = selectedItem.id;
        const user = new User(userId);

        Log.debug('Fetching dev token for user ' + userId);

        await getEntity([publisher, user], userContext, FetchDevTokenStatus).then((response) => {
          runInAction(() => {
            const fetchedToken = response.toString() !== 'false' ? response.toString() : DevTokenFetch.TokenNotFound;
            Log.info('Fetched dev token: ' + fetchedToken);
            if (store.devTokenPanelParams) {
              store.devTokenPanelParams.developerToken = fetchedToken;
            }
          });
        });
      } catch (exception) {
        notification = {
          text: `Fetching the token raised an error "${exception}"`,
          messageBarType: MessageBarType.error,
        };
        // This will make the UI show an error message instead of infinite spinner
        runInAction(() => {
          if (store.devTokenPanelParams) {
            store.devTokenPanelParams.developerToken = DevTokenFetch.ApiCallFailed;
          }
        });
        Log.error('Fetching the token raised an error ' + exception.toString());
        onNotificationChanged(notification);
      }
    }
  }
});
