import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.pages.errorPages.suspendedUser.title',
    defaultMessage: 'Your profile has been rejected',
  },
  description: {
    id: 'app.pages.errorPages.suspendedUser.description',
    defaultMessage: "We have rejected your profile because it doesn't comply with our ",
  },
  termAndConditions: {
    id: 'app.pages.errorPages.suspendedUser.termAndConditions',
    defaultMessage: 'Terms and Conditions.',
  },
});
