import { action } from 'satcheljs';
import { UserInvitation } from '../../../../../../@data';

export const onUserInvited = action('onUserInvited');

export const onResendUserInvitation = action('onResendUserInvitation', (userInvite: UserInvitation) => ({ userInvite }));

export const onDeleteUserInvitation = action('onDeleteUserInvitation', (userInvite: UserInvitation) => ({ userInvite }));

export const loadUserInvitations = action('loadUserInvitations');

export const dismissUserInvitePanel = action('dismissUserInvitePanel');
