import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class DropdownMenu extends Model {
  public className = UserRoleEntity.DropdownMenu;
  public adManagement: number;
  public partnerManagment: number;
  public reporting: number;

  constructor(params: DropdownMenu) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: DropdownMenu, menu: DropdownMenu) {
    // tslint:disable:no-bitwise
    this.accessPermission |= menu.accessPermission;
    this.adManagement |= menu.adManagement;
    this.partnerManagment |= menu.partnerManagment;
    this.reporting |= menu.reporting;
    // tslint:enable:no-bitwise
  }
}
