import { getTheme, keyframes, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { ICommonStyleProps } from '../../types';
import { flexWithJustifyBetween } from '../../utils/GlobalStyleUtils';
import { ToastType } from './ToastNotification.types';

export interface IToastStyleProps extends ICommonStyleProps {
  type: ToastType;
  suppressAutoDismiss?: boolean;
  animationTime?: number;
}

type StyleProperties = 'notificationsContainer' | 'notificationBody' | 'notificationAnimation' | 'notificationIcon';

type ToastNotificationStyles = Record<StyleProperties, string>;

export const getStyles = (props: IToastStyleProps): ToastNotificationStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette } = theme;
  const AUTO_DISMISS_ANIMATION_TIME: number = props.animationTime || 3000;
  const MANUAL_DISMISS_ANIMATION_TIME: number = AUTO_DISMISS_ANIMATION_TIME / 4;

  const toastStyle = {
    info: {
      background: palette.neutralLighter,
    },
    warning: {
      background: palette.warning,
    },
    error: {
      background: palette.messageBarError,
      color: palette.neutralPrimary,
    },
    success: {
      background: palette.success,
    },
    loading: {
      background: palette.neutralLighter,
    },
  };

  return mergeStyleSets({
    notificationsContainer: {
      position: 'absolute',
      zIndex: 9999999,
      top: spacing.size50,
      right: spacing.size40,
      display: 'flex',
      flexDirection: 'column',
      gap: spacing.size10,
      alignItems: 'flex-end',
    },
    notificationBody: {
      ...flexWithJustifyBetween,
      boxShadow: 'rgb(0 0 0 / 14%) 0px 4px 8px, rgb(0 0 0 / 12%) 0px 0px 2px',
      padding: `${spacing.size20} ${spacing.size10}`,
      gap: spacing.size10,
      borderRadius: 4,
      width: 'fit-content',
      ...toastStyle[props.type.toString()],
    },
    notificationAnimation: {
      animationName: props.suppressAutoDismiss ? automaticFadeIn : automaticFadeInOut,
      animationDuration: props.suppressAutoDismiss ? `${MANUAL_DISMISS_ANIMATION_TIME}ms` : `${AUTO_DISMISS_ANIMATION_TIME}ms`,
      animationIterationCount: 1,
    },
    notificationIcon: {
      cursor: 'pointer',
      userSelect: 'none',
    },
  });
};

const automaticFadeInOut = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(100%)',
    easing: 'ease-out',
  },
  '25%': {
    opacity: 1,
    transform: 'translateX(0)',
    easing: 'ease-in',
  },
  '50%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
  '75%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
  '100%': {
    opacity: 0,
    transform: 'translateX(100%)',
  },
});

const automaticFadeIn = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(100%)',
    easing: 'ease-out',
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)',
    easing: 'ease-in',
  },
});

export function getClassNames<PropType>(props?: PropType): ToastNotificationStyles {
  return getStyles({ theme: getTheme(), type: ToastType.info, ...props });
}
