import { classNamesFunction, getTheme, IProcessedStyleSet } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { isTythonSelfServeUser } from '../../@data';
import { ExternalLink } from '../../components/ExternalLink';
import { SUPPORT_EMAIL, TNC_HREF_PATH } from '../../constants/AppConstants';
import { SupportPageRoute } from '../../Routes';
import messages from './Footer.messages';
import { getStyles } from './Footer.styles';
import { IFooterProps, IFooterStyleProps, IFooterStyles } from './Footer.types';

const theme = getTheme();
const getClassNames = classNamesFunction<IFooterStyleProps, IFooterStyles>();
const CONSUMER_HEALTH_PRIVACY_URL = 'https://go.microsoft.com/fwlink/?linkid=2259814';

export const Footer = injectIntl(
  class extends React.Component<IFooterProps> {
    private _classNames: IProcessedStyleSet<IFooterStyles>;

    public render(): JSX.Element {
      this._classNames = getClassNames(getStyles, { theme });
      const formatMessage = this.props.intl.formatMessage;

      return (
        <footer className={this._classNames.root} aria-label={formatMessage(messages.footerAriaLabel)}>
          <ExternalLink href={TNC_HREF_PATH} className={this._classNames.footerLinks} aria-label={formatMessage(messages.legal)}>
            {formatMessage(messages.legal)}
          </ExternalLink>

          <ExternalLink
            href="http://go.microsoft.com/fwlink/?LinkId=248681"
            className={this._classNames.footerLinks}
            aria-label={formatMessage(messages.privacyCookies)}
          >
            {formatMessage(messages.privacyCookies)}
          </ExternalLink>

          <ExternalLink
            href={CONSUMER_HEALTH_PRIVACY_URL}
            className={this._classNames.footerLinks}
            aria-label={formatMessage(messages.consumerHealthPrivacy)}
          >
            {formatMessage(messages.consumerHealthPrivacy)}
          </ExternalLink>

          <ExternalLink
            href={isTythonSelfServeUser() ? `${SupportPageRoute}` : `mailto:${SUPPORT_EMAIL}`}
            aria-label={formatMessage(messages.contactSupport)}
            className={this._classNames.footerLinks}
          >
            {formatMessage(messages.contactSupport)}
          </ExternalLink>

          <span className={this._classNames.copyrightText}>{formatMessage(messages.copyrightMicrosoft)}</span>
        </footer>
      );
    }
  }
);
