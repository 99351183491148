import {
  AccountPaymentOptionDataResponse,
  AccountSkuDataResponse,
  ApiError,
  getAppStore,
  handleApiErrors,
  handleError,
  ISubGeography,
  LocaleDataResponse,
  MarketDataResponse,
  ServiceLevel,
  serviceLevelNameIdMap,
} from '..';
import { uiConfig } from '../../config/ui.config';
import { IMarket, StateOrProvinceDataResponse } from '../store/schema';
import { AdUnitTaxonomy } from '../store/schema/models/AdUnitTaxonomy';
import { AdUnitTaxonomyDataResponse } from '../store/schema/models/AdUnitTaxonomyDataResponse';
import { CountryDataResponse } from '../store/schema/models/CountryDataResponse';
import { LanguageDataResponse } from '../store/schema/models/LanguageDataResponse';
import { TimezoneDataResponse } from '../store/schema/models/TimezoneDataResponse';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';

export const baseAddress = uiConfig.getMetadataApiBaseAddress();
export const customerMetadataBaseAddress = uiConfig.getCustomerMetadataApiBaseAddress();
export const adUnitMetadataApiBaseAddress = uiConfig.getAdUnitMetadataApiBaseAddress();
export const languageKey = '_languages';
export const timezoneKey = '_timezones';
export const localeKey = '_locales';
export const countryKey = '_countriesData';
export const marketKey = '_markets';
export const adUnitTaxonomyKey = '_taxonomyValues';

async function fetchResources<ApiResponseType, ReturnType>(
  url: string,
  responseDataProcessor: (data: ApiResponseType) => ReturnType,
  onResponse?: (data: ApiResponseType) => void
): Promise<ReturnType> {
  const { userContext } = getAppStore();
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      return handleApiErrors(response, url);
    }

    const responseJson: ApiResponseType = await response.json();
    onResponse?.(responseJson);

    return responseDataProcessor(responseJson);
  } catch (err) {
    const error = `Data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

async function fetchResourcesWithLocalStorageCache<ApiResponseType, ReturnType>(
  url: string,
  resourceCacheKey: string,
  responseDataProcessor: (data: ApiResponseType) => ReturnType
): Promise<ReturnType> {
  const supportedResourcesFromCache = localStorage.getItem(resourceCacheKey);
  if (supportedResourcesFromCache === null) {
    return fetchResources(url, responseDataProcessor, (data) => {
      localStorage.setItem(resourceCacheKey, JSON.stringify(data));
    });
  } else {
    return responseDataProcessor(JSON.parse(supportedResourcesFromCache));
  }
}

export async function getSupportedLanguages(): Promise<string[]> {
  const url = `${baseAddress}${encodeURIComponent('supportedLanguages')}`;
  return fetchResourcesWithLocalStorageCache<LanguageDataResponse, string[]>(url, languageKey, getLanguages);
}

// TODO: This is not used anywhere. Please remove it.
export async function getAvailableAllowedMarkets(): Promise<IMarket[]> {
  const url = `${customerMetadataBaseAddress}${encodeURIComponent('markets')}`;
  return fetchResourcesWithLocalStorageCache<MarketDataResponse, IMarket[]>(url, marketKey, getMarkets);
}

export async function getSupportedCountries(serviceLevel?: ServiceLevel): Promise<string[]> {
  const url = `${baseAddress}${encodeURIComponent('supportedCountries')}`;
  return fetchResourcesWithLocalStorageCache<CountryDataResponse, string[]>(url, countryKey, getCountriesWithServiceLevel(serviceLevel));
}

export async function getSupportedSubGeographies(countryCode: string): Promise<ISubGeography[]> {
  const url = `${baseAddress}${encodeURIComponent('supportedSubGeographies')}?countryCode=${countryCode}`;
  return fetchResources<StateOrProvinceDataResponse, ISubGeography[]>(url, (stateData) => stateData.data.subGeographyList);
}

export async function getSupportedTimezones(): Promise<number[]> {
  const url = `${baseAddress}${encodeURIComponent('supportedTimezones')}`;
  return fetchResourcesWithLocalStorageCache<TimezoneDataResponse, number[]>(url, timezoneKey, getTimezones);
}

export async function getSupportedAccountSkus(): Promise<string[]> {
  const url = `${baseAddress}${encodeURIComponent('supportedAccountSkus')}`;
  return fetchResources<AccountSkuDataResponse, string[]>(url, (skuData) => skuData.data.accountSKUs);
}

export async function getSupportedAccountPaymentOptions(): Promise<Map<number, string>> {
  const url = `${baseAddress}${encodeURIComponent('supportedPaymentOptions')}`;
  return fetchResources<AccountPaymentOptionDataResponse, Map<number, string>>(
    url,
    (paymentOptionData) => paymentOptionData.data.paymentOptionData
  );
}

// TODO: This is not used anywhere, we can remove it.
export async function getSupportedLocales(): Promise<string[]> {
  const url = `${baseAddress}${encodeURIComponent('supportedLocales')}`;
  return fetchResourcesWithLocalStorageCache<LocaleDataResponse, string[]>(url, localeKey, getLocales);
}

export async function getAdUnitTaxonomyValues(): Promise<AdUnitTaxonomy[]> {
  const url = `${adUnitMetadataApiBaseAddress}${encodeURI('attributes/taxonomy/values')}`;
  return fetchResourcesWithLocalStorageCache<AdUnitTaxonomyDataResponse, AdUnitTaxonomy[]>(url, adUnitTaxonomyKey, getTaxonomyValues);
}

function getCountriesWithServiceLevel(serviceLevel?: ServiceLevel) {
  return function (countriesData: CountryDataResponse) {
    return getCountries(countriesData, serviceLevel);
  };
}

function getCountries(countriesData: CountryDataResponse, serviceLevel?: ServiceLevel): string[] {
  const countriesJson = countriesData.data.countries;
  const countryCodes: string[] = [];
  if (serviceLevel) {
    countriesJson.forEach((country) => {
      Object.keys(country.serviceLevels).forEach((serviceLevelKey) => {
        country.serviceLevels[serviceLevelKey].forEach((serviceLevelValue) => {
          if (serviceLevelValue === serviceLevelNameIdMap[serviceLevel]) {
            countryCodes.push(country.code);
          }
        });
      });
    });
  } else {
    countriesJson.forEach((country) => {
      countryCodes.push(country.code);
    });
  }
  return countryCodes;
}

function getTimezones(timezoneJson: TimezoneDataResponse): number[] {
  const timezones: number[] = [];
  const timezoneData = timezoneJson.data.timeZoneData;

  Object.keys(timezoneData).forEach((timezoneCode) => {
    timezones.push(parseInt(timezoneCode, 10));
  });

  return timezones;
}

function getLanguages(languageJson: LanguageDataResponse): string[] {
  const languages: string[] = [];
  const languageData = languageJson.data.languageData;

  Object.keys(languageData).forEach((languageCode) => {
    languages.push(languageData[languageCode]);
  });

  return languages;
}

function getMarkets(marketsJson: MarketDataResponse): IMarket[] {
  const markets: IMarket[] = [];
  marketsJson.data.searchMarkets.forEach((market) => {
    markets.push(market);
  });
  marketsJson.data.mobileSearchMarkets.forEach((market) => {
    markets.push(market);
  });
  return markets;
}

function getLocales(localeJson: LocaleDataResponse): string[] {
  const locales: string[] = [];
  const localeData = localeJson.data.localeData;

  Object.keys(localeData).forEach((localeCode) => {
    locales.push(localeData[localeCode]);
  });

  return locales;
}

function getTaxonomyValues(taxonomyJson: AdUnitTaxonomyDataResponse): AdUnitTaxonomy[] {
  const adUnitTaxonomyValues: AdUnitTaxonomy[] = [];
  taxonomyJson.data.attributeValues.forEach((taxonomy) => adUnitTaxonomyValues.push(taxonomy));
  return adUnitTaxonomyValues;
}
