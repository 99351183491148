import { isPublisherRejectionUnappealable, isTythonSelfServeUser } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { IRoute } from '../../../../Routes.types';
import { BaseReportPage } from '../../../report/BaseReportPage';
import { AccountPage } from '../../Account';
import { LazyAdBlockPage } from '../../AdBlock/LazyAdBlockPage';
import { AdFilterPage } from '../../AdFilter/AdFilterPage';
import { AdQualityPage } from '../../AdQuality/AdQualityPage';
import { TythonAppManagePage } from '../../Ads/Manage';
import { AdunitPage } from '../../Adunit/AdunitPage';
import { ChannelPage } from '../../Channel/ChannelPage';
import { HomePage } from '../../Home/HomePage';
import { TythonInventoryManagePage } from '../../Inventory/Manage';
import { PropertyPage } from '../../Property';
import { RouteName } from '../store/store';

export function getRoutes(formatMessage): IRoute[] {
  const routes: IRoute[] = [
    {
      key: RouteName[RouteName.adunit],
      text: formatMessage(AppMessages.navAdUnits),
      icon: 'AdUnit',
      disabled: isPublisherRejectionUnappealable(),
      component: AdunitPage,
    },
    {
      key: RouteName[RouteName.channel],
      text: formatMessage(AppMessages.navChannels),
      icon: 'AdUnitChannel',
      component: ChannelPage,
    },
    {
      key: RouteName[RouteName.adfilter],
      text: formatMessage(AppMessages.navAdFilters),
      icon: 'Filter',
      component: AdFilterPage,
    },
    {
      key: RouteName[RouteName.property],
      text: isTythonSelfServeUser() ? formatMessage(AppMessages.navSites) : formatMessage(AppMessages.navProperties),
      icon: isTythonSelfServeUser() ? 'Favicon' : 'BusinessHoursSign',
      component: PropertyPage,
      disabled: isPublisherRejectionUnappealable(),
    },
    {
      key: RouteName[RouteName.adquality],
      text: formatMessage(AppMessages.navAdQuality),
      icon: 'Blocked',
      component: AdQualityPage,
    },
  ];

  if (isTythonSelfServeUser()) {
    routes.unshift({
      key: RouteName[RouteName.home],
      text: formatMessage(AppMessages.navHome),
      icon: 'Home',
      component: HomePage,
    });
    routes.push({
      key: RouteName[RouteName.inventory],
      text: formatMessage(AppMessages.navInventory),
      icon: 'Inventory',
      disabled: isPublisherRejectionUnappealable(),
      component: (TythonInventoryManagePage as unknown) as React.ComponentType,
    });
    routes.push({
      key: RouteName[RouteName.ads],
      text: formatMessage(AppMessages.navAds),
      icon: 'Ads',
      disabled: isPublisherRejectionUnappealable(),
      component: (TythonAppManagePage as unknown) as React.ComponentType,
    });
    routes.push({
      key: RouteName[RouteName.adblock],
      text: formatMessage(AppMessages.navAdBlocks),
      disabled: isPublisherRejectionUnappealable(),
      icon: 'AdUnitBlock',
      component: LazyAdBlockPage,
    });
    routes.push({
      key: RouteName[RouteName.dashboard],
      text: formatMessage(AppMessages.navReports),
      icon: 'CRMReport',
      disabled: isPublisherRejectionUnappealable(),
      component: BaseReportPage,
    });
    routes.push({
      key: RouteName[RouteName.publisherProfile],
      text: formatMessage(AppMessages.navAccount),
      icon: 'AccountManagement',
      component: AccountPage,
      disabled: isPublisherRejectionUnappealable(),
    });
  }

  return routes;
}
