import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, onNotificationChanged, onSavingChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { validateSchema } from '../../../../../utils';
import messages from '../../AdReview/AdCreativePage.messages';
import {
  onAdCreativeAdPerfDataChanged,
  onAdCreativeBlockPanelChanged,
  onBlockImagesErrorChanged,
  onBlockImagesSubmitted,
  onBlockedContextChanged,
  onBlockedImagesContextChanged,
} from '../actions';
import { createImageBlock } from '../services/AdCreativeService';
import { blockItemErrorModel } from '../store/schema/BlockItemErrorModel';
import { BlockImagessData } from '../store/schema/models';
import { getStore } from '../store/store';

orchestrator(onBlockImagesSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { blockAdsPage, blockedImagesContext, active } = getStore();

  let notification: INotificationBarItem = {};

  if (publisher && publisher.id && userContext) {
    blockAdsPage.adPerfBlockAdsData.forEach(async (item, value) => {
      const body: BlockImagessData = new BlockImagessData();
      body.adId = item.adId;
      body.adTitle = item.adTitle;
      body.adUnitId = item.adUnitId;
      body.images = item.blockedImages;
      body.blockReason = blockAdsPage.blockReason;
      body.blockReasonComment = blockAdsPage.blockDetails;
      body.blockedBy = userContext.id;
      body.blockSource = 1;

      const params = new URLSearchParams([['targetCustomerId', publisher.id!.toString()]]);

      try {
        await validateSchema(blockItemErrorModel(), blockAdsPage);
        onBlockImagesErrorChanged();
      } catch (e) {
        onBlockImagesErrorChanged(e);
        onNotificationChanged({
          textMessageDescriptor: messages.adqualityBlockImagesMissingFields,
          intlMessageValues: { count: Object.keys(e).length },
          messageBarType: MessageBarType.severeWarning,
        });
        return;
      }

      onSavingChanged(true);
      try {
        await createImageBlock(body, params);

        const reportedImages = blockedImagesContext ? blockedImagesContext : [];
        if (item.blockedImages) {
          item.blockedImages.forEach((blockImage) => {
            const imageReportDataTuple = { adId: item.adId, imageUrl: blockImage };
            reportedImages.push(JSON.stringify(imageReportDataTuple));
          });
          if (active && active.adPerfData) {
            const filteredAds = active.adPerfData;
            filteredAds.forEach((ad) => {
              ad.blockedAdIdImages = reportedImages;
              // ad.imagesContext = [];
              // if (ad.images) {
              //   ad.images.forEach((image) => {
              //     const imageMetadata: ImageMetaData = { imageUrl: image, isBlocked: false };
              //     const imageReportDataTuple = { adId: ad.adId, imageUrl: processImage(image) };
              //     if (reportedImages.includes(JSON.stringify(imageReportDataTuple))) {
              //       imageMetadata.isBlocked = true;
              //     }
              //     ad.imagesContext!.push(imageMetadata);
              //   });
              // }
            });
            onAdCreativeAdPerfDataChanged(filteredAds);
            onBlockedImagesContextChanged(reportedImages);
          }
        }
        onBlockedContextChanged(publisher.id!);

        notification = {
          textMessageDescriptor: messages.adqualityBlockImagesSuccess,
          messageBarType: MessageBarType.success,
        };
        onNotificationChanged(notification);
      } catch (e) {
        onNotificationChanged({
          textMessageDescriptor: messages.adqualityBlockImagesFailed,
          messageBarType: MessageBarType.severeWarning,
        });
      } finally {
        onAdCreativeBlockPanelChanged(false);
        onSavingChanged(false);
      }
    });
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
