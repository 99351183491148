import React from 'react';
import { IconTooltip } from '../../../../components/IconTooltip/IconTooltip';
import { injectIntlAndObserver } from '../../../../utils';
import { getClassNames } from './MetricMarker.styles';
import { IMetricMarkerIntlProps } from './MetricMarker.types';

export const MetricMarker = injectIntlAndObserver(
  ({ color, metricData, metricName, isActive, isIncompatible, incompatibleTooltipCantText, onClick }: IMetricMarkerIntlProps) => {
    const { contentStyle, marker, markerData, markerName, markerWrapper, tooltip } = getClassNames({ color, isActive, isIncompatible });

    return (
      <div onClick={!isIncompatible ? onClick : undefined} className={markerWrapper}>
        <div className={marker} />
        <div className={contentStyle}>
          <span className={markerData}>
            {isIncompatible || !metricData ? '- -' : metricData}
            {isIncompatible && (
              <span className={tooltip}>
                <IconTooltip tooltipMessage={incompatibleTooltipCantText || ''} iconName={'warning'} />
              </span>
            )}
          </span>
          <span className={markerName}>{metricName}</span>
        </div>
      </div>
    );
  }
);
