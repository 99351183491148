import { SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { getAppStore, Publisher } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { ZeroResults } from '../../../../components/ZeroResults/ZeroResults';
import { useWindowSize } from '../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../hooks/useWindowSize/store/store';
import { GridPageLayout } from '../../../../layout/GridPageLayout/GridPageLayout';
import { GridV2, IGridV2Column } from '../../../../layout/GridPageV2';
import { RoutePrefix } from '../../../../Routes';
import { injectIntlWithObserver, IRouterWithIntl } from '../../../../utils/ReactUtils';
import { RouteName } from '../../@data';
import { onManagePageInitialized, onPublishersFetched } from '../@data/actions';
import { getStore } from '../@data/store';
import messages from './ManagePage.messages';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

export const ManagePage = injectIntlWithObserver(function PublisherManagePage(props: IRouterWithIntl) {
  const { publisher } = getAppStore();
  const { publishers, isLoading, isFetchingData } = getStore();
  const { formatMessage } = props.intl;
  const { windowStoreSerializer } = useWindowSize();
  const { isWindowRegularSize } = windowStoreSerializer(getWindowSizeStore());
  const gridDefaultColumns: IGridV2Column[] = getGridColumns(formatMessage, isWindowRegularSize, props.history);

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={!!publisher ? formatMessage(messages.zeroResultTitle) : undefined}
      description={formatMessage(!!publisher ? messages.zeroResultDescription : messages.noPublisherSelected)}
      buttonText={formatMessage(messages.create)}
      onButtonClick={!!publisher ? () => props.history.push(`${RoutePrefix.partnerManagement}/${RouteName.publisher}/create`) : undefined}
    />
  );

  useEffect(() => {
    onManagePageInitialized();
    // eslint-disable-next-line
  }, [publisher]);

  return (
    <GridPageLayout>
      <GridV2
        history={props.history}
        location={props.location}
        match={props.match}
        entity={Publisher}
        pageTitle={{ title: formatMessage(AppMessages.publishers), helpId: 'pop_PCv4_Publishers' }}
        commandBarProps={getCommandBarItems()}
        dataGridProps={{
          gridV2Columns: gridDefaultColumns,
          data: publisher ? publishers || [] : [],
          setData: onPublishersFetched,
          shimmerLines: 10,
          selectionMode: SelectionMode.single,
          renderZeroItemsComponent: NoResultComponent,
        }}
        isGridReady={!isLoading}
        isDataLoading={isFetchingData}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
    </GridPageLayout>
  );
});
