import { ThemeProvider } from '@fluentui/react';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { addThreatMetrixScripts } from './app/@data/services/OneVetIntegrationService';
import { tythonRedirectionUtils } from './app/@data/utils/redirectionUtils';
import { App } from './app/App';
import './index.css';
import { Log } from './logging/src/Log';
import * as serviceWorker from './serviceWorker';
import { PubcenterCustomizations } from './theme';
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <BrowserRouter>
      <ThemeProvider theme={PubcenterCustomizations} style={{ height: 'inherit' }}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// This triggers the singleton 'Log' class and provides a single instance of
// the class that can be used anywhere in the UI code. 'Log.write' can now be
// used to write to ARIA and console
Log.getInstance();

addThreatMetrixScripts();

/**
 * Add a iframe to transfer localstorage from pubcenter to tython domain
 * In the new domain, add a listener to receive and set the localstorage
 */
tythonRedirectionUtils();
