import { orchestrator } from 'satcheljs';
import { onNavigateBack } from '../actions';

orchestrator(onNavigateBack, msg => {
  if (msg.routeHistory && msg.returnUrl) {
    msg.routeHistory.push(msg.returnUrl);
  } else {
    window.history.back();
  }
});
