import { action } from 'satcheljs';
import { AccountSKU } from '../../../../../@data';
import { ISkuSidePanelProps } from '../../Create/CreateAccount.types';
import { IAccountSkuViewModel } from '../store/schema/IAccountSkuViewModel';

export const onAccountSkuSidePanelInitialized = action('onAccountSkuSidePanelInitialized', (value?: ISkuSidePanelProps) => ({ value }));

export const onAccountSkuChanged = action('onAccountSkuChanged', (value?: IAccountSkuViewModel) => ({ value }));

export const onAccountSkusChanged = action('onAccountSkusChanged', (value?: AccountSKU[]) => {
  return { value };
});
