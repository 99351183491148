import { defineMessages } from 'react-intl';

export default defineMessages({
  footerAriaLabel: {
    id: 'footer.footerAriaLabel',
    defaultMessage: 'Footer',
  },
  legal: {
    id: 'footer.legal',
    defaultMessage: 'Legal',
  },
  privacyCookies: {
    id: 'footer.privacyCookies',
    defaultMessage: 'Privacy & Cookies',
  },
  consumerHealthPrivacy: {
    id: 'footer.consumerHealthPrivacy',
    defaultMessage: 'Consumer Health Privacy',
  },
  contactSupport: {
    id: 'footer.contactSupport',
    defaultMessage: 'Contact Support',
  },
  copyrightMicrosoft: {
    id: 'footer.copyrightMicrosoft',
    defaultMessage: '\u00a9 Microsoft 2024',
  },
});
