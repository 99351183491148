import { RefObject } from 'react';
import { HighChartsWrapper } from '../../../../components/HighCharts/HighChartsLibrary';
import { IRouterWithIntl } from '../../../../utils';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { ICustomCalenderDateRange } from '../../components/CustomCalender/CustomCalender.types';
import { FormatMessageFuncType } from '../../utils/intlHelper';
import { IMetadataElement, IReportsMetadata, TythonReportingDataRow } from '../@data/store/schema';
import { IMetricMarkerProps } from '../MetricMarker/MetricMarker.types';
import { IActiveFilter } from '../ReportDetailsPage/ActionBar/ActionBar';

export interface IReportCardProps extends IRouterWithIntl {
  title: string;
  type: ReportCardType;
  allowedChartTypes: IChartButton[];
  defaultColumns: TythonColumnsEnum[];
  defaultMetrics: TythonColumnsEnum[];
  enableHideChartsButton?: boolean;
  hasMinimumBreakdown?: boolean;
  reportData?: TythonReportingDataRow[];
  summaryData?: TythonReportingDataRow[];
  reportDetailedData?: TythonReportingDataRow[];
  isSummaryDataLoaded?: boolean;
  reportMetadata?: IReportsMetadata;
  defaultChartGrain?: ChartGrain;
  allowedChartGrains: IMetadataElement[];
  showChartGrains?: boolean;
  showMetricsMarkers?: boolean;
  dataMetrics: IReportDataMetrics;
  mainDimension?: string;
  dateDimension: string;
  showDetailsReportLink: boolean;
  dateRange: ICustomCalenderDateRange;
  filters: IActiveFilter[];
  isDetailedView: boolean;
  onChartGrainChanged?: (grain: ChartGrain) => void;
  onSelectedMetricChanged?: (metric: string) => void;
  onActiveMetricsChanged?: (activeMetrics: ActiveMetrics) => void;
  onBreakDownChanged?: (newBreakdown: string[]) => void;
  reportCardRef: RefObject<HTMLDivElement>;
}

export interface IChartButton {
  chartType: ChartTypes;
  disabled: boolean;
  iconName: string;
  dataTestId?: string;
}

export interface IReportOptions {
  mainDimension: string;
}

export interface IReportDataMetrics {
  chartDataMetrics: string;
}
export interface IChartsOptions {
  currentChartType: ChartTypes;
  currentChartGrain: ChartGrain;
  reportData: TythonReportingDataRow[];
  mainDimension?: IMetadataElement;
  selectedMetric: IMetadataElement;
  chartComponentRef: React.RefObject<HighChartsWrapper.RefObject>;
  mapChartComponentRef: React.RefObject<HighChartsWrapper.RefObject>;
  activeMetrics: ActiveMetrics;
  dateDimension: string;
  countryCodeDimension?: string;
  formatMessage: FormatMessageFuncType;
}
export interface IReportDetailsPageParams {
  type: ReportCardType;
}

export enum ChartTypes {
  DONUT = 'Donut',
  BAR = 'Bar',
  GRID = 'Grid',
  LINE = 'Line',
  MAP = 'Map',
}

export enum ChartGrain {
  NONE = 'None',
  HOURLY = 'Hour',
  DAILY = 'Day',
  WEEKLY = 'Week',
  MONTHLY = 'Month',
  YEARLY = 'Year',
  MONTH_AND_YEAR = 'MonthAndYear',
}
export enum ReportCardType {
  PERFORMANCE = 'performance',
  ADUNIT = 'adunit',
  SITE = 'site',
  COUNTRY = 'country',
  MEDIATION = 'mediation',
  CUSTOM = 'custom',
  SUMMARY = 'summary',
}

export type ActiveMetrics = Record<string, IMetricMarkerProps>;
