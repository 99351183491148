import { DirectionalHint, HoverCard, HoverCardType, ICalloutProps, Icon } from '@fluentui/react';
import React from 'react';
import { getClassNames } from '../GridV2.styles';

interface IHeaderInfoCalloutProps {
  placement?: DirectionalHint;
  calloutContent: JSX.Element | null;
  width?: number;
  hoverCardStyles?: React.CSSProperties;
  calloutProps?: ICalloutProps;
}

export const HeaderInfoCallout = (headerInfo: IHeaderInfoCalloutProps) => {
  const {
    placement = DirectionalHint.bottomCenter,
    calloutContent,
    width = 490,
    hoverCardStyles = { width: width, padding: '18px' },
    calloutProps = {
      isBeakVisible: true,
    },
  } = headerInfo;
  const { columnHeaderCallout, columnHeaderIcon } = getClassNames();

  return (props, defaultRender) => {
    if (!defaultRender || !props) {
      return null;
    }

    return (
      <div className={columnHeaderCallout}>
        {defaultRender?.(props)}

        <HoverCard
          style={hoverCardStyles}
          plainCardProps={{
            directionalHint: placement,
            calloutProps: calloutProps,
            onRenderPlainCard: () => calloutContent,
          }}
          type={HoverCardType.plain}
          sticky={true}
        >
          <Icon className={columnHeaderIcon} title="clarityTourIcon" iconName={'info'} />
        </HoverCard>
      </div>
    );
  };
};
