import * as React from 'react';
import { injectIntl } from 'react-intl';
import { IIndexPageProps } from './IndexPage.types';

import messages from './IndexPage.messages';

export const IndexPage = injectIntl(class extends React.Component<IIndexPageProps> {
  public render(): JSX.Element {
    return <h1>{this.props.intl.formatMessage(messages.title)}</h1>;
  }
});