import { mutatorAction } from 'satcheljs';
import { getAppStore, upperCaseCountryCode } from '../../../../../../@data';
import { getProfileStore } from '../../../../Profile/@data';
import { getStore } from '../store/store';

export const onLanguageChanged = mutatorAction('onLanguageChanged', (locale: string, localStorageKey: string) => {
  const { systemPreferences } = getStore().active;
  if (locale && locale !== 'redirect') {
    localStorage.setItem(localStorageKey, locale);

    if (systemPreferences) {
      systemPreferences.locale = locale;
    } else {
      getStore().active.systemPreferences = {
        locale: locale,
      };
    }
    if (getProfileStore().raw.primaryUser !== undefined) {
      getProfileStore().raw.primaryUser!.language = upperCaseCountryCode(locale);
    }

    getAppStore().locale = locale;
  }
});

export const onCurrencyChanged = mutatorAction('onCurrencyChanged', (currency: string) => {
  const { systemPreferences } = getStore().active;
  if (systemPreferences) {
    systemPreferences.currency = currency;
  } else {
    getStore().active.systemPreferences = {
      currency: currency,
    };
  }
});
