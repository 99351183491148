import React from 'react';
import { completeAccess, hasReadAccess, UserRoleEntity } from '../../../../@data/services/UserRoleService';
import { PublisherProfileTabs } from '../../../../@data/store/schema/userrolemodels/PublisherProfileTabs';
import { getEntityAccessPermissions } from '../../../../@data/utils/getAccessPermission';
import { ITabProps, Tabs } from '../../../../components/Tabs';
import { EmptyLayout } from '../../../../layout/EmptyLayout/EmptyLayout';
import { injectIntlWithObserver } from '../../../../utils';
import { LazyProfilePage } from '../../Profile/LazyProfilePage';
import UserManagementPage from '../../UserManagement/UserManagement';
import { PAYMENTS_TAB_KEY, PROFILE_TAB_KEY, SETTINGS_TAB_KEY, USER_MANAGEMENT_TAB_KEY } from '../constants';
import { PaymentsPage } from '../Payments/PaymentsPage';
import { LazySettingsPage } from '../Settings/LazySettingsPage';
import { accountManageMessages } from './ManagePage.messages';
import { getClassNames } from './ManagePage.styles';

// TODO: Refactor this to use a common component for lazy loading logic

export const ManagePage = injectIntlWithObserver(({ intl: { formatMessage } }) => {
  const { layoutRootStyle } = getClassNames();

  const publisherProfileAccess = getEntityAccessPermissions(UserRoleEntity.PublisherProfileTabs) as PublisherProfileTabs | null;

  function getTabs() {
    const tabs: ITabProps[] = [];

    if (hasReadAccess(publisherProfileAccess === null ? completeAccess : publisherProfileAccess.profile)) {
      tabs.push({
        key: PROFILE_TAB_KEY,
        headerText: formatMessage(accountManageMessages.profileTab),
        component: <LazyProfilePage />,
      });
    }

    if (hasReadAccess(publisherProfileAccess === null ? completeAccess : publisherProfileAccess.userManagement)) {
      tabs.push({
        key: USER_MANAGEMENT_TAB_KEY,
        headerText: formatMessage(accountManageMessages.userManagementTab),
        component: <UserManagementPage />,
      });
    }

    if (hasReadAccess(publisherProfileAccess === null ? completeAccess : publisherProfileAccess.payments)) {
      tabs.push({
        key: PAYMENTS_TAB_KEY,
        headerText: formatMessage(accountManageMessages.paymentsTab),
        component: <PaymentsPage />, // Any custom component can be passed here
      });
    }

    if (hasReadAccess(publisherProfileAccess === null ? completeAccess : publisherProfileAccess.settings)) {
      tabs.push({
        key: SETTINGS_TAB_KEY,
        headerText: formatMessage(accountManageMessages.settingsTab),
        component: <LazySettingsPage />,
      });
    }

    return tabs;
  }

  return (
    <EmptyLayout styleProps={{ root: layoutRootStyle }}>
      <Tabs tabs={getTabs()} />
    </EmptyLayout>
  );
});
