import { uiConfig } from '../../config/ui.config';
import {
  AccountStatus,
  PaymentsPageBalance,
  PaymentsPageEstimatedEarnings,
  UserContext,
  XPayJwtTokenRequest,
  XPayJwtTokenResponse,
} from '../store/schema';
import { PaymentsPageEstimatedEarningsRequestParams } from '../store/schema/models/PaymentPageEstimatedEarningsRequestParams';
import { handleApiErrors, handleError } from '../utils/handleApiErrors';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';
import { ApiError } from './ApiService';

export async function getEstimatedEarnings(
  publisherId,
  requestParams: PaymentsPageEstimatedEarningsRequestParams,
  userContext: UserContext
): Promise<PaymentsPageEstimatedEarnings> {
  const url = `${uiConfig.getApiBaseAddress()}/billing/publishers/${publisherId}/earnings?${new URLSearchParams(requestParams)}`;
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      return handleApiErrors<PaymentsPageEstimatedEarnings>(response, url);
    }

    const responseJson = await response.json();
    if (responseJson.data) {
      return responseJson.data;
    }
    return Promise.resolve({} as PaymentsPageEstimatedEarnings);
  } catch (err) {
    const error = `getEstimatedEarnings operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
export async function getBalance(publisherId, currencyCode, userContext: UserContext): Promise<PaymentsPageBalance> {
  const url = `${uiConfig.getApiBaseAddress()}/billing/publishers/${publisherId}/balance?currencyCode=${currencyCode}`;
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      return handleApiErrors<PaymentsPageBalance>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson.data) {
      return responseJson.data;
    }
    return Promise.resolve({} as PaymentsPageBalance);
  } catch (err) {
    const error = `getBalance operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export const getAccountStatus = async (publisherId, userContext: UserContext): Promise<AccountStatus> => {
  const url = `${uiConfig.getApiBaseAddress()}/billing/publishers/${publisherId}/xpayAccountStatus`;
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);
  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      return handleApiErrors<AccountStatus>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson.data) {
      return responseJson.data;
    }
    return Promise.resolve({} as AccountStatus);
  } catch (err) {
    const error = `getAccountStatus operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
};

export async function getXPayJwtToken(body: XPayJwtTokenRequest, userContext: UserContext): Promise<XPayJwtTokenResponse> {
  const url =
    uiConfig.getApiBaseAddress() + '/billing/publishers/' + body.publisherId + '/generateXPayToken?countryCode=' + body.countryCode;
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      return handleApiErrors<XPayJwtTokenResponse>(response, url);
    }

    const responseJson = await response.json();
    if (responseJson.data) {
      return responseJson.data;
    }
    return Promise.resolve({} as XPayJwtTokenResponse);
  } catch (err) {
    const error = `getXPayJwtToken operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
