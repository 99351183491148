import { defineMessages } from 'react-intl';
import { VerificationCodeGenerationErrorCodes } from './@data/store/schema/VerificationCodeGenerationErrorCodes';

export default defineMessages({
  [VerificationCodeGenerationErrorCodes.INVALID_INPUT]: {
    id: 'profilePage.verificationErrors.InvalidInput',
    defaultMessage: 'Invalid email format.',
  },
  [VerificationCodeGenerationErrorCodes.CODE_GENERATION_LIMIT_REACHED]: {
    id: 'profilePage.verificationErrors.CodeGenerationLimitReached',
    defaultMessage: 'Code generation limit reached.',
  },
  [VerificationCodeGenerationErrorCodes.REQUESTED_CODE_VERIFICATION_RESULT_NOT_FOUND_FOR_PUBLISHER]: {
    id: 'profilePage.verificationErrors.CodeGenerationLimitReached',
    defaultMessage: 'No existing code verification result found for this publisher.',
  },
});
