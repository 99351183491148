import { defineMessages } from 'react-intl';

export default defineMessages({
  customLabel: {
    id: 'customCalender.CustomLabel',
    defaultMessage: 'Custom',
  },
  todayLabel: {
    id: 'customCalender.todayLabel',
    defaultMessage: 'Today',
  },
  yesterdayLabel: {
    id: 'customCalender.yesterdayLabel',
    defaultMessage: 'Yesterday',
  },
  sundayTodayLabel: {
    id: 'customCalender.yesterdayLabel',
    defaultMessage: 'This week (Sun - Today)',
  },
  mondayTodayLabel: {
    id: 'customCalender.sundayTodayLabel',
    defaultMessage: 'This week (Mon - Today)',
  },
  last7FromTodayLabel: {
    id: 'customCalender.mondayTodayLabel',
    defaultMessage: 'Last 7 days',
  },
  last7FromSunToSatLabel: {
    id: 'customCalender.last7FromSunToSatLabel',
    defaultMessage: 'Last week (Sun - Sat)',
  },
});
