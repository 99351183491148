import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { ICommonStyleProps } from '../../types';

type StyleProperties = 'messageBarButtonStyle' | 'linkStyle';
export type AdManagementStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): AdManagementStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing } = theme;

  return mergeStyleSets({
    messageBarButtonStyle: {
      position: 'absolute',
      top: spacing.size4,
      right: spacing.size32,
    },
    linkStyle: {
      textDecoration: 'none',
      color: theme.palette.themePrimary,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): AdManagementStyles {
  return getStyles({ theme: getTheme(), ...props });
}
