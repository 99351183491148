import { IChoiceGroupOption, IComboBoxOption } from '@fluentui/react';
import { History } from 'history';
import { action } from 'satcheljs';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';

export const onFormIdChanged = action('onFormIdChanged', (value: number) => ({ value }));

export const onFormFirstNameChanged = action('onFormFirstNameChanged', (value?: string) => ({ value }));

export const onFormLastNameChanged = action('onFormLastNameChanged', (value?: string) => ({ value }));

export const onFormPhoneNumberChanged = action('onFormPhoneNumberChanged', (value?: string) => ({ value }));

export const onFormEmailAddressChanged = action('onFormEmailAddressChanged', (value?: string) => ({ value }));

export const onFormUsernameChanged = action('onFormUsernameChanged', (value?: string) => ({ value }));

// need to change string to IDropdown option once language is fixed
export const onFormLanguageChanged = action('onFormLanguageChanged', (value?: string) => ({ value }));

export const onFormUserLocaleChanged = action('onFormUserLocaleChanged', (value?: IComboBoxOption) => ({
  value,
}));

export const onFormContactByEmailChanged = action('onContactByEmailChanged', (value?: boolean) => ({ value }));

export const onFormContactByPhoneChanged = action('onFormContactByPhoneChanged', (value?: boolean) => ({ value }));

export const onFormAccountRoleChanged = action('onFormAccountRoleChanged', (value?: IChoiceGroupOption) => ({ value }));

export const onFormRoleAccountListChanged = action('onFormRoleAccountListChanged', (value?: IIdNamePair[]) => ({
  value,
}));

export const onFormInitialized = action('onFormInitialized', (mode: FormState, publisherId: string, entityId?: string) => ({
  mode,
  publisherId,
  entityId,
}));

export const onFormCancelled = action('onFormCancelled');

export const onFormCleared = action('onFormCleared');

export const onFormSubmitted = action('onFormSubmitted', (mode: FormState, routeHistory?: History) => ({ mode, routeHistory }));
