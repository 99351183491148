import { Model } from '.';
import { SecretQuestion } from '../enums';
import { IAddress, IContactInfo, INamePair, IUserRoleDetail } from '../interfaces';

export class User extends Model {
  public apiPath = undefined;
  public className = 'users';
  public contactByPost?: boolean;
  public contactByPhone?: boolean;
  public contactByEmail?: boolean;
  public contactInfo?: IContactInfo;
  public email?: string;
  public language?: string;
  public username?: string;
  public secretQuestion?: SecretQuestion;
  public postalAddress?: IAddress;
  public userRoleDetails?: IUserRoleDetail[];
  public windowsLiveIdUsername?: string;
  public userRoleDetailsETag?: string;
  public name?: INamePair;
  public phoneNumber?: string;
}
