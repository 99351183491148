import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseOption: {
    id: 'signup.chooseAccount.chooseOption',
    defaultMessage: 'Choose account type',
  },
  organizationContent: {
    id: 'pubcenter.accountType.organization.content',
    defaultMessage: 'Intended for business use.',
  },
  individualContent: {
    id: 'signup.chooseAccount.individualContent',
    defaultMessage: 'Sign up with your individual Microsoft account.',
  },
  organizationSubContentI: {
    id: 'pubcenter.accountType.organization.subcontentI',
    defaultMessage: 'This is ideal for multiple accounts within a business.',
  },
  organizationSubContentII: {
    id: 'signup.chooseAccount.organizationSubcontentII',
    defaultMessage: 'Requires business entity verification.',
  },
  organizationRequirements: {
    id: 'pubcenter.accountType.organization.organizationRequirements',
    defaultMessage:
      'Requirements: Business verification, business email address (e.g., email@contoso.com) and business domain URL (e.g., contoso.com).',
  },
  individualSubContentI: {
    id: 'pubcenter.accountType.individual.subcontentI',
    defaultMessage: 'Ideal for self-management.',
  },
  individualSubContentII: {
    id: 'signup.chooseAccount.individualSubcontentII',
    defaultMessage: 'Requires personal information verification  ',
  },
  individualRequirements: {
    id: 'pubcenter.accountType.individual.individualRequirements',
    defaultMessage: 'Requirements: Personal information verification and Microsoft account.',
  },
  individualHelperLinkText: {
    id: 'pubcenter.accountType.individual.individualHelperLinkText',
    defaultMessage: 'Learn more about creating a Microsoft account.',
  },
  moreInfo: {
    id: 'signup.chooseAccount.moreInfo',
    defaultMessage: 'More info',
  },
  individualBadgeText: {
    id: 'pubcenter.accountType.individual.individualBadgeText',
    defaultMessage: 'Most common',
  },
});
