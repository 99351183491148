import { defineMessages } from 'react-intl';

export default defineMessages({
  clarityProjectDetails: {
    id: 'admanagement.property.clarityProjectDetails',
    defaultMessage: 'Clarity project details',
  },
  enableMicrosoftClarity: {
    id: 'property.clarity.enableMicrosoftClarity',
    defaultMessage: 'Enable Clarity',
  },
  clarityProjectId: {
    id: 'property.clarity.existingClarityProjectLabel',
    defaultMessage: 'Clarity Project ID',
  },
  clarityProjectResendButton: {
    id: 'property.clarity.clarityProjectResendButton',
    defaultMessage: 'Resend',
  },
});
