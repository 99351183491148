import { mutator } from 'satcheljs';
import { MediaType, PaymentInstrumentLifeCycleStatus, PaymentOption } from '../../../../../@data';
import { FinancialStatus } from '../../../../../@data/store/schema/enums/FinancialStatus';
import { countryToCurrencyMap } from '../../../../../config/countryToCurrencyMapper.config';
import {
  onAccountIdChanged,
  onAccountSkuFormCleared,
  onAccountSkuFormMediumChanged,
  onAccountSkuFormSkuChanged,
  onFormAccountAllowAdultAdsChanged,
  onFormAccountAllowCallExtensionsChanged,
  onFormAccountAllowCrawlingChanged,
  onFormAccountAllowGlobalDefaultKeywordsChanged,
  onFormAccountAllowLocalExtensionsChanged,
  onFormAccountAllowLocationExtensionsChanged,
  onFormAccountAllowMerchantRatingExtensionsChanged,
  onFormAccountAllowMeteredCallExtensionsChanged,
  onFormAccountAllowPassedKeywordChanged,
  onFormAccountAllowPassedURLChanged,
  onFormAccountAllowPhoneExtensionsChanged,
  onFormAccountAllowSiteLinkExtensionsChanged,
  onFormAccountCountryChanged,
  onFormAccountCurrencyChanged,
  onFormAccountFinancialStatusChanged,
  onFormAccountFormCodesChanged,
  onFormAccountLanguageChanged,
  onFormAccountNameChanged,
  onFormAccountOperationRatioChanged,
  onFormAccountPartnerCodesChanged,
  onFormAccountPaymentLifeCycleStatusChanged,
  onFormAccountPaymentOptionChanged,
  onFormAccountPrimaryContactChanged,
  onFormAccountStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageChanged,
  onFormAccountStartDistributionOCIDL2sChanged,
  onFormAccountStartDistributionPlacementCodesChanged,
  onFormAccountStoreIdsChanged,
  onFormAccountTimezoneChanged,
  onFormCleared,
} from '../actions';
import { getStore } from '../store/store';

mutator(onAccountIdChanged, (msg) => {
  getStore().active.id = msg.value;
});

mutator(onFormAccountNameChanged, (msg) => {
  getStore().active.name = msg.value;
});

mutator(onFormAccountCurrencyChanged, (msg) => {
  const countyCode = getStore().active.countryCode!;
  getStore().active.currencyCode = countryToCurrencyMap[countyCode];
});

mutator(onFormCleared, () => {
  getStore().active = {};
});

mutator(onAccountSkuFormMediumChanged, (msg) => {
  getStore().accountSku!.medium = msg.value.key as MediaType;
});

mutator(onAccountSkuFormSkuChanged, (msg) => {
  getStore().accountSku!.sku = msg.value.key as string;
});

mutator(onAccountSkuFormCleared, () => {
  getStore().accountSku = undefined;
});

mutator(onFormAccountCountryChanged, (msg) => {
  getStore().active.countryCode = msg.value!!.key as string;
});

mutator(onFormAccountLanguageChanged, (msg) => {
  getStore().active.languageCode = msg.value.key as string;
});

mutator(onFormAccountPrimaryContactChanged, (msg) => {
  getStore().active.primaryContactUserId = msg.value.key as number;
});

mutator(onFormAccountTimezoneChanged, (msg) => {
  getStore().active.timeZoneId = msg.value!!.key as number;
});

mutator(onFormAccountPaymentOptionChanged, (msg) => {
  getStore().active.paymentOption = msg.value!!.key as PaymentOption;
});

mutator(onFormAccountFinancialStatusChanged, (msg) => {
  getStore().active.financialStatus = msg.value!!.key as FinancialStatus;
});

mutator(onFormAccountPaymentLifeCycleStatusChanged, (msg) => {
  getStore().active.paymentLifeCycleStatus = msg.value!!.key as PaymentInstrumentLifeCycleStatus;
});

mutator(onFormAccountOperationRatioChanged, (msg) => {
  getStore().active.operationRatio = Number(msg.value as string);
});

mutator(onFormAccountAllowPassedURLChanged, (msg) => {
  getStore().active.allowPassedURL = msg.value;
});

mutator(onFormAccountAllowPassedKeywordChanged, (msg) => {
  getStore().active.allowPassedKeyword = msg.value;
});

mutator(onFormAccountAllowCrawlingChanged, (msg) => {
  getStore().active.allowCrawling = msg.value;
});

mutator(onFormAccountAllowAdultAdsChanged, (msg) => {
  getStore().active.allowAdultAds = msg.value;
});

mutator(onFormAccountAllowGlobalDefaultKeywordsChanged, (msg) => {
  getStore().active.allowGlobalDefaultKeywords = msg.value;
});

mutator(onFormAccountAllowMeteredCallExtensionsChanged, (msg) => {
  getStore().active.allowMeteredCallExtensions = msg.value;
});

mutator(onFormAccountAllowLocationExtensionsChanged, (msg) => {
  getStore().active.allowLocationExtensions = msg.value;
});

mutator(onFormAccountAllowPhoneExtensionsChanged, (msg) => {
  getStore().active.allowPhoneExtensions = msg.value;
});

mutator(onFormAccountAllowMerchantRatingExtensionsChanged, (msg) => {
  getStore().active.allowMerchantRatingExtensions = msg.value;
});

mutator(onFormAccountAllowLocalExtensionsChanged, (msg) => {
  getStore().active.allowLocalExtensions = msg.value;
});

mutator(onFormAccountAllowCallExtensionsChanged, (msg) => {
  getStore().active.allowCallExtensions = msg.value;
});

mutator(onFormAccountAllowSiteLinkExtensionsChanged, (msg) => {
  getStore().active.allowSiteLinkExtensions = msg.value;
});

mutator(onFormAccountStoreIdsChanged, (msg) => {
  getStore().active.storeIds = msg.values;
});

mutator(onFormAccountPartnerCodesChanged, (msg) => {
  getStore().active.partnerCodes = msg.values;
});

mutator(onFormAccountFormCodesChanged, (msg) => {
  getStore().active.formCodes = msg.values;
});

mutator(onFormAccountStartDistributionPlacementCodesChanged, (msg) => {
  getStore().active.startDistributionPlacementCodes = msg.values;
});

mutator(onFormAccountStartDistributionOCIDL2sChanged, (msg) => {
  getStore().active.startDistributionOCIDL2s = msg.values;
});

mutator(onFormAccountStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageChanged, (msg) => {
  getStore().active.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage =
    msg.value !== null && msg.value !== undefined ? (isNaN(parseFloat(msg.value)) ? undefined : parseFloat(msg.value)) : undefined;
});
