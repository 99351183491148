import { DefaultButton } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { GRAIN_SELECTOR_BUTTON } from '../../../../constants/DataTestIdConstants';
import { getReportGrainsMetadataDisplayName } from '../../utils/intlHelper';
import { IMetadataElement } from '../@data/store/schema';
import { getClassNames } from '../ReportCard/ReportCard.styles';
import { ChartGrain } from '../ReportCard/ReportCard.types';
export interface IGrainSelectorProps extends InjectedIntlProps {
  activeGrain: ChartGrain;
  activeGrainChanged: (grain: ChartGrain) => void;
  allowedGrains: IMetadataElement[];
}

export const GrainSelector = injectIntl(
  ({ allowedGrains, activeGrain, activeGrainChanged, intl: { formatMessage } }: IGrainSelectorProps) => {
    const { active, buttonBorder } = getClassNames();
    return (
      <>
        <div>
          {allowedGrains
            .filter((allowedGrain) => allowedGrain.key !== ChartGrain.NONE)
            .map((allowedGrain) => {
              const isActive = activeGrain === (allowedGrain.key as ChartGrain);
              return (
                <DefaultButton
                  data-test-id={`${GRAIN_SELECTOR_BUTTON}-${allowedGrain.key}`}
                  key={allowedGrain.key}
                  className={isActive ? active : buttonBorder}
                  toggle
                  checked={isActive}
                  text={getReportGrainsMetadataDisplayName(allowedGrain.key, formatMessage)}
                  onClick={() => {
                    activeGrainChanged(allowedGrain.key as ChartGrain);
                  }}
                  allowDisabledFocus
                />
              );
            })}
        </div>
      </>
    );
  }
);
