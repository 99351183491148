import { Toggle } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { ClarityStatus, getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged } from '../../../../../../../../@data';
import { completeAccess, hasReadAccess } from '../../../../../../../../@data/services/UserRoleService';
import { FormItem, FormSection, FormState } from '../../../../../../../../components/Form';
import { FormLabel } from '../../../../../../../../components/Form/FormLabel';
import { injectIntlAndObserver } from '../../../../../../../../utils';
import { IClarityParams } from '../../../../../Manage/components/ClarityPanel/types';
import { onEnableClarityToggleChanged } from '../../__data__/mutatorActions';
import { GetPropertyAccessPermission } from '../../utils/GetPropertyAccessPermissions';
import { IsDisabled } from '../../utils/IsFieldDisabled';
import ClarityInputField from './components/ClarityInputField';
import ConnectedClarityField from './components/ConnectedClarityField';
import messages from './messages';

interface IClarityInputFieldProps {
  mode: FormState;
  clarityConfigs: IClarityParams | undefined;
  isShownInPanel?: boolean;
}

const ClaritySection = injectIntlAndObserver<InjectedIntlProps & IClarityInputFieldProps>(
  ({ intl, mode, clarityConfigs, isShownInPanel = false }) => {
    const { helpInfo, locale } = getAppStore();

    const { formatMessage } = intl;

    const propertyAccessPermissions = GetPropertyAccessPermission();
    const accessLevel = propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.clarity;

    if (!hasReadAccess(accessLevel)) {
      return null;
    }

    const showToggle = mode === FormState.Edit && hasReadAccess(accessLevel) && clarityConfigs?.clarityStatus;
    const showInput = mode !== FormState.Edit || clarityConfigs?.clarityStatus !== ClarityStatus.Approved || clarityConfigs?.clarityEdit;

    const getLabel = (): JSX.Element => {
      return (
        <FormLabel
          id={'clarityProjectId'}
          labelContent={formatMessage(messages.clarityProjectId)}
          calloutContent={helpInfo}
          iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_EnableMSClarity', locale)}
          calloutOnDismiss={() => onHelpInfoChanged()}
          intl={intl}
        />
      );
    };

    const getClaritySection = () => {
      return (
        <>
          {showToggle && (
            <FormItem>
              <Toggle
                inlineLabel={true}
                label={formatMessage(messages.enableMicrosoftClarity)}
                checked={clarityConfigs?.clarityLinkingEnabled}
                onChange={(_, value) => onEnableClarityToggleChanged(value || false)}
                disabled={IsDisabled(mode, accessLevel)}
              />
            </FormItem>
          )}

          {(clarityConfigs?.clarityLinkingEnabled || mode !== FormState.Edit) && (
            <>
              {getLabel()}
              {showInput ? (
                <ClarityInputField configs={clarityConfigs} isShownInPanel={isShownInPanel} />
              ) : (
                <ConnectedClarityField configs={clarityConfigs} />
              )}
            </>
          )}
        </>
      );
    };

    return isShownInPanel ? (
      getClaritySection()
    ) : (
      <FormSection title={formatMessage(messages.clarityProjectDetails)} hasBorderBottom={false}>
        {getClaritySection()}
      </FormSection>
    );
  }
);

export default ClaritySection;
