import { getAppStore, isPublisherRejectionUnappealable, isPublisherUnderReview } from '../../../../../@data';
import { getProfileStore } from './store';

export function getPublisherProfileRawData() {
  return getAppStore().publisher;
}

export function getPublisherProfileActiveData() {
  return getProfileStore().active;
}

export function getProfileErrors() {
  return getProfileStore().errors;
}

export function getPrimaryUserRawData() {
  return getProfileStore().raw.primaryUser;
}

export function getPrimaryUserFullName() {
  const { name } = getPrimaryUserRawData() || {};
  if (!name) {
    return '';
  }

  return `${name?.firstName} ${name?.lastName}`;
}

export function getPrimaryUserActiveData() {
  return getPublisherProfileActiveData().user;
}

export function getPublisherActiveData() {
  return getPublisherProfileActiveData().publisher;
}

export function isPrimaryUserDataPresent() {
  return !!getPrimaryUserRawData();
}

export function isProfileEditable() {
  if (isPublisherUnderReview()) {
    return false;
  }
  if (isPublisherRejectionUnappealable()) {
    return false;
  }

  return true;
}

export function getProfileActiveData() {
  return getProfileStore().active;
}

export function isProfileInEditMode() {
  return !!getProfileActiveData().isInEditMode;
}

export function isProfileSubmitted() {
  return !!getProfileActiveData().isSubmitted;
}

export function isPreviewShown() {
  return !!getProfileActiveData().isPreviewShown;
}

export function isPhoneVerificationMethodsShown() {
  return !!getProfileActiveData().isPhoneVerificationMethodsShown;
}

export function isVerificationCodeDialogShown() {
  return !!getProfileActiveData().isPhoneVerificationCodeShown;
}

export function isVerificationSuccessShown() {
  return !!getProfileActiveData().isVerificationSuccess;
}
export function isCodeBeingVerified() {
  return !!getProfileActiveData().isCodeBeingVerified;
}

export function isVerificationCodeGenerationLimitReached() {
  return !!getProfileActiveData().verificationCodeGenerationLimitReached;
}
