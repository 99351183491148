import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { AdUnit, getAppStore, isTythonSelfServeUser, listEntity, putEntity, showErrorToastNotification } from '../../../../../@data';
import { AdSenseOAuthStatus } from '../../../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { AdSenseAdUnitStatus } from '../../../../../@data/store/schema/models/AdSenseAdUnitStatus';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import { loadPropertiesServingConfigs } from '../../../Property/@data/orchestrators';
import messages from '../../Manage/ManagePage.messages';
import { fetchAdUnitsDataAction } from '../actions';
import { setAdUnitsData, setAdUnitsDataFetching, setAdUnitsLoading, setAdUnitsMediationFetching } from '../mutatorActions';
import { getAdunitStore } from '../store/store';

orchestrator(fetchAdUnitsDataAction, async () => {
  try {
    const { publisher, userContext, account } = getAppStore();
    const { adUnitsRaw } = getAdunitStore();

    if (!publisher) {
      return;
    }

    if (publisher && account) {
      // make a get request
      setAdUnitsDataFetching(true);
      if (adUnitsRaw.data === undefined || adUnitsRaw.data.length === 0) {
        setAdUnitsLoading(true);
      }
      const params = new URLSearchParams([['extendedInfo', 'true']]);
      const data = await listEntity([publisher, account], userContext, AdUnit, params);
      data.forEach((adUnit) => {
        if (!!adUnit.creationDate) {
          adUnit.creationDate = new Date(adUnit.creationDate).toISOString();
        }
      });
      if (!!adUnitsRaw.data?.[0]?.mediatedWithAdSense) {
        data?.forEach((adUnit) => {
          const adUnitStatus = adUnitsRaw.data?.find((item) => item.id === Number(adUnit.id))?.mediatedWithAdSense;
          if (adUnitStatus) {
            adUnit.mediatedWithAdSense = adUnitStatus;
          }
        });
      }
      data.sort((a, b) => {
        if (a.creationDate && b.creationDate) {
          return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
        }
        return 0;
      });
      setAdUnitsData(data);

      // Fetch adunit serving configs
      if (isTythonSelfServeUser()) {
        // We need property serving config to derive final adunit serving status
        loadPropertiesServingConfigs();
      }

      const { active } = getGoogleAdSenseStore();

      try {
        if (active.connectionStatus === AdSenseOAuthStatus.Success && active.isMediationEnabled) {
          setAdUnitsMediationFetching(true);
          const adUnitIds = data?.map((item) => item.id.toString()) as string[];
          if (adUnitIds && adUnitIds.length !== 0) {
            const request: AdSenseAdUnitStatus = {
              apiPath: 'preauth/mediation',
              className: 'getAdUnitMediationStatus',
              pubcenterAdUnitIds: adUnitIds.map((id) => Number.parseInt(id, 10)),
            };

            const urlParams = new URLSearchParams();
            urlParams.append('routeSuffix', 'api/mediation/admanager/getAdUnitMediationStatus');
            // Set the type as any because the PUT operation in ApiService only
            // returns singular item and this request for AdUnits mediation status returns array
            // TODO: this call shouldn't be put call
            // TODO: put operation should support array of objects as a response
            // tslint:disable-next-line: no-any
            const response = (await putEntity([], request, undefined, AdSenseAdUnitStatus, urlParams)) as any;

            if (response) {
              data?.forEach((adUnit) => {
                const adUnitStatus = response.find((pubcenterAdUnitId) => pubcenterAdUnitId.pubcenterAdUnitId === Number(adUnit.id));
                if (adUnitStatus) {
                  adUnit.mediatedWithAdSense = adUnitStatus;
                }
              });
            }
          }
        }
      } catch (error) {
        Log.error(error);
        data?.forEach((adUnit) => {
          adUnit.mediatedWithAdSense = {
            apiPath: 'preauth/mediation',
            className: 'getAdUnitMediationStatus',
            mediationStatus: false,
          };
        });
        showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadAdunitsMediationStatus });
      }

      setAdUnitsData(data);
      // set the response data in adUnitsRaw.data
    }
  } catch (error) {
    Log.error(error);
    showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadAdunits });
  } finally {
    setAdUnitsLoading(false);
    setAdUnitsMediationFetching(false);
    setAdUnitsDataFetching(false);
  }
});
