import { mutatorAction } from 'satcheljs';
import { AuditHistoryRequestFilter, AuditLog, AuditType, UserRole } from '../../../../../@data';
import { ICustomCalenderDateRange } from '../../../../report/components/CustomCalender/CustomCalender.types';
import { getAuditHistoryStore } from '../store/store';

export const onSelectedDatesChanged = mutatorAction('onSelectedDatesChanged', (dateRange: ICustomCalenderDateRange) => {
  dateRange.endDate.setDate(dateRange.endDate.getDate() + 1);

  getAuditHistoryStore().startDate = dateRange.startDate;
  getAuditHistoryStore().endDate = dateRange.endDate;
  getAuditHistoryStore().pageNumber = 1;
});

export const setShowAllDetails = mutatorAction('setShowAllDetails', (showDetails: boolean) => {
  getAuditHistoryStore().isShowAllDetailsEnabled = showDetails;
});

export const setAuditLogs = mutatorAction('setAuditLogs', (auditLogs: AuditLog[]) => {
  getAuditHistoryStore().data = auditLogs;
});

export const setTotalPages = mutatorAction('setTotalPages', (totalPages: number) => {
  getAuditHistoryStore().totalPages = totalPages;
});

export const setTotalRecords = mutatorAction('setTotalRecords', (totalRecords: number) => {
  getAuditHistoryStore().totalRecords = totalRecords;
});

export const setPageNumber = mutatorAction('setPageNumber', (pageNumber: number) => {
  getAuditHistoryStore().pageNumber = pageNumber;
});

export const setPageSize = mutatorAction('setPageSize', (pageSize: number) => {
  getAuditHistoryStore().pageSize = pageSize;
});

export const setFilters = mutatorAction('setFilters', (filters: AuditHistoryRequestFilter[]) => {
  getAuditHistoryStore().filters = filters;
});

export const setEntityTypes = mutatorAction('setEntityTypes', (entityTypes: string[]) => {
  getAuditHistoryStore().entityTypes = entityTypes;
});

export const setAuditTypes = mutatorAction('setAuditTypes', (auditTypes: AuditType[]) => {
  getAuditHistoryStore().auditTypes = auditTypes;
});

export const setUserRoles = mutatorAction('setUserRoles', (userRoles: UserRole[]) => {
  getAuditHistoryStore().userRoles = userRoles;
});

export const setIsDataLoading = mutatorAction('setIsDataLoading', (isDataLoading: boolean) => {
  getAuditHistoryStore().isDataLoading = isDataLoading;
});

export const resetFilters = mutatorAction('resetFilters', () => {
  const store = getAuditHistoryStore();
  store.filters = [];
  store.entityTypes = [];
  store.auditTypes = [];
  store.userRoles = [];
});
