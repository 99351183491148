import { MessageBarType } from '@fluentui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { Log } from '../../../../../../logging/src';
import {
  AdUnit,
  ClarityType,
  IAppState,
  Property,
  TythonAdUnitServingConfig,
  listEntity,
  onNotificationChanged,
} from '../../../../../@data';
import { useStateWithLatestCallback } from '../../../../../hooks';
import { IGoogleAdSenseViewState } from '../../../Account/Settings/components/GoogleConnector/@data';
import { onInitializeGoogleAccount as fetchAdSenseAccountStatus } from '../../../Account/Settings/components/GoogleConnector/@data/actions';
import { fetchPropertyById } from '../../../Property/@data/services/PropertyService';
import { createProperty as createPropertyService } from '../../../Property/Create/variations/CreateTythonProperty/__data__/services/createProperty';
import { fetchAdunitMediationStatus } from '../../@data/services/AdunitService';
import { IAdunitViewState } from '../../@data/store/schema/IAdunitViewState';
import messages from '../../Create/CreateAdunit.messages';
import { fetchProperties } from '../services/loadProperties';
import { fetchTythonAdUnitById } from '../services/loadTythonAdUnitById';
import {
  DEFAULT_AD_PREVIEW_BACKGROUND_COLOR,
  DEFAULT_AD_PREVIEW_BUTTON_BACKGROUND_COLOR,
  DEFAULT_AD_PREVIEW_FONT,
  DEFAULT_AD_PREVIEW_TITLE_BACKGROUND_COLOR,
  TythonAdTypes,
} from './constants';
import { AdTypes, ITythonAdUnit, NativeAdProperties } from './types';

export type UseAdUnitData = ({
  appStore,
  googleAdsenseStore,
  onPropertyCreated,
  formatMessage,
}: {
  appStore: IAppState;
  googleAdsenseStore: IGoogleAdSenseViewState;
  onPropertyCreated: () => void;
  adUnitsStore: IAdunitViewState;
  formatMessage: InjectedIntl['formatMessage'];
}) => {
  properties: Property[];
  isPropertyLoading: boolean;
  isPropertySaving: boolean;
  getActiveAdunit: () => ITythonAdUnit | undefined;
  activeAdunit: ITythonAdUnit | undefined;
  setActiveAdunit: (adunit: ITythonAdUnit | undefined) => void;
  createProperty: (property: { url: string; name: string; clarityType?: ClarityType; clarityId?: string }) => void;
  isAdUnitSaving: boolean;
  isValidAdUnit: boolean;
  isAdUnitLoading: boolean;
  isAdUnitMediationLoading: boolean;
  initialAdUnit: ITythonAdUnit | undefined;
  setIsAdUnitSaving: (isLoading: boolean) => void;
  isAdUnitInitialized: boolean;
};

const extractAdUnitId = (url: string): string | null => {
  const regex = /\/adunit\/edit\/(\d+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const convertToTythonAdUnitViewState = (data: TythonAdUnitServingConfig): ITythonAdUnit => ({
  id: data.adUnitId,
  accountId: data.accountId,
  propertyId: data.propertyId,
  name: data.adUnitName,
  adTypes: data.adTypes as AdTypes,
  nativeProperties: data.nativeProperties as NativeAdProperties,
  mediationEnabled: false, // this field is not present in your mock data
  raw: data as { id?: number },
});

export const getLargestAdUnitNumber = (arr: string[]): number => {
  let max = arr.length + 1; // Initialize to length of array + 1

  arr.forEach((str) => {
    const match = str.match(/^Ad Unit (\d+)$/); // The regex now ensures that only numbers follow "Ad Unit "
    if (match && match[1]) {
      const num = parseInt(match[1], 10) + 1;
      max = Math.max(max, num);
    }
  });
  return max;
};

/**
 * This hook mainly provides data and render state for ad unit form, it will take appStore and
 * goggleAdsenseStore to understand ad unit creation page render behavior
 */
const useAdUnitData: UseAdUnitData = ({ appStore, googleAdsenseStore, onPropertyCreated, adUnitsStore, formatMessage }) => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [isPropertyLoading, setIsPropertyLoading] = useState<boolean>(false);
  const [isPropertySaving, setIsPropertySaving] = useState<boolean>(false);
  const [isAdUnitLoading, setIsAdUnitLoading] = useState<boolean>(false);
  const [isAdUnitMediationLoading, setIsAdUnitMediationLoading] = useState<boolean>(false);
  const [isAdUnitSaving, setIsAdUnitSaving] = useState<boolean>(false);
  const isPropertyLoadHandled = useRef<boolean>(false);
  const isAdUnitLoadHandled = useRef<boolean>(false);
  const isMediationLoadHandled = useRef<boolean>(false);
  const [activeAdunit, setActiveAdunit, getActiveAdunit] = useStateWithLatestCallback<ITythonAdUnit | undefined>(undefined);
  const initialAdUnit = useRef<ITythonAdUnit | undefined>(undefined);
  const { publisher, userContext, account } = appStore;
  const { adUnitsRaw } = adUnitsStore;
  const shouldFetchProperties = useMemo(() => publisher && !isPropertyLoadHandled.current, [publisher]);
  const isCreatingNewAdUnit = window?.location?.href.includes('adunit/create');
  const isValidAdUnit = useMemo<boolean>(() => !!(activeAdunit?.adTypes?.length && activeAdunit.name && activeAdunit.propertyId), [
    activeAdunit,
  ]);
  const existingAdUnitId = extractAdUnitId(window?.location?.href);
  /**
   * fetch mediation status per ad unit
   * when
   * - user editing an existing ad unit
   * - user level mediation is enabled at account setting page
   * - ad unit is fetched and id is ready to use
   * - publisher and account are both loaded from appStore
   */
  const shouldFetchAdUnitMediationStatus = useMemo(
    () => googleAdsenseStore.active?.isMediationEnabled && publisher && account && activeAdunit?.id && !isMediationLoadHandled.current,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googleAdsenseStore.active?.isMediationEnabled, publisher, account, activeAdunit]
  );

  /**
   * dependency value to initialize page during creation experience
   */
  const shouldInitializeHookOnCreation = useMemo(
    () => isCreatingNewAdUnit && userContext && publisher && account?.id && !isAdUnitLoadHandled.current,
    [isCreatingNewAdUnit, userContext, publisher, account]
  );

  /**
   * dependency value to initialize page during edition experience
   */
  const shouldInitializeHookOnEdition = useMemo(() => existingAdUnitId && userContext && publisher && !isAdUnitLoadHandled.current, [
    existingAdUnitId,
    userContext,
    publisher,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchProperties(publisher!, userContext, setIsPropertyLoading);
      setProperties(data);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchProperties]);

  useEffect(() => {
    fetchAdSenseAccountStatus(true, false);
  }, []);

  useEffect(() => {
    if (shouldFetchAdUnitMediationStatus) {
      const fetchData = async () => {
        setIsAdUnitMediationLoading(true);
        const data = await fetchAdunitMediationStatus(publisher!, account!, `${activeAdunit?.id}`);
        const isAdUnitMediationEnabled = data?.[0]?.mediationStatus;
        initialAdUnit.current = { ...initialAdUnit.current!, mediationEnabled: isAdUnitMediationEnabled };
        setActiveAdunit({ ...getActiveAdunit()!, mediationEnabled: isAdUnitMediationEnabled });
        setIsAdUnitMediationLoading(false);
        isMediationLoadHandled.current = true;
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchAdUnitMediationStatus]);

  useEffect(() => {
    const generateNewAdUnitName = async () => {
      let newAdunitName = '';
      try {
        let data;
        if (adUnitsRaw.data !== undefined) {
          data = adUnitsRaw.data;
        } else {
          const params = new URLSearchParams([['extendedInfo', 'true']]);
          data = await listEntity([publisher!, account!], userContext, AdUnit, params);
        }
        newAdunitName = data?.length
          ? formatMessage(messages.defaultAdUnitName, { number: getLargestAdUnitNumber(data.map((d) => d.name)) })
          : '';
      } catch (e) {
        Log.error(`Fetch Ad Unit failed, ${e}`);
      }
      return newAdunitName;
    };

    const initializeDefaultAdUnit = async () => {
      if (shouldInitializeHookOnCreation) {
        const newAdunitName = await generateNewAdUnitName();
        setActiveAdunit({
          name: newAdunitName,
          adTypes: [TythonAdTypes.Native, TythonAdTypes.Display],
          mediationEnabled: false,
          nativeProperties: {
            fontFamily: DEFAULT_AD_PREVIEW_FONT,
            background: DEFAULT_AD_PREVIEW_BACKGROUND_COLOR,
            buttonBackground: DEFAULT_AD_PREVIEW_BUTTON_BACKGROUND_COLOR,
            titleColor: DEFAULT_AD_PREVIEW_TITLE_BACKGROUND_COLOR,
          },
        });
        isAdUnitLoadHandled.current = true;
      }
    };

    initializeDefaultAdUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInitializeHookOnCreation]);

  useEffect(() => {
    if (shouldInitializeHookOnEdition) {
      // load adunit
      const fetchAdUnit = async () => {
        const data = await fetchTythonAdUnitById(publisher!, userContext!, existingAdUnitId!, formatMessage, setIsAdUnitLoading);
        const fetchedAdUnit = convertToTythonAdUnitViewState(data);
        initialAdUnit.current = fetchedAdUnit;
        setActiveAdunit(fetchedAdUnit);
        isAdUnitLoadHandled.current = true;
      };
      fetchAdUnit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInitializeHookOnEdition]);

  const createProperty = async (property: { url: string; name: string; clarityType?: ClarityType; clarityId?: string }) => {
    const res = await createPropertyService(publisher!, property, formatMessage, userContext, setIsPropertySaving);
    if (res?.id) {
      /**
       * close the property panel in ad unit creation page and
       * render loading experience to fetch latest properties
       */
      onPropertyCreated();
      try {
        const newProperty = await fetchPropertyById(publisher!, res.id, userContext, setIsPropertyLoading);
        setProperties([newProperty, ...properties]);
      } catch (e) {
        onNotificationChanged({
          messageBarType: MessageBarType.error,
          textMessageDescriptor: messages.loadPropertyFailedInAdunit,
        });
      }
    }
  };

  return {
    properties,
    isPropertyLoading,
    isPropertySaving,
    activeAdunit,
    initialAdUnit: initialAdUnit.current,
    isValidAdUnit,
    isAdUnitMediationLoading,
    isAdUnitSaving,
    isAdUnitLoading,
    isAdUnitInitialized: isAdUnitLoadHandled.current,
    setIsAdUnitSaving,
    setActiveAdunit,
    getActiveAdunit,
    createProperty,
    setIsAdUnitLoading,
  };
};

export default useAdUnitData;
