import { TythonColumnsEnum } from '../@data/store/schema/ColumnsEnum';

export const EXCLUDED_FILTER_COLUMNS: TythonColumnsEnum[] = [TythonColumnsEnum.DateTime, TythonColumnsEnum.Date];
export const ALLOWED_MEDIATION_FILTER_COLUMNS: TythonColumnsEnum[] = [TythonColumnsEnum.AdUnitName];
export const MEDIATION_DEFAULT_COLUMNS: TythonColumnsEnum[] = [
  TythonColumnsEnum.AdClicks,
  TythonColumnsEnum.AdECPM,
  TythonColumnsEnum.Revenue,
  TythonColumnsEnum.Date,
  TythonColumnsEnum.AdUnitName,
];
export const MEDIATION_DEFAULT_METRICS: TythonColumnsEnum[] = [TythonColumnsEnum.Revenue];

export const ADUNIT_DEFAULT_COLUMNS: TythonColumnsEnum[] = [
  TythonColumnsEnum.Clicks,
  TythonColumnsEnum.eCPM,
  TythonColumnsEnum.Revenue,
  TythonColumnsEnum.DateTime,
  TythonColumnsEnum.AdUnitName,
];
export const ADUNIT_DEFAULT_METRICS: TythonColumnsEnum[] = [TythonColumnsEnum.Revenue];

export const SITE_DEFAULT_COLUMNS: TythonColumnsEnum[] = [
  TythonColumnsEnum.Clicks,
  TythonColumnsEnum.eCPM,
  TythonColumnsEnum.Revenue,
  TythonColumnsEnum.DateTime,
  TythonColumnsEnum.PropertyUrl,
];
export const SITE_DEFAULT_METRICS: TythonColumnsEnum[] = [TythonColumnsEnum.Revenue];

export const PERFORMANCE_DEFAULT_METRICS: TythonColumnsEnum[] = [
  TythonColumnsEnum.ImpressionsServed,
  TythonColumnsEnum.Clicks,
  TythonColumnsEnum.Revenue,
];
export const PERFORMANCE_DEFAULT_COLUMNS: TythonColumnsEnum[] = [
  TythonColumnsEnum.Clicks,
  TythonColumnsEnum.eCPM,
  TythonColumnsEnum.Revenue,
  TythonColumnsEnum.DateTime,
  TythonColumnsEnum.AdUnitName,
];

export const COUNTRY_DEFAULT_COLUMNS: TythonColumnsEnum[] = [
  TythonColumnsEnum.Clicks,
  TythonColumnsEnum.eCPM,
  TythonColumnsEnum.Revenue,
  TythonColumnsEnum.DateTime,
  TythonColumnsEnum.Country,
];
export const COUNTRY_DEFAULT_METRICS: TythonColumnsEnum[] = [TythonColumnsEnum.Revenue];

export const REPORT_ONBOARDING_RENDER_FLAG = 'isReportOnboardingExperienceTriggered';
export const REPORT_OLD_DATA_NOTIFICATION_DISMISS_FLAG = 'isOldDataNotificationDismissed';
