import React, { useEffect } from 'react';
import { injectRouterAndIntlWithObserver } from '../../../../../utils';
import {
  getTythonAdUnitCreatePageRoute,
  getTythonAdsCreationPageRoute,
  getTythonInventoryCreationPageRoute,
  getTythonProfilePageRoute,
  getTythonSiteCreatePageRoute,
} from '../../../../../utils/routeUtils';

import { usePreloadSVGImages } from '../../../../../hooks';
import { getStore as getWindowsAppFeatureFlagStore } from '../../../../../hooks/useWindowsAppFeatureFlag/store/store';
import ConnectAppImg from '../../../../../layout/EntityCreationLayout/connectApp.svg';
import ConnectSiteImg from '../../../../../layout/EntityCreationLayout/connectSite.svg';
import { editProfileAction } from '../../../Profile/@data/actions';
import { fetchSetupCardsStatus } from '../../@data/actions';
import { getStore } from '../../@data/store/store';
import { SetupCard } from '../SetupCard/SetupCard';
import { ISetupCard } from '../SetupCard/SetupCardUtils';
import AdUnitStep1 from '../SetupCard/resources/AdUnitStep1.svg';
import AdUnitStep2 from '../SetupCard/resources/AdUnitStep2.svg';
import ProfileStep1 from '../SetupCard/resources/ProfileStep1.svg';
import ProfileStep2 from '../SetupCard/resources/ProfileStep2.svg';
import ProfileStep3 from '../SetupCard/resources/ProfileStep3.svg';
import SiteStep1 from '../SetupCard/resources/SiteStep1.svg';
import SiteStep2 from '../SetupCard/resources/SiteStep2.svg';
import SiteStep3 from '../SetupCard/resources/SiteStep3.svg';
import messages from './GettingStarted.messages';
import { getClassNames } from './GettingStarted.styles';
import { getSetupCards, getSetupCardsPlaceholder } from './utils/getCards';
import { getStatusCardsInfo } from './utils/getStatusMessages';

export const GettingStarted = injectRouterAndIntlWithObserver(function GettingStartedComponent({ intl: { formatMessage }, history }) {
  const { cardsContainer, headerSection } = getClassNames();
  const { profileCardInfo, siteCardInfo, adunitCardInfo } = getStatusCardsInfo(formatMessage);
  const { isFeatureEnabled } = getWindowsAppFeatureFlagStore();
  // TODO - get isSetupDone from the store to control display of section
  const { adUnitStatus, siteStatus, profileStatus, isSetupDone } = getStore();
  let gettingStartedCards: ISetupCard[] = getSetupCardsPlaceholder();

  usePreloadSVGImages([
    ConnectAppImg,
    ConnectSiteImg,
    AdUnitStep1,
    AdUnitStep2,
    ProfileStep1,
    ProfileStep2,
    ProfileStep3,
    SiteStep1,
    SiteStep2,
    SiteStep3,
  ]);

  if (adUnitStatus && siteStatus && profileStatus) {
    gettingStartedCards = getSetupCards(
      handleProfileCardButtonClick,
      handleCompleteProfileCardButtonClick,
      handleSiteCardButtonClick,
      handleAdUnitCardButtonClick,
      profileCardInfo,
      siteCardInfo,
      adunitCardInfo,
      adUnitStatus,
      siteStatus,
      profileStatus,
      formatMessage
    );
  }

  function handleCompleteProfileCardButtonClick() {
    editProfileAction();
    history.push(getTythonProfilePageRoute());
  }

  function handleProfileCardButtonClick() {
    history.push(getTythonProfilePageRoute());
  }

  function handleSiteCardButtonClick() {
    history.push(isFeatureEnabled ? getTythonInventoryCreationPageRoute() : getTythonSiteCreatePageRoute());
  }

  function handleAdUnitCardButtonClick() {
    history.push(isFeatureEnabled ? getTythonAdsCreationPageRoute() : getTythonAdUnitCreatePageRoute());
  }

  useEffect(() => {
    // Make a call to fetch status for Profile, sites and adunit
    fetchSetupCardsStatus();
  }, []);

  const displaySetupCards = (cards: ISetupCard[]) => {
    return cards.map((card, index) => <SetupCard card={card} key={index} />);
  };

  return (
    <>
      {!isSetupDone ? (
        <div>
          <h2 className={headerSection}>{formatMessage(messages.gettingStartedTitle)}</h2>
          <div className={cardsContainer}>{displaySetupCards(gettingStartedCards)}</div>
        </div>
      ) : null}
    </>
  );
});
