export enum FinancialStatus {
  // Proposed = 'Proposed',
  // PendingCreditCheck = 'PendingCreditCheck',
  NoHold = 'NoHold',
  Hold = 'Hold',
  // SoldToOnly = 'SoldToOnly',
  // CreditHold = 'CreditHold',
  // CreditWarning = 'CreditWarning',
  TaxOnHold = 'TaxOnHold',
  UserHold = 'UserHold'
  // WriteOff = 'WriteOff'
}
