import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, onLoadingChanged, onNotificationChanged } from '../../../../@data';
import { uiConfig } from '../../../../config/ui.config';
import messages from '../../Report.messages';
import { onReportLinkClicked } from '../actions';
import { setUserPublisherContext } from '../services/ReportService';

orchestrator(onReportLinkClicked, async () => {
  const { publisher, userContext } = getAppStore();

  if (publisher && publisher.id && userContext) {
    onLoadingChanged(true);
    try {
      const commonsession = await setUserPublisherContext(userContext, publisher.id);
      // open a new window
      window.open(uiConfig.getReportingDashboardAddress(commonsession));
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.reportsNotAvailableAtThisTime,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.reportInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
