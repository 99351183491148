import { ActionButton, Stack } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { getAppStore } from '../../../../@data';
import { onSwitchAccount } from '../../../login/@data';
import messages from './AccountType.messages';
import { getStyles } from './AccountType.styles';
import { IAccountTypeProps } from './AccountType.types';
import { ChooseAccount } from './ChooseAccount.tsx/ChooseAccount';

export const AccountType = injectIntl<IAccountTypeProps>(function AccountTypeComponent({
  titleText,
  descriptionText,
  history,
  styleProps,
  intl,
}) {
  const { rootStyle, headerStyle, subheaderStyle, choiceContentStyle, accountSwitchStyle, accountButtonStyle } = getStyles();
  const { formatMessage } = intl;
  const userContext = getAppStore().userContext;

  return (
    <Stack verticalAlign="space-between" className={rootStyle}>
      <div className={headerStyle}>{titleText}</div>
      <div className={subheaderStyle}>{descriptionText}</div>
      <div className={choiceContentStyle}>
        <ChooseAccount />
      </div>
      <div className={accountSwitchStyle}>
        <span>{formatMessage(messages.accountPrefix)}</span>
        <b>{userContext?.email}</b>
        <span>{formatMessage(messages.accountPost)}</span>
        <ActionButton className={accountButtonStyle} onClick={() => onSwitchAccount()}>
          {formatMessage(messages.switchAccount)}
        </ActionButton>
      </div>
    </Stack>
  );
});
