import { IDictionary } from '../types';

export const PUBCENTER_TYTHON_DOMAIN_MAPPING: IDictionary<string> = {
  'localhost:3000': 'http://localhost:3001',
  'pubcenter.azurewebsites.net': 'https://si.monetizenow.microsoft.com',
  'pubcenter2.azurewebsites.net': 'https://offline.si.monetizenow.microsoft.com',
  'next.pubcenter.microsoft.com': 'https://monetizenow.microsoft.com',
  'pubcenternext-staging.azurewebsites.net': 'https://staging.monetizenow.microsoft.com',
};

export const PUBCENTER_NEXT_PROD = 'https://next.pubcenter.microsoft.com';
export const PUBCENTER_NEXT_PROD_STAGING = 'https://pubcenternext-staging.azurewebsites.net';
export const TYTHON_NEW_PROD = 'https://monetizenow.microsoft.com';
export const TYTHON_NEW_PROD_STAGING = 'https://staging.monetizenow.microsoft.com';

export const PUBCENTER_NEXT_SI = 'https://pubcenter.azurewebsites.net';
export const PUBCENTER_NEXT_SI_STAGING = 'https://pubcenter2.azurewebsites.net';
export const TYTHON_NEW_SI = 'https://si.monetizenow.microsoft.com';
export const TYTHON_NEW_SI_OFFLINE = 'https://offline.si.monetizenow.microsoft.com';

export const LOCALHOST = 'http://localhost:3000';
export const LOCALHOST2 = 'http://localhost:3001';

export const NEW_DOMAINS = [TYTHON_NEW_SI, TYTHON_NEW_SI_OFFLINE, TYTHON_NEW_PROD, TYTHON_NEW_PROD_STAGING, LOCALHOST2];

export const PUBCENTER_DOMAINS = [
  PUBCENTER_NEXT_SI,
  PUBCENTER_NEXT_SI_STAGING,
  PUBCENTER_NEXT_PROD,
  PUBCENTER_NEXT_PROD_STAGING,
  LOCALHOST,
];
