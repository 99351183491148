import { FontWeights, IStyle, IStyleFunctionOrObject, ITheme, classNamesFunction, getTheme } from '@fluentui/react';
import { BreakPoints, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../../../theme';
import { IFormSectionStyleProps, IFormSectionStyles } from '../../../../../../../../components/Form';
import { flexWithAlignCenter, flexWithJustifyBetween } from '../../../../../../../../utils/GlobalStyleUtils';

export interface IAdsTextStyleProps {
  theme: ITheme;
}
export interface IAdsTextStyles {
  container: IStyle;
  scriptBox: IStyle;
  scriptHolder: IStyle;
  stepHeader: IStyle;
  subComponentStyles: {
    formSection?: IStyleFunctionOrObject<IFormSectionStyleProps, IFormSectionStyles>;
  };
  fileUploadContainerStyle: IStyle;
  fileUploadedStyle: IStyle;
  actionContainerStyle: IStyle;
  fileUploadLabelStyle: IStyle;
  poolImage: IStyle;
}

export const getStyles = (props: IAdsTextStyleProps): IAdsTextStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { ResponsiveMedium } = BreakPoints;

  return {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      '& > :first-child': {
        flex: '3 1 75%',
      },
    },
    poolImage: {
      [ResponsiveMedium]: {
        display: 'none',
      },
    },
    subComponentStyles: {
      formSection: {
        root: {
          width: 'inherit',
          borderTop: 'none',
          borderBottom: 'none',
        },
      },
    },
    scriptBox: {
      borderColor: palette.neutralLight,
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: spacing.size12,
      marginBottom: spacing.size16,
    },
    scriptHolder: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      backgroundColor: palette.neutralLighter,
      minHeight: spacing.size16,
      maxHeight: spacing.size128,
      padding: spacing.size12,
      marginBottom: spacing.size12,
      overflow: 'auto',
      color: palette.black,
      lineHeight: spacing.size16,
      fontSize: theme.fonts.small.fontSize,
    },
    stepHeader: {
      fontWeight: FontWeights.semibold,
      display: 'inline-block',
      margin: `${spacing.size10} 0px`,
    },
    fileUploadContainerStyle: {
      ...flexWithAlignCenter,
      justifyContent: 'center',
      flexDirection: 'column',
      background: palette.neutralLighterAlt,
      border: `1px dashed ${palette.neutralQuaternaryAlt}`,
      padding: spacing.size24,
      marginBottom: spacing.size2,
      borderRadius: spacing.size4,
    },
    fileUploadedStyle: {
      ...flexWithJustifyBetween,
      width: '100%',
      '& > :first-child': {
        ...flexWithAlignCenter,
        gap: spacing.size8,
      },
    },
    actionContainerStyle: {
      display: 'flex',
      gap: spacing.size12,
    },
    fileUploadLabelStyle: {
      color: palette.highContrastPrimary,
      cursor: 'pointer',
    },
  };
};

export const getClassNames = classNamesFunction<IAdsTextStyleProps, IAdsTextStyles>();
