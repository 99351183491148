import { mutator } from 'satcheljs';
import { onFormAdunitsChanged, onFormChannelNameChanged, onFormCleared, onFormFiltersChanged, onFormIdChanged } from '../actions';
import { getStore } from '../store/store';

mutator(onFormIdChanged, msg => {
  getStore().active.id = msg.value;
});

mutator(onFormChannelNameChanged, msg => {
  getStore().active.name = msg.value;
});

mutator(onFormAdunitsChanged, msg => {
  getStore().active.adunitsSelected = msg.values;
});

mutator(onFormFiltersChanged, msg => {
  getStore().active.filtersSelected = msg.values;
});

mutator(onFormCleared, () => {
  getStore().active = {};
});
