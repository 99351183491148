import { defineMessages } from 'react-intl';

export default defineMessages({
  attachedAdUnits: {
    id: 'admanagement.property.attachedAdUnits',
    defaultMessage: 'Attached Ad units',
  },
  searchBoxPlaceHolder: {
    id: 'admanagement.property.searchBoxPlaceHolder',
    defaultMessage: 'Search ad unit or account by name',
  },
  initialSearchBoxPlaceHolder: {
    id: 'admanagement.property.initialSearchBoxPlaceHolder',
    defaultMessage: 'Search account by name',
  },
  adUnitPickerAccountsCountLabel: {
    id: 'admanagement.property.adUnitPicker.AccountsCountLabel',
    defaultMessage: 'Accounts: {count}',
  },
  adUnitPickerAdUnitsCountLabel: {
    id: 'admanagement.property.adUnitPicker.AdUnitsCountLabel',
    defaultMessage: 'Ad units: {count}',
  },
});
