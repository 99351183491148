import { getTheme, IStackTokens, mergeStyleSets } from '@fluentui/react';
import { ICommonStyleProps } from '../../../../types';
import { noPointerEvents } from '../../../../utils/GlobalStyleUtils';
import { BreakPoints, IPubcenterTheme } from './../../../../../theme';

type StyleProperties = 'buttonStyle' | 'disabledLink' | 'homepageCards' | 'placeholder';
type ManagePageStyles = Record<StyleProperties, string>;
const { ResponsiveMedium, ResponsiveSmall } = BreakPoints;

export const getStyles = (props: ICommonStyleProps): ManagePageStyles => {
  return mergeStyleSets({
    buttonStyle: {
      background: 'linear-gradient(88.27deg, #077BE6 -0.89%, #0A85E7 48.14%, #20AAE7 99.27%)',
    },
    disabledLink: {
      ...noPointerEvents,
    },
    homepageCards: {
      display: 'grid',
      gridTemplateColumns: '49.5% 49.5%',
      columnGap: '1%',
      rowGap: '2%',
      [ResponsiveMedium]: {
        gridTemplateColumns: '100%',
        columnGap: '0',
      },
      [ResponsiveSmall]: {
        gridTemplateColumns: '100%',
        columnGap: '0',
      },
    },
    placeholder: {
      height: '100%',
    },
  });
};

export function getClassNames<PropType>(props?: PropType): ManagePageStyles {
  return getStyles({ theme: getTheme(), ...props });
}

export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
