import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../theme';
import { ICommonStyleProps } from '../../../../../../types';

type StyleProperties = 'details' | 'titleStyle' | 'content' | 'arrow';

type SetupCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SetupCardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return mergeStyleSets({
    details: {
      borderRadius: `0px 0px ${spacing.size1} 0px`,
      borderBottom: `${spacing.size1} solid ${palette.neutralLight}`,
      cursor: 'pointer',
      summary: {
        listStyle: 'none',
        display: 'flex',
      },
    },
    titleStyle: {
      userSelect: 'none',
      padding: `${spacing.size16} ${spacing.size12}`,
      fontWeight: 600,
      fontSize: spacing.size16,
    },
    content: {
      borderTop: `${spacing.size1} solid ${palette.azureishWhite}`,
      padding: `${spacing.size16} ${spacing.size12} ${spacing.size16} ${spacing.size48}`,
      cursor: 'default',
      color: palette['NeutralForeground3.Rest'],
      '> a': {
        color: palette['CompoundBrandForeground1.Rest'],
        textDecoration: 'none',
      },
    },
    arrow: {
      pointerEvents: 'none',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: spacing.size36,
      paddingLeft: spacing.size6,
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
