import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import { History } from 'history';
import { action } from 'satcheljs';
import { AdUnitType, NativeAdTemplate, Property } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { StepState } from '../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';

export const onFormInitialized = action(
  'onFormInitialized',
  (mode: FormState, publisherId: string, accountId: string, entityId?: string, showLoadingSpinner?: boolean) => ({
    mode,
    publisherId,
    accountId,
    entityId,
    showLoadingSpinner,
  })
);

export const onFetchAdUnitName = action(
  'onFetchAdUnitName',
  (publisherId: string, accountId: string, entityId: string, setIsLoading: (isLoading: boolean) => void) => ({
    publisherId,
    accountId,
    entityId,
    setIsLoading,
  })
);

export const onFetchAdUnitTemplate = action('onFetchAdUnitTemplate', (entityId: string) => ({
  entityId,
}));

export const onAdunitIdChanged = action('onAdunitIdChanged', (value: number) => ({ value }));

export const onAdunitNameChanged = action('onAdunitNameChanged', (value?: string) => ({ value }));

export const onAdunitTypeChanged = action('onAdunitTypeChanged', (value?: AdUnitType) => ({ value }));

export const onMarketplaceChanged = action('onMarketplaceChanged', (value: IDropdownOption) => ({ value }));

export const onBusinessModelChanged = action('onBusinessModelChanged', (value?: IDropdownOption) => ({ value }));

export const onChannelsChanged = action('onChannelsChanged', (values?: IIdNamePair[]) => ({ values }));

export const onAdunitChannelListChanged = action('onAdunitChannelListChanged', (values?: IIdNamePair[]) => ({ values }));

export const onAssociatedPropertyChanged = action('onChannelsChanged', (value?: Property) => ({ value }));

export const onAdunitPropertyListChanged = action('onAdunitPropertyListChanged', (values?: Property[]) => ({ values }));

export const onCreatePropertyPanelOpenChanged = action('onCreatePropertyPanelOpenChanged', (isOpen: boolean) => ({ isOpen }));

export const onServingTierChanged = action('onServingTierChanged', (value?: IChoiceGroupOption) => ({ value }));

export const onPubGroupConfigChanged = action('onPubGroupConfigChanged', (value?: IDropdownOption) => ({ value }));

export const onStoreIdsChanged = action('onStoreIdsChanged', (values?: string) => ({ values }));

export const onAdUnitFormSubmittable = action('onAdUnitFormSubmittable', (isSubmittable: boolean) => ({ isSubmittable }));

export const onFormSubmitted = action(
  'onFormSubmitted',
  (mode: FormState, routeHistory?: History, onSubmit?: () => void, stepState?: StepState, shouldHandleMediation?: boolean) => ({
    mode,
    routeHistory,
    onSubmit,
    stepState,
    shouldHandleMediation,
  })
);

export const onAdUnitNameUpdate = action(
  'onAdUnitNameUpdate',
  (
    formatMessage: (
      messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
      values?:
        | {
            [key: string]: ReactIntl.MessageValue;
          }
        | undefined
    ) => string,
    setIsLoading: (isLoading: boolean) => void,
    setErrorMessage: (error: string) => void,
    setVisibleCallOut: (id: number) => void
  ) => ({
    formatMessage,
    setIsLoading,
    setErrorMessage,
    setVisibleCallOut,
  })
);

export const onFormCancelled = action('onFormCancelled');

export const onFormCleared = action('onFormCleared');

export const onTemplateUploaded = action('onTemplateUploaded', (content: NativeAdTemplate) => ({ content }));

export const onTemplateDownloaded = action('onTemplateDownloaded', (content: NativeAdTemplate) => ({ content }));

export const onDistributionPartnerCodesChanged = action('onDistributionPartnerCodesChanged', (value?: string) => ({ value }));

export const onImpressionFeedbackSignalChanged = action('onImpressionFeedbackSignalChanged', (value?: boolean) => ({ value }));
