import * as React from 'react';
import { useEffect, useState } from 'react';
import { ClarityType, getAppStore, onNavigateBack } from '../../../../../../@data';
import { FormState, IFormEditParams } from '../../../../../../components/Form';
import { DetailPage } from '../../../../../../layout/DetailPage/DetailPage';
import { DetailPageWithStepper } from '../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper';
import { IStepperStep } from '../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../../../utils';
import { getTythonInventoryPage } from '../../../../../../utils/routeUtils';
import { onFormCleared } from '../../__data__/mutatorActions';
import { IClarityParams } from '../../__data__/store/schema';
import { getCreatePropertyStore } from '../../__data__/store/store';
import useDetailsPageButtonsStatus from '../../utils/useDetailsPageButtonsStatus';
import { onFormSubmitted } from './__data__/orchestrators/formSubmitted';
import { AdsTextPage } from './components/AdsTextPage';
import { CreateProperty } from './components/CreateProperty';
import { PropertyScriptPage } from './components/ScriptPage';
import { onScriptPageSubmitted } from './components/ScriptPage/@data/actions/onScriptPageActions';
import messages from './messages';

interface ICreateTythonPropertyProps extends IRouterWithIntl<IFormEditParams> {
  mode: FormState;
  startStep?: number;
  onSubmit?: ({ name, url, clarity }: { name: string; url: string; clarity: IClarityParams }) => void;
  saving?: boolean;
  onCancel?: () => void;
}

export const CreateTythonProperty = injectIntlWithObserver((props: ICreateTythonPropertyProps) => {
  const { id: propertyId, publisherId, accountId } = props.match.params;
  const searchParams = new URLSearchParams(props.location.search);
  const step = searchParams.get('step');
  const { formatMessage } = props.intl;
  const { active: activeProperty } = getCreatePropertyStore();
  const { name, url, clarity } = activeProperty;
  const { clarityType, clarityId, approvalBehavioralOption } = clarity || { clarityType: undefined };
  const { titleLabel, submitLabel, onHandleSubmit, onHandleCancel } = useDetailsPageButtonsStatus(
    props.history,
    props.mode,
    props.onSubmit,
    props.onCancel,
    formatMessage
  );
  const { saving, loading } = getAppStore();
  const [isPropertyFormSubmittable, onPropertyFormSubmittable] = useState<boolean>(false);

  // Evaluates whether the submit button for the CreateProperty (First step) is enabled or disabled by checking
  // 1 - Property name
  // 2 - Property URL
  // 3 - Clarity option is advanced and user approved T&C of linking clarity OR Clarity option is basic
  useEffect(() => {
    if (
      name &&
      url &&
      name?.length !== 0 &&
      url?.length !== 0 &&
      ((clarityType === ClarityType.Behavioral && approvalBehavioralOption === true) ||
        clarityType === ClarityType.Fraud ||
        (clarityType === ClarityType.None && !!clarityId && clarityId?.length !== 0))
    ) {
      onPropertyFormSubmittable(true);
    } else {
      onPropertyFormSubmittable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, url, approvalBehavioralOption, clarityType, clarityId]);

  const clearStores = () => {
    onFormCleared();
  };

  const steps: IStepperStep[] = [
    {
      name: formatMessage(messages.CreateSiteStep),
      component: <CreateProperty {...props} />,
      helpId: 'pop_PCv4_PropertiesCreate',
      isNextDisabled: !isPropertyFormSubmittable,
      isStepDirty: propertyId !== undefined,
      onNext: (action, stepState) => onFormSubmitted(props.mode, props.history, action, stepState),
    },
    {
      name: formatMessage(messages.AdsTxtCreationStep),
      isStepDirty: propertyId !== undefined,
      component: <AdsTextPage {...props} />,
    },
    {
      name: formatMessage(messages.GetCodeAndFinishStep),
      component: <PropertyScriptPage {...props} />,
      isStepDirty: propertyId !== undefined,
      onNext: (action, _) => {
        clearStores();
        onScriptPageSubmitted(props.mode, props.history, action);
      },
    },
  ];

  return props.mode === FormState.InPageCreate ? (
    <DetailPage
      titleLabel={titleLabel}
      helpId={'pop_PCv4_PropertiesCreate'}
      submitLabel={submitLabel}
      onCancel={() => onHandleCancel(publisherId, accountId)}
      onSubmit={() => onHandleSubmit(activeProperty)}
      onNavClick={() => onHandleCancel(publisherId, accountId)}
      saving={saving || props.saving}
      loading={loading}
      pageMode={props.mode}
      hasFormTitle
      submitButtonDisabled={!isPropertyFormSubmittable}
      additionalHeightBoundary={true}
    >
      <CreateProperty {...props} />
    </DetailPage>
  ) : (
    <DetailPageWithStepper
      process={
        props.mode === FormState.MultiStepCreation ? formatMessage(messages.processNameCreate) : formatMessage(messages.processNameEdit)
      }
      openStep={(step && Number(step)) || 0}
      steps={steps.filter((stepToBeFiltered) => !stepToBeFiltered.hideStep)}
      pageMode={props.mode}
      backToBase={() => {
        clearStores();
        onNavigateBack(props.history, getTythonInventoryPage());
      }}
      {...props}
    />
  );
});
