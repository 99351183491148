import { ResourceType } from '../../../../../../@data/store/schema/enums/ResourceType';
import { IGlobalSearchResult } from '../../../../../../@data/store/schema/interfaces/IGlobalSearchResult';
import { SearchResult } from './SearchResult';

export class Account extends SearchResult {
  public createdDate?: string;
  public status?: string;
  private _item: IGlobalSearchResult;

  constructor(item?: IGlobalSearchResult) {
    super(item && item.resourceName, item && item.resourceId, 'Work');
    if (item) {
      this.createdDate = item.propertyBag && item.propertyBag.CreationDateTime;
      this.status = item.propertyBag && item.propertyBag.Status;
      this._item = item;
    }
  }

  public getPublisherId(): string {
    if (this._item && this._item.propertyBag && this._item.propertyBag.PublisherId) {
      return this._item.propertyBag.PublisherId;
    }

    return '';
  }

  public getAccountId(): string {
    if (this._item && this._item.propertyBag && this._item.propertyBag.AccountId) {
      return this._item.propertyBag.AccountId;
    }

    return '';
  }

  public getResourceType(): string {
    return ResourceType[ResourceType.Account];
  }
}
