import { InjectedIntl } from 'react-intl';
import { action, mutatorAction } from 'satcheljs';
import { RouteName, getPageStore } from '../store/store';

export const onCurrentRouteChanged = action(
  'onCurrentRouteChanged',
  (currentRoute: RouteName, formatMessage: InjectedIntl['formatMessage']) => ({ currentRoute, formatMessage })
);

export const onPanelOpenChanged = mutatorAction('onPanelOpenChanged', (panelIsOpen: boolean) => {
  getPageStore().panelIsOpen = panelIsOpen;
});
