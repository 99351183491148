import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../../theme';

type StyleProperties = 'navBackButton';
type SubPageNavbarStyles = Record<StyleProperties, string>;
interface ISubPageNavbarStyleProps {}
const theme = getTheme();
export const getStyles = (props: ISubPageNavbarStyleProps): SubPageNavbarStyles => {
  const spacing = theme.spacing as IPubcenterSpacing;
  return mergeStyleSets({
    navBackButton: [
      'ms-navBackButton',
      {
        display: 'flex',
        backgroundColor: theme.palette.neutralLighter,
        height: spacing.size44,
        alignItems: 'center',
      },
    ],
  });
};

export const breadcrumbStyles = {
  root: {
    margin: 0,
    alignItems: 'center',
  },
  item: {
    lineHeight: '22px',
    fontSize: theme.fonts.mediumPlus.fontSize,
    color: theme.palette.neutralPrimary,
  },
  chevron: {
    lineHeight: '22px',
    color: theme.palette.neutralPrimary,
  },
};

export function getClassNames<PropType>(props?: PropType): SubPageNavbarStyles {
  return getStyles({ theme: getTheme(), ...props });
}
