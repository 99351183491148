import { Divider, FluentProvider, Tab, TabList, makeStyles, webLightTheme } from '@fluentui/react-components';
import React from 'react';
import { injectIntl } from 'react-intl';
import { InventoryPageTabs } from '../../hooks/useEntityTypeManage';
import messages from './messages';

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    rowGap: '20px',
    marginLeft: '-0.8rem',
  },
  divider: {
    width: 'calc(100% + 4rem)',
    marginLeft: '-2rem',
    marginBottom: '1.25rem',
  },
  spacer: {
    marginTop: '32px',
  },
});

const EntityTypeSwitch = ({
  intl: { formatMessage },
  onTabSelect,
  selectedValue,
  siteTabLabel = '',
  appTabLabel = '',
  isFeatureEnabled = false,
}) => {
  const styles = useStyles();
  const onTabSelected = (e, data) => onTabSelect(data.value as InventoryPageTabs);

  if (!isFeatureEnabled) {
    return <div className={styles.spacer} />;
  }

  return (
    <FluentProvider theme={webLightTheme}>
      <div className={styles.root}>
        <TabList selectedValue={selectedValue} onTabSelect={onTabSelected}>
          <Tab value={InventoryPageTabs.Sites}>{siteTabLabel || formatMessage(messages.sites)}</Tab>
          <Tab value={InventoryPageTabs.Apps}>{appTabLabel || formatMessage(messages.apps)}</Tab>
        </TabList>
      </div>
      <Divider className={styles.divider} />
    </FluentProvider>
  );
};

export default injectIntl(EntityTypeSwitch);
