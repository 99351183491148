import { array, mixed, object } from 'yup';
import { IdNamePairErrorModel, UserRole } from '../../../../../../@data';
import { ArrayObservableSchemaYupType } from '../../../../../../utils';
import { IUserViewModel } from './IUserViewModel';

export function userRoleErrorModel() {
  return object<IUserViewModel>().shape({
    AccountManagerAccountsSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel).when('accountRole', {
      is: UserRole.AccountManager,
      then: array()
        .min(1)
        .required(),

      otherwise: array()
    }),
    ReportUserAccounstSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel).when('accountRole', {
      is: UserRole.ReportUser,
      then: array()
        .required()
        .min(1),
      otherwise: array()
    }),
    ListManagerAccountsSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel).when('accountRole', {
      is: UserRole.ListManager,
      then: array()
        .required()
        .min(1),
      otherwise: array()
    }),
    AdViewAccountsSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel).when('accountRole', {
      is: UserRole.AdView,
      then: array()
        .required()
        .min(1),
      otherwise: array()
    }),
    accountRole: mixed()
      .oneOf([
        UserRole.AccountManager,
        UserRole.AdView,
        UserRole.ListManager,
        UserRole.PublisherAdmin,
        UserRole.ReportUser,
        UserRole.SuperAdmin
      ])
      .required()
  });
}
