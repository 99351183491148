export function startOfDay(date: Date) {
  const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  return newDate;
}
export function endOfDay(date: Date) {
  const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0));
  return newDate;
}
export const daysAheadOfToday = (dateString: string): number => {
  const today = new Date();
  const date = new Date(dateString);
  const diffTime = date.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
export const breakdownDate = (date: Date, locale): { year: string; month: string; day: string } => {
  const year = date.toLocaleString(locale, { year: 'numeric' });
  const month = date.toLocaleString(locale, { month: 'short' });
  const day = date.toLocaleString(locale, { day: '2-digit' });
  return { year, month, day };
};
