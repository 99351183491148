import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../theme';
import { ICommonStyleProps } from '../../../../types';

export interface IManagePageStyles {
  layoutRootStyle?: string;
}

export const getStyles = (props: ICommonStyleProps): IManagePageStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing } = theme;

  return mergeStyleSets({
    layoutRootStyle: {
      paddingTop: 0,
      paddingLeft: spacing.size24,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): IManagePageStyles {
  return getStyles({ theme: getTheme(), ...props });
}
