import { IDictionary } from './countries';

// new Intl.DisplayNames(['fr-FR'], { type: 'currency' });
// currencyNames.of(key) // key is currency code

export const CURRENCIES_EN: IDictionary<string> = {
  AUD: 'Australian Dollar (AUD)',
  ARS: 'Argentinean Peso (ARS)',
  AZN: 'Azerbaijani Manat (AZN)',
  BDT: 'Bangladeshi Taka (BDT)',
  BGN: 'Bulgarian Lev (BGN)',
  BHD: 'Bahraini Dinar (BHD)',
  BRL: 'Brazilian Real (BRL)',
  BWP: 'Botswanan Pula (BWP)',
  CAD: 'Canadian Dollar (CAD) ',
  CHF: 'Switzerland Franc (CHF)',
  CLP: 'Chilean Peso (CLP)',
  COP: 'Colombian Peso (COP)',
  CRC: 'Costa Rican Colón (CRC)',
  CZK: 'Czech koruna (CZK)',
  DKK: 'Denmark Krone (DKK)',
  DOP: 'Dominican Peso (DOP)',
  DZD: 'Algerian Dinar (DZD)',
  EGP: 'Egyptian Pound (EGP)',
  ETB: 'Ethiopian Birr (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Fijian Dollar (FJD)',
  GBP: 'UK Pound (GBP)',
  GHS: 'Ghana Cedi (GHS)',
  GTQ: 'Guatemalan Quetzal (GTQ)',
  HKD: 'Hong Kong Dollar (HKD)',
  HRK: 'Croatian Kuna (HRK)',
  HUF: 'Hungarian forint (HUF)',
  IDR: 'Indonesian Rupiah',
  ILS: 'Shekel (ILS)',
  INR: 'Indian Rupee (INR)',
  IQD: 'Iraqi Dinar (IQD)',
  JMD: 'Jamaican Dollar (JMD)',
  JOD: 'Jordanian Dinar (JOD)',
  JPY: 'Japan Yen (JPY)',
  KES: 'Kenyan Shilling (KES)',
  KRW: 'Won (KRW)',
  KWD: 'Kuwaiti Dinar (KWD)',
  KZT: 'Kazakhstani Tenge (KZT)',
  LBP: 'Lebanese Pound (LBP)',
  LKR: 'Sri Lankan Rupee (LKR)',
  LTL: 'Lithuanian Litas (LTL)',
  LVL: 'Latvian Lats (LVL)',
  MAD: 'Moroccan Dirham (MAD)',
  MUR: 'Mauritian Rupee (MUR)',
  MWK: 'Malawian Kwacha (MWK)',
  MXN: 'Mexico Peso (MXN)',
  MYR: 'Malaysian Ringgit',
  NGN: 'Nigerian Naira (NGN)',
  NOK: 'Norwegian Krone (NOK)',
  NZD: 'New Zealand Dollar (NZD)',
  OMR: 'Rial Omani (OMR)',
  PEN: 'Peruvian Nuevo Sol (PEN)',
  PHP: 'Philippine Peso (PHP)',
  PKR: 'Pakistan Rupee (PKR)',
  PLN: 'Polish zloty (PLN)',
  QAR: 'Qatari Rial (QAR)',
  RUB: 'Russian Ruble (RUB)',
  RWF: 'Rwandan Franc (RWF)',
  SAR: 'Saudi Riyal (SAR)',
  SEK: 'Sweden Krona (SEK)',
  SGD: 'Singapore Dollar (SGD)',
  THB: 'Thai Baht (THB)',
  TND: 'Tunisian Dinar (TND)',
  TOP: 'Tongan Pa?anga (TOP)',
  TRY: 'Turkish Lira (TRY)',
  TTD: 'Trinidad and Tobago Dollar (TTD)',
  TWD: 'Taiwan Dollar (TWD)',
  TZS: 'Tanzanian Shilling (TZS)',
  UGX: 'Ugandan Shilling (UGX)',
  USD: 'US Dollar (USD)',
  UYU: 'Uruguayan Peso (UYU)',
  VEF: 'Venezuelan Bolivar Fuertes (VEF)',
  VND: 'Vietnam Dong',
  XAF: 'Central African CFA Franc (XAF)',
  XCD: 'East Caribbean Dollar (XCD)',
  XOF: 'West African CFA Franc (XOF)',
  ZAR: 'South African rand (ZAR)',
  ZMW: 'Zambian Kwacha (ZMW)',
};

export const CURRENCIES_EN_GB: IDictionary<string> = {
  AUD: 'Australian Dollar (AUD)',
  ARS: 'Argentinean Peso (ARS)',
  AZN: 'Azerbaijani Manat (AZN)',
  BDT: 'Bangladeshi Taka (BDT)',
  BGN: 'Bulgarian Lev (BGN)',
  BHD: 'Bahraini Dinar (BHD)',
  BRL: 'Brazilian Real (BRL)',
  BWP: 'Botswanan Pula (BWP)',
  CAD: 'Canadian Dollar (CAD)',
  CHF: 'Switzerland Franc (CHF)',
  CLP: 'Chilean Peso (CLP)',
  COP: 'Colombian Peso (COP)',
  CRC: 'Costa Rican Colón (CRC)',
  CZK: 'Czech koruna (CZK)',
  DKK: 'Denmark Krone (DKK)',
  DOP: 'Dominican Peso (DOP)',
  DZD: 'Algerian Dinar (DZD)',
  EGP: 'Egyptian Pound (EGP)',
  ETB: 'Ethiopian Birr (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Fijian Dollar (FJD)',
  GBP: 'UK Pound (GBP)',
  GHS: 'Ghana Cedi (GHS)',
  GTQ: 'Guatemalan Quetzal (GTQ)',
  HKD: 'Hong Kong Dollar (HKD)',
  HRK: 'Croatian Kuna (HRK)',
  HUF: 'Hungarian forint (HUF)',
  IDR: 'Indonesian Rupiah',
  ILS: 'Shekel (ILS)',
  INR: 'Indian Rupee (INR)',
  IQD: 'Iraqi Dinar (IQD)',
  JMD: 'Jamaican Dollar (JMD)',
  JOD: 'Jordanian Dinar (JOD)',
  JPY: 'Japan Yen (JPY)',
  KES: 'Kenyan Shilling (KES)',
  KRW: 'Won (KRW)',
  KWD: 'Kuwaiti Dinar (KWD)',
  KZT: 'Kazakhstani Tenge (KZT)',
  LBP: 'Lebanese Pound (LBP)',
  LKR: 'Sri Lankan Rupee (LKR)',
  LTL: 'Lithuanian Litas (LTL)',
  LVL: 'Latvian Lats (LVL)',
  MAD: 'Moroccan Dirham (MAD)',
  MUR: 'Mauritian Rupee (MUR)',
  MWK: 'Malawian Kwacha (MWK)',
  MXN: 'Mexico Peso (MXN)',
  MYR: 'Malaysian Ringgit',
  NGN: 'Nigerian Naira (NGN)',
  NOK: 'Norwegian Krone (NOK)',
  NZD: 'New Zealand Dollar (NZD)',
  OMR: 'Rial Omani (OMR)',
  PEN: 'Peruvian Nuevo Sol (PEN)',
  PHP: 'Philippine Peso (PHP)',
  PKR: 'Pakistan Rupee (PKR)',
  PLN: 'Polish zloty (PLN)',
  QAR: 'Qatari Rial (QAR)',
  RUB: 'Russian Ruble (RUB)',
  RWF: 'Rwandan Franc (RWF)',
  SAR: 'Saudi Riyal (SAR)',
  SEK: 'Sweden Krona (SEK)',
  SGD: 'Singapore Dollar (SGD)',
  THB: 'Thai Baht (THB)',
  TND: 'Tunisian Dinar (TND)',
  TOP: 'Tongan Pa?anga (TOP)',
  TRY: 'Turkish Lira (TRY)',
  TTD: 'Trinidad and Tobago Dollar (TTD)',
  TWD: 'Taiwan Dollar (TWD)',
  TZS: 'Tanzanian Shilling (TZS)',
  UGX: 'Ugandan Shilling (UGX)',
  USD: 'US Dollar (USD)',
  UYU: 'Uruguayan Peso (UYU)',
  VEF: 'Venezuelan Bolivar Fuertes (VEF)',
  VND: 'Vietnam Dong',
  XAF: 'Central African CFA Franc (XAF)',
  XCD: 'East Caribbean Dollar (XCD)',
  XOF: 'West African CFA Franc (XOF)',
  ZAR: 'South African rand (ZAR)',
  ZMW: 'Zambian Kwacha (ZMW)',
};

export const CURRENCIES_FR: IDictionary<string> = {
  AUD: 'Dollar australien (AUD)',
  ARS: 'Peso argentin (ARS)',
  AZN: 'Manat azéri (AZN)',
  BDT: 'Taka bangladeshi (BDT)',
  BGN: 'Lev bulgare (BGN)',
  BHD: 'Dinar bahreïni (BHD)',
  BRL: 'Réal brésilien (BRL)',
  BWP: 'Pula botswanais (BWP)',
  CAD: 'Dollar canadien (CAD)',
  CHF: 'Franc suisse (CHF)',
  CLP: 'Peso chilien (CLP)',
  COP: 'Peso colombien (COP)',
  CRC: 'Colón costaricain (CRC)',
  CZK: 'Couronne tchèque (CZK)',
  DKK: 'Couronne danoise (DKK)',
  DOP: 'Peso dominicain (DOP)',
  DZD: 'Dinar algérien (DZD)',
  EGP: 'Livre égyptienne (EGP)',
  ETB: 'Birr éthiopien (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Dollar fidjien (FJD)',
  GBP: 'Livre sterling (GBP)',
  GHS: 'Cédi ghanéen (GHS)',
  GTQ: 'Quetzal guatémaltèque (GTQ)',
  HKD: 'Dollar de Hong Kong (HKD)',
  HRK: 'Kuna croate (HRK)',
  HUF: 'Forint hongrois (HUF)',
  IDR: 'Roupie indonésienne (IDR)',
  ILS: 'Nouveau shekel israélien (ILS)',
  INR: 'Roupie indienne (INR)',
  IQD: 'Dinar irakien (IQD)',
  JMD: 'Dollar jamaïcain (JMD)',
  JOD: 'Dinar jordanien (JOD)',
  JPY: 'Yen japonais (JPY)',
  KES: 'Shilling kényan (KES)',
  KRW: 'Won sud-coréen (KRW)',
  KWD: 'Dinar koweïtien (KWD)',
  KZT: 'Tenge kazakh (KZT)',
  LBP: 'Livre libanaise (LBP)',
  LKR: 'Roupie srilankaise (LKR)',
  LTL: 'Litas lituanien (LTL)',
  LVL: 'Lats lettons (LVL)',
  MAD: 'Dirham marocain (MAD)',
  MUR: 'Roupie mauricienne (MUR)',
  MWK: 'Kwacha malawite (MWK)',
  MXN: 'Peso mexicain (MXN)',
  MYR: 'Ringgit malais (MYR)',
  NGN: 'Naira nigérian (NGN)',
  NOK: 'Couronne norvégienne (NOK)',
  NZD: 'Dollar néo-zélandais (NZD)',
  OMR: 'Riyal omanais (OMR)',
  PEN: 'Sol péruvien (PEN)',
  PHP: 'Peso philippin (PHP)',
  PKR: 'Roupie pakistanaise (PKR)',
  PLN: 'Zloty polonais (PLN)',
  QAR: 'Riyal qatari (QAR)',
  RUB: 'Rouble russe (RUB)',
  RWF: 'Franc rwandais (RWF)',
  SAR: 'Riyal saoudien (SAR)',
  SEK: 'Couronne suédoise (SEK)',
  SGD: 'Dollar de Singapour (SGD)',
  THB: 'Baht thaïlandais (THB)',
  TND: 'Dinar tunisien (TND)',
  TOP: 'Pa’anga tongan (TOP)',
  TRY: 'Livre turque (TRY)',
  TTD: 'Dollar de Trinité-et-Tobago (TTD)',
  TWD: 'Nouveau dollar taïwanais (TWD)',
  TZS: 'Shilling tanzanien (TZS)',
  UGX: 'Shilling ougandais (UGX)',
  USD: 'Dollar des États-Unis (USD)',
  UYU: 'Peso uruguayen (UYU)',
  VEF: 'Bolivar Fuertes vénézuélien (VEF)',
  VND: 'Dông vietnamien',
  XAF: 'Franc CFA (XAF)',
  XCD: 'Dollar des Caraïbes orientales (XCD)',
  XOF: 'Franc CFA (XOF)',
  ZAR: 'Rand sud-africain (ZAR)',
  ZMW: 'Kwacha zambien (ZMW)',
};

export const CURRENCIES_DE: IDictionary<string> = {
  AUD: 'Australischer Dollar (AUD)',
  ARS: 'Argentinischer Peso (ARS)',
  AZN: 'Aserbaidschan-Manat (AZN)',
  BDT: 'Bangladesch-Taka (BDT)',
  BGN: 'Bulgarischer Lew (BGN)',
  BHD: 'Bahrain-Dinar (BHD)',
  BRL: 'Brasilianischer Real (BRL)',
  BWP: 'Botswanischer Pula (BWP)',
  CAD: 'Kanadischer Dollar (CAD)',
  CHF: 'Schweizer Franken (CHF)',
  CLP: 'Chilenischer Peso (CLP)',
  COP: 'Kolumbianischer Peso (COP)',
  CRC: 'Costa-Rica-Colón (CRC)',
  CZK: 'Tschechische Krone (CZK)',
  DKK: 'Dänische Krone (DKK)',
  DOP: 'Dominikanischer Peso (DOP)',
  DZD: 'Algerischer Dinar (DZD)',
  EGP: 'Ägyptisches Pfund (EGP)',
  ETB: 'Äthiopischer Birr (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Fidschi-Dollar (FJD)',
  GBP: 'Britisches Pfund (GBP)',
  GHS: 'Ghanaischer Cedi (GHS)',
  GTQ: 'Guatemaltekischer Quetzal (GTQ)',
  HKD: 'Hongkong-Dollar (HKD)',
  HRK: 'Kroatischer Kuna (HRK)',
  HUF: 'Ungarischer Forint (HUF)',
  IDR: 'Indonesische Rupiah (IDR)',
  ILS: 'Israelischer Neuer Schekel (ILS)',
  INR: 'Indische Rupie (INR)',
  IQD: 'Irakischer Dinar (IQD)',
  JMD: 'Jamaika-Dollar (JMD)',
  JOD: 'Jordanischer Dinar (JOD)',
  JPY: 'Japanischer Yen (JPY)',
  KES: 'Kenia-Schilling (KES)',
  KRW: 'Südkoreanischer Won (KRW)',
  KWD: 'Kuwait-Dinar (KWD)',
  KZT: 'Kasachischer Tenge (KZT)',
  LBP: 'Libanesisches Pfund (LBP)',
  LKR: 'Sri-Lanka-Rupie (LKR)',
  LTL: 'Litauischer Litas (LTL)',
  LVL: 'Lettische Lats (LVL)',
  MAD: 'Marokkanischer Dirham (MAD)',
  MUR: 'Mauritius-Rupie (MUR)',
  MWK: 'Malawi-Kwacha (MWK)',
  MXN: 'Mexikanischer Peso (MXN)',
  MYR: 'Malaysischer Ringgit (MYR)',
  NGN: 'Nigerianischer Naira (NGN)',
  NOK: 'Norwegische Krone (NOK)',
  NZD: 'Neuseeland-Dollar (NZD)',
  OMR: 'Omanischer Rial (OMR)',
  PEN: 'Peruanischer Sol (PEN)',
  PHP: 'Philippinischer Peso (PHP)',
  PKR: 'Pakistanische Rupie (PKR)',
  PLN: 'Polnischer Złoty (PLN)',
  QAR: 'Katar-Riyal (QAR)',
  RUB: 'Russischer Rubel (RUB)',
  RWF: 'Ruanda-Franc (RWF)',
  SAR: 'Saudi-Rial (SAR)',
  SEK: 'Schwedische Krone (SEK)',
  SGD: 'Singapur-Dollar (SGD)',
  THB: 'Thailändischer Baht (THB)',
  TND: 'Tunesischer Dinar (TND)',
  TOP: 'Tongaischer Paʻanga (TOP)',
  TRY: 'Türkische Lira (TRY)',
  TTD: 'Trinidad-und-Tobago-Dollar (TTD)',
  TWD: 'Neuer Taiwan-Dollar (TWD)',
  TZS: 'Tansania-Schilling (TZS)',
  UGX: 'Uganda-Schilling (UGX)',
  USD: 'US-Dollar (USD)',
  UYU: 'Uruguayischer Peso (UYU)',
  VEF: 'Venezolanischer Bolivar Fuertes (VEF)',
  VND: 'Vietnamesischer Dong (VND)',
  XAF: 'Zentralafrikanischer CFA-Franc (XAF)',
  XCD: 'Ostkaribischer Dollar (XCD)',
  XOF: 'Westafrikanischer CFA-Franc (XOF)',
  ZAR: 'Südafrikanischer Rand (ZAR)',
  ZMW: 'Kwacha (ZMW)',
};

export const CURRENCIES_IT: IDictionary<string> = {
  AUD: 'Dollaro australiano (AUD)',
  ARS: 'Peso argentino (ARS)',
  AZN: 'Manat azero (AZN)',
  BDT: 'Taka bangladese (BDT)',
  BGN: 'Lev bulgaro (BGN)',
  BHD: 'Dinaro del Bahrein (BHD)',
  BRL: 'Real brasiliano (BRL)',
  BWP: 'Pula del Botswana (BWP)',
  CAD: 'Dollaro canadese (CAD)',
  CHF: 'Franco svizzero (CHF)',
  CLP: 'Peso cileno (CLP)',
  COP: 'Peso colombiano (COP)',
  CRC: 'Colón costaricano (CRC)',
  CZK: 'Corona ceca (CZK)',
  DKK: 'Corona danese (DKK)',
  DOP: 'Peso dominicano (DOP)',
  DZD: 'Dinaro algerino (DZD)',
  EGP: 'Sterlina egiziana (EGP)',
  ETB: 'Birr etiope (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Dollaro delle Figi (FJD)',
  GBP: 'Sterlina britannica (GBP)',
  GHS: 'Cedi ghanese (GHS)',
  GTQ: 'Quetzal guatemalteco (GTQ)',
  HKD: 'Dollaro di Hong Kong (HKD)',
  HRK: 'Kuna croata (HRK)',
  HUF: 'Fiorino ungherese (HUF)',
  IDR: 'Rupia indonesiana (IDR)',
  ILS: 'Nuovo siclo israeliano (ILS)',
  INR: 'Rupia indiana (INR)',
  IQD: 'Dinaro iracheno (IQD)',
  JMD: 'Dollaro giamaicano (JMD)',
  JOD: 'Dinaro giordano (JOD)',
  JPY: 'Yen giapponese (JPY)',
  KES: 'Scellino keniota (KES)',
  KRW: 'Won sudcoreano (KRW)',
  KWD: 'Dinaro kuwaitiano (KWD)',
  KZT: 'Tenge kazako (KZT)',
  LBP: 'Lira libanese (LBP)',
  LKR: 'Rupia di Sri Lanka (LKR)',
  LTL: 'Litas lituano (LTL)',
  LVL: 'Lats lettone (LVL)',
  MAD: 'Dirham marocchino (MAD)',
  MUR: 'Rupia mauriziana (MUR)',
  MWK: 'Kwacha malawiano (MWK)',
  MXN: 'Peso messicano (MXN)',
  MYR: 'Ringgit malese (MYR)',
  NGN: 'Naira nigeriana (NGN)',
  NOK: 'Corona norvegese (NOK)',
  NZD: 'Dollaro neozelandese (NZD)',
  OMR: 'Rial omanita (OMR)',
  PEN: 'Sol peruviano (PEN)',
  PHP: 'Peso filippino (PHP)',
  PKR: 'Rupia pakistana (PKR)',
  PLN: 'Zloty polacco (PLN)',
  QAR: 'Rial qatariano (QAR)',
  RUB: 'Rublo russo (RUB)',
  RWF: 'Franco ruandese (RWF)',
  SAR: 'Riyal saudita (SAR)',
  SEK: 'Corona svedese (SEK)',
  SGD: 'Dollaro di Singapore (SGD)',
  THB: 'Baht thailandese (THB)',
  TND: 'Dinaro tunisino (TND)',
  TOP: 'Paʻanga tongano (TOP)',
  TRY: 'Lira turca (TRY)',
  TTD: 'Dollaro di Trinidad e Tobago (TTD)',
  TWD: 'Nuovo dollaro taiwanese (TWD)',
  TZS: 'Scellino della Tanzania (TZS)',
  UGX: 'Scellino ugandese (UGX)',
  USD: 'Dollaro statunitense (USD)',
  UYU: 'Peso uruguayano (UYU)',
  VEF: 'Bolivar Fuertes venezuelano (VEF)',
  VND: 'Dong vietnamita (VND)',
  XAF: 'Franco CFA BEAC (XAF)',
  XCD: 'Dollaro dei Caraibi orientali (XCD)',
  XOF: 'Franco CFA BCEAO (XOF)',
  ZAR: 'Rand sudafricano (ZAR)',
  ZMW: 'Kwacha zambiano (ZMW)',
};

export const CURRENCIES_FR_CA: IDictionary<string> = {
  AUD: 'Dollar australien (AUD)',
  ARS: 'Peso argentin (ARS)',
  AZN: 'Manat azerbaïdjanais (AZN)',
  BDT: 'Taka bangladais (BDT)',
  BGN: 'Lev bulgare (BGN)',
  BHD: 'Dinar bahreïni (BHD)',
  BRL: 'Real brésilien (BRL)',
  BWP: 'Pula botswanais (BWP)',
  CAD: 'Dollar canadien (CAD)',
  CHF: 'Franc suisse (CHF)',
  CLP: 'Peso chilien (CLP)',
  COP: 'Peso colombien (COP)',
  CRC: 'Colón costaricain (CRC)',
  CZK: 'Couronne tchèque (CZK)',
  DKK: 'Couronne danoise (DKK)',
  DOP: 'Peso dominicain (DOP)',
  DZD: 'Dinar algérien (DZD)',
  EGP: 'Livre égyptienne (EGP)',
  ETB: 'Birr éthiopien (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Dollar fidjien (FJD)',
  GBP: 'Livre sterling (GBP)',
  GHS: 'Cedi ghanéen (GHS)',
  GTQ: 'Quetzal guatémaltèque (GTQ)',
  HKD: 'Dollar de Hong Kong (HKD)',
  HRK: 'Kuna croate (HRK)',
  HUF: 'Forint hongrois (HUF)',
  IDR: 'Roupie indonésienne (IDR)',
  ILS: 'Shekel israélien (ILS)',
  INR: 'Roupie indienne (INR)',
  IQD: 'Dinar irakien (IQD)',
  JMD: 'Dollar jamaïcain (JMD)',
  JOD: 'Dinar jordanien (JOD)',
  JPY: 'Yen japonais (JPY)',
  KES: 'Shilling kényan (KES)',
  KRW: 'Won sud-coréen (KRW)',
  KWD: 'Dinar koweïtien (KWD)',
  KZT: 'Tenge kazakh (KZT)',
  LBP: 'Livre libanaise (LBP)',
  LKR: 'Roupie srilankaise (LKR)',
  LTL: 'Litas lituanien (LTL)',
  LVL: 'Lats letton (LVL)',
  MAD: 'Dirham marocain (MAD)',
  MUR: 'Roupie mauricienne (MUR)',
  MWK: 'Kwacha malawien (MWK)',
  MXN: 'Peso mexicain (MXN)',
  MYR: 'Ringgit malaisien (MYR)',
  NGN: 'Naira nigérian (NGN)',
  NOK: 'Couronne norvégienne (NOK)',
  NZD: 'Dollar néo-zélandais (NZD)',
  OMR: 'Rial omanais (OMR)',
  PEN: 'Nouveau sol péruvien (PEN)',
  PHP: 'Peso philippin (PHP)',
  PKR: 'Roupie pakistanaise (PKR)',
  PLN: 'Zloty polonais (PLN)',
  QAR: 'Rial qatari (QAR)',
  RUB: 'Rouble russe (RUB)',
  RWF: 'Franc rwandais (RWF)',
  SAR: 'Riyal saoudien (SAR)',
  SEK: 'Couronne suédoise (SEK)',
  SGD: 'Dollar de Singapour (SGD)',
  THB: 'Baht thaïlandais (THB)',
  TND: 'Dinar tunisien (TND)',
  TOP: "Pa'anga tongan (TOP)",
  TRY: 'Lira turque (TRY)',
  TTD: 'Dollar de Trinité-et-Tobago (TTD)',
  TWD: 'Dollar taïwanais (TWD)',
  TZS: 'Shilling tanzanien (TZS)',
  UGX: 'Shilling ougandais (UGX)',
  USD: 'Dollar américain (USD)',
  UYU: 'Peso uruguayen (UYU)',
  VEF: 'Bolivar vénézuélien (VEF)',
  VND: 'Dong vietnamien (VND)',
  XAF: "Franc CFA d'Afrique centrale (XAF)",
  XCD: 'Dollar des Caraïbes orientales (XCD)',
  XOF: "Franc CFA d'Afrique de l'Ouest (XOF)",
  ZAR: 'Rand sud-africain (ZAR)',
  ZMW: 'Kwacha zambien (ZMW)',
};

export const CURRENCIES_ES_ES: IDictionary<string> = {
  AUD: 'Dólar australiano (AUD)',
  ARS: 'Peso argentino (ARS)',
  AZN: 'Manat azerbaiyano (AZN)',
  BDT: 'Taka bangladesí (BDT)',
  BGN: 'Lev búlgaro (BGN)',
  BHD: 'Dinar bareiní (BHD)',
  BRL: 'Real brasileño (BRL)',
  BWP: 'Pula botsuano (BWP)',
  CAD: 'Dólar canadiense (CAD)',
  CHF: 'Franco suizo (CHF)',
  CLP: 'Peso chileno (CLP)',
  COP: 'Peso colombiano (COP)',
  CRC: 'Colón costarricense (CRC)',
  CZK: 'Corona checa (CZK)',
  DKK: 'Corona danesa (DKK)',
  DOP: 'Peso dominicano (DOP)',
  DZD: 'Dinar argelino (DZD)',
  EGP: 'Libra egipcia (EGP)',
  ETB: 'Birr etíope (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Dólar fijiano (FJD)',
  GBP: 'Libra esterlina (GBP)',
  GHS: 'Cedi ghanés (GHS)',
  GTQ: 'Quetzal guatemalteco (GTQ)',
  HKD: 'Dólar de Hong Kong (HKD)',
  HRK: 'Kuna croata (HRK)',
  HUF: 'Forinto húngaro (HUF)',
  IDR: 'Rupia indonesia (IDR)',
  ILS: 'Nuevo séquel (ILS)',
  INR: 'Rupia india (INR)',
  IQD: 'Dinar iraquí (IQD)',
  JMD: 'Dólar jamaiquino (JMD)',
  JOD: 'Dinar jordano (JOD)',
  JPY: 'Yen japonés (JPY)',
  KES: 'Chelín keniano (KES)',
  KRW: 'Won surcoreano (KRW)',
  KWD: 'Dinar kuwaití (KWD)',
  KZT: 'Tenge kazajo (KZT)',
  LBP: 'Libra libanesa (LBP)',
  LKR: 'Rupia de Sri Lanka (LKR)',
  LTL: 'Litas lituano (LTL)',
  LVL: 'Lats letón (LVL)',
  MAD: 'Dirham marroquí (MAD)',
  MUR: 'Rupia mauriciana (MUR)',
  MWK: 'Kwacha malauí (MWK)',
  MXN: 'Peso mexicano (MXN)',
  MYR: 'Ringgit malasio (MYR)',
  NGN: 'Naira nigeriano (NGN)',
  NOK: 'Corona noruega (NOK)',
  NZD: 'Dólar neozelandés (NZD)',
  OMR: 'Rial omaní (OMR)',
  PEN: 'Sol peruano (PEN)',
  PHP: 'Peso filipino (PHP)',
  PKR: 'Rupia pakistaní (PKR)',
  PLN: 'Złoty polaco (PLN)',
  QAR: 'Rial catarí (QAR)',
  RUB: 'Rublo ruso (RUB)',
  RWF: 'Franco ruandés (RWF)',
  SAR: 'Riyal saudí (SAR)',
  SEK: 'Corona sueca (SEK)',
  SGD: 'Dólar de Singapur (SGD)',
  THB: 'Baht tailandés (THB)',
  TND: 'Dinar tunecino (TND)',
  TOP: "Pa'anga tongano (TOP)",
  TRY: 'Lira turca (TRY)',
  TTD: 'Dólar de Trinidad y Tobago (TTD)',
  TWD: 'Dólar taiwanés (TWD)',
  TZS: 'Chelín tanzano (TZS)',
  UGX: 'Chelín ugandés (UGX)',
  USD: 'Dólar estadounidense (USD)',
  UYU: 'Peso uruguayo (UYU)',
  VEF: 'Bolívar venezolano (VEF)',
  VND: 'Dong vietnamita (VND)',
  XAF: 'Franco CFA de África Central (XAF)',
  XCD: 'Dólar del Caribe Oriental (XCD)',
  XOF: 'Franco CFA de África Occidental (XOF)',
  ZAR: 'Rand sudafricano (ZAR)',
  ZMW: 'Kwacha zambiano (ZMW)',
};

export const CURRENCIES_ES_MX: IDictionary<string> = {
  AUD: 'Dólar australiano (AUD)',
  ARS: 'Peso argentino (ARS)',
  AZN: 'Manat azerbaiyano (AZN)',
  BDT: 'Taka bangladesí (BDT)',
  BGN: 'Lev búlgaro (BGN)',
  BHD: 'Dinar bareiní (BHD)',
  BRL: 'Real brasileño (BRL)',
  BWP: 'Pula de Botsuana (BWP)',
  CAD: 'Dólar canadiense (CAD)',
  CHF: 'Franco suizo (CHF)',
  CLP: 'Peso chileno (CLP)',
  COP: 'Peso colombiano (COP)',
  CRC: 'Colón costarricense (CRC)',
  CZK: 'Corona checa (CZK)',
  DKK: 'Corona danesa (DKK)',
  DOP: 'Peso dominicano (DOP)',
  DZD: 'Dinar argelino (DZD)',
  EGP: 'Libra egipcia (EGP)',
  ETB: 'Birr etíope (ETB)',
  EUR: 'Euro (EUR)',
  FJD: 'Dólar fiyiano (FJD)',
  GBP: 'Libra esterlina (GBP)',
  GHS: 'Cedi ghanés (GHS)',
  GTQ: 'Quetzal guatemalteco (GTQ)',
  HKD: 'Dólar de Hong Kong (HKD)',
  HRK: 'Kuna croata (HRK)',
  HUF: 'Forinto húngaro (HUF)',
  IDR: 'Rupia indonesia (IDR)',
  ILS: 'Nuevo shéquel (ILS)',
  INR: 'Rupia india (INR)',
  IQD: 'Dinar iraquí (IQD)',
  JMD: 'Dólar jamaiquino (JMD)',
  JOD: 'Dinar jordano (JOD)',
  JPY: 'Yen japonés (JPY)',
  KES: 'Chelín keniano (KES)',
  KRW: 'Won surcoreano (KRW)',
  KWD: 'Dinar kuwaití (KWD)',
  KZT: 'Tenge kazajo (KZT)',
  LBP: 'Libra libanesa (LBP)',
  LKR: 'Rupia de Sri Lanka (LKR)',
  LTL: 'Litas lituano (LTL)',
  LVL: 'Lats letón (LVL)',
  MAD: 'Dirham marroquí (MAD)',
  MUR: 'Rupia mauriciana (MUR)',
  MWK: 'Kwacha malauí (MWK)',
  MXN: 'Peso mexicano (MXN)',
  MYR: 'Ringgit malasio (MYR)',
  NGN: 'Naira nigeriano (NGN)',
  NOK: 'Corona noruega (NOK)',
  NZD: 'Dólar neozelandés (NZD)',
  OMR: 'Rial omaní (OMR)',
  PEN: 'Sol peruano (PEN)',
  PHP: 'Peso filipino (PHP)',
  PKR: 'Rupia pakistaní (PKR)',
  PLN: 'Złoty polaco (PLN)',
  QAR: 'Rial catarí (QAR)',
  RUB: 'Rublo ruso (RUB)',
  RWF: 'Franco ruandés (RWF)',
  SAR: 'Riyal saudí (SAR)',
  SEK: 'Corona sueca (SEK)',
  SGD: 'Dólar de Singapur (SGD)',
  THB: 'Baht tailandés (THB)',
  TND: 'Dinar tunecino (TND)',
  TOP: "Pa'anga tongano (TOP)",
  TRY: 'Lira turca (TRY)',
  TTD: 'Dólar de Trinidad y Tobago (TTD)',
  TWD: 'Dólar taiwanés (TWD)',
  TZS: 'Chelín tanzano (TZS)',
  UGX: 'Chelín ugandés (UGX)',
  USD: 'Dólar estadounidense (USD)',
  UYU: 'Peso uruguayo (UYU)',
  VEF: 'Bolívar fuerte venezolano (VEF)',
  VND: 'Dong vietnamita (VND)',
  XAF: 'Franco CFA de África Central (XAF)',
  XCD: 'Dólar del Caribe Oriental (XCD)',
  XOF: 'Franco CFA de África Occidental (XOF)',
  ZAR: 'Rand sudafricano (ZAR)',
  ZMW: 'Kwacha zambiano (ZMW)',
};

export const CURRENCIES_LANGUAGE_MAPPING = {
  'en-us': CURRENCIES_EN,
  'en-gb': CURRENCIES_EN_GB,
  'fr-fr': CURRENCIES_FR,
  'de-de': CURRENCIES_DE,
  'it-it': CURRENCIES_IT,
  'fr-ca': CURRENCIES_FR_CA,
  'es-es': CURRENCIES_ES_ES,
  'es-mx': CURRENCIES_ES_MX,
};

export const COUNTRY_CODE_CURRENCY_MAPPING = {
  US: 'USD',
  FR: 'EUR',
  DE: 'EUR',
  IT: 'EUR',
};
