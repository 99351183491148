import { ITooltipHostProps, TooltipHost } from '@fluentui/react';
import React from 'react';

export interface IConditionalTooltipProps extends ITooltipHostProps {
  showTooltip?: boolean;
  targetSelector?: string;
  children: React.ReactNode;
}

export function ConditionalTooltip({ showTooltip = false, children, targetSelector, ...tooltipHostProps }: IConditionalTooltipProps) {
  if (showTooltip) {
    return (
      <TooltipHost calloutProps={{ target: targetSelector, gapSpace: 0 }} {...tooltipHostProps}>
        {children}
      </TooltipHost>
    );
  }

  return <>{children}</>;
}
