import { getTheme, IStackTokens, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubcenterTheme } from '../../../../theme';
const { ResponsiveMedium, ResponsiveSmall } = BreakPoints;
interface ITythonReportCardsStyleProps {
  theme: IPubcenterTheme;
}
type StyleProperties = 'reportCard';

type TythonReportCardsStyles = Record<StyleProperties, string>;

export const getStyles = (props: ITythonReportCardsStyleProps): TythonReportCardsStyles => {
  return mergeStyleSets({
    reportCard: {
      display: 'grid',
      gridTemplateColumns: '49.5% 49.5%',
      columnGap: '1%',
      rowGap: '2%',
      [ResponsiveMedium]: {
        gridTemplateColumns: '100%',
        columnGap: '0',
      },
      [ResponsiveSmall]: {
        gridTemplateColumns: '100%',
        columnGap: '0',
      },
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: (getTheme() as unknown) as IPubcenterTheme, ...props });
}
export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
