import {
  ActionButton,
  Breadcrumb,
  DefaultButton,
  getId,
  IBreadcrumbData,
  mergeStyleSets,
  Overlay,
  PrimaryButton,
  Spinner,
} from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { getAppStore, LayoutMode, onHelpInfoCalloutOpened, onHelpInfoChanged, onLayoutChanged } from '../../@data';
import AppMessages from '../../App.messages';
import { FormTitle } from '../../components/Form';
import { isInPageForm } from '../../components/Form/FormState/formUtils';
import { Main } from '../../components/Main/Main';
import { Stepper } from '../../components/Stepper/Stepper';
import { StepperDirection } from '../../components/Stepper/Stepper.types';
import { injectIntlAndObserver } from '../../utils';
import messages from './DetailPageWithStepper.messages';
import { getClassNames } from './DetailPageWithStepper.styles';
import { IDetailPageWithStepperProps, StepState } from './DetailPageWithStepper.types';
import { LeavePagePopup } from './LeavePagePopup';
import { useStepperLogic } from './utils/useStepperLogic';

/**
 * DetailPageWithStepper is a layout component which is to be used when a form is displayed
 * with submit, cancel buttons and Stepper. And the page should have a title and a help icon.
 */
export const DetailPageWithStepper = injectIntlAndObserver(function DetailsPageWithStepperComponent(props: IDetailPageWithStepperProps) {
  const searchParams = new URLSearchParams(props.location.search);
  const showExtraBtns = searchParams.get('showExtraBtns');

  const { formatMessage } = props.intl;
  const { helpInfo, locale } = getAppStore();
  const formTitleId = getId('formTitle');
  const { process, pageMode, styles, steps, openStep, backToBase, direction = StepperDirection.Vertical } = props;
  const { root, navBackButton, scrollablePanel, buttons, content, subComponentStyles, mainContainer, leftPane, rightPane } = mergeStyleSets(
    getClassNames({ pageMode, direction }),
    styles
  );
  const {
    currentStep,
    state,
    stepsState,
    goToStep,
    nextStep,
    previousStep,
    navigateBack,
    getStepperTitle,
    getBreadCrumpTitle,
  } = useStepperLogic(pageMode, process, steps, openStep || 0, backToBase, formatMessage(messages.stepperGoToFutureStepError));

  const { saving, loading } = getAppStore();
  const showOverlay = saving || loading;

  const getSubmitLabel = (idx: number): string => {
    const label = idx === steps.length - 1 ? AppMessages.finish : AppMessages.continue;
    return props.steps[idx].nextBtnLbl || formatMessage(label);
  };

  const getCancelLabel = (idx: number): string => {
    const label = idx === 0 ? AppMessages.cancel : AppMessages.previous;
    return props.steps[idx].previousBtnLbl || formatMessage(label);
  };

  useEffect(() => {
    if (!isInPageForm(pageMode)) {
      onLayoutChanged(LayoutMode.ContentOnly);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={root}>
      {!isInPageForm(pageMode) && (
        <div className={navBackButton}>
          {showOverlay && <Overlay />}
          <ActionButton
            id="backButton"
            iconProps={{
              iconName: 'Back',
            }}
            onClick={() => navigateBack()}
            ariaDescription={formatMessage(messages.backButtonAriaDesc)}
          >
            {formatMessage(AppMessages.back)}
          </ActionButton>
          <Breadcrumb
            items={getBreadCrumpTitle}
            onReduceData={(data: IBreadcrumbData) => {
              return undefined;
            }}
            ariaLabel={formatMessage(messages.breadcrumbAriaLabel)}
            styles={subComponentStyles.breadcrumb}
          />
        </div>
      )}

      <div className={mainContainer}>
        {steps && (
          <div className={leftPane}>
            <Stepper
              direction={direction}
              labelArray={getStepperTitle}
              currentStep={currentStep! + 1}
              goToStep={goToStep}
              isStepMarked={(step) => stepsState[step] === StepState.Dirty}
            />
          </div>
        )}

        <div className={rightPane}>
          <Main className={scrollablePanel}>
            {showOverlay && <Overlay />}
            {steps[currentStep].name || steps[currentStep].title ? (
              <FormTitle
                id={formTitleId}
                title={steps[currentStep].title || steps[currentStep].name}
                calloutContent={helpInfo}
                iconButtonOnClick={() => onHelpInfoCalloutOpened(steps[currentStep].helpId || '', locale) || undefined}
                calloutOnDismiss={() => onHelpInfoChanged()}
                styles={subComponentStyles.formTitle}
                intl={props.intl}
                noHelpPopup={steps[currentStep].helpId !== undefined ? false : true}
              />
            ) : null}

            <div role="form" aria-labelledby={formTitleId} className={content}>
              {React.cloneElement(steps[currentStep].component, { stepState: state, goToStep: goToStep, ...props })}
              {showOverlay && <Overlay />}
            </div>
          </Main>
          <div className={buttons}>
            {!steps[currentStep].submitButtonDisabled && (
              <PrimaryButton
                id="primaryButton"
                text={getSubmitLabel(currentStep)}
                onClick={() => {
                  nextStep();
                }}
                disabled={saving || steps[currentStep].isNextDisabled}
              />
            )}

            <DefaultButton
              id="defaultButton"
              text={getCancelLabel(currentStep)}
              onClick={previousStep}
              disabled={saving}
              ariaDescription={formatMessage(messages.backButtonAriaDesc)}
            />

            {showExtraBtns && steps[currentStep].extraButtons}

            {saving && <Spinner label={formatMessage(AppMessages.saving)} labelPosition={'right'} />}
          </div>
        </div>
      </div>

      <LeavePagePopup onSubmit={() => props.backToBase()} />
    </div>
  );
});
