import { CommandButton, IContextualMenuItem, IContextualMenuProps, Stack } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Card } from '../../../../../components/Card/Card';
import { ICheckbox } from '../../../../../components/CheckboxGroup';
import { ISelection } from '../../../../../components/CheckboxTreeWithSelectionBox';
import { REPORTING_DATE_RANGE_PICKER } from '../../../../../constants/DataTestIdConstants';
import { injectIntlAndObserver } from '../../../../../utils';
import { getDataFromURL, setDataInURL } from '../../../@data/store/UrlQueryStore';
import { TythonColumnsEnum } from '../../../@data/store/schema/ColumnsEnum';
import { ReportsQueryStoreKeys } from '../../../@data/store/schema/ReportsQueryStoreKeys';
import { CustomCalender } from '../../../components/CustomCalender/CustomCalender';
import { ICustomCalenderDateRange, RangeType } from '../../../components/CustomCalender/CustomCalender.types';
import { EXCLUDED_FILTER_COLUMNS } from '../../../constants/common';
import { getReportColumnMetadataDisplayName } from '../../../utils/intlHelper';
import { fetchReportDimensionColumnMembers, resetColumnMembers } from '../../@data/actions';
import { IMetadataElement } from '../../@data/store/schema';
import { getColumnMembers } from '../../@data/store/selectors';
import { getReportPageStore } from '../../@data/store/store';
import { getIncompatibleFilterMessage } from '../../ReportCard/ReportCard.helpers';
import { ActiveMetrics, ReportCardType } from '../../ReportCard/ReportCard.types';
import { MenuItemWarningIcon } from '../WarningIcon/MenuItemWarningIcon';
import { FilterListBar } from './FilterListBar/FilterListBar';
import { ManageFilter } from './ManageFilter/ManageFilter';

export interface IActionBarProps extends InjectedIntlProps {
  onSelectedDatesChanged: (dateRange: ICustomCalenderDateRange, rangeType: RangeType) => void;
  onFilterChanged: (activeFilters: IActiveFilter[]) => void;
  initialDateRange: ICustomCalenderDateRange;
  initialDateRangeType: RangeType;
  dimensions: IMetadataElement[];
  isMetadataLoaded: boolean;
  reportCardType: ReportCardType;
  activeMetrics?: ActiveMetrics;
}

export interface IActiveFilter {
  key: string;
  filterKey: string;
  displayName: string;
  values: IMetadataElement[];
  incompatibleFields: string[];
  totalMembers: number;
}

const unAllowedFilters = EXCLUDED_FILTER_COLUMNS;

export const ActionBar = injectIntlAndObserver(
  ({
    onSelectedDatesChanged,
    onFilterChanged,
    initialDateRange,
    initialDateRangeType,
    dimensions,
    isMetadataLoaded,
    reportCardType,
    activeMetrics,
    intl: { formatMessage },
  }: IActionBarProps) => {
    const { reportType } = getReportPageStore();
    const columnMembers = getColumnMembers();
    const [isFilterOpen, { setTrue: setIsFilterOpenTrue, setFalse: setIsFilterOpenFalse }] = useBoolean(false);
    const filterButtonId = useId('callout-button');
    const [activeDimension, setActiveDimension] = useState<IMetadataElement>();
    const storedFilters = getDataFromURL<IActiveFilter[]>(ReportsQueryStoreKeys.FILTERS) || [];
    const [activeFilters, setActiveFilters] = useState<IActiveFilter[]>(storedFilters);
    const currentColumnMembers = activeDimension && columnMembers[activeDimension?.filterKey];
    const areColumnMembersLoaded = currentColumnMembers?.isDataLoaded;
    let dimensionFilter: ICheckbox[] =
      activeDimension && currentColumnMembers && currentColumnMembers.data
        ? currentColumnMembers.data.map((member) => {
            return {
              checked: false,
              key: member.Id,
              label: member.DisplayName,
            };
          })
        : [];
    const currentFilter = activeFilters.find((activeFilter) => activeFilter.filterKey === activeDimension?.filterKey);
    if (currentFilter) {
      dimensionFilter = dimensionFilter.map((filter) => {
        filter.checked = !!currentFilter.values?.find((value) => filter.key === value.key);
        return filter;
      });
    }
    const activeDimensionIncompatibleMessage = getIncompatibleFilterMessage(
      activeDimension?.incompatibleFields?.map((field) => activeMetrics?.[field].metricName).join(',') || '',
      getReportColumnMetadataDisplayName(activeDimension?.key || '', formatMessage) || ''
    );
    const filterMenuProps: IContextualMenuProps = {
      items: isMetadataLoaded
        ? dimensions
            .filter((dimension) => !unAllowedFilters.includes(dimension.key as TythonColumnsEnum) && !!dimension.filterKey)
            .map((dimension) => {
              return {
                key: dimension.key,
                filterKey: dimension.filterKey,
                text: getReportColumnMetadataDisplayName(dimension.key, formatMessage),
                onRenderContent: (props, defaultRender) => {
                  const message = getIncompatibleFilterMessage(
                    dimension?.incompatibleFields?.map((field) => activeMetrics?.[field].metricName).join(',') || '',
                    getReportColumnMetadataDisplayName(dimension.key, formatMessage)
                  );
                  return (
                    <MenuItemWarningIcon
                      text={props.item.text || ''}
                      warningMessage={formatMessage(message.cantMessage, message.params)}
                      isIncompatible={checkFieldCompatibility(dimension, activeMetrics)}
                    />
                  );
                },
                onClick: () => {
                  setActiveDimension(dimension);
                  setIsFilterOpenTrue();
                },
              } as IContextualMenuItem;
            })
        : [],
    };
    const onFilterApplied = (dimension: IMetadataElement, selectedFilters: ISelection[]) => {
      const activeFilterIndex = activeFilters.findIndex((filter) => filter.filterKey === dimension.filterKey);
      if (activeFilterIndex > -1) {
        if (selectedFilters.length <= 0) {
          activeFilters.splice(activeFilterIndex, 1);
        } else {
          activeFilters[activeFilterIndex].values = selectedFilters.map((selected) => ({
            key: selected.key,
            filterKey: selected.key,
            displayName: selected.title || '',
            incompatibleFields: [],
          }));
        }
      } else {
        activeFilters.push({
          key: dimension.key,
          filterKey: dimension.filterKey,
          displayName: getReportColumnMetadataDisplayName(dimension.key, formatMessage),
          totalMembers: currentColumnMembers?.data ? currentColumnMembers?.data.length : 0,
          values: selectedFilters.map((selected) => ({
            key: selected.key,
            filterKey: selected.key,
            displayName: selected.title || '',
            incompatibleFields: [],
          })),
          incompatibleFields: dimension.incompatibleFields,
        });
      }
      setActiveFilters([...activeFilters]);
      onFilterChanged(activeFilters);
      setDataInURL(ReportsQueryStoreKeys.FILTERS, activeFilters);
    };

    useEffect(() => {
      if (activeDimension && !areColumnMembersLoaded) {
        fetchReportDimensionColumnMembers(
          {
            column: activeDimension?.filterKey,
            reportType,
          },
          reportCardType
        );
      }
    }, [activeDimension, reportType, reportCardType, areColumnMembersLoaded]);
    useEffect(() => {
      return () => {
        resetColumnMembers();
      };
    }, []);

    return (
      <Card isDataLoaded={true} titleText={''}>
        <Stack horizontal horizontalAlign="space-between">
          <div>
            <CommandButton
              disabled={!isMetadataLoaded}
              id={filterButtonId}
              iconProps={{ iconName: 'Filter' }}
              text="Filter"
              menuProps={filterMenuProps}
            />
            <div>
              <ManageFilter
                initialFilterMembers={dimensionFilter}
                buttonId={filterButtonId}
                isFilterOpen={isFilterOpen}
                onDismiss={function (): void {
                  setIsFilterOpenFalse();
                }}
                activeDimension={activeDimension}
                isIncompatible={activeDimension && checkFieldCompatibility(activeDimension, activeMetrics)}
                inCompatibilityMessage={formatMessage(
                  activeDimensionIncompatibleMessage.cantMessage,
                  activeDimensionIncompatibleMessage.params
                )}
                onFilterApplied={onFilterApplied}
                areMembersLoaded={!!areColumnMembersLoaded}
              />
            </div>
          </div>
          <div>
            <CustomCalender
              dataTestId={REPORTING_DATE_RANGE_PICKER}
              onSelectedDatesChanged={onSelectedDatesChanged}
              initialDateRange={initialDateRange}
              initialRangeType={initialDateRangeType}
            />
          </div>
        </Stack>
        {activeFilters.length > 0 && (
          <FilterListBar
            filterItems={activeFilters.map((filter) => {
              return {
                key: filter.key || '',
                onClick() {
                  setActiveDimension({
                    key: filter.key,
                    filterKey: filter.filterKey,
                    displayName: getReportColumnMetadataDisplayName(filter.key, formatMessage),
                    incompatibleFields: filter.incompatibleFields,
                  });
                  setIsFilterOpenTrue();
                },
                onRemove() {
                  const updatedFilters = activeFilters.filter((active) => active.key !== filter.key);
                  setActiveFilters(updatedFilters);
                  onFilterChanged(updatedFilters);
                  setDataInURL(ReportsQueryStoreKeys.FILTERS, updatedFilters);
                },
                title: `${getReportColumnMetadataDisplayName(filter.key, formatMessage)}: ${filter.values[0].displayName}${
                  filter.values.length > 1 ? ` and ${filter.values.length - 1} more` : ''
                }`,
              };
            })}
          />
        )}
      </Card>
    );
  }
);

function checkFieldCompatibility(dimension: IMetadataElement, activeMetrics: ActiveMetrics | undefined) {
  return (
    dimension.incompatibleFields &&
    dimension.incompatibleFields.filter((field) => {
      return !!activeMetrics?.[field] && activeMetrics?.[field].isVisible;
    }).length > 0
  );
}
