import { mutatorAction } from "satcheljs";
import { getAdunitStore } from "../store/store";

export const onAdSenseMediationToggleChanged = mutatorAction(
  'onAdSenseMediationToggleChanged', (isEnabled: boolean) => {
    getAdunitStore().active.adSenseMediationToggle = isEnabled;
  }
);

export const onAdSenseMediationChanged = mutatorAction(
  'onAdSenseMediationChanged', (isEnabled: boolean) => {
    getAdunitStore().active.adSenseMediationStatus = isEnabled;
  }
);