import { defineMessages } from 'react-intl';

export default defineMessages({
  announcementTitle: {
    id: 'announcement.title',
    defaultMessage: 'Announcements',
  },
  firstAnnouncementTitle: {
    id: 'firstAnnouncement.title',
    defaultMessage: 'New customer support experience',
  },
  firstAnnouncementDescription: {
    id: 'firstAnnouncement.description',
    defaultMessage: 'Our new webform makes it easier for you to contact our customer support team.',
  },
  firstAnnouncementButton: {
    id: 'firstAnnouncement.button',
    defaultMessage: 'Contact Support',
  },
  secondAnnouncementTitle: {
    id: 'secondAnnouncement.title',
    defaultMessage: 'We’ve updated the settings for native ads',
  },
  secondAnnouncementDescription: {
    id: 'secondAnnouncement.description',
    defaultMessage: 'The updated settings for native ads make it easier to create ad units and earn from your website.',
  },
  secondAnnouncementButton: {
    id: 'secondAnnouncement.button',
    defaultMessage: 'Learn more',
  },
  thirdAnnouncementTitle: {
    id: 'thirdAnnouncement.title',
    defaultMessage: 'Preview your Google mediation report',
  },
  thirdAnnouncementDescription: {
    id: 'thirdAnnouncement.description',
    defaultMessage:
      'You can now preview your Google mediation report directly in the Reports tab. Please note, this feature is only accessible to users who have Google mediation enabled. You can activate Google mediation by selecting Account > Settings > Connect.',
  },
  thirdAnnouncementButton: {
    id: 'thirdAnnouncement.button',
    defaultMessage: 'View reports',
  },
});
