import { orchestrator } from 'satcheljs';
import { onTeachingBubbleEndDateStatusChanged, onTeachingBubbleStartDateStatusChanged } from '../actions';
import {
  onAdCreativeEndDateChanged, onAdCreativeStartDateChanged
} from '../actions/onAdCreativeChanged';
import { getStore } from '../store/store';

orchestrator(onAdCreativeStartDateChanged, async (msg) => {
  onAdCreativeDateChanged(onTeachingBubbleStartDateStatusChanged);
});

orchestrator(onAdCreativeEndDateChanged, async (msg) => {
  onAdCreativeDateChanged(onTeachingBubbleEndDateStatusChanged);
});

function onAdCreativeDateChanged(onTeachingBubbbleStatusChanged) {
  const { active } = getStore();
  const queryMayTimeoutDays = 5;
  const milisecondsToDays = 1000*3600*24;

  if (active.endDate !== undefined && active.startDate !== undefined
      && active.endDate.toString().length !== 0 && active.startDate.toString().length !== 0) {
    const diffInDays = (active.endDate.getTime() - active.startDate.getTime()) / milisecondsToDays;
    if (diffInDays > queryMayTimeoutDays){
        onTeachingBubbbleStatusChanged(true);
    }
  }
}
