import { defineMessages } from 'react-intl';

export default defineMessages({
  inputPropertyName: {
    id: 'admanagement.property.inputPropertyName',
    defaultMessage: 'Property name',
  },
  inputSiteName: {
    id: 'admanagement.site.inputSiteName',
    defaultMessage: 'Site name',
  },
  propertyNameLength: {
    id: 'admanagement.property.propertyNameLength',
    defaultMessage: 'Maximum of 35 characters',
  },
});
