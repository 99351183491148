import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getStore } from '..';
import {
  deleteEntity,
  getAppStore,
  getEntity,
  onNotificationChanged,
  postEntity,
  Publisher,
  PublisherTier,
  showLoadingToastNotification,
  showSuccessToastNotification,
  UserInvitation,
  UserInvitationResend,
  UserInvitationType,
} from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import messages from '../../UserPage.messages';
import { onUserInvitationChanged, onUserInvitationExpiryChanged, onUserInvitationsChanged } from '../actions';

orchestrator(onUserInvitationChanged, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const selectedItem = msg.value as UserInvitation;
  let notification: INotificationBarItem = {};

  if (selectedItem && publisher && userContext) {
    const entityInstance = new UserInvitation();
    entityInstance.id = selectedItem.id;
    const publisherDetails = await getEntity<Publisher>([publisher], userContext);
    entityInstance.userInvitationType = UserInvitationType.Default;
    if (
      publisherDetails.publisherTier === PublisherTier.SelfServe3rdParty ||
      publisherDetails.publisherTier === PublisherTier.SelfServeStandard
    ) {
      entityInstance.userInvitationType = UserInvitationType.PubCenterSelfServeUser;
    }
    try {
      if (msg.mode === FormState.Resend) {
        showLoadingToastNotification({
          textMessageDescriptor: messages.resendingUserInvitation,
          intlMessageValues: { email: selectedItem.email },
          onDismiss: async () => {
            await postEntity([publisher, entityInstance], UserInvitationResend, entityInstance, userContext);

            /* Updating the user invitation expiry date on successful resend */
            onUserInvitationExpiryChanged(selectedItem.id, new Date(new Date().setDate(new Date().getDate() + 30)));

            showSuccessToastNotification({
              textMessageDescriptor: messages.resendUserInvitationSuccess,
              intlMessageValues: { email: selectedItem.email },
            });
          },
        });
      } else if (msg.mode === FormState.Delete) {
        const result = await deleteEntity([publisher, entityInstance], undefined, userContext);

        if (result) {
          let userInvitations = getStore().userInvitations;

          if (userInvitations) {
            userInvitations = userInvitations.filter((item) => item.id !== selectedItem.id);
          }
          onUserInvitationsChanged(userInvitations);

          showSuccessToastNotification({
            textMessageDescriptor: messages.deletedUserInvitationSuccess,
            intlMessageValues: { firstName: selectedItem.firstName },
          });
        }
      }
    } catch (e) {
      notification = {
        text: (msg.mode === FormState.Delete ? 'Deleting' : 'Resending ') + `the invitation caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
      onNotificationChanged(notification);
    }
  }
});
