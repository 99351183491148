export enum ResourceType {
  AdUnit,
  Filter,
  Channel,
  Publisher,
  Reporting,
  WebProperty,
  Account,
  User
}

export enum CrossEntityResourceType {
  PublisherByAdunit,
  PublisherByAccount,
  PublisherByUser,
  PublisherByWebProperty,
  PublisherByChannel,
  PublisherByFilter
}

export const crossEntityResourceTypeMap: Map<string, string[]> = new Map([
  [
    ResourceType[ResourceType.Publisher],
    [
      CrossEntityResourceType[CrossEntityResourceType.PublisherByAccount],
      CrossEntityResourceType[CrossEntityResourceType.PublisherByAdunit],
      CrossEntityResourceType[CrossEntityResourceType.PublisherByUser],
      CrossEntityResourceType[CrossEntityResourceType.PublisherByWebProperty]
    ]
  ]
]);
