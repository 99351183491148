import { action } from 'satcheljs';
import { IFilterDetails } from '../../../../../components/GridFilter/GridFilter.types';
import { DownloadOption } from '../../../../../layout/GridPageV2';

export const onAuditHistoryPageInitialized = action('onAuditHistoryPageInitialized');

export const updateFiltersAndFetchLogs = action('updateFiltersAndFetchLogs', (filterConditions: IFilterDetails[]) => ({
  filterConditions,
}));

export const handleDownloadButtonClicked = action('handleDownloadButtonClicked', (downloadType: DownloadOption, formatMessage, locale) => ({
  downloadType,
  formatMessage,
  locale,
}));
