import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Step } from './Step';
import { getClassNames } from './Stepper.style';
import { IStepperProps, StepperDirection } from './Stepper.types';

export const Stepper = injectIntl<IStepperProps>(function StepperComponent({
  direction = StepperDirection.Horizontal,
  labelArray,
  currentStep,
  styleProps,
  goToStep,
  updateStep,
  isStepMarked,
}) {
  const { stepWrapper } = mergeStyleSets(getClassNames({ direction }), styleProps);

  return (
    <div className={stepWrapper} role="navigation" aria-label="Stepper">
      {labelArray.map((item, index) => (
        <Step
          key={index}
          label={item}
          direction={direction}
          goToStep={goToStep}
          updateStep={updateStep}
          index={index}
          isSelected={currentStep === index + 1}
          totalSteps={labelArray.length}
          isMarked={isStepMarked ? isStepMarked?.(index) : currentStep >= index + 1}
          isLastChild={index === labelArray.length - 1}
        />
      ))}
    </div>
  );
});
