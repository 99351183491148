import { mutator } from 'satcheljs';
import { UserRole } from '../../../../../@data';
import {
  onFormAccountRoleChanged,
  onFormCleared,
  onFormContactByEmailChanged,
  onFormContactByPhoneChanged,
  onFormEmailAddressChanged,
  onFormFirstNameChanged,
  onFormIdChanged,
  onFormLanguageChanged,
  onFormLastNameChanged,
  onFormPhoneNumberChanged,
  onFormRoleAccountListChanged,
  onFormUserLocaleChanged,
  onFormUsernameChanged
} from '../actions';
import { getStore } from '../store/store';

mutator(onFormIdChanged, msg => {
  getStore().active.id = msg.value;
});

mutator(onFormCleared, () => {
  getStore().active = {};
  getStore().raw = undefined;
});

mutator(onFormFirstNameChanged, msg => {
  getStore().active.firstName = msg.value;
});

mutator(onFormLastNameChanged, msg => {
  getStore().active.lastName = msg.value;
});

mutator(onFormPhoneNumberChanged, msg => {
  getStore().active.phoneNumber = msg.value;
});

mutator(onFormEmailAddressChanged, msg => {
  getStore().active.emailAddress = msg.value;
});

mutator(onFormUsernameChanged, msg => {
  getStore().active.userName = msg.value;
});

mutator(onFormLanguageChanged, msg => {
  getStore().active.language = msg.value;
});

mutator(onFormAccountRoleChanged, msg => {
  if (msg.value) {
    getStore().active.accountRole = msg.value.key as UserRole;
    getStore().active.accountsList = getStore().active.allAccounts;
  }
});

mutator(onFormUserLocaleChanged, msg => {
  getStore().active.localeId = msg.value!!.key as number;
});

mutator(onFormContactByEmailChanged, msg => {
  getStore().active.contactByEmail = !!msg.value;
});

mutator(onFormContactByPhoneChanged, msg => {
  getStore().active.contactByPhone = !!msg.value;
});

mutator(onFormRoleAccountListChanged, msg => {
  const userRole = getStore().active.accountRole;

  if (userRole === UserRole.AccountManager) {
    getStore().active.AccountManagerAccountsSelected = msg.value;
  } else if (userRole === UserRole.AdView) {
    getStore().active.AdViewAccountsSelected = msg.value;
  } else if (userRole === UserRole.ListManager) {
    getStore().active.ListManagerAccountsSelected = msg.value;
  } else if (userRole === UserRole.ReportUser) {
    getStore().active.ReportUserAccounstSelected = msg.value;
  }
});
