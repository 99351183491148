import { Publisher, User } from '.';
import { Model } from './Model';

export class SelfServePublisher extends Model {
  public apiPath = undefined;
  public className = 'publishers';

  public publisher?: Publisher;
  public user?: User;
}
