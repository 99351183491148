export const GOOGLE_ADSENSE_OAUTH_CLIENT_ID_PROD = '1049810782184-nk9negp0sgack3sfatbfei0vjillr6ll.apps.googleusercontent.com';
export const GOOGLE_ADSENSE_OAUTH_CLIENT_ID_SI = '1049810782184-nk9negp0sgack3sfatbfei0vjillr6ll.apps.googleusercontent.com';

export const GOOGLE_ADSENSE_OAUTH_URL = 'https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?';

export const GOOGLE_ADSENSE_OAUTH_ACCESS_TYPE = 'offline';

export const GOOGLE_ADSENSE_OAUTH_RESPONSE_TYPE = 'code';

export const GOOGLE_ADSENSE_SCOPES = [
  'https://www.googleapis.com/auth/admanager',
  'https://www.googleapis.com/auth/adsense',
  'https://www.googleapis.com/auth/userinfo.email',
];

export const GIF_ANIMATION_DURATION = 4475;

export const GOOGLE_API_SCRIPT = 'https://accounts.google.com/gsi/client';

export const NOTIFICATION_FAILED_WITH_REASON_ERROR_CODE = 'NOTIFICATION_FAILED_WITH_REASON_ERROR_CODE';

export const MULTIPLE_GAM_ID_ERROR_CODE = 'MultipleGamNetworksFound';
