export enum PopupType {
  None,
  Loading,
  Leaving,
  NoAds,
  Guide,
  Mediation,
  Disconnecting,
  DeleteConfirmation,
  RoleChangeConfirmation,
  DeactivateConfirmation,
  AdUnitOptOut,
}