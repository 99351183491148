import React, { useEffect } from 'react';
import { handleDomainRedirection } from '../../@data';
import { LandingPageRoute } from '../../Routes';
import { USER_SIGNUP_KEY } from '../../constants/AppConstants';
import { Page } from '../../layout/PageLayout/PageLayout';
import { injectRouterWithIntl } from '../../utils';
import { isLocationPubCenter } from '../../utils/UrlUtils';
import { onPubCenterAADLogin } from '../login/@data';

export const LandingPage = injectRouterWithIntl(function LandingPageComponent(props) {
  useEffect(() => {
    localStorage.setItem(USER_SIGNUP_KEY, 'true');

    if (isLocationPubCenter()) {
      handleDomainRedirection('', LandingPageRoute);
    } else {
      onPubCenterAADLogin();
    }
  }, []);

  return <Page {...props} />;
});
