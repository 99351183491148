import { ICustomCalenderDateRange } from '../components/CustomCalender/CustomCalender.types';

export function checkIfValidDateRange(dateRange: ICustomCalenderDateRange) {
  const parsedStartDate = Date.parse(dateRange.startDate.toString());
  const parsedEndDate = Date.parse(dateRange.endDate.toString());
  if (!(parsedStartDate && parsedEndDate)) {
    return false;
  }
  if (parsedEndDate < parsedStartDate) {
    return false;
  }
  return true;
}
