import { IDropdownOption } from '@fluentui/react';
import { mutator } from 'satcheljs';
import { AdSource } from '../../../../../@data';
import { adProvidersToNames } from '../../../../../config/adProviders';
import { blockReasons } from '../../../../../config/blockReasons';
import { xandrAdProvidersToNames } from '../../../../../config/xandrAdProviders';
import noImage from '../../AdReview/noImageAvailable.svg';
import {
  onAdListingPageSelection,
  onBlockAdsAdPerfDataCleared,
  onBlockAdsBlockDetailsChanged,
  onBlockAdsBlockedImagesChanged,
  onBlockAdsBlockReasonChanged,
  onBlockAdsChanged,
  onBlockAdsErrorChanged,
  onBlockAdsImagesChanged,
  onBlockAdsReasonLevelsChanged,
  onBlockAdsSamChanged,
  onBlockAdsSelectedMarketChanged,
  onBlockAdsViewChanged,
  onBlockImageAdsChanged,
  onBlockImagesErrorChanged,
} from '../actions';
import {
  onAdDescriptionChanged,
  onBlockAdsChannelsChanged,
  onBlockAdsListingsChanged,
  onBlockAdsRevenueChanged,
  onBlockAdsSelectedBlockRequestSourceChanged,
} from '../actions/onBlockAdsFormChanged';
import { processImagesLink } from '../services/utility/utility';
import { IBlockItemViewModel } from '../store/schema';
import { AdPerformanceBlockData } from '../store/schema/models/AdPerformanceBlockData';
import { getStore } from '../store/store';

mutator(onBlockAdsViewChanged, (msg) => {
  getStore().blockAdsPage = msg.value;
});

mutator(onAdDescriptionChanged, (msg) => {
  getStore().adDescription = msg.value;
});

mutator(onBlockAdsSamChanged, (msg) => {
  const adSource = getStore().active.selectedAdSource;
  const selectedAds = msg.value;
  selectedAds.forEach((item) => {
    const blockAdSamData: AdPerformanceBlockData = {
      uniqueCreativeAdId: item.uniqueCreativeAdId,
      adTitle: item.adTitle,
      advertiserName: item.advertiserName,
      adProvider: item.adProvider
        ? adSource === AdSource.Auction
          ? adProvidersToNames[item.adProvider]
          : xandrAdProvidersToNames[item.adProvider]
        : undefined,
      adDescription: item.adDescription,
      revenue: item.revenue,
    };

    getStore().blockAdsPage.adPerfBlockAdsData.push(blockAdSamData);
  });
});

mutator(onBlockAdsChanged, (msg) => {
  const selectedAds = msg.value;
  selectedAds.forEach((item) => {
    const blockAdData: AdPerformanceBlockData = {
      adId: item.adId,
      adUnitId: item.adUnitId,
      adTitle: item.adTitle,
      adURL: item.adURL,
      adProvider: item.adProvider ? adProvidersToNames[item.adProvider] : undefined,
      advertiserName: item.advertiserName,
      advertiserAccountId: item.advertiserAccountId,
      adDescription: item.adDescription,
      adDestUrl: item.adDestUrl,
      accountId: item.accountId,
      accountName: item.accountName,
    };
    getStore().blockAdsPage.adPerfBlockAdsData.push(blockAdData);
  });
});

mutator(onBlockImageAdsChanged, (msg) => {
  const selectedAds = msg.value;
  selectedAds.forEach((item) => {
    const blockImageAdData: AdPerformanceBlockData = {
      adId: item.adId,
      adTitle: item.adTitle,
      adUnitId: item.adUnitId,
      adSource: item.adSource,
      adType: item.adType,
    };

    getStore().blockAdsPage.adPerfBlockAdsData.push(blockImageAdData);
  });
});

mutator(onBlockAdsImagesChanged, (msg) => {
  if (msg.images) {
    let imgs = processImagesLink(msg.images ? msg.images : [], noImage, msg.isAuctionData);
    const filteredImages = msg.images!.filter((i) => i !== '');
    imgs = msg.needUrlProcess ? imgs : filteredImages;
    getStore().blockAdsPage.adPerfBlockAdsData[msg.index!].images = imgs;
  }
});

mutator(onBlockAdsAdPerfDataCleared, (msg) => {
  getStore().blockAdsPage.adPerfBlockAdsData = [];
});

mutator(onBlockAdsBlockedImagesChanged, (msg) => {
  let imgs: string[] = [];
  const blockAdsView: IBlockItemViewModel = {
    ...getStore().blockAdsPage,
  };
  if (blockAdsView.adPerfBlockAdsData[0].blockedImages) {
    blockAdsView.adPerfBlockAdsData[0].blockedImages.forEach((img) => {
      imgs.push(img);
    });
  }
  if (msg.isChecked && !imgs.includes(msg.item)) {
    imgs.push(msg.item);
  }
  if (msg.isChecked === false && imgs.includes(msg.item)) {
    imgs = imgs.filter((img) => img !== msg.item);
  }

  blockAdsView.adPerfBlockAdsData[0].blockedImages = imgs;
  getStore().blockAdsPage = blockAdsView;
});

mutator(onBlockAdsSelectedBlockRequestSourceChanged, (msg) => {
  getStore().blockAdsPage.selectedBlockRequestSource = msg.value.key as number;
});

mutator(onBlockAdsSelectedMarketChanged, (msg) => {
  getStore().blockAdsPage.selectedMarket = msg.value.key as string;
});

mutator(onBlockAdsReasonLevelsChanged, (msg) => {
  getStore().blockAdsPage.blockReasonL1 = msg.value.key as string;
  const dropDownValues: IDropdownOption[] = [];
  blockReasons[msg.index].forEach((value) => {
    if (msg.formatter) {
      dropDownValues.push({
        key: msg.formatter(value),
        text: msg.formatter(value),
        ariaLabel: msg.formatter(value),
      });
    }
  });

  getStore().blockAdsPage.blockReasonsL2 = dropDownValues;
  getStore().blockAdsPage.blockReason = undefined;
});

mutator(onBlockAdsChannelsChanged, (msg) => {
  getStore().blockAdsPage.channels[msg.accountId] = msg.channelId;
});

mutator(onBlockAdsRevenueChanged, (msg) => {
  getStore().blockAdsPage.adPerfBlockAdsData[msg.index].revenue = msg.value;
});

mutator(onBlockAdsListingsChanged, (msg) => {
  getStore().blockAdsPage.adPerfBlockAdsData[msg.index].adListingsData = msg.value;
});

mutator(onBlockAdsBlockReasonChanged, (msg) => {
  const blockReason = (getStore().blockAdsPage.blockReasonL1 + ' - ' + msg.value.key) as string;
  getStore().blockAdsPage.blockReason = blockReason;
});

mutator(onBlockAdsBlockDetailsChanged, (msg) => {
  getStore().blockAdsPage.blockDetails = msg.value;
});

mutator(onAdListingPageSelection, (msg) => {
  if (getStore().blockAdsPage.adPerfBlockAdsData[msg.index!].adListingsData) {
    getStore().blockAdsPage.adPerfBlockAdsData[msg.index!].adListingsData!.adListingSelectedPage = msg.value as number;
  }
});

mutator(onBlockAdsErrorChanged, (msg) => {
  getStore().errors = msg.values || {};
});

mutator(onBlockImagesErrorChanged, (msg) => {
  getStore().errors = msg.values || {};
});
