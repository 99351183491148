import { Body1, Body1Strong, Body2, Button, Card, Divider, FluentProvider, webLightTheme } from '@fluentui/react-components';
import React from 'react';
import { useCMUIHelpExperience } from '../../../../../../../hooks/useCMUIHelpExperience';
import { IRouterWithIntl, injectRouterWithIntl } from '../../../../../../../utils';
import { getTythonAppAdunitCreationRoute } from '../../../../../../../utils/routeUtils';
import messages from '../messages';
import CreateAppIcon from '../resources/CreateAppIcon.svg';
import { useAppPropertyFinishPageStyles } from './styles';

type FinishPageType = React.FC<IRouterWithIntl>;

const FinishPage: FinishPageType = ({ intl: { formatMessage }, history }) => {
  const { CMUIPopupArticleByIdAnchor } = useCMUIHelpExperience();
  const classNames = useAppPropertyFinishPageStyles();
  return (
    <>
      <FluentProvider theme={webLightTheme}>
        <div className={classNames.root}>
          <Body1 className={classNames.content} block>
            {formatMessage(messages.finishPageExpectNextContent1)}
          </Body1>
          <br />
          <Body1 className={classNames.content} block>
            {formatMessage(messages.finishPageExpectNextContent2)}
          </Body1>
          <Body1 block>
            <CMUIPopupArticleByIdAnchor query="ext07504" name={formatMessage(messages.finishPageExpectNextContentHelpLink)} />
          </Body1>
          <Divider className={classNames.divider} />
          <Body2 className={classNames.title} block>
            {formatMessage(messages.finishPageExpectNextTitle2)}
          </Body2>
          <br />
          <Card>
            <div className={classNames.actionDivWrapper}>
              <img src={CreateAppIcon} alt={formatMessage(messages.finishPageCardTitle)} />
              <div>
                <Body1Strong block className={classNames.cardTitle}>
                  {formatMessage(messages.finishPageCardTitle)}
                </Body1Strong>
                <Body1 block className={classNames.content}>
                  {formatMessage(messages.finishPageCardContent)}
                </Body1>
                <Button onClick={() => history.push(getTythonAppAdunitCreationRoute())}>
                  <Body1Strong>{formatMessage(messages.finishPageActionButtonLabel)}</Body1Strong>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </FluentProvider>
    </>
  );
};

export default injectRouterWithIntl(FinishPage);
