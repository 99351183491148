import {
  Checkbox,
  ChoiceGroup,
  classNamesFunction,
  ComboBox,
  Dropdown,
  getTheme,
  IComboBoxOption,
  IDropdownOption,
  IProcessedStyleSet,
  Panel,
  PanelType,
  TextField,
} from '@fluentui/react';
import * as React from 'react';
import {
  EntityLimitTier,
  FeatureOverideTier,
  getAppStore,
  IPRange,
  ISubGeography,
  NetworkAffiliation,
  onNavigateBack,
  Partner,
  PublisherTier,
} from '../../../../@data';
import { completeAccess, hasReadAccess, hasUpdateAccess, UserRoleEntity, userRoleKey } from '../../../../@data/services/UserRoleService';
import { Publisher as PublisherAccessPermissions } from '../../../../@data/store/schema/userrolemodels/Publisher';
import AppMessages from '../../../../App.messages';
import { DataGrid, DataGridSelectionMode, IDataGrid, IDataGridCommandBarProps } from '../../../../components/DataGrid';
import { FormItem, FormSection, FormState } from '../../../../components/Form';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { countryToCurrencyMap } from '../../../../config/countryToCurrencyMapper.config';

import {
  COUNTRIES_LANGUAGE_MAPPING,
  CURRENCIES_LANGUAGE_MAPPING,
  LANGUAGES_LANGUAGE_MAPPING,
  TIMEZONE_LANGUAGE_MAPPING,
} from '../../../../globalization';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { IDataGridColumn } from '../../../../layout/GridPageV2';
import gridPageMessages from '../../../../layout/GridPageV2/GridV2.messages';
import { getScreenReaderTextStyles } from '../../../../utils/GlobalStyleUtils';
import { injectIntlWithObserver } from '../../../../utils/ReactUtils';
import { onPanelOpenChanged } from '../../@data/actions';
import { createReturnUrl } from '../../@data/service/PartnerManagementService';
import { getPageStore, RouteName } from '../../@data/store/store';
import {
  onFormCancelled,
  onFormCreated,
  onFormEditted,
  onFormInitialized,
  onFormPublisherAccountCountry,
  onFormPublisherAccountLanguage,
  onFormPublisherAccountName,
  onFormPublisherAccountTimezoneChanged,
  onFormPublisherAddressCityChanged,
  onFormPublisherAddressCountryCodeChanged,
  onFormPublisherAddressLine1Changed,
  onFormPublisherAddressLine2Changed,
  onFormPublisherAddressStateChanged,
  onFormPublisherAddressZipcodeChanged,
  onFormPublisherDistributionChanged,
  onFormPublisherDistributionV2Changed,
  onFormPublisherDynamicPropertyRegistrationChanged,
  onFormPublisherEnableDefaultTythonV2Changed,
  onFormPublisherEntityLimitTierChanged,
  onFormPublisherIpChanged,
  onFormPublisherIpNameChanged,
  onFormPublisherMaximumIpChanged,
  onFormPublisherMediationChanged,
  onFormPublisherMinimumIpChanged,
  onFormPublisherMobileSearchChanged,
  onFormPublisherNameChanged,
  onFormPublisherNativeChanged,
  onFormPublisherNetworkChanged,
  onFormPublisherOverrideTierChanged,
  onFormPublisherPartnerChanged,
  onFormPublisherPropertyAutoChanged,
  onFormPublisherPSDashboardChanged,
  onFormPublisherPSReportingAPIChanged,
  onFormPublisherSelectTierChanged,
  onFormPublisherServiceLevelChanged,
  onFormPublisherStartDistributionChanged,
  onFormPublisherStartDistributionContentChanged,
  onFormPublisherSyndicationChanged,
  onFormPublisherTierChanged,
  onFormPublisherWebAdunitsChanged,
  onPanelCleared,
} from '../@data/actions/onPublisherFormsChanged';
import '../@data/mutators/PublisherChanged';
import '../@data/orchestrators/formInitialized';
import { getStore } from '../@data/store';
import messages from './CreatePublisher.messages';
import { getStyles } from './CreatePublisher.styles';
import {
  ICreatePublisherProps,
  ICreatePublisherStyleProps,
  ICreatePublisherStyles,
  IFormInitializationState,
} from './CreatePublisher.types';

const getClassNames = classNamesFunction<ICreatePublisherStyleProps, ICreatePublisherStyles>();

export const CreatePublisher = injectIntlWithObserver(
  class extends React.Component<ICreatePublisherProps, IFormInitializationState> {
    private _classNames: IProcessedStyleSet<ICreatePublisherStyles>;
    private _datagrid = React.createRef<IDataGrid>();

    constructor(props: ICreatePublisherProps) {
      super(props);
      this.state = {
        initializeForm: true,
        ipPanelFormState: FormState.InPageCreate,
      };
    }

    public componentDidMount() {
      const publisherId = this.props.match.params.publisherId;
      const entityId = this.props.match.params.id;
      this.setState({ id: entityId });

      onFormInitialized(this.props.mode, publisherId, entityId);
    }

    public componentDidUpdate() {
      const publisherId = this.props.match.params.publisherId;
      const entityId = this.props.match.params.id;
      if (this.state.initializeForm && this.state.id !== entityId) {
        this.setState({ id: entityId });

        onFormInitialized(this.props.mode, publisherId, entityId);
      }
    }

    public render(): JSX.Element {
      const { formatMessage } = this.props.intl;
      const theme = getTheme();
      this._classNames = getClassNames(getStyles, { theme });

      const publisherId = this.props.match.params.publisherId;
      const store = getStore();
      const publisherAccessPermissions = this._getPublisherAccessPermission();

      return (
        <DetailPage
          titleLabel={this.props.mode === FormState.Edit ? formatMessage(messages.editPageTitle) : formatMessage(messages.createPageTitle)}
          helpId={'pop_PCv4_PublisherCreate'}
          submitLabel={formatMessage(this.props.mode === FormState.Edit ? messages.updatePublisher : messages.createPageTitle)}
          onSubmit={() => this._onSubmit(this.props.mode, publisherId)}
          onCancel={() => this._onCancel(publisherId)}
          onNavClick={() => this._onCancel(publisherId)}
          saving={getAppStore().saving}
          pageMode={this.props.mode}
        >
          <FormItem>
            <ComboBox
              useComboBoxAsMenuWidth={true}
              autoComplete="on"
              label={formatMessage(messages.partner)}
              required={true}
              selectedKey={store.active.partner}
              options={[
                { key: Partner.Microsoft, text: formatMessage(messages.microsoft) },
                { key: Partner.Yahoo, text: formatMessage(messages.yahoo) },
              ]}
              disabled={
                this.props.mode === FormState.Edit ||
                (publisherAccessPermissions ? !hasUpdateAccess(publisherAccessPermissions.partner) : false)
              }
              // Removing comboBox from tab order when it's disabled. When enabled tabIndex is set to undefined to prevent double tabbing
              tabIndex={
                this.props.mode === FormState.Edit ||
                (publisherAccessPermissions ? !hasUpdateAccess(publisherAccessPermissions.partner) : false)
                  ? -1
                  : undefined
              }
              errorMessage={store.errors.partner}
              onChange={(_, value) => onFormPublisherPartnerChanged(value!)}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <TextField
              label={formatMessage(messages.publisherName)}
              ariaLabel={formatMessage(messages.publisherName)}
              required={true}
              value={store.active.name}
              disabled={this.props.mode === FormState.Edit}
              onChange={(_, value) => onFormPublisherNameChanged(value)}
              errorMessage={store.errors.name}
            />
          </FormItem>
          {store.active.id && (
            <FormItem>
              <TextField
                label={formatMessage(messages.publisherId)}
                ariaLabel={formatMessage(messages.publisherId)}
                required={true}
                disabled={true}
                defaultValue={String(store.active.id)}
              />
            </FormItem>
          )}
          <FormItem formWidth={440}>
            <ChoiceGroup
              aria-activedescendant={formatMessage(messages.networkAffiliationOptionPartnerOwned)}
              label={formatMessage(messages.networkAffiliationLabel)}
              ariaLabelledBy={'NetworkAffiliation'}
              required={true}
              selectedKey={store.active.networkAffiliation ?? NetworkAffiliation.OwnedAndOperated}
              options={[
                {
                  id: formatMessage(messages.networkAffiliationOptionPartnerOwned),
                  key: NetworkAffiliation.OwnedAndOperated,
                  text: formatMessage(messages.networkAffiliationOptionPartnerOwned),
                },
                {
                  id: formatMessage(messages.networkAffiliationOptionSyndicated),
                  key: NetworkAffiliation.Syndicated,
                  text: formatMessage(messages.networkAffiliationOptionSyndicated),
                },
              ]}
              styles={this._classNames.subComponentStyles.servingTierChoiceGroup}
              onChange={(_, value) => onFormPublisherNetworkChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.networkAffiliation
              )}
            />
            <span id="NetworkAffiliation" style={getScreenReaderTextStyles()}>
              {formatMessage(messages.networkAffiliationLabel)}
            </span>
          </FormItem>
          <FormSection
            expanded={true}
            title={formatMessage(messages.publisherAddressTitle)}
            styles={this._classNames.subComponentStyles.formSection}
          >
            {this._publisherAddress(publisherAccessPermissions)}
          </FormSection>
          {this.props.mode === FormState.Edit && (
            <FormSection
              title={formatMessage(messages.advancedpublihserattributes)}
              styles={this._classNames.subComponentStyles.formSection}
            >
              {this._advancedpublisherAttributes(publisherAccessPermissions)}
            </FormSection>
          )}
          {this.props.mode === FormState.Create && (
            <FormSection
              expanded={true}
              title={formatMessage(messages.createAnAccountTitle)}
              styles={this._classNames.subComponentStyles.formSection}
            >
              {this._createAccount(publisherAccessPermissions)}
            </FormSection>
          )}
          <Panel
            isOpen={getPageStore().panelIsOpen}
            type={PanelType.medium}
            onDismiss={() => onPanelOpenChanged(false)}
            closeButtonAriaLabel={formatMessage(AppMessages.close)}
          >
            {this._createIPAddress(this._getSelectionItems(), this.state.ipPanelFormState, publisherAccessPermissions)}
          </Panel>
        </DetailPage>
      );
    }

    private _publisherAddress = (publisherAccessPermissions: PublisherAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();
      return (
        <>
          <FormItem formWidth={440}>
            <TextField
              label={formatMessage(AppMessages.address)}
              ariaLabel={formatMessage(AppMessages.address)}
              value={store.active.addressLine1}
              required={true}
              onChange={(_, value) => onFormPublisherAddressLine1Changed(value)}
              errorMessage={store.errors.addressLine1}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address
              )}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <TextField
              value={store.active.addressLine2}
              ariaLabel={formatMessage(messages.publisherAddressLine2)}
              onChange={(_, value) => onFormPublisherAddressLine2Changed(value)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address
              )}
            />
          </FormItem>
          <FormItem>
            <TextField
              label={formatMessage(AppMessages.city)}
              ariaLabel={formatMessage(AppMessages.city)}
              value={store.active.city}
              required={true}
              errorMessage={store.errors.city}
              onChange={(_, value) => onFormPublisherAddressCityChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address
              )}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <ComboBox
              useComboBoxAsMenuWidth={true}
              autoComplete="on"
              label={formatMessage(AppMessages.countryOrRegion)}
              required={true}
              errorMessage={store.errors.country}
              options={this._createCountryDropDown(store.active.availableCountries!)}
              selectedKey={store.active.country}
              onChange={(_, value) => onFormPublisherAddressCountryCodeChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address
              )}
              tabIndex={
                this._isDisabled(this.props.mode, publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address)
                  ? -1
                  : undefined
              }
            />
          </FormItem>
          <FormItem formWidth={440}>
            <ComboBox
              useComboBoxAsMenuWidth={true}
              autoComplete="on"
              required={true}
              errorMessage={store.errors.state}
              label={formatMessage(AppMessages.stateOrProvince)}
              options={this._createStateOrProvinceDropDown(store.active.availableStates!)}
              selectedKey={store.active.state}
              onChange={(_, value) => onFormPublisherAddressStateChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address
              )}
              tabIndex={
                this._isDisabled(this.props.mode, publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address)
                  ? -1
                  : undefined
              }
            />
          </FormItem>
          <FormItem>
            <TextField
              label={formatMessage(AppMessages.zipcode)}
              ariaLabel={formatMessage(AppMessages.zipcode)}
              value={store.active.postalcode}
              required={true}
              onChange={(_, value) => onFormPublisherAddressZipcodeChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.address
              )}
            />
          </FormItem>
        </>
      );
    };

    private _advancedpublisherAttributes = (publisherAccessPermissions: PublisherAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();

      const publisherTierDropdownOption: IComboBoxOption[] = [
        {
          key: PublisherTier.InternalPremium,
          text: formatMessage(messages.publisherTierInternalPremium),
        },
        {
          key: PublisherTier.ManagedPremium,
          text: formatMessage(messages.publisherTierManagedPremium),
        },
        {
          key: PublisherTier.SelfServeStandard,
          text: formatMessage(messages.publishertierSelfServeStandard),
        },
        {
          key: PublisherTier.SelfServe3rdParty,
          text: formatMessage(messages.publishertierSelfServe3rdParty),
        },
        {
          key: PublisherTier.SelfServe1stParty,
          text: formatMessage(messages.publishertierSelfServe1stParty),
        },
        {
          key: PublisherTier.SelfServe1stPartyBoost,
          text: formatMessage(messages.publishertierSelfServe1stPartyBoost),
        },
      ];

      const featureOverrideTierOption: IComboBoxOption[] = [
        {
          key: FeatureOverideTier.InternalPremium,
          text: formatMessage(messages.publisherFeatureOverrideTierInternalPremium),
        },
        {
          key: FeatureOverideTier.ManagedPremium,
          text: formatMessage(messages.publisherFeatureOverrideTierManagedPremium),
        },
        {
          key: FeatureOverideTier.SelfServeStandard,
          text: formatMessage(messages.publisherFeatureOverrideTierSelfServeStandard),
        },
        {
          key: FeatureOverideTier.Api,
          text: formatMessage(messages.publisherFeatureOverrideTierAPI),
        },
        {
          key: FeatureOverideTier.SelfServeExperiment,
          text: formatMessage(messages.publisherFeatureOverrideTierSelfServeExperiment),
        },
        {
          key: FeatureOverideTier.SelfServeSearch,
          text: formatMessage(messages.publisherFeatureOverrideTierSelfServeSearch),
        },
      ];

      const entityLimitTierOption: IComboBoxOption[] = [
        {
          key: EntityLimitTier.Infinite,
          text: formatMessage(messages.publisherEntityLimitInfinite),
        },
        {
          key: EntityLimitTier.Small,
          text: formatMessage(messages.publisherEntityLimitSmall),
        },
        {
          key: EntityLimitTier.Medium,
          text: formatMessage(messages.publisherEntityLimitMedium),
        },
        {
          key: EntityLimitTier.Large,
          text: formatMessage(messages.publisherEntityLimitLarge),
        },
        {
          key: EntityLimitTier.Network,
          text: formatMessage(messages.publisherEntityLimitNetwork),
        },
      ];

      const publisherServiceLevelOption: IComboBoxOption[] = [
        {
          key: 'Premium',
          text: formatMessage(messages.publisherServiceLevelPremium),
        },
        {
          key: 'SelfServe',
          text: formatMessage(messages.publisherServiceLevelSelfServe),
        },
        {
          key: 'trusted',
          text: formatMessage(messages.publisherServiceLevelSelfServeTrusted),
        },
        {
          key: 'internal',
          text: formatMessage(messages.publisherServiceLevelInternal),
        },
        {
          key: 'select',
          text: formatMessage(messages.publisherServiceLevelSelect),
        },
      ];

      return (
        <>
          {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.publisherTier) && (
            <FormItem>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                label={formatMessage(messages.publishertier)}
                selectedKey={store.active.publisherTier}
                required={true}
                options={publisherTierDropdownOption}
                onChange={(_, value) => onFormPublisherTierChanged(value!)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.publisherTier
                )}
                tabIndex={
                  this._isDisabled(
                    this.props.mode,
                    publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.publisherTier
                  )
                    ? -1
                    : undefined
                }
              />
            </FormItem>
          )}
          {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.overrideFeatureTier) && (
            <FormItem>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                label={formatMessage(messages.publisherFeatureOverrideTier)}
                selectedKey={store.active.overrideFeatureTier}
                required={true}
                options={featureOverrideTierOption}
                onChange={(_, value) => onFormPublisherOverrideTierChanged(value!)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.overrideFeatureTier
                )}
                tabIndex={
                  this._isDisabled(
                    this.props.mode,
                    publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.overrideFeatureTier
                  )
                    ? -1
                    : undefined
                }
              />
            </FormItem>
          )}
          {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.entityLimitTier) && (
            <FormItem>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                label={formatMessage(messages.publisherEntityLimit)}
                selectedKey={store.active.entityLimitTier}
                required={true}
                options={entityLimitTierOption}
                onChange={(_, value) => onFormPublisherEntityLimitTierChanged(value!)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.entityLimitTier
                )}
                tabIndex={
                  this._isDisabled(
                    this.props.mode,
                    publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.entityLimitTier
                  )
                    ? -1
                    : undefined
                }
              />
            </FormItem>
          )}
          {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.serviceLevel) && (
            <FormItem>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                label={formatMessage(messages.publisherServiceLevel)}
                selectedKey={store.active.serviceLevel}
                required={true}
                options={publisherServiceLevelOption}
                onChange={(_, value) => onFormPublisherServiceLevelChanged(value!)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.serviceLevel
                )}
                tabIndex={
                  this._isDisabled(
                    this.props.mode,
                    publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.serviceLevel
                  )
                    ? -1
                    : undefined
                }
              />
            </FormItem>
          )}

          <FormItem
            formWidth={440}
            role="region"
            ariaLabelledBy="pubAlwdFeaturesLabel"
            ariaDescription={formatMessage(messages.publisherAllowedFeaturesDesc)}
          >
            <FormLabel
              id="pubAlwdFeaturesLabel"
              labelContent={formatMessage(messages.publisherAllowedFeatures)}
              noInfoButton={true}
              intl={this.props.intl}
            />
            {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowWebAdUnit) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesWebAdUnits)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesWebAdUnits)}
                checked={!!store.active.allowWebAdUnit}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherWebAdunitsChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowWebAdUnit
                )}
              />
            )}
            {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowMobileSearchAdUnit) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesMobileSearchAdunit)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesMobileSearchAdunit)}
                checked={!!store.active.allowMobileSearchAdUnit}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherMobileSearchChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowMobileSearchAdUnit
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowDistributionReporting
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesDistributionReport)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesDistributionReport)}
                checked={!!store.active.allowDistributionReporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherDistributionChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowDistributionReporting
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowDistributionV2Reporting
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesDistributionV2Report)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesDistributionV2Report)}
                checked={!!store.active.allowDistributionV2Reporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherDistributionV2Changed(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowDistributionV2Reporting
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowStartDistributionReporting
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesStartDistributionReport)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesStartDistributionReport)}
                checked={!!store.active.allowStartDistributionReporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherStartDistributionChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowStartDistributionReporting
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowStartDistributionContentReporting
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesStartDistributionContentReport)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesStartDistributionContentReport)}
                checked={!!store.active.allowStartDistributionContentReporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherStartDistributionContentChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowStartDistributionContentReporting
                )}
              />
            )}
            {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowNativeReporting) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesNativeReport)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesNativeReport)}
                checked={!!store.active.allowNativeReporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherNativeChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowNativeReporting
                )}
              />
            )}
            {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowSyndicationReporting) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesSyndicationReport)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesSyndicationReport)}
                checked={!!store.active.allowSyndicationReporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherSyndicationChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowSyndicationReporting
                )}
              />
            )}
            {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowSelectTierReporting) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesSelectTierReport)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesSelectTierReport)}
                checked={!!store.active.allowSelectTierReporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherSelectTierChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowSelectTierReporting
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.publisherAllowedFeaturesPropertyAutoApproved
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesPropertyAutoApproved)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesPropertyAutoApproved)}
                checked={!!store.active.propertyAutoApproved}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherPropertyAutoChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null
                    ? completeAccess
                    : publisherAccessPermissions.publisherAllowedFeaturesPropertyAutoApproved
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowPaidSearchReportingApi
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesPaidSearchReportingApi)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesPaidSearchReportingApi)}
                checked={!!store.active.allowPaidSearchReportingApi}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherPSReportingAPIChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowPaidSearchReportingApi
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowPaidSearchReportingDashboard
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesPaidSearchDashboard)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesPaidSearchDashboard)}
                checked={!!store.active.allowPaidSearchReportingDashboard}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherPSDashboardChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowPaidSearchReportingDashboard
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowDynamicPropertyRegistration
            ) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesDynamicSites)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesDynamicSites)}
                checked={!!store.active.allowDynamicPropertyRegistration}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherDynamicPropertyRegistrationChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowDynamicPropertyRegistration
                )}
              />
            )}
            {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowMediationReporting) && (
              <Checkbox
                label={formatMessage(messages.publisherAllowedFeaturesMediationReport)}
                ariaLabel={formatMessage(messages.publisherAllowedFeaturesMediationReport)}
                checked={!!store.active.allowMediationReporting}
                styles={this._classNames.subComponentStyles.checkbox}
                onChange={(_, value) => onFormPublisherMediationChanged(value)}
                disabled={this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.allowMediationReporting
                )}
              />
            )}
            {hasReadAccess(
              publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.enableDefaultTythonV2Reporting
            ) &&
              store.active.publisherTier === PublisherTier.SelfServe3rdParty && (
                <Checkbox
                  label={formatMessage(messages.publisherEnableDefaultTythonV2Report)}
                  ariaLabel={formatMessage(messages.publisherEnableDefaultTythonV2Report)}
                  checked={!!store.active.enableDefaultTythonV2Reporting}
                  styles={this._classNames.subComponentStyles.checkbox}
                  onChange={(_, value) => onFormPublisherEnableDefaultTythonV2Changed(value)}
                  disabled={this._isDisabled(
                    this.props.mode,
                    publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.enableDefaultTythonV2Reporting
                  )}
                />
              )}
          </FormItem>
          {hasReadAccess(publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.ipRanges) && (
            <FormItem formWidth={660}>
              <FormLabel labelContent={formatMessage(messages.publisherIPAddress)} noInfoButton={true} intl={this.props.intl} />

              {this._IPGrid(publisherAccessPermissions)}
            </FormItem>
          )}
        </>
      );
    };

    private _createAccount = (publisherAccessPermissions: PublisherAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();
      const CURRENCY = CURRENCIES_LANGUAGE_MAPPING[getAppStore().locale];

      return (
        <>
          <span>{formatMessage(messages.createAnAccountInfo)}</span>
          <FormItem formWidth={440}>
            <TextField
              label={formatMessage(messages.accountName)}
              ariaLabel={formatMessage(messages.accountName)}
              required={true}
              errorMessage={store.errors.accountName}
              onChange={(_, value) => onFormPublisherAccountName(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.newAccount
              )}
            />
          </FormItem>
          <FormItem>
            <ComboBox
              useComboBoxAsMenuWidth={true}
              autoComplete="on"
              label={formatMessage(AppMessages.language)}
              options={this._createLanguageDropDown(store.active.accountAvailableLanguages!)}
              selectedKey={store.active.accountLanguage}
              required={true}
              errorMessage={store.errors.accountLanguage}
              onChange={(_, value) => onFormPublisherAccountLanguage(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.newAccount
              )}
              tabIndex={
                this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.newAccount
                )
                  ? -1
                  : undefined
              }
            />
          </FormItem>
          <FormItem>
            <ComboBox
              useComboBoxAsMenuWidth={true}
              autoComplete="on"
              label={formatMessage(AppMessages.countryOrRegion)}
              options={this._createCountryDropDown(store.active.availableCountries!)}
              selectedKey={store.active.accountCountry}
              required={true}
              errorMessage={store.errors.accountCountry}
              onChange={(_, value) => onFormPublisherAccountCountry(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.newAccount
              )}
              tabIndex={
                this._isDisabled(
                  this.props.mode,
                  publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.newAccount
                )
                  ? -1
                  : undefined
              }
            />
          </FormItem>
          <FormItem>
            <TextField
              label={formatMessage(AppMessages.currency)}
              ariaLabel={formatMessage(AppMessages.currency)}
              required={true}
              value={store.active.accountCountry && CURRENCY[countryToCurrencyMap[store.active.accountCountry]]}
              placeholder=""
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.newAccount
              )}
              // onChange={(_, value) => onFormPublisherAccountCurrency(value!!)}
              errorMessage={store.errors.accountCurrency}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <Dropdown
              label={formatMessage(AppMessages.timezoneLabel)}
              ariaLabel={formatMessage(AppMessages.timezoneLabel)}
              required={true}
              options={this._createTimezoneDropDown(store.active.availableTimezones!)}
              defaultSelectedKey={store.active.accountTimezoneId}
              onChange={(_, value) => onFormPublisherAccountTimezoneChanged(value!)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.newAccount
              )}
            />
          </FormItem>
        </>
      );
    };

    private _createIPAddress = (
      data: IPRange[] | null,
      mode: FormState,
      publisherAccessPermissions: PublisherAccessPermissions | null
    ): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();

      return (
        <DetailPage
          titleLabel={formatMessage(mode === FormState.InPageEdit ? messages.IPAddressEditTitle : messages.IPAddressCreateTitle)}
          helpId={'pop_PCv4_IPAddressCreate'}
          submitLabel={formatMessage(mode === FormState.InPageEdit ? messages.IPAddressEditTitle : messages.IPAddressCreateTitle)}
          onSubmit={() => {
            onFormPublisherIpChanged(data ? data : [], mode);
          }}
          onCancel={() => {
            onPanelCleared();
            onPanelOpenChanged(false);
          }}
          saving={getAppStore().saving}
          pageMode={mode}
        >
          <FormItem formWidth={440}>
            <TextField
              label={formatMessage(messages.IPAddressRangeName)}
              ariaLabel={formatMessage(messages.IPAddressRangeName)}
              required={true}
              value={store.active.ipAddressName}
              errorMessage={store.errors.ipAddressName}
              onChange={(_, value) => onFormPublisherIpNameChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.ipRanges
              )}
            />
          </FormItem>
          <FormItem>
            <TextField
              label={formatMessage(messages.IPAddressMinimum)}
              ariaLabel={formatMessage(messages.IPAddressMinimum)}
              required={true}
              value={store.active.minIPAddress}
              errorMessage={store.errors.minIPAddress}
              onChange={(_, value) => onFormPublisherMinimumIpChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.ipRanges
              )}
            />
          </FormItem>
          <FormItem>
            <TextField
              label={formatMessage(messages.IPAddressMaximum)}
              ariaLabel={formatMessage(messages.IPAddressMaximum)}
              required={true}
              value={store.active.maxIPAddress}
              errorMessage={store.errors.maxIPAddress}
              onChange={(_, value) => onFormPublisherMaximumIpChanged(value)}
              disabled={this._isDisabled(
                this.props.mode,
                publisherAccessPermissions === null ? completeAccess : publisherAccessPermissions.ipRanges
              )}
            />
          </FormItem>
        </DetailPage>
      );
    };

    private _IPGrid = (publisherAccessPermissions: PublisherAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();
      const appStore = getAppStore();

      const _gridDefaultColumns: IDataGridColumn[] = [
        { columnName: 'name', friendlyName: 'Name', isVisible: true },
        { columnName: 'minIPAddress', friendlyName: 'Minimum IP address', isVisible: true },
        { columnName: 'maxIPAddress', friendlyName: 'Maximum IP address', isVisible: true },
      ];
      const _commandBarProps: IDataGridCommandBarProps = {
        items: [
          {
            key: 'new',
            name: formatMessage(messages.publisherIPAddressRange),
            iconProps: {
              iconName: 'Add',
              ariaLabel: 'Add',
            },
            onClick: () => {
              this.setState({ ipPanelFormState: FormState.InPageCreate });
              onPanelOpenChanged(true);
            },
            selectionModes: [DataGridSelectionMode.None],
            ariaDescription: formatMessage(gridPageMessages.createCmdbtnAriaDesc, {
              label: formatMessage(messages.publisherIPAddressRange),
            }),
          },
          {
            key: 'edit',
            name: formatMessage(AppMessages.edit),
            iconProps: {
              iconName: 'Edit',
              ariaLabel: 'Edit',
            },
            onClick: () => {
              const item = this._getSelectionItems();
              if (item && item[0]) {
                onFormPublisherIpNameChanged(item[0].name!.toString());
                onFormPublisherMinimumIpChanged(item[0].minIPAddress);
                onFormPublisherMaximumIpChanged(item[0].maxIPAddress);
              }
              this.setState({ ipPanelFormState: FormState.InPageEdit });
              onPanelOpenChanged(true);
            },
            selectionModes: [DataGridSelectionMode.Single],
            ariaDescription: formatMessage(gridPageMessages.editCmdBtnAriaDesc),
          },
          {
            key: 'delete',
            name: formatMessage(AppMessages.delete),
            onClick: () => {
              const item = this._getSelectionItems();
              onFormPublisherIpChanged(item ? item : [], FormState.Delete);
            },
            iconProps: {
              iconName: 'Delete',
              ariaLabel: 'Delete',
            },
            selectionModes: [DataGridSelectionMode.Single],
            ariaDescription: formatMessage(gridPageMessages.deleteCmdBtnAriaDesc),
          },
        ],
      };
      return (
        <div className={this._classNames.ipGrid}>
          <DataGrid
            intl={this.props.intl}
            helpInfo={appStore.helpInfo}
            componentRef={this._datagrid}
            commandBarProps={_commandBarProps}
            dataIsLoading={false}
            detailListProps={{
              items: store.active.ipAddressGrid !== undefined ? store.active.ipAddressGrid : [],
              columns: _gridDefaultColumns,
            }}
          />
        </div>
      );
    };

    private _getSelectionItems() {
      if (this._datagrid.current) {
        const currentSelection = this._datagrid.current.getSelection();
        const selectedIndices = currentSelection.getSelectedIndices();
        const items = currentSelection.getItems();

        const selectedItems: IPRange[] = [];
        selectedIndices.map((selectedIndex) => selectedItems.push(items[selectedIndex] as IPRange));
        return selectedItems;
      }
      return null;
    }

    private _createCountryDropDown(countries: string[]): IComboBoxOption[] {
      const availableCountrys: IComboBoxOption[] = [];
      const countriesWithLocalLang = COUNTRIES_LANGUAGE_MAPPING[getAppStore().locale];

      if (countries) {
        countries.forEach((countryCode) => {
          if (countriesWithLocalLang[countryCode]) {
            availableCountrys!.push({
              key: countryCode,
              text: countriesWithLocalLang[countryCode],
            });
          }
        });
      }
      availableCountrys.sort((a, b) => {
        const firstValue = a.text;
        const secondValue = b.text;

        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      });
      return availableCountrys;
    }

    private _createTimezoneDropDown(timezones: number[]): IDropdownOption[] {
      const availableTimezones: IDropdownOption[] = [];
      const TIMEZONE = TIMEZONE_LANGUAGE_MAPPING[getAppStore().locale];

      if (timezones) {
        timezones.forEach((timezoneId) => {
          if (TIMEZONE[timezoneId]) {
            availableTimezones!.push({
              key: timezoneId,
              text: TIMEZONE[timezoneId],
            });
          }
        });
      }

      return availableTimezones;
    }

    private _createStateOrProvinceDropDown(states: ISubGeography[]): IComboBoxOption[] {
      const availableStates: IComboBoxOption[] = [];
      if (states) {
        states.forEach((stateOrProvince) => {
          availableStates!.push({
            key: stateOrProvince.code,
            text: stateOrProvince.name,
          });
        });
      }
      availableStates.sort((a, b) => {
        const firstValue = a.text;
        const secondValue = b.text;

        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      });
      return availableStates;
    }

    private _createLanguageDropDown(languages: string[]): IComboBoxOption[] {
      const availableLanguages: IComboBoxOption[] = [];
      const LANGUAGES = LANGUAGES_LANGUAGE_MAPPING[getAppStore().locale];

      if (languages) {
        languages.forEach((languageCode) => {
          if (LANGUAGES[languageCode]) {
            availableLanguages!.push({
              key: languageCode,
              text: LANGUAGES[languageCode],
            });
          }
        });
      }

      return availableLanguages;
    }

    private _onSubmit = (mode: FormState, publisherId?: string): void => {
      this.setState({ initializeForm: false });
      if (mode === FormState.Create) {
        onFormCreated(this.props.history);
      } else if (mode === FormState.Edit) {
        onFormEditted(this.props.history);
      }
    };

    private _onCancel = (publisherId?: string): void => {
      this.setState({ initializeForm: false });
      onFormCancelled();
      const returnUrl = createReturnUrl(RouteName.publisher, publisherId);
      onNavigateBack(this.props.history, returnUrl);
    };

    private _getPublisherAccessPermission(): PublisherAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.Publisher) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }

    private _isDisabled(mode: FormState, permission: number): boolean {
      if (mode === FormState.Create || mode === FormState.InPageCreate) {
        return false;
      }

      return !hasUpdateAccess(permission);
    }
  }
);
