import { mutator } from 'satcheljs';
import { getAppStore } from '../../../../@data';
import { clearStore, setData, setSearchParams, showMoreLoading } from '../actions/onSearchTriggered';
import { ISearchResultInfo } from '../store/schema/IGlobalSearchResultPageState';
import { getStore } from '../store/store';

mutator(setData, msg => {
  const store = getStore();
  const appStore = getAppStore();

  msg.results.forEach((searchResultInfo: ISearchResultInfo, key: string) => {
    const resultInfo = store.results.get(key);
    if (resultInfo) {
      resultInfo.pageToken = searchResultInfo.pageToken;
      resultInfo.resultData = resultInfo.resultData.concat(searchResultInfo.resultData);
      store.results.set(key, resultInfo);
    } else {
      store.results.set(key, searchResultInfo);
    }
  });
  msg.resourceTypes.forEach((value: number, key: string) => {
    if (value) {
      store.resourceTypes.set(key, value);
    }
  });
  appStore.loading = false;
});

mutator(setSearchParams, msg => {
  const store = getStore();
  store.searchQuery = msg.query;
  store.sourceRef = msg.sourceRef;
});

mutator(showMoreLoading, msg => {
  const store = getStore();
  store.isShowMoreLoading.set(msg.resourceType, msg.value);
});

mutator(clearStore, () => {
  getStore().resourceTypes.clear();
  getStore().isShowMoreLoading.clear();
  getStore().results.clear();
  getStore().searchQuery = '';
});
