import { orchestrator } from 'satcheljs';
import { NotificationLabel, fetchBackendNotifications, getAppStore, mappingReasonsToLabels, onNotificationPanelItemsChanged } from '..';
import { Log } from '../../../logging/src';
import { INotificationCardItem } from '../../components/NotificationCard';
import { SESSION_DISMISSED_NOTIFICATIONS_BACKEND, SESSION_NOTIFICATIONS_UI } from '../../constants/AppConstants';
import { getBackendNotifications } from '../services/NotificationService';
import messages from './../../NotificationsPanel.Types.messages';

orchestrator(fetchBackendNotifications, async ({ intl }) => {
  const userContext = getAppStore().userContext;
  const publisher = getAppStore().publisher;
  const account = getAppStore().account;
  const dismissedNotifications = sessionStorage.getItem(SESSION_DISMISSED_NOTIFICATIONS_BACKEND);

  let dismissedBackendNotificationInUI: string[] = [];
  if (dismissedNotifications) {
    dismissedBackendNotificationInUI = JSON.parse(dismissedNotifications);
  }
  const { formatMessage } = intl;
  try {
    let notifications = await getBackendNotifications(publisher?.id!, account?.id!, userContext!);

    const existingNotifications = sessionStorage.getItem(SESSION_NOTIFICATIONS_UI);

    let existingNotificationsCollection: INotificationCardItem[] = [];
    if (existingNotifications) {
      existingNotificationsCollection = JSON.parse(existingNotifications);
    }
    if (dismissedBackendNotificationInUI && dismissedBackendNotificationInUI.length > 0) {
      notifications = notifications.filter((item) => !dismissedBackendNotificationInUI.includes(item.notificationId));
    }
    notifications.forEach((notification) => {
      const intlNotificationTitle = notification.notificationLabel + 'Title';
      const intlNotificationContent = notification.notificationLabel + 'Content';
      const notificationItem: INotificationCardItem = {
        id: notification.notificationId,
        title: messages[intlNotificationTitle] ? formatMessage(messages[intlNotificationTitle]) : notification.notificationTitle,
        content: messages[intlNotificationContent] ? formatMessage(messages[intlNotificationContent]) : notification.notificationContent,
        severity: notification.severity,
        timestamp: notification.lastModifiedDTime,
        notificationLabel: notification.notificationLabel,
        isUIGenerated: false,
      };
      if (notification.notificationLabel === NotificationLabel.PolicyUpdate) {
        const intlNotificationPrimaryActionLabel = notification.notificationLabel + 'PrimaryActionLabel';
        notificationItem.primaryActionText = messages[intlNotificationPrimaryActionLabel]
          ? formatMessage(messages[intlNotificationPrimaryActionLabel])
          : notification.notificationLabel;
      }
      if (notification.notificationLabel === NotificationLabel.PaymentFailed) {
        // get the property bag a dictionary of <string, string>
        const propertyBag = notification.propertyBag;
        const errorCode = propertyBag['errorCode'];
        const errorReason = propertyBag['errorReason'];

        // get the localized error message\reason\content
        // replace all the spaces in errorcode with empty string
        const errorCodeStr = errorCode.toString().replace(/\s/g, '');
        const intlNotificationErrorReason = notification.notificationLabel + 'Reason_' + errorCodeStr;
        const intlNotificationErrorReasonTitle = notification.notificationLabel + 'Title_' + errorCodeStr;
        if (messages[intlNotificationErrorReasonTitle]) {
          notificationItem.title = formatMessage(messages[intlNotificationErrorReasonTitle]);
        }
        notificationItem.content = messages[intlNotificationErrorReason]
          ? formatMessage(messages[intlNotificationErrorReason])
          : errorReason;

        notificationItem.notificationLabel = mappingReasonsToLabels[intlNotificationErrorReason]
          ? mappingReasonsToLabels[intlNotificationErrorReason]
          : notificationItem.notificationLabel;

        // could_not_process could be for 2 reasons, Stripe or bank
        if (errorCodeStr === 'could_not_process' && errorReason.toString().contains('Stripe')) {
          const reason = notification.notificationLabel + 'ReasonStripe_' + errorCodeStr;
          if (messages[reason]) {
            notificationItem.content = messages[reason];
            notificationItem.notificationLabel = mappingReasonsToLabels[reason];
          }
        }

        // no_account could be for 2 reasons, not located or invalid
        if (errorCodeStr === 'no_account' && errorReason.toString().contains('located')) {
          const reason = notification.notificationLabel + 'ReasonLocate_' + errorCodeStr;
          if (messages[reason]) {
            notificationItem.content = messages[reason];
            notificationItem.notificationLabel = mappingReasonsToLabels[reason];
          }
        }
      }

      if (notification.notificationActions && notification.notificationActions.length > 0) {
        const primaryAction = notification.notificationActions[0];
        const intlNotificationPrimaryActionLabel = notification.notificationLabel + 'PrimaryActionLabel';
        notificationItem.primaryActionText = messages[intlNotificationPrimaryActionLabel]
          ? formatMessage(messages[intlNotificationPrimaryActionLabel])
          : primaryAction.text;
        notificationItem.primaryActionLink = primaryAction.url;
        if (notification.notificationActions.length > 1) {
          const secondaryAction = notification.notificationActions[1];
          const intlNotificationSecondaryActionLabel = notification.notificationLabel + 'SecondaryActionLabel';
          notificationItem.secondaryActionText = messages[intlNotificationSecondaryActionLabel]
            ? formatMessage(messages[intlNotificationSecondaryActionLabel])
            : secondaryAction.text;
          notificationItem.secondaryActionLink = secondaryAction.url;
        }
      }
      if (
        notification.notificationLabel === NotificationLabel.ClarityLinkDeclined ||
        notification.notificationLabel === NotificationLabel.ClarityLinkExpired
      ) {
        // get the property bag a dictionary of <object, object>
        if (notification.propertyBag) {
          const propertyBag = notification.propertyBag;
          const clarityId = propertyBag['clarityId'];
          const propertyName = propertyBag['propertyName'];

          notificationItem.title = messages[intlNotificationTitle]
            ? formatMessage(messages[intlNotificationTitle], { projectId: clarityId })
            : notification.notificationTitle;
          notificationItem.intlTitleMessageValues = { projectId: clarityId };

          notificationItem.content = messages[intlNotificationContent]
            ? formatMessage(messages[intlNotificationContent], { projectId: clarityId, propertyName: propertyName })
            : notification.notificationTitle;
          notificationItem.intlContentMessageValues = { projectId: clarityId, propertyName: propertyName };

          notificationItem.propertyBag = propertyBag;
        }
      }
      existingNotificationsCollection.push(notificationItem);
    });

    onNotificationPanelItemsChanged(existingNotificationsCollection);
  } catch (error) {
    Log.error(JSON.stringify(error));
  }
});
