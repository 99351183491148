import { orchestrator } from 'satcheljs';
import { onAccountChanged } from '../actions';
import { StorageAccountKey } from '../store/store';

orchestrator(onAccountChanged, async msg => {
  if (!msg.account) {
    localStorage.removeItem(StorageAccountKey);
  } else {
    localStorage.setItem(StorageAccountKey, JSON.stringify(msg.account));
  }
});