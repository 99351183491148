import { defineMessages } from 'react-intl';

export default defineMessages({
  showRows: {
    id: 'showRows',
    defaultMessage: 'Show rows:',
  },
  of: {
    id: 'of',
    defaultMessage: 'of',
  },
  firstPage: {
    id: 'firstPage',
    defaultMessage: 'First page',
  },
  prevPage: {
    id: 'prevPage',
    defaultMessage: 'Previous page',
  },
  lastPage: {
    id: 'lastPage',
    defaultMessage: 'Last page',
  },
  nextPage: {
    id: 'nextPage',
    defaultMessage: 'Next page',
  },
});
