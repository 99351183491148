import { defineMessages } from 'react-intl';

export const notificationMessages = defineMessages({
  setupPaymentButton: {
    id: 'payment.setup.notification.button',
    defaultMessage: 'Set up payments',
  },
  setupPaymentNotificationBoldedText: {
    id: 'payment.setup.notification.bolded.text',
    defaultMessage: 'You’ll need to set up your payment to start earning with {appName}.',
  },
  setupPaymentNotificationText: {
    id: 'payment.setup.notification.text',
    defaultMessage: `Add your payment details to get started.`,
  },
  loadXPayContainerFailed: {
    id: 'payment.xPay.container.load.fail',
    defaultMessage: 'Failed to load xPay container, please try again later.',
  },
});

export const paymentCheerMessages = defineMessages({
  cheerMessageTitle: {
    id: 'payment.cheerMessage.title',
    defaultMessage: ' Your payout is ready.',
  },
  cheerMessageContent: {
    id: 'payment.cheerMessage.content',
    defaultMessage: 'You’ll need to set up your payment information to receive your payout. Add your payment details to get started.',
  },
  cheerMessageButtonAction: {
    id: 'payment.cheerMessage.button.action',
    defaultMessage: `Set up payments`,
  },
  cheerMessageButtonCancel: {
    id: 'payment.cheerMessage.button.cancel',
    defaultMessage: `Remind me later`,
  },
  cheerMessageCancelArialabel: {
    id: 'payment.cheerMessage.button.cancel.ariaLabel',
    defaultMessage: 'Close payment cheer message',
  },
});
