import { ChannelType } from '../enums';
import { Model } from './Model';

export class Channel extends Model {
  public apiPath = undefined;
  public className = 'channels';
  public accountId?: number;
  public associatedAdUnitIds?: number[];
  public associatedFilterIds?: number[];
  public associatedAdUnitCount?: number;
  public associatedFilterCount?: number;
  public type?: ChannelType = ChannelType.CUSTOM;
  public defaultCategoryIds?: number[];
  public defaultKeywords?: string[];
  public urls?: string[] = [];
  public eTag?: string;
}
