import { EntityType } from '../enums';
import { Model } from './Model';

export interface IRejectionCode {
  rejectionCodes: string;
  appealDocuments?: string;
}

export class EvaluationStatus extends Model {
  public apiPath = undefined;
  public className = 'entityEvaluation';
  public entityId: number;
  public approvalStatus: EntityEvaluationStatus;
  public properties?: IRejectionCode;
  public entityType: EntityType;
}

export class PublisherEvaluationStatus extends EvaluationStatus {
  public entityType: EntityType.Publisher;
}

export class PropertyEvaluationStatus extends EvaluationStatus {
  public entityType: EntityType.Property;
}

export enum EntityEvaluationStatus {
  Pending = 'Pending',
  InReview = 'UnderConsideration',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export enum EntityEvaluationErrorCode {
  'P001' = 1, // TST failure
  'P002' = 2, // OVet failure
  'D001' = 3, // PDE failure
  'U001' = 4, // Unknown or default
  'R001' = 5, // ATS manual review failed

  // CRM Manual Overrides
  'CRM_MO_001' = 6, // Manual override to Review Case Terminated (Unappealable)
  'CRM_MO_002' = 7, // Manual Deactivation of Publisher in CRM (Unappealable)

  'OV_DNE_E000' = 100, // OneVet DNE failed (Unappealable)
  'OV_TSS_E000' = 200, // OneVet TSS failed (Unappealable)

  'OV_EV_E000' = 300, // OneVet Email Verification failed

  'OV_BV_E000' = 400, // OneVet Business Verification failed - UnAppealable
  'OV_BV_E001' = 401, //  OneVet Business Verification failed - Appealable

  'OV_DV_E000' = 500, // OneVet Domain Verification failed - UnAppealable
  'OV_DV_E001' = 501, // OneVet Domain Verification failed - Appealable

  'OV_TV_E000' = 600, // OneVet Token Verification failed
}

export const appealableRejectionCodes = [EntityEvaluationErrorCode['OV_BV_E001'], EntityEvaluationErrorCode['OV_DV_E001']];
export const unappealableRejectionCodes = [
  EntityEvaluationErrorCode['P001'],
  EntityEvaluationErrorCode['P002'],
  EntityEvaluationErrorCode['D001'],
  EntityEvaluationErrorCode['U001'],
  EntityEvaluationErrorCode['R001'],
  EntityEvaluationErrorCode['CRM_MO_001'],
  EntityEvaluationErrorCode['CRM_MO_002'],
  EntityEvaluationErrorCode['OV_DNE_E000'],
  EntityEvaluationErrorCode['OV_TSS_E000'],
  EntityEvaluationErrorCode['OV_EV_E000'],
  EntityEvaluationErrorCode['OV_BV_E000'],
  EntityEvaluationErrorCode['OV_DV_E000'],
  EntityEvaluationErrorCode['OV_TV_E000'],
];

export enum EvidenceDocumentType {
  OV_BV_001, // OneVet Business Verification - Formation documents, such as articles of incorporation, partnership deed.
  OV_BV_002, // OneVet Business Verification - Franchise or agency appointment letters.
  OV_BV_003, // OneVet Business Verification - Government issued letter, license, registration, or certificate.
  OV_BV_004, // OneVet Business Verification - Lease or tenancy documents.
  OV_BV_005, // OneVet Business Verification - Letter or statement from a financial institution or a utility company.
  OV_BV_006, // OneVet Business Verification - Record on a Government registry website (site/link must be displayed).
  OV_BV_007, // OneVet Business Verification - Stock exchange filings or tax filing records.

  OV_DV_001, // OneVet Domain Verification (Email domain) - Assignment letter from an authorized representative of the organization.
  OV_DV_002, // OneVet Domain Verification (Email domain) - Domain ownership records, such as Whois.
  OV_DV_003, // OneVet Domain Verification (Email domain) - Domain purchase invoices or registry confirmation records.
  OV_DV_004, // OneVet Domain Verification (Email domain) - Website showing name, address, contact information and domain of the org
}
