import { createStore } from 'satcheljs';
import { EntityType } from '../../../../../../@data';
import { DEFAULT_CURRENCY_CODE, LOCAL_STORAGE_LOCALE } from '../../../../../../constants/AppConstants';
import { ISettingsViewState } from './schema/ISettingsViewState';

export const getStore = createStore<ISettingsViewState>('ISettingsViewState', {
  active: {
    entityType: EntityType.Publisher,
    notificationPreferences: {
      billingAlerts: true,
      servingStatus: true,
      profileReview: true,
      invitedUsers: true,
    },
    systemPreferences: {
      locale: localStorage.getItem(LOCAL_STORAGE_LOCALE) || undefined,
      currency: DEFAULT_CURRENCY_CODE,
    },
  },
});
