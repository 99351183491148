import { Icon, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { getClassNames } from './Step.style';
import { IStepProps } from './Step.types';
import { StepperDirection } from './Stepper.types';

export const Step = injectIntl<IStepProps>(function StepComponent({
  label,
  styleProps,
  index,
  totalSteps,
  updateStep,
  goToStep,
  isSelected,
  isMarked,
  isLastChild,
  direction,
}) {
  const {
    stepBlock,
    circleWrapper,
    barStyle,
    circle,
    circleSelected,
    circleMarked,
    circleLabel,
    stepTitle,
    stepTitleSelected,
  } = mergeStyleSets(getClassNames({ direction }), styleProps);

  const updateStepClick = (e) => {
    updateStep?.(e);
    goToStep?.(index);
  };

  const getAriaLabel = () => {
    return `${label} - step ${index + 1} of ${totalSteps}, ${isMarked ? 'completed' : 'uncompleted'}`;
  };

  return (
    <button className={stepBlock} role="presentation" onClick={updateStepClick} aria-label={getAriaLabel()}>
      <div className={circleWrapper}>
        <div className={direction === StepperDirection.Vertical ? circleLabel : ''}>
          <Icon className={isSelected ? circleSelected : isMarked ? circleMarked : circle} iconName="CheckMark" />
          <div className={isLastChild ? '' : barStyle} />
        </div>
        {direction === StepperDirection.Vertical && <div className={`${stepTitle} ${isSelected && stepTitleSelected}`}>{label}</div>}
      </div>
      {direction === StepperDirection.Horizontal && <div className={`${stepTitle} ${isSelected && stepTitleSelected}`}>{label}</div>}
    </button>
  );
});
