import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing, IPubcenterTheme } from '../../../../../theme';
import { INotificationsPanelClassNames } from './NotificationsPanel.types';

export const getClassNames = (): INotificationsPanelClassNames => {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing as IPubcenterSpacing;
  const WIDTH = '456px';
  const { size16 } = theme.fonts as IPubCenterFonts;
  return mergeStyleSets({
    root: {
      position: 'relative',
      width: WIDTH,
      maxWidth: WIDTH,
    },
    dismissAll: {
      marginRight: spacing.size2,
    },
    emptySection: {
      textAlign: 'center',
      flexDirection: 'column',
    },
    textContent: {
      marginTop: spacing.size8,
      marginBottom: '0px',
      fontSize: size16.fontSize,
    },
    panelParent: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    img: {
      width: '160px',
      height: '160px',
      marginBottom: spacing.size8,
    },
  });
};
