import { defineMessages } from 'react-intl';

export default defineMessages({
  accountPrefix: {
    id: 'signup.accountType.accountPrefix',
    defaultMessage: 'You’re signing up with ',
  },
  accountPost: {
    id: 'signup.accountType.accountPost',
    defaultMessage: '. Need to sign in with a different Microsoft account?',
  },
  switchAccount: {
    id: 'signup.accountType.switchAccount',
    defaultMessage: 'Switch now',
  },
});
