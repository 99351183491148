import { action } from 'satcheljs';
import { LifeCycleStatus } from '../../../../../@data';
import { IDataGridColumn } from '../../../../../layout/GridPageV2';
import { BlockAdsData, SAMBlockSummaryData } from '../store/schema/models';

export const onBlockAdsDataChanged = action('onBlockAdsDataChanged', (data?: BlockAdsData[]) => ({ data }));

export const onSAMBlockAdsDataChanged = action('onSAMBlockAdsDataChanged', (data?: SAMBlockSummaryData[]) => ({ data }));

export const onSAMBlockSummaryViewImagesClicked = action('onSAMBlockSummaryViewImagesClicked', (item: SAMBlockSummaryData) => ({
  item,
}));

export const onBlockedImagesExportClicked = action('onBlockedImagesExportClicked', (columns?: IDataGridColumn[]) => ({ columns }));

export const onBlockedAdsExportClicked = action('onBlockedAdsExportClicked', (columns?: IDataGridColumn[]) => ({ columns }));

export const onBlockAdsItemStatusChanged = action('onBlockAdsItemStatusChanged', (data: BlockAdsData, status: LifeCycleStatus) => ({
  data,
  status,
}));

export const onSAMBlockAdsItemStatusChanged = action(
  'onSAMBlockAdsItemStatusChanged',
  (data: SAMBlockSummaryData, status: LifeCycleStatus) => ({
    data,
    status,
  })
);

export const onRefreshBlockSummary = action('onRefreshBlockSummary', () => ({}));

export const onBlockSummaryStartDateChanged = action('onBlockSummaryStartDateChanged', (startDate?: Date) => ({ startDate }));

export const onBlockSummaryEndDateChanged = action('onBlockSummaryEndDateChanged', (endDate?: Date) => ({ endDate }));
