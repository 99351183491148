import { defineMessages } from 'react-intl';

export default defineMessages({
  errorSellerIdDoesNotMatchApp: {
    id: 'appInventoryForm.errorSellerIdDoesNotMatchApp',
    defaultMessage: 'SellerId does not match provided App.',
  },
  errorPropertyNameAlreadyExists: {
    id: 'appInventoryForm.errorPropertyNameAlreadyExists',
    defaultMessage: 'An inventory by this name already exists',
  },
  errorInventoryAppUrlAlreadyExists: {
    id: 'appInventoryForm.errorPropertyNameAlreadyExists',
    defaultMessage: 'An inventory by this url already exists',
  },
  processNameCreate: {
    id: 'appInventoryForm.processNameCreate',
    defaultMessage: 'Create app',
  },
  processNameEdit: {
    id: 'appInventoryForm.processNameEdit',
    defaultMessage: 'Edit app',
  },
  CreateSiteStep: {
    id: 'appInventoryForm.CreateSiteStep',
    defaultMessage: 'App details',
  },
  AdsTxtCreationStep: {
    id: 'appInventoryForm.AdsTxtCreationStep',
    defaultMessage: 'Ads.txt creation',
  },
  AdsTxtCreationStepTitle: {
    id: 'appInventoryForm.AdsTxtCreationStepTitle',
    defaultMessage: 'Create ads.txt file',
  },
  GetCodeAndFinishStep: {
    id: 'appInventoryForm.GetCodeAndFinishStep',
    defaultMessage: 'Get code',
  },
  appNameFieldLabel: {
    id: 'appInventoryForm.appNameFieldLabel',
    defaultMessage: 'App Name',
  },
  appSellerIdFieldLabel: {
    id: 'appInventoryForm.appSellerIdFieldLabel',
    defaultMessage: 'Seller ID',
  },
  appUrlFieldLabel: {
    id: 'appInventoryForm.appUrlFieldLabel',
    defaultMessage: 'App Url',
  },
  appNameFieldPlaceholder: {
    id: 'appInventoryForm.appNameFieldPlaceholder',
    defaultMessage: 'Enter the name of your App',
  },
  appSellerIdFieldPlaceholder: {
    id: 'appInventoryForm.appSellerIdFieldPlaceholder',
    defaultMessage: 'Enter the name of your windows store seller id',
  },
  appUrlFieldPlaceholder: {
    id: 'appInventoryForm.appUrlFieldPlaceholder',
    defaultMessage: 'Enter app URL',
  },
  appPlatformInfo: {
    id: 'appInventoryForm.appPlatformInfo',
    defaultMessage: 'Currently Ads SDK supports only Windows UWP framework. Future support for additional frameworks will be added soon.',
  },
  appPlatformWindowsUWPLabel: {
    id: 'appInventoryForm.appPlatformWindowsUWPLabel',
    defaultMessage: 'Windows UWP',
  },
  appPlatformTitleLabel: {
    id: 'appInventoryForm.appPlatformTitleLabel',
    defaultMessage: 'App Platform',
  },
  appPlatformConnectLiveAppInfo: {
    id: 'appInventoryForm.appPlatformConnectLiveAppInfo',
    defaultMessage: 'Your app must be live in the Microsoft Store for us to serve ads. Please search your app using Store ID',
  },
  appPlatformConnectLiveAppTitleLabel: {
    id: 'appInventoryForm.appPlatformConnectLiveAppTitleLabel',
    defaultMessage: 'Connect your live app',
  },
  appSearchFieldPlaceholder: {
    id: 'appInventoryForm.appSearchFieldPlaceholder',
    defaultMessage: 'Search for your app by Store ID',
  },
  appAgreement: {
    id: 'appInventoryForm.appAgreement',
    defaultMessage: 'COPPA Agreement',
  },
  appAgreementSection1: {
    id: 'appInventoryForm.appAgreementSection1',
    defaultMessage:
      'In accordance with the Children’s Online Privacy Protection Act (COPPA) in the United States, we require all partners using Microsoft’s Monetize services to identify whether or not their apps are primarily directed toward children under the age of thirteen (13).',
  },
  appAgreementSection2: {
    id: 'appInventoryForm.appAgreementSection2',
    defaultMessage:
      'By clicking “Agree” below, partner: (i) represents that this app is not primarily directed at children under age thirteen (13), and (ii) agrees that this app will not be tagged as primarily child-directed.',
  },
  appAgreementCheckboxLabel: {
    id: 'appInventoryForm.appAgreementCheckboxLabel',
    defaultMessage: 'I agree',
  },
  getCodeTitle: {
    id: 'appInventoryForm.getCodeTitle',
    defaultMessage: 'Your app set-up is almost complete',
  },
  finishStepperTittle: {
    id: 'appInventoryForm.finishStepperTittle',
    defaultMessage: 'Finish',
  },
  finishPageExpectNextTitle: {
    id: 'appInventoryForm.finishPageExpectNextTitle',
    defaultMessage: 'What to expect next?',
  },
  finishPageExpectNextContent1: {
    id: 'appInventoryForm.finishPageExpectNextContent1',
    defaultMessage: 'Now that your app is set up, please ensure your have added app-ads.txt on your website for an unhindered ad delivery.',
  },
  finishPageExpectNextContent2: {
    id: 'appInventoryForm.finishPageExpectNextContent2',
    defaultMessage:
      'We will now review your ad to make sure it’s ready to show ads. App reviews typically take 7 business days. Ad serving is will be restricted until the review is complete.',
  },
  finishPageExpectNextContentHelpLink: {
    id: 'appInventoryForm.finishPageExpectNextContentHelpLink',
    defaultMessage: 'Learn more about app review process',
  },
  finishPageExpectNextTitle2: {
    id: 'appInventoryForm.finishPageExpectNextTitle2',
    defaultMessage: 'Next Steps',
  },
  finishPageCardTitle: {
    id: 'appInventoryForm.finishPageCardTitle',
    defaultMessage: 'Set up ad unit for your App',
  },
  finishPageActionButtonLabel: {
    id: 'appInventoryForm.finishPageActionButtonLabel',
    defaultMessage: 'Create App ad unit',
  },
  finishPageCardContent: {
    id: 'appInventoryForm.finishPageCardContent',
    defaultMessage: 'Set up ad placements for this app so that when your app is approved, you can start serving ads right away',
  },
  CreateSiteStepFormTitle: {
    id: 'appInventoryForm.CreateSiteStepFormTitle',
    defaultMessage: 'Add your App details',
  },
  loadingLabel: {
    id: 'appInventoryForm.loadingLabel',
    defaultMessage: 'Loading...',
  },
  coopaTnCInfo: {
    id: 'appInventoryForm.coopaTnCInfo',
    defaultMessage:
      'Partners may have other legal obligations under COPPA. Please review the FTC’s guidance and/or seek your own advice of counsel. Microsoft pubCenter tools are designed only to facilitate compliance and do not relieve partners of their legal obligations, including under COPPA.',
  },
  appPropertyInvalidUrl: {
    id: 'appInventoryForm.appPropertyInvalidUrl',
    defaultMessage: 'Invalid URL, no path or query parameters',
  },
});
