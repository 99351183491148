import { Icon } from '@fluentui/react';
import React, { ReactNode } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { injectIntlAndObserver } from '../../../../../../utils';
import { getClassNames } from './FAQCard.styles';

export interface IFAQProps {
  title: string;
  description: ReactNode;
  isOpen?: boolean;
  order?: number;
  setIsOpen?: (index: number) => void;
}

const FAQCard = injectIntlAndObserver<InjectedIntlProps & IFAQProps>(({ intl, ...props }) => {
  const { title, description, isOpen, order } = props;
  const { details, titleStyle, arrow, content } = getClassNames();

  return (
    <details className={details} open={isOpen}>
      <summary
        onClick={(e) => {
          props.setIsOpen?.(isOpen ? -1 : order ?? -1);
          e.preventDefault();
        }}
      >
        <Icon className={arrow} iconName={isOpen ? 'ChevronDown' : 'ChevronRight'} />
        <span className={titleStyle}>{title}</span>
      </summary>

      <div className={content}>{description}</div>
    </details>
  );
});

export default FAQCard;
