import { useEffect, useState } from 'react';
import { AdUnit, constructUrl, getAppStore, Publisher } from '../@data';

let isAdBlockerDetectRun = false;

// NOTE: Source: https://github.com/aruniverse/adblock-detect-react/blob/master/adblock-detect-react/src/hooks/useDetectAdBlock.ts
export const useDetectAdBlock = () => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);
  const { publisher } = getAppStore();

  // We use the pubcenter fetch adunits API endpoint to detect ad blockers
  // If the request is blocked then we assume an ad blocker is present
  useEffect(() => {
    if (isAdBlockerDetectRun) {
      return;
    }

    const url = constructUrl([new Publisher(publisher?.id)], AdUnit, new URLSearchParams([['extendedInfo', 'true']]));
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .catch(() => {
        setAdBlockDetected(true);
      })
      .finally(() => {
        isAdBlockerDetectRun = true;
      });
  }, [publisher]);

  return adBlockDetected;
};
