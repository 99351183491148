import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  MessageBar,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  mergeStyleSets,
} from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Model, getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged } from '../../@data';
import '../../@data/orchestrators';
import AppMessages from '../../App.messages';
import { FormTitle } from '../../components/Form';
import { IFilterDetails } from '../../components/GridFilter/GridFilter.types';
import { Main } from '../../components/Main/Main';
import { ZeroResults } from '../../components/ZeroResults/ZeroResults';
import { PAGINATION_PAGE_SIZES } from '../../constants/AppConstants';
import { injectIntlAndObserver } from '../../utils';
import { IGridV2Props, ISelectableRow, getClassNames } from './';
import { onGridPageV2Initialized } from './@data/actions/gridActions';
import './@data/mutatorActions';
import { onPageNumberChanged, onPageSizeChanged, setSearchWord, setSelectedItems } from './@data/mutatorActions';
import './@data/orchestrators';
import { getStore } from './@data/store/store';
import { RenderHeaderCheckbox, RenderRowCheckbox } from './components/CustomCheckbox';
import { CustomCommandBar } from './components/CustomCommandBar/CustomCommandBar';
import { Pagination } from './components/Pagination';
import { PaginationDisplay } from './components/Pagination/Pagination.types';
import { useSortableGridColumns } from './hooks';
import { usePagination } from './hooks/usePagination';
import { useSearchableGrid } from './hooks/useSearchableGrid';
import { useSelectionGrid } from './hooks/useSelectionGrid';
import { applyFilters } from './utils/FilteringUtils';

export const GridV2 = injectIntlAndObserver(function GridV2Component(props: IGridV2Props<Model>) {
  const {
    intl,
    pageTitle,
    notGridReadyLabel,
    isGridReady,
    isDataLoading,
    dataGridProps,
    commandBarProps,
    entity,
    styleProps,
    paginationProps,
    msgBarProps,
    children,
    bypassFiltrationAndSearching,
    bypassGridResetting,
  } = props;
  const { data, selectedRows, gridV2Columns, onColumnsChanged, onSelectionChanged, identifier, setData, hideDisableStyle } = dataGridProps;
  const rowIdentifier = identifier || 'id';
  const { dataQueryProps, customApplyFilterFn } = commandBarProps || {};
  const isSelectionDisabled = dataGridProps?.selectionMode === SelectionMode.none;
  const { root, titleContainer } = mergeStyleSets(getClassNames(), styleProps);

  const { filterMenuCalloutOpen, paginator } = getStore();
  const { helpInfo, publisher, account, locale } = getAppStore();

  const [gridRows, setGridRows] = useState<ISelectableRow[]>(
    data.map(
      (item) =>
        ({
          ...item,
          isSelected: selectedRows?.find((row) => row[rowIdentifier] === item[rowIdentifier])?.['isSelected'] || false,
        } as ISelectableRow)
    )
  );
  const [appliedFilters, setAppliedFilters] = useState<IFilterDetails[]>([]);
  const rowRef = useRef(null);

  const handleGridFilter = (filterConditions) => applyFilters(data, filterConditions, setAppliedFilters, customApplyFilterFn);
  const { gridColumns, handleColumnHeaderClick, changeColumnsVisibility } = useSortableGridColumns(
    gridV2Columns,
    gridRows,
    setGridRows,
    onColumnsChanged
  );
  const { handleSearch, searchWord } = useSearchableGrid(gridColumns, data, setSearchWord);
  const { selection, getKey } = useSelectionGrid(rowIdentifier, dataGridProps.selectionMode, setGridRows, onSelectionChanged);
  const { paginateData, initializePagination } = usePagination(gridRows, paginationProps);

  const removeAllSelectedItems = () => {
    setGridRows(gridRows.map((item) => ({ ...item, isSelected: false } as ISelectableRow)));
    setSelectedItems([]);
    selection.setAllSelected(false);
  };

  const resetGrid = () => {
    onPageNumberChanged(1);
    onPageSizeChanged(PAGINATION_PAGE_SIZES[0]);
    removeAllSelectedItems();
  };

  const defaultRenderZeroItemsComponent = () => {
    return (
      <ZeroResults
        showDefaultImage
        title={isGridReady ? dataGridProps?.noEntityFound && intl.formatMessage(dataGridProps.noEntityFound) : notGridReadyLabel}
      />
    );
  };

  const getVisibleColumns = () => gridColumns.filter((col) => col.isVisible);

  /**
   * replace tabindex=-1 to aria-hidden="true" for accessibility enhancements
   */
  useEffect(() => {
    if (rowRef.current) {
      const checkboxWrappers = document.querySelectorAll('div[role="checkbox"]');
      const headerRowWrapper = document.querySelector('div[role="row"][aria-label="List Header"]');
      if (checkboxWrappers.length) {
        for (let i = 0; i < checkboxWrappers.length; i++) {
          if (checkboxWrappers[i].hasAttribute('tabindex')) {
            checkboxWrappers[i].removeAttribute('tabindex');
          }
        }
      }
      if (headerRowWrapper && !headerRowWrapper.hasAttribute('aria-busy')) {
        headerRowWrapper.setAttribute('aria-busy', 'true');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowRef.current, gridRows]);

  useEffect(() => {
    onGridPageV2Initialized(data, entity, dataQueryProps);
  }, [dataQueryProps, data, entity]);

  useEffect(() => {
    if (bypassFiltrationAndSearching && bypassGridResetting) {
      return;
    }
    const filteredRowsUsingSearch = handleSearch(searchWord);
    const filteredRowsUsingFilter = applyFilters(filteredRowsUsingSearch, appliedFilters, setAppliedFilters, customApplyFilterFn, false);
    setGridRows(filteredRowsUsingFilter);
    initializePagination(filteredRowsUsingFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, appliedFilters, searchWord]);

  useEffect(() => {
    setGridRows(
      data.map(
        (item) =>
          ({
            ...item,
            isSelected: selectedRows?.find((row) => row[rowIdentifier] === item[rowIdentifier])?.['isSelected'] || false,
          } as ISelectableRow)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (bypassFiltrationAndSearching && bypassGridResetting) {
      return;
    }
    resetGrid();
    return () => resetGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, account]);

  return (
    <Main className={root}>
      {pageTitle && (
        <div className={titleContainer}>
          <FormTitle
            {...pageTitle}
            intl={intl}
            calloutContent={helpInfo}
            iconButtonOnClick={() => onHelpInfoCalloutOpened(pageTitle.helpId!, locale)}
            calloutOnDismiss={() => onHelpInfoChanged()}
            styles={pageTitle.styleProps}
          />
          {isDataLoading ? <Spinner /> : <></>}
        </div>
      )}

      {commandBarProps && (
        <CustomCommandBar
          history={props.history}
          location={props.location}
          match={props.match}
          entity={props.entity}
          columns={gridColumns}
          commandBarProps={commandBarProps}
          data={gridRows}
          setData={setData}
          cancelSelection={removeAllSelectedItems}
          handleSearch={handleSearch}
          isGridReady={isGridReady}
          changeColumnsVisibility={changeColumnsVisibility}
          filterMenuCalloutOpen={filterMenuCalloutOpen}
          handleGridFilter={handleGridFilter}
        />
      )}

      {msgBarProps && isGridReady && (
        <MessageBar className={msgBarProps.className} messageBarType={msgBarProps.messageBarType} onDismiss={msgBarProps.onDismiss}>
          {msgBarProps.children}
        </MessageBar>
      )}

      {!(dataGridProps && dataGridProps.renderEmptyGrid) && data.length === 0 && isGridReady ? (
        dataGridProps?.renderZeroItemsComponent ?? defaultRenderZeroItemsComponent()
      ) : (
        <ShimmeredDetailsList
          constrainMode={dataGridProps?.constrainMode ?? ConstrainMode.horizontalConstrained}
          enableShimmer={!isGridReady}
          shimmerLines={dataGridProps?.shimmerLines}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForGrid={pageTitle?.title ?? intl.formatMessage(AppMessages.ariaLabelForGrid)}
          ariaLabelForListHeader={intl.formatMessage(AppMessages.ariaLabelForListHeader)}
          ariaLabelForSelectionColumn={intl.formatMessage(AppMessages.ariaLabelForSelectionColumn)}
          ariaLabelForSelectAllCheckbox={intl.formatMessage(AppMessages.ariaLabelForSelectAllCheckBox)}
          ariaLabelForShimmer={intl.formatMessage(AppMessages.ariaLabelForShimmer)}
          checkButtonAriaLabel={intl.formatMessage(AppMessages.checkBoxLabel)}
          checkButtonGroupAriaLabel={intl.formatMessage(AppMessages.checkButtonGroupAriaLabel)}
          items={
            paginationProps?.shouldGridHandlePagination ? paginateData(paginator.currentPageNumber, paginator.currentPageSize) : gridRows
          }
          selection={dataGridProps?.selectionMode !== SelectionMode.none ? selection : undefined}
          setKey={rowIdentifier}
          getKey={getKey}
          columns={getVisibleColumns().sort((a, b) => (a.columnOrder || 1) - (b.columnOrder || 0))}
          onColumnHeaderClick={handleColumnHeaderClick}
          selectionMode={dataGridProps?.selectionMode || SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.justified}
          checkboxVisibility={isSelectionDisabled ? CheckboxVisibility.hidden : CheckboxVisibility.always}
          onRenderDetailsHeader={(headerProps) => RenderHeaderCheckbox(selection, headerProps)}
          onRenderRow={(detailsRowProps) => RenderRowCheckbox(rowIdentifier, selection, detailsRowProps, hideDisableStyle)}
          componentRef={rowRef}
        />
      )}

      {isGridReady && data.length !== 0 && paginationProps && paginationProps.showPagination !== false && (
        <Pagination
          intl={props.intl}
          display={PaginationDisplay.Navigation}
          totalPageCount={paginator.numberOfPages}
          currentPageNumber={paginator.currentPageNumber}
          pageSize={paginator.currentPageSize}
          onPageNumberChange={onPageNumberChanged}
          onPageSizeChange={onPageSizeChanged}
        />
      )}

      {children}
    </Main>
  );
});
