import { DefaultButton, IRefObject, ITextField, Image, ImageFit, TextField, getTheme } from '@fluentui/react';
import React, { useLayoutEffect, useRef } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { LOCAL_STORAGE_LOCALE } from '../../../constants';
import { GlobalSearchHeader } from '../../../layout/GlobalSearchHeader/GlobalSearchHeader';
import { InternationalizationLayout } from '../../../layout/InternationalizationLayout';
import { NotificationLayout } from '../../../layout/NotificationLayout';
import { injectIntlWithObserver } from '../../../utils';
import { onLanguageChanged } from '../../ad-management/Account/Settings/@data/mutatorActions';
import { getLoginPageStore, onEmailChanged, onLoginButtonClicked } from '../@data';
import '../@data/mutators/userAuthentication';
import '../@data/orchestrators';
import LoginPageMessages from '../LoginPage.messages';
import { getClassNames, getTextFieldStyles } from '../LoginPage.styles';
import loginBackgroundImage from '../resources/loginBackgroundImage.svg';

export interface ILoginPageProps extends RouteComponentProps, InjectedIntlProps {}

export const LoginPageView = injectIntlWithObserver(function LoginPageComponent({ location, intl }: ILoginPageProps) {
  const { formatMessage } = intl;
  const { title, welcome, description, emailPlaceholder, signInButton, imageAlt, signInButtonAriaLabel } = LoginPageMessages;
  const { active, errors } = getLoginPageStore();
  const { email } = active;
  const { buttonBackground, imageStyle, titleStyle, descStyle, signInButtonStyle } = getClassNames();
  const emailInputRef = useRef<{ state: { isFocused: boolean } }>(null);

  useLayoutEffect(() => {
    const localStorageLocal = localStorage.getItem(LOCAL_STORAGE_LOCALE);
    if (localStorageLocal) {
      onLanguageChanged(localStorageLocal, LOCAL_STORAGE_LOCALE);
    }
  });

  return (
    <>
      <GlobalSearchHeader />
      <InternationalizationLayout />
      <NotificationLayout ignoreGlobalAsync>
        <Image
          imageFit={ImageFit.cover}
          src={loginBackgroundImage}
          className={imageStyle}
          alt={formatMessage(imageAlt)}
          shouldFadeIn={true}
        />
        <div
          className={buttonBackground}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' && emailInputRef?.current?.state?.isFocused) {
              onLoginButtonClicked();
            }
          }}
        >
          <h1 className={titleStyle}>{formatMessage(title)}</h1>
          <label className={descStyle}>{formatMessage(welcome)}</label>
          <label className={descStyle}>{formatMessage(description)}</label>
          <TextField
            id="signInEmailInput"
            componentRef={emailInputRef as IRefObject<ITextField>}
            styles={getTextFieldStyles({ hasErrorMessage: !!errors.email, theme: getTheme() })}
            value={email}
            placeholder={formatMessage(emailPlaceholder)}
            onChange={(_, val) => onEmailChanged(val || '')}
            errorMessage={errors.email}
            type="email"
          />
          <DefaultButton
            id="signInButton"
            className={signInButtonStyle}
            onClick={onLoginButtonClicked}
            text={formatMessage(signInButton)}
            ariaLabel={formatMessage(signInButtonAriaLabel)}
          />
        </div>
      </NotificationLayout>
    </>
  );
});
