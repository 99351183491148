import { mutator } from 'satcheljs';
import { FilterScope } from '../../../../../@data';
import {
  onFilterChannelListChanged,
  onfilterItemsEntryTypeChanged,
  onFilterUpdateTypeChanged,
  onFormAppendExcludedAdKeywordsChanged,
  onFormAppendExcludedQueryKeywordsChanged,
  onFormAppendExcludedUrlsChanged,
  onFormChannelsChanged,
  onFormCleared,
  onFormDownloadExcludedAdkeywordLoading,
  onFormDownloadExcludedQuerykeywordLoading,
  onFormDownloadExcludedUrlLoading,
  onFormExludedAdKeywordChanged,
  onFormExludedQueryKeywordChanged,
  onFormExludedUrlChanged,
  onFormFilterEndTimeChanged,
  onFormFilterNameChanged,
  onFormFilterStartTimeChanged,
  onFormFilterTypeChanged,
  onFormIdChanged,
  onFormResetTimeRange,
  onFormUndoTimeRange,
} from '../actions';
import { IFilterViewModel } from '../store/schema';
import { getFilterStore } from '../store/store';

mutator(onFormIdChanged, (msg) => {
  getFilterStore().active.id = msg.value;
});

mutator(onFormFilterNameChanged, (msg) => {
  getFilterStore().active.name = msg.value;
});

mutator(onFormFilterNameChanged, (msg) => {
  getFilterStore().active.name = msg.value;
});

mutator(onFormFilterTypeChanged, (msg) => {
  const filterModel: IFilterViewModel = {
    ...getFilterStore().active,
  };
  filterModel.filterType = msg.value.key as FilterScope;
  getFilterStore().active = filterModel;
});

mutator(onFormChannelsChanged, (msg) => {
  getFilterStore().active.channelsSelected = msg.values;
});

mutator(onFilterChannelListChanged, (msg) => {
  getFilterStore().active.channels = msg.values;
});

mutator(onFilterUpdateTypeChanged, (msg) => {
  const filterModel: IFilterViewModel = {
    ...getFilterStore().active,
  };
  filterModel.appendOnly = msg.value.key === 'appendOnly' ? true : false;
  getFilterStore().active = filterModel;
});

mutator(onfilterItemsEntryTypeChanged, (msg) => {
  getFilterStore().active.filterItemsEntryType = msg.value.key;
});

mutator(onFormExludedUrlChanged, (msg) => {
  getFilterStore().active.excludedUrls = msg.value;
});

mutator(onFormExludedAdKeywordChanged, (msg) => {
  getFilterStore().active.excludedAdKeywords = msg.value;
});

mutator(onFormExludedQueryKeywordChanged, (msg) => {
  getFilterStore().active.excludedQueryKeywords = msg.value;
});

mutator(onFormAppendExcludedUrlsChanged, (msg) => {
  getFilterStore().active.appendExcludedUrls = msg.value;
});

mutator(onFormAppendExcludedAdKeywordsChanged, (msg) => {
  getFilterStore().active.appendExcludedAdKeywords = msg.value;
});

mutator(onFormAppendExcludedQueryKeywordsChanged, (msg) => {
  getFilterStore().active.appendExcludedQueryKeywords = msg.value;
});

mutator(onFormDownloadExcludedUrlLoading, (msg) => {
  getFilterStore().active.downloadExcludedUrlLoading = msg.value;
});

mutator(onFormDownloadExcludedAdkeywordLoading, (msg) => {
  getFilterStore().active.downloadExcludedAdkeywordLoading = msg.value;
});

mutator(onFormDownloadExcludedQuerykeywordLoading, (msg) => {
  getFilterStore().active.downloadExcludedQueryKeywordLoading = msg.value;
});

mutator(onFormFilterStartTimeChanged, (msg) => {
  getFilterStore().active.filterStartTime = msg.value;
});

mutator(onFormFilterEndTimeChanged, (msg) => {
  getFilterStore().active.filterEndTime = msg.value;
});

mutator(onFormResetTimeRange, () => {
  getFilterStore().active.filterStartTime = new Date();
  getFilterStore().active.filterEndTime = undefined;
});

mutator(onFormUndoTimeRange, () => {
  const { raw } = getFilterStore();
  const localDate = new Date();
  const UTCDate = new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
    localDate.getUTCHours(),
    localDate.getUTCMinutes()
  );
  getFilterStore().active.filterStartTime = raw && raw.startTimeStamp ? new Date(raw.startTimeStamp * 1000) : UTCDate;
  getFilterStore().active.filterEndTime = raw && raw.endTimeStamp ? new Date(raw.endTimeStamp * 1000) : undefined;
});

mutator(onFormCleared, () => {
  getFilterStore().active = {};
});
