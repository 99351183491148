import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { AuditHistoryRequestFilter, AuditType, UserRole } from '../../../../../@data';
import { FilterCondition } from '../../../../../@data/store/schema/enums/FilterCondition';
import {
  setAuditLogs,
  setAuditTypes,
  setEntityTypes,
  setFilters,
  setPageSize,
  setTotalPages,
  setTotalRecords,
  setUserRoles,
} from '../actions';
import { updateFiltersAndFetchLogs } from '../actions/actions';
import { fetchAuditLogs } from '../services/fetchAuditLogs';
import { getAuditHistoryStore } from '../store/store';

orchestrator(updateFiltersAndFetchLogs, async ({ filterConditions }) => {
  const newFilters: AuditHistoryRequestFilter[] = [];
  const newUserRoles: UserRole[] = [];
  const newAuditTypes: AuditType[] = [];
  const newEntityTypes: string[] = [];

  const filterConditionMap = new Map<string, string>([
    [FilterCondition.Contains, 'CONTAINS'],
    [FilterCondition.DoesNotContain, 'NOT_CONTAINS'],
    [FilterCondition.BeginsWith, 'BEGINS_WITH'],
    [FilterCondition.EndsWith, 'ENDS_WITH'],
    [FilterCondition.Equals, 'EQUALS'],
    [FilterCondition.DoesNotEqual, 'NOT_EQUALS'],
  ]);

  filterConditions.forEach((filterCondition) => {
    switch (filterCondition.columnName) {
      case 'userEmail': {
        newFilters.push({
          field: 'userEmail',
          value: filterCondition.filterText ?? '',
          operator: filterCondition.filterCondition ? filterConditionMap.get(filterCondition.filterCondition) ?? 'Contains' : 'Contains',
        });
        break;
      }
      case 'userRole':
        newUserRoles.push(filterCondition.filterCondition as UserRole);
        break;
      case 'actionType':
        newAuditTypes.push(filterCondition.filterCondition as AuditType);
        break;
      case 'actionOn':
        if (filterCondition.filterCondition) {
          newEntityTypes.push(filterCondition.filterCondition);
        }
        break;
      default:
        Log.debug('Unknown filter encountered');
        break;
    }
  });
  const { filters, auditTypes, userRoles, entityTypes } = getAuditHistoryStore();
  if (filters !== newFilters || auditTypes !== newAuditTypes || userRoles !== newUserRoles || entityTypes !== newEntityTypes) {
    setFilters(newFilters);
    setAuditTypes(newAuditTypes);
    setUserRoles(newUserRoles);
    setEntityTypes(newEntityTypes);

    const responseJson = await fetchAuditLogs();

    if (responseJson) {
      const { changeLogs, pageSize, totalPages, totalRecords } = responseJson;
      setAuditLogs(changeLogs);
      setPageSize(pageSize);
      setTotalPages(totalPages);
      setTotalRecords(totalRecords);
    }
  }
});
