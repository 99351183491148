import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, onNotificationChanged, onSavingChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { adQualityLocales } from '../../../../../config/locale';
import { validateSchema } from '../../../../../utils';
import messages from '../../AdReview/AdCreativePage.messages';
import {
  onAdCreativeBlockPanelChanged,
  onAdCreativeRefreshDataChanged,
  onBlockAdsErrorChanged,
  onRefreshBlockSummary,
  onSAMBlockAdsSubmitted,
} from '../actions';
import { createSAMAdBlock } from '../services/AdCreativeService';
import { samBlockItemErrorModel } from '../store/schema/SAMBlockItemErrorModel';
import { BlockItemType, SAMBlockAdData } from '../store/schema/models';
import { getStore } from '../store/store';

orchestrator(onSAMBlockAdsSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { blockAdsPage } = getStore();

  let notification: INotificationBarItem = {};
  if (publisher && publisher.id && userContext) {
    const body: SAMBlockAdData[] = [];
    blockAdsPage.adPerfBlockAdsData.forEach(async (item, index) => {
      const block: SAMBlockAdData = new SAMBlockAdData();
      block.creativeAdId = item.uniqueCreativeAdId;
      block.adTitle = item.adTitle;
      block.allAdImages = item.images;
      block.advertiserName = item.advertiserName;
      block.adProvider = item.adProvider;
      block.blockRequestSourceId = blockAdsPage.selectedBlockRequestSource;
      block.reasonCategoryString = blockAdsPage.blockReason;
      block.reasonComment = blockAdsPage.blockDetails;
      block.modifiedBy = userContext.id;
      block.publisherId = publisher.id;
      block.sevenDayRevenueImpact = item.revenue;
      block.filterSource = 'TakedownTool';
      if (blockAdsPage.selectedMarket) {
        block.localeScope = blockAdsPage.selectedMarket === 'All' ? 'global' : adQualityLocales[blockAdsPage.selectedMarket];
      }
      body.push(block);
    });

    const params = new URLSearchParams([['targetCustomerId', publisher.id!.toString()]]);
    try {
      onAdCreativeBlockPanelChanged(true, BlockItemType.Ads);
      await validateSchema(samBlockItemErrorModel(), blockAdsPage);
      onBlockAdsErrorChanged();
    } catch (e) {
      onBlockAdsErrorChanged(e);
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityBlockAdsMissingFields,
        intlMessageValues: { count: Object.keys(e).length },
        messageBarType: MessageBarType.severeWarning,
      });
      return;
    }

    onSavingChanged(true);
    try {
      await createSAMAdBlock(body, params);
      notification = {
        textMessageDescriptor: messages.adqualityBlockedAdsSuccessfully,
        messageBarType: MessageBarType.success,
      };
      onNotificationChanged(notification);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityFailedToBlockAds,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onRefreshBlockSummary();
      onAdCreativeBlockPanelChanged(false);
      onSavingChanged(false);
      onAdCreativeRefreshDataChanged();
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
