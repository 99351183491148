import { HomePageStatus } from '../../../../pages/ad-management/Home/@data/store/schema/IHomeViewState';
import { Model } from './Model';

export class EntitySummary extends Model {
  public apiPath = undefined;
  public className = 'entitySummary';
  public profile: HomePageStatus;
  public site: HomePageStatus;
  public adUnit: HomePageStatus;
  public isHomePageSetupComplete: boolean;
}
