import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { Channel, getAppStore, listEntity, onNotificationChanged } from '../../../../../@data';
import { onChannelsFetched, onFetchChannelsLoading, onFetchingChannelsData } from '../actions';
import { onManagePageInitialized } from '../actions/onManagePageInitialized';
import { getStore } from '../store/store';

orchestrator(onManagePageInitialized, async () => {
  try {
    const { publisher, userContext, account } = getAppStore();
    const channels = getStore().gridChannels;

    if (channels === undefined || channels.length === 0) {
      onFetchChannelsLoading(true);
    }
    onFetchingChannelsData(true);

    if (publisher && account && userContext) {
      const params = new URLSearchParams([['extendedInfo', 'true']]);
      const data = await listEntity([publisher, account], userContext, Channel, params);
      onChannelsFetched(data);
    } else {
      onChannelsFetched([]);
    }
  } catch (error) {
    Log.error(error);
    onNotificationChanged({ messageBarType: MessageBarType.error, text: 'Failed to load channels' });
  } finally {
    onFetchingChannelsData(false);
    onFetchChannelsLoading(false);
  }
});
