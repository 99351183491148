import { styled } from '@fluentui/react';
import { NotificationCardBase } from './NotificationCard.base';
import { getStyles } from './NotificationCard.styles';
import { INotificationCardProps, INotificationCardStyleProps, INotificationCardStyles } from './NotificationCard.types';

export const NotificationCard = styled<INotificationCardProps, INotificationCardStyleProps, INotificationCardStyles>(
  NotificationCardBase,
  getStyles,
  undefined,
  {
    scope: 'NotificationCard',
  }
);
