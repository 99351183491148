import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, listEntity, onLoadingChanged, onNotificationChanged, Publisher } from '../../../../../../../../../../@data';
import { PropertyServingConfigs } from '../../../../../../../../../../@data/store/schema/models/ServingConfigs';
import messages from '../../messages';
import { onClarityProjectChanged, onScriptPageInitialized } from '../actions/onScriptPageActions';

orchestrator(onScriptPageInitialized, async (msg) => {
  const publisherId = parseInt(msg.publisherId, 10);
  const propertyId = parseInt(msg.propertyId, 10);
  const { userContext } = getAppStore();

  if (!isNaN(publisherId) && !isNaN(propertyId)) {
    const publisher = new Publisher(publisherId);
    onLoadingChanged(true);

    try {
      const propertiesServingConfigs = await listEntity([publisher], userContext, PropertyServingConfigs);
      const propertyConfig = propertiesServingConfigs.find((config) => config.propertyId === propertyId);
      onClarityProjectChanged(propertyConfig?.clarityConfig?.clarityId || '');
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.scriptPageFailedToLoadClarityProject,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.scriptPageInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
