import { Callout, DefaultButton, PrimaryButton, Spinner, TextField } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAppStore } from '../../../../../../@data';
import AppMessages from '../../../../../../App.messages';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../../../utils';
import { getAdunitStore } from '../../../@data';
import { onAdUnitNameUpdate, onAdunitNameChanged, onFetchAdUnitName } from '../../../@data/actions';
import messages from '../../ManagePage.messages';
import callOutMessages from './AdUnitNameCallout.messages';
import { getClassNames } from './AdUnitNameCallout.styles';

interface IAdUnitCallout extends IRouterWithIntl {
  visibleCallOut: number;
  setVisibleCallOut: (id: number) => void;
}

export const AdUnitCallout = injectIntlAndObserver(function AdUnitCalloutComponent(props: IAdUnitCallout) {
  const { calloutStyles, textFieldContainer, spinnerStyles, buttonsContainer } = getClassNames();
  const { intl, visibleCallOut, setVisibleCallOut } = props;
  const { formatMessage } = intl;
  const { publisher, account } = getAppStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (visibleCallOut !== 0 && publisher && account) {
      onFetchAdUnitName(publisher.id, account.id, visibleCallOut.toString(), setIsLoading);
    }
    return () => setErrorMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleCallOut]);

  return visibleCallOut !== 0 ? (
    <Callout
      ariaLabelledBy={formatMessage(callOutMessages.title)}
      role="dialog"
      gapSpace={0}
      target={`#adunitCallout-${visibleCallOut}`}
      styles={{ root: calloutStyles }}
      onDismiss={() => setVisibleCallOut(0)}
      setInitialFocus
    >
      <>
        <div className={textFieldContainer}>
          <TextField
            label={formatMessage(messages.name)}
            required={true}
            errorMessage={errorMessage}
            value={getAdunitStore().active.name}
            disabled={isLoading}
            onChange={(_, value) => onAdunitNameChanged(value)}
          />
          {isLoading && <Spinner styles={{ root: spinnerStyles }} />}
        </div>
        <div className={buttonsContainer}>
          <PrimaryButton
            text={formatMessage(AppMessages.save)}
            disabled={isLoading}
            onClick={() => onAdUnitNameUpdate(formatMessage, setIsLoading, setErrorMessage, setVisibleCallOut)}
          />
          <DefaultButton text={formatMessage(AppMessages.cancel)} onClick={() => setVisibleCallOut(0)} />
        </div>
      </>
    </Callout>
  ) : (
    <></>
  );
});
