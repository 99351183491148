import { IconButton, MessageBarType, ProgressIndicator } from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { NativeAdTemplate, onNotificationChanged } from '../../../../../../@data';
import { NativeAdTemplateBoost, TemplateTypeNumber } from '../../../../../../@data/store/schema/models/NativeAdTemplateBoost';
import AppMessages from '../../../../../../App.messages';
import { injectIntlAndObserver } from '../../../../../../utils';
import { convertSizeToReadableUnits } from '../../../../../../utils/UnitsUtils';
import { getAdunitStore } from '../../../@data';
import messages from './BoostJsonFileUpload.messages';
import { getClassNames } from './BoostJsonFileUpload.styles';

const ACCEPTED_FILE_EXTENSION = '.JSON';
const JSON_FILE_TYPE = 'text/JSON';
export interface IBoostJsonFileUpload {
  adUnitId?: string;
  onNewFileUpload: (file: NativeAdTemplate) => void;
  onReplaceFile?: (file: NativeAdTemplate) => void;
  existingDomains?: string[];
}

export interface IFileUploadStats {
  name?: string;
  type?: string;
  size?: number;
  readableSize?: string;
  successCount?: number;
  failureCount?: number;
  conflictCount?: number;
}

enum FileOperation {
  NewUpload,
  Append,
  Replace,
}

export const LAST_FILE_UPLOAD_INFO_KEY = 'lastFileUploadInfo';

export const BoostJsonFileUpload = injectIntlAndObserver(
  ({ adUnitId, intl, onNewFileUpload, onReplaceFile }: IBoostJsonFileUpload & InjectedIntlProps) => {
    const {
      active: { BoostTemplate },
    } = getAdunitStore();

    const { formatMessage } = intl;
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [fileUploadError, setFileUploadError] = useState<string | null>(null);
    const [fileUploadStats, setFileUploadStats] = useState<IFileUploadStats | null>(null);
    const [template, setTemplate] = useState<NativeAdTemplate | undefined>(BoostTemplate);

    const fileReader = useRef(new FileReader());
    let fileOperation: FileOperation;

    const {
      fileInputStyle,
      fileUploadContainerStyle,
      fileUploadLabelStyle,
      fileUploadedStyle,
      errorStyle,
      progressContainerStyle,
      actionContainerStyle,
    } = getClassNames({
      isError: !!fileUploadError,
    });

    function getFileUploadStorageKey() {
      return 'boostAdUnitTemplateJsonFile';
    }

    function handleReplaceFile(event) {
      fileOperation = FileOperation.Replace;
      return handleFileUpload(event);
    }

    function handleNewFileUpload(event) {
      fileOperation = FileOperation.NewUpload;
      return handleFileUpload(event);
    }

    function boostFileValidation(JsonObject) {
      return (
        JsonObject.type !== undefined &&
        JsonObject.numberOfAds !== undefined &&
        JsonObject.assets !== undefined &&
        JsonObject.renderingConfig !== undefined
      );
    }

    function handleFileUpload(event) {
      setFileUploadError(null);
      const file = event.target.files[0] as File;
      const { size, name, type } = file || {};

      setFileUploadStats({ readableSize: convertSizeToReadableUnits(size), size, name, type });

      const readerInstance = fileReader.current;

      readerInstance.onloadstart = () => setIsUploadInProgress(true);
      readerInstance.onloadend = () => {
        const { result } = readerInstance;
        if (typeof result !== 'string') {
          const errorMessage = formatMessage(messages.errorMessage);
          const errorDiv = document.createElement('div');
          errorDiv.innerHTML = errorMessage;
          document.body.appendChild(errorDiv);
          onNotificationChanged({ text: errorMessage, messageBarType: MessageBarType.error });
          setFileUploadError(formatMessage(messages.uploadError));
          return;
        }

        try {
          const JsonObject: NativeAdTemplate = JSON.parse(result);
          if (!boostFileValidation(JsonObject)) {
            // setFileUploadStats({ name: 'ERROR: Incorrect Format' });
            setFileUploadError(formatMessage(messages.uploadError));
            return;
          }

          if (fileOperation === FileOperation.Replace && fileUploadStats) {
            onReplaceFile?.(JsonObject);
          } else {
            onNewFileUpload(JsonObject);
          }
          setTemplate(JsonObject);
        } catch (e) {
          const errorMessage = formatMessage(messages.errorMessage);
          setFileUploadError(formatMessage(messages.uploadError));
          onNotificationChanged({ text: errorMessage, messageBarType: MessageBarType.error });
        }

        setFileUploadStats((prevValue) => ({
          ...prevValue,
        }));
      };

      readerInstance.readAsText(file, JSON_FILE_TYPE);
    }

    function isNoFileUploadedYet() {
      return !isUploadInProgress && !fileUploadStats && template === undefined;
    }

    function shouldShowFileDetails(): boolean {
      return !!fileUploadStats && !isUploadInProgress;
    }

    function cancelUpload() {
      fileReader.current.abort();
      setIsUploadInProgress(false);
    }

    function renderUploadInProgressSection() {
      const { name } = fileUploadStats || {};
      if (!isUploadInProgress || !name) {
        return null;
      }

      return (
        <div className={fileUploadedStyle}>
          <div className={progressContainerStyle}>
            <div className="fileNameStyle">{name}</div>
            <ProgressIndicator />
          </div>
          <div className="closeContainerStyle">
            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={cancelUpload} />
          </div>
        </div>
      );
    }

    function renderFileUploadedSection() {
      if (!fileUploadStats) {
        return null;
      }

      const { name, readableSize } = fileUploadStats;
      return (
        <div className={fileUploadedStyle}>
          <div>
            <div className="fileName">
              {name} ({readableSize})
            </div>
          </div>
          <div className={actionContainerStyle}>
            <label className={fileUploadLabelStyle} tabIndex={0}>
              <input type="file" className={fileInputStyle} accept={ACCEPTED_FILE_EXTENSION} onChange={handleReplaceFile} />
              {formatMessage(AppMessages.replace)}
            </label>
          </div>
        </div>
      );
    }

    function renderNoFileUploadedSection() {
      return (
        <label className={fileUploadLabelStyle} tabIndex={0}>
          <input type="file" className={fileInputStyle} accept={ACCEPTED_FILE_EXTENSION} onChange={handleNewFileUpload} />
          {formatMessage(messages.uploadFile)}
        </label>
      );
    }

    const handleDownload = async () => {
      // Code to download file goes here...
      if (template !== undefined) {
        const jsonLink = document.createElement('a');
        // Need logic to turn the string into number
        const ParsedBoostTemplate: NativeAdTemplateBoost = JSON.parse(JSON.stringify(template));
        ParsedBoostTemplate.type = TemplateTypeNumber[template.type as keyof typeof TemplateTypeNumber];
        jsonLink.setAttribute(
          'download',
          ParsedBoostTemplate.name ? ParsedBoostTemplate.name.toString() + '.JSON' : 'CurrentTemplate.JSON'
        );
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(ParsedBoostTemplate));
        jsonLink.setAttribute('href', dataStr);
        document.body.appendChild(jsonLink);
        jsonLink.click();
        document.body.removeChild(jsonLink);
      } else {
        alert('No template found');
      }
    };

    useEffect(() => {
      if (fileUploadStats && !fileUploadError) {
        sessionStorage.setItem(getFileUploadStorageKey(), JSON.stringify(fileUploadStats));
        setIsUploadInProgress(false);
      }
    }, [fileUploadStats, fileUploadError]);

    useEffect(() => {
      return () => {
        if (template === undefined) {
          setFileUploadStats(null);
        }
      };
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <div className={fileUploadContainerStyle}>
          {isUploadInProgress && renderUploadInProgressSection()}
          {shouldShowFileDetails() && renderFileUploadedSection()}
          {isNoFileUploadedYet() && renderNoFileUploadedSection()}
          {fileUploadStats === null && template && (
            <div className={fileUploadedStyle} tabIndex={0}>
              <label className={fileUploadLabelStyle} onClick={handleDownload}>
                {formatMessage(messages.downloadTemplate)}
              </label>
              <div className={actionContainerStyle}>
                <label className={fileUploadLabelStyle} tabIndex={0}>
                  <input type="file" className={fileInputStyle} accept={ACCEPTED_FILE_EXTENSION} onChange={handleReplaceFile} />
                  {formatMessage(AppMessages.replace)}
                </label>
              </div>
            </div>
          )}
        </div>
        {fileUploadError && <div className={errorStyle}>{fileUploadError}</div>}
      </>
    );
  }
);
