import { mutator } from 'satcheljs';
import { Account } from '../../../../../@data';
import { mergeTypeWithObject } from '../../../../../utils';
import {
  onAccountChanged,
  onAccountErrorChanged,
  onAccountSkuChanged,
  onAccountSkusChanged,
  onAccountsFetched,
  onFetchAccountsLoading,
  onFetchingAccountsData,
  onRawChanged,
} from '../actions';
import { getStore } from '../store/store';

mutator(onAccountChanged, (msg) => {
  getStore().active = msg.account;
});

mutator(onAccountSkuChanged, (msg) => {
  getStore().accountSku = msg.value;
});

mutator(onAccountSkusChanged, (msg) => {
  getStore().active.skus = msg.value;
});

mutator(onRawChanged, (msg) => {
  getStore().raw = mergeTypeWithObject(new Account(), msg.value);
});

mutator(onAccountErrorChanged, (msg) => {
  getStore().errors = msg.values || {};
});

mutator(onFetchAccountsLoading, (msg) => {
  getStore().isLoading = msg.isLoading;
});

mutator(onFetchingAccountsData, (msg) => {
  getStore().isFetchingData = msg.isFetchingData;
});

mutator(onAccountsFetched, (msg) => {
  getStore().accounts = msg.items;
});
