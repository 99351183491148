import { createStore } from 'satcheljs';
import { DEFAULT_NON_TYTHON_TNC, DEFAULT_TNC } from '../orchestrators';

export interface ITnC {
  content: string;
  nonTythonContent: string;
  latestTnCReleaseDate?: string;
  latestTnCVersion?: string;
  isDataLoaded: boolean;
  isNotificationPanelTrigerred?: boolean;
}

export const getStore = createStore<ITnC>('ITnC', {
  content: DEFAULT_TNC,
  nonTythonContent: DEFAULT_NON_TYTHON_TNC,
  latestTnCReleaseDate: undefined,
  latestTnCVersion: undefined,
  isDataLoaded: false,
  isNotificationPanelTrigerred: false,
});
