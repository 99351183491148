import { mutatorAction } from 'satcheljs';
import { PrivateMarketplace } from '../../../../../@data';
import { getAdunitStore } from '../store/store';

export const onAdunitPrivateMarketplaceChanged = mutatorAction(
  'onAdunitPrivateMarketplaceChanged',
  (privateMarketplace?: PrivateMarketplace) => {
    getAdunitStore().privateMarketplace = privateMarketplace ? privateMarketplace : undefined;
    getAdunitStore().enablePrivateMarketplace = privateMarketplace ? true : false;
  }
);

export const onAdunitEnablePrivateMarketplaceChanged = mutatorAction(
  'onAdunitEnablePrivateMarketplaceChanged',
  (enablePrivateMarketplace: boolean) => {
    getAdunitStore().enablePrivateMarketplace = enablePrivateMarketplace;
  }
);
