import { orchestrator } from 'satcheljs';
import {
  DismissNotificationRequest,
  NotificationSeverity,
  getAppStore,
  onDismissAllNotifications,
  onNotificationPanelItemsChanged,
} from '..';
import { Log } from '../../../logging/src';
import { SESSION_DISMISSED_NOTIFICATIONS_BACKEND, SESSION_NOTIFICATIONS_UI } from '../../constants/AppConstants';
import { dismissNotifications } from '../services/NotificationService';

orchestrator(onDismissAllNotifications, async () => {
  const store = getAppStore();

  const panelNotifications = store.panelNotifications || [];
  const dismissedNotifications = sessionStorage.getItem(SESSION_DISMISSED_NOTIFICATIONS_BACKEND);

  let dismissedBackendNotificationInUI: string[] = [];
  if (dismissedNotifications) {
    dismissedBackendNotificationInUI = JSON.parse(dismissedNotifications);
  }

  const backendDismissedNotifications: string[] = [];
  panelNotifications.forEach((panelNotification) => {
    if (!panelNotification.isUIGenerated) {
      if (panelNotification.severity === NotificationSeverity.Critical || panelNotification.severity === NotificationSeverity.Warning) {
        // we donot dismiss backend notification but remove in UI
        dismissedBackendNotificationInUI.push(panelNotification.id!);
      } else {
        backendDismissedNotifications.push(panelNotification.id!);
      }
    }
  });
  sessionStorage.setItem(SESSION_DISMISSED_NOTIFICATIONS_BACKEND, JSON.stringify(dismissedBackendNotificationInUI));

  if (backendDismissedNotifications.length > 0) {
    try {
      const body: DismissNotificationRequest = { notificationIds: backendDismissedNotifications };
      await dismissNotifications(store.publisher?.id!, store.account?.id!, store.userContext!, body);
    } catch (e) {
      Log.error(`Failed to dismiss notifications:  ${JSON.stringify(e)}`);
    }
  }

  onNotificationPanelItemsChanged([]);
  sessionStorage.setItem(SESSION_NOTIFICATIONS_UI, '');
});
