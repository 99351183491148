import { CommandBar } from '@fluentui/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { Model } from '../../../../@data';
import { GridFilter } from '../../../../components/GridFilter/GridFilter';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../utils';
import { setFilterMenuCalloutStatus } from '../../@data/mutatorActions';
import { getStore } from '../../@data/store/store';
import { IBuildCommandBarProps, IColumnVisibilityMapping, IDataGridCommandBarProps, IGridV2Column } from '../../GridV2.types';
import { DeactivateConfirmationDialog } from '../DeactivateConfirmationDialog';
import { DeleteConfirmationDialog } from '../DeleteConfirmationDialog';
import { ModifyColumnsPanel } from '../ModifyColumnsPanel';
import { getCustomCommandBarStyles } from './CustomCommandBar.styles';
import { buildCommandBarProps } from './utils';

export interface ICustomCommandBarProps extends IRouterWithIntl {
  entity: new () => Model;
  columns: IGridV2Column[];
  commandBarProps?: IBuildCommandBarProps<Model>;
  isGridReady?: boolean;
  // tslint:disable-next-line: no-any
  data: any[];
  cancelSelection: () => void;
  setData?: (data?: Model[]) => void;
  handleSearch?: (value: string) => void;
  filterMenuCalloutOpen?: boolean;
  handleGridFilter: (filterConditions) => Model[];
  changeColumnsVisibility: (columnsVisibility: IColumnVisibilityMapping[]) => void;
}

export const CustomCommandBar = injectIntlAndObserver(function CustomCommandBarComponent(props: ICustomCommandBarProps): JSX.Element {
  const commandBarRef = useRef(null);
  const { selectedItems, isSearchCalloutVisible } = getStore();
  const {
    commandBarProps,
    data,
    setData,
    isGridReady,
    columns,
    intl,
    filterMenuCalloutOpen,
    changeColumnsVisibility,
    handleGridFilter,
  } = props;
  const {
    filterItems,
    deleteLabels,
    deactivateLabels,
    onDeactivateDialogDismiss,
    onPreDeleteItems,
    onDeleteItems,
    onPostDeleteItems,
    onItemsStatusChanged,
    includeActions,
    hideModifyColumnsDescription,
  } = commandBarProps || {};
  const commandBarItems: IDataGridCommandBarProps = useMemo(
    () => buildCommandBarProps(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItems, columns, data, isSearchCalloutVisible, isGridReady]
  );

  /**
   * to pass 1CS we would need add "aria-busy" attribute in the commandbar wrapper to enhance accessibility UI,
   *  which fluentUI doesn't provide such mechanism for <CommandBar /> component
   */
  useEffect(() => {
    if (commandBarRef.current) {
      const commandBarWrapper = document.querySelectorAll('div[role="menubar"]')?.[0];
      if (commandBarWrapper && !commandBarWrapper.hasAttribute('aria-busy')) {
        commandBarWrapper.setAttribute('aria-busy', 'true');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commandBarRef.current]);

  return commandBarItems ? (
    <>
      <CommandBar
        {...commandBarItems}
        styles={getCustomCommandBarStyles(!!selectedItems?.length, includeActions?.length || 0)}
        componentRef={commandBarRef}
        data-test-id="grid-command-bar"
      />

      {filterItems && (
        <GridFilter
          intl={intl}
          filterItems={filterItems || []}
          onFilterMenuCalloutClose={() => setFilterMenuCalloutStatus(false)}
          filterMenuCalloutOpen={filterMenuCalloutOpen}
          onFilterApplied={handleGridFilter}
        />
      )}

      <ModifyColumnsPanel
        columns={columns}
        changeColumnsVisibility={changeColumnsVisibility}
        hideDescription={hideModifyColumnsDescription}
      />
      <DeleteConfirmationDialog
        data={data}
        setData={setData}
        deleteLabels={deleteLabels}
        selectedItems={selectedItems}
        preDeleteAction={onPreDeleteItems}
        deleteAction={onDeleteItems}
        postDeleteAction={onPostDeleteItems}
      />
      <DeactivateConfirmationDialog
        data={data}
        setData={setData}
        deactivateLabels={deactivateLabels}
        selectedItems={selectedItems}
        onItemsStatusChanged={onItemsStatusChanged}
        onDismiss={onDeactivateDialogDismiss}
      />
    </>
  ) : (
    <></>
  );
});
