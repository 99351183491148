import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import {
  AdUnit,
  InventoryPlatformType,
  Model,
  PopupType,
  getAccessPermission,
  getAppStore,
  isTythonSelfServeUser,
} from '../../../../../@data';
import { hasDeleteAccess, hasUpdateAccess } from '../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../App.messages';
import { ZeroResults } from '../../../../../components/ZeroResults/ZeroResults';
import { CommandBarActions, IBuildCommandBarProps, IGridV2Column } from '../../../../../layout/GridPageV2';
import { setDialogType } from '../../../../../layout/GridPageV2/@data/mutatorActions';
import { getTythonAppAdunitCreationRoute, getTythonAppAdunitEditionRoute } from '../../../../../utils/routeUtils';
import { getAdunitStore } from '../../../Adunit/@data';
import { setAdUnitsData } from '../../../Adunit/@data/mutatorActions';
import { getFilterItems } from '../../../Adunit/Manage/utils/getFilterItems';
import { getGridPropertyStore } from '../../../Property/Manage/__data__/store/store';
import messages from '../messages';
import { UseAppAdsGridType } from '../types';

const InventoryPlatformTypeLabel = {
  [InventoryPlatformType.UWP]: messages.propertyInventoryTypeLabelUWP,
};

// @ts-ignore TODO: fix types
const useAppAdsGrid: UseAppAdsGridType = ({ formatMessage, history, onPanelTrigger }) => {
  const { publisher, account } = getAppStore();
  const { adUnitsRaw } = getAdunitStore();
  const adUnitsGridData = publisher && account ? adUnitsRaw.data || [] : [];

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={formatMessage(messages.zeroAdUnitsTitle)}
      description={formatMessage(messages.zeroAdUnitsDescription)}
      buttonText={formatMessage(messages.zeroAdUnitsCallToAction)}
      onButtonClick={() => history.push(getTythonAppAdunitCreationRoute())}
    />
  );

  const columns: IGridV2Column[] = [
    {
      columnOrder: 2,
      key: 'name',
      fieldName: 'name',
      name: formatMessage(messages.gridColumnTitleAppName),
      minWidth: 100,
      maxWidth: 125,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 3,
      key: 'id',
      fieldName: 'id',
      name: formatMessage(messages.gridColumnTitleAppId),
      minWidth: 100,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
    },
    {
      columnOrder: 4,
      key: 'connectedApp',
      fieldName: 'connectedApp',
      name: formatMessage(messages.gridColumnTitleConnectedApp),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
      onRender: (item: AdUnit) => {
        const servingConfig = getGridPropertyStore()?.servingConfigs?.find((config) => item.propertyId === config.propertyId);
        return servingConfig?.name;
      },
      exportFunction: (item: AdUnit) => {
        const servingConfig = getGridPropertyStore()?.servingConfigs?.find((config) => item.propertyId === config.propertyId);
        return servingConfig?.name;
      },
    },
    {
      columnOrder: 5,
      key: 'platform',
      fieldName: 'platform',
      name: formatMessage(messages.gridColumnTitleAdUnitPlatform),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
      onRender: (item: AdUnit) => {
        const servingConfig = getGridPropertyStore()?.servingConfigs?.find((config) => item.propertyId === config.propertyId);
        return formatMessage(InventoryPlatformTypeLabel[servingConfig?.inventoryPlatformType!]);
      },
      exportFunction: (item: AdUnit) => {
        const servingConfig = getGridPropertyStore()?.servingConfigs?.find((config) => item.propertyId === config.propertyId);
        return formatMessage(InventoryPlatformTypeLabel[servingConfig?.inventoryPlatformType!]);
      },
    },
    {
      columnOrder: 6,
      key: 'status',
      fieldName: 'status',
      name: formatMessage(messages.gridColumnTitleAppAdUnitStatus),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
    },
    {
      columnOrder: 8,
      key: 'actions',
      name: formatMessage(messages.gridColumnTitleActions),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: false,
      onRender: (item: AdUnit) => {
        const accessPermission = getAccessPermission(item);

        const editAdUnitOptions = undefined;

        const editButtonOnClick = () => history.push(`${getTythonAppAdunitEditionRoute(item.id)}`);
        const isWindowRegularSize = true; // TODO: revisit responsive
        return (
          <div>
            <CommandBarButton
              id={`appAdunitCallout-${item.id}`}
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'edit' }}
              ariaLabel={formatMessage(AppMessages.edit)}
              text={isWindowRegularSize ? formatMessage(AppMessages.edit) : ''}
              menuProps={editAdUnitOptions}
              disabled={!hasUpdateAccess(accessPermission)}
              onClick={editButtonOnClick}
            />
            <CommandBarButton
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'PasteAsCode' }}
              ariaLabel={formatMessage(AppMessages.code)}
              text={isWindowRegularSize ? formatMessage(AppMessages.code) : ''}
              onClick={() => onPanelTrigger(true, item)}
            />
            <CommandBarButton
              data-test-id={`grid-delete-action-${item.id}`}
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'Delete' }}
              text={isWindowRegularSize ? formatMessage(AppMessages.delete) : ''}
              onClick={() => setDialogType(PopupType.DeleteConfirmation)}
              disabled={!hasDeleteAccess(accessPermission)}
            />
          </div>
        );
      },
    },
  ];

  const dataGridProps = {
    gridV2Columns: columns,
    shimmerLines: 10,
    renderZeroItemsComponent: NoResultComponent,
    setData: setAdUnitsData,
    data: isTythonSelfServeUser()
      ? adUnitsGridData.filter((adunit) =>
          getGridPropertyStore()?.servingConfigs?.find(
            (config) => config.propertyId === adunit.propertyId && config.inventoryPlatformType === InventoryPlatformType.UWP
          )
        )
      : adUnitsGridData,
  };

  const commandBarItems: IBuildCommandBarProps<Model> = {
    primaryButtonUrl: getTythonAppAdunitCreationRoute(),
    primaryButtonLabel: messages.gridCreateButtonLabel,
    includeActions: [CommandBarActions.new],
    includeFarActions: [
      CommandBarActions.searchBox,
      CommandBarActions.addFilter,
      CommandBarActions.code,
      CommandBarActions.download,
      CommandBarActions.modifyColumns,
    ],
    filterItems: getFilterItems(formatMessage, true),
  };

  return {
    dataGridProps,
    commandBarItems,
  };
};

export default useAppAdsGrid;
