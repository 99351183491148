export interface IDictionary<T> {
  [key: string]: T;
}

export const COUNTRIES: IDictionary<string> = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CI: 'Côte d’Ivoire',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo (DRC)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Islas Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji Islands',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern and Antarctic Lands',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HK: 'Hong Kong SAR',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IQ: 'Iraq',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  '99': 'Latin America',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao SAR',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  AN: 'Netherlands Antilles',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Authority',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn Islands',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  SD: 'Republic of the Sudan',
  '97': 'Rest Of World',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'São Tomé and Príncipe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  CS: 'Serbia and Montenegro',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SR: 'Suriname',
  SJ: 'Svalbard',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  '96': 'TEST USE ONLY',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UM: 'U.S. Minor Outlying Islands',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VA: 'Vatican City',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands',
  VI: 'Virgin Islands',
  WF: 'Wallis and Futuna',
  ZW: 'Zimbabwe',
  YE: 'Yemen',
  ZM: 'Zambia',
};

export const COUNTRIES_EN: IDictionary<string> = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo (Democratic Republic of the)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (Democratic People's Republic of)",
  KR: 'Korea (Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia (the former Yugoslav Republic of)',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China[a]',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom of Great Britain',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
};

export const COUNTRIES_EN_GB: IDictionary<string> = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo (Democratic Republic of the)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (Democratic People's Republic of)",
  KR: 'Korea (Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia (the former Yugoslav Republic of)',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China[a]',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom of Great Britain',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands',
};

export const COUNTRIES_FR: IDictionary<string> = {
  AF: 'Afghanistan',
  DE: 'Allemagne',
  AD: 'Andorre',
  AI: 'Anguilla',
  AG: 'Antigua-et-Barbuda',
  AW: 'Aruba',
  AT: 'Autriche',
  BS: 'Bahamas',
  BH: 'Bahreïn',
  BD: 'Bangladesh',
  BE: 'Belgique',
  BZ: 'Belize',
  BM: 'Bermudes',
  BO: 'Bolivie (État Plurinational de)',
  BQ: 'Bonaire, Sint Eustatius et Saba',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BN: 'Brunei Darussalam',
  BR: 'Brésil',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  BJ: 'Bénin',
  CV: 'Cabo Verde',
  KH: 'Cambodge',
  CM: 'Cameroun',
  CA: 'Canada',
  CL: 'Chili',
  CY: 'Chypre',
  KM: 'Comores',
  CG: 'Congo',
  CD: 'Congo (République Démocratique du)',
  KR: 'Corée (République de)',
  KP: 'Corée (République populaire Démocratique de)',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CW: 'Curaçao',
  DK: 'Danemark',
  DJ: 'Djibouti',
  DM: 'Dominique',
  SV: 'El Salvador',
  ES: 'Espagne',
  FJ: 'Fidji',
  FR: 'France',
  RU: 'Fédération De Russie',
  GA: 'Gabon',
  GI: 'Gibraltar',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernesey',
  GQ: 'Guinée Équatoriale',
  GF: 'Guyane Française',
  GS: 'Géorgie du sud et les Îles Sandwich du Sud',
  HT: 'Haïti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hongrie',
  IR: 'Iran (République Islamique de)',
  IL: 'Israël',
  IT: 'Italie',
  JP: 'Japon',
  JE: 'Jersey',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KG: 'Kirghizistan',
  KI: 'Kiribati',
  KW: 'Koweït',
  ZA: "L'Afrique Du Sud",
  AO: "L'Angola",
  SA: "L'Arabie Saoudite",
  UA: "L'Ukraine",
  UY: "L'Uruguay",
  AL: "L'albanie",
  DZ: "L'algérie",
  AQ: "L'antarctique",
  AR: "L'argentine",
  AM: "L'arménie",
  AU: "L'australie",
  AZ: "L'azerbaïdjan",
  EG: "L'egypte",
  EE: "L'estonie",
  ET: "L'ethiopie",
  IN: "L'inde",
  ID: "L'indonésie",
  IQ: "L'irak",
  IE: "L'irlande",
  IS: "L'islande",
  UG: "L'ouganda",
  CX: "L'Île Christmas",
  NF: "L'Île De Norfolk",
  HM: "L'Île heard et des Îles McDonald",
  EC: "L'équateur",
  ER: "L'érythrée",
  IM: "L'île de Man",
  MU: "L'île maurice",
  CI: "La Côte d'Ivoire",
  GW: 'La Guinée-Bissau',
  NC: 'La Nouvelle-Calédonie',
  NZ: 'La Nouvelle-Zélande',
  PS: "La Palestine, l'État de",
  PF: 'La Polynésie Française',
  LA: 'La République Démocratique populaire Lao',
  RE: 'La Réunion',
  SL: 'La Sierra Leone',
  BB: 'La barbade',
  BY: 'La biélorussie',
  BA: 'La bosnie-et-Herzégovine',
  BG: 'La bulgarie',
  CN: 'La chine',
  CO: 'La colombie',
  HR: 'La croatie',
  FI: 'La finlande',
  GM: 'La gambie',
  GD: 'La grenade',
  GR: 'La grèce',
  GN: 'La guinée',
  GY: 'La guyane',
  GE: 'La géorgie',
  JM: 'La jamaïque',
  JO: 'La jordanie',
  LV: 'La lettonie',
  LY: 'La libye',
  LT: 'La lituanie',
  MY: 'La malaisie',
  MR: 'La mauritanie',
  MN: 'La mongolie',
  NA: 'La namibie',
  NO: 'La norvège',
  RO: 'La roumanie',
  MD: 'La république de Moldova (République de)',
  CZ: 'La république tchèque',
  RS: 'La serbie',
  SK: 'La slovaquie',
  SI: 'La slovénie',
  SO: 'La somalie',
  CH: 'La suisse',
  TR: 'La turquie',
  ZM: 'La zambie',
  GH: 'Le Ghana',
  LI: 'Le Liechtenstein',
  ML: 'Le Mali',
  RW: 'Le Rwanda',
  SS: 'Le Soudan Du Sud',
  TL: 'Le Timor-Leste',
  VN: 'Le Viet Nam',
  BT: 'Le bhoutan',
  GL: 'Le groenland',
  ME: 'Le monténégro',
  TM: 'Le turkménistan',
  AX: "Les Îles D'Åland",
  LS: 'Lesotho',
  LB: 'Liban',
  LR: 'Libéria',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macédoine (ex-République Yougoslave de)',
  MG: 'Madagascar',
  MW: 'Malawi',
  MV: 'Maldives',
  MT: 'Malte',
  MA: 'Maroc',
  MQ: 'Martinique',
  YT: 'Mayotte',
  MX: 'Mexique',
  FM: 'Micronésie (États Fédérés de)',
  MC: 'Monaco',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NR: 'Nauru',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NP: 'Népal',
  OM: 'Oman',
  UZ: 'Ouzbékistan',
  PK: 'Pakistan',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papouasie-Nouvelle-Guinée',
  PY: 'Paraguay',
  NL: 'Pays-bas',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Pologne',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  PE: 'Pérou',
  QA: 'Qatar',
  GB: 'Royaume-Uni de Grande-Bretagne',
  SY: 'République Arabe Syrienne',
  CF: 'République Centrafricaine',
  DO: 'République Dominicaine',
  EH: 'Sahara Occidental',
  BL: 'Saint-Barthélemy',
  KN: 'Saint-Kitts-et-Nevis',
  MF: 'Saint-Martin (partie française)',
  PM: 'Saint-Pierre-et-Miquelon',
  VA: 'Saint-Siège',
  VC: 'Saint-Vincent-et-les-Grenadines',
  SH: 'Sainte-Hélène, Ascension et Tristan da Cunha',
  LC: 'Sainte-Lucie',
  WS: 'Samoa',
  AS: 'Samoa Américaines',
  SM: 'San Marino',
  ST: 'Sao Tomé-et-Principe',
  SC: 'Seychelles',
  SG: 'Singapour',
  SX: 'Sint Maarten (en néerlandais partie)',
  SD: 'Soudan',
  LK: 'Sri Lanka',
  SR: 'Suriname',
  SE: 'Suède',
  SJ: 'Svalbard et Jan Mayen',
  SZ: 'Swaziland',
  SN: 'Sénégal',
  TJ: 'Tadjikistan',
  TW: 'Taiwan, Province de Chine[a]',
  TZ: 'Tanzanie, République-Unie de',
  TD: 'Tchad',
  TF: 'Terres Australes Françaises',
  IO: "Territoire Britannique De L'Océan Indien",
  TH: 'Thaïlande',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinité-et-Tobago',
  TN: 'Tunisie',
  TV: 'Tuvalu',
  VU: 'Vanuatu',
  VE: 'Venezuela (République Bolivarienne du)',
  WF: 'Wallis-et-Futuna',
  YE: 'Yémen',
  ZW: 'Zimbabwe',
  AE: 'Émirats Arabes Unis',
  UM: 'États-Unis Îles Mineures Éloignées',
  US: "États-unis d'Amérique",
  KY: 'Îles Caïmans',
  CC: 'Îles Cocos (Keeling) ',
  CK: 'Îles Cook',
  FK: 'Îles Falkland (Malvinas)',
  FO: 'Îles Féroé',
  MP: 'Îles Mariannes Du Nord',
  MH: 'Îles Marshall',
  SB: 'Îles Salomon',
  VG: 'Îles Vierges (Britanniques)',
  TC: 'Îles turques et Caïques',
  VI: 'Îles vierges (états-UNIS)',
};

export const COUNTRIES_DE: IDictionary<string> = {
  AF: 'Afghanistan',
  AL: 'Albanien',
  DZ: 'Algerien',
  AS: 'Amerikanisch-Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarktis',
  AG: 'Antigua und Barbuda',
  AR: 'Argentinien',
  AM: 'Armenien',
  AW: 'Aruba',
  AU: 'Australien',
  AT: 'Österreich',
  AZ: 'Aserbaidschan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesch',
  BB: 'Barbados',
  BY: 'Weißrussland',
  BE: 'Belgien',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivien (Plurinationaler Staat)',
  BQ: 'Bonaire, Sint Eustatius und Saba',
  BA: 'Bosnien und Herzegowina',
  BW: 'Botswana',
  BV: 'Bouvetinsel',
  BR: 'Brasilien',
  IO: 'Britisches Territorium im Indischen Ozean',
  BN: 'Brunei Darussalam',
  BG: 'Bulgarien',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Kambodscha',
  CM: 'Kamerun',
  CA: 'Kanada',
  KY: 'Kaimaninseln',
  CF: 'Zentralafrikanische Republik',
  TD: 'Tschad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Weihnachtsinsel',
  CC: 'Kokosinseln (Keeling)',
  CO: 'Kolumbien',
  KM: 'Komoren',
  CG: 'Kongo',
  CD: 'Kongo (Demokratische Republik Kongo)',
  CK: 'Cookinseln',
  CR: 'Costa Rica',
  HR: 'Kroatien',
  CU: 'Kuba',
  CW: 'Curaçao',
  CY: 'Zypern',
  CZ: 'Tschechien',
  CI: 'Elfenbeinküste',
  DK: 'Dänemark',
  DJ: 'Dschibuti',
  DM: 'Dominica',
  DO: 'Dominikanische Republik',
  EC: 'Ecuador',
  EG: 'Ägypten',
  SV: 'El Salvador',
  GQ: 'Äquatorialguinea',
  ER: 'Eritrea',
  EE: 'Estland',
  ET: 'Äthiopien',
  FK: 'Falklandinseln (Malvinas)',
  FO: 'Färöer-Inseln',
  FJ: 'Fidschi',
  FI: 'Finnland',
  FR: 'Frankreich',
  GF: 'Französisch-Guayana',
  PF: 'Französisch-Polynesien',
  TF: 'Französische Süd- und Antarktisgebiete',
  GA: 'Gabun',
  GM: 'Gambia',
  GE: 'Georgien',
  DE: 'Deutschland',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Griechenland',
  GL: 'Grönland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Heiliger Stuhl (Vatikanstadt)',
  HN: 'Honduras',
  HK: 'Hongkong',
  HU: 'Ungarn',
  IS: 'Island',
  IN: 'Indien',
  ID: 'Indonesien',
  IR: 'Iran (Islamische Republik)',
  IQ: 'Irak',
  IE: 'Irland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italien',
  JM: 'Jamaika',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordanien',
  KZ: 'Kasachstan',
  KE: 'Kenia',
  KI: 'Kiribati',
  KP: 'Korea (Demokratische Volksrepublik)',
  KR: 'Korea (Republik)',
  KW: 'Kuwait',
  KG: 'Kirgisistan',
  LA: 'Laos',
  LV: 'Lettland',
  LB: 'Libanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyen',
  LI: 'Liechtenstein',
  LT: 'Litauen',
  LU: 'Luxemburg',
  MO: 'Macao',
  MK: 'Mazedonien (ehemalige jugoslawische Republik)',
  MG: 'Madagaskar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Malediven',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshallinseln',
  MQ: 'Martinique',
  MR: 'Mauretanien',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexiko',
  FM: 'Mikronesien (Föderierte Staaten)',
  MD: 'Moldawien (Republik)',
  MC: 'Monaco',
  MN: 'Mongolei',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Marokko',
  MZ: 'Mosambik',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Niederlande',
  NC: 'Neukaledonien',
  NZ: 'Neuseeland',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolkinsel',
  MP: 'Nördliche Marianen',
  NO: 'Norwegen',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palästina, Staat',
  PA: 'Panama',
  PG: 'Papua-Neuguinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippinen',
  PN: 'Pitcairn',
  PL: 'Polen',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Katar',
  RO: 'Rumänien',
  RU: 'Russische Föderation',
  RW: 'Ruanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'St. Helena, Ascension und Tristan da Cunha',
  KN: 'St. Kitts und Nevis',
  LC: 'St. Lucia',
  MF: 'St. Martin (französischer Teil)',
  PM: 'St. Pierre und Miquelon',
  VC: 'St. Vincent und die Grenadinen',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'São Tomé und Príncipe',
  SA: 'Saudi-Arabien',
  SN: 'Senegal',
  RS: 'Serbien',
  SC: 'Seychellen',
  SL: 'Sierra Leone',
  SG: 'Singapur',
  SX: 'Sint Maarten (niederländischer Teil)',
  SK: 'Slowakei',
  SI: 'Slowenien',
  SB: 'Salomonen',
  SO: 'Somalia',
  ZA: 'Südafrika',
  GS: 'Südgeorgien und die Südlichen Sandwichinseln',
  SS: 'Südsudan',
  ES: 'Spanien',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard und Jan Mayen',
  SZ: 'Swasiland',
  SE: 'Schweden',
  CH: 'Schweiz',
  SY: 'Syrien',
  TW: 'Taiwan',
  TJ: 'Tadschikistan',
  TZ: 'Tansania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad und Tobago',
  TN: 'Tunesien',
  TR: 'Türkei',
  TM: 'Turkmenistan',
  TC: 'Turks- und Caicosinseln',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'Vereinigte Arabische Emirate',
  GB: 'Vereinigtes Königreich',
  UM: 'United States Minor Outlying Islands',
  US: 'Vereinigte Staaten von Amerika',
  UY: 'Uruguay',
  UZ: 'Usbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Britische Jungferninseln',
  VI: 'Amerikanische Jungferninseln',
  WF: 'Wallis und Futuna',
  EH: 'Westsahara',
  YE: 'Jemen',
  ZM: 'Sambia',
  ZW: 'Simbabwe',
  AX: 'Åland-Inseln',
};
export const COUNTRIES_IT: IDictionary<string> = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'Samoa Americane',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antartide',
  AG: 'Antigua e Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaigian',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Bielorussia',
  BE: 'Belgio',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Stato Plurinazionale)',
  BQ: 'Bonaire, Sint Eustatius e Saba',
  BA: 'Bosnia ed Erzegovina',
  BW: 'Botswana',
  BV: 'Isola Bouvet',
  BR: 'Brasile',
  IO: "Territorio Britannico dell'Oceano Indiano",
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Capo Verde',
  KH: 'Cambogia',
  CM: 'Camerun',
  CA: 'Canada',
  KY: 'Isole Cayman',
  CF: 'Repubblica Centrafricana',
  TD: 'Ciad',
  CL: 'Cile',
  CN: 'Cina',
  CX: 'Isola di Natale',
  CC: 'Isole Cocos (Keeling)',
  CO: 'Colombia',
  KM: 'Comore',
  CG: 'Congo',
  CD: 'Congo (Repubblica Democratica del)',
  CK: 'Isole Cook',
  CR: 'Costa Rica',
  HR: 'Croazia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cipro',
  CZ: 'Cechia',
  CI: "Costa d'Avorio",
  DK: 'Danimarca',
  DJ: 'Gibuti',
  DM: 'Dominica',
  DO: 'Repubblica Dominicana',
  EC: 'Ecuador',
  EG: 'Egitto',
  SV: 'El Salvador',
  GQ: 'Guinea Equatoriale',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Etiopia',
  FK: 'Isole Falkland (Malvine)',
  FO: 'Isole Fær Øer',
  FJ: 'Figi',
  FI: 'Finlandia',
  FR: 'Francia',
  GF: 'Guyana Francese',
  PF: 'Polinesia Francese',
  TF: 'Territori Francesi del Sud',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germania',
  GH: 'Ghana',
  GI: 'Gibilterra',
  GR: 'Grecia',
  GL: 'Groenlandia',
  GD: 'Grenada',
  GP: 'Guadalupa',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Isole Heard e McDonald',
  VA: 'Santa Sede (Città del Vaticano)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Ungheria',
  IS: 'Islanda',
  IN: 'India',
  ID: 'Indonesia',
  IR: "Iran (Repubblica Islamica dell')",
  IQ: 'Iraq',
  IE: 'Irlanda',
  IM: 'Isola di Man',
  IL: 'Israele',
  IT: 'Italia',
  JM: 'Giamaica',
  JP: 'Giappone',
  JE: 'Jersey',
  JO: 'Giordania',
  KZ: 'Kazakistan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'Corea (Repubblica Popolare Democratica di)',
  KR: 'Corea (Repubblica di)',
  KW: 'Kuwait',
  KG: 'Kirghizistan',
  LA: 'Repubblica Democratica Popolare Lao',
  LV: 'Lettonia',
  LB: 'Libano',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libia',
  LI: 'Liechtenstein',
  LT: 'Lituania',
  LU: 'Lussemburgo',
  MO: 'Macao',
  MK: 'Macedonia (ex Repubblica Iugoslava di)',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malesia',
  MV: 'Maldive',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Isole Marshall',
  MQ: 'Martinica',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Messico',
  FM: 'Micronesia (Stati Federati di)',
  MD: 'Moldavia (Repubblica di)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Marocco',
  MZ: 'Mozambico',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Paesi Bassi',
  NC: 'Nuova Caledonia',
  NZ: 'Nuova Zelanda',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Isola Norfolk',
  MP: 'Isole Marianne Settentrionali',
  NO: 'Norvegia',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestina (Stato di)',
  PA: 'Panama',
  PG: 'Papua Nuova Guinea',
  PY: 'Paraguay',
  PE: 'Perù',
  PH: 'Filippine',
  PN: 'Pitcairn',
  PL: 'Polonia',
  PT: 'Portogallo',
  PR: 'Porto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Federazione Russa',
  RW: 'Ruanda',
  RE: 'Riunione',
  BL: 'Saint-Barthélemy',
  SH: "Sant'Elena, Ascensione e Tristan da Cunha",
  KN: 'Saint Kitts e Nevis',
  LC: 'Santa Lucia',
  MF: 'Saint Martin (parte francese)',
  PM: 'Saint Pierre e Miquelon',
  VC: 'Saint Vincent e Grenadine',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'São Tomé e Príncipe',
  SA: 'Arabia Saudita',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (parte olandese)',
  SK: 'Slovacchia',
  SI: 'Slovenia',
  SB: 'Isole Salomone',
  SO: 'Somalia',
  ZA: 'Sudafrica',
  GS: 'Georgia del Sud e Isole Sandwich del Sud',
  SS: 'Sudan del Sud',
  ES: 'Spagna',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard e Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Svezia',
  CH: 'Svizzera',
  SY: 'Repubblica Araba Siriana',
  TW: 'Taiwan, Provincia di Cina',
  TJ: 'Tagikistan',
  TZ: 'Tanzania, Repubblica Unita di',
  TH: 'Thailandia',
  TL: 'Timor Est',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad e Tobago',
  TN: 'Tunisia',
  TR: 'Turchia',
  TM: 'Turkmenistan',
  TC: 'Isole Turks e Caicos',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ucraina',
  AE: 'Emirati Arabi Uniti',
  GB: 'Regno Unito di Gran Bretagna',
  UM: 'Isole minori esterne degli Stati Uniti',
  US: "Stati Uniti d'America",
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Repubblica Bolivariana di)',
  VN: 'Vietnam',
  VG: 'Isole Vergini Britanniche',
  VI: 'Isole Vergini Americane',
  WF: 'Wallis e Futuna',
  EH: 'Sahara Occidentale',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Isole Åland',
};

export const COUNTRIES_FR_CA: IDictionary<string> = {
  AF: 'Afghanistan',
  AL: 'Albanie',
  DZ: 'Algérie',
  AS: 'Samoa américaines',
  AD: 'Andorre',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctique',
  AG: 'Antigua-et-Barbuda',
  AR: 'Argentine',
  AM: 'Arménie',
  AW: 'Aruba',
  AU: 'Australie',
  AT: 'Autriche',
  AZ: 'Azerbaïdjan',
  BS: 'Bahamas',
  BH: 'Bahreïn',
  BD: 'Bangladesh',
  BB: 'Barbade',
  BY: 'Biélorussie',
  BE: 'Belgique',
  BZ: 'Belize',
  BJ: 'Bénin',
  BM: 'Bermudes',
  BT: 'Bhoutan',
  BO: 'Bolivie (État plurinational de)',
  BQ: 'Bonaire, Saint-Eustache et Saba',
  BA: 'Bosnie-Herzégovine',
  BW: 'Botswana',
  BV: 'Île Bouvet',
  BR: 'Brésil',
  IO: "Territoire britannique de l'océan Indien",
  BN: 'Brunei Darussalam',
  BG: 'Bulgarie',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodge',
  CM: 'Cameroun',
  CA: 'Canada',
  KY: 'Îles Caïmans',
  CF: 'République centrafricaine',
  TD: 'Tchad',
  CL: 'Chili',
  CN: 'Chine',
  CX: 'Île Christmas',
  CC: 'Îles Cocos (Keeling)',
  CO: 'Colombie',
  KM: 'Comores',
  CG: 'Congo',
  CD: 'Congo (République démocratique du)',
  CK: 'Îles Cook',
  CR: 'Costa Rica',
  HR: 'Croatie',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Chypre',
  CZ: 'Tchéquie',
  CI: "Côte d'Ivoire",
  DK: 'Danemark',
  DJ: 'Djibouti',
  DM: 'Dominique',
  DO: 'République dominicaine',
  EC: 'Équateur',
  EG: 'Égypte',
  SV: 'El Salvador',
  GQ: 'Guinée équatoriale',
  ER: 'Érythrée',
  EE: 'Estonie',
  ET: 'Éthiopie',
  FK: 'Îles Falkland (Malvinas)',
  FO: 'Îles Féroé',
  FJ: 'Fidji',
  FI: 'Finlande',
  FR: 'France',
  GF: 'Guyane française',
  PF: 'Polynésie française',
  TF: 'Territoires austraux français',
  GA: 'Gabon',
  GM: 'Gambie',
  GE: 'Géorgie',
  DE: 'Allemagne',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grèce',
  GL: 'Groenland',
  GD: 'Grenade',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernesey',
  GN: 'Guinée',
  GW: 'Guinée-Bissau',
  GY: 'Guyana',
  HT: 'Haïti',
  HM: 'Îles Heard et McDonald',
  VA: 'Saint-Siège',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hongrie',
  IS: 'Islande',
  IN: 'Inde',
  ID: 'Indonésie',
  IR: "Iran (République islamique d')",
  IQ: 'Irak',
  IE: 'Irlande',
  IM: 'Île de Man',
  IL: 'Israël',
  IT: 'Italie',
  JM: 'Jamaïque',
  JP: 'Japon',
  JE: 'Jersey',
  JO: 'Jordanie',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'Corée (République populaire démocratique de)',
  KR: 'Corée (République de)',
  KW: 'Koweït',
  KG: 'Kirghizistan',
  LA: 'République démocratique populaire lao',
  LV: 'Lettonie',
  LB: 'Liban',
  LS: 'Lesotho',
  LR: 'Libéria',
  LY: 'Libye',
  LI: 'Liechtenstein',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macédoine (ex-République yougoslave de)',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaisie',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malte',
  MH: 'Îles Marshall',
  MQ: 'Martinique',
  MR: 'Mauritanie',
  MU: 'Maurice',
  YT: 'Mayotte',
  MX: 'Mexique',
  FM: 'Micronésie (États fédérés de)',
  MD: 'Moldavie (République de)',
  MC: 'Monaco',
  MN: 'Mongolie',
  ME: 'Monténégro',
  MS: 'Montserrat',
  MA: 'Maroc',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibie',
  NR: 'Nauru',
  NP: 'Népal',
  NL: 'Pays-Bas',
  NC: 'Nouvelle-Calédonie',
  NZ: 'Nouvelle-Zélande',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigéria',
  NU: 'Niue',
  NF: 'Île Norfolk',
  MP: 'Îles Mariannes du Nord',
  NO: 'Norvège',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palaos',
  PS: 'Palestine, État de',
  PA: 'Panama',
  PG: 'Papouasie-Nouvelle-Guinée',
  PY: 'Paraguay',
  PE: 'Pérou',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Pologne',
  PT: 'Portugal',
  PR: 'Porto Rico',
  QA: 'Qatar',
  RO: 'Roumanie',
  RU: 'Fédération de Russie',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint-Barthélemy',
  SH: 'Sainte-Hélène, Ascension et Tristan da Cunha',
  KN: 'Saint-Kitts-et-Nevis',
  LC: 'Sainte-Lucie',
  MF: 'Saint-Martin (partie française)',
  PM: 'Saint-Pierre-et-Miquelon',
  VC: 'Saint-Vincent-et-les Grenadines',
  WS: 'Samoa',
  SM: 'Saint-Marin',
  ST: 'Sao Tomé-et-Principe',
  SA: 'Arabie saoudite',
  SN: 'Sénégal',
  RS: 'Serbie',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapour',
  SX: 'Saint-Martin (partie néerlandaise)',
  SK: 'Slovaquie',
  SI: 'Slovénie',
  SB: 'Îles Salomon',
  SO: 'Somalie',
  ZA: 'Afrique du Sud',
  GS: 'Géorgie du Sud et les Îles Sandwich du Sud',
  SS: 'Soudan du Sud',
  ES: 'Espagne',
  LK: 'Sri Lanka',
  SD: 'Soudan',
  SR: 'Suriname',
  SJ: 'Svalbard et Jan Mayen',
  SZ: 'Eswatini',
  SE: 'Suède',
  CH: 'Suisse',
  SY: 'République arabe syrienne',
  TW: 'Taïwan, Province de Chine[a]',
  TJ: 'Tadjikistan',
  TZ: 'Tanzanie, République-Unie de',
  TH: 'Thaïlande',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinité-et-Tobago',
  TN: 'Tunisie',
  TR: 'Turquie',
  TM: 'Turkménistan',
  TC: 'Îles Turques-et-Caïques',
  TV: 'Tuvalu',
  UG: 'Ouganda',
  UA: 'Ukraine',
  AE: 'Émirats arabes unis',
  GB: 'Royaume-Uni de Grande-Bretagne',
  UM: 'Îles mineures éloignées des États-Unis',
  US: "États-Unis d'Amérique",
  UY: 'Uruguay',
  UZ: 'Ouzbékistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (République bolivarienne du)',
  VN: 'Viet Nam',
  VG: 'Îles Vierges britanniques',
  VI: 'Îles Vierges des États-Unis',
  WF: 'Wallis-et-Futuna',
  EH: 'Sahara occidental',
  YE: 'Yémen',
  ZM: 'Zambie',
  ZW: 'Zimbabwe',
  AX: 'Îles Åland',
};

export const COUNTRIES_ES_ES: IDictionary<string> = {
  AF: 'Afganistán',
  AL: 'Albania',
  DZ: 'Argelia',
  AS: 'Samoa Americana',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguila',
  AQ: 'Antártida',
  AG: 'Antigua y Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaiyán',
  BS: 'Bahamas',
  BH: 'Baréin',
  BD: 'Bangladés',
  BB: 'Barbados',
  BY: 'Bielorrusia',
  BE: 'Bélgica',
  BZ: 'Belice',
  BJ: 'Benín',
  BM: 'Bermudas',
  BT: 'Bután',
  BO: 'Bolivia (Estado Plurinacional de)',
  BQ: 'Bonaire, San Eustaquio y Saba',
  BA: 'Bosnia y Herzegovina',
  BW: 'Botsuana',
  BV: 'Isla Bouvet',
  BR: 'Brasil',
  IO: 'Territorio Británico del Océano Índico',
  BN: 'Brunéi Darusalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Camboya',
  CM: 'Camerún',
  CA: 'Canadá',
  KY: 'Islas Caimán',
  CF: 'República Centroafricana',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Isla de Navidad',
  CC: 'Islas Cocos (Keeling)',
  CO: 'Colombia',
  KM: 'Comoras',
  CG: 'Congo',
  CD: 'Congo (República Democrática del)',
  CK: 'Islas Cook',
  CR: 'Costa Rica',
  HR: 'Croacia',
  CU: 'Cuba',
  CW: 'Curazao',
  CY: 'Chipre',
  CZ: 'Chequia',
  CI: 'Costa de Marfil',
  DK: 'Dinamarca',
  DJ: 'Yibuti',
  DM: 'Dominica',
  DO: 'República Dominicana',
  EC: 'Ecuador',
  EG: 'Egipto',
  SV: 'El Salvador',
  GQ: 'Guinea Ecuatorial',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Etiopía',
  FK: 'Islas Malvinas (Falkland)',
  FO: 'Islas Feroe',
  FJ: 'Fiyi',
  FI: 'Finlandia',
  FR: 'Francia',
  GF: 'Guayana Francesa',
  PF: 'Polinesia Francesa',
  TF: 'Territorios Australes Franceses',
  GA: 'Gabón',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Alemania',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grecia',
  GL: 'Groenlandia',
  GD: 'Granada',
  GP: 'Guadalupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bisáu',
  GY: 'Guyana',
  HT: 'Haití',
  HM: 'Islas Heard y McDonald',
  VA: 'Santa Sede',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungría',
  IS: 'Islandia',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Irán (República Islámica de)',
  IQ: 'Irak',
  IE: 'Irlanda',
  IM: 'Isla de Man',
  IL: 'Israel',
  IT: 'Italia',
  JM: 'Jamaica',
  JP: 'Japón',
  JE: 'Jersey',
  JO: 'Jordania',
  KZ: 'Kazajistán',
  KE: 'Kenia',
  KI: 'Kiribati',
  KP: 'Corea (República Popular Democrática de)',
  KR: 'Corea (República de)',
  KW: 'Kuwait',
  KG: 'Kirguistán',
  LA: 'República Democrática Popular Lao',
  LV: 'Letonia',
  LB: 'Líbano',
  LS: 'Lesoto',
  LR: 'Liberia',
  LY: 'Libia',
  LI: 'Liechtenstein',
  LT: 'Lituania',
  LU: 'Luxemburgo',
  MO: 'Macao',
  MK: 'Macedonia (ex República Yugoslava de)',
  MG: 'Madagascar',
  MW: 'Malaui',
  MY: 'Malasia',
  MV: 'Maldivas',
  ML: 'Malí',
  MT: 'Malta',
  MH: 'Islas Marshall',
  MQ: 'Martinica',
  MR: 'Mauritania',
  MU: 'Mauricio',
  YT: 'Mayotte',
  MX: 'México',
  FM: 'Micronesia (Estados Federados de)',
  MD: 'Moldavia (República de)',
  MC: 'Mónaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Marruecos',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Países Bajos',
  NC: 'Nueva Caledonia',
  NZ: 'Nueva Zelanda',
  NI: 'Nicaragua',
  NE: 'Níger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Isla Norfolk',
  MP: 'Islas Marianas del Norte',
  NO: 'Noruega',
  OM: 'Omán',
  PK: 'Pakistán',
  PW: 'Palaos',
  PS: 'Palestina, Estado de',
  PA: 'Panamá',
  PG: 'Papúa Nueva Guinea',
  PY: 'Paraguay',
  PE: 'Perú',
  PH: 'Filipinas',
  PN: 'Pitcairn',
  PL: 'Polonia',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Catar',
  RO: 'Rumanía',
  RU: 'Federación de Rusia',
  RW: 'Ruanda',
  RE: 'Reunión',
  BL: 'San Bartolomé',
  SH: 'Santa Elena, Ascensión y Tristán de Acuña',
  KN: 'San Cristóbal y Nieves',
  LC: 'Santa Lucía',
  MF: 'San Martín (parte francesa)',
  PM: 'San Pedro y Miquelón',
  VC: 'San Vicente y las Granadinas',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Santo Tomé y Príncipe',
  SA: 'Arabia Saudita',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leona',
  SG: 'Singapur',
  SX: 'San Martín (parte holandesa)',
  SK: 'Eslovaquia',
  SI: 'Eslovenia',
  SB: 'Islas Salomón',
  SO: 'Somalia',
  ZA: 'Sudáfrica',
  GS: 'Georgia del Sur y las Islas Sandwich del Sur',
  SS: 'Sudán del Sur',
  ES: 'España',
  LK: 'Sri Lanka',
  SD: 'Sudán',
  SR: 'Surinam',
  SJ: 'Svalbard y Jan Mayen',
  SZ: 'Suazilandia',
  SE: 'Suecia',
  CH: 'Suiza',
  SY: 'República Árabe Siria',
  TW: 'Taiwán, Provincia de China[a]',
  TJ: 'Tayikistán',
  TZ: 'Tanzania, República Unida de',
  TH: 'Tailandia',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad y Tobago',
  TN: 'Túnez',
  TR: 'Turquía',
  TM: 'Turkmenistán',
  TC: 'Islas Turcas y Caicos',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ucrania',
  AE: 'Emiratos Árabes Unidos',
  GB: 'Reino Unido de Gran Bretaña',
  UM: 'Islas Ultramarinas Menores de Estados Unidos',
  US: 'Estados Unidos de América',
  UY: 'Uruguay',
  UZ: 'Uzbekistán',
  VU: 'Vanuatu',
  VE: 'Venezuela (República Bolivariana de)',
  VN: 'Vietnam',
  VG: 'Islas Vírgenes (Británicas)',
  VI: 'Islas Vírgenes (EE.UU.)',
  WF: 'Wallis y Futuna',
  EH: 'Sahara Occidental',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabue',
  AX: 'Islas Åland',
};

export const COUNTRIES_ES_MX: IDictionary<string> = {
  AF: 'Afganistán',
  AL: 'Albania',
  DZ: 'Argelia',
  AS: 'Samoa Americana',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguila',
  AQ: 'Antártida',
  AG: 'Antigua y Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaiyán',
  BS: 'Bahamas',
  BH: 'Baréin',
  BD: 'Bangladés',
  BB: 'Barbados',
  BY: 'Bielorrusia',
  BE: 'Bélgica',
  BZ: 'Belice',
  BJ: 'Benín',
  BM: 'Bermudas',
  BT: 'Bután',
  BO: 'Bolivia (Estado Plurinacional de)',
  BQ: 'Bonaire, San Eustaquio y Saba',
  BA: 'Bosnia y Herzegovina',
  BW: 'Botsuana',
  BV: 'Isla Bouvet',
  BR: 'Brasil',
  IO: 'Territorio Británico del Océano Índico',
  BN: 'Brunéi Darusalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Camboya',
  CM: 'Camerún',
  CA: 'Canadá',
  KY: 'Islas Caimán',
  CF: 'República Centroafricana',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Isla de Navidad',
  CC: 'Islas Cocos (Keeling)',
  CO: 'Colombia',
  KM: 'Comoras',
  CG: 'Congo',
  CD: 'Congo (República Democrática del)',
  CK: 'Islas Cook',
  CR: 'Costa Rica',
  HR: 'Croacia',
  CU: 'Cuba',
  CW: 'Curazao',
  CY: 'Chipre',
  CZ: 'Chequia',
  CI: 'Costa de Marfil',
  DK: 'Dinamarca',
  DJ: 'Yibuti',
  DM: 'Dominica',
  DO: 'República Dominicana',
  EC: 'Ecuador',
  EG: 'Egipto',
  SV: 'El Salvador',
  GQ: 'Guinea Ecuatorial',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Etiopía',
  FK: 'Islas Malvinas (Falkland)',
  FO: 'Islas Feroe',
  FJ: 'Fiyi',
  FI: 'Finlandia',
  FR: 'Francia',
  GF: 'Guayana Francesa',
  PF: 'Polinesia Francesa',
  TF: 'Territorios Australes Franceses',
  GA: 'Gabón',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Alemania',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grecia',
  GL: 'Groenlandia',
  GD: 'Granada',
  GP: 'Guadalupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bisáu',
  GY: 'Guyana',
  HT: 'Haití',
  HM: 'Islas Heard y McDonald',
  VA: 'Santa Sede',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungría',
  IS: 'Islandia',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Irán (República Islámica de)',
  IQ: 'Irak',
  IE: 'Irlanda',
  IM: 'Isla de Man',
  IL: 'Israel',
  IT: 'Italia',
  JM: 'Jamaica',
  JP: 'Japón',
  JE: 'Jersey',
  JO: 'Jordania',
  KZ: 'Kazajistán',
  KE: 'Kenia',
  KI: 'Kiribati',
  KP: 'Corea (República Popular Democrática de)',
  KR: 'Corea (República de)',
  KW: 'Kuwait',
  KG: 'Kirguistán',
  LA: 'República Democrática Popular Lao',
  LV: 'Letonia',
  LB: 'Líbano',
  LS: 'Lesoto',
  LR: 'Liberia',
  LY: 'Libia',
  LI: 'Liechtenstein',
  LT: 'Lituania',
  LU: 'Luxemburgo',
  MO: 'Macao',
  MK: 'Macedonia (ex República Yugoslava de)',
  MG: 'Madagascar',
  MW: 'Malaui',
  MY: 'Malasia',
  MV: 'Maldivas',
  ML: 'Malí',
  MT: 'Malta',
  MH: 'Islas Marshall',
  MQ: 'Martinica',
  MR: 'Mauritania',
  MU: 'Mauricio',
  YT: 'Mayotte',
  MX: 'México',
  FM: 'Micronesia (Estados Federados de)',
  MD: 'Moldavia (República de)',
  MC: 'Mónaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Marruecos',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Países Bajos',
  NC: 'Nueva Caledonia',
  NZ: 'Nueva Zelanda',
  NI: 'Nicaragua',
  NE: 'Níger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Isla Norfolk',
  MP: 'Islas Marianas del Norte',
  NO: 'Noruega',
  OM: 'Omán',
  PK: 'Pakistán',
  PW: 'Palaos',
  PS: 'Palestina, Estado de',
  PA: 'Panamá',
  PG: 'Papúa Nueva Guinea',
  PY: 'Paraguay',
  PE: 'Perú',
  PH: 'Filipinas',
  PN: 'Pitcairn',
  PL: 'Polonia',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Catar',
  RO: 'Rumanía',
  RU: 'Federación de Rusia',
  RW: 'Ruanda',
  RE: 'Reunión',
  BL: 'San Bartolomé',
  SH: 'Santa Elena, Ascensión y Tristán de Acuña',
  KN: 'San Cristóbal y Nieves',
  LC: 'Santa Lucía',
  MF: 'San Martín (parte francesa)',
  PM: 'San Pedro y Miquelón',
  VC: 'San Vicente y las Granadinas',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Santo Tomé y Príncipe',
  SA: 'Arabia Saudita',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leona',
  SG: 'Singapur',
  SX: 'San Martín (parte holandesa)',
  SK: 'Eslovaquia',
  SI: 'Eslovenia',
  SB: 'Islas Salomón',
  SO: 'Somalia',
  ZA: 'Sudáfrica',
  GS: 'Georgia del Sur y las Islas Sandwich del Sur',
  SS: 'Sudán del Sur',
  ES: 'España',
  LK: 'Sri Lanka',
  SD: 'Sudán',
  SR: 'Surinam',
  SJ: 'Svalbard y Jan Mayen',
  SZ: 'Suazilandia',
  SE: 'Suecia',
  CH: 'Suiza',
  SY: 'República Árabe Siria',
  TW: 'Taiwán, Provincia de China[a]',
  TJ: 'Tayikistán',
  TZ: 'Tanzania, República Unida de',
  TH: 'Tailandia',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad y Tobago',
  TN: 'Túnez',
  TR: 'Turquía',
  TM: 'Turkmenistán',
  TC: 'Islas Turcas y Caicos',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ucrania',
  AE: 'Emiratos Árabes Unidos',
  GB: 'Reino Unido de Gran Bretaña',
  UM: 'Islas Ultramarinas Menores de Estados Unidos',
  US: 'Estados Unidos de América',
  UY: 'Uruguay',
  UZ: 'Uzbekistán',
  VU: 'Vanuatu',
  VE: 'Venezuela (República Bolivariana de)',
  VN: 'Vietnam',
  VG: 'Islas Vírgenes (Británicas)',
  VI: 'Islas Vírgenes (EE.UU.)',
  WF: 'Wallis y Futuna',
  EH: 'Sahara Occidental',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabue',
  AX: 'Islas Åland',
};

export const COUNTRIES_LANGUAGE_MAPPING = {
  'en-us': COUNTRIES,
  'en-gb': COUNTRIES_EN_GB,
  'fr-fr': COUNTRIES_FR,
  'de-de': COUNTRIES_DE,
  'it-it': COUNTRIES_IT,
  'fr-ca': COUNTRIES_FR_CA,
  'es-es': COUNTRIES_ES_ES,
  'es-mx': COUNTRIES_ES_MX,
};
