import { Panel, PanelType } from '@fluentui/react';
import React from 'react';
import AppMessages from '../../../../../../App.messages';
import { FormState } from '../../../../../../components/Form';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../../../utils';
import { getAdunitStore } from '../../../@data';
import { onPanelOpenChanged } from '../../../@data/actions';
import { onScriptPageFormCleared } from '../../../TythonAdunit/components/ScriptPage/@data/actions/onScriptPageActions';
import { NativeAdScriptPage } from '../../../TythonAdunit/components/ScriptPage/ScriptPage';

export interface ICodePanelProps extends IRouterWithIntl {}

export const CodePanel = injectIntlAndObserver(function CodePanelComponent(props: ICodePanelProps) {
  const { isPanelOpen, scriptProps } = getAdunitStore();

  return (
    <Panel
      isOpen={isPanelOpen}
      type={PanelType.custom}
      customWidth={'900px'}
      onDismiss={() => {
        onPanelOpenChanged(false);
        onScriptPageFormCleared();
      }}
      isLightDismiss
      closeButtonAriaLabel={props.intl.formatMessage(AppMessages.close)}
    >
      <NativeAdScriptPage
        {...props}
        mode={FormState.InPageView}
        adunitId={scriptProps?.adunitId?.toString()}
        onSubmit={() => onPanelOpenChanged(false)}
        onCancel={() => onPanelOpenChanged(false)}
        siteId={scriptProps?.propertyId!}
      />
    </Panel>
  );
});
