import { Checkbox, ISearchBoxProps, mergeStyleSets, SearchBox } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { NullableJSXElement } from '../../types';
import { CheckboxGroup, ICheckbox, ICheckboxGroup, ICheckboxGroupProps } from '../CheckboxGroup';
import { CheckboxGroupStyles } from '../CheckboxGroup/CheckboxGroup.styles';
import { ConditionalTooltip } from '../ConditionalTooltip';
import { getClassNames, ICheckboxTreeStyles } from './CheckboxTree.styles';

export type GroupedCheckbox = ICheckboxGroup | ICheckbox;

export interface ICheckboxTreeProps
  extends Pick<ICheckboxGroupProps, 'onParentCheckboxChange' | 'onChildCheckboxChange' | 'disabledCheckboxTooltipText'> {
  groups: GroupedCheckbox[];
  styleProps?: Partial<ICheckboxTreeStyles & CheckboxGroupStyles>;
  selectedText?: string;
  suppressSearch?: boolean;
  searchPlaceholderText?: string;
  onSearch?: (value: string) => void;
  onSearchTermChange?: (ev: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onSearchClear?: ISearchBoxProps['onSearch'];
}

// Narrowing using type predicates. Refer: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export function isCheckboxGroup(group: GroupedCheckbox): group is ICheckboxGroup {
  return (group as ICheckboxGroup).children !== undefined;
}

export function CheckboxTree({
  groups,
  styleProps,
  onChildCheckboxChange,
  onParentCheckboxChange,
  selectedText,
  onSearch,
  onSearchClear,
  onSearchTermChange,
  suppressSearch,
  searchPlaceholderText,
  disabledCheckboxTooltipText,
}: ICheckboxTreeProps) {
  const {
    singleCheckboxStyle,
    checkboxContainerStyle,
    checkboxTreeContainerStyle,
    searchBoxRootStyle,
    selectedBlockStyle,
  } = mergeStyleSets(getClassNames(), styleProps);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (value: string) => {
    onSearch?.(value);
  };

  const handleSearchTermChange = (ev: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSearchTerm(value);
    onSearchTermChange?.(ev, value);
  };

  const getFilteredGroups = useCallback((): GroupedCheckbox[] => {
    if (!searchTerm) {
      return groups;
    }

    return groups.filter((group) => {
      const { label = '' } = group;
      if (isCheckboxGroup(group)) {
        return group.children.some((child) => (child.label ?? '').toLowerCase().includes(searchTerm.toLowerCase()));
      }
      return label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [groups, searchTerm]);

  const handleSearchClear = (ev) => {
    setSearchTerm('');
    onSearchClear?.(ev);
  };

  const renderSearchBox = (): NullableJSXElement => {
    if (suppressSearch) {
      return null;
    }

    return (
      <SearchBox
        styles={{ root: searchBoxRootStyle }}
        placeholder={searchPlaceholderText}
        onClear={handleSearchClear}
        onChange={handleSearchTermChange}
        onSearch={handleSearch}
      />
    );
  };

  const renderSelectedBlock = (): NullableJSXElement => {
    if (!selectedText) {
      return null;
    }

    return <div className={selectedBlockStyle}>{selectedText}</div>;
  };

  const getCheckboxId = (checkbox: ICheckbox) => `check-${checkbox.key}`;

  const renderCheckbox = (checkbox: ICheckbox): NullableJSXElement => {
    return (
      <Checkbox
        disabled={!!checkbox.disabled}
        className={singleCheckboxStyle}
        id={getCheckboxId(checkbox)}
        label={checkbox.label}
        checked={checkbox.checked}
        onChange={(event, checked) => onChildCheckboxChange(event, checked, checkbox)}
      />
    );
  };

  const renderCheckboxGroups = (): JSX.Element => {
    return (
      <div className={checkboxContainerStyle}>
        {renderSelectedBlock()}
        {getFilteredGroups().map((group: GroupedCheckbox) => {
          if (isCheckboxGroup(group)) {
            return (
              <CheckboxGroup
                key={group.key}
                group={group}
                onChildCheckboxChange={onChildCheckboxChange}
                onParentCheckboxChange={onParentCheckboxChange}
                disabledCheckboxTooltipText={disabledCheckboxTooltipText}
              />
            );
          } else {
            return (
              <ConditionalTooltip
                key={group.key}
                showTooltip={group.disabled}
                targetSelector={`#${getCheckboxId(group)}`}
                content={disabledCheckboxTooltipText}
              >
                {renderCheckbox(group)}
              </ConditionalTooltip>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className={checkboxTreeContainerStyle}>
      {renderSearchBox()}
      {renderCheckboxGroups()}
    </div>
  );
}
