import { FontWeights, IButtonStyles, ITheme } from '@fluentui/react';
import { BreakPoints, IPubcenterTheme } from '../../../theme';
import { IHeaderStyleProps, IHeaderStyles } from './Header.types';

const { ResponsiveExtraLarge, ResponsiveSmall } = BreakPoints;

export const getStyles = (props: IHeaderStyleProps): IHeaderStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, fonts, palette } = theme;

  return {
    root: {
      backgroundColor: props.isTythonExperience ? palette['NeutralBackground5.Rest'] : palette.headerGlobal,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
    },
    defaultButtonStyle: {
      borderRadius: '5px',
      whiteSpace: 'nowrap',
    },
    defaultButtonWrapperStyle: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'nowrap',
      flexGrow: '100',
      justifyContent: 'flex-end',
    },
    itemIcon: {
      fontSize: fonts.size16.fontSize,
      width: spacing.size48,
      height: spacing.size48,
      textAlign: 'center',
      lineHeight: spacing.size48,
      selectors: {
        ':hover': {
          background: palette.themeSecondary,
        },
      },
    },
    dot: {
      position: 'absolute',
      top: spacing.size12,
      marginLeft: spacing.size24,
      width: spacing.size10,
      height: spacing.size10,
      backgroundColor: 'red',
      borderRadius: '50%',
    },
    moreInfoIcon: {
      color: '#ffffff',
      [ResponsiveSmall]: {
        color: palette.neutralLight,
      },
    },
    overflowIcon: {
      color: '#000000',
    },
    overflowMenuWrapper: {
      '.ms-ContextualMenu-link': {
        ':hover': {
          '.ms-ContextualMenu-icon': {
            color: '#000000',
          },
        },
      },
    },
    navigationLm: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      // adding padding to accormodate the focus indicator outline within the header
      padding: `${spacing.size2} 0`,
    },
    leftSection: {
      [ResponsiveExtraLarge]: {
        marginRight: theme.spacing.s1,
      },
    },
    rightSection: {
      flexGrow: 1,
      justifyContent: 'flex-end',
      ...(props.isTythonExperience
        ? {
            '.ms-OverflowSet-item': {
              'i[role="img"]': { color: palette['NeutralForeground2.Rest'] },
              'i[role="img"]:hover': { background: palette['NeutralBackground3.Rest'] },
              '.ms-Persona:hover': { background: palette['NeutralBackground3.Rest'] },
            },
          }
        : {}),
      [ResponsiveSmall]: {
        zIndex: 9999,
      },
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      ...(props.isTythonExperience ? { color: palette['NeutralForeground1.Rest'] } : {}),
      marginLeft: theme.spacing.s1,
      marginRight: theme.spacing.s1,
      height: 44,
      selectors: {
        i: {
          fontSize: 28,
          marginRight: theme.spacing.s1,
        },
        span: {
          fontSize: fonts.size16.fontSize,
          fontWeight: FontWeights.semibold,
          marginRight: theme.spacing.s1,
          ...(props.isTythonExperience ? { marginLeft: theme.spacing.size30 } : {}),
        },
      },
      [ResponsiveSmall]: {
        paddingLeft: 0,
      },
    },
    helpCenterIcon: { color: '#ffffff' },
    textHeader: {
      marginLeft: 8,
    },
    dropdownOption: {
      marginRight: theme.spacing.s1,
    },
    navItem: {
      cursor: 'pointer',
    },
    subComponentStyles: {
      dropdown: {
        root: {
          width: 200,
          marginRight: theme.spacing.m,
        },
        dropdown: [
          {
            selectors: {
              '&:focus $title': {
                borderColor: palette.themeSecondary,
              },
            },
          },
        ],
      },
      itemIdentity: {
        root: {
          flexDirection: 'row-reverse',
          height: '100%',
          padding: '10px',
          marginRight: '10px',
          selectors: {
            '&:hover': {
              background: palette.themeSecondary,
            },
            '&:hover $primaryText': {
              color: 'white',
            },
          },
        },
        primaryText: {
          color: 'white',
        },
        secondaryText: {
          color: 'white',
        },
      },
    },
  };
};

export const getOverflowIconStyle = (theme: ITheme): Partial<IButtonStyles> => {
  return {
    root: {
      fontSize: 16,
      minWidth: 0,
      color: '#ffffff',
      width: 48,
      // adjusting the height with extra padding to accomodate the focus indicator outline within the header
      height: 44,
      textAlign: 'center',
      lineHeight: 44,
    },
    rootHovered: {
      background: theme.palette.themeSecondary,
    },
    rootExpanded: {
      background: theme.palette.themeSecondary,
    },
  };
};
