import { classNamesFunction, ICalloutProps, Label } from '@fluentui/react';
import * as React from 'react';
import AppMessages from '../../../App.messages';
import { InfoButton } from '../../InfoButton';
import { IFormLabelProps, IFormLabelStyleProps, IFormLabelStyles } from './FormLabel.types';

const getClassNames = classNamesFunction<IFormLabelStyleProps, IFormLabelStyles>();

export class FormLabelBase extends React.Component<IFormLabelProps> {
  public render(): JSX.Element {
    const {
      id,
      disableHeading = false,
      disableAriaLevel = false,
      styles,
      theme,
      labelContent,
      labelRequired,
      calloutContent,
      noInfoButton,
      calloutOnDismiss,
      iconButtonOnClick,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const classNames = getClassNames(styles, { theme: theme! });
    const calloutProps: ICalloutProps = {
      calloutWidth: 300,
    };
    return (
      <div className={classNames.root}>
        <Label
          id={id}
          required={labelRequired}
          styles={classNames.subComponentStyles.Label}
          role={disableHeading ? undefined : 'heading'}
          aria-level={disableAriaLevel ? undefined : 3}
        >
          {labelContent}
        </Label>
        {!noInfoButton ? (
          <InfoButton
            ariaLabel={formatMessage(AppMessages.infoLabel, { label: labelContent })}
            ariaDescription={formatMessage(AppMessages.infoDescription, { label: labelContent })}
            iconTitle="Information"
            calloutContent={calloutContent}
            calloutProps={calloutProps}
            iconButtonOnClick={iconButtonOnClick}
            calloutOnDismiss={calloutOnDismiss}
          />
        ) : null}
      </div>
    );
  }
}
