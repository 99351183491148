import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { isTythonSelfServeUser } from '../../../../@data';
import { CUSTOM_MESSAGE_CODE } from '../../../../@data/services/OneVetIntegrationService';
import { ExternalLink } from '../../../../components/ExternalLink';
import { APP_NAME_NEW, APP_NAME_PUBCENTER, SUPPORT_EMAIL, TYTHON_SUPPORT_EMAIL } from '../../../../constants/AppConstants';
import { InvalidUserErrorPage } from '../InvalidUser';
import { getClassNames } from '../InvalidUser.styles';
import messages from './NoAccessPage.messages';

interface INoAccessErrorPageProps extends InjectedIntlProps, RouteComponentProps {}

export const NoAccessErrorPage = injectIntl(function NoAccessErrorPageComp(props: INoAccessErrorPageProps): JSX.Element {
  const { formatMessage } = props.intl;
  const { title: titleStyle } = getClassNames();

  const renderTitle = (): JSX.Element => {
    return (
      <div>
        <h1>{formatMessage(messages.pageTitle)}</h1>
        <h2 className={titleStyle}>
          {formatMessage(messages.errorMessage, { appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER })}
        </h2>
        <p>
          {formatMessage(messages.errorDescriptionPart1)}
          <ExternalLink
            href={`mailto:${isTythonSelfServeUser() ? TYTHON_SUPPORT_EMAIL : SUPPORT_EMAIL}`}
            aria-label={formatMessage(messages.contactSupport)}
          >
            {formatMessage(messages.contactSupport)}
          </ExternalLink>
          {formatMessage(messages.errorDescriptionPart2, {
            messageCode: CUSTOM_MESSAGE_CODE,
            transactionId: sessionStorage.getItem('session_id'),
          })}
        </p>
      </div>
    );
  };

  return <InvalidUserErrorPage {...props} errorImageAltText={formatMessage(messages.imageAlt)} renderTitle={renderTitle} />;
});
