import { getDataFromURL } from '../@data/store/UrlQueryStore';
import { ReportsQueryStoreKeys } from '../@data/store/schema/ReportsQueryStoreKeys';
import { ICustomCalenderDateRange, RangeType } from '../components/CustomCalender/CustomCalender.types';
import { get7DaysExcludingToday } from '../components/CustomCalender/utils/CalenderUtils';
import { checkIfValidDateRange } from './checkIfValidDateRange';

export function getInitialDateRange() {
  const last7Days = get7DaysExcludingToday();
  let initialStartDate = last7Days[0];
  let initialEndDate = last7Days[last7Days.length - 1];
  const storedDateRange = getDataFromURL<ICustomCalenderDateRange>(ReportsQueryStoreKeys.DATE_RANGE)!;
  if (storedDateRange && checkIfValidDateRange(storedDateRange)) {
    initialStartDate = new Date(storedDateRange.startDate);
    initialEndDate = new Date(storedDateRange.endDate);
  }
  return { dateRange: { initialStartDate, initialEndDate }, initialDateRangeType: getInitialDateRangeType() };
}
export function getInitialDateRangeType() {
  const storedDateRangeType = getDataFromURL<RangeType>(ReportsQueryStoreKeys.DATE_RANGE_TYPE);
  let initialRangeType = RangeType.Last7ExcludingToday;
  if (storedDateRangeType && RangeType[storedDateRangeType]) {
    initialRangeType = storedDateRangeType;
  }
  return initialRangeType;
}
