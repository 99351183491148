import { IChoiceGroupOption } from '@fluentui/react';
import { mutatorAction } from 'satcheljs';
import { IStringDictionary } from '../../../../../../@data';
import { FeedbackType } from '../store/schema/enums/FeedbackType';
import { getFeedbackPanelStore } from '../store/store';

export const onFeedbackTypeChanged = mutatorAction('onFeedbackTypeChanged', (value?: IChoiceGroupOption) => {
  getFeedbackPanelStore().active.type = value?.key as FeedbackType;
});

export const onFeedbackTitleChanged = mutatorAction('onFeedbackTitleChanged', (title?: string) => {
  getFeedbackPanelStore().active.title = title;
});

export const onFeedbackDescriptionChanged = mutatorAction('onFeedbackDescriptionChanged', (description?: string) => {
  getFeedbackPanelStore().active.description = description;
});

export const onFeedbackEmailChanged = mutatorAction('onFeedbackEmailChanged', (email?: string) => {
  getFeedbackPanelStore().active.email = email;
});

export const onFeedbackPanelErrorChanged = mutatorAction('onFeedbackPanelErrorChanged', (values?: IStringDictionary) => {
  getFeedbackPanelStore().errors = values || {};
});

export const onFormCleared = mutatorAction('onFormCleared', () => {
  getFeedbackPanelStore().active = {
    type: FeedbackType.Appreciation,
  };
  getFeedbackPanelStore().isContactMeChecked = false;
  getFeedbackPanelStore().errors = {};
});

export const onFeedbackCheckboxChanged = mutatorAction('onFeedbackCheckboxChanged', (value: boolean) => {
  getFeedbackPanelStore().isContactMeChecked = value;
  if (!value) {
    delete getFeedbackPanelStore().errors.email;
  }
});

export const removeEmail = mutatorAction('removeEmail', () => {
  delete getFeedbackPanelStore().active.email;
});
