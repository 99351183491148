import { IStyle, classNamesFunction } from '@fluentui/react';
import React from 'react';
export interface IZeroTotalChartStyles {
  root: IStyle;
}
export const getStyles = (): IZeroTotalChartStyles => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '50px',
      minHeight: '200px',
      alignItems: 'center',
      userSelect: 'none',
    },
  };
};
const getClassNames = classNamesFunction<{}, IZeroTotalChartStyles>();
const classNames = getClassNames(getStyles);

export const ZeroTotalChart = () => {
  return <div className={classNames.root}>0</div>;
};
