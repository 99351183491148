import { Icon, Image } from '@fluentui/react';
import React from 'react';
import { useI18n } from '../../../../../../../i18n/useI18n';
import { getAppStore } from '../../../../../../@data';
import { injectRouterAndIntlWithObserver } from '../../../../../../utils';
import { getTythonReportDashboardPageRoute } from '../../../../../../utils/routeUtils';
import { TythonColumnsEnum } from '../../../../../report/@data/store/schema/ColumnsEnum';
import { TythonReportingDataRow } from '../../../../../report/DashboardCards/@data/store/schema';
import { constructRequestBody } from '../../../../../report/DashboardCards/ReportCard/ReportCard.helpers';
import { ChartGrain, ReportCardType } from '../../../../../report/DashboardCards/ReportCard/ReportCard.types';
import { useReportMetadata, useReportQuery } from '../../../../../report/hooks/useReportQuery';
import { getReportColumnMetadataDisplayName } from '../../../../../report/utils/intlHelper';
import { formatMetric } from '../../../../../report/utils/metricsFormatter';
import { getStore } from '../../../../Account/Settings/@data/store/store';
import dataUsage from '../resources/Data_Usage.svg';
import MetricItem from './MetricItem';
import { getClassNames } from './PerformanceCard.styles';
import messages from './messages';

const PerformanceCard = injectRouterAndIntlWithObserver(({ intl: { formatMessage }, history }) => {
  const DAYS_BACK: number = 30;
  const dateRange = {
    startDate: new Date(new Date().setDate(new Date().getDate() - DAYS_BACK)),
    endDate: new Date(),
  };
  const { data: reportMetadata } = useReportMetadata();
  const isMetadataLoaded = !!reportMetadata;
  const { data: summaryData } = useReportQuery(
    constructRequestBody(ReportCardType.SUMMARY, dateRange, ChartGrain.NONE, [], [], reportMetadata?.metrics || []),
    false,
    isMetadataLoaded
  );
  const isDataLoaded = !!summaryData;
  const summary = (summaryData && summaryData[0] ? summaryData[0] : { DateTime: '' }) as TythonReportingDataRow;
  const { publisher, userContext } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });

  const {
    cardContainerStyle,
    cardContentStyle,
    headerStyle,
    titleStyle,
    timeRangeStyle,
    metricsContainerStyle,
    metricStyle,
    iconContainerStyle,
    iconStyle,
    iconContentStyle,
    iconTextStyle,
  } = getClassNames();

  function handleViewReportButton() {
    history.push(getTythonReportDashboardPageRoute());
  }

  return (
    <>
      <div className={cardContainerStyle}>
        <div className={cardContentStyle}>
          <div className={headerStyle}>
            <h3 className={titleStyle}>{formatMessage(messages.performanceCardTitle)}</h3>
            <p className={timeRangeStyle}>{formatMessage(messages.metricsDaterange)}</p>
          </div>

          <div className={metricsContainerStyle}>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.Revenue, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.Revenue, summary.Revenue, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.FillRate, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.FillRate, summary.FillRate, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.ImpressionsServed, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.ImpressionsServed, summary.ImpressionsServed, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.Viewability, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.Viewability, summary.Viewability, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.ImpressionsViewed, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.ImpressionsViewed, summary.ImpressionsViewed, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.CTR, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.CTR, summary.CTR, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.eCPM, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.eCPM, summary.eCPM, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
            <div className={metricStyle}>
              <MetricItem
                title={(isMetadataLoaded && getReportColumnMetadataDisplayName(TythonColumnsEnum.Clicks, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.Clicks, summary.Clicks, locale)}`}
                isDataLoaded={isDataLoaded}
              />
            </div>
          </div>
          <div className={iconContainerStyle}>
            <Icon className={iconStyle} onClick={() => handleViewReportButton()}>
              <div className={iconContentStyle}>
                <Image src={dataUsage} />
                <span className={iconTextStyle}>{formatMessage(messages.viewReports)}</span>
              </div>
            </Icon>
          </div>
        </div>
      </div>
    </>
  );
});

export default PerformanceCard;
