export const PAID_SEARCH = 'PS';

export const AUDIENCE_INTENT_MARKETPLACE = 'AIM';

export const TEXT_ADS = 'TA';

export const PAGE_ADS = 'PA';

export const TARGET_CUSTOMER_ID = 'targetCustomerId';

export const AGG_LEVEL_LISTING = 'listingid';

export const AGG_LEVEL_CREATIVE = 'uniquecreativeadid';

export const AD_PERFORMANCE_DATA = 'adPerformanceData';
