import React, { useEffect, useState } from 'react';
import { completeAccess, hasReadAccess } from '../../../../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../../../../App.messages';
import { FormItem, FormSection, FormState, IFormEditParams } from '../../../../../../../../components/Form';
import { HierarchicalMultiSelectPicker } from '../../../../../../../../components/HierarchicalMultiSelectPicker';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../../../../../utils';
import { getCreatePropertyStore } from '../../../../__data__/store/store';
import { onAttachedAdunitsChanged } from '../../__data__/mutatorActions';
import { loadAttachedAdUnits } from '../../__data__/orchestrators';
import { GetPropertyAccessPermission } from '../../utils/GetPropertyAccessPermissions';
import { IsDisabled } from '../../utils/IsFieldDisabled';
import messages from './messages';

export interface IPropertyAttachedAdUnitsFieldProps {
  mode: FormState;
}

const PropertyAttachedAdUnitsField = injectIntlWithObserver<IRouterWithIntl<IFormEditParams> & IPropertyAttachedAdUnitsFieldProps>(
  ({ intl, mode, match }) => {
    const { formatMessage } = intl;
    const { active } = getCreatePropertyStore();
    const propertyAccessPermissions = GetPropertyAccessPermission();
    const accessLevel = propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.attachedAdUnits;
    const { publisherId, accountId, id: entityId } = match.params;
    const [isExpanded, setExpanded] = useState(false);

    if (!hasReadAccess(accessLevel)) {
      return null;
    }

    useEffect(() => {
      if (isExpanded && publisherId && active.adunits === undefined) {
        loadAttachedAdUnits(mode, publisherId, accountId, entityId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publisherId, entityId, isExpanded, active.adunits]);

    return (
      <FormSection title={formatMessage(messages.attachedAdUnits)} hasBorderBottom={false} sectionStateChange={setExpanded}>
        <FormItem>
          <HierarchicalMultiSelectPicker
            items={active.adunits || []}
            searchBoxPlaceHolder={formatMessage(messages.searchBoxPlaceHolder)}
            initialSearchBoxPlaceHolder={formatMessage(messages.initialSearchBoxPlaceHolder)}
            onFilteredItemsLabel={(count: number) => formatMessage(messages.adUnitPickerAccountsCountLabel, { count: count })}
            onSelectedItemsLabel={(count: number) => formatMessage(AppMessages.multiSelectPickerSelectedItemsLabel, { count: count })}
            onSubItemsLabel={(count) => formatMessage(messages.adUnitPickerAdUnitsCountLabel, { count: count })}
            onSelectedItems={(items) => onAttachedAdunitsChanged(items)}
            initialSelectedItems={active.adunitsSelected}
            intl={intl}
            disabled={IsDisabled(mode, accessLevel)}
          />
        </FormItem>
      </FormSection>
    );
  }
);

export default PropertyAttachedAdUnitsField;
