export class BlockImagessData {
  public adId?: number;
  public adTitle?: string;
  public adUnitId?: number;
  public images?: string[];
  public blockReason?: string;
  public blockReasonComment?: string;
  public blockedBy?: number;
  public blockSource?: number;
}
