import { array, mixed, object, string } from 'yup';
import { FilterScope, IdNamePairErrorModel } from '../../../../../../@data/store/schema';
import { ArrayObservableSchemaYupType, StringListSchemaYupType } from '../../../../../../utils/validation';
import { IFilterViewModel } from './IFilterViewModel';

export function filterErrorModel() {
  return object<IFilterViewModel>().shape({
    name: string().trim().required(),
    channelsSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel).when('filterType', {
      is: FilterScope.Account,
      then: array().required(),
      otherwise: array(),
    }),
    filterType: mixed().oneOf([FilterScope.Account, FilterScope.Publisher]).required(),
    excludedUrls: new StringListSchemaYupType().of(string().trim().oldDomain()),
    excludedAdKeywords: new StringListSchemaYupType().of(string().trim()),
    excludedQueryKeywords: new StringListSchemaYupType().of(string().trim()),
  });
}
