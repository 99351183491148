import { mergeStyleSets, Pivot, PivotItem } from '@fluentui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { injectRouterAndIntlWithObserver, IRouterWithIntl } from '../../utils';
import { getCurrentHashValue } from '../../utils/UrlUtils';
import { getClassNames, TabsStyles } from './Tabs.styles';

export interface ITabProps {
  key: string;
  headerText: string;
  component: JSX.Element;
}

export interface ITabsProps {
  tabs: ITabProps[];
  className?: string;
  styleProps?: Partial<TabsStyles>;
  defaultTab?: string;
  showItem?: (item) => void;
}

const getDefaultSelectedTabKey = (keys: string[]): string => {
  const currentHashValue = getCurrentHashValue();
  if (keys.includes(currentHashValue)) {
    return currentHashValue;
  }

  // return first tab key if no hash is provided in the URL
  return keys[0];
};

const updateUrl = (item?: PivotItem) => {
  if (!item) {
    return;
  }

  const url = new URL(window.location.href);
  url.hash = item.props.itemKey || '';
  window.history.replaceState('', '', url.toString());
};

export const Tabs = injectRouterAndIntlWithObserver(
  ({
    tabs,
    styleProps,
    className = '',
    history,
    showItem,
  }: ITabsProps &
    IRouterWithIntl &
    RouteComponentProps<
      {},
      {},
      {
        defaultTab?: string;
      }
    >): JSX.Element => {
    const { pivotRootStyle, pivotItemContainerStyle } = mergeStyleSets(getClassNames(), styleProps);
    const keys = useMemo(() => tabs.map((tab) => tab.key), [tabs]);
    const defaultTab = getDefaultSelectedTabKey(keys);
    const redirectTab = history?.location?.state?.defaultTab;
    const [selectedKey, setSelectedKey] = useState(defaultTab);

    useEffect(() => {
      if (redirectTab) {
        setSelectedKey(redirectTab);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectTab]);

    useEffect(() => {
      return () => setSelectedKey(defaultTab);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Pivot
        defaultSelectedKey={defaultTab}
        selectedKey={selectedKey}
        className={className}
        styles={{ root: pivotRootStyle, itemContainer: pivotItemContainerStyle }}
        onLinkClick={(link) => {
          setSelectedKey(link?.props?.itemKey!!);
          showItem?.(link);
          updateUrl(link);
        }}
      >
        {tabs.map((tab) => (
          <PivotItem key={tab.key} itemKey={tab.key} headerText={tab.headerText}>
            {tab.component}
          </PivotItem>
        ))}
      </Pivot>
    );
  }
);
