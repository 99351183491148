import { defineMessages } from 'react-intl';

export default defineMessages({
  notFound: {
    id: 'home.notFound',
    defaultMessage: 'No home found for this account.',
  },
  noPublisherSelected: {
    id: 'home.noPublisherSelected',
    defaultMessage: 'You arrived in an invalid state, please report.',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  welcome: {
    id: 'home.welcome',
    defaultMessage: 'Welcome to {appName}',
  },
  showMore: {
    id: 'home.showMore',
    defaultMessage: 'Show more',
  },
  contentDialog: {
    id: 'home.dialog.content',
    defaultMessage: "We're happy you're here! Now you can connect with your websites and create ads!",
  },
  buttonDialog: {
    id: 'home.dialog.button',
    defaultMessage: 'Get started',
  },
});
