import { Model } from '../../../../../../../@data';

export class BlockAdsData extends Model {
  public apiPath = undefined;
  public className = 'blockAdsData';
  public channelId?: number;
  public channelName?: string | null;
  public adId?: number;
  public adTitle?: string;
  public allImages?: string;
  public accountName?: string;
  public accountId?: number;
  public advertiserAccountId?: number;
  public advertiserName?: string;
  public blockRequestSourceId?: number;
  public reason?: string;
  public reasonCategory?: string;
  public blockReasonMainCategory?: string;
  public blockReasonSubCategory?: string;
  public modifiedBy?: string;
  public modifiedDateTime?: string;
  public modifiedByName?: string;
  public startDate?: string | null;
  public endDate?: string | null;
  public isEnabled?: boolean | string;
  public pastRevenue?: number;
}
