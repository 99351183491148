import { mutator } from 'satcheljs';
import { AdSource } from '../../../../../@data';
import {
  onAdCreativeAccountChanged,
  onAdCreativeAdPerfDataChanged,
  onAdCreativeBlockedMarketChanged,
  onAdCreativeBlockPanelChanged,
  onAdCreativeChanged,
  onAdCreativeEndDateChanged,
  onAdCreativeExportClicked,
  onAdCreativeMarketChanged,
  onAdCreativeSearchBoxValueChanged,
  onAdCreativeStartDateChanged,
  onAdProviderChanged,
  onAdSourceChanged,
} from '../actions/onAdCreativeChanged';
import { onTeachingBubbleEndDateStatusChanged, onTeachingBubbleStartDateStatusChanged } from '../actions/onTeachingBubbleActions';
import { getStore } from '../store/store';

mutator(onAdCreativeChanged, (msg) => {
  getStore().active = msg.adcreative;
});

mutator(onAdSourceChanged, (msg) => {
  if (msg.adSource) {
    getStore().active.selectedAdSource = msg.adSource.key as AdSource;
    getStore().active.adPerfData = [];
  }
});

mutator(onAdProviderChanged, (msg) => {
  if (msg.adProvider) {
    getStore().active.selectedAdProviderId = msg.adProvider.key as number;
  }
});

mutator(onAdCreativeAccountChanged, (msg) => {
  if (msg.account) {
    getStore().active.selectedAccount = msg.account.key as number;
  } else {
    getStore().active.selectedAccount = undefined;
  }
});

mutator(onAdCreativeMarketChanged, (msg) => {
  if (msg.market) {
    getStore().active.selectedMarket = msg.market.key as string;
  } else {
    getStore().active.selectedMarket = undefined;
  }
});

mutator(onAdCreativeBlockedMarketChanged, (msg) => {
  if (msg.market) {
    getStore().active.selectedBlockedMarketId = msg.market.key as number;
  } else {
    getStore().active.selectedBlockedMarketId = undefined;
  }
});

mutator(onAdCreativeSearchBoxValueChanged, (msg) => {
  getStore().active.searchBoxValue = msg.value;
});

mutator(onAdCreativeStartDateChanged, (msg) => {
  // Datepicker gives date in local time at 00:00:00
  getStore().active.startDate = msg.startDate;
});

mutator(onAdCreativeEndDateChanged, (msg) => {
  // Datepicker gives date in local time at 00:00:00
  getStore().active.endDate = msg.endDate;
});

mutator(onAdCreativeAdPerfDataChanged, (msg) => {
  getStore().active.adPerfData = msg.data;
});

mutator(onAdCreativeBlockPanelChanged, (msg) => {
  getStore().panelIsOpen = msg.value;
  getStore().panelItemType = msg.item;
  getStore().blockAdsPage.blockItemType = msg.item;
  if (!msg.value) {
    getStore().blockAdsPage = { adPerfBlockAdsData: [], channels: {} };
    getStore().errors = {};
  }
});

mutator(onAdCreativeExportClicked, (msg) => {
  getStore().columns = msg.columns;
});

mutator(onTeachingBubbleEndDateStatusChanged, (msg) => {
  const store = getStore();
  store.teachingBubbleEndDate = msg.isVisible;
});

mutator(onTeachingBubbleStartDateStatusChanged, (msg) => {
  const store = getStore();
  store.teachingBubbleStartDate = msg.isVisible;
});
