import { defineMessages } from 'react-intl';

export default defineMessages({
  searchResults: {
    id: 'globalSearch.searchResults',
    defaultMessage: 'Search results'
  },
  filterSearchBy: {
    id: 'globalSearch.filterSearchBy',
    defaultMessage: 'Filter search by'
  },
  clearFilters: {
    id: 'globalSearch.clearFilters',
    defaultMessage: 'Clear filters'
  },
  filterCheckboxAriaDesc: {
    id: 'globalSearch.filterCheckboxAriaDesc',
  defaultMessage: 'Check this to include {entity} search results'
  }
});
