import { orchestrator } from 'satcheljs';
import {
  getAppStore,
  NotificationLabel,
  NotificationSeverity,
  onNotificationCardAdded,
  showErrorToastNotification,
  XPayJwtTokenRequest,
} from '../../../../../@data';
import { getXPayJwtToken } from '../../../../../@data/services/PaymentsService';
import notificationMessages from '../../../../../NotificationsPanel.Types.messages';
import { fetchAccountById } from '../../../../partner-management/Account/@data/services/AccountService';
import { setXpayJwtTokenStatusIsLoading } from '../actions';
import { onInitializePaymentsPage } from '../actions/actions';
import { getPaymentsPageData } from '../store/paymentsPageStore';

orchestrator(
  onInitializePaymentsPage,
  async ({ shouldRenderOnboardingPopup, locale, countryCode, onResolvedCallback = (success: boolean) => null }) => {
    try {
      const { xpayJwtToken, xpayJwtTokenStatusIsLoading, xpayJwtTokenRequest, xpayTokenExpirationTimeStamp } = getPaymentsPageData();
      const { publisher, userContext } = getAppStore();
      let tokenResponse, xPayJWTRequest;

      if (
        (!xpayJwtToken && !xpayJwtTokenStatusIsLoading) ||
        (xpayJwtToken && xpayTokenExpirationTimeStamp && xpayTokenExpirationTimeStamp <= new Date().getTime())
      ) {
        setXpayJwtTokenStatusIsLoading(true);
        // TODO : remove the fetch account and depends on countryCode passed as props
        const accountId = getAppStore().account?.id;
        const account = await fetchAccountById(publisher!, accountId, userContext);
        xPayJWTRequest = new XPayJwtTokenRequest();
        xPayJWTRequest.publisherId = publisher?.id!;
        xPayJWTRequest.countryCode = account.countryCode || countryCode;
        tokenResponse = await getXPayJwtToken(xPayJWTRequest, userContext!);
      }

      const xpayParams: {
        authToken: string;
        containerId: string;
        theme: string;
        locale: string;
        xpayClientName: string;
        isRefreshedUI: boolean;
        country: string;
        showTransactionReport: boolean;
        showSetupPopup: boolean;
        serviceAgreement: string;
      } = {
        authToken: xpayJwtToken ?? tokenResponse.xpayJwtToken,
        containerId: 'onboarding-container',
        theme: 'dark',
        locale: locale,
        xpayClientName: 'PubCenter',
        isRefreshedUI: false,
        country: xpayJwtTokenRequest.countryCode ?? xPayJWTRequest.countryCode,
        showTransactionReport: true,
        showSetupPopup: shouldRenderOnboardingPopup,
        serviceAgreement: xpayJwtTokenRequest.countryCode === 'CA' ? 'Recipient' : 'Full',
      };

      if (window.hasOwnProperty('xPayUIAccountOnboarding')) {
        console.log('window has property xPayUIAccountOnboarding');
        window['xPayUIAccountOnboarding']['renderStripeOnboardingComponent'](xpayParams);
        onResolvedCallback(true);
      }
    } catch (error) {
      showErrorToastNotification({ textMessageDescriptor: notificationMessages.LoadPayoutInfoFailedContent });
      onNotificationCardAdded({
        // tslint:disable-next-line: no-any
        severity: NotificationSeverity.Error,
        titleMessageDescriptor: notificationMessages.LoadPayoutInfoFailedTitle,
        contentMessageDescriptor: notificationMessages.LoadPayoutInfoFailedContent,
        notificationLabel: NotificationLabel.LoadPayoutInfoFailed,
      });
      onResolvedCallback(false);
    } finally {
      setXpayJwtTokenStatusIsLoading(false);
    }
  }
);
