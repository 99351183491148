import { MessageBarType } from '@fluentui/react';
import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { onNotificationChanged } from '../../@data';
import { useDetectAdBlock } from '../../hooks/useDetectAdBlock';
import { INotificationBarItem } from '../NotificationBar';
import messages from './DetectAdBlock.messages';

export const DetectAdBlock = injectIntl(({ intl }) => {
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      const notification: INotificationBarItem = {
        text: intl.formatMessage(messages.notificationText),
        messageBarType: MessageBarType.warning,
        suppressAutoDismiss: true,
      };

      onNotificationChanged(notification);
    }
  }, [adBlockDetected, intl]);

  return null;
});
