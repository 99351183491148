import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class Publisher extends Model {
  public className = UserRoleEntity.Publisher;
  public id: number;
  public partner: number;
  public name: number;
  public networkAffiliation: number;
  public address: number;
  public publisherTier: number;
  public overrideFeatureTier: number;
  public entityLimitTier: number;
  public serviceLevel: number;
  public allowDistributionReporting: number;
  public allowDistributionV2Reporting: number;
  public allowStartDistributionReporting: number;
  public allowStartDistributionContentReporting: number;
  public allowSelectTierReporting: number;
  public allowMediationReporting: number;
  public allowMobileSearchAdUnit: number;
  public allowNativeReporting: number;
  public allowSyndicationReporting: number;
  public allowWebAdUnit: number;
  public allowPaidSearchReportingApi: number;
  public allowPaidSearchReportingDashboard: number;
  public allowDynamicPropertyRegistration: number;
  public enableDefaultTythonV2Reporting: number;
  public publisherAllowedFeaturesPropertyAutoApproved: number;
  public ipRanges: number;
  public newAccount: number;

  constructor(params: Publisher) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: Publisher, publisher: Publisher) {
    // tslint:disable:no-bitwise
    this.accessPermission |= publisher.accessPermission;
    this.partner |= publisher.partner;
    this.name |= publisher.name;
    this.networkAffiliation |= publisher.networkAffiliation;
    this.address |= publisher.address;
    this.publisherTier |= publisher.publisherTier;
    this.overrideFeatureTier |= publisher.overrideFeatureTier;
    this.entityLimitTier |= publisher.entityLimitTier;
    this.serviceLevel |= publisher.serviceLevel;
    this.allowDistributionReporting |= publisher.allowDistributionReporting;
    this.allowDistributionV2Reporting |= publisher.allowDistributionV2Reporting;
    this.allowStartDistributionReporting |= publisher.allowStartDistributionReporting;
    this.allowStartDistributionContentReporting |= publisher.allowStartDistributionContentReporting;
    this.allowSelectTierReporting |= publisher.allowSelectTierReporting;
    this.allowMediationReporting |= publisher.allowMediationReporting;
    this.allowMobileSearchAdUnit |= publisher.allowMobileSearchAdUnit;
    this.allowNativeReporting |= publisher.allowNativeReporting;
    this.allowSyndicationReporting |= publisher.allowSyndicationReporting;
    this.allowWebAdUnit |= publisher.allowWebAdUnit;
    this.allowPaidSearchReportingApi |= publisher.allowPaidSearchReportingApi;
    this.allowPaidSearchReportingDashboard |= publisher.allowPaidSearchReportingDashboard;
    this.allowDynamicPropertyRegistration |= publisher.allowDynamicPropertyRegistration;
    this.enableDefaultTythonV2Reporting |= publisher.enableDefaultTythonV2Reporting;
    this.publisherAllowedFeaturesPropertyAutoApproved |= publisher.publisherAllowedFeaturesPropertyAutoApproved;
    this.ipRanges |= publisher.ipRanges;
    this.newAccount |= publisher.newAccount;
    // tslint:enable:no-bitwise
  }
}
