import { AdSource } from '../../../../../@data';
import { FilterCondition } from '../../../../../@data/store/schema/enums/FilterCondition';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { adQualityLocales } from '../../../../../config/locale';
import { AdQualityFilterNames, IConditionValue } from './schema';
import { IAdReviewModel } from './schema/models/AdReviewModel';
import { getStore } from './store';

enum ConditionType {
  Text,
  Numerical,
}

function idAggregation(condition: FilterCondition, val: string): string {
  let result: string = '';
  switch (condition) {
    case FilterCondition.Equals:
      result = val;
      break;
    case FilterCondition.DoesNotEqual:
      result = '-'.concat(val);
      break;
    default:
      break;
  }
  return result;
}

function textAggregation(condition: FilterCondition, val: string): string {
  let result: string = '';
  val = val.trim();
  switch (condition) {
    case FilterCondition.Contains:
      result = val;
      break;
    case FilterCondition.DoesNotContain:
      const tokens = val.split(' ');
      result = tokens.map((token) => (token = '-'.concat(token))).join(' ');
      break;
    case FilterCondition.BeginsWith:
      result = val.concat('*');
      break;
    case FilterCondition.Equals:
      result = '"'.concat(val).concat('"');
      break;
    case FilterCondition.DoesNotEqual:
      result = '-"'.concat(val).concat('"');
      break;
    default:
      break;
  }
  return result;
}

function getAggregation(type: ConditionType, condition?: FilterCondition, val?: string): string {
  let result: string = '';

  if (condition !== undefined && val !== undefined) {
    switch (type) {
      case ConditionType.Text:
        result = textAggregation(condition, val);
        break;

      case ConditionType.Numerical:
        result = idAggregation(condition, val);
        break;

      default:
        break;
    }
  }
  return result;
}

function getMultiTextValues(triplets: IConditionValue<string>[] | undefined, type: ConditionType): string[] {
  const values = triplets?.map((triplet: IConditionValue<string>) => getAggregation(type, triplet.condition, triplet.value));
  const result: string = values ? values?.filter((value: string) => value !== '').join(' | ') : '';
  return result !== '' ? [result] : [];
}

export function getAdQualityListValues(list: IIdNamePair[] | undefined) {
  const result: string[] = [];
  if (list) {
    list.forEach((item: IIdNamePair) => {
      if (item.isSelected) {
        result.push(adQualityLocales[item.id]);
      }
    });
  }
  return result;
}

function getIdListValues(list: IIdNamePair[] | undefined) {
  const result: number[] = [];
  if (list) {
    list.forEach((item: IIdNamePair) => {
      if (item.isSelected) {
        result.push(item.id);
      }
    });
  }
  return result;
}

function getIdValues(list: IIdNamePair[] | undefined) {
  const result: string[] = [];
  if (list) {
    list.forEach((item: IIdNamePair) => {
      if (item.isSelected) {
        result.push(item.id.toString());
      }
    });
  }
  return result;
}

export function converterIn(adSource: AdSource, rawFilter: boolean): IAdReviewModel {
  const filters = rawFilter ? getStore().filters.raw : getStore().filters.active;
  const result: IAdReviewModel = {
    Filters: {
      Text: {
        adProviders: getIdValues(filters?.[AdQualityFilterNames.AdProviders]?.values),
        locales: getAdQualityListValues(filters?.[AdQualityFilterNames.Markets]?.values),
        uniqueCreativeAdIds: getMultiTextValues(filters?.[AdQualityFilterNames.CreativeAdId]?.conditions, ConditionType.Numerical),
        advertiserNames: getMultiTextValues(filters?.[AdQualityFilterNames.Advertiser]?.conditions, ConditionType.Text),
        adIds: getMultiTextValues(filters?.[AdQualityFilterNames.AdId]?.conditions, ConditionType.Numerical),
        advertiserIds: getMultiTextValues(filters?.[AdQualityFilterNames.AdvertiserId]?.conditions, ConditionType.Numerical),
        advertiserAccountIds: getMultiTextValues(filters?.[AdQualityFilterNames.AdvertiserAccountId]?.conditions, ConditionType.Numerical),
        adTitles: getMultiTextValues(filters?.[AdQualityFilterNames.AdTitle]?.conditions, ConditionType.Text),
        adUrls: getMultiTextValues(filters?.[AdQualityFilterNames.AdURL]?.conditions, ConditionType.Text),
        longHeadlines: getMultiTextValues(filters?.[AdQualityFilterNames.LongHeadline]?.conditions, ConditionType.Text),
        shortHeadlines: getMultiTextValues(filters?.[AdQualityFilterNames.ShortHeadline]?.conditions, ConditionType.Text),
      },
      Numerical: {
        accountIds: getIdListValues(filters?.[AdQualityFilterNames.AccountName]?.values),
        adUnitIds: [],
      },
    },
  };

  if (adSource === AdSource.Microsoft) {
    delete result.Filters?.Text?.locales;
    delete result.Filters?.Text?.adProviders;
  }

  if (adSource !== AdSource.Microsoft) {
    delete result.Filters?.Text?.longHeadlines;
    delete result.Filters?.Text?.shortHeadlines;
  }

  return result;
}
