import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { getAppStore } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { DetailPage } from '../../../../../layout/DetailPage/DetailPage';
import { injectIntlAndObserver } from '../../../../../utils';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import { getAdunitStore } from '../../@data';
import AdUnitMediationSelector from '../components/AdUnitMediationSelector';
import AdUnitNameInputField from '../components/AdUnitNameInput';
import AdUnitSiteSelector from '../components/AdUnitSiteSelector';
import AdUnitTypeSelector from '../components/AdUnitTypeSelector';
import { NativeAdScriptPage } from '../components/ScriptPage/ScriptPage';
import useCreateTythonAdunitRenderControl from './useCreateTythonAdunit';

interface ICreateTythonAdunitProps {
  mode: FormState;
  routeProps: RouteComponentProps;
}

const CreateTythonAdunit = injectIntlAndObserver<InjectedIntlProps & ICreateTythonAdunitProps>(({ intl, mode, routeProps }) => {
  const appStore = getAppStore();
  const googleAdsenseStore = getGoogleAdSenseStore();
  const adUnitsStore = getAdunitStore();
  const {
    adUnitNameProps,
    adUnitSiteProps,
    adUnitTypeProps,
    adUnitMediationProps,
    adUnitDetailPageProps,
    adUnitScriptPageProps,
  } = useCreateTythonAdunitRenderControl({
    appStore,
    googleAdsenseStore,
    mode,
    routeProps,
    intl,
    adUnitsStore,
  });

  return (
    <DetailPage helpId={'pop_PCv4_AdUnitsCreate'} pageMode={mode} {...adUnitDetailPageProps}>
      <AdUnitNameInputField {...adUnitNameProps} />
      <AdUnitSiteSelector {...adUnitSiteProps} />
      <AdUnitTypeSelector {...adUnitTypeProps} />
      <AdUnitMediationSelector {...adUnitMediationProps} />
      <NativeAdScriptPage {...adUnitScriptPageProps} />
    </DetailPage>
  );
});

export default CreateTythonAdunit;
