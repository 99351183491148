import { BreakPoints, IPubcenterPalette, IPubcenterSpacing } from '../../../theme';
import { ISearchBarStyleProps, ISearchBarStyles } from './Search.types';

const { ResponsiveSmall } = BreakPoints;

export const getSearchStyles = (props: ISearchBarStyleProps): ISearchBarStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const pallete = theme.palette as IPubcenterPalette;

  return {
    root: ['ms-GlobalSearch'],
    searchBar: [
      'ms-GlobalSearchBar',
      {
        display: 'flex',
        alignItems: 'center',
      },
    ],
    calloutWrapper: {
      [ResponsiveSmall]: {
        left: '0 !important',
        right: '0 !important',
        '>div[role="dialog"]': {
          height: '100vh',
        },
      },
    },
    shimmerCallout: {
      flexDirection: 'column',
      width: spacing.size480,
    },
    shimmerElement: {
      width: spacing.size480,
      height: spacing.size48,
      padding: '0 16px',
    },
    subComponentStyles: {
      searchBox: {
        root: [
          'ms-fontSize-Body14',
          {
            height: spacing.size32,
            width: `clamp(${spacing.size256}, 50vw, ${spacing.size960})`,
            borderRadius: '2px',
            background: pallete.neutralQuaternaryAlt,
            selectors: {
              ':hover': {
                background: pallete.white,
              },
            },
            [ResponsiveSmall]: {
              width: `clamp(30vw, 40vw, 45vw);`,
            },
          },
        ],
      },
    },
  };
};
