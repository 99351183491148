import { BaseComponent, classNamesFunction, FocusTrapZone, MessageBar } from '@fluentui/react';
import * as React from 'react';
import { INotificationBarItem, INotificationBarProps, INotificationBarStyleProps, INotificationBarStyles } from './NotificationBar.types';

const getClassNames = classNamesFunction<INotificationBarStyleProps, INotificationBarStyles>();
interface INotificationBarState {
  useTrapZone: boolean;
}

export class NotificationBarBase extends BaseComponent<INotificationBarProps, INotificationBarState> {
  constructor(props) {
    super(props);
    this.state = {
      useTrapZone: false,
    };
  }

  public componentDidUpdate(prevProps: INotificationBarProps, prevState: INotificationBarState): void {
    if (this.props.items && prevProps.items && this.props.items?.length > prevProps.items?.length && !prevState.useTrapZone) {
      this.setState({ useTrapZone: true });
    }
  }

  public render(): JSX.Element {
    const { dismissButtonAriaLabel, styles, theme, items = [], intl } = this.props;
    const classNames = getClassNames(styles, { theme: theme! });

    const onMessageDismiss = (item: INotificationBarItem) => {
      const { onDismiss, noDismissButton } = item;
      if (!!onDismiss) {
        return onDismiss;
      }

      if (!noDismissButton) {
        return () => this.props.onRemoved(item);
      }
    };

    return (
      <div className={classNames.root}>
        {items.map((item, idx) => (
          <FocusTrapZone
            key={`focus-trap-${idx}`}
            disabled={!this.state.useTrapZone}
            onFocus={() => {
              setTimeout(() => this.setState({ useTrapZone: false }), 1000);
            }}
          >
            <MessageBar
              key={idx}
              messageBarType={item.messageBarType}
              styles={{ ...classNames.subComponentStyles.messageBar, ...(item.messageBarStyles ?? {}) }}
              onDismiss={onMessageDismiss(item)}
              dismissButtonAriaLabel={dismissButtonAriaLabel}
              role="alert"
              actions={item.actions}
            >
              {/* {item.text} */}
              {item.textMessageDescriptor && intl ? intl?.formatMessage(item.textMessageDescriptor, item.intlMessageValues) : item.text}
              {item.children}
              {item.component && item.component}
            </MessageBar>
          </FocusTrapZone>
        ))}
      </div>
    );
  }
}
