import { BaseComponent, Icon, PrimaryButton, Stack, classNamesFunction } from '@fluentui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { NotificationSeverity } from '../../@data';
import { INotificationCardProps, INotificationCardStyleProps, INotificationCardStyles } from './NotificationCard.types';

const getClassNames = classNamesFunction<INotificationCardStyleProps, INotificationCardStyles>();

export class NotificationCardBase extends BaseComponent<INotificationCardProps> {
  public render(): JSX.Element {
    const {
      styles,
      theme,
      title,
      content,
      severity,
      onDismiss,
      onPrimaryActionClicked,
      onSecondaryActionClicked,
      timestamp,
      primaryActionText,
      primaryActionLink,
      dismissLabel,
      yesterdayLabel,
      twoDaysAgoLabel,
      threeDaysAgoLabel,
      primaryIsExternal,
      secondaryActionText,
      secondaryActionLink,
      secondaryIsExternal
    } = this.props;
    const classNames = getClassNames(styles, { theme: theme!, severity: severity });

    const formattedTime = this.getFormattedDate(new Date(timestamp), yesterdayLabel, twoDaysAgoLabel, threeDaysAgoLabel);

    return (
      <div className={classNames.cardContainerStyle}>
        <Stack>
          <div className={classNames.headerRowStyle}>
            <Stack horizontal horizontalAlign="space-between">
              <div className={classNames.leftContainerStyle}>
                <Icon iconName={this.getIconName(severity)} className={classNames.iconStyle} />
              </div>

              <div className={classNames.rightContainerStyle}>
                <Stack>
                  <div className={classNames.titleStyle}>{title}</div>
                  <div className={classNames.contentStyle}>{content}</div>
                  <div className={classNames.timestampStyle}>{formattedTime}</div>
                </Stack>
              </div>
            </Stack>
          </div>
          <Stack horizontal>
            {primaryIsExternal && (
              <PrimaryButton className={classNames.buttonStyle} onClick={onPrimaryActionClicked}>
                <span>{primaryActionText}</span>
              </PrimaryButton>
            )}
            {primaryActionLink && !primaryIsExternal && (
              <Link to={primaryActionLink} className={classNames.linkStyle} onClick={onPrimaryActionClicked}>
                <span>{primaryActionText}</span>
              </Link>
            )}
            {secondaryIsExternal && (
              <PrimaryButton className={classNames.buttonStyle} onClick={onSecondaryActionClicked}>
                <span>{secondaryActionText}</span>
              </PrimaryButton>
            )}
             {secondaryActionLink && !secondaryIsExternal && (
              <Link to={secondaryActionLink} className={classNames.linkStyle} onClick={onSecondaryActionClicked}>
                <span>{secondaryActionText}</span>
              </Link>
            )}

            <PrimaryButton onClick={onDismiss} className={classNames.buttonStyle}>
              <span>{dismissLabel}</span>
            </PrimaryButton>
          </Stack>
        </Stack>
      </div>
    );
  }

  private getIconName = (severity: NotificationSeverity) => {
    switch (severity) {
      case NotificationSeverity.Informational:
        return 'Info';
      case NotificationSeverity.Warning:
        return 'Warning';
      case NotificationSeverity.Critical:
        return 'ErrorBadge';
      case NotificationSeverity.Announcements:
        return 'Announcements';
      case NotificationSeverity.Error:
        return 'StatusErrorFull';
    }
  };

  private isSameDay = (date1: Date, date2: Date) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  };

  private getPreviousDay = (date: Date, numDays: number) => {
    const previousDay = new Date(date);
    previousDay.setDate(previousDay.getDate() - numDays);
    return previousDay;
  };

  private getFormattedDate = (date: Date, yesterdayLabel: string, twoDaysAgoLabel: string, threeDaysAgoLabel: string) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (this.isSameDay(date, today)) {
      return date.toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    } else if (this.isSameDay(date, yesterday)) {
      return yesterdayLabel;
    } else if (this.isSameDay(date, this.getPreviousDay(today, 2))) {
      return twoDaysAgoLabel;
    } else if (this.isSameDay(date, this.getPreviousDay(today, 3))) {
      return threeDaysAgoLabel;
    } else {
      return date.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' });
    }
  };
}
