import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubCenterFonts, IPubcenterSpacing } from '../../../../theme';
import { ICommonStyleProps } from '../../../types';

type StyleProperties = 'page' | 'container' | 'titleStyle' | 'descriptionStyle';
type SetupCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SetupCardStyles => {
  const { theme } = props;
  const fonts = theme.fonts as IPubCenterFonts;
  const spacing = theme.spacing as IPubcenterSpacing;
  const { ResponsiveSmall } = BreakPoints;

  return mergeStyleSets({
    page: {
      height: '100%',
      zIndex: 999999,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: spacing.size24,
      marginTop: '5%',
      padding: `0 ${spacing.size32}`,
      [ResponsiveSmall]: {
        flexDirection: 'column',
      },
    },
    titleStyle: {
      fontSize: fonts.size24.fontSize,
      fontWeight: FontWeights.bold,
    },
    descriptionStyle: {
      fontSize: fonts.size16.fontSize,
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
