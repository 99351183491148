import { defineMessages } from 'react-intl';

export default defineMessages({
  recommendationsTitle: {
    id: 'recommendations.title',
    defaultMessage: 'Recommendations',
  },
  setupPaymentTitle: {
    id: 'setupPayment.title',
    defaultMessage: 'Setup payment method',
  },
  setupPaymentDescription: {
    id: 'setupPayment.description',
    defaultMessage: 'Provide payment details to ensure your revenue is paid out correctly.',
  },
  setupPaymentButton: {
    id: 'setupPayment.button',
    defaultMessage: 'Go to Payments',
  },
  setupMediationTitle: {
    id: 'setupMediation.title',
    defaultMessage: 'Enable Google Mediation',
  },
  setupMediationDescription: {
    id: 'setupMediation.description',
    defaultMessage: 'Run your Google ads using {appName} to get maximum value for your ad inventory.',
  },
  setupMediationButton: {
    id: 'setupMediation.button',
    defaultMessage: 'Setup mediation',
  },
  setupAdsTxtTitle: {
    id: 'setupAdsTxt.title',
    defaultMessage: 'Enable Ads.txt on all sites',
  },
  setupAdsTxtDescription: {
    id: 'setupAdsTxt.description',
    defaultMessage: 'Install this file to verify your authenticity and increase the amount of advertisers who can advertise on your site.',
  },
  setupAdsTxtButton: {
    id: 'setupAdsTxt.button',
    defaultMessage: 'View sites',
  },
  paymentImage: {
    id: 'payment.image',
    defaultMessage: 'Payment Image',
  },
  mediationImage: {
    id: 'mediation.image',
    defaultMessage: 'Mediation Image',
  },
  adsTxtImage: {
    id: 'adsTxt.image',
    defaultMessage: 'Ads Txt image',
  },
  adBlockCardTitle: {
    id: 'adblock.card.title',
    defaultMessage: 'Set ad blocks',
  },
  adBlockCardDescription: {
    id: 'adblock.card.description',
    defaultMessage: 'Ad blocks shape which ads will show up on your sites ensuring that only content you approve of will be shown.',
  },
  adBlockCardAction: {
    id: 'adblock.card.action',
    defaultMessage: 'Ad blocks',
  },
});
