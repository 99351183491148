import { mutatorAction } from 'satcheljs';
import { ClarityType } from '../../../../../../../../@data';
import { getCreatePropertyStore } from '../../../../__data__/store/store';

export const onClarityTypeChanged = mutatorAction('onClarityTypeChanged', (type?: ClarityType) => {
  const clarity = getCreatePropertyStore().active.clarity;
  if (clarity !== undefined) {
    if (type !== ClarityType.Behavioral && clarity.originalClarityType !== ClarityType.Behavioral) {
      clarity.approvalBehavioralOption = false;
    }
    if (type !== ClarityType.None) {
      // If option changed from existing to other, clear the clarity id to not send it and confuse the API
      clarity.clarityId = '';
    }
    clarity.clarityType = type;
  }
});

export const onApprovalBehavioralTC = mutatorAction('onApprovalBehavioralTC', (isApproved: boolean) => {
  const clarity = getCreatePropertyStore().active.clarity;
  if (clarity !== undefined) {
    clarity.approvalBehavioralOption = isApproved;
  }
});
