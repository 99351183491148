export interface IStepperProps {
  labelArray: string[];
  currentStep: number;
  direction?: StepperDirection,
  styleProps?: string;
  goToStep?: (step: number) => void;
  updateStep?: (step: number) => void;
  isStepMarked?: (step: number) => boolean;
}

export interface IStepperStyleProps {
  direction?: StepperDirection;
}

export interface IStepperStyles {
  stepWrapper?: string;
}

export enum StepperDirection {
  Vertical,
  Horizontal
}
