/* tslint:disable */
import { classNamesFunction, DirectionalHint, FocusZone, FocusZoneDirection, styled } from '@fluentui/react';
import * as React from 'react';
import { debounce, useWindowSize } from '../../hooks';
import { getStore } from '../../hooks/useWindowSize/store/store';
import { ConditionalTooltip } from '../ConditionalTooltip';
import { Nav } from './Nav';
import { getStyles } from './Nav.styles';
import { ICustomNavLinkGroup, INavState, INavStyleProps, INavStyles, INavTogglerProps, NavGroupType } from './Nav.types';
import { NavLink } from './NavLink';
import messages from './NavToggler.messages';
import { SlimNav } from './SlimNav';

const getClassNames = classNamesFunction<INavStyleProps, INavStyles>();
const { windowSizeFormatter, windowStoreSerializer } = useWindowSize();

class NavTogglerComponent extends React.Component<INavTogglerProps, INavState> {
  constructor(props: INavTogglerProps) {
    super(props);

    this.state = {
      isNavCollapsed: props.isNavCollapsed ? props.isNavCollapsed : false,
      showMore: props.showMore ? props.showMore : false,
      isGroupCollapsed: {},
      lockAutoCollapse: false,
    };

    this._onShowMoreLinkClicked = this._onShowMoreLinkClicked.bind(this);
  }

  public triggerNavCollapse = (expand = true) => {
    this.props.onNavCollapsedCallback?.(expand);
    this.setState({ ...this.state, isNavCollapsed: expand });
  };

  public onOverlayClick = () => {
    const { isWindowSmallSize, isWindowExtraSmallSize } = windowStoreSerializer(getStore());
    if ((isWindowSmallSize || isWindowExtraSmallSize) && !this.state.isNavCollapsed) {
      this.triggerNavCollapse();
    }
  };

  // auto collapse nav bar when resizing to a smaller size
  public handleResizeCollapseReset = () => {
    if (this.state.lockAutoCollapse && this.state.isNavCollapsed) {
      return;
    }
    const isWindowNonRegularSize = windowSizeFormatter(window.innerWidth) !== 'regular';
    if (isWindowNonRegularSize && !this.state.isNavCollapsed) {
      this.triggerNavCollapse();
    }
    if (!isWindowNonRegularSize && this.state.isNavCollapsed) {
      this.triggerNavCollapse(false);
    }
  };

  public componentDidMount(): void {
    window.addEventListener('resize', debounce(this.handleResizeCollapseReset, 10));
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', debounce(this.handleResizeCollapseReset, 10));
  }

  // in small window size when nav item is clicked, nav should auto collapse
  public componentDidUpdate(prevProps: Readonly<INavTogglerProps>, prevState: Readonly<INavState>, snapshot?: any): void {
    const { isWindowSmallSize, isWindowExtraSmallSize } = windowStoreSerializer(getStore());
    const shouldTriggerNavCollapse =
      prevProps.selectedKey !== this.props.selectedKey && (isWindowSmallSize || isWindowExtraSmallSize) && !this.state.isNavCollapsed;
    if (shouldTriggerNavCollapse) {
      this.triggerNavCollapse();
    }
  }

  public render() {
    if (!this.props.groups || this.props.groups.length === 0) {
      return null;
    }

    const { isNavCollapsed, showMore } = this.state;

    const { styles, groups, theme, ariaLabel } = this.props;
    const classNames = getClassNames(styles!, { isCollapsed: isNavCollapsed, theme: theme! });

    const toggleNavGroups = groups.filter((navGroup: ICustomNavLinkGroup) => {
      return !!navGroup && navGroup.groupType === NavGroupType.ToggleGroup;
    });

    const nonToggleNavGroups = groups.filter((navGroup: ICustomNavLinkGroup) => {
      return !!navGroup && navGroup.groupType !== NavGroupType.ToggleGroup;
    });

    return (
      <div className={classNames.root}>
        <FocusZone id={'navFocusZone'} direction={FocusZoneDirection.vertical}>
          <div className={classNames.overlay} onClick={this.onOverlayClick} />
          <nav aria-label={ariaLabel}>
            {this._renderExpandCollapseNavItem(toggleNavGroups)}
            {isNavCollapsed ? (
              <SlimNav
                groups={nonToggleNavGroups}
                selectedKey={this.props.selectedKey}
                navScrollerId={this.props.navScrollerId}
                dataHint={this.props.dataHint}
                enableCustomization={this.props.enableCustomization}
                showMore={showMore}
                onShowMoreLinkClicked={this._onShowMoreLinkClicked}
                onEditLeftNavClickedCallback={this.props.onEditLeftNavClickedCallback}
              />
            ) : (
              <Nav
                groups={nonToggleNavGroups}
                selectedKey={this.props.selectedKey}
                dataHint={this.props.dataHint}
                enableCustomization={this.props.enableCustomization}
                showMore={showMore}
                onShowMoreLinkClicked={this._onShowMoreLinkClicked}
                onNavNodeExpandedCallback={this.props.onNavNodeExpandedCallback}
                onEditLeftNavClickedCallback={this.props.onEditLeftNavClickedCallback}
              />
            )}
          </nav>
        </FocusZone>
      </div>
    );
  }

  private _onNavCollapseClicked(ev: React.MouseEvent<HTMLElement>): void {
    this.setState((prevState: INavState) => {
      const isNavCollapsed = !prevState.isNavCollapsed;

      // inform the caller about the collapse event
      if (!!this.props.onNavCollapsedCallback) {
        this.props.onNavCollapsedCallback(isNavCollapsed);
      }

      return {
        isNavCollapsed: isNavCollapsed,
        /**
         * manual toggle nav will disable the auto collapse logic while resizing, only manual toggle nav again will unlock the auto collapse logic
         */
        lockAutoCollapse: !this.state.lockAutoCollapse,
      };
    });

    ev.preventDefault();
    ev.stopPropagation();
  }

  private _renderExpandCollapseNavItem(toggleNavGroups: ICustomNavLinkGroup[]): React.ReactElement<{}> | null {
    const { formatMessage } = this.props.intl;
    if ((!!toggleNavGroups && toggleNavGroups.length === 0) || !toggleNavGroups[0].links || toggleNavGroups[0].links.length === 0) {
      // There is no toggle group with links defined
      return null;
    }

    const isNavCollapsed = this.state.isNavCollapsed;
    const { styles, dataHint, theme } = this.props;
    const classNames = getClassNames(styles!, { theme: theme! });
    const link = toggleNavGroups[0].links[0];
    const navLinkId = 'expandCollapseButton';

    return (
      <ConditionalTooltip
        showTooltip={!!link.tooltipText}
        targetSelector={`#${navLinkId}`}
        content={link.tooltipText}
        directionalHint={DirectionalHint.bottomAutoEdge}
      >
        <NavLink
          id={navLinkId}
          href={link.url}
          content={link.name}
          disabled={link.disabled}
          onClick={this._onNavCollapseClicked.bind(this)}
          ariaExpanded={!isNavCollapsed}
          dataHint={dataHint}
          dataValue={link.key}
          rootClassName={classNames.navToggler}
          leftIconName={link.icon}
          iconClassName={classNames.navItemIconColumn}
          barClassName={classNames.navItemBarMarker}
          textClassName={classNames.navItemNameColumn}
          focusedStyle={classNames.focusedStyle}
          role="button"
          ariaLabel={formatMessage(isNavCollapsed ? messages.expandMenu : messages.collapseMenu)}
        />
      </ConditionalTooltip>
    );
  }

  private _onShowMoreLinkClicked(ev: React.MouseEvent<HTMLElement>): void {
    ev.preventDefault();
    ev.stopPropagation();

    this.setState((prevState: INavState) => {
      // let the consumer know about the "show more" or "show less" event
      if (this.props.onShowMoreLinkClicked) {
        this.props.onShowMoreLinkClicked(ev, !prevState.showMore);
      }

      return {
        showMore: !prevState.showMore,
      };
    });
  }
}

export const NavToggler = styled<INavTogglerProps, INavStyleProps, INavStyles>(NavTogglerComponent, getStyles);
/* tslint:enable */
