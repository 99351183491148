import { makeStyles, shorthands, tokens, typographyStyles } from '@fluentui/react-components';
import { BreakPoints } from '../../../../../../../../theme';

export const useAppPropertyDetailPageStyles = makeStyles({
  sectionTitle: {
    ...typographyStyles.title3,
  },
  sectionTitleOffset: {
    marginBottom: tokens.spacingVerticalXS,
  },
  textTitle: {
    ...typographyStyles.body1Strong,
    '::after': {
      content: `" *"`,
      color: tokens.colorPaletteRedForeground3,
      paddingRight: tokens.spacingHorizontalM,
    },
  },
  infoMsgBar: {
    ...typographyStyles.caption1Strong,
    marginBottom: tokens.spacingVerticalXS,
    paddingTop: tokens.spacingVerticalXS,
    paddingBottom: tokens.spacingVerticalXS,
  },
  agreementTitle: {
    ...typographyStyles.body1,
    color: tokens.colorNeutralForeground3,
  },
  agreementContent: {
    ...typographyStyles.caption1,
    color: tokens.colorNeutralForeground3,
  },
  platformDropdown: {
    width: '100%',
  },
  infoButton: {
    width: tokens.spacingHorizontalL,
    height: tokens.spacingVerticalL,
    display: 'contents',
    '::before': {
      content: `" *"`,
      color: tokens.colorPaletteRedForeground3,
    },
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
  skeletonWrapper: {
    width: '96px',
    marginTop: tokens.spacingVerticalSNudge,
    marginBottom: tokens.spacingVerticalSNudge,
  },
  urlPrefix: {
    backgroundColor: tokens.colorSubtleBackgroundSelected,
    color: tokens.colorNeutralStrokeAccessible,
    height: '28px',
    marginLeft: '-9px',
    paddingLeft: '10px',
    paddingRight: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-1px',
  },
});

export const useConnectAppDropdownStyles = makeStyles({
  containerWrapper: {
    '>span': {
      width: '100%',
    },
  },
  appSearchIcon: {
    transform: 'scaleX(-1)',
  },
  isHiding: {
    display: 'none',
  },
  optionCardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionCardSelectable: {
    minHeight: '64px',
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1Hover,
      cursor: 'pointer',
    },
    ...shorthands.borderRadius(tokens.borderRadiusNone),
    ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingHorizontalSNudge),
  },
  optionCardTitle: {
    marginLeft: tokens.spacingHorizontalSNudge,
    color: tokens.colorNeutralForeground1,
  },
  optionCardImgIcon: {
    width: '48px',
  },
  optionCardAppId: {},
  optionInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  optionInnerWrapperLower: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: tokens.colorNeutralForeground3,
  },
  infoMsgBar: {
    ...typographyStyles.caption1Strong,
    marginBottom: tokens.spacingVerticalXS,
    paddingTop: tokens.spacingVerticalXS,
    paddingBottom: tokens.spacingVerticalXS,
  },
  optionsWrapper: {
    maxHeight: '194px',
    overflowY: 'scroll',
    ...shorthands.padding(0),
    ...shorthands.gap(0),
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
});
export const useAppPropertyFinishPageStyles = makeStyles({
  title: {
    ...typographyStyles.subtitle1,
  },
  content: {
    color: tokens.colorNeutralForeground3,
  },
  root: {
    width: '800px',
  },
  cardTitle: {
    color: tokens.colorNeutralForeground1,
  },
  divider: {
    height: '96px',
    width: 'calc(100vw - 96px - 222px)', // 96px layout horizontal padding + 222px of stepper nav, nav will take 0px width in smaller screen
    [BreakPoints.ResponsiveMedium]: {
      width: 'calc(100vw - 96px)',
    },
    [BreakPoints.ResponsiveSmall]: {
      width: 'calc(100vw - 96px)',
    },
    [BreakPoints.ResponsiveExtraSmall]: {
      width: 'calc(100vw - 96px)',
    },
  },
  actionDivWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '> img': {
      ...shorthands.padding(0, tokens.spacingHorizontalM, 0, 0),
    },
  },
});
