import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { getAppStore } from '../../../../../../@data';
import { FormState } from '../../../../../../components/Form';
import { RouteName } from '../../../../@data';
import { createReturnUrl } from '../../../../@data/services/AdManagementService';
import NoCodeFound from '../../resources/NoCodeFound.svg';
import messages from './NoScriptPage.messages';
import { getClassNames } from './NoScriptPage.styles';
import { INoScriptPageProps } from './NoScriptPage.types';

export const NoScriptPage = injectIntl(function NoScriptPageComponent(props: INoScriptPageProps) {
  const { publisher, account } = getAppStore();
  const { formatMessage } = props.intl;
  const { root, title, description } = getClassNames();
  const setupTemplateRedirectionFromPanel = () => {
    const returnUrl = createReturnUrl(RouteName.adunit, publisher?.id, account?.id, props.adunitId.toString(), FormState.MultiStepEdit, 2);
    props.history.push(returnUrl);
  };

  const setupTemplateRedirectionInPageView = () => props.goToStep?.(2);

  return (
    <div className={root}>
      <img src={NoCodeFound} alt={formatMessage(messages.noCodeFound)} />
      <h2 className={title}>{formatMessage(messages.noCodeFound)}</h2>
      <p className={description}>{formatMessage(messages.noScriptDesc)}</p>
      <DefaultButton
        text={formatMessage(messages.setUpTemplate)}
        onClick={
          props.mode === FormState.MultiStepCreation || props.mode === FormState.MultiStepEdit
            ? setupTemplateRedirectionInPageView
            : setupTemplateRedirectionFromPanel
        }
      />
    </div>
  );
});
