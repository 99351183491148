import { SideNavigationProps } from '@bingads-webui-fluent-component/navigation';
import { classNamesFunction, getTheme } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { AuditHistoryRoute, HomePageRoute, UserLevelSettingRoute } from '../../Routes';
import { ICustomNavLinkGroup } from '../../components/Nav';
import { getRoutes } from '../../pages/ad-management/@data/services/getAdmanagementRoutes';
import { getStore } from '../../pages/ad-management/Account/Settings/@data/store/store';
import { getStyles } from './styles';
import { TythonNavStyleProps, TythonNavStyles, UseTythonNav } from './types';

const useTythonNav: UseTythonNav = (props) => {
  const {
    accessibleRoutes,
    history,
    generateNavIcons,
    intl: { formatMessage },
  } = props;
  const { systemPreferences } = getStore().active;
  const [navItems, setNavItems] = useState<SideNavigationProps['items']>([]);
  const theme = getTheme();
  const getClassNames = classNamesFunction<TythonNavStyleProps, TythonNavStyles>();
  const classNames = getClassNames(getStyles, { theme });
  const handleNavItemClick = (url: string, id: string) => {
    url && history.push(url);
  };

  const generateNavItems = (input: ICustomNavLinkGroup[]): SideNavigationProps['items'] => {
    return input.map((group) => {
      return group.links.map((link) => {
        let isNavItemSelected = history.location.pathname.includes(link.url);
        // corner case - when user navigates to home page from logo click
        if (history.location.pathname === HomePageRoute && link.icon === 'Home') {
          isNavItemSelected = true;
        }
        // corner case - when user navigates to pages where we don't have a nav item for them. e.g. Audit History, User Level Setting page
        if (history.location.pathname.includes(AuditHistoryRoute) || history.location.pathname.includes(UserLevelSettingRoute)) {
          isNavItemSelected = false;
        }
        return {
          text: getRoutes(formatMessage).find((route) => route.text === link.name)?.text ?? '',
          onClick: () => handleNavItemClick(link.url, link.key ?? ''),
          icon: generateNavIcons(link.icon),
          title: link.title ?? '',
          disabled: link.disabled ?? false,
          id: link.key ?? '',
          tooltipText: link.tooltipText ?? '',
          isSelected: isNavItemSelected,
        };
      });
    });
  };

  /**
   * Initialize nav items
   */
  useEffect(() => {
    setNavItems(generateNavItems(accessibleRoutes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * lifecycle hook to update nav render conditions when
   * - url updated
   * - locale updated
   */
  useEffect(() => {
    setNavItems(generateNavItems(accessibleRoutes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, systemPreferences?.locale]);

  /**
   * accessibility enhancement: set tabindex to -1 for dummy elements in ads UI kit nav component
   */
  useEffect(() => {
    const checkRender = () => {
      const element = document?.querySelectorAll('i[data-tabster-dummy]');
      if (element?.length) {
        element.forEach((el) => {
          el.setAttribute('tabindex', '-1');
        });
      } else {
        window.requestAnimationFrame(checkRender);
      }
    };

    checkRender();
  }, []);

  return { navItems, classNames };
};

export default useTythonNav;
