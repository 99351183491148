import { action } from 'satcheljs';

export const onPanelOpenChanged = action(
  'onPanelOpenChanged',
  (isPanelOpen: boolean, activePropertyId?: number, activeAdUnitId?: number) => ({
    isPanelOpen,
    activePropertyId,
    activeAdUnitId,
  })
);
