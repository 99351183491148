import { ITheme } from '@fluentui/react';
import { ClarityStatus } from '../../../../../../@data';

export interface IClarityParams {
  clarityId?: string;
  originalClarityId?: string;
  clarityLinkingEnabled?: boolean;
  clarityErrorMessage?: string;
  clarityEdit?: boolean;
  clarityStatus?: ClarityStatus;
}

export interface IClarityPanelStyleProps {
  theme: ITheme;
}

export interface IClarityPanelStyle {
  panelButton?: string;
  panelDescriptor?: string;
  panelAgreement?: string;
  panelPendingResendButton?: string;
}

export enum ClarityErrors {
  ClarityProjectNotLinked = 'ClarityProjectNotLinked',
  ClarityProjectUnlinkingFailed = 'ClarityProjectUnlinkingFailed',
}
