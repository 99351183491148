import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { AdSource, onNotificationChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import messages from '../../AdReview/AdCreativePage.messages';
import { onAdCreativeExportClicked } from '../actions';
import { exportCSV } from '../services/utility/utility';
import { getStore } from '../store/store';

orchestrator(onAdCreativeExportClicked, async (msg) => {
  let notification: INotificationBarItem = {};
  const adSource = getStore().active.selectedAdSource;

  const adPerfForCSVFromTable = getStore().active.adPerfData;
  const columns = getStore().columns;
  if (adPerfForCSVFromTable) {
    if (adSource === AdSource.Microsoft && columns) {
      exportCSV(adPerfForCSVFromTable, 'publisher_ad_performance.csv', columns);
    } else if (columns) {
      exportCSV(adPerfForCSVFromTable, 'publisher_auction_ad_performance.csv', columns);
    }

    notification = {
      textMessageDescriptor: messages.adqualityAdPerformanceDataDownloadSuccess,
      messageBarType: MessageBarType.success,
    };
    onNotificationChanged(notification);
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityNoDataToDownload,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
