import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { ICommonStyleProps } from '../../types';

type StyleProperties = 'pivotRootStyle' | 'pivotItemContainerStyle';
export type TabsStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): TabsStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette } = theme;

  return mergeStyleSets({
    pivotRootStyle: {
      borderBottom: `1px solid ${palette.neutralQuaternaryAlt}`,
      marginLeft: `-${spacing.size32}`,
      marginTop: spacing.size2,
      padding: `0 ${spacing.size32}`,
    },
    pivotItemContainerStyle: {
      marginLeft: spacing.size8,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): TabsStyles {
  return getStyles({ theme: getTheme(), ...props });
}
