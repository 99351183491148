import { Checkbox, DefaultButton, List, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import React, { useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import AppMessages from '../../../../App.messages';
import { IconTooltip } from '../../../../components/IconTooltip/IconTooltip';
import { IMetricMarkerProps } from '../MetricMarker/MetricMarker.types';
import { ActiveMetrics } from '../ReportCard/ReportCard.types';
import { customStyles, getClassNames } from './ChartMetricPicker.styles';

export interface IMetricsSidePanelProps extends InjectedIntlProps {
  onMetricsChanged: (activeMetrics: ActiveMetrics) => void;
  activeMetrics: ActiveMetrics;
  isOpen: boolean;
  onPanelHide: () => void;
}

export const MetricsSidePanel = injectIntl(
  ({ isOpen, activeMetrics, onPanelHide, onMetricsChanged, intl: { formatMessage } }: IMetricsSidePanelProps) => {
    const { panelButton, columnTooltip, columnCheckboxWrapper } = getClassNames();
    const [availableMetrics, setAvailableMetrics] = useState<IMetricMarkerProps[]>([]);
    const onRenderCell = (item: IMetricMarkerProps): JSX.Element => {
      return (
        <div className={columnCheckboxWrapper}>
          <Checkbox
            label={item.metricName}
            defaultChecked={item.isVisible}
            onChange={(_, value) => {
              const temp = {
                ...item,
                isVisible: !!value,
                isActive: !value ? false : item.isActive,
              };
              setAvailableMetrics([...availableMetrics, temp]);
            }}
            styles={customStyles.columnCheckbox}
          />
          {item.isIncompatible && (
            <div className={columnTooltip}>
              <IconTooltip tooltipMessage={item.incompatibleTooltipWontText || ''} iconName={'warning'} />
            </div>
          )}
        </div>
      );
    };
    const onSave = () => {
      for (const updatedMetric of availableMetrics) {
        activeMetrics[updatedMetric.metricKey] = { ...updatedMetric };
      }
      setAvailableMetrics([]);
      onMetricsChanged({ ...activeMetrics });
      onPanelHide();
    };
    return (
      <Panel
        isOpen={isOpen}
        type={PanelType.smallFixedFar}
        isLightDismiss
        onDismiss={onPanelHide}
        headerText={formatMessage(AppMessages.addMetrics)}
        closeButtonAriaLabel={formatMessage(AppMessages.panelCloseButtonAriaDesc)}
        isFooterAtBottom={true}
        styles={customStyles.sidePanelStyles}
        onRenderFooterContent={() => {
          return (
            <div className={panelButton}>
              <PrimaryButton
                onClick={onSave}
                text={formatMessage(AppMessages.save)}
                ariaDescription={formatMessage(AppMessages.ariaLabelForSave)}
              />
              <DefaultButton onClick={onPanelHide} text={formatMessage(AppMessages.cancel)} />
            </div>
          );
        }}
      >
        <List items={Object.values(activeMetrics).filter((metric) => !metric.disabled)} onRenderCell={onRenderCell} />
      </Panel>
    );
  }
);
