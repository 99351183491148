import { string } from 'yup';
import { StringListTransformerYupType } from '../../../../utils/validation';

const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
const minStrLength = 4;
const maxStrLength = 10;

export const PartnerCodesValidationSchema = new StringListTransformerYupType()
  .of(
    string()
      .matches(alphaNumericRegex, 'Partner Code should be alphanumeric')
      .min(minStrLength, `Partner Code should be at least ${minStrLength} characters long`)
      .max(maxStrLength, `Partner Code should be no more than ${maxStrLength} characters long`)
  )
  .test('is-unique', 'Partner Codes should be unique', function (partnerCodeList) {
    if (!partnerCodeList) {
      return true;
    }

    // Check if the list has any duplicates. Eg: ['PC1', 'pC1'] is not valid.
    const partnerCodesWithoutDuplicates = new Set(
      partnerCodeList.map(item => item.toLowerCase()));

    return partnerCodesWithoutDuplicates.size === partnerCodeList.length;
  });
