import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import { deleteEntity, getAppStore, UserInvitation } from '../../../../../../@data';
import {
  showErrorToastNotification,
  showInfoToastNotification,
  showSuccessToastNotification,
} from '../../../../../../@data/mutatorActions';
import messages from '../../UserInvitations.messages';
import { onDeleteUserInvitation } from '../actions/userInvitationActions';
import { setUserInvitations } from '../mutatorActions';
import { getUserInvitationStore } from '../store';

orchestrator(onDeleteUserInvitation, async ({ userInvite }) => {
  const { publisher, userContext } = getAppStore();

  if (userInvite && publisher && userContext) {
    const entityInstance = new UserInvitation(userInvite.id);

    try {
      showInfoToastNotification({ textMessageDescriptor: messages.userInvitationDeletingUserInvitation });
      await deleteEntity([publisher, entityInstance], undefined, userContext);

      const { userInvitations } = getUserInvitationStore();
      setUserInvitations(userInvitations.filter((ui) => ui.id !== userInvite.id));

      showSuccessToastNotification({
        textMessageDescriptor: messages.userInvitationDeletedInvitationSuccessfully,
        intlMessageValues: { email: userInvite.email },
      });
    } catch (e) {
      Log.error(e);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: messages.userInvitationInvalidState });
  }
});
