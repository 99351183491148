import { createStore } from 'satcheljs';
import { IPropertyViewState } from './schema/IPropertyViewState';

export const getGridPropertyStore = createStore<IPropertyViewState>('GridPropertyState', {
  isPanelOpen: false,
  isCodePanelOpen: false,
  isFetchingPropertyStatus: false,
  isFetchingData: false,
  isLoading: false,
});
