import { ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react';
import React from 'react';

export const getShimmerSetupCard = (): JSX.Element => {
  const styles = {
    root: {
      // Set the background color to white
      background: 'linear-gradient(to right, #E6E6E6,60%, white)',
    },
  };

  const stylesButton = {
    root: {
      // Set the background color to white
      background: 'linear-gradient(to right, #E6E6E6,30%, white)',
    },
  };
  return (
    <div>
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 90 }]} />
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 15 }]} />
      <ShimmerElementsGroup
        styles={styles}
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, width: '10%', height: 50 },
          { type: ShimmerElementType.line, width: '80%', height: 50, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '10%', height: 50 },
        ]}
      />

      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 5 }]} />

      <ShimmerElementsGroup
        styles={styles}
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, width: '10%', height: 20 },
          { type: ShimmerElementType.line, width: '80%', height: 20, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '10%', height: 20 },
        ]}
      />
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 5 }]} />

      <ShimmerElementsGroup
        styles={styles}
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, width: '10%', height: 20 },
          { type: ShimmerElementType.line, width: '80%', height: 20, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '10%', height: 20 },
        ]}
      />
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 55 }]} />

      <ShimmerElementsGroup
        styles={stylesButton}
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, width: '10%', height: 20 },
          { type: ShimmerElementType.line, width: '50%', height: 30, verticalAlign: 'bottom' },
          { type: ShimmerElementType.gap, width: '40%', height: 20 },
        ]}
      />
    </div>
  );
};
