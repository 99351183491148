import { action } from 'satcheljs';
import { EntityType } from '../../../../../../@data';
import { INotificationPreferences } from '../store/schema/ISettingsViewModel';

export const onUpdateNotificationPreferences = action(
  'onUpdateNotificationPreferences',
  (entityType: EntityType, np: INotificationPreferences) => ({ entityType, np })
);

export const toggleBillingAlerts = action('toggleBillingAlerts', (entityType: EntityType, checked?: boolean) => ({ entityType, checked }));

export const toggleServingStatus = action('toggleServingStatus', (entityType: EntityType, checked?: boolean) => ({ entityType, checked }));

export const toggleProfileReview = action('toggleProfileReview', (entityType: EntityType, checked?: boolean) => ({ entityType, checked }));

export const toggleInvitedUsers = action('toggleInvitedUsers', (entityType: EntityType, checked?: boolean) => ({ entityType, checked }));
