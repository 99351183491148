import { ApiError, handleError, LifeCycleStatus } from '../../../../../@data';
import { buildRequestHeadersWithAuthToken } from '../../../../../@data/utils/requestUtils';
import { BlockAdsData, BlockImageSummaryData, SAMBlockSummaryData } from '../store/schema/models';
import { baseAddress } from './AdCreativeService';
import { constructUrl } from './utility/utility';

export async function deactivateBlockedAds(models: string[][], params: URLSearchParams): Promise<number> {
  const baseUrl = baseAddress + 'adblockdata';
  const url = constructUrl(baseUrl, models, params);

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: headers,
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function toggleSAMBlockedAds(params: URLSearchParams, body: string): Promise<number> {
  let url = baseAddress + 'adblockdata/samrtbblock';
  url = url.concat('?' + params.toString());

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: body,
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getBlockSummaryData(models: string[][], params: URLSearchParams): Promise<BlockAdsData[]> {
  const baseUrl = baseAddress + 'adblockdata';
  const url = constructUrl(baseUrl, models, params);
  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    const responseJson: BlockAdsData[] = await response.json();
    responseJson.forEach((item) => {
      const blockReasonParts = item.reasonCategory ? item.reasonCategory.split(' - ') : ' ';
      item.blockReasonMainCategory = blockReasonParts[0];
      item.blockReasonSubCategory = blockReasonParts[1];
    });
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getSAMBlockSummaryData(models: string[][], params: URLSearchParams): Promise<SAMBlockSummaryData[]> {
  const baseUrl = baseAddress + 'adblockdata/samrtb';
  const url = constructUrl(baseUrl, models, params);

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    const responseJson: SAMBlockSummaryData[] = await response.json();
    // Set the LifeCycleStatus based on whether or not the block is applied or disabled
    responseJson.forEach((item) => {
      const blockReasonParts = item.blockReasonCategory ? item.blockReasonCategory.split(' - ') : ' ';
      item.blockReasonMainCategory = blockReasonParts[0];
      item.blockReasonSubCategory = blockReasonParts[1];
      item.status = item.filterStatus === 'Applied' ? LifeCycleStatus.Active : LifeCycleStatus.Inactive;
    });
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getBlockImagesData(publisherId: number): Promise<BlockImageSummaryData[]> {
  const params = new URLSearchParams([
    ['targetCustomerId', publisherId.toString()],
    ['isNativeAdmin', 'true'],
  ]);
  const baseUrl = baseAddress + 'adblockdata/image';
  const url = constructUrl(baseUrl, [['publisher', publisherId.toString()]], params);

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    const responseJson: BlockImageSummaryData[] = await response.json();
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getSAMBlockImageData(adId: string, publisherId: number): Promise<string[]> {
  const models = [['images', adId]];
  const baseUrl = baseAddress + 'adblockdata/samrtb';
  const params = new URLSearchParams([['targetCustomerId', publisherId.toString()]]);

  const url = constructUrl(baseUrl, models, params);
  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    const responseJson: [number, string][] = await response.json();
    const imageList: string[] = [];
    responseJson.forEach((filterImage) => {
      const key = 'adImageUrl';
      imageList.push(filterImage[key]);
    });
    return imageList;
  } catch (err) {
    const error = `Data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
