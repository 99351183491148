import { IDictionary } from './countries';

export const LANGUAGES_EN: IDictionary<string> = {
  EN: 'English',
  FR: 'French',
  AR: 'Arabic',
  DA: 'Danish',
  ZH: 'Chinese',
  DE: 'German',
  IT: 'Italian',
  KO: 'Korean',
  PT: 'Portuguese',
  ES: 'Spanish',
  FI: 'Finnish',
  HI: 'Hindi',
  JA: 'Japanese',
  KN: 'Kannada',
  ML: 'Malayalam',
  NL: 'Dutch',
  NO: 'Norwegian (Nynorsk)',
  SV: 'Swedish',
  TA: 'Tamil',
  TE: 'Telugu',
};

export const LANGUAGES_EN_GB: IDictionary<string> = {
  EN: 'English',
  FR: 'French',
  AR: 'Arabic',
  DA: 'Danish',
  ZH: 'Chinese',
  DE: 'German',
  IT: 'Italian',
  KO: 'Korean',
  PT: 'Portuguese',
  ES: 'Spanish',
  FI: 'Finnish',
  HI: 'Hindi',
  JA: 'Japanese',
  KN: 'Kannada',
  ML: 'Malayalam',
  NL: 'Dutch',
  NO: 'Norwegian (Nynorsk)',
  SV: 'Swedish',
  TA: 'Tamil',
  TE: 'Telugu',
};

export const LANGUAGES_FR: IDictionary<string> = {
  EN: 'Anglais',
  FR: 'Français',
  AR: 'Arabe',
  DA: 'Danois',
  ZH: 'Chinois',
  DE: 'Allemand',
  IT: 'Italien',
  KO: 'Coréen',
  PT: 'Portugais',
  ES: 'Espagnol',
  FI: 'Finnois',
  HI: 'Hindi',
  JA: 'Japonais',
  KN: 'Kannada',
  ML: 'Malayalam',
  NL: 'Néerlandais',
  NO: 'Norvégien (Nynorsk)',
  SV: 'Nuédois',
  TA: 'Tamoul',
  TE: 'Télougou',
};

export const LANGUAGES_DE: IDictionary<string> = {
  EN: 'Englisch',
  FR: 'Französisch',
  AR: 'Arabisch',
  DA: 'Dänisch',
  ZH: 'Chinesisch',
  DE: 'Deutsch',
  IT: 'Italienisch',
  KO: 'Koreanisch',
  PT: 'Portugiesisch',
  ES: 'Spanisch',
  FI: 'Finnisch',
  HI: 'Hindi',
  JA: 'Japanisch',
  KN: 'Kannada',
  ML: 'Malayalam',
  NL: 'Niederländisch',
  NO: 'Norwegisch',
  SV: 'Schwedisch',
  TA: 'Tamil',
  TE: 'Telugu',
};

export const LANGUAGES_IT: IDictionary<string> = {
  EN: 'Inglese',
  FR: 'Francese',
  AR: 'Arabo',
  DA: 'Danese',
  ZH: 'Cinese',
  DE: 'Tedesco',
  IT: 'Italiano',
  KO: 'Coreano',
  PT: 'Portoghese',
  ES: 'Spagnolo',
  FI: 'Finlandese',
  HI: 'Hindi',
  JA: 'Giapponese',
  KN: 'Kannada',
  ML: 'Malayalam',
  NL: 'Olandese',
  NO: 'Norvegese',
  SV: 'Svedese',
  TA: 'Tamil',
  TE: 'Telugu',
};

export const LANGUAGES_FR_CA: IDictionary<string> = {
  EN: 'anglais',
  FR: 'français',
  AR: 'arabe',
  DA: 'danois',
  ZH: 'chinois',
  DE: 'allemand',
  IT: 'italien',
  KO: 'coréen',
  PT: 'portugais',
  ES: 'espagnol',
  FI: 'finnois',
  HI: 'hindi',
  JA: 'japonais',
  KN: 'kannada',
  ML: 'malayalam',
  NL: 'néerlandais',
  NO: 'norvégien (nynorsk)',
  SV: 'suédois',
  TA: 'tamoul',
  TE: 'télougou',
};

export const LANGUAGES_ES_ES: IDictionary<string> = {
  EN: 'Inglés',
  FR: 'Francés',
  AR: 'Árabe',
  DA: 'Danés',
  ZH: 'Chino',
  DE: 'Alemán',
  IT: 'Italiano',
  KO: 'Coreano',
  PT: 'Portugués',
  ES: 'Español',
  FI: 'Finés',
  HI: 'Hindi',
  JA: 'Japonés',
  KN: 'Canarés',
  ML: 'Malayalam',
  NL: 'Neerlandés',
  NO: 'Noruego (Nynorsk)',
  SV: 'Sueco',
  TA: 'Tamil',
  TE: 'Telugu',
};

export const LANGUAGES_ES_MX: IDictionary<string> = {
  EN: 'Inglés',
  FR: 'Francés',
  AR: 'Árabe',
  DA: 'Danés',
  ZH: 'Chino',
  DE: 'Alemán',
  IT: 'Italiano',
  KO: 'Coreano',
  PT: 'Portugués',
  ES: 'Español',
  FI: 'Finlandés',
  HI: 'Hindi',
  JA: 'Japonés',
  KN: 'Canarés',
  ML: 'Malayalam',
  NL: 'Neerlandés',
  NO: 'Noruego (Nynorsk)',
  SV: 'Sueco',
  TA: 'Tamil',
  TE: 'Telugu',
};

export const LANGUAGES_LANGUAGE_MAPPING = {
  'en-us': LANGUAGES_EN,
  'es-gb': LANGUAGES_EN_GB,
  'fr-fr': LANGUAGES_FR,
  'de-de': LANGUAGES_DE,
  'it-it': LANGUAGES_IT,
  'fr-ca': LANGUAGES_FR_CA,
  'es-es': LANGUAGES_ES_ES,
  'es-mx': LANGUAGES_ES_MX,
};
