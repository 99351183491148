import { AnimationClassNames, FontWeights } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../theme';
import { IMultiSelectPickerStyleProps, IMultiSelectPickerStyles } from './MultiSelectPicker.types';

// TODO: Implement Focused States #1636116 (errorMessage focus too)
export const getStyles = (props: IMultiSelectPickerStyleProps): IMultiSelectPickerStyles => {
  const { theme, height, hasErrorMessage } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const defaultHeight = 260;
  const { semanticColors } = theme;

  return {
    root: ['ms-MultiSelectPicker'],
    picker: {
      height: height || defaultHeight,
      display: 'flex',
      flexDirection: 'row',
      marginTop: spacing.size8,
      width: 580,
    },
    itemsPane: [
      {
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        overflow: 'hidden',
        flexDirection: 'column',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#B3B0AD',
        selectors: {
          ':first-child': {
            marginRight: spacing.size16,
          },
        },
      },
      hasErrorMessage && {
        borderColor: semanticColors.errorText,
        selectors: {
          '&:focus, &:hover': {
            borderColor: semanticColors.errorText,
          },
        },
      },
    ],
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: spacing.size8,
      marginBottom: spacing.size8,
    },
    headerLabel: [
      'ms-font-m',
      {
        fontWeight: FontWeights.semibold,
        marginLeft: spacing.size8,
        padding: 0,
      },
    ],
    textButton: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: spacing.size8,
    },
    errorMessage: [
      'ms-MultiSelectPicker-errorMessage',
      AnimationClassNames.slideDownIn20,
      theme.fonts.small,
      {
        color: semanticColors.errorText,
        margin: 0,
        paddingTop: 5,
        display: 'flex',
        alignItems: 'center',
      },
    ],
    list: {
      flex: 1,
      overflowY: 'auto',
    },
    disabledPanel: {
      backgroundColor: theme.palette.neutralLighter,
    },
    listItem: {
      display: 'flex',
      paddingLeft: spacing.size8,
      paddingRight: spacing.size8,
      height: spacing.size36,
      lineHeight: spacing.size36,
      cursor: 'pointer',
      selectors: {
        ':hover': {
          backgroundColor: theme.palette.neutralLighter,
        },
      },
    },
    disabledListItem: {
      backgroundColor: theme.palette.neutralLight,
    },
    listItemLabel: [
      'ms-font-m',
      {
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    ],
    subComponentStyles: {
      search: {
        root: {
          height: spacing.size48,
        },
      },
      iconButton: {
        root: [
          'ms-font-m',
          {
            color: theme.palette.themePrimary,
          },
        ],
      },
    },
  };
};
