import { defineMessages } from 'react-intl';

export default defineMessages({
  startDate: {
    id: 'admanagement.adquality.startDate',
    defaultMessage: 'Start date (UTC)',
  },
  blockErrorMessage: {
    id: 'admanagement.adquality.blockErrorMessage',
    defaultMessage: 'Error in bulk blocking. Please select maximum of 30 ads when using bulk blocks.',
  },
  startDatePlaceHolder: {
    id: 'admanagement.adquality.startDatePlaceHolder',
    defaultMessage: 'Select start date...',
  },
  clearStartDateSelAriaLabel: {
    id: 'admanagement.adquality.clearStartDateSelAriaLabel',
    defaultMessage: 'clear start date selection',
  },
  clear: {
    id: 'admanagement.adquality.clear',
    defaultMessage: 'Clear',
  },
  adReview: {
    id: 'admanagement.adquality.adReview',
    defaultMessage: 'Ad review',
  },
  noAdsPerfData: {
    id: 'admanagement.adquality.noAdsPerfData',
    defaultMessage: 'There is no data for this selection',
  },
  noBlockData: {
    id: 'admanagement.adquality.noBlockData',
    defaultMessage: 'There are no ad blocks for this selection',
  },
  blockSummary: {
    id: 'admanagement.adquality.blockSummary',
    defaultMessage: 'Block summary',
  },
  endDate: {
    id: 'admanagement.adquality.endDate',
    defaultMessage: 'End date (UTC)',
  },
  endDatePlaceHolder: {
    id: 'admanagement.adquality.endDatePlaceHolder',
    defaultMessage: 'Select end date...',
  },
  clearEndDateSelAriaLabel: {
    id: 'admanagement.adquality.clearEndDateSelAriaLabel',
    defaultMessage: 'clear end date selection',
  },
  searchboxPlaceHolder: {
    id: 'admanagement.adquality.searchboxPlaceHolder',
    defaultMessage: 'Filter',
  },
  searchBoxAriaLabel: {
    id: 'admanagement.adquality.searchBoxAriaLabel',
    defaultMessage: 'Filter data grid',
  },
  accountsCombo: {
    id: 'admanagement.adquality.accountsCombo',
    defaultMessage: 'Accounts',
  },
  marketsCombo: {
    id: 'admanagement.adquality.marketsCombo',
    defaultMessage: 'Markets',
  },
  applyFilters: {
    id: 'admanagement.adquality.applyFilters',
    defaultMessage: 'Apply',
  },
  applyFiltersAriaDesc: {
    id: 'admanagement.adquality.applyFiltersAriaDesc',
    defaultMessage: 'Press to apply selected filters on the data grid',
  },
  blockAds: {
    id: 'admanagement.adquality.blockad',
    defaultMessage: 'Block Ads',
  },
  blockImages: {
    id: 'admanagement.adquality.blockImages',
    defaultMessage: 'Block images',
  },
  viewImages: {
    id: 'admanagement.adquality.viewImages',
    defaultMessage: 'View images',
  },
  blockAdsMenuButtonLabel: {
    id: 'admanagement.adquality.blockAdsMenuButtonLabel',
    defaultMessage: 'Block ads',
  },
  blockImagesMenuButtonLabel: {
    id: 'admanagement.adquality.blockImagesMenuButtonLabel',
    defaultMessage: 'Block/View images',
  },
  viewImagesDescription: {
    id: 'admanagement.adquality.viewImagesDescription',
    defaultMessage: 'Some images cannot be blocked. Currently the entire ad must be blocked to prevent those images from being served.',
  },
  blockSummaryMSGridAriaLabel: {
    id: 'admanagement.adquality.blockSummaryMSGridAriaLabel',
    defaultMessage: 'Grid containing details of blocked Microsoft ads',
  },
  blockSummaryAuctionGridAriaLabel: {
    id: 'admanagement.adquality.blockSummaryAuctionGridAriaLabel',
    defaultMessage: 'Grid containing details of blocked Auction ads',
  },
  adReviewGridAriaLabel: {
    id: 'admanagement.adquality.adReviewGridAriaLabel',
    defaultMessage: 'Datagrid containing ads',
  },
  exportGrid: {
    id: 'admanagement.adquality.exportGrid',
    defaultMessage: 'Export below table',
  },
  exportImageBlocks: {
    id: 'admanagement.adquality.exportImageBlocks',
    defaultMessage: 'Export all image blocks',
  },
  enableBlocking: {
    id: 'enableBlocking',
    defaultMessage: 'Enable blocking',
  },
  disableBlocking: {
    id: 'disableBlocking',
    defaultMessage: 'Disable blocking',
  },
  adSourceCombo: {
    id: 'admanagement.adquality.adSourceCombo',
    defaultMessage: 'Ad Source',
  },
  auctionAdSourceCombo: {
    id: 'admanagement.adquality.auctionAdSourceCombo',
    defaultMessage: 'Ad Provider',
  },
  microsoft: {
    id: 'admanagement.adquality.microsoft',
    defaultMessage: 'Microsoft',
  },
  auction: {
    id: 'admanagement.adquality.auction',
    defaultMessage: 'Auction',
  },
  xandrAuction: {
    id: 'admanagement.adquality.xandrAuction',
    defaultMessage: 'Xandr Auction',
  },
  AdId: {
    id: 'admanagement.adquality.AdId',
    defaultMessage: 'Ad Id - Displaying {count}',
  },
  blockAdTitle: {
    id: 'admanagement.adquality.blockAdTitle',
    defaultMessage: 'Title',
  },
  blockAdURL: {
    id: 'admanagement.adquality.blockAdURL',
    defaultMessage: 'URL',
  },
  selectChannel: {
    id: 'admanagement.adquality.selectChannel',
    defaultMessage: 'Select channel',
  },
  selectMarket: {
    id: 'admanagement.adquality.selectMarket',
    defaultMessage: 'Select market',
  },
  createNewChannel: {
    id: 'admanagement.adquality.createNewChannel',
    defaultMessage: 'Create new channel',
  },
  blockReasonCategory: {
    id: 'admanagement.adquality.blockReasonCategory',
    defaultMessage: 'Block reason category',
  },
  blockReason: {
    id: 'admanagement.adquality.blockReason',
    defaultMessage: 'Block reason sub-category',
  },
  additionalDetials: {
    id: 'admanagement.adquality.additionalDetials',
    defaultMessage: 'Additional details/reason for blocking',
  },
  editBlockReason: {
    id: 'admanagement.adquality.editBlockReason',
    defaultMessage: 'Edit block reason',
  },
  channelName: {
    id: 'admanagement.adquality.channelName',
    defaultMessage: 'Channel name',
  },
  addAdnuits: {
    id: 'admanagement.adquality.addAdunits',
    defaultMessage: 'Add ad units',
  },
  disallowedByNCAP: {
    id: 'admanagement.adquality.disallowedByNCAP',
    defaultMessage: 'Disallowed by NCAP',
  },
  maliciousAdvertiser: {
    id: 'admanagement.adquality.maliciousAdvertiser',
    defaultMessage: 'Malicious Advertiser',
  },
  Misspellings: {
    id: 'admanagement.adquality.Misspellings',
    defaultMessage: 'Ad Text Quality – Misspellings',
  },
  competitiveExclusion: {
    id: 'admanagement.adquality.competitiveExclusion',
    defaultMessage: 'Competitive Exclusion',
  },
  badLandingPage: {
    id: 'admanagement.adquality.badLandingPage',
    defaultMessage: 'Bad landing page experience',
  },
  misleadingAdHeadline: {
    id: 'admanagement.adquality.misleadingAdHeadline',
    defaultMessage: 'Misleading ad headline',
  },
  sensationalAdOrLandingPage: {
    id: 'admanagement.adquality.sensationalAdOrLandingPage',
    defaultMessage: 'Sensational ad or landing page content',
  },
  offensiveAdOrLandingPage: {
    id: 'admanagement.adquality.offensiveAdOrLandingPage',
    defaultMessage: 'Offensive ad or landing page content',
  },
  wrongLanguageforMarket: {
    id: 'admanagement.adquality.wrongLanguageforMarket',
    defaultMessage: 'Wrong language for market',
  },
  prohibitedAdCategory: {
    id: 'admanagement.adquality.prohibitedAdCategory',
    defaultMessage: 'Prohibited ad category',
  },
  deceptiveAdvertiser: {
    id: 'admanagement.adquality.deceptiveAdvertiser',
    defaultMessage: 'Deceptive advertiser',
  },
  otherReasonCategory: {
    id: 'admanagement.adquality.otherReasonCategory',
    defaultMessage: 'Other',
  },
  pastRevenue: {
    id: 'admanagement.adquality.pastRevenue',
    defaultMessage: 'Publisher 7-day revenue impact',
  },
  disallowedContent: {
    id: 'admanagement.adquality.disallowed',
    defaultMessage: 'Disallowed Content',
  },
  disallowedDeceptive: {
    id: 'admanagement.adquality.disallowedDeceptive',
    defaultMessage: 'Deceptive Products and services',
  },
  disallowedMisleading: {
    id: 'admanagement.adquality.disallowedMisleading',
    defaultMessage: 'Misleading messaging, content, or images',
  },
  disallowedOffensive: {
    id: 'admanagement.adquality.disallowedOffensive',
    defaultMessage: 'Offensive/controversial content',
  },
  disallowedSuffering: {
    id: 'admanagement.adquality.disallowedSuffering',
    defaultMessage: 'Suffering and violence',
  },
  disallowedDrugs: {
    id: 'admanagement.adquality.disallowedDrugs',
    defaultMessage: 'Drugs and related paraphernalia',
  },
  disallowedTobacco: {
    id: 'admanagement.adquality.disallowedTobacco',
    defaultMessage: 'Tobacco products',
  },
  disallowedSensitive: {
    id: 'admanagement.adquality.disallowedSensitive',
    defaultMessage: 'Sensitive advertising',
  },
  legalPrivacyAndSecurity: {
    id: 'admanagement.adquality.legalPrivacyAndSecurity',
    defaultMessage: 'Legal, privacy, and security',
  },
  legalMalware: {
    id: 'admanagement.adquality.legalMalware',
    defaultMessage: 'Malware and security',
  },
  legalPrivacy: {
    id: 'admanagement.adquality.legalPrivacy',
    defaultMessage: 'Privacy and data protection policies',
  },
  mediaFormats: {
    id: 'admanagement.adquality.mediaFormats',
    defaultMessage: 'Media formats',
  },
  mediaAnimation: {
    id: 'admanagement.adquality.mediaAnimation',
    defaultMessage: 'Animation',
  },
  mediaNudity: {
    id: 'admanagement.adquality.mediaNudity',
    defaultMessage: 'Nudity, offensive advertising',
  },
  relevanceAndQuality: {
    id: 'admanagement.adquality.relevanceAndQuality',
    defaultMessage: 'Relevance and quality',
  },
  relevanceLandingPage: {
    id: 'admanagement.adquality.relevanceLandingPage',
    defaultMessage: 'Landing pages',
  },
  restrictedCategories: {
    id: 'admanagement.adquality.restrictedCategories',
    defaultMessage: 'Restricted categories',
  },
  restrictedAdult: {
    id: 'admanagement.adquality.restrictedAdult',
    defaultMessage: 'Adult content',
  },
  restrictedAlcohol: {
    id: 'admanagement.adquality.restrictedAlcohol',
    defaultMessage: 'Alcohol',
  },
  restrictedBeauty: {
    id: 'admanagement.adquality.restrictedBeauty',
    defaultMessage: 'Beauty & Cosmetics',
  },
  restrictedDating: {
    id: 'admanagement.adquality.restrictedDating',
    defaultMessage: 'Dating / personals',
  },
  restrictedFinance: {
    id: 'admanagement.adquality.restrictedFinance',
    defaultMessage: 'Finance',
  },
  restrictedGambling: {
    id: 'admanagement.adquality.restrictedGambling',
    defaultMessage: 'Gambling and lotteries',
  },
  restrictedLegalServices: {
    id: 'admanagement.adquality.restrictedLegalServices',
    defaultMessage: 'Legal Services',
  },
  restrictedCompetitors: {
    id: 'admanagement.adquality.restrictedCompetitors',
    defaultMessage: 'Microsoft competitors',
  },
  restrictedPharmacy: {
    id: 'admanagement.adquality.restrictedPharmacy',
    defaultMessage: 'Pharmacy and healthcare',
  },
  restrictedPolitical: {
    id: 'admanagement.adquality.restrictedPolitical',
    defaultMessage: 'Political content',
  },
  restrictedSoftware: {
    id: 'admanagement.adquality.restrictedSoftware',
    defaultMessage: 'Software, freeware and shareware',
  },
  restrictedSolicitation: {
    id: 'admanagement.adquality.restrictedSolicitation',
    defaultMessage: 'Solicitation of funds/ fund-raising',
  },
  restrictedWeapons: {
    id: 'admanagement.adquality.restrictedWeapons',
    defaultMessage: 'Weapons, knives, firearms, ammunition',
  },
  textGuidelines: {
    id: 'admanagement.adquality.textGuidelines',
    defaultMessage: 'Text guidelines',
  },
  textAdFunctionality: {
    id: 'admanagement.adquality.textAdFunctionality',
    defaultMessage: 'Ad functionality',
  },
  textAdLanguage: {
    id: 'admanagement.adquality.textAdLanguage',
    defaultMessage: 'Ad language',
  },
  textGrammar: {
    id: 'admanagement.adquality.textGrammar',
    defaultMessage: 'Grammar, punctuation, and spelling',
  },
  imageNotRelevant: {
    id: 'admanagement.adquality.imageNotRelevant',
    defaultMessage: 'Image not relevant to Ad',
  },
  lowQualityImage: {
    id: 'admanagement.adquality.lowQualityImage',
    defaultMessage: 'Low quality image',
  },
  offensiveImage: {
    id: 'admanagement.adquality.offensiveImage',
    defaultMessage: 'Offensive image',
  },
  policyIssue: {
    id: 'admanagement.adquality.policyIssue',
    defaultMessage: 'Additional policy issue',
  },
  altAdTitleListings: {
    id: 'admanagement.adquality.altAdTitleListings',
    defaultMessage: 'Alternate Ad Title',
  },
  altAdUrlListings: {
    id: 'admanagement.adquality.altAdUrlListings',
    defaultMessage: 'Alternate Ad Url',
  },
  altAdImageListings: {
    id: 'admanagement.adquality.altAdImageListings',
    defaultMessage: 'Alt. Ad Image',
  },
  loadingAltAdCreativeListings: {
    id: 'admanagement.adquality.loadingAltAdCreativeListings',
    defaultMessage: 'Loading Alternate Ad Creatives',
  },
  noAltAdCreativeListings: {
    id: 'admanagement.adquality.noAltAdCreativeListings',
    defaultMessage: 'No Alternate Ad Creatives for this Ad',
  },
  allImages: {
    id: 'admanagement.adquality.allImages',
    defaultMessage: 'View all images',
  },
  moreImages: {
    id: 'admanagement.adquality.moreImages',
    defaultMessage: '... {imageListLength} more images',
  },
  viewOnlyImageLabel: {
    id: 'admanagement.adquality.viewOnlyImageLabel',
    defaultMessage: 'image {itemNumber} view only',
  },
  blockedLabel: {
    id: 'admanagement.adquality.blockedLabel',
    defaultMessage: 'BLOCKED',
  },
  verizonMediaWarningLabel: {
    id: 'admanagement.adquality.verizonMediaWarningLabel',
    defaultMessage: 'Warning : Block ads for Gemini in Pubcenter & Partner Native Tool',
  },
  queryTimeoutWarningHeaderLabel: {
    id: 'admanagement.adquality.queryTimeoutWarningHeaderLabel',
    defaultMessage: 'Querying data for date ranges greater than 5 days may time out',
  },
  queryTimeoutWarningDescriptionLabel: {
    id: 'admanagement.adquality.queryTimeoutWarningDescriptionLabel',
    defaultMessage: 'Please consider using a shorter date range or using filters if you need a date range greater than 5 days.',
  },
  auctionBlockAdIdLabel: {
    id: 'admanagement.adquality.auctionBlockAdIdLabel',
    defaultMessage: 'AdId: {uniqueCreativeAdId}',
  },
  accountName: {
    id: 'admanagement.adquality.accountName',
    defaultMessage: 'Account',
  },
  adId: {
    id: 'admanagement.adquality.adid',
    defaultMessage: 'Ad id',
  },
  adTitle: {
    id: 'admanagement.adquality.adTitle',
    defaultMessage: 'Ad title',
  },
  advertiser: {
    id: 'admanagement.adquality.advertiser',
    defaultMessage: 'Advertiser',
  },
  advertiserId: {
    id: 'admanagement.adquality.advertiserId',
    defaultMessage: 'Advertiser id',
  },
  advertiserAccountId: {
    id: 'admanagement.adquality.advertiserAccountId',
    defaultMessage: 'Advertiser account id',
  },
  filterPanelApplyButtonAriaDesc: {
    id: 'admanagement.adquality.filterPanelApplyButtonAriaDesc',
    defaultMessage: 'Click to apply selected options to the filter',
  },
  blockRequestSource: {
    id: 'admanagement.adquality.blockRequestSource',
    defaultMessage: 'Block request source',
  },
  adCreativeServiceLoadEntityFailed: {
    id: 'admanagement.adquality.adCreativeServiceLoadEntityFailed',
    defaultMessage: 'Failed to load entity',
  },
  adqualityInvalidState: {
    id: 'admanagement.adquality.invalidState',
    defaultMessage: 'You have arrived in an invalid state, please report.',
  },
  adqualityFailedToBlockAds: {
    id: 'admanagement.adquality.failedToBlockAds',
    defaultMessage: 'Failed to block ads',
  },
  adqualityBlockAdsMissingFields: {
    id: 'admanagement.adquality.blockAdsMissingFields',
    defaultMessage: 'BlockAds - You have {count} missing fields, please fill them to continue',
  },
  adqualityUpdateBlockedAdsStatusFailed: {
    id: 'admanagement.adquality.updatedBlockedAdsStatusFailed',
    defaultMessage: 'Failed to update blocked ads status',
  },
  adqualityBlockedAdsSuccessfully: {
    id: 'admanagement.adquality.blockedAdsSuccessfully',
    defaultMessage: 'Blocked ads successfully',
  },
  adqualityBlockImagesFailed: {
    id: 'admanagement.adquality.blockImagesFailed',
    defaultMessage: 'Failed to block images',
  },
  adqualityBlockImagesSuccess: {
    id: 'admanagement.adquality.blockImagesSuccess',
    defaultMessage: 'Block Images success',
  },
  adqualityBlockImagesMissingFields: {
    id: 'admanagement.adquality.blockImagesMissingFields',
    defaultMessage: 'Block Images - You have {count} missing/incorrect fields, please fill them to continue',
  },
  adqualityDownloadBlockedImagesFailed: {
    id: 'admanagement.adquality.downloadBlockedImagesFailed',
    defaultMessage: 'Failed to download blocked images',
  },
  adqualityDownloadBlockedImagesSuccess: {
    id: 'admanagement.adquality.downloadBlockedImagesSuccess',
    defaultMessage: 'Blocked images data download success',
  },
  adqualityDownloadAdContextFailed: {
    id: 'admanagement.adquality.downloadAdContextFailed',
    defaultMessage: 'Failed to download ad context',
  },
  adqualityNoDataToDownload: {
    id: 'admanagement.adquality.noDataToDownload',
    defaultMessage: 'It appears as though there is currently no data to download!',
  },
  adqualityBlockedAdsDataDownloadSuccess: {
    id: 'admanagement.adquality.blockedAdsDataDownloadSuccess',
    defaultMessage: 'Blocked ads data download success',
  },
  adqualityUpdatedBlockedAdsStatusSuccess: {
    id: 'admanagement.adquality.updatedBlockedAdsStatusSuccess',
    defaultMessage: 'Update blocked ads {ID} status success',
  },
  adqualityLoadListingAdDataFailed: {
    id: 'admanagement.adquality.loadListingAdDataFailed',
    defaultMessage: 'Failed to load ad listing data',
  },
  adqualityLoadRevenueAdDataFailed: {
    id: 'admanagement.adquality.loadRevenueAdDataFailed',
    defaultMessage: 'Failed to load ad revenue data',
  },
  adqualityTimeoutQueringForData: {
    id: 'admanagement.adquality.timeoutQueringForData',
    defaultMessage:
      'An error occurred. This is likely due to a timeout querying the data for the time range selected. Please try a shorter date range. {trackingId}',
  },
  adqualityProvideSearchTerm: {
    id: 'admanagement.adquality.provideSearchTerm',
    defaultMessage: 'Please provide a search term, while searching over multiple days',
  },
  adqualityAdPerformanceDataDownloadSuccess: {
    id: 'admanagement.adquality.adPerformanceDataDownloadSuccess',
    defaultMessage: 'Ad Performance data download success',
  },
  adDescriptionCheckboxLabel: {
    id: 'admanagement.adquality.adDescriptionCheckboxLabel',
    defaultMessage: 'Include description',
  },
});
