import { getAppStore, Publisher, putEntity, UserContext } from '../../../../../@data';
import { PublisherServingConfig } from '../../../../../@data/store/schema/models/ServingConfigs';

export async function updatePublisherServingConfig(
  publisher: Publisher,
  body: PublisherServingConfig,
  userContext?: UserContext
): Promise<PublisherServingConfig> {
  const effectiveUserContext = userContext || getAppStore().userContext;
  const publisherServingConfig = await putEntity([publisher], body, effectiveUserContext, PublisherServingConfig);
  return publisherServingConfig;
}
