import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { AdSource, LifeCycleStatus, getAppStore, onLoadingChanged, onNotificationChanged } from '../../../../../@data';
import { adQualityLocales } from '../../../../../config/locale';
import messages from '../../AdReview/AdCreativePage.messages';
import { onBlockAdsDataChanged, onRefreshBlockSummary, onSAMBlockAdsDataChanged } from '../actions';
import { getBlockSummaryData, getSAMBlockSummaryData } from '../services/AdBlockService';
import { BlockAdsData, SAMBlockSummaryData } from '../store/schema/models';
import { getStore } from '../store/store';

orchestrator(onRefreshBlockSummary, async (msg) => {
  const { publisher } = getAppStore();
  const { active } = getStore();
  if (publisher && publisher.id) {
    try {
      onLoadingChanged(true);
      const adSource = active.selectedAdSource;

      let blockSummaryParams = new URLSearchParams([
        ['targetCustomerId', publisher.id.toString()],
        ['creativeagglevel', 'listingid'],
        ['adtypes', 'TA,PA'],
        ['adSources', 'PS,AIM'],
      ]);
      if (adSource === AdSource.Microsoft) {
        onBlockAdsDataChanged([]);
        let blockSummaryData: BlockAdsData[] = [];

        if (active.selectedAccount) {
          if (active.selectedAccount === -1) {
            blockSummaryData = await getBlockSummaryData([['publisher', publisher.id!.toString()]], blockSummaryParams);
            // } else if (active.selectedAdUnit && active.selectedAdUnit !== -1) {
            //   adPerfData = await getAdPerfData([['adunit', active.selectedAdUnit.toString()]], params);
            //   blockSummaryData = await getBlockSummaryData([['adunit', active.selectedAdUnit.toString()]], blockSummaryParams);
          } else {
            blockSummaryData = await getBlockSummaryData([['account', active.selectedAccount.toString()]], blockSummaryParams);
          }
        }
        blockSummaryData.forEach((d) => (d.isEnabled ? (d.status = LifeCycleStatus.Active) : (d.status = LifeCycleStatus.Inactive)));

        if (active.blockSummaryStartDate) {
          blockSummaryData = blockSummaryData.filter(
            (blockSummary) =>
              blockSummary.modifiedDateTime &&
              active.blockSummaryStartDate &&
              new Date(blockSummary.modifiedDateTime) >= active.blockSummaryStartDate
          );
        }
        if (active.blockSummaryEndDate) {
          blockSummaryData = blockSummaryData.filter(
            (blockSummary) =>
              blockSummary.modifiedDateTime &&
              active.blockSummaryEndDate &&
              new Date(blockSummary.modifiedDateTime) <= active.blockSummaryEndDate
          );
        }
        onBlockAdsDataChanged(blockSummaryData);
      } else {
        onSAMBlockAdsDataChanged([]);
        let blockSummaryData: SAMBlockSummaryData[] = [];

        blockSummaryParams = new URLSearchParams([
          ['targetCustomerId', publisher.id.toString()],
          ['NumImages', 2],
        ]);

        if (active.selectedMarket) {
          blockSummaryData = await getSAMBlockSummaryData([['publisher', publisher.id!.toString()]], blockSummaryParams);
          if (active.selectedBlockedMarketId !== undefined) {
            if (active.selectedBlockedMarketId === 0) {
              blockSummaryData = blockSummaryData.filter((blockSummary) => blockSummary.locale === 'global');
            } else {
              blockSummaryData = blockSummaryData.filter(
                (blockSummary) =>
                  blockSummary.locale === 'global' || blockSummary.locale === adQualityLocales[active.selectedBlockedMarketId!]
              );
            }
          }
          if (active.blockSummaryStartDate) {
            blockSummaryData = blockSummaryData.filter(
              (blockSummary) =>
                blockSummary.modifiedDateTime &&
                active.blockSummaryStartDate &&
                new Date(blockSummary.modifiedDateTime) >= active.blockSummaryStartDate
            );
          }
          if (active.blockSummaryEndDate) {
            blockSummaryData = blockSummaryData.filter(
              (blockSummary) =>
                blockSummary.modifiedDateTime &&
                active.blockSummaryEndDate &&
                new Date(blockSummary.modifiedDateTime) <= active.blockSummaryEndDate
            );
          }
          onSAMBlockAdsDataChanged(blockSummaryData);
        }
      }
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adCreativeServiceLoadEntityFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
