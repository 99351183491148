import { AdPropertyType, AdUnitServingTier, AdUnitType, PublisherGroupConfig } from '../enums';
import { IAdUnitClassification, IAttribute } from '../interfaces';
import { AdSenseAdUnitStatus } from './AdSenseAdUnitStatus';
import { Model } from './Model';

export class AdUnit extends Model {
  public apiPath = undefined;
  public className = 'adunits';
  public classification: IAdUnitClassification;
  public attributes?: IAttribute;
  public channelIds: number[];
  public publisherGroupConfiguration?: PublisherGroupConfig;
  public storeIds?: number[];
  public adUnitType?: AdUnitType;
  public overrideNetworkAffiliation?: boolean;
  public accountId?: number;
  public accountName?: string;
  public adPropertyType?: AdPropertyType;
  public etag?: string;
  public servingCostTier?: AdUnitServingTier;
  public partnerCodes?: string[];
  public propertyId?: number;
  public propertyUrl?: string;
  public mediatedWithAdSense: AdSenseAdUnitStatus;
  public creationDate: string;
  public taxonomy: string;
  public requireImpressionFeedbackForBillableTraffic?: boolean;
}
