import { defineMessages } from 'react-intl';

export default defineMessages({
  ProfileReviewTSTFailedTitle: {
    id: 'notifications.profileReviewTSTFailedTitle',
    defaultMessage: 'Your profile has been rejected ',
  },
  ProfileReviewTSTFailedContent: {
    id: 'notifications.profileReviewTSTFailedContent',
    defaultMessage:
      'We have rejected your profile because it doesn’t comply with our Terms and Conditions. In seven days, all account information and data will be deleted and cannot be restored.',
  },
  MissingPaymentInformationTitle: {
    id: 'notifications.missingPaymentInformationTitle',
    defaultMessage: 'Update your payment information',
  },
  MissingPaymentInformationContent: {
    id: 'notifications.missingPaymentInformationContent',
    defaultMessage:
      'You’ll need to set up your payment information to start earning with {appName}. Add your payment details to get started.',
  },
  MissingPaymentInformationPrimaryActionLabel: {
    id: 'notifications.missingPaymentInformationAction',
    defaultMessage: 'Set up payments',
  },
  PolicyUpdatePrimaryActionLabel: {
    id: 'notifications.policyUpdatePrimaryActionLabel',
    defaultMessage: 'Accept Terms and Conditions',
  },
  PaymentFailedTitle: {
    id: 'notifications.PaymentFailedTitle',
    defaultMessage: 'Payment Failed',
  },
  PaymentFailedReason_account_closed: {
    id: 'notifications.PaymentFailedReason_account_closed',
    defaultMessage: 'Your payout failed because your bank account is closed. Please contact your banking institution for questions.',
  },
  PaymentFailedTitle_bank_account_restricted: {
    id: 'notifications.PaymentFailedTitle_bank_account_restricted',
    defaultMessage: 'Restricted payment method',
  },
  PaymentFailedReason_bank_account_restricted: {
    id: 'notifications.PaymentFailedReason_bank_account_restricted',
    defaultMessage:
      'Your bank may have restrictions on the type and number of transfers allowed. This can indicate that your account is a savings or non-checking account.',
  },
  PaymentFailedReasonStripe_could_not_process: {
    id: 'notifications.PaymentFailedReasonStripe_could_not_process',
    defaultMessage: 'Stripe is unable to process your payment.',
  },
  PaymentFailedReason_could_not_process: {
    id: 'notifications.PaymentFailedReason_could_not_process',
    defaultMessage: 'Your bank could not process this transfer.',
  },
  PaymentFailedTitle_declined: {
    id: 'notifications.PaymentFailedTitle_declined',
    defaultMessage: 'Payment declined',
  },
  PaymentFailedReason_declined: {
    id: 'notifications.PaymentFailedReason_declined',
    defaultMessage: 'Your bank has declined the transfer of your payment. Please contact your banking institution for clarification.',
  },
  PaymentFailedTitle_expired_card: {
    id: 'notifications.PaymentFailedTitle_expired_card',
    defaultMessage: 'Expired credit card',
  },
  PaymentFailedReason_expired_card: {
    id: 'notifications.PaymentFailedReason_expired_card',
    defaultMessage: 'Your card has expired. Please contact your card issuer to fix any issues or consider changing your payment method.',
  },
  PaymentFailedTitle_incorrect_account_holder_name: {
    id: 'notifications.PaymentFailedTitle_incorrect_account_holder_name',
    defaultMessage: 'Invalid account holder name',
  },
  PaymentFailedReason_incorrect_account_holder_name: {
    id: 'notifications.PaymentFailedReason_incorrect_account_holder_name',
    defaultMessage:
      'Your account details may have been entered incorrectly. Please check that you’ve entered the account holder’s name correctly before trying again.',
  },
  PaymentFailedTitle_invalid_account_number: {
    id: 'notifications.PaymentFailedTitle_invalid_account_number',
    defaultMessage: 'Invalid payment method',
  },
  PaymentFailedReason_invalid_account_number: {
    id: 'notifications.PaymentFailedReason_invalid_account_number',
    defaultMessage:
      'Your payment details may be invalid. Please check that you’ve entered your account number correctly before trying again.',
  },
  PaymentFailedTitle_invalid_card: {
    id: 'notifications.PaymentFailedTitle_invalid_card',
    defaultMessage: 'Invalid payment method',
  },
  PaymentFailedReason_invalid_card: {
    id: 'notifications.PaymentFailedReason_invalid_card',
    defaultMessage: 'Your payment method is invalid. Please contact your card issuer and resolve the issue before trying again.',
  },
  PaymentFailedTitle_invalid_currency: {
    id: 'notifications.PaymentFailedTitle_invalid_currency',
    defaultMessage: 'Unavailable currency',
  },
  PaymentFailedReason_invalid_currency: {
    id: 'notifications.PaymentFailedReason_invalid_currency',
    defaultMessage:
      'Your banking institution was unable to process your payment in your desired currency. Check with your banking institution to make sure that they allow international transactions.',
  },
  PaymentFailedTitle_lost_or_stolen_card: {
    id: 'notifications.PaymentFailedTitle_lost_or_stolen_card',
    defaultMessage: 'Lost or stolen card',
  },
  PaymentFailedReason_lost_or_stolen_card: {
    id: 'notifications.PaymentFailedReason_lost_or_stolen_card',
    defaultMessage:
      'Your card has been reported lost or stolen. Please contact the card issuer to fix any issues with your card. Then update your card or switch to a different payment method.',
  },
  PaymentFailedTitle_no_account: {
    id: 'notifications.PaymentFailedTitle_no_account',
    defaultMessage: 'Invalid account',
  },
  PaymentFailedReason_no_account: {
    id: 'notifications.PaymentFailedReason_no_account',
    defaultMessage: 'Your payment method is invalid. Please check that you’ve entered your account number correctly before trying again.',
  },
  PaymentFailedReasonLocate_no_account: {
    id: 'notifications.PaymentFailedReasonLocate_no_account',
    defaultMessage:
      'We couldn’t locate your banking details. Please check that you’ve entered your account number correctly before trying again. ',
  },
  ClarityLinkExpiredTitle: {
    id: 'notifications.ClarityLinkExpiredTitle',
    defaultMessage: 'Clarity project {projectId} linking failed',
  },
  ClarityLinkExpiredContent: {
    id: 'notifications.ClarityLinkExpiredContent',
    defaultMessage:
      'The request to link your site {propertyName} with Clarity project ID {projectId} failed as the request was not approved by Clarity admin. Please re-trigger the request to ensure your ad serving does not get impacted.',
  },
  ClarityLinkDeclinedTitle: {
    id: 'notifications.ClarityLinkDeclinedTitle',
    defaultMessage: 'Clarity project {projectId} linking failed',
  },
  ClarityLinkDeclinedContent: {
    id: 'notifications.ClarityLinkDeclinedContent',
    defaultMessage:
      'The request to link your site {propertyName} with Clarity project ID {projectId} failed as the request was rejected by Clarity admin. Please re-trigger the request to ensure your ad serving does not get impacted.',
  },
  ClarityLinkExpiredPrimaryActionLabel: {
    id: 'notifications.ClarityLinkExpiredPrimaryActionLabel',
    defaultMessage: 'Resend link request',
  },
  ClarityLinkDeclinedPrimaryActionLabel: {
    id: 'notifications.ClarityLinkDeclinedPrimaryActionLabel',
    defaultMessage: 'Resend link request',
  },
  ClarityLinkExpiredSecondaryActionLabel: {
    id: 'notifications.ClarityLinkExpiredSecondaryActionLabel',
    defaultMessage: 'Edit Project Id',
  },
  ClarityLinkDeclinedSecondaryActionLabel: {
    id: 'notifications.ClarityLinkDeclinedSecondaryActionLabel',
    defaultMessage: 'Edit Project Id',
  },
  ChooseGamNetworkFailureContent: {
    id: 'gamNetworkSelection.notification.post.failed',
    defaultMessage: 'Failed to update google network selection. Please refresh the page and try again later.',
  },
  ChooseGamNetworkFailureTitle: {
    id: 'gamNetworkSelection.notification.post.failed.title',
    defaultMessage: 'Update google network selection',
  },
  ChangingItemStatusFailureTitle: {
    id: 'grid.changingItemStatusFailureTitle',
    defaultMessage: 'Status change error',
  },
  ChangingItemStatusFailureContent: {
    id: 'grid.changingItemStatusFailureContent',
    defaultMessage: 'Something went wrong while changing the status. Please try again later.',
  },
  DeletingItemFailureTitle: {
    id: 'grid.deletingItemFailureTitle',
    defaultMessage: 'Delete failed',
  },
  DeletingItemFailureContent: {
    id: 'grid.deletingItemFailureContent',
    defaultMessage: 'Something went wrong while deleting the item. Please try again later.',
  },
  DuplicateGridItemFailureTitle: {
    id: 'grid.duplicatingGridItemFailureTitle',
    defaultMessage: 'Duplicating grid item failed',
  },
  DuplicateGridItemFailureContent: {
    id: 'grid.duplicatingGridItemFailureContent',
    defaultMessage: 'Something went wrong while duplicating the grid item. Please try again later',
  },
  GridDataDownloadFailureTitle: {
    id: 'grid.dataDownloadFailureTitle',
    defaultMessage: 'No data found',
  },
  GridDataDownloadFailureContent: {
    id: 'grid.dataDownloadFailureContent',
    defaultMessage: 'No data available to download',
  },
  FeedbackErrorMessageContent: {
    id: 'feedbackErrorMessage.content',
    defaultMessage: 'Something went wrong while submitting your feedback. Please try again later.',
  },
  FeedbackErrorMessageTitle: {
    id: 'feedbackErrorMessage.title',
    defaultMessage: 'Feedback submit failed',
  },
  FeedBackInvalidFormMessageContent: {
    id: 'feedBackInvalidFormMessage.content',
    defaultMessage: 'Feedback form is invalid. Please check the form details and try again.',
  },
  FeedBackInvalidFormMessageTitle: {
    id: 'feedBackInvalidFormMessage.title',
    defaultMessage: 'Feedback submit failed',
  },
  PropertyEditFailedNotificationContent: {
    id: 'siteManagement.propertyEditFailedNotificationContent',
    defaultMessage: 'Something went wrong while updating the item. Please try again later.',
  },
  PropertyEditFailedNotificationTitle: {
    id: 'siteManagement.propertyEditFailedNotificationTitle',
    defaultMessage: 'Failed to submit site details',
  },
  LoadingSitesFailedTitle: {
    id: 'adunit.loadingSitesFailedTitle',
    defaultMessage: 'No site found',
  },
  LoadingSitesFailedContent: {
    id: 'adunit.loadingSitesFailedContent',
    defaultMessage: 'Something went wrong while loading your sites. Please try again later',
  },
  DuplicatedSiteCreationErrorNotificationTitle: {
    id: 'tython.adunit.create.duplicated.site.error.notification.title',
    defaultMessage: 'Duplicated site creation',
  },
  DuplicatedSiteCreationErrorNotificationContent: {
    id: 'tython.adunit.create.duplicated.site.error.notification.content',
    defaultMessage: 'Your new site ({name}: {url}) has duplicated name or url against an existing site',
  },
  AdblockUpdateFailedTitle: {
    id: 'adBlock.adblockUpdateFailedTitle',
    defaultMessage: 'Ad block update failed',
  },
  AdblockUpdateFailedContent: {
    id: 'adBlock.adblockUpdateFailedContent',
    defaultMessage: `Something went wrong while updating Ad blocks for site {siteId}. Please try again later`,
  },
  AdblockToggleFailedContent: {
    id: 'adBlock.adblockToggleFailedContent',
    defaultMessage: `Something went wrong while toggling block settings for site {siteId}. Please try again later`,
  },
  AdblockToggleFailedTitle: {
    id: 'adBlock.adblockToggleFailedTitle',
    defaultMessage: `Ad block update failed`,
  },
  ToggleUniversalBlockFailedContent: {
    id: 'adBlock.toggleUniversalBlockFailedContent',
    defaultMessage: `We encountered an issue while toggling universal blocks. Please try again later.`,
  },
  ToggleUniversalBlockFailedTitle: {
    id: 'adBlock.toggleUniversalBlockFailedTitle',
    defaultMessage: `Ad block update failed`,
  },
  UniversalAdblockUpdateFailedContent: {
    id: 'adBlock.universalAdblockUpdateFailedContent',
    defaultMessage: `We encountered an issue while updating universal blocks. Please try again later.`,
  },
  UniversalAdblockUpdateFailedTitle: {
    id: 'adBlock.universalAdblockUpdateFailedTitle',
    defaultMessage: `Ad block update failed`,
  },
  PayoutInformationFailedContent: {
    id: 'paymentPage.payoutInformationFailedContent',
    defaultMessage: 'Something went wrong while loading your payout information. Please try again later',
  },
  PayoutInformationFailedTitle: {
    id: 'paymentPage.payoutInformationFailedTitle',
    defaultMessage: 'No data found',
  },
  LoadingBalanceDataFailedContent: {
    id: 'paymentPage.loadingBalanceDataFailedContent',
    defaultMessage: 'Something went wrong while loading your balance data. Please try again later',
  },
  LoadingBalanceDataFailedTitle: {
    id: 'paymentPage.loadingBalanceDataFailedTitle',
    defaultMessage: 'No data found',
  },
  LoadPayoutInfoFailedContent: {
    id: 'loadPayoutInfo.failedContent',
    defaultMessage: 'Something went wrong while loading your payout information. Please try again later',
  },
  LoadPayoutInfoFailedTitle: {
    id: 'loadPayoutInfo.failedTitle',
    defaultMessage: 'No data found',
  },
  DeletingUserWentWrongContent: {
    id: 'userManagement.deletingUserWentWrongContent',
    defaultMessage: 'Something went wrong while deleting user.',
  },
  DeletingUserWentWrongTitle: {
    id: 'userManagement.deletingUserWentWrongTitle',
    defaultMessage: 'Failed to delete user',
  },

  PaymentFailedAccountClosedTitle: {
    id: 'notifications.PaymentFailedAccountClosedTitle',
    defaultMessage: 'Payment Failed',
  },
  PaymentFailedAccountClosedContent: {
    id: 'notifications.PaymentFailedAccountClosedContent',
    defaultMessage: 'Your payout failed because your bank account is closed. Please contact your banking institution for questions.',
  },
  PaymentFailedBankAccountRestrictedTitle: {
    id: 'notifications.PaymentFailedBankAccountRestrictedTitle',
    defaultMessage: 'Restricted payment method',
  },
  PaymentFailedBankAccountRestrictedContent: {
    id: 'notifications.PaymentFailedBankAccountRestrictedContent',
    defaultMessage:
      'Your bank may have restrictions on the type and number of transfers allowed. This can indicate that your account is a savings or non-checking account.',
  },
  PaymentFailedStripeCouldNotProcessTitle: {
    id: 'notifications.PaymentFailedStripeCouldNotProcessTitle',
    defaultMessage: 'Payment Failed',
  },
  PaymentFailedStripeCouldNotProcessContent: {
    id: 'notifications.PaymentFailedStripeCouldNotProcessContent',
    defaultMessage: 'Stripe is unable to process your payment.',
  },
  PaymentFailedCouldNotProcessTitle: {
    id: 'notifications.PaymentFailedCouldNotProcessTitle',
    defaultMessage: 'Payment Failed',
  },
  PaymentFailedCouldNotProcessContent: {
    id: 'notifications.PaymentFailedCouldNotProcessContent',
    defaultMessage: 'Your bank could not process this transfer.',
  },
  PaymentFailedDeclinedTitle: {
    id: 'notifications.PaymentFailedDeclinedTitle',
    defaultMessage: 'Payment declined',
  },
  PaymentFailedDeclinedContent: {
    id: 'notifications.PaymentFailedDeclinedContent',
    defaultMessage: 'Your bank has declined the transfer of your payment. Please contact your banking institution for clarification.',
  },
  PaymentFailedExpiredCardTitle: {
    id: 'notifications.PaymentFailedExpiredCardTitle',
    defaultMessage: 'Expired credit card',
  },
  PaymentFailedExpiredCardContent: {
    id: 'notifications.PaymentFailedExpiredCardContent',
    defaultMessage: 'Your card has expired. Please contact your card issuer to fix any issues or consider changing your payment method.',
  },
  PaymentFailedIncorrectAccountHolderNameTitle: {
    id: 'notifications.PaymentFailedIncorrectAccountHolderNameTitle',
    defaultMessage: 'Invalid account holder name',
  },
  PaymentFailedIncorrectAccountHolderNameContent: {
    id: 'notifications.PaymentFailedIncorrectAccountHolderNameContent',
    defaultMessage:
      "Your account details may have been entered incorrectly. Please check that you've entered the account holder's name correctly before trying again.",
  },
  PaymentFailedInvalidAccountNumberTitle: {
    id: 'notifications.PaymentFailedInvalidAccountNumberTitle',
    defaultMessage: 'Invalid payment method',
  },
  PaymentFailedInvalidAccountNumberContent: {
    id: 'notifications.PaymentFailedInvalidAccountNumberContent',
    defaultMessage:
      "Your payment details may be invalid. Please check that you've entered your account number correctly before trying again.",
  },
  PaymentFailedInvalidCardTitle: {
    id: 'notifications.PaymentFailedInvalidCardTitle',
    defaultMessage: 'Invalid payment method',
  },
  PaymentFailedInvalidCardContent: {
    id: 'notifications.PaymentFailedInvalidCardContent',
    defaultMessage: 'Your payment method is invalid. Please contact your card issuer and resolve the issue before trying again.',
  },
  PaymentFailedInvalidCurrencyTitle: {
    id: 'notifications.PaymentFailedInvalidCurrencyTitle',
    defaultMessage: 'Unavailable currency',
  },
  PaymentFailedInvalidCurrencyContent: {
    id: 'notifications.PaymentFailedInvalidCurrencyContent',
    defaultMessage:
      'Your banking institution was unable to process your payment in your desired currency. Check with your banking institution to make sure that they allow international transactions.',
  },
  PaymentFailedLostOrStolenCardTitle: {
    id: 'notifications.PaymentFailedLostOrStolenCardTitle',
    defaultMessage: 'Lost or stolen card',
  },
  PaymentFailedLostOrStolenCardContent: {
    id: 'notifications.PaymentFailedLostOrStolenCardContent',
    defaultMessage:
      'Your card has been reported lost or stolen. Please contact the card issuer to fix any issues with your card. Then update your card or switch to a different payment method.',
  },
  PaymentFailedNoAccountTitle: {
    id: 'notifications.PaymentFailedNoAccountTitle',
    defaultMessage: 'Invalid account',
  },
  PaymentFailedNoAccountContent: {
    id: 'notifications.PaymentFailedNoAccountContent',
    defaultMessage: "Your payment method is invalid. Please check that you've entered your account number correctly before trying again.",
  },
  PaymentFailedNoAccountLocatedTitle: {
    id: 'notifications.PaymentFailedNoAccountLocatedTitle',
    defaultMessage: 'Invalid account',
  },
  PaymentFailedNoAccountLocatedContent: {
    id: 'notifications.PaymentFailedNoAccountLocatedContent',
    defaultMessage:
      "We couldn't locate your banking details. Please check that you've entered your account number correctly before trying again.",
  },
  CreateTythonAdUnitFailedTitle: {
    id: 'notifications.createTythonAdunitFailedTitle',
    defaultMessage: 'Create ad unit failed',
  },
  ProfileInformationUpdatedTitle: {
    id: 'notifications.profileInformationUpdated',
    defaultMessage: `You've updated your profile.`,
  },
  ProfileInformationUpdatedContent: {
    id: 'notifications.profileInformationUpdatedContent',
    defaultMessage: `Your profile information has been updated`,
  },
});
