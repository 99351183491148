import { useEffect, useState } from 'react';

export const usePUID = (): string | null => {
  const [puid, setPUID] = useState<string | null>(null);

  useEffect(() => {
    // Check if "msal.account.keys" exists in localStorage
    const msalAccountKeys = localStorage.getItem('msal.account.keys');

    if (msalAccountKeys) {
      try {
        // Parse the value as JSON
        const keysArray = JSON.parse(msalAccountKeys);

        if (Array.isArray(keysArray) && keysArray[0]) {
          const idTokenClaims = JSON.parse(localStorage.getItem(keysArray[0])!)?.idTokenClaims;

          if (idTokenClaims?.puid) {
            // Set the PUID state to the retrieved value
            setPUID(idTokenClaims.puid);
          }
        }
      } catch (error) {
        console.error('Error parsing JSON or retrieving PUID:', error);
      }
    }
  }, []);

  return puid;
};
