import { IStyle } from '@fluentui/merge-styles';
import { ITheme } from '@fluentui/theme';
import { BreakPoints, IPubcenterSpacing } from '../../../../../../theme';

export interface IPaymentsOnboardingStyles {
  root: IStyle;
  buttonContainer: IStyle;
  dialogContentContainer: IStyle;
  text: IStyle;
  defaultButton: IStyle;
  xPayContainer: IStyle;
  xPayOverlay: IStyle;
  setupNotificationWrapper: IStyle;
  setupNotificationText: IStyle;
  xPayLoadingSpinner: IStyle;
  transparentContainer: IStyle;
  targetPointer: IStyle;
  actionButton: IStyle;
  tncNotificationText: IStyle;
}
export interface IPaymentOnboardingStyleProps {
  theme: ITheme;
}

export const getStyles = (props: IPaymentOnboardingStyleProps): IPaymentsOnboardingStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const { ResponsiveSmall } = BreakPoints;

  return {
    root: {
      padding: spacing.size8,
      [`#xpay-cpconboarding-container > div:nth-child(2) > div:nth-child(1)`]: { boxShadow: '1px 1px 4px -2px lightgray' },
    },
    buttonContainer: {
      display: 'flex',
      marginTop: spacing.l2,
      justifyContent: 'flex-end',
      '> button:first-child': { marginRight: spacing.size12 },
    },
    dialogContentContainer: {},
    setupNotificationWrapper: {
      display: 'flex',
      width: `calc(100vw - 64px)`,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    xPayContainer: {
      zIndex: 4,
      position: 'relative',
      backgroundColor: '#ffffff',
      borderRadius: 12,
    },
    xPayOverlay: { backgroundColor: 'lightgray', opacity: 0.6, zIndex: 3, overflowY: 'auto', transition: 'opacity 3s ease-in-out' },
    text: { color: theme.semanticColors.primaryButtonText },
    defaultButton: { color: theme.semanticColors.primaryButtonBackground },
    xPayLoadingSpinner: {
      paddingTop: 24,
    },
    transparentContainer: { opacity: 0 },
    setupNotificationText: {
      maxWidth: 'calc(100vw - 181px)',
      [ResponsiveSmall]: {
        alignSelf: 'center',
      },
    },
    tncNotificationText: {
      alignItems: 'center',
      maxWidth: 'calc(100vw - 164px)',
      [ResponsiveSmall]: {
        alignSelf: 'center',
      },
    },
    targetPointer: {
      position: 'absolute',
      width: 24,
      height: 24,
      backgroundColor: 'rgb(0, 120, 212)',
      transform: 'rotate(132deg)',
      bottom: -6,
      left: 30,
    },
    actionButton: {
      height: spacing.size24,
    },
  };
};
