import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing } from '../../../../../../../theme';
import { INoScriptPageClassNames } from './NoScriptPage.types';

export const getClassNames = (): INoScriptPageClassNames => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const fonts = theme.fonts as IPubCenterFonts;

  return mergeStyleSets({
    root: {
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
    },
    title: {
      margin: `${spacing.size16} 0`,
      fontWeight: FontWeights.regular,
      color: theme.palette.neutralSecondary,
      fontSize: '26px',
    },
    description: {
      marginBottom: spacing.size16,
      color: theme.palette.neutralSecondary,
      fontSize: fonts.size16.fontSize,
      lineHeight: fonts.size16.lineHeight,
    },
  });
};
