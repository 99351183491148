import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import { AdUnit, Model, getAccessPermission, getAppStore, isTythonSelfServeUser } from '../../../../../@data';
import { hasCreateAccess } from '../../../../../@data/services/UserRoleService';
import {
  CommandBarActions,
  Direction,
  GridSelectionMode,
  IBuildCommandBarProps,
  IGridPageCommandBarItemProps,
} from '../../../../../layout/GridPageV2';
import { getTythonAdUnitCreatePageRoute } from '../../../../../utils/routeUtils';
import { getAdunitStore } from '../../@data';
import { updateAdUnitMediationStatus } from '../../@data/actions';
import { disableAdunitMediationStatus } from '../../@data/services/AdunitService';
import messages from '../ManagePage.messages';
import { getFilterItems } from './getFilterItems';

export function getCommandBarItems(formatMessage, history, match, isWindowExtraSmallSize): IBuildCommandBarProps<Model> | undefined {
  const { publisher, account } = getAppStore();
  const {
    adUnitsRaw: { data, isLoading },
    mediationUpdatePool,
  } = getAdunitStore();

  const nonTythonItems: IBuildCommandBarProps<Model> | undefined =
    (data && data.length !== 0 && publisher && account) || isLoading
      ? {
          includeActions: [CommandBarActions.custom],
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.addFilter,
            CommandBarActions.edit,
            CommandBarActions.activate,
            CommandBarActions.deactivate,
            CommandBarActions.delete,
            CommandBarActions.download,
            CommandBarActions.modifyColumns,
            CommandBarActions.duplicate,
            CommandBarActions.export,
            CommandBarActions.upload,
          ],
          getCustomCommandBarItems: () => {
            const accessPermission = getAccessPermission(AdUnit);

            const commandBarItems: IGridPageCommandBarItemProps[] = [
              {
                key: 'new',
                placement: { direction: Direction.before },
                commandBarButtonAs: () => (
                  <PrimaryButton
                    iconProps={{ iconName: 'Add' }}
                    text={isWindowExtraSmallSize ? '' : formatMessage(messages.create)}
                    onClick={() => {
                      history.push(`${match.url}/create`);
                    }}
                    disabled={
                      (data === undefined && publisher === undefined && account === undefined) ||
                      account?.id === 0 ||
                      isLoading ||
                      !hasCreateAccess(accessPermission)
                    }
                  />
                ),
                selectionModes: [GridSelectionMode.None],
              },
            ];

            return commandBarItems;
          },
          filterItems: getFilterItems(formatMessage),
        }
      : undefined;

  const tythonItems: IBuildCommandBarProps<Model> | undefined =
    (data && data.length !== 0) || isLoading
      ? {
          primaryButtonUrl: getTythonAdUnitCreatePageRoute(),
          primaryButtonLabel: messages.create,
          includeActions: [CommandBarActions.new],
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.addFilter,
            CommandBarActions.edit,
            CommandBarActions.activate,
            CommandBarActions.deactivate,
            CommandBarActions.code,
            CommandBarActions.delete,
            CommandBarActions.download,
            CommandBarActions.modifyColumns,
          ].filter((item) =>
            mediationUpdatePool.length
              ? ![CommandBarActions.edit, CommandBarActions.deactivate, CommandBarActions.delete].includes(item)
              : item
          ),
          onPreDeleteItems: async (items: Model[]) => {
            const deletedItem = items[0] as AdUnit;
            if (publisher && account && deletedItem && deletedItem?.mediatedWithAdSense?.mediationStatus === true) {
              await disableAdunitMediationStatus(publisher, account, [deletedItem.id]);
            }
          },
          onItemsStatusChanged: (adunits, status) => updateAdUnitMediationStatus(adunits as AdUnit[], status),
          filterItems: getFilterItems(formatMessage),
        }
      : undefined;

  return isTythonSelfServeUser() ? tythonItems : nonTythonItems;
}
