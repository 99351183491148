import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  deleteEntity,
  getAppStore,
  NotificationLabel,
  NotificationSeverity,
  onAuthLogout,
  onNotificationCardAdded,
  onNotificationChanged,
  showErrorToastNotification,
  showSuccessToastNotification,
  User,
} from '../../../../../../@data';
import notificationMessages from '../../../../../../NotificationsPanel.Types.messages';
import messages from '../../SettingsPage.messages';
import { onUserSelfDeleted } from '../actions/onUserSelfDeleted';

orchestrator(onUserSelfDeleted, async () => {
  const { publisher, userContext } = getAppStore();

  if (userContext && publisher) {
    try {
      const user = new User(userContext.id);

      await deleteEntity([publisher, user], undefined, userContext);

      showSuccessToastNotification({ textMessageDescriptor: messages.deletingUserSuccess, intlMessageValues: { name: userContext.name } });

      onAuthLogout();
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: notificationMessages.DeletingUserWentWrongContent });
      onNotificationCardAdded({
        // tslint:disable-next-line: no-any
        severity: NotificationSeverity.Error,
        titleMessageDescriptor: notificationMessages.DeletingUserWentWrongTitle,
        contentMessageDescriptor: notificationMessages.DeletingUserWentWrongContent,
        notificationLabel: NotificationLabel.DeletingUserWentWrong,
      });
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.invalidStateLoginOrReport,
    });
  }
});
