import { getAppStore, listEntity, UserContext } from '../../../../../@data';
import { BrandSearchResponse } from '../../../../../@data/store/schema/models/BrandSearchResponse';

export async function fetchBrands(keyword: string, userContext?: UserContext): Promise<BrandSearchResponse[]> {
  const effectiveUserContext = userContext || getAppStore().userContext;
  const searchParams = new URLSearchParams({
    search: keyword,
  });

  return await listEntity([], effectiveUserContext, BrandSearchResponse, searchParams);
}
