import { action } from 'satcheljs';
import { Account, IStringDictionary } from '../../../../../@data';
import { IAccountViewModel } from '../store/schema';

export const onAccountChanged = action('onAccountChanged', (account: IAccountViewModel) => ({ account }));

export const onRawChanged = action('onRawChanged', (value?: Account) => ({ value }));

export const onAccountErrorChanged = action('onAccountErrorChanged', (values?: IStringDictionary) => ({ values }));

export const onFetchAccountsLoading = action('onFetchAccountsLoading', (isLoading: boolean) => ({ isLoading }));

export const onFetchingAccountsData = action('onFetchingAccountsData', (isFetchingData: boolean) => ({ isFetchingData }));

export const onAccountsFetched = action('onAccountsFetched', (items: Account[]) => ({ items }));
