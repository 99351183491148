import { AdUnit, Channel, Filter, Property, PublisherSearch } from '..';
import { completeAccess, UserRoleEntity, userRoleKey } from '../services/UserRoleService';
import { Model as UserRoleModel } from '../store/schema/userrolemodels/Model';

export const getAccessPermission = (entity): number => {
  const userRoles = localStorage.getItem(userRoleKey);
  if (userRoles === null) {
    return completeAccess;
  }
  const userRolesJSON = JSON.parse(userRoles);
  const userRolesMap = new Map<string, UserRoleModel>();

  // tslint:disable-next-line: forin
  for (const value in userRolesJSON) {
    userRolesMap.set(value, JSON.parse(userRolesJSON[value]));
  }

  let userRoleEntity: UserRoleModel | undefined;
  if (entity === AdUnit) {
    userRoleEntity = userRolesMap.get(UserRoleEntity.AdUnit);
  } else if (entity === Channel) {
    userRoleEntity = userRolesMap.get(UserRoleEntity.Channel);
  } else if (entity === Filter) {
    userRoleEntity = userRolesMap.get(UserRoleEntity.Filter);
  } else if (entity === Property) {
    userRoleEntity = userRolesMap.get(UserRoleEntity.Property);
  } else if (entity === PublisherSearch) {
    userRoleEntity = userRolesMap.get(UserRoleEntity.Publisher);
  }

  if (userRoleEntity) {
    return userRoleEntity.accessPermission;
  }

  return completeAccess;
};

export function getEntityAccessPermissions(userRoleEntity: UserRoleEntity): UserRoleModel | null {
  const userRoles = localStorage.getItem(userRoleKey);
  if (userRoles === null) {
    return null;
  }
  const userRolesJSON = JSON.parse(userRoles);

  for (const value in userRolesJSON) {
    if (value === userRoleEntity) {
      return JSON.parse(userRolesJSON[value]);
    }
  }

  return null;
}
