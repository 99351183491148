import { Filter, listEntity, Publisher, UserContext } from '../../../../../@data';

export const fetchFilterById = async (publisher: Publisher, id: string, userContext?: UserContext): Promise<Filter> => {
  const list = await listEntity([publisher], userContext, Filter, new URLSearchParams([['ids', id]]));

  if (!list || !list.length) {
    throw Error('Cannot find filter Id');
  }

  return list[0];
};