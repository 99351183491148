// The feature of Partner Codes is only enabled for the following publishers
export const partnerCodesEnabledPublishers =
  process.env.REACT_APP_ENV === 'production' ?
    [
      '230887', // Live Search (Prod)
      
      '251148833', // Contoso Limited (test publisher on Prod)

      // Add other Prod publishers here
    ]:
    [
      '279554782', // SI Clone MSN Native (SI)

      // Add other SI publishers here
    ];

export const isPartnerCodeEnabled = (publisherId: string | undefined): boolean => {
  if (partnerCodesEnabledPublishers.includes(publisherId ?? '0')) {
    return true;
  }

  return false;
};