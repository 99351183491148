import { Model, getAppStore } from '../../../../../@data';
import { CommandBarActions, IBuildCommandBarProps } from '../../../../../layout/GridPageV2';
import { getStore } from '../../@data';
import messages from '../ManagePage.messages';

export function getCommandBarItems(): IBuildCommandBarProps<Model> | undefined {
  const { publisher } = getAppStore();
  const { accounts, isLoading } = getStore();

  const commandBarItems =
    (accounts && accounts.length !== 0 && publisher) || isLoading
      ? {
          primaryButtonLabel: messages.create,
          includeActions: [CommandBarActions.new],
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.edit,
            CommandBarActions.activate,
            CommandBarActions.deactivate,
            CommandBarActions.duplicate,
            CommandBarActions.download,
            CommandBarActions.modifyColumns,
          ],
        }
      : undefined;

  return commandBarItems;
}
