import { useEffect } from 'react';

export const usePreloadSVGImages = (imageList: string[]) => {
  useEffect(() => {
    const preloadContainer = document.createElement('div');
    preloadContainer.style.display = 'none';

    imageList.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
      img.width = 0;
      img.height = 0;
      preloadContainer.appendChild(img);
    });

    document.body.appendChild(preloadContainer);

    return () => {
      // Cleanup to remove the preloaded images when not needed
      document.body.removeChild(preloadContainer);
    };
    // eslint-disable-next-line
  }, []);
};
