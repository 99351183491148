import { Body1, Body1Strong, Button, Field, InfoLabel, Input, Label, LabelProps, Spinner } from '@fluentui/react-components';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { getAppStore, onHelpInfoCalloutOpened } from '../../../../../@data';
import { useNativeAdSdk } from '../../../../../hooks';
import AdPreview from '../../TythonAdunit/components/AdUnitTypeSelector/PreviewComponent';
import AutoFitHeight from '../../TythonAdunit/resources/AutoFitHeight.svg';
import AutoFitWidth from '../../TythonAdunit/resources/AutoFitWidth.svg';
import messages from '../messages';
import { InfoLabelContent } from './V2HelpInfoBubble';
import { useAppAdUnitPrimaryFormStyles } from './styles';

const NativeAdDynamicAdPreview = ({ intl: { formatMessage }, config }) => {
  const { isSdkLoaded } = useNativeAdSdk();

  const { helpInfo, locale } = getAppStore();
  const classNames = useAppAdUnitPrimaryFormStyles();
  const [adWidth, setAdWidth] = useState(config.adWidth);
  const [adHeight, setAdHeight] = useState(config.adHeight);

  return (
    <>
      <Field
        label={{
          children: (_: unknown, slotProps: LabelProps) => (
            <Label>
              <Body1Strong>{formatMessage(messages.appAdUnitNativePreviewTitle)}</Body1Strong>
              <Button
                className={classNames.infoBubbleNoRequire}
                appearance="transparent"
                onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
              >
                <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
              </Button>
            </Label>
          ),
        }}
        className={classNames.sectionTitle}
      />
      <div className={classNames.dimensionInputsWrapper}>
        <Body1>{formatMessage(messages.nativeAdPreviewContainerSize)}</Body1>
        <Input
          className={classNames.dimensionInput}
          contentBefore={<img src={AutoFitWidth} alt={formatMessage(messages.nativeAdPreviewContainerSize)} />}
          value={`${adWidth}` ?? ''}
          onChange={(e) => {
            const newValue = parseInt(e.target.value, 10);
            if (newValue >= 0) {
              setAdWidth(newValue);
            } else if (e.target.value === '') {
              setAdWidth(0);
            }
          }}
          type="number"
        />
        <Body1Strong>x</Body1Strong>
        <Input
          className={classNames.dimensionInput}
          contentBefore={<img src={AutoFitHeight} alt={formatMessage(messages.nativeAdPreviewContainerSize)} />}
          value={`${adHeight}` ?? ''}
          onChange={(e) => {
            const newValue = parseInt(e.target.value, 10);
            if (newValue >= 0) {
              setAdHeight(newValue);
            } else if (e.target.value === '') {
              setAdHeight(0);
            }
          }}
          type="number"
        />
      </div>
      {isSdkLoaded ? (
        <div className={classNames.previewSDKWrapper}>
          <AdPreview config={{ ...config, adHeight: adHeight, adWidth: adWidth }} />
        </div>
      ) : (
        <Spinner size="tiny" />
      )}
    </>
  );
};

export default injectIntl(NativeAdDynamicAdPreview);
