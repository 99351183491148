/**
 * Types the responses coming from json and decorates them with the specified classes.
 *
 * @param typeToRestore The object to copy its type from, basically just do `new ObjectName`
 * @param obj The erased object that we need to restore.
 */
// tslint:disable-next-line:no-any
export function restoreTypeWithObject<T extends { constructor: any }>(typeToRestore: T, obj: any): T | undefined {
  if (!obj) {
    return undefined;
  }

  if (typeof obj === 'string') {
    try {
      obj = JSON.parse(obj);
    } catch (_) {
      return undefined;
    }
  }

  obj.constructor = typeToRestore.constructor;
  // tslint:disable-next-line:no-any
  obj.__proto__ = (typeToRestore as any).__proto__;

  // Add all values spread.
  typeToRestore = {
    ...typeToRestore,
    ...obj,
  };

  return typeToRestore;
}

// tslint:disable-next-line:no-any
export function mergeTypeWithObject<T>(typeToRestore: T, obj: any): T {
  return restoreTypeWithObject(typeToRestore, obj) || typeToRestore;
}

export function groupBy<T, K>(list: T[], getKey: (item: T) => K) {
  const map = new Map<K, T[]>();
  list.forEach((item) => {
    const key = getKey(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map);
}

export function getEnumNames<T>(obj: T): string[] {
  const results: string[] = [];
  for (const key of Object.keys(obj).filter((k: string) => {
    return !isNaN(+k);
  })) {
    results.push(obj[key]);
  }
  return results;
}

// tslint:disable-next-line: no-any
export function deepClone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

// tslint:disable-next-line: no-any
export function invert(obj: any) {
  const res = {};
  Object.keys(obj).forEach((key) => {
    res[obj[key]] = key;
  });

  return res;
}

type AnyObject = { [key: string]: unknown };

export function deepEqual(obj1: AnyObject, obj2: AnyObject, convertType: boolean = false): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (convertType) {
      // Handle type conversion for numbers and strings
      if (typeof obj1[key] === 'number' && typeof obj2[key] === 'string') {
        // @ts-ignore
        if (obj1[key].toString() !== obj2[key]) {
          return false;
        }
      } else if (typeof obj1[key] === 'string' && typeof obj2[key] === 'number') {
        // @ts-ignore
        if (obj1[key] !== obj2[key].toString()) {
          return false;
        }
        // @ts-ignore
      } else if (!deepEqual(obj1[key], obj2[key], convertType)) {
        return false;
      }
    } else {
      // @ts-ignore
      if (!deepEqual(obj1[key], obj2[key], convertType)) {
        return false;
      }
    }
  }

  return true;
}
