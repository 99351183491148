import { createStore } from 'satcheljs';
import { IPaymentsPageData } from './schema';

// !NOTE: This is to be added explicitly for the orchestrators to register themselves.
import { XPayJwtTokenRequest } from '../../../../../@data';
import '../orchestrators';

export const getPaymentsPageData = createStore<IPaymentsPageData>('PaymentsPageData', {
  estimatedEarnings: { yearly: { isDataLoaded: false }, monthly: { isDataLoaded: false } },
  balance: { isDataLoaded: false },
  xpayJwtTokenStatusIsLoading: false,
  xpayJwtTokenRequest: new XPayJwtTokenRequest(),
});
