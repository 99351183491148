import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { getAppStore, listEntity, onNotificationChanged, UserInvitation } from '../../../../../@data';
import { onFetchingUserInvitationsData, onFetchUserInvitationsLoading, onUserInvitationsChanged } from '../actions';
import { onUserInvitationsGridInitialized } from '../actions/onManagePageInitialized';
import { getStore } from '../store/store';

orchestrator(onUserInvitationsGridInitialized, async (msg) => {
  try {
    const { publisher, userContext } = getAppStore();
    const userInvitations = getStore().userInvitations;

    if (userInvitations === undefined || userInvitations.length === 0) {
      onFetchUserInvitationsLoading(true);
    }
    onFetchingUserInvitationsData(true);

    if (userContext && publisher) {
      const fetchedUserInvitations = await listEntity([publisher], userContext, UserInvitation);
      fetchedUserInvitations.forEach((userInvitation) => {
        if (!!userInvitation.expirationDate) {
          userInvitation.expirationDate = new Date(userInvitation.expirationDate).toISOString();
        }
      });
      onUserInvitationsChanged(fetchedUserInvitations);
    }
  } catch (error) {
    Log.error(error);
    onNotificationChanged({ messageBarType: MessageBarType.error, text: 'Failed to load user invitations' });
  } finally {
    onFetchUserInvitationsLoading(false);
    onFetchingUserInvitationsData(false);
  }
});
