import { DataGridSelectionMode } from '../../components/DataGrid';

export function getSelectionMode(selectionCount: Number): DataGridSelectionMode {
  switch (selectionCount) {
    case 0:
      return DataGridSelectionMode.None;
    case 1:
      return DataGridSelectionMode.Single;
    default:
      return DataGridSelectionMode.Multiple;
  }
}
