import { Icon, TooltipHost } from '@fluentui/react';
import React from 'react';

export interface IIconTooltipProps {
  tooltipMessage: string;
  iconName: string;
}

const tooltipHostStyles = {
  root: {
    display: 'flex',
  },
};

export const IconTooltip = ({ tooltipMessage: warningMessage, iconName }: IIconTooltipProps) => {
  return (
    <TooltipHost content={warningMessage} styles={tooltipHostStyles} aria-label={warningMessage}>
      <Icon iconName={iconName} />
    </TooltipHost>
  );
};
