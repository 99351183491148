import { defineMessages } from 'react-intl';

export default defineMessages({
  signUpDetailsTitle: {
    id: 'signup.signUpDetails.title',
    defaultMessage: 'Before you begin',
  },
  signUpDetailsContentYes: {
    id: 'signup.signUpDetails.contentYes',
    defaultMessage: 'Yes, send me customized help and performance suggestions',
  },
  signUpDetailsContentNo: {
    id: 'signup.signUpDetails.contentNo',
    defaultMessage: `No, I don't want customized help and performance suggestions`,
  },
  signUpDetailsCountryTerritory: {
    id: 'signup.signUpDetails.CountryTerritory',
    defaultMessage: 'Your payment country / territory',
  },
  signUpDetailsTermsAndConditions: {
    id: 'signup.signUpDetails.TermsAndConditions',
    defaultMessage: 'Our terms and conditions',
  },
  signUpDetailsCountry: {
    id: 'signup.signUpDetails.country',
    defaultMessage: 'Your payment country / territory',
  },
  agreementText: {
    id: 'signup.agreementText',
    defaultMessage: 'I have read and accepted the terms and conditions.  *',
  },
  defaultCountry: {
    id: 'signup.defaultCountry',
    defaultMessage: 'United States',
  },
  messageBarText: {
    id: 'signup.messageBar',
    defaultMessage:
      'Select the country/region here you currently live and where your bank account is associated. This will help you get paid.',
  },
  organizationName: {
    id: 'signup.organizationName',
    defaultMessage: 'Organization name',
  },
  orgNamePlaceholder: {
    id: 'signup.orgNamePlaceholder',
    defaultMessage: 'Do not exceed {limit} characters',
  },
  firstName: {
    id: 'signup.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'signup.lastName',
    defaultMessage: 'Last name',
  },
  signupdetailsBoldBanner: {
    id: 'signupdetails.boldBanner',
    defaultMessage: '{appName} is currently only accepting payment in the United States',
  },
  signupdetailsInfoBanner: {
    id: 'signupdetails.infoBanner',
    defaultMessage:
      'Note that your country and currency selections cannot be changed after submitting. Also, if you use Google Ad Manager, it is recommended that you use the same currency selection.',
  },
  signupdetailsWaitlist: {
    id: 'signupdetails.waitlist',
    defaultMessage: 'waitlist',
  },
  signupEmailOptIn: {
    id: 'signup.email.OptIn',
    defaultMessage: 'I would like information, tips, and offers about {appName}.',
  },
  signupEmailOptInPrivacyLink: {
    id: 'signup.email.OptIn.PrivacyLink',
    defaultMessage: 'Privacy Statement',
  },
  signUpDetailsPreferredLanguage: {
    id: 'signupdetails.preferred.language',
    defaultMessage: 'Preferred language',
  },
  signUpDetailsCurrency: {
    id: 'signupdetails.currency',
    defaultMessage: 'Currency',
  },
});
