import { DefaultButton, IconButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { IPaginationProps } from '../../Pagination';
import { getClassNames } from './DottedPagination.styles';
import { DOTS, useDottedPagination } from './useDottedPagination';

export interface IDottedPaginationProps extends IPaginationProps {}

export const DottedPagination = ({
  currentPageNumber,
  pageSize,
  className,
  onPageChange,
  siblingCount,
  totalItemCount,
  totalPageCount,
  styleProps,
}: IDottedPaginationProps) => {
  const paginationRange = useDottedPagination({
    currentPageNumber,
    totalItemCount,
    totalPageCount,
    siblingCount,
    pageSize,
  });
  const { containerStyle, itemStyle, dotsStyle, selectedItemStyle, disabledItemStyle } = mergeStyleSets(getClassNames(), styleProps);

  if (!paginationRange) {
    return null;
  }

  if (currentPageNumber === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPageChange!(currentPageNumber + 1);
  };

  const onPrevious = () => {
    onPageChange!(currentPageNumber - 1);
  };

  return (
    <div className={`${containerStyle} ${className || ''}`}>
      <IconButton
        id="previousPage"
        iconProps={{ iconName: 'ChevronLeftSmall' }}
        className={`${itemStyle} ${currentPageNumber === 1 ? disabledItemStyle : ''}`}
        onClick={onPrevious}
      />

      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <span className={dotsStyle}>&#8230;</span>;
        }

        return (
          <DefaultButton
            id={`customPage-${index}`}
            key={pageNumber}
            className={`${itemStyle} ${currentPageNumber === pageNumber ? selectedItemStyle : ''}`}
            onClick={() => onPageChange!(pageNumber as number)}
          >
            {pageNumber}
          </DefaultButton>
        );
      })}
      <IconButton
        id="nextPage"
        iconProps={{ iconName: 'ChevronRightSmall' }}
        className={`${itemStyle} ${currentPageNumber === lastPage ? disabledItemStyle : ''}`}
        onClick={onNext}
      />
    </div>
  );
};
