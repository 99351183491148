export enum TythonColumnsEnum {
  ImpressionRequests = 'ImpressionRequests',
  ImpressionsReturned = 'ImpressionsReturned',
  ImpressionsServed = 'ImpressionsServed',
  ImpressionsViewed = 'ImpressionsViewed',
  Viewability = 'Viewability',
  Revenue = 'Revenue',
  WinningBids = 'WinningBids',
  WinRate = 'WinRate',
  eCPM = 'eCPM',
  Clicks = 'Clicks',
  CTR = 'CTR',
  FillRate = 'FillRate',
  Impressions = 'Impressions',
  ViewableImpressions = 'ViewableImpressions',
  MeasurableImpressions = 'MeasurableImpressions',
  ImpressionsRate = 'ImpressionsRate',
  EligibleImpressions = 'EligibleImpressions',
  MeasurableImpressionsRate = 'MeasurableImpressionsRate',
  ResponsesServed = 'ResponsesServed',
  DateTime = 'DateTime',
  AdUnitName = 'AdUnitName',
  Country = 'Country',
  CountryCode = 'CountryCode',
  AdECPM = 'AdECPM',
  AdClicks = 'AdClicks',
  AdCTR = 'AdCTR',
  Date = 'Date',
  AdUnitId = 'AdUnitId',
  SiteName = 'SiteName',
  PropertyUrl = 'PropertyUrl',
  OperatingSystem = 'OperatingSystem',
}
