import { Callout, classNamesFunction, DirectionalHint, IconButton, Spinner } from '@fluentui/react';
import * as React from 'react';
import { IInfoButtonProps, IInfoButtonStyleProps, IInfoButtonStyles } from './InfoButton.types';

const getClassNames = classNamesFunction<IInfoButtonStyleProps, IInfoButtonStyles>();
interface IInfoButtonState {
  isCalloutVisible?: boolean;
}

export class InfoButtonBase extends React.Component<IInfoButtonProps, IInfoButtonState> {
  private _iconButtonElement = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);

    this.state = {
      isCalloutVisible: false,
    };
  }

  public render(): JSX.Element {
    const { styles, theme, iconName, iconTitle, calloutContent, calloutProps, ariaLabel, ariaDescription } = this.props;
    const classNames = getClassNames(styles, { theme: theme! });

    return (
      <div className={classNames.root}>
        <div className={classNames.icon} ref={this._iconButtonElement}>
          <IconButton
            styles={{
              rootFocused: {
                '@media screen and (-ms-high-contrast: active), screen and (forced-colors: active)  .ms-Fabric--isFocusVisible .root-241:focus::after': {
                  inset: '2px !important',
                },
              },
            }}
            iconProps={{ iconName: iconName ? iconName : 'info' }}
            title={iconTitle}
            ariaLabel={ariaLabel}
            ariaDescription={ariaDescription}
            onClick={this._onInfoButtonClick}
          />
        </div>
        <Callout
          className={classNames.callout}
          {...calloutProps}
          target={this._iconButtonElement.current}
          onDismiss={this._onCalloutDismiss}
          hidden={!this.state.isCalloutVisible}
          directionalHint={DirectionalHint.rightCenter}
          gapSpace={15}
          ariaLabel={ariaLabel + ' Callout'}
          setInitialFocus={true}
          aria-live="assertive"
        >
          {calloutContent ? <div dangerouslySetInnerHTML={{ __html: calloutContent }} /> : <Spinner />}
        </Callout>
      </div>
    );
  }

  private _onInfoButtonClick = (ev: React.MouseEvent<HTMLDivElement>): void => {
    ev.stopPropagation(); // Prevents the event from bubbling up to the parent element
    this.setState({ isCalloutVisible: !this.state.isCalloutVisible });
    if (this.props.iconButtonOnClick) {
      this.props.iconButtonOnClick();
    }
  };

  private _onCalloutDismiss = (): void => {
    this.setState({ isCalloutVisible: false });
    if (this.props.calloutOnDismiss) {
      this.props.calloutOnDismiss();
    }
  };
}
