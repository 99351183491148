import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  getAppStore,
  onAuthLogout,
  onNotificationChanged,
  onSavingChanged,
  patchEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
  User,
  UserRole,
  UserRoleEntityType,
  UserRoleUpdate,
} from '../../../../../../@data';
import messages from '../../../UserManagement.messages';
import { onUserRoleChanged } from '../actions/usersTabActions';
import { updateUserRole } from '../mutatorActions';
import { getUserManagementStore } from '../store';

orchestrator(onUserRoleChanged, async (msg) => {
  const { publisher, account, userContext } = getAppStore();
  const user = getUserManagementStore().selectedItem;

  if (user && publisher && userContext) {
    try {
      onSavingChanged(true);

      const entity = new UserRoleUpdate();

      entity.eTag = user.userRoleDetailsETag;
      entity.removedRoles = user.userRoleDetails || [];
      entity.addedRoles = [
        {
          entityId: publisher.id,
          userRole: msg.role,
          entityType: UserRoleEntityType.Customer,
        },
      ];

      if ([UserRole.AccountManager, UserRole.ReportUser].includes(msg.role) && account) {
        entity.addedRoles.push({
          entityId: account.id,
          userRole: msg.role,
          entityType: UserRoleEntityType.Account,
        });
      }

      user.className = new User().className;
      const response = await patchEntity([publisher, user], UserRoleUpdate, entity, userContext);

      updateUserRole(user, response);

      if (user.id === userContext.id) {
        // User role has changed, so logout the user to provide different UX
        onAuthLogout();
      }

      showSuccessToastNotification({
        textMessageDescriptor: messages.editedUserRoleSuccessfully,
        intlMessageValues: { username: user.username },
      });
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.userRoleChangeFailed });
    } finally {
      onSavingChanged(false);
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.userManagementInvalidState,
    });
  }
});
