import {
  ActionButton,
  classNamesFunction,
  getId,
  getTheme,
  IColumn,
  Icon,
  Link,
  nullRender,
  SelectionMode,
  ShimmeredDetailsList,
} from '@fluentui/react';
import * as React from 'react';
import { CrossEntityResourceType, ResourceType } from '../../../@data/store/schema/enums/ResourceType';
import AppMessages from '../../../App.messages';
import { RoutePrefix } from '../../../Routes';
import { injectIntlWithObserver } from '../../../utils';
import { getScreenReaderTextStyles } from '../../../utils/GlobalStyleUtils';
import { onSearchTriggered } from '../@data/actions/onSearchTriggered';
import { SearchResult } from '../@data/store/schema/models/SearchResult';
import { getStore } from '../@data/store/store';
import GlobalSearchResultListMessages from './GlobalSearchResultList.messages';
import { getStyles } from './GlobalSearchResultList.styles';
import {
  IGlobalSearchResultListProps,
  IGlobalSearchResultListStyleProps,
  IGlobalSearchResultListStyles,
} from './GlobalSearchResultList.types';

const getClassNames = classNamesFunction<IGlobalSearchResultListStyleProps, IGlobalSearchResultListStyles>();

export const GlobalSearchResultList = injectIntlWithObserver(
  class extends React.Component<IGlobalSearchResultListProps> {
    private _columns: Map<string, string> = new Map([
      ['id', this.props.intl.formatMessage(GlobalSearchResultListMessages.id)],
      ['name', this.props.intl.formatMessage(GlobalSearchResultListMessages.name)],
      ['status', this.props.intl.formatMessage(AppMessages.status)],
      ['createdDate', this.props.intl.formatMessage(GlobalSearchResultListMessages.createdDate)],
      ['numberOfExcludedURLs', this.props.intl.formatMessage(GlobalSearchResultListMessages.numberOfExcludedURLs)],
      ['numberOfExcludedKeywords', this.props.intl.formatMessage(GlobalSearchResultListMessages.numberOfExcludedKeywords)],
      ['numberOfChannels', this.props.intl.formatMessage(GlobalSearchResultListMessages.numberOfChannels)],
      ['numberOfAdunits', this.props.intl.formatMessage(GlobalSearchResultListMessages.numberOfAdunits)],
      ['numberOfAdFilters', this.props.intl.formatMessage(GlobalSearchResultListMessages.numberOfAdFilters)],
      ['lastModifiedDateTime', this.props.intl.formatMessage(GlobalSearchResultListMessages.lastModifiedDateTime)],
      ['fullName', this.props.intl.formatMessage(GlobalSearchResultListMessages.fullName)],
      ['publisherName', this.props.intl.formatMessage(GlobalSearchResultListMessages.publisherName)],
      ['websiteURL', this.props.intl.formatMessage(GlobalSearchResultListMessages.websiteUrl)],
      ['notes', this.props.intl.formatMessage(GlobalSearchResultListMessages.notes)],
    ]);

    private _columnNames: Map<string, string[]> = new Map([
      [ResourceType[ResourceType.Account], ['name', 'id', 'createdDate', 'status']],
      [ResourceType[ResourceType.AdUnit], ['name', 'id', 'numberOfChannels', 'status']],
      [ResourceType[ResourceType.Filter], ['name', 'id', 'numberOfExcludedURLs', 'numberOfExcludedKeywords']],
      [ResourceType[ResourceType.Channel], ['name', 'id', 'numberOfAdunits', 'numberOfAdFilters']],
      [ResourceType[ResourceType.Publisher], ['name', 'id', 'createdDate', 'lastModifiedDateTime', 'notes']],
      [ResourceType[ResourceType.User], ['name', 'id', 'fullName', 'publisherName']],
      [ResourceType[ResourceType.WebProperty], ['name', 'id', 'publisherName', 'websiteURL']],
    ]);

    public render(): JSX.Element | null {
      const classNames = getClassNames(getStyles, { theme: getTheme() });
      const store = getStore();
      const searchResultInfo = store.results.get(this.props.resourceType);
      const { formatMessage } = this.props.intl;

      if (searchResultInfo === undefined) {
        return nullRender();
      }

      const isLoading = store.isShowMoreLoading.get(this.props.resourceType);
      const totalCount = store.resourceTypes.get(this.props.resourceType) || 0;
      /* const crossEntityResourceTypes = crossEntityResourceTypeMap.get(this.props.resourceType);

      if (crossEntityResourceTypes) {
        crossEntityResourceTypes.forEach((resourceType: string) => {
          totalCount += store.resourceTypes.get(resourceType) || 0;
        });
      }*/

      const header = this.props.headerText + ' (' + totalCount + ')';

      return (
        <div className={classNames.root}>
          <h2 className={classNames.header}>{header}</h2>
          <ShimmeredDetailsList
            items={searchResultInfo.resultData}
            onRenderItemColumn={this._renderItemColumn}
            columns={this._buildSearchResultListColumns(this.props.resourceType)}
            selectionMode={SelectionMode.none}
          />
          {isLoading && (
            <ShimmeredDetailsList
              items={[]}
              columns={this._buildSearchResultListColumns(this.props.resourceType)}
              selectionMode={SelectionMode.none}
              enableShimmer={true}
              isHeaderVisible={false}
              shimmerLines={5}
            />
          )}
          <ActionButton
            disabled={searchResultInfo.pageToken === ''}
            className={classNames.button}
            onClick={() => onSearchTriggered(this.props.resourceType)}
            ariaDescription={formatMessage(GlobalSearchResultListMessages.showMoreDescription, { entity: this.props.headerText })}
          >
            {formatMessage(AppMessages.showMore)}
            <Icon className={classNames.buttonIcon} iconName="ChevronDown" />
          </ActionButton>
        </div>
      );
    }

    private _buildSearchResultListColumns(resourceType: string): IColumn[] {
      const columns = this._columnNames.get(resourceType);
      const classNames = getClassNames(getStyles, { theme: getTheme() });
      const newColumns: IColumn[] = [];

      const iconColumn: IColumn = {
        key: 'iconColumn',
        name: 'File Type',
        className: classNames.iconCell,
        iconClassName: classNames.iconHeader,
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'iconName',
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: SearchResult) => {
          return <Icon className={classNames.iconImg} iconName={item.iconName} />;
        },
      };
      newColumns.push(iconColumn);
      if (columns) {
        // eslint-disable-next-line
        columns.map((column: string) => {
          const newColumn: IColumn = {
            key: column,
            name: this._columns.get(column) || '',
            fieldName: column,
            isCollapsable: false,
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
            isPadded: true,
          };
          newColumns.push(newColumn);
        });
      }

      return newColumns;
    }

    private _onClick = (item: SearchResult): void => {
      const publisherId = item.getPublisherId();
      const accountId = item.getAccountId();
      const resourceType = item.getResourceType();
      const entityId = item.id;
      let route = '';

      switch (resourceType) {
        case ResourceType[ResourceType.AdUnit]: {
          if (publisherId !== '' && accountId !== '') {
            route = `/${RoutePrefix.adManagement}/${publisherId}-${accountId}/adunit/edit/${entityId}`;
          }
          break;
        }
        case ResourceType[ResourceType.Channel]: {
          if (publisherId !== '' && accountId !== '') {
            route = `/${RoutePrefix.adManagement}/${publisherId}-${accountId}/channel/edit/${entityId}`;
          }
          break;
        }
        case ResourceType[ResourceType.WebProperty]: {
          if (publisherId !== '') {
            route = `/${RoutePrefix.adManagement}/${publisherId}/property/edit/${entityId}`;
          }
          break;
        }
        case ResourceType[ResourceType.Filter]: {
          if (publisherId !== '' && accountId !== '') {
            route = `/${RoutePrefix.adManagement}/${publisherId}-${accountId}/adfilter/edit/${entityId}`;
          }
          break;
        }
        case ResourceType[ResourceType.Account]: {
          if (publisherId !== '') {
            route = `/${RoutePrefix.partnerManagement}/${publisherId}/account/edit/${entityId}`;
          }
          break;
        }
        case ResourceType[ResourceType.Publisher]:
        case CrossEntityResourceType[CrossEntityResourceType.PublisherByAccount]:
        case CrossEntityResourceType[CrossEntityResourceType.PublisherByAdunit]:
        case CrossEntityResourceType[CrossEntityResourceType.PublisherByChannel]:
        case CrossEntityResourceType[CrossEntityResourceType.PublisherByFilter]:
        case CrossEntityResourceType[CrossEntityResourceType.PublisherByUser]:
        case CrossEntityResourceType[CrossEntityResourceType.PublisherByWebProperty]: {
          if (entityId !== '') {
            route = `/${RoutePrefix.partnerManagement}/${entityId}/publisher/edit/${entityId}`;
          }
          break;
        }
        case ResourceType[ResourceType.User]: {
          if (publisherId !== '') {
            route = `/${RoutePrefix.partnerManagement}/${publisherId}/user/edit/${entityId}`;
          }
          break;
        }
      }

      if (route !== '') {
        this.props.history.push(route);
      }
    };

    private _renderItemColumn = (item: SearchResult, index: number, column: IColumn): JSX.Element => {
      const cellAriaDescId = getId('clickableCellAriaDesc-');

      if (column.key === 'name') {
        return (
          <>
            <Link onClick={() => this._onClick(item)} aria-describedby={cellAriaDescId}>
              {item.name}
            </Link>
            <span id={cellAriaDescId} style={getScreenReaderTextStyles()}>
              {this.props.intl.formatMessage(GlobalSearchResultListMessages.clickableCellAriaDesc)}
            </span>
          </>
        );
      }
      return <span>{item[column.fieldName || '']}</span>;
    };
  }
);
