import { SideNavigation } from '@bingads-webui-fluent-component/navigation';
import { css } from '@fluentui/react';
import React from 'react';
import {
  AccountNavIcon,
  AccountNavIconActive,
  AdBlockNavIcon,
  AdBlockNavIconActive,
  AdUnitNavIcon,
  AdUnitNavIconActive,
  AdsIcon,
  AdsIconActive,
  HomeNavIcon,
  HomeNavIconActive,
  InventoryIcon,
  InventoryIconActive,
  ReportNavIcon,
  ReportNavIconActive,
  SiteNavIcon,
  SiteNavIconActive,
} from './resources';
import { GenerateNavIcons, TythonNavComponent } from './types';
import useTythonNav from './useTythonNav';

const generateNavIcons: GenerateNavIcons = (iconName, className) => {
  let iconSrc;
  let iconSrcActive;
  switch (iconName) {
    case 'Home':
      iconSrc = HomeNavIcon;
      iconSrcActive = HomeNavIconActive;
      break;
    case 'AdUnit':
      iconSrc = AdUnitNavIcon;
      iconSrcActive = AdUnitNavIconActive;
      break;
    case 'Favicon':
      iconSrc = SiteNavIcon;
      iconSrcActive = SiteNavIconActive;
      break;
    case 'AdUnitBlock':
      iconSrc = AdBlockNavIcon;
      iconSrcActive = AdBlockNavIconActive;
      break;
    case 'CRMReport':
      iconSrc = ReportNavIcon;
      iconSrcActive = ReportNavIconActive;
      break;
    case 'AccountManagement':
      iconSrc = AccountNavIcon;
      iconSrcActive = AccountNavIconActive;
      break;
    case 'Inventory':
      iconSrc = InventoryIcon;
      iconSrcActive = InventoryIconActive;
      break;
    case 'Ads':
      iconSrc = AdsIcon;
      iconSrcActive = AdsIconActive;
      break;
    default:
      iconSrc = undefined;
      iconSrcActive = undefined;
  }
  return () => (
    <>
      <img src={iconSrc} alt={iconName} className={css(className, 'navIcon')} />
      <img src={iconSrcActive} alt={iconName} className={css(className, 'navIconActive')} />
    </>
  );
};

const TythonNav: TythonNavComponent = (props) => {
  const { accessibleRoutes, history, intl } = props;
  const { navItems, classNames } = useTythonNav({ accessibleRoutes, history, generateNavIcons, intl });

  return (
    <div className={classNames.container}>
      <SideNavigation items={navItems} />
    </div>
  );
};

export default TythonNav;
