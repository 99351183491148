import { IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing } from '../../../theme';

export interface ILoginPageStyles {
  titleStyle: string;
  descStyle: string;
  buttonBackground: string;
  signInButtonStyle: string;
  imageStyle: string;
}

const { ResponsiveNonSmall, ResponsiveSmall, ResponsiveExtraSmall } = BreakPoints;

export const getClassNames = (): ILoginPageStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const fonts = theme.fonts as IPubCenterFonts;
  const { size32, size16 } = fonts;

  return mergeStyleSets({
    titleStyle: {
      ...size32,
      color: theme.palette.white,
      margin: `0 0  ${spacing.size24} 0`,
      textAlign: 'center',
    },
    descStyle: {
      color: theme.palette.white,
      ...size16,
      textAlign: 'center',
      [ResponsiveNonSmall]: {
        width: '382px',
      },
      [ResponsiveSmall]: {
        maxWidth: '320px',
        minWidth: '224px',
      },
    },
    buttonBackground: {
      borderRadius: '10px',
      background: '#0a152ecc',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: `${spacing.size80} ${spacing.size32}`,
      [ResponsiveNonSmall]: {
        width: `446px`,
      },
      [ResponsiveSmall]: {
        maxWidth: `calc(100vw - ${spacing.size96})`,
        width: 'max-content',
      },
      [ResponsiveExtraSmall]: {
        maxWidth: `calc(100vw - ${spacing.size96})`,
      },
    },
    signInButtonStyle: {
      height: spacing.size48,
      background: 'transparent',
      color: theme.palette.white,
      border: `${spacing.size2} solid ${theme.palette.white}`,
      marginTop: spacing.size16,
      marginLeft: spacing.size24,
      marginRight: spacing.size24,
      selectors: {
        '&:focus': {
          outline: `1px solid ${theme.palette.white}`,
        },
      },
      [ResponsiveNonSmall]: {
        width: spacing.size320,
      },
      [ResponsiveSmall]: {
        minWidth: '224px',
        maxWidth: spacing.size320,
        width: '-webkit-fill-available',
      },
    },
    imageStyle: {
      width: '100vw',
      height: '100vh',
    },
  });
};

export const getTextFieldStyles = (props: ITextFieldStyleProps): IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const { size16 } = theme.fonts as IPubCenterFonts;
  const palette = theme.palette as IPubcenterPalette;
  const { hasErrorMessage } = props;

  return {
    root: {
      marginTop: spacing.size24,
      marginLeft: spacing.size24,
      marginRight: spacing.size24,
      [ResponsiveNonSmall]: {
        width: spacing.size320,
      },
      [ResponsiveSmall]: {
        minWidth: '224px',
        maxWidth: spacing.size320,
        width: '-webkit-fill-available',
      },
    },
    fieldGroup: [
      {
        height: spacing.size48,
      },
      hasErrorMessage && {
        background: palette.messageBarError,
        border: 'none',
      },
    ],
    field: [
      hasErrorMessage && {
        color: palette.redDark,
        border: `2px solid ${theme.palette.white}`,
      },
    ],
    errorMessage: {
      color: theme.palette.white,
      ...size16,
      width: spacing.size320,
    },
  };
};
