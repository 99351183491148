import { DefaultButton, Image, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { IZeroResultsStyles, getClassNames } from './ZeroResults.styles';
import defaultImage from './resources/default.svg';

export interface IZeroResultsProps {
  showDefaultImage?: boolean;
  imageSrc?: string;
  title?: string;
  description?: string;
  buttonText?: string;
  href?: string;
  onButtonClick?: () => void;
  styleProps?: Partial<IZeroResultsStyles>;
  children?: React.ReactNode;
}

export function ZeroResults({
  showDefaultImage,
  imageSrc,
  title,
  description,
  buttonText,
  onButtonClick,
  href,
  styleProps,
  children,
}: IZeroResultsProps) {
  const { rootStyle, imageContainerStyle, descriptionStyle, titleStyle, imageStyle } = mergeStyleSets(getClassNames(), styleProps);

  return (
    <div className={rootStyle}>
      {(imageSrc || showDefaultImage) && (
        <div className={imageContainerStyle}>
          <Image src={imageSrc ?? defaultImage} shouldFadeIn styles={{ image: imageStyle }} aria-label={title ?? description} />
        </div>
      )}
      {title && <strong className={titleStyle}>{title}</strong>}
      {description && <p className={descriptionStyle}>{description}</p>}
      {buttonText && onButtonClick && <DefaultButton onClick={onButtonClick}>{buttonText}</DefaultButton>}
      {buttonText && href && (
        <Link to={href} tabIndex={-1}>
          <DefaultButton text={buttonText} />
        </Link>
      )}
      {children}
    </div>
  );
}
