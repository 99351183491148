/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  Announced,
  Callout,
  classNamesFunction,
  DirectionalHint,
  FocusZone,
  FocusZoneDirection,
  getTheme,
  IProcessedStyleSet,
  Label,
  SearchBox,
} from '@fluentui/react';
import React from 'react';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import messages from './PropertySitePicker.messages';
import { getStyles } from './PropertySitePicker.styles';
import {
  IPropertySitePickerItem,
  IPropertySitePickerProps,
  IPropertySitePickerStyleProps,
  IPropertySitePickerStyles,
} from './PropertySitePicker.types';
interface IPropertySitePickerState {
  searchText: string;
  filteredItems: IPropertySitePickerItem[];
  isCalloutVisible: boolean;
  announceItemSelected: boolean;
}

const getClassNames = classNamesFunction<IPropertySitePickerStyleProps, IPropertySitePickerStyles>();

export class PropertySitePicker extends React.Component<IPropertySitePickerProps, IPropertySitePickerState> {
  private _classNames: IProcessedStyleSet<IPropertySitePickerStyles>;

  constructor(props: IPropertySitePickerProps) {
    super(props);

    this.state = {
      searchText: '',
      filteredItems: props.items,
      isCalloutVisible: false,
      announceItemSelected: false,
    };
  }

  public componentDidUpdate(prevProps: IPropertySitePickerProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        filteredItems: this.props.items,
      });
    }
  }

  public render(): JSX.Element {
    const theme = getTheme();
    this._classNames = getClassNames(getStyles, { theme });
    const {
      title,
      searchBoxPlaceholder,
      onListItemClicked,
      propertyOptionsAriaLabel,
      searchBoxAriaDescription,
      itemSelectedAnnounceMessage,
      isLoading,
      isDisabled,
      clearSearchOnSelect,
      formatMessage,
    } = this.props;
    const { searchText, filteredItems, isCalloutVisible, announceItemSelected } = this.state;

    const listItems: JSX.Element[] = [];

    listItems.push(
      <div
        className={this._classNames.listItem}
        key={0}
        onClick={() => {
          onListItemClicked(undefined);
          this.setState({ isCalloutVisible: false });
        }}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' || ev.key === ' ') {
            onListItemClicked(undefined);
            this.setState({ isCalloutVisible: false });
          }
        }}
        role="option"
        aria-selected={false}
        data-is-focusable={true}
      >
        {this.props.emptyOptionLabel ? this.props.emptyOptionLabel : ''}
      </div>
    );

    filteredItems.forEach((item: IPropertySitePickerItem, index: number) => {
      listItems.push(
        <div
          className={this._classNames.listItem}
          key={item.id}
          onClick={() => {
            onListItemClicked(item);
            this.setState({
              isCalloutVisible: false,
              announceItemSelected: true,
              searchText: clearSearchOnSelect ? '' : searchText,
            });
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' || ev.key === ' ') {
              onListItemClicked(item);
              this.setState({
                isCalloutVisible: false,
                announceItemSelected: true,
                searchText: clearSearchOnSelect ? '' : searchText,
              });
            }
          }}
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="option"
          // TODO: add i18n
          aria-label={`${item.id + ' - (' + item.url + ')'} ${searchBoxPlaceholder?.includes(`${item.id}`) ? 'selected' : 'not selected'}`}
          data-is-focusable={true}
          data-item-index={index}
        >
          <span className={this._classNames.listItemLabel}>{item.id + ' - (' + item.url + ')'}</span>
        </div>
      );
    });

    return (
      <>
        {announceItemSelected && <Announced message={itemSelectedAnnounceMessage} aria-live="assertive" />}

        <div id={'searchBox'} className={this._classNames.searchBox}>
          <Label required={true}>{title}</Label>
          <SearchBox
            styles={{
              iconContainer: {
                display: 'none',
              },
              field: {
                padding: '0px 4px',
              },
            }}
            value={searchText}
            placeholder={isLoading ? formatMessage(messages.adUnitSiteLoading) : searchBoxPlaceholder}
            required={true}
            underlined={false}
            onChange={(_, filter: string) => {
              this.setState({ searchText: filter });
              this._onCallSuggestionsChanged(filter);
            }}
            onSearch={() => this.setState({ isCalloutVisible: true })}
            aria-describedby={'searchBoxAriaDesc'}
            onClick={() => {
              this.setState({ isCalloutVisible: true });
            }}
            disabled={isLoading || isDisabled}
            ariaLabel={title}
          />
        </div>
        {isCalloutVisible ? (
          <Callout
            target={'#searchBox'}
            directionalHint={DirectionalHint.bottomLeftEdge}
            gapSpace={0}
            isBeakVisible={false}
            calloutWidth={400}
            preventDismissOnScroll={true}
            setInitialFocus={true}
            styles={{ root: { marginTop: '32px' } }}
            onDismiss={() => this.setState({ isCalloutVisible: false })}
            onKeyDown={(ev: React.KeyboardEvent<HTMLDivElement>) => {
              if (ev.key === 'Tab' || (ev.shiftKey && ev.key === 'Tab')) {
                this.setState({ isCalloutVisible: false });
              }
            }}
          >
            <FocusZone
              direction={FocusZoneDirection.vertical}
              isCircularNavigation={true}
              role="listbox"
              aria-labelledby={'propertyOptionsAriaLabel'}
            >
              {listItems}
            </FocusZone>
          </Callout>
        ) : null}
        <span id="propertyOptionsAriaLabel" style={getScreenReaderTextStyles()}>
          {propertyOptionsAriaLabel}
        </span>
        <span id="searchBoxAriaDesc" style={getScreenReaderTextStyles()}>
          {searchBoxAriaDescription}
        </span>
      </>
    );
  }

  private _onCallSuggestionsChanged = (filter: string) => {
    const filteredItems = this.props.items.filter((p) => {
      const idUrlString = p.id.toString() + p.url.toLocaleLowerCase();
      return idUrlString.indexOf(filter.toLocaleLowerCase()) !== -1;
    });

    this.setState({ filteredItems: filteredItems });
  };
}
