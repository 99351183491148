import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getAppStore } from '../../../../@data';
import EntityTypeSwitch from '../../../../components/EntityTypeSwitch';
import { InventoryPageTabs, useEntityTypeManage } from '../../../../hooks/useEntityTypeManage';
import { getStore as getWindowsAppFeatureFlagStore } from '../../../../hooks/useWindowsAppFeatureFlag/store/store';
import { GridPageLayout } from '../../../../layout/GridPageLayout/GridPageLayout';
import { fetchPublisherEvaluationStatusAction } from '../../@data/actions';
import { ManagePage } from '../../Property/Manage';
import { onManagePageInitialized } from '../../Property/Manage/__data__/orchestrators';
import { getGridPropertyStore } from '../../Property/Manage/__data__/store/store';
import AppInventoryManagePage from './AppInventoryManagePage';

export const TythonInventoryManagePage = withRouter(
  observer(({ history, match, location }) => {
    const { isFeatureEnabled } = getWindowsAppFeatureFlagStore();
    const { activeSubTab, setActiveSubTab } = useEntityTypeManage({ isFeatureEnabled });
    const SiteManageView = <ManagePage withParentLayout history={history} match={match} location={location} abortDataFetch />;
    const AppInventoryManageView = <AppInventoryManagePage />;
    const store = getGridPropertyStore();

    const { publisher } = getAppStore();

    useEffect(() => {
      if (publisher !== undefined && store?.data && store?.data[0]?.publisherId === publisher?.id) {
        return;
      }
      onManagePageInitialized();
      fetchPublisherEvaluationStatusAction({ cache: false });
      // eslint-disable-next-line
    }, [publisher]);

    return (
      <GridPageLayout withSubHeader>
        <EntityTypeSwitch selectedValue={activeSubTab} onTabSelect={setActiveSubTab} isFeatureEnabled={isFeatureEnabled} />
        {activeSubTab === InventoryPageTabs.Sites ? SiteManageView : <></>}
        {activeSubTab === InventoryPageTabs.Apps ? AppInventoryManageView : <></>}
      </GridPageLayout>
    );
  })
);
