import { object, string } from 'yup';
import { getFeedbackPanelStore } from '../store';
import { IFeedbackPanelViewModel } from './IFeedbackPanelViewModel';

export function feedbackPanelErrorModel() {
  return object<IFeedbackPanelViewModel>().shape({
    title: string().trim().required(),
    description: string().trim().required(),
    email: getFeedbackPanelStore().isContactMeChecked ? string().trim().email().required() : string().trim().email(),
  });
}
