import { MessageBarType } from '@fluentui/react';
import {
  handleApiErrors,
  onAccessiblePublisherListChanged,
  onDefaultPublisherSet,
  onNotificationChanged,
  onUserContextChanged,
  UserType,
} from '..';
import { Log } from '../../../logging/src';
import messages from '../../App.messages';
import { uiConfig } from '../../config/ui.config';
import { DEFAULT_PUBLISHER_CACHE_KEY } from '../../constants/AppConstants';
import { isLocationPubCenter } from '../../utils';
import { onLoadingChanged, onLoadingSetting, onLoadingWaiting } from '../actions';
import { UserContext } from '../store/schema';
import { IUserRoleScope } from '../store/schema/interfaces/IUserRoleData';
import { Account } from '../store/schema/userrolemodels/Account';
import { AdBlock } from '../store/schema/userrolemodels/AdBlock';
import { AdUnit } from '../store/schema/userrolemodels/AdUnit';
import { Channel } from '../store/schema/userrolemodels/Channel';
import { DropdownMenu } from '../store/schema/userrolemodels/DropdownMenu';
import { Filter } from '../store/schema/userrolemodels/Filter';
import { Model } from '../store/schema/userrolemodels/Model';
import { NavigationMenu } from '../store/schema/userrolemodels/NavigationMenu';
import { Property } from '../store/schema/userrolemodels/Property';
import { Publisher } from '../store/schema/userrolemodels/Publisher';
import { PublisherProfileTabs } from '../store/schema/userrolemodels/PublisherProfileTabs';
import { PublisherSettings } from '../store/schema/userrolemodels/PublisherSettings';
import { User } from '../store/schema/userrolemodels/User';
import { UserRoleResponse } from '../store/schema/userrolemodels/UserRoleResponse';
import { UserSettings } from '../store/schema/userrolemodels/UserSettings';
import { isTythonSelfServeUser } from '../utils/checkUserType';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';
export const userRoleKey = '_userRole';
export const completeAccess = 0b1111;

export enum UserRoleEntity {
  AdUnit = 'AdUnit',
  Channel = 'Channel',
  Filter = 'Filter',
  Property = 'Property',
  Publisher = 'Publisher',
  Account = 'Account',
  User = 'User',
  DropdownMenu = 'DropdownMenu',
  NavigationMenu = 'NavigationMenu',
  PublisherProfileTabs = 'PublisherProfileTabs',
  PublisherSettings = 'PublisherSettings',
  UserSettings = 'UserSettings',
  AdBlock = 'AdBlock',
}

/* The rolePermissions map has the details of CRUD permissions of entities(i.e. Adunit, Channel etc.)
   for different user roles. Key of this map is user role id and value of the map is list of entities where
   each entity has accessPermission number field whose 4 LSBs represents CRUD permissions :-
   1st bit - Permission to delete the entity.
   2nd bit - Permission to update the entity.
   3rd bit - Permission to read the entity.
   4th bit - Permission to create the entity.

   Similarly, each entity has its own fields which has their own CRUD permission details.
*/
const rolePermissions = new Map<number, Model[]>([
  [
    // PC Inventory Management
    169,
    [
      new DropdownMenu({
        adManagement: 0b1111,
        partnerManagment: 0b1111,
        reporting: 0b1111,
      } as DropdownMenu),
      new Publisher({
        id: 0b1111,
        accessPermission: 0b1111,
        partner: 0b1111,
        name: 0b1111,
        networkAffiliation: 0b1111,
        address: 0b1111,
        publisherTier: 0b1111,
        overrideFeatureTier: 0b1111,
        entityLimitTier: 0b1111,
        serviceLevel: 0b1111,
        allowDistributionReporting: 0b1111,
        allowDistributionV2Reporting: 0b1111,
        allowStartDistributionReporting: 0b1111,
        allowStartDistributionContentReporting: 0b1111,
        allowSelectTierReporting: 0b1111,
        allowMediationReporting: 0b1111,
        allowMobileSearchAdUnit: 0b1111,
        allowNativeReporting: 0b1111,
        allowSyndicationReporting: 0b1111,
        allowWebAdUnit: 0b1111,
        allowPaidSearchReportingApi: 0b1111,
        allowPaidSearchReportingDashboard: 0b1111,
        allowDynamicPropertyRegistration: 0b0010,
        enableDefaultTythonV2Reporting: 0b1111,
        publisherAllowedFeaturesPropertyAutoApproved: 0b1111,
        ipRanges: 0b1111,
        newAccount: 0b1111,
      } as Publisher),
      new Account({
        accessPermission: 0b1111,
        publisherName: 0b1111,
        accountName: 0b1111,
        primaryContact: 0b1111,
        lanuage: 0b1111,
        countryOrRegion: 0b1111,
        currency: 0b1111,
        timeZone: 0b1111,
        paymentOption: 0b1111,
        financialStatus: 0b1111,
        paymentLifecycle: 0b1111,
        operationCostRation: 0b1111,
        accountLevelAttribute: 0b1111,
        inHousePromotion: 0b1111,
        partnerCodesforDistributionReport: 0b1111,
        formCodesforDistributionReport: 0b1111,
        startDistributionReporting: 0b1111,
        SKUs: 0b1111,
      } as Account),
      new User({
        accessPermission: 0b1111,
        userFirstName: 0b1111,
        userLastName: 0b1111,
        userEmail: 0b1111,
        userAccountRole: 0b1111,
        userLanguage: 0b1111,
        userInviteFirstName: 0b1111,
        userInviteLastName: 0b1111,
        userInviteEmail: 0b1111,
        userInviteLanguage: 0b1111,
        userInviteAccountRole: 0b1111,
      } as User),
      new AdUnit({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        markeplaceClassification: 0b1111,
        businessModel: 0b1111,
        channelList: 0b1111,
        servingTier: 0b1111,
        publisherGroupConfig: 0b1111,
        inhousePromotions: 0b1111,
      } as AdUnit),
      new Channel({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        adFilters: 0b1111,
        adUnitList: 0b1111,
      } as Channel),
      new Property({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        websiteURL: 0b1101,
        approvalStatus: 0b1111,
        notes: 0b1111,
        attachedAdUnits: 0b1111,
        clarity: 0b1111,
      } as Property),
      new Filter({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        filterType: 0b1101,
        channelList: 0b1111,
        excludedAdKeywords: 0b1111,
        excludedQueryKeywords: 0b1111,
        excludedURLs: 0b1111,
      } as Filter),
    ],
  ],
  [
    // PC Support
    170,
    [
      new Publisher({
        id: 0b1111,
        accessPermission: 0b1111,
        partner: 0b1111,
        name: 0b1111,
        networkAffiliation: 0b1111,
        address: 0b1111,
        publisherTier: 0b1111,
        overrideFeatureTier: 0b1111,
        entityLimitTier: 0b1111,
        serviceLevel: 0b1111,
        allowDistributionReporting: 0b1111,
        allowDistributionV2Reporting: 0b1111,
        allowStartDistributionReporting: 0b1111,
        allowStartDistributionContentReporting: 0b1111,
        allowSelectTierReporting: 0b1111,
        allowMediationReporting: 0b1111,
        allowMobileSearchAdUnit: 0b1111,
        allowNativeReporting: 0b1111,
        allowSyndicationReporting: 0b1111,
        allowWebAdUnit: 0b0100,
        allowPaidSearchReportingApi: 0b1111,
        allowPaidSearchReportingDashboard: 0b1111,
        allowDynamicPropertyRegistration: 0b0100,
        enableDefaultTythonV2Reporting: 0b1111,
        publisherAllowedFeaturesPropertyAutoApproved: 0b1111,
        ipRanges: 0b1111,
        newAccount: 0b1111,
      } as Publisher),
      new Account({
        accessPermission: 0b1111,
        publisherName: 0b1111,
        accountName: 0b1111,
        primaryContact: 0b1111,
        lanuage: 0b1111,
        countryOrRegion: 0b1111,
        currency: 0b1111,
        timeZone: 0b1111,
        paymentOption: 0b1111,
        financialStatus: 0b1111,
        paymentLifecycle: 0b1111,
        operationCostRation: 0b1111,
        accountLevelAttribute: 0b1111,
        inHousePromotion: 0b1111,
        partnerCodesforDistributionReport: 0b1111,
        formCodesforDistributionReport: 0b1111,
        startDistributionReporting: 0b1111,
        SKUs: 0b1111,
      } as Account),
      new User({
        accessPermission: 0b1111,
        userFirstName: 0b1111,
        userLastName: 0b1111,
        userEmail: 0b1111,
        userAccountRole: 0b1111,
        userLanguage: 0b1111,
        userInviteFirstName: 0b1111,
        userInviteLastName: 0b1111,
        userInviteEmail: 0b1111,
        userInviteLanguage: 0b1111,
        userInviteAccountRole: 0b1111,
      } as User),
      new AdUnit({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        markeplaceClassification: 0b1111,
        businessModel: 0b1111,
        distributionPartnerCodes: 0b1111,
        channelList: 0b1111,
        servingTier: 0b1111,
        publisherGroupConfig: 0b1111,
        inhousePromotions: 0b1111,
      } as AdUnit),
      new Channel({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        adFilters: 0b1111,
        adUnitList: 0b1111,
      } as Channel),
      new Property({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        websiteURL: 0b1101,
        approvalStatus: 0b1111,
        notes: 0b1111,
        attachedAdUnits: 0b1111,
        clarity: 0b1111,
      } as Property),
      new Filter({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        filterType: 0b1101,
        channelList: 0b1111,
        excludedAdKeywords: 0b1111,
        excludedQueryKeywords: 0b1111,
        excludedURLs: 0b1111,
      } as Filter),
    ],
  ],
  [
    // PC Finance Ops
    172,
    [
      new Publisher({
        id: 0b1111,
        accessPermission: 0b1111,
        partner: 0b1111,
        name: 0b1111,
        networkAffiliation: 0b1111,
        address: 0b1111,
        publisherTier: 0b1111,
        overrideFeatureTier: 0b1111,
        entityLimitTier: 0b1111,
        serviceLevel: 0b1111,
        allowDistributionReporting: 0b1111,
        allowDistributionV2Reporting: 0b1111,
        allowStartDistributionReporting: 0b1111,
        allowStartDistributionContentReporting: 0b1111,
        allowSelectTierReporting: 0b1111,
        allowMediationReporting: 0b1111,
        allowMobileSearchAdUnit: 0b1111,
        allowNativeReporting: 0b1111,
        allowSyndicationReporting: 0b1111,
        allowWebAdUnit: 0b1111,
        allowPaidSearchReportingApi: 0b1111,
        allowPaidSearchReportingDashboard: 0b1111,
        allowDynamicPropertyRegistration: 0b1111,
        enableDefaultTythonV2Reporting: 0b1111,
        publisherAllowedFeaturesPropertyAutoApproved: 0b1111,
        ipRanges: 0b1111,
        newAccount: 0b1111,
      } as Publisher),
      new Account({
        accessPermission: 0b1111,
        publisherName: 0b1111,
        accountName: 0b1111,
        primaryContact: 0b1111,
        lanuage: 0b1111,
        countryOrRegion: 0b1111,
        currency: 0b1111,
        timeZone: 0b1111,
        paymentOption: 0b1111,
        financialStatus: 0b1111,
        paymentLifecycle: 0b1111,
        operationCostRation: 0b1111,
        accountLevelAttribute: 0b1111,
        inHousePromotion: 0b1111,
        partnerCodesforDistributionReport: 0b1111,
        formCodesforDistributionReport: 0b1111,
        startDistributionReporting: 0b1111,
        SKUs: 0b1111,
      } as Account),
      new User({
        accessPermission: 0b1111,
        userFirstName: 0b1111,
        userLastName: 0b1111,
        userEmail: 0b1111,
        userAccountRole: 0b1111,
        userLanguage: 0b1111,
        userInviteFirstName: 0b1111,
        userInviteLastName: 0b1111,
        userInviteEmail: 0b1111,
        userInviteLanguage: 0b1111,
        userInviteAccountRole: 0b1111,
      } as User),
      new AdUnit({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        markeplaceClassification: 0b1111,
        businessModel: 0b1111,
        channelList: 0b1111,
        servingTier: 0b1111,
        publisherGroupConfig: 0b1111,
        inhousePromotions: 0b1111,
      } as AdUnit),
      new Channel({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        adFilters: 0b1111,
        adUnitList: 0b1111,
      } as Channel),
      new Property({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        websiteURL: 0b1101,
        approvalStatus: 0b1111,
        notes: 0b1111,
        attachedAdUnits: 0b1111,
        clarity: 0b1111,
      } as Property),
      new Filter({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        filterType: 0b1101,
        channelList: 0b1111,
        excludedAdKeywords: 0b1111,
        excludedQueryKeywords: 0b1111,
        excludedURLs: 0b1111,
      } as Filter),
    ],
  ],
  [
    // Verizon Media
    125,
    [
      new Publisher({
        id: 0b0100,
        accessPermission: 0b1111,
        partner: 0b0100,
        name: 0b1111,
        networkAffiliation: 0b1111,
        address: 0b1111,
        publisherTier: 0b0000,
        overrideFeatureTier: 0b0000,
        entityLimitTier: 0b0000,
        serviceLevel: 0b0000,
        allowDistributionReporting: 0b0000,
        allowDistributionV2Reporting: 0b0000,
        allowStartDistributionReporting: 0b0000,
        allowStartDistributionContentReporting: 0b0000,
        allowSelectTierReporting: 0b0000,
        allowMediationReporting: 0b0000,
        allowMobileSearchAdUnit: 0b0000,
        allowNativeReporting: 0b0000,
        allowSyndicationReporting: 0b0000,
        allowWebAdUnit: 0b0000,
        allowPaidSearchReportingApi: 0b0000,
        allowPaidSearchReportingDashboard: 0b0000,
        allowDynamicPropertyRegistration: 0b1111,
        enableDefaultTythonV2Reporting: 0b0000,
        publisherAllowedFeaturesPropertyAutoApproved: 0b0000,
        ipRanges: 0b0000,
        newAccount: 0b1111,
      } as Publisher),
      new Account({
        accessPermission: 0b1111,
        publisherName: 0b1111,
        accountName: 0b1111,
        primaryContact: 0b1111,
        lanuage: 0b1111,
        countryOrRegion: 0b1111,
        currency: 0b1111,
        timeZone: 0b1111,
        paymentOption: 0b0000,
        financialStatus: 0b0000,
        paymentLifecycle: 0b0000,
        operationCostRation: 0b0000,
        accountLevelAttribute: 0b0000,
        inHousePromotion: 0b0000,
        partnerCodesforDistributionReport: 0b0000,
        formCodesforDistributionReport: 0b0000,
        startDistributionReporting: 0b0000,
        SKUs: 0b0000,
      } as Account),
      new User({
        accessPermission: 0b0000,
        userFirstName: 0b0000,
        userLastName: 0b0000,
        userEmail: 0b0000,
        userAccountRole: 0b0000,
        userLanguage: 0b0000,
        userInviteFirstName: 0b0000,
        userInviteLastName: 0b0000,
        userInviteEmail: 0b0000,
        userInviteLanguage: 0b0000,
        userInviteAccountRole: 0b0000,
      } as User),
      new AdUnit({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        markeplaceClassification: 0b1111,
        businessModel: 0b1111,
        channelList: 0b1111,
        servingTier: 0b0000,
        publisherGroupConfig: 0b1111,
        inhousePromotions: 0b0000,
      } as AdUnit),
      new Channel({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        adFilters: 0b1111,
        adUnitList: 0b1111,
      } as Channel),
      new Property({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        websiteURL: 0b1101,
        approvalStatus: 0b1111,
        notes: 0b1111,
        attachedAdUnits: 0b1111,
        clarity: 0b1111,
      } as Property),
      new Filter({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        filterType: 0b1101,
        channelList: 0b1111,
        excludedAdKeywords: 0b1111,
        excludedQueryKeywords: 0b1111,
        excludedURLs: 0b1111,
      } as Filter),
    ],
  ],
  [
    // Verizon Media
    156,
    [
      new AdUnit({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        markeplaceClassification: 0b1111,
        businessModel: 0b1111,
        channelList: 0b1111,
        servingTier: 0b0000,
        publisherGroupConfig: 0b1111,
        inhousePromotions: 0b0000,
      } as AdUnit),
      new Channel({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        adFilters: 0b1111,
        adUnitList: 0b1111,
      } as Channel),
      new Property({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        websiteURL: 0b1101,
        approvalStatus: 0b1111,
        notes: 0b1111,
        attachedAdUnits: 0b1111,
        clarity: 0b1111,
      } as Property),
      new Filter({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        filterType: 0b1101,
        channelList: 0b1111,
        excludedAdKeywords: 0b1111,
        excludedQueryKeywords: 0b1111,
        excludedURLs: 0b1111,
      } as Filter),
    ],
  ],
  [
    // PC Guest
    171,
    [
      new Publisher({
        id: 0b0100,
        accessPermission: 0b0100,
        partner: 0b0100,
        name: 0b0100,
        networkAffiliation: 0b0100,
        address: 0b0100,
        publisherTier: 0b0100,
        overrideFeatureTier: 0b0100,
        entityLimitTier: 0b0100,
        serviceLevel: 0b0100,
        allowDistributionReporting: 0b0100,
        allowDistributionV2Reporting: 0b0100,
        allowStartDistributionReporting: 0b0100,
        allowStartDistributionContentReporting: 0b0100,
        allowSelectTierReporting: 0b0100,
        allowMediationReporting: 0b0100,
        allowMobileSearchAdUnit: 0b0100,
        allowNativeReporting: 0b0100,
        allowSyndicationReporting: 0b0100,
        allowWebAdUnit: 0b0100,
        allowPaidSearchReportingApi: 0b0100,
        allowPaidSearchReportingDashboard: 0b0100,
        allowDynamicPropertyRegistration: 0b0100,
        enableDefaultTythonV2Reporting: 0b0100,
        publisherAllowedFeaturesPropertyAutoApproved: 0b0100,
        ipRanges: 0b0100,
        newAccount: 0b0100,
      } as Publisher),
      new Account({
        publisherName: 0b0100,
        accountName: 0b0100,
        primaryContact: 0b0100,
        lanuage: 0b0100,
        countryOrRegion: 0b0100,
        currency: 0b0100,
        timeZone: 0b0100,
        paymentOption: 0b0100,
        financialStatus: 0b0100,
        paymentLifecycle: 0b0100,
        operationCostRation: 0b0100,
        accountLevelAttribute: 0b0100,
        inHousePromotion: 0b0100,
        partnerCodesforDistributionReport: 0b0100,
        formCodesforDistributionReport: 0b0100,
        startDistributionReporting: 0b0100,
        SKUs: 0b0100,
      } as Account),
      new User({
        accessPermission: 0b0100,
        userFirstName: 0b0100,
        userLastName: 0b0100,
        userEmail: 0b0100,
        userAccountRole: 0b0100,
        userLanguage: 0b0100,
        userInviteFirstName: 0b0100,
        userInviteLastName: 0b0100,
        userInviteEmail: 0b0100,
        userInviteLanguage: 0b0100,
        userInviteAccountRole: 0b0100,
      } as User),
      new AdUnit({
        accessPermission: 0b0100,
        name: 0b0100,
        id: 0b0100,
        markeplaceClassification: 0b0100,
        businessModel: 0b0100,
        channelList: 0b0100,
        servingTier: 0b0000,
        publisherGroupConfig: 0b0100,
        inhousePromotions: 0b0100,
      } as AdUnit),
      new Channel({
        accessPermission: 0b0100,
        name: 0b0100,
        id: 0b0100,
        adFilters: 0b0100,
        adUnitList: 0b0100,
      } as Channel),
      new Property({
        accessPermission: 0b0100,
        name: 0b0100,
        id: 0b0100,
        websiteURL: 0b0100,
        approvalStatus: 0b0100,
        notes: 0b0100,
        clarity: 0b0100,
      } as Property),
      new Filter({
        accessPermission: 0b0100,
        name: 0b0100,
        id: 0b0100,
        filterType: 0b0100,
        channelList: 0b0100,
        excludedAdKeywords: 0b0100,
        excludedQueryKeywords: 0b0100,
        excludedURLs: 0b0100,
      } as Filter),
    ],
  ],
]);

export const tythonUserRolePermissions = new Map<number, Model[]>([
  [
    // Super admin
    41,
    [
      new DropdownMenu({
        adManagement: 0b1111,
        partnerManagment: 0b0000,
        reporting: 0b0000,
      } as DropdownMenu),
      new NavigationMenu({
        home: 0b1111,
        adunit: 0b1111,
        channel: 0b0000,
        adFilter: 0b0000,
        property: 0b1111,
        adblock: 0b1111,
        publisherProfile: 0b1111,
        reporting: 0b1111,
        // tython users should not be able to access these pages
        publisher: 0b0000,
        account: 0b0000,
        user: 0b0000,
        adquality: 0b0000,
        dashboard: 0b01111,
        auditHistory: 0b1111,
      } as NavigationMenu),
      new Publisher({
        id: 0b0101,
        accessPermission: 0b1111,
        partner: 0b0000,
        name: 0b0000,
        networkAffiliation: 0b0000,
        address: 0b1111,
        publisherTier: 0b0000,
        overrideFeatureTier: 0b0000,
        entityLimitTier: 0b0000,
        serviceLevel: 0b0000,
        allowDistributionReporting: 0b0000,
        allowDistributionV2Reporting: 0b0000,
        allowStartDistributionReporting: 0b0000,
        allowStartDistributionContentReporting: 0b0000,
        allowSelectTierReporting: 0b0000,
        allowMediationReporting: 0b0000,
        allowMobileSearchAdUnit: 0b0000,
        allowNativeReporting: 0b0000,
        allowSyndicationReporting: 0b0000,
        allowWebAdUnit: 0b0000,
        allowPaidSearchReportingApi: 0b0000,
        allowPaidSearchReportingDashboard: 0b1111,
        allowDynamicPropertyRegistration: 0b0000,
        enableDefaultTythonV2Reporting: 0b0000,
        publisherAllowedFeaturesPropertyAutoApproved: 0b0000,
        ipRanges: 0b0000,
        // Tython self-serve publishers cannot access/create accounts
        newAccount: 0b0000,
      } as Publisher),
      new Account({
        accessPermission: 0b0000,
        publisherName: 0b0000,
        accountName: 0b0000,
        primaryContact: 0b0000,
        lanuage: 0b0000,
        countryOrRegion: 0b0000,
        currency: 0b0000,
        timeZone: 0b0000,
        paymentOption: 0b0000,
        financialStatus: 0b0000,
        paymentLifecycle: 0b0000,
        operationCostRation: 0b0000,
        accountLevelAttribute: 0b0000,
        inHousePromotion: 0b0000,
        partnerCodesforDistributionReport: 0b0000,
        formCodesforDistributionReport: 0b0000,
        startDistributionReporting: 0b0000,
        SKUs: 0b0000,
      } as Account),
      new User({
        accessPermission: 0b1111,
        userFirstName: 0b1111,
        userLastName: 0b1111,
        userEmail: 0b1111,
        userAccountRole: 0b1111,
        userLanguage: 0b1111,
        userInviteFirstName: 0b1111,
        userInviteLastName: 0b1111,
        userInviteEmail: 0b1111,
        userInviteLanguage: 0b1111,
        userInviteAccountRole: 0b1111,
      } as User),
      new AdUnit({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        markeplaceClassification: 0b0000,
        businessModel: 0b0000,
        distributionPartnerCodes: 0b1111,
        channelList: 0b0000,
        servingTier: 0b0000,
        publisherGroupConfig: 0b0000,
        inhousePromotions: 0b0000,
      } as AdUnit),
      new Channel({
        accessPermission: 0b0000,
        name: 0b0000,
        id: 0b0000,
        adFilters: 0b0000,
        adUnitList: 0b0000,
      } as Channel),
      new Property({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        websiteURL: 0b1101,
        approvalStatus: 0b0000,
        notes: 0b0000,
        attachedAdUnits: 0b0000,
        clarity: 0b0000,
      } as Property),
      new Filter({
        accessPermission: 0b0000,
        name: 0b0000,
        id: 0b0000,
        filterType: 0b0000,
        channelList: 0b0000,
        excludedAdKeywords: 0b0000,
        excludedQueryKeywords: 0b0000,
        excludedURLs: 0b0000,
      } as Filter),
      new PublisherProfileTabs({
        accessPermission: 0b1111,
        profile: 0b1111,
        userManagement: 0b1111,
        payments: 0b1111,
        settings: 0b1111,
      } as PublisherProfileTabs),
      new PublisherSettings({
        mediation: 0b1111,
        publisherPreferences: 0b1111,
        publisherDelete: 0b0001,
      } as PublisherSettings),
      new UserSettings({
        // Seperate user level system preferences are not provisioned yet
        systemPreferences: 0b0100,
        billingAlerts: 0b1111,
        servingStatus: 0b1111,
        profileReview: 0b1111,
        invitedUsers: 0b1111,
        userDelete: 0b0001,
      } as UserSettings),
      new AdBlock({
        enablePublisherBlocks: 0b1111,
        enableSiteBlocks: 0b1111,
        editDomainBlocks: 0b1111,
        editCategoryBlocks: 0b1111,
      } as AdBlock),
    ],
  ],
  [
    // Account manager
    43,
    [
      new DropdownMenu({
        adManagement: 0b1111,
        partnerManagment: 0b0000,
        reporting: 0b0000,
      } as DropdownMenu),
      new NavigationMenu({
        home: 0b1111,
        adunit: 0b1111,
        channel: 0b0000,
        adFilter: 0b0000,
        property: 0b1111,
        adblock: 0b1111,
        publisherProfile: 0b1111,
        reporting: 0b1111,
        publisher: 0b0000,
        account: 0b0000,
        user: 0b0000,
        adquality: 0b0000,
        dashboard: 0b1111,
        auditHistory: 0b1111,
      } as NavigationMenu),
      new Publisher({
        id: 0b0000,
        accessPermission: 0b0000,
        partner: 0b0000,
        name: 0b0000,
        networkAffiliation: 0b0000,
        address: 0b0000,
        publisherTier: 0b0000,
        overrideFeatureTier: 0b0000,
        entityLimitTier: 0b0000,
        serviceLevel: 0b0000,
        allowDistributionReporting: 0b0000,
        allowDistributionV2Reporting: 0b0000,
        allowStartDistributionReporting: 0b0000,
        allowStartDistributionContentReporting: 0b0000,
        allowSelectTierReporting: 0b0000,
        allowMediationReporting: 0b0000,
        allowMobileSearchAdUnit: 0b0000,
        allowNativeReporting: 0b0000,
        allowSyndicationReporting: 0b0000,
        allowWebAdUnit: 0b0000,
        allowPaidSearchReportingApi: 0b0000,
        allowPaidSearchReportingDashboard: 0b0000,
        allowDynamicPropertyRegistration: 0b0000,
        enableDefaultTythonV2Reporting: 0b0000,
        publisherAllowedFeaturesPropertyAutoApproved: 0b0000,
        ipRanges: 0b0000,
        newAccount: 0b0000,
      } as Publisher),
      new Account({
        accessPermission: 0b0000,
        publisherName: 0b0000,
        accountName: 0b0000,
        primaryContact: 0b0000,
        lanuage: 0b0000,
        countryOrRegion: 0b0000,
        currency: 0b0000,
        timeZone: 0b0000,
        paymentOption: 0b0000,
        financialStatus: 0b0000,
        paymentLifecycle: 0b0000,
        operationCostRation: 0b0000,
        accountLevelAttribute: 0b0000,
        inHousePromotion: 0b0000,
        partnerCodesforDistributionReport: 0b0000,
        formCodesforDistributionReport: 0b0000,
        startDistributionReporting: 0b0000,
        SKUs: 0b0000,
      } as Account),
      new User({
        accessPermission: 0b0000,
        userFirstName: 0b0000,
        userLastName: 0b0000,
        userEmail: 0b0000,
        userAccountRole: 0b0000,
        userLanguage: 0b0000,
        userInviteFirstName: 0b0000,
        userInviteLastName: 0b0000,
        userInviteEmail: 0b0000,
        userInviteLanguage: 0b0000,
        userInviteAccountRole: 0b0000,
      } as User),
      new AdUnit({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        // Following fields are not exposed to tython self-serve users
        markeplaceClassification: 0b0000,
        businessModel: 0b0000,
        channelList: 0b0000,
        servingTier: 0b0000,
        publisherGroupConfig: 0b0000,
        inhousePromotions: 0b0000,
      } as AdUnit),
      new Channel({
        accessPermission: 0b0000,
        name: 0b0000,
        id: 0b0000,
        adFilters: 0b0000,
        adUnitList: 0b0000,
      } as Channel),
      new Property({
        accessPermission: 0b1111,
        name: 0b1111,
        id: 0b0101,
        websiteURL: 0b1101,
        approvalStatus: 0b0000,
        notes: 0b0000,
        attachedAdUnits: 0b0000,
        clarity: 0b0000,
      } as Property),
      new Filter({
        accessPermission: 0b0000,
        name: 0b0000,
        id: 0b0000,
        filterType: 0b0000,
        channelList: 0b0000,
        excludedAdKeywords: 0b0000,
        excludedQueryKeywords: 0b0000,
        excludedURLs: 0b0000,
      } as Filter),
      new PublisherProfileTabs({
        accessPermission: 0b1000,
        profile: 0b0100,
        userManagement: 0b0000,
        payments: 0b0000,
        settings: 0b0100,
      } as PublisherProfileTabs),
      new PublisherSettings({
        mediation: 0b0100,
        publisherPreferences: 0b0000,
        publisherDelete: 0b0000,
      } as PublisherSettings),
      new UserSettings({
        systemPreferences: 0b0100,
        billingAlerts: 0b0000,
        servingStatus: 0b1111,
        profileReview: 0b1111,
        invitedUsers: 0b0000,
        userDelete: 0b0001,
      } as UserSettings),
      new AdBlock({
        enablePublisherBlocks: 0b1111,
        enableSiteBlocks: 0b1111,
        editDomainBlocks: 0b1111,
        editCategoryBlocks: 0b1111,
      } as AdBlock),
    ],
  ],
  [
    // Report user
    49,
    [
      new DropdownMenu({
        adManagement: 0b1111,
        partnerManagment: 0b0000,
        reporting: 0b0000,
      } as DropdownMenu),
      new NavigationMenu({
        home: 0b1111,
        adunit: 0b1111,
        channel: 0b0000,
        adFilter: 0b0000,
        property: 0b1111,
        adblock: 0b1111,
        publisherProfile: 0b0000,
        reporting: 0b1111,
        publisher: 0b0000,
        account: 0b0000,
        user: 0b0000,
        adquality: 0b0000,
        dashboard: 0b01111,
        auditHistory: 0b0000,
      } as NavigationMenu),
      new Publisher({
        id: 0b0000,
        accessPermission: 0b0000,
        partner: 0b0000,
        name: 0b0000,
        networkAffiliation: 0b0000,
        address: 0b0000,
        publisherTier: 0b0000,
        overrideFeatureTier: 0b0000,
        entityLimitTier: 0b0000,
        serviceLevel: 0b0000,
        allowDistributionReporting: 0b0000,
        allowDistributionV2Reporting: 0b0000,
        allowStartDistributionReporting: 0b0000,
        allowStartDistributionContentReporting: 0b0000,
        allowSelectTierReporting: 0b0000,
        allowMediationReporting: 0b0000,
        allowMobileSearchAdUnit: 0b0000,
        allowNativeReporting: 0b0000,
        allowSyndicationReporting: 0b0000,
        allowWebAdUnit: 0b0000,
        allowPaidSearchReportingApi: 0b0000,
        allowPaidSearchReportingDashboard: 0b0000,
        allowDynamicPropertyRegistration: 0b0000,
        enableDefaultTythonV2Reporting: 0b0000,
        publisherAllowedFeaturesPropertyAutoApproved: 0b0000,
        ipRanges: 0b0000,
        newAccount: 0b0000,
      } as Publisher),
      new Account({
        accessPermission: 0b0000,
        publisherName: 0b0000,
        accountName: 0b0000,
        primaryContact: 0b0000,
        lanuage: 0b0000,
        countryOrRegion: 0b0000,
        currency: 0b0000,
        timeZone: 0b0000,
        paymentOption: 0b0000,
        financialStatus: 0b0000,
        paymentLifecycle: 0b0000,
        operationCostRation: 0b0000,
        accountLevelAttribute: 0b0000,
        inHousePromotion: 0b0000,
        partnerCodesforDistributionReport: 0b0000,
        formCodesforDistributionReport: 0b0000,
        startDistributionReporting: 0b0000,
        SKUs: 0b0000,
      } as Account),
      new User({
        accessPermission: 0b0000,
        userFirstName: 0b0000,
        userLastName: 0b0000,
        userEmail: 0b0000,
        userAccountRole: 0b0000,
        userLanguage: 0b0000,
        userInviteFirstName: 0b0000,
        userInviteLastName: 0b0000,
        userInviteEmail: 0b0000,
        userInviteLanguage: 0b0000,
        userInviteAccountRole: 0b0000,
      } as User),
      new AdUnit({
        accessPermission: 0b0100,
        name: 0b0100,
        id: 0b0100,
        markeplaceClassification: 0b0000,
        businessModel: 0b0000,
        channelList: 0b0000,
        servingTier: 0b0000,
        publisherGroupConfig: 0b0000,
        inhousePromotions: 0b0000,
      } as AdUnit),
      new Channel({
        accessPermission: 0b0000,
        name: 0b0000,
        id: 0b0000,
        adFilters: 0b0000,
        adUnitList: 0b0000,
      } as Channel),
      new Property({
        accessPermission: 0b0100,
        name: 0b0100,
        id: 0b0100,
        websiteURL: 0b0100,
        approvalStatus: 0b0000,
        notes: 0b0000,
        attachedAdUnits: 0b0000,
        clarity: 0b0000,
      } as Property),
      new Filter({
        accessPermission: 0b0000,
        name: 0b0000,
        id: 0b0000,
        filterType: 0b0000,
        channelList: 0b0000,
        excludedAdKeywords: 0b0000,
        excludedQueryKeywords: 0b0000,
        excludedURLs: 0b0000,
      } as Filter),
      new PublisherProfileTabs({
        accessPermission: 0b0000,
        profile: 0b0000,
        userManagement: 0b0000,
        payments: 0b0000,
        settings: 0b0000,
      } as PublisherProfileTabs),
      new PublisherSettings({
        mediation: 0b0000,
        publisherPreferences: 0b0000,
        publisherDelete: 0b0000,
      } as PublisherSettings),
      new UserSettings({
        systemPreferences: 0b0100,
        billingAlerts: 0b0000,
        servingStatus: 0b0000,
        profileReview: 0b0000,
        invitedUsers: 0b0000,
        userDelete: 0b0001,
      } as UserSettings),
      new AdBlock({
        enablePublisherBlocks: 0b0100,
        enableSiteBlocks: 0b0100,
        editDomainBlocks: 0b0100,
        editCategoryBlocks: 0b0100,
      } as AdBlock),
    ],
  ],
]);

// tslint:disable:no-bitwise
export const hasReadAccess = (val: number): boolean => {
  return (val & (1 << 2)) > 0 ? true : false;
};

export const hasCreateAccess = (val: number): boolean => {
  return (val & (1 << 3)) > 0 ? true : false;
};

export const hasUpdateAccess = (val: number): boolean => {
  return (val & (1 << 1)) > 0 ? true : false;
};

export const hasDeleteAccess = (val: number): boolean => {
  return (val & 1) > 0 ? true : false;
};
// tslint:enable:no-bitwise

export async function setUserRoles(userContext: UserContext) {
  const url = uiConfig.getApiBaseAddress() + '/user/scope';
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;
  let id;

  try {
    onLoadingChanged(true);
    onLoadingSetting(false);
    const timeout = 30000;
    id = setTimeout(() => onLoadingWaiting(true), timeout);
    response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      if (response.status !== 302 || isLocationPubCenter()) {
        return handleApiErrors(response, url);
      }
    }

    const responseJson: UserRoleResponse = await response.json();

    if (responseJson.data) {
      const scope = responseJson.data.scope;

      setUserContext(userContext, scope, userContext.userType);

      setUserRolesInCache(scope.roleIds);
    }
  } catch (err) {
    const error = `Failed to fetch user scopes for ${url}. Errors: ${err}`;
    Log.error(error);
    onNotificationChanged({ textMessageDescriptor: messages.failedToFetchUserScope, messageBarType: MessageBarType.error });
  } finally {
    clearTimeout(id);
    onLoadingChanged(false);
  }
}

export function setUserContext(userContext: UserContext, scope: IUserRoleScope, userType?: UserType) {
  Log.debug(`Setting user scope, parentCustomer: ${scope.parentCustomer}, role ids: ${scope.roleIds.toString()}`);

  userContext.id = scope.id;
  userContext.userRoles = scope.roleIds;
  onUserContextChanged({ ...userContext, userType: userType });

  const parentCustomerId = scope.parentCustomer;
  const accessiblePublisherList = scope.accessiblePublishers;

  onDefaultPublisherSet(parentCustomerId);
  onAccessiblePublisherListChanged(accessiblePublisherList);

  localStorage.setItem(DEFAULT_PUBLISHER_CACHE_KEY, parentCustomerId.toString());
}

export function setUserRolesInCache(roleIds: number[]) {
  const userRoles = new Map<string, Model>();
  Log.debug(`Setting user access permissions in cache, ${roleIds.toString()}`);

  roleIds.forEach((roleId: number) => {
    let entities;
    if (isTythonSelfServeUser()) {
      entities = tythonUserRolePermissions.get(roleId);
    } else {
      entities = rolePermissions.get(roleId);
    }

    if (entities) {
      entities.forEach((entity: Model) => {
        const userRoleEntity = userRoles.get(entity.className);
        if (userRoleEntity) {
          userRoleEntity.or(entity);
          userRoles.set(userRoleEntity.className, userRoleEntity);
        } else {
          userRoles.set(entity.className, entity);
        }
      });
    }
  });

  const userRolesJSON = {};
  userRoles.forEach((value: Model, key: string) => {
    userRolesJSON[key] = JSON.stringify(value);
  });

  localStorage.setItem(userRoleKey, JSON.stringify(userRolesJSON));
}
