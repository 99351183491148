import { ApiError, handleApiErrors, handleError } from '..';
import { EN_LOCALE_KEY } from '../../constants';

export async function getHelpInfo(id: string, locale?: string): Promise<string> {
  const helpUrl =
    process.env.REACT_APP_ENV === 'production'
      ? `https://help.pubcenter.microsoft.com/api/apexapi/?project=pubcenter_v4&language=${
          locale ?? EN_LOCALE_KEY
        }&method=popup&asRow=popup&Query=`
      : `https://help.si.pubcenter.microsoft.com/api/apexapi/?project=pubcenter_v4&language=${
          locale ?? EN_LOCALE_KEY
        }&method=popup&asRow=popup&Query=`;

  const url = helpUrl + encodeURIComponent(id) + '.htm';
  try {
    const response = await fetch(url);

    if (!response.ok) {
      return handleApiErrors(response, url);
    }

    const responseJson = await response.json();
    const htmlStr =
      process.env.REACT_APP_ENV === 'production'
        ? '<link rel="stylesheet" type="text/css" href="http://help.pubcenter.microsoft.com/resources/popup/styles/PopupStyles.min.css?v=2.00023"/> '
        : '<link rel="stylesheet" type="text/css" href="http://help.si.pubcenter.microsoft.com/resources/popup/styles/PopupStyles.min.css?v=2.00023"/> ';

    const helpContent = responseJson?.Content?.Content ?? `Couldn't load help content!!`;
    return htmlStr + helpContent;
  } catch (err) {
    const error = `helpInfo callout operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
