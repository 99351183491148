import { IBreadcrumbStyleProps, IBreadcrumbStyles, IStyle, IStyleFunctionOrObject, ITheme } from '@fluentui/react';
import { ReactElement } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { FormState, IFormEditParams } from '../../components/Form';
import { IFormTitleStyleProps, IFormTitleStyles } from '../../components/Form/FormTitle';
import { StepperDirection } from '../../components/Stepper/Stepper.types';
import { IRouterWithIntl } from '../../utils';

export enum StepState {
  Empty,
  Dirty
}

export interface IStepperStep {
  name: string;
  helpId?: string;
  component: ReactElement;
  nextBtnLbl?: string;
  previousBtnLbl?: string;
  submitButtonDisabled?: boolean;
  allowFreeNavigation?: boolean;
  freeNavigationErrorMessage?: string;
  hideStep?: boolean;
  isNextDisabled?: boolean;
  isStepDirty?: boolean;
  extraButtons?:  ReactElement[];
  /** In case we need some additional actions for next/previous buttons prior to changing current step */
  onNext?: (action: () => void, stepState: StepState) => void;
  onPrevious?: () => void;
  title?:string
}

export interface IDetailPageWithStepperProps extends InjectedIntlProps, IRouterWithIntl<IFormEditParams> {
  process: string,
  steps: IStepperStep[],
  pageMode: FormState;
  backToBase: () => void,
  openStep?: number,
  direction?: StepperDirection,
  styles?: Partial<IDetailPageWithStepperStyles>;
}

export interface IDetailPageWithStepperStyleProps {
  theme: ITheme;
  pageMode?: FormState;
  direction?: StepperDirection,
}

export interface IDetailPageWithStepperStyles {
  root: IStyle;
  navBackButton?: IStyle;
  scrollablePanel?: IStyle;
  content?: IStyle;
  buttons?: IStyle;
  subComponentStyles: {
    formTitle?: IStyleFunctionOrObject<IFormTitleStyleProps, IFormTitleStyles>;
    breadcrumb?: IStyleFunctionOrObject<IBreadcrumbStyleProps, IBreadcrumbStyles>;
  };
  mainContainer?: IStyle;
  leftPane?: IStyle;
  rightPane?: IStyle;
}
