import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'userManagement.title',
    defaultMessage: 'User management',
  },
  usersTab: {
    id: 'userManagement.usersTab',
    defaultMessage: 'Team users',
  },
  userInvitationsTab: {
    id: 'userManagement.userInvitationsTab',
    defaultMessage: 'User invitations',
  },
  email: {
    id: 'userManagement.email',
    defaultMessage: 'Email',
  },
  actions: {
    id: 'userManagement.actions',
    defaultMessage: 'Actions',
  },
  role: {
    id: 'userManagement.role',
    defaultMessage: 'Role',
  },
  noEntityFound: {
    id: 'userManagement.noEntityFound',
    defaultMessage: 'No users found for this publisher, invite an user',
  },
  gridNotReadyLabel: {
    id: 'userManagement.gridNotReadyLabel',
    defaultMessage: 'Publisher context is not set in this session. Try logging out and login again.',
  },
  superAdminDesc: {
    id: 'userManagement.superAdminDesc',
    defaultMessage: `Can access and control the publisher's account.`,
  },
  accountManagerDesc: {
    id: 'userManagement.accountManagerDesc',
    defaultMessage: `Can partially manage the publisher's account.`,
  },
  reportUserDesc: {
    id: 'userManagement.reportUserDesc',
    defaultMessage: 'Can view account information and reporting.',
  },
  superAdmin: {
    id: 'userManagement.userpage.superAdmin',
    defaultMessage: 'Super admin',
  },
  accountManager: {
    id: 'userManagement.accountManager',
    defaultMessage: 'Account manager',
  },
  reportUser: {
    id: 'userManagement.reportUser',
    defaultMessage: 'Reporting user',
  },
  changeUserRole: {
    id: 'userManagement.changeUserRole',
    defaultMessage: 'Change user role',
  },
  roleChangePanelDesc: {
    id: 'userManagement.roleChangePanelDesc',
    defaultMessage: `Please select a new user role.`,
  },
  deleteConfirmationDialogTitle: {
    id: 'publisherShellTab.deleteConfirmationDialogTitle',
    defaultMessage: 'Are you sure that you want to delete this user?',
  },
  deleteConfirmationDialogDesc: {
    id: 'publisherShellTab.deleteConfirmationDialogDesc',
    defaultMessage: `This action will permanently delete the selected user and all data.`,
  },
  confirmActionMessage: {
    id: 'publisherShellTab.confirmActionMessage',
    defaultMessage: `Enter "yes" to confirm that you want to permanently delete this user.`,
  },
  deleteButtonText: {
    id: 'publisherShellTab.deleteButtonText',
    defaultMessage: `Delete user`,
  },
  searchByNamePlaceholder: {
    id: 'app.searchByNamePlaceholder',
    defaultMessage: 'Search by Name',
  },
  loadUserDetailsFailed: {
    id: 'loadUserDetails.failed',
    defaultMessage: 'We’re unable to load your user details at this time. Please try again later.',
  },
  userRoleChangeFailed: {
    id: 'userRoleChange.failed',
    defaultMessage: 'Something went wrong while submitting the user role change. Please try again later.',
  },
  userManagementInvalidState: {
    id: 'userManagement.invalidState',
    defaultMessage: 'You have arrived in an invalid state, please re-login or report if issue persists.',
  },
  userManagementFailedToDeleteItem: {
    id: 'userManagement.failedToDeleteItem',
    defaultMessage: 'Failed to delete item',
  },
  editedUserRoleSuccessfully: {
    id: 'userManagement.editedUserRoleSuccessfully',
    defaultMessage: 'Edited user role for user {username} successfully',
  },
});
