import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src/Log';
import { getAppStore } from '../../../../@data';
import { postSearch } from '../../../../@data/services/GlobalSearchApiService';
import { ResourceType } from '../../../../@data/store/schema/enums/ResourceType';
import { IGlobalSearchResultData } from '../../../../@data/store/schema/interfaces/IGlobalSearchResultData';
import { GlobalSearchRequest } from '../../../../@data/store/schema/models/GlobalSearchRequest';
import { onSearchTriggered, setGlobalSearchResult, setSearchingStatus } from '../actions/onSearchTriggered';
import { getGlobalSearchCalloutStore } from '../store/store';

orchestrator(onSearchTriggered, async (msg) => {
  const { userContext } = getAppStore();
  const pageSize = 8;
  const resourceTypes: string[] = [];

  if (msg.resourceTypes) {
    msg.resourceTypes.map((resourceType: ResourceType) => resourceTypes.push(ResourceType[resourceType]));
  }

  const request: GlobalSearchRequest = {
    query: msg.query,
    globalFilters: [],
    resourceTypes: resourceTypes,
    options: {
      paginationOptions: {
        pageSize: msg.pageSize ? msg.pageSize : pageSize,
        pageToken: '',
      },
      resourceTypeDistribution: msg.resourceTypeDistribution,
      highlightingOptions: {
        highlightPreTag: '<b>',
        highlightPostTag: '</b>',
      },
    },
  };

  try {
    const response: IGlobalSearchResultData = await postSearch(request, userContext);
    if (request.query === getGlobalSearchCalloutStore().searchText) {
      setGlobalSearchResult(response);
      setSearchingStatus(false);
    } else if (getGlobalSearchCalloutStore().searchText === '') {
      setSearchingStatus(false);
    }
  } catch (e) {
    Log.error(`Error: ${JSON.stringify(e)}`);
    setSearchingStatus(false);
  }
});
