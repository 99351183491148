import { defineMessages } from 'react-intl';

export default defineMessages({
  searchBoxPlaceholder: {
    id: 'grid.searchBoxPlaceholder',
    defaultMessage: 'Find by name or ID ',
  },
  itemDisplayed: {
    id: 'grid.itemDisplayed',
    defaultMessage: '{displayedCount} of {totalCount} items displayed',
  },
  itemSelectedCount: {
    id: 'grid.itemSelectedCount',
    defaultMessage: '{count} selected',
  },
  teachingBubbleHeadline: {
    id: 'grid.teachingBubbleHeadline',
    defaultMessage: 'Please select account',
  },
  teachingBubbleContent: {
    id: 'grid.teachingBubbleContent',
    defaultMessage: 'You must select a specific account first to proceed creating ad units under the account',
  },
  dialogDismissBtnAriaDesc: {
    id: 'grid.dialogDismissBtnAriaDesc',
    defaultMessage: 'Press to dismiss',
  },
  createCmdbtnAriaDesc: {
    id: 'grid.createCmdbtnAriaDesc',
    defaultMessage: 'Click to {label}',
  },
  addFilterCmdBtnAriaDesc: {
    id: 'grid.addFilterCmdBtnAriaDesc',
    defaultMessage: 'Filter the grid below',
  },
  editCmdBtnAriaDesc: {
    id: 'grid.editCmdBtnAriaDesc',
    defaultMessage: 'Click to edit entity',
  },
  codeCmdBtnAriaDesc: {
    id: 'grid.codeCmdBtnAriaDesc',
    defaultMessage: 'Click to open code panel',
  },
  deleteCmdBtnAriaDesc: {
    id: 'grid.deleteCmdBtnAriaDesc',
    defaultMessage: 'Click to delete entity',
  },
  tokenStatusCmdBtnAriaLabel: {
    id: 'grid.tokenStatusCmdBtnAriaLabel',
    defaultMessage: 'Click to display token status',
  },
  clarityStatusCmdBtnAriaLabel: {
    id: 'grid.clarityStatusCmdBtnAriaLabel',
    defaultMessage: 'Click to display clarity status',
  },
  activateCmdBtnAriaDesc: {
    id: 'grid.activateCmdBtnAriaDesc',
    defaultMessage: 'Click to activate entity',
  },
  deactivateCmdBtnAriaDesc: {
    id: 'grid.deactivateCmdBtnAriaDesc',
    defaultMessage: 'Click to deactivate entity',
  },
  copyCmdBtnAriaDesc: {
    id: 'grid.copyCmdBtnAriaDesc',
    defaultMessage: 'Click to create a copy of this entity',
  },
  modifyColumnsCmdBtnAriaDesc: {
    id: 'grid.modifyColumnsCmdBtnAriaDesc',
    defaultMessage: 'Click to modify columns in the below grid',
  },
  modifyColumnsPanelApplyButtonAriaDesc: {
    id: 'grid.modifyColumnsPanelApplyButtonAriaDesc',
    defaultMessage: 'Click to apply selected columns to the grid',
  },
  editAdunit: {
    id: 'grid.editAdunit',
    defaultMessage: 'Edit ad unit',
  },
  changeTemplate: {
    id: 'grid.changeTemplate',
    defaultMessage: 'Change template',
  },
  gridDataDownloadSuccess: {
    id: 'grid.dataDownloadSuccess',
    defaultMessage: 'Grid data download success',
  },
  gridDataDeletingItem: {
    id: 'grid.dataDeletingTheItem',
    defaultMessage: 'Deleting the item...',
  },
  gridDataDeletingItemFailed: {
    id: 'grid.dataDeletingTheItemFailed',
    defaultMessage: 'Something went wrong while deleting the item. Please try again later.',
  },
  gridDataSubmittingChangingStatus: {
    id: 'grid.dataSubmittingChangingStatus',
    defaultMessage: 'Submitting the changing status...',
  },
  gridDataSubmittingChangingStatusFailed: {
    id: 'grid.dataSubmittedChangingStatusFailed',
    defaultMessage: 'Something went wrong while changing the status. Please try again later.',
  },
  gridDataDuplicatingFailed: {
    id: 'grid.dataDuplicatingFailed',
    defaultMessage: 'Something went wrong while duplicating the grid item. Please try again later',
  },
  gridDataDuplicating: {
    id: 'grid.dataDuplicating',
    defaultMessage: 'Duplicating the item...',
  },
});
