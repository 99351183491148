import { getAppStore } from '../../../../@data';
import { PublisherType } from '../../../../@data/store/schema/enums/PublisherType';
import { getSignUpStore } from './store';

export const isAccountTypeOrganization = () => getSignUpStore().active.accountType === PublisherType.organization;

export const getPublisherName = () => {
  const { active } = getSignUpStore();
  const userFullName = getAppStore().userContext?.name ?? `${active.userFirstName} ${active.userLastName}`;
  return active.accountType === PublisherType.organization ? active.organizationName : userFullName;
};
