import { FontWeights } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { flexWithAlignCenter } from '../../utils/GlobalStyleUtils';
import { ILogoStyleProps, ILogoStyles } from './Logo.types';

export const getStyles = (props: ILogoStyleProps): ILogoStyles => {
  const { spacing, fonts, palette } = (props.theme as unknown) as IPubcenterTheme;
  const { shouldRenderLogo, renderDarkLogo, renderGreyLogo } = props;

  return {
    logo: {
      ...flexWithAlignCenter,
      selectors: {
        img: {
          ...(renderGreyLogo
            ? { margin: `3px ${spacing.size12} 0 ${spacing.size12}`, width: '113px', objectFit: 'cover' }
            : renderDarkLogo
            ? {
                width: spacing.size128,
              }
            : { marginLeft: spacing.size10 }),
        },
        div: {
          fontSize: fonts.size28.fontSize,
          marginRight: shouldRenderLogo ? spacing.size8 : spacing.size16,
          marginTop: spacing.size4,
          marginBottom: spacing.size8,
        },
        span: {
          ...(renderGreyLogo
            ? { fontSize: fonts.size20.fontSize, color: '#737373' }
            : renderDarkLogo
            ? {
                fontSize: fonts.size18.fontSize,
                color: palette.neutralPrimary,
              }
            : {
                fontSize: fonts.size16.fontSize,
              }),
          fontWeight: FontWeights.semibold,
          marginRight: spacing.s1,
          marginLeft: 0,
        },
      },
    },
    logoNavButton: {
      opacity: shouldRenderLogo ? 0 : 1,
      width: shouldRenderLogo ? 0 : spacing.size48,
    },
    divider: {
      fontWeight: renderDarkLogo ? 100 : undefined,
      ...(renderGreyLogo ? { color: '#737373', fontSize: fonts.size24.fontSize, width: '1px' } : {}),
    },
  };
};
