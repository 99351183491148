import {
  IOverflowSetItemProps,
  IPanelProps,
  IPersonaStyleProps,
  IPersonaStyles,
  IStyle,
  IStyleFunctionOrObject,
  ITheme,
  PanelType,
} from '@fluentui/react';
import { ReactNode } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { IUseCMUIExperience } from '../../hooks/useCMUIHelpExperience/index.types';
import { ISearchProps } from '../Search/Search.types';

export interface IHeaderItem extends IOverflowSetItemProps {
  title: string;
  icon?: string;
  identity?: IHeaderIdentity;
  type?: PanelTypes;
  panel?: React.ComponentType<object>;
  panelProps?: IPanelProps;
  query?: string;
  onClick?: () => void;
  shouldOverflowUnderExtraSmallScreen: boolean;
  showRedDot?: boolean;
}

export enum PanelTypes {
  Profile = 'Profile',
  Feedback = 'Feedback',
  Notifications = 'Notifications',
}

export interface IHeaderIdentity {
  name: string;
  email: string;
}

export interface IHeaderProps<T> extends InjectedIntlProps {
  dropdownItems?: IHeaderDropdownItem[];
  items?: IHeaderItem[];
  loadingText?: string;
  onNavigate?: (path: string) => void;
  searchProps?: ISearchProps<T>;
  renderLeftIcon?: ReactNode;
  useCMUIHelpExperience: IUseCMUIExperience;
}

export interface IHeaderDropdownItem {
  key: string;
  text: string;
  icon: string;
}

export interface IHeaderItemProps {
  item: IHeaderItem;
  theme: ITheme;
}

export interface IHeaderState {
  title: string;
  selectedItem?: IHeaderItem;
  currentRoute: string;
  announcedMessage?: string;
  panelType?: PanelType;
  isSearchBarActiveInNonRegularView: boolean;
  selectedLanguage: string;
}

export interface IHeaderStyles {
  root: IStyle;
  itemIcon: IStyle;
  navigationLm: IStyle;
  leftSection: IStyle;
  rightSection?: IStyle;
  logo: IStyle;
  dropdownOption: IStyle;
  navItem: IStyle;
  textHeader: IStyle;
  helpCenterIcon: IStyle;
  moreInfoIcon: IStyle;
  overflowIcon: IStyle;
  overflowMenuWrapper: IStyle;
  subComponentStyles: {
    dropdown: IStyleFunctionOrObject<object, object>;
    itemIdentity: IStyleFunctionOrObject<IPersonaStyleProps, IPersonaStyles>;
  };
  dot: IStyle;
  defaultButtonWrapperStyle: IStyle;
  defaultButtonStyle: IStyle;
}

export interface IHeaderStyleProps {
  theme: ITheme;
  isTythonExperience: boolean;
}
