import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import { Model, getAppStore } from '../../../../../../@data';
import { FormState } from '../../../../../../components/Form';
import { CommandBarActions, Direction, GridSelectionMode, IBuildCommandBarProps } from '../../../../../../layout/GridPageV2';
import { getStore } from '../../../@data';
import { onUserInvitationChanged, onUserInvitationsChanged } from '../../../@data/actions';
import messages from '../../ManagePage.messages';

export function getCommandBarItems(formatMessage, history, match): IBuildCommandBarProps<Model> | undefined {
  const { publisher } = getAppStore();
  const { userInvitations, isFetchingUserInvitationsLoading } = getStore();

  const commandBarItems =
    (userInvitations && userInvitations.length !== 0 && publisher) || isFetchingUserInvitationsLoading
      ? {
          includeActions: [CommandBarActions.custom],
          includeFarActions: [CommandBarActions.searchBox, CommandBarActions.delete, CommandBarActions.modifyColumns],
          onPostDeleteItems: (deletedItem) => {
            onUserInvitationsChanged(userInvitations.filter((row) => row.id !== deletedItem[0].id));
          },
          getCustomCommandBarItems: (getSelectedItems) => {
            return [
              {
                key: 'inviteUser',
                name: formatMessage(messages.invite),
                iconProps: {
                  iconName: 'Add',
                  ariaLabel: 'Add',
                },
                commandBarButtonAs: () => (
                  <PrimaryButton
                    iconProps={{ iconName: 'Add' }}
                    text={formatMessage(messages.invite)}
                    onClick={() => history.push(`${match.url}/create`)}
                    disabled={isFetchingUserInvitationsLoading}
                  />
                ),
                placement: { direction: Direction.before },
                selectionModes: [GridSelectionMode.None],
                disabled: isFetchingUserInvitationsLoading,
              },
              {
                key: 'resend',
                name: formatMessage(messages.resendInvitation),
                ariaLabel: formatMessage(messages.resendInvitation),
                iconProps: {
                  iconName: 'Undo',
                  ariaLabel: 'Undo',
                },
                placement: { direction: Direction.before },
                onClick: () => {
                  const items = getSelectedItems();
                  onUserInvitationChanged(FormState.Resend, items ? items[0] : undefined);
                },
                selectionModes: [GridSelectionMode.Single],
              },
            ];
          },
        }
      : undefined;

  return commandBarItems;
}
