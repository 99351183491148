import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { AdSource } from '../../../../@data';
import { ListFilter } from '../../../../components/Filters/ListFilter/ListFilter';
import { IIdNamePair } from '../../../../components/MultiSelectPicker';
import { AdQualityFilterNames, IConditionValue } from '../@data/store/schema';
import { onAddRawElementToList, onDeselectAllChanged, onSelectAllChanged, onSelectChanged } from './@data/mutatorActions';
import { getClassNames } from './AdQualityFilters.styles';
import { IAdQualityFiltersProps } from './AdQualityFilters.types';
import { MultiTextFilters } from './MultiTextFilters/MultiTextFilters';

export const AdQualityFilters = injectIntl<IAdQualityFiltersProps>(function AdQualityFiltersComponent(
  props: IAdQualityFiltersProps
): JSX.Element | null {
  const { root } = mergeStyleSets(getClassNames());
  const { active } = props.filtersItems;
  const adSource = props.adSource;
  const { shouldShowHeadlines } = props;

  const filtersToRender = {
    [AdSource.Microsoft]: [
      AdQualityFilterNames.AccountName,
      AdQualityFilterNames.Advertiser,
      AdQualityFilterNames.AdvertiserId,
      AdQualityFilterNames.AdvertiserAccountId,
      AdQualityFilterNames.AdId,
      AdQualityFilterNames.AdTitle,
      AdQualityFilterNames.AdURL,
      ...(shouldShowHeadlines ? [AdQualityFilterNames.LongHeadline] : []),
      ...(shouldShowHeadlines ? [AdQualityFilterNames.ShortHeadline] : []),
    ],
    [AdSource.Auction]: [
      AdQualityFilterNames.CreativeAdId,
      AdQualityFilterNames.Advertiser,
      AdQualityFilterNames.AdTitle,
      AdQualityFilterNames.AdProviders,
      AdQualityFilterNames.Markets,
    ],
    [AdSource.XandrAuction]: [
      AdQualityFilterNames.CreativeAdId,
      AdQualityFilterNames.Advertiser,
      AdQualityFilterNames.AdTitle,
      AdQualityFilterNames.AdProviders,
      AdQualityFilterNames.Markets,
    ],
  };

  if (!adSource) {
    return null;
  }
  const filters = filtersToRender[adSource] || [];

  const addTempConditionPair = (heading: string): void => {
    const newItem: IConditionValue<string> = { condition: undefined, value: '' };
    onAddRawElementToList(heading, newItem);
  };

  const onSelectHandle = (heading: string, item: IIdNamePair, value: boolean) => {
    onSelectChanged(heading, item, value);
  };

  const onSelectAllHandle = (heading: string) => {
    onSelectAllChanged(heading);
  };

  const onDeselectAllHandle = (heading: string) => {
    onDeselectAllChanged(heading);
  };

  if (!active) {
    return null;
  }

  return (
    <div className={root}>
      {filters.map((filter: AdQualityFilterNames) => {
        switch (filter) {
          case AdQualityFilterNames.AccountName:
            return (
              <ListFilter
                heading={AdQualityFilterNames.AccountName}
                list={active?.[AdQualityFilterNames.AccountName]?.values}
                onSelect={onSelectHandle}
                onSelectAll={onSelectAllHandle}
                onDeselectAll={onDeselectAllHandle}
              />
            );
          case AdQualityFilterNames.AdProviders:
            return (
              <ListFilter
                heading={AdQualityFilterNames.AdProviders}
                list={active?.[AdQualityFilterNames.AdProviders]?.values}
                onSelect={onSelectHandle}
                onSelectAll={onSelectAllHandle}
                onDeselectAll={onDeselectAllHandle}
              />
            );
          case AdQualityFilterNames.Markets:
            return (
              <ListFilter
                heading={AdQualityFilterNames.Markets}
                list={active?.Markets?.values}
                onSelect={onSelectHandle}
                onSelectAll={onSelectAllHandle}
                onDeselectAll={onDeselectAllHandle}
              />
            );
          default:
            return <MultiTextFilters heading={filter} conditions={active?.[filter]?.conditions} onAddMoreClick={addTempConditionPair} />;
        }
      })}
    </div>
  );
});
