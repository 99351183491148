import { PrimaryButton } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { ZeroResults } from '../../../../components/ZeroResults/ZeroResults';
import desertImage from '../../AdBlock/resources/Desert.svg';
import { setUserInvitationPanelStatus } from './@data/mutatorActions';
import UserInvitationsMessages from './UserInvitations.messages';

export const ZeroInvitations = injectIntl(function ZeroInvitationsComponent({ intl: { formatMessage } }) {
  return (
    <>
      <ZeroResults imageSrc={desertImage} title={formatMessage(UserInvitationsMessages.zeroInvitationsTitle)}>
        <PrimaryButton
          iconProps={{ iconName: 'Add' }}
          text={formatMessage(UserInvitationsMessages.inviteUser)}
          onClick={() => setUserInvitationPanelStatus(true)}
        />
      </ZeroResults>
    </>
  );
});
