import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import {
  EntityPreferences,
  EntityType,
  getAppStore,
  getEntity,
  isLocalRetrieved,
  NotificationType,
  onLoadingChanged,
  onNotificationChanged,
  showErrorToastNotification,
  User,
} from '../../../../../../@data';
import { onFetchTnC } from '../../../../../terms-and-conditions/@data/actions';
import { TNC_PRE_SIGNATURE_FLAG } from '../../../../../terms-and-conditions/useTnC';
import messages from '../../SettingsPage.messages';
import { hasAccessToNotifications } from '../../utils/roleDifferentiationUtils';
import { onFetchUserPreferences } from '../actions/onSettingsPageChanged';
import { onSettingsChanged, saveRawSettings } from '../mutatorActions/settingsPageChanged';
import { INotificationPreferences, ISettingsViewModel } from '../store/schema/ISettingsViewModel';
import { getStore } from '../store/store';

orchestrator(onFetchUserPreferences, async (msg) => {
  Log.debug('fetching user preferences');
  const { publisher, userContext } = getAppStore();

  if (publisher && userContext) {
    try {
      onLoadingChanged(true);
      const user = new User(userContext.id);

      const userPreferences = await getEntity([publisher, user], userContext, EntityPreferences);
      const tnc = userPreferences.tncSignatureStatus;

      const notificationPreferences: INotificationPreferences = {};

      userPreferences.notificationPreferences.notifications.forEach((np) => {
        switch ((NotificationType[np.type as NotificationType] as unknown) as NotificationType) {
          case NotificationType.BillingAlert:
            notificationPreferences.billingAlerts = np.enabled;
            break;
          case NotificationType.ServingStatus:
            notificationPreferences.servingStatus = np.enabled;
            break;
          case NotificationType.ProfileReview:
            notificationPreferences.profileReview = np.enabled;
            break;
          case NotificationType.InvitedUser:
            notificationPreferences.invitedUsers = np.enabled;
            break;
        }
      });

      if (!tnc?.isLatestAccepted) {
        isLocalRetrieved() && onFetchTnC(msg.locale);
      }

      /** In case user level preference doesn't exist, inherit value from publisher preferences
       * If publisher level preference is true, we inherit the value,
       * if false, user level setting is undefined, so that user can't override it.
       */
      if (hasAccessToNotifications()) {
        const publisherPreferences = await getEntity([publisher], userContext, EntityPreferences);
        publisherPreferences.notificationPreferences.notifications.forEach((np) => {
          if (np.enabled) {
            if (
              (NotificationType[np.type] as unknown) === NotificationType.BillingAlert &&
              notificationPreferences.billingAlerts === undefined
            ) {
              notificationPreferences.billingAlerts = np.enabled;
            } else if (
              (NotificationType[np.type] as unknown) === NotificationType.ServingStatus &&
              notificationPreferences.servingStatus === undefined
            ) {
              notificationPreferences.servingStatus = np.enabled;
            } else if (
              (NotificationType[np.type] as unknown) === NotificationType.ProfileReview &&
              notificationPreferences.profileReview === undefined
            ) {
              notificationPreferences.profileReview = np.enabled;
            } else if (
              (NotificationType[np.type] as unknown) === NotificationType.InvitedUser &&
              notificationPreferences.invitedUsers === undefined
            ) {
              notificationPreferences.invitedUsers = np.enabled;
            }
          }
        });
      }

      const settings = getStore().active;

      const viewModel: ISettingsViewModel = {
        entityType: EntityType.User,
        systemPreferences: settings.systemPreferences,
        notificationPreferences: notificationPreferences,
        tncSignatureStatus: userPreferences.tncSignatureStatus,
      };

      onSettingsChanged(viewModel);
      saveRawSettings(userPreferences);
    } catch (e) {
      /**
       * this is a workaround to hide user preference call failures, the endpoint
       * is expected to return failures when user just signed up within the first
       * few minutes
       */
      !window?.localStorage.getItem(TNC_PRE_SIGNATURE_FLAG) &&
        showErrorToastNotification({ textMessageDescriptor: messages.userPreferencesFailed });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.invalidStateLoginOrReport,
    });
  }
});
