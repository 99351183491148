import { BaseButton, classNamesFunction, getTheme, ITheme, Spinner, Text } from '@fluentui/react';
import React from 'react';
import { APP_NAME_NEW } from '../../../../../constants/AppConstants';
import { AccountPageRoute } from '../../../../../Routes';
import { injectIntlAndObserver, injectRouterAndIntlWithObserver, IRouterWithIntl } from '../../../../../utils';
import { PAYMENTS_TAB_KEY } from '../../constants';
import { notificationMessages } from './paymentsOnboarding.messages';
import { getStyles, IPaymentsOnboardingStyles } from './PaymentsOnboarding.styles';
import usePaymentsOnboarding, { SETUP_PAYMENTS_NOTIFICATION_ACTION_BUTTON_ID } from './usePaymentsOnboarding';

const getClassNames = classNamesFunction<{ theme: ITheme }, IPaymentsOnboardingStyles>();
export const classNames = getClassNames(getStyles, { theme: getTheme() });
const ONBOARDING_CONTAINER_ID = 'onboarding-container';

export const PaymentsOnboarding = injectIntlAndObserver(({ intl: { formatMessage } }) => {
  // render logic
  const { xPayContainerRef, isInitializingXPayContainer } = usePaymentsOnboarding(formatMessage);

  return (
    <div className={classNames.root}>
      {isInitializingXPayContainer && <Spinner className={classNames.xPayLoadingSpinner} />}
      <div
        id={ONBOARDING_CONTAINER_ID}
        ref={xPayContainerRef}
        className={(isInitializingXPayContainer && classNames.transparentContainer) || undefined}
      />
    </div>
  );
});

export const SetupPaymentNotification = injectRouterAndIntlWithObserver<IRouterWithIntl & { publisher: string; onAction: () => void }>(
  ({ onAction, history, publisher: publisherId, intl: { formatMessage } }) => (
    <div className={classNames.setupNotificationWrapper}>
      <Text className={classNames.setupNotificationText}>
        <b>
          {formatMessage(notificationMessages.setupPaymentNotificationBoldedText, {
            appName: APP_NAME_NEW,
          })}
        </b>{' '}
        {formatMessage(notificationMessages.setupPaymentNotificationText)}
      </Text>
      {!history.location.pathname.includes(`${AccountPageRoute}#${PAYMENTS_TAB_KEY}`) && (
        <BaseButton
          className={classNames.actionButton}
          id={SETUP_PAYMENTS_NOTIFICATION_ACTION_BUTTON_ID}
          onClick={() => {
            history.push(`/ad-management/${publisherId}${AccountPageRoute}#${PAYMENTS_TAB_KEY}`, { defaultTab: PAYMENTS_TAB_KEY });
            onAction();
          }}
        >
          {formatMessage(notificationMessages.setupPaymentButton)}
        </BaseButton>
      )}
    </div>
  )
);
