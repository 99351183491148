import { action } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src/Log';
import { isNotAuthorizedUser, upperCaseCountryCode } from '../../../../@data';
import { uiConfig } from '../../../../config/ui.config';
import { EN_LOCALE_KEY, UNAUTHORIZED_LOCAL_STORAGE_LOCALE } from '../../../../constants';
import { onFetchTnC } from '../actions';
import { getStore } from '../store/store';

orchestrator(onFetchTnC, async (msg) => {
  const tncStore = getStore();
  const authorizedUserLocale = upperCaseCountryCode(msg.locale ?? EN_LOCALE_KEY);
  const unauthorizedUserLocale = upperCaseCountryCode(localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE) ?? EN_LOCALE_KEY);
  const TnCLocale = isNotAuthorizedUser() ? unauthorizedUserLocale : authorizedUserLocale;

  const url = uiConfig.getApiBaseAddress() + `/terms/getLatestByLocales?locales=${TnCLocale}`; // getLatest

  let response: Response;
  try {
    response = await fetch(url, {
      method: 'GET',
    });

    const responseJson = await response.json();

    if (response.ok) {
      const { releaseDate, version, localContent } = responseJson.data;
      action(() => {
        tncStore.content = localContent[TnCLocale];
        tncStore.latestTnCReleaseDate = releaseDate;
        tncStore.latestTnCVersion = version;
      })();
    } else {
      return Log.error(`Fetch latest Terms and Conditions failed. Error: ${JSON.stringify(responseJson.errors)}`);
    }
  } catch (e) {
    Log.error(e);
  } finally {
    action(() => {
      tncStore.isDataLoaded = true;
    })();
  }
});
