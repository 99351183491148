import React, { useEffect, useRef, useState } from 'react';
import { Card } from '../../../../../components/Card/Card';
import { useIntersectionObserverWithRefCallback } from '../../../../../hooks';
import { injectIntlAndObserver } from '../../../../../utils';
import { fetchPaymentsPageEstimatedEarnings } from '../../@data/actions';
import { getPaymentsPageEstimatedEarnings } from '../../@data/store/selectors';
import messages from '../PaymentsPage.messages';
import { getClassNames as getPaymentsCommonClassNames } from '../PaymentsPage.styles';
import { getChart, getShimmerCustomElements, getTopBarActions } from './EstimatedEarnings.helpers';
import { getClassNames } from './EstimatedEarnings.styles';
import { IEstimatedEarningsProps } from './EstimatedEarnings.types';

const DATA_METRIC_ID = 'estimated-earnings-card';
const CHART_DATA_METRIC_ID = 'estimated-earnings-chart';
export const EstimatedEarnings = injectIntlAndObserver<IEstimatedEarningsProps>((props) => {
  const { cardContainer } = getClassNames();
  const { cardsTitleBarStyle } = getPaymentsCommonClassNames(props);
  const { defaultChartType, defaultChartGrain, intl } = props;
  const { formatMessage } = intl;
  const [activeGrain, setActiveGrain] = useState(defaultChartGrain);
  const estimatedEarningsAllGrainData = getPaymentsPageEstimatedEarnings();
  const { data, isDataLoaded } = estimatedEarningsAllGrainData[activeGrain];
  const [intersectionEntry, targetObservationRef] = useIntersectionObserverWithRefCallback({});
  const isCardVisible = !!intersectionEntry?.isIntersecting;
  const [currentChartType, setCurrentChartType] = useState(defaultChartType);
  const ref = useRef<HTMLDivElement>(null);
  const [parentCardHeight, setHeight] = useState(0);
  const [parentCardWidth, setWidth] = useState(0);
  useEffect(() => {
    if (!isDataLoaded && isCardVisible) {
      fetchPaymentsPageEstimatedEarnings(activeGrain);
    }
  }, [isCardVisible, isDataLoaded, activeGrain]);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setWidth(event[0].contentBoxSize[0].inlineSize);
      setHeight(event[0].contentBoxSize[0].blockSize);
    });

    ref.current && resizeObserver.observe(ref.current);
  });

  return (
    <div className={cardContainer} data-metric={DATA_METRIC_ID} ref={targetObservationRef}>
      <Card
        titleText={formatMessage(messages.estimatedEarningsTitle)}
        shimmerCustomElement={getShimmerCustomElements()}
        isDataLoaded={isDataLoaded}
        cardTopBarActions={getTopBarActions(
          { currentChartType, setCurrentChartType },
          { activeGrain, setActiveGrain },
          formatMessage,
          props
        )}
        styleProps={{ titleBar: cardsTitleBarStyle }}
      >
        <div data-metric={CHART_DATA_METRIC_ID} ref={ref}>
          {getChart(currentChartType, activeGrain, data!, { height: parentCardHeight, width: parentCardWidth }, formatMessage)}
        </div>
      </Card>
    </div>
  );
});
