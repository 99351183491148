import { FontWeights, IStyle, ITheme, classNamesFunction, getTheme } from '@fluentui/react';
import { BreakPoints, IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../../../../../theme';

export interface IClarityOptionsStyleProps {
  theme: ITheme;
}
export interface IClarityOptionsStyle {
  wrapper: IStyle;
  wrapperTitle: IStyle;
  section: IStyle;
  sectionDetails: IStyle;
  disabledSection: IStyle;
  sectionSelector: IStyle;
  title: IStyle;
  desc: IStyle;
  advantageWrapper: IStyle;
  advantageSection: IStyle;
  toggle: IStyle;
  imageWrapper: IStyle;
  recommendationTag: IStyle;
  advantageHeader: IStyle;
  panelPending: IStyle;
  panelProjectIdApprovedSection: IStyle;
  errorMessage: IStyle;
}

export const getStyles = (props: IClarityOptionsStyleProps): IClarityOptionsStyle => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const fonts = theme.fonts as IPubCenterFonts;
  const palette = theme.palette as IPubcenterPalette;
  const { ResponsiveSmall, ResponsiveMedium } = BreakPoints;

  return {
    wrapper: {
      display: 'flex',
      gap: spacing.size16,
      marginTop: spacing.size24,
      flexDirection: 'column',
      position: 'relative',
      transition: 'all 0.3s ease - in -out',
    },
    wrapperTitle: {
      fontWeight: FontWeights.semibold,
      selectors: {
        '::after': {
          marginLeft: spacing.size6,
          content: "'*'",
          color: 'red',
        },
      },
    },
    section: {
      display: 'grid',
      gridTemplateColumns: 'auto 10fr',
      gap: spacing.size6,
      border: `1px solid ${palette.neutralLight}`,
      position: 'relative',
      [ResponsiveMedium]: {
        gridTemplateColumns: '1fr',
      },
      [ResponsiveSmall]: {
        gridTemplateColumns: '1fr',
      },
    },
    sectionDetails: {
      padding: `${spacing.size24} ${spacing.size16}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    disabledSection: {
      background: 'rgb(243, 242, 241, 0.4)',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: '999',
      cursor: 'not-allowed',
    },
    sectionSelector: {
      position: 'absolute',
      zIndex: '998',
      left: spacing.size16,
    },
    title: {
      ...fonts.size18,
      fontWeight: FontWeights.semibold,
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.25em',
    },
    desc: {
      ...fonts.size14,
      color: palette.disabledGray,
      overflowWrap: 'anywhere',
    },
    advantageWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: spacing.size8,
      marginTop: spacing.size16,
      [ResponsiveSmall]: {
        gridTemplateColumns: '1fr',
      },
    },
    advantageHeader: {
      display: 'flex',
      alignItems: 'baseline',
      gap: spacing.size8,
      [ResponsiveSmall]: {
        gap: 0,
        flexDirection: 'column',
        '& :nth-child(2)': {
          marginBlockStart: '0',
          marginBlockEnd: '0.75em',
        },
      },
    },
    advantageSection: {
      backgroundColor: palette.neutralLighter,
      width: 'fit-content',
      padding: spacing.size8,
      display: 'flex',
      gap: spacing.size8,
      '& :nth-child(2)': {
        '& :first-child': {
          fontWeight: FontWeights.semibold,
          marginBlockStart: '0',
          marginBlockEnd: '0.25em',
        },
      },
    },
    toggle: {
      marginTop: spacing.size8,
    },
    imageWrapper: {
      width: '260px',
      backgroundColor: '#F5F8FA',
      cursor: 'pointer',
      [ResponsiveMedium]: {
        width: '100%',
        height: '180px',
      },
      [ResponsiveSmall]: {
        width: '100%',
        height: '180px',
      },
    },
    recommendationTag: {
      backgroundColor: palette.success,
      color: palette.green,
      width: 'fit-content',
      borderRadius: spacing.size4,
      padding: spacing.size6,
    },
    panelPending: {
      display: 'flex',
      gap: spacing.size6,
      alignItems: 'center',
      backgroundColor: palette.messageBarError,
      margin: `${spacing.size12} 0 ${spacing.size12} 0`,
      padding: `${spacing.size12} ${spacing.size12}`,
    },
    panelProjectIdApprovedSection: {
      display: 'flex',
      gap: spacing.size8,
      alignItems: 'center',
      margin: `${spacing.size8} 0 ${spacing.size4} 0`,
    },
    errorMessage: {
      fontSize: fonts.size12.fontSize,
      color: 'rgb(164, 38, 44)',
    },
  };
};

export const getClassNames = classNamesFunction<IClarityOptionsStyleProps, IClarityOptionsStyle>();
