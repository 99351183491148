import { Channel, listEntity, Publisher, UserContext } from '../../../../../@data';

export const fetchChannelById = async (publisher: Publisher, id: string, userContext?: UserContext): Promise<Channel> => {
  const list = await listEntity([publisher], userContext, Channel, new URLSearchParams([['ids', id]]));

  if (!list || !list.length) {
    throw Error('Cannot find channel Id');
  }

  return list[0];
};
