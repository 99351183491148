import { defineMessages } from 'react-intl';

export default defineMessages({
  headerLabel: {
    id: 'systemPreferences.headerLabel',
    defaultMessage: 'Locale',
  },
  headerAction: {
    id: 'systemPreferences.headerAction',
    defaultMessage: 'Edit',
  },
  panelHeaderText: {
    id: 'systemPreferences.panelHeaderText',
    defaultMessage: 'Edit system preferences',
  },
  updateLanguageFailed: {
    id: 'systemPreferences.languageUpdateFailed',
    defaultMessage: 'Failed to update user language. Please try again!',
  },
  invalidStateReLoginOrReport: {
    id: 'systemPreferences.invalidStateReLoginOrReport',
    defaultMessage: 'You have arrived in an invalid state, please re-login or report if issue persists.',
  },
});
