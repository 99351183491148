import { Dropdown, DropdownMenuItemType, IconButton, SelectionMode } from '@fluentui/react';
import React, { useState } from 'react';
import { useI18n } from '../../../../../../i18n/useI18n';
import { getAppStore } from '../../../../../@data';
import { IconTooltip } from '../../../../../components/IconTooltip/IconTooltip';
import {
  CommandBarActions,
  Direction,
  GridSelectionMode,
  GridV2,
  IBuildCommandBarProps,
  IGridPageCommandBarItemProps,
  IGridV2Column,
} from '../../../../../layout/GridPageV2';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../../utils';
import { getStore } from '../../../../ad-management/Account/Settings/@data/store/store';
import { TythonColumnsEnum } from '../../../@data/store/schema/ColumnsEnum';
import { default as reportMessages } from '../../../Report.messages';
import { EXCLUDED_FILTER_COLUMNS } from '../../../constants/common';
import { FormatMessageFuncType, getReportColumnMetadataDisplayName } from '../../../utils/intlHelper';
import { roundMetric } from '../../../utils/metricsFormatter';
import { IMetadataElement, ReportingDataRow, TythonReportingDataRow } from '../../@data/store/schema';
import {
  buildGridV2Columns,
  getIncompatibleBreakdownMessage,
  getIncompatibleColumnWithBreakdownAndFilterMessage,
  getIncompatibleColumnWithBreakdownMessage,
  getIncompatibleColumnWithFilterMessage,
} from '../../ReportCard/ReportCard.helpers';
import { ChartGrain } from '../../ReportCard/ReportCard.types';
import { IActiveFilter } from '../ActionBar/ActionBar';
import reportDetailsMessages from '../ReportDetailsPage.messages';
import { MenuItemWarningIcon } from '../WarningIcon/MenuItemWarningIcon';
import { getClassNames } from './ReportDetailsGrid.styles';

export interface IReportDetailsGridProps extends IRouterWithIntl {
  data: ReportingDataRow[];
  isDetailedDataLoaded: boolean;
  hasMinimumBreakdown: boolean;
  dimensions: IMetadataElement[];
  metrics: IMetadataElement[];
  currentChartGrain: ChartGrain;
  initialBreakdown: IMetadataElement[];
  defaultColumns: TythonColumnsEnum[];
  dateDimension: string;
  filters: IActiveFilter[];
  onBreakdownChanged: (newBreakdown: IMetadataElement[]) => void;
}
const unAllowedBreakdown = EXCLUDED_FILTER_COLUMNS;
const removeKey = 'remove';
export const ReportDetailsGrid = injectIntlAndObserver<IReportDetailsGridProps>(
  ({
    data,
    dimensions,
    metrics,
    isDetailedDataLoaded,
    hasMinimumBreakdown,
    initialBreakdown,
    defaultColumns,
    currentChartGrain,
    dateDimension,
    filters,
    history,
    match,
    location,
    onBreakdownChanged,
    intl: { formatMessage },
  }: IReportDetailsGridProps) => {
    const classNames = getClassNames();
    const { publisher, userContext } = getAppStore();
    const { systemPreferences } = getStore().active;
    const { locale } = useI18n({ systemPreferences, publisher, userContext });
    const dateTimeColumn = dimensions.find((value) => value.key === TythonColumnsEnum.DateTime || value.key === TythonColumnsEnum.Date);
    const addDateColumn = currentChartGrain !== ChartGrain.NONE && dateTimeColumn;
    const breakdownList = dimensions.filter((value) => !unAllowedBreakdown.includes(value.key as TythonColumnsEnum));
    const formattedData = formatDate(roundMetrics(data), currentChartGrain, dateDimension, locale);
    const [activeDimensions, setActiveDimensions] = useState<IMetadataElement[]>(initialBreakdown);
    const [metricColumns] = useState(
      addCompatibilityProps(buildGridV2Columns([], metrics, defaultColumns, formatMessage), filters, activeDimensions)
    );
    const [dimensionColumns] = useState(
      buildGridV2Columns(addDateColumn ? [dateTimeColumn, ...activeDimensions] : activeDimensions, [], defaultColumns, formatMessage)
    );
    const [gridColumns, setGridColumns] = useState(applyOrdering([...dimensionColumns, ...metricColumns]));

    function addCompatibilityProps(
      metricGridColumns: IGridV2Column[],
      activeFilters: IActiveFilter[],
      activeBreakdowns: IMetadataElement[] = []
    ) {
      return metricGridColumns.map((column) => {
        const metricMetadata = metrics.find((metric) => metric.key === column.key);
        const incompatibleFields = (metricMetadata && getIncompatibleBreakdown(metricMetadata, activeBreakdowns, formatMessage)) || [];
        const breakdownIncompatibleMessage = getIncompatibleColumnWithBreakdownMessage(
          getReportColumnMetadataDisplayName(column.key, formatMessage) || '',
          incompatibleFields[0]
        );
        const isBreakdownIncompatible = incompatibleFields.length > 0;
        const incompatibleFilters = (metricMetadata && getIncompatibleFilters(metricMetadata, activeFilters, formatMessage)) || [];
        const filterIncompatibleMessage = getIncompatibleColumnWithFilterMessage(
          getReportColumnMetadataDisplayName(column.key, formatMessage) || '',
          incompatibleFilters[0]
        );
        const filterAndBreakdownIncompatibleMessage = getIncompatibleColumnWithBreakdownAndFilterMessage(
          getReportColumnMetadataDisplayName(column.key, formatMessage) || '',
          incompatibleFilters[0]
        );
        const isFilterIncompatible = incompatibleFilters.length > 0;
        const isIncompatible = isBreakdownIncompatible || isFilterIncompatible;
        const incompatibleMessage =
          isBreakdownIncompatible && isFilterIncompatible
            ? filterAndBreakdownIncompatibleMessage
            : isBreakdownIncompatible
            ? breakdownIncompatibleMessage
            : filterIncompatibleMessage;
        return {
          ...column,
          incompatibleProps: {
            isIncompatible: isIncompatible,
            incompatibleCantMessage: isIncompatible ? formatMessage(incompatibleMessage.cantMessage, incompatibleMessage.params) : '',
            incompatibleWontMessage: isIncompatible ? formatMessage(incompatibleMessage.wontMessage, incompatibleMessage.params) : '',
          },
          suppressSorting: isIncompatible,
          onRenderHeader: (props, defaultRender) => {
            return isIncompatible ? (
              <div className={classNames.inCompatibleColumnWrapper}>
                <span>{props?.column.name}</span>
                <IconTooltip
                  tooltipMessage={isIncompatible ? formatMessage(incompatibleMessage.cantMessage, incompatibleMessage.params) : ''}
                  iconName={'warning'}
                />
              </div>
            ) : (
              defaultRender(props)
            );
          },
        };
      });
    }
    const getBreakdownComponent = () => {
      return (
        <div className={classNames.breakdownWrapper}>
          <div className={classNames.breakdownText}>{formatMessage(reportMessages.breakDownBy)}</div>
          {activeDimensions.map((activeDimension, index) => {
            return (
              <Dropdown
                key={index}
                selectedKey={activeDimension.key}
                onChange={(event, item) => {
                  let newActiveDimensions;
                  if (!item) {
                    return;
                  }
                  if (item.key === removeKey) {
                    newActiveDimensions = activeDimensions.filter((active) => {
                      return active.key !== activeDimension.key;
                    });
                  } else {
                    activeDimensions[index] = {
                      displayName: item.text,
                      key: String(item.key),
                      filterKey: '',
                      incompatibleFields: activeDimension.incompatibleFields,
                    };
                    newActiveDimensions = activeDimensions;
                  }
                  onBreakdownChanged([...newActiveDimensions]);
                  setActiveDimensions([...newActiveDimensions]);
                  setGridColumns(
                    applyOrdering([
                      ...buildGridV2Columns(
                        addDateColumn ? [dateTimeColumn, ...newActiveDimensions] : newActiveDimensions,
                        [],
                        defaultColumns,
                        formatMessage
                      ),
                      ...addCompatibilityProps(metricColumns, filters, newActiveDimensions),
                    ])
                  );
                }}
                onRenderOption={(option) => {
                  return (
                    <>
                      <MenuItemWarningIcon
                        text={option?.text || ''}
                        warningMessage={option?.data?.incompatibleMessage || ''}
                        isIncompatible={option?.data?.isIncompatible || false}
                      />
                    </>
                  );
                }}
                options={[
                  {
                    key: removeKey,
                    text: formatMessage(reportDetailsMessages.removeBreakdown),
                    disabled: hasMinimumBreakdown && activeDimensions.length <= 1,
                    title: formatMessage(reportDetailsMessages.removeBreakdown),
                  },
                  { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
                  ...breakdownList.map((breakdown) => {
                    const incompatibleMetrics = getIncompatibleFields(breakdown, gridColumns);
                    const incompatibleMessage = getIncompatibleBreakdownMessage(
                      incompatibleMetrics[0],
                      getReportColumnMetadataDisplayName(breakdown.key, formatMessage)
                    );

                    return {
                      key: breakdown.key,
                      text: getReportColumnMetadataDisplayName(breakdown.key, formatMessage),
                      disabled: !!activeDimensions.find((active) => active.key === breakdown.key),
                      data: {
                        isIncompatible: incompatibleMetrics.length > 0,
                        incompatibleMessage: formatMessage(incompatibleMessage.message, incompatibleMessage.params),
                      },
                    };
                  }),
                ]}
                dropdownWidth={'auto'}
              />
            );
          })}
          {activeDimensions.length < breakdownList.length && (
            <IconButton
              menuProps={{
                items: breakdownList
                  .filter((dimension) => !activeDimensions.find((active) => active.key === dimension.key))
                  .map((dimension) => {
                    const incompatibleMetrics = getIncompatibleFields(dimension, gridColumns);

                    const incompatibleMessage = getIncompatibleBreakdownMessage(
                      incompatibleMetrics[0],
                      getReportColumnMetadataDisplayName(dimension.key, formatMessage)
                    );
                    return {
                      key: dimension.key,
                      text: getReportColumnMetadataDisplayName(dimension.key, formatMessage),
                      onClick: () => {
                        activeDimensions.push(dimension);
                        setActiveDimensions([...activeDimensions]);
                        onBreakdownChanged([...activeDimensions]);
                        setGridColumns(
                          applyOrdering([
                            ...buildGridV2Columns(
                              addDateColumn ? [dateTimeColumn, ...activeDimensions] : activeDimensions,
                              [],
                              defaultColumns,
                              formatMessage
                            ),
                            ...addCompatibilityProps(metricColumns, filters, activeDimensions),
                          ])
                        );
                      },
                      onRenderContent: (props) => {
                        return (
                          <MenuItemWarningIcon
                            text={props.item.text || ''}
                            warningMessage={formatMessage(incompatibleMessage.message, incompatibleMessage.params)}
                            isIncompatible={incompatibleMetrics.length > 0}
                          />
                        );
                      },
                    };
                  }),
              }}
              iconProps={{ iconName: 'Add' }}
              ariaLabel="Add"
            />
          )}
        </div>
      );
    };
    const commandBarProps: IBuildCommandBarProps<TythonReportingDataRow> = {
      includeActions: [CommandBarActions.custom],
      includeFarActions: [CommandBarActions.searchBox, CommandBarActions.modifyColumns],
      hideModifyColumnsDescription: true,
      getCustomCommandBarItems: () => {
        const commandBarItems: IGridPageCommandBarItemProps[] = [
          {
            key: 'breakdown',
            placement: { direction: Direction.before },
            commandBarButtonAs: getBreakdownComponent,
            selectionModes: [GridSelectionMode.None],
          },
        ];

        return commandBarItems;
      },
    };

    return (
      <div>
        <GridV2
          entity={ReportingDataRow}
          dataGridProps={{
            shimmerLines: 10,
            selectionMode: SelectionMode.none,
            renderEmptyGrid: true,
            noEntityFound: reportMessages.noData,
            data: redactIncompatibleFields(gridColumns, formattedData),
            gridV2Columns: gridColumns,
            onColumnsChanged(columns) {
              setGridColumns(columns);
            },
          }}
          commandBarProps={commandBarProps}
          isGridReady={isDetailedDataLoaded}
          paginationProps={{
            shouldGridHandlePagination: true,
          }}
          history={history}
          location={location}
          match={match}
        />
      </div>
    );
  }
);

function redactIncompatibleFields(gridColumns: IGridV2Column[], data: ReportingDataRow[]) {
  const incompatibleFields = gridColumns
    .filter((column) => {
      return column.incompatibleProps?.isIncompatible && column.isVisible;
    })
    .map((column) => column.key);
  return data.map((row) => {
    const newRow = { ...row };
    for (const field of incompatibleFields) {
      newRow[field] = '--';
    }
    return newRow;
  });
}

function getIncompatibleFields(field: IMetadataElement, gridColumns: IGridV2Column[]) {
  const incompatibleSet = new Set(field.incompatibleFields);
  const incompatibleFields = gridColumns
    .filter((column) => {
      return incompatibleSet.has(column.key as TythonColumnsEnum) && column.isVisible;
    })
    .map((column) => column.name);
  return incompatibleFields;
}
function getIncompatibleFilters(field: IMetadataElement, filters: IActiveFilter[], formatMessage: FormatMessageFuncType) {
  const incompatibleSet = new Set(field.incompatibleFields);
  const incompatibleFields = filters
    .filter((filter) => {
      return incompatibleSet.has(filter.key as TythonColumnsEnum);
    })
    .map((filter) => getReportColumnMetadataDisplayName(filter.key, formatMessage));
  return incompatibleFields;
}
function getIncompatibleBreakdown(field: IMetadataElement, breakdownList: IMetadataElement[], formatMessage: FormatMessageFuncType) {
  const incompatibleSet = new Set(field.incompatibleFields);
  const incompatibleFields = breakdownList
    .filter((breakdown) => {
      return incompatibleSet.has(breakdown.key as TythonColumnsEnum);
    })
    .map((breakdown) => getReportColumnMetadataDisplayName(breakdown.key, formatMessage));
  return incompatibleFields;
}

function applyOrdering(columns: IGridV2Column[]): IGridV2Column[] {
  return columns.map((column, index) => {
    column.columnOrder = index;
    return column;
  });
}

export function roundMetrics(data: ReportingDataRow[]) {
  return data.map((row) => {
    const newRow = { ...row };
    for (const field of Object.keys(row)) {
      newRow[field] = roundMetric(TythonColumnsEnum[field], row[field], true);
    }
    return newRow;
  });
}
export function formatDate(data: TythonReportingDataRow[], currentChartGrain: ChartGrain, dateDimension: string, locale) {
  return data.map((row) => {
    if (row[dateDimension]) {
      const newRow = { ...row };
      const options: Intl.DateTimeFormatOptions = {};
      currentChartGrain === ChartGrain.HOURLY && (options.hour = '2-digit');
      if (currentChartGrain === ChartGrain.MONTHLY || currentChartGrain === ChartGrain.MONTH_AND_YEAR) {
        options.month = 'short';
        options.year = 'numeric';
      }
      currentChartGrain === ChartGrain.YEARLY && (options.year = 'numeric');
      const dateValue = new Date(row[dateDimension]).toLocaleDateString(locale, options);
      newRow[dateDimension] = dateValue;
      return newRow;
    }
    return row;
  });
}
