import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../theme';
import { ICommonStyleProps } from '../../../../types';

type StyleProperties =
  | 'calenderContainer'
  | 'calenderRangeSelectorsContainer'
  | 'calenderRangeSelector'
  | 'calenderRangeSelectedSelector'
  | 'calenderRangeSelectorDateContainer'
  | 'calenderRangeSelectorDate'
  | 'calenderButton'
  | 'calender'
  | 'calenderHeader'
  | 'calenderMonthLabel'
  | 'calenderNavigator'
  | 'calenderBody'
  | 'actions'
  | 'daysGrid';

type CustomCalenderStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): CustomCalenderStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return mergeStyleSets({
    calenderContainer: {
      display: 'flex',
    },
    calenderRangeSelectorsContainer: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: `3px solid ${palette.themePrimary}`,
      borderRight: '1px solid #D6D6D6',
    },
    calenderRangeSelectorDateContainer: {
      padding: spacing.size10,
    },
    calenderRangeSelectorDate: {
      padding: '5px 20px 5px 10px',
      border: '1px solid #757575',
      borderRadius: 2,
      userSelect: 'none',
    },
    calenderRangeSelector: {
      padding: spacing.size10,
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        color: palette.white,
        background: palette.themePrimary,
      },
    },
    calenderRangeSelectedSelector: {
      color: palette.white,
      background: palette.themePrimary,
    },
    calenderButton: {
      display: 'flex',
      alignItems: 'center',
      color: '#757575',
      gap: spacing.size10,
      width: 'fit-content',
      padding: spacing.size6,
      border: '1px solid #757575',
      borderRadius: 2,
      cursor: 'pointer',
      userSelect: 'none',
    },
    calender: {
      width: '450px',
      display: 'grid',
      gap: spacing.size10,
      textAlign: 'center',
      alignItems: 'center',
      padding: spacing.size16,
    },
    calenderHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    calenderNavigator: {
      borderRadius: 2,
      cursor: 'pointer',
      userSelect: 'none',
      padding: spacing.size6,
      '&:hover': {
        color: palette.white,
        background: palette.themePrimary,
      },
    },
    calenderMonthLabel: {
      flexGrow: 1,
      userSelect: 'none',
    },
    calenderBody: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    daysGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      userSelect: 'none',
    },
    actions: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'end',
    },
  });
};

export function getClassNames<PropType>(props?: PropType): CustomCalenderStyles {
  return getStyles({ theme: getTheme(), ...props });
}
