import { BaseButton, Text } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { isTythonSelfServeUser } from '../../@data';
import { APP_NAME_NEW, APP_NAME_PUBCENTER } from '../../constants/AppConstants';
import { classNames } from '../../pages/ad-management/Account/Payments/Onboarding/PaymentsOnboarding';
import { daysAheadOfToday } from '../../utils';
import messages from './TnCModal.messages';
import { ITnCNotificationProps } from './TnCModal.types';

const TnCNotificationComponent = injectIntl<InjectedIntlProps & ITnCNotificationProps>(
  ({ onAction, latestTnCReleaseDate, signatureDate, intl: { formatMessage } }) => (
    <div className={classNames.setupNotificationWrapper}>
      <Text className={classNames.tncNotificationText}>
        {formatMessage(messages.tncNotificationContent, {
          latestTnCReleaseDate: latestTnCReleaseDate
            ? // TODO: add i18n migration once feature is ready
              new Date(latestTnCReleaseDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
            : formatMessage(messages.unknown),
          buffer:
            signatureDate && daysAheadOfToday(signatureDate) >= 0
              ? formatMessage(messages.dayBuffer, { buffer: daysAheadOfToday(signatureDate) })
              : formatMessage(messages.twoWeekBuffer),
          appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER,
        })}
      </Text>
      <BaseButton className={classNames.actionButton} onClick={onAction}>
        {formatMessage(messages.goToAccept)}
      </BaseButton>
    </div>
  )
);

export const TnCNotification = (props: ITnCNotificationProps) => <TnCNotificationComponent {...props} />;
