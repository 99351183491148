import { mutator } from 'satcheljs';
import { getAppStore, onEntityGridColumnsChanged } from '..';

mutator(onEntityGridColumnsChanged, (msg) => {
  const store = getAppStore();
  const entityGridColumns = store.entityGridColumns || [];
  const existingEntityColumns = entityGridColumns.find((entityGridColumn) => entityGridColumn.entity === msg.entityColumnsData.entity);

  if (existingEntityColumns) {
    existingEntityColumns.gridColumns = msg.entityColumnsData.gridColumns;
  } else {
    store.entityGridColumns = [...entityGridColumns, msg.entityColumnsData];
  }
});
