import { SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { User, getAppStore } from '../../../../../@data';
import AppMessages from '../../../../../App.messages';
import { ZeroResults } from '../../../../../components/ZeroResults/ZeroResults';
import { useCanvasTextWidth, useWindowSize } from '../../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../../hooks/useWindowSize/store/store';
import { GridV2, IGridV2Column } from '../../../../../layout/GridPageV2';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../../utils';
import { getStore } from '../../@data';
import { onManagePageInitialized, onUsersChanged } from '../../@data/actions';
import messages from '../ManagePage.messages';
import { DevTokenPanel } from './components/DevTokenPanel';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

export const UsersGrid = injectIntlWithObserver((props: IRouterWithIntl) => {
  const { publisher } = getAppStore();
  const { users, isFetchingUsersLoading, isFetchingUsers } = getStore();
  const { formatMessage } = props.intl;
  const { windowStoreSerializer } = useWindowSize();
  const { isWindowRegularSize } = windowStoreSerializer(getWindowSizeStore());

  const longestEmail = useMemo(() => users?.map((user) => user.email || '').reduce((a, b) => (a.length > b.length ? a : b), '') || '', [
    users,
  ]);
  const gridDefaultColumns: IGridV2Column[] = getGridColumns(
    formatMessage,
    isWindowRegularSize,
    props.history,
    useCanvasTextWidth(longestEmail)
  );

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={!!publisher ? formatMessage(messages.zeroResultTitleUser) : undefined}
      description={formatMessage(!!publisher ? messages.notFound : messages.noPublisherSelected)}
    />
  );

  useEffect(() => {
    onManagePageInitialized(gridDefaultColumns);
    // eslint-disable-next-line
  }, [publisher]);

  return (
    <>
      <GridV2
        {...props}
        entity={User}
        pageTitle={{ title: formatMessage(AppMessages.users), helpId: 'pop_PCv4_Users' }}
        commandBarProps={getCommandBarItems(formatMessage)}
        dataGridProps={{
          gridV2Columns: gridDefaultColumns,
          data: publisher ? users || [] : [],
          setData: onUsersChanged,
          shimmerLines: 10,
          selectionMode: SelectionMode.single,
          renderZeroItemsComponent: NoResultComponent,
        }}
        isGridReady={!isFetchingUsersLoading}
        isDataLoading={isFetchingUsers}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
      <DevTokenPanel {...props} />
    </>
  );
});
