import { IPubcenterSpacing } from '../../../../theme';
import { IFormItemStyleProps, IFormItemStyles } from './FormItem.types';

export const getStyles = (props: IFormItemStyleProps): IFormItemStyles => {
  const { formWidth, theme } = props;
  const DefaultFormWidth = 220;
  const spacing = theme.spacing as IPubcenterSpacing;
  return {
    root: [
      'ms-Form-Item',
      {
        display: 'block',
        width: formWidth || DefaultFormWidth,
        marginBottom: spacing.size16
      }
    ],
  };
};
