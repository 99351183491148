import { IPubcenterPalette, IPubcenterSpacing } from '../../../../theme';
import { IGlobalSearchResultPageStyleProps, IGlobalSearchResultPageStyles } from './GlobalSearchResultPage.types';

export const getStyles = (props: IGlobalSearchResultPageStyleProps): IGlobalSearchResultPageStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const pallete = theme.palette as IPubcenterPalette;
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      maxHeight: '95vh',
      flex: 1,
      backgroundColor: theme.palette.white
    },
    header: [
      'ms-fontWeight-semibold',
      {
        display: 'flex',
        fontSize: `calc(${spacing.size16} + ${spacing.size4})`,
        paddingTop: spacing.size32,
        paddingBottom: spacing.size24
      }
    ],
    filter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    filterContent: {
      display: 'flex',
      paddingRight: spacing.size24
    },
    clearFilterButton: {
      display: 'flex',
      color: pallete.themeDarkAlt,
      marginLeft: 'auto',
      selectors: {
        ':hover': {
          color: pallete.blueDark
        }
      }
    },
    navBackButton: [
      'ms-navBackButton',
      {
        display: 'flex',
        color: pallete.themeDarkAlt,
        backgroundColor: theme.palette.neutralLighter,
        height: spacing.size44,
        alignItems: 'center',
        selectors: {
          ':hover': {
            color: pallete.blueDark
          }
        }
      }
    ],
    scrollablePanel: {
      borderBottomStyle: 'solid',
      borderColor: theme.palette.neutralLight,
      borderBottomWidth: 1,
      overflowY: 'auto',
      flex: 1
    },
    pageContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: spacing.size44,
      paddingRight: spacing.size44,
      paddingBottom: spacing.size44
    },
  };
};
