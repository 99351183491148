import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { NotificationSeverity, onNotificationCardAdded, showErrorToastNotification } from '../../../../@data';
import { getCurrentPublisher, getPublisherEvaluationStatus, isPublisherRejectionAppealable } from '../../../../@data/selectors';
import { AccountPageRoute } from '../../../../Routes';
import messages from '../../Account/AccountPage.messages';
import ProfileCardMessages from '../../Home/components/ProfileCard/ProfileCard.messages';
import { fetchAppealHistory } from '../../Profile/@data/actions';
import { setPublisherEvaluationFetchFailedAction, setPublisherEvaluationStatusAction } from '../actions/entityEvaluationMutatorActions';
import { fetchPublisherEvaluationStatusAction } from '../actions/publisherEvaluationActions';
import { fetchPublisherEvaluationStatus } from '../services/publisherEvaluationService';

/**
 * This orchestrator is for the whole ad-management module as any page of the
 * ad-management module can trigger this action.
 */

orchestrator(fetchPublisherEvaluationStatusAction, async ({ cache }) => {
  try {
    const publisher = getCurrentPublisher();
    if (!publisher) {
      return;
    }

    if (cache && !!getPublisherEvaluationStatus()) {
      return;
    }

    const evaluationResponse = await fetchPublisherEvaluationStatus(publisher);
    setPublisherEvaluationStatusAction(evaluationResponse);
    setPublisherEvaluationFetchFailedAction(false);

    if (isPublisherRejectionAppealable()) {
      fetchAppealHistory();

      onNotificationCardAdded({
        id: 'publisherProfileNeedsAttention',
        titleMessageDescriptor: ProfileCardMessages.retryTitle,
        contentMessageDescriptor: ProfileCardMessages.retryDescription,
        severity: NotificationSeverity.Warning,
        primaryActionTextMessageDescriptor: ProfileCardMessages.viewProfile,
        primaryActionLink: `/ad-management/${publisher?.id}${AccountPageRoute}#profile`,
      });
    }
  } catch (error) {
    setPublisherEvaluationFetchFailedAction(true);
    showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadPublisherStatus });
    Log.error(`Failed to fetch publisher status. Please retry after sometime.: ${error.message}}`);
  }
});
