import { defineMessages } from 'react-intl';

export default defineMessages({
  resourceTitle: {
    id: 'resource.title',
    defaultMessage: 'Resources',
  },
  learnTitle: {
    id: 'resource.learnTitle',
    defaultMessage: 'Learn about monetizing your site.',
  },
  learnDescription: {
    id: 'resource.learnDescription',
    defaultMessage: 'Visit our documentation center.',
  },
  assistanceTitle: {
    id: 'resource.assistanceTitle',
    defaultMessage: 'Need additional assistance?',
  },
  assistanceDescription: {
    id: 'resource.assistanceDescription',
    defaultMessage: 'Contact Support',
  },
  improveTitle: {
    id: 'resource.improveTitle',
    defaultMessage: 'Help us improve {appName}',
  },
  improveDescription: {
    id: 'resource.improveDescription',
    defaultMessage: 'Share your thoughts and ideas',
  },
  feedbackTitle: {
    id: 'resource.feedbacktitle',
    defaultMessage: 'Feedback to {appName}',
  },
});
