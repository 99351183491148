import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubcenterTheme } from '../../../theme';
import { ICommonStyleProps } from '../../types';
import { flexWithAlignCenter, flexWithJustifyBetween } from '../../utils/GlobalStyleUtils';

type StyleProperties =
  | 'containerStyle'
  | 'headerRowStyle'
  | 'headingTitleStyle'
  | 'headingIconStyle'
  | 'sectionStyle'
  | 'sectionTitleStyle'
  | 'contentRowStyle'
  | 'contentLabelStyle'
  | 'contentLabelStyleWithColor'
  | 'sectionContentStyle'
  | 'bodyContainerStyle'
  | 'actionStyle';
export type SummaryCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SummaryCardStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, fonts } = theme;
  const { ResponsiveSmall } = BreakPoints;

  return mergeStyleSets({
    containerStyle: {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)',
      borderRadius: spacing.size6,
      marginBottom: spacing.size16,
    },
    headingTitleStyle: {
      ...fonts.size16,
      margin: 0,
      fontWeight: FontWeights.semibold,
      ...flexWithAlignCenter,
    },
    headingIconStyle: {
      fontSize: fonts.size14.fontSize,
      height: spacing.size16,
      width: spacing.size16,
      marginRight: spacing.size8,
    },
    headerRowStyle: {
      padding: `0 ${spacing.size24}`,
      height: spacing.size54,
      ...flexWithJustifyBetween,
      borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
    },
    sectionStyle: {
      '&:not(:last-child)': {
        marginBottom: spacing.size12,
      },
      width: '50%',
    },
    sectionTitleStyle: {
      ...fonts.size14Medium,
      margin: 0,
      [ResponsiveSmall]: {
        overflowWrap: 'break-word',
      },
    },
    contentRowStyle: {
      display: 'flex',
      marginBottom: spacing.size4,
      ...fonts.size14,
      [ResponsiveSmall]: {
        display: 'block',
      },
    },
    contentLabelStyle: {
      opacity: 0.6,
      minWidth: 200,
      color: theme.palette.black,
    },
    contentLabelStyleWithColor: {
      opacity: 0.6,
      minWidth: 200,
      color: theme.palette.eerieBlack,
    },
    sectionContentStyle: {
      margin: 0,
      ...fonts.size14,
      [ResponsiveSmall]: {
        overflowWrap: 'break-word',
      },
    },
    bodyContainerStyle: {
      padding: `${spacing.size12} ${spacing.size24}`,
      display: 'flex',
      flexWrap: 'wrap',
    },
    actionStyle: {},
  });
};

export function getClassNames<PropType>(props?: PropType): SummaryCardStyles {
  return getStyles({ theme: getTheme(), ...props });
}
