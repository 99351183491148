import { ResourceType } from '../../../../../../@data/store/schema/enums/ResourceType';
import { IGlobalSearchResult } from '../../../../../../@data/store/schema/interfaces/IGlobalSearchResult';
import { SearchResult } from './SearchResult';

export class AdFilter extends SearchResult {
  public numberOfExcludedURLs?: string;
  public numberOfExcludedKeywords?: string;
  private _item: IGlobalSearchResult;

  constructor(item?: IGlobalSearchResult) {
    super(item && item.resourceName, item && item.resourceId, 'Filter');
    if (item) {
      this.numberOfExcludedURLs = item.propertyBag && item.propertyBag.NumberOfExcludedURLs;
      this.numberOfExcludedKeywords = item.propertyBag && item.propertyBag.NumberOfExcludedKeywords;
      this._item = item;
    }
  }

  public getPublisherId(): string {
    if (this._item && this._item.propertyBag && this._item.propertyBag.PublisherId) {
      return this._item.propertyBag.PublisherId;
    }

    return '';
  }

  public getAccountId(): string {
    if (this._item && this._item.propertyBag && this._item.propertyBag.AccountId) {
      return this._item.propertyBag.AccountId;
    }

    return '';
  }

  public getResourceType(): string {
    return ResourceType[ResourceType.Filter];
  }
}
