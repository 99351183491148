import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'property.create',
    defaultMessage: 'Create Property',
  },
  siteCreate: {
    id: 'property.create',
    defaultMessage: 'Create Site',
  },
  update: {
    id: 'property.update',
    defaultMessage: 'Update property',
  },
  notFound: {
    id: 'property.notFound',
    defaultMessage: 'No properties found for this account, create one.',
  },
  siteNotFound: {
    id: 'property.notFound',
    defaultMessage: 'No sites found for this account, create one.',
  },
  noPublisherSelected: {
    id: 'property.noPublisherSelected',
    defaultMessage: 'A publisher isn’t selected yet. To start managing properties, search for a publisher..',
  },
  deactivateTitle: {
    id: 'property.deactivateTitle',
    defaultMessage: 'Do you want to deactivate property(s)?',
  },
  deactivateSiteTitle: {
    id: 'property.deactivateTitle',
    defaultMessage: 'Do you want to deactivate site(s)?',
  },
  name: {
    id: 'property.name',
    defaultMessage: 'Property name',
  },
  siteName: {
    id: 'property.name',
    defaultMessage: 'Site Name',
  },
  id: {
    id: 'property.id',
    defaultMessage: 'Property ID',
  },
  siteId: {
    id: 'property.id',
    defaultMessage: 'Site ID',
  },
  url: {
    id: 'property.url',
    defaultMessage: 'Website URL',
  },
  approvalStatus: {
    id: 'property.approvalStatus',
    defaultMessage: 'Approval status',
  },
  issues: {
    id: 'property.issues',
    defaultMessage: 'Issues',
  },
  properties: {
    id: 'property.properties',
    defaultMessage: 'Properties',
  },
  sites: {
    id: 'property.sites',
    defaultMessage: 'Sites',
  },
  sitesCannotServeMessage: {
    id: 'property.sitesCannotServeMessage',
    defaultMessage: 'Sites cannot serve ads until your profile is submitted and approved {learn}',
  },
  clarityStatus: {
    id: 'property.clarityStatus',
    defaultMessage: 'Clarity status',
  },
  clarityPending: {
    id: 'property.clarityPending',
    defaultMessage: 'Pending',
  },
  clarityPendingApproval: {
    id: 'property.clarityPendingApproval',
    defaultMessage: 'Pending Approval',
  },
  clarityRejected: {
    id: 'property.clarityRejected',
    defaultMessage: 'Rejected',
  },
  clarityNotConnected: {
    id: 'property.clarityNotConnected',
    defaultMessage: 'Not Connected',
  },
  clarityApproved: {
    id: 'property.clarityApproved',
    defaultMessage: 'Approved',
  },
  clarityExpired: {
    id: 'property.clarityExpired',
    defaultMessage: 'Expired',
  },
  clarityCancelled: {
    id: 'property.clarityCancelled',
    defaultMessage: 'Cancelled',
  },
  clarityNotFound: {
    id: 'property.clarityNotFound',
    defaultMessage: 'Not Found',
  },
  clarityLinkStatusCalloutTitle: {
    id: 'property.clarityLinkStatusCalloutTitle',
    defaultMessage: 'Microsoft Clarity Project',
  },
  clarityLinkStatusCalloutProjectStatus: {
    id: 'property.clarityLinkStatusCalloutTitle',
    defaultMessage: '{id}: Link request {status}',
  },
  clarityLinkPendingStatus: {
    id: 'property.clarityLinkPendingStatus',
    defaultMessage: 'Pending Approval',
  },
  clarityLinkRejectedStatus: {
    id: 'property.clarityLinkRejectedStatus',
    defaultMessage: ' Request rejected',
  },
  clarityLinkCancelledStatus: {
    id: 'property.clarityLinkCancelledStatus',
    defaultMessage: 'Cancelled',
  },
  clarityLinkExpiredStatus: {
    id: 'property.clarityLinkExpiredStatus',
    defaultMessage: 'Request expired',
  },
  clarityLinkPendingStatusCalloutDescription: {
    id: 'property.clarityLinkPendingStatusCalloutDescription',
    defaultMessage: 'Request to link your Clarity project pending on Clarity Admin.',
  },
  clarityLinkRejectStatusCalloutDescription: {
    id: 'property.clarityLinkRejectStatusCalloutDescription',
    defaultMessage: 'Request to connect Clarity project was rejected',
  },
  clarityLinkCancelledStatusCalloutDescription: {
    id: 'property.clarityLinkCancelledStatusCalloutDescription',
    defaultMessage: 'Linked Clarity project does not exist.',
  },
  clarityLinkExpiredStatusCalloutDescription: {
    id: 'property.clarityLinkExpiredStatusCalloutDescription',
    defaultMessage: ' Request to link your Clarity project expired.',
  },
  clarityLinkNotConnectedStatusCalloutDescription: {
    id: 'property.clarityLinkNotConnectedStatusCalloutDescription',
    defaultMessage: 'Request to link your Clarity project. Click below send the link request.',
  },
  clarityLinkNotFoundStatusCalloutDescription: {
    id: 'property.clarityLinkNotFoundStatusCalloutDescription',
    defaultMessage: 'Clarity hasn’t been added to this site. Clarity can be added from site edit screen.',
  },
  clarityLinkStatusCalloutResendAction: {
    id: 'property.clarityLinkStatusCalloutResendAction',
    defaultMessage: 'Resend link request',
  },
  clarityLinkStatusRejected: {
    id: 'property.clarityLinkStatusRejected',
    defaultMessage: 'Link request rejected',
  },
  clarityLinkStatusPendingApproval: {
    id: 'property.clarityLinkStatusPendingApproval',
    defaultMessage: 'Pending Approval',
  },
  clarityProject: {
    id: 'property.clarityProject',
    defaultMessage: 'Clarity project ID',
  },
  clarityProjectInfo: {
    id: 'property.clarityProjectInfo',
    defaultMessage: 'This column shows your bundled Clarity ID.',
  },
  servingStatus: {
    id: 'property.servingStatus',
    defaultMessage: 'Serving status',
  },
  status: {
    id: 'property.status',
    defaultMessage: 'Status',
  },
  actions: {
    id: 'property.actions',
    defaultMessage: 'Actions',
  },
  zeroResultTitleNonTython: {
    id: 'property.titleNonTython',
    defaultMessage: 'No properties yet',
  },
  zeroResultTitle: {
    id: 'property.title',
    defaultMessage: 'No sites yet',
  },
  zeroResultDescription: {
    id: 'property.description',
    defaultMessage: 'You haven’t created any site yet',
  },
  zeroResultDescriptionNonTython: {
    id: 'property.descriptionNonTython',
    defaultMessage: 'You haven’t created any property yet',
  },
  zeroResultcallToAction: {
    id: 'property.callToAction',
    defaultMessage: 'Create site',
  },
  clarityTour: {
    id: 'property.clarityTour',
    defaultMessage: 'Clarity Tour',
  },
  clarityTourSecondPageHeader: {
    id: 'property.clarityTourSecondPageHeader',
    defaultMessage: 'Jump to Clarity with one click',
  },
  clarityTourSecondPageDesc: {
    id: 'property.clarityTourSecondPageDesc',
    defaultMessage: 'When you want to check the site performance in Clarity, clicking on the Clarity project id from here.',
  },
  statusTourFirstPageHeader: {
    id: 'property.statusTourFirstPageHeader',
    defaultMessage: 'Site Status',
  },
  statusTourFirstPageDesc: {
    id: 'property.statusTourFirstPageDesc',
    defaultMessage:
      'This column will show each sites serving status. If you view one of these sites in another context, you can hover or click on the tag to get more information.',
  },
  statusTourSecondPageHeader: {
    id: 'property.statusTourSecondPageHeader',
    defaultMessage: 'Issues',
  },
  statusTourSecondPageDesc: {
    id: 'property.statusTourSecondPageDesc',
    defaultMessage:
      'Any issues with your sites will appear here. Blocking issues are shown with a red dot while non-blocking issues are shown with an orange dot.',
  },
  clarityBenefits: {
    id: 'property.clarityBenefits',
    defaultMessage: 'What more can you do in Clarity:',
  },
  clarityFirstBenefit: {
    id: 'property.clarityFirstBenefit',
    defaultMessage: 'Clarity masks all sensitive content on your site by default. Review your settings',
  },
  claritySecondBenefit: {
    id: 'property.claritySecondBenefit',
    defaultMessage: 'Check our all Clarity features with our',
  },
  claritySecondBenefitRedirection: {
    id: 'property.claritySecondBenefitRedirection',
    defaultMessage: ' live demo',
  },
  clarityThirdBenefit: {
    id: 'property.clarityThirdBenefit',
    defaultMessage: 'Add site and',
  },
  clarityThirdBenefitRedirection: {
    id: 'property.clarityThirdBenefitRedirection',
    defaultMessage: 'privacy disclosures',
  },
  siteServingAds: {
    id: 'property.siteServingAds',
    defaultMessage: 'Site is currently serving ads',
  },
  siteServingAdsWithoutAdsTxt: {
    id: 'property.siteServingAdsWithoutAdsTxt',
    defaultMessage: 'Site performance could be enhanced by adding Ads.txt. {more}',
  },
  siteServingAdsWithoutScript: {
    id: 'property.siteServingAdsWithoutScript',
    defaultMessage: 'Site is ready to show ads. Please add {snippet} in your webpage',
  },
  sitePendingWithApprovedProfile: {
    id: 'property.sitePendingWithApprovedProfile',
    defaultMessage:
      'Your site is being reviewed. The review takes seven business days, and you will be notified by email once the review is approved.',
  },
  sitePendingWithoutApprovedProfile: {
    id: 'property.sitePendingWithoutApprovedProfile',
    defaultMessage: 'Your site review is pending. We will review your site after your profile is approved',
  },
  siteRejected: {
    id: 'property.siteRejected',
    defaultMessage:
      'This site has been rejected. Please make sure that it follows {guidelines}. If you update your site and would like it to be reviewed again, please delete this site and add it to {appName} again as a new site.',
  },
  siteNotServingIssuesHeader: {
    id: 'property.siteNotServingIssuesHeader',
    defaultMessage: 'Issues with this site:',
  },
  siteNotServingPubCenterScriptInActive: {
    id: 'property.siteNotServingPubCenterScriptInActive',
    defaultMessage: 'Script not found',
  },
  siteNotServingSiteIsRejected: {
    id: 'property.siteNotServingSiteIsRejected',
    defaultMessage: 'Site is rejected',
  },
  siteNotServingWithoutAdsTxt: {
    id: 'property.siteNotServingWithoutAdsTxt',
    defaultMessage: 'Ads.txt is not found',
  },
  codeSnippet: {
    id: 'property.codeSnippet',
    defaultMessage: 'code snippet',
  },
  siteApprovalGuideLines: {
    id: 'property.siteApprovalGuideLines',
    defaultMessage: 'site approval guidelines',
  },
  approvalStatusPendingInfo: {
    id: 'property.approvalStatusPendingInfo',
    defaultMessage: 'Pending: Your site is awaiting verification to be approved.',
  },
  approvalStatusApprovalInfo: {
    id: 'property.approvalStatusApprovalInfo',
    defaultMessage: 'Approved: Your site is ready to show ads.',
  },
  approvalStatusRejectedInfo: {
    id: 'property.approvalStatusRejectedInfo',
    defaultMessage: 'Rejected: Your site is reviewed and not approved.',
  },
  approvalStatusInfoHelp: {
    id: 'property.approvalStatusInfoHelp',
    defaultMessage: 'If you need more help, please visit {guidelines} or {support}.',
  },
  approvalStatusInfoGuideLines: {
    id: 'property.approvalStatusInfoGuideLines',
    defaultMessage: 'site approval guidelines',
  },
  approvalStatusInfoSupport: {
    id: 'property.approvalStatusInfoSupport',
    defaultMessage: 'contact support',
  },
  deletePropertyPopupTitle: {
    id: 'property.deletePopupApproval',
    defaultMessage: 'Are you sure you want to delete this site?',
  },
  deletePropertyPopupDescription: {
    id: 'property.deletePopupDescription',
    defaultMessage: 'By deleting this site, you’ll unlink the ad units associated with it',
  },
});
