import { Dropdown, MessageBar, Pivot, PivotItem, Stack, Text, TextField, css, getTheme } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import Accordion from '../../../../../../components/Accordion';
import { ColorPicker } from '../../../../../../components/ColorPicker/ColorPicker';
import { FormLabel } from '../../../../../../components/Form/FormLabel';
import { useCMUIHelpExperience } from '../../../../../../hooks';
import { getClassNames } from '../../../Create/CreateAdunit';
import { TythonAdUnitDisplayAdTypeLayout, TythonAdUnitDisplayAdTypeLayoutLabel } from '../../CreateAdunit/constants';
import messages from '../../CreateAdunit/messages';
import { getStyles } from '../../CreateAdunit/styles';
import { AdTypes, NativeAdProperties } from '../../CreateAdunit/types';
import AutoFitHeight from '../../resources/AutoFitHeight.svg';
import AutoFitWidth from '../../resources/AutoFitWidth.svg';
import HorizontalBottomAdPreview from '../../resources/HorizontalBottomAdPreview.svg';
import HorizontalUpperAdPreview from '../../resources/HorizontalUpperAdPreview.svg';
import SquareBottomAdPreview from '../../resources/SquareBottomAdPreview.svg';
import SquareLeftAdPreview from '../../resources/SquareLeftAdPreview.svg';
import VerticalBottomAdPreview from '../../resources/VerticalBottomAdPreview.svg';
import VerticalLeftAdPreview from '../../resources/VerticalLeftAdPreview.svg';
import { fontOptions } from '../../services/fontOptions';
import AdPreview from './PreviewComponent';
import useAdUnitTypeSelector from './useAdUnitTypeSelector';

export interface IAdUnitTypeSelectorProps {
  adTypes: AdTypes;
  nativeProperties: NativeAdProperties;
  shouldRenderComponent?: boolean;
  isLoading?: boolean;
  onChange: ({ adTypes, nativeProperties }: { adTypes?: AdTypes; nativeProperties?: NativeAdProperties }) => void;
}

const AdUnitTypeSelector = injectIntl<InjectedIntlProps & IAdUnitTypeSelectorProps>(
  ({ intl, shouldRenderComponent, isLoading, ...props }) => {
    if (!shouldRenderComponent) {
      return null;
    }
    const theme = getTheme();
    const { formatMessage } = intl;
    const {
      isSdkLoaded,
      adPreviewConfig,
      isNativeAdActive,
      isDisplayAdActive,
      shouldRenderFewerSelectionMsg,
      shouldRenderNoSelectionMsg,
      displayAdLayout,
      onDisaplayAdLayoutChange,
      onToggleNativeAd,
      onToggleDisplayAd,
      onAdPreviewConfigChange,
    } = useAdUnitTypeSelector({ ...props });
    const { CMUIPopupArticleByIdAnchor } = useCMUIHelpExperience();

    const classNames = getClassNames(getStyles, { theme, displayAdLayout });

    return (
      <div className={classNames.adTypeContainer}>
        <FormLabel
          id="adunitTypeLabel"
          labelContent={formatMessage(messages.adunitType)}
          intl={intl}
          labelRequired
          noInfoButton
          disableHeading
          disableAriaLevel
        />
        <Accordion
          isActive={isNativeAdActive}
          onToggle={onToggleNativeAd}
          id="adUnit-native-ad-selector"
          label={formatMessage(messages.nativeAd)}
          subContent={formatMessage(messages.nativeAdSubContent)}
          wrapperClassName={classNames.adunitTypeAccordionWrapper}
        >
          <Stack enableScopedSelectors horizontal className={classNames.adunitTypeAccordionContentWrapper}>
            <Stack.Item className={classNames.adunitTypeLeftSection}>
              <div className="nativeAdType">
                <Text className={classNames.adunitTypeFormTitlePrimary} block role="heading" aria-level={2}>
                  {formatMessage(messages.nativeAdConfigLabel)}
                </Text>
                {/* Font selection */}
                <Dropdown
                  label={formatMessage(messages.nativeAdConfigLabelFont)}
                  options={fontOptions}
                  selectedKey={adPreviewConfig.fontFamily}
                  onChange={(_, option) => onAdPreviewConfigChange('fontFamily', option!.text)}
                />
                {/* Title color selection */}
                <div className={classNames.horizontalSelectionWrapper}>
                  <label className={classNames.boldFieldTitle}>{formatMessage(messages.nativeAdConfigLabelTitleColor)}</label>
                  <ColorPicker
                    selectedColor={adPreviewConfig.titleColor}
                    onChange={(color) => onAdPreviewConfigChange('titleColor', color)}
                    colorType={formatMessage(messages.nativeAdConfigLabelTitleColor)}
                  />
                </div>
                {/* Background color selection */}
                <div className={classNames.horizontalSelectionWrapper}>
                  <label className={classNames.boldFieldTitle}>{formatMessage(messages.nativeAdConfigLabelBackgroundColor)}</label>
                  <ColorPicker
                    selectedColor={adPreviewConfig.background}
                    onChange={(color) => onAdPreviewConfigChange('background', color)}
                    colorType={formatMessage(messages.nativeAdConfigLabelBackgroundColor)}
                  />
                </div>
                {/* Botton background color selection */}
                <div className={classNames.horizontalSelectionWrapper}>
                  <label className={classNames.boldFieldTitle}>{formatMessage(messages.nativeAdConfigLabelButtonBackgroundColor)}</label>
                  <ColorPicker
                    selectedColor={adPreviewConfig.buttonBackground}
                    onChange={(color) => onAdPreviewConfigChange('buttonBackground', color)}
                    colorType={formatMessage(messages.nativeAdConfigLabelButtonBackgroundColor)}
                  />
                </div>
                <br />
                <MessageBar messageBarType={0} className={classNames.infoMsgBar}>
                  {formatMessage(messages.nativeAdConfigMsgBarContent)}
                </MessageBar>
              </div>
            </Stack.Item>
            <Stack.Item className={css(classNames.adunitTypeRightSection, classNames.adPreviewWrapper)}>
              <div>
                <Text className={classNames.adunitTypeFormTitlePrimary} block role="heading" aria-level={2}>
                  {formatMessage(messages.adPreview)}
                </Text>
                <div className={classNames.horizontalSelectionWrapper}>
                  <label className={classNames.boldFieldTitle}>
                    {formatMessage(messages.adPreviewContainerSize)}&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <TextField
                    id="adCardWidthTextBox"
                    ariaLabel="adjustWidth"
                    styles={classNames.subComponentStyles.textBox}
                    value={adPreviewConfig.adWidth.toString()}
                    onChange={(_, val) => onAdPreviewConfigChange('adWidth', val)}
                    type="number"
                    onRenderPrefix={() => (
                      <img className={classNames.expandIcon} src={AutoFitWidth} alt={formatMessage(messages.adPreviewHorizontal)} />
                    )}
                  />
                  <label className={classNames.boldFieldTitle}>&nbsp;&nbsp;X&nbsp;&nbsp;</label>
                  <TextField
                    id="adCardHeightTextBox"
                    ariaLabel="adjustHeight"
                    styles={classNames.subComponentStyles.textBox}
                    value={adPreviewConfig.adHeight.toString()}
                    onChange={(_, val) => onAdPreviewConfigChange('adHeight', val)}
                    type="number"
                    onRenderPrefix={() => (
                      <img className={classNames.expandIcon} src={AutoFitHeight} alt={formatMessage(messages.adPreviewVertical)} />
                    )}
                  />
                </div>
                <br />
                <div className={classNames.previewSDKWrapper}>{isSdkLoaded && <AdPreview config={{ ...adPreviewConfig }} />}</div>
              </div>
            </Stack.Item>
          </Stack>
        </Accordion>
        <br />
        <Accordion
          isActive={isDisplayAdActive}
          onToggle={onToggleDisplayAd}
          id="adUnit-display-ad-selector"
          label={formatMessage(messages.displayAd)}
          subContent={formatMessage(messages.displayAdSubContent)}
          wrapperClassName={classNames.adunitTypeAccordionWrapper}
        >
          <Stack enableScopedSelectors horizontal className={classNames.adunitTypeAccordionContentWrapper}>
            <Stack.Item className={classNames.adunitTypeLeftSection}>
              <MessageBar messageBarType={0} className={classNames.infoMsgBar}>
                <FormattedMessage
                  {...messages.displayAdMsgBarContent}
                  values={{
                    displayAdMsgBarLink: <CMUIPopupArticleByIdAnchor query="ext07504" name={formatMessage(messages.displayAdMsgBarLink)} />,
                  }}
                />
              </MessageBar>
            </Stack.Item>
            <Stack.Item className={css(classNames.adunitTypeRightSection, classNames.adPreviewWrapper)}>
              <div className={classNames.adunitTypeDisplayAdPreviewHead}>
                <Text className={classNames.adunitTypeFormTitlePrimary} block role="heading" aria-level={2}>
                  {formatMessage(messages.adPreview)}
                </Text>
                <div className={classNames.adunitTypeDisplayAdPreviewHeadButtons}>
                  <Pivot
                    aria-label={formatMessage(messages.selectDisplayAdLayout)}
                    linkSize="normal"
                    linkFormat="tabs"
                    onLinkClick={(a) => onDisaplayAdLayoutChange(a?.props.itemKey as TythonAdUnitDisplayAdTypeLayout)}
                    selectedKey={displayAdLayout}
                  >
                    {Object.values(TythonAdUnitDisplayAdTypeLayout).map((layoutKey) => (
                      <PivotItem
                        headerText={formatMessage(
                          messages[
                            TythonAdUnitDisplayAdTypeLayoutLabel[
                              Object.keys(TythonAdUnitDisplayAdTypeLayout)[
                                Object.values(TythonAdUnitDisplayAdTypeLayout).indexOf(layoutKey)
                              ]
                            ]
                          ]
                        )}
                        itemKey={layoutKey}
                      />
                    ))}
                  </Pivot>
                </div>
              </div>
              <div>
                <div className={classNames.adunitTypeDisplayAdPreviewLayoutWrapper}>
                  <div className={classNames.adunitTypeDisplayAdPreviewContainer}>
                    <Text className={classNames.displayAdPreviewExampleTitle} block>
                      {formatMessage(messages.displayAdPreviewExamplePrimaryTitle)}
                    </Text>
                    {displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Horizontal && (
                      <img src={HorizontalUpperAdPreview} alt={formatMessage(messages.adPreview)} />
                    )}
                    {displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Square && (
                      <img src={SquareLeftAdPreview} alt={formatMessage(messages.adPreview)} />
                    )}
                    {displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Vertical && (
                      <img src={VerticalLeftAdPreview} alt={formatMessage(messages.adPreview)} />
                    )}
                  </div>
                  <div className={classNames.adunitTypeDisplayAdPreviewContainer}>
                    <Text className={classNames.displayAdPreviewExampleTitle} block>
                      {formatMessage(messages.displayAdPreviewExampleSecondaryTitle)}
                    </Text>
                    {displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Horizontal && (
                      <img src={HorizontalBottomAdPreview} alt={formatMessage(messages.adPreview)} />
                    )}
                    {displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Square && (
                      <img src={SquareBottomAdPreview} alt={formatMessage(messages.adPreview)} />
                    )}
                    {displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Vertical && (
                      <img
                        className={classNames.verticalAdPreviewBottomWrapper}
                        src={VerticalBottomAdPreview}
                        alt={formatMessage(messages.adPreview)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Stack.Item>
          </Stack>
        </Accordion>
        <br />
        {shouldRenderNoSelectionMsg && !isLoading && (
          <MessageBar messageBarType={1} className={classNames.adunitTypeWarningMsgBar}>
            {formatMessage(messages.warningAdUnitTypeNotSelected)}
          </MessageBar>
        )}
        {shouldRenderFewerSelectionMsg && !isLoading && (
          <MessageBar messageBarType={5} className={classNames.adunitTypeWarningMsgBar}>
            {formatMessage(messages.warningAdUnitTypeNotFullySelected)}
          </MessageBar>
        )}
        <br />
        <br />
      </div>
    );
  }
);

export default AdUnitTypeSelector;
