import { DefaultButton, getTheme, IconButton, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import AppMessages from '../../../../../../App.messages';
import { FormState } from '../../../../../../components/Form';
import { injectIntlAndObserver } from '../../../../../../utils';
import { getCreatePropertyStore } from '../../../Create/__data__/store/store';
import ClaritySection from '../../../Create/variations/CreateNonTythonProperty/components/ClarityFormSection';
import { setClarityPanelVisibility } from '../../__data__/mutatorActions';
import { onClarityUpdate } from '../../__data__/orchestrators/updateClarity';
import { getGridPropertyStore } from '../../__data__/store/store';
import messages from './messages';
import { getStyles } from './styles';

const ClarityPanel = injectIntlAndObserver<InjectedIntlProps>(
  ({ intl }): JSX.Element => {
    const {
      active: { clarity },
    } = getCreatePropertyStore();
    const { isPanelOpen, selectedPropertyId } = getGridPropertyStore();
    const { originalClarityId, clarityId } = clarity || {};
    const { panelButton, panelDescriptor, panelAgreement } = getStyles({ theme: getTheme() });
    const { formatMessage } = intl;

    const isDisabled = (originalClarityId?.length === 0 && clarityId?.length === 0) || originalClarityId === clarityId;

    return (
      <Panel
        isOpen={isPanelOpen}
        type={PanelType.medium}
        onDismiss={() => setClarityPanelVisibility(false)}
        headerText={formatMessage(messages.sidePanelLabel)}
        closeButtonAriaLabel={formatMessage(AppMessages.panelCloseButtonAriaDesc)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
          return (
            <div className={panelButton}>
              <PrimaryButton
                onClick={onClarityUpdate}
                text={formatMessage(messages.update)}
                ariaDescription={formatMessage(messages.update)}
                disabled={isDisabled}
              />
              <DefaultButton onClick={() => setClarityPanelVisibility(false)} text={formatMessage(AppMessages.cancel)} />
            </div>
          );
        }}
      >
        <div className={panelDescriptor}>{formatMessage(messages.sidePanelDescription, { id: selectedPropertyId })}</div>

        <ClaritySection mode={FormState.Edit} clarityConfigs={clarity} isShownInPanel={true} />

        <div className={panelAgreement}>
          <IconButton iconProps={{ iconName: 'Info' }} />
          <div>{formatMessage(messages.sidePanelTC)}</div>
        </div>
      </Panel>
    );
  }
);

export default ClarityPanel;
