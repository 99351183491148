import { defineMessages } from 'react-intl';

export default defineMessages({
  collapseMenu: {
    id: 'nav.collapseMenu',
    defaultMessage: 'Collapse main menu',
  },
  expandMenu: {
    id: 'nav.expandMenu',
    defaultMessage: 'Expand main menu',
  },
});
