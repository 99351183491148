import { orchestrator } from 'satcheljs';
import {
  Account,
  AdUnit,
  Filter,
  Model,
  NotificationLabel,
  NotificationSeverity,
  Publisher,
  PublisherSearch,
  getAppStore,
  onNotificationCardAdded,
  showLoadingToastNotification,
} from '../../../../@data';
import { onPopupOpenChanged, showErrorToastNotification, showSuccessToastNotification } from '../../../../@data/mutatorActions';
import { PopupType } from '../../../../@data/store/schema/enums/PopupType';
import notificationMessages from '../../../../NotificationsPanel.Types.messages';
import { getSuccessNotificationDuplicateItem } from '../../../../utils';
import messages from '../../GridV2.messages';
import { ISelectableRow } from '../../GridV2.types';
import { onDuplicateItem } from '../actions/commandBarActions';
import { setLoading } from '../mutatorActions';
import { duplicateAccount, duplicateData } from '../services/GridService';
import { getStore } from '../store/store';

orchestrator(onDuplicateItem, async (msg) => {
  onPopupOpenChanged(PopupType.None);

  const { entity } = getStore();

  const selectedItem = msg.selectedItem;

  const showErrorMessage = () => {
    // TODO: Figure out how to internationalize orchestrators. #1632176
    showErrorToastNotification({ textMessageDescriptor: messages.gridDataDuplicatingFailed });
    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      titleMessageDescriptor: notificationMessages.DuplicateGridItemFailureTitle,
      contentMessageDescriptor: notificationMessages.DuplicateGridItemFailureContent,
      notificationLabel: NotificationLabel.DuplicateGridItemFailure,
    });
  };

  const duplicateSucceeded = (newEntity, entityInstance, formatMessage) => {
    if (newEntity) {
      const selectedEntity: Model = { ...msg.selectedItem, className: msg.selectedItem.className, apiPath: msg.selectedItem.apiPath };
      selectedEntity.name = selectedItem.name + ' (copy)';
      selectedEntity.id = newEntity.id;

      msg.setData?.([...(msg.data || []), selectedEntity].map((item) => ({ ...item, isSelected: false } as ISelectableRow)));
      msg.onDuplicateAction?.([selectedEntity]);

      // const commandText = AppMessages.duplicate.defaultMessage;
      // showSuccessToastNotification({ text: getSuccessNotification(commandText!, [newEntity], entityInstance) });
      showSuccessToastNotification({ text: getSuccessNotificationDuplicateItem([selectedItem], entityInstance, formatMessage) });
    }
  };

  if (entity && selectedItem) {
    const { userContext, publisher } = getAppStore();

    if (userContext && publisher) {
      let entityInstance = new entity();
      try {
        const id = encodeURI('' + selectedItem.id);
        if (entityInstance instanceof PublisherSearch) {
          entityInstance = new Publisher();
        }
        const enparams = new URLSearchParams([['ids', id]]);
        if (entityInstance instanceof AdUnit || entityInstance instanceof Filter) {
          enparams.append('type', 'Search');
        }
        if (entityInstance instanceof AdUnit) {
          enparams.append('details', 'true');
        }

        let newEntity;
        if (entityInstance instanceof Account) {
          showLoadingToastNotification({
            textMessageDescriptor: messages.gridDataDuplicating,
            onDismiss: async () => {
              try {
                newEntity = await duplicateAccount(publisher, selectedItem.id!, userContext);
                duplicateSucceeded(newEntity, entityInstance, msg.formatMessage);
              } catch (e) {
                showErrorMessage();
              }
            },
          });
        } else {
          showLoadingToastNotification({
            textMessageDescriptor: messages.gridDataDuplicating,
            onDismiss: async () => {
              try {
                newEntity = await duplicateData([publisher], userContext, entity, enparams);
                duplicateSucceeded(newEntity, entityInstance, msg.formatMessage);
              } catch (e) {
                showErrorMessage();
              }
            },
          });
        }
      } catch (e) {
        showErrorMessage();
      } finally {
        setLoading(false);
      }
    }
  }
});
