import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Model, PopupType } from '../../../@data';
import AppMessages from '../../../App.messages';
import { PopupCard } from '../../../components/Popup';
import { injectIntlAndObserver } from '../../../utils';
import { onDeleteItem } from '../@data/actions/commandBarActions';
import { setDialogType } from '../@data/mutatorActions';
import { getStore } from '../@data/store/store';
import { IDestructiveDialogLabelsProps } from '../GridV2.types';

export interface IDeleteConfirmationDialogProps extends InjectedIntlProps {
  deleteLabels?: IDestructiveDialogLabelsProps;
  setData?: (data?: Model[]) => void;
  data: Model[];
  selectedItems: Model[];
  preDeleteAction?: (items: Model[]) => void;
  deleteAction?: (items: Model[]) => void;
  postDeleteAction?: (items: Model[]) => void;
}

export const DeleteConfirmationDialog = injectIntlAndObserver(function DeleteConfirmationDialogComp({
  intl: { formatMessage },
  deleteLabels,
  setData,
  data,
  selectedItems,
  preDeleteAction,
  deleteAction,
  postDeleteAction,
}: IDeleteConfirmationDialogProps): JSX.Element | null {
  const { dialogType } = getStore();

  const onDialogDismissed = () => setDialogType(PopupType.None);

  const handleDeleteButtonClick = () => {
    onDeleteItem(selectedItems[0], data, formatMessage, setData, preDeleteAction, deleteAction, postDeleteAction);
    onDialogDismissed();
  };

  return (
    <PopupCard
      isShown={dialogType === PopupType.DeleteConfirmation}
      popUpTitle={formatMessage(deleteLabels ? deleteLabels.title : AppMessages.deleteGenericTitle)}
      popUpDescription={deleteLabels?.description ? formatMessage(deleteLabels.description) : undefined}
      onPopupDismiss={onDialogDismissed}
      onPrimaryButtonClick={handleDeleteButtonClick}
      onSecondaryButtonClick={onDialogDismissed}
      primaryButtonAriaLabel={formatMessage(AppMessages.delete)}
      secondaryButtonAriaLabel={formatMessage(AppMessages.cancel)}
    />
  );
});
