import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import { History } from 'history';
import { action } from 'satcheljs';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';

export const onFormIdChanged = action('onFormIdChanged', (value: number) => ({ value }));

export const onFormFilterNameChanged = action('onFormFilterNameChanged', (value?: string) => ({ value }));

export const onFormFilterTypeChanged = action('onFormFilterTypeChanged', (value: IDropdownOption) => ({ value }));

export const onFormChannelsChanged = action('onFormChannelsChanged', (values?: IIdNamePair[]) => ({ values }));

export const onFilterChannelListChanged = action('onFilterChannelListChanged', (values?: IIdNamePair[]) => ({ values }));

export const onFilterUpdateTypeChanged = action('onFilterUpdateTypeChanged', (value: IChoiceGroupOption) => ({ value }));

export const onfilterItemsEntryTypeChanged = action('onfilterItemsEntryTypeChanged', (value: IChoiceGroupOption) => ({ value }));

export const onFormExludedUrlChanged = action('onFormExludedUrlChanged', (value?: string) => ({ value }));

export const onFormExludedAdKeywordChanged = action('onFormExludedAdKeywordChanged', (value?: string) => ({ value }));

export const onFormExludedQueryKeywordChanged = action('onFormExludedQueryKeywordChanged', (value?: string) => ({ value }));

export const onFormAppendExcludedUrlsChanged = action('onFormAppendExcludedUrlsChanged', (value?: string) => ({ value }));

export const onFormAppendExcludedAdKeywordsChanged = action('onFormAppendExcludedAdKeywordsChanged', (value?: string) => ({ value }));

export const onFormAppendExcludedQueryKeywordsChanged = action('onFormAppendExcludedQueryKeywordsChanged', (value?: string) => ({ value }));

export const onFormDownloadClicked = action('onFormDownloadClicked', (value: string) => ({ value }));

export const onFormDownloadExcludedUrlLoading = action('onFormDownloadExcludedUrlLoading', (value: boolean) => ({ value }));

export const onFormDownloadExcludedAdkeywordLoading = action('onFormDownloadExcludedAdkeywordLoading', (value: boolean) => ({ value }));

export const onFormDownloadExcludedQuerykeywordLoading = action('onFormDownloadExcludedQuerykeywordLoading', (value: boolean) => ({
  value,
}));

export const onFormFilterStartTimeChanged = action('onFormFilterStartTimeChanged', (value?: Date) => ({ value }));

export const onFormFilterEndTimeChanged = action('onFormFilterEndTimeChanged', (value?: Date) => ({ value }));

export const onFormResetTimeRange = action('onFormResetTimeRange');
export const onFormUndoTimeRange = action('onFormUndoTimeRange');

export const onFormInitialized = action(
  'onFormInitialized',
  (mode: FormState, publisherId: string, accountId: string, entityId?: string) => ({ mode, publisherId, accountId, entityId })
);

export const onFormSubmitted = action('onFormSubmitted', (mode: FormState, routeHistory?: History) => ({ mode, routeHistory }));

export const onFormCancelled = action('onFormCancelled');

export const onFormCleared = action('onFormCleared');
