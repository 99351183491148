import { default as React, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Log } from '../../../logging/src';
import { LogType } from '../../../logging/src/LogType';
import { getAppStore, showTokenExpirationNotification } from '../../@data';
import AppMessages from '../../App.messages';
import { DefaultRoute } from '../../Routes';
import { PageSpinner } from '../../components/Spinners';
import {
  DOMAIN_REDIRECTION_QUERY_PARAM,
  REDIRECT_URI_SESSION_STORAGE_KEY,
  TOKEN_EXPIRED_SEARCH_PARAM_KEY,
  TYTHON_DOMAIN_REDIRECTION_CACHE_KEY,
} from '../../constants/AppConstants';
import { getUrlSearchParameter, injectIntlWithObserver } from '../../utils';
import { getLoginPageStore, onAuthPageInitialized, onLoadingSet } from './@data';
import './@data/mutators/userAuthentication';
import './@data/orchestrators';
import LoginPageMessages from './LoginPage.messages';
import { ILoginPageProps } from './components/LoginPageView';
import { RedirectionSpinner } from './components/RedirectionSpinner';

export const LoginPage = injectIntlWithObserver(function LoginPageComponent({ location, intl }: ILoginPageProps) {
  const { formatMessage } = intl;
  const { loading } = getLoginPageStore();
  const [isLoading, setLoading] = React.useState(true);
  const [isRedirectionFlow, setRedirectionFlow] = React.useState(!!sessionStorage.getItem(TYTHON_DOMAIN_REDIRECTION_CACHE_KEY));

  useEffect(() => {
    Log.info(`Navigated to new integrated login page: ${window.location.href}`);
    const loginHint = getUrlSearchParameter(window.location.search, DOMAIN_REDIRECTION_QUERY_PARAM);

    if (loginHint) {
      Log.write(`Setting redirected user cache for : ${loginHint}`, LogType.DEBUG);
      setRedirectionFlow(true);
      sessionStorage.setItem(TYTHON_DOMAIN_REDIRECTION_CACHE_KEY, 'true');
      onLoadingSet(true);
    }
    onAuthPageInitialized(loginHint);

    const searchParam = new URLSearchParams(location.search);
    if (searchParam.get(TOKEN_EXPIRED_SEARCH_PARAM_KEY) === 'true') {
      showTokenExpirationNotification();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => setLoading(!!loading), [loading]);

  const { userContext, isInviteUserFlow, waiting } = getAppStore();
  // tslint:disable-next-line: no-any
  const { from } = (location.state as any) || { from: { pathname: DefaultRoute } };

  if (!isLoading) {
    if (isInviteUserFlow) {
      return <Redirect to={'acceptUserInvite/code=redirect/locale=redirect'} />;
    }

    let redirectTo = from.pathname;
    if (userContext && userContext.token) {
      if (redirectTo === '/login') {
        redirectTo = DefaultRoute;
      }

      if (sessionStorage.getItem(REDIRECT_URI_SESSION_STORAGE_KEY)) {
        redirectTo = sessionStorage.getItem(REDIRECT_URI_SESSION_STORAGE_KEY) || '';
        sessionStorage.removeItem(REDIRECT_URI_SESSION_STORAGE_KEY);
      }

      Log.debug(`Redirecting to: ${redirectTo} after login`);
      return <Redirect to={redirectTo} />;
    }
  } else if (!userContext && !isRedirectionFlow && !window.location.search.includes(DOMAIN_REDIRECTION_QUERY_PARAM)) {
    return <Redirect to={'/'} />;
  }

  // by default if you're here, then authentication is ongoing. Once user is authentication, then redirect user to appropriate page
  return isRedirectionFlow ? (
    <RedirectionSpinner label={formatMessage(LoginPageMessages.redirectionMessage)} />
  ) : (
    <PageSpinner label={formatMessage(waiting ? AppMessages.loadingWaitingLabel : AppMessages.loadingLabel)} />
  );
});
