import { Link, SelectionMode } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EntityEvaluationStatus, InventoryPlatformType, Property, getAppStore } from '../../../../@data';
import { useWindowSize } from '../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../hooks/useWindowSize/store/store';
import { GridV2 } from '../../../../layout/GridPageV2';
import { injectRouterAndIntlWithObserver } from '../../../../utils';
import { setPropertyData } from '../../Property/Manage/__data__/mutatorActions';
import { getGridPropertyStore } from '../../Property/Manage/__data__/store/store';
import messages from './messages';
import { AppInventoryManagePageType } from './types';
import useAppInventoryGrid from './useAppInventoryGrid';

const { windowStoreSerializer } = useWindowSize();

const AppInventoryManagePage: AppInventoryManagePageType = ({ intl: { formatMessage }, history, location, match }) => {
  const { isWindowRegularSize } = windowStoreSerializer(getWindowSizeStore());
  const { publisher, publisherEvaluationStatus } = getAppStore();
  const isPublisherRejected = publisherEvaluationStatus?.approvalStatus === EntityEvaluationStatus.Rejected;
  const store = getGridPropertyStore();
  const { gridColumns, pageTitle, commandBarItems, NoResultComponent } = useAppInventoryGrid(
    formatMessage,
    history,
    isWindowRegularSize,
    publisher
  );

  return (
    <div>
      <GridV2
        history={history}
        location={location}
        match={match}
        entity={Property}
        pageTitle={pageTitle}
        msgBarProps={
          isPublisherRejected
            ? {
                messageBarType: 1,
                children: (
                  <span>
                    <FormattedMessage
                      {...messages.appsCannotServeMessage}
                      values={{ learn: <Link>{formatMessage(messages.learnMore)}</Link> }}
                    />
                  </span>
                ),
              }
            : undefined
        }
        isGridReady={!store.isLoading}
        isDataLoading={store.isFetchingData}
        commandBarProps={commandBarItems}
        dataGridProps={{
          gridV2Columns: gridColumns,
          data: publisher
            ? store.data?.filter?.((property) => property.servingConfig?.inventoryPlatformType === InventoryPlatformType.UWP) || []
            : [],
          setData: setPropertyData,
          shimmerLines: 10,
          selectionMode: SelectionMode.single,
          renderZeroItemsComponent: NoResultComponent,
        }}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
    </div>
  );
};

export default (injectRouterAndIntlWithObserver(AppInventoryManagePage) as unknown) as React.FC;
