import { mutator } from 'satcheljs';
import { Channel } from '../../../../../@data';
import { mergeTypeWithObject } from '../../../../../utils';
import {
  onChannelChanged,
  onChannelErrorChanged,
  onChannelsFetched,
  onFetchChannelsLoading,
  onFetchingChannelsData,
  onRawChanged,
} from '../actions';
import { getStore } from '../store/store';

mutator(onRawChanged, (msg) => {
  getStore().raw = mergeTypeWithObject(new Channel(), msg.value);
});

mutator(onChannelChanged, (msg) => {
  getStore().active = msg.channel;
});

mutator(onFetchChannelsLoading, (msg) => {
  getStore().isLoading = msg.isLoading;
});

mutator(onFetchingChannelsData, (msg) => {
  getStore().isFetchingData = msg.isFetchingData;
});

mutator(onChannelsFetched, (msg) => {
  getStore().gridChannels = msg.items;
});

mutator(onChannelErrorChanged, (msg) => {
  const store = getStore();
  if (!store.errors.name && !store.errors.adunitsSelected && !store.errors.filtersSelected && !!msg.values) {
    getStore().errors = msg.values;
  } else if (!!getStore().errors.name || !!getStore().errors.adunitsSelected || !!getStore().errors.filtersSelected) {
    getStore().errors = msg.values || {};
  }
});
