import * as React from 'react';
import { useEffect } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { isTythonSelfServeUser } from '../../@data';
import { injectIntlWithObserver } from '../../utils';
import { onInitializeGoogleAccount } from '../ad-management/Account/Settings/components/GoogleConnector/@data/actions';
import { fetchAdUnitsDataAction } from '../ad-management/Adunit/@data/actions';
import { onCurrentRouteChanged } from './@data/actions';
import './@data/mutators';
import './@data/orchestrators';
import { RouteName } from './@data/store/store';
import { fetchMediationReportsMetadata } from './DashboardCards/@data/actions';
import { ReportDetailsPage } from './DashboardCards/ReportDetailsPage/ReportDetailsPage';

import { ReportPage } from './ReportPage';
import { useMediationAvailable } from './hooks/useMediationAvailable';

export const BaseReportPage = injectIntlWithObserver((props: RouteComponentProps & InjectedIntlProps) => {
  const { isMediationAvailable } = useMediationAvailable();
  useEffect(() => {
    onCurrentRouteChanged(RouteName.dashboard, props.intl.formatMessage);
    if (isTythonSelfServeUser()) {
      onInitializeGoogleAccount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTythonSelfServeUser() && isMediationAvailable) {
      fetchMediationReportsMetadata();
      fetchAdUnitsDataAction();
    }
  }, [isMediationAvailable]);
  return (
    <>
      <Switch>
        <Route exact path={`${props.match.url}`} component={ReportPage} />
        <Route exact path={`${props.match.url}/:type/${RouteName.reportDetails}`} component={ReportDetailsPage} />
      </Switch>
    </>
  );
});
