import { ApiError, handleApiErrors, handleError, UserContext } from '../../../../@data';
import { buildRequestHeadersWithAuthToken } from '../../../../@data/utils/requestUtils';
import { uiConfig } from '../../../../config/ui.config';

export async function setUserPublisherContext(userContext: UserContext, publisherId: number): Promise<string> {
  const url = uiConfig.getApiBaseAddress() + '/user/context';
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  headers.append('targetCustomerId', JSON.stringify(publisherId));

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
    });

    const responseJson = await response.json();

    if (!response.ok) {
      return handleApiErrors(response, url);
    }

    const sessionId = responseJson.data.commonSessionId as string;
    return Promise.resolve(sessionId as string);
  } catch (err) {
    const error = `Failed to fetch user scopes for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
