import { FontWeights, getTheme, ITheme, keyframes, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { noPointerEvents } from '../../utils/GlobalStyleUtils';

export interface IGridWithCustomColumnStyleProps {
  theme: ITheme;
}

export interface IGridWithCustomColumnStyles {
  detailsListStyles?: string;
  detailsHeaderRootStyle?: string;
  detailsRowRootStyle?: string;
  headingRootStyle?: string;
  disabledRowStyle?: string;
}

export const getStyles = (props: IGridWithCustomColumnStyleProps): IGridWithCustomColumnStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette, fonts } = theme;

  return mergeStyleSets({
    detailsListStyles: {
      fontWeight: FontWeights.regular,
      padding: 0,
      marginRight: '-1px',
    },
    detailsHeaderRootStyle: {
      fontWeight: FontWeights.regular,
      padding: 0,
      borderTop: `1px solid ${palette.neutralPrimary}`,
      borderBottom: `1px solid ${palette.neutralPrimary}`,
      '.ms-DetailsHeader-cell:not(:last-child)': {
        marginRight: '-1px',
      },
    },
    detailsRowRootStyle: {
      ...fonts.size14,
      '.ms-DetailsRow-cell:not(:last-child)': {
        marginRight: '-1px',
        borderBottom: 'none',
      },
      '.ms-DetailsRow-cell:(:last-child)': {
        borderBottom: 'none',
      },
      '.ms-DetailsRow-fields.updated': {
        animation: keyframes({
          '50%': {
            backgroundColor: palette.themeLighter,
          },
        }),
        animationDuration: '300ms',
      },
    },
    disabledRowStyle: {
      ...noPointerEvents,
    },
    headingRootStyle: {
      margin: `${spacing.size32} 0 ${spacing.size12}`,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): IGridWithCustomColumnStyles {
  return getStyles({ theme: getTheme(), ...props });
}
