import { Account } from './Account';
import { Model } from './Model';
import { PublisherCreate } from './PublisherCreate';
import { User } from './User';

export class PublisherAccountUserCreate implements Model {
  public apiPath = 'signup';
  public className = 'signup';
  public publisher?: PublisherCreate;
  public account?: Account;
  public user?: User;
}
