import { IInfoButtonStyleProps, IInfoButtonStyles } from './InfoButton.types';

export const getStyles = (props: IInfoButtonStyleProps): IInfoButtonStyles => {
  return {
    callout:['ms-font-m',{
        padding: 10,
    }],
    icon: {
      display: 'inline-block'
    }
  };
};
