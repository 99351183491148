import { mutatorAction } from 'satcheljs';
import { UserRole } from '../../../../../../@data';
import { getUserInvitationStore } from '../store';

export const setFirstName = mutatorAction('setFirstName', (value: string) => {
  getUserInvitationStore().userInviteViewModel.firstName = value;
});

export const setLastName = mutatorAction('setLastName', (value: string) => {
  getUserInvitationStore().userInviteViewModel.lastName = value;
});

export const updateEmail = mutatorAction('updateEmail', (value: string) => {
  getUserInvitationStore().userInviteViewModel.email = value;
});

export const updateRecipientPreferredLanguage = mutatorAction('updateRecipientPreferredLanguage', (value: string) => {
  getUserInvitationStore().userInviteViewModel.recipientPreferredLang = value;
});

export const updateRole = mutatorAction('updateRole', (value: UserRole) => {
  getUserInvitationStore().userInviteViewModel.userRole = value;
});

export const onFormCleared = mutatorAction('onFormCleared', () => {
  getUserInvitationStore().userInviteViewModel = {};
});
