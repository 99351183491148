import { Dialog } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { TYTHON_NEW_PROD } from '../../../../../constants';
import { usePreloadSVGImages } from '../../../../../hooks';
import { getScreenReaderTextStyles } from '../../../../../utils/GlobalStyleUtils';
import { setIsFRECardShown } from '../../@data/mutatorActions';
import '../../@data/orchestrators';
import { FRECard, ICardProps } from './FRECard';
import messages from './messages';
import fre1 from './resources/fre-1.jpg';
import fre2 from './resources/fre-2.jpg';
import fre3 from './resources/fre-3.jpg';
import { getFRECardStyles } from './styles';

export const FREContainer = injectIntl(({ intl }) => {
  const { formatMessage } = intl;
  const { subComponentStyles } = getFRECardStyles();

  const [currentStep, updateCurrentStep] = React.useState(0);
  usePreloadSVGImages([fre1, fre2, fre3]);

  const cards: ICardProps[] = [
    {
      title: formatMessage(messages.title1),
      description: formatMessage(messages.description1),
      imageSrc: fre1,
      imageAlt: formatMessage(messages.title1),
    },
    {
      title: formatMessage(messages.title2),
      description: formatMessage(messages.description2, { newDomain: TYTHON_NEW_PROD }),
      imageSrc: fre2,
      imageAlt: formatMessage(messages.title2),
    },
    {
      title: formatMessage(messages.title3),
      description: formatMessage(messages.description3),
      imageSrc: fre3,
      imageAlt: formatMessage(messages.title3),
    },
  ];

  return (
    <Dialog
      hidden={false}
      styles={subComponentStyles.dialog}
      modalProps={{ titleAriaId: 'freDialogAria' }}
      dialogContentProps={{ styles: subComponentStyles.dialogContent }}
    >
      <FRECard
        {...cards[currentStep]}
        dismissDialog={() => setIsFRECardShown(false)}
        nextButtonClicked={() => updateCurrentStep(currentStep + 1)}
        totalCardCount={cards.length}
        isLastCard={currentStep === cards.length - 1}
        selectedCardIndex={currentStep}
      />
      <span id="freDialogAria" style={getScreenReaderTextStyles()}>
        {formatMessage(messages.dialogAriaLabel)}
      </span>
    </Dialog>
  );
});
