import { IDropdownOption } from '@fluentui/react';

export const fontOptions: IDropdownOption[] = [
  { key: 'Arial', text: 'Arial' },
  { key: 'Arial Black', text: 'Arial Black' },
  { key: 'Calibri', text: 'Calibri' },
  { key: 'Calibri Light', text: 'Calibri Light' },
  { key: 'Cambria', text: 'Cambria' },
  { key: 'Comic Sans MS', text: 'Comic Sans MS' },
  { key: 'Courier New', text: 'Courier New' },
  { key: 'Garamond', text: 'Garamond' },
  { key: 'Georgia', text: 'Georgia' },
  { key: 'Helvetica', text: 'Helvetica' },
  { key: 'Impact', text: 'Impact' },
  // Removing these fonts temporarily, as they are not being reflected in UI
  // { key: 'Lato', text: 'Lato' },
  // { key: 'Open Sans', text: 'Open Sans' },
  // { key: 'Poppins', text: 'Poppins' },
  // { key: 'Roboto', text: 'Roboto' },
  // { key: 'Raleway', text: 'Raleway' },
  { key: 'Segoe UI', text: 'Segoe UI' },
  { key: 'Sitka Heading', text: 'Sitka Heading' },
  { key: 'Tahoma', text: 'Tahoma' },
  { key: 'Times New Roman', text: 'Times New Roman' },
  { key: 'Verdana', text: 'Verdana' },
];
