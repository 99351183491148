import React from 'react';
import { getGridClassNames } from './GridCommonStyles';

export function GridCell({ children }): JSX.Element {
  const { gridCell } = getGridClassNames();
  return (
    <div title={typeof children === 'string' ? children : ''} className={gridCell}>
      {children}
    </div>
  );
}
