import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, onNotificationChanged, onSavingChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { validateSchema } from '../../../../../utils';
import messages from '../../AdReview/AdCreativePage.messages';
import {
  onAdCreativeBlockPanelChanged,
  onAdCreativeRefreshDataChanged,
  onBlockAdsErrorChanged,
  onBlockedAdsContextChanged,
  onMSANBlockAdsSubmitted,
  onRefreshBlockSummary,
} from '../actions';
import { getBlockImagesData } from '../services/AdBlockService';
import { createAdBlock, getBlockedAdContext } from '../services/AdCreativeService';
import { blockItemErrorModel } from '../store/schema/BlockItemErrorModel';
import { BlockItemType } from '../store/schema/models';
import { BlockAdsData } from '../store/schema/models/BlockAdsData';
import { getStore } from '../store/store';

orchestrator(onMSANBlockAdsSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { blockAdsPage } = getStore();

  let notification: INotificationBarItem = {};
  if (publisher && publisher.id && userContext) {
    const body: BlockAdsData[] = [];
    blockAdsPage.adPerfBlockAdsData.forEach(async (item, index) => {
      const block: BlockAdsData = new BlockAdsData();
      block.channelId = blockAdsPage.channels[item.accountId!];
      block.channelName = null;
      block.adId = item.adId;
      block.adTitle = item.adTitle;
      block.reason = blockAdsPage.blockDetails;
      block.reasonCategory = blockAdsPage.blockReason;
      block.advertiserAccountId = item.advertiserAccountId;
      block.blockRequestSourceId = blockAdsPage.selectedBlockRequestSource;
      block.modifiedBy = userContext.id.toString();
      block.modifiedDateTime = new Date(Date.now()).toISOString();
      block.startDate = null;
      block.endDate = null;
      block.isEnabled = true;
      block.pastRevenue = item.revenue ? item.revenue : 0;
      const imgs = item.images ? item.images : [];
      block.allImages = imgs.join('___');

      body.push(block);
    });

    const params = new URLSearchParams([['targetCustomerId', publisher.id!.toString()]]);

    try {
      onAdCreativeBlockPanelChanged(true, BlockItemType.Ads);
      await validateSchema(blockItemErrorModel(), blockAdsPage);
      onBlockAdsErrorChanged();
    } catch (e) {
      onBlockAdsErrorChanged(e);
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityBlockAdsMissingFields,
        intlMessageValues: { count: Object.keys(e).length },
        messageBarType: MessageBarType.severeWarning,
      });
      return;
    }

    onSavingChanged(true);
    try {
      await createAdBlock(body, params);
      notification = {
        textMessageDescriptor: messages.adqualityBlockedAdsSuccessfully,
        messageBarType: MessageBarType.success,
      };
      onNotificationChanged(notification);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityFailedToBlockAds,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      // TODO: Figure out why using onBlockedContextChanged isnt working anymore
      const blockedAdContext = await getBlockedAdContext(publisher.id!);
      blockedAdContext.mapAdIdImages = new Map<number, string[]>();
      const blockedImageSummary = await getBlockImagesData(publisher.id!);
      blockedImageSummary.forEach((element) => {
        if (blockedAdContext.mapAdIdImages) {
          if (blockedAdContext.mapAdIdImages.has(element.adId!)) {
            blockedAdContext.mapAdIdImages.get(element.adId!)!.push(element.image!);
          } else {
            blockedAdContext.mapAdIdImages.set(element.adId!, [element.image!]);
          }
        }
      });
      onBlockedAdsContextChanged(blockedAdContext);
      onRefreshBlockSummary();
      onAdCreativeBlockPanelChanged(false);
      onSavingChanged(false);
      onAdCreativeRefreshDataChanged();
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
