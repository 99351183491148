import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import {
  EntityPreferences,
  EntityType,
  getAppStore,
  getEntity,
  NotificationType,
  onLoadingChanged,
  onNotificationChanged,
  showErrorToastNotification,
} from '../../../../../../@data';
import messages from '../../SettingsPage.messages';
import { onFetchPublisherPreferences } from '../actions/onSettingsPageChanged';
import { onSettingsChanged, saveRawSettings } from '../mutatorActions/settingsPageChanged';
import { INotificationPreferences, ISettingsViewModel } from '../store/schema/ISettingsViewModel';
import { getStore } from '../store/store';

orchestrator(onFetchPublisherPreferences, async () => {
  Log.debug('Fetching publisher preferences');
  const { publisher, userContext } = getAppStore();

  if (publisher && userContext) {
    try {
      onLoadingChanged(true);

      const raw = await getEntity([publisher], userContext, EntityPreferences);

      const notificationPreferences: INotificationPreferences = {
        billingAlerts: true,
        servingStatus: true,
        profileReview: true,
        invitedUsers: true,
      };

      if (raw.notificationPreferences) {
        raw.notificationPreferences.notifications.forEach((np) => {
          switch ((NotificationType[np.type] as unknown) as NotificationType) {
            case NotificationType.BillingAlert:
              notificationPreferences.billingAlerts = np.enabled;
              break;
            case NotificationType.ServingStatus:
              notificationPreferences.servingStatus = np.enabled;
              break;
            case NotificationType.ProfileReview:
              notificationPreferences.profileReview = np.enabled;
              break;
            case NotificationType.InvitedUser:
              notificationPreferences.invitedUsers = np.enabled;
              break;
          }
        });
      }

      const settings = getStore().active;

      const viewModel: ISettingsViewModel = {
        entityType: EntityType.Publisher,
        systemPreferences: settings.systemPreferences,
        notificationPreferences: notificationPreferences,
      };

      onSettingsChanged(viewModel);
      saveRawSettings(raw);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.publisherPreferencesFailed });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.invalidStateLoginOrReport,
    });
  }
});
