import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../../../theme';
import { ICommonStyleProps } from '../../../../../../../../types';

type StyleProperties =
  | 'root'
  | 'title'
  | 'tipsText'
  | 'scriptWithImage'
  | 'scriptSectionLeft'
  | 'messageBar'
  | 'scriptBox'
  | 'scriptHolder'
  | 'clarityContainer'
  | 'claritySection'
  | 'claritySectionTitle'
  | 'panelPending'
  | 'panelPendingText';

type ScriptStyles = Record<StyleProperties, string>;

export interface IScriptStylesProps extends ICommonStyleProps {
  isShownInPanel?: boolean;
}

export const getStyles = (props?: IScriptStylesProps): ScriptStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const fonts = theme.fonts as IPubCenterFonts;
  const { ResponsiveMedium, ResponsiveExtraSmall, ResponsiveSmall } = BreakPoints;

  return mergeStyleSets({
    root: {
      marginRight: spacing.size32,
      display: 'grid',
      gridTemplateColumns: `${props?.isShownInPanel ? '1f' : '2fr 1fr'}`,
      gap: spacing.size40,
      [ResponsiveMedium]: {
        gridTemplateColumns: '1fr',
      },
      [ResponsiveSmall]: {
        gridTemplateColumns: '1fr',
      },
      [ResponsiveExtraSmall]: {
        gridTemplateColumns: '1fr',
      },
    },
    title: {
      marginBottom: spacing.size16,
      fontSize: theme.fonts.mediumPlus.fontSize,
      fontWeight: FontWeights.semibold,
      lineHeight: '22px',
    },
    tipsText: {
      fontSize: theme.fonts.medium.fontSize,
      lineHeight: spacing.size20,
      margin: `${spacing.size16} 0`,
    },
    scriptWithImage: {
      display: 'flex',
      gap: spacing.size16,
      [ResponsiveSmall]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
      [ResponsiveExtraSmall]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    scriptSectionLeft: {
      display: 'flex',
      flexDirection: 'column',
      width: '-webkit-fill-available',
    },
    messageBar: {
      marginBottom: spacing.size12,
      backgroundColor: theme.palette.themeLighterAlt,
    },
    scriptBox: {
      borderColor: palette.neutralLight,
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: spacing.size12,
      marginBottom: spacing.size16,
    },
    scriptHolder: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      backgroundColor: palette.neutralLighter,
      minHeight: spacing.size16,
      maxHeight: spacing.size128,
      padding: spacing.size12,
      marginBottom: spacing.size12,
      overflow: 'auto',
      color: palette.black,
      lineHeight: spacing.size16,
      fontSize: theme.fonts.small.fontSize,
    },
    clarityContainer: {
      border: `1px solid ${palette.neutralLight}`,
      borderRadius: 10,
      height: 'fit-content',
    },
    claritySection: {
      padding: spacing.size32,
    },
    claritySectionTitle: {
      ...fonts.size20,
    },
    panelPending: {
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.neutralLighterAlt,
      fontSize: theme.fonts.small.fontSize,
      fontWeight: FontWeights.light,
      marginTop: spacing.size12,
      marginButtom: spacing.size6,
      paddingTop: spacing.size12,
      paddingBottom: spacing.size12,
      paddingRight: spacing.size6,
      paddingLeft: spacing.size6,
    },
    panelPendingText: {
      backgroundColor: theme.palette.neutralLighterAlt,
    },
  });
};

export function getClassNames<IScriptStyles>(props?: IScriptStyles): ScriptStyles {
  return getStyles({ theme: getTheme(), ...props });
}
