import { orchestrator } from 'satcheljs';
import { EntityEvaluation, getAppStore, listEntity } from '../../../../../@data';
import { AppealHistory } from '../../../../../@data/store/schema/models/AppealHistory';
import { fetchAppealHistory, setAppealHistory } from '../actions';

orchestrator(fetchAppealHistory, async () => {
  const { publisher, userContext } = getAppStore();

  // fetch appeal history
  if (publisher && userContext) {
    try {
      const appealHistory: AppealHistory[] = await listEntity([new EntityEvaluation(), publisher], userContext, AppealHistory);
      setAppealHistory(appealHistory);
    } catch (e) {
      console.log('Failed to fetch appeal history');
    }
  }
});
