import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, LifeCycleStatus, onLoadingChanged, onNotificationChanged, onSavingChanged } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { adQualityLocales } from '../../../../../config/locale';
import messages from '../../AdReview/AdCreativePage.messages';
import { onSAMBlockAdsDataChanged, onSAMBlockAdsItemStatusChanged } from '../actions';
import { getSAMBlockSummaryData, toggleSAMBlockedAds } from '../services/AdBlockService';
import { SAMBlockSummaryData } from '../store/schema/models';
import { getStore } from '../store/store';

orchestrator(onSAMBlockAdsItemStatusChanged, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const status = msg.status;
  const creativeAdId = msg.data.creativeAdId;
  const locale = msg.data.locale;

  let notification: INotificationBarItem = {};
  let params;

  if (publisher && publisher.id && userContext && creativeAdId && locale) {
    params = new URLSearchParams([
      ['targetCustomerId', publisher.id.toString()],
      ['creativeId', creativeAdId],
      ['locale', locale],
    ]);

    onSavingChanged(true);
    onLoadingChanged(true);

    try {
      let result, filterStatusId;

      if (status === LifeCycleStatus.Inactive) {
        // Set the filter to the 'Disabled' status
        filterStatusId = '4';
        result = await toggleSAMBlockedAds(params, filterStatusId);
      } else if (status === LifeCycleStatus.Active) {
        // Set the filter to the 'Applied' status
        filterStatusId = '1';
        result = await toggleSAMBlockedAds(params, filterStatusId);
      }

      if (result) {
        const active = getStore().active;
        const samBlockSummaryParams = new URLSearchParams([
          ['targetCustomerId', publisher.id.toString()],
          ['NumImages', 2],
        ]);
        let samBlockSummaryData: SAMBlockSummaryData[] = [];
        if (active.selectedMarket) {
          samBlockSummaryData = await getSAMBlockSummaryData([['publisher', publisher.id!.toString()]], samBlockSummaryParams);
          if (active.selectedBlockedMarketId !== undefined) {
            if (active.selectedBlockedMarketId === 0) {
              samBlockSummaryData.filter((blockSummary) => blockSummary.locale === 'global');
            } else {
              samBlockSummaryData.filter(
                (blockSummary) =>
                  blockSummary.locale === 'global' || blockSummary.locale === adQualityLocales[active.selectedBlockedMarketId!]
              );
            }
          }
        }

        samBlockSummaryData.forEach((item) => {
          item.status = item.filterStatus === 'Applied' ? (item.status = LifeCycleStatus.Active) : (item.status = LifeCycleStatus.Inactive);
        });

        onSAMBlockAdsDataChanged(samBlockSummaryData);

        notification = {
          text: `Update blocked ads ${creativeAdId} status success`,
          messageBarType: MessageBarType.success,
        };

        onNotificationChanged(notification);
      }
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityUpdateBlockedAdsStatusFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
      onSavingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
