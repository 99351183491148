import { IconButton } from '@fluentui/react';
import React, { useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import messages from '../../Report.messages';
import { getClassNames } from '../ReportCard/ReportCard.styles';
import { ChartGrain, ChartTypes, IChartButton } from '../ReportCard/ReportCard.types';
export interface IChartTypeSelectorProps extends InjectedIntlProps {
  allowedChartTypes: IChartButton[];
  currentChartType: ChartTypes;
  onChartTypeChange: (grain: ChartTypes) => void;
  setCurrentChartGrain: (grain: ChartGrain) => void;
  enableHideChartsButton?: boolean;
  onChartsHideClicked: (hidden: boolean) => void;
}

export const ChartTypeSelector = injectIntl<IChartTypeSelectorProps>(
  ({
    currentChartType,
    onChartTypeChange,
    allowedChartTypes,
    setCurrentChartGrain,
    enableHideChartsButton,
    onChartsHideClicked,
    intl: { formatMessage },
  }: IChartTypeSelectorProps) => {
    const { active, buttonBorder } = getClassNames();
    const [chartsHidden, setChartsHidden] = useState(false);
    const onHideChartsClick = () => {
      const newValue = !chartsHidden;
      onChartsHideClicked(newValue);
      setChartsHidden(newValue);
    };
    return (
      <>
        {allowedChartTypes.map((chart, index) => {
          const onChartTypeIconClick = () => {
            onChartTypeChange(chart.chartType);
          };
          return (
            <IconButton
              key={index}
              title={formatMessage(messages.chartSelectIcon, { chartType: chart.chartType })}
              iconProps={{ iconName: chart.iconName }}
              className={currentChartType === chart.chartType && !chart.disabled && !chartsHidden ? active : buttonBorder}
              onClick={onChartTypeIconClick}
              disabled={chart.disabled || chartsHidden}
              data-test-id={chart.dataTestId}
            />
          );
        })}
        {!!enableHideChartsButton && (
          <IconButton
            title={formatMessage(messages.toggleChartIcon)}
            iconProps={{ iconName: 'blocked' }}
            className={chartsHidden ? active : buttonBorder}
            onClick={onHideChartsClick}
          />
        )}
      </>
    );
  }
);
