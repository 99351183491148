import { Model, isTythonSelfServeUser } from '../../../../../@data';
import { CommandBarActions, IBuildCommandBarProps } from '../../../../../layout/GridPageV2';
import { getTythonSiteCreatePageRoute } from '../../../../../utils/routeUtils';
import { setPropertiesFetching } from '../__data__/mutatorActions';
import { getGridPropertyStore } from '../__data__/store/store';
import { getClarityCommandBarItems } from '../components/ClarityPanel/utils/getCommandBarItems';
import messages from '../messages';
import { getFilterItems } from './getFilterItems';

export function getCommandBarItems(formatMessage): IBuildCommandBarProps<Model> | undefined {
  const { data, isLoading } = getGridPropertyStore();

  const onUpdatePropertyEnd = () => setPropertiesFetching(false);
  const nonTythonItems: IBuildCommandBarProps<Model> | undefined =
    (data && data.length !== 0) || isLoading
      ? {
          primaryButtonLabel: messages.create,
          includeActions: [CommandBarActions.new],
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.edit,
            CommandBarActions.activate,
            CommandBarActions.deactivate,
            CommandBarActions.delete,
            CommandBarActions.download,
            CommandBarActions.modifyColumns,
            CommandBarActions.clarity,
          ],
          getCustomCommandBarItems: (getSelectedItems) => getClarityCommandBarItems(getSelectedItems, formatMessage),
          filterItems: getFilterItems(formatMessage),
        }
      : undefined;

  const tythonItems: IBuildCommandBarProps<Model> | undefined =
    (data && data.length !== 0) || isLoading
      ? {
          primaryButtonLabel: messages.siteCreate,
          primaryButtonUrl: getTythonSiteCreatePageRoute(),
          includeActions: [CommandBarActions.new],
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.addFilter,
            CommandBarActions.edit,
            // CommandBarActions.activate,
            // CommandBarActions.deactivate,
            CommandBarActions.delete,
            CommandBarActions.download,
            CommandBarActions.modifyColumns,
          ],
          onDeactivateDialogDismiss: onUpdatePropertyEnd,
          filterItems: getFilterItems(formatMessage),
          deleteLabels: {
            title: messages.deletePropertyPopupTitle,
            description: messages.deletePropertyPopupDescription,
          },
        }
      : undefined;

  return isTythonSelfServeUser() ? tythonItems : nonTythonItems;
}
