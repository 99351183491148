import { FocusZone, FocusZoneDirection } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useRef } from 'react';
import { getClassNames } from './FilterListBar.styles';
import { FilterListItem } from './FilterListItem';

export interface IFilterListBarProps {
  filterItems: IFilterItem[];
}
export interface IFilterItem {
  key: string;
  title: string;
  onRemove: (key: string) => void;
  onClick: (key: string) => void;
}
const DATA_METRIC_ID = 'filter-list-bar';
export const FilterListBar = ({ filterItems }: IFilterListBarProps) => {
  const classNames = getClassNames();
  const [isBarFocused, { setFalse: setBarUnfocused, setTrue: setBarFocused }] = useBoolean(false);
  const filterBarRef = useRef<HTMLDivElement>(null);
  return (
    <div
      tabIndex={0}
      ref={filterBarRef}
      onFocus={(ev) => {
        setBarFocused();
      }}
      onBlur={() => {
        setBarUnfocused();
      }}
      className={isBarFocused ? classNames.filterBarFocused : classNames.filterBarUnFocused}
      data-metric={DATA_METRIC_ID}
    >
      <FocusZone
        className={classNames.filterListPageStyle}
        direction={FocusZoneDirection.horizontal}
        isCircularNavigation={true}
        aria-label={''}
        aria-describedby="filterListAriaDescription"
      >
        {filterItems.map((item, index) => {
          return (
            <FilterListItem
              key={`filterFocusedList-${index}`}
              filterKey={item.key}
              id={item.key}
              data-list-index={index}
              title={item.title}
              isFocused={isBarFocused}
              onClick={(key) => isBarFocused && item.onClick(key)}
              onRemove={(key) => isBarFocused && item.onRemove(key)}
            />
          );
        })}
      </FocusZone>
    </div>
  );
};
