import { action } from 'satcheljs';
import { IGridV2Column } from '../../../../../layout/GridPageV2';

export const onManagePageInitialized = action('onManagePageInitialized', (defaultDataGridColumns: IGridV2Column[]) => ({
  defaultDataGridColumns,
}));

export const onUserInvitationsGridInitialized = action('onUserInvitationsGridInitialized', (defaultDataGridColumns: IGridV2Column[]) => ({
  defaultDataGridColumns,
}));
