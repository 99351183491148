import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'adfilter.create',
    defaultMessage: 'Create Ad Filter',
  },
  notFound: {
    id: 'adfilter.notFound',
    defaultMessage: 'No Ad Filters found for this account, create one.',
  },
  deactivateTitle: {
    id: 'adfilter.deactivateTitle',
    defaultMessage: 'Do you want to deactivate ad filter(s)?',
  },
  name: {
    id: 'adfilter.name',
    defaultMessage: 'Ad filter name',
  },
  id: {
    id: 'adfilter.id',
    defaultMessage: 'Ad filter ID',
  },
  scope: {
    id: 'adfilter.scope',
    defaultMessage: 'Scope',
  },
  excludedURL: {
    id: 'adfilter.excludedURL',
    defaultMessage: 'Excluded URLs',
  },
  excludedKeywords: {
    id: 'adfilter.excludedKeywords',
    defaultMessage: 'Excluded keywords',
  },
  channelsNumber: {
    id: 'adfilter.ChannelsNumber',
    defaultMessage: '# of channels',
  },
  channelNames: {
    id: 'adfilter.channelNames',
    defaultMessage: 'Channel names',
  },
  adfilters: {
    id: 'adfilters',
    defaultMessage: 'Ad filters',
  },
  dateRangeFilterName: {
    id: 'adfilter.dateRangeFilterName',
    defaultMessage: 'Date range',
  },
  startTime: {
    id: 'adfilter.startTime',
    defaultMessage: 'Start time',
  },
  endTime: {
    id: 'adfilter.endTime',
    defaultMessage: 'End time',
  },
  actions: {
    id: 'adfilter.actions',
    defaultMessage: 'Actions',
  },
  zeroResultTitle: {
    id: 'channel.zeroResultTitle',
    defaultMessage: 'No filters yet',
  },
  zeroResultDescription: {
    id: 'channel.zeroResultDescription',
    defaultMessage: 'You haven’t created any filters yet',
  },
  noPublisherSelected: {
    id: 'property.noPublisherSelected',
    defaultMessage: 'A publisher isn’t selected yet. To start managing filters, search for a publisher..',
  },
});
