import { Log } from '../../../logging/src';
import { UnauthorizedInternalUserPageRoute } from '../../Routes';

export const PubCenterUserRoles = [41, 42, 43, 49, 61, 62, 91, 116, 124, 125, 156, 169, 170, 171, 172, 174];

export function checkInternalUserAccess(roleIds?: number[]) {
  if (!roleIds) {
    return;
  }

  const usersPubCenterRoles = roleIds.filter((roleId: number) => PubCenterUserRoles.includes(roleId));

  if (usersPubCenterRoles.length > 0) {
    return;
  }

  const UnauthorizedInternalUserRoute = encodeURI(
    window.location.protocol + '//' + window.location.host + UnauthorizedInternalUserPageRoute
  );
  Log.error(
    `User does not have access to PubCenter with following roles: ${roleIds}. Redirecting to ${UnauthorizedInternalUserPageRoute} error page`
  );

  window.location.replace(UnauthorizedInternalUserRoute);
}
