import { observer } from 'mobx-react';
import * as React from 'react';
import { InjectedIntlProps, IntlProvider } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useI18n } from '../i18n/useI18n';
import { getAppStore } from './@data';
import './@data/mutators';
import './@data/orchestrators';
import { ApplicationRoute } from './ApplicationRoute';
import useWindowsAppFeatureFlag from './hooks/useWindowsAppFeatureFlag';
import { getStore } from './pages/ad-management/Account/Settings/@data/store/store';

export interface IAppProps extends RouteComponentProps, InjectedIntlProps {}

export const App = withRouter(
  observer(() => {
    const { systemPreferences } = getStore().active;
    const { publisher, userContext } = getAppStore();
    const { locale, messages } = useI18n({ systemPreferences, publisher, userContext });
    useWindowsAppFeatureFlag(userContext, publisher);

    return (
      <IntlProvider locale={locale} messages={messages} onError={() => null}>
        <ApplicationRoute />
      </IntlProvider>
    );
  })
);
