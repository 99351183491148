import { ActionButton, IconButton } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import messages from '../../App.messages';
import { NullableJSXElement } from '../../types';
import { CheckboxTree } from '../CheckboxTree';
import { ConditionalTooltip } from '../ConditionalTooltip';
import { getClassNames } from './CheckboxTreeWithSelectionBox.styles';
import { ICheckboxTreeWithSelectionBoxProps, ISelection } from './CheckboxTreeWithSelectionBox.types';

export const CheckboxTreeWithSelectionBox = injectIntl(function CheckboxTreeWithSelectionBoxComponent({
  selectionList,
  selectionTitle,
  disabledSelectionTooltipText,
  onRemoveSelection,
  onRenderSelectionItem,
  onRemoveAllSelection,
  intl: { formatMessage },
  errorMessage,
  ...restProps
}: ICheckboxTreeWithSelectionBoxProps & InjectedIntlProps) {
  const {
    containerStyle,
    selectionBoxContainerStyle,
    disabledItemStyle,
    selectedItemStyle,
    selectedItemsContainerStyle,
    titleContainerStyle,
    removeAllButtonStyle,
    checkboxTreeContainerStyle,
    errorMessageStyle,
    rootStyle,
  } = getClassNames({ isError: !!errorMessage });

  function getActiveItems() {
    return selectionList.filter((item) => !item.isDisabled);
  }

  function renderSelectedItem({ isDisabled, key, title }: ISelection): JSX.Element | undefined {
    return (
      <div className={`${selectedItemStyle} ${isDisabled ? disabledItemStyle : ''}`}>
        <div className="title">{title}</div>
        <IconButton
          iconProps={{ iconName: 'Delete' }}
          id={key}
          onClick={() => onRemoveSelection(key)}
          disabled={isDisabled}
          ariaLabel={formatMessage(messages.delete)}
        />
      </div>
    );
  }

  function renderSelectionBox(): NullableJSXElement {
    return (
      <div className={selectionBoxContainerStyle}>
        <div className={titleContainerStyle}>
          {selectionTitle}
          {onRemoveAllSelection && getActiveItems().length > 0 && (
            <ActionButton styles={{ root: removeAllButtonStyle }} onClick={onRemoveAllSelection}>
              Remove all
            </ActionButton>
          )}
        </div>
        <div className={selectedItemsContainerStyle}>
          {selectionList.map((selectedItem) => {
            const { isDisabled, key } = selectedItem;
            return (
              <ConditionalTooltip key={key} showTooltip={isDisabled} content={disabledSelectionTooltipText} targetSelector={`#${key}`}>
                {renderSelectedItem(selectedItem)}
              </ConditionalTooltip>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={rootStyle}>
      <div className={containerStyle}>
        <CheckboxTree {...restProps} styleProps={{ checkboxTreeContainerStyle }} />
        {renderSelectionBox()}
      </div>
      {errorMessage && <div className={errorMessageStyle}>{errorMessage}</div>}
    </div>
  );
});
