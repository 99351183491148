import { addMethod, string, StringSchema } from 'yup';
import { Property } from '../../@data';
import { IPropertyViewModel } from '../../pages/ad-management/Property/Create/__data__/store/schema';

declare module 'yup' {
  // tslint:disable-next-line:interface-name
  interface StringSchema {
    domain(message?: TestOptionsMessage): StringSchema;
    oldDomain(message?: TestOptionsMessage): StringSchema;
    duplicateDomain(sites: Property[], isEditMode: boolean, activeSite: IPropertyViewModel): StringSchema;
    name(message?: TestOptionsMessage): StringSchema;
    duplicateName(sites: Property[], activeSite: IPropertyViewModel): StringSchema;
  }
}
;

export function domainInitialize(defaultMessage: string) {
  const rDomain = /^(https?:\/\/)?([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,}?\/?.*(?<!\/)$|^(\*\.([a-zA-Z0-9]+)((.[a-zA-Z0-9]+)?)+)$/i
  const pattern = new RegExp('^(?!://)([a-zA-Z0-9-_]+.)*[a-zA-Z0-9][a-zA-Z0-9-_]+.[a-zA-Z]{2,11}?$', 'i');

  function domainStringValidator(this: StringSchema, message?: string) {
    return this.matches(rDomain, { message: message ?? defaultMessage, excludeEmptyString: true });
  }

  function tythonDomainStringValidator(this: StringSchema, message?: string) {
    return (
      this.matches(rDomain, { message: message ?? defaultMessage, excludeEmptyString: true }) &&
      this.matches(pattern, { message: message ?? defaultMessage, excludeEmptyString: true })
    );
  }

  addMethod(string, 'oldDomain', domainStringValidator);
  addMethod(string, 'domain', tythonDomainStringValidator);
}

export function duplicateDomainInitialize(defaultMessage: string) {
  // This function retrieves the domain name from the already saved site domains, if the sites saved contains 'www.' or 'https://'
  // it just retrieves the domain name without 'www.'
  function getDomainNameFromURL(websiteURL: string): string {
    let getTheHostName: string;
    if (websiteURL.indexOf('//') > -1) {
      getTheHostName = websiteURL.split('/')[2];
    } else {
      getTheHostName = websiteURL.split('/')[0];
    }
    getTheHostName = getTheHostName.split(':')[0];
    getTheHostName = getTheHostName.split('?')[0];
    getTheHostName = getTheHostName.split('www.')[1] ?? getTheHostName;
    return getTheHostName;
  }

  function checkSiteDomainDuplicates(this: StringSchema, sites: Property[], isEditMode: boolean, activeSite?: IPropertyViewModel) {
    if (!isEditMode) {
      const sitesDomain = sites.filter((site) => site.url && site.id !== activeSite?.id).map((site) => getDomainNameFromURL(site.url));

      return this.test('domain-duplicate', defaultMessage, (value: string) => {
        const domainName = value.split('www.')[1] ?? value;
        return !sitesDomain.some((site) => site === domainName);
      });
    } else {
      return this.test('domain-duplicate', defaultMessage, () => true);
    }
  }

  addMethod(string, 'duplicateDomain', checkSiteDomainDuplicates);
}

export function nameInitialize(defaultMessage: string) {
  const nameRegex = /^[^\d^\W].*$/i;

  function nameStringValidator(this: StringSchema, message?: string) {
    return this.matches(nameRegex, { message: message ?? defaultMessage, excludeEmptyString: true });
  }

  addMethod(string, 'name', nameStringValidator);
}

export function duplicateNameInitialize(defaultMessage: string) {
  function checkSiteNameDuplicates(this: StringSchema, sites: Property[], activeSite?: IPropertyViewModel) {
    return this.test('name-duplicate', defaultMessage, (value: string) => {
      return !sites.filter((site) => site.id !== activeSite?.id).some((site) => site.name === value);
    });
  }

  addMethod(string, 'duplicateName', checkSiteNameDuplicates);
}
