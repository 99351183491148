import { date, object, string } from 'yup';
import { getStore } from '../store';
import { IAdCreativeViewModel } from './IAdCreativeViewModel';

export function adCreativeViewErrorModel() {
  return object<IAdCreativeViewModel>().shape({
    startDate: date().required(),
    endDate: date().required(),
    searchBoxValue:
      Math.abs(getStore().active.endDate?.getTime()! - getStore().active.startDate?.getTime()!) > 0
        ? string().trim().required()
        : string().trim(),
  });
}
