import { Link } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, MessageValue } from 'react-intl';
import { AdsTxtStatus, Property } from '../../../../../@data';
import AppMessages from '../../../../../App.messages';
import { ADS_DOT_TEXT_HELP_URL, APP_NAME_NEW, CONNECT_SITE_HELP_URL } from '../../../../../constants/AppConstants';
import { setCodePanelVisibility } from '../__data__/mutatorActions';
import messages from '../messages';

type Statuses = {
  [key: string]: Status;
};

type Status = {
  defaultMessage: JSX.Element | string | null;
  issues: Issues;
};

type Issues = Array<{ type: string; isActive: boolean; message: JSX.Element | string }>;

export const getServingStatusCalloutContent = (
  formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor, values?: { [key: string]: MessageValue }) => string,
  isPublisherRejected: boolean,
  item: Property
): JSX.Element | null => {
  if (item === undefined || item.servingConfig === undefined) {
    return null;
  }

  const { servingStatus, scriptStatus, adsDotTxtStatus } = item.servingConfig;

  const toolTipContent: Statuses = {
    Serving: {
      defaultMessage: formatMessage(messages.siteServingAds),
      issues: [
        {
          type: 'noAdsTxt',
          isActive: (adsDotTxtStatus && AdsTxtStatus[adsDotTxtStatus.toString()] === AdsTxtStatus.NotFound) || false,
          message: (
            <FormattedMessage
              {...messages.siteServingAds}
              values={{
                more: (
                  <Link href={ADS_DOT_TEXT_HELP_URL} target="_blank" rel="noreferrer noopener">
                    {formatMessage(AppMessages.readMore)}
                  </Link>
                ),
              }}
            />
          ),
        },
        {
          type: 'noScript',
          isActive: scriptStatus === false,
          message: (
            <FormattedMessage
              {...messages.siteServingAdsWithoutScript}
              values={{
                snippet: (
                  <Link
                    onClick={() => setCodePanelVisibility(true, item?.id.toString(), item?.servingConfig?.clarityConfig?.clarityStatus)}
                  >
                    {formatMessage(messages.codeSnippet)}
                  </Link>
                ),
              }}
            />
          ),
        },
      ],
    },
    Pending: {
      defaultMessage: null,
      issues: [
        {
          type: 'siteReview',
          isActive: isPublisherRejected === true,
          message: formatMessage(messages.sitePendingWithApprovedProfile),
        },
        {
          type: 'domainReview',
          isActive: isPublisherRejected === false,
          message: formatMessage(messages.sitePendingWithoutApprovedProfile),
        },
      ],
    },
    NotServing: {
      defaultMessage: (
        <FormattedMessage
          {...messages.siteRejected}
          values={{
            guidelines: (
              <Link href={CONNECT_SITE_HELP_URL} target="_blank" rel="noreferrer noopener">
                {formatMessage(messages.siteApprovalGuideLines)}
              </Link>
            ),
            appName: APP_NAME_NEW,
          }}
        />
      ),
      issues: [],
    },
  };

  const status = toolTipContent[servingStatus];
  if (status === undefined) {
    return null;
  }

  const getStatusMessage = (toolTipStatus: Status): JSX.Element | string | null | undefined => {
    const issues = toolTipStatus.issues;
    return issues.length > 0 ? issues.find((issue) => issue.isActive)?.message : toolTipStatus.defaultMessage;
  };

  return <>{getStatusMessage(status)}</>;
};
