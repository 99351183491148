import { INotificationBarStyleProps, INotificationBarStyles } from './NotificationBar.types';

export const getStyles = (props: INotificationBarStyleProps): INotificationBarStyles => {
  const { theme } = props;

  return {
    root: {
    },
    subComponentStyles: {
      messageBar: {
        root: {
          marginBottom: theme.spacing.s1,
          selectors: {
            '&:last-child': {
              marginBottom: 0
            }
          }
        }
      }
    }
  };
};
