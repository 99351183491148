import { IPubcenterSpacing } from "../../../theme";
import { IDateTimePickerStyleProps, IDateTimePickerStyles } from "./DateTimePicker.types";

export const getStyles = (props: IDateTimePickerStyleProps): IDateTimePickerStyles => {
  const { theme } = props
  const spacing = theme.spacing as IPubcenterSpacing;
  // In future we can use datePickerWidth to customise the width of the date time picker

  return {
    root: {
        display: 'flex',
        marginBottom: spacing.size16,
        maxWidth: props.datePickerWidth ? props.datePickerWidth : 640,
    },
    colonSeperator: {
      margin: '32px 8px 0 8px'
    },
    hourPicker: {
      root: {
        marginTop: '29px',
        marginLeft: '16px',
        maxWidth: 133,
      }
    },
    minutesPicker: {
      root: {
        marginTop: '29px',
        maxWidth: 133,
      }
    },
    secondsPicker: {
      marginTop: '29px',
      maxWidth: 133,
    },
    subComponentStyles: {
      datePicker: {
        root: {
          width: 320
        }
      }
    }
  }
}