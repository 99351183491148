export function pluralize(name: string): string {
  if (name === '') {
    return '';
  }
  return name.endsWith('y') ? `${name.slice(0, -1)}ies` : name.endsWith('s') ? `${name}es` : `${name}s`;
}

export function singularize(name: string): string {
  if (name === '') {
    return '';
  }
  return name.endsWith('ies')
    ? `${name.slice(0, -3)}y`
    : name.endsWith('es')
    ? `${name.slice(0, -2)}`
    : name.endsWith('s')
    ? `${name.slice(0, -1)}`
    : name;
}

export function isEmpty(str: string | undefined): boolean {
  return typeof str === 'undefined' || !str || 0 === str.length;
}

export function buildHtmlId(id: string) {
  return id.replace(/[\s.]/g, '');
}

/**
 *  Converts ',', '\r' and '\n' separated string to list of strings
 * Examples:
 *      "'example.com\nexample.org\nexample.net\n'" => ['example.com', 'example.org', 'example.net']
 *      "'example.com,example.org,example.net'" => ['example.com', 'example.org', 'example.net']
 */
export function stringToList(str: string | undefined): string[] {
  const resultList: string[] = [];
  if (str !== undefined || str === '') {
    str
      .replace(/(\r|\n)/gm, ',') // replaces newline and carriage return characters with commas
      .split(',') // splits string into array of strings, using comma as the delimiter
      .map((s) => s.trim()) // trims whitespace from each string
      .filter((u) => u.length > 0) // removes empty strings
      .forEach((u) => resultList.push(u.trim())); // trims whitespace from each string
  }

  return resultList;
}
