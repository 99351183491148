import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Route, RouteComponentProps } from 'react-router-dom';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import './@data/mutators';
import './@data/orchestrators';
import { AdCreativePage } from './AdReview/AdCreativePage';

export const AdQualityPage = injectIntl(function AdQualityPageComponent(props: RouteComponentProps & InjectedIntlProps): JSX.Element {
  React.useEffect(() => {
    onCurrentRouteChanged(RouteName.adquality, props.intl.formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Route exact path={`${props.match.url}`} component={AdCreativePage} />
    </React.Fragment>
  );
});
