/* istanbul ignore file */
import '@bingads-webui-help/apex';
import $ from 'jquery';
import { uiConfig } from '../../config/ui.config';

let prevLocale;

export function initApexPlatform(supportButton, locale) {
  if (prevLocale !== locale) {
    const apexInitOptions = {
      // The project name, ask the writers team for your project name
      Project: 'PubCenter_v4', //  2024-03-11-06-31-14-PubCenter-v4
      // The language of help content, ask the writers team for supported languages of your project
      Market: locale,
      EndPoint: uiConfig.getApexInitUrl(),
      // Set it to true to enable inline help popup
      InitPopup: true,
      // Whether to init on ready, need to set to false for basic usage
      InitOnReady: false,
      SupportOptions: { template: supportButton },
    };

    $.fn.InitApexPlatform(apexInitOptions);
    prevLocale = locale;
  }
}
