import { createStore } from 'satcheljs';
import { IStringDictionary, User, UserInvitation } from '../../../../../@data';
import { EN_LOCALE_KEY, LOCAL_STORAGE_LOCALE } from '../../../../../constants';
import { IUserInviteViewModel } from './schema/UserInviteViewModel';

export interface IUserInvitationState {
  userInviteErrors?: IStringDictionary;
  userInvitePanelOpen: boolean;
  selectedItem?: User;
  userInvitations: UserInvitation[];
  userInviteViewModel: IUserInviteViewModel;
}

export const getUserInvitationStore = createStore<IUserInvitationState>('UserInvitationState', {
  userInviteErrors: {},
  userInvitePanelOpen: false,
  userInvitations: [],
  userInviteViewModel: {
    recipientPreferredLang: localStorage.getItem(LOCAL_STORAGE_LOCALE) ?? EN_LOCALE_KEY,
  },
});
