import { FontSizes, FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../../../theme';
import { WIDTH } from '../../SignUpPage';
import { IFinishStyles } from './Finish.types';

export const getClassNames = (): IFinishStyles => {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const palette = theme.palette as IPubcenterPalette;
  const spacing = theme.spacing as IPubcenterSpacing;
  const font = FontSizes;
  const weight = FontWeights;

  return mergeStyleSets({
    rootStyle: {
      position: 'relative',
      marginTop: spacing.size12,
      width: WIDTH,
      left: 'auto',
      color: palette.black,
    },
    titleStyle: {
      fontSize: font.size20,
      fontWeight: weight.semibold,
      textAlign: 'left',
      marginBottom: spacing.size12,
    },
    contentStyle: {
      fontSize: font.size14,
      fontWeight: weight.semibold,
      marginTop: spacing.size8,
      marginBottom: spacing.size8,
      textAlign: 'left',
      lineHeight: '22px',
    },
    subcontentStyle: {
      fontSize: font.size14,
      marginTop: spacing.size8,
      marginBottom: spacing.size8,
      textAlign: 'left',
      width: 'calc(100% - 10px)',
    },
  });
};
