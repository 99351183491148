import { defineMessages } from 'react-intl';

export default defineMessages({
  finishTitle: {
    id: 'finish.title',
    defaultMessage: 'One last step to go',
  },
  email: {
    id: 'finish.email',
    defaultMessage: 'Email account',
  },
  accountType: {
    id: 'finish.accountType',
    defaultMessage: 'Account type',
  },
  customizedHelp: {
    id: 'finish.customizedHelp',
    defaultMessage: 'Get more out of {appName}',
  },
  customizedHelpYes: {
    id: 'finish.customizedHelp.yes',
    defaultMessage: 'Yes, I would like information, tips, and offers about {appName}.',
  },
  customizedHelpNo: {
    id: 'finish.customizedHelp.no',
    defaultMessage: 'No, I would not like information, tips, and offers about {appName}.',
  },
  paymentTerritory: {
    id: 'finish.paymentTerritory',
    defaultMessage: 'Your payment country/region',
  },
  paymentTerritoryDefault: {
    id: 'finish.paymentTerritory.default',
    defaultMessage: 'United States',
  },
  infoTitle: {
    id: 'finish.infoTitle',
    defaultMessage: 'Please review your selections before getting started.',
  },
  orgName: {
    id: 'finish.orgName',
    defaultMessage: 'Organization name',
  },
  userName: {
    id: 'finish.userName',
    defaultMessage: 'User name',
  },
});
