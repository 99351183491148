/**
 * Each element of a domain name separated by [.] is called a “label.”
 * The maximum length of each label is 63 characters, and a full domain name can have a maximum of 253 characters.
 * Alphanumeric characters and hyphens can be used in labels, but a domain name must not commence or end with a hyphen or dot.
 */
export const VALID_DOMAIN_REGEXP = '^([^-\\.][^:/?#=\\s\\.]+){1}(\\.([^:/?#=\\s\\.]+[^-\\.])){1,2}$';

export const DOMAIN_MAX_LENGTH = 253;

export function getCurrentHashValue(): string {
  return (window.location.hash || '').replace('#', '');
}

export function isValidDomain(domain) {
  const urlRegexp = new RegExp(VALID_DOMAIN_REGEXP);
  return domain.length < DOMAIN_MAX_LENGTH && urlRegexp.test(domain);
}

export function getUrlSearchParameter(urlQuery?: string, parameter?: string) {
  if (!urlQuery || !parameter) {
    return undefined;
  }
  const urlParams = new URLSearchParams(urlQuery.substring(1));
  return urlParams.get(parameter) || '';
}

export const isLocationPubCenter = () => {
  return window.location.href.indexOf('pubcenter') > -1 || window.location.host === 'localhost:3000';
};
