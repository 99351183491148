import { Image, Shimmer } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { injectIntlAndObserver } from '../../../../../utils';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import { getStore } from '../../@data/store/store';
import messages from './SetupCard.messages';
import { getClassNames } from './SetupCard.styles';
import { ISetupCard } from './SetupCardUtils';
import { getShimmerSetupCard } from './utils/getShimmerSetupCard';
import { getProgressBar } from './utils/renderProgressBar';

export interface ISetupCardProps extends InjectedIntlProps {
  card: ISetupCard;
}

export const SetupCard = injectIntlAndObserver(function SetupCardComponent({ intl: { formatMessage }, card }: ISetupCardProps) {
  // need to be loaded from store, either the value itself or status of other entities are retrieved
  const { adUnitStatus, siteStatus, profileStatus, isSetupDone } = getStore();
  const { active } = getGoogleAdSenseStore();
  const {
    titleStyle,
    statusStyle,
    setupCardContainerStyle,
    imageContainerStyle,
    imageStyle,
    setupContentStyle,
    descriptionStyle,
    setupCardButtonStyle,
  } = getClassNames({ cardType: card.cardType });

  const isStatusFieldsHaveValue = (statusAdUnit, statusSite, statusProfile) => {
    return statusAdUnit !== undefined && statusSite !== undefined && statusProfile !== undefined;
  };
  const showContent = isSetupDone
    ? active.connectionStatus !== undefined
      ? true
      : false
    : isStatusFieldsHaveValue(adUnitStatus, siteStatus, profileStatus);

  return (
    <div className={setupCardContainerStyle}>
      {showContent ? (
        <>
          <div className={imageContainerStyle}>{card.image && <Image src={card.image} className={imageStyle} alt={card.imageAlt} />}</div>
          <div className={setupContentStyle}>
            <span className={titleStyle}>{card.title}</span>
            {getProgressBar(card.steps, card.status)}
            {card.status && <span className={statusStyle}>{formatMessage(messages[card.status])}</span>}
            <p className={descriptionStyle}>{card.description}</p>
          </div>
          <div className={setupCardButtonStyle}>{card.button}</div>
        </>
      ) : (
        <Shimmer customElementsGroup={getShimmerSetupCard()} style={{ minHeight: '320px' }} />
      )}
    </div>
  );
});
