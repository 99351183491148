import { orchestrator } from 'satcheljs';
import { setAuditLogs, setPageSize, setTotalPages, setTotalRecords } from '../actions';
import { onAuditHistoryPageInitialized } from '../actions/actions';
import { fetchAuditLogs } from '../services/fetchAuditLogs';

orchestrator(onAuditHistoryPageInitialized, async () => {
  const responseJson = await fetchAuditLogs();

  if (responseJson) {
    const { changeLogs, pageSize, totalPages, totalRecords } = responseJson;

    setPageSize(pageSize);
    setTotalPages(totalPages);
    setTotalRecords(totalRecords);
    setAuditLogs(changeLogs);
  } else {
    // resetting data in case publisher or user context is null or API failure
    setPageSize(10);
    setTotalPages(0);
    setTotalRecords(0);
    setAuditLogs([]);
  }
});
