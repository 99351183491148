import { createStore } from 'satcheljs';
import { PublisherType } from '../../../../@data/store/schema/enums/PublisherType';
import { ISignUpViewState } from './schema';
export const DEFAULT_SELECTED_ACCOUNT_TYPE = PublisherType.individual;

export const getSignUpStore = createStore<ISignUpViewState>('SignUpState', {
  active: {
    accountType: DEFAULT_SELECTED_ACCOUNT_TYPE,
  },
  errors: {},
  isTermsAndConditionsAgreed: false,
  fetchingOvdfStatus: false,
  optInPromotionalEmail: false,
});
