import { defineMessages } from 'react-intl';

export default defineMessages({
  inputChannelName: {
    id: 'admanagement.channel.inputChannelName',
    defaultMessage: 'Channel name',
  },
  inputAdunits: {
    id: 'admanagement.channel.inputAdunits',
    defaultMessage: 'Add ad units',
  },
  adunitPickerAriaDesc: {
    id: 'admanagement.channel.adunitPickerAriaDesc',
    defaultMessage: 'Select ad units to be associated with the channel'
  },
  channelId: {
    id: 'admanagement.channel.channelId',
    defaultMessage: 'Channel ID',
  },
  inputFilters: {
    id: 'admanagement.channel.inputFilters',
    defaultMessage: 'Add ad filters',
  },
  filterPickerAriaDesc: {
    id: 'admanagement.channel.filterPickerAriaDesc',
    defaultMessage: 'Select ad filters to be associated with the channel'
  },
  createChannel: {
    id: 'admanagement.channel.createTitle',
    defaultMessage: 'Create channel',
  },
  editChannel: {
    id: 'admanagement.channel.editTitle',
    defaultMessage: 'Edit channel',
  },
  updateChannel: {
    id: 'admanagement.channel.updateChannel',
    defaultMessage: 'Update channel',
  },
});
