import { TextField } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { completeAccess, hasReadAccess } from '../../../../../../../../@data/services/UserRoleService';
import { FormItem, FormState } from '../../../../../../../../components/Form';
import { injectIntlAndObserver } from '../../../../../../../../utils';
import { getCreatePropertyStore } from '../../../../__data__/store/store';
import { onApprovalStatusCommentsChanged } from '../../__data__/mutatorActions';
import { GetPropertyAccessPermission } from '../../utils/GetPropertyAccessPermissions';
import { IsDisabled } from '../../utils/IsFieldDisabled';
import messages from './messages';

export interface IPropertyApprovalStatusInputFieldProps {
  mode: FormState;
}

const PropertyApprovalStatusInputField = injectIntlAndObserver<InjectedIntlProps & IPropertyApprovalStatusInputFieldProps>(
  ({ intl: { formatMessage }, mode }) => {
    const { active, errors } = getCreatePropertyStore();
    const propertyAccessPermissions = GetPropertyAccessPermission();
    const accessLevel = propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.notes;

    if (!hasReadAccess(accessLevel)) {
      return null;
    }

    return (
      <FormItem>
        <TextField
          label={formatMessage(messages.notes)}
          required={false}
          errorMessage={errors.approvalStatusComments}
          multiline={true}
          value={active.approvalStatusComments}
          onChange={(_, value) => onApprovalStatusCommentsChanged(value || '')}
          disabled={IsDisabled(mode, accessLevel)}
        />
      </FormItem>
    );
  }
);

export default PropertyApprovalStatusInputField;
