export const ratioMetrics = [
  'WinRate',
  'eCPM',
  'CTR',
  'Viewability',
  'FillRate',
  'AdECPM',
  'AdCTR',
  'ImpressionsRate',
  'MeasurableImpressionsRate',
];
