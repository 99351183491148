import { defineMessages } from 'react-intl';

export const googleConnectorMessages = defineMessages({
  settingsTab: {
    id: 'googleConnectorMessages.settingsTab',
    defaultMessage: 'Settings',
  },
  googleAccountNotConnectedTab: {
    id: 'googleConnectorMessages.googleAccountNotConnectedTab',
    defaultMessage: 'Connect your Google accounts',
  },
  googleAccountConnectedTab: {
    id: 'googleConnectorMessages.googleAccountConnectedTab',
    defaultMessage: 'Google account connection',
  },
  googleAccountDetails: {
    id: 'googleConnectorMessages.googleAccountHeader',
    defaultMessage: 'Google account details',
  },
  googleAccountConnectionStatus: {
    id: 'googleConnectorMessages.googleAccountConnectionStatus',
    defaultMessage: 'Status',
  },
  googleAccountConnectedStatus: {
    id: 'googleConnectorMessages.googleAccountConnectedStatus',
    defaultMessage: 'Connected',
  },
  googleAccountVerifyingStatus: {
    id: 'googleConnectorMessages.googleAccountVerifyingStatus',
    defaultMessage: 'Verifying',
  },
  googleAccountFailedStatus: {
    id: 'googleConnectorMessages.googleAccountFailedStatus',
    defaultMessage: 'Failed',
  },
  googleAccountConnectionDate: {
    id: 'googleConnectorMessages.googleAccountConnectionDate',
    defaultMessage: 'Connection date',
  },
  googleAccountMediateHeader: {
    id: 'googleConnectorMessages.googleAccountMediateHeader',
    defaultMessage: 'Boost revenue for every ad',
  },
  googleAccountMediateDescription: {
    id: 'googleConnectorMessages.googleAccountMediateDescription',
    defaultMessage: `By enabling boost revenue for every ad, you can boost the value of each ad unit. You'll see this option when creating or editing your ad units.`,
  },
  googleAccountConnect: {
    id: 'googleConnectorMessages.googleAccountConnect',
    defaultMessage: 'Connect',
  },
  googleAccountDisconnect: {
    id: 'googleConnectorMessages.googleAccountDisconnect',
    defaultMessage: 'Disconnect',
  },
  googleAccountRetry: {
    id: 'googleConnectorMessages.googleAccountRetry',
    defaultMessage: 'Retry',
  },
  monetizationDetails: {
    id: 'googleConnectorMessages.monetizationDetails',
    defaultMessage: `Connect your Google accounts to ensure you are getting the highest bid possible for your ad inventory. We'll only serve our ads if we predict we can deliver a higher payment compared to Google.`,
  },
  monetizationInstructionHeader: {
    id: 'googleConnectorMessages.monetizationInstructionHeader',
    defaultMessage: 'Before connecting your Google Ads account, please make sure you have:',
  },
  monetizationFirstInstruction: {
    id: 'googleConnectorMessages.monetizationFirstInstruction',
    defaultMessage: 'An approved Google Ad Manager and Google Adsense account',
  },
  monetizationSecondInstruction: {
    id: 'googleConnectorMessages.monetizationSecondInstruction',
    defaultMessage: 'Administrative rights',
  },
  monetizationThirdInstruction: {
    id: 'googleConnectorMessages.monetizationThirdInstruction',
    defaultMessage: 'API access enabled for your Google Ad manager and Google Ad sense account',
  },
  monetizationReviewTOSandPP: {
    id: 'googleConnectorMessages.monetizationReviewTOSandPP',
    defaultMessage: 'Please review the {appName} ',
  },
  monetizationPP: {
    id: 'googleConnectorMessages.monetizationPP',
    defaultMessage: 'Privacy policy',
  },
  monetizationReviewAnd: {
    id: 'googleConnectorMessages.monetizationReviewAnd',
    defaultMessage: ' and ',
  },
  monetizationTOS: {
    id: 'googleConnectorMessages.monetizationTOS',
    defaultMessage: 'Terms of service',
  },
  grantOAuthConnectingProcessTerminated: {
    id: 'googleConnectorMessages.grantOAuthConnectingProcessTerminated',
    defaultMessage: 'Connecting process terminated',
  },
  googleConnectorMediationOperationEnabling: {
    id: 'googleConnectorMessages.googleConnectorMediationOperationEnabling',
    defaultMessage: 'Enabling',
  },
  googleConnectorMediationOperationDisabling: {
    id: 'googleConnectorMessages.googleConnectorMediationOperationDisabling',
    defaultMessage: 'Disabling',
  },
  googleConnectorMediationFailed: {
    id: 'googleConnectorMessages.googleConnectorMediationFailed',
    defaultMessage: ' mediation failed, please try again later',
  },
  googleConnectorFailedToFetchAdSenseConnectionDetails: {
    id: 'googleConnectorMessages.googleConnectorFailedToFetchAdSenseConnectionDetails',
    defaultMessage: 'Failed to fetching adSense connection details. Please try again!',
  },
  disconnectingGoogleAccountFailed: {
    id: 'googleConnectorMessages.disconnectingGoogleAccountFailed',
    defaultMessage: 'Disconnecting account failed.',
  },
  disconnectingGoogleAccountSuccess: {
    id: 'googleConnectorMessages.disconnectingGoogleAccountSuccess',
    defaultMessage: 'Your Google accounts are successfully disconnected.',
  },
  connectGoogleAccountFailed: {
    id: 'googleConnectorMessages.connectGoogleAccountFailed',
    defaultMessage: 'We’re unable to connect to your Google account. Please try again later.',
  },
  disconnectGoogleAccountFailed: {
    id: 'googleConnectorMessages.disconnectGoogleAccountFailed',
    defaultMessage: 'We’re unable to disconnect your Google account. Please try again later.',
  },
  initializeGoogleAccountFailed: {
    id: 'googleConnectorMessages.initializeGoogleAccountFailed',
    defaultMessage: 'We’re unable to access your AdSense connection details. Please try again later.',
  },
  googleAccountConnectedSuccessfully: {
    id: 'googleConnectorMessages.googleAccountConnectedSuccessfully',
    defaultMessage: 'Your Google account is successfully connected.',
  },
});
