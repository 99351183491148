import { IDictionary } from './countries';

export const LOCALE_EN: IDictionary<string> = {
  14337: 'Arabic - United Arab Emirates',
  3073: 'Arabic - Egypt',
  1025: 'Arabic - Saudi Arabia',
  1093: 'Bangla - India',
  1029: 'Czech - Czech Republic',
  1030: 'Danish - Denmark',
  3079: 'German - Austria',
  2055: 'German - Switzerland',
  1031: 'German - Germany',
  1032: 'Greek - Greece',
  '-4': 'English - United Arab Emirates',
  3081: 'English - Australia',
  4105: 'English - Canada',
  2057: 'English - United Kingdom',
  14345: 'English - Indonesia',
  6153: 'English - Ireland',
  16393: 'English - India',
  17417: 'English - Malaysia',
  5129: 'English - New Zealand',
  13321: 'English - Philippines',
  18441: 'English - Singapore',
  1033: 'English - United States',
  '-1': 'English - International',
  7177: 'English - South Africa',
  11274: 'Spanish - Argentina',
  13322: 'Spanish - Chile',
  9226: 'Spanish - Colombia',
  12298: 'Spanish - Ecuador',
  3082: 'Spanish - Spain',
  2058: 'Spanish - Mexico',
  10250: 'Spanish - Peru',
  21514: 'Spanish - United States',
  8202: 'Spanish - Venezuela',
  '-3': 'Spanish - Latin America',
  1035: 'Finnish - Finland',
  2060: 'French - Belgium',
  3084: 'French - Canada',
  4108: 'French - Switzerland',
  1036: 'French - France',
  5132: 'French - Luxembourg',
  '-2': 'French - Africa',
  1037: 'Hebrew - Israel',
  1081: 'Hindi - India',
  1038: 'Hungarian - Hungary',
  1057: 'Indonesian - Indonesia',
  1040: 'Italian - Italy',
  1041: 'Japanese - Japan',
  1042: 'Korean - Korea',
  1063: 'Lithuanian - Lithuania',
  1102: 'Marathi - India',
  1044: 'Norwegian Bokmål - Norway',
  2067: 'Dutch - Belgium',
  1043: 'Dutch - Netherlands',
  1045: 'Polish - Poland',
  1046: 'Portuguese - Brazil',
  2070: 'Portuguese - Portugal',
  1049: 'Russian - Russia',
  1053: 'Swedish - Sweden',
  1098: 'Telugu - India',
  1054: 'Thai - Thailand',
  1055: 'Turkish - Turkey',
  1066: 'Vietnamese - Vietnam',
  2052: 'Chinese, Simplified - China',
  3076: 'Chinese, Traditional - Hong Kong SAR',
  4100: 'Chinese, Simplified - Singapore',
  1028: 'Chinese, Traditional - Taiwan',
};

export const LOCALE_FR: IDictionary<string> = {
  14337: 'Arabe - Émirats arabes unis',
  3073: 'Arabe - Égypte',
  1025: 'Arabe - Arabie Saoudite',
  1093: 'Bangla - Inde',
  1029: 'Tchèque - République tchèque',
  1030: 'Danois - Danemark',
  3079: 'Allemand - Autriche',
  2055: 'Allemand - Suisse',
  1031: 'Allemand - Allemagne',
  1032: 'Grec- Grèce',
  '-4': 'Anglais - Émirats arabes unis',
  3081: 'Anglais - Australie',
  4105: 'Anglais - Canada',
  2057: 'Anglais - Royaume-Uni',
  14345: 'Anglais - Indonésie',
  6153: 'Anglais - Irlande',
  16393: 'Anglais - Inde',
  17417: 'Anglais - Malaisie',
  5129: 'Anglais - Nouvelle-Zélande',
  13321: 'Anglais - Philippines',
  18441: 'Anglais - Singapour',
  1033: 'Anglais - États-Unis',
  '-1': 'Anglais - International',
  7177: 'Anglais - Afrique du Sud',
  11274: 'Espagnol - Argentine',
  13322: 'Espagnol - Chili',
  9226: 'Espagnol - Colombie',
  12298: 'Espagnol - Équateur',
  3082: 'Espagnol - Espagne',
  2058: 'Espagnol - Mexique',
  10250: 'Espagnol - Pérou',
  21514: 'Espagnol - États-Unis',
  8202: 'Espagnol - Venezuela',
  '-3': 'Espagnol - Amérique latine',
  1035: 'Finnois - Finlande',
  2060: 'Français - Belgique',
  3084: 'Français - Canada',
  4108: 'Français - Suisse',
  1036: 'Français - France',
  5132: 'Français - Luxembourg',
  '-2': 'Français - Afrique',
  1037: 'Hébreu - Israël',
  1081: 'Hindi - Inde',
  1038: 'Hongrois - Hongrie',
  1057: 'Indonésien - Indonésie',
  1040: 'Italien - Italie',
  1041: 'Japonais - Japon',
  1042: 'Coréen - Corée',
  1063: 'Lituanien - Lituanie',
  1102: 'Marathi - Inde',
  1044: 'Bokmål norvégien - Norvège',
  2067: 'Néerlandais - Belgique',
  1043: 'Néerlandais - Pays-Bas',
  1045: 'Polonais - Pologne',
  1046: 'Portugais - Brésil',
  2070: 'Portugais - Portugal',
  1049: 'Russe - Russie',
  1053: 'Suédois - Suède',
  1098: 'Telugu - Inde',
  1054: 'Thaïlandais - Thaïlande',
  1055: 'Turc - Turquie',
  1066: 'Vietnamien - Viêt Nam',
  2052: 'Chinois, simplifié - Chine',
  3076: 'Chinois, traditionnel - RAS de Hong Kong',
  4100: 'Chinois, simplifié - Singapour',
  1028: 'Chinois, traditionnel - Taïwan',
};

export const LOCALE_DE: IDictionary<string> = {
  14337: 'Arabisch - Vereinigte Arabische Emirate',
  3073: 'Arabisch - Ägypten',
  1025: 'Arabisch - Saudi-Arabien',
  1093: 'Bangla - Indien',
  1029: 'Tschechisch - Tschechische Republik',
  1030: 'Dänisch - Dänemark',
  3079: 'Deutsch - Österreich',
  2055: 'Deutsch - Schweiz',
  1031: 'Deutsch - Deutschland',
  1032: 'Griechisch - Griechenland',
  '-4': 'Englisch - Vereinigte Arabische Emirate',
  3081: 'Englisch - Australien',
  4105: 'Englisch - Kanada',
  2057: 'Englisch - Vereinigtes Königreich',
  14345: 'Englisch - Indonesien',
  6153: 'Englisch - Irland',
  16393: 'Englisch - Indien',
  17417: 'Englisch - Malaysia',
  5129: 'Englisch - Neuseeland',
  13321: 'Englisch - Philippinen',
  18441: 'Englisch - Singapur',
  1033: 'Englisch - Vereinigte Staaten',
  '-1': 'Englisch - International',
  7177: 'Englisch - Südafrika',
  11274: 'Spanisch - Argentinien',
  13322: 'Spanisch - Chile',
  9226: 'Spanisch - Kolumbien',
  12298: 'Spanisch - Ecuador',
  3082: 'Spanisch - Spanien',
  2058: 'Spanisch - Mexiko',
  10250: 'Spanisch - Peru',
  21514: 'Spanisch - Vereinigte Staaten',
  8202: 'Spanisch - Venezuela',
  '-3': 'Spanisch - Lateinamerika',
  1035: 'Finnisch - Finnland',
  2060: 'Französisch - Belgien',
  3084: 'Französisch - Kanada',
  4108: 'Französisch - Schweiz',
  1036: 'Französisch - Frankreich',
  5132: 'Französisch - Luxemburg',
  '-2': 'Französisch - Afrika',
  1037: 'Hebräisch - Israel',
  1081: 'Hindi - Indien',
  1038: 'Ungarisch - Ungarn',
  1057: 'Indonesisch - Indonesien',
  1040: 'Italienisch - Italien',
  1041: 'Japanisch - Japan',
  1042: 'Koreanisch - Korea',
  1063: 'Litauisch - Litauen',
  1102: 'Marathi - Indien',
  1044: 'Norwegisch Bokmål - Norwegen',
  2067: 'Niederländisch - Belgien',
  1043: 'Niederländisch - Niederlande',
  1045: 'Polnisch - Polen',
  1046: 'Portugiesisch - Brasilien',
  2070: 'Portugiesisch - Portugal',
  1049: 'Russisch - Russland',
  1053: 'Schwedisch - Schweden',
  1098: 'Telugu - Indien',
  1054: 'Thailändisch - Thailand',
  1055: 'Türkisch - Türkei',
  1066: 'Vietnamesisch - Vietnam',
  2052: 'Chinesisch (vereinfacht) - China',
  3076: 'Chinesisch (traditionell) - Hongkong',
  4100: 'Chinesisch (vereinfacht) - Singapur',
  1028: 'Chinesisch (traditionell) - Taiwan',
};

export const LOCALE_IT: IDictionary<string> = {
  14337: 'Arabo - Emirati Arabi Uniti',
  3073: 'Arabo - Egitto',
  1025: 'Arabo - Arabia Saudita',
  1093: 'Bangla - India',
  1029: 'Ceco - Repubblica Ceca',
  1030: 'Danese - Danimarca',
  3079: 'Tedesco - Austria',
  2055: 'Tedesco - Svizzera',
  1031: 'Tedesco - Germania',
  1032: 'Greco - Grecia',
  '-4': 'Inglese - Emirati Arabi Uniti',
  3081: 'Inglese - Australia',
  4105: 'Inglese - Canada',
  2057: 'Inglese - Regno Unito',
  14345: 'Inglese - Indonesia',
  6153: 'Inglese - Irlanda',
  16393: 'Inglese - India',
  17417: 'Inglese - Malaysia',
  5129: 'Inglese - Nuova Zelanda',
  13321: 'Inglese - Filippine',
  18441: 'Inglese - Singapore',
  1033: 'Inglese - Stati Uniti',
  '-1': 'Inglese - Internazionale',
  7177: 'Inglese - Sudafrica',
  11274: 'Spagnolo - Argentina',
  13322: 'Spagnolo - Cile',
  9226: 'Spagnolo - Colombia',
  12298: 'Spagnolo - Ecuador',
  3082: 'Spagnolo - Spagna',
  2058: 'Spagnolo - Messico',
  10250: 'Spagnolo - Perù',
  21514: 'Spagnolo - Stati Uniti',
  8202: 'Spagnolo - Venezuela',
  '-3': 'Spagnolo - America Latina',
  1035: 'Finlandese - Finlandia',
  2060: 'Francese - Belgio',
  3084: 'Francese - Canada',
  4108: 'Francese - Svizzera',
  1036: 'Francese - Francia',
  5132: 'Francese - Lussemburgo',
  '-2': 'Francese - Africa',
  1037: 'Ebraico - Israele',
  1081: 'Hindi - India',
  1038: 'Ungherese - Ungheria',
  1057: 'Indonesiano - Indonesia',
  1040: 'Italiano - Italia',
  1041: 'Giapponese - Giappone',
  1042: 'Coreano - Corea',
  1063: 'Lituano - Lituania',
  1102: 'Marathi - India',
  1044: 'Norvegese Bokmål - Norvegia',
  2067: 'Olandese - Belgio',
  1043: 'Olandese - Paesi Bassi',
  1045: 'Polacco - Polonia',
  1046: 'Portoghese - Brasile',
  2070: 'Portoghese - Portogallo',
  1049: 'Russo - Russia',
  1053: 'Svedese - Svezia',
  1098: 'Telugu - India',
  1054: 'Tailandese - Thailandia',
  1055: 'Turco - Turchia',
  1066: 'Vietnamita - Vietnam',
  2052: 'Cinese semplificato - Cina',
  3076: 'Cinese tradizionale - Hong Kong (SAR)',
  4100: 'Cinese semplificato - Singapore',
  1028: 'Cinese tradizionale - Taiwan',
};

export const LOCALE_LANGUAGE_MAPPING = {
  'en-us': LOCALE_EN,
  'fr-fr': LOCALE_FR,
};

// Supported languages in the system
export const SUPPORTED_LOCALES_EN: IDictionary<string> = {
  1033: 'English - United States',
  2057: 'English - United Kingdom',
  1036: 'French - France',
  1031: 'German - Germany',
  1040: 'Italian - Italy',
  3084: 'French - Canada',
  3082: 'Spanish - Spain',
  2058: 'Spanish - Mexico',
};

export const SUPPORTED_LOCALES_EN_GB: IDictionary<string> = {
  1033: 'English - United States',
  2057: 'English - United Kingdom',
  1036: 'French - France',
  1031: 'German - Germany',
  1040: 'Italian - Italy',
  3084: 'French - Canada',
  3082: 'Spanish - Spain',
  2058: 'Spanish - Mexico',
};

export const SUPPORTED_LOCALES_FR: IDictionary<string> = {
  1033: 'Anglais - États-Unis',
  2057: 'Anglais - Royaume-Uni',
  1036: 'Français - France',
  1031: 'Allemand - Allemagne',
  1040: 'Italien - Italie',
  3084: 'Français - Canada',
  3082: 'Espagnol - Espagne',
  2058: 'Espagnol - Mexique',
};

export const SUPPORTED_LOCALES_DE: IDictionary<string> = {
  1033: 'Englisch - Vereinigte Staaten',
  2057: 'Englisch - Vereinigtes Königreich',
  1036: 'Französisch - Frankreich',
  1031: 'Deutsch - Deutschland',
  1040: 'Italienisch - Italien',
  3084: 'Französisch - Kanada',
  3082: 'Spanisch - Spanien',
  2058: 'Spanisch - Mexiko',
};

export const SUPPORTED_LOCALES_IT: IDictionary<string> = {
  1033: 'Inglese - Stati Uniti',
  2057: 'Inglese - Regno Unito',
  1036: 'Francese - Francia',
  1031: 'Tedesco - Germania',
  1040: 'Italiano - Italia',
  3084: 'Francese - Canada',
  3082: 'Spagnolo - Spagna',
  2058: 'Spagnolo - Messico',
};

export const SUPPORTED_LOCALES_FR_CA: IDictionary<string> = {
  1033: 'Anglais - États-Unis',
  2057: 'Anglais - Royaume-Uni',
  1036: 'Français - France',
  1031: 'Allemand - Allemagne',
  1040: 'Italien - Italie',
  3084: 'Français - Canada',
  3082: 'Espagnol - Espagne',
  2058: 'Espagnol - Mexique',
};

export const SUPPORTED_LOCALES_ES_ES: IDictionary<string> = {
  1033: 'Inglés - Estados Unidos',
  2057: 'Inglés - Reino Unido',
  1036: 'Francés - Francia',
  1031: 'Alemán - Alemania',
  1040: 'Italiano - Italia',
  3084: 'Francés - Canadá',
  3082: 'Español - España',
  2058: 'Español - México',
};

export const SUPPORTED_LOCALES_ES_MX: IDictionary<string> = {
  1033: 'Inglés - Estados Unidos',
  2057: 'Inglés - Reino Unido',
  1036: 'Francés - Francia',
  1031: 'Alemán - Alemania',
  1040: 'Italiano - Italia',
  3084: 'Francés - Canadá',
  3082: 'Español - España',
  2058: 'Español - México',
};

export const SUPPORTED_LOCALES_MAPPING = {
  'en-us': SUPPORTED_LOCALES_EN,
  'en-gb': SUPPORTED_LOCALES_EN_GB,
  'fr-fr': SUPPORTED_LOCALES_FR,
  'de-de': SUPPORTED_LOCALES_DE,
  'it-it': SUPPORTED_LOCALES_IT,
  'fr-ca': SUPPORTED_LOCALES_FR_CA,
  'es-es': SUPPORTED_LOCALES_ES_ES,
  'es-mx': SUPPORTED_LOCALES_ES_MX,
};
