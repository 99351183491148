import { getAppStore, postEntity } from '../../../../../@data';
import { EvaluationStatus, PhoneVerificationVerifyCode, Publisher } from '../../../../../@data/store';

export async function verifyGeneratedCodeAsync(body: PhoneVerificationVerifyCode, publisher: Publisher) {
  const { userContext } = getAppStore();
  const phoneVerificationResp = await postEntity<PhoneVerificationVerifyCode>(
    [new EvaluationStatus(), publisher],
    PhoneVerificationVerifyCode,
    body,
    userContext
  );
  return phoneVerificationResp.data;
}
