import { IContentLayoutStyleProps, IContentLayoutStyles } from './ContentLayout.types';

export const getStyles = (props: IContentLayoutStyleProps): IContentLayoutStyles => {
  const { theme } = props;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      flex: 1
    },
    menuAndContent: {
      display: 'flex',
      flexDirection: 'row',
      height: 'inherit',
      flex: 1,
      overflowY: 'auto'
    },
    appNav: [
      'ms-appNav',
      {
        overflowY: 'auto',
        zIndex: 999,
        display: 'flex'
      }
    ],
    appContent: {
      backgroundColor: theme.palette.white,
      flex: 1,
      overflowY: 'auto',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    },
    appContentRoutes: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }
  };
};
