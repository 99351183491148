import { orchestrator } from 'satcheljs';
import { Log } from '../../../logging/src';
import { removePaymentNotificationBuffers } from '../../pages/ad-management/Account/Payments/Onboarding/usePaymentsOnboarding';
import { onAuthLogout } from '../actions';
import { logoutPubCenterAADUser } from '../services/PubCenterAADAuthService';
import { getAppStore } from '../store/store';

orchestrator(onAuthLogout, async ({ isTokenExpired }) => {
  const { userContext } = getAppStore();
  Log.info(`Logging out ${userContext?.email}`);

  removePaymentNotificationBuffers();

  await logoutPubCenterAADUser(isTokenExpired);
});
