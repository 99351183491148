import { action } from 'satcheljs';
import { MediationReportingDataRequestBody } from '../../../../../@data/store/schema/models/MediationReportingDataRequestBody';
import { ReportColumnMembersParams } from '../../../../../@data/store/schema/models/ReportColumnMembersParams';
import { ReportingDataRequestBody } from '../../../../../@data/store/schema/models/ReportingDataRequestBody';
import { ReportCardType } from '../../ReportCard/ReportCard.types';

export const fetchReportPageSummary = action('fetchReportPageSummary', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageMediationSummary = action(
  'fetchReportPageMediationSummary',
  (reportRequestBody: MediationReportingDataRequestBody) => ({
    reportRequestBody,
  })
);
export const fetchReportPageSites = action('fetchReportPageSites', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageAdUnits = action('fetchReportPageAdUnits', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageCountry = action('fetchReportPageCountry', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageMediation = action('fetchReportPageMediation', (reportRequestBody: MediationReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPagePerformance = action('fetchReportPagePerformance', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageDetailedSites = action('fetchReportPageDetailedSites', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageDetailedAdUnits = action('fetchReportPageDetailedAdUnits', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageDetailedPerformance = action(
  'fetchReportPageDetailedPerformance',
  (reportRequestBody: ReportingDataRequestBody) => ({
    reportRequestBody,
  })
);
export const fetchReportPageDetailedCountry = action('fetchReportPageDetailedCountry', (reportRequestBody: ReportingDataRequestBody) => ({
  reportRequestBody,
}));
export const fetchReportPageDetailedMediation = action(
  'fetchReportPageDetailedMediation',
  (reportRequestBody: MediationReportingDataRequestBody) => ({
    reportRequestBody,
  })
);
export const fetchMediationReportsMetadata = action('fetchMediationReportsMetadata');
export const fetchReportsMetadata = action('fetchReportsMetadata');
export const fetchReportDimensionColumnMembers = action(
  'fetchReportDimensionColumnMembers',
  (requestParams: ReportColumnMembersParams, reportCardType?: ReportCardType) => ({
    requestParams,
    reportCardType,
  })
);
