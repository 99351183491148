import React, { useEffect } from 'react';
import { LayoutMode, onLayoutChanged } from '../../@data';
import { getClassNames } from './GridPageLayout.styles';

export interface IGridPageLayoutProps {
  children?: JSX.Element[] | JSX.Element;
  withSubHeader?: boolean;
}

export const GridPageLayout = function GridPageLayoutComponent(props: IGridPageLayoutProps) {
  const { gridContainer } = getClassNames({ withSubHeader: props.withSubHeader });

  useEffect(() => {
    onLayoutChanged(LayoutMode.Default);
    // eslint-disable-next-line
  }, []);

  return <div className={gridContainer}>{props.children}</div>;
};
