import { orchestrator } from 'satcheljs';
import { onLoadingChanged } from '../../../../../@data';
import { getSupportedAccountSkus } from '../../../../../@data/services/MetadataService';
import { FormState } from '../../../../../components/Form';
import { onPanelOpenChanged } from '../../../@data/actions';
import { onAccountSkuChanged, onAccountSkuSidePanelInitialized } from '../actions';
import { IAccountSkuViewModel } from '../store/schema/IAccountSkuViewModel';

orchestrator(onAccountSkuSidePanelInitialized, async (msg) => {
  const panelProps = msg.value;

  if (panelProps) {
    onLoadingChanged(true);
    const accountSku: IAccountSkuViewModel = {};
    if (panelProps.mode === FormState.InPageEdit) {
      if (panelProps.selectedAccountSku) {
        accountSku.accountId = panelProps.selectedAccountSku.accountId;
        accountSku.medium = panelProps.selectedAccountSku.medium;
        accountSku.sku = panelProps.selectedAccountSku.sku;
        accountSku.listOfMediums = [panelProps.selectedAccountSku.medium.toString()];
      }
    } else {
      accountSku.listOfMediums = panelProps.nonSKUMappedMediums;
    }

    accountSku.availableSkus = await getSupportedAccountSkus();

    onAccountSkuChanged(accountSku);
    onLoadingChanged(false);
    onPanelOpenChanged(true);
  } else {
    // error handling
  }
});
