import { IDropdownOption } from '@fluentui/react';
import { PropertyApprovalStatus } from '../../../../../../../../../@data';
import messages from '../messages';

export function GetApprovalStatusOptions(formatMessage): IDropdownOption[] {
  return [
    { key: PropertyApprovalStatus.Approved, text: formatMessage(messages.approved), ariaLabel: formatMessage(messages.approved) },
    {
      key: PropertyApprovalStatus.AutoApproved,
      text: formatMessage(messages.autoApproved),
      ariaLabel: formatMessage(messages.autoApproved),
    },
    { key: PropertyApprovalStatus.Rejected, text: formatMessage(messages.rejected), ariaLabel: formatMessage(messages.rejected) },
    {
      key: PropertyApprovalStatus.UnderManualReview,
      text: formatMessage(messages.manualreview),
      ariaLabel: formatMessage(messages.manualreview),
    },
  ];
}
