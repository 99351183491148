import { mutatorAction } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import { IStringDictionary, UserInvitation } from '../../../../../../@data';
import { getUserInvitationStore } from '../store';

export const setUserInvitationErrors = mutatorAction('setUserInvitationErrors', (errors?: IStringDictionary) => {
  getUserInvitationStore().userInviteErrors = errors;
});

export const setUserInvitationPanelStatus = mutatorAction('setUserInvitationPanelStatus', (isPanelOpen: boolean) => {
  getUserInvitationStore().userInvitePanelOpen = isPanelOpen;
});

export const setUserInvitations = mutatorAction('setUserInvitations', (userInvitations?: UserInvitation[]) => {
  getUserInvitationStore().userInvitations = userInvitations || [];
});

export const updateUserInvitationExpiry = mutatorAction('updateUserInvitationExpiry', (id: number, date: Date) => {
  const { userInvitations } = getUserInvitationStore();

  const userInvitation = userInvitations?.find((ui) => ui.id === id);
  if (userInvitation) {
    userInvitation.expirationDate = date;

    getUserInvitationStore().userInvitations = [...userInvitations];
    Log.info(`Updated user invitation expiry date to ${userInvitation.expirationDate}`);
  }
});
