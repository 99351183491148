import { IStyle, mergeStyleSets } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/style-utilities';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../theme';
import { ICommonStyleProps } from '../../../../../../types';

export interface IBalanceCardStyles {
  cardContainerStyle: string;
  contentStyle: string;
  balanceImageStyle: string;
  balanceStyle: string;
  balanceTextStyle: string;
  currentBalanceStyle: string;
  lastPaymentInfoStyle: string;
  imageStyle: string;
  barChartSectionStyle: string;
  currentBalanceInfoStyle: string;
  currentBalanceInfoTextStyle: string;
  iconSectionStyle: string;
  iconStyle: string;
  iconContentStyle: string;
  iconTextStyle: string;
  subComponentStyles: {
    chartTitle: IStyle;
    items: IStyle;
  };
}

export const getStyles = (props: ICommonStyleProps): IBalanceCardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return mergeStyleSets({
    cardContainerStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
      boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.2)',
      transition: '0.3s',
      borderRadius: spacing.size10,
      flex: 1,
      gridColumn: 'span 1',
      minHeight: spacing.size256,
    },
    contentStyle: {
      display: 'flex',
      flexDirection: 'column',
      margin: `${spacing.size10} ${spacing.size15}`,
      height: '100%',
    },
    balanceImageStyle: {
      display: 'flex',
    },
    balanceStyle: {
      width: spacing.size150,
      marginRight: 'auto',
    },
    balanceTextStyle: {
      fontSize: spacing.size16,
      fontWeight: 600,
    },
    currentBalanceStyle: {
      margin: '0px',
      fontSize: spacing.size28,
      fontWeight: 600,
    },
    lastPaymentInfoStyle: {
      fontSize: spacing.size12,
      fontWeight: 400,
      marginTop: spacing.size4,
    },
    imageStyle: {
      display: 'flex',
      marginTop: spacing.size10,
    },
    barChartSectionStyle: {
      marginTop: spacing.size20,
    },
    currentBalanceInfoStyle: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    currentBalanceInfoTextStyle: {
      fontSize: spacing.size10,
      fontWeight: 400,
    },
    iconSectionStyle: {
      marginTop: spacing.size40,
    },
    iconStyle: {
      border: 'none',
      padding: '0px',
      cursor: 'pointer',
      fontStyle: 'normal',
    },
    iconContentStyle: {
      width: 'auto',
      padding: `0px ${spacing.size12}`,
      height: spacing.size32,
      border: `${spacing.size1} solid ${palette['NeutralStroke1.Rest']}`,
      borderRadius: spacing.size4,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      ':hover': {
        backgroundColor: palette['NeutralBackground3.Rest'],
        cursor: 'pointer',
      },
    },
    iconTextStyle: {
      marginLeft: spacing.size10,
      marginBottom: spacing.size4,
      color: palette['NeutralForeground1.Rest'],
      fontSize: spacing.size14,
      fontWeight: 600,
    },
    subComponentStyles: {
      chartTitle: {
        justifyContent: 'flex-end',
      },
      items: {
        marginBottom: '3px',
      },
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
