import { defineMessages } from 'react-intl';

export default defineMessages({
  applyFilterButtonLabel: {
    id:'gridFilter.filterSelectionCallout.apply',
    defaultMessage: 'Apply'
  },
  filterSelectionTextBoxErrorMessage: {
    id:'gridFilter.filterSelectionCallout.TextBoxErrorMessage',
    defaultMessage: 'Field requires numerical value'
  },
  filtersTitle: {
    id:'gridFilter.filtersTitle',
    defaultMessage: 'Filters'
  },
  filterListAriaDescription: {
    id: 'gridFilter.filterListAriaDescription',
    defaultMessage: 'Click on list item to view or edit filter'
  },
  appliedFilterListAriaLabel: {
    id: 'gridFilter.appliedFilterListAriaLabel',
    defaultMessage: 'Applied filter list',
  },
  filterMenuAriaLabel: {
    id: 'gridFilter.filterMenuAriaLabel',
    defaultMessage: 'Filter Menu'
  },
  removeFilterIconAriaLabel: {
    id: 'gridFilter.removeFilterIconAriaLabel',
    defaultMessage: 'Remove filter'
  },
  applyFilterAriaDesc: {
    id: 'gridFilter.applyFilterAriaDesc',
    defaultMessage: 'Click to apply filter'
  },
  filterOptionDropdownAriaLabel: {
    id: 'gridFilter.filterOptionDropdownAriaLabel',
    defaultMessage: 'Filter options'
  },
  filterTextBoxAriaLabel: {
    id: 'gridFilter.filterTextBoxAriaLabel',
    defaultMessage: 'Filter value',
  },
  startDatePlaceholder: {
    id:' gridFilter.filterSelectionCallout.startDatePlaceholder',
    defaultMessage: 'Select start date...'
  },
  endDatePlaceholder: {
    id:'gridFilter.filterSelectionCallout.endDatePlaceholder',
    defaultMessage: 'Select end date...'
  },
  equals: {
    id:'gridFilter.filterConditionStrings.equals',
    defaultMessage: 'equals'
  },
  doesNotEqual: {
    id:'gridFilter.filterConditionStrings.doesNotEqual',
    defaultMessage: 'does not equal'
  },
  greaterThan: {
    id:'gridFilter.filterConditionStrings.greaterThan',
    defaultMessage: 'greater than'
  },
  greaterThanEqual: {
    id:'gridFilter.filterConditionStrings.greaterThanEqual',
    defaultMessage: 'greater than or equals'
  },
  lessThan: {
    id:'gridFilter.filterConditionStrings.lessThan',
    defaultMessage: 'less than'
  },
  lessThanEqual: {
    id:'gridFilter.filterConditionStrings.lessThanEqual',
    defaultMessage: 'less than or equals'
  },
  contains: {
    id:'gridFilter.filterConditionStrings.contains',
    defaultMessage: 'contains'
  },
  doesNotContain: {
    id:'gridFilter.filterConditionStrings.doesNotContain',
    defaultMessage: 'does not contain'
  },
  beginsWith: {
    id:'gridFilter.filterConditionStrings.beginsWith',
    defaultMessage: 'begins with'
  },
  endsWith: {
    id:'gridFilter.filterConditionStrings.endsWith',
    defaultMessage: 'ends with'
  }
});