import { InjectedIntlProps } from 'react-intl';

export interface IEstimatedEarningsProps extends InjectedIntlProps {
  defaultChartType: EstimatedEarningsChartTypes;
  defaultChartGrain: ChartGrain;
}

export enum EstimatedEarningsChartTypes {
  VERTICAL_BAR = 'vertical_bar',
  LINE = 'line',
}
export enum ChartGrain {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}
