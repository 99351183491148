import { useMemo } from 'react';

export const DOTS = '...';
export const DEFAULT_SIBLING_COUNT = 1;

export interface IUsePaginationProps {
  currentPageNumber: number;
  pageSize?: number;
  totalPageCount?: number;
  totalItemCount?: number;
  siblingCount?: number;
}

const getRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

export const useDottedPagination = ({
  totalPageCount,
  totalItemCount,
  pageSize,
  siblingCount = DEFAULT_SIBLING_COUNT,
  currentPageNumber,
}: IUsePaginationProps) => {
  const paginationRange = useMemo(() => {
    if (!totalPageCount && (!totalItemCount || !pageSize)) {
      throw new Error('Either totalPageCount or totalItemCount and pageSize must be provided');
    }

    const pageCount = totalPageCount ?? Math.ceil(totalItemCount! / pageSize!);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2 * DOTS
    const totalPageNumbersDisplayed = siblingCount + 5;

    if (totalPageNumbersDisplayed >= pageCount) {
      return getRange(1, pageCount);
    }

    const leftSiblingIndex = Math.max(currentPageNumber - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPageNumber + siblingCount, pageCount);

    /*
      We do not want to show dots if there is only one position left
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = pageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = getRange(1, leftItemCount);

      return [...leftRange, DOTS, pageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = getRange(pageCount - rightItemCount + 1, pageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getRange(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalItemCount, pageSize, siblingCount, currentPageNumber, totalPageCount]);

  return paginationRange;
};
