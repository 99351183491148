import { mutatorAction } from 'satcheljs';
import { getAppealStore } from '../store/appealStore';
import { DocumentUploadStatus } from '../store/schema';

export const setDocumentUploadPanelOpen = mutatorAction('setDocumentUploadPanelOpen', (isPanelOpen: boolean) => {
  getAppealStore().isDocumentUploadPanelOpen = isPanelOpen;
});

export const setDocumentUploadStatus = mutatorAction('setBVDocumentUploadStatus', (index: number, status: DocumentUploadStatus) => {
  getAppealStore().documentUploadStatus[index] = status;
});

// tslint:disable-next-line: no-any
export const setAppealHistory = mutatorAction('setAppealHistory', (appealHistory: any) => {
  getAppealStore().appealHistory = appealHistory;
});
