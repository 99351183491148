import { action } from 'satcheljs';
import { UserRole } from '../../../../../../@data';

export const onUserRoleChanged = action('onUserRoleChanged', (role: UserRole) => ({ role }));

export const onUsersDeleted = action(
  'onUsersDeleted',
  (
    formatMessage: (
      messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
      values?:
        | {
            [key: string]: ReactIntl.MessageValue;
          }
        | undefined
    ) => string
  ) => ({ formatMessage })
);

export const loadUsers = action('loadUsers');
