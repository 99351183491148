import { orchestrator } from 'satcheljs';
import { IPRange } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { validateSchema } from '../../../../../utils';
import { onPanelOpenChanged } from '../../../@data/actions';
import { onFormPublisherIpChanged, onFormPublisherIpRangesChanged, onPanelCleared, onPublisherErrorChanged } from '../actions';
import { getStore, publisherIPAddressViewModel } from '../store';

orchestrator(onFormPublisherIpChanged, async (msg) => {
  const { active } = getStore();
  const newIpRangeGrid: IPRange[] = [];
  const newIpAddress = new IPRange();
  newIpAddress.name = active.ipAddressName;
  newIpAddress.minIPAddress = active.minIPAddress;
  newIpAddress.maxIPAddress = active.maxIPAddress;

  try {
    await validateSchema(publisherIPAddressViewModel(), active);
    onPublisherErrorChanged();
  } catch (e) {
    onPublisherErrorChanged(e);
    return;
  }

  if (msg.mode === FormState.Delete || msg.mode === FormState.InPageEdit) {
    active.ipAddressGrid!!.forEach((ip) => {
      if (ip.name !== msg.value[0].name) {
        newIpRangeGrid.push(ip);
      }
    });
  } else {
    if (active.ipAddressGrid) {
      active.ipAddressGrid.forEach((i) => newIpRangeGrid.push(i));
    }
  }

  if (msg.mode !== FormState.Delete) {
    newIpRangeGrid.push(newIpAddress);
  }

  onFormPublisherIpRangesChanged(newIpRangeGrid);
  onPanelOpenChanged(false);
  onPanelCleared();
});
