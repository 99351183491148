import { action } from 'satcheljs';

export const onFetchPublisherPreferences = action('onFetchEntityPreferences');

export const onFetchUserPreferences = action('onFetchEntityPreferences', (locale?: string) => ({ locale }));

export const onUpdateSystemPreferences = action('onUpdateSystemPreferences');

export const onUpdateUserLanguage = action(
  'onUpdateUserLanguage',
  (locale, closePanel?: () => void, setFormChanged?: (state: boolean) => void) => ({ closePanel, setFormChanged, locale })
);
