import { Log } from '../../../../../../../logging/src';
import { AdSource, Model, Publisher } from '../../../../../../@data';
import { adProvidersToNames } from '../../../../../../config/adProviders';
import { IDataGridColumn } from '../../../../../../layout/GridPageV2';
import { CSVHelper, invert } from '../../../../../../utils';
import { IAdCreativeViewModel } from '../../store/schema';
import { AdPerformanceData, BlockAdsData, BlockedContext, SAMBlockSummaryData } from '../../store/schema/models';
import { IAdReviewModel } from '../../store/schema/models/AdReviewModel';
import { AGG_LEVEL_CREATIVE, AGG_LEVEL_LISTING, AUDIENCE_INTENT_MARKETPLACE, PAGE_ADS, PAID_SEARCH, TEXT_ADS } from './constants';

export function handleError<T>(error: Error, url: string): Promise<T> {
  Log.error(error);
  return Promise.reject(error);
}

export function constructUrl(baseUrl: string, models: string[][], params?: URLSearchParams, defaultParams?: URLSearchParams): string {
  const urlArray = [baseUrl];

  models.forEach((model) => {
    if (model[1] !== '') {
      model.forEach((str) => urlArray.push(encodeURIComponent(str)));
    }
  });

  let url = urlArray.join('/');
  if (params) {
    url = url.concat('?' + params.toString());
  }

  if (defaultParams) {
    url = url.concat('&' + defaultParams.toString());
  }

  return url;
}

export function convertDateToString(date: Date): string {
  // Uses the local date of the given date, not the UTC date.
  return date.toISOString().split('T')[0].replace(/-/g, '');
}

export function constructAdPerfRequest(
  active: IAdCreativeViewModel,
  data: IAdReviewModel,
  publisher: Publisher,
  accountIds?: number[],
  adDescription?: boolean
): IAdReviewModel {
  const body: IAdReviewModel = {
    StartDate: active.selectedAdSource === AdSource.XandrAuction ? undefined : convertDateToString(active.startDate!),
    EndDate: active.selectedAdSource === AdSource.XandrAuction ? undefined : convertDateToString(active.endDate!),
    PageNumber: 1,
    PageSize: 100,
    Filters: data.Filters,
    Keyword: active.searchBoxValue && active.searchBoxValue !== '' ? active.searchBoxValue : '',
    Creativeagglevel:
      active.selectedAdSource === AdSource.Microsoft
        ? AGG_LEVEL_LISTING
        : active.selectedAdSource === AdSource.Auction
        ? AGG_LEVEL_CREATIVE
        : undefined,
    AdTypes: active.selectedAdSource === AdSource.Microsoft ? [PAGE_ADS, TEXT_ADS] : undefined,
    AdSources: active.selectedAdSource === AdSource.Microsoft ? [AUDIENCE_INTENT_MARKETPLACE, PAID_SEARCH] : undefined,
    ...(active.selectedAdSource === AdSource.Microsoft && adDescription
      ? {
          Fields: [
            'adtitle',
            'adurl',
            'advertisername',
            'adid',
            'uniquecreativeadidstr',
            'advertiseraccountid',
            'advertiserid',
            'uniquecreativeadid',
            'addescription',
          ],
        }
      : {}),
  };

  if (body.Filters?.Numerical) {
    body.Filters.Numerical.publisherId = publisher.id;
    if (accountIds) {
      body.Filters.Numerical.accountIds = accountIds;
    }
  }

  return body;
}

export function isImageBlockable(img: string, adtype?: string, adsource?: string): boolean {
  if (img && adtype === TEXT_ADS && adsource === PAID_SEARCH) {
    const isNativeStockPhoto = img.indexOf('pid=AdsNative') >= 0;

    // We can block PA corpus Images that are displayed on TA ads the
    // same way as we block the AdsNative stock photos.
    const isPaCorpusImage = img.indexOf('&pid=21.1') >= 0;

    return isNativeStockPhoto || isPaCorpusImage;
  }
  return false;
}

export function isImageBlocked(img: string, adId: number, blockedImages?: string[], blockedAdsContext?: BlockedContext) {
  let isBlocked = false;
  const imageReportDataTuple = { adId: adId, imageUrl: img };
  if (
    (blockedImages && blockedImages.includes(JSON.stringify(imageReportDataTuple))) ||
    (blockedAdsContext &&
      blockedAdsContext.mapAdIdImages &&
      blockedAdsContext.mapAdIdImages!.has(adId) &&
      compareImages(blockedAdsContext.mapAdIdImages!.get(adId)!, img))
  ) {
    isBlocked = true;
  }
  return isBlocked;
}

export function isAllImagesViewOnly(imgs?: string[], adtype?: string, adsource?: string): boolean {
  return imgs ? imgs.every((image) => !isImageBlockable(image, adtype, adsource)) : true;
}

export function compareImages(allImages: string[], imageToCompare: string) {
  if (allImages.length === 0) {
    return false;
  }
  const strippedImages: string[] = [];
  allImages.forEach((imageUrl) => {
    strippedImages.push(imageUrl.replace('https://www.bing.com/', ''));
  });
  imageToCompare = imageToCompare.replace('https://bing.com/', '');
  return strippedImages.includes(imageToCompare);
}

export function processImagesLink(imageUrls: string[], noImageUrl: string, isAuctionData = false): string[] {
  imageUrls = imageUrls.filter((v) => v !== '');
  imageUrls.forEach((img, imgIndex) => {
    if (img === null || img === '') {
      imageUrls[imgIndex] = noImageUrl;
    } else {
      imageUrls[imgIndex] = isAuctionData ? img : sanitizeBingImageUrl(img);
    }
  });
  if (imageUrls.length === 0) {
    imageUrls = [noImageUrl];
  }
  return imageUrls;
}

export function sanitizeBingImageUrl(imgUrl: string): string {
  let imageUrl = '';
  if (imgUrl.indexOf('bing.com/th?id=') >= 0) {
    imageUrl = 'https://' + imgUrl.slice(imgUrl.indexOf('bing.com/th?id='));
  } else if (imgUrl.indexOf('th?id=') >= 0) {
    imageUrl = 'https://bing.com' + imgUrl;
  } else {
    imageUrl = 'https://bing.com/th?id=' + imgUrl;
  }
  return imageUrl;
}

export function extractPlacement(item: AdPerformanceData): string {
  // tslint:disable-next-line: no-string-literal
  const { regions, pagetypes } = item;
  return (pagetypes || []).concat(regions || []).join(', ');
}

export function exportCSV(blockDataForCSV: Model[], fileName: string, columns: IDataGridColumn[]) {
  const stringDataForCSV: string[] = [];
  for (const rowData of blockDataForCSV) {
    stringDataForCSV.push(
      CSVHelper.getCSVStrings(
        rowData,
        columns.filter((col) => col.isExportColumn)
      )
    );
  }

  const headerCsvList: string[] = [];
  for (const coldata of columns) {
    if (coldata && coldata.isExportColumn) {
      headerCsvList.push(coldata.headerCSV ? coldata.headerCSV : coldata.friendlyName);
    }
  }

  CSVHelper.downloadDataAsCSV(stringDataForCSV.join('\n'), headerCsvList.join(','), fileName);
}

export function toHttpsLink(urlToUpdate: string): string {
  if (!urlToUpdate || urlToUpdate.startsWith('http://') || urlToUpdate.startsWith('https://')) {
    // Url is null or already proper http.
    return urlToUpdate;
  } else {
    return 'https://' + urlToUpdate;
  }
}

export function samBlockSummaryToAdPerfMapper(items: SAMBlockSummaryData[]): AdPerformanceData[] {
  const mappedItems: AdPerformanceData[] = [];
  items.forEach((item) => {
    if (item.adProvider && item.allAdImages) {
      mappedItems.push({
        apiPath: undefined,
        className: 'adPerformanceData',
        adTitle: item.adTitle,
        adProvider: Number(invert(adProvidersToNames)[item.adProvider]),
        advertiserName: item.advertiser,
        images: item.allAdImages,
        blockedImages: item.allAdImages,
        revenue: item.sevenDayRevenueImpact,
        uniqueCreativeAdId: item.creativeAdId,
      });
    }
  });

  return mappedItems;
}

export function blockDatatoAdPerfMapper(items: BlockAdsData[]): AdPerformanceData[] {
  const mappedItems: AdPerformanceData[] = [];
  items.forEach((item) => {
    if (item.allImages) {
      const imageList = item.allImages.split('___');
      mappedItems.push({
        apiPath: undefined,
        className: 'adPerformanceData',
        adTitle: item.adTitle,
        adId: item.adId,
        revenue: item.pastRevenue,
        accountName: item.accountName,
        advertiserAccountId: item.advertiserAccountId,
        accountId: item.accountId,
        images: imageList,
        blockedImages: imageList,
      });
    }
  });
  return mappedItems;
}
