import React, { RefObject, useState } from 'react';
import appMessages from '../../../../App.messages';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../utils';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { ICustomCalenderDateRange } from '../../components/CustomCalender/CustomCalender.types';
import { PERFORMANCE_DEFAULT_COLUMNS, PERFORMANCE_DEFAULT_METRICS } from '../../constants/common';
import { useReportQuery } from '../../hooks/useReportQuery';
import { IReportsMetadata, TythonReportingDataRow } from '../@data/store/schema';
import { ReportCard } from '../ReportCard/ReportCard';
import { constructRequestBody } from '../ReportCard/ReportCard.helpers';
import { ActiveMetrics, ChartGrain, IChartButton, ReportCardType } from '../ReportCard/ReportCard.types';
import { IActiveFilter } from '../ReportDetailsPage/ActionBar/ActionBar';

const DATA_METRIC_ID = 'performance-report-card';
const CHART_DATA_METRIC_ID = 'performance-report-chart';
export interface IPerformanceReportCardProps extends IRouterWithIntl {
  allowedChartTypes: IChartButton[];
  showDetailsReportLink: boolean;
  enableHideChartsButton?: boolean;
  filters: IActiveFilter[];
  dateRange: ICustomCalenderDateRange;
  isDetailedView: boolean;
  onChartGrainChanged?: (grain: ChartGrain) => void;
  onSelectedMetricChanged?: (metric: string) => void;
  onActiveMetricsChanged?: (activeMetrics: ActiveMetrics) => void;
  onBreakdownChanged?: (breakdown: string[]) => void;
  reportDetailedData?: TythonReportingDataRow[];
  reportCardRef: RefObject<HTMLDivElement>;
  reportMetadata?: IReportsMetadata;
  summaryData?: TythonReportingDataRow[];
}
export const PerformanceReportCard = injectIntlWithObserver<IPerformanceReportCardProps>(
  ({
    dateRange,
    filters,
    allowedChartTypes,
    showDetailsReportLink,
    enableHideChartsButton,
    isDetailedView,
    onChartGrainChanged,
    onSelectedMetricChanged,
    onActiveMetricsChanged,
    onBreakdownChanged,
    reportDetailedData,
    reportCardRef,
    intl: { formatMessage },
    reportMetadata,
    summaryData,
    history,
    location,
    match,
  }) => {
    const title = formatMessage(appMessages.performanceCardTitle);
    const [currentChartGrain, setCurrentChartGrain] = useState(ChartGrain.DAILY);
    const { data: reportData } = useReportQuery(
      constructRequestBody(title, dateRange, currentChartGrain, isDetailedView ? filters : [], [], reportMetadata?.metrics || []),
      isDetailedView,
      !!reportMetadata
    );
    return (
      <div data-metric={DATA_METRIC_ID}>
        <ReportCard
          title={title}
          allowedChartTypes={allowedChartTypes}
          allowedChartGrains={reportMetadata?.grains || []}
          defaultChartGrain={ChartGrain.DAILY}
          reportData={reportData}
          reportDetailedData={reportDetailedData}
          summaryData={summaryData}
          isSummaryDataLoaded={!!summaryData}
          showChartGrains
          showMetricsMarkers
          dataMetrics={{ chartDataMetrics: CHART_DATA_METRIC_ID }}
          type={ReportCardType.PERFORMANCE}
          showDetailsReportLink={showDetailsReportLink}
          enableHideChartsButton={enableHideChartsButton}
          dateRange={dateRange}
          isDetailedView={isDetailedView}
          filters={filters}
          history={history}
          location={location}
          match={match}
          defaultColumns={PERFORMANCE_DEFAULT_COLUMNS}
          defaultMetrics={PERFORMANCE_DEFAULT_METRICS}
          hasMinimumBreakdown={false}
          reportMetadata={reportMetadata}
          dateDimension={TythonColumnsEnum.DateTime}
          onChartGrainChanged={(updatedGrain) => {
            onChartGrainChanged && onChartGrainChanged(updatedGrain);
            setCurrentChartGrain(updatedGrain);
          }}
          onBreakDownChanged={onBreakdownChanged}
          onSelectedMetricChanged={onSelectedMetricChanged}
          onActiveMetricsChanged={onActiveMetricsChanged}
          reportCardRef={reportCardRef}
        />
      </div>
    );
  }
);
