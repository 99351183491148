import { classNamesFunction, getId, Spinner } from '@fluentui/react';
import * as React from 'react';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import { IBulkUploadProps, IBulkUploadStyleProps, IBulkUploadStyles } from './bulkUpload.types';

const getClassNames = classNamesFunction<IBulkUploadStyleProps, IBulkUploadStyles>();

interface IBulkUploadState {
  isCalloutVisible?: boolean;
  file?: string;
}

export class BulkUploadBase extends React.Component<IBulkUploadProps, IBulkUploadState> {
  constructor(props) {
    super(props);
    this.state = {
      isCalloutVisible: false,
    };
  }

  public render(): JSX.Element {
    const { styles, theme, uploadDescription, isLoading, onUpload, disabled, ariaLabelledBy, ariaLabel } = this.props;
    const classNames = getClassNames(styles, { theme: theme! });
    const bulkUploadDescription = getId('bulkUploadDescription');

    return (
      <div className={classNames.root}>
        <input
          type="file"
          accept=".txt"
          onChange={(event) => {
            onUpload(event);
          }}
          disabled={disabled}
          {...(ariaLabelledBy ? { 'aria-labelledby': ariaLabelledBy } : { 'aria-label': ariaLabel })}
          aria-describedby={bulkUploadDescription}
        />
        {isLoading && <Spinner label="Uploading file..." labelPosition="right" />}
        <div className={classNames.uploadDescription}>{uploadDescription}</div>
        <span id={bulkUploadDescription} style={getScreenReaderTextStyles()}>
          {uploadDescription}
        </span>
      </div>
    );
  }
}
