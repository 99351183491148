import { mutatorAction } from 'satcheljs';
import { IIdNamePair } from '../../../../../../components/MultiSelectPicker';
import { IConditionValue } from '../../../@data/store/schema';
import { getStore } from '../../../@data/store/store';

export const onAddRawElementToList = mutatorAction(
  'onAddRawElementToList',
  function onAddRawElement(heading: string, item: IConditionValue<string>) {
    const activeFilters = getStore().filters.active?.[heading];
    activeFilters.conditions.push(item);
  }
);

export const onSelectChanged = mutatorAction('onSelectChanged', function onSelect(heading: string, item: IIdNamePair, value: boolean) {
  const elemList: IIdNamePair[] = getStore().filters.active?.[heading]?.values;
  if (item && elemList) {
    const index: number = elemList.findIndex((e: { id: number }) => e.id === item.id);
    elemList[index].isSelected = value;
  }
});

export const onSelectAllChanged = mutatorAction('onSelectAllChanged', function onSelectAll(heading: string) {
  getStore().filters.active?.[heading].values.forEach((elemList: IIdNamePair) => {
    elemList.isSelected = true;
  });
});

export const onDeselectAllChanged = mutatorAction('onDeselectAllChanged', function onDeselectAll(heading: string) {
  getStore().filters.active?.[heading].values.forEach((elemList: IIdNamePair) => {
    elemList.isSelected = false;
  });
});
