import { mutatorAction } from 'satcheljs';
import { ClarityStatus, Property } from '../../../../../../@data';
import { PropertyServingConfigs } from '../../../../../../@data/store/schema/models/ServingConfigs';
import { getGridPropertyStore } from '../store/store';

export const setPropertyData = mutatorAction('setSitesData', (data?: Property[]) => {
  getGridPropertyStore().data = data;
});

export const setLoading = mutatorAction('setLoading', (loading: boolean) => {
  getGridPropertyStore().isLoading = loading;
});

export const setFetchingData = mutatorAction('setFetchingData', (isFetching: boolean) => {
  getGridPropertyStore().isFetchingData = isFetching;
});

export const setPropertiesServingConfigs = mutatorAction('setPropertiesServingConfigs', (configs: PropertyServingConfigs[]) => {
  getGridPropertyStore().servingConfigs = configs;
});

export const setPropertiesFetching = mutatorAction('setPropertiesFetching', (isFetching: boolean) => {
  getGridPropertyStore().isFetchingPropertyStatus = isFetching;
});

export const setClarityPanelVisibility = mutatorAction('setClarityPanelVisibility', (isOpen: boolean) => {
  getGridPropertyStore().isPanelOpen = isOpen;
});

export const setCodePanelVisibility = mutatorAction(
  'setCodePanelVisibility',
  (isOpen: boolean, property?: string, clarityStatus?: ClarityStatus) => {
    getGridPropertyStore().isCodePanelOpen = isOpen;
    getGridPropertyStore().selectedPropertyId = property;
    if (!isOpen) {
      getGridPropertyStore().clarityPanel = undefined;
    } else {
      getGridPropertyStore().clarityPanel = {
        clarityStatus: clarityStatus,
      };
    }
  }
);

export const setSelectedPropertyId = mutatorAction('setSelectedPropertyId', (id: string) => {
  getGridPropertyStore().selectedPropertyId = id;
});
