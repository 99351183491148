import { Body1, Button, Caption1, Card, Subtitle1, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import messages from '../messages';
import AdVector from './resources/adVector.svg';
import { useAdPreviewRendererStyles } from './styles';
import { AppAdUnitPreviewLayout } from './types';

const AdPreviewDynamicRenderer: React.FC<InjectedIntlProps & { layout: AppAdUnitPreviewLayout }> = ({
  intl: { formatMessage },
  layout,
}) => {
  const classNames = useAdPreviewRendererStyles();

  const classes = React.useMemo(
    () => ({
      previewCardWrapperClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.previewCardWrapperHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.previewCardWrapperSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.previewCardWrapperVertical,
      },
      primaryAdWrapperClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.primaryAdWrapperHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.primaryAdWrapperSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.primaryAdWrapperVertical,
      },
      primaryButtonClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.primaryAdButtonHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.primaryAdButtonSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.primaryAdButtonVertical,
      },
      primaryTitleClass: {
        [AppAdUnitPreviewLayout.Vertical]: classNames.primaryTitleVertical,
        [AppAdUnitPreviewLayout.Square]: classNames.primaryTitleSquare,
      },
      primaryAdVectorClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.primaryAdIconHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.primaryAdIconSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.primaryAdIconVertical,
      },
      primaryAdBadgeClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.primaryAdBadgeHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.primaryAdBadgeSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.primaryAdBadgeVertical,
      },
      primaryCaptionClass: {
        [AppAdUnitPreviewLayout.Vertical]: classNames.displayNone,
        [AppAdUnitPreviewLayout.Square]: classNames.displayNone,
      },
      primaryBodyClass: {
        [AppAdUnitPreviewLayout.Vertical]: classNames.primaryBodyVertical,
        [AppAdUnitPreviewLayout.Square]: classNames.primaryBodySquare,
      },
      secondaryAdWrapperClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.secondaryAdWrapperHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.secondaryAdWrapperSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.secondaryAdWrapperVertical,
      },
      secondarySubtitleClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.secondarySubtitleHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.secondarySubtitleSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.secondarySubtitleVertical,
      },
      secondaryButtonClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.secondaryAdButtonHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.secondaryAdButtonSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.secondaryAdButtonVertical,
      },
      secondaryAdVectorClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.secondaryAdIconHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.secondaryAdIconSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.secondaryAdIconVertical,
      },
      secondaryAdBadgeClass: {
        [AppAdUnitPreviewLayout.Horizontal]: classNames.secondaryBadgeHorizontal,
        [AppAdUnitPreviewLayout.Square]: classNames.secondaryBadgeSquare,
        [AppAdUnitPreviewLayout.Vertical]: classNames.secondaryBadgeVertical,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layout]
  );
  return (
    <>
      <div className={mergeClasses(classes.previewCardWrapperClass[layout], classNames.nonSelectableText)}>
        <Card className={mergeClasses(classes.primaryAdWrapperClass[layout])}>
          <Subtitle1 className={mergeClasses(classes.primaryTitleClass[layout])}>{formatMessage(messages.adPreviewPrimaryTitle)}</Subtitle1>
          <Caption1 className={mergeClasses(classes.primaryCaptionClass[layout])}>
            {formatMessage(messages.adPreviewPrimaryDescriptionLine1)}
          </Caption1>
          <Body1 className={mergeClasses(classes.primaryBodyClass[layout])}>
            {formatMessage(messages.adPreviewPrimaryDescriptionLine2)}
          </Body1>
          <Button appearance="primary" className={mergeClasses(classes.primaryButtonClass[layout])}>
            {formatMessage(messages.adPreviewPrimaryDescriptionActionButton)}
          </Button>
          <img
            className={mergeClasses(classes.primaryAdVectorClass[layout])}
            src={AdVector}
            alt={formatMessage(messages.adPreviewPrimaryTitle)}
          />
          <div className={mergeClasses(classes.primaryAdBadgeClass[layout])}>{formatMessage(messages.adPreviewAdBadgeContent)}</div>
        </Card>
        <Card className={mergeClasses(classes.secondaryAdWrapperClass[layout])}>
          <Subtitle1 className={mergeClasses(classes.secondarySubtitleClass[layout])}>
            {formatMessage(messages.adPreviewPrimaryTitle)}
          </Subtitle1>
          <Button className={mergeClasses(classes.secondaryButtonClass[layout])}>
            {formatMessage(messages.adPreviewPrimaryDescriptionActionButton)}
          </Button>
          <img
            className={mergeClasses(classes.secondaryAdVectorClass[layout])}
            src={AdVector}
            alt={formatMessage(messages.adPreviewPrimaryTitle)}
          />
          <div className={mergeClasses(classes.secondaryAdBadgeClass[layout])}>{formatMessage(messages.adPreviewAdBadgeContent)}</div>
        </Card>
      </div>
    </>
  );
};

export default injectIntl(AdPreviewDynamicRenderer);
