import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { isTythonSelfServeUser, showLoadingToastNotification } from '../../../../../@data';
import { APP_NAME_NEW_COMPACT, APP_NAME_PUBCENTER } from '../../../../../constants';
import { DownloadOption } from '../../../../../layout/GridPageV2';
import { onDownloadButtonClicked } from '../../../../../layout/GridPageV2/@data/actions/commandBarActions';
import '../../../../../layout/GridPageV2/@data/orchestrators/downloadButtonClicked';
import AuditHistoryMessages from '../../AuditHistory.messages';
import { getGridColumns } from '../../utils/getGridColumns';
import { handleDownloadButtonClicked } from '../actions';
import { fetchAuditLogs } from '../services/fetchAuditLogs';
import { getAuditHistoryStore } from '../store/store';

orchestrator(handleDownloadButtonClicked, async ({ downloadType, formatMessage, locale }) => {
  Log.info(`User intends to download audit history data for download type: ${downloadType}`);

  const filename = `${isTythonSelfServeUser() ? APP_NAME_NEW_COMPACT : APP_NAME_PUBCENTER}-Audit-History-${new Date().getFullYear()}-${
    new Date().getMonth() + 1
  }-${new Date().getDate()}}.csv`;
  const columns = getGridColumns(formatMessage, locale);
  let data = getAuditHistoryStore().data || [];

  if (downloadType === DownloadOption.all) {
    showLoadingToastNotification({
      textMessageDescriptor: AuditHistoryMessages.startingDownloadNotification,
      onDismiss: () => console.log('download data fetched'),
    });
    const response = await fetchAuditLogs(getAuditHistoryStore().totalRecords, false);
    data = response?.changeLogs || [];
  }

  onDownloadButtonClicked(formatMessage, data, filename, columns, downloadType);
});
