import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { AdUnit, ApiError, getAppStore, putEntity, showErrorToastNotification, showSuccessToastNotification } from '../../../../../@data';
import messages from '../../Manage/ManagePage.messages';
import { onAdUnitNameUpdate } from '../actions';
import { setAdUnitsData } from '../mutatorActions';
import { getAdunitStore } from '../store/store';

orchestrator(onAdUnitNameUpdate, async ({ formatMessage, setIsLoading, setErrorMessage, setVisibleCallOut }) => {
  const { publisher, userContext, account } = getAppStore();
  const { active, raw } = getAdunitStore();

  if (active.name?.trim().length === 0) {
    setErrorMessage(formatMessage(messages.adunitNameInvalid));
    return;
  }

  if (publisher && account && raw) {
    const body: AdUnit = {
      ...raw,
      id: active.id,
      name: active.name,
    };

    setIsLoading(true);

    try {
      let entity: AdUnit | undefined;

      // while adunit is being edited when all accounts is set in manage page
      body.accountId = raw.accountId;
      entity = await putEntity([publisher], body, userContext, AdUnit, new URLSearchParams([['type', 'Search']]));

      showSuccessToastNotification({ textMessageDescriptor: messages.editedAdunitSuccess, intlMessageValues: { id: entity.id } });

      let adUnitsRawData = [...(getAdunitStore().adUnitsRaw.data || [])];
      if (adUnitsRawData && entity) {
        adUnitsRawData = adUnitsRawData.map((adUnit) => {
          const adUnitData = { ...adUnit };
          if (adUnitData.id === entity?.id) {
            adUnitData.name = entity?.name;
            return adUnitData;
          } else {
            return adUnit;
          }
        });
        setAdUnitsData(adUnitsRawData);
      }
    } catch (e) {
      Log.error(e);
      const apiErrorObj = e as ApiError;
      let errMsg = apiErrorObj.message;
      errMsg = errMsg.substring(errMsg.indexOf(`{"errors":`));
      showErrorToastNotification({ text: `Update adunit name caught an error "${errMsg}"` });
    } finally {
      setIsLoading(false);
      setVisibleCallOut(0);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: messages.adUnitInvalidId, intlMessageValues: { id: active.id } });
    setVisibleCallOut(0);
  }
});
