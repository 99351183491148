export function downloadFile(dataUrl: string, filename: string) {
  if (!filename || !dataUrl) {
    return;
  }
  const link = document.createElement('a');
  link.setAttribute('download', filename);
  link.setAttribute('href', dataUrl);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
