import { mutator } from 'satcheljs';
import { onCopyCodeIconChanged, onCopyScriptIconChanged, onScriptChanged, onScriptPageFormCleared } from '../actions/onScriptPageActions';
import { getScriptPageStore } from '../store/store';

mutator(onScriptChanged, (msg) => {
  getScriptPageStore().script = msg.script;
});

mutator(onCopyScriptIconChanged, (msg) => {
  getScriptPageStore().copyScriptIcon = msg.iconName;
});

mutator(onCopyCodeIconChanged, (msg) => {
  getScriptPageStore().copyCodeIcon = msg.iconName;
});

mutator(onScriptPageFormCleared, () => {
  getScriptPageStore().script = '';
});
