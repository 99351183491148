import { mergeStyleSets } from '@fluentui/react';
import { ISignUpPageStyleProps, ISignUpPageStyles } from '.';
import { BreakPoints, IPubcenterPalette, IPubcenterSpacing } from '../../../theme';

export const getStyles = (props: ISignUpPageStyleProps): ISignUpPageStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const { ResponsiveSmall } = BreakPoints;
  return mergeStyleSets({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      overflow: 'hidden',
    },
    header: {
      height: 48,
      backgroundColor: (theme.palette as IPubcenterPalette).headerGlobal,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: '600',
    },
    content: {
      fontSize: 14,
    },
    section: {
      width: 560,
      textAlign: 'justify',
      justifyContent: 'inter-word',
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      selectors: {
        div: {
          fontSize: 28,
          marginRight: spacing.size16,
          marginTop: '4px',
          marginBottom: '9px',
        },
        span: {
          fontSize: 16,
          lineHeight: 22,
          marginRight: theme.spacing.s1,
        },
      },
    },
    body: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 14,
      textAlign: 'center',
    },
    componentContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      textAlign: 'center',
      [ResponsiveSmall]: {
        minWidth: '300px',
        width: '100%',
      },
    },
    stepper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      textAlign: 'center',
      [ResponsiveSmall]: {
        justifyContent: 'left',
        paddingLeft: '15px',
      },
    },
    buttonSection: {
      width: '577px',
      textAlign: 'end',
      [ResponsiveSmall]: {
        width: '100%',
      },
    },
    itemIdentity: {
      flexDirection: 'row-reverse',
      marginLeft: 'auto',
      marginRight: '10px',
    },
    buttonStyle: {
      margin: spacing.size4,
    },
  });
};
