import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { Publisher, getCurrentPublisher, onLoadingChanged, showErrorToastNotification } from '../../../../../@data';
import { AddressValidation } from '../../../../../@data/store/schema/models/AddressValidation';
import { deepClone } from '../../../../../utils';
import messages from '../../ProfilePage.messages';
import { setIsOrgAddressValid, setProfileErrorsAction, validateOrgAddress } from '../actions';
import { validateAddressAsync } from '../services';

orchestrator(validateOrgAddress, async ({ profile, formatMessage }) => {
  try {
    const publisher = getCurrentPublisher();

    if (!publisher) {
      return Promise.reject('No publisher found');
    }

    const newPublisherDetails: Publisher = deepClone(profile.publisher);
    if (!newPublisherDetails) {
      throw new Error('Publisher details are missing');
    }

    const addressValidation = new AddressValidation();
    addressValidation.postalCode = newPublisherDetails.address?.postalCode;
    addressValidation.country = newPublisherDetails.address?.country;
    addressValidation.city = newPublisherDetails.address?.city;
    addressValidation.stateOrProvince = newPublisherDetails.address?.stateOrProvince;
    addressValidation.line1 = newPublisherDetails.address?.line1;
    addressValidation.line2 = newPublisherDetails.address?.line2;
    addressValidation.isOrg = true;

    // add new endpoint call
    const result = await validateAddressAsync(addressValidation, publisher);
    setIsOrgAddressValid(result.isOrgAddressValid);
  } catch (error) {
    if (error.code === 400) {
      setProfileErrorsAction({ 'publisher.address.postalCode': formatMessage(messages.profileInvalidAddressErrorMessage) });
    } else {
      showErrorToastNotification({ text: formatMessage(messages.profileAddressValidationFailed) });
    }
    setIsOrgAddressValid(false);

    onLoadingChanged(false);
    Log.error(error);
  }
});
