import { action, orchestrator } from 'satcheljs';
import { getAppStore, isTythonSelfServeUser } from '../../../../../@data';
import { fetchPropertiesServingConfigs } from '../../../AdBlock/@data/services';
import { setPropertiesFetching, setPropertiesServingConfigs } from '../../Manage/__data__/mutatorActions';

export const loadPropertiesServingConfigs = action('loadPropertiesServingConfigs');

orchestrator(loadPropertiesServingConfigs, async () => {
  const { publisher } = getAppStore();

  if (publisher && isTythonSelfServeUser()) {
    setPropertiesFetching(true);
    const propertyServingConfig = await fetchPropertiesServingConfigs(publisher);
    setPropertiesServingConfigs(propertyServingConfig);
    setPropertiesFetching(false);
  }
});
