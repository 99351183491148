import { defineMessages } from 'react-intl';

export default defineMessages({
  nonCSVFileUploadError: {
    id: 'boost.nonCSVFileUploadError',
    defaultMessage: 'File upload failed. Please upload only CSV file.',
  },
  uploadSizeLimitError: {
    id: 'boost.uploadSizeLimitError',
    defaultMessage: "File size can't exceed {limit}. Please upload a smaller file.",
  },
  uploadError: {
    id: 'boost.uploadError',
    defaultMessage: "The file didn't upload. Please upload it again.",
  },
  downloadTemplate: {
    id: 'boost.template',
    defaultMessage: 'Download Current Template',
  },
  uploadFile: {
    id: 'boost.uploadFile',
    defaultMessage: 'Upload file',
  },
  errorMessage: {
    id: 'boost.errorMessage',
    defaultMessage: 'Incorrect JSON, fix JSON and then replace the file',
  },
});
