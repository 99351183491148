import { BreakPoints, IPubcenterTheme } from '../../../theme';
import { ITnCModalStyleProps, ITnCModalStyles } from './TnCModal.types';

export const getStyles = (props: ITnCModalStyleProps): ITnCModalStyles => {
  const { spacing, fonts, palette } = (props.theme as unknown) as IPubcenterTheme;

  const CONTAINER_WIDTH = 582;
  const CONTAINER_WIDTH_MEDIUM = 548;
  const { ResponsiveSmall, ResponsiveMedium } = BreakPoints;
  const PADDING = 28;
  const LAYOUT_HEIGHT = 48 + 48 + PADDING; // header height + footer height + vertical padding
  const getLeftPosition = (width) => `calc((100vw - ${width}px - 4em)/2)`;

  return {
    root: {
      background: 'rgba(0, 0, 0, 0.2)',
      bottom: 0,
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0,
      [ResponsiveSmall]: {
        position: 'initial',
        background: 'transparent',
      },
    },
    content: {
      overflowY: 'auto',
      background: 'white',
      padding: '1em 2em',
      position: 'absolute',
      top: 80,
      width: CONTAINER_WIDTH,
      left: getLeftPosition(CONTAINER_WIDTH),
      borderRadius: spacing.size12,
      height: 'calc(100vh - 200px)',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 386,
      color: palette.neutralPrimary,
      [ResponsiveMedium]: {
        width: CONTAINER_WIDTH_MEDIUM,
        left: getLeftPosition(CONTAINER_WIDTH_MEDIUM),
      },
      [ResponsiveSmall]: {
        top: spacing.size48,
        height: `calc(100vh - ${LAYOUT_HEIGHT}px)`,
        borderRadius: 0,
        width: `calc(100vw - ${PADDING * 2}px)`,
        left: 0,
      },
    },
    summaryContent: {
      marginBottom: spacing.size24,
    },
    tncWrapper: {
      minHeight: spacing.size64,
      overflow: 'scroll',
      maxHeight: 'calc(100% - 372px)',
      border: `1px solid ${palette.neutralSecondary}`,
      boxShadow: `1px 1px 1px ${palette.neutralSecondary}40`,
      borderRadius: spacing.size2,
      padding: spacing.size12,
      paddingRight: spacing.size4,
      margin: '16px 0px',
      color: palette.neutralSecondaryAlt,
      fontFamily: 'inherit',
    },
    tncCheckbox: {
      display: 'inline-flex',
      marginRight: spacing.size12,
    },
    tncCheckboxWrapper: {
      display: 'flex',
      alignItems: 'center',
      svg: {
        marginLeft: spacing.size6,
      },
    },
    tncAckMsgSubline: {
      marginLeft: spacing.size36,
      color: palette.neutralSecondary,
      fontSize: fonts.size12.fontSize,
    },
    endingButton: {
      marginLeft: spacing.size12,
    },
    actionButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: spacing.size12,
    },
    headLineGreeting: {
      fontWeight: 600,
      fontSize: fonts.size20.fontSize,
      margin: `${spacing.size12} 0px`,
    },
  };
};
