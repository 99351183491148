import { Spinner } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { onHelpInfoChanged } from '../../@data';

export const HelpcenterInfoBubbleV2 = ({ html }) => {
  useEffect(() => {
    return () => {
      onHelpInfoChanged();
    };
  }, []);
  if (!html) {
    return <Spinner size="tiny" />;
  }
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
