import { defineMessages } from 'react-intl';

export default defineMessages({
  adunitId: {
    id: 'adunit.id',
    defaultMessage: 'Ad unit ID',
  },
  uploadBoostTemplate: {
    id: 'upload.message',
    defaultMessage: 'Upload Configuration File',
  },
  downloadTemplate: {
    id: 'download.template',
    defaultMessage: 'Download Current Template',
  },
  adunitType: {
    id: 'adunit.type',
    defaultMessage: 'Ad unit type',
  },
  marketPlaceOptionCore: {
    id: 'marketeplace.core',
    defaultMessage: 'Owned and Operated(Core)',
  },
  marketPlaceOptionRest: {
    id: 'marketeplace.rest',
    defaultMessage: 'Owned and Operated(Rest)',
  },
  marketPlaceOptionSyndicated: {
    id: 'marketeplace.syndicated',
    defaultMessage: 'Syndicated',
  },
  marketPlaceOptionSelectTier: {
    id: 'marketplace.selectTier',
    defaultMessage: 'Select Tier',
  },
  marketPlaceOptionsSponsored: {
    id: 'marketplace.sponsored',
    defaultMessage: 'Sponsored',
  },
  marketPlaceOptionsSponsoredEnhanced: {
    id: 'marketplace.sponsoredEnhanced',
    defaultMessage: 'Sponsored Enhanced',
  },
  adunitNameLabel: {
    id: 'adunitName.label',
    defaultMessage: 'Ad unit name',
  },
  adunitIdLabel: {
    id: 'adunit.id',
    defaultMessage: 'Ad unit ID',
  },
  marketplaceLabel: {
    id: 'marketpalce.label',
    defaultMessage: 'Marketplace classfication',
  },
  businessModelLabel: {
    id: 'businessmodel.label',
    defaultMessage: 'Business model',
  },
  businessModelLevel2AriaLabel: {
    id: 'businessmodel.level2Arialabel',
    defaultMessage: 'Business model level 2',
  },
  distributionPartnerCodesLabel: {
    id: 'distributionPartnerCodes.label',
    defaultMessage: 'Search Distribution Partner Codes',
  },
  distributionPartnerCodesLabelDescription: {
    id: 'distributionPartnerCodesDescription.label',
    defaultMessage: 'Please list the Distribution Partner Codes above, with one code per line',
  },
  distributionPartnerCodesTextfieldPlaceholder: {
    id: 'distributionPartnerCodes.textfield.placeholder',
    defaultMessage: 'ExamplePC01\nExamplePC02\nExamplePC03',
  },
  createPageTitle: {
    id: 'create.page.title',
    defaultMessage: 'Create ad unit',
  },
  editPageTitle: {
    id: 'edit.page.title',
    defaultMessage: 'Edit ad unit',
  },
  updateAdUnit: {
    id: 'adunit.updateAdunit',
    defaultMessage: 'Update ad unit',
  },
  continueAdUnit: {
    id: 'adunit.continueAdunit',
    defaultMessage: 'Continue',
  },
  cancelAdUnit: {
    id: 'adunit.cancelAdUnit',
    defaultMessage: 'Cancel',
  },
  previousStepAdUnit: {
    id: 'adunit.previousStepAdUnit',
    defaultMessage: 'Previous',
  },
  channelTitle: {
    id: 'channel.title',
    defaultMessage: 'Channels',
  },
  propertySectionTitle: {
    id: 'property.sectionTitle',
    defaultMessage: 'Properties (optional)',
  },
  advOptionTitle: {
    id: 'advOption.title',
    defaultMessage: 'Advanced Options',
  },
  createChannelButton: {
    id: 'create.channel.button',
    defaultMessage: 'Create Channel',
  },
  channelPickerAriaDesc: {
    id: 'channelPickerAriaDesc',
    defaultMessage: 'Select channels to add the ad unit',
  },
  servingTierTitle: {
    id: 'servingTier.title',
    defaultMessage: 'Serving Tier',
  },
  servingTierOptionLowcost: {
    id: 'servingTier.option.lowcost',
    defaultMessage: 'Low Cost',
  },
  publisherGroupConfigLabel: {
    id: 'publisherGroupConfig.label',
    defaultMessage: 'Publisher Group configuration',
  },
  publisherGroupConfigOptionDefaultPlus: {
    id: 'publisherGroupConfig.option.defaultPlus',
    defaultMessage: 'Default+',
  },
  publisherGroupConfigOptionDefault: {
    id: 'publisherGroupConfig.option.default',
    defaultMessage: 'Default',
  },
  publisherGroupConfigOptionGeneralist: {
    id: 'publisherGroupConfig.option.Generalist',
    defaultMessage: 'Generalist',
  },
  publisherGroupConfigOptionYieldOptimizer: {
    id: 'publisherGroupConfig.option.yieldOptimizer',
    defaultMessage: 'Yield Optimizer',
  },
  publisherGroupConfigOptionYieldMaximizer: {
    id: 'publisherGroupConfig.option.yieldMaximizer',
    defaultMessage: 'Yield Maximizer',
  },
  publisherGroupConfigOptionBingandYahoo: {
    id: 'publisherGroupConfig.option.BingandYahoo',
    defaultMessage: 'Bing/Yahoo O&O',
  },
  publisherGroupConfigOptionLowQualityDistribution: {
    id: 'publisherGroupConfig.option.LowQualityDistribution',
    defaultMessage: 'Low quality Distribution traffic',
  },
  publisherGroupConfigOptionMediumQualityDistribution: {
    id: 'publisherGroupConfig.option.MediumQualityDistribution',
    defaultMessage: 'Medium quality Distribution traffic',
  },
  publisherGroupConfigOptionHighQualityDistribution: {
    id: 'publisherGroupConfig.option.HighQualityDistribution',
    defaultMessage: 'High quality Distribution traffic',
  },
  privateMarketplaceLabel: {
    id: 'privateMarketplace.label',
    defaultMessage: 'Enable Private Marketplace',
  },
  privateMarketplaceTextfieldLabel: {
    id: 'privateMarketplace.textfield.label',
    defaultMessage: 'Private Marketplace IDs',
  },
  privateMarketplaceTextfieldPlaceholder: {
    id: 'privateMarketplace.textfield.placeholder',
    defaultMessage: 'Example ID 1, enter one per line',
  },
  promotionRadioOptionLabel: {
    id: 'promotionOption.radio.label',
    defaultMessage: 'Sponsored Product Ads Stores',
  },
  promotionOptionTextfieldLabel: {
    id: 'promotionOption.textfield.label',
    defaultMessage: 'Store IDs',
  },
  promotionOptionTextfieldPlaceholder: {
    id: 'promotionOption.textfield.placeholder',
    defaultMessage: 'Example Store ID 1, enter one per line',
  },
  boostRadioOptionLabel: {
    id: 'boostOption.radio.label',
    defaultMessage: 'Boost Advertiser Accounts',
  },
  boostOptionTextfieldLabel: {
    id: 'boostOption.textfield.label',
    defaultMessage: 'Advertiser Account IDs',
  },
  boostOptionTextfieldPlaceholder: {
    id: 'boostOption.textfield.placeholder',
    defaultMessage: 'Example Account ID 1, enter one per line',
  },
  propertyName: {
    id: 'property.name',
    defaultMessage: 'Property name',
  },
  propertyButton: {
    id: 'property.add',
    defaultMessage: 'Add property',
  },
  siteButton: {
    id: 'site.add',
    defaultMessage: 'Add site',
  },
  site: {
    id: 'site',
    defaultMessage: 'Site',
  },
  websiteUrl: {
    id: 'property.url',
    defaultMessage: 'Website URL',
  },
  propertyId: {
    id: 'property.id',
    defaultMessage: 'Property ID',
  },
  propertyDefaultOptionText: {
    id: 'property.defaultOptionText',
    defaultMessage: 'Type ID or URL to select the site',
  },
  propertyDefaultNotFound: {
    id: 'property.defaultNotFound',
    defaultMessage: 'No property found, create new property before select',
  },
  siteDefaultNotFound: {
    id: 'site.defaultNotFound',
    defaultMessage: 'No site found, create new site before select',
  },
  createPropertyButtonLabel: {
    id: 'property.CreateButtonLabel',
    defaultMessage: 'Create property',
  },
  propertySearchPlaceholder: {
    id: 'property.SearchPlaceholder',
    defaultMessage: 'Search properties by ID or URL',
  },
  businessModelLevel1DefaultOptionText: {
    id: 'businessmodel.Level1DefaultOptionText',
    defaultMessage: 'Select level 1 (optional)',
  },
  businessModelLevel2DefaultOptionText: {
    id: 'businessmodel.Level2DefaultOptionText',
    defaultMessage: 'Select level 2 (optional)',
  },
  propertyPickerEmptyOptionLabel: {
    id: 'propertyPicker.EmptyOptionLabel',
    defaultMessage: 'Select a property',
  },
  createPropButtonAriaDesc: {
    id: 'propertyPicker.createButtonAriaDesc',
    defaultMessage: 'Click this button to create property within this page',
  },
  propertyOptionsAriaLabel: {
    id: 'propertyPicker.OptionsAriaLabel',
    defaultMessage: 'Property search results',
  },
  propertyPickerSearchBoxAriaDesc: {
    id: 'propertyPickerSearchBoxAriaDesc',
    defaultMessage: 'Click enter to view all properties',
  },
  propertyPickerItemSelectedAnnounceMsg: {
    id: 'propertyPickerItemSelectedAnnounceMsg',
    defaultMessage: 'Property selected',
  },
  createAdunit: {
    id: 'createAdunit',
    defaultMessage: 'Create new ad unit',
  },
  newAudienceAd: {
    id: 'newAudienceAd',
    defaultMessage: 'New audience ad',
  },
  nativeAdOptionLabel: {
    id: 'nativeAdOptionLabel',
    defaultMessage: 'Native/Audience ad \n\n Match the look of your site and fit in between posts and listings.',
  },
  searchAdOptionLabel: {
    id: 'searchAdOptionLabel',
    defaultMessage: 'Search ad \n\n Coming soon',
  },
  otherAdOptionLabel: {
    id: 'otherAdOptionLabel',
    defaultMessage: 'Other ad \n\n Coming soon',
  },
  nativeAdDesc: {
    id: 'nativeAdDesc',
    defaultMessage: 'Match the look of your site and fit in between posts and listings.',
  },
  seeExample: {
    id: 'seeExample',
    defaultMessage: 'See example',
  },
  comingSoon: {
    id: 'comingSoon',
    defaultMessage: 'Coming soon',
  },
  templatePanelTitle: {
    id: 'templatePanelTitle',
    defaultMessage: 'Choose a template and style to match the feeds on your page',
  },

  mediationHeader: {
    id: 'mediationSetupMessages.mediationHeader',
    defaultMessage: 'Mediation set up',
  },
  mediationDescription: {
    id: 'mediationSetupMessages.mediationDescription',
    defaultMessage: `Connect your Google accounts to ensure you are getting the highest bid possible for your ad inventory. We'll only serve our ads if we predict we can deliver a higher payment compared to Google.`,
  },
  mediationToggleLabel: {
    id: 'mediationSetupMessages.mediationToggleLabel',
    defaultMessage: 'Enable mediation',
  },
  nativeAd: {
    id: 'nativeAdAccordionCheckboxLabel',
    defaultMessage: 'Native Ad',
  },
  nativeAdSubContent: {
    id: 'nativeAdAccordionSubContent',
    defaultMessage: 'Match the look of your site and fit in between posts and listings',
  },
  displayAd: {
    id: 'displayAdAccordionCheckboxLabel',
    defaultMessage: 'Display Ad',
  },
  displayAdSubContent: {
    id: 'displayAdAccordionSubContent',
    defaultMessage: 'Simple image ads that fit in between posts and listings',
  },
  nativeAdConfigLabel: {
    id: 'nativeAd.configuration.label',
    defaultMessage: 'Native ad configuration',
  },
  nativeAdConfigLabelFont: {
    id: 'nativeAd.configuration.font.label',
    defaultMessage: 'Font',
  },
  nativeAdConfigLabelTitleColor: {
    id: 'nativeAd.configuration.titleColor.label',
    defaultMessage: 'Title Color',
  },
  nativeAdConfigLabelBackgroundColor: {
    id: 'nativeAd.configuration.backgroundColor.label',
    defaultMessage: 'Background Color',
  },
  nativeAdConfigLabelButtonBackgroundColor: {
    id: 'nativeAd.configuration.buttonBackgroundColor.label',
    defaultMessage: 'Button Background Color',
  },
  nativeAdConfigMsgBarContent: {
    id: 'nativeAd.configuration.messageBar.content',
    defaultMessage: 'Ads might look different from the preview shown.',
  },
  displayAdMsgBarContent: {
    id: 'displayAd.configuration.messageBar.content',
    defaultMessage:
      'Smart auto-sizing will adapt to your site’s container, and so the actual ads served might look slightly different from the examples shown. For smart auto-sizing to work, {displayAdMsgBarLink}',
  },
  displayAdMsgBarLink: {
    id: 'displayAd.configuration.messageBar.link',
    defaultMessage: 'ensure you have properly defined your container sizes',
  },
  adPreview: {
    id: 'adType.preview.title',
    defaultMessage: 'Ad Preview',
  },
  adPreviewContainerSize: {
    id: 'adType.preview.container.size.label',
    defaultMessage: 'Container size',
  },
  warningAdUnitTypeNotSelected: {
    id: 'adType.warning.notSelected',
    defaultMessage: 'At least one ad unit type should be selected.',
  },
  warningAdUnitTypeNotFullySelected: {
    id: 'adType.warning.notFullySelected',
    defaultMessage: 'Selecting fewer ad types may reduce monetization.',
  },
  saveAndGetCode: {
    id: 'tython.adunit.create.submit.label',
    defaultMessage: 'Save and get code',
  },
  finishAdUnitCreation: {
    id: 'tython.adunit.create.submit.aftercode',
    defaultMessage: 'Finish',
  },
  comingSoonMsg: {
    id: 'tython.adunit.create.type.display.intro.msg',
    defaultMessage: 'Coming Soon',
  },
  selectDisplayAdLayout: {
    id: 'tython.adunit.create.type.display.layout.selection',
    defaultMessage: 'Pick a layout for your display ad',
  },
  displayAdPreviewHeadline: {
    id: 'tython.adunit.create.type.display.preview.headline',
    defaultMessage: 'Lorem ipsum dolor sit amet',
  },
  displayAdPreviewTextContent: {
    id: 'tython.adunit.create.type.display.preview.text.content',
    defaultMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  displayAdPreviewLearnMoreButton: {
    id: 'tython.adunit.create.type.display.preview.learnMore.button',
    defaultMessage: 'LEARN MORE',
  },
  displayAdPreviewExamplePrimaryTitle: {
    id: 'tython.adunit.create.type.display.preview.primary.title',
    defaultMessage: 'Example 1',
  },
  displayAdPreviewExampleSecondaryTitle: {
    id: 'tython.adunit.create.type.display.preview.secondary.title',
    defaultMessage: 'Example 2',
  },
  displayAdPreviewExampleTertiaryTitle: {
    id: 'tython.adunit.create.type.display.preview.tertiary.title',
    defaultMessage: 'Example 3',
  },
  requiredFeedbackSignalsLabel: {
    id: 'requiredFeedbackSignals.label',
    defaultMessage: 'Required feedback signals',
  },
  requiredImpressionFeedbackSignalOptionLabel: {
    id: 'requiredImpressionFeedbackSignalOption.radio.label',
    defaultMessage: 'Impression feedback (usually required for native ads and not search ads)',
  },
  defaultAdUnitName: {
    id: 'tython.default.adUnit.name',
    defaultMessage: 'Ad unit {number}',
  },
  adunitErrorInForm: {
    id: 'tython.adunits.adunitErrorInForm',
    defaultMessage: 'Adunit - You have {count} errors in the form, please fix them to continue.',
  },
  createdAdunitSuccess: {
    id: 'tython.adunits.createdAdunitSuccess',
    defaultMessage: 'Created new adunit {id} success',
  },
  editedAdunitSuccess: {
    id: 'tython.adunits.editedAdunitSuccess',
    defaultMessage: 'Edited adunit {id} success',
  },
  loadPropertyFailedInAdunit: {
    id: 'tython.adunit.loadPropertyFailedInAdunit',
    defaultMessage: 'Load property failed, please try again later.',
  },
});
