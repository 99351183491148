import { mixed, object, string } from 'yup';
import { Property, PropertyApprovalStatus } from '../../../../../../../@data';
import { IPropertyViewModel } from './IPropertyViewModel';

export function nonTythonPropertyErrorModel(form: IPropertyViewModel, isEditMode: boolean, properties: Property[] = []) {
  return object<IPropertyViewModel>().shape({
    name: string().trim().required() && string().max(35).duplicateName(properties, form),
    url: string().trim().domain().required() && string().trim().duplicateDomain(properties, isEditMode, form),
    approvalStatusComments: string(),
    approvalStatus: mixed()
      .oneOf([
        PropertyApprovalStatus.Approved,
        PropertyApprovalStatus.AutoApproved,
        PropertyApprovalStatus.Rejected,
        PropertyApprovalStatus.UnderManualReview,
      ])
      .required(),
  });
}

export function tythonPropertyErrorModel(form: IPropertyViewModel, isEditMode: boolean, properties: Property[] = []) {
  return object<IPropertyViewModel>().shape({
    name: string().trim().required() && string().max(35).duplicateName(properties, form),
    url:
      string().trim().domain().required() &&
      string()
        .trim()
        .duplicateDomain(properties, isEditMode, form)
        .test('url', 'Invalid domain name', (value) => new RegExp(/^(?!https?:\/\/)(?!.*\/)[\w.-]+\.[\w-]{2,}$/i).test(value)),
  });
}
