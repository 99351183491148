import { TextField } from '@fluentui/react';
import React, { useEffect, useRef } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { isTythonSelfServeUser } from '../../../../../../@data';
import { completeAccess } from '../../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../../App.messages';
import { FormItem, FormState } from '../../../../../../components/Form';
import { PROPERTY_NAME_INPUT } from '../../../../../../constants/DataTestIdConstants';
import { injectIntlAndObserver } from '../../../../../../utils';
import { onNameChanged } from '../../__data__/mutatorActions';
import { getCreatePropertyStore } from '../../__data__/store/store';
import { GetPropertyAccessPermission } from '../../variations/CreateNonTythonProperty/utils/GetPropertyAccessPermissions';
import { IsDisabled } from '../../variations/CreateNonTythonProperty/utils/IsFieldDisabled';
import messages from './messages';

export interface IPropertyNameInputFieldProps {
  mode: FormState;
}

const PropertyNameInputField = injectIntlAndObserver<InjectedIntlProps & IPropertyNameInputFieldProps>(
  ({ intl: { formatMessage }, mode }) => {
    const {
      active: { name },
      errors: { name: error },
    } = getCreatePropertyStore();
    const propertyAccessPermissions = GetPropertyAccessPermission();
    const accessLevel = propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.name;
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current === null) {
        return;
      }
      const container = document.getElementById('propertyId') as HTMLElement;
      const label = container.getElementsByClassName('ms-Label')[0] as HTMLElement;
      if (label) {
        label.title = formatMessage(AppMessages.required);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef.current]);

    return (
      <FormItem id="propertyId">
        <TextField
          label={formatMessage(isTythonSelfServeUser() ? messages.inputSiteName : messages.inputPropertyName)}
          componentRef={inputRef}
          required={true}
          aria-required={true}
          errorMessage={error}
          value={name}
          placeholder={formatMessage(messages.propertyNameLength)}
          onChange={(_, value) => onNameChanged(value!)}
          disabled={IsDisabled(mode, accessLevel)}
          data-test-id={PROPERTY_NAME_INPUT}
        />
      </FormItem>
    );
  }
);

export default PropertyNameInputField;
