import { Body1Strong, Caption1, Card, Input, Skeleton, SkeletonItem, Spinner, mergeClasses } from '@fluentui/react-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Log } from '../../../../../../../../logging/src';
import { buildRequestHeadersWithAuthToken, getAppStore } from '../../../../../../../@data';
import { uiConfig } from '../../../../../../../config/ui.config';
import { useDebouncedValue } from '../../../../../../../hooks';
import useOnClickOutside from '../../../../../../../hooks/useOnClickOutside';
import { injectIntlAndObserver } from '../../../../../../../utils';
import messages from '../messages';
import SearchIcon from '../resources/SearchIcon.svg';
import { useConnectAppDropdownStyles } from './styles';

type ConnectAppDropdownProps = {
  inventoryAppStoreId: string;
  onChange: (value: string) => void;
  isSaving?: boolean;
  onChangeCallback?: (value: string) => void;
} & InjectedIntlProps;

type ConnectAppDropdownType = React.FC<ConnectAppDropdownProps>;

const ConnectAppDropdown: ConnectAppDropdownType = ({
  inventoryAppStoreId,
  isSaving,
  onChange,
  onChangeCallback,
  intl: { formatMessage },
}) => {
  const [searchString, setSearchString] = useState('');
  const [appOptions, setAppOptions] = useState([]); // TODO: update type
  const [selectedOption, setSelectedOption] = useState(undefined);
  const debouncedAppSearchString = useDebouncedValue(searchString, 500);
  const [shouldRenderOptions, setShouldRenderOptions] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { userContext } = getAppStore();
  const isEditMode = window.location.pathname.includes('editApp');
  const isOptionInitialized = useRef(false);
  const optionMenuRef = useRef<HTMLDivElement>(null);
  const classNames = useConnectAppDropdownStyles();
  useOnClickOutside(optionMenuRef, () => {
    setShouldRenderOptions(false);
  });
  const shouldInitializeOptions = isEditMode && !isOptionInitialized.current && inventoryAppStoreId;

  const fetchAppSearchData = async (searchedString, signal?: AbortSignal) => {
    if (searchedString.length) {
      const url = `${uiConfig.getApiBaseAddress('1.2')}/metadata/store?appId=${searchedString}&market=en-US`;
      try {
        setIsSearching(true);
        const headers = await buildRequestHeadersWithAuthToken(url, userContext);

        const response = await fetch(url, { method: 'GET', headers: headers, signal });
        if (!response.ok) {
          Log.error(`HTTP error! status: ${response.status}`);
        }
        const appSearchResult = await response.json();
        setAppOptions(appSearchResult.data.matchingApps);
        setShouldRenderOptions(true);
        if (!signal) {
          setSelectedOption(appSearchResult.data.matchingApps[0]);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.error('Fetch aborted:', searchedString);
        } else {
          Log.error(error);
        }
      } finally {
        setIsSearching(false);
      }
    } else {
      setAppOptions([]);
    }
  };

  useEffect(() => {
    // Create an instance of AbortController
    const controller = new AbortController();
    const signal = controller.signal;
    fetchAppSearchData(debouncedAppSearchString, signal);
    // Cleanup function that aborts the fetch when the component unmounts or debouncedAppSearchString changes
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAppSearchString]);

  useEffect(() => {
    if (shouldInitializeOptions) {
      fetchAppSearchData(inventoryAppStoreId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInitializeOptions]);

  const onOptionSelect = (option) => {
    setShouldRenderOptions(false);
    onChange(option.appId);
    setSelectedOption(option);
    setSearchString('');
    onChangeCallback?.(option.appName);
  };

  const renderAppCard = (option, isEditable) => {
    const content = (
      <>
        <div className={classNames.optionInnerWrapper}>
          <img className={classNames.optionCardImgIcon} src={option.iconImageUrl} alt={option.appName} />
          <Body1Strong block className={classNames.optionCardTitle}>
            {option.appName}
          </Body1Strong>
        </div>
        <div className={classNames.optionInnerWrapperLower}>
          <Caption1 className={classNames.optionCardAppId}>{option.appId}</Caption1>
        </div>
      </>
    );

    if (!isEditable) {
      return (
        <Card appearance="outline" className={classNames.optionCardWrapper}>
          {content}
        </Card>
      );
    }
    return (
      <Card className={mergeClasses(classNames.optionCardWrapper, classNames.optionCardSelectable)} onClick={() => onOptionSelect(option)}>
        {content}
      </Card>
    );
  };

  const [
    shouldRenderSearchInput,
    shouldRenderAppPreviewForExistingInventory,
    shouldRenderAppOptionsDropdown,
    shouldRenderSelectedAppPreview,
  ] = useMemo(
    () => [!isEditMode, selectedOption, !isEditMode && shouldRenderOptions, !isEditMode && selectedOption && !shouldRenderOptions],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditMode, appOptions, shouldRenderOptions, selectedOption]
  );

  return (
    <>
      {isSaving && (
        <Skeleton>
          <SkeletonItem />
        </Skeleton>
      )}
      <div className={isSaving ? classNames.isHiding : classNames.containerWrapper}>
        {shouldRenderSearchInput && (
          <Input
            id="connectAppId"
            value={searchString}
            placeholder={formatMessage(messages.appSearchFieldPlaceholder)}
            onClick={() => setShouldRenderOptions(true)}
            disabled={false}
            onChange={(_, data) => setSearchString(data.value.toUpperCase())}
            contentBefore={
              <img className={classNames.appSearchIcon} src={SearchIcon} alt={formatMessage(messages.appSearchFieldPlaceholder)} />
            }
            onBlur={(e) => {
              if (selectedOption && appOptions.length === 0) {
                setShouldRenderOptions(false);
              }
            }}
            contentAfter={isSearching ? <Spinner size="tiny" /> : undefined}
          />
        )}

        {isEditMode ? (shouldRenderAppPreviewForExistingInventory && renderAppCard(selectedOption, false)) || <Spinner /> : <></>}

        {shouldRenderAppOptionsDropdown && appOptions.length > 0 && (
          <Card ref={optionMenuRef} className={classNames.optionsWrapper}>
            {appOptions.map((option) => renderAppCard(option, true))}
          </Card>
        )}

        {(shouldRenderSelectedAppPreview && renderAppCard(selectedOption, false)) || <></>}
      </div>
    </>
  );
};

export default injectIntlAndObserver<ConnectAppDropdownProps>(ConnectAppDropdown);
