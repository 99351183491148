import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class UserSettings extends Model {
  public className = UserRoleEntity.UserSettings;
  public systemPreferences: number;
  public billingAlerts: number;
  public servingStatus: number;
  public profileReview: number;
  public invitedUsers: number;
  public userDelete: number;

  constructor(params: UserSettings) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: UserSettings, userSettings: UserSettings) {
    // tslint:disable:no-bitwise
    this.accessPermission |= userSettings.accessPermission;
    this.systemPreferences |= userSettings.systemPreferences;
    this.billingAlerts |= userSettings.billingAlerts;
    this.servingStatus |= userSettings.servingStatus;
    this.profileReview |= userSettings.profileReview;
    this.invitedUsers |= userSettings.invitedUsers;
    this.userDelete |= userSettings.userDelete;
    // tslint:enable:no-bitwise
  }
}
