import { useBoolean } from '@fluentui/react-hooks';
import { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import messages from '../../Create/CreateAdunit.messages';
import { IAdUnitMediationSelectorProps } from '../components/AdUnitMediationSelector';
import { IAdUnitNameInputFieldProps } from '../components/AdUnitNameInput';
import { IAdUnitSiteSelectorProps } from '../components/AdUnitSiteSelector';
import { IAdUnitTypeSelectorProps } from '../components/AdUnitTypeSelector';
import { IScriptPageProps } from '../components/ScriptPage/ScriptPage.types';
import { propertySerializer } from '../services/loadProperties';
import { getSiteSelectionSummary } from '../services/utils';
import { AdTypes, NativeAdProperties, TythonAdUnitDetailPageProps, UseCreateTythonAdunitRenderControl } from './types';
import useAdUnitData from './useAdUnitData';
import useAdunitEventHandlers from './useAdUnitEventHandlers';

/**
 * This is the hook to serve components render behavior in ad unit creation flow for tython experience
 * it will take satcheljs store values as props to compose props for adunit builder form components.
 * Please see @type UseCreateTythonAdunitRenderControl for hook usages
 */
const useCreateTythonAdunitRenderControl: UseCreateTythonAdunitRenderControl = ({
  appStore,
  googleAdsenseStore,
  mode,
  routeProps,
  intl,
  adUnitsStore,
}) => {
  const { formatMessage } = intl ?? {};
  const isEditing = !window?.location?.href.includes('adunit/create');
  const [isScriptViewActive, { setTrue: activeScriptView }] = useBoolean(false);
  const [isPropertyPanelActive, { setTrue: activeNewPropertyCreation, setFalse: deActiveNewPropertyCreation }] = useBoolean(false);
  const {
    properties,
    isPropertyLoading,
    isValidAdUnit,
    isPropertySaving,
    isAdUnitMediationLoading,
    activeAdunit,
    initialAdUnit,
    isAdUnitSaving,
    isAdUnitLoading,
    isAdUnitInitialized,
    setIsAdUnitSaving,
    setActiveAdunit,
    getActiveAdunit,
    createProperty,
  } = useAdUnitData({
    appStore,
    googleAdsenseStore,
    onPropertyCreated: deActiveNewPropertyCreation,
    adUnitsStore,
    formatMessage,
  });

  const {
    onAdunitNameChange,
    onAdunitTypeChange,
    onAdunitMediationChange,
    onAddSiteClick,
    onSiteChange,
    onCreatingNewProperty,
    onAdUnitSubmit,
    onRedirectBackToAdUnitManagePage,
  } = useAdunitEventHandlers({
    getActiveAdunit,
    setActiveAdunit,
    createProperty,
    activeNewPropertyCreation,
    setIsAdUnitSaving,
    activeScriptView,
    deActiveNewPropertyCreation,
    formatMessage,
    routeProps,
    appStore,
    googleAdsenseStore,
    initialAdUnit,
    properties,
  });

  const adUnitNameProps = useMemo<IAdUnitNameInputFieldProps>(
    () => ({
      onChange: onAdunitNameChange,
      value: activeAdunit?.name,
      errorMsg: undefined,
      disabled: false,
      shouldRenderComponent: !isScriptViewActive,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeAdunit?.name, isScriptViewActive]
  );

  const adUnitSiteProps = useMemo<IAdUnitSiteSelectorProps & RouteComponentProps>(
    () => ({
      options: propertySerializer(properties),
      onAddSiteClick,
      onSiteChange,
      searchBoxPlaceholder:
        getSiteSelectionSummary(properties, activeAdunit?.propertyId) ?? formatMessage(messages.propertyDefaultOptionText),
      isPropertyPanelActive,
      onCloseNewPropertyPanel: deActiveNewPropertyCreation,
      onCreatingNewProperty,
      isLoading: isPropertyLoading,
      isSaving: isPropertySaving,
      shouldRenderComponent: !isScriptViewActive,
      isDisabled: isEditing,
      ...routeProps,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeAdunit?.propertyId, isPropertyLoading, isPropertySaving, properties, isPropertyPanelActive, routeProps, isScriptViewActive]
  );

  const adUnitTypeProps = useMemo<IAdUnitTypeSelectorProps>(
    () => ({
      nativeProperties: activeAdunit?.nativeProperties as NativeAdProperties,
      adTypes: activeAdunit?.adTypes as AdTypes,
      onChange: onAdunitTypeChange,
      shouldRenderComponent: !isScriptViewActive,
      isLoading: isAdUnitLoading || appStore.loading || !isAdUnitInitialized,
      intl,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeAdunit?.adTypes, activeAdunit?.nativeProperties, intl, isScriptViewActive, isAdUnitLoading, appStore.loading]
  );

  const adUnitMediationProps = useMemo<IAdUnitMediationSelectorProps>(
    () => ({
      activeAdunit,
      toggleIsLoading: isAdUnitMediationLoading,
      shouldRenderComponent: !!googleAdsenseStore?.active?.isMediationEnabled && !isScriptViewActive,
      onAdunitMediationChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeAdunit, googleAdsenseStore?.active?.isMediationEnabled, isScriptViewActive, isAdUnitMediationLoading]
  );

  const adUnitScriptPageProps = useMemo<IScriptPageProps>(
    () => ({
      adunitId: `${activeAdunit?.id}`,
      siteId: activeAdunit?.propertyId,
      shouldRenderComponent: isScriptViewActive,
      intl,
      mode,
      ...routeProps,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isScriptViewActive, appStore.publisher?.id, activeAdunit?.id, routeProps, intl, mode, isScriptViewActive]
  );

  const adUnitDetailPageProps = useMemo<TythonAdUnitDetailPageProps>(
    () => ({
      saving: isAdUnitSaving,
      loading: isAdUnitLoading,
      onSubmit: isScriptViewActive ? onRedirectBackToAdUnitManagePage : onAdUnitSubmit,
      onCancel: isScriptViewActive ? undefined : onRedirectBackToAdUnitManagePage,
      submitButtonDisabled: !isValidAdUnit || appStore.loading,
      submitLabel: formatMessage(isScriptViewActive ? messages.finishAdUnitCreation : messages.saveAndGetCode),
      titleLabel: formatMessage(isEditing ? messages.editPageTitle : messages.createPageTitle),
      hasFormTitle: true,
      hideNavButton: isScriptViewActive,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdUnitSaving, isAdUnitLoading, isScriptViewActive, isValidAdUnit, isEditing, onAdUnitSubmit, onRedirectBackToAdUnitManagePage]
  );

  return {
    adUnitNameProps,
    adUnitSiteProps,
    adUnitTypeProps,
    adUnitMediationProps,
    adUnitDetailPageProps,
    adUnitScriptPageProps,
    isScriptViewActive,
  };
};

export default useCreateTythonAdunitRenderControl;
