import { IPubcenterPalette, IPubcenterSpacing } from '../../../../theme';
import { IGlobalSearchResultListStyleProps, IGlobalSearchResultListStyles } from './GlobalSearchResultList.types';

export const getStyles = (props: IGlobalSearchResultListStyleProps): IGlobalSearchResultListStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const pallete = theme.palette as IPubcenterPalette;
  return {
    root: {
      backgroundColor: theme.palette.white,
      paddingTop: spacing.size32
    },
    header: [
      'ms-fontWeight-semibold',
      {
        fontSize: '14px',
        paddingBottom: spacing.size16,
        margin: 0
      }
    ],
    button: [
      'ms-showmore-button',
      {
        color: pallete.themeDark,
        paddingTop: spacing.size16,
        selectors: {
          ':hover': {
            color: pallete.blueDark
          },
          ':disabled': {
            color: pallete.neutralQuaternary
          }
        }
      }
    ],
    buttonIcon: {
      paddingLeft: spacing.size8
    },
    iconHeader: {
      padding: 0,
      fontSize: spacing.size16
    },
    iconCell: {
      textAlign: 'center',
      selectors: {
        '&:before': {
          content: '.',
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '100%',
          width: '0px',
          visibility: 'hidden'
        }
      }
    },
    iconImg: {
      verticalAlign: 'middle',
      maxHeight: spacing.size16,
      maxWidth: spacing.size16
    },
  };
};
