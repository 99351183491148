import { AdManagementRouter } from './pages/ad-management/AdManagementRouter';
import { PartnerManagementRouter } from './pages/partner-management/PartnerManagementRouter';
import { ReportRouter } from './pages/report/ReportRouter';
import { IRoute } from './Routes.types';

export enum RoutePrefix {
  report = 'report',
  adManagement = 'ad-management',
  partnerManagement = 'partner-management',
  capacity = 'capacity',
}

export const getGlobalRoutes = (): IRoute[] => [
  {
    key: `/${RoutePrefix.report}`,
    text: 'Reporting',
    icon: 'CRMReport',
    component: ReportRouter,
  },
  {
    key: `/${RoutePrefix.adManagement}`,
    text: 'Ad Management',
    icon: 'DocumentManagement',
    // @ts-ignore
    component: AdManagementRouter,
  },
  {
    key: `/${RoutePrefix.partnerManagement}`,
    text: 'Partner Management',
    icon: 'AccountManagement',
    component: PartnerManagementRouter,
  },
  // {
  //   key: `/${RoutePrefix.capacity}`,
  //   text: 'Capacity',
  //   icon: 'OfflineStorageSolid',
  //   component: CapacityRouter
  // }
];

export const DefaultRoute = `/${RoutePrefix.adManagement}`;
export const SignUpPageRoute = '/signup';
export const LandingPageRoute = '/landing-page';
export const LogInPageRoute = '/login';
export const AccountPageRoute = '/publisherProfile';
export const AcceptInvitePageRoute = '/acceptUserInvite/code=';
export const UserDoesNotExistErrorPageRoute = '/user-does-not-exist';
export const NoAccessErrorPageRoute = '/no-access';
export const UnauthorizedInternalUserPageRoute = '/unauthorized-user';
export const HomePageRoute = '/ad-management/home';
export const AuditHistoryRoute = '/auditHistory';
export const UserLevelSettingRoute = '/userSettings';
export const SupportPageRoute = '/support';
