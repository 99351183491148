import { Icon, IStyle, mergeStyles } from '@fluentui/react';
import * as React from 'react';
import { isPublisherRejectionUnappealable } from '../../@data';
import { INavLinkProps } from './Nav.types';

/**
 * Represents a composed link in the Nav component.
 */
export const NavLink: React.FunctionComponent<INavLinkProps> = (props: INavLinkProps) => {
  if (!props) {
    return null;
  }

  const {
    rightIconName,
    leftIconName,
    id,
    href,
    target,
    disabled,
    onClick,
    dataHint,
    dataValue,
    ariaLabel,
    ariaExpanded,
    ariaCurrent,
    title,
    role,
    rootClassName,
    barClassName,
    content,
    iconClassName,
    textClassName,
    focusedStyle,
    disabledStyle,
  } = props;

  const computedTextWidth: IStyle = {
    // 100px to accommodate left and right icons (48px each)
    width: 'calc(100% - 96px)',
  };

  if (!rightIconName && !leftIconName) {
    // no icons, take full with to text
    computedTextWidth.width = '100%';
  } else if (!leftIconName || !rightIconName) {
    // 48px to the left or right icon
    computedTextWidth.width = 'calc(100% - 48px)';
  }

  const fixedIconWidth: IStyle = {
    width: '48px',
    display: rightIconName === 'OpenInNewWindow' ? 'none' : 'inline-block',
  };

  return (
    <>
      <a
        id={id}
        href={href}
        target={target}
        onClick={onClick}
        data-hint={dataHint}
        data-value={dataValue}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-current={ariaCurrent}
        role={role}
        className={disabled ? disabledStyle : focusedStyle}
        title={title}
        style={{ pointerEvents: isPublisherRejectionUnappealable() ? 'none' : undefined }}
      >
        <div className={rootClassName} aria-hidden="true">
          <hr className={barClassName} />
          {leftIconName ? <Icon iconName={leftIconName} className={iconClassName} /> : null}
          {content ? <div className={mergeStyles(textClassName, computedTextWidth)}>{content}</div> : null}
          {rightIconName ? <Icon iconName={rightIconName} className={mergeStyles(iconClassName, fixedIconWidth)} /> : null}
        </div>
      </a>
    </>
  );
};
