import { defineMessages } from 'react-intl';

export const accountManageMessages = defineMessages({
  profileTab: {
    id: 'accountManageMessages.profileTab',
    defaultMessage: 'Profile Information',
  },
  paymentsTab: {
    id: 'accountManageMessages.paymentsTab',
    defaultMessage: 'Payments',
  },
  settingsTab: {
    id: 'accountManageMessages.settingsTab',
    defaultMessage: 'Account settings',
  },
  userManagementTab: {
    id: 'accountManageMessages.userManagementTab',
    defaultMessage: 'User Management',
  },
});
