import { classNamesFunction, getTheme } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../theme';
import { IAppPageStyleProps, IAppPageStyles } from './App.types';

export const getStyles = (props: IAppPageStyleProps): IAppPageStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return {
    skipButton: {
      left: '50%',
      padding: spacing.size16,
      zIndex: '1',
      position: 'absolute',
      transform: 'translateY(-100%)',
      transition: 'transform 0.3s',
      backgroundColor: palette.neutralLight,
      selectors: {
        '&:focus, &:hover': {
          transform: 'translateY(0%)',
        },
      },
    },
  };
};

const classNames = classNamesFunction<IAppPageStyleProps, IAppPageStyles>();

export function getClassNames<PropType>(props?: PropType) {
  return classNames(getStyles, { theme: getTheme(), ...props });
}
