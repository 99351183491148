import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import { onInitializeGoogleAccount } from '../../../Account/Settings/components/GoogleConnector/@data/actions';
import { onInitializeRecommendationSection } from '../actions/actions';

orchestrator(onInitializeRecommendationSection, async () => {
  onInitializeGoogleAccount(false);
  await when(() => {
    const { active: googleAdSense } = getGoogleAdSenseStore();
    return !!googleAdSense.connectionStatus;
  });
});
