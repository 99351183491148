import 'dotenv/config';
import { Log } from '../../../logging/src';
import { uiConfig } from '../../config/ui.config';
import { handleError } from '../../pages/ad-management/AdQuality/@data/services/utility/utility';
import '../../pages/ad-management/Profile/@data/orchestrators';
import { generateGuid } from '../../utils/GuidUtils';
import { Publisher, User } from '../store';
import { PublisherType } from '../store/schema/enums/PublisherType';
import { OvdfAPIRequest } from '../store/schema/models/OvdfAPIRequest';
import { VerifyCodeStatusRequest } from '../store/schema/models/VerifyCodeStatusRequest';
import { VerifyCodeStatusResponse } from '../store/schema/models/VerifyCodeStatusResponse';
import { getAppStore } from '../store/store';
import { buildRequestHeadersWithAuthToken, handleApiErrors } from '../utils';
import { ApiError } from './ApiService';
import { getIdToken } from './PubCenterAADAuthService';

export const CUSTOM_MESSAGE_CODE = '715-123325';

export async function fetchOneVetReviewStatus(eventType: 'login' | 'signup', publisher?: Publisher, user?: User): Promise<string> {
  if (process.env.REACT_APP_ENV !== 'production') {
    return 'pass';
  }

  const { userContext } = getAppStore();
  const sessionId = sessionStorage.getItem('session_id');
  const idToken = await getIdToken();

  if (userContext && sessionId && idToken) {
    const url = `${uiConfig.getApiBaseAddress()}/${encodeURIComponent('ovdf')}`;
    const headers = await buildRequestHeadersWithAuthToken(url, userContext);

    const request: OvdfAPIRequest = {
      sessionId: sessionId || '',
      eventType: eventType,
      publisherId: publisher?.id || 0,
      publisherName: publisher?.name?.toString() || '',
      publisherType: publisher?.publisherType || PublisherType.individual,
      idToken: idToken,
      user: user,
      organization: {
        address: publisher?.address,
        extendedProperties: publisher?.extendedProperties,
      },
      ipAddress: (await getIP()) || '',
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(request),
      });

      const responseJson = await response.json();
      const reviewStatus = responseJson.data.reviewStatus.toString();

      // Blocking user signup and redirecting user to no-access page. But existing users will be allowed to login
      if (reviewStatus === 'reject' && eventType === 'signup') {
        Log.write(`TMX review failed for this user ${userContext.email}. Response: ${JSON.stringify(responseJson)}`);

        const noAccessPageRoute = encodeURI(window.location.protocol + '//' + window.location.host + '/no-access');
        window.location.replace(noAccessPageRoute);
      }

      Log.debug(`OneVet review for user: <${userContext.email}>, trying to <${eventType}> resulted as: <${reviewStatus}>`);
      Log.debug('OVDF response: ' + JSON.stringify(responseJson));

      sessionStorage.setItem('review_status', reviewStatus);
      return reviewStatus;

      // TODO: handle unknown_session scenario
    } catch (err) {
      const error = `Session query API failed for ${url}. Errors: ${JSON.stringify(err)}`;
      sessionStorage.setItem('review_status', 'API failed');

      Log.error(error);
      return 'pass';
    }
  } else {
    Log.error('Could not fetch OVDF ReviewStatus: userContext/idToken is null or session id is missing');
    return 'pass';
  }
}

export async function fetchOneVetCodeVerificationStatus(
  publisherId: string,
  requestBody: VerifyCodeStatusRequest
): Promise<{ data: VerifyCodeStatusResponse }> {
  const url = `${uiConfig.getApiBaseAddress()}/entityEvaluation/publishers/${publisherId}/tokenVerification/checkIfVerified`;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      return handleApiErrors<{ data: VerifyCodeStatusResponse }>(response, url);
    }

    const responseJson = await response.json();
    if (responseJson) {
      return responseJson;
    }
    return Promise.resolve({} as { data: VerifyCodeStatusResponse });
  } catch (err) {
    if (err.name === 'AbortError') {
      return Promise.resolve({} as { data: VerifyCodeStatusResponse });
    }
    const error = `getReportData operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export function addThreatMetrixScripts() {
  const tmScriptId = 'tmScript';
  const tmNoScriptId = 'tmNoScript';
  const tmScript = document.getElementById(tmScriptId);
  const tmNoScript = document.getElementById(tmNoScriptId);

  const currentTimestamp = new Date().getTime();
  const sessionId = sessionStorage.getItem('session_id') || generateGuid() + currentTimestamp;
  const orgId = process.env.REACT_APP_ENV === 'production' ? 'y6jn8c31' : 'w0rnzpxd';
  const srcUrl = 'https://vlscppe.microsoft.com/fp/tags.js?org_id=' + orgId + '&session_id=' + sessionId;

  if (!tmScript) {
    const script = document.createElement('script');

    script.id = tmScriptId;
    script.src = srcUrl;

    sessionStorage.setItem('session_id', sessionId);
    document.head.appendChild(script);
  }

  if (!tmNoScript) {
    const noScript = document.createElement('noscript');
    const iframe = document.createElement('iframe');

    iframe.setAttribute('aria-hidden', 'true');
    iframe.setAttribute('style', 'width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;');
    iframe.src = srcUrl;

    noScript.id = tmNoScriptId;
    noScript.appendChild(iframe);
    document.body.appendChild(noScript);
  }
}

export async function getIP() {
  const ipResponse = await fetch('https://api.ipify.org?format=json')
    .then((response) => response.json())
    .catch(() => console.log('IP fetch failed'));

  return ipResponse?.ip;
}
