import { TokenType, UserType } from '../enums';

export class UserContext {
  public className = 'userContext';
  public firstName?: string;
  public lastName?: string;
  public name?: string;
  public userName?: string;
  public email?: string;
  public token?: string;
  public tokenType?: TokenType;
  public userType?: UserType;
  public userRoles?: number[];
  public error?: string;
  public id: number;

  constructor(userName?: string, email?: string, tokenType?: TokenType) {
    this.name = userName;
    this.email = email;
    this.tokenType = tokenType || TokenType.PubCenterAADToken;
  }
}
