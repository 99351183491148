import { object, string } from 'yup';
import { IPublisherViewModel } from './IPublisherViewModel';

export function publisherIPAddressViewModel() {
  return object<IPublisherViewModel>().shape({
    ipAddressName: string().trim().required(),
    minIPAddress: string().trim().required(),
    maxIPAddress: string().trim().required(),
  });
}
