import { hasUpdateAccess } from '../../../../../../../@data/services/UserRoleService';
import { FormState } from '../../../../../../../components/Form';

export function IsDisabled(mode: FormState, permission: number): boolean {
  if (mode === FormState.Create || mode === FormState.InPageCreate) {
    return false;
  }

  return !hasUpdateAccess(permission);
}
