import { action } from 'satcheljs';
import { IStringDictionary, Model, Publisher } from '../../../../../@data';
import { IPublisherViewModel } from '../store';

export const onRawChanged = action('onRawChanged', (value?: Publisher) => ({ value }));

export const onPublisherChanged = action('onPublisherChanged', (publisher: IPublisherViewModel) => ({ publisher }));

export const onPublisherErrorChanged = action('onPublisherErrorChanged', (values?: IStringDictionary) => ({ values }));

export const onFetchPublishersLoading = action('onFetchPublishersLoading', (isLoading: boolean) => ({ isLoading }));

export const onFetchingPublishersData = action('onFetchingPublishersData', (isFetchingData: boolean) => ({ isFetchingData }));

export const onPublishersFetched = action('onPublishersFetched', (items: Publisher[] | Model[]) => ({ items }));
