import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { getAppStore, listEntity, onNotificationChanged, User } from '../../../../../@data';
import { onFetchingUsersData, onFetchUsersLoading, onUsersChanged } from '../actions';
import { onManagePageInitialized } from '../actions/onManagePageInitialized';
import { getStore } from '../store/store';

orchestrator(onManagePageInitialized, async (msg) => {
  try {
    const { publisher, userContext } = getAppStore();
    const users = getStore().users;

    if (users === undefined || users.length === 0) {
      onFetchUsersLoading(true);
    }
    onFetchingUsersData(true);

    if (userContext && publisher) {
      const fetchedUsers = await listEntity([publisher], userContext, User);
      onUsersChanged(fetchedUsers);
    }
  } catch (error) {
    Log.error(error);
    onNotificationChanged({ messageBarType: MessageBarType.error, text: 'Failed to load users' });
  } finally {
    onFetchUsersLoading(false);
    onFetchingUsersData(false);
  }
});
