import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import { Channel, PopupType, getAccessPermission, getAppStore } from '../../../../../@data';
import { hasDeleteAccess, hasUpdateAccess } from '../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../App.messages';
import { IGridV2Column } from '../../../../../layout/GridPageV2';
import { setDialogType } from '../../../../../layout/GridPageV2/@data/mutatorActions';
import messages from '../ManagePage.messages';

export function getGridColumns(formatMessage, isWindowRegularSize, routeHistory): IGridV2Column[] {
  const appStore = getAppStore();

  const columns: IGridV2Column[] = [
    {
      key: 'name',
      fieldName: 'name',
      name: formatMessage(messages.channelName),
      minWidth: 100,
      maxWidth: 125,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
      onRender: (item: Channel) => item.name,
    },
    {
      key: 'id',
      fieldName: 'id',
      name: formatMessage(messages.channelId),
      minWidth: 100,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      onRender: (item: Channel) => item.id,
    },
    {
      key: 'status',
      name: formatMessage(AppMessages.status),
      minWidth: 100,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      onRender: (item: Channel) => item.status,
    },
    {
      key: 'associatedAdUnitCount',
      fieldName: 'associatedAdUnitCount',
      name: formatMessage(messages.adunitsNumber),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
      onRender: (item: Channel) => item.associatedAdUnitCount,
    },
    {
      key: 'associatedAdUnitNames',
      fieldName: 'associatedAdUnitNames',
      name: formatMessage(messages.adunitNames),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: false,
      isExportable: true,
      onRender: (item: Channel) => item.associatedAdUnitIds?.length,
    },
    {
      key: 'associatedFilterCount',
      fieldName: 'associatedFilterCount',
      name: formatMessage(messages.adfiltersNumber),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
      onRender: (item: Channel) => item.associatedFilterCount,
    },
    {
      key: 'associatedFilterNames',
      fieldName: 'associatedFilterNames',
      name: formatMessage(messages.adfilterNames),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: false,
      isExportable: true,
      onRender: (item: Channel) => item.associatedFilterIds?.length,
    },
    {
      key: 'actions',
      name: formatMessage(messages.actions),
      minWidth: 150,
      maxWidth: 150,
      isPadded: true,
      isSearchable: true,
      isVisible: true,
      suppressSorting: true,
      onRender: (item: Channel) => {
        const accessPermission = getAccessPermission(item);

        return (
          <div>
            <CommandBarButton
              id={`adunitCallout-${item.id}`}
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'edit' }}
              ariaLabel={formatMessage(AppMessages.edit)}
              text={isWindowRegularSize ? formatMessage(AppMessages.edit) : ''}
              disabled={!hasUpdateAccess(accessPermission) || appStore?.publisher === undefined || appStore?.account === undefined}
              onClick={() =>
                routeHistory.push(`/ad-management/${appStore?.publisher?.id}-${appStore?.account?.id}/channel/edit/${item.id}`)
              }
            />
            <CommandBarButton
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'Delete' }}
              text={isWindowRegularSize ? formatMessage(AppMessages.delete) : ''}
              onClick={() => setDialogType(PopupType.DeleteConfirmation)}
              disabled={!hasDeleteAccess(accessPermission)}
            />
          </div>
        );
      },
    },
  ];

  return columns;
}
