import { mutator } from 'satcheljs';
import { onFeatureFlagChanged, onLoadingChanged } from '../actions/windowsFeatureFlagActions';
import { getStore } from '../store/store';

mutator(onLoadingChanged, (msg) => {
  const store = getStore();
  store.isLoading = msg.isLoading;
});

mutator(onFeatureFlagChanged, (msg) => {
  const store = getStore();
  store.isFeatureEnabled = msg.isFeatureEnabled;
});
