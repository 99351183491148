import { mixed, number, object, string } from 'yup';
import { AdUnitType, IdNamePairErrorModel } from '../../../../../../@data/store/schema';
import { PartnerCodesValidationSchema } from '../../../../../../@data/store/schema/viewmodels/PartnerCodesValidationModel';
import { isTythonSelfServeUser } from '../../../../../../@data/utils/checkUserType';
import { ArrayObservableSchemaYupType, StringListSchemaYupType } from '../../../../../../utils/validation';
import { IAdunitViewModel } from './IAdunitViewModel';

export function adunitErrorModel() {
  return object<IAdunitViewModel>().shape({
    name: string().trim().required(),
    channelsSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel),
    storeIds: new StringListSchemaYupType().of(number().integer()),
    distributionPartnerCodes: PartnerCodesValidationSchema,
    propertySelected: isTythonSelfServeUser() ? string().required() : string(),
    adUnitType:  isTythonSelfServeUser() ? mixed<AdUnitType>().required() : string()
  });
}
