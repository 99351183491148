import { mutatorAction } from 'satcheljs';
import { getAppStore } from '../store/store';

export const onUserFirstTime = mutatorAction('ON_USER_FIRST_TIME_CHANGED', function userFirstTimeChanged(value: boolean) {
  const store = getAppStore();
  store.isFirstTimeUser = value;
});

export const onUserUserSuspended = mutatorAction('onUserUserSuspended', (value: boolean) => {
  const store = getAppStore();
  store.isUserSuspended = value;
});
