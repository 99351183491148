import { IPubcenterTheme } from '../../../../theme';
import { flexWithAlignCenter } from '../../../utils/GlobalStyleUtils';
import { IFormTitleStyleProps, IFormTitleStyles } from './FormTitle.types';

export const getStyles = (props: IFormTitleStyleProps): IFormTitleStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { fonts } = theme;

  return {
    root: [
      {
        ...flexWithAlignCenter,
      },
    ],
    title: [
      'ms-fontWeight-semibold',
      {
        ...fonts.size20,
      },
    ],
  };
};
