import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../../../logging/src';
import { deleteEntity, getAppStore, onLoadingChanged } from '../../../../../../../../@data';
import { showErrorToastNotification, showSuccessToastNotification } from '../../../../../../../../@data/mutatorActions';
import { AdSenseOAuthStatus } from '../../../../../../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { AdSenseMediationOptOut } from '../../../../../../../../@data/store/schema/models/AdSenseMediation';
import { googleConnectorMessages } from '../../GoogleConnector.messages';
import { onDisconnectGoogleAccount } from '../actions';
import { onChangeConnection } from '../mutatorActions';

orchestrator(onDisconnectGoogleAccount, async () => {
  const { publisher, account } = getAppStore();

  onLoadingChanged(true);

  try {
    if (publisher && account) {
      const urlParams = new URLSearchParams();
      urlParams.append('routeSuffix', 'api/mediation/admanager/optOut');
      const response = (await deleteEntity([], AdSenseMediationOptOut, undefined, urlParams, undefined)) as AdSenseMediationOptOut;

      if (response && response?.mediationStatus === false) {
        onChangeConnection({
          connectionStatus: AdSenseOAuthStatus.None,
        });

        showSuccessToastNotification({ textMessageDescriptor: googleConnectorMessages.disconnectingGoogleAccountSuccess });
      } else {
        showErrorToastNotification({ textMessageDescriptor: googleConnectorMessages.disconnectingGoogleAccountFailed });
      }
    }
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: googleConnectorMessages.disconnectGoogleAccountFailed });
    Log.error(`Error while initializing google account ${error}`);
  } finally {
    onLoadingChanged(false);
  }
});
