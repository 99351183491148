import { Model } from './Model';

export class UpdateClarityProject extends Model {
  public apiPath = undefined;
  public className = 'updateClarityProject';
  public propertyId?: string;
  // NOTE: this should be reduced to one attribute for both cases
  // For non Tython users
  public clarityProjectId?: string;
  // For Tython users
  public clarityProjectIdForTython?: string;
  public enableMicrosoftClarity?: boolean;
}
