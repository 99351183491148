import { getAppStore, getCurrentPublisher, getEntity, User } from '../../../../../@data';

export async function fetchUserDetails(user: User): Promise<User> {
  const { userContext } = getAppStore();
  const publisher = getCurrentPublisher();
  if (!publisher) {
    throw new Error('No publisher found');
  }

  const userDetails = await getEntity<User>([publisher, user], userContext);
  return userDetails;
}
