import { action } from 'satcheljs';
import { ChartGrain } from '../../Payments/EstimatedEarnings/EstimatedEarnings.types';

export const onInitializePaymentsPage = action(
  'onInitializePaymentsPage',
  (shouldRenderOnboardingPopup: boolean, locale: string, countryCode: string, onResolvedCallback?: (success: boolean) => void) => ({
    shouldRenderOnboardingPopup,
    locale,
    countryCode,
    onResolvedCallback,
  })
);
export const fetchPaymentsPageBalance = action('fetchPaymentsPageBalance');
export const fetchPaymentsPageEstimatedEarnings = action('fetchPaymentsPageEstimatedEarnings', (grain: ChartGrain) => ({
  grain,
}));
export const fetchAccountStatus = action('fetchAccountStatus');
