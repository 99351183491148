import { mutatorAction } from 'satcheljs';
import { IColumnVisibilityMapping } from '../../GridV2.types';
import { getGridColumns } from '../selectors/gridSelectors';
import { getStore } from '../store/store';

export const onGridColumnsModified = mutatorAction('onGridColumnsModified', (columnVisibilityMappings: IColumnVisibilityMapping[]) => {
  const { entity } = getStore();
  const gridColumns = getGridColumns();

  if (entity) {
    columnVisibilityMappings.forEach((colVisMap) => {
      const gridColumn = gridColumns.find((col) => col.key === colVisMap.columnKey);

      if (gridColumn) {
        gridColumn.isVisible = colVisMap.isVisible;
      }
    });
  }
});
