import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { Main } from '../../components/Main/Main';
import { getClassNames } from './EmptyLayout.styles';
import { IEmptyLayoutProps } from './EmptyLayout.types';

export function EmptyLayout({ headingTitle, descriptionText, children, styleProps }: IEmptyLayoutProps): JSX.Element {
  const { root, heading, description } = mergeStyleSets(getClassNames(), styleProps);

  return (
    <Main className={root}>
      {headingTitle && <h1 className={heading}>{headingTitle}</h1>}
      {descriptionText && <div className={description}>{descriptionText}</div>}
      {children}
    </Main>
  );
}
