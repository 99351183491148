import { DefaultButton, Image, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import AppMessages from '../../../../../App.messages';
import messages from './messages';
import { getFRECardStyles } from './styles';

export interface ICardProps {
  title: string;
  description: string;
  imageSrc?: string;
  imageAlt?: string;
}

export interface IFRECardProps extends ICardProps {
  isLastCard?: boolean;
  selectedCardIndex?: number;
  totalCardCount: number;
  dismissDialog: () => void;
  nextButtonClicked: () => void;
}

export const FRECard = injectIntl(
  ({
    title,
    description,
    imageAlt,
    imageSrc,
    isLastCard,
    selectedCardIndex = 0,
    totalCardCount,
    dismissDialog,
    nextButtonClicked,
    intl,
  }: IFRECardProps & InjectedIntlProps) => {
    const { formatMessage } = intl;
    const { dot, selectedDot, content, title: titleStyle, description: descStyle } = getFRECardStyles();

    return (
      <>
        {imageSrc && <Image width={600} height={324} src={imageSrc} alt={imageAlt} loading="lazy" />}

        <div className={content}>
          <div className={titleStyle} role="heading" aria-level={2}>
            {title}
          </div>
          <div className={descStyle}>{description}</div>

          <Stack horizontal horizontalAlign={'space-between'}>
            {!isLastCard && <DefaultButton text={formatMessage(messages.notNowButton)} onClick={dismissDialog} shape={'rounded'} />}

            <Stack horizontal tokens={{ childrenGap: '7px', maxHeight: '32px' }}>
              {Array.from(Array(totalCardCount).keys()).map((_, index) => (
                <span key={index} className={index === selectedCardIndex ? selectedDot : dot} />
              ))}
            </Stack>

            {!isLastCard ? (
              <PrimaryButton text={formatMessage(AppMessages.next)} onClick={nextButtonClicked} shape={'rounded'} />
            ) : (
              <PrimaryButton text={formatMessage(AppMessages.finish)} onClick={dismissDialog} shape={'rounded'} />
            )}
          </Stack>
        </div>
      </>
    );
  }
);
