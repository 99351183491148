import { IPubcenterSpacing } from '../../../theme';
import { IDataGridStyleProps, IDataGridStyles } from './DataGrid.types';

export const getStyles = (props: IDataGridStyleProps): IDataGridStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      flex: 1,
      position: 'relative',
    },
    filter: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: spacing.size8,
    },
    filterItem: {
      marginLeft: spacing.size8,
    },
    searchResults: {
      position: 'absolute',
      top: spacing.size10,
      right: spacing.size36,
    },
    subComponentStyles: {
      scrollablePane: {
        root: {
          flex: 1,
          overflowY: 'auto',
          position: 'relative',
        },
      },
      commandBar: {
        root: {
          marginBottom: spacing.size8,
        },
      },
    },
    notReadyLabel: {
      color: theme.palette.neutralSecondary,
      marginLeft: spacing.size48,
      position: 'relative',
      marginTop: spacing.size12,
      fontSize: theme.fonts.medium.fontSize,
    },
  };
};
