import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../theme';

export const getStyles = () => {
  const theme = getTheme();
  const { palette } = theme;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    showDetails: {
      color: palette.themePrimary,
      cursor: 'pointer',
    },
    showDetailsIcon: {
      paddingLeft: spacing.size6,
    },
    actionDetailsList: {
      listStyleType: 'none',
      paddingLeft: '0px',
    },
    actionDetailsListItem: {
      color: palette.neutralSecondary,
    },
  });
};
