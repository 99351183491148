import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAppStore } from '../../../../../../@data';

import { DetailPage } from '../../../../../../layout/DetailPage/DetailPage';
import { injectIntlWithObserver } from '../../../../../../utils/ReactUtils';
import { getCreatePropertyStore } from '../../__data__/store/store';
import PropertyIdField from '../../components/PropertyIdField';
import PropertyNameInputField from '../../components/PropertyNameInputField';
import PropertyUrlInputField from '../../components/PropertyUrlInputField';
import useDetailsPageButtonsStatus from '../../utils/useDetailsPageButtonsStatus';
import { onFormInitialized, onFormSubmitted } from './__data__/orchestrators';
import ClaritySection from './components/ClarityFormSection';
import PropertyApprovalStateField from './components/PropertyApprovalStateField';
import PropertyApprovalStatusInputField from './components/PropertyApprovalStatusInputField';
import PropertyAttachedAdUnitsField from './components/PropertyAttachedAdUnitsField';
import { ICreatePropertyProps, IFormInitializationState } from './types';

export const CreateProperty = injectIntlWithObserver<ICreatePropertyProps & IFormInitializationState>((props) => {
  const {
    match,
    mode,
    intl: { formatMessage },
    history,
    onSubmit,
    onCancel,
    isInPanel,
    saving: savingProp,
  } = props;
  const { saving, loading } = getAppStore();
  const { active } = getCreatePropertyStore();
  const { publisherId, accountId, id: entityId } = match.params;

  const [activePropertyId, setActivePropertyId] = useState(entityId);
  const { titleLabel, submitLabel, onHandleCancel, isSubmitButtonDisabled } = useDetailsPageButtonsStatus(
    history,
    mode,
    onSubmit,
    onCancel,
    formatMessage
  );

  useEffect(() => {
    if (activePropertyId !== entityId) {
      setActivePropertyId(entityId);
      if (publisherId) {
        onFormInitialized(mode, publisherId, accountId, entityId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  useEffect(() => {
    if (publisherId) {
      onFormInitialized(mode, publisherId, accountId, entityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DetailPage
      titleLabel={titleLabel}
      helpId={'pop_PCv4_PropertiesCreate'}
      submitLabel={submitLabel}
      onCancel={() => onHandleCancel(publisherId, accountId)}
      onSubmit={() => onFormSubmitted(mode, history, onSubmit)}
      onNavClick={() => onHandleCancel(publisherId, accountId)}
      saving={saving || savingProp}
      loading={loading}
      pageMode={mode}
      hasFormTitle
      submitButtonDisabled={isSubmitButtonDisabled(active)}
      additionalHeightBoundary={isInPanel}
    >
      <PropertyNameInputField mode={mode} />
      <PropertyIdField id={active.id} />
      <PropertyUrlInputField mode={mode} />
      <PropertyApprovalStateField mode={mode} />
      <ClaritySection mode={mode} clarityConfigs={active.clarity} />
      <PropertyAttachedAdUnitsField {...props} mode={mode} />
      <PropertyApprovalStatusInputField mode={mode} />
    </DetailPage>
  );
});
