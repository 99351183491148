import { getTheme, mergeStyleSets } from '@fluentui/react';
import { AccessibilityContrast, IPubcenterPalette, IPubcenterSpacing } from '../../../../../../theme';
import { ICommonStyleProps } from '../../../../../types';

type StyleProperties = 'containerStyle' | 'contentStyle' | 'headerStyle' | 'dateStyle' | 'descriptionStyle' | 'buttonStyle';
type SetupCardStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SetupCardStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { Activated } = AccessibilityContrast;

  return mergeStyleSets({
    containerStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
      boxShadow: `0px 0px ${spacing.size7} 0px rgba(0,0,0,0.2)`,
      transition: '0.3s',
      borderRadius: spacing.size4,
      flex: 1,
      [Activated]: {
        color: 'windowtext',
        borderColor: 'window',
        forcedColorAdjust: 'none',
      },
    },
    contentStyle: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: spacing.size16,
      paddingRight: spacing.size10,
    },
    headerStyle: { margin: `${spacing.size10} 0px`, fontSize: spacing.size18, fontWeight: '600' },
    dateStyle: { color: palette.darkSilver, fontSize: spacing.size12, marginBottom: spacing.size6 },
    descriptionStyle: {
      marginTop: '0px',
    },
    buttonStyle: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingLeft: spacing.size16,
      paddingBottom: spacing.size16,
      marginTop: 'auto',
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
