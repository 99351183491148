import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  Channel,
  Filter,
  FilterScope,
  getAppStore,
  listEntity,
  onLoadingChanged,
  onNotificationChanged,
  Publisher,
} from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { setPublisherAndAccountContext } from '../../../@data/services/AdManagementService';
import { onFilterChanged, onFilterErrorChanged, onFormInitialized, onRawChanged } from '../actions';
import { fetchFilterById } from '../services/FilterService';
import { IFilterViewModel } from '../store/schema';

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();

  const viewModel: IFilterViewModel = {
    channels: [],
    filterType: FilterScope.Account,
  };

  const publisherId = parseInt(msg.publisherId, 10);
  const accountId = parseInt(msg.accountId, 10);

  if (!isNaN(publisherId) && !isNaN(accountId)) {
    const publisher = new Publisher(publisherId);
    const account = new Account(accountId);

    try {
      setPublisherAndAccountContext(publisher, account);

      onFilterErrorChanged();
      onLoadingChanged(true);

      const response = await listEntity([publisher, account], userContext, Channel);
      viewModel.channels = response ? response.map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];

      const localeDate = new Date();
      const UTCDate = new Date(
        localeDate.getUTCFullYear(),
        localeDate.getUTCMonth(),
        localeDate.getUTCDate(),
        localeDate.getUTCHours(),
        localeDate.getUTCMinutes(),
        0
      );
      // We decided to keep filter start time as current datetime by default.
      viewModel.filterStartTime = UTCDate;
      viewModel.filterItemsEntryType = 'manualEntry';

      if (msg.mode === FormState.Edit && msg.entityId) {
        const raw = await fetchFilterById(publisher, msg.entityId, userContext);

        viewModel.id = raw.id;
        viewModel.name = String(raw.name);
        viewModel.appendOnly = raw.appendOnly;
        viewModel.filterItemsEntryType = raw.appendOnly ? 'fileUpload' : 'manualEntry';

        if (!viewModel.appendOnly) {
          viewModel.excludedUrlsCount = raw.blockedUrls!.length;
          viewModel.excludedAdkeywordsCount = raw.blockedAdKeywords!.length;
          viewModel.excludedQueryKeywordsCount = raw.blockedQueryKeywords!.length;
        } else {
          const filterResponse = await listEntity([publisher, account], userContext, Filter);
          const matchedFilter = filterResponse.filter((f) => f.id === raw.id)[0];

          viewModel.excludedUrlsCount = matchedFilter.blockedUrlCount;
          viewModel.excludedAdkeywordsCount = matchedFilter.blockedAdKeywordCount;
          viewModel.excludedQueryKeywordsCount = matchedFilter.blockedQueryKeywordCount;
        }

        // Set filter Type to Standard as default.
        viewModel.filterType = raw.filterScope || FilterScope.Account;

        if (raw.blockedUrls) {
          viewModel.excludedUrls = raw.blockedUrls.join('\n');
        }

        if (raw.blockedAdKeywords) {
          viewModel.excludedAdKeywords = raw.blockedAdKeywords.join('\n');
        }

        if (raw.blockedQueryKeywords) {
          viewModel.excludedQueryKeywords = raw.blockedQueryKeywords.join('\n');
        }

        // Currently API accepts and returns start/end time in epoch. Converting epoch to Date type.
        const timeZoneOffset = new Date().getTimezoneOffset();
        viewModel.filterStartTime = raw.startTimeStamp ? new Date((raw.startTimeStamp + timeZoneOffset * 60) * 1000) : undefined;
        viewModel.filterEndTime = raw.endTimeStamp ? new Date((raw.endTimeStamp + timeZoneOffset * 60) * 1000) : undefined;

        viewModel.channelsSelected =
          raw.associatedChannelIds && raw.associatedChannelIds.length > 0
            ? viewModel.channels.filter((c) => raw.associatedChannelIds!.includes(c.id))
            : [];

        onRawChanged(raw);
      } else {
        onRawChanged();
      }
      onFilterChanged(viewModel);
    } catch (e) {
      onNotificationChanged({
        text: 'Failed to load entity',
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      text: `You have arrived in an invalid state, please report.`,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
