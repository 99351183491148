import { IContextualMenuProps } from '@fluentui/react';
import { AuditLog } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import {
  CommandBarActions,
  Direction,
  DownloadOption,
  IBuildCommandBarProps,
  IGridPageCommandBarItemProps,
} from '../../../../layout/GridPageV2';
import { handleDownloadButtonClicked, setShowAllDetails, updateFiltersAndFetchLogs } from '../@data/actions';
import { getAuditHistoryStore } from '../@data/store/store';
import messages from '../AuditHistory.messages';
import { getFilterItems } from './getFilterItems';

export function getCommandBarItems(formatMessage, locale): IBuildCommandBarProps<AuditLog> {
  const { isShowAllDetailsEnabled } = getAuditHistoryStore();

  const downloadSubMenuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: 'page',
        text: formatMessage(AppMessages.downloadPage),
        onClick: () => {
          handleDownloadButtonClicked(DownloadOption.page, formatMessage, locale);
        },
        ariaDescribedBy: formatMessage(AppMessages.downloadPageCmdBtnAriaDesc),
      },
      {
        key: 'all',
        text: formatMessage(AppMessages.downloadAll),
        onClick: () => {
          handleDownloadButtonClicked(DownloadOption.all, formatMessage, locale);
        },
        ariaDescribedBy: formatMessage(AppMessages.downloadAllCmdBtnAriaDesc),
      },
    ],
  };

  return {
    includeFarActions: [CommandBarActions.searchBox, CommandBarActions.addFilter],
    getCustomCommandBarItems: (_): IGridPageCommandBarItemProps[] => {
      return [
        {
          key: 'showAll',
          text: formatMessage(isShowAllDetailsEnabled ? messages.hideAll : messages.showAll),
          iconProps: { iconName: isShowAllDetailsEnabled ? 'Hide3' : 'ShowAll' },
          onClick: () => setShowAllDetails(!isShowAllDetailsEnabled),
          placement: {
            direction: Direction.after,
            key: CommandBarActions.addFilter,
          },
        },
        {
          key: CommandBarActions.download,
          name: formatMessage(AppMessages.download),
          ariaDescription: formatMessage(AppMessages.downloadAllCmdBtnAriaDesc),
          iconProps: {
            iconName: 'Download',
          },
          subMenuProps: downloadSubMenuProps,
          placement: {
            direction: Direction.after,
            key: CommandBarActions.addFilter,
          },
        },
      ];
    },
    filterItems: getFilterItems(formatMessage),
    customApplyFilterFn: (filterConditions, items) => {
      updateFiltersAndFetchLogs(filterConditions);
      return getAuditHistoryStore().data || items;
    },
  };
}
