import * as React from 'react';
import { useEffect } from 'react';
import { Channel, getAppStore } from '../../../../@data';
import { DefaultRoute } from '../../../../Routes';
import { ZeroResults } from '../../../../components/ZeroResults/ZeroResults';
import { useWindowSize } from '../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../hooks/useWindowSize/store/store';
import { GridPageLayout } from '../../../../layout/GridPageLayout/GridPageLayout';
import { GridV2, IGridV2Column } from '../../../../layout/GridPageV2';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../utils/ReactUtils';
import { RouteName } from '../../@data';
import { getStore } from '../@data';
import { onChannelsFetched } from '../@data/actions';
import { onManagePageInitialized } from '../@data/actions/onManagePageInitialized';
import messages from './ManagePage.messages';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

export const ManagePage = injectIntlWithObserver(function ChannelManagePage(props: IRouterWithIntl) {
  const { publisher, account } = getAppStore();
  const { gridChannels, isLoading, isFetchingData } = getStore();
  const { formatMessage } = props.intl;
  const { windowStoreSerializer } = useWindowSize();
  const { isWindowRegularSize } = windowStoreSerializer(getWindowSizeStore());
  const gridDefaultColumns: IGridV2Column[] = getGridColumns(formatMessage, isWindowRegularSize, props.history);

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={!!publisher ? formatMessage(messages.zeroResultTitle) : undefined}
      description={formatMessage(!!publisher ? messages.zeroResultDescription : messages.noPublisherSelected)}
      buttonText={formatMessage(messages.create)}
      onButtonClick={
        !!publisher ? () => props.history.push(`${DefaultRoute}/${publisher?.id}-${account?.id}/${RouteName.channel}/create`) : undefined
      }
    />
  );

  useEffect(() => {
    onManagePageInitialized();
  }, [publisher, account]);

  return (
    <GridPageLayout>
      <GridV2
        history={props.history}
        location={props.location}
        match={props.match}
        entity={Channel}
        pageTitle={{ title: formatMessage(messages.channels), helpId: 'pop_PCv4_Channels' }}
        commandBarProps={getCommandBarItems()}
        dataGridProps={{
          gridV2Columns: gridDefaultColumns,
          data: publisher && account ? gridChannels || [] : [],
          setData: onChannelsFetched,
          shimmerLines: 10,
          renderZeroItemsComponent: NoResultComponent,
          noEntityFound: messages.notFound,
        }}
        isGridReady={!isLoading}
        isDataLoading={isFetchingData}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
    </GridPageLayout>
  );
});
