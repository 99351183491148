import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { onPublisherChanged, Publisher, showErrorToastNotification, StoragePublisherKey } from '../../../../../@data';
import messages from '../../ProfilePage.messages';
import { fetchPublisherProfileDetailsAction } from '../actions';
import { setPublisherProfileDetailsAction } from '../actions/mutatorActions';
import { fetchPublisherProfileDetails } from '../services/fetchPublisherProfileDetails';

orchestrator(fetchPublisherProfileDetailsAction, async ({ publisherId }) => {
  try {
    if (!publisherId) {
      return;
    }

    const publisherProfileDetails = await fetchPublisherProfileDetails(new Publisher(publisherId));
    const publisher = new Publisher(publisherProfileDetails.id, publisherProfileDetails.name);
    publisher.publisherTier = publisherProfileDetails.publisherTier;
    publisher.enableDefaultTythonV2Reporting = publisherProfileDetails.enableDefaultTythonV2Reporting;
    localStorage.setItem(StoragePublisherKey, JSON.stringify(publisher));
    setPublisherProfileDetailsAction(publisherProfileDetails);
    onPublisherChanged(publisherProfileDetails);
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadPublisherDetails });
    Log.error(`Failed to load publisher details: ${error.message}`);
  }
});
