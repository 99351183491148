import { createStore } from 'satcheljs';
import { IHomeViewState } from './schema/IHomeViewState';

import '../orchestrators';

export const getStore = createStore<IHomeViewState>('HomeViewState', {
  active: {
    showDialog: true,
  },
  isStatusLoading: false,
  isSetupDone: false,
  isFRECardShown: false,
});
