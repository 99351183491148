export const WEEK_DAYS_EN = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const WEEK_DAYS_EN_GB = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const WEEK_DAYS_FR = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
export const WEEK_DAYS_DE = ['S', 'M', 'D', 'M', 'D', 'F', 'S'];
export const WEEK_DAYS_IT = ['D', 'L', 'M', 'M', 'G', 'V', 'S'];
export const WEEK_DAYS_FR_CA = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
export const WEEK_DAYS_ES_ES = ['L', 'M', 'M', 'J', 'V', 'S', 'S'];
export const WEEK_DAYS_ES_MX = ['L', 'M', 'M', 'J', 'V', 'S', 'S'];

export const WEEK_DAYS_LANGUAGE_MAPPING = {
  'en-us': WEEK_DAYS_EN,
  'fr-fr': WEEK_DAYS_FR,
  'de-de': WEEK_DAYS_DE,
  'it-it': WEEK_DAYS_IT,
  'fr-ca': WEEK_DAYS_FR_CA,
  'es-es': WEEK_DAYS_ES_ES,
  'es-mx': WEEK_DAYS_ES_MX,
};
