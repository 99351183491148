import { classNamesFunction, FontWeights, IStyle, ITheme } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../theme';

export interface IManagePageStyleProps {
  theme: ITheme;
}

export interface IManagePageStyles {
  redirectionLink: IStyle;
  hoverCardTitle: IStyle;
  hoverCardInfo: IStyle;
  clarityCalloutTitle: IStyle;
  clarityStatusUnderline: IStyle;
  clarityCalloutFooter: IStyle;
  errorMsg: IStyle;
  errorMsgContent: IStyle;
  errorBulletPoint: IStyle;
  warningBulletPoint: IStyle;
  propertyIssuesUnorderedList: IStyle;
}

export const getStyles = (props: IManagePageStyleProps): IManagePageStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { fonts, spacing, palette } = theme;

  return {
    redirectionLink: {
      color: palette.white,
      cursor: 'pointer',
      textDecorationLine: 'underline',
      '&:hover': {
        color: palette.white,
      },
      '&:active': {
        color: palette.white,
      },
      '&:focus': {
        color: palette.white,
      },
    },
    hoverCardTitle: {
      fontSize: fonts.size16.fontSize,
      fontWeight: fonts.size14Medium.fontWeight,
      marginBottom: spacing.size10,
    },
    hoverCardInfo: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: fonts.size14Medium.lineHeight,
    },
    clarityCalloutTitle: {
      ...fonts.size20,
    },
    clarityCalloutFooter: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    clarityStatusUnderline: {
      borderBottom: `1px dotted ${palette.themePrimary}`,
      width: 'fit-content',
      boxSizing: 'border-box',
    },
    errorMsg: {
      border: `1px solid ${palette.paleChestnut}`,
      borderRadius: spacing.size4,
      marginBottom: spacing.size10,
      i: {
        display: 'flex',
        alignSelf: 'center',
      },
    },
    errorMsgContent: {
      fontSize: fonts.size14.fontSize,
      fontWeight: FontWeights.semibold,
    },
    errorBulletPoint: {
      '&::marker': {
        color: palette.redDark,
      },
    },
    warningBulletPoint: {
      ' &::marker': {
        color: palette.orangeLighter,
      },
    },
    propertyIssuesUnorderedList: {
      margin: 0,
    },
  };
};

export const getClassNames = classNamesFunction<IManagePageStyleProps, IManagePageStyles>();
