import { defineMessages } from 'react-intl';

export default defineMessages({
  linkingNote: {
    id: 'property.clarity.linkingNote',
    defaultMessage: '{status}. Click {here} to resend the request again.',
  },
  cancelledStatus: {
    id: 'property.clarity.cancelledStatus',
    defaultMessage: 'Request was cancelled',
  },
  pendingStatus: {
    id: 'property.clarity.pendingStatus',
    defaultMessage: 'Pending Approval',
  },
  declinedStatus: {
    id: 'property.clarity.declinedStatus',
    defaultMessage: 'Request was declined',
  },
  expiredStatus: {
    id: 'property.clarity.expiredStatus',
    defaultMessage: 'Request was expired',
  },
});
