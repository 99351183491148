import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { LifeCycleStatus, Model, PopupType } from '../../../@data';
import AppMessages from '../../../App.messages';
import { PopupCard } from '../../../components/Popup';
import { injectIntlAndObserver } from '../../../utils';
import { onChangeItemsStatus } from '../@data/actions/commandBarActions';
import { setDialogType } from '../@data/mutatorActions';
import { getStore } from '../@data/store/store';
import { IDestructiveDialogLabelsProps } from '../GridV2.types';

export interface IDeactivateConfirmationDialogProps extends InjectedIntlProps {
  deactivateLabels?: IDestructiveDialogLabelsProps;
  setData?: (data?: Model[]) => void;
  data: Model[];
  // tslint:disable-next-line: no-any
  selectedItems: any[];
  onItemsStatusChanged?: (items?: Model[], status?: LifeCycleStatus) => void;
  onDismiss?: () => void;
}

export const DeactivateConfirmationDialog = injectIntlAndObserver(function DeactivateConfirmationDialogComp({
  intl: { formatMessage },
  deactivateLabels,
  setData,
  data,
  selectedItems,
  onItemsStatusChanged,
  onDismiss,
}: IDeactivateConfirmationDialogProps): JSX.Element | null {
  const { dialogType } = getStore();

  const onDialogDismissed = () => {
    setDialogType(PopupType.None);
    onDismiss?.();
  };

  const handleDeactivateButtonClick = () => {
    onChangeItemsStatus(selectedItems, LifeCycleStatus.Inactive, data, formatMessage, setData, onItemsStatusChanged);
    setDialogType(PopupType.None);
  };

  return (
    <PopupCard
      isShown={dialogType === PopupType.DeactivateConfirmation}
      popUpTitle={formatMessage(deactivateLabels ? deactivateLabels.title : AppMessages.deactivateGenericTitle)}
      popUpDescription={deactivateLabels?.description ? formatMessage(deactivateLabels.description) : undefined}
      onPopupDismiss={onDialogDismissed}
      onPrimaryButtonClick={handleDeactivateButtonClick}
      onSecondaryButtonClick={onDialogDismissed}
      primaryButtonAriaLabel={formatMessage(AppMessages.deactivate)}
      secondaryButtonAriaLabel={formatMessage(AppMessages.cancel)}
    />
  );
});
