import { IChoiceGroupOption } from '@fluentui/react';
import { mutatorAction } from 'satcheljs';
import { PrivateMarketplace } from '../../../../../@data';
import { getStore } from '../store/store';

export const onAccountPrivateMarketplaceChanged = mutatorAction(
  'onAccountPrivateMarketplaceChanged',
  (privateMarketplace: IChoiceGroupOption) => {
    getStore().privateMarketplace = privateMarketplace.key as PrivateMarketplace;
    getStore().enablePrivateMarketplace = true;
  }
);

export const onAccountEnablePrivateMarketplaceChanged = mutatorAction(
  'onAccountEnablePrivateMarketplaceChanged',
  (enablePrivateMarketplace: boolean) => {
    getStore().enablePrivateMarketplace = enablePrivateMarketplace;
  }
);
