import { orchestrator } from 'satcheljs';
import { Account, getAppStore, Publisher, showErrorToastNotification } from '../../../../../@data';
import { setPublisherAndAccountContext } from '../../../@data/services/AdManagementService';
import messages from '../../Manage/ManagePage.messages';
import { onAdunitChanged, onAdunitErrorChanged, onFetchAdUnitName, onRawChanged } from '../actions';
import { fetchAdunitById } from '../services/AdunitService';
import { IAdunitViewModel } from '../store/schema';

orchestrator(onFetchAdUnitName, async ({ publisherId, accountId, entityId, setIsLoading }) => {
  const { userContext } = getAppStore();
  const viewModel: IAdunitViewModel = {};
  const parsedPublisherId = parseInt(publisherId, 10);
  const parsedAccountId = parseInt(accountId, 10);

  if (!isNaN(parsedPublisherId) && !isNaN(parsedAccountId) && entityId) {
    const publisher = new Publisher(parsedPublisherId);
    const account = new Account(parsedAccountId);

    try {
      setPublisherAndAccountContext(publisher, account);
      onAdunitErrorChanged();
      setIsLoading(true);
      const raw = await fetchAdunitById(publisher, entityId, userContext);

      viewModel.id = raw.id;
      viewModel.name = String(raw.name);

      onRawChanged(raw);
      onAdunitChanged(viewModel);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadAdunit });
    } finally {
      setIsLoading(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadAdunit });
  }
});
