import { Model, getAppStore } from '../../../../../@data';
import { CommandBarActions, IBuildCommandBarProps } from '../../../../../layout/GridPageV2';
import { getFilterStore } from '../../@data';
import messages from '../ManagePage.messages';
import { getFilterItems } from './getFilterItems';

export function getCommandBarItems(formatMessage): IBuildCommandBarProps<Model> | undefined {
  const { publisher, account } = getAppStore();
  const { gridFilters, isLoading } = getFilterStore();

  const commandBarItems: IBuildCommandBarProps<Model> | undefined =
    (gridFilters && gridFilters.length !== 0 && publisher && account) || isLoading === true
      ? {
          primaryButtonLabel: messages.create,
          includeActions: [CommandBarActions.new],
          includeFarActions: [
            CommandBarActions.searchBox,
            CommandBarActions.addFilter,
            CommandBarActions.edit,
            CommandBarActions.activate,
            CommandBarActions.deactivate,
            CommandBarActions.delete,
            CommandBarActions.download,
            CommandBarActions.duplicate,
            CommandBarActions.modifyColumns,
          ],
          filterItems: getFilterItems(formatMessage),
        }
      : undefined;

  return commandBarItems;
}
