import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class NavigationMenu extends Model {
  public className = UserRoleEntity.NavigationMenu;
  public home: number;
  public adunit: number;
  public channel: number;
  public adFilter: number;
  public property: number;
  public adblock: number;
  public publisherProfile: number;
  public reporting: number;
  public publisher: number;
  public account: number;
  public user: number;
  public adquality: number;
  public dashboard: number;
  public auditHistory: number;

  constructor(params: NavigationMenu) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: NavigationMenu, menu: NavigationMenu) {
    // tslint:disable:no-bitwise
    this.accessPermission |= menu.accessPermission;
    this.home |= menu.home;
    this.adunit |= menu.adunit;
    this.channel |= menu.channel;
    this.adFilter |= menu.adFilter;
    this.property |= menu.property;
    this.adblock |= menu.adblock;
    this.publisherProfile |= menu.publisherProfile;
    this.publisher |= menu.publisher;
    this.account |= menu.account;
    this.user |= menu.user;
    this.reporting |= menu.reporting;
    this.adquality |= menu.adquality;
    this.dashboard |= menu.dashboard;
    this.auditHistory |= menu.auditHistory;
    // tslint:enable:no-bitwise
  }
}
