import { defineMessages } from 'react-intl';

export default defineMessages({
  headerLabel: {
    id: 'notificationConfigTab.headerLabel',
    defaultMessage: 'Notification preferences',
  },
  headerAction: {
    id: 'notificationConfigTab.headerAction',
    defaultMessage: 'Edit',
  },
  panelHeader: {
    id: 'notificationConfigTab.panelHeader',
    defaultMessage: 'Edit notification preferences',
  },
  billingLabel: {
    id: 'notificationConfigTab.billingLabel',
    defaultMessage: 'Billing alerts',
  },
  billingDescription: {
    id: 'notificationConfigTab.billingDescription',
    defaultMessage: `We'll notify you with statements, monthly invoices, prepay or postpay threshold accounts.`,
  },
  servingLabel: {
    id: 'notificationConfigTab.servingLabel',
    defaultMessage: 'Serving status',
  },
  servingDescription: {
    id: 'notificationConfigTab.servingDescription',
    defaultMessage: `We'll notify you about your serving status.`,
  },
  profileLabel: {
    id: 'notificationConfigTab.profileLabel',
    defaultMessage: 'Profile review',
  },
  profileDescription: {
    id: 'notificationConfigTab.profileDescription',
    defaultMessage: `We'll notify you about any profile review updates.`,
  },
  invitedUserLabel: {
    id: 'notificationConfigTab.invitedUserLabel',
    defaultMessage: 'User invitations',
  },
  invitedUserDescription: {
    id: 'notificationConfigTab.invitedUserDescription',
    defaultMessage: `We'll notify you when a new user has been invited to this account.`,
  },
  saveButtonAriaDesc: {
    id: 'notificationConfigTab.saveButtonDescription',
    defaultMessage: 'Click to update notification preferences',
  },
});
