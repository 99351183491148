import { defineMessages } from 'react-intl';

export default defineMessages({
  clarityRequestSuccess: {
    id: 'siteManagement.clarityRequestSuccess',
    defaultMessage: "You've successfully sent a request to relink your Clarity project ID.",
  },
  propertyCreateSuccess: {
    id: 'siteManagement.propertyCreateSuccess',
    defaultMessage: "You've successfully created a new property {entityId}.",
  },
  propertyCreateFailed: {
    id: 'siteManagement.propertyCreateFailed',
    defaultMessage: 'Create properties failed, please try again later.',
  },
  propertyEditSuccess: {
    id: 'siteManagement.propertyEditSuccess',
    defaultMessage: "You've successfully edited your property {entityId}.",
  },
  invalidForm: {
    id: 'siteManagement.invalidForm',
    defaultMessage: 'Property form "{formOperation}" is invalid id {entityId}',
  },
  invalidState: {
    id: 'siteManagement.invalidState',
    defaultMessage: 'You have arrived in an invalid state, please report.',
  },
  clarityRelinkSuccess: {
    id: 'siteManagement.clarityRelinkSuccess',
    defaultMessage: "You've successfully sent a request to relink your Clarity project ID.",
  },
  clarityRelinkFailed: {
    id: 'siteManagement.clarityRelinkFailed',
    defaultMessage: "Your request to relink your Clarity project {projectId} wasn't successful. Please try again later.",
  },
  clarityLinkSuccess: {
    id: 'siteManagement.clarityLinkSuccess',
    defaultMessage: "You've successfully sent a request to link your Clarity project.",
  },
  clarityUnlinkSuccess: {
    id: 'siteManagement.clarityUnlinkSuccess',
    defaultMessage: "You've successfully unlinked your Clarity project.",
  },
  clarityLinkFailed: {
    id: 'siteManagement.clarityLinkFailed',
    defaultMessage: "We're unable to link your Clarity project {projectId}.",
  },
  propertyLoadError: {
    id: 'siteManagement.propertyLoadError',
    defaultMessage: "We're unable to load your entity. Please try again later.",
  },
  propertyFormError: {
    id: 'siteManagement.propertyFormError',
    defaultMessage: 'There are {errorsCount} errors in the form. Please revise and resubmit to continue.',
  },
  adUnitsFetchFailed: {
    id: 'siteManagement.adUnitsFetchFailed',
    defaultMessage: "We're unable to load your ad units.",
  },
  sitesFetchFailed: {
    id: 'siteManagement.sitesFetchFailed',
    defaultMessage: "We're unable to load your sites.",
  },
  propertiesFetchFailed: {
    id: 'siteManagement.sitesFetchFailed',
    defaultMessage: "We're unable to load your properties.",
  },
  failedToLoadSites: {
    id: 'siteManagement.failedToLoadSites',
    defaultMessage: 'Failed to load sites',
  },
});
