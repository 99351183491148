import { IStackTokens, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../theme';

type StyleProperties = 'root';
type ReportDetailsPageStyles = Record<StyleProperties, string>;
interface IReportDetailsPageStyleProps {}
const theme = (getTheme() as unknown) as IPubcenterTheme;
const spacing = theme.spacing;

export const getStyles = (props: IReportDetailsPageStyleProps): ReportDetailsPageStyles => {
  return mergeStyleSets({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: 1,
    },
  });
};
export const formTitleStyles = {
  root: {
    marginBottom: spacing.size24,
  },
};
export function getClassNames<PropType>(props?: PropType): ReportDetailsPageStyles {
  return getStyles({ theme: getTheme(), ...props });
}
export function getStackTokens(): IStackTokens {
  return { childrenGap: spacing.size12 };
}
