import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useAppAdUnitCodePanelStyles = makeStyles({
  textArea: {
    width: '100%',
    height: '236px',
    '> textarea': {
      maxHeight: '100%',
    },
  },
  textAreaWrapper: {
    ':hover': {
      '> button': {
        zIndex: 100,
      },
    },
    position: 'relative',
    ...shorthands.padding(tokens.spacingVerticalM),
  },
  codeBlocOnlyTextWrapper: {
    width: '600px',
  },
  copyButton: {
    position: 'absolute',
    right: tokens.spacingHorizontalXXL,
    top: tokens.spacingVerticalXXL,
  },
  additionalCodeArea: {
    height: '300px',
  },
});
