import { DirectionalHint, HoverCard, HoverCardType, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { getClassNames } from '../GridV2.styles';

interface ICellInfoCalloutProps {
  children: JSX.Element;
  placement?: DirectionalHint;
  calloutContent: JSX.Element | null;
}

export const CellInfoCallout = (props: ICellInfoCalloutProps) => {
  const { children, placement = DirectionalHint.leftTopEdge, calloutContent } = props;
  const { calloutFocusable } = mergeStyleSets(getClassNames());

  return (
    <HoverCard
      style={{
        width: 360,
        padding: '20px 15px',
      }}
      className={calloutFocusable}
      plainCardProps={{
        directionalHint: placement,
        calloutProps: {
          isBeakVisible: true,
        },
        onRenderPlainCard: () => calloutContent,
      }}
      cardDismissDelay={200}
      instantOpenOnClick
      type={HoverCardType.plain}
      trapFocus={true}
      setInitialFocus
    >
      {children}
    </HoverCard>
  );
};
