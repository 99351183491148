import { IStyleFunctionOrObject } from '@fluentui/merge-styles';
import { getTheme, IButtonStyles, ICommandBarStyleProps, ICommandBarStyles } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing } from '../../../../../theme';

export const getCustomCommandBarStyles = (
  itemsSelected: boolean,
  itemsCount: number
): IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> => {
  const theme = getTheme();
  const palette = theme.palette as IPubcenterPalette;
  const spacing = theme.spacing as IPubcenterSpacing;

  const overflowSetStyles = {
    backgroundColor: palette.white,
    gridGap: spacing.size4,
  };

  const styles = {
    root: {
      backgroundColor: palette.white,
      alignItems: 'center',
      height: spacing.size50,
      padding: '0',
      [`& .ms-OverflowSet-item:nth-child(${itemsCount})`]: {
        display: 'flex',
        flexGrow: itemsSelected ? 0 : 1,
      },
    },
    primarySet: {
      ...overflowSetStyles,
    },
    secondarySet: {
      ...overflowSetStyles,
    },
  };

  if (itemsCount === 0 && !itemsSelected) {
    styles.root['justify-content'] = 'flex-end';
    styles.primarySet['flex-grow'] = 0;
  }

  if (itemsSelected) {
    styles.root.backgroundColor = palette.themePrimary;
    styles.primarySet.backgroundColor = palette.themePrimary;
    styles.secondarySet.backgroundColor = palette.themePrimary;
    /* tslint:disable:no-string-literal */
    styles.secondarySet['height'] = '100%';
  }

  return styles;
};

export const getButtonStyles = (): IButtonStyles => {
  const theme = getTheme();
  const { semanticColors: s, palette: p } = theme;
  const { size14 } = theme.fonts as IPubCenterFonts;

  return {
    root: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
      ...size14,
      selectors: {
        '&:focus': {
          border: 'none',
          outlineColor: p.white,
          backgroundColor: 'black',
        },
      }, // UM_TODO: focus box is black, not getting reflected
    },
    rootHovered: {
      backgroundColor: s.primaryButtonBackgroundHovered,
      color: s.primaryButtonTextHovered,
    },
    rootPressed: {
      backgroundColor: s.primaryButtonBackgroundPressed,
      color: s.primaryButtonTextPressed,
    },
    rootFocused: {
      backgroundColor: s.primaryButtonBackgroundHovered,
      color: s.primaryButtonTextHovered,
    },
    rootExpandedHovered: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
    rootHasMenu: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
    icon: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
    iconHovered: {
      backgroundColor: s.primaryButtonBackgroundHovered,
      color: s.primaryButtonTextHovered,
    },
    iconExpanded: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
    iconExpandedHovered: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
    iconPressed: {
      backgroundColor: s.primaryButtonBackgroundPressed,
      color: s.primaryButtonTextPressed,
    },
    menuIcon: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
    menuIconHovered: {
      backgroundColor: s.primaryButtonBackgroundHovered,
      color: s.primaryButtonTextHovered,
    },
    menuIconPressed: {
      backgroundColor: s.primaryButtonBackgroundPressed,
      color: s.primaryButtonTextPressed,
    },
    menuIconExpandedHovered: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
    menuIconExpanded: {
      backgroundColor: s.primaryButtonBackground,
      color: s.primaryButtonText,
    },
  };
};

export const getCountMenuStyles = () => {
  const theme = getTheme();
  const palette = theme.palette as IPubcenterPalette;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    borderRight: `1px solid ${palette.white}`,
    margin: `${spacing.size10} 0`,
    padding: `${spacing.size4} ${spacing.size10}`,
    color: palette.white,
  };
};
