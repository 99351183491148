import {
  ADUNIT_CHART_TYPE_BAR_BUTTON,
  ADUNIT_CHART_TYPE_DONUT_BUTTON,
  ADUNIT_CHART_TYPE_GRID_BUTTON,
  COUNTRY_CHART_TYPE_BAR_BUTTON,
  COUNTRY_CHART_TYPE_GRID_BUTTON,
  COUNTRY_CHART_TYPE_MAP_BUTTON,
  MEDIATION_CHART_TYPE_BAR_BUTTON,
  MEDIATION_CHART_TYPE_DONUT_BUTTON,
  MEDIATION_CHART_TYPE_GRID_BUTTON,
  PERFORMANCE_CHART_TYPE_BAR_BUTTON,
  PERFORMANCE_CHART_TYPE_DONUT_BUTTON,
  PERFORMANCE_CHART_TYPE_LINE_BUTTON,
  SITE_CHART_TYPE_BAR_BUTTON,
  SITE_CHART_TYPE_DONUT_BUTTON,
  SITE_CHART_TYPE_GRID_BUTTON,
} from '../../../constants/DataTestIdConstants';
import { ChartTypes, IChartButton } from '../DashboardCards/ReportCard/ReportCard.types';

export const PERFORMANCE_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.LINE, disabled: false, iconName: '', dataTestId: PERFORMANCE_CHART_TYPE_LINE_BUTTON },
];
export const DETAILED_PERFORMANCE_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.LINE, disabled: false, iconName: 'LineChart', dataTestId: PERFORMANCE_CHART_TYPE_LINE_BUTTON },
  { chartType: ChartTypes.BAR, disabled: true, iconName: 'BarChartHorizontal', dataTestId: PERFORMANCE_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.DONUT, disabled: true, iconName: 'DonutChart', dataTestId: PERFORMANCE_CHART_TYPE_DONUT_BUTTON },
];
export const ADUNIT_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.DONUT, disabled: false, iconName: 'DonutChart', dataTestId: ADUNIT_CHART_TYPE_DONUT_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: ADUNIT_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.GRID, disabled: false, iconName: 'GridViewSmall', dataTestId: ADUNIT_CHART_TYPE_GRID_BUTTON },
];
export const DETAILED_ADUNIT_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.LINE, disabled: false, iconName: 'LineChart', dataTestId: ADUNIT_CHART_TYPE_DONUT_BUTTON },
  { chartType: ChartTypes.DONUT, disabled: false, iconName: 'DonutChart', dataTestId: ADUNIT_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: ADUNIT_CHART_TYPE_GRID_BUTTON },
];
export const SITE_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.DONUT, disabled: false, iconName: 'DonutChart', dataTestId: SITE_CHART_TYPE_DONUT_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: SITE_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.GRID, disabled: false, iconName: 'GridViewSmall', dataTestId: SITE_CHART_TYPE_GRID_BUTTON },
];
export const DETAILED_SITE_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.LINE, disabled: false, iconName: 'LineChart', dataTestId: SITE_CHART_TYPE_DONUT_BUTTON },
  { chartType: ChartTypes.DONUT, disabled: false, iconName: 'DonutChart', dataTestId: SITE_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: SITE_CHART_TYPE_GRID_BUTTON },
];
export const MEDIATION_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.DONUT, disabled: false, iconName: 'DonutChart', dataTestId: MEDIATION_CHART_TYPE_DONUT_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: MEDIATION_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.GRID, disabled: false, iconName: 'GridViewSmall', dataTestId: MEDIATION_CHART_TYPE_GRID_BUTTON },
];
export const DETAILED_MEDIATION_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.LINE, disabled: false, iconName: 'LineChart', dataTestId: MEDIATION_CHART_TYPE_DONUT_BUTTON },
  { chartType: ChartTypes.DONUT, disabled: false, iconName: 'DonutChart', dataTestId: MEDIATION_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: MEDIATION_CHART_TYPE_GRID_BUTTON },
];
export const COUNTRY_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.MAP, disabled: false, iconName: 'World', dataTestId: COUNTRY_CHART_TYPE_MAP_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: COUNTRY_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.GRID, disabled: false, iconName: 'GridViewSmall', dataTestId: COUNTRY_CHART_TYPE_GRID_BUTTON },
];
export const DETAILED_COUNTRY_ALLOWED_CHARTS: IChartButton[] = [
  { chartType: ChartTypes.LINE, disabled: false, iconName: 'LineChart', dataTestId: COUNTRY_CHART_TYPE_MAP_BUTTON },
  { chartType: ChartTypes.MAP, disabled: false, iconName: 'World', dataTestId: COUNTRY_CHART_TYPE_BAR_BUTTON },
  { chartType: ChartTypes.BAR, disabled: false, iconName: 'BarChartHorizontal', dataTestId: COUNTRY_CHART_TYPE_GRID_BUTTON },
];
