import { FilterScope } from '../enums';
import { Model } from './Model';

export class Filter extends Model {
  public apiPath = undefined;
  public className = 'filters';
  public appendOnly?: boolean;
  public associatedChannelIds?: number[];
  public associatedChannelCount?: number;
  public blockedAdKeywords?: string[];
  public blockedQueryKeywords?: string[];
  public blockedUrls?: string[];
  public filterScope?: FilterScope;
  public scopeId?: number;
  public blockedUrlCount?: number;
  public blockedAdKeywordCount?: number;
  public blockedQueryKeywordCount?: number;
  public startTime?: string;
  public endTime?: string;
  public startTimeStamp?: number;
  public endTimeStamp?: number;
}
