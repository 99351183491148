import { AccountInfo, AuthenticationResult, EndSessionRequest, RedirectRequest } from '@azure/msal-browser';
import { Log } from '../../../logging/src/Log';
import { pubcenterApp } from '../../PubCenterAuthContext';
import {
  aadConfig,
  accountEnvironment,
  authTokenRequest,
  envBasedConfig,
  silentTokenRequest,
  silentTokenRequestRedirectUri,
} from '../../config/pubCenterAAD.config';
import {
  REDIRECT_URI_SESSION_STORAGE_KEY,
  TOKEN_EXPIRED_SEARCH_PARAM_KEY,
  UNAUTHORIZED_LOCAL_STORAGE_LOCALE,
} from '../../constants/AppConstants';
import { clearAllCookies } from '../../utils/cookiesUtils';
import { UserContext } from '../store';
import { StorageUserContextKey, getAppStore } from '../store/store';

let authResponse: AuthenticationResult;
const { userContext } = getAppStore();
let username = userContext?.userName ?? userContext?.email ?? '';

export async function logoutPubCenterAADUser(isTokenExpired?: boolean): Promise<void> {
  const tenantId = pubcenterApp.getAccountByUsername(username)?.idTokenClaims?.tid;

  if (tenantId === aadConfig.auth.msaTenantId) {
    msaLogout(isTokenExpired);
  } else {
    aadLogout(isTokenExpired);
  }

  Log.debug('Clearing storage cache after logout');
  // clearing cache and cookies to avoid login issues
  const localStorageUnAuthLocale = window.localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE);
  localStorage.clear();
  sessionStorage.clear();
  clearAllCookies();
  if (localStorageUnAuthLocale) {
    window.localStorage.setItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE, localStorageUnAuthLocale);
  }
}

function aadLogout(isTokenExpired?: boolean) {
  const logoutRequest: EndSessionRequest = {
    logoutHint: pubcenterApp.getAccountByUsername(username)?.idTokenClaims?.login_hint,
    postLogoutRedirectUri: getPostLogoutRedirectUri(isTokenExpired),
  };

  Log.write('Logging out AAD user');
  pubcenterApp.logoutRedirect(logoutRequest);
}

function msaLogout(isTokenExpired?: boolean) {
  const postLogoutUrl = 'redirect_uri=' + encodeURIComponent(getPostLogoutRedirectUri(isTokenExpired));
  const clientIdQueryStr = 'client_id=' + encodeURIComponent(aadConfig.auth.clientId);
  const msaLogoutRedirectionUrl = `${aadConfig.auth.msaAuthority}/oauth20_logout.srf?${clientIdQueryStr}&${postLogoutUrl}`;

  Log.write('Logging out MSA user and redirecting to login page ' + msaLogoutRedirectionUrl.toString());
  window.location.replace(msaLogoutRedirectionUrl);
}

export function loginUserWithAdditionalClaims(authenticateHeader: string | null) {
  if (!authenticateHeader?.includes('claims')) {
    return;
  }

  const claimsRegex = `claims="([^"]+)"`;
  const match = new RegExp(claimsRegex).exec(authenticateHeader);
  let claims = '';
  if (!match || match.length < 1) {
    return;
  }
  claims = atob(match[1]);

  sessionStorage.setItem(REDIRECT_URI_SESSION_STORAGE_KEY, window.location.pathname);

  const requestParams: Partial<RedirectRequest> = {
    redirectStartPage: '/login',
    extraQueryParameters: { claims: encodeURI(claims) },
    prompt: 'login',
  };
  loginPubCenterAADUser(requestParams);
}

export function loginPubCenterAADUser(requestParams?: Partial<RedirectRequest>) {
  handleInvalidInteractionStatus();

  const redirectRequest: RedirectRequest = { ...authTokenRequest, ...requestParams };
  if (!redirectRequest.extraQueryParameters) {
    redirectRequest.extraQueryParameters = {};
  }

  // msaoauth2 paramenter is required to enable MSA user login flow
  redirectRequest.extraQueryParameters.msaoauth2 = 'true';
  pubcenterApp.loginRedirect(redirectRequest);
}

export async function acquireAuthResponseAsync(request, usernameHint?: string): Promise<AuthenticationResult> {
  username = usernameHint ?? username ?? pubcenterApp.getActiveAccount()?.username;

  if (username) {
    const accountInfo: AccountInfo = {
      username: username,
      environment: accountEnvironment,
      tenantId: aadConfig.auth.msaTenantId,
      homeAccountId: '',
      localAccountId: '',
    };
    request.account = pubcenterApp.getAccountByUsername(username) || accountInfo;

    try {
      authResponse = await pubcenterApp.acquireTokenSilent({ ...request, silentTokenRequestRedirectUri });
    } catch (error) {
      Log.warn('silent token acquisition fails. acquiring token using redirect: ' + JSON.stringify(error));
      const requestParams: Partial<RedirectRequest> = { loginHint: username, redirectStartPage: aadConfig.auth.redirectUri };
      loginPubCenterAADUser(requestParams);
    }
  } else {
    loginPubCenterAADUser();
  }
  return authResponse;
}

export async function fetchPubCenterAADAccessTokenAsync() {
  authResponse = await acquireAuthResponseAsync(silentTokenRequest);
  if (authResponse) {
    return authResponse.accessToken;
  }
}

export async function getIdToken() {
  authResponse = await acquireAuthResponseAsync(silentTokenRequest);
  if (authResponse) {
    return authResponse.idToken;
  }
}

export async function handleLoginRedirectAsync() {
  await pubcenterApp
    .handleRedirectPromise()
    .then((response) => {
      if (!!response) {
        username = response.account !== null ? response.account.username : '';
        Log.info('Login successful with: ' + username);
        authResponse = response;
      }
    })
    .catch((err) => {
      Log.error(err);
    });
  return authResponse;
}

/**
 * In cases where the Login redirection flow is interruped by the user, the session storage cached data used for proper
 * login flow does not get cleared out. This dormant cache data creates login issues the next time user tries
 * to login. This method helps in removing any dormant cache data present in session storage.
 */
function handleInvalidInteractionStatus() {
  const interactionStatus = sessionStorage.getItem('msal.interaction.status');
  const clientIdInteractionStatus = sessionStorage.getItem(`msal.${envBasedConfig.clientId}.interaction.status`);

  // After refresh token is expired, user is successfully logged out and redirected to PubCenter login page,
  // it recommends us to close the browser to clear session context, but mostly user continue to login directly
  // here session storage is not cleared and sometimes has a interaction in progress status from the earlier interaction
  // When user tries to login again this status interrupts the login flow. Clearing session storage helps to start a fresh session.
  if (
    (interactionStatus && interactionStatus === envBasedConfig.clientId) ||
    (clientIdInteractionStatus && clientIdInteractionStatus === 'interaction_in_progress')
  ) {
    console.log('Interaction in progress, clearing session storage');
    localStorage.clear();
    sessionStorage.removeItem('msal.interaction.status');
    sessionStorage.removeItem(`msal.${envBasedConfig.clientId}.interaction.status`);
    clearAllCookies();
    localStorage.setItem(StorageUserContextKey, JSON.stringify(new UserContext()));
  }
}

function getPostLogoutRedirectUri(isTokenExpired?: boolean): string {
  let postLogoutRedirectUri = aadConfig.auth.redirectUri;
  if (isTokenExpired && postLogoutRedirectUri) {
    postLogoutRedirectUri += `?${TOKEN_EXPIRED_SEARCH_PARAM_KEY}=true`;
  }
  return postLogoutRedirectUri;
}
