import { useMemo } from 'react';
import { addLocaleData } from 'react-intl';
import deLocaleData from 'react-intl/locale-data/de';
import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import frLocaleData from 'react-intl/locale-data/fr';
import itLocaleData from 'react-intl/locale-data/it';
import { upperCaseCountryCode } from '../app/@data';
import {
  DEFAULT_COUNTRY_CODE,
  DE_LOCALE_KEY,
  EN_GB_LOCALE_KEY,
  EN_LOCALE_KEY,
  ES_LOCALE_KEY,
  ES_MX_LOCALE_KEY,
  FR_CA_LOCALE_KEY,
  FR_LOCALE_KEY,
  IT_LOCALE_KEY,
} from '../app/constants/AppConstants';
import de from './locales/de-de.json';
import enGb from './locales/en-gb.json';
import es from './locales/es-es.json';
import esMx from './locales/es-mx.json';
import frCa from './locales/fr-ca.json';
import fr from './locales/fr-fr.json';
import it from './locales/it-it.json';

import { UseI18n } from './useI18n.types';

export const useI18n: UseI18n = ({ systemPreferences, publisher, userContext }) => {
  const { locale: sysLocale, countryCode: sysCountryCode } = systemPreferences ?? {};
  addLocaleData(enLocaleData);
  addLocaleData(frLocaleData);
  addLocaleData(deLocaleData);
  addLocaleData(itLocaleData);
  addLocaleData(esLocaleData);

  const [locale, formattedLocale, countryCode, messages] = useMemo(() => {
    // set default locale to English
    const appLocale = sysLocale ?? EN_LOCALE_KEY;
    const appCountryCode = sysCountryCode ?? DEFAULT_COUNTRY_CODE;
    const formatLocale = upperCaseCountryCode(appLocale);
    switch (appLocale) {
      case EN_GB_LOCALE_KEY:
        return [appLocale, formatLocale, appCountryCode, enGb];
      case FR_LOCALE_KEY:
        return [appLocale, formatLocale, appCountryCode, fr];
      case DE_LOCALE_KEY:
        return [appLocale, formatLocale, appCountryCode, de];
      case IT_LOCALE_KEY:
        return [appLocale, formatLocale, appCountryCode, it];
      case FR_CA_LOCALE_KEY:
        return [appLocale, formatLocale, appCountryCode, frCa];
      case ES_LOCALE_KEY:
        return [appLocale, formatLocale, appCountryCode, es];
      case ES_MX_LOCALE_KEY:
        return [appLocale, formatLocale, appCountryCode, esMx];
      default:
        return [appLocale, formatLocale, appCountryCode];
    }
  }, [sysLocale, sysCountryCode]);

  return {
    locale,
    formattedLocale,
    countryCode,
    messages,
  };
};
