import { LifeCycleStatus, Model } from '../../../../../../../@data';

export class SAMBlockSummaryData extends Model {
  public apiPath = undefined;
  public className = 'samBlockSummaryData';
  public filterId?: number;
  public filterScope?: string;
  public creativeAdId?: string;
  public adTitle?: string;
  public advertiser?: string;
  public sevenDayRevenueImpact?: number;
  public adProvider?: string;
  public blockRequestSourceId?: number;
  public locale?: string;
  public allAdImages?: string[];
  public blockReasonCategory?: string;
  public blockReasonMainCategory?: string;
  public blockReasonSubCategory?: string;
  public blockReasonComment?: string;
  public reportedBy?: number;
  public reportedByName?: string;
  public modifiedDateTime?: string;
  public filterStatus?: string;
  public status?: LifeCycleStatus;
}
