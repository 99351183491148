import { MessageBarType } from '@fluentui/react';
import { Log } from '../../../../../../logging/src';
import {
  Publisher,
  TythonAdUnit,
  UserContext,
  buildRequestHeadersWithAuthToken,
  constructUrl,
  onNotificationChanged,
} from '../../../../../@data';
import { deepEqual } from '../../../../../utils';
import messages from '../CreateAdunit/messages';
import { ITythonAdUnit } from '../CreateAdunit/types';

export const updateTythonAdUnit = async (
  publisher: Publisher,
  userContext: UserContext,
  adunit: ITythonAdUnit,
  isAdUnitNameUpdated: boolean,
  updateLoadingStatus?: (isLoading: boolean) => void
): Promise<TythonAdUnit | { isDirty?: boolean }> => {
  updateLoadingStatus?.(true);
  const adUnit = new TythonAdUnit(adunit.id);
  adUnit.className = 'adunits';
  const urlParams = new URLSearchParams();
  urlParams.append('extendedinfo', 'true');

  const body = {
    servingConfig: {
      ...(adunit.raw! as ITythonAdUnit),
      adUnitName: adunit.name,
      adUnitId: adunit.id,
      accountId: adunit.accountId,
      propertyId: adunit.propertyId,
      adTypes: adunit.adTypes,
      nativeProperties: adunit.nativeProperties,
    },
  };

  const isMadeNoChange = deepEqual(adunit.raw!, { ...body.servingConfig, adUnitName: adunit.name }, true);

  if (isMadeNoChange) {
    updateLoadingStatus?.(false);
    return Promise.resolve({ isDirty: false });
  }
  /**
   * if there is no update to ad unit name, we will delete the name
   * property in the payload for backend perf enhancement
   */
  if (!isAdUnitNameUpdated) {
    delete body.servingConfig.adUnitName;
  }

  const requestUrl = constructUrl([publisher, adUnit], undefined, urlParams, undefined, 'servingConfig');
  const headers = await buildRequestHeadersWithAuthToken(requestUrl, userContext);

  try {
    const response = await fetch(requestUrl, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: headers,
    });

    if (!response.ok) {
      onNotificationChanged({
        messageBarType: MessageBarType.error,
        text: `Update ad unit failed: ${response.status} ${response.statusText}`,
      });
      updateLoadingStatus?.(false);
      Log.error(`Update ad unit failed: ${response.status} ${response.statusText}`);
      return Promise.reject();
    }

    const data: TythonAdUnit = await response.json();
    updateLoadingStatus?.(false);
    return Promise.resolve(data);
  } catch (e) {
    updateLoadingStatus?.(false);
    onNotificationChanged({ messageBarType: MessageBarType.error, textMessageDescriptor: messages.updateAdunitFailed });
    Log.error(e);
    return Promise.reject();
  }
};
