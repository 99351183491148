import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { IStepClassNames, IStepStyleProps } from './Step.types';
import { StepperDirection } from './Stepper.types';

export const  getStyles = (props: IStepStyleProps): IStepClassNames => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { fonts, spacing, palette } = theme;
  const weight = FontWeights;

  const circleAbstract = {
    marginTop: spacing.size6,
    width: spacing.size18,
    height: spacing.size18,
    borderRadius: '50%',
    lineHeight: spacing.size18,
    position: 'relative',
    textAlign: 'center',
    borderWidth: spacing.size2,
    borderStyle: 'solid',
  }

  const verticalStepStyles = {
    stepBlock: {
      display: 'flex',
      flexDirection: 'column',
      border: 0,
      padding: 0,
      background: 'transparent'
    },
    stepTitle: {
      fontSize: fonts.size10,
      cursor: 'pointer'
    },
    stepTitleSelected: {
      fontWeight: weight.semibold,
    },
    circleWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      gap: spacing.size10,
    },
    circleLabel: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },
    circle: {
      ...circleAbstract,
      color: 'transparent',
      backgroundColor: 'transparent',
      borderColor: palette.neutralLight,
    },
    circleSelected: {
      ...circleAbstract,
      color: palette.themeLight,
      backgroundColor: palette.themeLight,
      borderColor: palette.themeLight,
    },
    circleMarked: {
      ...circleAbstract,
      color: palette.white,
      backgroundColor: palette.themePrimary,
      borderColor: palette.themePrimary,
    },
    barStyle: {
      width: spacing.size2,
      height: '25px',
      margin: `${spacing.size8} auto`,
      backgroundColor: palette.neutralQuaternaryAlt,
    },
  }

  const horizontalStepStyles = {
    stepBlock: {
      marginTop: spacing.size24,
      border: 0,
      padding: 0,
      background: 'transparent'
    },
    stepTitle: {
      fontSize: fonts.size10,
      marginTop: spacing.size12,
      textAlign: 'center'
    },
    stepTitleSelected: {
      fontWeight: weight.semibold,
    },
    circleWrapper: {
      padding: '0px 115px',
      position: 'relative',
    },
    circleLabel: {},
    circle: {
      ...circleAbstract,
      color: palette.white,
      backgroundColor: palette.white,
      borderColor: palette.neutralLight,
    },
    circleSelected: {
      ...circleAbstract,
      color: palette.themeLight,
      backgroundColor: palette.themeLight,
      borderColor: palette.themeLight,
    },
    circleMarked: {
      ...circleAbstract,
      color: palette.white,
      backgroundColor: palette.themePrimary,
      borderColor: palette.themePrimary,
      fontSize: fonts.size10,
    },
    barStyle: {
      content: '',
      width: '80%',
      height: '2px',
      position: 'absolute',
      backgroundColor: palette.neutralQuaternaryAlt,
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: '150px',
    },
  };

  return mergeStyleSets(props.direction === StepperDirection.Horizontal ? horizontalStepStyles: verticalStepStyles );
};

export function getClassNames<PropType>(props?: PropType): IStepClassNames {
  return getStyles({ direction: StepperDirection.Horizontal, theme: getTheme(), ...props });
}