import { singularize } from '.';
import { Model } from '../@data';
import messages from '../App.messages';

export function getSuccessNotification(command: string, selectedItems: Model[], entityInstance: Model, ids?: string) {
  if (selectedItems.length === 1) {
    const selectedItem = selectedItems[0];
    const notificationSingularTextFn = (entityName, name, commandName, statusName, id) =>
      `${commandName}d! Your ${!!name ? name : ''} ${entityName} has been ${statusName}d. ${entityName} ID: ${id}`;

    return notificationSingularTextFn(
      singularize(entityInstance.className),
      selectedItem.name,
      command,
      command.toLocaleLowerCase(),
      selectedItem.id
    );
  } else {
    const notificationMultipleTextFn = (entityName, commandName, statusName, multipleIds) =>
      `${commandName}d! Selected ${entityName} have been ${statusName}d. ${entityName} IDs: ${multipleIds}`;
    if (ids) {
      return notificationMultipleTextFn(entityInstance.className, command, command.toLocaleLowerCase(), ids);
    }
  }
}

export function getSuccessNotificationDeleteItem(selectedItems: Model[], entityInstance: Model, formatMessage, ids?: string) {
  if (selectedItems.length === 1) {
    const selectedItem = selectedItems[0];

    // return 'Deleted! Your {name} ' + 'entityName' + ' has been deleted. ' + 'entityName' + ' ID: {id}';
    return (
      formatMessage(messages.deleteNotificationSingleItem, { name: !!selectedItem.name ? selectedItem.name : '' }) +
      formatMessage(messages[entityInstanceMapping[singularize(entityInstance.className)]]) +
      formatMessage(messages.deleteNotificationSingleItemCont) +
      formatMessage(messages[entityInstanceMapping[singularize(entityInstance.className)]]) +
      formatMessage(messages.listId, { id: !!selectedItem.id ? selectedItem.id : '' })
    );
  } else if (ids) {
    // return 'Deleted! Selected ' + 'entityNameS' + ' have been deleted. ' + 'entityNameS' + ' IDs: {ids}';
    return (
      formatMessage(messages.deleteNotificationPlural) +
      formatMessage(messages[entityInstanceMapping[entityInstance.className]]) +
      formatMessage(messages.deleteNotificationPluralCont) +
      formatMessage(messages[entityInstanceMapping[entityInstance.className]]) +
      formatMessage(messages.listId, { IDs: ids })
    );
  }
}

export function getSuccessNotificationDuplicateItem(selectedItems: Model[], entityInstance: Model, formatMessage, ids?: string) {
  if (selectedItems.length === 1) {
    const selectedItem = selectedItems[0];

    // return 'Duplicated! Your {name} ' + 'entityName' + ' has been duplicated. ' + 'entityName ' + 'ID: {id}';
    return (
      formatMessage(messages.duplicateNotificationSingleItem, { name: !!selectedItem.name ? selectedItem.name : '' }) +
      formatMessage(messages[entityInstanceMapping[singularize(entityInstance.className)]]) +
      formatMessage(messages.duplicateNotificationSingleItemCont) +
      formatMessage(messages[entityInstanceMapping[singularize(entityInstance.className)]]) +
      formatMessage(messages.listId, { id: !!selectedItem.id ? selectedItem.id : '' })
    );
  } else if (ids) {
    // return 'Duplicated! Selected ' + 'entityNameS' + ' have been duplicated. ' + 'entityNameS ' + 'IDs: {ids}';
    return (
      formatMessage(messages.duplicateNotificationPlural) +
      formatMessage(messages[entityInstanceMapping[entityInstance.className]]) +
      formatMessage(messages.duplicateNotificationPluralCont) +
      formatMessage(messages[entityInstanceMapping[entityInstance.className]]) +
      formatMessage(messages.listId, { IDs: ids })
    );
  }
}

export function getSuccessNotificationChangingStatus(
  commandName,
  selectedItems: Model[],
  entityInstance: Model,
  formatMessage,
  ids?: string
) {
  if (selectedItems.length === 1) {
    const selectedItem = selectedItems[0];

    // return 'Activated/Deactivated!' + ' Your {name} ' + 'entityName ' + 'has been ' + 'activated/deleted. ' + 'entityName' + ' ID: {id}';
    return (
      formatMessage(messages[changingStatusMapping[commandName]]) +
      formatMessage(messages.changingStatusItemName, { name: !!selectedItem.name ? selectedItem.name : '' }) +
      formatMessage(messages[entityInstanceMapping[singularize(entityInstance.className)]]) +
      formatMessage(messages.changingStatusSingular) +
      formatMessage(messages[changingStatusMapping[commandName.toLocaleLowerCase()]]) +
      formatMessage(messages[entityInstanceMapping[singularize(entityInstance.className)]]) +
      formatMessage(messages.listId, { id: !!selectedItem.id ? selectedItem.id : '' })
    );
  } else if (ids) {
    // return 'Activated/Deactivated!+ ' Selected ' + 'entityNameS ' + 'have been '+ 'activated/deleted. '+ 'entityNameS' + ' IDs: {ids}';
    return (
      formatMessage(messages[changingStatusMapping[commandName]]) +
      formatMessage(messages.changingStatusItemsSelection) +
      formatMessage(messages[entityInstanceMapping[entityInstance.className]]) +
      formatMessage(messages.changingStatusPlural) +
      formatMessage(messages[changingStatusMapping[commandName.toLocaleLowerCase()]]) +
      formatMessage(messages[entityInstanceMapping[singularize(entityInstance.className)]]) +
      formatMessage(messages.listId, { IDs: ids })
    );
  }
}

const entityInstanceMapping = {
  adunits: 'adUnits',
  properties: 'properties',
  users: 'users',
  accounts: 'accounts',
  channels: 'channels',
  filters: 'adFilters',
  publishers: 'publishers',
  adunit: 'adUnit',
  property: 'property',
  user: 'user',
  account: 'account',
  channel: 'channel',
  filter: 'adFilter',
  publisher: 'publisher',
};

const changingStatusMapping = {
  Deactivate: 'deactivatedUpperCase',
  Activate: 'activatedUpperCase',
  activate: 'activatedLowerCase',
  deactivate: 'deactivatedLowerCase',
};
