import { IDropdownOption, IIconStyleProps, IIconStyles, IRefObject, IStyle, IStyleFunctionOrObject, ITheme } from '@fluentui/react';
import { InjectedIntlProps } from 'react-intl';
import { Model } from '../../@data';
import { FilterCondition } from '../../@data/store/schema/enums/FilterCondition';
import { IFilterItem } from '../../layout/GridPageV2';

export interface IGridFilter {
  resetGridFilter(): void;
}

export interface IGridFilterProps extends React.HTMLAttributes<HTMLDivElement>, InjectedIntlProps {
  componentRef?: IRefObject<IGridFilter>;
  theme?: ITheme;
  styles?: IStyleFunctionOrObject<IGridFilterStyleProps, IGridFilterStyles>;
  filterItems: IFilterItem[];
  filterMenuCalloutOpen?: boolean;
  onFilterApplied: (filterList: IFilterDetails[]) => void;
  onFilterMenuCalloutClose: () => void;
  onClearAll?: () => void;
  onDeleteTextCondition?: (heading: string, filterCondition: FilterCondition, val: string) => void;
  onListValueChanged?: (heading: string, val: string) => void;
  showClearAll?: boolean;
  initialFilterListValues?: IFilterDetails[];
  hideCallout?: boolean;
}

export enum FilterType {
  custom = 'custom',
  string = 'string',
  relational = 'relational',
  date = 'date',
}

export interface IFilterDetails {
  filterId: string;
  filterName: string;
  filterType: FilterType;
  columnName?: string;
  filterOptions?: IDropdownOption[];
  filterCondition?: string;
  filterText?: string;
  startDate?: Date;
  endDate?: Date;
  customFunction?: (dataRow: Model, filterDetails: IFilterDetails) => boolean;
  includeNulls?: boolean;
  showNullsCheckboxLabel?: string;
}

export interface IGridFilterStyleProps {
  theme: ITheme;
}

export interface IGridFilterStyles {
  root: IStyle;
  filterBarFocused?: IStyle;
  filterBarUnFocused?: IStyle;
  appliedfilterList?: IStyle;
  filterInfoContainer?: IStyle;
  highlightedFilterInfoContainer?: IStyle;
  filterInfoFocused?: IStyle;
  filterInfoUnfocused?: IStyle;
  viewAllButton?: IStyle;
  clearAllButton?: IStyle;
  filterSummaryCallout?: IStyle;
  filterCloseIcon?: IStyle;
  calloutCloseIcon?: IStyle;
  filterMenuListItem?: IStyle;
  disabledFilterMenuListItem?: IStyle;
  filterSelectionCallout?: IStyle;
  filterSelectionCalloutTitle?: IStyle;
  filterSelectionToolBar?: IStyle;
  filterSelectionApplyButton?: IStyle;
  filterSelectionCancelButton?: IStyle;
  disabledApplyButton?: IStyle;
  filterSummaryCalloutHeader?: IStyle;
  filterIcon?: IStyle;
  filterInfoSelectors?: IStyle;
  highlightedFilterInfoSelectors?: IStyle;
  filterInfoSummaryItem?: IStyle;
  filterSummaryCalloutTitle?: IStyle;
  filterListPageStyle?: IStyle;
  subComponentStyles: {
    iconButton?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  };
}
