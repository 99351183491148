import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import { PopupType, UserInvitation, getAccessPermission } from '../../../../../../@data';
import { hasDeleteAccess } from '../../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../../App.messages';
import { IGridV2Column } from '../../../../../../layout/GridPageV2';
import { setDialogType } from '../../../../../../layout/GridPageV2/@data/mutatorActions';
import messages from '../../ManagePage.messages';

export function getGridColumns(formatMessage, isWindowRegularSize, longestEmailLength): IGridV2Column[] {
  const columns: IGridV2Column[] = [
    {
      columnOrder: 0,
      key: 'firstName',
      fieldName: 'firstName',
      name: formatMessage(messages.name),
      minWidth: 100,
      maxWidth: 200,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 1,
      key: 'email',
      fieldName: 'email',
      name: formatMessage(messages.emailAddress),
      minWidth: longestEmailLength,
      maxWidth: longestEmailLength + 10,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
    },
    {
      columnOrder: 2,
      key: 'expirationDate',
      fieldName: 'expirationDate',
      name: formatMessage(messages.dateExpires),
      minWidth: 200,
      maxWidth: 200,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
    },
    {
      columnOrder: 3,
      key: 'actions',
      name: formatMessage(AppMessages.actions),
      minWidth: 150,
      maxWidth: 200,
      isPadded: true,
      isSearchable: true,
      isVisible: true,
      suppressSorting: true,
      isResizable: true,
      onRender: (item: UserInvitation) => {
        const accessPermission = getAccessPermission(item);

        return (
          <CommandBarButton
            styles={{ root: { background: 'transparent' } }}
            iconProps={{ iconName: 'Delete' }}
            text={isWindowRegularSize ? formatMessage(AppMessages.delete) : ''}
            onClick={() => setDialogType(PopupType.DeleteConfirmation)}
            disabled={!hasDeleteAccess(accessPermission)}
          />
        );
      },
    },
  ];

  return columns;
}
