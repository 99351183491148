import { BreakPoints } from '../../../theme';
import { IFooterStyleProps, IFooterStyles } from './Footer.types';

export const getStyles = (props: IFooterStyleProps): IFooterStyles => {
  const { theme } = props;
  const { ResponsiveSmall } = BreakPoints;

  return {
    root: {
      display: 'block',
      backgroundColor: theme.palette['NeutralBackground4.Rest'],
      padding: '13px 15px',
      width: '-webkit-fill-available',
      minWidth: `-moz-available`,
      [ResponsiveSmall]: {
        padding: '0px 15px',
      },
    },
    footerLinks: {
      color: theme.palette.themeDarkAlt,
      fontSize: theme.fonts.smallPlus.fontSize,
      padding: '0 15px',
      lineHeight: '22px',
      cursor: 'pointer',
      textDecoration: 'none',
      [ResponsiveSmall]: {
        lineHeight: theme.fonts.small.fontSize,
        padding: '0px 8px',
      },
    },
    copyrightText: {
      fontSize: theme.fonts.smallPlus.fontSize,
      padding: '0 15px',
      lineHeight: '22px',
      [ResponsiveSmall]: {
        lineHeight: theme.fonts.small.fontSize,
        padding: '0px 8px',
      },
    },
  };
};
