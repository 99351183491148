import { InjectedIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { IAppState } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { IAdPreviewConfig } from '../../TythonAdunit/components/AdUnitTypeSelector/PreviewComponent';
import { AppAdUnitPreviewLayout } from '../components/types';
import messages from '../messages';

export enum AppAdUnitType {
  Display = 'Display',
  Video = 'Video',
  Native = 'Native',
}

export const AppAdUnitBannerSize = {
  '320 x 50': {
    value: '320 x 50',
    width: 320,
    height: 50,
    layout: AppAdUnitPreviewLayout.Horizontal,
  },
  '300 x 250': {
    value: '300 x 250',
    width: 300,
    height: 250,
    layout: AppAdUnitPreviewLayout.Horizontal,
  },
  '300 x 50': {
    value: '300 x 50',
    width: 300,
    height: 50,
    layout: AppAdUnitPreviewLayout.Horizontal,
  },
  '728 x 90': {
    value: '728 x 90',
    width: 728,
    height: 90,
    layout: AppAdUnitPreviewLayout.Horizontal,
  },
};

export type VideoPlayMethod = 'auto_sound_on' | 'auto_sound_off' | 'click_to_play' | 'mouse_off' | 'auto_play' | 'sound_unknown';

type AppAdUnitVideoPlayMethodItem = {
  label: ReactIntl.FormattedMessage.MessageDescriptor;
  value: VideoPlayMethod;
};

export const appAdUnitVideoPlayMethod: AppAdUnitVideoPlayMethodItem[] = [
  {
    label: messages.appAdUnitVideoPlayMethodAutoSoundOn,
    value: 'auto_sound_on',
  },
  {
    label: messages.appAdUnitVideoPlayMethodAutoSoundOff,
    value: 'auto_sound_off',
  },
  {
    label: messages.appAdUnitVideoPlayMethodClickToPlay,
    value: 'click_to_play',
  },
  {
    label: messages.appAdUnitVideoPlayMethodMouseOff,
    value: 'mouse_off',
  },
  {
    label: messages.appAdUnitVideoPlayMethodAutoplay,
    value: 'auto_play',
  },
  {
    label: messages.appAdUnitVideoPlayMethodSoundUnknown,
    value: 'sound_unknown',
  },
];

export type SelectedAppAdUnitBannerSize = keyof typeof AppAdUnitBannerSize;
export interface ICreateTythonAppAdunitProps {
  mode: FormState;
  routeProps: RouteComponentProps;
}

export type AppAdUnitPrimaryFormProps = {
  adUnitName: string | undefined;
  onAdUnitNameChange: (value: string) => void;
  adUnitApplicationId: string | undefined;
  onAdUnitApplicationIdChange: (value: string) => void;
  adUnitType: AppAdUnitType | undefined;
  onAdUnitTypeChange: (value: AppAdUnitType) => void;
  shouldRenderPrimaryForm: boolean;
  validationError: { addUnitName?: string };
  applicationOptions: { key: number; label: string; secondaryLabel: string }[];
  selectedBannerAdUnitAdSize: SelectedAppAdUnitBannerSize;
  onBannerAdUnitAdSizeChange: (value: SelectedAppAdUnitBannerSize) => void;
  bannerAdSizeOptions: { key: string; value: string }[];
  videoAdUnitSkipAllowed: boolean | undefined;
  onVideoAdUnitSkipAllowedChange: (value: boolean) => void;
  videoAdUnitDuration: string | undefined;
  onVideoAdUnitDurationChange: (value?: string) => void;
  nativeAdUnitConfig: IAdPreviewConfig;
  onNativeAdUnitConfigChange: (config: IAdPreviewConfig) => void;
  videoAdUnitPlayMethod: VideoPlayMethod;
  onVideoAdUnitPlayMethodChange: (method: VideoPlayMethod) => void;
  isAdUnitLoading: boolean;
  isEditMode: boolean;
};

export type AppAdUnitTypeFormProps = {
  shouldRenderAdTypeForm: boolean;
  adUnitType?: AppAdUnitType;
  bannerAdSizeOptions: { key: string; value: string }[];
  selectedBannerAdUnitAdSize: SelectedAppAdUnitBannerSize;
  onBannerAdUnitAdSizeChange: (value: SelectedAppAdUnitBannerSize) => void;
};

export type AppAdUnitDetailPageProps = {
  saving: boolean;
  loading: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
  submitButtonDisabled: boolean;
  submitLabel: string;
  titleLabel: string;
  cancelLabel: string;
  hasFormTitle: boolean;
  hideNavButton: boolean;
  pageMode: FormState;
  helpId?: string;
  overlayHeight?: string;
};

export type UseCreateTythonAppAdUnitProps = {
  appStore: IAppState;
  formatMessage: InjectedIntl['formatMessage'];
  routeProps: RouteComponentProps;
} & ICreateTythonAppAdunitProps;

export type AppAdUnitResultSummaryProps = {
  shouldRenderResultSummary: boolean;
  adUnitId: string;
};

export type UseCreateTythonAppAdUnitType = (
  props: UseCreateTythonAppAdUnitProps
) => {
  primaryFormProps: AppAdUnitPrimaryFormProps;
  detailPageProps: AppAdUnitDetailPageProps;
  resultSummaryProps: AppAdUnitResultSummaryProps;
};
