import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { DEFAULT_SELECTED_ACCOUNT_TYPE } from '..';
import { getAppStore, onNotificationChanged, onUserFirstTime } from '../../../../@data';
import { CustomerHelp } from '../../../../@data/store/schema/enums/CustomerHelp';
import { DEFAULT_CURRENCY_CODE, EN_LOCALE_KEY, UNAUTHORIZED_LOCAL_STORAGE_LOCALE } from '../../../../constants';
import messages from '../../SignUpPage.messages';
import { onAccountChanged, onFormInitialized } from '../actions';
import { ISignUpViewModel } from '../store/schema';

const DEFAULT_COUNTRY = 'US';

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();
  const { name, firstName, lastName } = userContext || {};

  try {
    const account: ISignUpViewModel = {};
    account.availableCountries = [DEFAULT_COUNTRY, 'FR', 'DE'];
    account.countryCode = DEFAULT_COUNTRY;
    account.customerHelp = CustomerHelp.Default;
    account.userFirstName = firstName ?? (name ? name.split(' ')[0] : undefined);
    account.userLastName = lastName ?? (name ? name.split(' ')[1] : undefined);
    account.preferredLanguageCode = localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE) ?? EN_LOCALE_KEY;
    account.currencyCode = DEFAULT_CURRENCY_CODE;
    account.accountType = DEFAULT_SELECTED_ACCOUNT_TYPE;

    onUserFirstTime(true);
    onAccountChanged(account);
  } catch (e) {
    onNotificationChanged({
      textMessageDescriptor: messages.signupFailedToLoadEntity,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
