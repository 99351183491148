import { Model } from '../../../@data';
import { FilterCondition } from '../../../@data/store/schema/enums/FilterCondition';
import { FilterType, IFilterDetails } from '../../../components/GridFilter/GridFilter.types';
import { ISelectableRow } from '../GridV2.types';

export const applyFilters = (
  // tslint:disable-next-line: no-any
  items: any[],
  filterConditions: IFilterDetails[],
  setAppliedFilters,
  // tslint:disable-next-line: no-any
  customApplyFilterFn?: (filterDetails: IFilterDetails[], items: any[]) => any[],
  shouldApplyFilter = true
): ISelectableRow[] => {
  let filteredItems: ISelectableRow[] = items;

  if (!customApplyFilterFn) {
    filterConditions.forEach((filterCol) => {
      if (filterCol.customFunction) {
        filteredItems = filteredItems.filter((dataRow: Model) =>
          filterCol.customFunction ? filterCol.customFunction(dataRow, filterCol) : false
        );
      } else {
        filteredItems = applyFilter(filteredItems, filterCol);
      }
    });
  }

  if (customApplyFilterFn && shouldApplyFilter) {
    filteredItems = customApplyFilterFn(filterConditions, items);
  }

  setAppliedFilters(filterConditions);
  return filteredItems;
};

// tslint:disable-next-line: no-any
function applyFilter(items: any[], filterColumn: IFilterDetails): ISelectableRow[] {
  if (filterColumn.columnName !== undefined) {
    const filteredItems = items.filter((item: object) => {
      const val = item[filterColumn.columnName || ''];

      switch (filterColumn.filterType) {
        case FilterType.custom:
          return filterColumn.filterCondition ? val === filterColumn.filterCondition : true;

        case FilterType.date: {
          const dateVal = new Date(val);
          const { startDate, endDate } = filterColumn;

          if (startDate || endDate) {
            if (startDate && endDate) {
              return dateVal >= startDate && dateVal <= endDate;
            }
            if (endDate) {
              return dateVal <= endDate;
            }
            if (startDate) {
              return dateVal >= startDate;
            }
          }
          return true;
        }

        case FilterType.relational: {
          const numVal = Number(val);

          if (filterColumn.filterText) {
            const filterNumVal = Number(filterColumn.filterText);
            switch (filterColumn.filterCondition) {
              case FilterCondition.Equals:
                return numVal === filterNumVal;
              case FilterCondition.DoesNotEqual:
                return numVal !== filterNumVal;
              case FilterCondition.GreaterThan:
                return numVal > filterNumVal;
              case FilterCondition.GreaterThanEqual:
                return numVal >= filterNumVal;
              case FilterCondition.LessThan:
                return numVal < filterNumVal;
              case FilterCondition.LessThanEqual:
                return numVal <= filterNumVal;
            }
          }
          return true;
        }

        case FilterType.string: {
          const filterVal = filterColumn.filterText!.toLocaleLowerCase();
          const strVal = String(val).toLocaleLowerCase();
          switch (filterColumn.filterCondition) {
            case FilterCondition.Contains:
              return strVal.indexOf(filterVal) !== -1;
            case FilterCondition.DoesNotContain:
              return strVal.indexOf(filterVal) === -1;
            case FilterCondition.BeginsWith:
              return strVal.indexOf(filterVal) === 0;
            case FilterCondition.EndsWith: {
              const lastIndex = strVal.lastIndexOf(filterVal);
              return lastIndex === strVal.length - filterVal.length;
            }
            case FilterCondition.Equals:
              return strVal === filterVal;
            case FilterCondition.DoesNotEqual:
              return strVal !== filterVal;
          }
        }
      }
      return val.toLocaleLowerCase().indexOf(filterColumn.filterText!.toLocaleLowerCase()) !== -1;
    });

    return filteredItems;
  }
  // in case column name is not passed, filter cannot be applied.
  return items;
}
