import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { UserContext, getAppStore, onUserContextChanged } from '../../../../@data';
import { checkInternalUserAccess } from '../../../../@data/services/AuthorizationService';
import * as pubCenterAADAuthService from '../../../../@data/services/PubCenterAADAuthService';
import { setUserRoles } from '../../../../@data/services/UserRoleService';
import {
  corpTenantId,
  silentTokenRequest as pubSilentTokenRequest,
  authTokenRequest as pubTokenRequest,
} from '../../../../config/pubCenterAAD.config';
import { decodeToken } from '../../../../utils/JwtDecoderUtils';
import { onAuthPageInitialized, onLoadingSet } from '../actions';

orchestrator(onAuthPageInitialized, async ({ loginHint }) => {
  const { userContext, isInviteUserFlow } = getAppStore();
  if (userContext || window.location.hash || loginHint) {
    onLoadingSet(true);
    let authResponse = await pubCenterAADAuthService.handleLoginRedirectAsync();

    // Need the second check because we need to make sure accessToken is for PubCenter endpoint
    if (!authResponse || !authResponse.scopes.includes(pubSilentTokenRequest.scopes[0])) {
      Log.write('No auth response from redirection, trying to acquire token silently');
      authResponse = await pubCenterAADAuthService.acquireAuthResponseAsync(pubTokenRequest, loginHint);
    }

    if (authResponse && authResponse.scopes.includes(pubSilentTokenRequest.scopes[0])) {
      const idToken = authResponse.idToken;
      const userInfo = decodeToken(idToken);

      // Set user context
      const newUserContext = new UserContext();
      newUserContext.userName = authResponse.account ? authResponse.account.username : undefined;
      newUserContext.email = userInfo.email ?? userInfo.preferred_username;
      newUserContext.token = authResponse.accessToken;
      newUserContext.name = userInfo.name;
      newUserContext.firstName = userInfo.given_name;
      newUserContext.lastName = userInfo.family_name;

      onUserContextChanged(newUserContext);

      if (!(userInfo.tid !== corpTenantId && isInviteUserFlow)) {
        await setUserRoles(newUserContext);
        checkInternalUserAccess(newUserContext.userRoles);
      } else {
        Log.write('Skipping scope call for user invitation flow');
      }
    }
  }
  onLoadingSet(false);
});
