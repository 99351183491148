import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { getAppStore, showErrorToastNotification } from '../../../../../@data';
import messages from '../../Manage/ManagePage.messages';
import { updateAdUnitMediationStatus } from '../actions';
import { updateAdunitMediationStatus } from '../services/AdunitService';

orchestrator(updateAdUnitMediationStatus, async ({ adUnits, state }) => {
  const { publisher, account } = getAppStore();

  if (publisher && account) {
    try {
      await updateAdunitMediationStatus(publisher, account, adUnits, state);
    } catch (error) {
      showErrorToastNotification({ textMessageDescriptor: messages.adunitMediationUpdateStatusFailed });
      Log.error(`Error while updating adUnits' mediation status: ${error}`);
    }
  }
});
