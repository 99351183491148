import { mutatorAction } from 'satcheljs';
import { getAppStore } from '../../../../../@data';
import { getStore } from '../store/store';

export const onDialogShown = mutatorAction('ON_DIALOG_SHOWN', function showDialog() {
  const store = getStore();
  store.active.showDialog = true;
});

export const onDialogDismissed = mutatorAction('ON_DIALOG_DISMISSED', function dismissDialog() {
  const store = getStore();
  store.active.showDialog = false;
  const appStore = getAppStore();
  appStore.isFirstTimeUser = false;
});
