import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing, IPubcenterTheme } from '../../../theme';
import { flexWithCenter } from '../../utils/GlobalStyleUtils';
import { IStepperStyleProps, IStepperStyles, StepperDirection } from './Stepper.types';

export const getStyles = (props: IStepperStyleProps): IStepperStyles => {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing as IPubcenterSpacing;

  const verticalStepStyles = {
    stepWrapper: {
      display: 'flex',
      flexDirection: 'column'
    },
  }

  const horizontalStepStyles = {
    stepWrapper: {
      ...flexWithCenter,
      marginBottom: spacing.size20,
      marginLeft: spacing.size12,
    },
  }

  return mergeStyleSets(props.direction === StepperDirection.Horizontal ? horizontalStepStyles : verticalStepStyles);
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ direction: StepperDirection.Horizontal, ...props });
}
