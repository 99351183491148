import { ISubGeography } from '../..';
import { getMetaDataStore } from './store';

export function getStatesOfCountry(country: string): ISubGeography[] {
  const metadataStore = getMetaDataStore();
  return metadataStore.raw?.states?.[country] || [];
}

export function isStatesPresentForCountry(country: string): boolean {
  return getStatesOfCountry(country).length > 0;
}

export function getStatesDropdownOptions(country: string): { key: string; text: string }[] {
  return getStatesOfCountry(country).map((state) => ({ key: state.code, text: state.name }));
}
