import { nullRender } from '@fluentui/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  Account,
  getAppStore,
  onAccountChanged,
  onDefaultPublisherSet,
  onPublisherAccountChanged,
  onPublisherChanged,
  Publisher,
} from '../../@data';
import '../../@data/orchestrators';
import { isTythonSelfServeUser } from '../../@data/utils/checkUserType';
import { DEFAULT_PUBLISHER_CACHE_KEY } from '../../constants/AppConstants';
import { PublisherAccountPicker } from '../../layout/PublisherAccountPicker/PublisherAccountPicker';
import { defaultRoute, getAdManagementStore, RouteName } from './@data';
import { IAdManagementHeaderProps } from './AdManagement.types';

export const AdManagementHeader = observer(
  class AdManagementHeaderComponent extends React.Component<IAdManagementHeaderProps> {
    public componentDidMount() {
      if (!getAppStore().defaultPublisher && localStorage.getItem(DEFAULT_PUBLISHER_CACHE_KEY)) {
        onDefaultPublisherSet(Number(localStorage.getItem(DEFAULT_PUBLISHER_CACHE_KEY)));
      }
      const defaultPublisherId = getAppStore().defaultPublisher;
      const publisherId = [3000, 3001, 3002, 3003, 3004, 0].includes(defaultPublisherId === undefined ? 0 : defaultPublisherId)
        ? (this.props.match.params.publisherId as string)
        : String(defaultPublisherId);
      const accountId = this.props.match.params.accountId as string;

      onPublisherAccountChanged(publisherId, accountId, this.props.history);
    }

    public render(): JSX.Element | null {
      const appStore = getAppStore();
      const { accessiblePublishers, defaultPublisher } = appStore;
      const { doNotRenderDefaultPage } = getAdManagementStore();

      /** We don't want to render Publisher account picker for tython flows.
       * Rendering AdmanagementHeader on the page helps to set the publisher/account context properly,
       * so that entity grids can be loaded and all actions are allowed based on user permissions.
       * Currently Tython flows and all the create/edit forms depend on the respective header components to set required context.
       * Ideally we should not depend on the side-effect of the component, so we can consider separating out the logic
       * in the componentDidMount and render AdManagementHeader whenever PublisherAccountPicker is required.
       *
       * If publisher id in appStore is not equal to defaultPublisher, it might lead to user unauthorized error
       */
      const publisherId = defaultPublisher && [3000, 3001, 3002, 3003, 3004, 0].includes(defaultPublisher) ? undefined : defaultPublisher;
      if (doNotRenderDefaultPage || (publisherId && appStore.publisher?.id !== publisherId) || isTythonSelfServeUser()) {
        return nullRender();
      }

      return (
        <PublisherAccountPicker
          {...this.props}
          onChange={this._onPublisherAccountChanged}
          publisher={appStore.publisher}
          account={appStore.account}
          isAccountHidden={this.props.isAccountHidden}
          isPublisherSelectionDisabled={accessiblePublishers && accessiblePublishers.length === 1}
        />
      );
    }

    private _onPublisherAccountChanged = (publisher?: Publisher, account?: Account) => {
      const store = getAdManagementStore();
      const currentRoute = RouteName[store.currentRoute || defaultRoute];

      if (publisher && account) {
        if (
          currentRoute === RouteName[RouteName.property] ||
          currentRoute === RouteName[RouteName.adquality] ||
          currentRoute === RouteName[RouteName.auditHistory] ||
          (currentRoute === RouteName[RouteName.adunit] && account.id === 0)
        ) {
          this.props.history.push(`/ad-management/${publisher.id}/${currentRoute}`);
        } else {
          this.props.history.push(`/ad-management/${publisher.id}-${account.id}/${currentRoute}`);
        }
      } else if (publisher) {
        this.props.history.push(`/ad-management/${publisher.id}/${currentRoute}`);
      } else {
        this.props.history.push(`/ad-management/${currentRoute}`);
      }

      onPublisherChanged(publisher);
      onAccountChanged(account);
    };
  }
);
