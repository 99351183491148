import { defineMessages } from "react-intl";

export default defineMessages({
  datePlaceholder: {
    id: 'dateTimePicker.datePlaceholder',
    defaultMessage: 'Select date'
  },
  label: {
    id: 'dateTimePicker.label',
    defaultMessage: 'Date time picker'
  },
  goToToday: {
    id: 'dateTimePicker.goToToday',
    defaultMessage: 'Go to today',
  },
  prevMonthAriaLabel: {
    id: 'dateTimePicker.prevMonthAriaLabel',
    defaultMessage: 'Go to previous month',
  },
  nextMonthAriaLabel: {
    id: 'dateTimePicker.nextMonthAriaLabel',
    defaultMessage: 'Go to next month',
  },
  prevYearAriaLabel: {
    id: 'dateTimePicker.prevYearAriaLabel',
    defaultMessage: 'Go to previous year',
  },
  nextYearAriaLabel: {
    id: 'dateTimePicker.nextYearAriaLabel',
    defaultMessage: 'Go to next year',
  },
  closeButtonAriaLabel: {
    id: 'dateTimePicker.closeButtonAriaLabel',
    defaultMessage: 'Close date picker',
  },
  isRequiredErrorMessage: {
    id: 'dateTimePicker.isRequiredErrorMessage',
    defaultMessage: 'Field is required.',
  },
  invalidDateErrorMessage: {
    id: 'dateTimePicker.invalidInputErrorMessage',
    defaultMessage: 'Invalid date format.',
  },
  invalidMinuteErrorMessage: {
    id: 'dateTimePicker.invalidMinuteErrorMessage',
    defaultMessage: 'Invalid minute value.',
  },
  invalidSecondsErrorMessage: {
    id: 'dateTimePicker.invalidSecondsErrorMessage',
    defaultMessage: 'Invalid second value.',
  },
});