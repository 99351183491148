import {
  CommandBarButton,
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IContextualMenuItem,
  IProcessedStyleSet,
  Link,
  Shimmer,
  getTheme,
} from '@fluentui/react';
import React from 'react';
import {
  AdUnit,
  LifeCycleStatus,
  PopupType,
  ServingStatus,
  ServingStatusLabel,
  getAccessPermission,
  getAppStore,
  isTythonSelfServeUser,
} from '../../../../../@data';
import { hasDeleteAccess, hasUpdateAccess } from '../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../App.messages';
import { FormState } from '../../../../../components/Form';
import { IGridV2Column } from '../../../../../layout/GridPageV2';
import { setDialogType } from '../../../../../layout/GridPageV2/@data/mutatorActions';
import { HeaderInfoCallout } from '../../../../../layout/GridPageV2/components/HeaderInfoCallout';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/services/AdManagementService';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import adManagementMessages from '../../../AdManagement.messages';
import { getGridPropertyStore } from '../../../Property/Manage/__data__/store/store';
import { getAdunitStore, isPartnerCodeEnabled } from '../../@data';
import { onPanelOpenChanged } from '../../@data/actions';
import messages from '../ManagePage.messages';
import { getClassNames, getStyles } from '../ManagePage.styles';
import { IManagePageStyles } from '../ManagePage.types';

let FormatMessage;
let Styles: IProcessedStyleSet<IManagePageStyles>;
let History;

const editRouter = (item: AdUnit, step?: number, showExtraBtns?: boolean) => {
  const { publisher, account } = getAppStore();
  const returnUrl = createReturnUrl(RouteName.adunit, publisher!.id, account!.id, item.id, FormState.MultiStepEdit, step, showExtraBtns);
  History.push(returnUrl);
};

const getCallOutContent = (item: AdUnit): JSX.Element => {
  const isMediated = item?.mediatedWithAdSense?.mediationStatus;
  const header = isMediated ? messages.mediationCardConnectedHeader : messages.mediationCardNoConnectionHeader;
  const description = isMediated ? messages.mediationCardConnectedDesc : messages.mediationCardNoConnectionDesc;
  const callToActionLabel = isMediated ? messages.mediationCardConnectedC2A : messages.mediationCardNoConnectionC2A;

  return (
    <>
      <h3 className={Styles.title}>{FormatMessage(header)}</h3>
      <p>{FormatMessage(description)}</p>
      <Link onClick={() => editRouter(item, 0, true)}>{FormatMessage(callToActionLabel)}</Link>
    </>
  );
};

const getServingStatusLabel = (servingStatus: ServingStatus, formatMessage) => {
  const servingStatusType = Object.keys(ServingStatus)[Object.values(ServingStatus).indexOf(servingStatus)];
  return servingStatusType ? formatMessage(adManagementMessages[ServingStatusLabel[servingStatusType]]) : '';
};

export function getGridColumns(
  formatMessage,
  routeHistory,
  isWindowRegularSize,
  setAdUnitCallout,
  isFetchingAdUnitStatus,
  isFetchingPropertyStatus,
  propertyServingConfigs
): IGridV2Column[] {
  FormatMessage = formatMessage;
  History = routeHistory;

  const { publisher, account } = getAppStore();
  const classNames = getClassNames(getStyles, { theme: getTheme() });
  const isAdSenseConnected = getGoogleAdSenseStore().active.isMediationEnabled;
  Styles = classNames;

  const commonColumns: IGridV2Column[] = [
    {
      columnOrder: 2,
      key: 'name',
      fieldName: 'name',
      name: formatMessage(messages.name),
      minWidth: 100,
      maxWidth: 125,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 3,
      key: 'id',
      fieldName: 'id',
      name: formatMessage(messages.id),
      minWidth: 100,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
    },
    {
      columnOrder: 4,
      key: 'status',
      name: formatMessage(messages.servingStatus),
      minWidth: 100,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      onRender: (item: AdUnit) => {
        if (isTythonSelfServeUser()) {
          if (item.status === LifeCycleStatus.Inactive) {
            return getServingStatusLabel(ServingStatus.NotServing, formatMessage);
          }

          const servingStatus = getGridPropertyStore()?.servingConfigs?.find((config) => item.propertyId === config.propertyId)
            ?.servingStatus;

          return getGridPropertyStore().isFetchingPropertyStatus && servingStatus === undefined ? (
            <Shimmer styles={{ root: { display: 'flex', alignItems: 'center', height: '100%' }, shimmerWrapper: { height: '7px' } }} />
          ) : (
            getServingStatusLabel(ServingStatus[servingStatus!], formatMessage)
          );
        } else {
          return item.status;
        }
      },
    },
    {
      columnOrder: 5,
      key: 'propertyUrl',
      fieldName: 'propertyUrl',
      name: formatMessage(messages.propertyUrl),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
    },
    {
      columnOrder: 7,
      key: 'creationDate',
      fieldName: 'creationDate',
      name: formatMessage(messages.adUnitCreationDate),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
    },
    {
      columnOrder: 10,
      key: 'actions',
      name: formatMessage(messages.actions),
      minWidth: 220,
      maxWidth: 220,
      isPadded: true,
      isSearchable: true,
      isVisible: true,
      suppressSorting: true,
      onRender: (item: AdUnit) => {
        const accessPermission = getAccessPermission(item);

        const nonTythonEditAdUnitOptions: IContextualMenuItem[] = [
          {
            key: 'name',
            text: formatMessage(messages.changeName),
            onClick: () => setAdUnitCallout(item.id),
          },
        ];

        const editAdUnitOptions = isTythonSelfServeUser() ? undefined : { items: nonTythonEditAdUnitOptions };

        const editButtonOnClick = isTythonSelfServeUser()
          ? () => History.push(createReturnUrl(RouteName.adunit, publisher?.id, account?.id, item.id, FormState.Edit))
          : undefined;

        return (
          <div className={classNames.actionButtonsContainer}>
            <CommandBarButton
              id={`adunitCallout-${item.id}`}
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'edit' }}
              ariaLabel={formatMessage(AppMessages.edit)}
              text={isWindowRegularSize ? formatMessage(AppMessages.edit) : ''}
              menuProps={editAdUnitOptions}
              disabled={!hasUpdateAccess(accessPermission) || getAdunitStore().mediationUpdatePool.includes(item.id)}
              onClick={editButtonOnClick}
            />
            {isTythonSelfServeUser() && item.propertyId !== undefined && (
              <CommandBarButton
                styles={{ root: { background: 'transparent' } }}
                iconProps={{ iconName: 'PasteAsCode' }}
                ariaLabel={formatMessage(AppMessages.code)}
                text={isWindowRegularSize ? formatMessage(AppMessages.code) : ''}
                onClick={() => onPanelOpenChanged(true, item.propertyId, item.id)}
              />
            )}
            <CommandBarButton
              data-test-id={`grid-delete-action-${item.id}`}
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'Delete' }}
              text={isWindowRegularSize ? formatMessage(AppMessages.delete) : ''}
              onClick={() => setDialogType(PopupType.DeleteConfirmation)}
              disabled={!hasDeleteAccess(accessPermission) || getAdunitStore().mediationUpdatePool.includes(item.id)}
            />
          </div>
        );
      },
    },
  ];

  const tythonColumns: IGridV2Column[] = [
    {
      columnOrder: 9,
      key: 'monetization',
      fieldName: 'monetization',
      name: formatMessage(messages.higherMonetization),
      minWidth: 175,
      maxWidth: 175,
      suppressSorting: true,
      isSearchable: true,
      isVisible: isAdSenseConnected,
      isShowingDisabled: !isAdSenseConnected,
      onRenderHeader: HeaderInfoCallout({
        calloutContent: (
          <div>
            <span className={classNames.title}>{formatMessage(messages.mediationCardNoConnectionHeader)}</span>
            <p>{formatMessage(messages.mediationCardNoConnectionDesc)}</p>
          </div>
        ),
      }),
      onRender: (item: AdUnit) => {
        return getAdunitStore().isFetchingAdUnitStatus && item.mediatedWithAdSense === undefined ? (
          <Shimmer styles={{ root: { display: 'flex', alignItems: 'center', height: '100%' }, shimmerWrapper: { height: '7px' } }} />
        ) : (
          <HoverCard
            style={{
              width: 360,
              padding: '0px 15px 20px 15px',
            }}
            plainCardProps={{
              directionalHint: DirectionalHint.leftTopEdge,
              calloutProps: {
                isBeakVisible: true,
              },
              onRenderPlainCard: () => getCallOutContent(item),
            }}
            instantOpenOnClick
            type={HoverCardType.plain}
            hidden={getAdunitStore().mediationUpdatePool.includes(item.id)}
            trapFocus
            setInitialFocus
            tabIndex={0}
            className={classNames.monetizationUnderline}
          >
            <div className={classNames.monetizationUnderline} tabIndex={0}>
              {getAdunitStore().mediationUpdatePool.includes(item.id)
                ? formatMessage(messages.pending)
                : item?.mediatedWithAdSense?.mediationStatus
                ? formatMessage(messages.higherMonetizationEnabled)
                : formatMessage(messages.higherMonetizationNotSetup)}
            </div>
          </HoverCard>
        );
      },
    },
  ];

  const nonTythonColumns: IGridV2Column[] = [
    {
      columnOrder: 0,
      key: 'accountName',
      fieldName: 'accountName',
      name: formatMessage(messages.accountName),
      minWidth: 100,
      maxWidth: 100,
      isVisible: false,
      isExportable: true,
      isResizable: true,
      isSearchable: true,
    },
    {
      columnOrder: 1,
      key: 'accountId',
      fieldName: 'accountId',
      name: formatMessage(messages.accountId),
      minWidth: 100,
      maxWidth: 100,
      isVisible: false,
      isExportable: true,
      isResizable: true,
      isSearchable: true,
    },
    {
      columnOrder: 6,
      key: 'propertyId',
      fieldName: 'propertyId',
      name: formatMessage(messages.propertyId),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: false,
      isExportable: true,
    },
    {
      columnOrder: 4.5,
      key: 'partnerCodes',
      fieldName: 'partnerCodes',
      name: formatMessage(AppMessages.partnerCodes),
      minWidth: 100,
      maxWidth: 150,
      isVisible: isPartnerCodeEnabled(publisher?.id.toString()),
      isExportable: true,
      isResizable: true,
      isSearchable: true,
      onRender: (item: AdUnit) => {
        if (Array.isArray(item.partnerCodes)) {
          return item.partnerCodes.join(', ');
        } else {
          return item.partnerCodes;
        }
      },
    },
    {
      columnOrder: 8,
      key: 'taxonomy',
      fieldName: 'taxonomy',
      name: formatMessage(messages.businessModel),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: false,
      isExportable: true,
    },
  ];

  return [...commonColumns, ...(isTythonSelfServeUser() ? tythonColumns : nonTythonColumns)];
}
