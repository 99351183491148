import { mixed, object, string } from 'yup';
import { UserRole } from '../../../../../../@data';
import { IUserInviteViewModel } from './UserInviteViewModel';

export function UserInviteErrorModel() {
  return object<IUserInviteViewModel>().shape({
    firstName: string().trim().required(),
    lastName: string().trim().required(),
    email: string().email().required(),
    recipientPreferredLang: string().trim().required(),
    userRole: mixed()
      .oneOf([
        UserRole.AccountManager,
        UserRole.AdView,
        UserRole.ListManager,
        UserRole.PublisherAdmin,
        UserRole.ReportUser,
        UserRole.SuperAdmin,
      ])
      .required(),
  });
}
