import { orchestrator } from 'satcheljs';
import { Log } from '../../../logging/src';
import { uiConfig } from '../../config/ui.config';
import { onDoNotRenderDefaultPageChanged } from '../../pages/ad-management/@data/actions';
import { setUserPublisherContext } from '../../pages/report/@data/services/ReportService';
import { onAuthLogout, redirectToReportingDashboard } from '../actions';
import { getAppStore } from '../store/store';
import { isTythonSelfServeUser } from '../utils';

orchestrator(redirectToReportingDashboard, async () => {
  const { publisher, userContext, defaultPublisher } = getAppStore();

  if (userContext && publisher && publisher.id && publisher.id === defaultPublisher && !isTythonSelfServeUser()) {
    onDoNotRenderDefaultPageChanged(true);
    try {
      const commonSessionId = await setUserPublisherContext(userContext, publisher.id);
      window.location.replace(uiConfig.getReportingDashboardAddress(commonSessionId));
    } catch (e) {
      Log.write(`Recognized non-Tython MSA user, but failed to redirect to reporting dashboard.`);
      onAuthLogout();
    }
  }
});
