import {
  IComboBoxStyles,
  IDatePickerStrings,
  IDatePickerStyleProps,
  IDatePickerStyles,
  IRefObject,
  IStyle,
  IStyleFunctionOrObject,
  ITheme,
} from '@fluentui/react';
import { InjectedIntlProps } from 'react-intl';
import { DateTimePicker } from './DateTimePicker';
import messages from './DateTimePicker.messages';

export interface IDateTimePickerProps extends React.HTMLAttributes<HTMLDivElement>, InjectedIntlProps {
  onDateTimeChanged: (value: Date) => void;
  componentRef?: IRefObject<DateTimePicker>;
  label?: string;
  value?: Date;
  disabled?: boolean;
  datePlaceholder?: string;
  hourPlaceholder?: string;
  timePlaceholder?: string;
  datePickerWidth?: number;
  minDateTime?: Date;
  maxDateTime?: Date;
  allowSecondsField?: boolean;
  styles?: Partial<IDateTimePickerStyles>;
  /**
   * Error message to render for TextField if date boundary (minDate, maxDate) validation fails.
   */
  isOutOfBoundsErrorMessage?: string;
}

export interface IDateTimePickerStyleProps {
  theme: ITheme;
  datePickerWidth?: number;
}

export interface IDateTimePickerStyles {
  root?: IStyle;
  colonSeperator?: IStyle;
  datePicker?: IStyle;
  hourPicker?: Partial<IComboBoxStyles>;
  minutesPicker?: Partial<IComboBoxStyles>;
  secondsPicker?: IStyle;
  subComponentStyles: {
    datePicker?: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles>;
  };
}

export const DayPickerStrings: IDatePickerStrings = {
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: messages.goToToday.defaultMessage!,
  prevMonthAriaLabel: messages.prevMonthAriaLabel.defaultMessage!,
  nextMonthAriaLabel: messages.nextMonthAriaLabel.defaultMessage!,
  prevYearAriaLabel: messages.prevYearAriaLabel.defaultMessage!,
  nextYearAriaLabel: messages.nextYearAriaLabel.defaultMessage!,
  closeButtonAriaLabel: messages.closeButtonAriaLabel.defaultMessage!,
  isRequiredErrorMessage: messages.isRequiredErrorMessage.defaultMessage!,
  invalidInputErrorMessage: messages.invalidDateErrorMessage.defaultMessage!,
};
