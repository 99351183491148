import { createStore } from 'satcheljs';
import { IGlobalSearchResultPageState, ISearchResultInfo } from './schema/IGlobalSearchResultPageState';

export enum Filters {
  adunits,
  adfilters,
  channels,
  publishers,
  reporting,
  properties,
  accounts,
  users
}

export const getStore = createStore<IGlobalSearchResultPageState>('GlobalSearchResultPageState', {
  resourceTypes: new Map<string, number>(),
  results: new Map<string, ISearchResultInfo>(),
  isShowMoreLoading: new Map<string, boolean>(),
  searchQuery: '',
  sourceRef: ''
});
