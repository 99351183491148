import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { getAppStore } from '../../../../../../@data';
import { onPopupOpenChanged } from '../../../../../../@data/mutatorActions';
import { PopupType } from '../../../../../../@data/store/schema/enums/PopupType';
import messages from '../../../../../../App.messages';
import { PopupCard } from '../../../../../../components/Popup';
import { injectIntlAndObserver } from '../../../../../../utils';
import { onAdSenseMediationToggleChanged } from '../../../@data/mutatorActions';

import { mediationPopupMessages } from './DisablingMediationPopup.messages';

export interface IOptOutMediationPopupProps extends InjectedIntlProps {}

export const OptOutMediationPopup = injectIntlAndObserver(function DisablingMediationComponent({
  intl: { formatMessage },
}: IOptOutMediationPopupProps): JSX.Element {
  const { openedPopup } = getAppStore();

  return (
    <PopupCard
      isShown={openedPopup === PopupType.AdUnitOptOut}
      popUpTitle={formatMessage(mediationPopupMessages.title)}
      popUpDescription={formatMessage(mediationPopupMessages.description)}
      primaryButtonAriaLabel={formatMessage(mediationPopupMessages.turnOff)}
      secondaryButtonAriaLabel={formatMessage(messages.cancel)}
      onPopupDismiss={() => {
        onPopupOpenChanged(PopupType.None);
        onAdSenseMediationToggleChanged(true);
      }}
      onPrimaryButtonClick={() => {
        onPopupOpenChanged(PopupType.None);
        onAdSenseMediationToggleChanged(false);
      }}
      onSecondaryButtonClick={() => {
        onPopupOpenChanged(PopupType.None);
        onAdSenseMediationToggleChanged(true);
      }}
    />
  );
});
