import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../../theme';
import { ICommonStyleProps } from '../../../../../types';

type StyleProperties = 'cardsContainer' | 'buttonStyle' | 'headerSection';
type GettingStartedStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): GettingStartedStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return mergeStyleSets({
    cardsContainer: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(${spacing.size260}, 1fr))`,
      justifyContent: 'space-between',
      gap: spacing.size15,
    },
    buttonStyle: {
      justifyContent: 'center',
      display: 'flex',
      background: palette.white,
      color: palette.neutralPrimary,
      border: `${spacing.size1} solid ${palette['NeutralStroke1.Rest']}`,
      borderRadius: spacing.size4,
      selectors: {
        '&:focus': {
          border: '2px solid black',
        },
        ':hover': {
          background: palette.neutralLighter,
          border: `${spacing.size1} solid ${palette['NeutralStroke1.Rest']}`,
          color: palette.neutralPrimary,
        },
      },
    },
    headerSection: {
      margin: `${spacing.size15} 0px`,
      fontSize: spacing.size20,
      fontWeight: '600',
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
