import { action, orchestrator } from 'satcheljs';

import {
  ClarityStatus,
  ClarityType,
  Publisher,
  getAppStore,
  listEntity,
  onLoadingChanged,
  showErrorToastNotification,
} from '../../../../../../../../@data';
import { PropertyServingConfigs } from '../../../../../../../../@data/store/schema/models/ServingConfigs';
import { FormState } from '../../../../../../../../components/Form';
import { fetchPropertyById } from '../../../../../@data/services/PropertyService';
import PropertyMessages from '../../../../../Property.messages';
import { onPropertyChanged, onPropertyErrorChanged, onRawChanged } from '../../../../__data__/mutatorActions';
import { IPropertyViewModel } from '../../../../__data__/store/schema';

export const onFormInitialized = action(
  'onFormInitialized',
  (mode: FormState, publisherId: string, accountId?: string, entityId?: string) => ({
    mode,
    publisherId,
    accountId,
    entityId,
  })
);

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();

  const property: IPropertyViewModel = {};
  property.clarity = {};

  const publisherId = parseInt(msg.publisherId, 10);
  if (!isNaN(publisherId)) {
    const publisher = new Publisher(publisherId);
    try {
      onPropertyErrorChanged();
      onLoadingChanged(true);

      if (
        (msg.mode === FormState.Edit || msg.mode === FormState.MultiStepEdit || msg.mode === FormState.MultiStepCreation) &&
        msg.entityId
      ) {
        const rawProperty = await fetchPropertyById(publisher, msg.entityId, userContext);
        property.id = rawProperty.id;
        property.name = String(rawProperty.name);
        property.url = rawProperty.url;
        property.approvalStatus = rawProperty.approvalStatus;

        const propertyConfigs = await listEntity([publisher], userContext, PropertyServingConfigs);
        propertyConfigs?.forEach((p) => {
          if (p.propertyId.toString() === msg.entityId && p.clarityConfig?.clarityId) {
            if (p.clarityConfig?.clarityType && ClarityType[p.clarityConfig?.clarityType] !== ClarityType.None) {
              const type: ClarityType = ClarityType[p.clarityConfig?.clarityType];
              property.clarity = {
                originalClarityType: type,
                clarityType: type,
                approvalBehavioralOption: type === ClarityType.Behavioral,
                clarityStatus: p.clarityConfig.clarityStatus ? ClarityStatus[p.clarityConfig.clarityStatus] : ClarityStatus.NotConnected,
              };
            } else {
              property.clarity = {
                originalClarityType: ClarityType.None,
                clarityType: ClarityType.None,
                clarityStatus: p.clarityConfig.clarityStatus ? ClarityStatus[p.clarityConfig.clarityStatus] : ClarityStatus.NotConnected,
                clarityId: p.clarityConfig.clarityId,
                originalClarityId: p.clarityConfig.clarityId,
                approvalBehavioralOption: false,
              };
            }
          }
        });

        onRawChanged(rawProperty);
      } else {
        onRawChanged();
      }
      onPropertyChanged(property);
      onLoadingChanged(false);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: PropertyMessages.propertyLoadError });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: PropertyMessages.invalidState });
  }
});
