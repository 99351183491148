import { FontWeights, getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';
import { HEADING_ITEM_HEIGHT, SMALLER_LIST_ITEM_HEIGHT } from '../../constants/StyleConstants';
import { flexWithAlignCenter } from '../../utils/GlobalStyleUtils';
import { BOX_HEIGHT, BOX_WIDTH } from '../CheckboxTreeWithSelectionBox/CheckboxTreeWithSelectionBox.styles';

// NOTE: Keep the style types in the same file where the style definitions are present.
// This helps in better DX (Developer Experience) as we needn't switch files to see what's the type.
export interface ICheckboxTreeStyleProps {
  theme: ITheme;
}

export interface ICheckboxTreeStyles {
  singleCheckboxStyle?: string;
  checkboxTreeContainerStyle?: string;
  checkboxContainerStyle?: string;
  searchBoxRootStyle?: string;
  selectedBlockStyle?: string;
}

export const getStyles = (props: ICheckboxTreeStyleProps): ICheckboxTreeStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing, palette, fonts } = theme;

  const commonCheckboxStyle = {
    padding: `${spacing.size8} 0`,
    ...flexWithAlignCenter,
  };
  const searchBorderStyle = {
    border: 'none',
    borderBottom: `1px solid ${palette.neutralQuaternaryAlt}`,
  };

  return mergeStyleSets({
    singleCheckboxStyle: {
      ...commonCheckboxStyle,
    },
    checkboxTreeContainerStyle: {
      border: `1px solid ${palette.neutralTertiaryAlt}`,
      display: 'inline-block',
      height: BOX_HEIGHT,
      width: BOX_WIDTH,
    },
    checkboxContainerStyle: {
      paddingLeft: spacing.size12,
      overflowY: 'auto',
      height: BOX_HEIGHT - HEADING_ITEM_HEIGHT,
    },
    searchBoxRootStyle: {
      height: HEADING_ITEM_HEIGHT,
      ...searchBorderStyle,
      '&:hover,&:focus': {
        ...searchBorderStyle,
      },
    },
    selectedBlockStyle: {
      ...fonts.size12,
      height: SMALLER_LIST_ITEM_HEIGHT,
      ...flexWithAlignCenter,
      marginLeft: `-${spacing.size12}`,
      paddingLeft: spacing.size12,
      background: palette.neutralLighter,
      fontWeight: FontWeights.semibold,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): ICheckboxTreeStyles {
  return getStyles({ theme: getTheme(), ...props });
}
