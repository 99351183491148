import { mutator } from 'satcheljs';
import { onBlockedAdsContextChanged, onBlockedImagesContextChanged } from '../actions';
import { getStore } from '../store/store';

mutator(onBlockedAdsContextChanged, msg => {
  getStore().blockedAdsContext = msg.data;
});

mutator(onBlockedImagesContextChanged, msg => {
  getStore().blockedImagesContext = msg.data;
});
