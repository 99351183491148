import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'publisher.create',
    defaultMessage: 'Create publisher',
  },
  deactivateTitle: {
    id: 'publisher.deactivateTitle',
    defaultMessage: 'Do you want to deactivate publisher(s)?',
  },
  notFound: {
    id: 'publisher.notFound',
    defaultMessage: 'No publishers found, create one.',
  },
  name: {
    id: 'publisher.name',
    defaultMessage: 'Publisher name',
  },
  id: {
    id: 'publisher.id',
    defaultMessage: 'Publisher ID',
  },
  impressions: {
    id: 'publisher.impressions',
    defaultMessage: 'Impressions prev.month',
  },
  accountNumber: {
    id: 'publisher.accountNumber',
    defaultMessage: '# of accounts',
  },
  status: {
    id: 'publisher.status',
    defaultMessage: 'Status',
  },
  joinDate: {
    id: 'publisher.joinDate',
    defaultMessage: 'Join date',
  },
  noPublisherSelected: {
    id: 'publisher.noPublisherSelected',
    defaultMessage: 'A publisher isn’t selected yet. To start managing publishers, search for a publisher',
  },
  actions: {
    id: 'publisher.actions',
    defaultMessage: 'Actions',
  },
  zeroResultTitle: {
    id: 'publisher.zeroResultTitle',
    defaultMessage: 'No publishers yet',
  },
  zeroResultDescription: {
    id: 'publisher.zeroResultDescription',
    defaultMessage: 'You haven’t created any publishers yet',
  },
});
