import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Route, RouteComponentProps } from 'react-router-dom';
import { RoutePrefix } from '../../../Routes';
import { FormState } from '../../../components/Form';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import './@data/mutators';
import './@data/orchestrators';
import { CreateAdFilter } from './Create/CreateAdFilter';
import { ManagePage } from './Manage/ManagePage';

export const AdFilterPage = injectIntl(function AdFilterPageComponent({
  match,
  intl,
}: RouteComponentProps & InjectedIntlProps): JSX.Element {
  React.useEffect(() => {
    onCurrentRouteChanged(RouteName.adfilter, intl.formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Route exact path={`${match.url}`} component={ManagePage} />
      <Route
        path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.adfilter]}/create`}
        render={(props) => <CreateAdFilter {...props} mode={FormState.Create} />}
      />
      <Route
        path={`/${RoutePrefix.adManagement}/:publisherId-:accountId/${RouteName[RouteName.adfilter]}/edit/:id`}
        render={(props) => <CreateAdFilter {...props} mode={FormState.Edit} />}
      />
    </React.Fragment>
  );
});
