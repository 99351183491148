import { Checkbox, ComboBox, IComboBoxOption, Label, Link, MessageBar, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { getAppStore } from '../../../../@data';
import { APP_NAME_NEW } from '../../../../constants/AppConstants';

import { getSupportedLanguagesComboBoxOptions } from '../../../../@data/utils/supportedLanguages';
import { COUNTRIES_LANGUAGE_MAPPING, CURRENCIES_LANGUAGE_MAPPING } from '../../../../globalization';
import { injectIntlAndObserver } from '../../../../utils';
import { getStore as getTnCStore } from '../../../terms-and-conditions/@data/store/store';
import { ORG_NAME_MAX_LENGTH, getSignUpStore } from '../../@data';
import {
  onCountryCodeChanged,
  onCurrencyCodeChanged,
  onOptInPromotionalEmailCheckChanged,
  onOrgNameChanged,
  onPreferredLanguageChanged,
  onTermsAndConditionsCheckChanged,
  onUserFirstNameChanged,
  onUserLastNameChanged,
} from '../../@data/actions';
import { isAccountTypeOrganization } from '../../@data/store/selectors';
import './../../@data/orchestrators';
import messages from './SignUpDetails.messages';
import { getStyles } from './SignUpDetails.styles';
import { ISignUpDetailsProps } from './SignUpDetails.types';

export const SignUpDetails = injectIntlAndObserver(function SignUpDetailsComponent({ intl }: ISignUpDetailsProps & InjectedIntlProps) {
  const {
    rootStyle,
    headerStyle,
    comboBoxStyle,
    termsAndConditionsStyle,
    termsContainerStyle,
    nameContainerStyle,
    // linksStyle,
    privacyLinkWrapper,
    privacyLinkStyle,
  } = getStyles();
  const { formatMessage } = intl;
  const store = getSignUpStore();
  const activeAccount = store.active;
  const [readAgreement, setReadAgreement] = React.useState(false);
  const { content } = getTnCStore(); // this view will never expose to non-tython users, so TnC will always be tython version
  const { name, firstName, lastName } = getAppStore().userContext || {};
  const preferredLanguage = activeAccount.preferredLanguageCode;
  const selectedCountryCode = activeAccount.countryCode;
  const currencyCode = activeAccount.currencyCode;

  /**
   * Pending till new countries are available
   * Function to fetch countries in dropdown
   * Param: countries: string[]
   * return countries: IComboBoxOption[]
   */
  const _createCountryDropDown = (countries: string[]) => {
    const availableCountries: IComboBoxOption[] = [];
    const countriesWithLocalLang = COUNTRIES_LANGUAGE_MAPPING[getAppStore().locale];

    if (countries) {
      countries.forEach((countryCode) => {
        if (countriesWithLocalLang[countryCode]) {
          availableCountries!.push({
            key: countryCode,
            text: countriesWithLocalLang[countryCode],
          });
        }
      });
    }
    availableCountries.sort((a, b) => {
      const firstValue = a.text;
      const secondValue = b.text;

      return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
    });

    return availableCountries;
  };

  const getCurrencyDropdownOptions = (): IComboBoxOption[] => {
    const availableCurrencies: IComboBoxOption[] = [];
    const currenciesWithLocalLang = CURRENCIES_LANGUAGE_MAPPING[getAppStore().locale];

    Object.keys(currenciesWithLocalLang).forEach((currCode) => {
      availableCurrencies.push({
        key: currCode,
        text: currenciesWithLocalLang[currCode],
      });
    });

    return availableCurrencies;
  };

  const InfoBanner = () => {
    return (
      <MessageBar>
        <FormattedMessage {...messages.signupdetailsInfoBanner} />
      </MessageBar>
    );
  };

  /**
   * Function to handle the scroll
   * Detect when user reach the bottom and set readAgreement to true.
   * Keeping a buffer of 20px, considering the bottom padding and clientHeight variations.
   */
  const handleScroll = (e) => {
    const hasScrollHitBottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 120;
    !readAgreement && setReadAgreement(hasScrollHitBottom);
  };

  return (
    <Stack verticalAlign="space-between" className={rootStyle}>
      <div className={headerStyle}>{formatMessage(messages.signUpDetailsTitle)}</div>
      {isAccountTypeOrganization() && (
        <TextField
          className={comboBoxStyle}
          placeholder={formatMessage(messages.orgNamePlaceholder, { limit: ORG_NAME_MAX_LENGTH })}
          label={formatMessage(messages.organizationName)}
          required={true}
          errorMessage={store.errors.organizationName}
          value={store.active.organizationName}
          onChange={(_, value) => onOrgNameChanged(value)}
        />
      )}

      <div className={nameContainerStyle}>
        <TextField
          className={comboBoxStyle}
          placeholder={formatMessage(messages.firstName)}
          label={formatMessage(messages.firstName)}
          required={true}
          errorMessage={store.errors.userFirstName}
          value={store.active.userFirstName}
          onChange={(_, value) => onUserFirstNameChanged(value)}
          disabled={!!name || !!firstName}
        />
        <TextField
          className={comboBoxStyle}
          placeholder={formatMessage(messages.lastName)}
          label={formatMessage(messages.lastName)}
          required={true}
          errorMessage={store.errors.userLastName}
          value={store.active.userLastName}
          onChange={(_, value) => onUserLastNameChanged(value)}
          disabled={!!(name && name.split(' ')[1]) || !!lastName?.trim()}
        />
      </div>

      <div className={comboBoxStyle}>
        <ComboBox
          autoComplete={'on'}
          label={formatMessage(messages.signUpDetailsPreferredLanguage)}
          options={getSupportedLanguagesComboBoxOptions(getAppStore().locale)}
          disabled={process.env.REACT_APP_ENV === 'production' ? true : false}
          required
          selectedKey={preferredLanguage}
          onChange={(_, value) => onPreferredLanguageChanged(value!!)}
          useComboBoxAsMenuWidth={true}
        />
      </div>

      <div className={comboBoxStyle}>
        <ComboBox
          autoComplete={'on'}
          label={formatMessage(messages.signUpDetailsCountry)}
          options={_createCountryDropDown(activeAccount.availableCountries!)}
          required
          disabled={process.env.REACT_APP_ENV === 'production' ? true : false}
          selectedKey={selectedCountryCode}
          onChange={(_, value) => onCountryCodeChanged(value!!)}
          useComboBoxAsMenuWidth={true}
        />
      </div>

      <div className={comboBoxStyle}>
        <ComboBox
          autoComplete={'on'}
          label={formatMessage(messages.signUpDetailsCurrency)}
          options={getCurrencyDropdownOptions()}
          required
          selectedKey={currencyCode}
          onChange={(_, value) => onCurrencyCodeChanged(value!!)}
          useComboBoxAsMenuWidth={true}
          disabled
        />
      </div>
      {InfoBanner()}

      <div className={termsAndConditionsStyle}>
        <Label required={true}>{formatMessage(messages.signUpDetailsTermsAndConditions)}</Label>
        <div onScroll={handleScroll} className={termsContainerStyle}>
          {<div dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </div>

      <Checkbox
        required={true}
        label={formatMessage(messages.agreementText)}
        defaultChecked={store.isTermsAndConditionsAgreed}
        onChange={(_, value) => onTermsAndConditionsCheckChanged(value)}
      />

      <div className={privacyLinkWrapper}>
        <Checkbox
          required={false}
          label={formatMessage(messages.signupEmailOptIn, { appName: APP_NAME_NEW })}
          defaultChecked={store.optInPromotionalEmail}
          onChange={(_, value) => onOptInPromotionalEmailCheckChanged(value)}
        />
        <Link href="http://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" className={privacyLinkStyle}>
          {formatMessage(messages.signupEmailOptInPrivacyLink)}
        </Link>
      </div>
      <br />
    </Stack>
  );
});
