import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class Property extends Model {
  public className = UserRoleEntity.Property;
  public name: number;
  public id: number;
  public websiteURL: number;
  public approvalStatus: number;
  public notes: number;
  public attachedAdUnits: number;
  public clarity: number;

  constructor(params: Property) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: Property, property: Property) {
    // tslint:disable:no-bitwise
    this.accessPermission |= property.accessPermission;
    this.approvalStatus |= property.approvalStatus;
    this.id |= property.id;
    this.name |= property.name;
    this.notes |= property.notes;
    this.websiteURL |= property.websiteURL;
    this.attachedAdUnits |= property.attachedAdUnits;
    this.clarity |= property.clarity;
    // tslint:enable:no-bitwise
  }
}
