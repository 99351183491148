import { styled } from '@fluentui/react';
import { NotificationBarBase } from './NotificationBar.base';
import { getStyles } from './NotificationBar.styles';
import { INotificationBarProps, INotificationBarStyleProps, INotificationBarStyles } from './NotificationBar.types';

export const NotificationBar = styled<INotificationBarProps, INotificationBarStyleProps, INotificationBarStyles>(
  NotificationBarBase,
  getStyles,
  undefined,
  {
    scope: 'NotificationBar',
  }
);
