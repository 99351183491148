import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

type UseStateWithLatestCallback<S> = [S, Dispatch<SetStateAction<S>>, () => S];

export function useStateWithLatestCallback<S>(initialState: S): UseStateWithLatestCallback<S> {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const getState = () => stateRef.current;

  return [state, setState, getState];
}
