import { createStore } from 'satcheljs';
import { IReportViewState } from './schema/IReportViewState';

export enum RouteName {
  dashboard = 'dashboard',
  reportDetails = 'report-details',
}

export const defaultRoute = RouteName.dashboard;

export const getPageStore = createStore<IReportViewState>('ReportViewState', {
  loading: false,
});
