import { Link, SelectionMode, getTheme } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EntityEvaluationStatus, InventoryPlatformType, Property, getAppStore } from '../../../../@data';
import { isTythonSelfServeUser } from '../../../../@data/utils/checkUserType';
import AppMessages from '../../../../App.messages';
import { DefaultRoute } from '../../../../Routes';
import { ZeroResults } from '../../../../components/ZeroResults/ZeroResults';
import { useWindowSize } from '../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../hooks/useWindowSize/store/store';
import { GridPageLayout } from '../../../../layout/GridPageLayout/GridPageLayout';
import { GridV2, IGridV2Column } from '../../../../layout/GridPageV2';
import TutorialCallout from '../../../../layout/TutorialCallout';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../utils/ReactUtils';
import { RouteName } from '../../@data';
import { fetchPublisherEvaluationStatusAction } from '../../@data/actions';
import { setPropertyData } from './__data__/mutatorActions';
import { onManagePageInitialized } from './__data__/orchestrators';
import { getGridPropertyStore } from './__data__/store/store';
import ClarityPanel from './components/ClarityPanel';
import { CodePanel } from './components/CodePanel/CodePanel';
import messages from './messages';
import { getClassNames, getStyles } from './styles';
import { getClarityTutorialSteps } from './utils/getClarityTutorialSteps';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

const { windowStoreSerializer } = useWindowSize();

export const ManagePage = injectIntlAndObserver((props: IRouterWithIntl & { withParentLayout?: boolean; abortDataFetch?: boolean }) => {
  const { publisher, publisherEvaluationStatus } = getAppStore();
  const tythonUser = isTythonSelfServeUser();
  const { history, intl, location, match, withParentLayout, abortDataFetch = false } = props;
  const { formatMessage } = intl;
  const { isWindowRegularSize } = windowStoreSerializer(getWindowSizeStore());
  const store = getGridPropertyStore();
  const [clarityTutorialCallout, setClarityTutorialCallout] = useState(false);
  const styles = getClassNames(getStyles, { theme: getTheme() });
  const isPublisherRejected = publisherEvaluationStatus?.approvalStatus === EntityEvaluationStatus.Rejected;

  const changeClarityCalloutVisibility = () => setClarityTutorialCallout((prevState) => !prevState);

  const gridDefaultColumns: IGridV2Column[] = getGridColumns(
    formatMessage,
    props.history,
    isWindowRegularSize,
    getGridPropertyStore().isFetchingPropertyStatus,
    getGridPropertyStore().servingConfigs,
    changeClarityCalloutVisibility
  );

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={
        tythonUser || (!tythonUser && !!publisher)
          ? formatMessage(tythonUser ? messages.zeroResultTitle : messages.zeroResultTitleNonTython)
          : undefined
      }
      description={formatMessage(
        tythonUser ? messages.zeroResultDescription : !!publisher ? messages.zeroResultDescriptionNonTython : messages.noPublisherSelected
      )}
      buttonText={formatMessage(tythonUser ? messages.siteCreate : messages.create)}
      onButtonClick={
        tythonUser || (!tythonUser && !!publisher)
          ? () => history.push(`${DefaultRoute}/${publisher?.id}/${RouteName.property}/create`)
          : undefined
      }
    />
  );

  useEffect(() => {
    if ((publisher !== undefined && store?.data && store?.data[0]?.publisherId === publisher?.id) || abortDataFetch) {
      return;
    }
    onManagePageInitialized();
    fetchPublisherEvaluationStatusAction({ cache: false });
    // eslint-disable-next-line
  }, [publisher]);

  const pageContent = (
    <>
      <TutorialCallout
        steps={getClarityTutorialSteps(formatMessage, store.servingConfigs)}
        isOpen={clarityTutorialCallout}
        onChangeState={changeClarityCalloutVisibility}
      />

      <ClarityPanel {...props} />
      <>{isTythonSelfServeUser() && <CodePanel {...props} />}</>
      <GridV2
        history={history}
        location={location}
        match={match}
        entity={Property}
        pageTitle={{
          title: formatMessage(tythonUser ? messages.sites : messages.properties),
          helpId: tythonUser ? 'pop_PCv4_Sites' : 'pop_PCv4_Properties',
        }}
        msgBarProps={
          isPublisherRejected
            ? {
                messageBarType: 1,
                children: (
                  <span className={styles.errorMsgContent}>
                    <FormattedMessage
                      {...messages.sitesCannotServeMessage}
                      values={{ learn: <Link>{formatMessage(AppMessages.learnMore)}</Link> }}
                    />
                  </span>
                ),
                className: styles.errorMsg,
              }
            : undefined
        }
        isGridReady={!store.isLoading}
        isDataLoading={store.isFetchingData}
        commandBarProps={getCommandBarItems(formatMessage)}
        dataGridProps={{
          gridV2Columns: gridDefaultColumns,
          data: publisher
            ? store.data?.filter?.((property) => property.servingConfig?.inventoryPlatformType !== InventoryPlatformType.UWP) || []
            : [],
          setData: setPropertyData,
          shimmerLines: 10,
          selectionMode: SelectionMode.single,
          renderZeroItemsComponent: NoResultComponent,
          noEntityFound: tythonUser ? messages.siteNotFound : messages.notFound,
        }}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
    </>
  );
  return withParentLayout ? <>{pageContent}</> : <GridPageLayout>{pageContent}</GridPageLayout>;
});
