import {
  Checkbox,
  DefaultButton,
  FocusTrapZone,
  IProcessedStyleSet,
  Layer,
  Popup,
  PrimaryButton,
  Text,
  classNamesFunction,
  getTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import * as React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { useI18n } from '../../../i18n/useI18n';
import { getAppStore, isTythonSelfServeUser } from '../../@data';
import { APP_NAME_NEW, APP_NAME_PUBCENTER } from '../../constants';
import { getStore } from '../../pages/ad-management/Account/Settings/@data/store/store';
import { injectIntlAndObserver } from '../../utils';
import { Logo } from '../Logo/Logo';
import messages from './TnCModal.messages';
import { getStyles } from './TnCModal.styles';
import { ITnCModalStyleProps, ITnCModalStyles } from './TnCModal.types';

const getClassNames = classNamesFunction<ITnCModalStyleProps, ITnCModalStyles>();
export interface ITnCModalProps {
  onSkip?: () => void;
  onAccept: () => void;
  isActive: boolean;
  content?: string;
  releaseDate?: string;
  isLoading: boolean;
}

export const TnCModal = injectIntlAndObserver(
  ({ intl: { formatMessage }, onSkip, onAccept, isActive, content, releaseDate, isLoading }: ITnCModalProps & InjectedIntlProps) => {
    const [isTnCAccepted, { toggle, setFalse }] = useBoolean(false);
    const { publisher, userContext } = getAppStore();
    const { systemPreferences } = getStore().active;
    const { locale } = useI18n({ systemPreferences, publisher, userContext });
    const tncReleaseDate = new Date(releaseDate!).toLocaleDateString(locale, { month: 'long', day: 'numeric', year: 'numeric' });
    const classNames: IProcessedStyleSet<ITnCModalStyles> = getClassNames(getStyles, {
      theme: getTheme(),
    });

    if (!isActive) {
      return null;
    }

    return (
      <>
        {isActive && (
          <Layer styles={{ root: { background: 'transparent' } }}>
            <Popup className={classNames.root} role="dialog" aria-modal="true" onDismiss={onSkip}>
              <FocusTrapZone disabled>
                <div role="document" className={classNames.content}>
                  <Logo renderDarkLogo />
                  <Text block className={classNames.headLineGreeting}>
                    {formatMessage(messages.headLineGreeting)}
                  </Text>
                  <Text block className={classNames.summaryContent}>
                    {formatMessage(messages.summary, {
                      releaseDate: tncReleaseDate,
                      appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER,
                    })}
                  </Text>
                  <Text block>{formatMessage(messages.thanksMsg)}</Text>
                  <Text block>
                    {formatMessage(messages.thanksMsgSubLine, { appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER })}
                  </Text>
                  <div className={classNames.tncWrapper}>
                    <div dangerouslySetInnerHTML={{ __html: content! }} />
                  </div>
                  <Text block className={classNames.tncCheckboxWrapper}>
                    <Checkbox checked={isTnCAccepted} onChange={toggle} className={classNames.tncCheckbox} />
                    {formatMessage(messages.ackMsg)}
                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.864 0.879999L3.624 3.184L5.928 2.536L6.096 3.64L3.888 3.82L5.316 5.716L4.284 6.28L3.264 4.18L2.34 6.28L1.272 5.716L2.676 3.82L0.492 3.64L0.66 2.536L2.94 3.184L2.688 0.879999H3.864Z"
                        fill="#A80000"
                      />
                    </svg>
                  </Text>
                  <Text block className={classNames.tncAckMsgSubline}>
                    {formatMessage(messages.ackMsgSubline)}
                  </Text>
                  <div className={classNames.actionButtonWrapper}>
                    <PrimaryButton onClick={onAccept} disabled={!isTnCAccepted || isLoading}>
                      {formatMessage(isLoading ? messages.submitting : messages.acceptButton)}
                    </PrimaryButton>
                    <DefaultButton
                      className={classNames.endingButton}
                      onClick={() => {
                        setFalse();
                        onSkip?.();
                      }}
                      disabled={onSkip === undefined}
                    >
                      {formatMessage(messages.skipButton)}
                    </DefaultButton>
                  </div>
                </div>
              </FocusTrapZone>
            </Popup>
          </Layer>
        )}
      </>
    );
  }
);
