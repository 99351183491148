import React, { useState } from 'react';
import { IHeaderItem } from '../../../../../components/Header/Header.types';
import { useCMUIHelpExperience } from '../../../../../hooks';
import { injectRouterAndIntlWithObserver } from '../../../../../utils';
import { AnnouncementCard, IAnnouncementCard } from '../AnnouncementCard/AnnouncementCard';
import messages from './Announcements.message';
import { getClassNames } from './Announcements.styles';
import { getAnnouncementCards } from './utils/getAnnouncementCards';
import { createPanel, getHeaderItems } from './utils/utils';

interface IHeaderData {
  items?: IHeaderItem[];
}

export const Announcements = injectRouterAndIntlWithObserver(function AnnouncementsComponent(props) {
  const { cardsContainer, headerSection } = getClassNames();
  const { formatMessage } = props.intl;
  const { CMUIHeaderMenuItemSerializer, CMUIPopupArticleUsingButton } = useCMUIHelpExperience();
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

  const itemsData: IHeaderData = getHeaderItems(CMUIHeaderMenuItemSerializer, formatMessage);

  // TODO
  // Get the annoucements from BE using a new endpoint through a useEffect

  const displayAnnouncementCards = (cards: IAnnouncementCard[]) => {
    return cards.map((card, index) => <AnnouncementCard card={card} key={index} />);
  };

  return (
    <div>
      <h2 className={headerSection}>{formatMessage(messages.announcementTitle)}</h2>
      {createPanel(itemsData.items![1], isNotificationPanelOpen, formatMessage, setIsNotificationPanelOpen)}
      <div className={cardsContainer}>
        {displayAnnouncementCards(
          getAnnouncementCards(props.history, setIsNotificationPanelOpen, itemsData.items![0], CMUIPopupArticleUsingButton, formatMessage)
        )}
      </div>
    </div>
  );
});
