import { IStyle, ITheme } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../theme';

export interface IAccordionStyleProps {
  theme: ITheme;
  isExpanded: boolean;
  isActive: boolean;
}

export interface IAccordionStyles {
  accordionControlWrapper: IStyle;
  accordionToggleCheckboxLabel: IStyle;
  accordionContentWrapper: IStyle;
  accordionChevron: IStyle;
  subContentText: IStyle;
}

export const getStyles = (props: IAccordionStyleProps): IAccordionStyles => {
  const { theme, isExpanded } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return {
    accordionControlWrapper: {
      borderTopLeftRadius: spacing.size12,
      borderTopRightRadius: spacing.size12,
      border: `${spacing.size1} solid ${palette.neutralLight}`,
      padding: `${spacing.size16} ${spacing.size18}`,
      ...(!isExpanded && { borderBottomWidth: 0 }),
      '&:hover': {
        '.triggerIcon': {
          fontWeight: 'bold',
        },
      },
    },
    accordionToggleCheckboxLabel: {
      '.ms-Checkbox-text': {
        fontSize: spacing.size18,
        fontWeight: 600,
      },
    },
    accordionContentWrapper: {
      borderBottomLeftRadius: spacing.size12,
      borderBottomRightRadius: spacing.size12,
      border: `${spacing.size1} solid ${palette.neutralLight}`,
      padding: `${spacing.size16}`,
      borderTopWidth: 0,
      ...(!isExpanded && { paddingTop: 0 }),
      ...(isExpanded && { padding: 0 }),
    },
    accordionChevron: {
      cursor: 'pointer',
      padding: `${spacing.size10} ${spacing.size10} ${spacing.size10} ${spacing.size46}`,
      margin: `-${spacing.size10} -${spacing.size10} -${spacing.size10} -${spacing.size46}`,
      background: 'transparent',
    },
    subContentText: { color: palette.neutralTertiaryWhiteContrast, marginLeft: spacing.size30 },
  };
};
