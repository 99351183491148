import { mutator } from 'satcheljs';
import { Filter } from '../../../../../@data';
import { mergeTypeWithObject } from '../../../../../utils/ObjectUtils';
import {
  onFetchFiltersLoading,
  onFetchingFiltersData,
  onFilterChanged,
  onFilterErrorChanged,
  onFiltersFetched,
  onRawChanged,
} from '../actions';
import { getFilterStore } from '../store/store';

mutator(onRawChanged, (msg) => {
  getFilterStore().raw = mergeTypeWithObject(new Filter(), msg.value);
});

mutator(onFilterChanged, (msg) => {
  getFilterStore().active = msg.filter;
});

mutator(onFilterErrorChanged, (msg) => {
  getFilterStore().errors = msg.values || {};
});

mutator(onFetchFiltersLoading, (msg) => {
  getFilterStore().isLoading = msg.isLoading;
});

mutator(onFetchingFiltersData, (msg) => {
  getFilterStore().isFetchingData = msg.isFetchingData;
});

mutator(onFiltersFetched, (msg) => {
  getFilterStore().gridFilters = msg.items;
});
