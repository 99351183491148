import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing } from '../../../theme';
import { IEmptyLayoutStyleProps, IEmptyLayoutStyles } from './EmptyLayout.types';

export const getStyles = (props: IEmptyLayoutStyleProps): IEmptyLayoutStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const { size16, size32 } = spacing;
  const fonts = theme.fonts as IPubCenterFonts;

  return mergeStyleSets({
    root: {
      padding: size32,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0',
    },
    heading: {
      margin: `0 0 ${size16} 0`,
      ...fonts.size28,
      fontWeight: FontWeights.semibold,
    },
    description: {
      ...fonts.size16,
      marginBottom: size16,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): IEmptyLayoutStyles {
  return getStyles({ theme: getTheme(), ...props });
}
