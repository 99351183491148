import { orchestrator } from 'satcheljs';
import { onUserContextChanged } from '../actions';
import { getAppStore, StorageUserContextKey } from '../store/store';

orchestrator(onUserContextChanged, async (msg) => {
  const store = getAppStore();
  if (!store.userContext) {
    localStorage.clear();
  } else {
    localStorage.setItem(StorageUserContextKey, JSON.stringify(msg.userDetails));
  }
});