import {
  ActionButton,
  ChoiceGroup,
  classNamesFunction,
  Dropdown,
  getTheme,
  IconButton,
  IProcessedStyleSet,
  ITextFieldProps,
  Label,
  Link,
  Panel,
  PanelType,
  Spinner,
  TextField,
} from '@fluentui/react';
import * as React from 'react';
import { FilterScope, getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged, onNavigateBack } from '../../../../@data';
import { completeAccess, hasUpdateAccess, UserRoleEntity, userRoleKey } from '../../../../@data/services/UserRoleService';
import { Filter as FilterAccessPermissions } from '../../../../@data/store/schema/userrolemodels/Filter';
import AppMessages from '../../../../App.messages';
import { BulkUpload } from '../../../../components/bulkUpload/bulkUpload';
import { DateTimePicker } from '../../../../components/DateTimePicker/DateTimePicker';
import { FormItem, FormSection, FormState } from '../../../../components/Form';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { MultiSelectPicker } from '../../../../components/MultiSelectPicker';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { injectIntlWithObserver } from '../../../../utils/ReactUtils';
import { getAdManagementStore, RouteName } from '../../@data';
import { onPanelOpenChanged } from '../../@data/actions';
import { createReturnUrl } from '../../@data/services/AdManagementService';
import { CreateChannel } from '../../Channel/Create/CreateChannel';
import { getFilterStore } from '../@data';
import {
  onfilterItemsEntryTypeChanged,
  onFilterUpdateTypeChanged,
  onFormAppendExcludedAdKeywordsChanged,
  onFormAppendExcludedQueryKeywordsChanged,
  onFormAppendExcludedUrlsChanged,
  onFormCancelled,
  onFormChannelsChanged,
  onFormDownloadClicked,
  onFormExludedAdKeywordChanged,
  onFormExludedQueryKeywordChanged,
  onFormExludedUrlChanged,
  onFormFilterEndTimeChanged,
  onFormFilterNameChanged,
  onFormFilterStartTimeChanged,
  onFormFilterTypeChanged,
  onFormInitialized,
  onFormResetTimeRange,
  onFormSubmitted,
  onFormUndoTimeRange,
} from '../@data/actions';
import messages from './CreateAdFilter.messages';
import { getStyles } from './CreateAdFilter.styles';
import {
  FilterExcludedType,
  ICreateAdFilterProps,
  ICreateAdFilterStyleProps,
  ICreateAdFilterStyles,
  IFormInitializationState,
} from './CreateAdFilter.types';

const getClassNames = classNamesFunction<ICreateAdFilterStyleProps, ICreateAdFilterStyles>();

export const CreateAdFilter = injectIntlWithObserver(
  class CreateAdFilterPage extends React.Component<ICreateAdFilterProps, IFormInitializationState> {
    private _classNames: IProcessedStyleSet<ICreateAdFilterStyles>;

    constructor(props: ICreateAdFilterProps) {
      super(props);
      this.state = {
        initializeForm: true,
        textFileLoading: false,
      };
    }

    public componentDidMount() {
      const publisherId = this.props.match.params.publisherId;
      const accountId = this.props.match.params.accountId;
      const entityId = this.props.match.params.id;
      this.setState({ id: entityId });
      if (publisherId && accountId) {
        onFormInitialized(this.props.mode, publisherId, accountId, entityId);
      }
    }

    public componentDidUpdate() {
      const publisherId = this.props.match.params.publisherId;
      const accountId = this.props.match.params.accountId;
      const entityId = this.props.match.params.id;
      if (this.state.initializeForm && this.state.id !== entityId) {
        this.setState({ id: entityId });
        if (publisherId && accountId) {
          onFormInitialized(this.props.mode, publisherId, accountId, entityId);
        }
      }
    }

    public render() {
      const theme = getTheme();
      this._classNames = getClassNames(getStyles, { theme });
      const { formatMessage } = this.props.intl;
      const publisherId = this.props.match.params.publisherId;
      const accountId = this.props.match.params.accountId;
      const { saving, loading } = getAppStore();

      return (
        <DetailPage
          titleLabel={formatMessage(this.props.mode === FormState.Edit ? messages.editFilter : messages.createFilter)}
          helpId={'pop_PCv4_FilterCreate'}
          submitLabel={formatMessage(this.props.mode === FormState.Edit ? messages.editFilter : messages.createFilter)}
          onSubmit={() => this._onSubmit(publisherId, accountId)}
          onCancel={() => this._onCancel(publisherId, accountId)}
          onNavClick={() => this._onCancel(publisherId, accountId)}
          saving={saving}
          loading={loading}
          pageMode={this.props.mode}
        >
          {this._formContent()}
        </DetailPage>
      );
    }

    private _formContent = (): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getFilterStore();
      const filterAccessPermissions = this._getFilterAccessPermission();

      return (
        <div>
          <FormItem formWidth={440}>
            <TextField
              label={formatMessage(messages.inputName)}
              required={true}
              placeholder={formatMessage(messages.inputNameLength)}
              errorMessage={store.errors.name}
              value={store.active.name}
              onChange={(_, value) => onFormFilterNameChanged(value)}
              disabled={this._isDisabled(this.props.mode, filterAccessPermissions === null ? completeAccess : filterAccessPermissions.name)}
            />
          </FormItem>
          {store.active.id && (
            <FormItem formWidth={440}>
              <TextField label={formatMessage(messages.filterId)} required={true} disabled={true} value={String(store.active.id)} />
            </FormItem>
          )}
          <FormItem>
            <Dropdown
              label={formatMessage(messages.filterType)}
              required={true}
              options={[
                { key: FilterScope.Account, text: formatMessage(messages.accountFilter) },
                { key: FilterScope.Publisher, text: formatMessage(messages.publisherFilter) },
              ]}
              defaultSelectedKey={store.active.filterType}
              onChange={(_, value) => onFormFilterTypeChanged(value!!)}
              errorMessage={store.errors.filterType}
              disabled={this.props.mode === FormState.Edit}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <ChoiceGroup
              label={formatMessage(messages.filterUpdateType)}
              options={[
                { key: 'nonAppendOnly', text: formatMessage(messages.nonAppendOnly) },
                { key: 'appendOnly', text: formatMessage(messages.appendOnly) },
              ]}
              styles={this._classNames.subComponentStyles.choiceGroup}
              onChange={(_, value) => onFilterUpdateTypeChanged(value!!)}
              selectedKey={store.active.appendOnly ? 'appendOnly' : 'nonAppendOnly'}
              disabled={
                this.props.mode === FormState.Edit ||
                this._isDisabled(this.props.mode, filterAccessPermissions === null ? completeAccess : filterAccessPermissions.appendOnly)
              }
            />
          </FormItem>
          {store.active.filterType !== FilterScope.Publisher && (
            <FormItem formWidth={600}>
              <MultiSelectPicker
                intl={this.props.intl}
                required={true}
                errorMessage={store.errors.channelsSelected}
                label={formatMessage(messages.inputAttachChannel)}
                ariaDescription={formatMessage(messages.channelPickerAriaDesc)}
                enableCreateButton={this.props.mode !== FormState.InPageCreate}
                createButtonLabel={formatMessage(AppMessages.createChannel)}
                onCreateButtonClick={() => onPanelOpenChanged(true)}
                items={store.active.channels || []}
                initialSelectedItems={store.active.channelsSelected}
                onSelectedItems={(items) => onFormChannelsChanged(items)}
                disabled={this._isDisabled(
                  this.props.mode,
                  filterAccessPermissions === null ? completeAccess : filterAccessPermissions.channelList
                )}
              />
            </FormItem>
          )}
          <FormSection
            title={formatMessage(messages.excludedItems)}
            formWidth={1000}
            expanded={true}
            ariaDescription={formatMessage(messages.excludedDescription)}
          >
            <Label id="excludedDescription" styles={this._classNames.subComponentStyles.excludedDescription}>
              {formatMessage(messages.excludedDescription)}
            </Label>
            <ChoiceGroup
              options={[
                { key: 'manualEntry', text: formatMessage(messages.manualEntry) },
                { key: 'fileUpload', text: formatMessage(messages.fileUpload) },
              ]}
              styles={this._classNames.subComponentStyles.choiceGroup}
              onChange={(_, value) => onfilterItemsEntryTypeChanged(value!!)}
              selectedKey={store.active.filterItemsEntryType || 'manualEntry'}
            />
            {(!store.active.filterItemsEntryType || store.active.filterItemsEntryType === 'manualEntry') &&
              this._excludedItemsTextField(filterAccessPermissions)}
            {store.active.filterItemsEntryType === 'fileUpload' && this._excludedItemsUploadField(filterAccessPermissions)}
          </FormSection>
          <FormSection
            title={formatMessage(messages.timeRangeSectionTitle)}
            formWidth={1000}
            expanded={true}
            ariaDescription={formatMessage(messages.timeRangeSectionDescription)}
          >
            <Label id="excludedDescription" styles={this._classNames.subComponentStyles.excludedDescription}>
              {formatMessage(messages.timeRangeSectionDescription)}
            </Label>
            <DateTimePicker
              intl={this.props.intl}
              label={formatMessage(messages.filterStartTimeLabel)}
              maxDateTime={store.active.filterEndTime}
              onDateTimeChanged={onFormFilterStartTimeChanged}
              value={store.active.filterStartTime ? store.active.filterStartTime : undefined}
              isOutOfBoundsErrorMessage={formatMessage(messages.startDateErrorMessage)}
            />
            <DateTimePicker
              intl={this.props.intl}
              label={formatMessage(messages.filterEndTimeLabel)}
              datePlaceholder={formatMessage(messages.filterEndTimePlaceholder)}
              minDateTime={store.active.filterStartTime}
              onDateTimeChanged={onFormFilterEndTimeChanged}
              value={store.active.filterEndTime ? store.active.filterEndTime : undefined}
              isOutOfBoundsErrorMessage={formatMessage(messages.endDateErrorMessage)}
            />
            {this.props.mode === FormState.Edit ? (
              <Link onClick={(ev) => onFormUndoTimeRange()}>{formatMessage(messages.undoTimeRange)}</Link>
            ) : (
              <Link onClick={(ev) => onFormResetTimeRange()}>{formatMessage(messages.resetTimeRange)}</Link>
            )}
          </FormSection>
          <Panel
            isOpen={getAdManagementStore().panelIsOpen}
            type={PanelType.custom}
            customWidth="700px"
            onDismiss={() => onPanelOpenChanged(false)}
            styles={this._classNames.subComponentStyles.inPagePanel}
            onRenderNavigation={() => (
              <div className={this._classNames.panelHeader} role="navigation">
                <IconButton
                  iconProps={{ iconName: 'Cancel' }}
                  aria-label={formatMessage(AppMessages.close)}
                  onClick={() => onPanelOpenChanged(false)}
                />
              </div>
            )}
          >
            <CreateChannel {...this.props} mode={FormState.InPageCreate} />
          </Panel>
        </div>
      );
    };

    private _onSubmit = (publisherId?: string, accountId?: string): void => {
      this.setState({ initializeForm: false });
      onFormSubmitted(this.props.mode, this.props.history);
    };

    private _onCancel = (publisherId?: string, accountId?: string): void => {
      this.setState({ initializeForm: false });
      onFormCancelled();
      // return to manage page
      const returnUrl = createReturnUrl(RouteName.adfilter, publisherId, accountId);
      onNavigateBack(this.props.history, returnUrl);
    };

    private _onRenderTextFieldDescription = (props: ITextFieldProps): JSX.Element => {
      return <div className={this._classNames.description}>{props.description}</div>;
    };

    private _excludedItemsTextField = (filterAccessPermissions: FilterAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getFilterStore();
      const { helpInfo, locale } = getAppStore();
      return (
        <div className={this._classNames.excludedItemsTextField}>
          <FormItem formWidth={440}>
            <TextField
              errorMessage={store.errors.excludedUrls}
              multiline={true}
              placeholder={formatMessage(messages.excludedUrlPlaceholder)}
              description={formatMessage(messages.excludedUrlDescription)}
              aria-labelledby="filterExcludedUrlLabel"
              onRenderLabel={() => {
                return (
                  <FormLabel
                    id="filterExcludedUrlLabel"
                    labelContent={formatMessage(messages.inputExcludedURLs)}
                    calloutContent={helpInfo}
                    iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_FilterExcludeURL', locale)}
                    calloutOnDismiss={() => onHelpInfoChanged()}
                    intl={this.props.intl}
                  />
                );
              }}
              value={store.active.excludedUrls}
              onChange={(_, value) => onFormExludedUrlChanged(value)}
              onRenderDescription={this._onRenderTextFieldDescription}
              disabled={this._isDisabled(
                this.props.mode,
                filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedURLs
              )}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <TextField
              errorMessage={store.errors.excludedAdKeywords}
              multiline={true}
              placeholder={formatMessage(messages.excludedKeywordPlaceholder)}
              description={formatMessage(messages.excludeAdLevelKeywordsDescription)}
              aria-labelledby="filterExcludedAdKeywordLabel"
              onRenderLabel={() => {
                return (
                  <FormLabel
                    id="filterExcludedAdKeywordLabel"
                    labelContent={formatMessage(messages.inputExcludedKeywordAd)}
                    calloutContent={helpInfo}
                    iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_FilterExcludeKwyAd', locale)}
                    calloutOnDismiss={() => onHelpInfoChanged()}
                    intl={this.props.intl}
                  />
                );
              }}
              value={store.active.excludedAdKeywords}
              onChange={(_, value) => onFormExludedAdKeywordChanged(value)}
              onRenderDescription={this._onRenderTextFieldDescription}
              disabled={this._isDisabled(
                this.props.mode,
                filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedAdKeywords
              )}
            />
          </FormItem>
          <FormItem formWidth={440}>
            <TextField
              errorMessage={store.errors.excludedQueryKeywords}
              multiline={true}
              placeholder={formatMessage(messages.excludedKeywordPlaceholder)}
              description={formatMessage(messages.excludedQueryLevelKeywordDescription)}
              aria-labelledby="filterExcludedQueryKeywordLabel"
              onRenderLabel={() => {
                return (
                  <FormLabel
                    id="filterExcludedQueryKeywordLabel"
                    labelContent={formatMessage(messages.inputExcludedKeywordQuery)}
                    calloutContent={helpInfo}
                    iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_FilterExcludeKwyQuery', locale)}
                    calloutOnDismiss={() => onHelpInfoChanged()}
                    intl={this.props.intl}
                  />
                );
              }}
              value={store.active.excludedQueryKeywords}
              onChange={(_, value) => onFormExludedQueryKeywordChanged(value)}
              onRenderDescription={this._onRenderTextFieldDescription}
              disabled={this._isDisabled(
                this.props.mode,
                filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedQueryKeywords
              )}
            />
          </FormItem>
        </div>
      );
    };

    private _excludedItemsUploadField = (filterAccessPermissions: FilterAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const { helpInfo, locale } = getAppStore();
      const store = getFilterStore();

      return (
        <>
          <FormItem formWidth={440}>
            <FormLabel
              id="filterExcludedUrlBulkLabel"
              labelContent={formatMessage(messages.inputExcludedURLs)}
              calloutContent={helpInfo}
              iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_FilterExcludeURL', locale)}
              calloutOnDismiss={() => onHelpInfoChanged()}
              intl={this.props.intl}
            />
            <BulkUpload
              uploadDescription={formatMessage(messages.excludedUrlBulkUploadDescription)}
              isLoading={this.state.textFileLoading}
              onUpload={(e) => this._uploadFile(e, FilterExcludedType.Url)}
              disabled={this._isDisabled(
                this.props.mode,
                filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedURLs
              )}
              ariaLabelledBy={'filterExcludedUrlBulkLabel'}
            />
            <>
              <ActionButton
                className={this._classNames.exportButton}
                iconProps={{ iconName: 'export' }}
                onClick={() => onFormDownloadClicked('ExcludedURL')}
                disabled={
                  this.props.mode !== FormState.Edit ||
                  this._isDisabled(
                    this.props.mode,
                    filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedURLs
                  ) ||
                  store.active.downloadExcludedUrlLoading
                }
                ariaDescription={formatMessage(messages.exportButtonDesc, { label: formatMessage(messages.inputExcludedURLs) })}
              >
                {formatMessage(AppMessages.export)}
              </ActionButton>
              {store.active.downloadExcludedUrlLoading && <Spinner />}
            </>
          </FormItem>
          <FormItem formWidth={440}>
            <FormLabel
              id="filterExcludedAdKeywordBulkLabel"
              labelContent={formatMessage(messages.inputExcludedKeywordAd)}
              calloutContent={helpInfo}
              iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_FilterExcludeKwyAd', locale)}
              calloutOnDismiss={() => onHelpInfoChanged()}
              intl={this.props.intl}
            />
            <BulkUpload
              uploadDescription={formatMessage(messages.exlcudedKeywordsBulkUploadDescription)}
              isLoading={this.state.textFileLoading}
              onUpload={(e) => this._uploadFile(e, FilterExcludedType.AdKeyword)}
              disabled={this._isDisabled(
                this.props.mode,
                filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedAdKeywords
              )}
              ariaLabelledBy={'filterExcludedAdKeywordBulkLabel'}
            />
            <>
              <ActionButton
                className={this._classNames.exportButton}
                iconProps={{ iconName: 'export' }}
                onClick={() => onFormDownloadClicked('ExcludedAdKeyword')}
                disabled={
                  this.props.mode !== FormState.Edit ||
                  this._isDisabled(
                    this.props.mode,
                    filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedAdKeywords
                  ) ||
                  store.active.downloadExcludedAdkeywordLoading
                }
                ariaDescription={formatMessage(messages.exportButtonDesc, { label: formatMessage(messages.inputExcludedKeywordAd) })}
              >
                {formatMessage(AppMessages.export)}
              </ActionButton>
              {store.active.downloadExcludedAdkeywordLoading && <Spinner />}
            </>
          </FormItem>
          <FormItem formWidth={440}>
            <FormLabel
              id="filterExcludedQueryKeywordBulkLabel"
              labelContent={formatMessage(messages.inputExcludedKeywordQuery)}
              calloutContent={helpInfo}
              iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_FilterExcludeKwyQuery', locale)}
              calloutOnDismiss={() => onHelpInfoChanged()}
              intl={this.props.intl}
            />
            <BulkUpload
              uploadDescription={formatMessage(messages.exlcudedKeywordsBulkUploadDescription)}
              isLoading={this.state.textFileLoading}
              onUpload={(e) => this._uploadFile(e, FilterExcludedType.QueryKeyword)}
              disabled={this._isDisabled(
                this.props.mode,
                filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedQueryKeywords
              )}
              ariaLabelledBy={'filterExcludedQueryKeywordBulkLabel'}
            />
            <>
              <ActionButton
                className={this._classNames.exportButton}
                iconProps={{ iconName: 'export' }}
                onClick={() => onFormDownloadClicked('ExcludedQueryKeyword')}
                disabled={
                  this.props.mode !== FormState.Edit ||
                  this._isDisabled(
                    this.props.mode,
                    filterAccessPermissions === null ? completeAccess : filterAccessPermissions.excludedQueryKeywords
                  ) ||
                  store.active.downloadExcludedQueryKeywordLoading
                }
                ariaDescription={formatMessage(messages.exportButtonDesc, { label: formatMessage(messages.inputExcludedKeywordQuery) })}
              >
                {formatMessage(AppMessages.export)}
              </ActionButton>
              {store.active.downloadExcludedQueryKeywordLoading && <Spinner />}
            </>
          </FormItem>
        </>
      );
    };

    private _uploadFile(event, excludedType: FilterExcludedType) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onloadstart = () => {
        this.setState({ textFileLoading: true });
      };
      // tslint:disable-next-line
      reader.onload = (event: any) => {
        const content = event.target.result;
        switch (excludedType) {
          case FilterExcludedType.Url:
            onFormAppendExcludedUrlsChanged(content);
            break;
          case FilterExcludedType.AdKeyword:
            onFormAppendExcludedAdKeywordsChanged(content);
            break;
          case FilterExcludedType.QueryKeyword:
            onFormAppendExcludedQueryKeywordsChanged(content);
            break;
        }
      };
      reader.onloadend = () => {
        this.setState({ textFileLoading: false });
      };
      if (file) {
        reader.readAsText(file);
      }
    }

    private _getFilterAccessPermission(): FilterAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.Filter) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }

    private _isDisabled(mode: FormState, permission: number): boolean {
      if (mode === FormState.Create || mode === FormState.InPageCreate) {
        return false;
      }

      return !hasUpdateAccess(permission);
    }
  }
);
