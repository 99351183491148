import { Callout, DefaultButton, DirectionalHint, Icon, PrimaryButton } from '@fluentui/react';
import React, { useState } from 'react';
import { useI18n } from '../../../../../i18n/useI18n';
import { getAppStore } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { DATE_RANGE_PICKER_APPLY_BUTTON } from '../../../../constants/DataTestIdConstants';
import { WEEK_DAYS_LANGUAGE_MAPPING } from '../../../../globalization';
import { injectIntlAndObserver } from '../../../../utils';
import { getStore } from '../../../ad-management/Account/Settings/@data/store/store';
import messages from './CustomCalender.messages';
import { getClassNames } from './CustomCalender.styles';
import { ICustomCalenderProps, IDateElements, IDay, RangeType } from './CustomCalender.types';
import { SingleDay } from './components/SingleDay';
import {
  get7DaysExcludingToday,
  getDatesInRange,
  getLastSundayToSaturday,
  getMondayToToday,
  getMonthlyCalendar,
  getPresetDataTestId,
  getSelectedEndingDate,
  getSelectedPeriodLabel,
  getSelectedStartingDate,
  getSundayToToday,
  getToday,
  getYesterday,
  isDayInFuture,
} from './utils/CalenderUtils';

const END_OF_YEAR_INDEX = 11;
const START_OF_YEAR_INDEX = 0;

export const CustomCalender = injectIntlAndObserver(function CustomCalenderComponent(props: ICustomCalenderProps): JSX.Element {
  const { onSelectedDatesChanged, initialRangeType, initialDateRange, intl, disableFutureDays = true, dataTestId } = props;
  const { formatMessage } = intl;
  const [selectedDateRange, setSelectedDateRange] = useState<Date[]>(getDatesInRange(initialDateRange.startDate, initialDateRange.endDate));
  const [finalDateRange, setFinalDateRange] = useState<Date[]>(getDatesInRange(initialDateRange.startDate, initialDateRange.endDate));
  const [hoveredDateRange, setHoveredDateRange] = useState<Date[]>([]);
  const [turn, setTurn] = useState(0);
  const [isCalenderVisible, setIsCalenderVisible] = useState(false);
  const [rangeType, setRangeType] = useState(initialRangeType);
  const [finalRangeType, setFinalRangeType] = useState(initialRangeType);
  const { publisher, userContext } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });
  const options: Intl.DateTimeFormatOptions = {};
  options.year = 'numeric';
  options.month = 'short';
  const optionsDays: Intl.DateTimeFormatOptions = {};
  optionsDays.weekday = 'narrow';
  const WEEK_DAYS = WEEK_DAYS_LANGUAGE_MAPPING[getAppStore().locale];

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const {
    calenderContainer,
    calenderRangeSelectorsContainer,
    calenderRangeSelector,
    calenderRangeSelectedSelector,
    calenderRangeSelectorDateContainer,
    calenderRangeSelectorDate,
    calenderButton,
    calender,
    calenderBody,
    calenderNavigator,
    calenderMonthLabel,
    daysGrid,
    calenderHeader,
    actions,
  } = getClassNames();

  const nextMonth = () => {
    if (currentMonth < END_OF_YEAR_INDEX) {
      setCurrentMonth((prev) => prev + 1);
    } else {
      setCurrentMonth(START_OF_YEAR_INDEX);
      setCurrentYear((prev) => prev + 1);
    }
  };

  const prevMonth = () => {
    if (currentMonth > START_OF_YEAR_INDEX) {
      setCurrentMonth((prev) => prev - 1);
    } else {
      setCurrentMonth(END_OF_YEAR_INDEX);
      setCurrentYear((prev) => prev - 1);
    }
  };

  const handleDateRangeSelection = (selectedDate: IDateElements) => {
    if (turn === 0) {
      setTurn(1);
      setSelectedDateRange([new Date(selectedDate.year, selectedDate.month, selectedDate.day)]);
      setHoveredDateRange([new Date(selectedDate.year, selectedDate.month, selectedDate.day)]);
    } else if (turn === 1) {
      let dates = getDatesInRange(selectedDateRange[0], new Date(selectedDate.year, selectedDate.month, selectedDate.day));
      if (dates.length === 0) {
        dates = [selectedDateRange[0]];
      }
      if (dates.length === 1) {
        dates = [selectedDateRange[0], selectedDateRange[0]];
      }
      setTurn(0);
      setSelectedDateRange(dates);
      setHoveredDateRange([]);
      setRangeType(RangeType.Custom);
    }
  };

  const handleDateRangeHover = (date: IDateElements) => {
    const days = getDatesInRange(hoveredDateRange[0], new Date(date.year, date.month, date.day));
    setHoveredDateRange(days.length === 0 ? [selectedDateRange[0]] : days);
  };

  const getRangeSelectorContent = (type: RangeType, message: ReactIntl.FormattedMessage.MessageDescriptor, clickable: boolean = true) => {
    const getRange = (selector: RangeType) => {
      setRangeType(selector);
      let dateRange;
      switch (selector) {
        case RangeType.Today:
          dateRange = getToday();
          break;
        case RangeType.Yesterday:
          dateRange = getYesterday();
          break;
        case RangeType.SundayToday:
          dateRange = getSundayToToday();
          break;
        case RangeType.MondayToday:
          dateRange = getMondayToToday();
          break;
        case RangeType.Last7ExcludingToday:
          dateRange = get7DaysExcludingToday();
          break;
        case RangeType.Last7FromSunToSat:
          dateRange = getLastSundayToSaturday();
          break;
        default:
          break;
      }
      setSelectedDateRange(dateRange);
    };

    return (
      <span
        data-test-id={getPresetDataTestId(type)}
        className={`${calenderRangeSelector} ${rangeType === type ? calenderRangeSelectedSelector : ''}`}
        onClick={() => clickable && getRange(type)}
      >
        {formatMessage(message)}
      </span>
    );
  };

  const getCalenderContent = (year: number, month: number) => {
    return (
      <div className={daysGrid}>
        {WEEK_DAYS.map((day, index) => (
          <p key={index}>{day}</p>
        ))}
        {getMonthlyCalendar(year, month).map((day: IDay, index) => (
          <SingleDay
            {...props}
            key={index}
            dayObj={day}
            turn={turn}
            isDisabled={disableFutureDays && isDayInFuture(day.fullDay)}
            handleDateRangeSelection={handleDateRangeSelection}
            selectedDateRange={selectedDateRange}
            hoveredDateRange={hoveredDateRange}
            handleDateRangeHover={handleDateRangeHover}
          />
        ))}
      </div>
    );
  };
  const resetCalender = () => {
    setSelectedDateRange(finalDateRange);
    setRangeType(finalRangeType);
  };
  const hideCalender = () => {
    setIsCalenderVisible(false);
  };
  const onApplyClicked = () => {
    onSelectedDatesChanged({ startDate: selectedDateRange[0], endDate: selectedDateRange[selectedDateRange.length - 1] }, rangeType);
    setFinalDateRange(selectedDateRange);
    setFinalRangeType(rangeType);
    hideCalender();
  };
  const onDismiss = () => {
    resetCalender();
    hideCalender();
  };

  return (
    <>
      <div
        data-test-id={dataTestId}
        id={'customCalender'}
        className={calenderButton}
        onClick={() => setIsCalenderVisible(!isCalenderVisible)}
      >
        <span>
          {getSelectedPeriodLabel(rangeType, formatMessage)}
          {getSelectedStartingDate(selectedDateRange, locale)}
          {getSelectedEndingDate(selectedDateRange, locale)}
        </span>
        <Icon iconName="Calendar" />
      </div>
      {isCalenderVisible && (
        <Callout target={'#customCalender'} isBeakVisible={false} onDismiss={onDismiss} directionalHint={DirectionalHint.bottomRightEdge}>
          <div className={calenderContainer}>
            <div className={calenderRangeSelectorsContainer}>
              <div className={calenderRangeSelectorDateContainer}>
                <span className={calenderRangeSelectorDate}>{selectedDateRange[0].toLocaleDateString(locale)}</span>
                {' - '}
                <span className={calenderRangeSelectorDate}>
                  {selectedDateRange[selectedDateRange.length - 1].toLocaleDateString(locale)}
                </span>
              </div>
              {getRangeSelectorContent(RangeType.Custom, messages.customLabel, false)}
              {getRangeSelectorContent(RangeType.Today, messages.todayLabel)}
              {getRangeSelectorContent(RangeType.Yesterday, messages.yesterdayLabel)}
              {getRangeSelectorContent(RangeType.SundayToday, messages.sundayTodayLabel)}
              {getRangeSelectorContent(RangeType.MondayToday, messages.mondayTodayLabel)}
              {getRangeSelectorContent(RangeType.Last7ExcludingToday, messages.last7FromTodayLabel)}
              {getRangeSelectorContent(RangeType.Last7FromSunToSat, messages.last7FromSunToSatLabel)}
            </div>
            <div className={calender}>
              <div className={calenderHeader}>
                <Icon iconName="ChevronLeft" className={calenderNavigator} onClick={prevMonth} />
                <span className={calenderMonthLabel}>
                  {new Date(
                    currentMonth - 1 >= 0 ? currentYear : currentYear - 1,
                    currentMonth - 1 >= 0 ? currentMonth - 1 : 11
                  ).toLocaleDateString(locale, options)}
                </span>
                <span className={calenderMonthLabel}>{new Date(currentYear, currentMonth).toLocaleDateString(locale, options)}</span>
                <Icon iconName="ChevronRight" className={calenderNavigator} onClick={nextMonth} />
              </div>
              <div className={calenderBody}>
                {getCalenderContent(currentMonth - 1 >= 0 ? currentYear : currentYear - 1, currentMonth - 1 >= 0 ? currentMonth - 1 : 11)}
                {getCalenderContent(currentYear, currentMonth)}
              </div>
              <div className={actions}>
                <PrimaryButton data-test-id={DATE_RANGE_PICKER_APPLY_BUTTON} onClick={onApplyClicked}>
                  {formatMessage(AppMessages.apply)}
                </PrimaryButton>
                <DefaultButton onClick={onDismiss}>{formatMessage(AppMessages.cancel)}</DefaultButton>
              </div>
            </div>
          </div>
        </Callout>
      )}
    </>
  );
});
