import { createStore } from 'satcheljs';
import { IPartnerManagementViewState } from './schema/IPartnerManagementViewState';

export enum RouteName {
  default = 'default',
  publisher = 'publisher',
  account = 'account',
  user = 'user'
}

export const defaultRoute = RouteName.publisher;

export const getPageStore = createStore<IPartnerManagementViewState>('PartnerManagementViewState', {
  loading: false,
  invalid: false,
  panelIsOpen: false
});
