import { ReactElement } from 'react';
import { HomePageStatus } from '../../@data/store/schema/IHomeViewState';

export interface ISetupCard {
  title: string;
  description: string;
  image?: string;
  imageAlt?: string;
  status?: HomePageStatus;
  button?: ReactElement;
  steps?: number;
  hidden?: boolean;
  onLoad?: () => void;
  cardType?: SetupCardType;
}

export enum SetupCardType {
  GetStarted = 'GetStarted',
  Recommendations = 'Recommendations',
}
