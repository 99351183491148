import {
  Announced,
  BaseComponent,
  buildColumns,
  classNamesFunction,
  ColumnActionsMode,
  CommandBar,
  ConstrainMode,
  ContextualMenu,
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  IButtonProps,
  IColumn,
  ICommandBarProps,
  IContextualMenuProps,
  IDetailsHeaderProps,
  IDetailsList,
  IProcessedStyleSet,
  IRenderFunction,
  IScrollablePane,
  ITextField,
  ITooltipHostProps,
  Label,
  nullRender,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import * as React from 'react';
import { getAppStore, LifeCycleStatus, Model, onHelpInfoCalloutOpened, onHelpInfoChanged } from '../../@data';
import { FilterCondition } from '../../@data/store/schema/enums/FilterCondition';
import appMessages from '../../App.messages';
import { getSelectionMode, sortItemsBasedOnColumn } from '../../utils/DataGridUtils';
import { FormItem } from '../Form';
import { GridFilter } from '../GridFilter/GridFilter';
import { FilterType, IFilterDetails, IGridFilter } from '../GridFilter/GridFilter.types';
import { InfoToggle } from '../InfoToggle';
import messages from './DataGrid.messages';
import { IDataGrid, IDataGridProps, IDataGridStyleProps, IDataGridStyles } from './DataGrid.types';

const getClassNames = classNamesFunction<IDataGridStyleProps, IDataGridStyles>();

export enum DataGridSelectionMode {
  None,
  Single,
  Multiple,
  All,
}

interface IDataGridState {
  displayedItems: Model[];
  lastSortedColumn?: IColumn;
  selectedCommandBarProps: ICommandBarProps;
  isModalSelection?: boolean;
  announcedMessage?: string;
  filterApplied?: boolean;
  isResizeDialogOpen?: boolean;
  columnHeaderMenuProps?: IContextualMenuProps;
}

export class DataGridBase extends BaseComponent<IDataGridProps, IDataGridState> implements IDataGrid {
  private _scrollablePane = React.createRef<IScrollablePane>();
  private _selection: Selection;
  private _classNames: IProcessedStyleSet<IDataGridStyles>;
  private _gridFilter = React.createRef<IGridFilter>();
  private _columnToEdit: IColumn | null;
  private _columnWidthFieldRef = React.createRef<ITextField>();
  private _detailsListRef = React.createRef<IDetailsList>();

  constructor(props: IDataGridProps) {
    super(props);
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectedCommandBarProps: this._buildCommandPropsOnSelection(),
        });
      },
    });
    let items = this.props.detailListProps.items;
    this.state = {
      selectedCommandBarProps: this._buildCommandPropsOnSelection(),
      displayedItems: items,
      isModalSelection: false,
      announcedMessage: undefined,
      filterApplied: false,
      isResizeDialogOpen: false,
    };

    const columns = this._buildDataGridColumns();
    if (columns.length >= 1) {
      // Default to sorting by columns[0] ascending if no specific column is specified.
      const selectedSortColumn = this.props.sortByColumn
        ? columns.find((element) => this.props.sortByColumn !== undefined && element.key === this.props.sortByColumn.columnKeyToSortBy)
        : undefined;
      const lastSortedColumn = selectedSortColumn ? selectedSortColumn : columns[0];
      lastSortedColumn.isSorted = true;

      // Default is sort by ascending unless a valid column is selected and sort order is selected.
      lastSortedColumn.isSortedDescending =
        selectedSortColumn && this.props.sortByColumn && this.props.sortByColumn.isSortedDescending !== undefined
          ? this.props.sortByColumn.isSortedDescending
          : false;

      // Modify data to be suitable for UI display using displayFunction sent for some columns
      items = this.modifyDataUsingDisplayFunction(items);

      this.state = {
        selectedCommandBarProps: this._buildCommandPropsOnSelection(),
        lastSortedColumn: lastSortedColumn,
        displayedItems: sortItemsBasedOnColumn(items, lastSortedColumn),
      };
    }
  }

  public componentDidUpdate(prevProps: IDataGridProps, prevState: IDataGridState) {
    if (this.props.detailListProps.items === prevProps.detailListProps.items) {
      return;
    }

    // Data has been changed, modifying and sorting data before re-rendering the data grid.
    let items = this.props.detailListProps.items;
    items = this.modifyDataUsingDisplayFunction(items);

    this.setState({
      displayedItems: sortItemsBasedOnColumn(items, prevState.lastSortedColumn),
    });

    // reset grid filter when grid data changes
    if (this._gridFilter.current) {
      this._gridFilter.current.resetGridFilter();
    }

    // add 'aria-busy' to detail list component to enhance accessibility capacity
    if (this._detailsListRef.current) {
      const detailListWrapper = document.querySelector('div[role="row"].ms-DetailsHeader[data-automationid="DetailsHeader"]');
      if (detailListWrapper && !detailListWrapper.hasAttribute?.('aria-busy')) {
        detailListWrapper.setAttribute('aria-busy', 'true');
      }
    }
  }

  public getSelection(): Selection {
    return this._selection;
  }

  public getDisplayedItems(): Model[] {
    return this.state.displayedItems;
  }

  public render(): JSX.Element {
    const { detailListProps, styles, theme, filterProps, dataIsLoading, helpInfo, dataGridfilterProps, searchResultsVisible } = this.props;
    const { displayedItems, lastSortedColumn, isModalSelection, announcedMessage, filterApplied } = this.state;
    const columns = this._buildDataGridColumns(lastSortedColumn, detailListProps.items);
    const commandBarProps = this._buildCommandPropsOnSelection();
    const displayedItemCount = displayedItems.length;
    this._classNames = getClassNames(styles, { theme: theme! });
    const { formatMessage } = this.props.intl;
    const { locale } = getAppStore();

    let filterField = nullRender();
    if (filterProps) {
      const {
        searchBoxPlaceholder,
        searchBoxVisible,
        onItemDisplayedLabel,
        onChange,
        searchBoxValue,
        searchBoxAriaLabel,
        onChangeText,
      } = filterProps;
      filterField = (
        <div className={this._classNames.filter}>
          {searchBoxVisible && (
            <FormItem>
              <SearchBox
                className={this._classNames.filterItem}
                onSearch={(newValue) => onChange(newValue)}
                onClear={() => onChange('')}
                onChange={(event, newValue) => onChangeText(event, newValue)}
                placeholder={searchBoxPlaceholder}
                value={searchBoxValue}
                ariaLabel={searchBoxAriaLabel}
              />
            </FormItem>
          )}
          <Label className={this._classNames.filterItem}> {onItemDisplayedLabel(displayedItemCount)} </Label>
        </div>
      );
    }

    return (
      <div className={this._classNames.root}>
        {filterField}
        <CommandBar
          {...commandBarProps}
          styles={this._classNames.subComponentStyles.commandBar}
          ariaLabel={formatMessage(messages.commandBarNavigationHelpArialLabel)}
        />
        {searchResultsVisible ? (
          <Label className={this._classNames.searchResults}> {'Search Results: ' + displayedItemCount} </Label>
        ) : null}
        {dataGridfilterProps ? (
          <GridFilter
            intl={this.props.intl}
            componentRef={this._gridFilter}
            {...dataGridfilterProps}
            onFilterApplied={this._onFilterApplied}
          />
        ) : null}
        <InfoToggle
          label={formatMessage(messages.selectTextToggleLabel)}
          intl={this.props.intl}
          checked={isModalSelection}
          onChange={this._onChangeModalSelection}
          calloutContent={helpInfo}
          iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_SelectMode', locale)}
          calloutOnDismiss={() => onHelpInfoChanged()}
          ariaDescription={formatMessage(messages.selectTextToggleAriaDesc)}
        />
        <ScrollablePane
          componentRef={this._scrollablePane}
          scrollbarVisibility={ScrollbarVisibility.auto}
          styles={this._classNames.subComponentStyles.scrollablePane}
        >
          {filterApplied ? (
            <Announced
              message={this.props.intl.formatMessage(messages.numberOfItemsAfterFilterLabel, { displayItems: displayedItemCount })}
            />
          ) : undefined}
          {announcedMessage ? <Announced message={announcedMessage} /> : undefined}
          <ShimmeredDetailsList
            {...detailListProps}
            componentRef={this._detailsListRef}
            items={!this.props.notReadyProps ? displayedItems : []}
            columns={columns}
            onColumnHeaderClick={this._onColumnHeaderClick}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={this._onRenderDetailsHeader}
            selection={this._selection}
            selectionMode={isModalSelection ? SelectionMode.none : undefined}
            selectionPreservedOnEmptyClick={true}
            enableShimmer={dataIsLoading}
            ariaLabelForSelectAllCheckbox={formatMessage(messages.ariaLabelForSelectAllCheckbox)}
            ariaLabelForSelectionColumn={formatMessage(messages.ariaLabelForSelectionColumn)}
            ariaLabelForGrid={this.props.ariaLabel}
            checkButtonAriaLabel={formatMessage(messages.checkButtonAriaLabel)}
            aria-busy="true"
          />
          {this.state.columnHeaderMenuProps && <ContextualMenu {...this.state.columnHeaderMenuProps} />}
          <Dialog
            hidden={!this.state.isResizeDialogOpen}
            onDismiss={this._hideResizeDialog}
            dialogContentProps={this._getResizeDialogContentProps()}
          >
            <TextField
              type="number"
              componentRef={this._columnWidthFieldRef}
              ariaLabel={formatMessage(messages.columnWidthFieldLabel)}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  this._resizeColumn();
                }
              }}
            />
            <DialogFooter>
              <PrimaryButton onClick={this._resizeColumn} text={formatMessage(messages.resizeButtonLabel)} />
              <DefaultButton onClick={this._hideResizeDialog} text={formatMessage(appMessages.cancel)} />
            </DialogFooter>
          </Dialog>
        </ScrollablePane>
      </div>
    );
  }

  private _hideResizeDialog = () => this.setState({ isResizeDialogOpen: false });
  private _showResizeDialog = () => this.setState({ isResizeDialogOpen: true });

  private _handleResizeButtonClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
    ev.stopPropagation();

    this._columnToEdit = column;
    this._showResizeDialog();
  };

  private _onColumnHeaderClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    this.setState({ columnHeaderMenuProps: this._getContextualMenuProps(ev, column) });
  };

  private _getContextualMenuProps = (ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps => {
    const { formatMessage } = this.props.intl;
    const items = [
      { key: 'resize', text: formatMessage(messages.resizeButtonLabel), onClick: () => this._handleResizeButtonClick(ev, column) },
      { key: 'sort', text: formatMessage(messages.sortButtonLabel), onClick: () => this._handleSortButtonClick(ev, column) },
    ];

    return {
      items: items,
      target: ev.currentTarget as HTMLElement,
      gapSpace: 10,
      isBeakVisible: true,
      onDismiss: () => this.setState({ columnHeaderMenuProps: undefined }),
    };
  };

  private _getResizeDialogContentProps = () => {
    const { formatMessage } = this.props.intl;
    const { columnResizeDialogTitle, columnResizeDialogDescription } = messages;

    return {
      type: DialogType.normal,
      title: formatMessage(columnResizeDialogTitle),
      closeButtonAriaLabel: formatMessage(appMessages.close),
      subText: formatMessage(columnResizeDialogDescription),
    };
  };

  private _resizeColumn = () => {
    const column = this._columnToEdit;
    if (!column) {
      return;
    }

    this._detailsListRef.current?.updateColumn(column, { width: Number(this._columnWidthFieldRef.current?.value) });
    this._hideResizeDialog();
    this._columnToEdit = null;
  };

  private modifyDataUsingDisplayFunction = (items: Model[]) => {
    const { columns } = this.props.detailListProps;

    if (columns && columns.length >= 1) {
      columns
        .filter((col) => col.displayFunction !== undefined)
        .forEach((col) => {
          items.forEach((item) => {
            item[col.columnName] = col.displayFunction ? col.displayFunction(item[col.columnName], item) : item[col.columnName];
          });
        });
    }

    return items;
  };

  private _onFilterApplied = (filterList: IFilterDetails[]): void => {
    // displayed items might already be filtered, hence to modify filters considering original items
    const { items } = this.props.detailListProps;
    let filteredItems = sortItemsBasedOnColumn(items, this.state.lastSortedColumn);

    filterList.forEach((filterCol) => {
      if (filterCol.customFunction) {
        filteredItems = filteredItems.filter((dataRow: Model) =>
          filterCol.customFunction ? filterCol.customFunction(dataRow, filterCol) : false
        );
      } else {
        filteredItems = this._applyFilter(filteredItems, filterCol);
      }
    });
    this.setState({
      displayedItems: filteredItems,
      filterApplied: true,
    });
  };

  private _applyFilter = (items: Model[], filterColumn: IFilterDetails): Model[] => {
    if (filterColumn.columnName !== undefined) {
      const filteredItems = items.filter((item: object) => {
        const val = item[filterColumn.columnName || ''];

        switch (filterColumn.filterType) {
          case FilterType.custom:
            return filterColumn.filterCondition ? val === filterColumn.filterCondition : true;

          case FilterType.date: {
            const dateVal = new Date(val);
            const { startDate, endDate } = filterColumn;

            if (startDate || endDate) {
              if (startDate && endDate) {
                return dateVal >= startDate && dateVal <= endDate;
              }
              if (endDate) {
                return dateVal <= endDate;
              }
              if (startDate) {
                return dateVal >= startDate;
              }
            }
            return true;
          }

          case FilterType.relational: {
            const numVal = Number(val);

            if (filterColumn.filterText) {
              const filterNumVal = Number(filterColumn.filterText);
              switch (filterColumn.filterCondition) {
                case FilterCondition.Equals:
                  return numVal === filterNumVal;
                case FilterCondition.DoesNotEqual:
                  return numVal !== filterNumVal;
                case FilterCondition.GreaterThan:
                  return numVal > filterNumVal;
                case FilterCondition.GreaterThanEqual:
                  return numVal >= filterNumVal;
                case FilterCondition.LessThan:
                  return numVal < filterNumVal;
                case FilterCondition.LessThanEqual:
                  return numVal <= filterNumVal;
              }
            }
            return true;
          }

          case FilterType.string: {
            const filterVal = filterColumn.filterText!.toLocaleLowerCase();
            const strVal = String(val).toLocaleLowerCase();
            switch (filterColumn.filterCondition) {
              case FilterCondition.Contains:
                return strVal.indexOf(filterVal) !== -1;
              case FilterCondition.DoesNotContain:
                return strVal.indexOf(filterVal) === -1;
              case FilterCondition.BeginsWith:
                return strVal.indexOf(filterVal) === 0;
              case FilterCondition.EndsWith: {
                const lastIndex = strVal.lastIndexOf(filterVal);
                return lastIndex === strVal.length - filterVal.length;
              }
              case FilterCondition.Equals:
                return strVal === filterVal;
              case FilterCondition.DoesNotEqual:
                return strVal !== filterVal;
            }
          }
        }
        return val.toLocaleLowerCase().indexOf(filterColumn.filterText!.toLocaleLowerCase()) !== -1;
      });

      return filteredItems;
    }
    // in case column name is not passed, filter cannot be applied.
    return items;
  };

  private _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
    this.setState({ isModalSelection: checked });
  };

  // TODO: once we have all the ManagePages pass the column mapping, then we can remove this default buildcolumn behavior from the list
  private _buildDataGridColumns(lastSortedColumn?: IColumn, list?: Model[]): IColumn[] {
    const { formatMessage } = this.props.intl;
    const columnModel = this.props.detailListProps.columns;

    if (!columnModel && list) {
      return buildColumns(list, true).map((c) => {
        c.isCollapsible = false;
        return c;
      });
    }
    const newColumns: IColumn[] = [];
    if (columnModel) {
      // traversing over the columnModel will ensure order and columns for which we dont have value in items
      columnModel.forEach((column) => {
        if (column.isVisible) {
          let isSorted = false;
          if (lastSortedColumn && lastSortedColumn.key === column.columnName) {
            isSorted = true;
          }
          const newColumn: IColumn = {
            key: column.columnName,
            name: column.friendlyName,
            fieldName: column.columnName,
            isCollapsible: false,
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
            isPadded: true,
            isSorted: isSorted,
            isMultiline: true,
            columnActionsMode: ColumnActionsMode.hasDropdown,
            isSortedDescending: isSorted ? lastSortedColumn!.isSortedDescending : undefined,
            ariaLabel: formatMessage(messages.columnAriaLabel, { columnName: column.friendlyName }),
            sortAscendingAriaLabel: formatMessage(messages.columnSortAscendingAriaLabel, { columnName: column.friendlyName }),
            sortDescendingAriaLabel: formatMessage(messages.columnSortDescendingAriaLabel, { columnName: column.friendlyName }),
          };
          newColumns.push(newColumn);
        }
      });
    }
    return newColumns;
  }

  private _buildCommandPropsOnSelection(): ICommandBarProps {
    const overflowButtonProps: IButtonProps = { ariaLabel: this.props.intl.formatMessage(appMessages.moreOptionsButtonAriaLabel) };
    const commandBarProps: ICommandBarProps = { items: [], overflowButtonProps };

    if (this.props.commandBarProps) {
      const mode = getSelectionMode(this._selection.count);
      const hasSelection = (item) => !item.selectionModes || item.selectionModes.includes(mode);
      const hasStatusSelection = (item) => !item.selectionStatus || this._hasStatusinSelection(item.selectionStatus);
      commandBarProps.items = this.props.commandBarProps.items.filter((item) => hasSelection(item) && hasStatusSelection(item));
      if (this.props.commandBarProps.farItems) {
        commandBarProps.farItems = this.props.commandBarProps.farItems.filter((item) => hasSelection(item) && hasStatusSelection(item));
      }
    }

    return commandBarProps;
  }

  private _hasStatusinSelection(status: LifeCycleStatus): boolean {
    const selectedIndices = this._selection.getSelectedIndices();
    const items = this._selection.getItems() as Model[];
    return selectedIndices.some((selectedIndex) => status === items[selectedIndex].status);
  }

  private _handleSortButtonClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
    if (!ev || !column) {
      return;
    }
    let isSortedDescending = column.isSortedDescending;
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    column.isSortedDescending = isSortedDescending;

    const { displayedItems } = this.state;

    this.setState({
      lastSortedColumn: column,
      displayedItems: sortItemsBasedOnColumn(displayedItems, column),
      announcedMessage: column.isSortedDescending
        ? this.props.intl.formatMessage(messages.columnSortDescendingAriaLabel, { columnName: column.name })
        : this.props.intl.formatMessage(messages.columnSortAscendingAriaLabel, { columnName: column.name }),
    });
  };

  private _onRenderDetailsHeader = (
    props?: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ): JSX.Element | null => {
    let notReadyPropsRenderer: JSX.Element | undefined;

    if (this.props.notReadyProps !== undefined) {
      notReadyPropsRenderer = (
        <React.Fragment>
          <div className={this._classNames.notReadyLabel}>{this.props.notReadyProps.notReadyLabel}</div>
        </React.Fragment>
      );
    }
    if (!props || !defaultRender) {
      return nullRender();
    }

    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        {defaultRender({
          ...props,
          onRenderColumnHeaderTooltip: (tooltipHostProps?: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />,
        })}
        {notReadyPropsRenderer}
      </Sticky>
    );
  };
}
