import { useMemo } from 'react';
import { AdSenseOAuthStatus } from '../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { getGoogleAdSenseStore } from '../../ad-management/Account/Settings/components/GoogleConnector/@data';

export const useMediationAvailable = () => {
  const { active } = getGoogleAdSenseStore();
  const isMediationAvailable = useMemo(() => active?.connectionStatus === AdSenseOAuthStatus.Success && active?.isMediationEnabled, [
    active.connectionStatus,
    active.isMediationEnabled,
  ]);
  return { isMediationAvailable };
};
