import { uiConfig } from '../../config/ui.config';
import { IColumnMemberDetails, IMetadataElement } from '../../pages/report/DashboardCards/@data/store/schema';
import { ReportColumnMembersParams } from '../store/schema/models/ReportColumnMembersParams';
import { ReportingDataRequestBody } from '../store/schema/models/ReportingDataRequestBody';
import { ReportingDataResponse } from '../store/schema/models/ReportingDataResponse';
import { handleApiErrors, handleError } from '../utils/handleApiErrors';
import { buildRequestHeadersWithAuthToken } from '../utils/requestUtils';
import { ApiError } from './ApiService';

export async function getReportData(
  requestBody: ReportingDataRequestBody,
  abortController: AbortController
): Promise<{ data: ReportingDataResponse; aborted: boolean }> {
  const baseUrl = `${uiConfig.getReportingApiBaseAddress()}`;
  const redirectUrl = 'data';
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
      signal: abortController.signal,
    });
    if (!response.ok) {
      return handleApiErrors<{ data: ReportingDataResponse; aborted: boolean }>(response, url);
    }

    const responseJson = await response.json();
    if (responseJson) {
      return { data: responseJson, aborted: false };
    }
    return Promise.resolve({ aborted: false } as { data: ReportingDataResponse; aborted: boolean });
  } catch (err) {
    if (err.name === 'AbortError') {
      return Promise.resolve({ aborted: true } as { data: ReportingDataResponse; aborted: boolean });
    }
    const error = `getReportData operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getDimensions(reportType: string): Promise<IMetadataElement[]> {
  const baseUrl = `${uiConfig.getReportingApiBaseAddress()}`;
  const redirectUrl = `metadata/dimensions?reportType=${reportType}`;
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IMetadataElement[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getDimensions operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getMetrics(reportType: string): Promise<IMetadataElement[]> {
  const baseUrl = `${uiConfig.getReportingApiBaseAddress()}`;
  const redirectUrl = `metadata/metrics?reportType=${reportType}`;
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IMetadataElement[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getMetrics operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getGranularity(reportType: string): Promise<IMetadataElement[]> {
  const baseUrl = `${uiConfig.getReportingApiBaseAddress()}`;
  const redirectUrl = `metadata/grain?reportType=${reportType}`;
  const url = baseUrl + redirectUrl;

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IMetadataElement[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getGranularity operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getColumnMembers(requestParams: ReportColumnMembersParams): Promise<IColumnMemberDetails[]> {
  const baseUrl = `${uiConfig.getReportingApiBaseAddress()}`;
  const redirectUrl = `metadata/columnMembers?${new URLSearchParams(requestParams)}`;
  const url = baseUrl + encodeURIComponent(redirectUrl);

  const headers = await buildRequestHeadersWithAuthToken(url);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      return handleApiErrors<IColumnMemberDetails[]>(response, url);
    }

    const responseJson = await response.json();

    if (responseJson) {
      return responseJson;
    }
    return Promise.resolve([]);
  } catch (err) {
    const error = `getColumnMembers operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
