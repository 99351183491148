import { defineMessages } from 'react-intl';

export default defineMessages({
  faqTitle: {
    id: 'faq.title',
    defaultMessage: 'FAQ: Setup and Activation',
  },
  firstQuestion: {
    id: 'first.question',
    defaultMessage: 'How do I activate my {appName} account?',
  },
  firstAnswer: {
    id: 'first.answer',
    defaultMessage: `You can take the following steps to activate your account:
    1. Submit your profile for review and approval.
    2. Connect and submit your site for review.
    3. Ensure that an ad unit status is actively serving.
    Upon successful completion of these steps, your account will be activated, and your site will be eligible to show ads.`,
  },
  secondQuestion: {
    id: 'second.question',
    defaultMessage: 'Can I earn money from my website if I’m located outside of the United States?',
  },
  secondAnswer: {
    id: 'second.answer',
    defaultMessage:
      'No, we only provide access to publishers with active bank accounts in the United States. If you’re located outside of the United States and wish to express your interest, please connect with us ',
  },
  thirdQuestion: {
    id: 'third.question',
    defaultMessage: 'Can I earn money from multiple sites using one account?',
  },
  thirdAnswer: {
    id: 'third.answer',
    defaultMessage: 'Yes, you can add multiple sites to your account. After completing our site review, your site will be ready to earn.',
  },
  fourthQuestion: {
    id: 'fourth.question',
    defaultMessage: 'Why is my website status pending? What can I do to change its status?',
  },
  fourthAnswer: {
    id: 'fourth.answer',
    defaultMessage:
      'Your website may be pending because your profile review isn’t complete. Once your profile review is complete, we’ll continue with your site review and your website status will reflect this change.',
  },
  fifthQuestion: {
    id: 'fifth.question',
    defaultMessage: 'Can I correct an error that I made while submitting my profile?',
  },
  fifthAnswer: {
    id: 'fifth.answer',
    defaultMessage:
      'Once your profile is submitted, you won’t be able to edit until the review process is finalized. If you need to make changes to your profile during the review process, please ',
  },
  contactSupport: {
    id: 'contact.support',
    defaultMessage: 'contact support.',
  },
  redirectUser: {
    id: 'redirect.user',
    defaultMessage: 'here.',
  },
});
