import { FontSizes, FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../../../theme';
import { WIDTH } from '../../SignUpPage';
import { IAccountTypeStyles } from './AccountType.types';

export const getStyles = (): IAccountTypeStyles => {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const font = FontSizes;
  const weight = FontWeights;
  const { ResponsiveSmall } = BreakPoints;

  return mergeStyleSets({
    rootStyle: {
      padding: '24px 16px 24px 16px',
      position: 'relative',
      marginTop: spacing.size12,
      color: palette.black,
      width: WIDTH,
    },
    headerStyle: {
      fontSize: font.size20,
      fontWeight: weight.semibold,
      lineHeight: '28px',
      textAlign: 'left',
      marginBottom: spacing.size12,
      [ResponsiveSmall]: {
        width: 'calc(100% - 20px)',
      },
    },
    subheaderStyle: {
      fontSize: font.size14,
      marginTop: spacing.size20,
      marginBottom: spacing.size20,
      textAlign: 'left',
      [ResponsiveSmall]: {
        width: 'calc(100% - 20px)',
      },
    },
    contentStyle: {
      fontSize: font.size14,
      zIndex: -1,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    choiceContentStyle: {
      marginBottom: spacing.size20,
    },
    imageStyle: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '5%',
      marginBottom: '5%',
    },
    contentTitleStyle: {
      position: 'inherit',
      marginTop: spacing.size16,
      marginBottom: spacing.size8,
      fontSize: '18px',
      fontWeight: weight.semibold,
    },
    contentInfoStyle: {
      position: 'inherit',
      width: '256px',
      height: '60px',
      lineHeight: '20px',
    },
    contentListStyle: {
      lineHeight: '24px',
      zIndex: -1,
      textAlign: 'left',
    },
    contentMoreStyle: {
      lineHeight: '12px',
      zIndex: -1,
      marginTop: '35px',
      marginBottom: '35px',
    },
    accountSwitchStyle: {
      textAlign: 'left',
    },
    accountButtonStyle: {
      color: palette.themePrimary,
      height: spacing.size20,
    },
  });
};
