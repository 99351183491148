import { action, orchestrator } from 'satcheljs';

import {
  ApiError,
  ClarityStatus,
  Property,
  getAppStore,
  isTythonSelfServeUser,
  onLoadingChanged,
  patchEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../../@data';
import { UpdateClarityProject } from '../../../../../../@data/store/schema/models/UpdateClarityProject';
import { setPropertyData } from '../../../Manage/__data__/mutatorActions';
import { getGridPropertyStore } from '../../../Manage/__data__/store/store';
import PropertyMessages from '../../../Property.messages';

export const onResendClarityLink = action('onResendClarityLink', (propertyId: string, clarityId: string, clarityEnabled?: boolean) => ({
  propertyId,
  clarityId,
  clarityEnabled,
}));

orchestrator(onResendClarityLink, async ({ propertyId, clarityId, clarityEnabled }) => {
  const { publisher, userContext } = getAppStore();
  const { data } = getGridPropertyStore();

  if (publisher && propertyId && clarityId) {
    try {
      onLoadingChanged(true);

      const body: UpdateClarityProject = {
        apiPath: undefined,
        className: 'updateClarityProject',
        propertyId: propertyId?.toString(),
      };

      if (isTythonSelfServeUser()) {
        body.clarityProjectIdForTython = clarityId;
      } else {
        body.clarityProjectId = clarityId;
        body.enableMicrosoftClarity = clarityEnabled;
      }

      await patchEntity([publisher, new Property()], UpdateClarityProject, body, userContext);
      showSuccessToastNotification({ textMessageDescriptor: PropertyMessages.clarityRelinkSuccess });

      if (clarityEnabled === undefined && data !== undefined) {
        const updatedProperty = { ...data?.find((property) => property.id === propertyId) };
        updatedProperty.servingConfig!.clarityConfig!.clarityStatus = ClarityStatus.Pending;

        const updatedProperties: Property[] =
          data?.map((property) => {
            if (property.id === propertyId) {
              return updatedProperty as Property;
            } else {
              return property as Property;
            }
          }) || [];

        setPropertyData(updatedProperties);
      }
    } catch (e) {
      const apiErrorObj = e as ApiError;
      if (clarityId && apiErrorObj && (apiErrorObj.code === 404 || apiErrorObj.code === 400)) {
        showErrorToastNotification({ text: JSON.parse(apiErrorObj.message)[0].description });
      } else {
        // Add check for link or unlink request
        showErrorToastNotification({
          textMessageDescriptor: PropertyMessages.clarityRelinkFailed,
          intlMessageValues: { projectId: clarityId },
        });
      }
    } finally {
      onLoadingChanged(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: PropertyMessages.invalidState });
  }
});
