/* This is copied from https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b */

export const COUNTRY_WISE_ZIP_CODES_REGEX = {
  AF: {
    name: 'Afghanistan',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  AX: {
    name: 'Åland Islands',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  AL: {
    name: 'Albania',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  DZ: {
    name: 'Algeria',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  AS: {
    name: 'American Samoa',
    format: 'NNNNN (optionally NNNNN-NNNN or NNNNN-NNNNNN)',
    regex: '^\\d{5}(-{1}\\d{4,6})$',
  },
  AD: {
    name: 'Andorra',
    format: 'CCNNN',
    regex: '^[Aa][Dd]\\d{3}$',
  },
  AO: {
    name: 'Angola',
    format: '- no codes -',
    regex: '',
  },
  AI: {
    name: 'Anguilla',
    format: 'AI-2640',
    regex: '^[Aa][I][-][2][6][4][0]$',
  },
  AG: {
    name: 'Antigua and Barbuda',
    format: '- no codes -',
    regex: '',
  },
  AR: {
    name: 'Argentina',
    format: '1974-1998 NNNN; From 1999 ANNNNAAA',
    regex: '^\\d{4}|[A-Za-z]\\d{4}[a-zA-Z]{3}$',
  },
  AM: {
    name: 'Armenia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  AW: {
    name: 'Aruba',
    format: '- no codes -',
    regex: '',
  },
  AC: {
    name: 'Ascension island',
    format: 'ASCN 1ZZ',
    regex: '^[Aa][Ss][Cc][Nn]\\s{0,1}[1][Zz][Zz]$',
  },
  AU: {
    name: 'Australia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  AT: {
    name: 'Austria',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  AZ: {
    name: 'Azerbaijan',
    format: 'CCNNNN',
    regex: '^[Aa][Zz]\\d{4}$',
  },
  BS: {
    name: 'Bahamas',
    format: '- no codes -',
    regex: '',
  },
  BH: {
    name: 'Bahrain',
    format: 'NNN or NNNN',
    regex: '^\\d{3,4}$',
  },
  BD: {
    name: 'Bangladesh',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  BB: {
    name: 'Barbados',
    format: 'CCNNNNN',
    regex: '^[Aa][Zz]\\d{5}$',
  },
  BY: {
    name: 'Belarus',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  BE: {
    name: 'Belgium',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  BZ: {
    name: 'Belize',
    format: '- no codes -',
    regex: '',
  },
  BJ: {
    name: 'Benin',
    format: '- no codes -',
    regex: '',
  },
  BM: {
    name: 'Bermuda',
    format: 'AA NN or AA AA',
    regex: '^[A-Za-z]{2}\\s([A-Za-z]{2}|\\d{2})$',
  },
  BT: {
    name: 'Bhutan',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  BO: {
    name: 'Bolivia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  BQ: {
    name: 'Bonaire, Sint Eustatius and Saba',
    format: '- no codes -',
    regex: '',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  BW: {
    name: 'Botswana',
    format: '- no codes -',
    regex: '',
  },
  BR: {
    name: 'Brazil',
    format: 'NNNNN-NNN (NNNNN from 1971 to 1992)',
    regex: '^\\d{5}-\\d{3}$',
  },
  '': {
    name: 'British Antarctic Territory',
    format: 'BIQQ 1ZZ',
    regex: '^[Bb][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$',
  },
  IO: {
    name: 'British Indian Ocean Territory',
    format: 'BBND 1ZZ',
    regex: '^[Bb]{2}[Nn][Dd]\\s{0,1}[1][Zz]{2}$',
  },
  VG: {
    name: 'British Virgin Islands',
    format: 'CCNNNN',
    regex: '^[Vv][Gg]\\d{4}$',
  },
  BN: {
    name: 'Brunei',
    format: 'AANNNN',
    regex: '^[A-Za-z]{2}\\d{4}$',
  },
  BG: {
    name: 'Bulgaria',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  BF: {
    name: 'Burkina Faso',
    format: '- no codes -',
    regex: '',
  },
  BI: {
    name: 'Burundi',
    format: '- no codes -',
    regex: '',
  },
  KH: {
    name: 'Cambodia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  CM: {
    name: 'Cameroon',
    format: '- no codes -',
    regex: '',
  },
  CA: {
    name: 'Canada',
    format: 'ANA NAN',
    regex: '^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$',
  },
  CV: {
    name: 'Cape Verde',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  KY: {
    name: 'Cayman Islands',
    format: 'CCN-NNNN',
    regex: '^[Kk][Yy]\\d[-\\s]{0,1}\\d{4}$',
  },
  CF: {
    name: 'Central African Republic',
    format: '- no codes -',
    regex: '',
  },
  TD: {
    name: 'Chad',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  CL: {
    name: 'Chile',
    format: 'NNNNNNN (NNN-NNNN)',
    regex: '^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$',
  },
  CN: {
    name: 'China',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  CX: {
    name: 'Christmas Island',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  CC: {
    name: 'Cocos (Keeling) Island',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  CO: {
    name: 'Colombia',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  KM: {
    name: 'Comoros',
    format: '- no codes -',
    regex: '',
  },
  CG: {
    name: 'Congo (Brazzaville)',
    format: '- no codes -',
    regex: '',
  },
  CD: {
    name: 'Congo, Democratic Republic',
    format: '- no codes -',
    regex: '^[Cc][Dd]$',
  },
  CK: {
    name: 'Cook Islands',
    format: '- no codes -',
    regex: '',
  },
  CR: {
    name: 'Costa Rica',
    format: 'NNNNN (NNNN until 2007)',
    regex: '^\\d{4,5}$',
  },
  CI: {
    name: "Côte d'Ivoire (Ivory Coast)",
    format: '- no codes -',
    regex: '',
  },
  HR: {
    name: 'Croatia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  CU: {
    name: 'Cuba',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  CW: {
    name: 'Curaçao',
    format: '- no codes -',
    regex: '',
  },
  CY: {
    name: 'Cyprus',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  CZ: {
    name: 'Czech Republic',
    format: 'NNNNN (NNN NN)',
    regex: '^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$',
  },
  DK: {
    name: 'Denmark',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  DJ: {
    name: 'Djibouti',
    format: '- no codes -',
    regex: '',
  },
  DM: {
    name: 'Dominica',
    format: '- no codes -',
    regex: '',
  },
  DO: {
    name: 'Dominican Republic',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  TL: {
    name: 'East Timor',
    format: '- no codes -',
    regex: '',
  },
  EC: {
    name: 'Ecuador',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  SV: {
    name: 'El Salvador',
    format: '1101',
    regex: '^1101$',
  },
  EG: {
    name: 'Egypt',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  GQ: {
    name: 'Equatorial Guinea',
    format: '- no codes -',
    regex: '',
  },
  ER: {
    name: 'Eritrea',
    format: '- no codes -',
    regex: '',
  },
  EE: {
    name: 'Estonia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  ET: {
    name: 'Ethiopia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  FK: {
    name: 'Falkland Islands',
    format: 'FIQQ 1ZZ',
    regex: '^[Ff][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$',
  },
  FO: {
    name: 'Faroe Islands',
    format: 'NNN',
    regex: '^\\d{3}$',
  },
  FJ: {
    name: 'Fiji',
    format: '- no codes -',
    regex: '',
  },
  FI: {
    name: 'Finland',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  FR: {
    name: 'France',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  GF: {
    name: 'French Guiana',
    format: '973NN',
    regex: '^973\\d{2}$',
  },
  PF: {
    name: 'French Polynesia',
    format: '987NN',
    regex: '^987\\d{2}$',
  },
  TF: {
    name: 'French Southern and Antarctic Territories',
    format: '- no codes -',
    regex: '',
  },
  GA: {
    name: 'Gabon',
    format: 'NN [city name] NN',
    regex: '^\\d{2}\\s[a-zA-Z-_ ]\\s\\d{2}$',
  },
  GM: {
    name: 'Gambia',
    format: '- no codes -',
    regex: '',
  },
  GE: {
    name: 'Georgia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  DE: {
    name: 'Germany',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  GH: {
    name: 'Ghana',
    format: '- no codes -',
    regex: '',
  },
  GI: {
    name: 'Gibraltar',
    format: 'GX11 1AA',
    regex: '^[Gg][Xx][1]{2}\\s{0,1}[1][Aa]{2}$',
  },
  GR: {
    name: 'Greece',
    format: 'NNN NN',
    regex: '^\\d{3}\\s{0,1}\\d{2}$',
  },
  GL: {
    name: 'Greenland',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  GD: {
    name: 'Grenada',
    format: '- no codes -',
    regex: '',
  },
  GP: {
    name: 'Guadeloupe',
    format: '971NN',
    regex: '^971\\d{2}$',
  },
  GU: {
    name: 'Guam',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  GT: {
    name: 'Guatemala',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  GG: {
    name: 'Guernsey',
    format: 'AAN NAA, AANN NAA',
    regex: '^[A-Za-z]{2}\\d\\s{0,1}\\d[A-Za-z]{2}$',
  },
  GN: {
    name: 'Guinea',
    format: '- no codes -',
    regex: '',
  },
  GW: {
    name: 'Guinea Bissau',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  GY: {
    name: 'Guyana',
    format: '- no codes -',
    regex: '',
  },
  HT: {
    name: 'Haiti',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  HM: {
    name: 'Heard and McDonald Islands',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  HN: {
    name: 'Honduras',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  HK: {
    name: 'Hong Kong',
    format: '- no codes -',
    regex: '',
  },
  HU: {
    name: 'Hungary',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  IS: {
    name: 'Iceland',
    format: 'NNN',
    regex: '^\\d{3}$',
  },
  IN: {
    name: 'India',
    format: 'NNNNNN,&#10;NNN NNN',
    regex: '^\\d{6}$',
  },
  ID: {
    name: 'Indonesia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  IR: {
    name: 'Iran',
    format: 'NNNNN-NNNNN',
    regex: '^\\d{5}-\\d{5}$',
  },
  IQ: {
    name: 'Iraq',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  IE: {
    name: 'Ireland',
    format: '- no codes -',
    regex: '',
  },
  IM: {
    name: 'Isle of Man',
    format: 'CCN NAA, CCNN NAA',
    regex: '^[Ii[Mm]\\d{1,2}\\s\\d\\[A-Z]{2}$',
  },
  IL: {
    name: 'Israel',
    format: 'NNNNNNN, NNNNN',
    regex: '^\\b\\d{5}(\\d{2})?$',
  },
  IT: {
    name: 'Italy',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  JM: {
    name: 'Jamaica',
    format: 'Before suspension: CCAAANN &#10;After suspension: NN',
    regex: '^\\d{2}$',
  },
  JP: {
    name: 'Japan',
    format: 'NNNNNNN (NNN-NNNN)',
    regex: '^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$',
  },
  JE: {
    name: 'Jersey',
    format: 'CCN NAA',
    regex: '^[Jj][Ee]\\d\\s{0,1}\\d[A-Za-z]{2}$',
  },
  JO: {
    name: 'Jordan',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  KZ: {
    name: 'Kazakhstan',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  KE: {
    name: 'Kenya',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  KI: {
    name: 'Kiribati',
    format: '- no codes -',
    regex: '',
  },
  KP: {
    name: 'Korea, North',
    format: '- no codes -',
    regex: '',
  },
  KR: {
    name: 'South Korea',
    format: 'NNNNNN (NNN-NNN)',
    regex: '^\\d{6}\\s\\(\\d{3}-\\d{3}\\)$',
  },
  XK: {
    name: 'Kosovo',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  KW: {
    name: 'Kuwait',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  KG: {
    name: 'Kyrgyzstan',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  LV: {
    name: 'Latvia',
    format: 'LV-NNNN',
    regex: '^[Ll][Vv][- ]{0,1}\\d{4}$',
  },
  LA: {
    name: 'Laos',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  LB: {
    name: 'Lebanon',
    format: 'NNNN NNNN',
    regex: '^\\d{4}\\s{0,1}\\d{4}$',
  },
  LS: {
    name: 'Lesotho',
    format: 'NNN',
    regex: '^\\d{3}$',
  },
  LR: {
    name: 'Liberia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  LY: {
    name: 'Libya',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  LI: {
    name: 'Liechtenstein',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  LT: {
    name: 'Lithuania',
    format: 'LT-NNNNN',
    regex: '^[Ll][Tt][- ]{0,1}\\d{5}$',
  },
  LU: {
    name: 'Luxembourg',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  MO: {
    name: 'Macau',
    format: '- no codes -',
    regex: '',
  },
  MK: {
    name: 'Macedonia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  MG: {
    name: 'Madagascar',
    format: 'NNN',
    regex: '^\\d{3}$',
  },
  MW: {
    name: 'Malawi',
    format: '- no codes -',
    regex: '',
  },
  MV: {
    name: 'Maldives',
    format: 'NNNN, NNNNN',
    regex: '^\\d{4,5}$',
  },
  MY: {
    name: 'Malaysia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  ML: {
    name: 'Mali',
    format: '- no codes -',
    regex: '',
  },
  MT: {
    name: 'Malta',
    format: 'AAANNNN (AAA NNNN)',
    regex: '^[A-Za-z]{3}\\s{0,1}\\d{4}$',
  },
  MH: {
    name: 'Marshall Islands',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  MR: {
    name: 'Mauritania',
    format: '- no codes -',
    regex: '',
  },
  MU: {
    name: 'Mauritius',
    format: '- no codes -',
    regex: '',
  },
  MQ: {
    name: 'Martinique',
    format: '972NN',
    regex: '^972\\d{2}$',
  },
  YT: {
    name: 'Mayotte',
    format: '976NN',
    regex: '^976\\d{2}$',
  },
  FM: {
    name: 'Micronesia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  MX: {
    name: 'Mexico',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  MD: {
    name: 'Moldova',
    format: 'CCNNNN (CC-NNNN)',
    regex: '^[Mm][Dd][- ]{0,1}\\d{4}$',
  },
  MC: {
    name: 'Monaco',
    format: '980NN',
    regex: '^980\\d{2}$',
  },
  MN: {
    name: 'Mongolia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  ME: {
    name: 'Montenegro',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  MS: {
    name: 'Montserrat',
    format: 'MSR 1110-1350',
    regex: '^[Mm][Ss][Rr]\\s{0,1}\\d{4}$',
  },
  MA: {
    name: 'Morocco',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  MZ: {
    name: 'Mozambique',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  MM: {
    name: 'Myanmar',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  NA: {
    name: 'Namibia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  NR: {
    name: 'Nauru',
    format: '- no codes -',
    regex: '',
  },
  NP: {
    name: 'Nepal',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  NL: {
    name: 'Netherlands',
    format: 'NNNN AA',
    regex: '^\\d{4}\\s{0,1}[A-Za-z]{2}$',
  },
  NC: {
    name: 'New Caledonia',
    format: '988NN',
    regex: '^988\\d{2}$',
  },
  NZ: {
    name: 'New Zealand',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  NI: {
    name: 'Nicaragua',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  NE: {
    name: 'Niger',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  NG: {
    name: 'Nigeria',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  NU: {
    name: 'Niue',
    format: '- no codes -',
    regex: '',
  },
  NF: {
    name: 'Norfolk Island',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  MP: {
    name: 'Northern Mariana Islands',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  NO: {
    name: 'Norway',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  OM: {
    name: 'Oman',
    format: 'NNN',
    regex: '^\\d{3}$',
  },
  PK: {
    name: 'Pakistan',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  PW: {
    name: 'Palau',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  PA: {
    name: 'Panama',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  PG: {
    name: 'Papua New Guinea',
    format: 'NNN',
    regex: '^\\d{3}$',
  },
  PY: {
    name: 'Paraguay',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  PE: {
    name: 'Peru',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  PH: {
    name: 'Philippines',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  PN: {
    name: 'Pitcairn Islands',
    format: 'PCRN 1ZZ',
    regex: '^[Pp][Cc][Rr][Nn]\\s{0,1}[1][Zz]{2}$',
  },
  PL: {
    name: 'Poland',
    format: 'NNNNN (NN-NNN)',
    regex: '^\\d{2}[- ]{0,1}\\d{3}$',
  },
  PT: {
    name: 'Portugal',
    format: 'NNNN-NNN (NNNN NNN)',
    regex: '^\\d{4}[- ]{0,1}\\d{3}$',
  },
  PR: {
    name: 'Puerto Rico',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  QA: {
    name: 'Qatar',
    format: '- no codes -',
    regex: '',
  },
  RE: {
    name: 'Réunion',
    format: '974NN',
    regex: '^974\\d{2}$',
  },
  RO: {
    name: 'Romania',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  RU: {
    name: 'Russia',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  BL: {
    name: 'Saint Barthélemy',
    format: '97133',
    regex: '^97133$',
  },
  SH: {
    name: 'Tristan da Cunha',
    format: 'TDCU 1ZZ',
    regex: '^[Tt][Dd][Cc][Uu]\\s{0,1}[1][Zz]{2}$',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    format: '- no codes -',
    regex: '',
  },
  LC: {
    name: 'Saint Lucia',
    format: '- no codes -',
    regex: '',
  },
  MF: {
    name: 'Saint Martin',
    format: '97150',
    regex: '^97150$',
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    format: '97500',
    regex: '^97500$',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    format: 'CCNNNN',
    regex: '^[Vv][Cc]\\d{4}$',
  },
  SM: {
    name: 'San Marino',
    format: '4789N',
    regex: '^4789\\d$',
  },
  ST: {
    name: 'Sao Tome and Principe',
    format: '- no codes -',
    regex: '',
  },
  SA: {
    name: 'Saudi Arabia',
    format: 'NNNNN for PO Boxes. NNNNN-NNNN for home delivery.',
    regex: '^\\d{5}(-{1}\\d{4})?$',
  },
  SN: {
    name: 'Senegal',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  RS: {
    name: 'Serbia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  SC: {
    name: 'Seychelles',
    format: '- no codes -',
    regex: '',
  },
  SX: {
    name: 'Sint Maarten',
    format: '- no codes -',
    regex: '',
  },
  SL: {
    name: 'Sierra Leone',
    format: '- no codes -',
    regex: '',
  },
  SG: {
    name: 'Singapore',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  SK: {
    name: 'Slovakia',
    format: 'NNNNN (NNN NN)',
    regex: '^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$',
  },
  SI: {
    name: 'Slovenia',
    format: 'NNNN (CC-NNNN)',
    regex: '^([Ss][Ii][- ]{0,1}){0,1}\\d{4}$',
  },
  SB: {
    name: 'Solomon Islands',
    format: '- no codes -',
    regex: '',
  },
  SO: {
    name: 'Somalia',
    format: '- no codes -',
    regex: '',
  },
  ZA: {
    name: 'South Africa',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    format: 'SIQQ 1ZZ',
    regex: '^[Ss][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$',
  },
  ES: {
    name: 'Spain',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  LK: {
    name: 'Sri Lanka',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  SD: {
    name: 'Sudan',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  SR: {
    name: 'Suriname',
    format: '- no codes -',
    regex: '',
  },
  SZ: {
    name: 'Swaziland',
    format: 'ANNN',
    regex: '^[A-Za-z]\\d{3}$',
  },
  SE: {
    name: 'Sweden',
    format: 'NNNNN (NNN NN)',
    regex: '^\\d{3}\\s*\\d{2}$',
  },
  CH: {
    name: 'Switzerland',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  SY: {
    name: 'Syria',
    format: '- no codes -',
    regex: '',
  },
  TW: {
    name: 'Taiwan',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  TJ: {
    name: 'Tajikistan',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  TZ: {
    name: 'Tanzania',
    format: '- no codes -',
    regex: '',
  },
  TH: {
    name: 'Thailand',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  TG: {
    name: 'Togo',
    format: '- no codes -',
    regex: '',
  },
  TK: {
    name: 'Tokelau',
    format: '- no codes -',
    regex: '',
  },
  TO: {
    name: 'Tonga',
    format: '- no codes -',
    regex: '',
  },
  TT: {
    name: 'Trinidad and Tobago',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  TN: {
    name: 'Tunisia',
    format: 'NNNN',
    regex: '^\\d{4}$',
  },
  TR: {
    name: 'Turkey',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  TM: {
    name: 'Turkmenistan',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    format: 'TKCA 1ZZ',
    regex: '^[Tt][Kk][Cc][Aa]\\s{0,1}[1][Zz]{2}$',
  },
  TV: {
    name: 'Tuvalu',
    format: '- no codes -',
    regex: '',
  },
  UG: {
    name: 'Uganda',
    format: '- no codes -',
    regex: '',
  },
  UA: {
    name: 'Ukraine',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  AE: {
    name: 'United Arab Emirates',
    format: '- no codes -',
    regex: '',
  },
  GB: {
    name: 'United Kingdom',
    format: 'A(A)N(A/N)NAA (A[A]N[A/N] NAA)',
    regex: '^[A-Z]{1,2}[0-9R][0-9A-Z]?\\s*[0-9][A-Z-[CIKMOV]]{2}',
  },
  US: {
    name: 'United States',
    format: 'NNNNN (optionally NNNNN-NNNN)',
    regex: '^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$',
  },
  UY: {
    name: 'Uruguay',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  VI: {
    name: 'U.S. Virgin Islands',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  UZ: {
    name: 'Uzbekistan',
    format: 'NNN NNN',
    regex: '^\\d{3} \\d{3}$',
  },
  VU: {
    name: 'Vanuatu',
    format: '- no codes -',
    regex: '',
  },
  VA: {
    name: 'Vatican',
    format: '120',
    regex: '^120$',
  },
  VE: {
    name: 'Venezuela',
    format: 'NNNN or NNNN A',
    regex: '^\\d{4}(\\s[a-zA-Z]{1})?$',
  },
  VN: {
    name: 'Vietnam',
    format: 'NNNNNN',
    regex: '^\\d{6}$',
  },
  WF: {
    name: 'Wallis and Futuna',
    format: '986NN',
    regex: '^986\\d{2}$',
  },
  YE: {
    name: 'Yemen',
    format: '- no codes -',
    regex: '',
  },
  ZM: {
    name: 'Zambia',
    format: 'NNNNN',
    regex: '^\\d{5}$',
  },
  ZW: {
    name: 'Zimbabwe',
    format: '- no codes -',
    regex: '',
  },
};
