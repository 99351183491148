import { PrimaryButton, TextField } from '@fluentui/react';
import React, { useEffect, useLayoutEffect } from 'react';

import { RedirectRequest } from '@azure/msal-browser';
import { Image } from '@fluentui/react';
import { LayoutMode, getAppStore, onLayoutChanged } from '../../@data';
import { DefaultRoute } from '../../Routes';
import { aadConfig } from '../../config/pubCenterAAD.config';
import { APP_NAME_NEW, APP_NAME_PUBCENTER, TYTHON_SUPPORT_EMAIL, USER_SIGNUP_KEY } from '../../constants/AppConstants';
import { Page } from '../../layout/PageLayout/PageLayout';
import { IRouterWithIntl, injectIntlWithObserver, isLocationPubCenter } from '../../utils';
import { getLoginPageStore, onEmailChanged, onLoginButtonClicked, onPubCenterAADLogin } from '../login/@data';
import messages from './AboutUsPage.messages';
import { getClassNames } from './AboutUsPage.styles';
import ads from './resources/ads.png';
import man from './resources/man.jpg';
import money from './resources/money.png';
import one from './resources/one.png';
import three from './resources/three.png';
import time from './resources/time.png';
import two from './resources/two.png';
import twoWomen from './resources/twoWomen.jpg';
import woman from './resources/woman.jpg';

export interface IAboutUsPageProps extends IRouterWithIntl {}

export const AboutUsPage = injectIntlWithObserver(function AboutUsPageComponent(props: IAboutUsPageProps) {
  const { formatMessage } = props.intl;
  const {
    errors,
    active: { email },
  } = getLoginPageStore();
  const { userContext } = getAppStore();

  const {
    introductionContainer,
    imageContainer,
    signInContainer,
    bodyContainer,
    imageWithTextOnLeft,
    imageWithTextOnRight,
    iconsContainer,
    iconContainer,
    stepsContainer,
    monetizationContainer,
    textFieldTitle,
    textFieldGroup,
    centerTextAlign,
    iconsContainerPrimary,
    header,
    smaller,
    font18,
    font16,
    font14,
  } = getClassNames();

  useEffect(() => {
    onLayoutChanged(LayoutMode.Default);
  }, []);

  useLayoutEffect(() => {
    if (userContext && userContext.token) {
      props.history.push(`${DefaultRoute}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext]);

  const AADLogin = (shouldSetSessionFlag = false) => {
    if (shouldSetSessionFlag) {
      localStorage.setItem(USER_SIGNUP_KEY, 'true');
    }
    const requestParams: Partial<RedirectRequest> = { redirectStartPage: aadConfig.auth.redirectUri };
    onPubCenterAADLogin(requestParams);
  };

  const handleSignInClick = () => {
    onLoginButtonClicked();
  };

  const handleSignUpClick = () => {
    AADLogin(true);
  };

  return (
    <Page {...props}>
      <div className={introductionContainer}>
        <div className={imageContainer}>
          <p className={header}>
            {formatMessage(messages.productName, { appName: isLocationPubCenter() ? APP_NAME_PUBCENTER : APP_NAME_NEW })}
          </p>
          <span className={header}>{formatMessage(messages.slogan)}</span>
          <p>{formatMessage(messages.incentive)}</p>
          <br />
          <br />
          <PrimaryButton
            text={formatMessage(messages.contactUs)}
            href={`mailto:${TYTHON_SUPPORT_EMAIL}`}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
        <div className={signInContainer}>
          <p className={header}>
            {formatMessage(messages.alreadyMember, { appName: isLocationPubCenter() ? APP_NAME_PUBCENTER : APP_NAME_NEW })}
          </p>
          <TextField
            label={formatMessage(messages.emailAddressLabel)}
            styles={{ subComponentStyles: { label: { root: textFieldTitle } }, fieldGroup: textFieldGroup }}
            placeholder={formatMessage(messages.emailInputPlaceholder)}
            onChange={(_, val) => onEmailChanged(val || '')}
            errorMessage={errors.email}
            value={email}
            type="email"
            onKeyDown={(ev) => ev.key === 'Enter' && handleSignInClick()}
          />
          <PrimaryButton onClick={handleSignInClick} text={formatMessage(messages.signIn)} />
        </div>
      </div>
      <div className={bodyContainer}>
        <div className={imageWithTextOnLeft}>
          <Image imageFit={1} src={woman} alt={formatMessage(messages.imgBackgroundAlt)} loading="lazy" />
          <div>
            <p className={smaller}>{formatMessage(messages.usersToBeQuestion)}</p>
            <ul>
              <li className={font16}>{formatMessage(messages.usersToBeQuirkOne)}</li>
              <li className={font16}>{formatMessage(messages.usersToBeQuirkTwo)}</li>
              <li className={font16}>{formatMessage(messages.usersToBeQuirkThree)}</li>
            </ul>
          </div>
        </div>
        <div className={`${iconsContainer} ${iconsContainerPrimary}`}>
          <div className={iconContainer}>
            <Image src={money} alt={formatMessage(messages.imgMoneyBagAlt)} loading="lazy" />
            <span className={font18}>{formatMessage(messages.moneyLabel)}</span>
            <span className={font14}>{formatMessage(messages.moneyDesc)}</span>
          </div>
          <div className={iconContainer}>
            <Image src={ads} alt={formatMessage(messages.imgAdsAlt)} loading="lazy" />
            <span className={font18}>{formatMessage(messages.adsLabel)}</span>
            <span className={font14}>{formatMessage(messages.adsDesc)}</span>
          </div>
          <div className={iconContainer}>
            <Image src={time} alt={formatMessage(messages.imgTimeAlt)} loading="lazy" />
            <span className={font18}>{formatMessage(messages.timeLabel)}</span>
            <span className={font14}>{formatMessage(messages.timeDesc)}</span>
          </div>
        </div>
        <div className={imageWithTextOnRight}>
          <div>
            <p className={smaller}>{formatMessage(messages.advantageOneHeader)}</p>
            <p className={font16}>{formatMessage(messages.advantageOneDescOne)}</p>
            <p className={font16}>{formatMessage(messages.advantageOneDescTwo)}</p>
          </div>
          <Image imageFit={1} src={man} alt={formatMessage(messages.imgPrimaryOnboardAlt)} loading="lazy" />
        </div>
        <div className={imageWithTextOnLeft}>
          <Image imageFit={1} src={twoWomen} alt={formatMessage(messages.imgPrimaryOnboardAlt)} loading="lazy" />
          <div>
            <p className={smaller}>{formatMessage(messages.advantageTwoHeader)}</p>
            <p className={font16}>{formatMessage(messages.advantageTwoDescOne)}</p>
            <p className={font16}>{formatMessage(messages.advantageTwoDescTwo)}</p>
          </div>
        </div>
      </div>
      <div className={stepsContainer}>
        <div className={centerTextAlign}>
          <span className={smaller}>{formatMessage(messages.startingHeader)}</span>
          <p>{formatMessage(messages.startingDesc)}</p>
        </div>
        <div className={`${bodyContainer} ${iconsContainer}`}>
          <div className={iconContainer}>
            <Image src={one} alt={formatMessage(messages.imgSecondaryOnboardAltOne)} loading="lazy" />
            <span className={font18}>{formatMessage(messages.stepOneHeader)}</span>
            <span className={font14}>{formatMessage(messages.stepOneDesc)}</span>
          </div>
          <div className={iconContainer}>
            <Image src={two} alt={formatMessage(messages.imgSecondaryOnboardAltTwo)} loading="lazy" />
            <span className={font18}>{formatMessage(messages.stepTwoHeader)}</span>
            <span className={font14}>{formatMessage(messages.stepTwoDesc)}</span>
          </div>
          <div className={iconContainer}>
            <Image src={three} alt={formatMessage(messages.imgSecondaryOnboardAltThree)} loading="lazy" />
            <span className={font18}>{formatMessage(messages.stepThreeHeader)}</span>
            <span className={font14}>{formatMessage(messages.stepThreeDesc)}</span>
          </div>
        </div>
      </div>
      <div className={monetizationContainer}>
        <div className={centerTextAlign}>
          <p className={smaller}>{formatMessage(messages.monetizationHeader)}</p>
          <p className={font16}>{formatMessage(messages.monetizationDesc)}</p>
          <PrimaryButton text={formatMessage(messages.signUp)} target="_blank" rel="noopener noreferrer" onClick={handleSignUpClick} />
        </div>
      </div>
    </Page>
  );
});
