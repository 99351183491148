import { defineMessages } from 'react-intl';

export default defineMessages({
  themeColors: {
    id: 'colorPicker.themeColors',
    defaultMessage: 'Theme Colors',
  },
  customColor: {
    id: 'colorPicker.customColor',
    defaultMessage: 'Custom Color',
  },
  colorPicker: {
    id: 'colorPicker.colorPicker',
    defaultMessage: 'Pick a color',
  },
});
