import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { Switch } from 'react-router-dom';
import { IAppState, getAppStore } from '../../@data';
import AppMessages from '../../App.messages';
import { IRoute } from '../../Routes.types';
import { ContentLayout } from '../../layout/ContentLayout/ContentLayout';
import { IMenuProps } from '../../layout/ContentLayout/ContentLayout.types';
import { RouteName } from './@data/store/store';
import { BaseReportPage } from './BaseReportPage';
import { ReportHeader } from './ReportHeader';

export const getRoutes = (formatMessage) => [
  {
    key: RouteName[RouteName.dashboard],
    text: formatMessage(AppMessages.navReports),
    icon: 'CRMReport',
    component: BaseReportPage,
  },
];

const DefaultRoute = RouteName[RouteName.dashboard];

export const ReportRouter = injectIntl(
  class extends React.Component<RouteComponentProps & InjectedIntlProps> {
    public render(): JSX.Element {
      const store = getAppStore();
      const menuProps: IMenuProps = {
        ...this.props,
        routes: this._generateMenuRoutes(store),
      };
      return (
        <ContentLayout {...this.props} menuProps={menuProps} onRenderHeader={this._renderHeader}>
          <Redirect exact path={this.props.match.url} to={`${this.props.match.url}/${DefaultRoute}`} />
          {getRoutes(this.props.intl.formatMessage).map((route: IRoute, index: number) => (
            <Route
              key={index}
              path={[`${this.props.match.url}/${route.key}`, `${this.props.match.url}/:publisherId/${route.key}`]}
              component={route.component}
            />
          ))}
        </ContentLayout>
      );
    }

    private _generateMenuRoutes(store: IAppState) {
      let keyFnc: (key: string) => string;

      if (store.publisher && store.publisher.id) {
        const publisherId = store.publisher.id.toString();
        keyFnc = (key) => {
          return `/${publisherId}/${key}`;
        };
      } else {
        keyFnc = (key) => `/${key}`;
      }

      // Update the menu routes according to whether the publisher/account were specified.
      return getRoutes(this.props.intl.formatMessage).map(
        (route) =>
          ({
            key: keyFnc(route.key),
            text: route.text,
            icon: route.icon,
          } as IRoute)
      );
    }

    private _renderHeader = (): JSX.Element => {
      // Support non pub urls as well. When nothing matched, go back to home to do the default route.
      return (
        <React.Fragment>
          <Switch>
            <Route path={`${this.props.match.url}/:publisherId`} render={(props) => <ReportHeader {...props} />} />
            <Route path="*" render={() => <Redirect to={this.props.match.url} />} />
          </Switch>
        </React.Fragment>
      );
    };
  }
);
