import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { User, getCurrentPublisher, onLoadingChanged, showErrorToastNotification } from '../../../../../@data';
import { AddressValidation } from '../../../../../@data/store/schema/models/AddressValidation';
import { deepClone } from '../../../../../utils';
import messages from '../../ProfilePage.messages';
import { setIsAddressValid, setProfileErrorsAction, validateAddress } from '../actions';
import { validateAddressAsync } from '../services';

orchestrator(validateAddress, async ({ profile, formatMessage }) => {
  try {
    const publisher = getCurrentPublisher();
    if (!publisher) {
      return Promise.reject('No publisher found');
    }

    const newUserDetails: User = deepClone(profile.user);
    if (!newUserDetails) {
      throw new Error('User details are missing');
    }

    const addressValidation = new AddressValidation();
    addressValidation.postalCode = newUserDetails.postalAddress?.postalCode;
    addressValidation.country = newUserDetails.postalAddress?.country;
    addressValidation.city = newUserDetails.postalAddress?.city;
    addressValidation.stateOrProvince = newUserDetails.postalAddress?.stateOrProvince;
    addressValidation.line1 = newUserDetails.postalAddress?.line1;
    addressValidation.line2 = newUserDetails.postalAddress?.line2;
    addressValidation.isOrg = false;

    // add new endpoint call
    const result = await validateAddressAsync(addressValidation, publisher);
    setIsAddressValid(result.isAddressValid);
  } catch (error) {
    if (error.code === 400) {
      setProfileErrorsAction({ 'user.postalAddress.postalCode': formatMessage(messages.profileInvalidAddressErrorMessage) });
    } else {
      showErrorToastNotification({ text: formatMessage(messages.profileAddressValidationFailed) });
    }
    setIsAddressValid(false);
    onLoadingChanged(false);
    Log.error(error);
  }
});
