import React, { useEffect } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { LayoutMode, onLayoutChanged } from '../../../@data';
import { RouteName } from '../@data';
import { fetchPublisherEvaluationStatusAction, onCurrentRouteChanged } from '../@data/actions';
import { ManagePage } from './Manage/ManagePage';

export const HomePage = injectIntl(function HomePageComponent(props: RouteComponentProps & InjectedIntlProps): JSX.Element {
  useEffect(() => {
    onLayoutChanged(LayoutMode.Default);
    onCurrentRouteChanged(RouteName.home, props.intl.formatMessage);
    fetchPublisherEvaluationStatusAction({ cache: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path={props.match.url} component={ManagePage} />
    </Switch>
  );
});
