import { Model } from '.';
import { User } from './User';

export class UserCreateFromInvitation extends Model {
  public apiPath = 'livelogin/userCreateFromInvitation';
  public className = 'userCreateFromInvitation';

  public user?: User;
  public invitationCode?: string;
  public termsAndConditionsAccepted?: boolean;
}
