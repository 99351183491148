import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import {
  UserUpdateLanguage,
  getAppStore,
  onLoadingChanged,
  onNotificationChanged,
  patchEntity,
  showErrorToastNotification,
} from '../../../../../../@data';
import { LOCAL_STORAGE_LOCALE } from '../../../../../../constants';
import { onFetchTnC } from '../../../../../terms-and-conditions/@data';
import { getPrimaryUserRawData } from '../../../../Profile/@data';
import { setPrimaryUserDetailsETagAction } from '../../../../Profile/@data/actions';
import messages from '../../components/SystemConfiguration/SystemConfiguration.messages';
import { onUpdateUserLanguage } from '../actions';
import { onLanguageChanged } from '../mutatorActions';

orchestrator(onUpdateUserLanguage, async (msg) => {
  Log.debug('Updating user language');
  const { publisher, userContext } = getAppStore();
  const user = getPrimaryUserRawData();

  if (publisher && userContext && user && user.language) {
    try {
      onLoadingChanged(true);
      const updatedUserLanguage: UserUpdateLanguage = new UserUpdateLanguage();
      updatedUserLanguage.language = msg.locale;
      updatedUserLanguage.userId = user.id;
      updatedUserLanguage.eTag = user.eTag;

      const updateUserResp = await patchEntity([publisher, user], UserUpdateLanguage, updatedUserLanguage, userContext);
      if (updateUserResp.eTag) {
        setPrimaryUserDetailsETagAction(updateUserResp.eTag);
      }

      onLanguageChanged(msg.locale, LOCAL_STORAGE_LOCALE);
      onFetchTnC(msg.locale);

      const element = document.getElementById('apex_platform_chrome');
      element?.remove();

      msg?.closePanel?.();
      msg?.setFormChanged?.(false);
    } catch (error) {
      showErrorToastNotification({ textMessageDescriptor: messages.updateLanguageFailed });
      Log.error(error);
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.invalidStateReLoginOrReport,
    });
  }
});
