import { ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react';
import React from 'react';

export const getBalanceValueShimmer = (): JSX.Element => {
  return (
    <div>
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 37 }]} />
    </div>
  );
};

export const getBalanceHorizontalBarShimmer = (): JSX.Element => {
  return (
    <div>
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 16 }]} />
      <ShimmerElementsGroup
        backgroundColor={'transparent'}
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, width: '60%', height: 14 },
          { type: ShimmerElementType.line, width: '40%', height: 14, verticalAlign: 'bottom' },
        ]}
      />
    </div>
  );
};
