import { IconButton, css } from '@fluentui/react';
import React from 'react';
import { isButtonPressKey } from '../../../../../../utils/AccessibilityUtils';
import { getClassNames } from './FilterListBar.styles';

export interface IFilterListItemProps {
  id: string;
  filterKey: string;
  title: string;
  isFocused: boolean;
  onRemove: (key: string) => void;
  onClick: (key: string) => void;
}

export const FilterListItem = ({ id, filterKey, title, isFocused, onClick, onRemove }: IFilterListItemProps) => {
  const classNames = getClassNames();
  return (
    <div id={id} className={isFocused ? classNames.filterInfoContainer : classNames.filterInfoContainerUnfocused}>
      <div
        className={isFocused ? css(classNames.filterInfoFocused, classNames.filterInfoSelectors) : classNames.filterInfoUnfocused}
        onClick={() => {
          onClick(filterKey);
        }}
        onKeyDown={(ev) => isButtonPressKey(ev.key)}
        role="button"
        // tabIndex={0}
        data-is-focusable={true}
      >
        {title}
      </div>
      <IconButton
        className={isFocused ? classNames.filterCloseIcon : classNames.hidden}
        iconProps={{ iconName: 'Cancel' }}
        onClick={() => {
          onRemove(filterKey);
        }}
        tabIndex={0}
        onKeyDown={(ev) => isButtonPressKey(ev.key)}
        aria-label={''}
        data-is-focusable={true}
      />
    </div>
  );
};
