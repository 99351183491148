import { UserRoleEntity, userRoleKey } from '../../../../../../../@data/services/UserRoleService';
import { Property as PropertyAccessPermissions } from '../../../../../../../@data/store/schema/userrolemodels/Property';

export function GetPropertyAccessPermission(): PropertyAccessPermissions | null {
  const userRoles = localStorage.getItem(userRoleKey);
  if (userRoles === null) {
    return null;
  }
  const userRolesJSON = JSON.parse(userRoles);

  for (const value in userRolesJSON) {
    if (value === UserRoleEntity.Property) {
      return JSON.parse(userRolesJSON[value]);
    }
  }

  return null;
}
