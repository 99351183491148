import { MessageBarType } from '@fluentui/react';
import { AdSource, ApiError, LifeCycleStatus, getAppStore, onNotificationChanged } from '../../../../../@data';
import { buildRequestHeadersWithAuthToken } from '../../../../../@data/utils/requestUtils';
import { adQualityLocales } from '../../../../../config/locale';
import { uiConfig } from '../../../../../config/ui.config';
import messages from '../../AdReview/AdCreativePage.messages';
import { IAdListingSample } from '../store/schema';
import { IAdListingsData } from '../store/schema/IAdListingsData';
import {
  AdPerformanceData,
  BlockAdsData,
  BlockImagessData,
  BlockedContext,
  InvertedAdDetails,
  SAMBlockAdData,
} from '../store/schema/models';
import { IAdReviewModel } from '../store/schema/models/AdReviewModel';
import { BlockRequestSourceData } from '../store/schema/models/BlockRequestSourceData';
import { getStore } from '../store/store';
import { constructUrl, handleError, sanitizeBingImageUrl } from './utility/utility';

export const baseAddress = uiConfig.getAdQualityApiBaseAddress();

export async function getBlockedAdContext(publisherId: number): Promise<BlockedContext> {
  const { userContext } = getAppStore();
  const blockedDataUrl =
    baseAddress + 'adblockdata/adunitadidtuples/' + publisherId.toString() + '?targetCustomerId=' + publisherId.toString();

  const headers = await buildRequestHeadersWithAuthToken(blockedDataUrl, userContext);
  const adunitAdIdPair = await fetch(blockedDataUrl, {
    method: 'GET',
    headers: headers,
  });

  const blockedContext: BlockedContext = await adunitAdIdPair.json();
  return blockedContext;
}

export async function getAdPerfData(body: IAdReviewModel, params: URLSearchParams): Promise<AdPerformanceData[]> {
  const { active, blockedAdsContext, blockedImagesContext } = getStore();
  const baseUrl = baseAddress + 'adperfdata/search';
  const url = constructUrl(baseUrl, [], params);
  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });

    const responseJson = await response.json();
    if (response.status === 500) {
      const error = `data fetch operation failed for ${url}. Errors: ${response.statusText}`;
      return handleError(new ApiError(response.status, error, responseJson.trackingId), url);
    }

    let filteredAds = responseJson.adPerformanceData ? responseJson.adPerformanceData : [];
    if (active.selectedAdSource === AdSource.Microsoft) {
      if (blockedAdsContext) {
        const mapAdIdAdunits: Map<number, number[]> = blockedAdsContext.mapAdIdAdunits as Map<number, number[]>;
        const mapAdIdAccounts: Map<number, number[]> = blockedAdsContext.mapAdIdAccounts as Map<number, number[]>;
        if (mapAdIdAdunits) {
          filteredAds = filteredAds.filter((a) => {
            if (a.adId) {
              return !(Object.keys(mapAdIdAdunits).includes(a.adId!.toString()) && mapAdIdAdunits[a.adId!].includes(a.adUnitId!));
            } else {
              return true;
            }
          });
        }
        if (mapAdIdAccounts) {
          filteredAds = filteredAds.filter((a) => {
            if (a.adId) {
              return !(Object.keys(mapAdIdAccounts).includes(a.adId!.toString()) && mapAdIdAccounts[a.adId!].includes(a.accountId!));
            } else {
              return true;
            }
          });
        }
        if (blockedAdsContext.mapAdIdImages) {
          filteredAds.forEach((ad) => {
            ad.blockedImages = blockedAdsContext.mapAdIdImages!.get(ad.adId!) || [];
          });
        }
      }
      if (blockedImagesContext) {
        filteredAds.forEach((ad) => {
          ad.blockedAdIdImages = blockedImagesContext;
          // we will need the below in case we decide to do the filtering of reported images in UI
          // ad.imagesContext = [];
          // if (ad.images) {
          //   ad.images.forEach((image) => {
          //     const imageMetadata: ImageMetaData = { imageUrl: image, isBlocked: false };
          //     const imageReportDataTuple = { adId: ad.adId, imageUrl: image };
          //     if (blockedImagesContext.includes(JSON.stringify(imageReportDataTuple))) {
          //       imageMetadata.isBlocked = true;
          //     }
          //     ad.imagesContext!.push(imageMetadata);
          //   });
          // }
        });
      }
    } else {
      // We assume that the block summary is up-to-date for Auction blocked Ads
      const locales: string[] = ['global', adQualityLocales[active.selectedMarket!]];
      active.samBlockSummaryData?.filter((blockSummary) => blockSummary.locale && locales.includes(blockSummary.locale));
      filteredAds.forEach((ad) => {
        const blockedAdFind = active.samBlockSummaryData?.find((blockedAd) => ad.uniqueCreativeAdId === blockedAd.creativeAdId);
        blockedAdFind && blockedAdFind.status === LifeCycleStatus.Active
          ? (ad.status = LifeCycleStatus.Inactive)
          : (ad.status = LifeCycleStatus.Active);
      });
    }
    return filteredAds;
  } catch (err) {
    onNotificationChanged({
      textMessageDescriptor: messages.adCreativeServiceLoadEntityFailed,
      messageBarType: MessageBarType.severeWarning,
    });
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getAdIdRevenue(models: string[][], params: URLSearchParams): Promise<number> {
  const baseUrl = baseAddress + 'adperfdata';
  const url = constructUrl(baseUrl, models, params);

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getAdIdDetails(models: string[][], params: URLSearchParams): Promise<IAdListingsData> {
  const baseUrl = baseAddress + 'adperfdata';
  const url = constructUrl(baseUrl, models, params);

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    const responseListingsInverted: InvertedAdDetails = await response.json();
    const titlesInverted = responseListingsInverted.adTitles as Map<string, string[]>;
    const urlsInverted = responseListingsInverted.adUrls;
    const destUrlsInverted = responseListingsInverted.adDestUrls;
    const imagesInverted = responseListingsInverted.images;

    const listingSamplesMap: Map<string, IAdListingSample> = new Map();
    let listingSamples: IAdListingSample[] = [];

    // Add all Titles, urls, desturls, images to each sample based on its adlisting.
    for (const [eachTitle, listingsWithTheTitle] of Object.entries(titlesInverted)) {
      listingsWithTheTitle.forEach((eachListing) => {
        if (!listingSamplesMap.has(eachListing)) {
          listingSamplesMap.set(eachListing, { adTitle: eachTitle } as IAdListingSample);
        } else {
          const sample = listingSamplesMap.get(eachListing);
          if (sample) {
            sample.adTitle = eachTitle;
          }
        }
      });
    }

    for (const [eachUrl, listingsWithTheUrl] of Object.entries(urlsInverted)) {
      listingsWithTheUrl.forEach((eachListing) => {
        if (!listingSamplesMap.has(eachListing)) {
          listingSamplesMap.set(eachListing, { adUrl: eachUrl } as IAdListingSample);
        } else {
          const sample = listingSamplesMap.get(eachListing);
          if (sample) {
            sample.adUrl = eachUrl;
          }
        }
      });
    }

    for (const [eachDestUrl, listingsWithTheDestUrl] of Object.entries(destUrlsInverted)) {
      listingsWithTheDestUrl.forEach((eachListing) => {
        if (!listingSamplesMap.has(eachListing)) {
          listingSamplesMap.set(eachListing, { adDestUrl: eachDestUrl } as IAdListingSample);
        } else {
          const sample = listingSamplesMap.get(eachListing);
          if (sample) {
            sample.adDestUrl = eachDestUrl;
          }
        }
      });
    }

    for (const [eachImage, listingsWithTheImage] of Object.entries(imagesInverted)) {
      listingsWithTheImage.forEach((eachListing) => {
        if (!listingSamplesMap.has(eachListing)) {
          listingSamplesMap.set(eachListing, { adimage: sanitizeBingImageUrl(eachImage) } as IAdListingSample);
        } else {
          const sample = listingSamplesMap.get(eachListing);
          if (sample) {
            sample.adImage = sanitizeBingImageUrl(eachImage);
          }
        }
      });
    }

    listingSamples = Array.from(listingSamplesMap.values());

    // in case of empty samples we will just push one empty sample to avoid downstream errors.
    if (listingSamples.length < 1) {
      listingSamples.push({});
    }

    const adListingsData: IAdListingsData = {
      listingsCount: listingSamples.length,
      adListingItems: listingSamples,
      adListingSelectedPage: 1,
    };
    return adListingsData;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function getBlockRequestSources(publisherId: number): Promise<BlockRequestSourceData[]> {
  let url = baseAddress + 'adperfdata/blockRequestSources';
  url = url.concat('?targetCustomerId=' + publisherId);

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function createAdBlock(body: BlockAdsData[], params: URLSearchParams): Promise<BlockAdsData> {
  let url = baseAddress + 'adblockdata/bulkAdBlock';
  url = url.concat('?' + params.toString());

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    const error = `data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function createImageBlock(body: BlockImagessData, params: URLSearchParams): Promise<BlockImagessData> {
  let url = baseAddress + 'adblockdata/imagereporting';

  url = url.concat('?' + params.toString());

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    const error = `ad image blocking failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}

export async function createSAMAdBlock(body: SAMBlockAdData[], params: URLSearchParams): Promise<BlockAdsData> {
  let url = baseAddress + 'adblockdata/samrtbBulk';
  url = url.concat('?' + params.toString());

  let headers: Headers;
  try {
    headers = await buildRequestHeadersWithAuthToken(url);
  } catch (createHeaderError) {
    return handleError(createHeaderError, url);
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    const error = `Data fetch operation failed for ${url}. Errors: ${err}`;
    return handleError(new ApiError(500, error), url);
  }
}
