import { ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react';
import React from 'react';

export const getPerformanceShimmer = (): JSX.Element => {
  return (
    <div style={{ width: '142px' }}>
      <ShimmerElementsGroup flexWrap width="100%" shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 37 }]} />
    </div>
  );
};
