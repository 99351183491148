import { Model } from './Model';

export class EntityPreferences extends Model {
  public apiPath = undefined;
  public className = 'preferences';
  public entityId: number;
  public notificationPreferences: NotificationPreferences;
  public systemPreferences: SystemPreferences;
  public tncSignatureStatus: TnCSignatureStatus;
  public showFRECards?: boolean;
}

export class NotificationPreferences {
  public notifications: NotificationPreference[];
}

export class NotificationPreference {
  public type: NotificationType;
  public enabled: boolean;
}

export enum NotificationType {
  BillingAlert = 0,
  ServingStatus = 1,
  ProfileReview = 2,
  InvitedUser = 3,
}

export class SystemPreferences {
  public locale?: string;
  public currency?: string;
  public countryCode?: string;
}

export class TnCSignatureStatus {
  public acceptedVersion: string;
  public signatureDate: string;
  public isLatestAccepted: boolean;
}
