import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import {
  IPhoneVerificationData,
  PhoneVerificationGenerateCode,
  PhoneVerificationMediumType,
  PhoneVerificationSubStatus,
  User,
  getCurrentPublisher,
  isOrganizationPublisher,
  onLoadingChanged,
  showErrorToastNotification,
} from '../../../../../@data';
import { EN_LOCALE_KEY } from '../../../../../constants';
import { deepClone } from '../../../../../utils';
import { getStore } from '../../../Account/Settings/@data/store/store';
import messages from '../../ProfilePage.messages';
import VerificationErrorsMessages from '../../VerificationErrors.messages';
import {
  generateVerificationCode,
  setIsPhoneVerificationCodeShown,
  setIsPhoneVerificationMethodsShown,
  setPhoneVerificationRequestId,
  setPhoneVerificationResp,
  setVerificationCodeGenerationLimitReached,
} from '../actions';
import { generateCodeAsync } from '../services';
import { VerificationCodeGenerationErrorCodes } from '../store/schema/VerificationCodeGenerationErrorCodes';
import { getProfileStore } from '../store/store';

orchestrator(generateVerificationCode, async ({ profile }) => {
  try {
    const publisher = getCurrentPublisher();

    if (!publisher) {
      return Promise.reject('No publisher found');
    }

    const newUserDetails: User = deepClone(profile.user);
    if (!newUserDetails) {
      throw new Error('User details are missing');
    }

    const { systemPreferences } = getStore().active;
    const phoneVerificationGenerateCode: PhoneVerificationGenerateCode = profile.phoneVerificationGenerateCode;
    phoneVerificationGenerateCode.locale = systemPreferences && systemPreferences.locale ? systemPreferences.locale : EN_LOCALE_KEY;
    if (phoneVerificationGenerateCode.verificationMediumType === PhoneVerificationMediumType.email) {
      phoneVerificationGenerateCode.email = profile.publisher?.extendedProperties?.organizationEmailAddress!;
    } else {
      phoneVerificationGenerateCode.phoneNumberWithCountryCode = newUserDetails.contactInfo?.phone1!;
    }

    const result: IPhoneVerificationData = await generateCodeAsync(phoneVerificationGenerateCode, publisher);

    setPhoneVerificationRequestId(result.requestId);
    setPhoneVerificationResp(result);
    setVerificationCodeGenerationLimitReached(false);
    onLoadingChanged(false);

    if (phoneVerificationGenerateCode.verificationMediumType === PhoneVerificationMediumType.email) {
      setIsPhoneVerificationCodeShown(true);
    }
    // need to check subStatus other options
    await when(
      () => {
        const subStatus = getProfileStore().active.phoneVerificationResp?.subStatus;
        return subStatus === PhoneVerificationSubStatus.deliveryFailed || subStatus === PhoneVerificationSubStatus.codeSent;
      },
      () => {
        const subStatus = getProfileStore().active.phoneVerificationResp?.subStatus;
        if (subStatus === PhoneVerificationSubStatus.codeSent) {
          setIsPhoneVerificationMethodsShown(false);
          setIsPhoneVerificationCodeShown(true);
        } else if (subStatus === PhoneVerificationSubStatus.deliveryFailed) {
          showErrorToastNotification({ textMessageDescriptor: messages.generatedCodeFailed });
        }
      }
    );
  } catch (error) {
    let apiErrorCode: VerificationCodeGenerationErrorCodes;
    switch (error.code) {
      case 400:
        apiErrorCode = JSON.parse(error.message)?.[0]?.code;
        showErrorToastNotification({
          textMessageDescriptor:
            VerificationErrorsMessages[apiErrorCode] ?? isOrganizationPublisher()
              ? messages.generateEmailVerificationCodeFailed
              : messages.generateVerificationCodeFailed,
        });
        break;
      case 403:
        apiErrorCode = JSON.parse(error.message)?.[0]?.code;
        if (apiErrorCode === VerificationCodeGenerationErrorCodes.CODE_GENERATION_LIMIT_REACHED) {
          setIsPhoneVerificationCodeShown(true);
          setVerificationCodeGenerationLimitReached(true);
        }
        break;
      default:
        showErrorToastNotification({
          textMessageDescriptor: isOrganizationPublisher()
            ? messages.generateEmailVerificationCodeFailed
            : messages.generateVerificationCodeFailed,
        });
        break;
    }
    onLoadingChanged(false);
    Log.error(error);
  }
});
