import { InjectedIntl } from 'react-intl';
import { action } from 'satcheljs';
import { IProfileActive, SelfServePublisherActiveModel } from '../store/schema';

export const onInitializeProfilePage = action('onInitializeProfilePage', (publisherId: string, countryCode: string) => ({
  publisherId,
  countryCode,
}));

export const fetchPublisherProfileDetailsAction = action('fetchPublisherProfileDetailsAction', (publisherId: string) => ({ publisherId }));

export const publisherProfileUpdatedAction = action('publisherProfileUpdatedAction');

export const updatePublisherProfileAction = action('updatePublisherProfileAction', (profile: SelfServePublisherActiveModel) => ({
  profile,
}));

export const fetchUserDetailsAction = action('fetchUserDetailsAction', (userId: number) => ({ userId }));

export const editProfileAction = action('editProfileAction');

export const validateAndPreviewProfileChangesAction = action(
  'validateAndPreviewProfileChangesAction',
  (profile: SelfServePublisherActiveModel, formatMessage: InjectedIntl['formatMessage'], isVerificationRequired: boolean) => ({
    profile,
    formatMessage,
    isVerificationRequired,
  })
);

export const generateVerificationCode = action('generateVerificationCode', (profile: IProfileActive) => ({ profile }));

export const verifyGeneratedCode = action(
  'verifyGeneratedCode',
  (profile: IProfileActive, formatMessage: InjectedIntl['formatMessage']) => ({ profile, formatMessage })
);

export const validateAddress = action(
  'validateAddress',
  (profile: SelfServePublisherActiveModel, formatMessage: InjectedIntl['formatMessage']) => ({ profile, formatMessage })
);

export const validateOrgAddress = action(
  'validateOrgAddress',
  (profile: SelfServePublisherActiveModel, formatMessage: InjectedIntl['formatMessage']) => ({ profile, formatMessage })
);
