import { nullRender } from '@fluentui/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getAppStore, onPublisherChanged, onPublisherSelected, Publisher } from '../../@data';
import { PublisherAccountPicker } from '../../layout/PublisherAccountPicker/PublisherAccountPicker';
import { onSetPublisherReporting } from './@data/actions';
import { IReportParams } from './@data/store/schema';
import { defaultRoute, getPageStore, RouteName } from './@data/store/store';

export const ReportHeader = observer(
  class ReportHeaderComponent extends React.Component<RouteComponentProps<IReportParams>> {
    public componentDidMount() {
      const publisherId = this.props.match.params.publisherId;

      onPublisherSelected(publisherId);
    }

    public render(): JSX.Element | null {
      const appStore = getAppStore();
      const store = getPageStore();

      if (store.loading) {
        return nullRender();
      }

      return (
        <PublisherAccountPicker
          {...this.props}
          onChange={this._onPublisherChanged}
          publisher={appStore.publisher}
          isAccountHidden={true}
          isAccountSelectionDisabled={true}
        />
      );
    }

    private _onPublisherChanged = (publisher?: Publisher) => {
      const store = getPageStore();
      const currentRoute = RouteName[store.currentRoute || defaultRoute];

      const publisherId = publisher ? publisher.id : 0;

      if (publisher && publisherId !== 0) {
        this.props.history.push(`/report/${publisherId}/${currentRoute}`);
        onSetPublisherReporting(publisher);
      } else {
        this.props.history.push(`/report/${currentRoute}`);
      }
      onPublisherChanged(publisher);
    };
  }
);
