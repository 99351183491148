import { MessageBarType, getTheme } from '@fluentui/react';
import html2canvas from 'html2canvas';
import { IPubcenterSpacing } from '../../../../theme';
import { onNotificationChanged } from '../../../@data';
import { breakdownDate } from '../../../utils';
import { IMetadataElement } from '../DashboardCards/@data/store/schema';
import { ChartGrain } from '../DashboardCards/ReportCard/ReportCard.types';
import messages from '../Report.messages';
import { ICustomCalenderDateRange } from '../components/CustomCalender/CustomCalender.types';
import { downloadCSV } from './downloadCSV';
import { downloadFile } from './downloadFile';
import { FormatMessageFuncType, getReportColumnMetadataDisplayName } from './intlHelper';

export function exportToCsv(data: object[], columns: IMetadataElement[], filename, formatMessage: FormatMessageFuncType) {
  if (!filename || columns.length <= 0) {
    return;
  }
  const csvData = data
    .map((row) => {
      return columns
        .map((col) => {
          return row[col.key];
        })
        .join(',');
    })
    .join('\n');
  const csvHeaders = columns
    .map((col) => {
      return getReportColumnMetadataDisplayName(col.key, formatMessage);
    })
    .join(',');

  downloadCSV(csvData, csvHeaders, filename);
}

export async function exportToPNG(node: HTMLElement, filename: string) {
  try {
    if (!filename || !node) {
      return;
    }
    const theme = getTheme();
    const spacing = theme.spacing as IPubcenterSpacing;
    node.style.padding = `${spacing.size8} ${spacing.size16}`;
    const dataUrl = (await html2canvas(node)).toDataURL();
    downloadFile(dataUrl, `${filename}.png`);
  } catch (error) {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.failedToExportReportAsPNG,
    });
  }
}
export function getExportFileName(
  calendarDateRange: ICustomCalenderDateRange,
  chartGrain: ChartGrain,
  reportTitle: string,
  locale: string
) {
  const brokenDownStartDate = breakdownDate(calendarDateRange.startDate, locale);
  const brokenDownEndDate = breakdownDate(calendarDateRange.endDate, locale);
  const startDateString = `${brokenDownStartDate.month}-${brokenDownStartDate.day}-${brokenDownStartDate.year}`;
  const endDateString = `${brokenDownEndDate.month}-${brokenDownEndDate.day}-${brokenDownEndDate.year}`;
  const exportedFileName = chartGrain ? constructName(reportTitle, chartGrain, startDateString, endDateString) : '';
  return exportedFileName;
}

export function constructName(reportName: string, currentChartGrain: ChartGrain, startDateString: string, endDateString: string) {
  return `${reportName}${currentChartGrain === ChartGrain.NONE ? '-' : `-${currentChartGrain}-`}${startDateString}-to-${endDateString}`;
}
