import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  getAppStore,
  getEntity,
  LifeCycleStatus,
  onLoadingChanged,
  onLoadingSetting,
  onLoadingWaiting,
  onNotificationChanged,
  PaymentInstrumentLifeCycleStatus,
  PaymentOption,
  Publisher,
} from '../../../../../@data';
import { getSupportedCountries, getSupportedLanguages, getSupportedTimezones } from '../../../../../@data/services/MetadataService';
import { AccountProfileFeature } from '../../../../../@data/store/schema/enums/AccountProfileFeature';
import { AdExtensionType } from '../../../../../@data/store/schema/enums/AdExtensionType';
import { FinancialStatus } from '../../../../../@data/store/schema/enums/FinancialStatus';
import { FormState } from '../../../../../components/Form';
import { countryToCurrencyMap } from '../../../../../config/countryToCurrencyMapper.config';
import { setPublisherContext } from '../../../@data/service/PartnerManagementService';
import { onAccountChanged, onFormInitialized, onRawChanged } from '../actions';
import { onAccountEnablePrivateMarketplaceChanged } from '../mutatorActions';
import { fetchAccountById, fetchAllAvailableUsers, fetchAllMediumAccountSkus } from '../services/AccountService';
import { IAccountViewModel } from '../store/schema';

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();
  try {
    const publisherId = parseInt(msg.publisherId, 10);
    if (!isNaN(publisherId)) {
      const publisher = new Publisher(publisherId);
      const account: IAccountViewModel = {};
      setPublisherContext(publisher);
      onLoadingChanged(true);
      onLoadingSetting(false);
      const timeout = 30000;
      const id = setTimeout(() => onLoadingWaiting(true), timeout);

      const rawPublisher = await getEntity<Publisher>([publisher], userContext);
      account.availableLanguages = await getSupportedLanguages();
      account.availableTimezones = await getSupportedTimezones();
      account.availableCountries = await getSupportedCountries(rawPublisher.serviceLevel!);
      account.availableUsers = await fetchAllAvailableUsers(publisher, userContext);
      account.isDistributionReportingApplicable = rawPublisher.allowDistributionReporting || rawPublisher.allowDistributionV2Reporting;
      clearTimeout(id);
      account.isStartDistributionReportingApplicable = rawPublisher.allowStartDistributionReporting;
      account.isStartDistributionContentReportingApplicable = rawPublisher.allowStartDistributionContentReporting;

      account.primaryContactUserId = account.availableUsers && account.availableUsers.length > 0 ? account.availableUsers[0].id : undefined;
      account.languageCode = account.availableLanguages && account.availableLanguages.length > 0 ? 'EN' : undefined;
      account.countryCode = account.availableCountries && account.availableCountries.length > 0 ? 'US' : undefined;
      account.currencyCode = account.countryCode && countryToCurrencyMap[account.countryCode];
      account.timeZoneId = account.availableTimezones && account.availableTimezones.length > 0 ? 75 : undefined;

      account.status = LifeCycleStatus.Active;
      account.paymentOption = PaymentOption.MonthlyThreshold;
      account.financialStatus = FinancialStatus.TaxOnHold;
      account.paymentLifeCycleStatus = PaymentInstrumentLifeCycleStatus.Validated;
      account.revenueSharePercentage = 100;
      account.operationRatio = 0;
      account.allowGlobalDefaultKeywords = true;
      account.allowPassedKeyword = true;
      account.allowCallExtensions = true;
      account.allowSiteLinkExtensions = true;
      account.allowLocationExtensions = true;
      account.allowMeteredCallExtensions = true;
      account.allowMerchantRatingExtensions = true;

      if (msg.mode === FormState.Edit && msg.entityId) {
        const rawaccount = await fetchAccountById(publisher, msg.entityId, userContext);
        const accountSkuInfo = await fetchAllMediumAccountSkus(publisher, msg.entityId, userContext);

        account.id = rawaccount.id;
        account.name = String(rawaccount.name);
        account.number = rawaccount.number;
        account.primaryContactUserId = rawaccount.primaryContactUserId;
        account.status = rawaccount.status;
        account.countryCode = rawaccount.countryCode;
        account.languageCode = rawaccount.languageCode;
        account.currencyCode = rawaccount.currencyCode;
        account.timeZoneId = rawaccount.timeZoneId;
        account.adDeliverySettings = rawaccount.adDeliverySettings;
        account.additionalMarkets = rawaccount.additionalMarkets;

        if (rawaccount.paymentInformation) {
          account.financialStatus = rawaccount.paymentInformation.financialStatus;
          account.paymentLifeCycleStatus = rawaccount.paymentInformation.paymentInstrumentationLifeCycleStatus;
          account.paymentOption = rawaccount.paymentInformation.paymentOption;
          account.operationRatio = rawaccount.paymentInformation.operationCostRatio;
          account.revenueSharePercentage = rawaccount.paymentInformation.revenueSharePercentage;
        }

        if (rawaccount.enabledFeatures) {
          account.allowGlobalDefaultKeywords = rawaccount.enabledFeatures.some(
            (enFeature) => enFeature === AccountProfileFeature.EnableGlobalDefaultKeywords
          );
          account.allowAdultAds = rawaccount.enabledFeatures.some((enFeature) => enFeature === AccountProfileFeature.EnableAdultAds);
          account.allowCrawling = rawaccount.enabledFeatures.some((enFeature) => enFeature === AccountProfileFeature.EnableCrawling);
          account.allowPassedURL = rawaccount.enabledFeatures.some((enFeature) => enFeature === AccountProfileFeature.EnablePassedUrls);
          account.allowPassedKeyword = rawaccount.enabledFeatures.some(
            (enFeature) => enFeature === AccountProfileFeature.EnablePassedKeywords
          );
        }

        if (rawaccount.adExtensionOptions) {
          account.allowSiteLinkExtensions = rawaccount.adExtensionOptions.some(
            (adExtension) => adExtension.type === AdExtensionType.SiteLink && adExtension.enabled === true
          );
          account.allowPhoneExtensions = rawaccount.adExtensionOptions.some(
            (adExtension) => adExtension.type === AdExtensionType.Phone && adExtension.enabled === true
          );
          account.allowLocalExtensions = rawaccount.adExtensionOptions.some(
            (adExtension) => adExtension.type === AdExtensionType.Local && adExtension.enabled === true
          );
          account.allowLocationExtensions = rawaccount.adExtensionOptions.some(
            (adExtension) => adExtension.type === AdExtensionType.Location && adExtension.enabled === true
          );
          account.allowMeteredCallExtensions = rawaccount.adExtensionOptions.some(
            (adExtension) => adExtension.type === AdExtensionType.MeteredCall && adExtension.enabled === true
          );
          account.allowMerchantRatingExtensions = rawaccount.adExtensionOptions.some(
            (adExtension) => adExtension.type === AdExtensionType.MerchantRatings && adExtension.enabled === true
          );
          account.allowCallExtensions = rawaccount.adExtensionOptions.some(
            (adExtension) => adExtension.type === AdExtensionType.Call && adExtension.enabled === true
          );
        }

        if (rawaccount.storeIds && rawaccount.storeIds.length > 0) {
          account.storeIds = rawaccount.storeIds.join('\n');
        }
        if (rawaccount.partnerCodes && rawaccount.partnerCodes.length > 0) {
          account.partnerCodes = rawaccount.partnerCodes.join('\n');
        }
        if (rawaccount.formCodes && rawaccount.formCodes.length > 0) {
          account.formCodes = rawaccount.formCodes.join('\n');
        }
        if (rawaccount.startDistributionPlacementCodes && rawaccount.startDistributionPlacementCodes.length > 0) {
          account.startDistributionPlacementCodes = rawaccount.startDistributionPlacementCodes.join('\n');
        }
        if (rawaccount.startDistributionOCIDL2s && rawaccount.startDistributionOCIDL2s.length > 0) {
          account.startDistributionOCIDL2s = rawaccount.startDistributionOCIDL2s.join('\n');
        }
        account.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage =
          rawaccount.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage;
        account.skus = accountSkuInfo.mediumMappedSkus;
        account.nonSKUMappedMediums = accountSkuInfo.nonMappedMediums;

        const privateMarketplace = rawaccount.storeIds && rawaccount.storeIds.length !== 0 ? rawaccount.storeIds.length !== 0 : false;
        onAccountEnablePrivateMarketplaceChanged(privateMarketplace);

        onRawChanged(rawaccount);
      } else {
        onRawChanged();
      }

      onAccountChanged(account);
    } else {
      onNotificationChanged({
        text: `You have arrived in an invalid state, please report.`,
        messageBarType: MessageBarType.severeWarning,
      });
    }
  } catch (e) {
    onNotificationChanged({
      text: 'Failed to load entity',
      messageBarType: MessageBarType.severeWarning,
    });
  } finally {
    onLoadingChanged(false);
  }
});
