import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { setTimeout } from 'timers';
import { Log } from '../../../../../../../logging/src';
import { getAppStore } from '../../../../../../@data';
import { FormTitle } from '../../../../../../components/Form';
import { isInPageForm } from '../../../../../../components/Form/FormState/formUtils';
import { COPY_BUTTON_TIMEOUT } from '../../../../../../constants/AppConstants';
import { injectIntlWithObserver } from '../../../../../../utils';
import { getAdunitStore } from '../../../@data';
import scriptPlacementImage from '../../resources/scriptPlacement.png';
import { NoScriptPage } from '../NoScriptPage/NoScriptPage';
import { onCopyCodeIconChanged, onCopyScriptIconChanged, onScriptPageInitialized } from './@data/actions/onScriptPageActions';
import './@data/mutators/scriptChanged';
import './@data/orchestrators';
import { getScriptPageStore } from './@data/store/store';
import messages from './ScriptPage.messages';
import { getClassNames } from './ScriptPage.styles';
import { IScriptPageProps } from './ScriptPage.types';

/**
 *
 * TODO: we should not dependent on API to provide this script, going forward we need move the script
 * generation logic to UI side and reduce the load time for script generation.
 * Track this here https://msasg.visualstudio.com/Bing_Ads/_workitems/edit/7434728
 */
const adUnitSnippetSerializer = ({
  originalScript,
  adUnitId,
}: {
  originalScript?: string;
  adUnitId?: string | number;
}): string | boolean => {
  // Extracting the adUnitId from the input string or from props
  const adUnitIdMatch = originalScript ? originalScript.match(/adUnitId:\s*"(.*?)"/)?.[1] : adUnitId ? adUnitId.toString() : '';

  if (!adUnitIdMatch) {
    Log.error(`Invalid props, ${originalScript}, ${adUnitId}`);
    return false;
  }

  const output = `
<div id="${adUnitIdMatch}"></div>
<script>
  window.msAdsQueue.push(function(){
    window.mmnow.render({
      adUnitId: ${adUnitIdMatch},
      elementId: ${adUnitIdMatch}
    });
  });
</script>
  `;
  return output.trim();
};

export const NativeAdScriptPage = injectIntlWithObserver(function ScriptPage(props: IScriptPageProps) {
  const { formatMessage } = props.intl;
  const classNames = getClassNames();
  const appStore = getAppStore();
  const { script: scriptRaw, copyScriptIcon, copyCodeIcon } = getScriptPageStore();
  // In case of mode=FormState.InPageView, adunitId should be passed as a prop
  const adunitId = props.match.params.id || props.adunitId || getAdunitStore().active.id?.toString();
  let publisherId = props.match.params.publisherId;
  // In case publisherId is not extracted from route, fetch it from appStore
  if (!publisherId && appStore.publisher) {
    publisherId = appStore.publisher?.id;
  }

  const script = React.useMemo(() => scriptRaw?.replace('$SITE_ID', props.siteId?.toString?.() ?? 'unknown'), [scriptRaw, props.siteId]);
  const shouldManualTriggerInitialization = React.useMemo(() => props.shouldRenderComponent && publisherId && adunitId && !scriptRaw, [
    props.shouldRenderComponent,
    publisherId,
    adunitId,
    scriptRaw,
  ]);
  // We might have to update this script splitting logic whenever script changes
  const headerScript = script?.substring(0, script.indexOf('<div'));
  const adRenderingCode = ((script?.substring(script.indexOf('<div')) &&
    adUnitSnippetSerializer({ originalScript: script, adUnitId: adunitId })) ||
    '') as string;

  const onCopyScriptClicked = (text: string) => {
    navigator.clipboard.writeText(text);
    // This change of icon will indicate the user that script copy was successful
    onCopyScriptIconChanged('Accept');
    // After 3000ms we change the icon back to 'Copy'
    setTimeout(() => onCopyScriptIconChanged('Copy'), COPY_BUTTON_TIMEOUT);
  };

  const onCopyCodeClicked = (text: string) => {
    // Replace narrow no-break space with empty space
    navigator.clipboard.writeText(text.replace(/\u202F/g, ' '));
    onCopyCodeIconChanged('Accept');
    setTimeout(() => onCopyCodeIconChanged('Copy'), COPY_BUTTON_TIMEOUT);
  };

  useEffect(() => {
    if (publisherId && adunitId && adunitId !== 'undefined') {
      onScriptPageInitialized(publisherId, adunitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldManualTriggerInitialization) {
      onScriptPageInitialized(publisherId!, adunitId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldManualTriggerInitialization]);

  // This useEffect will focus on the 'Finish' button when the component is rendered
  useEffect(() => {
    if (script && props.shouldRenderComponent) {
      const buttons = document.querySelectorAll('button');
      buttons.forEach((button) => {
        if (button.innerText === 'Finish') {
          button.focus();
        }
      });
    }
  }, [props.shouldRenderComponent, script]); // The empty dependency array ensures this runs only once, when the component mounts.

  if (props.shouldRenderComponent !== undefined && !props.shouldRenderComponent) {
    return null;
  }

  return script === null || script === undefined ? (
    <NoScriptPage {...props} mode={props.mode} adunitId={parseInt(adunitId!, 10)} />
  ) : (
    <div className={classNames.root}>
      {isInPageForm(props.mode) && <FormTitle title={formatMessage(messages.title)} intl={props.intl} noHelpPopup={true} />}
      <p className={classNames.tipsText}>{formatMessage(messages.tips)}</p>
      <h2 className={classNames.title}>{formatMessage(messages.subTitle1)}</h2>
      <div className={classNames.scriptWithImage}>
        <div className={classNames.scriptSectionLeft}>
          <div className={classNames.scriptBox}>
            <code className={classNames.scriptHolder}>{headerScript}</code>
            <DefaultButton
              text={formatMessage(messages.copyCodeButton)}
              iconProps={{ iconName: copyScriptIcon }}
              onClick={() => onCopyScriptClicked(headerScript!)}
              ariaDescription={formatMessage(messages.scriptCodePasteGuideline)}
            />
          </div>
        </div>
        <img src={scriptPlacementImage} alt={formatMessage(messages.copyScriptImageAlt)} width={285} height={178} />
      </div>
      <h2 className={classNames.title}>{formatMessage(messages.subTitle2)}</h2>
      <div className={classNames.scriptBox}>
        <code className={classNames.scriptHolder}>{adRenderingCode}</code>
        <DefaultButton
          text={formatMessage(messages.copyCodeButton)}
          iconProps={{ iconName: copyCodeIcon }}
          onClick={() => onCopyCodeClicked(adRenderingCode!)}
          ariaDescription={formatMessage(messages.copyCodeButtonDescription)}
        />
      </div>
    </div>
  );
});
