import { action } from 'satcheljs';

export const onInitializeGoogleAccount = action(
  'onInitializeGoogleAccount',
  (showLoadingSpinner?: boolean, showNotifications?: boolean, onFinished?: () => void) => ({
    showLoadingSpinner,
    showNotifications,
    onFinished,
  })
);

export const onMediateGoogleAccount = action(
  'onMediateGoogleAccount',
  (
    mediate: boolean,
    formatMessage: (
      messageDescriptor: ReactIntl.FormattedMessage.MessageDescriptor,
      values?:
        | {
            [key: string]: ReactIntl.MessageValue;
          }
        | undefined
    ) => string
  ) => ({ mediate, formatMessage })
);

export const onConnectGoogleAccount = action('onConnectGoogleAccount', (code: string) => ({ code }));

export const onDisconnectGoogleAccount = action('onDisconnectGoogleAccount');
