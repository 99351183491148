import { array } from 'yup';

/**
 * Schema which transform a string into an array string.
 */
export class StringListSchemaYupType extends array {
  constructor() {
    super();

    this.withMutation(() => {
      this.transform(function(value, originalvalue) {
        if (this.isType(value)) {
          return value;
        }

        const strs = originalvalue.split('\n');
        return strs.filter(i => i !== '');
      });
    });
  }
}
