import { AnimationClassNames, FontWeights } from '@fluentui/react';
import { IHierarchicalMultiSelectPickerStyleProps, IHierarchicalMultiSelectPickerStyles } from '.';
import { IPubcenterSpacing } from '../../../theme';

export const getStyles = (props: IHierarchicalMultiSelectPickerStyleProps): IHierarchicalMultiSelectPickerStyles => {
  const { theme, height, hasErrorMessage } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const defaultHeight = 260;
  const { semanticColors } = theme;

  return {
    root: ['ms-HierarchicalMultiSelectPicker'],
    picker: {
      height: height || defaultHeight,
      display: 'flex',
      flexDirection: 'row',
      marginTop: spacing.size8,
      width: 860,
    },
    itemsPane: [
      {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.neutralTertiary,
        selectors: {
          ':first-child': {
            marginRight: spacing.size16,
            flex: 2,
          },
        },
      },
      hasErrorMessage && {
        borderColor: semanticColors.errorText,
        selectors: {
          '&:focus, &:hover': {
            borderColor: semanticColors.errorText,
          },
        },
      },
    ],
    subPane: [
      {
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'auto',
        height: '-webkit-fill-available',
      },
    ],
    subItemsPane: [
      {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
      },
    ],
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: spacing.size8,
      marginBottom: spacing.size8,
    },
    headerLabel: [
      'ms-font-m',
      {
        fontWeight: FontWeights.semibold,
        marginLeft: spacing.size8,
      },
    ],
    textButton: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: spacing.size8,
    },
    errorMessage: [
      'ms-HierarchicalMultiSelectPicker-errorMessage',
      AnimationClassNames.slideDownIn20,
      theme.fonts.small,
      {
        color: semanticColors.errorText,
        margin: 0,
        paddingTop: 5,
        display: 'flex',
        alignItems: 'center',
      },
    ],
    list: {
      flex: 1,
      overflowY: 'auto',
    },
    disabledPanel: {
      backgroundColor: theme.palette.neutralLighter,
    },
    listItem: {
      display: 'flex',
      paddingLeft: spacing.size8,
      paddingRight: spacing.size8,
      height: spacing.size36,
      lineHeight: spacing.size36,
      cursor: 'pointer',
      selectors: {
        ':hover': {
          backgroundColor: theme.palette.neutralLighter,
        },
      },
    },
    disabledListItem: {
      backgroundColor: theme.palette.neutralLight,
    },
    listItemLabel: [
      'ms-font-m',
      {
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    ],
    headerTitle: [
      theme.fonts.medium,
      {
        padding: '10px 0',
        fontWeight: FontWeights.semibold,
        marginLeft: spacing.size8,
      },
    ],
    groupHeader: [
      'ms-font-m',
      {
        display: 'flex',
        alignItems: 'center',
        marginTop: spacing.size8,
        marginLeft: spacing.size4,
        color: theme.palette.neutralSecondary,
      },
    ],
    groupHeaderIcon: {
      marginLeft: spacing.size4,
    },
    selectedPaneItem: {
      paddingLeft: spacing.size16,
    },
    subComponentStyles: {
      search: {
        root: {
          height: spacing.size48,
        },
      },
      iconButton: {
        root: [
          'ms-font-m',
          {
            color: theme.palette.themePrimary,
          },
        ],
      },
    },
  };
};
