import { useEffect, useMemo, useState } from 'react';
import { Log } from '../../../../../../../logging/src';
import { InventoryPlatformType, Property, listEntity, postEntity, putEntity } from '../../../../../../@data';
import { PropertyServingConfigs } from '../../../../../../@data/store/schema/models/ServingConfigs';
import { fetchPropertyById } from '../../../@data/services/PropertyService';
import messages from './messages';

enum ErrorType {
  SellerIdDoesNotMatchApp = 'SellerIdDoesNotMatchApp',
  PropertyNameAlreadyExists = 'PropertyNameAlreadyExists',
}

const useCreateTythonAppProperty = ({ mode, propertyId, publisher, userContext, formatMessage }) => {
  const [appName, setAppName] = useState<string>('');
  const [appUrl, setAppUrl] = useState<string>('');
  const [appSearchString, setAppSearchString] = useState<string>('');
  const [appAgreement, setAppAgreement] = useState<boolean>(false);
  const [appSellerId, setAppSellerId] = useState<string>('');
  const [isPropertyLoading, setIsPropertyLoading] = useState<boolean>(false);
  const [inventoryAppStoreId, setInventoryAppStoreId] = useState<string>(''); // TODO: update type
  const [rawProperty, setRawProperty] = useState<Property | undefined>(undefined); // TODO: update type
  const [rawPropertyServingConfigs, setRawPropertyServingConfigs] = useState<PropertyServingConfigs[] | undefined>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorState, setErrorState] = useState({ appSellerId: undefined, appName: undefined, appUrl: undefined });
  const isEditMode = propertyId;

  // clean up app url error state when updating url
  useEffect(() => {
    setErrorState({ ...errorState, appUrl: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUrl]);

  // clean up app seller id error state when updating seller id
  useEffect(() => {
    setErrorState({ ...errorState, appSellerId: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSellerId]);

  // clean up app name error state when updating app name
  useEffect(() => {
    setErrorState({ ...errorState, appName: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appName]);

  const handlePropertyErrors = (error) => {
    try {
      const nextErrorState = { ...errorState };
      const errorMsg = Error(error).message.toString();
      if (errorMsg.includes(ErrorType.SellerIdDoesNotMatchApp)) {
        nextErrorState.appSellerId = formatMessage(messages.errorSellerIdDoesNotMatchApp);
      }
      if (errorMsg.includes(ErrorType.PropertyNameAlreadyExists)) {
        nextErrorState.appName = formatMessage(messages.errorPropertyNameAlreadyExists);
      }
      setErrorState(nextErrorState);
    } catch (e) {
      Log.error(`error in app inventory view: ${error}`);
    }
  };

  const onAppPropertyFormSubmit = async (action) => {
    let shouldRedirect = false;
    if (!isEditMode && rawPropertyServingConfigs?.some((p) => p.url === appUrl)) {
      setErrorState({ ...errorState, appUrl: formatMessage(messages.errorInventoryAppUrlAlreadyExists) });
      return;
    }
    if (!isEditMode && appUrl.includes('/')) {
      setErrorState({ ...errorState, appUrl: formatMessage(messages.appPropertyInvalidUrl) });
      return;
    }

    try {
      setIsSaving(true);
      const appProperty = {
        name: appName,
        className: 'properties',
        approvalStatus: 'Approved',
        approvalStatusComments: '',
        publisherId: publisher.id,
        url: appUrl,
        MicrosoftStoreAppId: inventoryAppStoreId,
        COPPAAgreement: appAgreement,
        inventoryPlatformType: InventoryPlatformType.UWP,
        associatedAdUnitIds: [],
        clarityType: 1,
        PartnerCenterSellerId: appSellerId,
      };

      if (isEditMode) {
        const property = await putEntity(
          [publisher],
          ({ ...appProperty, id: rawProperty?.id!, etag: rawProperty?.eTag } as unknown) as Property,
          userContext,
          Property
        );
        setRawProperty(property);
        action();
      } else {
        const property = await postEntity([publisher], Property, (appProperty as unknown) as Property, userContext);
        const appPropertyId = property.id;
        shouldRedirect = true;
        window.location.replace(`/ad-management/${publisher.id}/property/editApp/${appPropertyId}?step=1`);
        return Promise.resolve();
      }
    } catch (error) {
      handlePropertyErrors(error);
    } finally {
      !shouldRedirect && setIsSaving(false);
    }
  };

  const isValidAppInventoryCreation = useMemo(() => {
    if (isEditMode) {
      return appName.trim() !== '' && appUrl.trim() !== '' && inventoryAppStoreId.trim() !== '';
    }
    return appName.trim() !== '' && appUrl.trim() !== '' && appSellerId.trim() !== '' && inventoryAppStoreId.trim() !== '';
  }, [appName, appUrl, appSellerId, inventoryAppStoreId, isEditMode]);

  useEffect(() => {
    async function fetchPropertyData() {
      setIsPropertyLoading(true);
      if (isEditMode) {
        try {
          const fetchPropertyPromise = fetchPropertyById(publisher, propertyId, userContext);
          const propertyConfigsPromise = listEntity([publisher], userContext, PropertyServingConfigs);
          const [savedProperty, propertyConfigs] = await Promise.all([fetchPropertyPromise, propertyConfigsPromise]);
          const propertyConfig = propertyConfigs.find((p) => p.propertyId.toString() === propertyId);
          setRawPropertyServingConfigs(propertyConfigs);
          setAppName(savedProperty.name! as string);
          setAppUrl(savedProperty.url! as string);
          setInventoryAppStoreId(propertyConfig?.MicrosoftStoreAppId! as string);
          setRawProperty(savedProperty);
          setAppAgreement(savedProperty.COPPAAgreement! as boolean);
        } catch (error) {
          handlePropertyErrors(error);
        } finally {
          setIsPropertyLoading(false);
        }
      } else {
        try {
          const propertyServingConfigs = await listEntity([publisher], userContext, PropertyServingConfigs);
          setRawPropertyServingConfigs(propertyServingConfigs);
        } catch (error) {
          console.error(error);
        } finally {
          setIsPropertyLoading(false);
        }
      }
    }
    fetchPropertyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appPropertyFormProps = {
    mode,
    appName: isPropertyLoading ? formatMessage(messages.loadingLabel) : appName,
    onAppNameChange: setAppName,
    isAppNameInputDisabled: isPropertyLoading,
    appUrl: isPropertyLoading ? formatMessage(messages.loadingLabel) : appUrl,
    onAppUrlChange: setAppUrl,
    isAppUrlInputDisabled: isPropertyLoading || isEditMode,
    appSearchString,
    onAppSearchStringChange: setAppSearchString,
    appAgreement,
    onAppAgreementChange: setAppAgreement,
    appSellerId,
    onAppSellerIdChange: setAppSellerId,
    inventoryAppStoreId,
    onInventoryAppStoreIdChange: setInventoryAppStoreId,
    isSaving,
    errorState,
  };

  return {
    isValidAppInventoryCreation,
    appPropertyFormProps,
    onAppPropertyFormSubmit,
  };
};

export default useCreateTythonAppProperty;
