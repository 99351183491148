import { classNamesFunction, getId, Icon } from '@fluentui/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useWindowSize } from '../../../hooks';
import { getStore } from '../../../hooks/useWindowSize/store/store';
import { isButtonPressKey } from '../../../utils/AccessibilityUtils';
import { getScreenReaderTextStyles } from '../../../utils/GlobalStyleUtils';
import { iconButtonStyles } from './FormSection.styles';
import { IFormSectionProps, IFormSectionStyleProps, IFormSectionStyles } from './FormSection.types';

const getClassNames = classNamesFunction<IFormSectionStyleProps, IFormSectionStyles>();

const { windowStoreSerializer } = useWindowSize();

export const FormSectionBase = observer((props: IFormSectionProps) => {
  const { isWindowSmallSize } = windowStoreSerializer(getStore());

  const { styles, theme, title, ariaDescription, formWidth = isWindowSmallSize ? 324 : 440, rightChevron, hasBorderBottom } = props;
  const [expanded, setExpanded] = useState(props.expanded);
  const classNames = getClassNames(styles, {
    theme: theme!,
    sectionWidth: formWidth,
    spaceBetween: rightChevron,
    expanded,
    hasBorderBottom: hasBorderBottom!,
  });
  const id = getId('formSectionDescription');
  const formSectionDescription =
    (ariaDescription !== undefined ? ariaDescription : '') + 'Click to ' + (expanded ? 'collapse' : 'expand') + ' section.';

  const ChevronIcon = <Icon iconName={expanded ? 'ChevronUp' : 'ChevronDown'} styles={iconButtonStyles(theme!)} />;

  const TitleLabel = (
    <div className={classNames.titleLabel} role="heading" aria-level={2}>
      {title}
    </div>
  );

  const onToggle = (): void => {
    setExpanded(!expanded);
    props.sectionStateChange?.(!expanded);
  };

  return (
    <div className={classNames.root}>
      <div
        className={classNames.title}
        onClick={onToggle}
        onKeyDown={(ev) => isButtonPressKey(ev.key) && onToggle()}
        role="button"
        tabIndex={0}
        aria-expanded={expanded}
        aria-describedby={id}
      >
        {rightChevron ? (
          <>
            {TitleLabel}
            {ChevronIcon}
          </>
        ) : (
          <>
            {ChevronIcon}
            {TitleLabel}
          </>
        )}

        <span id={id} style={getScreenReaderTextStyles()}>
          {formSectionDescription}
        </span>
      </div>
      <div className={classNames.content} hidden={!expanded} aria-hidden={!expanded}>
        {props.children}
      </div>
    </div>
  );
});
