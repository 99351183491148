import { MessageBarButton, MessageBarType } from '@fluentui/react';
import React from 'react';
import { isPublisherActionRequired, isPublisherRejectionUnappealable } from '../../@data';
import { ExternalLink } from '../../components/ExternalLink';
import { INotificationBarItem, NotificationBar } from '../../components/NotificationBar';
import { TNC_HREF_PATH } from '../../constants/AppConstants';
import { NullableJSXElement } from '../../types';
import { RouteName, getAdManagementStore } from './@data';
import { setPublisherRejectedMessageClosedAction } from './@data/actions/entityEvaluationMutatorActions';
import { getClassNames } from './AdManagement.styles';
import profileCardMessages from './Home/components/ProfileCard/ProfileCard.messages';
import { isProfileInEditMode } from './Profile/@data';

export function renderCustomNotifications(): NullableJSXElement {
  const pageStore = getAdManagementStore();
  const { messageBarButtonStyle, linkStyle } = getClassNames();
  const { formatMessage } = this.props.intl;

  const notifications: INotificationBarItem[] = [];

  if (isPublisherRejectionUnappealable() && !pageStore.isPublisherRejectedMessageClosed) {
    notifications.push({
      children: (
        <div>
          {formatMessage(profileCardMessages.rejectedBannerPart1)}
          <ExternalLink href={TNC_HREF_PATH} className={linkStyle}>
            {formatMessage(profileCardMessages.termsAndConditions)}
          </ExternalLink>
          .&nbsp;
          {/* Removing this message as we don't have publisher deletion flow in place yet.
          {formatMessage(profileCardMessages.rejectedBannerPart2)}
          */}
        </div>
      ),
      onDismiss: () => setPublisherRejectedMessageClosedAction(true),
      messageBarType: MessageBarType.error,
      suppressAutoDismiss: true,
    });
  }

  if (isPublisherActionRequired() && !isProfileInEditMode() && !window.location.pathname.includes(`${RouteName.publisherProfile}`)) {
    notifications.push({
      children: (
        <div>
          {formatMessage(profileCardMessages.retryDescription)}
          <MessageBarButton onClick={this._handleProfileEditButtonClick} className={messageBarButtonStyle}>
            {formatMessage(profileCardMessages.viewProfile)}
          </MessageBarButton>
        </div>
      ),
      noDismissButton: true,
      messageBarType: MessageBarType.severeWarning,
      suppressAutoDismiss: true,
    });
  }

  return (
    <NotificationBar
      items={notifications}
      intl={this.props.intl}
      onRemoved={() => {
        return;
      }}
    />
  );
}
