import { useQuery } from '@tanstack/react-query';
import { showErrorToastNotification } from '../../../@data';
import {
  getDimensions as getMediationDimensions,
  getGranularity as getMediationGranularity,
  getMetrics as getMediationMetrics,
  getMediationReportData,
} from '../../../@data/services/MediationReportingService';
import { getDimensions, getGranularity, getMetrics, getReportData } from '../../../@data/services/ReportingService';
import { MediationReportingDataRequestBody } from '../../../@data/store/schema/models/MediationReportingDataRequestBody';
import { ReportingDataRequestBody } from '../../../@data/store/schema/models/ReportingDataRequestBody';
import { getAdunitStore } from '../../ad-management/Adunit/@data';
import { IReportsMetadata, MediationReportingDataRow } from '../DashboardCards/@data/store/schema';
import { getReportType } from '../DashboardCards/@data/store/selectors';
import { mapAdUnitName } from '../DashboardCards/@data/utils/mapAdUnitName';
import { ReportCardType } from '../DashboardCards/ReportCard/ReportCard.types';
import ReportMessages from '../Report.messages';

export function useReportQuery(reportRequestBody: ReportingDataRequestBody, isDetailedReport: boolean, enabled: boolean) {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['reportData', { ...reportRequestBody, columns: reportRequestBody.columns.sort(), callerGuid: '' }],
    queryFn: async () => {
      const { data: reportData } = await getReportData(reportRequestBody, new AbortController());
      return reportData.Data;
    },
    onError: () => {
      const isSummaryReport = ReportCardType[reportRequestBody.reportName] === ReportCardType.SUMMARY;
      const messageDescriptor = isSummaryReport
        ? ReportMessages.loadingReportSummaryFailedMessage
        : isDetailedReport
        ? ReportMessages.loadingDetailedReportFailedMessage
        : ReportMessages.loadingReportFailedMessage;
      showErrorToastNotification({
        textMessageDescriptor: messageDescriptor,
        intlMessageValues: { reportType: reportRequestBody.reportName.toLowerCase() },
      });
    },
    enabled,
    staleTime: 3600000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  return { isLoading, isError, data, error };
}
export function useMediationReportQuery(reportRequestBody: MediationReportingDataRequestBody, enabled: boolean) {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['reportData', { ...reportRequestBody, columns: reportRequestBody.columns.sort(), callerGuid: '' }],
    queryFn: async () => {
      const { data: reportData } = await getMediationReportData(reportRequestBody, new AbortController());
      const {
        adUnitsRaw: { data: adUnitsData },
      } = getAdunitStore();
      const transformedData: MediationReportingDataRow[] = mapAdUnitName(reportData, adUnitsData);
      return transformedData;
    },
    onError: () => {
      showErrorToastNotification({
        textMessageDescriptor: ReportMessages.loadingMediationReportFailedMessage,
      });
    },
    enabled: enabled && !getAdunitStore().isFetchingAdUnitStatus,
    staleTime: 3600000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  return { isLoading, isError, data, error };
}

export function useReportMetadata() {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['reportMetadata'],
    queryFn: async () => {
      const reportType = getReportType();
      const [dimensions, metrics, grains] = await Promise.all([
        getDimensions(reportType),
        getMetrics(reportType),
        getGranularity(reportType),
      ]);
      return { dimensions, metrics, grains } as IReportsMetadata;
    },
    onError: () => {
      showErrorToastNotification({ textMessageDescriptor: ReportMessages.loadingReportMetadataFailedMessage });
    },
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  return { isLoading, isError, data, error };
}
export function useMediationReportMetadata(enabled = true) {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['mediationReportMetadata'],
    queryFn: async () => {
      const reportType = getReportType();
      const [dimensions, metrics, grains] = await Promise.all([
        getMediationDimensions(reportType),
        getMediationMetrics(reportType),
        getMediationGranularity(reportType),
      ]);
      return { dimensions, metrics, grains } as IReportsMetadata;
    },
    onError: () => {
      showErrorToastNotification({ textMessageDescriptor: ReportMessages.loadingReportMetadataFailedMessage });
    },
    enabled,
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  return { isLoading, isError, data, error };
}
