import { DefaultButton, IconButton, Link } from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { setTimeout } from 'timers';
import { ClarityStatus, getAppStore } from '../../../../../../../../@data';
import AppMessages from '../../../../../../../../App.messages';
import { FormTitle, IFormEditParams } from '../../../../../../../../components/Form';
import { CLARITY_WEB_APP, COPY_BUTTON_TIMEOUT } from '../../../../../../../../constants/AppConstants';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../../../../../utils';
import { getCreatePropertyStore } from '../../../../__data__/store/store';
import clarityLogo from '../../resources/clarityLogo.svg';
import scriptPlacementImage from '../../resources/scriptPlacement.png';
import { onCopyScriptIconChanged, onScriptPageInitialized } from './@data/actions/onScriptPageActions';
import './@data/mutators/scriptChanged';
import './@data/orchestrators';
import { getPropertyScriptPageStore } from './@data/store/store';
import messages from './messages';
import { getClassNames } from './styles';

export interface IScriptPageProps extends IRouterWithIntl<IFormEditParams> {
  siteId?: string;
  clarityStatus?: ClarityStatus;
  isShownInPanel?: boolean;
}

export const PropertyScriptPage = injectIntlWithObserver(function ScriptPage(props: IScriptPageProps) {
  const { formatMessage } = props.intl;
  const classNames = getClassNames({ isShownInPanel: props.isShownInPanel || false });
  const appStore = getAppStore();
  const { copyScriptIcon, clarityProjectId } = getPropertyScriptPageStore();
  const { active } = getCreatePropertyStore();

  let publisherId = props.match.params.publisherId;
  const propertyId = props.isShownInPanel ? props.siteId : props.match.params.id;

  // In case publisherId is not extracted from route, fetch it from appStore
  if (!publisherId && appStore.publisher) {
    publisherId = appStore.publisher?.id;
  }

  const HEADER_SCRIPT = `<script>window.msAdsQueue = window.msAdsQueue || [];</script>
  <script async src="https://adsdk.microsoft.com/mmnow/sdk.js?siteId=${propertyId}&publisherId=${publisherId}" crossorigin="anonymous"></script>`;

  const onCopyScriptClicked = (text: string) => {
    navigator.clipboard.writeText(text);
    // This change of icon will indicate the user that script copy was successful
    onCopyScriptIconChanged('Accept');
    // After 3000ms we change the icon back to 'Copy'
    setTimeout(() => onCopyScriptIconChanged('Copy'), COPY_BUTTON_TIMEOUT);
  };

  useEffect(() => {
    if (publisherId && propertyId) {
      onScriptPageInitialized(publisherId, propertyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames.root}>
      <div>
        <FormTitle title={formatMessage(messages.title)} intl={props.intl} noHelpPopup={true} />
        <p className={classNames.tipsText}>{formatMessage(messages.tips)}</p>
        <h2 className={classNames.title}>{formatMessage(messages.subTitle)}</h2>
        <div className={classNames.scriptWithImage}>
          <div className={classNames.scriptSectionLeft}>
            <div className={classNames.scriptBox}>
              <code className={classNames.scriptHolder}>{HEADER_SCRIPT}</code>
              <DefaultButton
                text={formatMessage(messages.copyCodeButton)}
                iconProps={{ iconName: copyScriptIcon }}
                onClick={() => onCopyScriptClicked(HEADER_SCRIPT!)}
              />
            </div>
          </div>
          <img src={scriptPlacementImage} alt={formatMessage(messages.tips)} width={285} height={178} />
        </div>
      </div>
      {clarityProjectId && (
        <div className={classNames.clarityContainer}>
          <div className={classNames.claritySection}>
            <img src={clarityLogo} alt={'clarityLogo'} />
            <p role="heading" className={classNames.claritySectionTitle} aria-level={6}>
              {formatMessage(messages.microsoftClarity)}
            </p>
            <span>
              {formatMessage(messages.microsoftConnectedClarity).replace('{projectId}', clarityProjectId)}
              <Link href={CLARITY_WEB_APP.replace('{id}', clarityProjectId)} target="_blank" rel="noreferrer noopener">
                {formatMessage(AppMessages.here)}
              </Link>
            </span>
            <br />
            <span>{formatMessage(messages.microsoftClarityConnectedAccountNotice)}</span>
          </div>
          {(active.clarity?.clarityStatus === ClarityStatus.Pending || props.clarityStatus === ClarityStatus.Pending) && (
            <div className={classNames.panelPending}>
              <div>
                <IconButton iconProps={{ iconName: 'Info' }} />
              </div>
              <div className={classNames.panelPendingText}>{formatMessage(messages.microsoftClarityEmailSentNotice)}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
