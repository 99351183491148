import { createStore } from 'satcheljs';
import { CustomPanelType, Model, PopupType } from '../../../../@data';
import { PAGINATION_PAGE_SIZES } from '../../../../constants/AppConstants';
import { IGridQuery } from '../../GridV2.types';

export interface IPagination {
  currentPageSize: number;
  currentPageNumber: number;
  numberOfPages: number;
}

export interface IGridV2ViewState<T extends Model> {
  loading: boolean;
  query?: IGridQuery<T>;
  entity?: new () => T;
  filterMenuCalloutOpen?: boolean;
  dialogType: PopupType;
  panelType: CustomPanelType;
  selectedItems: Model[];
  searchWord: string;
  isSearchCalloutVisible: boolean;
  data: Model[];
  paginator: IPagination;
}

export const getStore = createStore<IGridV2ViewState<Model>>('GridV2ViewState', {
  loading: false,
  dialogType: PopupType.None,
  panelType: CustomPanelType.None,
  filterMenuCalloutOpen: false,
  selectedItems: [],
  isSearchCalloutVisible: false,
  searchWord: '',
  data: [],
  paginator: {
    currentPageSize: PAGINATION_PAGE_SIZES[0],
    currentPageNumber: 1,
    numberOfPages: 1,
  },
});
