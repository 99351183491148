import { IPubcenterSpacing } from '../../../../../theme';
import { ICreatePublisherStyleProps, ICreatePublisherStyles } from './CreatePublisher.types';

export const getStyles = (props: ICreatePublisherStyleProps): ICreatePublisherStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    root: {
      marginTop: theme.spacing.m,
      marginLeft: theme.spacing.m
    },
    ipGrid: {
      display: 'flex',
      flexDirection: 'column',
      height: '400px',
      flex: 1
    },
    subComponentStyles: {
      servingTierChoiceGroup: {
        flexContainer: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          selectors: {
            '.ms-ChoiceField': {
              marginRight: theme.spacing.m
            }
          }
        }
      },
      formSection: {
        root: {
          borderBottomWidth: 0
        }
      },
      checkbox: {
        root: {
          marginBottom: spacing.size8
        }
      }
    }
  };
};
