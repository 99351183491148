import { mergeStyleSets } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/style-utilities';
import { IPubcenterSpacing } from '../../../../../../theme';
import { ICommonStyleProps } from '../../../../../types';

type StyleProperties = 'titleStyle' | 'sectionContainerStyle';
type PerformanceSectionStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): PerformanceSectionStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    titleStyle: {
      margin: `${spacing.size15} 0px`,
      fontSize: spacing.size20,
      fontWeight: 600,
    },
    sectionContainerStyle: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(${spacing.size260}, 1fr))`,
      justifyContent: 'space-between',
      gap: spacing.size15,
    },
  });
};

export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
