import { defineMessages } from 'react-intl';

export default defineMessages({
  notificationsNotImplements: {
    id: 'notifications.notImplemented',
    defaultMessage: 'Notifications Not Implemented Yet',
  },
  dismissAll: {
    id: 'notifications.dismissAll',
    defaultMessage: 'Dismiss All',
  },
  dismiss: {
    id: 'notifications.dismiss',
    defaultMessage: 'Dismiss',
  },
  yesterday: {
    id: 'notifications.yesterday',
    defaultMessage: 'Yesterday',
  },
  threeDaysAgo: {
    id: 'notifications.threeDaysAgo',
    defaultMessage: '3 days ago',
  },
  twoDaysAgo: {
    id: 'notifications.twoDaysAgo',
    defaultMessage: '2 days ago',
  },
  allSet: {
    id: 'notifications.allSet',
    defaultMessage: "You'\rre all up-to-date! No new notifications at the moment.",
  },
  activityLogsHint: {
    id: 'notifications.activityLogsHint',
    defaultMessage: 'More events in the activity logs',
  },
  critical: {
    id: 'notifications.critical',
    defaultMessage: 'Critical',
  },
  warning: {
    id: 'notifications.warning',
    defaultMessage: 'Warning',
  },
  informational: {
    id: 'notifications.informational',
    defaultMessage: 'Informational',
  },
  error: {
    id: 'notifications.error',
    defaultMessage: 'Error',
  },
});
