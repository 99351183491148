import { ObjectSchema, Shape } from 'yup';

/**
 * Cleans up the path by normalizing to the its root attribute.
 * @param path the json path error
 */
function normalizePath(path: string) {
  return path.replace(/(\w+)(\[\w+\])*/, '$1');
}
/**
 * Validates the values for the given schema and throws errors in an object accordingly.
 *
 * @param schema The Yup validation Schema
 * @param values The values to validate
 */
export function validateSchema(schema: ObjectSchema<Shape<{}, Object>>, values: Object) {
  return new Promise(async (resolve, reject) => {
    try {
      await schema.validate(values, {
        abortEarly: false,
        stripUnknown: true
      });
      resolve(true);
    } catch (e) {
      reject(
        e.inner &&
          e.inner.reduce(
            (errors, error) => ({
              ...errors,
              [normalizePath(error.path)]: error.errors[0]
            }),
            {}
          )
      );
    }
  });
}
