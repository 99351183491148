import { classNamesFunction, FontWeights, getTheme } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../../../theme';
import { IScriptPageStyleProps, IScriptPageStyles } from './ScriptPage.types';

export const getStyles = (props?: IScriptPageStyleProps): IScriptPageStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const pageWidth = spacing.size896;

  return {
    root: {
      marginRight: spacing.size32,
      maxWidth: pageWidth,
    },
    title: {
      marginBottom: spacing.size16,
      fontSize: theme.fonts.mediumPlus.fontSize,
      fontWeight: FontWeights.semibold,
      lineHeight: '22px',
    },
    tipsText: {
      fontSize: theme.fonts.medium.fontSize,
      lineHeight: spacing.size20,
      margin: `${spacing.size16} 0`,
    },
    scriptWithImage: {
      display: 'flex',
      gap: spacing.size16,
    },
    scriptSectionLeft: {
      display: 'flex',
      flexDirection: 'column',
      width: '-webkit-fill-available',
    },
    messageBar: {
      marginBottom: spacing.size12,
    },
    scriptBox: {
      borderColor: palette.neutralLight,
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: spacing.size12,
      marginBottom: spacing.size16,
    },
    scriptHolder: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      backgroundColor: palette.neutralLighter,
      minHeight: spacing.size16,
      maxHeight: spacing.size146,
      padding: spacing.size12,
      marginBottom: spacing.size12,
      overflow: 'auto',
      color: palette.black,
      lineHeight: spacing.size16,
      fontSize: theme.fonts.small.fontSize,
    },
  };
};

const _getClassNames = classNamesFunction<IScriptPageStyleProps, IScriptPageStyles>();

export const getClassNames = (props?: IScriptPageStyleProps) => _getClassNames(getStyles, props);
