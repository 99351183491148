import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette } from '../../../theme';

export interface IEmptyChartStyleProps {
  theme: ITheme;
}

type StyleProperties = 'noDataText' | 'chartLine' | 'container' | 'chartGrid';
type EmptyChartStyles = Record<StyleProperties, string>;

export const getStyles = (props: IEmptyChartStyleProps): EmptyChartStyles => {
  const { theme } = props;
  const palette = theme.palette as IPubcenterPalette;
  return mergeStyleSets({
    noDataText: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    chartLine: {
      width: '100%',
      borderColor: palette.neutralLight,
      borderStyle: 'solid',
    },
    chartGrid: {
      display: 'flex',
      flexDirection: 'column',
      gap: '70px',
    },
    container: {
      position: 'relative',
      minHeight: '300px',
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
