import { IComboBoxOption } from '@fluentui/react';
import { Account, getAppStore, getEntity, onPublisherChanged, Publisher } from '../../../../@data';
import { IFormEditParams } from '../../../../components/Form';
import { countryToLanguagesMap } from '../../../../config/countryToLanguageMapper.config';
import { LANGUAGES_LANGUAGE_MAPPING } from '../../../../globalization';
import { RoutePrefix } from '../../../../Routes';
import { IRouterWithIntl } from '../../../../utils';
import { RouteName } from '../store/store';

export async function setPublisherContext(publisher: Publisher, account?: Account) {
  const appStore = getAppStore();
  if (appStore.publisher === undefined || appStore.publisher.id !== publisher.id) {
    const publisherAsync = await getEntity<Publisher>([publisher], appStore.userContext);
    if (publisherAsync) {
      const changedPublisher = new Publisher(publisherAsync.id, publisherAsync.name);
      changedPublisher.publisherTier = publisherAsync.publisherTier;
      onPublisherChanged(changedPublisher);
    } else {
      onPublisherChanged(undefined);
    }
  }
}

export function createReturnUrl(entity: RouteName, publisherId?: string): string {
  let returnUrl = `/${RoutePrefix.partnerManagement}/${RouteName[entity]}`;

  if (publisherId) {
    returnUrl = `/${RoutePrefix.partnerManagement}/${publisherId}/${RouteName[entity]}`;
  }

  return returnUrl;
}

export function createAccountAdLanguageDropDown(country: string | undefined, props: IRouterWithIntl<IFormEditParams>): IComboBoxOption[] {
  const availableLanguages: IComboBoxOption[] = [];
  const languageSet: Set<string> = new Set();
  const LANGUAGES = LANGUAGES_LANGUAGE_MAPPING[getAppStore().locale];

  if (country) {
    countryToLanguagesMap[country].forEach((languageCode) => {
      if (LANGUAGES[languageCode]) {
        languageSet.add(languageCode);
      }
    });
  }
  languageSet.forEach((language) => {
    availableLanguages!.push({
      key: language,
      text: LANGUAGES[language],
    });
  });
  availableLanguages.sort((a, b) => {
    const firstValue = a.text;
    const secondValue = b.text;

    return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
  });
  return availableLanguages;
}
