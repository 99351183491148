import { observer } from 'mobx-react';
import React, { RefObject, useState } from 'react';
import { injectIntl } from 'react-intl';
import appMessages from '../../../../App.messages';
import { IRouterWithIntl } from '../../../../utils';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { ICustomCalenderDateRange } from '../../components/CustomCalender/CustomCalender.types';
import { ADUNIT_DEFAULT_COLUMNS, ADUNIT_DEFAULT_METRICS } from '../../constants/common';
import { useReportQuery } from '../../hooks/useReportQuery';
import { IReportsMetadata, TythonReportingDataRow } from '../@data/store/schema';
import { ReportCard } from '../ReportCard/ReportCard';
import { constructRequestBody } from '../ReportCard/ReportCard.helpers';
import { ActiveMetrics, ChartGrain, IChartButton, ReportCardType } from '../ReportCard/ReportCard.types';
import { IActiveFilter } from '../ReportDetailsPage/ActionBar/ActionBar';

const CHART_DATA_METRIC_ID = 'ad-units-chart';
export interface IAdUnitsReportCardProps extends IRouterWithIntl {
  dateRange: ICustomCalenderDateRange;
  filters: IActiveFilter[];
  allowedChartTypes: IChartButton[];
  showDetailsReportLink: boolean;
  enableHideChartsButton?: boolean;
  showMetricsMarkers?: boolean;
  isDetailedView: boolean;
  onChartGrainChanged?: (grain: ChartGrain) => void;
  onSelectedMetricChanged?: (metric: string) => void;
  onActiveMetricsChanged?: (activeMetrics: ActiveMetrics) => void;
  onBreakdownChanged?: (breakdown: string[]) => void;
  reportDetailedData?: TythonReportingDataRow[];
  reportCardRef: RefObject<HTMLDivElement>;
  reportMetadata?: IReportsMetadata;
  summaryData?: TythonReportingDataRow[];
}
export const AdUnitsReportCard = observer(
  injectIntl(
    ({
      dateRange,
      filters,
      allowedChartTypes,
      enableHideChartsButton,
      showDetailsReportLink,
      showMetricsMarkers = false,
      isDetailedView,
      onChartGrainChanged,
      onSelectedMetricChanged,
      onActiveMetricsChanged,
      onBreakdownChanged,
      reportCardRef,
      intl: { formatMessage },
      reportMetadata,
      summaryData,
      reportDetailedData,
      history,
      location,
      match,
    }: IAdUnitsReportCardProps) => {
      const title = formatMessage(appMessages.adUnitsCardTitle);
      const mainDimension = TythonColumnsEnum.AdUnitName;
      const [currentChartGrain, setCurrentChartGrain] = useState(ChartGrain.DAILY);

      const { data: reportData } = useReportQuery(
        constructRequestBody(
          title,
          dateRange,
          currentChartGrain,
          isDetailedView ? filters : [],
          [mainDimension],
          reportMetadata?.metrics || []
        ),
        isDetailedView,
        !!reportMetadata
      );

      return (
        <ReportCard
          title={title}
          reportData={reportData}
          reportDetailedData={reportDetailedData}
          summaryData={summaryData}
          isSummaryDataLoaded={!!summaryData}
          allowedChartTypes={allowedChartTypes}
          allowedChartGrains={reportMetadata?.grains || []}
          dataMetrics={{ chartDataMetrics: CHART_DATA_METRIC_ID }}
          mainDimension={mainDimension}
          type={ReportCardType.ADUNIT}
          enableHideChartsButton={enableHideChartsButton}
          showDetailsReportLink={showDetailsReportLink}
          dateRange={dateRange}
          showMetricsMarkers={showMetricsMarkers}
          isDetailedView={isDetailedView}
          filters={filters}
          history={history}
          location={location}
          match={match}
          defaultColumns={ADUNIT_DEFAULT_COLUMNS}
          defaultMetrics={ADUNIT_DEFAULT_METRICS}
          hasMinimumBreakdown={true}
          reportMetadata={reportMetadata}
          dateDimension={TythonColumnsEnum.DateTime}
          onChartGrainChanged={(updatedGrain) => {
            onChartGrainChanged && onChartGrainChanged(updatedGrain);
            setCurrentChartGrain(updatedGrain);
          }}
          onBreakDownChanged={onBreakdownChanged}
          onSelectedMetricChanged={onSelectedMetricChanged}
          onActiveMetricsChanged={onActiveMetricsChanged}
          reportCardRef={reportCardRef}
        />
      );
    }
  )
);
