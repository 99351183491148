import { Icon, Link } from '@fluentui/react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { getStyles } from './OptionCard.styles';
import { IOptionCardProps } from './OptionCard.types';

export const OptionCard = injectIntl<IOptionCardProps>(function OptionCardComponent({
  content,
  subContent,
  linkText,
  isSelected,
  info,
  linkHref,
}) {
  const { contentStyle, contentInfoStyle, contentMoreInfoStyle, contentMoreStyle, infoIconStyle, helperLink } = getStyles(isSelected);
  return (
    <div className={contentStyle}>
      <div className={contentInfoStyle}>
        {content} {subContent}
      </div>
      <>
        {info && (
          <div className={contentMoreStyle}>
            <Icon className={infoIconStyle} iconName="Info" />
            <span className={contentMoreInfoStyle}>
              {info}
              {!!linkText && (
                <>
                  <Link className={helperLink} href={linkHref} target="_blank" rel="noopener noreferrer">
                    {linkText}
                  </Link>
                </>
              )}
            </span>
          </div>
        )}
      </>
    </div>
  );
});
