import React from 'react';

function ordinal(dayNum: number): string {
  const suffixes = {
    '1': 'st',
    '21': 'st',
    '31': 'st',
    '2': 'nd',
    '22': 'nd',
    '3': 'rd',
    '23': 'rd',
  };
  return suffixes[dayNum] || 'th';
}

export function dateFormate(date: Date, locale: string): JSX.Element {
  return (
    <>
      {date.toLocaleString(locale, { month: 'long' }) + ' ' + date.getDate()}
      <sup>{ordinal(date.getDate())}</sup>
      {', ' + date.getFullYear()}
    </>
  );
}
