import { mixed, object, string } from 'yup';
import { CustomerHelp } from '../../../../../@data/store/schema/enums/CustomerHelp';
import { PublisherType } from '../../../../../@data/store/schema/enums/PublisherType';
import { ISignUpViewModel } from './ISignUpViewModel';

export const ORG_NAME_MIN_LENGTH = 3;
export const ORG_NAME_MAX_LENGTH = 250;

export function signUpErrorModel() {
  return object<ISignUpViewModel>().shape({
    accountType: mixed().oneOf([PublisherType.individual, PublisherType.organization]).required(),
    organizationName: mixed()
      .when('accountType', {
        is: PublisherType.organization,
        then: string().required().min(ORG_NAME_MIN_LENGTH).max(ORG_NAME_MAX_LENGTH),
      })
      .label('Organization name'),
    userFirstName: string().trim().required().label('First name'),
    userLastName: string().trim().required().label('Last name'),
    customerHelp: mixed().oneOf([CustomerHelp.Default, CustomerHelp.NoHelp]),
  });
}
