import { ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { Card } from '../../../../../components/Card/Card';
import { getClassNames } from './MetricCard.styles';

export interface IMetricCardProps {
  title: string;
  dataValue: string;
  isDataLoaded?: boolean;
}

export const MetricCard = observer(({ title, isDataLoaded, dataValue }: IMetricCardProps) => {
  const { metricCard, titleStyle, metricStyle } = getClassNames();
  return (
    <Card
      shimmerCustomElement={
        <ShimmerElementsGroup
          flexWrap
          width="100%"
          shimmerElements={[
            { type: ShimmerElementType.gap, width: '10%', height: 30 },
            { type: ShimmerElementType.line, width: '80%', height: 20 },
            { type: ShimmerElementType.gap, width: '10%', height: 30 },
          ]}
        />
      }
      isDataLoaded={isDataLoaded}
      titleText={title}
      styleProps={{ titleStyle }}
    >
      <div className={metricCard}>
        <div className={metricStyle}>{dataValue ? dataValue : '--'}</div>
      </div>
    </Card>
  );
});
