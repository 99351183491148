import {
  ActionButton,
  Checkbox,
  classNamesFunction,
  ComboBox,
  getTheme,
  IComboBoxOption,
  IProcessedStyleSet,
  Link,
  TextField,
} from '@fluentui/react';
import * as React from 'react';
import {
  getAppStore,
  onHelpInfoCalloutOpened,
  onHelpInfoChanged,
  onIsAcceptInviteFlowChanged,
  onUserInvitationCodeChanged,
} from '../../../../@data';
import { getSupportedLanguagesDropDownOptions } from '../../../../@data/utils/supportedLanguages';
import AppMessages from '../../../../App.messages';
import { FormItem, FormState } from '../../../../components/Form';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { PageSpinner } from '../../../../components/Spinners';
import { ContentLayout } from '../../../../layout/ContentLayout/ContentLayout';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { injectIntlWithObserver } from '../../../../utils';
import { getLoginPageStore, onSwitchAccount } from '../../../login/@data';
import signUpMessages from '../../../signup/component/SignupDetails/SignUpDetails.messages';
import { getStore as getUserPageStore } from '../@data';
import {
  onAcceptInvitePageInitialized,
  onAcceptInvitePageSubmitted,
  onFormCancelled,
  onFormContactByEmailChanged,
  onFormEmailAddressChanged,
  onFormFirstNameChanged,
  onFormLanguageChanged,
  onFormLastNameChanged,
  onFormPhoneNumberChanged,
  onFormUserLocaleChanged,
} from '../@data/actions';
import '../@data/mutators';
import '../@data/orchestrators';
import messages from '../UserPage.messages';
import { getStyles } from './AcceptInvite.styles';
import { IAccepInviteProps, IAcceptInviteStyleProps, IAcceptInviteStyles } from './AcceptInvite.types';

const getClassNames = classNamesFunction<IAcceptInviteStyleProps, IAcceptInviteStyles>();

export const AcceptInvitePage = injectIntlWithObserver(
  class extends React.Component<IAccepInviteProps> {
    private _classNames: IProcessedStyleSet<IAcceptInviteStyles>;
    public componentDidMount() {
      const invitationCode = this.props.match.params.invitationcode;
      const invitationLocale = this.props.match.params.invitationLocale;

      onAcceptInvitePageInitialized(invitationCode, invitationLocale);
    }

    public render(): JSX.Element | null {
      const { formatMessage } = this.props.intl;
      const theme = getTheme();
      const store = getUserPageStore();
      const loginPageStore = getLoginPageStore();
      const { userContext, helpInfo } = getAppStore();
      this._classNames = getClassNames(getStyles, { theme });

      // Spin till loading is set
      if (!userContext?.token && loginPageStore.loading) {
        return <PageSpinner label={formatMessage(AppMessages.loadingLabel)} />;
      }

      return (
        <ContentLayout {...this.props} ignoreNotificationAsync>
          <DetailPage
            titleLabel={formatMessage(messages.acceptInvite)}
            submitLabel={formatMessage(messages.createUser)}
            onSubmit={() => this._onSubmit()}
            onCancel={() => this._onCancel()}
            onNavClick={() => this._onCancel()}
            saving={getAppStore().saving}
            helpId={'pop_PCv4_AcceptInvite'}
            pageMode={FormState.AcceptInvite}
          >
            <>
              <FormItem>
                <TextField
                  label={formatMessage(messages.userName)}
                  ariaLabel={formatMessage(messages.userName)}
                  required={true}
                  value={store.active.userName}
                  disabled={true}
                />
              </FormItem>
              <FormItem>
                <TextField
                  required={true}
                  label={formatMessage(messages.firstName)}
                  ariaLabel={formatMessage(messages.firstName)}
                  onChange={(_, value) => onFormFirstNameChanged(value)}
                  value={store.active.firstName}
                  errorMessage={store.errors.firstName}
                />
              </FormItem>
              <FormItem>
                <TextField
                  required={true}
                  label={formatMessage(messages.lastName)}
                  ariaLabel={formatMessage(messages.lastName)}
                  value={store.active.lastName}
                  onChange={(_, value) => onFormLastNameChanged(value)}
                  errorMessage={store.errors.lastName}
                />
              </FormItem>
              <FormItem formWidth={440}>
                <TextField
                  required={true}
                  label={formatMessage(messages.emailAddress)}
                  ariaLabel={formatMessage(messages.emailAddress)}
                  value={store.active.emailAddress}
                  onChange={(_, value) => onFormEmailAddressChanged(value)}
                  errorMessage={store.errors.emailAddress}
                />
              </FormItem>
              <FormItem>
                <ComboBox
                  useComboBoxAsMenuWidth={true}
                  autoComplete="on"
                  label={formatMessage(messages.preferredLanguage)}
                  required={true}
                  options={getSupportedLanguagesDropDownOptions(getAppStore().locale)}
                  selectedKey={store.active.language}
                  onChange={(_, value) => this._onPreferredLanguageChange(value)}
                  errorMessage={store.errors.language}
                />
              </FormItem>
              <FormItem>
                <FormLabel
                  labelContent={formatMessage(messages.marketingContactSetting)}
                  calloutContent={helpInfo}
                  iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_MarketingContactSettings')}
                  calloutOnDismiss={() => onHelpInfoChanged()}
                  intl={this.props.intl}
                />
              </FormItem>
              <div className={this._classNames.privacyEmailLinkWrapper}>
                <Checkbox
                  required={false}
                  label={formatMessage(signUpMessages.signupEmailOptIn)}
                  ariaLabel={formatMessage(messages.email)}
                  checked={!!store.active.contactByEmail}
                  onChange={(_, value) => onFormContactByEmailChanged(value)}
                />
                <Link href="http://go.microsoft.com/fwlink/?LinkId=521839" target="_blank">
                  {formatMessage(signUpMessages.signupEmailOptInPrivacyLink)}
                </Link>
              </div>
              {store.active.contactByPhone && (
                <FormItem>
                  <TextField
                    label={formatMessage(messages.telephoneNumber)}
                    ariaLabel={formatMessage(messages.telephoneNumber)}
                    value={store.active.phoneNumber}
                    onChange={(_, value) => onFormPhoneNumberChanged(value)}
                    required={true}
                    errorMessage={store.errors.phoneNumber}
                  />
                </FormItem>
              )}
              <label>{formatMessage(messages.useDifferentAccountMessage, { email: store.active.emailAddress })}</label>
              <ActionButton className={this._classNames.switchNowButton} onClick={this._onSwitchAccountButtonClicked}>
                {formatMessage(messages.switchNow)}
              </ActionButton>
            </>
          </DetailPage>
        </ContentLayout>
      );
    }

    private _onSwitchAccountButtonClicked = (): void => {
      const invitationCode = this.props.match.params.invitationcode;
      onIsAcceptInviteFlowChanged(true);
      onUserInvitationCodeChanged(invitationCode);
      onSwitchAccount();
    };

    private _onPreferredLanguageChange = (value?: IComboBoxOption): void => {
      onFormUserLocaleChanged(value);
      value && typeof value.key === 'string' && onFormLanguageChanged(value.key);
    };

    private _onSubmit = (): void => {
      onAcceptInvitePageSubmitted();
    };

    private _onCancel = (): void => {
      onFormCancelled();
    };
  }
);
