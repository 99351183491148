export function setDataInURL<T>(queryKey: string, data: T) {
  const currentSearchQuery = window.location.search;
  const searchQuery = new URLSearchParams(currentSearchQuery);
  searchQuery.set(queryKey, JSON.stringify(data));
  window.history.replaceState('', '', `?${searchQuery}`);
}
export function getDataFromURL<T>(queryKey: string): T | null {
  try {
    const currentSearchQuery = window.location.search;
    const searchQuery = new URLSearchParams(currentSearchQuery);
    return JSON.parse(searchQuery.get(queryKey) || '') as T;
  } catch (e) {
    return null;
  }
}
export function hasDataInURL(queryKey: string): boolean {
  const currentSearchQuery = window.location.search;
  const searchQuery = new URLSearchParams(currentSearchQuery);
  return searchQuery.has(queryKey);
}
