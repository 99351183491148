import { defineMessages } from 'react-intl';

export default defineMessages({
  invite: {
    id: 'userInvitations.invite',
    defaultMessage: 'Invite',
  },
  inviteUser: {
    id: 'userInvitations.inviteUser',
    defaultMessage: 'Invite user',
  },
  resend: {
    id: 'userInvitations.resend',
    defaultMessage: 'Resend',
  },
  send: {
    id: 'userInvitations.send',
    defaultMessage: 'Send invite',
  },
  expirationDate: {
    id: 'userInvitations.expirationDate',
    defaultMessage: 'Invitation expiration date',
  },
  panelDescription: {
    id: 'userInvitations.panelDescription',
    defaultMessage: `Provide the email address and select the user role for the person you'd like to invite.`,
  },
  inviteUserEmailTitle: {
    id: 'userInvitations.inviteUserEmailTitle',
    defaultMessage: 'Email',
  },
  recipientPreferredLanguageTitle: {
    id: 'userInvitations.inviteUserRecipientPreferredLangTitle',
    defaultMessage: "Recipient's preferred language",
  },
  emailPlaceholder: {
    id: 'userInvitations.emailPlaceholder',
    defaultMessage: 'Email address',
  },
  pending: {
    id: 'userInvitations.pending',
    defaultMessage: 'Pending',
  },
  expired: {
    id: 'userInvitations.expired',
    defaultMessage: 'Expired',
  },
  firstNameTitle: {
    id: 'userInvitations.firstNameTitle',
    defaultMessage: 'First name',
  },
  lastNameTitle: {
    id: 'userInvitations.lastNameTitle',
    defaultMessage: 'Last name',
  },
  zeroInvitationsTitle: {
    id: 'userInvitations.zeroInvitationsTitle',
    defaultMessage: 'No pending invitations',
  },
  failedToFetchInvitedUser: {
    id: 'userInvitations.failedToFetchInvitedUserDetails',
    defaultMessage: 'Failed to fetch invited user details',
  },
  invalidState: {
    id: 'userInvitations.invalidState',
    defaultMessage: 'You have arrived in an invalid state, please re-login or report if issue persists.',
  },
  resendEmailInviteFailed: {
    id: 'resendEmailInvite.failed',
    defaultMessage: 'We’re unable to resend the user invitation email at this time. Please try again later.',
  },
  userInvitedNewUserSuccessfully: {
    id: 'userInvitations.invitedNewUserSuccessfully',
    defaultMessage: 'Invited new user successfully!',
  },
  userInvitedNewUserFailed: {
    id: 'userInvitations.inviteNewUserFailed',
    defaultMessage: 'Submitting the user invitation form caught an error "{error}"',
  },
  userInvitationResendSuccessfully: {
    id: 'userInvitations.resendInvitationSuccessfully',
    defaultMessage: 'Resend user invitation to {email} successfully',
  },
  userInvitationInvalidState: {
    id: 'userInvitation.deleteUserInvalidState',
    defaultMessage: 'You have arrived in an invalid state, please report.',
  },
  userInvitationDeletedInvitationSuccessfully: {
    id: 'userInvitation.deletedUserInvitationSuccessfully',
    defaultMessage: 'Deleted invitation for {email} successfully',
  },
  userInvitationDeletingUserInvitation: {
    id: 'userInvitation.deletingUserInvitation',
    defaultMessage: 'Deleting user invitation',
  },
  userInvitationInvalidStateReportOrLogin: {
    id: 'userInvitation.userInvitationInvalidStateReportOrLogin',
    defaultMessage: 'You have arrived in an invalid state, please re-login or report if issue persists.',
  },
  userInviteFormFailed: {
    id: 'userInvitation.userInviteFormFailed',
    defaultMessage: 'User Invite form has {count} errors, please fix them to continue.',
  },
});
