import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { User, getAppStore } from '../../../../../@data';
import { fetchCcmtAccountById } from '../../../../partner-management/Account/@data/services/AccountService';
import { onSettingsChanged } from '../../../Account/Settings/@data/mutatorActions';
import { ISettingsViewModel } from '../../../Account/Settings/@data/store/schema/ISettingsViewModel';
import { getStore } from '../../../Account/Settings/@data/store/store';
import { getProfileStore, isPrimaryUserDataPresent } from '../../../Profile/@data';
import { setPrimaryUserDetailsAction } from '../../../Profile/@data/actions';
import { fetchUserDetails } from '../../../Profile/@data/services/fetchUserDetails';
import { onInitializeSystemPreferences } from '../actions';

orchestrator(onInitializeSystemPreferences, async () => {
  const { userContext, publisher, account } = getAppStore();

  if (userContext && publisher && account) {
    let userDetails: User = new User();
    if (!isPrimaryUserDataPresent()) {
      userDetails = await fetchUserDetails(new User(userContext.id));
      setPrimaryUserDetailsAction(userDetails);
    } else {
      userDetails = getProfileStore().raw.primaryUser!;
    }

    const ccmtAccount = await fetchCcmtAccountById(publisher!, account.id, userContext);

    // set system preference
    const settings: ISettingsViewModel = getStore().active;
    const systemPreferences = {
      locale: userDetails.language?.toLocaleLowerCase(),
      currency: ccmtAccount.currency,
      countryCode: ccmtAccount.countryCode,
    };

    onSettingsChanged({ ...settings, systemPreferences });
  } else {
    Log.error('Could not initialize system preferences');
  }
});
