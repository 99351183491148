import { InjectedIntlProps } from 'react-intl';
import { IDataMetric } from '../../../../types';

export enum RangeType {
  Custom = 'Custom',
  Today = 'Today',
  Yesterday = 'Yesterday',
  SundayToday = 'This week (Sun - Today)',
  MondayToday = 'This week (Mon - Today)',
  Last7ExcludingToday = 'Last 7 days',
  Last7FromSunToSat = 'Last week (Sun - Sat)',
}

export type RangeTypeKey = keyof typeof RangeType;

export const RangeTypeMessage: { [key in RangeTypeKey]: string } = {
  Custom: 'customLabel',
  Today: 'todayLabel',
  Yesterday: 'yesterdayLabel',
  SundayToday: 'sundayTodayLabel',
  MondayToday: 'mondayTodayLabel',
  Last7ExcludingToday: 'last7FromTodayLabel',
  Last7FromSunToSat: 'last7FromSunToSatLabel',
};

export interface ICustomCalenderProps extends InjectedIntlProps, IDataMetric {
  onSelectedDatesChanged: (dateRange: ICustomCalenderDateRange, rangeType: RangeType) => void;
  initialDateRange: ICustomCalenderDateRange;
  initialRangeType: RangeType;
  disableFutureDays?: boolean;
}
export interface ICustomCalenderDateRange {
  startDate: Date;
  endDate: Date;
}

export interface IDay {
  fullDay: IDateElements;
  day: number;
  isInCurrentMonth: boolean;
  isSelected?: boolean;
  isFirstOrLastInSelected?: boolean;
}

export interface IDateElements {
  day: number;
  month: number;
  year: number;
}
