/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  Callout,
  classNamesFunction,
  DirectionalHint,
  FocusZone,
  FocusZoneDirection,
  ICalloutProps,
  IProcessedStyleSet,
  SearchBox,
  Shimmer,
  ShimmerElementType,
} from '@fluentui/react';
import * as React from 'react';
import { PubcenterTheme } from '../../../theme';
import { useWindowSize } from '../../hooks';
import { getStore } from '../../hooks/useWindowSize/store/store';
import { ISearchBarStyleProps, ISearchBarStyles, ISearchProps, ISearchState } from './Search.types';

const getClassNames = classNamesFunction<ISearchBarStyleProps, ISearchBarStyles>();
const defaultMinLettersToSearch = 3;
const defaultCalloutWidth = `clamp(${PubcenterTheme.spacing.size256}, 50vw, ${PubcenterTheme.spacing.size960})`;
const maxCalloutHeight = 614;

export class SearchBase<T> extends React.Component<ISearchProps<T>, ISearchState> {
  private _classNames: IProcessedStyleSet<ISearchBarStyles>;
  private _searchBarElement = React.createRef<HTMLDivElement>();

  constructor(props: ISearchProps<T>) {
    super(props);
    this.state = {
      calloutVisible: false,
    };
  }

  public handleClickOutside = (event) => {
    if (this._searchBarElement && !this._searchBarElement?.current?.contains(event.target)) {
      this.props.onClearInResponsiveView?.();
    }
  };

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ calloutVisible: false });
  }

  public componentDidUpdate(prevProps: ISearchProps<T>) {
    if (prevProps.searchInProgress === true && this.props.searchInProgress === false) {
      this.setState({ calloutVisible: true });
    }
  }

  public render(): JSX.Element {
    const {
      styles,
      theme,
      searchBoxProps,
      searchInProgress,
      minLettersToSearch,
      calloutProps,
      searchResultList,
      searchResultListAriaLabel,
    } = this.props;

    const { windowStoreSerializer } = useWindowSize();
    const windowSize = windowStoreSerializer(getStore());
    const { width: screenWidth, height: screenHeight, isWindowSmallSize, isWindowExtraSmallSize } = windowSize;
    this._classNames = getClassNames(styles, { theme: theme! });

    let searchCalloutProps: ICalloutProps = {
      calloutMaxHeight: isWindowSmallSize || isWindowExtraSmallSize ? screenHeight : maxCalloutHeight,
      style: {
        width: isWindowSmallSize || isWindowExtraSmallSize ? screenWidth : calloutProps?.calloutWidth ?? defaultCalloutWidth,
      },
    };
    if (calloutProps) {
      const { calloutWidth, calloutMaxHeight, ...otherCalloutProps } = calloutProps;
      searchCalloutProps = { ...otherCalloutProps };
    }

    return (
      <>
        <div role="search" className={this._classNames.searchBar} ref={this._searchBarElement}>
          <SearchBox
            styles={this._classNames.subComponentStyles.searchBox}
            placeholder={searchBoxProps.placeholder}
            disableAnimation={true}
            onSearch={(value) => {
              this.setState({ calloutVisible: true });
              this._onChange(value, minLettersToSearch ? minLettersToSearch : defaultMinLettersToSearch);
            }}
            onClear={this._onClear}
            onChange={(_, value: string) => this._onChange(value, minLettersToSearch ? minLettersToSearch : defaultMinLettersToSearch)}
            ariaLabel={searchBoxProps.searchBoxAriaLabel}
          />
        </div>
        {searchInProgress && (
          <Callout
            target={this._searchBarElement.current}
            className={this._classNames.calloutWrapper}
            {...searchCalloutProps}
            role={'dialog'}
            directionalHint={DirectionalHint.bottomCenter}
            directionalHintFixed={true}
            isBeakVisible={false}
            gapSpace={2}
          >
            {this._onRenderShimmer()}
          </Callout>
        )}
        {!searchInProgress && searchResultList && this.state.calloutVisible && (
          <Callout
            target={this._searchBarElement.current}
            className={this._classNames.calloutWrapper}
            {...searchCalloutProps}
            role={'dialog'}
            onDismiss={() => this.setState({ calloutVisible: false })}
            directionalHint={DirectionalHint.bottomCenter}
            directionalHintFixed={true}
            isBeakVisible={false}
            gapSpace={2}
            setInitialFocus={searchResultList ? true : false}
          >
            <FocusZone
              onClick={() => this.setState({ calloutVisible: false })}
              onKeyDown={(ev) => {
                // Earlier clicking tab was taking focus out of context and hence breaking the navigation order
                // Dismissing the callout makes sure that it will focus on the next active element in the tab order.

                if (ev.key === 'Tab' || (ev.shiftKey && ev.key === 'Tab') || ev.key === ' ' || ev.key === 'Enter') {
                  this.setState({ calloutVisible: false });
                }
              }}
              role="listbox"
              isCircularNavigation={true}
              direction={FocusZoneDirection.vertical}
              aria-label={searchResultListAriaLabel}
              aria-live="assertive"
            >
              {searchResultList.length > 0 &&
                searchResultList.map((category) => this.props.onRenderSearchResult && this.props.onRenderSearchResult(category))}
              {this.props.onRenderSearchResultFooter && this.props.onRenderSearchResultFooter()}
            </FocusZone>
          </Callout>
        )}
      </>
    );
  }

  private _onRenderShimmer = (): JSX.Element => {
    return (
      <div className={this._classNames.shimmerCallout}>
        <Shimmer
          className={this._classNames.shimmerElement}
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 28, width: 28 },
            { type: ShimmerElementType.gap, height: 28, width: 16 },
            { type: ShimmerElementType.line, height: 20, width: 390 },
            { type: ShimmerElementType.gap, height: 28, width: '100%' },
          ]}
        />
        <Shimmer
          className={this._classNames.shimmerElement}
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 28, width: 28 },
            { type: ShimmerElementType.gap, height: 28, width: 16 },
            { type: ShimmerElementType.line, height: 20, width: 390 },
            { type: ShimmerElementType.gap, height: 28, width: '100%' },
          ]}
        />
        <Shimmer
          className={this._classNames.shimmerElement}
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 28, width: 28 },
            { type: ShimmerElementType.gap, height: 28, width: 16 },
            { type: ShimmerElementType.line, height: 20, width: 390 },
            { type: ShimmerElementType.gap, height: 28, width: '100%' },
          ]}
        />
      </div>
    );
  };

  private _onChange = (queryText: string, minNumLettersForSearchTrigger: number): void => {
    if (this.props.searchBoxProps && this.props.searchBoxProps.onChange) {
      this.props.searchBoxProps.onChange(queryText, minNumLettersForSearchTrigger);
    }
  };

  private _onClear = (): void => {
    this.setState({ calloutVisible: false });
    this.props.onClearInResponsiveView?.();
    if (this.props.searchBoxProps && this.props.searchBoxProps.onClear) {
      this.props.searchBoxProps.onClear();
    }
  };
}
