import {
  Button,
  Card,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  FluentProvider,
  OverlayDrawer,
  Textarea,
  mergeClasses,
  webLightTheme,
} from '@fluentui/react-components';
import { Checkmark16Regular, Copy16Regular, Dismiss24Regular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { AdUnit, getAppStore } from '../../../../../@data';
import messages from '../messages';
import { useAppAdUnitCodePanelStyles } from '../styles';
import { AppAdUnitCodePanelType, UseAppAdUnitCodePanel } from '../types';

export const useAppAdUnitCodePanel: UseAppAdUnitCodePanel = (renderCodeBlockOnly = false) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [codeContent, setCodeContent] = useState('');
  const [adunitCode, setAdunitCode] = useState('');
  const [configCode, setConfigCode] = useState('');
  const { publisher } = getAppStore();
  const onPanelTrigger = (open: boolean, item?: AdUnit) => {
    setIsPanelOpen(open);
    const codeForAdUnit = `<!-- MainPage.xaml -->
    <Page
        ...
        xmlns:adSdk="using:Microsoft.AdSdk.Uwp">

        <!-- Placement Code is required! -->
        <adSdk:AdControl x:Name="NativeAdControl" PlacementCode="adunit_${item?.id}" Width="300" Height="250" CornerRadius="15" Background="Transparent"/>
    </Page>`;
    const codeForConfig = `/* MainPage.xaml.cs */
    using Microsoft.AdSdk.Uwp;

    public sealed partial class MainPage : Page
    {
        public MainPage()
        {
            NativeAdControl.SdkConfiguration = SdkConfigFactory.Create(
                callback: (SdkConfigurationOptions options) =>
                {
                    options.PublisherCode = "publisher_${publisher?.id}";
                    options.MemberId = "14163";
                });
        }
    }`;
    if (item) {
      setCodeContent(`${codeForAdUnit}\n${codeForConfig}`.replace(/\u202F/g, ' '));
      setAdunitCode(codeForAdUnit.replace(/\u202F/g, ' '));
      setConfigCode(codeForConfig.replace(/\u202F/g, ' '));
    }
  };

  return {
    isPanelOpen,
    codeContent,
    onPanelTrigger,
    renderCodeBlockOnly,
    adunitCode,
    configCode,
  };
};

const AppAdUnitCodePanel: AppAdUnitCodePanelType = ({
  intl: { formatMessage },
  isPanelOpen,
  onPanelTrigger,
  codeContent,
  renderCodeBlockOnly = false,
  adunitCode,
  configCode,
}) => {
  const classNames = useAppAdUnitCodePanelStyles();
  const [isContentCopied, setIsContentCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(codeContent);
    setIsContentCopied(true);
  };

  const codeBlock = (
    <Card
      className={mergeClasses(classNames.textAreaWrapper, renderCodeBlockOnly ? classNames.codeBlocOnlyTextWrapper : '')}
      appearance="outline"
      onMouseLeave={() => setIsContentCopied(false)}
    >
      <Button
        className={classNames.copyButton}
        icon={isContentCopied ? <Checkmark16Regular /> : <Copy16Regular />}
        onClick={() => onCopy()}
      />
      <Textarea resize="none" appearance="filled-darker" size="medium" className={classNames.textArea} value={adunitCode} />
      <Textarea
        resize="none"
        appearance="filled-darker"
        size="medium"
        className={mergeClasses(classNames.textArea, classNames.additionalCodeArea)}
        value={configCode}
      />
    </Card>
  );

  if (renderCodeBlockOnly) {
    return isPanelOpen ? codeBlock : <></>;
  }

  return (
    <FluentProvider theme={webLightTheme}>
      <OverlayDrawer position="end" size="medium" open={isPanelOpen} onOpenChange={(_, { open }) => onPanelTrigger(open)}>
        <DrawerHeader>
          <DrawerHeaderTitle
            action={<Button appearance="subtle" aria-label="Close" icon={<Dismiss24Regular />} onClick={() => onPanelTrigger(false)} />}
          >
            {formatMessage(messages.appAdunitCodePanelTitle)}
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>{codeBlock}</DrawerBody>
      </OverlayDrawer>
    </FluentProvider>
  );
};

export default injectIntl(AppAdUnitCodePanel);
