import React, { useEffect, useState } from 'react';
import { IColumnVisibilityMapping, IGridV2Column } from '../GridV2.types';
import { sortItemsBasedOnColumn } from '../utils';

export function useSortableGridColumns<ItemType>(
  columns: IGridV2Column[],
  items: ItemType[],
  onSortItems?: (items: ItemType[]) => void,
  onColumnsChanged?: (columns: IGridV2Column[]) => void,
  onCleanupSortItems?: () => void
) {
  const [gridColumns, setGridColumns] = useState<IGridV2Column[]>(columns);

  const changeColumnsVisibility = (colsVisMap: IColumnVisibilityMapping[]) => {
    const newColumns = [...gridColumns];
    colsVisMap.forEach((colVisMap) => {
      const gridColumn = newColumns.find((col) => col.key === colVisMap.columnKey);

      if (gridColumn) {
        gridColumn.isVisible = colVisMap.isVisible;
      }
    });
    setGridColumns(newColumns);
    onColumnsChanged?.(newColumns);
  };

  function handleColumnHeaderClick(ev?: React.MouseEvent<HTMLElement>, column?: IGridV2Column) {
    if (!column || column.suppressSorting) {
      return;
    }
    ev?.stopPropagation();

    const newColumns = gridColumns.slice();
    const currColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    newColumns.forEach((col: IGridV2Column) => {
      if (col === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        col.isSorted = false;
        col.isSortedDescending = undefined;
      }
    });

    setGridColumns(newColumns);
    const sortedItems = sortItemsBasedOnColumn(items, currColumn);
    onSortItems?.(sortedItems);
  }

  useEffect(() => {
    if (JSON.stringify(columns) === JSON.stringify(gridColumns)) {
      return;
    }
    setGridColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columns)]);

  useEffect(() => {
    return () => onCleanupSortItems?.();
  }, [onCleanupSortItems]);

  return { gridColumns, handleColumnHeaderClick, changeColumnsVisibility };
}
