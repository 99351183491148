import { action } from 'satcheljs';
import { IStringDictionary, User, UserInvitation } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { IUserViewModel } from '../store/schema';

export const onRawChanged = action('onRawChanged', (value?: User) => ({ value }));

export const onUserChanged = action('onUserChanged', (user: IUserViewModel) => ({ user }));

export const onFetchUsersLoading = action('onFetchUsersLoading', (isLoading: boolean) => ({ isLoading }));

export const onFetchingUsersData = action('onFetchingUsersData', (isFetchingData: boolean) => ({ isFetchingData }));

export const onUserInvitationsChanged = action('onUserInvitationsChanged', (userInvitations: UserInvitation[]) => ({ userInvitations }));

export const onFetchUserInvitationsLoading = action('onFetchUserInvitationsLoading', (isLoading: boolean) => ({ isLoading }));

export const onFetchingUserInvitationsData = action('onFetchingUserInvitationsData', (isFetchingData: boolean) => ({ isFetchingData }));

export const onUsersChanged = action('onUsersChanged', (users: User[]) => ({ users }));

export const onUserErrorChanged = action('onUserErrorChanged', (values?: IStringDictionary) => ({ values }));

export const onUserInvitationChanged = action('onUserInvitationChanged', (mode: FormState, value?: UserInvitation) => ({
  value,
  mode,
}));

export const onUserInvitationExpiryChanged = action('onUserInvitationExpiryChanged', (id: number, value: Date) => ({ id, value }));
