import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { getAppStore } from '../../../@data';
import { onPopupOpenChanged } from '../../../@data/mutatorActions';
import { PopupType } from '../../../@data/store/schema/enums/PopupType';
import { PopupCard } from '../../../components/Popup';
import { injectIntlAndObserver } from '../../../utils';
import { leavePagePopupMessages } from './LeavePagePopup.messages';

export interface ILeavePagePopupProps extends InjectedIntlProps {
  onSubmit?: () => void;
}

export const LeavePagePopup = injectIntlAndObserver(function LeavePagePopupComponent({
  onSubmit,
  intl: { formatMessage },
}: ILeavePagePopupProps): JSX.Element {
  const { openedPopup } = getAppStore();

  return (
    <PopupCard
      isShown={PopupType.Leaving === openedPopup}
      popUpTitle={formatMessage(leavePagePopupMessages.title)}
      popUpDescription={formatMessage(leavePagePopupMessages.description)}
      primaryButtonAriaLabel={formatMessage(leavePagePopupMessages.stayOnPage)}
      secondaryButtonAriaLabel={formatMessage(leavePagePopupMessages.leavePage)}
      onPopupDismiss={() => {
        onPopupOpenChanged(PopupType.None);
      }}
      onPrimaryButtonClick={async () => {
        onPopupOpenChanged(PopupType.None);
      }}
      onSecondaryButtonClick={() => {
        onSubmit?.();
        onPopupOpenChanged(PopupType.None);
      }}
    />
  );
});
