import {
  CommandBarButton,
  IButtonProps,
  ICommandBarItemProps,
  IComponentAs,
  IComponentAsProps,
  IContextualMenuProps,
  PrimaryButton,
  SearchBox,
} from '@fluentui/react';
import React from 'react';
import { CustomPanelType, LifeCycleStatus, PopupType, PublisherSearch, getAppStore } from '../../../../../@data';
import { hasCreateAccess, hasDeleteAccess, hasUpdateAccess } from '../../../../../@data/services/UserRoleService';
import { getAccessPermission } from '../../../../../@data/utils/getAccessPermission';
import { default as AppMessages, default as appMessages } from '../../../../../App.messages';
import { useWindowSize } from '../../../../../hooks';
import { getStore as getWindowStore } from '../../../../../hooks/useWindowSize/store/store';
import { onChangeItemsStatus, onDownloadButtonClicked, onDuplicateItem } from '../../../@data/actions/commandBarActions';
import '../../../@data/mutatorActions';
import { setDialogType, setFilterMenuCalloutStatus, setPanelType } from '../../../@data/mutatorActions';
import '../../../@data/orchestrators';
import { getStore } from '../../../@data/store/store';
import messages from '../../../GridV2.messages';
import { CommandBarActions, DownloadOption, GridSelectionMode, IDataGridCommandBarItemProps } from '../../../GridV2.types';
import { ICustomCommandBarProps } from '../../../components/CustomCommandBar/CustomCommandBar';
import { SearchBoxCallOut } from '../../SearchBoxCallOut/SearchBoxCallOut';
import { getButtonStyles, getCountMenuStyles } from '../CustomCommandBar.styles';

const { windowStoreSerializer } = useWindowSize();

export function getDefaultCommandBarItems(props: ICustomCommandBarProps): IDataGridCommandBarItemProps[] {
  if (!props.commandBarProps) {
    return [];
  }
  const { isGridReady, intl, history, match, columns, data, setData, entity, commandBarProps, handleSearch } = props;
  const { primaryButtonLabel, searchBoxPlaceholder, primaryButtonUrl, onDuplicateItems, onItemsStatusChanged } = commandBarProps;
  const accessPermission = getAccessPermission(entity);
  const { formatMessage } = intl;

  const commandBarItems: IDataGridCommandBarItemProps[] = [];
  const { isWindowSmallSize, isWindowExtraSmallSize } = windowStoreSerializer(getWindowStore());

  const downloadItems = (type: DownloadOption) => {
    const entityInstance = new entity();
    const publisherName = getAppStore().publisher?.name ?? '';

    const filename = `${publisherName}-${entityInstance.className}.csv`;
    onDownloadButtonClicked(formatMessage, data || [], filename, columns, type);
  };
  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: [
      {
        key: 'selected',
        text: formatMessage(appMessages.downloadSelected),
        onClick: () => downloadItems(DownloadOption.selection),
        ariaDescription: formatMessage(appMessages.downloadSelectedCmdBtnAriaDesc),
        disabled: getStore().selectedItems.length === 0,
      },
      {
        key: 'page',
        text: formatMessage(appMessages.downloadPage),
        onClick: () => downloadItems(DownloadOption.page),
        ariaDescribedBy: formatMessage(appMessages.downloadPageCmdBtnAriaDesc),
      },
      {
        key: 'all',
        text: formatMessage(appMessages.downloadAll),
        onClick: () => downloadItems(DownloadOption.all),
        ariaDescribedBy: formatMessage(appMessages.downloadAllCmdBtnAriaDesc),
      },
    ],
  };

  if (primaryButtonLabel) {
    commandBarItems.push({
      key: CommandBarActions.new,
      name: formatMessage(primaryButtonLabel),
      commandBarButtonAs: () => (
        <PrimaryButton
          iconProps={{ iconName: 'Add' }}
          text={isWindowExtraSmallSize ? '' : formatMessage(primaryButtonLabel)}
          onClick={() => {
            history.push(primaryButtonUrl || `${match.url}/create`);
          }}
          disabled={!(isGridReady && hasCreateAccess(accessPermission))}
          role="link"
        />
      ),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        history.push(`${match.url}/create`);
      },
      disabled: !(isGridReady && hasCreateAccess(accessPermission)),
      selectionModes: [GridSelectionMode.None],
      ariaDescription: formatMessage(messages.createCmdbtnAriaDesc, { label: formatMessage(primaryButtonLabel) }),
    });
  }

  if (handleSearch) {
    commandBarItems.push({
      key: CommandBarActions.searchBox,
      commandBarButtonAs: () => {
        return isWindowSmallSize || isWindowExtraSmallSize ? (
          <SearchBoxCallOut placeHolder={searchBoxPlaceholder ? formatMessage(searchBoxPlaceholder) : ''} handleSearch={handleSearch} />
        ) : (
          <SearchBox
            value={getStore().searchWord}
            placeholder={searchBoxPlaceholder ? formatMessage(searchBoxPlaceholder) : ''}
            onChange={(_, val: string) => handleSearch?.(val)}
            onClear={() => handleSearch('')}
            ariaLabel={formatMessage(messages.searchBoxPlaceholder)}
            disabled={!isGridReady}
          />
        );
      },
      renderedInOverflow: false,
      selectionModes: [GridSelectionMode.None],
    });
  }

  commandBarItems.push(
    ...[
      {
        key: 'count',
        commandBarButtonAs: () => {
          const { selectedItems } = getStore();
          return <div style={getCountMenuStyles()}>{formatMessage(AppMessages.selectedCount, { count: selectedItems.length })}</div>;
        },
        selectionModes: [GridSelectionMode.Single, GridSelectionMode.Multiple],
      },
      {
        id: 'addFilterButton',
        key: CommandBarActions.addFilter,
        name: formatMessage(appMessages.addFilter),
        iconProps: { iconName: 'Filter' },
        iconOnly: isWindowSmallSize || isWindowExtraSmallSize,
        onClick: () => setFilterMenuCalloutStatus(true),
        commandBarButtonAs: addFilterButton,
        ariaDescription: formatMessage(messages.addFilterCmdBtnAriaDesc),
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        disabled: !isGridReady,
        selectionModes: [GridSelectionMode.None],
      },
      {
        key: CommandBarActions.edit,
        name: formatMessage(appMessages.edit),
        iconProps: {
          iconName: 'Edit',
        },
        ariaDescription: formatMessage(messages.editCmdBtnAriaDesc),
        onClick: () => {
          const { selectedItems } = getStore();
          if (selectedItems.length > 0) {
            if (selectedItems[0].id) {
              history.push(`${match.url}/edit/${selectedItems[0].id}`);
            } else {
              history.push(`${match.url}/edit`);
            }
          }
        },
        /* In case of Publisher, edit is disabled if publisher is inactive */
        selectionStatus: new entity() instanceof PublisherSearch ? LifeCycleStatus.Active : undefined,
        disabled: !hasUpdateAccess(accessPermission),
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        selectionModes: [GridSelectionMode.Single],
      },
      {
        key: CommandBarActions.delete,
        name: formatMessage(appMessages.delete),
        iconProps: {
          iconName: 'Delete',
        },
        ariaDescription: formatMessage(messages.deleteCmdBtnAriaDesc),
        onClick: () => setDialogType(PopupType.DeleteConfirmation),
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        disabled: !hasDeleteAccess(accessPermission),
        selectionModes: [GridSelectionMode.Single],
      },
      {
        key: CommandBarActions.activate,
        name: formatMessage(appMessages.activate),
        iconProps: {
          iconName: 'ActivateOrders',
        },
        ariaDescription: formatMessage(messages.activateCmdBtnAriaDesc),
        selectionStatus: LifeCycleStatus.Inactive,
        onClick: () => {
          const { selectedItems, data: dataStore } = getStore();
          onChangeItemsStatus(selectedItems, LifeCycleStatus.Active, dataStore, formatMessage, setData, onItemsStatusChanged);
        },
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        disabled: !hasUpdateAccess(accessPermission),
        selectionModes: entity.name === 'Account' ? [GridSelectionMode.Single] : [GridSelectionMode.Single, GridSelectionMode.Multiple],
      },
      {
        key: CommandBarActions.deactivate,
        name: formatMessage(appMessages.deactivate),
        iconProps: {
          iconName: 'DeactivateOrders',
        },
        ariaDescription: formatMessage(messages.deactivateCmdBtnAriaDesc),
        onClick: () => setDialogType(PopupType.DeactivateConfirmation),
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        selectionStatus: LifeCycleStatus.Active,
        disabled: !hasUpdateAccess(accessPermission),
        selectionModes: entity.name === 'Account' ? [GridSelectionMode.Single] : [GridSelectionMode.Single, GridSelectionMode.Multiple],
      },
      {
        key: CommandBarActions.duplicate,
        name: formatMessage(appMessages.duplicate),
        iconProps: {
          iconName: 'Copy',
        },
        ariaDescription: formatMessage(messages.copyCmdBtnAriaDesc),
        onClick: () => {
          const { selectedItems } = getStore();
          if (selectedItems.length === 1) {
            onDuplicateItem(selectedItems[0], data, formatMessage, setData, onDuplicateItems);
          }
        },
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        disabled: !hasCreateAccess(accessPermission),
        selectionModes: [GridSelectionMode.Single],
      },
      {
        key: CommandBarActions.download,
        name: formatMessage(appMessages.download),
        iconProps: {
          iconName: 'Download',
        },
        ariaDescription: formatMessage(appMessages.downloadCmdBtnAriaDesc),
        subMenuProps: menuProps,
        disabled: !isGridReady,
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        selectionModes: [GridSelectionMode.None],
      },
      {
        key: CommandBarActions.modifyColumns,
        name: formatMessage(appMessages.modifyColumns),
        iconProps: {
          iconName: 'TripleColumnEdit',
        },
        ariaDescription: formatMessage(messages.modifyColumnsCmdBtnAriaDesc),
        onClick: () => setPanelType(CustomPanelType.ModifyColumns),
        buttonStyles: getStore().selectedItems.length > 0 ? getButtonStyles() : undefined,
        selectionModes: [GridSelectionMode.None],
        disabled: !isGridReady,
      },
    ]
  );

  return commandBarItems;
}

export const addFilterButton: IComponentAs<ICommandBarItemProps> = (commandBarItemProps: IComponentAsProps<ICommandBarItemProps>) => {
  const { ...buttonProps } = commandBarItemProps;
  const castButtonProps = buttonProps as IButtonProps;

  return (
    <CommandBarButton
      {...castButtonProps}
      aria-haspopup={true}
      aria-expanded={document.body.contains(document.getElementById('filterMenuDialog'))}
    />
  );
};
