import { ClarityType, isTythonSelfServeUser, onNavigateBack } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/services/AdManagementService';
import { onFormCleared } from '../__data__/mutatorActions';
import { IPropertyViewModel } from '../__data__/store/schema';
import messages from '../variations/CreateNonTythonProperty/messages';

const useDetailsPageButtonsStatus = (history, mode, onSubmit, onCancel, formatMessage) => {
  const isTythonUser = isTythonSelfServeUser();

  const titleLabel =
    mode === FormState.Edit
      ? formatMessage(isTythonUser ? messages.editSitePageTitle : messages.editPageTitle)
      : formatMessage(isTythonUser ? messages.createSitePageTitle : messages.createPageTitle);

  const submitLabel =
    mode === FormState.Edit
      ? formatMessage(isTythonUser ? messages.editSitePageTitle : messages.editPageTitle)
      : formatMessage(isTythonUser ? messages.createSitePageTitle : messages.createPageTitle);

  const isSubmitButtonDisabled = (active): boolean => {
    if (!active.name || !active.url || active.approvalStatus === undefined) {
      return true;
    }

    /* For tython users selecting clarity type is mandatory*/
    if (isTythonUser) {
      return !(
        (active.tythonClarity?.connectingClarityApprovalTython && active.tythonClarity?.clarityType === ClarityType.Behavioral) ||
        active.tythonClarity?.clarityType === ClarityType.Fraud
      );
    }

    return false;
  };

  const onHandleSubmit = (active: IPropertyViewModel): void => {
    if (onSubmit) {
      onSubmit({
        url: active?.url as string,
        name: active?.name as string,
        clarityType: active?.clarity?.clarityType,
        clarityId: active?.clarity?.clarityId,
      });
      return;
    }
  };

  const onHandleCancel = (publisherId?: string, accountId?: string): void => {
    onFormCleared();

    if (onCancel) {
      onCancel();
      return;
    }

    if (mode !== FormState.InPageCreate) {
      // return to manage page
      const returnUrl = createReturnUrl(isTythonUser ? RouteName.inventory : RouteName.property, publisherId, accountId);
      onNavigateBack(history, returnUrl);
    }
  };

  return {
    titleLabel,
    submitLabel,
    isSubmitButtonDisabled,
    onHandleSubmit,
    onHandleCancel,
  };
};

export default useDetailsPageButtonsStatus;
