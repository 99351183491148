import { classNamesFunction, ICalloutProps, Toggle } from '@fluentui/react';
import * as React from 'react';
import AppMessages from '../../App.messages';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import { InfoButton } from '../InfoButton';
import { IInfoToggleProps, IInfoToggleStyleProps, IInfoToggleStyles } from './InfoToggle.types';

const getClassNames = classNamesFunction<IInfoToggleStyleProps, IInfoToggleStyles>();

export class InfoToggleBase extends React.Component<IInfoToggleProps> {
  public render(): JSX.Element {
    const { styles, theme, label, checked, onChange, calloutContent, ariaDescription, calloutOnDismiss, iconButtonOnClick } = this.props;
    const { formatMessage } = this.props.intl;

    const classNames = getClassNames(styles, { theme: theme! });
    const calloutProps: ICalloutProps = {
      calloutWidth: 300,
    };
    return (
      <div className={classNames.root}>
        <Toggle
          onText={label}
          offText={label}
          defaultChecked={checked}
          onChange={onChange}
          styles={classNames.subComponentStyles.toggle}
          aria-describedby={'toggleDescription'}
        />
        <InfoButton
          ariaLabel={formatMessage(AppMessages.infoLabel, { label: label })}
          ariaDescription={formatMessage(AppMessages.infoDescription, { label: label })}
          iconTitle="Information"
          calloutContent={calloutContent}
          calloutProps={calloutProps}
          iconButtonOnClick={iconButtonOnClick}
          calloutOnDismiss={calloutOnDismiss}
        />
        <span id="toggleDescription" style={getScreenReaderTextStyles()}>
          {ariaDescription}
        </span>
      </div>
    );
  }
}
