import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { Model, User, getAppStore, patchEntity } from '../../../../../@data';
import { TYTHON_DOMAIN_REDIRECTION_CACHE_KEY } from '../../../../../constants';
import { dismissFRE } from '../actions';
import { setIsFRECardShown } from '../mutatorActions';

export class DismissFRERequest extends Model {
  public apiPath = `dismissFRE`;
  public className = 'dismissFRE';
}

orchestrator(dismissFRE, async () => {
  setIsFRECardShown(false);
  sessionStorage.removeItem(TYTHON_DOMAIN_REDIRECTION_CACHE_KEY);

  const { publisher, userContext } = getAppStore();
  const user = new User(userContext?.id);

  if (publisher && user) {
    try {
      await patchEntity([publisher, user], DismissFRERequest, undefined, userContext);
    } catch (e) {
      Log.error(`Failed to dismiss FRE card, ${JSON.stringify(e)}`);
    }
  }
});
