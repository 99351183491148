export enum ServiceLevel {
  SelfServe = 'SelfServe',
  SelfServeTrusted = 'SelfServeTrusted',
  Premium = 'Premium',
  Internal = 'Internal',
  Select = 'Select'
}

export const serviceLevelNameIdMap = {
  SelfServe: 1,
  SelfServeTrusted: 2,
  Premium: 3,
  Internal: 4,
  Select: 5
};
