import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Route, RouteComponentProps } from 'react-router-dom';
import { RoutePrefix } from '../../../Routes';
import { FormState } from '../../../components/Form';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import './@data/mutators';
import './@data/orchestrators';
import { CreateAccount } from './Create/CreateAccount';
import { ManagePage } from './Manage/ManagePage';

export const AccountPage = injectIntl(function AccountPageComponent({ match, intl }: RouteComponentProps & InjectedIntlProps): JSX.Element {
  React.useEffect(() => {
    onCurrentRouteChanged(RouteName.account, intl.formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Route exact path={`${match.url}`} component={ManagePage} />
      <Route
        path={`/${RoutePrefix.partnerManagement}/:publisherId/${RouteName[RouteName.account]}/create`}
        render={(props) => <CreateAccount {...props} mode={FormState.Create} />}
      />
      <Route
        path={`/${RoutePrefix.partnerManagement}/:publisherId/${RouteName[RouteName.account]}/edit/:accountId`}
        render={(props) => <CreateAccount {...props} mode={FormState.Edit} />}
      />
    </React.Fragment>
  );
});
