import { crossEntityResourceTypeMap, ResourceType } from '../../../../@data/store/schema/enums/ResourceType';
import { IGlobalSearchResult } from '../../../../@data/store/schema/interfaces/IGlobalSearchResult';
import { IGlobalSearchResultData } from '../../../../@data/store/schema/interfaces/IGlobalSearchResultData';
import { RoutePrefix } from '../../../../Routes';
import { ISearchResultInfo } from '../store/schema/IGlobalSearchResultPageState';
import { Account, AdFilter, AdUnit, Channel, Publisher, User, WebProperty } from '../store/schema/models/Index';

const resourceTypePriorityMap = new Map<string, string[]>([
  [
    RoutePrefix.adManagement,
    [
      ResourceType[ResourceType.Filter],
      ResourceType[ResourceType.Channel],
      ResourceType[ResourceType.AdUnit],
      ResourceType[ResourceType.WebProperty],
      ResourceType[ResourceType.Reporting],
      ResourceType[ResourceType.Account],
      ResourceType[ResourceType.Publisher],
      ResourceType[ResourceType.User]
    ]
  ],
  [
    RoutePrefix.partnerManagement,
    [
      ResourceType[ResourceType.Account],
      ResourceType[ResourceType.Publisher],
      ResourceType[ResourceType.User],
      ResourceType[ResourceType.Reporting],
      ResourceType[ResourceType.Filter],
      ResourceType[ResourceType.Channel],
      ResourceType[ResourceType.AdUnit],
      ResourceType[ResourceType.WebProperty]
    ]
  ],
  [
    RoutePrefix.report,
    [
      ResourceType[ResourceType.Reporting],
      ResourceType[ResourceType.Filter],
      ResourceType[ResourceType.Channel],
      ResourceType[ResourceType.AdUnit],
      ResourceType[ResourceType.WebProperty],
      ResourceType[ResourceType.Account],
      ResourceType[ResourceType.Publisher],
      ResourceType[ResourceType.User]
    ]
  ]
]);

export function buildSearchResults(data: IGlobalSearchResultData): ISearchResultInfo {
  const searchResultInfo: ISearchResultInfo = { pageToken: '', resultData: [] };
  const publisherCrossEntities = crossEntityResourceTypeMap.get(ResourceType[ResourceType.Publisher]);
  if (data.results.length > 0) {
    // eslint-disable-next-line
    data.results.map((item: IGlobalSearchResult) => {
      if (item.resourceType === ResourceType[ResourceType.Account]) {
        const searchResult = new Account(item);
        searchResultInfo.resultData.push(searchResult);
      } else if (item.resourceType === ResourceType[ResourceType.AdUnit]) {
        const searchResult = new AdUnit(item);
        searchResultInfo.resultData.push(searchResult);
      } else if (item.resourceType === ResourceType[ResourceType.Filter]) {
        const searchResult = new AdFilter(item);
        searchResultInfo.resultData.push(searchResult);
      } else if (item.resourceType === ResourceType[ResourceType.Channel]) {
        const searchResult = new Channel(item);
        searchResultInfo.resultData.push(searchResult);
      } else if (item.resourceType === ResourceType[ResourceType.WebProperty]) {
        const searchResult = new WebProperty(item);
        searchResultInfo.resultData.push(searchResult);
      } else if (item.resourceType === ResourceType[ResourceType.Publisher]) {
        const searchResult = new Publisher(item);
        searchResultInfo.resultData.push(searchResult);
      } else if (item.resourceType === ResourceType[ResourceType.User]) {
        const searchResult = new User(item);
        searchResultInfo.resultData.push(searchResult);
      } else if (publisherCrossEntities && publisherCrossEntities.find((value: string) => value === item.resourceType)) {
        const searchResult = new Publisher(item);
        searchResultInfo.resultData.push(searchResult);
      }
    });
  }
  searchResultInfo.pageToken = data.resultMetadata ? data.resultMetadata.NextPageToken : '';
  return searchResultInfo;
}

export function getOrderedResources(resourceTypes: Map<string, number>, routePrefix: string): Map<string, number> {
  const orderedResourceTypesMap = new Map<string, number>();
  const orderedResourceTypes = resourceTypePriorityMap.get(routePrefix);

  if (orderedResourceTypes) {
    orderedResourceTypes.forEach((resource: string) => {
      const value = resourceTypes.get(resource);
      if (value) {
        orderedResourceTypesMap.set(resource, value);
        resourceTypes.delete(resource);
      }
    });

    resourceTypes.forEach((value: number, resource: string) => {
      orderedResourceTypesMap.set(resource, value);
    });

    return orderedResourceTypesMap;
  }

  return resourceTypes;
}
