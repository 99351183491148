import {
  Announced,
  classNamesFunction,
  ContextualMenu,
  css,
  DefaultButton,
  Dropdown,
  getTheme,
  Icon,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  IDropdownOption,
  IProcessedStyleSet,
  OverflowSet,
  Panel,
  PanelType,
  Persona,
  PersonaPresence,
  PersonaSize,
  ResizeGroup,
} from '@fluentui/react';
import * as React from 'react';
import { getAppStore, LayoutMode } from '../../@data';
import { isNotAuthorizedUser, isTythonSelfServeUser } from '../../@data/utils/checkUserType';
import AppMessages from '../../App.messages';
import { reverseLocales, userLocales } from '../../config/locale';
import {
  APP_NAME_NEW,
  APP_NAME_PUBCENTER,
  CUSTOM_NOTIFICATIONS_WIDTH,
  CUSTOM_WIDTH,
  EN_LOCALE_KEY,
  UNAUTHORIZED_LOCAL_STORAGE_LOCALE,
} from '../../constants/AppConstants';
import { SUPPORTED_LOCALES_MAPPING } from '../../globalization';
import { useWindowSize } from '../../hooks';
import { getStore } from '../../hooks/useWindowSize/store/store';
import { onLanguageChanged } from '../../pages/ad-management/Account/Settings/@data/mutatorActions';
import { AcceptInvitePageRoute, DefaultRoute, LandingPageRoute, SignUpPageRoute } from '../../Routes';
import { isLocationPubCenter } from '../../utils';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { Logo } from '../Logo/Logo';
import { Search } from '../Search/Search';
import messages from './Header.messages';
import { getOverflowIconStyle, getStyles } from './Header.styles';
import { IHeaderDropdownItem, IHeaderItem, IHeaderProps, IHeaderState, IHeaderStyleProps, IHeaderStyles, PanelTypes } from './Header.types';

const getClassNames = classNamesFunction<IHeaderStyleProps, IHeaderStyles>();
const defaultLang = localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE)
  ? SUPPORTED_LOCALES_MAPPING[localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE)!][
      reverseLocales[localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE)!]
    ]
  : 'English - United States';

interface IHeaderData {
  items?: IHeaderItem[];
  isSearchBarActiveInNonRegularView?: boolean;
}

export class Header<T> extends React.Component<IHeaderProps<T>, IHeaderState> {
  private _classNames: IProcessedStyleSet<IHeaderStyles>;
  constructor(props: IHeaderProps<T>) {
    super(props);

    const initialRouteMatch = window.location.pathname.match(/(\/[\w-]+)\/?/);
    const initialRoute = initialRouteMatch ? initialRouteMatch[1] : DefaultRoute;
    this.state = {
      title: this.props.loadingText || '...',
      currentRoute: initialRoute,
      announcedMessage: undefined,
      panelType: undefined,
      isSearchBarActiveInNonRegularView: false,
      selectedLanguage: defaultLang,
    };
  }

  public componentDidMount(): void {
    getAppStore().userContext?.id === undefined &&
      onLanguageChanged(localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE) ?? EN_LOCALE_KEY, UNAUTHORIZED_LOCAL_STORAGE_LOCALE);
  }

  public componentDidUpdate(prevProps: IHeaderProps<T>) {
    const routeMatch = window.location.pathname.match(/(\/[\w-]+)\/?/);
    const route = routeMatch ? routeMatch[1] : DefaultRoute;

    if (route !== this.state.currentRoute) {
      this.setState({
        currentRoute: route,
      });
    }

    // make sure this state get's reset so screen reader will announce every time when redirection is triggered
    if (this.state.announcedMessage) {
      setTimeout(
        () =>
          this.setState({
            announcedMessage: undefined,
          }),
        500
      );
    }
  }

  public render(): JSX.Element {
    const {
      items,
      useCMUIHelpExperience: { CMUIHeaderMenuItemSerializer },
    } = this.props;
    this._classNames = getClassNames(getStyles, {
      theme: getTheme(),
      isTythonExperience: isTythonSelfServeUser() || !isLocationPubCenter(),
    });

    const headerData: IHeaderData = {
      items: CMUIHeaderMenuItemSerializer(isTythonSelfServeUser(), items),
    };

    return <ResizeGroup data={headerData} onRenderData={this._onRenderData} onReduceData={this._onReduceData} />;
  }

  private _onReduceData = (data: IHeaderData): IHeaderProps<T> | undefined => {
    return undefined;
  };

  private _onRenderData = (data?: IHeaderData): JSX.Element => {
    const { publisher, userContext } = getAppStore();
    const signingUp: boolean = publisher === undefined && userContext?.id === undefined;
    const shouldRenderCustomLeftIcon = !!this.props.renderLeftIcon;
    const { formatMessage } = this.props.intl;
    const { windowStoreSerializer } = useWindowSize();
    const { isWindowSmallSize, isWindowRegularSize, isWindowExtraSmallSize } = windowStoreSerializer(getStore());
    const { isSearchBarActiveInNonRegularView } = this.state;
    const shouldHideDropdownInResponsiveView = !isWindowRegularSize && isSearchBarActiveInNonRegularView;
    const shouldRenderSearchBarInResponsiveView = (isSearchBarActiveInNonRegularView && !isWindowRegularSize) || isWindowRegularSize;
    const SearchBarIconButton: IHeaderItem[] =
      // responsive logic to hide search icon
      isWindowRegularSize || isSearchBarActiveInNonRegularView
        ? []
        : [
            // render logic to disable search icon for tython user
            ...(isTythonSelfServeUser()
              ? []
              : [
                  {
                    title: '',
                    icon: 'Search',
                    onClick: () => this.setState({ isSearchBarActiveInNonRegularView: true }),
                    key: 'HeaderSearchIcon',
                    shouldOverflowUnderExtraSmallScreen: true,
                  },
                ]),
          ];
    const headerItems = [...SearchBarIconButton, ...(data?.items ?? [])];
    const [headerIconItems, headerIconOverflowItems] = [
      isWindowExtraSmallSize ? headerItems.filter((item) => !item.shouldOverflowUnderExtraSmallScreen) : headerItems,
      isWindowExtraSmallSize
        ? headerItems
            .filter((item) => item.shouldOverflowUnderExtraSmallScreen)
            // as a11y enhancement, make sure first item gets auto focused
            .map((item, index) => (index === 0 ? { ...item, autoFocus: 'true' } : item))
        : [],
    ];
    const isContentOnlyLayout = getAppStore().layout === LayoutMode.ContentOnly;
    const selectedLang = localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE)
      ? SUPPORTED_LOCALES_MAPPING[localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE)!][
          reverseLocales[localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE)!]
        ]
      : 'English - United States';
    const selectedHeaderItem = headerItems.find((item) => item.key === this.state.selectedItem?.key);

    localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE) ?? localStorage.setItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE, EN_LOCALE_KEY);

    const menuProps: IContextualMenuProps = {
      onItemClick: (ev, item) => {
        if (item && item.text && item.key !== localStorage.getItem(UNAUTHORIZED_LOCAL_STORAGE_LOCALE)) {
          onLanguageChanged(item.key, UNAUTHORIZED_LOCAL_STORAGE_LOCALE);
        }
      },
      items: this._getLanguageContextualMenuItems(getAppStore().locale),
      directionalHintFixed: true,
    };

    return (
      <header className={this._classNames.root}>
        {this.state.announcedMessage ? <Announced message={this.state.announcedMessage} /> : undefined}

        <div
          className={css(this._classNames.logo, shouldRenderCustomLeftIcon ? '' : this._classNames.navItem)}
          onClick={() => {
            if (!shouldRenderCustomLeftIcon) {
              this._navigateTo(DefaultRoute);
              this.setState({
                announcedMessage: formatMessage(messages.defaultRouteDirection, {
                  appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER,
                }),
              });
            }
          }}
          onKeyDown={(ev) => {
            if (!shouldRenderCustomLeftIcon && isButtonPressKey(ev.key)) {
              this._navigateTo(DefaultRoute);
              this.setState({
                announcedMessage: formatMessage(messages.defaultRouteDirection, {
                  appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER,
                }),
              });
            }
          }}
          role="link"
          tabIndex={0}
          aria-label={formatMessage(messages.homeButtonAriaLabel, {
            appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER,
          })}
        >
          {this.props.renderLeftIcon ?? (
            <Logo
              shouldRenderLogo={!(isWindowSmallSize || isWindowExtraSmallSize) || isContentOnlyLayout}
              renderGreyLogo={!isLocationPubCenter() || isTythonSelfServeUser()}
            />
          )}
        </div>

        <nav className={css(this._classNames.navigationLm)}>
          {/* Temporarily hiding module selection dropDown and global search for tython MVP users (including signup page) */}
          {this._shouldShowDropdownSearch() && (
            <>
              {!shouldHideDropdownInResponsiveView && (
                <div className={this._classNames.leftSection}>
                  <Dropdown
                    className={this._classNames.navItem}
                    styles={this._classNames.subComponentStyles.dropdown}
                    onRenderOption={this._onRenderOption}
                    options={this._initializeRoutesData()}
                    selectedKey={this.state.currentRoute}
                    onChange={this._onDropdownChange}
                    ariaLabel={formatMessage(messages.dropdownAriaLabel)}
                  />
                </div>
              )}

              {this.props.searchProps && shouldRenderSearchBarInResponsiveView && (
                <Search
                  {...this.props.searchProps}
                  onClearInResponsiveView={
                    isSearchBarActiveInNonRegularView ? () => this.setState({ isSearchBarActiveInNonRegularView: false }) : undefined
                  }
                />
              )}
            </>
          )}

          {signingUp && (
            <div className={this._classNames.defaultButtonWrapperStyle}>
              <DefaultButton
                text={selectedLang}
                iconProps={{ iconName: 'LocaleLanguage' }}
                menuProps={menuProps}
                menuAs={this._getMenu}
                persistMenu={true}
                className={this._classNames.defaultButtonStyle}
              />
            </div>
          )}

          <OverflowSet
            className={this._classNames.rightSection}
            items={headerIconItems}
            overflowItems={headerIconOverflowItems}
            onRenderItem={this._onRenderItem}
            onRenderOverflowButton={this._onRenderOverflowButton}
            overflowSide="start"
          />

          {this._createPanel(selectedHeaderItem)}
        </nav>
      </header>
    );
  };

  private _getMenu = (props: IContextualMenuProps): JSX.Element => {
    return <ContextualMenu {...props} />;
  };

  private _getLanguageContextualMenuItems = (locale: string): IContextualMenuItem[] => {
    const isProdEnv = process.env.REACT_APP_ENV === 'production';
    const supportedLocales = SUPPORTED_LOCALES_MAPPING[locale ?? EN_LOCALE_KEY];
    const supportedLanguages: IContextualMenuItem[] = [];

    Object.keys(supportedLocales).forEach((localeCode) => {
      supportedLanguages.push({
        key: userLocales[localeCode].toLocaleLowerCase(),
        text: supportedLocales[localeCode],
      });
    });

    supportedLanguages.sort((a, b) => {
      const firstValue = a.text;
      const secondValue = b.text;

      return firstValue!.toLowerCase() > secondValue!.toLowerCase() ? 1 : -1;
    });

    const filteredLanguages = supportedLanguages.filter((value, index) => value.key === EN_LOCALE_KEY);

    return isProdEnv ? filteredLanguages : supportedLanguages;
  };

  private _createPanel(item?: IHeaderItem): JSX.Element {
    // For icons such as CMUI help icon, panels are integrated with 3rd party libraries no need to render native panel
    if (item && !item.panel) {
      return <></>;
    }

    return (
      <Panel
        headerText={item?.title}
        type={item ? (item.type && item.type === PanelTypes.Profile ? PanelType.smallFixedFar : PanelType.custom) : undefined}
        customWidth={item?.type === PanelTypes.Notifications ? CUSTOM_NOTIFICATIONS_WIDTH : CUSTOM_WIDTH}
        isOpen={!!item}
        isLightDismiss={true}
        onDismiss={this._hidePanel}
        closeButtonAriaLabel={this.props.intl.formatMessage(AppMessages.close)}
        isFooterAtBottom={item?.panelProps?.isFooterAtBottom}
        onRenderFooterContent={() => item?.panelProps?.onRenderFooterContent?.({ onDismiss: this._hidePanel }) || null}
      >
        {item?.panel ? React.cloneElement(<item.panel />, { hidePanel: this._hidePanel }) : <p>Loading...</p>}
      </Panel>
    );
  }

  private _initializeRoutesData(): IDropdownOption[] {
    if (!this.props.dropdownItems) {
      return [];
    }

    return this.props.dropdownItems.map((item: IHeaderDropdownItem) => {
      return {
        key: item.key,
        text: item.text,
        title: '',
        ariaLabel: item.text,
        data: {
          icon: item.icon,
        },
      };
    });
  }

  private _onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon className={this._classNames.dropdownOption} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
        )}
        <span>{option.text}</span>
      </div>
    );
  };

  private _onDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      const currentRoute = option.key.toString();
      this._navigateTo(currentRoute);
    }
  };

  private _navigateTo = (route: string): void => {
    this.setState({
      currentRoute: route,
    });

    if (this.props.onNavigate) {
      this.props.onNavigate(route);
    }
  };

  private _onRenderItem = (item: IHeaderItem): JSX.Element | React.ReactNode => {
    return item.identity ? this._renderIdentity(item) : this._renderIcon(item);
  };

  private _onRenderOverflowButton = (overflowItems: IHeaderItem[]): JSX.Element => {
    const overflowItemSerializer = (items: IHeaderItem[]) =>
      items.map((item) => ({
        ...item,
        name: item.title,
        ...(item.icon ? { iconProps: { iconName: item.icon, className: this._classNames.overflowIcon } } : {}),
        ...(item.panel ? { onClick: () => this._showPanel(item) } : {}),
      }));
    return (
      <IconButton
        title="More options"
        styles={getOverflowIconStyle(getTheme())}
        menuIconProps={{ iconName: 'More', className: this._classNames.moreInfoIcon }}
        menuProps={{ items: overflowItemSerializer(overflowItems!), className: this._classNames.overflowMenuWrapper }}
      />
    );
  };

  private _renderIdentity = (item: IHeaderItem): JSX.Element => {
    const identity = item.identity!;

    return (
      <Persona
        className={this._classNames.navItem}
        title={item.title}
        styles={this._classNames.subComponentStyles.itemIdentity}
        text={identity.name}
        secondaryText={identity.email}
        size={PersonaSize.size24}
        presence={PersonaPresence.online}
        showSecondaryText={false}
        hidePersonaDetails={true}
        role="button"
        aria-haspopup={true}
        aria-describedby={this.props.intl.formatMessage(messages.myAccountDescribedByLabel)}
        imageAlt={this.props.intl.formatMessage(messages.myAccountStatusLabel)}
        onClick={() => this._showPanel(item)}
        aria-expanded={this.state.selectedItem !== undefined}
        onKeyDown={(ev) => isButtonPressKey(ev.key) && this._showPanel(item)}
        tabIndex={0}
      />
    );
  };

  private _renderIcon = (item: IHeaderItem): JSX.Element => {
    /**
     * when icon has a `query` prop that means icon is for bingads helpcenter use, in this case we
     * will need to wrap it in an anchor tag with query attribute
     *  */
    if (item.query) {
      return (
        <a href="/#" query={item.query} className={css(item.className, this._classNames.helpCenterIcon)}>
          <Icon
            className={css(this._classNames.navItem, this._classNames.itemIcon, item.className)}
            title={item.title}
            iconName={item.icon}
            onKeyDown={(ev) => (item.panel ? isButtonPressKey(ev.key) && this._showPanel(item) : null)}
            // not to show panel for icons don't have panels
            onClick={() => (item.panel ? this._showPanel(item) : item.onClick?.() ?? null)}
          />
        </a>
      );
    } else if (item.showRedDot) {
      return (
        <div>
          <span className={this._classNames.dot} />
          <Icon
            className={css(this._classNames.navItem, this._classNames.itemIcon, item.className)}
            title={item.title}
            iconName={item.icon}
            onKeyDown={(ev) => (item.panel ? isButtonPressKey(ev.key) && this._showPanel(item) : null)}
            tabIndex={0}
            // not to show panel for icons don't have panels
            onClick={() => (item.panel ? this._showPanel(item) : item.onClick?.() ?? null)}
          />
        </div>
      );
    }
    return (
      <Icon
        className={css(this._classNames.navItem, this._classNames.itemIcon, item.className)}
        title={item.title}
        iconName={item.icon}
        onKeyDown={(ev) => (item.panel ? isButtonPressKey(ev.key) && this._showPanel(item) : null)}
        tabIndex={0}
        // not to show panel for icons don't have panels
        onClick={() => (item.panel ? this._showPanel(item) : item.onClick?.() ?? null)}
      />
    );
  };

  private _showPanel(item: IHeaderItem) {
    this.setState({
      title: item.title,
      selectedItem: item,
      panelType: item.type && item.type === PanelTypes.Profile ? PanelType.smallFixedFar : PanelType.custom,
    });
  }

  private _hidePanel = (): void => {
    this.setState({ selectedItem: undefined });
  };

  private _shouldShowDropdownSearch = () =>
    !isTythonSelfServeUser() &&
    !window.location.pathname.includes(AcceptInvitePageRoute) &&
    ![SignUpPageRoute, LandingPageRoute, '/'].includes(window.location.pathname) &&
    !isNotAuthorizedUser();
}
