import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../../../theme';

export interface IAdUnitCalloutClassNames {
  calloutStyles: string;
  textFieldContainer: string;
  spinnerStyles: string;
  buttonsContainer: string;
}

export const getClassNames = (): IAdUnitCalloutClassNames => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    calloutStyles: {
      padding: spacing.size16
    },
    textFieldContainer: {
      position: 'relative'
    },
    spinnerStyles: {
        position: 'absolute',
        top: spacing.size36,
        right: spacing.size8,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: spacing.size10
    },
  });
};
