import { getAppStore, getCurrentPublisher, Publisher, putEntity } from '../../../../../@data';

export async function updatePublisherDetails(body: Publisher): Promise<Publisher> {
  const publisher = getCurrentPublisher();
  if (!publisher) {
    return Promise.reject('No publisher found');
  }

  const { userContext } = getAppStore();
  const publisherDetails = await putEntity([publisher], body, userContext);
  return publisherDetails;
}
