import { Model } from '../../../../../@data';
import AppMessages from '../../../../../App.messages';
import { getStore } from '../../../@data/store/store';
import {
  Direction,
  GridSelectionMode,
  IDataGridCommandBarItemProps,
  IDataGridCommandBarProps,
  IGridPageCommandBarPlacement,
} from '../../../GridV2.types';
import { ICustomCommandBarProps } from '../../../components/CustomCommandBar/CustomCommandBar';
import { getButtonStyles } from '../CustomCommandBar.styles';
import { getDefaultCommandBarFarItems } from './getDefaultCommandBarFarItems';
import { getDefaultCommandBarItems } from './getDefaultCommandBarItems';
import { getSelectionMode } from './getSelectionMode';

export function buildCommandBarProps(props: ICustomCommandBarProps): IDataGridCommandBarProps {
  const { includeActions, includeFarActions, excludeActions, getCustomCommandBarItems } = props.commandBarProps || {};

  const customCommandBarItems = getCustomCommandBarItems?.(() => (getStore().selectedItems as Model[]) || []);
  const defaultCommandBarItems = getDefaultCommandBarItems(props);
  const defaultFarCommandBarItems = getDefaultCommandBarFarItems(props);

  let items: IDataGridCommandBarItemProps[] = [];
  let farItems: IDataGridCommandBarItemProps[] = [];
  const overflowButtonProps = { ariaLabel: props.intl.formatMessage(AppMessages.moreOptionsButtonAriaLabel) };

  // This will only include the given list of actions from defaultCommandBarItems
  includeActions?.forEach((includeKey) => {
    items.push(...defaultCommandBarItems.filter((item) => item.key === includeKey));
  });

  // This will only include the given list of actions from defaultCommandBarItems
  includeFarActions?.forEach((includeKey) => {
    items.push(...defaultCommandBarItems.filter((item) => item.key === includeKey));
    farItems.push(...defaultFarCommandBarItems.filter((item) => item.key === includeKey));
  });

  // This will exclude the given actions from defaultCommandBarItems
  excludeActions?.forEach((excludeKey) => {
    items = items.filter((item) => item.key !== excludeKey);
    farItems = farItems.filter((item) => item.key !== excludeKey);
  });

  customCommandBarItems?.forEach((customItem) => {
    const placement = customItem.placement;
    if (!customItem.buttonStyles) {
      customItem.buttonStyles = getStore().selectedItems.length > 0 ? getButtonStyles() : undefined;
    }

    // If placement details are given, then insert at specified placement
    if (placement) {
      items = insertCommandBarItem(items, customItem, placement);
    } else {
      // no placement details provided insert at the end of left sided command bar items
      items.push(customItem);
    }
  });

  const countItem = defaultCommandBarItems.find((item) => item.key === 'count');
  if (countItem) {
    items.unshift(countItem);
  }
  const cancelItem = defaultFarCommandBarItems.find((item) => item.key === 'Cancel');
  if (cancelItem) {
    farItems.push(cancelItem);
  }

  const commandBarProps: IDataGridCommandBarProps = { items: items, farItems: farItems, overflowButtonProps: overflowButtonProps };

  const mode = getSelectionMode(getStore().selectedItems.length);
  commandBarProps.items = getItemsBasedOnModeAndStatus(commandBarProps.items, mode, getStore().selectedItems);
  commandBarProps.farItems = getItemsBasedOnModeAndStatus(commandBarProps.farItems || [], mode, getStore().selectedItems) || [];

  return commandBarProps;
}

function insertCommandBarItem(
  items: IDataGridCommandBarItemProps[],
  customItem: IDataGridCommandBarItemProps,
  placement: IGridPageCommandBarPlacement
): IDataGridCommandBarItemProps[] {
  // If key not specificed, insert based on direction either before or after rest of the items
  if (!placement.key) {
    switch (placement.direction) {
      case Direction.before:
        items.unshift(customItem);
        break;
      default:
      case Direction.after:
        items.push(customItem);
        break;
    }
  } else {
    const keyItem = items.find((item) => item.key === placement.key);
    let indextoAdd = 0;

    if (keyItem) {
      const keyPosition = items.findIndex((item) => item === keyItem);
      indextoAdd = keyPosition;
      switch (placement.direction) {
        case Direction.before:
          if (keyPosition === 0) {
            indextoAdd = 0;
          }
          break;
        default:
        case Direction.after:
          indextoAdd++;
          break;
      }
      items.splice(indextoAdd, 0, customItem);
    }
  }

  return items;
}

function getItemsBasedOnModeAndStatus(items: IDataGridCommandBarItemProps[], mode: GridSelectionMode, selectedItems?: Model[]) {
  const hasSelection = (item) => !item.selectionModes || item.selectionModes.includes(mode);
  const hasStatusSelection = (item) =>
    !item.selectionStatus || (selectedItems && selectedItems.some((dataRow) => dataRow.status === item.selectionStatus));
  return items.filter((item) => hasSelection(item) && hasStatusSelection(item));
}
