import { mutatorAction } from 'satcheljs';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { deepClone } from '../../../../../utils';
import { AdQualityFilterNames, IAdQualityFilter } from '../store/schema';
import { getStore } from '../store/store';

export const onAdCreativeInitialAccountsChanged = mutatorAction(
  'onAdCreativeInitialAccountsChanged',
  (accounts: IIdNamePair[], account: number | undefined) => {
    const rawAccounts = getStore().filters?.raw?.[AdQualityFilterNames.AccountName];
    if (rawAccounts) {
      rawAccounts.values = accounts;
    }

    if (account) {
      rawAccounts?.values.map((item: IIdNamePair) => {
        if (item.id === account) {
          item.isSelected = true;
        }
        return item;
      });
    }
  }
);

export const onAdCreativeInitialAdProvidersChanged = mutatorAction(
  'onAdCreativeInitialAdProvidersChanged',
  (auctionAdProviders: IIdNamePair[]) => {
    const adProviders = getStore().filters?.raw?.[AdQualityFilterNames.AdProviders];
    if (adProviders) {
      adProviders.values = auctionAdProviders;
    }
  }
);

export const onAdCreativeInitialMarketsChanged = mutatorAction('onAdCreativeInitialMarketsChanged', (auctionMarkets: IIdNamePair[]) => {
  const locales = getStore().filters?.raw?.Markets;
  if (locales) {
    locales.values = auctionMarkets;
  }
});

export const onActiveFilterValuesChanged = mutatorAction(
  'onActiveFilterValuesChanged',
  function filterValuesChanged(raw: IAdQualityFilter) {
    getStore().filters.active = deepClone(raw);
  }
);

export const onRawFilterValuesChanged = mutatorAction('onRawFilterValuesChanged', function filterValuesChanged(active: IAdQualityFilter) {
  for (const key in active) {
    if (active[key] && active[key].conditions) {
      const lastElement = active[key].conditions[active[key].conditions.length - 1];
      if (lastElement.condition === undefined || lastElement.value === '') {
        lastElement.condition = undefined;
        lastElement.value = '';
        lastElement.isApplied = false;
      }
    }
  }

  getStore().filters.raw = deepClone(active);
});

export const onSetIsAppliedForAllConditions = mutatorAction(
  'onSetIsAppliedForAllConditions',
  function setIsAppliedForAllConditions(active: IAdQualityFilter) {
    for (const key in active) {
      if (active[key] && active[key].conditions) {
        active[key].conditions.forEach((triplet) => {
          if (triplet.condition && triplet.value !== '') {
            triplet.isApplied = true;
          } else {
            triplet.isApplied = false;
          }
        });
      }
    }
  }
);
