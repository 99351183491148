import { Callout, IconButton, SearchBox } from '@fluentui/react';
import * as React from 'react';
import { getStore } from '../../../GridPageV2/@data/store/store';
import { setIsSearchCalloutVisible } from '../../@data/mutatorActions';

interface ISearchBoxCallOut {
  placeHolder?: string;
  handleSearch?: (val: string) => void;
}

export const SearchBoxCallOut = function SearchBoxCallOutComponent(props: ISearchBoxCallOut) {
  const { placeHolder, handleSearch } = props;
  const { searchWord, isSearchCalloutVisible } = getStore();

  return (
    <>
      <IconButton
        id={'gridSearchIcon'}
        onClick={() => setIsSearchCalloutVisible(!isSearchCalloutVisible)}
        iconProps={{ iconName: 'Search' }}
      />
      {isSearchCalloutVisible && (
        <Callout
          style={{
            padding: '10px 15px',
          }}
          target={'#gridSearchIcon'}
          isBeakVisible={false}
          onDismiss={() => setIsSearchCalloutVisible(false)}
          directionalHint={5}
        >
          <SearchBox
            value={searchWord}
            placeholder={placeHolder ? placeHolder : ''}
            onChange={(_, val: string) => handleSearch?.(val)}
            onClear={() => handleSearch?.('')}
            ariaLabel={placeHolder}
            autoFocus={isSearchCalloutVisible}
          />
        </Callout>
      )}
    </>
  );
};
