import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  AdSource,
  LifeCycleStatus,
  Publisher,
  getAppStore,
  listEntity,
  onLoadingChanged,
  onNotificationChanged,
} from '../../../../../@data';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import messages from '../../AdReview/AdCreativePage.messages';
import { onAdCreativeChanged, onBlockedContextChanged } from '../actions';
import { onAdCreativePageInitialized } from '../actions/onAdCreativePageInitialized';
import { onAdCreativeInitialAccountsChanged } from '../mutatorActions';
import { getBlockSummaryData } from '../services/AdBlockService';
import { getAdPerfData, getBlockRequestSources } from '../services/AdCreativeService';
import { TARGET_CUSTOMER_ID } from '../services/utility/constants';
import { constructAdPerfRequest } from '../services/utility/utility';
import { converterIn } from '../store/adQualityConverter';
import { IAdCreativeViewModel } from '../store/schema';
import { BlockRequestSourceData } from '../store/schema/models/BlockRequestSourceData';

orchestrator(onAdCreativePageInitialized, async (msg) => {
  const { userContext } = getAppStore();

  const viewModel: IAdCreativeViewModel = {
    selectedAdSource: AdSource.Microsoft,
    selectedAdProviderId: 0,
    selectedBlockedMarketId: 0,
  };
  if (msg.publisherId) {
    const publisher = new Publisher(msg.publisherId);

    try {
      onLoadingChanged(true);
      onBlockedContextChanged(msg.publisherId);

      const accounts = await listEntity([publisher], userContext, Account);
      viewModel.accounts = accounts ? accounts.map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];
      viewModel.selectedAccount = accounts[0].id;

      const account = new Account(viewModel.selectedAccount);

      // Set default date (for ad perf) to be the date of UTC now minus 4 hours.
      // We give the 4 hour buffer for data coming in on the new day.
      const defaultDateTimeLocal = new Date();
      defaultDateTimeLocal.setHours(defaultDateTimeLocal.getHours() - 4);
      const defaultDateUTCBased = new Date(
        defaultDateTimeLocal.getUTCFullYear(),
        defaultDateTimeLocal.getUTCMonth(),
        defaultDateTimeLocal.getUTCDate()
      );
      viewModel.startDate = defaultDateUTCBased;
      viewModel.endDate = defaultDateUTCBased;

      const blockRequestSourcesPromise = getBlockRequestSources(msg.publisherId);
      const params = new URLSearchParams([[TARGET_CUSTOMER_ID, publisher.id.toString()]]);
      onAdCreativeInitialAccountsChanged(viewModel.accounts, viewModel.selectedAccount);

      const data = converterIn(AdSource.Microsoft, false);
      const adPerfDataPromise = getAdPerfData(constructAdPerfRequest(viewModel, data, publisher, [account.id]), params);

      const blockSummaryDataPromise = getBlockSummaryData(
        [['account', account.id!.toString()]],
        new URLSearchParams([[TARGET_CUSTOMER_ID, msg.publisherId.toString()]])
      );

      const blockSummaryData = await blockSummaryDataPromise;
      blockSummaryData.forEach((d) => (d.isEnabled ? (d.status = LifeCycleStatus.Active) : (d.status = LifeCycleStatus.Inactive)));

      viewModel.blockSummaryData = blockSummaryData;
      viewModel.adPerfData = await adPerfDataPromise;
      const blockRequestData: BlockRequestSourceData[] = await blockRequestSourcesPromise;
      const mappedBlockRequestData = new Map();
      blockRequestData.forEach((blockRequestSource) => {
        mappedBlockRequestData.set(blockRequestSource.blockRequestSourceId, blockRequestSource.blockRequestSourceName);
      });
      viewModel.blockRequestSourceData = mappedBlockRequestData;
      onAdCreativeChanged(viewModel);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adCreativeServiceLoadEntityFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
