import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  getAppStore,
  getEntity,
  IUserName,
  listEntity,
  onLoadingChanged,
  onLoadingSetting,
  onLoadingWaiting,
  onNotificationChanged,
  Publisher,
  User,
} from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { setPublisherContext } from '../../../@data/service/PartnerManagementService';
import { onFormInitialized, onRawChanged, onUserChanged, onUserErrorChanged } from '../actions';
import { fetchAccounts } from '../services/UserService';
import { IUserViewModel } from '../store/schema';

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();

  const user: IUserViewModel = {
    accountsList: [],
  };

  const publisherId = parseInt(msg.publisherId, 10);
  if (!isNaN(publisherId)) {
    const publisher = new Publisher(publisherId);

    try {
      setPublisherContext(publisher);
      onUserErrorChanged();
      onLoadingChanged(true);
      onLoadingSetting(false);
      const timeout = 30000;
      const id = setTimeout(() => onLoadingWaiting(true), timeout);
      const response = await listEntity([publisher], userContext, Account);

      user.accountsList = response ? response.map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];
      user.allAccounts = response ? response.map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];
      user.localeId = 1033; // default to US-en

      if (msg.mode === FormState.Edit && msg.entityId) {
        const userId = parseInt(msg.entityId, 10);
        const userWithId = new User(userId);
        const rawUser = await getEntity<User>([publisher, userWithId], userContext);

        user.isLegacyUser = rawUser.windowsLiveIdUsername ? false : true;
        user.firstName = (rawUser.name as IUserName).firstName;
        user.lastName = (rawUser.name as IUserName).lastName;
        user.emailAddress = rawUser.email;
        user.phoneNumber = rawUser.contactInfo ? rawUser.contactInfo.homePhone : '';
        user.faxNumber = rawUser.contactInfo ? rawUser.contactInfo.fax : '';
        user.userName = rawUser.username;
        user.language = rawUser.language;
        user.SecretQuestion = rawUser.secretQuestion;
        user.contactByEmail = rawUser.contactByEmail;
        user.contactByPhone = rawUser.contactByPhone;
        user.contactByPost = rawUser.contactByPost;
        user.accountRole = rawUser.userRoleDetails ? rawUser.userRoleDetails[0].userRole : undefined;
        user.userRoleDetails = rawUser.userRoleDetails;

        // set selected account list for each role after figure out the entity type mapping
        const accountManagerAccountList: number[] = [];
        const reportUserAccountList: number[] = [];
        const listManagerAccountList: number[] = [];
        const adViewAccountList: number[] = [];

        if (rawUser.userRoleDetails) {
          rawUser.userRoleDetails.forEach((i) => {
            switch (i.userRole) {
              case 'AccountManager':
                accountManagerAccountList.push(i.entityId);
                break;
              case 'ReportUser':
                reportUserAccountList.push(i.entityId);
                break;
              case 'ListManager':
                listManagerAccountList.push(i.entityId);
                break;
              case 'AdViewer':
                adViewAccountList.push(i.entityId);
                break;
            }
          });
        }
        const allAccounts = await fetchAccounts(publisher, userContext);
        clearTimeout(id);

        const accountManagerResponse = allAccounts.filter((acc) => {
          return accountManagerAccountList.some((accountManagerAccount) => accountManagerAccount === acc.id);
        });
        user.AccountManagerAccountsSelected = accountManagerResponse.map((f) => ({ id: f.id, name: f.name } as IIdNamePair));

        const reportUserResponse = allAccounts.filter((acc) => {
          return reportUserAccountList.some((reportUserAccount) => reportUserAccount === acc.id);
        });
        user.ReportUserAccounstSelected = reportUserResponse.map((f) => ({ id: f.id, name: f.name } as IIdNamePair));

        const listManagerResponse = allAccounts.filter((acc) => {
          return listManagerAccountList.some((account) => account === acc.id);
        });
        user.ListManagerAccountsSelected = listManagerResponse.map((f) => ({ id: f.id, name: f.name } as IIdNamePair));

        const adViewResponse = allAccounts.filter((acc) => {
          return adViewAccountList.some((account) => account === acc.id);
        });
        user.AdViewAccountsSelected = adViewResponse.map((f) => ({ id: f.id, name: f.name } as IIdNamePair));

        onRawChanged(rawUser);
      } else {
        onRawChanged();
      }

      onUserChanged(user);
    } catch (e) {
      onNotificationChanged({
        text: 'Failed to load entity',
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      text: `You have arrived in an invalid state, please report.`,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
