import { makeStyles, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
  root: {
    marginLeft: '-3rem',
  },
  title: {
    marginTop: '2rem',
  },
  contentSection: {
    width: '50rem',
    marginLeft: 'calc(50% - 25rem)',
    color: tokens.colorNeutralForeground3,
  },
  imgWrapper: {
    width: '8rem',
  },
  cardGroupWrapper: {
    width: '50rem',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'calc(50% - 25rem)',
  },
  cardWrapper: {
    width: 'calc(50% - 0.75rem)',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    height: '18.75rem',
  },
  cardSelected: {
    backgroundColor: tokens.colorNeutralBackground2Selected,
  },
  cardTitle: {
    color: tokens.colorNeutralForeground1,
  },
  cardDescription: {
    color: tokens.colorNeutralForeground3,
  },
});
