import { defineMessages } from 'react-intl';

export default defineMessages({
  backButtonAriaDesc: {
    id: 'detailPage.backButtonAriaDesc',
    defaultMessage: 'Click to go back to manage page'
  },
  breadcrumbAriaLabel: {
    id: 'detailPage.breadcrumbAriaLabel',
    defaultMessage: 'Bread crumb showing the heirarchy of subpages'
  }
});