import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { Filter, getAppStore, listEntity, onNotificationChanged } from '../../../../../@data';
import { onFetchFiltersLoading, onFetchingFiltersData, onFiltersFetched } from '../actions';
import { onManagePageInitialized } from '../actions/onManagePageInitialized';
import { getFilterStore } from '../store/store';

orchestrator(onManagePageInitialized, async () => {
  try {
    const { publisher, userContext, account } = getAppStore();
    const filters = getFilterStore().gridFilters;

    if (filters === undefined || filters.length === 0) {
      onFetchFiltersLoading(true);
    }
    onFetchingFiltersData(true);

    if (publisher && account && userContext) {
      const params = new URLSearchParams([['extendedInfo', 'true']]);
      const data = await listEntity([publisher, account], userContext, Filter, params);
      data.forEach((filter) => {
        if (!!filter.startTimeStamp && !isNaN(Number(filter.startTimeStamp))) {
          filter.startTime = new Date(Number(filter.startTimeStamp) * 1000).toISOString();
        }
        if (!!filter.endTimeStamp && !isNaN(Number(filter.endTimeStamp))) {
          filter.endTime = new Date(Number(filter.endTimeStamp) * 1000).toISOString();
        }
      });
      onFiltersFetched(data);
    }
  } catch (error) {
    Log.error(error);
    onNotificationChanged({ messageBarType: MessageBarType.error, text: 'Failed to load ad filters' });
  } finally {
    onFetchFiltersLoading(false);
    onFetchingFiltersData(false);
  }
});
