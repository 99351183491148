import { mergeStyleSets, Overlay, Spinner } from '@fluentui/react';
import React from 'react';
import { LOADING_SPINNER } from '../../constants/DataTestIdConstants';
import { getClassNames, PageSpinnerStyles } from './PageSpinner.styles';

export interface IPageSpinnerProps {
  label?: string;
  styleProps?: Partial<PageSpinnerStyles>;
}

export function PageSpinner({ label, styleProps }: IPageSpinnerProps): JSX.Element {
  const { overlayStyle, spinnerStyle } = mergeStyleSets(getClassNames(), styleProps);

  return (
    <Overlay data-test-id={LOADING_SPINNER} className={overlayStyle}>
      <Spinner className={spinnerStyle} label={label} labelPosition="bottom" />
    </Overlay>
  );
}
