import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  AccountProfileFeature,
  getAppStore,
  onNavigateBack,
  onNotificationChanged,
  onSavingChanged,
  postEntity,
  putEntity,
} from '../../../../../@data';
import { AdExtensionType } from '../../../../../@data/store/schema/enums/AdExtensionType';
import { FormState } from '../../../../../components/Form';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { validateSchema } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/service/PartnerManagementService';
import { onAccountErrorChanged, onAccountIdChanged, onFormCleared, onFormSubmitted } from '../actions';
import { accountErrorModel } from '../store/schema/AccountErrorModel';
import { getStore } from '../store/store';

orchestrator(onFormSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { active, raw } = getStore();

  let notification: INotificationBarItem = {};

  if (publisher) {
    const body: Account = {
      ...raw,
      id: active.id,
      name: active.name,
      apiPath: undefined,
      className: 'accounts',
    };

    try {
      await validateSchema(accountErrorModel(), active);
      onAccountErrorChanged();
    } catch (e) {
      onAccountErrorChanged(e);
      onNotificationChanged({
        text: `Account - You have ${Object.keys(e).length} errors in the form, please fix them to continue.`,
        messageBarType: MessageBarType.severeWarning,
      });
      return;
    }

    onSavingChanged(true);
    body.status = active.status;
    body.countryCode = active.countryCode;
    body.primaryContactUserId = active.primaryContactUserId;
    body.currencyCode = active.currencyCode;
    body.languageCode = active.languageCode;
    body.adDeliverySettings = active.adDeliverySettings;
    body.additionalMarkets = active.additionalMarkets;

    body.timeZoneId = active.timeZoneId;
    body.adExtensionOptions = [];

    if (active.allowCallExtensions) {
      body.adExtensionOptions.push({
        type: AdExtensionType.Call,
        enabled: active.allowCallExtensions,
      });
    }

    if (active.allowSiteLinkExtensions) {
      body.adExtensionOptions.push({
        type: AdExtensionType.SiteLink,
        enabled: active.allowSiteLinkExtensions,
      });
    }

    if (active.allowPhoneExtensions) {
      body.adExtensionOptions.push({
        type: AdExtensionType.Phone,
        enabled: active.allowPhoneExtensions,
      });
    }

    if (active.allowLocalExtensions) {
      body.adExtensionOptions.push({
        type: AdExtensionType.Local,
        enabled: active.allowLocalExtensions,
      });
    }

    if (active.allowLocationExtensions) {
      body.adExtensionOptions.push({
        type: AdExtensionType.Location,
        enabled: active.allowLocationExtensions,
      });
    }

    if (active.allowMeteredCallExtensions) {
      body.adExtensionOptions.push({
        type: AdExtensionType.MeteredCall,
        enabled: active.allowMeteredCallExtensions,
      });
    }

    if (active.allowMerchantRatingExtensions) {
      body.adExtensionOptions.push({
        type: AdExtensionType.MerchantRatings,
        enabled: active.allowMerchantRatingExtensions,
      });
    }

    body.enabledFeatures = [];
    if (active.allowGlobalDefaultKeywords) {
      body.enabledFeatures.push(AccountProfileFeature.EnableGlobalDefaultKeywords);
    }
    if (active.allowAdultAds) {
      body.enabledFeatures.push(AccountProfileFeature.EnableAdultAds);
    }
    if (active.allowCrawling) {
      body.enabledFeatures.push(AccountProfileFeature.EnableCrawling);
    }
    if (active.allowPassedURL) {
      body.enabledFeatures.push(AccountProfileFeature.EnablePassedUrls);
    }
    if (active.allowPassedKeyword) {
      body.enabledFeatures.push(AccountProfileFeature.EnablePassedKeywords);
    }

    body.paymentInformation = {
      financialStatus: active.financialStatus!,
      paymentOption: active.paymentOption!,
      paymentInstrumentationLifeCycleStatus: active.paymentLifeCycleStatus!,
      operationCostRatio: active.operationRatio!,
      revenueSharePercentage: active.revenueSharePercentage!,
    };

    body.storeIds = active.storeIds
      ? active.storeIds
          .split('\n')
          .filter((i) => i !== '' && i !== '\r')
          .map((id) => +id)
      : [];

    body.partnerCodes = active.partnerCodes
      ? active.partnerCodes
          .split('\n')
          .filter((i) => i !== '' && i !== '\r')
          .map((code) => code.trim())
      : [];

    body.formCodes = active.formCodes
      ? active.formCodes
          .split('\n')
          .filter((i) => i !== '' && i !== '\r')
          .map((code) => code.trim())
      : [];

    body.startDistributionPlacementCodes = active.startDistributionPlacementCodes
      ? active.startDistributionPlacementCodes
          .split('\n')
          .filter((i) => i !== '' && i !== '\r')
          .map((code) => code.trim())
      : [];

    body.startDistributionOCIDL2s = active.startDistributionOCIDL2s
      ? active.startDistributionOCIDL2s
          .split('\n')
          .filter((i) => i !== '' && i !== '\r')
          .map((code) => code.trim())
      : [];

    body.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage =
      active.startDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentage;

    try {
      let entity: Account | undefined;

      if (msg.mode === FormState.Edit) {
        const account = new Account(active.id);
        entity = await putEntity([publisher, account], body, userContext);
        notification = {
          text: `Edited account ${entity.id} success`,
          messageBarType: MessageBarType.success,
        };
      } else {
        entity = await postEntity([publisher], Account, body, userContext);

        if (entity && entity.id) {
          onAccountIdChanged(entity.id);
        }
        notification = {
          text: `Created new account ${entity.id} success`,
          messageBarType: MessageBarType.success,
        };
      }

      // return to manage page
      const returnUrl = createReturnUrl(RouteName.account, String(publisher.id));
      onNavigateBack(msg.routeHistory, returnUrl);
      onFormCleared();
    } catch (e) {
      notification = {
        text: `Submitting the account form "${FormState[msg.mode]}" caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
    } finally {
      onSavingChanged(false);
    }
  } else {
    notification = {
      text: `Account form "${FormState[msg.mode]}" is invalid id ${active.id}`,
      messageBarType: MessageBarType.warning,
    };
  }
  onNotificationChanged(notification);
});
