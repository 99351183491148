import React from 'react';

export const isButtonPressKey = (key: string): boolean => {
  /* Based on Accessibility standard keystrokes for button press, keyboardEvents for key press of
   Enter and Space (encoded as ' ') are considered for button press
   https://www.w3.org/TR/uievents-key/#named-key-attribute-values */
  if (key === 'Enter' || key === ' ') {
    return true;
  }
  return false;
};

export function isKey(event: React.KeyboardEvent, key: string): boolean {
  return event.key === key;
}
