import { action } from 'satcheljs';
import { AdUnit, LifeCycleStatus } from '../../../../../@data';

export const fetchAdUnitsDataAction = action('fetchAdUnitsDataAction');

export const fetchAdUnitMediationStatus = action('fetchAdUnitMediationStatus',
  (adUnitId: string,
  showLoadingSpinner?: boolean,
  showNotifications?: boolean) => ({ adUnitId, showLoadingSpinner, showNotifications }));

export const updateAdUnitMediationStatus = action('updateAdUnitMediationStatus',
  (adUnits: AdUnit[], state: LifeCycleStatus) => ({ adUnits, state }));