import * as React from 'react';
import { useEffect } from 'react';
import { Account, getAppStore } from '../../../../@data';
import { ZeroResults } from '../../../../components/ZeroResults/ZeroResults';
import { useWindowSize } from '../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../hooks/useWindowSize/store/store';
import { GridPageLayout } from '../../../../layout/GridPageLayout/GridPageLayout';
import { GridV2, IGridV2Column } from '../../../../layout/GridPageV2';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../utils/ReactUtils';
import { RouteName } from '../../@data';
import { getStore } from '../@data';
import { onAccountsFetched, onManagePageInitialized } from '../@data/actions';
import messages from './ManagePage.messages';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

export const ManagePage = injectIntlWithObserver(function AccountManagePage(props: IRouterWithIntl) {
  const { publisher } = getAppStore();
  const { accounts, isLoading, isFetchingData } = getStore();
  const { formatMessage } = props.intl;
  const { windowStoreSerializer } = useWindowSize();
  const { isWindowRegularSize } = windowStoreSerializer(getWindowSizeStore());
  const gridDefaultColumns: IGridV2Column[] = getGridColumns(formatMessage, isWindowRegularSize, props.history);

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={!!publisher ? formatMessage(messages.zeroResultTitle) : undefined}
      description={formatMessage(!!publisher ? messages.zeroResultDescription : messages.noPublisherSelected)}
      buttonText={!!publisher ? formatMessage(messages.create) : undefined}
      href={`${RouteName.account}/create`}
    />
  );

  useEffect(() => {
    onManagePageInitialized();
    // eslint-disable-next-line
  }, [publisher]);

  return (
    <GridPageLayout>
      <GridV2
        history={props.history}
        location={props.location}
        match={props.match}
        entity={Account}
        pageTitle={{ title: formatMessage(messages.accounts), helpId: 'pop_PCv4_Accounts' }}
        commandBarProps={getCommandBarItems()}
        dataGridProps={{
          gridV2Columns: gridDefaultColumns,
          data: publisher ? accounts || [] : [],
          setData: onAccountsFetched,
          shimmerLines: 10,
          renderZeroItemsComponent: NoResultComponent,
        }}
        isGridReady={!isLoading}
        isDataLoading={isFetchingData}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
    </GridPageLayout>
  );
});
