import { AuditType, UserRole } from '../enums';
import { Model } from './Model';

export class AuditLog extends Model {
  public className = 'auditlogs';
  public apiPath = undefined;
  public modifiedAt: string;
  public publisherId: string;
  public auditType: AuditType;
  public entityType: string;
  public entityId: string;
  public entityName: string;
  public modifiedBy: string;
  public userEmail: string;
  public userRoles: UserRole[];
  public changes: AuditLogChange[];
}

export class AuditLogChange {
  public attributeChanged: string;
  public oldValue: string;
  public newValue: string;
  public log?: string;
}

export class AuditLogResponse {
  public changeLogs: AuditLog[];
  public pageNumber: number;
  public pageSize: number;
  public totalPages: number;
  public totalRecords: number;
}
