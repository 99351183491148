import { ICustomColumn } from '../../../types';

function flattenNestedObject(obj, parentKey = '') {
  let result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        const nestedValues = flattenNestedObject(obj[key], newKey);
        result = { ...result, ...nestedValues };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
}

function unflattenObject(flatObj) {
  const result = {};

  for (const key in flatObj) {
    if (flatObj.hasOwnProperty(key)) {
      const keys = key.split('.');
      let nestedObj = result;

      for (let i = 0; i < keys.length; i++) {
        const currentKey = keys[i];
        if (i === keys.length - 1) {
          nestedObj[currentKey] = flatObj[key];
        } else {
          nestedObj[currentKey] = nestedObj[currentKey] || {};
          nestedObj = nestedObj[currentKey];
        }
      }
    }
  }

  return result;
}

export function sortItemsBasedOnColumn<ItemType>(items: ItemType[], column?: ICustomColumn): ItemType[] {
  if (!column) {
    return items;
  }

  if (!items || !items[0]) {
    return [];
  }

  const { sortFunction, sortableValue, isSortedDescending, fieldName } = column;

  // Use the sorting function if provided
  if (typeof sortFunction === 'function') {
    return items.sort((a, b) => sortFunction(a, b, isSortedDescending));
  }

  let itemsCopy = [...items];

  itemsCopy = itemsCopy.map((item) => {
    return flattenNestedObject(item) as ItemType;
  });

  const sortedItems = itemsCopy.concat([]).sort((a: ItemType, b: ItemType) => {
    const firstValue = getSortableValue(a, fieldName, sortableValue);
    const secondValue = getSortableValue(b, fieldName, sortableValue);

    if (firstValue === undefined && secondValue !== undefined) {
      return 1;
    } else if (secondValue === undefined) {
      return -1;
    }

    if (typeof firstValue === 'string' && typeof secondValue === 'string') {
      if (isSortedDescending) {
        return firstValue.toLowerCase() > secondValue.toLowerCase() ? -1 : 1;
      } else {
        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      }
    } else {
      if (isSortedDescending) {
        return firstValue > secondValue ? -1 : 1;
      } else {
        return firstValue > secondValue ? 1 : -1;
      }
    }
  });

  return sortedItems.map((item) => {
    return unflattenObject(item) as ItemType;
  });
}

function getSortableValue(item, fieldName, sortableValue) {
  // Use the sortableValue function if provided
  return sortableValue ? sortableValue(item) : item[fieldName || ''];
}
