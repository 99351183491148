import { IDropdownOption } from '@fluentui/react';
import { History } from 'history';
import { action } from 'satcheljs';
import { FormState } from '../../../../../components/Form';

export const onFormInitialized = action('onFormInitialized', (mode: FormState, publisherId: string, entityId?: string) => ({
  mode,
  publisherId,
  entityId,
}));

export const onFormCancelled = action('onFormCancelled');

export const onFormCleared = action('onFormCleared');

export const onFormSubmitted = action('onFormSubmitted', (mode: FormState, routeHistory?: History) => ({ mode, routeHistory }));

export const onAccountIdChanged = action('onAccountIdChanged', (value: number) => ({ value }));

export const onAccountSkuFormMediumChanged = action('onAccountSkuFormMediumChanged', (value: IDropdownOption) => ({ value }));

export const onAccountSkuFormSkuChanged = action('onAccountSkuFormSkuChanged', (value: IDropdownOption) => ({ value }));

export const onAccountSkuFormCleared = action('onAccountSkuFormCleared');

export const onAccountSkuFormSubmitted = action('onAccountSkuFormSubmitted', (mode: FormState, accountId: number) => ({ mode, accountId }));

export const onFormAccountNameChanged = action('onFormAccountNameChanged', (value?: string) => ({ value }));

export const onFormAccountCurrencyChanged = action('onFormAccountCurrencyChanged', (value?: string) => ({ value }));

export const onFormAccountLanguageChanged = action('onFormAccountLanguageChanged', (value: IDropdownOption) => ({ value }));

export const onFormAccountPrimaryContactChanged = action('onFormAccountPrimaryContactChanged', (value: IDropdownOption) => ({ value }));

export const onFormAccountCountryChanged = action('onFormAccountCountryChanged', (value?: IDropdownOption) => ({
  value,
}));

export const onFormAccountTimezoneChanged = action('onFormAccountTimezoneChanged', (value?: IDropdownOption) => ({
  value,
}));

export const onFormAccountPaymentOptionChanged = action('onFormAccountPaymentOptionChanged', (value?: IDropdownOption) => ({
  value,
}));

export const onFormAccountFinancialStatusChanged = action('onFormAccountFinancialStatusChanged', (value?: IDropdownOption) => ({
  value,
}));

export const onFormAccountPaymentLifeCycleStatusChanged = action(
  'onFormAccountPaymentLifeCycleStatusChanged',
  (value?: IDropdownOption) => ({
    value,
  })
);

export const onFormAccountOperationRatioChanged = action('onFormAccountOperationRatioChanged', (value?: string) => ({
  value,
}));

export const onFormAccountAllowPassedURLChanged = action('onFormAccountAllowPassedURLChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountAllowPassedKeywordChanged = action('onFormAccountAllowPassedKeywordChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountAllowCrawlingChanged = action('onFormAccountAllowCrawlingChanged', (value?: boolean) => ({
  value,
}));
export const onFormAccountAllowAdultAdsChanged = action('onFormAccountAllowAdultAdsChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountAllowGlobalDefaultKeywordsChanged = action(
  'onFormAccountAllowGlobalDefaultKeywordsChanged',
  (value?: boolean) => ({
    value,
  })
);

export const onFormAccountAllowMeteredCallExtensionsChanged = action(
  'onFormAccountAllowMeteredCallExtensionsChanged',
  (value?: boolean) => ({
    value,
  })
);

export const onFormAccountAllowLocationExtensionsChanged = action('onFormAccountAllowLocationExtensionsChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountAllowPhoneExtensionsChanged = action('onFormAccountAllowPhoneExtensionsChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountAllowMerchantRatingExtensionsChanged = action(
  'onFormAccountAllowMerchantRatingExtensionsChanged',
  (value?: boolean) => ({
    value,
  })
);

export const onFormAccountAllowLocalExtensionsChanged = action('onFormAccountAllowLocalExtensionsChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountAllowCallExtensionsChanged = action('onFormAccountAllowCallExtensionsChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountAllowSiteLinkExtensionsChanged = action('onFormAccountAllowSiteLinkExtensionsChanged', (value?: boolean) => ({
  value,
}));

export const onFormAccountStoreIdsChanged = action('onFormAccountStoreIdsChanged', (values?: string) => ({ values }));

export const onFormAccountPartnerCodesChanged = action('onFormAccountPartnerCodesChanged', (values?: string) => ({ values }));

export const onFormAccountFormCodesChanged = action('onFormAccountFormCodesChanged', (values?: string) => ({ values }));

export const onFormAccountStartDistributionPlacementCodesChanged = action(
  'onFormAccountStartDistributionPlacementCodesChanged',
  (values?: string) => ({ values })
);

export const onFormAccountStartDistributionOCIDL2sChanged = action('onFormAccountStartDistributionOCIDL2sChanged', (values?: string) => ({
  values,
}));

export const onFormAccountStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageChanged = action(
  'onFormAccountStartDistributionNativeAdsInFeedsAndHeadlinesRevenueSharePercentageChanged',
  (value?: string) => ({ value })
);
