import { IComboBoxOption, MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { AdSource, getAppStore, onNotificationChanged } from '../../../../../@data';
import messages from '../../AdReview/AdCreativePage.messages';
import { onAdCreativeAccountChanged, onAdCreativeMarketChanged, onAdSourceChanged } from '../actions/onAdCreativeChanged';
import { getStore } from '../store/store';

orchestrator(onAdSourceChanged, async (msg) => {
  const { publisher } = getAppStore();
  if (msg.adSource && publisher) {
    try {
      const adSource = msg.adSource.key as AdSource;
      if (adSource === AdSource.Microsoft) {
        onAdCreativeMarketChanged(undefined);

        const accounts = getStore().active.accounts;
        if (accounts) {
          onAdCreativeAccountChanged({ key: accounts[0].id } as IComboBoxOption);
        }
      } else {
        onAdCreativeAccountChanged(undefined);
        // en-us market
        onAdCreativeMarketChanged({ key: 1033 } as IComboBoxOption);
      }
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adCreativeServiceLoadEntityFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    }
  }
});
