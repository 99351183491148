export const ariaConfig = {
  transmitProfile: 'AWT_REAL_TIME',

  // ARIA Project tokens for different envs
  // aria project name - PubcenterUINext-Dev
  // https://portal.aria.ms/project/13b6d1306d984c86b48a95df1443daa3/home
  ariaTokenTest: '13b6d1306d984c86b48a95df1443daa3-8bd3a776-3812-45d6-93b3-a9944af0602c-7250',

  // dev/SI/staging refer to the same env
  // aria project name - PubcenterUINext-SI
  // https://portal.aria.ms/project/8729f29d43a04e89bdfb2f7d9c2c22c1/home
  ariaTokenSI: '8729f29d43a04e89bdfb2f7d9c2c22c1-72910d57-9c8c-4929-ba85-adaedc15b808-7236',

  // aria project name - PubcenterUINext-BCP
  // https://portal.aria.ms/project/4730aa7b426443bca86a435110589367/home
  ariaTokenBCP: '4730aa7b426443bca86a435110589367-2a3d4128-5cf8-492c-a50b-2bcc1edac9a0-7040',

  // aria project name - PubcenterUINext-Prod
  // https://portal.aria.ms/project/ec1ea72e6f7f4f34a02a6fc2d1fa1280/home
  ariaTokenProd: 'ec1ea72e6f7f4f34a02a6fc2d1fa1280-679679fb-85d9-4a00-b895-fc8382c6cd17-7344',
}