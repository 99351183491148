import {
  Checkbox,
  classNamesFunction,
  ComboBox,
  DatePicker,
  DefaultButton,
  DirectionalHint,
  Dropdown,
  FocusZone,
  getTheme,
  IColumn,
  IComboBoxOption,
  Icon,
  IDropdownOption,
  IProcessedStyleSet,
  IRectangle,
  Label,
  Link,
  List,
  mergeStyles,
  MessageBarType,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  SearchBox,
  Spinner,
  TeachingBubble,
  TextField,
} from '@fluentui/react';
import * as React from 'react';
import { AdSource, getAppStore, LayoutMode, LifeCycleStatus, onLayoutChanged, onNotificationChanged } from '../../../../@data';
import { FilterCondition } from '../../../../@data/store/schema/enums/FilterCondition';
import AppMessages from '../../../../App.messages';
import { DataGrid, DataGridSelectionMode, IDataGrid, IDataGridCommandBarProps } from '../../../../components/DataGrid';
import { DayPickerStrings } from '../../../../components/DateTimePicker/DateTimePicker.types';
import { FormItem, FormSection, FormState } from '../../../../components/Form';
import { FilterType } from '../../../../components/GridFilter/GridFilter.types';
import { IIdNamePair } from '../../../../components/MultiSelectPicker';
import { adProvidersToNames } from '../../../../config/adProviders';
import { adQualityLocales } from '../../../../config/locale';
import { xandrAdProvidersToNames } from '../../../../config/xandrAdProviders';
import { NO_VALUE_PLACEHOLDER } from '../../../../constants/AppConstants';
import { LOCALE_LANGUAGE_MAPPING } from '../../../../globalization';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { CommandBarActions, IDataGridColumn, IFilterItem } from '../../../../layout/GridPageV2';
import { Pagination } from '../../../../layout/GridPageV2/components/Pagination';
import { CSVHelper, injectIntlWithObserver, IRouterWithIntl } from '../../../../utils';
import { isButtonPressKey } from '../../../../utils/AccessibilityUtils';
import {
  onAdCreativePageInitialized,
  onAdDescriptionChanged,
  onAdListingPageSelection,
  onBlockAdInitialized,
  onBlockAdsAdPerfDataCleared,
  onBlockAdsBlockDetailsChanged,
  onBlockAdsBlockedImagesChanged,
  onBlockAdsBlockReasonChanged,
  onBlockAdsImagesChanged,
  onBlockAdsItemStatusChanged,
  onBlockAdsReasonLevelsChanged,
  onBlockAdsSelectedBlockRequestSourceChanged,
  onBlockAdsSelectedMarketChanged,
  onBlockAdsSubmitted,
  onBlockedAdsExportClicked,
  onBlockedImagesExportClicked,
  onBlockImageAdsChanged,
  onBlockImagesSubmitted,
  onBlockSummaryEndDateChanged,
  onBlockSummaryStartDateChanged,
  onFilterPanelHide,
  onFilterPanelShown,
  onRefreshBlockSummary,
  onSAMBlockAdsItemStatusChanged,
  onSAMBlockSummaryViewImagesClicked,
  onTeachingBubbleEndDateStatusChanged,
  onTeachingBubbleStartDateStatusChanged,
} from '../@data/actions';
import {
  onAdCreativeAccountChanged,
  onAdCreativeBlockedMarketChanged,
  onAdCreativeBlockPanelChanged,
  onAdCreativeEndDateChanged,
  onAdCreativeExportClicked,
  onAdCreativeRefreshDataChanged,
  onAdCreativeSearchBoxValueChanged,
  onAdCreativeStartDateChanged,
  onAdQualityPanelApplyButtonClicked,
  onAdSourceChanged,
} from '../@data/actions/onAdCreativeChanged';
import {
  onActiveFilterValuesChanged,
  onAdCreativeInitialAdProvidersChanged,
  onAdCreativeInitialMarketsChanged,
  onClearAll,
  onDeleteTextCondition,
  onListValueChanged,
} from '../@data/mutatorActions';
import {
  blockDatatoAdPerfMapper,
  compareImages,
  extractPlacement,
  isAllImagesViewOnly,
  isImageBlockable,
  isImageBlocked,
  processImagesLink,
  samBlockSummaryToAdPerfMapper,
  toHttpsLink,
} from '../@data/services/utility/utility';
import { getInitialFilterList } from '../@data/store/adQualitySelectors';
import { IAdListingSample } from '../@data/store/schema';
import { AdPerformanceData, BlockAdsData, BlockItemType, SAMBlockSummaryData } from '../@data/store/schema/models';
import { AdPerformanceBlockData } from '../@data/store/schema/models/AdPerformanceBlockData';
import { getStore } from '../@data/store/store';
import { AdQualityFilters } from '../AdQualityFilters/AdQualityFilters';
import messages from './AdCreativePage.messages';
import { getStyles } from './AdCreativePage.styles';
import { IAdCreativePageStyleProps, IAdCreativePageStyles, IFilterPanelProps } from './AdCreativePage.types';
import noImage from './noImageAvailable.svg';

const getClassNames = classNamesFunction<IAdCreativePageStyleProps, IAdCreativePageStyles>();
const ROWS_PER_PAGE = 10000;
const MAX_ROW_HEIGHT = 200;
const DEFAULT_MARKET_ID = 1033;
const HEADLINE_ENABLE_DATE = new Date(
  window.location.href.includes('pubcenternext-staging') || window.location.href.includes('next.pubcenter') ? '2024-08-20' : '2024-08-02'
);

export const AdCreativePage = injectIntlWithObserver(
  class AdCreativePageComponent extends React.Component<IRouterWithIntl> {
    private _datagrid = React.createRef<IDataGrid>();
    private _classNames: IProcessedStyleSet<IAdCreativePageStyles>;
    private _columnCount: number;
    private _columnWidth: number;
    private _rowHeight: number;

    private _filterItems: IFilterItem[] = [
      { columnName: 'name', filterName: this.props.intl.formatMessage(messages.accountName), filterType: FilterType.string },
    ];

    public componentDidMount() {
      const publisherId = getAppStore().publisher!.id;
      onLayoutChanged(LayoutMode.Default);
      onAdCreativePageInitialized(publisherId);
    }

    public render(): JSX.Element {
      const { formatMessage } = this.props.intl;
      const theme = getTheme();
      this._classNames = getClassNames(getStyles, { theme });

      return (
        <Pivot
          aria-label={formatMessage(messages.adReview)}
          linkSize="large"
          styles={this._classNames.subComponentStyles.pivot}
          className={this._classNames.root}
          onLinkClick={(item) => {
            if (item && item.props.headerText === formatMessage(messages.adReview)) {
              onAdCreativeRefreshDataChanged();
            } else {
              if (getStore().active.selectedAdSource === AdSource.XandrAuction) {
                const val: IComboBoxOption = {
                  key: AdSource.Auction,
                  text: formatMessage(messages.auction),
                  ariaLabel: formatMessage(messages.auction),
                };
                onAdSourceChanged(val);
              }
            }
          }}
        >
          <PivotItem headerText={formatMessage(messages.adReview)} className={this._classNames.adReview} itemKey={'adCreative'}>
            {this._filterFields()}
            {this._adPerfDataGrid()}
            <Panel
              isOpen={getStore().panelIsOpen}
              type={PanelType.medium}
              onDismiss={() => {
                onAdCreativeBlockPanelChanged(false);
                onLayoutChanged(LayoutMode.Default);
              }}
              closeButtonAriaLabel={formatMessage(AppMessages.close)}
            >
              {getStore().panelItemType === BlockItemType.Ads && this._blockAds(FormState.InPageCreate)}
              {getStore().panelItemType === BlockItemType.Images && this._blockImages(FormState.InPageCreate)}
            </Panel>
          </PivotItem>
          <PivotItem headerText={formatMessage(messages.blockSummary)} itemKey={'blockSummary'} className={this._classNames.adReview}>
            {this._blockSummaryPageFields()}
            {getStore().active.selectedAdSource === AdSource.Auction && this._SAMBlockSummaryDataGrid()}
            {getStore().active.selectedAdSource === AdSource.Microsoft && this._blockSummaryDataGrid()}

            <Panel
              isOpen={getStore().panelIsOpen}
              type={PanelType.medium}
              onDismiss={() => {
                onAdCreativeBlockPanelChanged(false);
                onLayoutChanged(LayoutMode.Default);
              }}
              closeButtonAriaLabel={formatMessage(AppMessages.close)}
            >
              {getStore().panelItemType === BlockItemType.Images && this._blockImages(FormState.InPageEdit)}
              {getStore().panelItemType === BlockItemType.Ads && this._blockAds(FormState.InPageEdit)}
            </Panel>
          </PivotItem>
        </Pivot>
      );
    }

    private _blockSummaryPageFields = (): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();
      const appStore = getAppStore();
      const adSourceOptions: IDropdownOption[] = [
        {
          key: AdSource.Microsoft,
          text: formatMessage(messages.microsoft),
          ariaLabel: formatMessage(messages.microsoft),
        },
        {
          key: AdSource.Auction,
          text: formatMessage(messages.auction),
          ariaLabel: formatMessage(messages.auction),
        },
      ];
      return (
        <>
          <div className={this._classNames.adSourceField}>
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                placeholder={formatMessage(messages.adSourceCombo)}
                ariaLabel={formatMessage(messages.adSourceCombo)}
                label={formatMessage(messages.adSourceCombo)}
                options={adSourceOptions}
                required={true}
                onChange={(_, value) => {
                  onAdSourceChanged(value);
                }}
                selectedKey={store.active.selectedAdSource}
              />
            </FormItem>
          </div>
          <div className={this._classNames.filterFields}>
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                placeholder={formatMessage(messages.accountsCombo)}
                ariaLabel={formatMessage(messages.accountsCombo)}
                label={formatMessage(messages.accountsCombo)}
                options={this._createDropDown(store.active.accounts)}
                required={true}
                onChange={(_, value) => onAdCreativeAccountChanged(value)}
                selectedKey={store.active.selectedAccount}
                disabled={store.active.selectedAdSource ? store.active.selectedAdSource === AdSource.Auction : false}
                tabIndex={store.active.selectedAdSource && store.active.selectedAdSource === AdSource.Auction ? -1 : undefined}
              />
            </FormItem>
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <ComboBox
                useComboBoxAsMenuWidth={false}
                autoComplete="on"
                placeholder={formatMessage(messages.marketsCombo)}
                ariaLabel={formatMessage(messages.marketsCombo)}
                label={formatMessage(messages.marketsCombo)}
                options={this._createLocaleOptions(true)}
                required={true}
                selectedKey={store.active.selectedBlockedMarketId}
                onChange={(_, value) => onAdCreativeBlockedMarketChanged(value)}
                disabled={store.active.selectedAdSource ? store.active.selectedAdSource === AdSource.Microsoft : false}
                tabIndex={store.active.selectedAdSource && store.active.selectedAdSource === AdSource.Microsoft ? -1 : undefined}
              />
            </FormItem>
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <DatePicker
                strings={DayPickerStrings}
                allowTextInput={true}
                placeholder={formatMessage(messages.startDatePlaceHolder)}
                ariaLabel={formatMessage(messages.startDate)}
                label={formatMessage(messages.startDate)}
                value={store.active.blockSummaryStartDate}
                onSelectDate={(value) => onBlockSummaryStartDateChanged(value!)}
                maxDate={store.active.blockSummaryEndDate !== undefined ? store.active.blockSummaryEndDate : new Date(Date.now())}
              />
            </FormItem>
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <span
                className={this._classNames.selectedClose}
                role="button"
                tabIndex={0}
                onClick={() => onBlockSummaryStartDateChanged()}
                onKeyDown={(ev) => {
                  if (isButtonPressKey(ev.key)) {
                    onBlockSummaryStartDateChanged();
                  }
                }}
                aria-label={formatMessage(messages.clearStartDateSelAriaLabel)}
              >
                <Icon className={this._classNames.cancelIcon} iconName="Cancel" />
              </span>
            </FormItem>
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <DatePicker
                strings={DayPickerStrings}
                allowTextInput={true}
                placeholder={formatMessage(messages.endDatePlaceHolder)}
                ariaLabel={formatMessage(messages.endDatePlaceHolder)}
                maxDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                minDate={store.active.blockSummaryStartDate}
                label={formatMessage(messages.endDate)}
                value={store.active.blockSummaryEndDate}
                onSelectDate={(value) => onBlockSummaryEndDateChanged(value!)}
              />
            </FormItem>
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <span
                className={this._classNames.selectedClose}
                role="button"
                tabIndex={0}
                onClick={() => {
                  onBlockSummaryEndDateChanged();
                }}
                onKeyDown={(ev) => {
                  if (isButtonPressKey(ev.key)) {
                    onBlockSummaryEndDateChanged();
                  }
                }}
                aria-label={formatMessage(messages.clearEndDateSelAriaLabel)}
              >
                <Icon className={this._classNames.cancelIcon} iconName="Cancel" />
              </span>
            </FormItem>
            <PrimaryButton
              text={formatMessage(messages.applyFilters)}
              onClick={() => onRefreshBlockSummary()}
              disabled={appStore.loading}
              className={this._classNames.applyButton}
              ariaDescription={formatMessage(messages.applyFiltersAriaDesc)}
            />
          </div>
        </>
      );
    };

    private _renderAdProviderOptions = (providersToNames: Object): IIdNamePair[] => {
      const adSourceOptions: IDropdownOption[] = [];

      Object.keys(providersToNames).forEach((adProvider) => {
        // to exclude Bing/MSAN
        if (adProvider !== '483' && adProvider !== '548' && adProvider !== '4') {
          adSourceOptions!.push({
            key: parseInt(adProvider, 10),
            text: providersToNames[adProvider],
            ariaLabel: providersToNames[adProvider],
          });
        }
      });

      adSourceOptions.sort((a, b) => {
        const firstValue = a.text;
        const secondValue = b.text;

        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      });

      const adProviders: IIdNamePair[] = [...adSourceOptions].map((item) => ({
        id: parseInt(item.key.toString(), 10),
        name: item.text,
        isSelected: true,
      }));

      adSourceOptions.unshift({
        key: 0,
        text: 'All',
        ariaLabel: 'All',
      });

      return adProviders;
    };

    private _blockSummaryDataGrid = (): JSX.Element => {
      const store = getStore();
      const { formatMessage } = this.props.intl;

      const _gridDefaultColumns: IDataGridColumn[] = [
        { columnName: 'adTitle', friendlyName: 'Ad title', isVisible: true, headerCSV: 'Ad_Title', isExportColumn: true },
        { columnName: 'channelName', friendlyName: 'Channel', isVisible: true, headerCSV: 'Channel', isExportColumn: true },
        { columnName: 'accountName', friendlyName: 'Account', isVisible: true, headerCSV: 'Account', isExportColumn: false },
        { columnName: 'allImages', friendlyName: 'Ad images', isVisible: true, headerCSV: 'Ad_images', isExportColumn: false },
        { columnName: 'advertiserName', friendlyName: 'Advertiser', isVisible: true, headerCSV: 'Advertiser', isExportColumn: true },
        { columnName: 'adId', friendlyName: 'Ad id', isVisible: true, headerCSV: 'Ad_Id', isExportColumn: true },
        {
          columnName: 'reasonCategory',
          friendlyName: 'Reason category',
          isVisible: false,
          headerCSV: 'Reason_Category',
          isExportColumn: false,
        },
        {
          columnName: 'blockReasonMainCategory',
          friendlyName: 'Block category',
          isVisible: false,
          headerCSV: 'Block Category',
          isExportColumn: true,
        },
        {
          columnName: 'blockReasonSubCategory',
          friendlyName: 'Sub category',
          isVisible: false,
          headerCSV: 'Sub Category',
          isExportColumn: true,
        },
        {
          columnName: 'reason',
          friendlyName: 'Reason',
          isVisible: true,
          headerCSV: 'Reason',
          isExportColumn: true,
          exportFunction: CSVHelper.populateNull,
        },
        {
          columnName: 'blockRequestSource',
          friendlyName: 'Block request source',
          isVisible: true,
          headerCSV: 'Block request source',
          isExportColumn: true,
          exportFunction: CSVHelper.getBlockRequestSource,
        },
        {
          columnName: 'pastRevenue',
          friendlyName: 'Revenue impact',
          isVisible: true,
          headerCSV: 'Revenue_Impact_(7-day)',
          isExportColumn: true,
          exportFunction: CSVHelper.getRevenue,
        },
        {
          columnName: 'modifiedDateTime',
          friendlyName: 'Modified time (UTC)',
          isVisible: true,
          headerCSV: 'Modified_Date',
          isExportColumn: true,
          exportFunction: CSVHelper.extractDate,
        },
        { columnName: 'modifiedByName', friendlyName: 'Modified by', isVisible: true, headerCSV: 'Modified_By', isExportColumn: true },
        {
          columnName: 'isEnabled',
          friendlyName: 'Blocking status',
          isVisible: true,
          headerCSV: 'Blocking_status',
          isExportColumn: true,
          exportFunction: CSVHelper.getBlockingStatus,
        },
      ];

      const _gridImageDefaultColumns: IDataGridColumn[] = [
        { columnName: 'adId', friendlyName: 'Ad Id', isVisible: false, headerCSV: 'Ad_Id', isExportColumn: true },
        { columnName: 'adTitle', friendlyName: 'Ad title', isVisible: true, headerCSV: 'Ad_Title', isExportColumn: true },
        { columnName: 'image', friendlyName: 'Image Url', isVisible: false, headerCSV: 'Image_Url', isExportColumn: true },
        { columnName: 'adUnitId', friendlyName: 'Ad Unit Id', isVisible: false, headerCSV: 'Adunit_Id', isExportColumn: true },
        {
          columnName: 'blockReason',
          friendlyName: 'Reason',
          isVisible: true,
          headerCSV: 'Reason',
          isExportColumn: true,
          exportFunction: CSVHelper.populateNull,
        },
        {
          columnName: 'blockReasonComment',
          friendlyName: 'Reason Comment',
          isVisible: false,
          headerCSV: 'Reason_Comment',
          isExportColumn: true,
        },
        { columnName: 'reportedByName', friendlyName: 'Modified by', isVisible: true, headerCSV: 'Modified_By', isExportColumn: true },
        {
          columnName: 'modifiedDateTime',
          friendlyName: 'Modified time (UTC)',
          isVisible: true,
          headerCSV: 'Modified_Date',
          isExportColumn: true,
          exportFunction: CSVHelper.extractDate,
        },
      ];
      const _commandBarProps: IDataGridCommandBarProps = {
        items: [
          {
            key: 'ExportImageBlock',
            name: formatMessage(messages.exportImageBlocks),
            iconProps: {
              iconName: 'Export',
            },
            selectionModes: [DataGridSelectionMode.None, DataGridSelectionMode.Single, DataGridSelectionMode.Multiple],
            onClick: () => {
              onBlockedImagesExportClicked(_gridImageDefaultColumns);
            },
          },
          {
            key: 'ExportAdBlock',
            name: formatMessage(messages.exportGrid),
            iconProps: {
              iconName: 'Export',
            },
            selectionModes: [DataGridSelectionMode.None, DataGridSelectionMode.Single, DataGridSelectionMode.Multiple],
            onClick: () => {
              onBlockedAdsExportClicked(_gridDefaultColumns);
            },
          },
          {
            key: 'Activate',
            name: formatMessage(messages.enableBlocking),
            iconProps: {
              iconName: 'ActivateOrders',
            },
            selectionModes: [DataGridSelectionMode.Single],
            selectionStatus: LifeCycleStatus.Inactive,
            onClick: () => {
              const items = this._getBlockDataSelectionItems();
              if (items && items[0]) {
                onBlockAdsItemStatusChanged(items[0], LifeCycleStatus.Active);
              }
            },
          },
          {
            key: 'Deactivate',
            name: formatMessage(messages.disableBlocking),
            iconProps: {
              iconName: 'DeactivateOrders',
            },
            onClick: () => {
              const items = this._getBlockDataSelectionItems();
              if (items && items[0]) {
                onBlockAdsItemStatusChanged(items[0], LifeCycleStatus.Inactive);
              }
            },
            selectionModes: [DataGridSelectionMode.Single],
            selectionStatus: LifeCycleStatus.Active,
          },
          {
            key: 'Edit',
            name: formatMessage(messages.editBlockReason),
            iconProps: {
              iconName: 'Edit',
            },
            onClick: () => {
              const items = this._getBlockDataSelectionItems();

              if (items && items[0]) {
                const mappedItems = blockDatatoAdPerfMapper(items);
                onBlockAdInitialized(mappedItems);
              }

              onAdCreativeBlockPanelChanged(true, BlockItemType.Ads);
            },
            selectionModes: [DataGridSelectionMode.Single],
            selectionStatus: LifeCycleStatus.Active,
          },
        ],
      };

      return (
        <DataGrid
          componentRef={this._datagrid}
          intl={this.props.intl}
          commandBarProps={_commandBarProps}
          detailListProps={{
            items: store.active.blockSummaryData ? store.active.blockSummaryData : [],
            columns: _gridDefaultColumns,
            onRenderItemColumn: (data: BlockAdsData, index: number, column: IColumn) =>
              this._renderBlockSummaryItemColumn(data, index, column),
          }}
          sortByColumn={{
            columnKeyToSortBy: 'modifiedDateTime',
            isSortedDescending: true,
          }}
          ariaLabel={formatMessage(messages.blockSummaryMSGridAriaLabel)}
          // notReadyProps={
          //   !appStore.loading && store.active.blockSummaryData
          //     ? store.active.blockSummaryData.length === 0
          //       ? notReadyProps
          //       : undefined
          //     : undefined
          // }
        />
      );
    };

    private _SAMBlockSummaryDataGrid = (): JSX.Element => {
      const store = getStore();
      const { formatMessage } = this.props.intl;

      const _gridDefaultColumns: IDataGridColumn[] = [
        { columnName: 'adTitle', friendlyName: 'Ad title', headerCSV: 'Ad_Title', isVisible: true, isExportColumn: true },
        { columnName: 'adProvider', friendlyName: 'Ad provider', headerCSV: 'Ad_Provider', isVisible: true, isExportColumn: true },
        { columnName: 'advertiser', friendlyName: 'Advertiser', headerCSV: 'Advertiser', isVisible: true, isExportColumn: true },
        { columnName: 'allImages', friendlyName: 'Ad images', headerCSV: 'Ad_Images', isVisible: true, isExportColumn: false },
        { columnName: 'creativeAdId', friendlyName: 'Ad id', headerCSV: 'Ad_id', isVisible: true, isExportColumn: true },
        { columnName: 'blockReasonCategory', friendlyName: 'Reason', headerCSV: 'Reason_Category', isVisible: true, isExportColumn: false },
        {
          columnName: 'blockReasonMainCategory',
          friendlyName: 'Block category',
          isVisible: false,
          headerCSV: 'Block Category',
          isExportColumn: true,
        },
        {
          columnName: 'blockReasonSubCategory',
          friendlyName: 'Sub category',
          isVisible: false,
          headerCSV: 'Sub Category',
          isExportColumn: true,
        },
        {
          columnName: 'blockReasonComment',
          friendlyName: 'blockReasonComment',
          headerCSV: 'Reason',
          isVisible: false,
          isExportColumn: true,
          exportFunction: CSVHelper.populateNull,
        },
        {
          columnName: 'blockRequestSource',
          friendlyName: 'Block request source',
          headerCSV: 'Block request source',
          isVisible: true,
          isExportColumn: true,
          exportFunction: CSVHelper.getBlockRequestSource,
        },
        { columnName: 'locale', friendlyName: 'Blocked market', headerCSV: 'Blocked_Market', isVisible: true, isExportColumn: true },
        {
          columnName: 'modifiedDateTime',
          friendlyName: 'Modified time',
          headerCSV: 'Modified_Date',
          isVisible: true,
          isExportColumn: true,
          exportFunction: CSVHelper.extractDate,
        },
        { columnName: 'reportedByName', friendlyName: 'Modified by', headerCSV: 'Modified_By', isVisible: true, isExportColumn: true },
        {
          columnName: 'filterStatus',
          friendlyName: 'Blocking status',
          isVisible: true,
          headerCSV: 'Blocking_status',
          isExportColumn: true,
        },
      ];

      const _commandBarProps: IDataGridCommandBarProps = {
        items: [
          {
            key: 'ExportAdBlock',
            name: formatMessage(messages.exportGrid),
            iconProps: {
              iconName: 'Export',
            },
            selectionModes: [DataGridSelectionMode.None, DataGridSelectionMode.Single, DataGridSelectionMode.Multiple],
            onClick: () => {
              onBlockedAdsExportClicked(_gridDefaultColumns);
            },
          },
          {
            key: 'Activate',
            name: formatMessage(messages.enableBlocking),
            iconProps: {
              iconName: 'ActivateOrders',
            },
            selectionModes: [DataGridSelectionMode.Single],
            selectionStatus: LifeCycleStatus.Inactive,
            onClick: () => {
              const items = this._getSAMBlockDataSelectionItems();
              if (items && items[0]) {
                onSAMBlockAdsItemStatusChanged(items[0], LifeCycleStatus.Active);
              }
            },
          },
          {
            key: 'Deactivate',
            name: formatMessage(messages.disableBlocking),
            iconProps: {
              iconName: 'DeactivateOrders',
            },
            onClick: () => {
              const items = this._getSAMBlockDataSelectionItems();
              if (items && items[0]) {
                onSAMBlockAdsItemStatusChanged(items[0], LifeCycleStatus.Inactive);
              }
            },
            selectionModes: [DataGridSelectionMode.Single],
            selectionStatus: LifeCycleStatus.Active,
          },
          {
            key: 'Edit',
            name: formatMessage(messages.editBlockReason),
            iconProps: {
              iconName: 'Edit',
            },
            selectionModes: [DataGridSelectionMode.Single],
            selectionStatus: LifeCycleStatus.Active,
            onClick: () => {
              const items = this._getSAMBlockDataSelectionItems();

              if (items && items[0]) {
                const mappedItems = samBlockSummaryToAdPerfMapper(items);
                onBlockAdInitialized(mappedItems);
              }
              onAdCreativeBlockPanelChanged(true, BlockItemType.Ads);
            },
          },
        ],
      };

      return (
        <DataGrid
          componentRef={this._datagrid}
          intl={this.props.intl}
          commandBarProps={_commandBarProps}
          detailListProps={{
            items: store.active.samBlockSummaryData ? store.active.samBlockSummaryData : [],
            columns: _gridDefaultColumns,
            onRenderItemColumn: (item: SAMBlockSummaryData, index: number, column: IColumn) =>
              this._renderSAMBlockSummaryItemColumn(item, index, column),
          }}
          sortByColumn={{
            columnKeyToSortBy: 'modifiedDateTime',
            isSortedDescending: true,
          }}
          ariaLabel={formatMessage(messages.blockSummaryAuctionGridAriaLabel)}
        />
      );
    };

    private _filterFields = (): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();
      const appStore = getAppStore();
      const adSourceOptions: IDropdownOption[] = [
        {
          key: AdSource.Microsoft,
          text: formatMessage(messages.microsoft),
          ariaLabel: formatMessage(messages.microsoft),
        },
        {
          key: AdSource.Auction,
          text: formatMessage(messages.auction),
          ariaLabel: formatMessage(messages.auction),
        },
        {
          key: AdSource.XandrAuction,
          text: formatMessage(messages.xandrAuction),
          ariaLabel: formatMessage(messages.xandrAuction),
        },
      ];

      const auctionAdProviders = this._renderAdProviderOptions(adProvidersToNames);
      const xandrAuctionAdProviders = this._renderAdProviderOptions(xandrAdProvidersToNames);

      const auctionMarkets: IIdNamePair[] = [...this._createLocaleOptions()].map((item) => ({
        id: parseInt(item.key.toString(), 10),
        name: item.text,
        isSelected: item.key === DEFAULT_MARKET_ID,
      }));
      const last3Months = new Date();
      last3Months.setMonth(last3Months.getMonth() - 3);

      return (
        <>
          <div className={this._classNames.adSourceFields}>
            {
              // Ad Source
            }
            <FormItem styles={this._classNames.subComponentStyles.formItem}>
              <ComboBox
                useComboBoxAsMenuWidth={true}
                autoComplete="on"
                placeholder={formatMessage(messages.adSourceCombo)}
                ariaLabel={formatMessage(messages.adSourceCombo)}
                label={formatMessage(messages.adSourceCombo)}
                options={adSourceOptions}
                required={true}
                onChange={(_, value) => {
                  onAdSourceChanged(value);
                  if (value !== undefined && value.text === 'Auction') {
                    onAdCreativeInitialAdProvidersChanged(auctionAdProviders);
                  } else if (value !== undefined && value.text === 'Xandr Auction') {
                    onAdCreativeInitialAdProvidersChanged(xandrAuctionAdProviders);
                  }
                  onAdCreativeInitialMarketsChanged(auctionMarkets);
                  onClearAll();
                }}
                selectedKey={store.active.selectedAdSource}
              />
            </FormItem>
            {
              // Start Date
              store.active.selectedAdSource !== AdSource.XandrAuction && (
                <FormItem styles={this._classNames.subComponentStyles.formItem}>
                  <DatePicker
                    id={'startDatePicker'}
                    isRequired={true}
                    strings={DayPickerStrings}
                    allowTextInput={true}
                    placeholder={formatMessage(messages.startDatePlaceHolder)}
                    ariaLabel={formatMessage(messages.startDate)}
                    label={formatMessage(messages.startDate)}
                    value={store.active.startDate}
                    onSelectDate={(value) => onAdCreativeStartDateChanged(value!)}
                    maxDate={store.active.endDate}
                    minDate={last3Months}
                  />
                </FormItem>
              )
            }
            {
              // End date
              store.active.selectedAdSource !== AdSource.XandrAuction && (
                <FormItem styles={this._classNames.subComponentStyles.formItem}>
                  <DatePicker
                    id={'endDatePicker'}
                    isRequired={true}
                    strings={DayPickerStrings}
                    allowTextInput={true}
                    placeholder={formatMessage(messages.endDatePlaceHolder)}
                    ariaLabel={formatMessage(messages.endDatePlaceHolder)}
                    maxDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
                    minDate={store.active.startDate}
                    label={formatMessage(messages.endDate)}
                    value={store.active.endDate}
                    onSelectDate={(value) => onAdCreativeEndDateChanged(value!)}
                  />
                </FormItem>
              )
            }
            {
              // SearchBox
            }
            <div className={this._classNames.searchBoxItem}>
              <SearchBox
                styles={this._classNames.subComponentStyles.searchBox}
                placeholder={'Search'}
                defaultValue={store.active.searchBoxValue}
                onChanged={(value: string) => {
                  onAdCreativeSearchBoxValueChanged(value);
                }}
                onSearch={(value: string) => {
                  onAdCreativeSearchBoxValueChanged(value);
                  onAdCreativeRefreshDataChanged();
                }}
              />

              {store.active.selectedAdSource === AdSource.Microsoft && (
                <Checkbox
                  checked={store.adDescription}
                  className={this._classNames.descriptionCheckbox}
                  onChange={(_, value) => onAdDescriptionChanged(!!value)}
                  label={formatMessage(messages.adDescriptionCheckboxLabel)}
                  boxSide="end"
                />
              )}
            </div>

            {
              // Apply
            }
            <PrimaryButton
              text={formatMessage(messages.applyFilters)}
              onClick={() => {
                onAdCreativeRefreshDataChanged();
              }}
              disabled={appStore.loading}
              className={this._classNames.applyButton}
              ariaDescription={formatMessage(messages.applyFiltersAriaDesc)}
            />

            {this._renderFilterPanel(store.filterPanel)}

            {store.teachingBubbleEndDate ? (
              <TeachingBubble
                target={'#endDatePicker'}
                calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
                hasSmallHeadline={true}
                hasCloseIcon={true}
                closeButtonAriaLabel={formatMessage(AppMessages.close)}
                onDismiss={() => {
                  onTeachingBubbleEndDateStatusChanged(false);
                }}
                headline={formatMessage(messages.queryTimeoutWarningHeaderLabel)}
              >
                {formatMessage(messages.queryTimeoutWarningDescriptionLabel)}
              </TeachingBubble>
            ) : null}

            {store.teachingBubbleStartDate ? (
              <TeachingBubble
                target={'#startDatePicker'}
                calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
                hasSmallHeadline={true}
                hasCloseIcon={true}
                closeButtonAriaLabel={formatMessage(AppMessages.close)}
                onDismiss={() => {
                  onTeachingBubbleStartDateStatusChanged(false);
                }}
                headline={formatMessage(messages.queryTimeoutWarningHeaderLabel)}
              >
                {formatMessage(messages.queryTimeoutWarningDescriptionLabel)}
              </TeachingBubble>
            ) : null}
          </div>
        </>
      );
    };

    private _adPerfDataGrid = (): JSX.Element => {
      const store = getStore();
      const selectedAdSource = store.active.selectedAdSource;
      const { formatMessage } = this.props.intl;
      const _gridDefaultColumns: IDataGridColumn[] = [
        {
          columnName: 'status',
          friendlyName: 'Status',
          isVisible: selectedAdSource === AdSource.Auction || selectedAdSource === AdSource.XandrAuction,
          headerCSV: 'Status',
          isExportColumn: selectedAdSource === AdSource.Auction || selectedAdSource === AdSource.XandrAuction,
          exportFunction: CSVHelper.getStatus,
        },
        {
          columnName: 'adSource',
          friendlyName: 'Ad provider',
          isVisible: true,
          headerCSV: 'Ad_Provider',
          isExportColumn: selectedAdSource === AdSource.Microsoft,
          exportFunction: () => '"Microsoft"',
        },
        {
          columnName: 'adProvider',
          friendlyName: 'Ad provider',
          isVisible: false,
          headerCSV: 'Ad_Provider',
          isExportColumn: selectedAdSource === AdSource.Auction || selectedAdSource === AdSource.XandrAuction,
          exportFunction: selectedAdSource === AdSource.Auction ? CSVHelper.getAdProvider : CSVHelper.getXandrAdProvider,
        },
        {
          columnName: 'adId',
          friendlyName: 'Ad id',
          isVisible: selectedAdSource === AdSource.Microsoft,
          headerCSV: 'Ad_Id',
          isExportColumn: selectedAdSource === AdSource.Microsoft,
        },
        {
          columnName: 'adDomain',
          friendlyName: 'Ad domain',
          isVisible: false,
          headerCSV: 'Ad_Domain',
          isExportColumn: false,
        },
        {
          columnName: 'uniqueCreativeAdId',
          friendlyName: 'Creative ad id',
          isVisible: selectedAdSource === AdSource.Auction || selectedAdSource === AdSource.XandrAuction,
          headerCSV: 'Creative_Ad_Id',
          isExportColumn: selectedAdSource === AdSource.Auction || selectedAdSource === AdSource.XandrAuction,
        },
        { columnName: 'advertiserName', friendlyName: 'Advertiser', isVisible: true, headerCSV: 'Advertiser', isExportColumn: true },
        {
          columnName: 'advertiserId',
          friendlyName: 'Advertiser id',
          isVisible: selectedAdSource === AdSource.Microsoft,
          headerCSV: 'Advertiser_customer_id',
          isExportColumn: selectedAdSource === AdSource.Microsoft,
        },
        {
          columnName: 'advertiserAccountId',
          friendlyName: 'Advertiser account id',
          isVisible: selectedAdSource === AdSource.Microsoft,
          headerCSV: 'Advertiser_account_id',
          isExportColumn: selectedAdSource === AdSource.Microsoft,
        },
        {
          columnName: 'accountName',
          friendlyName: 'Account name',
          isVisible: selectedAdSource === AdSource.Microsoft && store.active.selectedAccount === -1,
        },
        { columnName: 'adTitle', friendlyName: 'Ad title', isVisible: true, headerCSV: 'Ad_Title', isExportColumn: true },
        {
          columnName: 'adURL',
          friendlyName: 'Ad URL',
          isVisible: selectedAdSource === AdSource.Microsoft || selectedAdSource === AdSource.XandrAuction,
          headerCSV: 'Ad_Url',
          isExportColumn: selectedAdSource === AdSource.Microsoft || selectedAdSource === AdSource.XandrAuction,
        },
        {
          columnName: 'longHeadline',
          friendlyName: 'Long Headline',
          isVisible: selectedAdSource === AdSource.Microsoft && !!(store.active.startDate && store.active.startDate > HEADLINE_ENABLE_DATE),
          headerCSV: 'Long Headline',
          isExportColumn:
            selectedAdSource === AdSource.Microsoft && !!(store.active.startDate && store.active.startDate > HEADLINE_ENABLE_DATE),
        },
        {
          columnName: 'shortHeadline',
          friendlyName: 'Short Headline',
          isVisible: selectedAdSource === AdSource.Microsoft && !!(store.active.startDate && store.active.startDate > HEADLINE_ENABLE_DATE),
          headerCSV: 'Short Headline',
          isExportColumn:
            selectedAdSource === AdSource.Microsoft && !!(store.active.startDate && store.active.startDate > HEADLINE_ENABLE_DATE),
        },
        {
          columnName: 'adDestUrl',
          friendlyName: 'Ad landing page',
          isVisible: false,
          headerCSV: 'Ad_Landing_Page',
          isExportColumn: selectedAdSource === AdSource.Microsoft,
        },
        { columnName: 'images', friendlyName: 'Ad images', isVisible: true },
        {
          columnName: 'adUnitName',
          friendlyName: 'Placement',
          isVisible: selectedAdSource === AdSource.Microsoft || selectedAdSource === AdSource.Auction,
          headerCSV: selectedAdSource === AdSource.Microsoft ? 'Ad_Unit' : 'Placement',
          isExportColumn: selectedAdSource === AdSource.Microsoft || selectedAdSource === AdSource.Auction,
          exportFunction: selectedAdSource === AdSource.Microsoft ? undefined : CSVHelper.getPlacement,
        },
        {
          columnName: 'adImpressions',
          friendlyName: 'Impressions',
          isVisible: selectedAdSource !== AdSource.XandrAuction,
          headerCSV: 'Impressions',
          isExportColumn: selectedAdSource !== AdSource.XandrAuction,
        },
        {
          columnName: 'revenue',
          friendlyName: 'Revenue',
          isVisible: false,
          headerCSV: 'Revenue',
          isExportColumn: selectedAdSource === AdSource.Microsoft,
          exportFunction: CSVHelper.getRevenue,
        },
      ];

      const _commandBarProps: IDataGridCommandBarProps = {
        items: [
          {
            id: 'addFilterButton',
            key: CommandBarActions.addFilter,
            name: formatMessage(AppMessages.addFilter),
            iconProps: {
              iconName: 'Filter',
            },
            onClick: () => {
              const filterPanel: IFilterPanelProps = {
                titleLabel: formatMessage(AppMessages.filters),
                descriptionLabel: formatMessage(AppMessages.filtersDescription),
              };
              onActiveFilterValuesChanged(getStore().filters.raw!);
              onFilterPanelShown(filterPanel);
            },
            selectionModes: [DataGridSelectionMode.None, DataGridSelectionMode.Single, DataGridSelectionMode.Multiple],
          },
          {
            key: 'ExportAdPerf',
            name: formatMessage(AppMessages.download),
            iconProps: {
              iconName: 'Download',
            },
            selectionModes: [DataGridSelectionMode.None, DataGridSelectionMode.Single, DataGridSelectionMode.Multiple],
            onClick: () => {
              onAdCreativeExportClicked(_gridDefaultColumns);
            },
            ariaLabel: formatMessage(messages.exportGrid),
          },
          {
            key: 'blockAds',
            name: formatMessage(messages.blockAdsMenuButtonLabel),
            iconProps: {
              iconName: 'StatusCircleBlock',
            },
            selectionStatus: selectedAdSource === AdSource.Auction ? LifeCycleStatus.Active : undefined,
            selectionModes: [DataGridSelectionMode.Single, DataGridSelectionMode.Multiple],
            onClick: () => {
              const items = this._getSelectionItems();
              if (items && items.length > 30) {
                onNotificationChanged({
                  text: messages.blockErrorMessage.defaultMessage,
                  messageBarType: MessageBarType.severeWarning,
                });
              } else {
                onBlockAdsAdPerfDataCleared();
                if (items) {
                  onBlockAdInitialized(items);
                }
                onAdCreativeBlockPanelChanged(true, BlockItemType.Ads);
              }
            },
          },
        ],
      };
      if (selectedAdSource === AdSource.Microsoft) {
        _commandBarProps.items.push({
          key: 'blockImages',
          name: formatMessage(messages.blockImagesMenuButtonLabel),
          iconProps: {
            iconName: 'StatusCircleBlock',
          },

          selectionModes: [DataGridSelectionMode.Single],
          onClick: () => {
            const items = this._getSelectionItems();

            if (items) {
              onBlockAdInitialized(items, true);
            }

            onAdCreativeBlockPanelChanged(true, BlockItemType.Images);
          },
        });
      }
      return (
        <>
          <DataGrid
            componentRef={this._datagrid}
            intl={this.props.intl}
            commandBarProps={_commandBarProps}
            detailListProps={{
              items: store.active.adPerfData ? store.active.adPerfData : [],
              columns: _gridDefaultColumns,
              onRenderItemColumn: (item: AdPerformanceData, index: number, column: IColumn) =>
                this._renderAdCreativeItemColumn(item, index, column),
            }}
            // filterProps={_filterProps}
            sortByColumn={{
              columnKeyToSortBy: 'adImpressions',
              isSortedDescending: true,
            }}
            ariaLabel={formatMessage(messages.adReviewGridAriaLabel)}
            dataGridfilterProps={{
              filterItems: this._filterItems,
              onFilterMenuCalloutClose: function (): void {
                throw new Error('Function not implemented.');
              },
              onClearAll: () => onClearAll(),
              onDeleteTextCondition: (heading: string, filterCondition: FilterCondition, val: string) =>
                onDeleteTextCondition(heading, filterCondition, val),
              onListValueChanged: (heading: string, val: string) => onListValueChanged(heading, val),
              initialFilterListValues: getInitialFilterList(),
              showClearAll: true,
              hideCallout: true,
            }}
            searchResultsVisible={true}
          />
        </>
      );
    };

    private _renderFilterPanel = (filterPanel?: IFilterPanelProps): JSX.Element | null => {
      if (!filterPanel) {
        return null;
      }
      const classNames = getClassNames(getStyles, { theme: getTheme() });
      const store = getStore();
      const appStore = getAppStore();
      const selectedAdSource = store.active.selectedAdSource;

      return (
        <Panel
          isOpen={true}
          type={PanelType.smallFixedFar}
          isLightDismiss
          onDismiss={() => onFilterPanelHide()}
          headerText={filterPanel.titleLabel}
          closeButtonAriaLabel={this.props.intl.formatMessage(AppMessages.panelCloseButtonAriaDesc)}
          styles={classNames.subComponentStyles.filterPanel}
          isFooterAtBottom={true}
          onRenderFooterContent={() => {
            return (
              <div className={classNames.panelButton}>
                <PrimaryButton
                  onClick={() => {
                    onAdQualityPanelApplyButtonClicked();
                  }}
                  text={this.props.intl.formatMessage(AppMessages.updateFilters)}
                  ariaDescription={this.props.intl.formatMessage(messages.filterPanelApplyButtonAriaDesc)}
                  disabled={appStore.loading}
                />
                <DefaultButton onClick={() => onFilterPanelHide()} text={this.props.intl.formatMessage(AppMessages.cancel)} />
              </div>
            );
          }}
        >
          <AdQualityFilters
            adSource={selectedAdSource}
            listFilters={store.active.accounts}
            filtersItems={store.filters}
            shouldShowHeadlines={
              selectedAdSource === AdSource.Microsoft && !!(store.active.startDate && store.active.startDate > HEADLINE_ENABLE_DATE)
            }
          />
        </Panel>
      );
    };

    private _blockImages = (mode: FormState): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();
      const reasonCategory: IDropdownOption[] = [
        {
          key: formatMessage(messages.imageNotRelevant),
          text: formatMessage(messages.imageNotRelevant),
          ariaLabel: formatMessage(messages.imageNotRelevant),
        },
        {
          key: formatMessage(messages.lowQualityImage),
          text: formatMessage(messages.lowQualityImage),
          ariaLabel: formatMessage(messages.lowQualityImage),
        },
        {
          key: formatMessage(messages.offensiveImage),
          text: formatMessage(messages.offensiveImage),
          ariaLabel: formatMessage(messages.offensiveImage),
        },
        {
          key: formatMessage(messages.policyIssue),
          text: formatMessage(messages.policyIssue),
          ariaLabel: formatMessage(messages.policyIssue),
        },
      ];
      const isViewOnly = isAllImagesViewOnly(
        store.blockAdsPage.adPerfBlockAdsData[0].images ? store.blockAdsPage.adPerfBlockAdsData[0].images : undefined,
        store.blockAdsPage.adPerfBlockAdsData[0].adType,
        store.blockAdsPage.adPerfBlockAdsData[0].adSource
      );
      return (
        <DetailPage
          titleLabel={
            mode === FormState.InPageCreate && !isViewOnly ? formatMessage(messages.blockImages) : formatMessage(messages.viewImages)
          }
          helpId={'pop_PCv4_blockAd'}
          submitLabel={
            mode === FormState.InPageCreate && !isViewOnly ? formatMessage(messages.blockImages) : formatMessage(messages.viewImages)
          }
          onSubmit={() => {
            onBlockImagesSubmitted();
          }}
          onCancel={() => {
            onAdCreativeBlockPanelChanged(false);
            onLayoutChanged(LayoutMode.Default);
          }}
          saving={getAppStore().saving}
          pageMode={mode}
          hideSubmitButton={mode !== FormState.InPageCreate || isViewOnly}
        >
          {isViewOnly && <div>{formatMessage(messages.viewImagesDescription)}</div>}
          <FocusZone>
            <List
              className={this._classNames.imageList}
              items={store.blockAdsPage.adPerfBlockAdsData[0].images ? store.blockAdsPage.adPerfBlockAdsData[0].images : [noImage]}
              renderedWindowsAhead={4}
              getItemCountForPage={this._getItemCountForPage}
              getPageHeight={this._getPageHeight}
              onRenderCell={this._onRenderImage}
            />
          </FocusZone>
          {!isViewOnly && (
            <>
              <FormItem>
                <Dropdown
                  label={formatMessage(messages.blockReason)}
                  ariaLabel={formatMessage(messages.blockReason)}
                  options={reasonCategory}
                  required={true}
                  errorMessage={store.errors.blockReason}
                  onChange={(_, value) => onBlockAdsBlockReasonChanged(value!)}
                />
              </FormItem>
              <FormItem formWidth={440}>
                <TextField
                  multiline
                  autoAdjustHeight
                  label={formatMessage(messages.additionalDetials)}
                  ariaLabel={formatMessage(messages.additionalDetials)}
                  errorMessage={store.errors.blockDetails || store.errors.blockedImages}
                  onChange={(_, value) => onBlockAdsBlockDetailsChanged(value)}
                />
              </FormItem>
            </>
          )}
        </DetailPage>
      );
    };

    private _getCurrentlySelectedListingPageItem(item: AdPerformanceBlockData): IAdListingSample {
      // Make sure the listing data exists for the requested page. Return empty if it does not exist.
      if (item.adListingsData && item.adListingsData.adListingItems) {
        const listingSample =
          item.adListingsData.adListingItems[
            (item.adListingsData.adListingSelectedPage ? item.adListingsData.adListingSelectedPage : 1) - 1
          ];
        return listingSample ? listingSample : {};
      }
      return {};
    }

    private _blockAds = (mode: FormState): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getStore();

      const reasonsCategoriesL1: IDropdownOption[] = [
        {
          key: formatMessage(messages.disallowedContent),
          text: formatMessage(messages.disallowedContent),
          ariaLabel: formatMessage(messages.disallowedContent),
        },
        {
          key: formatMessage(messages.legalPrivacyAndSecurity),
          text: formatMessage(messages.legalPrivacyAndSecurity),
          ariaLabel: formatMessage(messages.legalPrivacyAndSecurity),
        },
        {
          key: formatMessage(messages.mediaFormats),
          text: formatMessage(messages.mediaFormats),
          ariaLabel: formatMessage(messages.mediaFormats),
        },
        {
          key: formatMessage(messages.relevanceAndQuality),
          text: formatMessage(messages.relevanceAndQuality),
          ariaLabel: formatMessage(messages.relevanceAndQuality),
        },
        {
          key: formatMessage(messages.restrictedCategories),
          text: formatMessage(messages.restrictedCategories),
          ariaLabel: formatMessage(messages.restrictedCategories),
        },
        {
          key: formatMessage(messages.textGuidelines),
          text: formatMessage(messages.textGuidelines),
          ariaLabel: formatMessage(messages.textGuidelines),
        },
      ];

      let isGeminiAd = false;
      isGeminiAd = store.blockAdsPage.adPerfBlockAdsData.some((item) => item.adProvider === 'Gemini');

      const adDestUrls: string[] = [];
      store.blockAdsPage.adPerfBlockAdsData.forEach((item) => {
        adDestUrls.push(item.adDestUrl ? item.adDestUrl : item.adURL ? toHttpsLink(item.adURL) : '');
      });

      return (
        <DetailPage
          titleLabel={formatMessage(messages.blockAds)}
          helpId={'pop_PCv4_blockAd'}
          submitLabel={formatMessage(messages.blockAds)}
          onSubmit={() => {
            onBlockAdsSubmitted();
          }}
          onCancel={() => {
            onAdCreativeBlockPanelChanged(false);
            onLayoutChanged(LayoutMode.Default);
          }}
          saving={getAppStore().saving}
          pageMode={mode}
        >
          <FormItem styles={this._classNames.subComponentStyles.formItem}>
            <Label>{formatMessage(messages.AdId, { count: store.blockAdsPage.adPerfBlockAdsData.length })}</Label>
            {store.blockAdsPage.adPerfBlockAdsData.map((item, index) => (
              <FormSection
                key={index}
                expanded={true}
                title={formatMessage(
                  messages.auctionBlockAdIdLabel,
                  item.uniqueCreativeAdId ? { uniqueCreativeAdId: item.uniqueCreativeAdId } : { uniqueCreativeAdId: item.adId }
                )}
                styles={this._classNames.subComponentStyles.formSection}
              >
                <Label>{formatMessage(messages.blockAdTitle)}</Label>
                {item.adTitle ? item.adTitle : ''}

                {store.active.selectedAdSource === AdSource.Auction && (
                  <FormItem formWidth={440}>
                    <Label>{formatMessage(messages.auctionAdSourceCombo)}</Label>
                    {item.adProvider}
                  </FormItem>
                )}
                {store.active.selectedAdSource === AdSource.Microsoft && (
                  <FormItem formWidth={440}>
                    <Label id="blockadUrlLabel">{formatMessage(messages.blockAdURL)}</Label>
                    <Link href={adDestUrls[index]} target="_blank" aria-labelledby="blockadUrlLabel">
                      {item.adURL ? item.adURL : ''}
                    </Link>
                    <Label id="adAccountName">{formatMessage(messages.accountName)}</Label>
                    {item.accountName ? item.accountName : ''}
                  </FormItem>
                )}
                {store.active.selectedAdSource === AdSource.Microsoft && (
                  <FormItem>
                    <Label>{formatMessage(messages.pastRevenue)}</Label>
                    <div>{'$' + (item.revenue ? item.revenue.toFixed(2) : '0.0')}</div>
                  </FormItem>
                )}

                {store.active.selectedAdSource === AdSource.Microsoft && (
                  <FormItem formWidth={440}>
                    <div className={this._classNames.adBlockPanelListingBox}>
                      {item.adListingsData && item.adListingsData.adListingItems && item.adListingsData.adListingItems.length > 1 && (
                        <div>
                          <div className={this._classNames.adBlockPanelListingPaginatedBox}>
                            <div className={this._classNames.adBlockPanelListingPaginatedLeftItems}>
                              <Label>{formatMessage(messages.altAdTitleListings)}</Label>
                              <div className={this._classNames.adBlockPanelListingLightText}>
                                {this._getCurrentlySelectedListingPageItem(item).adTitle}
                              </div>
                              <Label>{formatMessage(messages.altAdUrlListings)}</Label>
                              <Link
                                className={this._classNames.adBlockPanelListingLightText}
                                href={toHttpsLink(this._getCurrentlySelectedListingPageItem(item).adDestUrl as string)}
                              >
                                {this._getCurrentlySelectedListingPageItem(item).adUrl}
                              </Link>
                            </div>
                            <div className={this._classNames.adBlockPanelListingPaginatedRightItems}>
                              <Label>{formatMessage(messages.altAdImageListings)}</Label>
                              <img
                                src={this._getCurrentlySelectedListingPageItem(item).adImage}
                                width={90}
                                height={90}
                                alt={noImage}
                                style={{ margin: '2px' }}
                              />
                            </div>
                          </div>
                          <Pagination
                            intl={this.props.intl}
                            currentPageNumber={item.adListingsData ? item.adListingsData.adListingSelectedPage : 1}
                            totalPageCount={
                              item.adListingsData && item.adListingsData.adListingItems ? item.adListingsData.adListingItems.length : 1
                            }
                            onPageChange={(page) => {
                              onAdListingPageSelection(page, index);
                            }}
                          />
                        </div>
                      )}
                      {!item.adListingsData && (
                        <div className={this._classNames.adBlockPanelListingLoadingBox}>
                          <Label className={this._classNames.adBlockPanelListingCenterLightText}>
                            {formatMessage(messages.loadingAltAdCreativeListings)}
                          </Label>
                          <Spinner />
                        </div>
                      )}
                      {item.adListingsData &&
                        !(item.adListingsData && item.adListingsData.adListingItems && item.adListingsData.adListingItems.length > 1) && (
                          <div className={this._classNames.adBlockPanelListingNoneBox}>
                            <Label className={this._classNames.adBlockPanelListingLightText}>
                              {formatMessage(messages.noAltAdCreativeListings)}
                            </Label>
                          </div>
                        )}
                    </div>
                  </FormItem>
                )}
              </FormSection>
            ))}
          </FormItem>
          <FormItem>
            <Dropdown
              dropdownWidth={500}
              label={formatMessage(messages.blockRequestSource)}
              ariaLabel={formatMessage(messages.blockRequestSource)}
              options={this._createBlockRequestSourcesDropdown(getStore().active.blockRequestSourceData!)}
              onChange={(_, value) => {
                onBlockAdsSelectedBlockRequestSourceChanged(value!);
              }}
              required={true}
              errorMessage={store.errors.selectedMarket}
            />
          </FormItem>
          {store.active.selectedAdSource !== AdSource.Microsoft && (
            <FormItem>
              <Dropdown
                dropdownWidth={500}
                label={formatMessage(messages.selectMarket)}
                ariaLabel={formatMessage(messages.selectMarket)}
                options={this._createBlockingMarketsDropdown(store.active.selectedMarket!)}
                onChange={(_, value) => onBlockAdsSelectedMarketChanged(value!)}
                required={true}
                errorMessage={store.errors.selectedMarket}
              />
            </FormItem>
          )}
          {store.active.selectedAdSource === AdSource.Microsoft && (
            <FormItem>
              <Dropdown
                dropdownWidth={500}
                label={formatMessage(messages.selectChannel)}
                ariaLabel={formatMessage(messages.selectChannel)}
                options={[{ key: 'Default', text: 'Default', ariaLabel: 'Default' }]}
                required={true}
                disabled={true}
                placeholder={'Default'}
                errorMessage={store.errors.selectChannel}
              />
            </FormItem>
          )}
          <FormItem>
            <Dropdown
              dropdownWidth={300}
              label={formatMessage(messages.blockReasonCategory)}
              ariaLabel={formatMessage(messages.blockReasonCategory)}
              options={reasonsCategoriesL1}
              required={true}
              errorMessage={store.errors.blockReason}
              onChange={(_, value, index) => onBlockAdsReasonLevelsChanged(value!, formatMessage, index!)}
            />
          </FormItem>
          {store.blockAdsPage.blockReasonL1 && (
            <FormItem>
              <Dropdown
                dropdownWidth={300}
                label={formatMessage(messages.blockReason)}
                ariaLabel={formatMessage(messages.blockReason)}
                options={store.blockAdsPage.blockReasonsL2 ? store.blockAdsPage.blockReasonsL2 : []}
                required={true}
                defaultSelectedKey={0}
                errorMessage={store.errors.blockReason}
                onChange={(_, value) => onBlockAdsBlockReasonChanged(value!)}
              />
            </FormItem>
          )}
          <FormItem formWidth={440}>
            <TextField
              multiline
              autoAdjustHeight
              label={formatMessage(messages.additionalDetials)}
              ariaLabel={formatMessage(messages.additionalDetials)}
              onChange={(_, value) => onBlockAdsBlockDetailsChanged(value)}
            />
          </FormItem>
          {/* TODO: remove gemini-specific messaging once SAMRTB begins honoring adblocks for Gemini */}
          {isGeminiAd && (
            <Label>
              <div style={{ color: 'red' }}>{formatMessage(messages.verizonMediaWarningLabel)}</div>
            </Label>
          )}
        </DetailPage>
      );
    };

    private _getSelectionItems() {
      if (this._datagrid.current) {
        const currentSelection = this._datagrid.current.getSelection();
        const selectedIndices = currentSelection.getSelectedIndices();
        const items = currentSelection.getItems();
        const selectedItems: AdPerformanceData[] = [];
        const sameAds = new Set();
        selectedIndices.map((selectedIndex) => {
          const adData = items[selectedIndex] as AdPerformanceData;

          if (
            adData.adId &&
            adData.accountId &&
            !sameAds.has(adData.adId.toString() + NO_VALUE_PLACEHOLDER + adData.accountId.toString())
          ) {
            selectedItems.push(items[selectedIndex] as AdPerformanceData);
            sameAds.add(adData.adId.toString() + NO_VALUE_PLACEHOLDER + adData.accountId.toString());
          } else if (!adData.adId && adData.uniqueCreativeAdId) {
            selectedItems.push(items[selectedIndex] as AdPerformanceData);
          }
          return undefined;
        });
        return selectedItems;
      }
      return null;
    }

    private _getBlockDataSelectionItems() {
      if (this._datagrid.current) {
        const currentSelection = this._datagrid.current.getSelection();
        const selectedIndices = currentSelection.getSelectedIndices();
        const items = currentSelection.getItems();

        const selectedItems: BlockAdsData[] = [];
        selectedIndices.map((selectedIndex) => selectedItems.push(items[selectedIndex] as BlockAdsData));

        return selectedItems;
      }
      return null;
    }

    private _getSAMBlockDataSelectionItems() {
      if (this._datagrid.current) {
        const currentSelection = this._datagrid.current.getSelection();
        const selectedIndices = currentSelection.getSelectedIndices();
        const items = currentSelection.getItems();

        const selectedItems: SAMBlockSummaryData[] = [];
        selectedIndices.map((selectedIndex) => selectedItems.push(items[selectedIndex] as SAMBlockSummaryData));

        return selectedItems;
      }
      return null;
    }

    private _renderSAMBlockSummaryItemColumn(item: SAMBlockSummaryData, index: number, column: IColumn): JSX.Element {
      // tslint:disable-next-line: no-string-literal
      const imageList = item['allAdImages'] ? item['allAdImages'] : [];
      const imageElements: JSX.Element[] = [];
      for (let i = 0; i < Math.min(imageList.length, 2); i++) {
        if (imageList[i] !== '') {
          imageElements.push(
            <a href={imageList[i]} target="_blank" rel="noopener noreferrer">
              <img key={i} src={imageList[i]} width={90} height={90} alt={'Not available'} style={{ margin: '2px' }} />
            </a>
          );
        }
      }
      imageElements.push(
        <Link
          onClick={async () => {
            if (item.creativeAdId) {
              onSAMBlockSummaryViewImagesClicked(item);
            }
          }}
        >
          {this.props.intl.formatMessage(messages.allImages)}
        </Link>
      );

      // tslint:disable-next-line: no-string-literal
      const reasonDetails: string = item['blockReasonComment'] ? item['blockReasonComment'] : 'null';
      const fieldContent = item[column.fieldName as keyof SAMBlockSummaryData];

      switch (column.key) {
        case 'blockReasonCategory':
          return (
            <>
              <div>
                <b>Block Category: </b>
                {item.blockReasonMainCategory}
              </div>
              <div>
                <b>Sub Category: </b>
                {item.blockReasonSubCategory}
              </div>
              <br />
              <div>
                <b>Details: </b>
                {reasonDetails}
              </div>
            </>
          );
        case 'blockRequestSource':
          return (
            <span>
              {item.blockRequestSourceId! === 0 ? 'N/A' : getStore().active.blockRequestSourceData?.get(item.blockRequestSourceId!)}
            </span>
          );
        case 'sevenDayRevenueImpact':
          return <span>{'$' + (fieldContent as number).toFixed(2)}</span>;
        case 'modifiedDateTime':
          return <span>{(fieldContent as string).substring(0, 10)}</span>;
        case 'allImages':
          return <span key={index}>{imageElements}</span>;

        default:
          return <span>{fieldContent}</span>;
      }
    }

    private _renderBlockSummaryItemColumn(item: BlockAdsData, index: number, column: IColumn): JSX.Element {
      // tslint:disable-next-line: no-string-literal
      const imgs: string = item['allImages'] ? item['allImages'] : '';
      const imageList = imgs.split('___');
      const imageElements: JSX.Element[] = [];
      for (let i = 0; i < imageList.length; i++) {
        if (i < 2) {
          if (imageList[i] !== '') {
            imageElements.push(
              <a href={imageList[i]} target="_blank" rel="noopener noreferrer">
                <img key={i} src={imageList[i]} width={90} height={90} alt={'Not available'} style={{ margin: '2px' }} />
              </a>
            );
          }
        } else {
          imageElements.push(
            <Link
              key={i}
              onClick={() => {
                onAdCreativeBlockPanelChanged(true, BlockItemType.Images);
                onBlockImageAdsChanged([{ apiPath: item.apiPath, className: item.className, blockedImages: [], adId: item.adId }]);
                onBlockAdsImagesChanged(imageList, 0, false);
              }}
            >
              {this.props.intl.formatMessage(messages.moreImages, { imageListLength: imageList.length - i })}
            </Link>
          );
          break;
        }
      }

      // tslint:disable-next-line: no-string-literal
      const reasonDetails: string = item['reason'] ? item['reason'] : 'null';

      // tslint:disable-next-line: no-string-literal
      const isEnabled: string = item['isEnabled'] === true ? 'Applied' : 'Disabled';

      const fieldContent = item[column.fieldName as keyof BlockAdsData];

      switch (column.key) {
        case 'reason':
          return (
            <>
              <div>
                <b>Block Category: </b>
                {item.blockReasonMainCategory}
              </div>
              <div>
                <b>Sub Category: </b>
                {item.blockReasonSubCategory}
              </div>
              <br />
              <div>
                <b>Details: </b>
                {reasonDetails}
              </div>
            </>
          );
        case 'blockRequestSource':
          return (
            <span>
              {item.blockRequestSourceId! === 0 ? 'N/A' : getStore().active.blockRequestSourceData?.get(item.blockRequestSourceId!)}
            </span>
          );
        case 'isEnabled':
          return <span key={index}>{isEnabled}</span>;
        case 'pastRevenue':
          return <span>{'$' + (fieldContent as number).toFixed(2)}</span>;
        case 'modifiedDateTime':
          return <span>{(fieldContent as string).substring(0, 10)}</span>;
        case 'allImages':
          return <span key={index}>{imageElements}</span>;

        default:
          return <span>{fieldContent}</span>;
      }
    }

    private _renderAdCreativeItemColumn(item: AdPerformanceData, index: number, column: IColumn): JSX.Element {
      // const blockedImagesContext = getStore().blockedAdsContext!.mapAdIdImages!;
      // tslint:disable-next-line: no-string-literal
      const isAuctionData = item['uniqueCreativeAdId'] !== undefined && item['uniqueCreativeAdId'] !== '';
      // tslint:disable-next-line: no-string-literal
      const imgs: string[] = item['images'] ? item['images'] : [];
      const imageList = processImagesLink(imgs, noImage, isAuctionData);
      const imageElements: JSX.Element[] = [];
      for (let i = 0; i < imageList.length; i++) {
        if (i < 2) {
          let isBlocked = false;

          const imageReportDataTuple = { adId: item.adId, imageUrl: imageList[i] };
          if (
            (item.blockedAdIdImages && item.blockedAdIdImages.includes(JSON.stringify(imageReportDataTuple))) ||
            (!isAuctionData && item.blockedImages && compareImages(item.blockedImages, imageList[i]))
          ) {
            isBlocked = true;
          }

          imageElements.push(
            <span key={'spanOuter' + i}>
              <div
                key={'divOuter' + i}
                className={mergeStyles({
                  position: 'absolute',
                  top: i * 98 + 0,
                  left: 0,
                  width: '90px',
                  height: '90px',
                  marginLeft: '14px',
                  marginTop: '12px',
                  borderRadius: 4,
                  backgroundColor: 'rgba(92, 86, 86, 0.534)',
                })}
                style={{ display: isBlocked ? 'block' : 'none' }}
              >
                <div
                  key={'blockedtext' + i}
                  className={mergeStyles({
                    color: 'white',
                    marginTop: '37px',
                    marginLeft: '22px',
                  })}
                  style={{ display: isBlocked ? 'block' : 'none' }}
                >
                  BLOCKED
                </div>
              </div>
              <a href={imageList[i]} target="_blank" rel="noopener noreferrer">
                <img
                  key={'AdCreativeImageCompressed' + i}
                  src={imageList[i]}
                  width={90}
                  height={90}
                  alt={noImage}
                  style={{ margin: '2px' }}
                />
              </a>
            </span>
          );
        } else {
          imageElements.push(
            <Link
              key={i}
              onClick={() => {
                onAdCreativeBlockPanelChanged(true, BlockItemType.Images);
                onBlockImageAdsChanged([item], isAuctionData);
                onBlockAdsImagesChanged(item.images, 0, true, isAuctionData);
              }}
            >
              {this.props.intl.formatMessage(messages.moreImages, { imageListLength: imageList.length - i })}
            </Link>
          );
          break;
        }
      }

      // tslint:disable-next-line: no-string-literal
      let adProvider = '',
        placement = '';
      const adProviderEnum = item['adProvider'];
      if (adProviderEnum && getStore().active.selectedAdSource === AdSource.Auction) {
        adProvider = adProvidersToNames[adProviderEnum];
        placement = extractPlacement(item);
      } else if (adProviderEnum && getStore().active.selectedAdSource === AdSource.XandrAuction) {
        adProvider = xandrAdProvidersToNames[adProviderEnum];
        placement = item['locale'];
      } else {
        adProvider = 'Microsoft';
        placement = item['adUnitName']!;
      }
      const fieldContent = item[column.fieldName as keyof AdPerformanceData];
      // tslint:disable-next-line: no-string-literal
      const adUrl = item['adURL'] ? item['adURL'] : '';
      // tslint:disable-next-line: no-string-literal
      const destUrl = item['adDestUrl'] ? item['adDestUrl'] : item['adURL'] ? item['adURL'] : '';
      // tslint:disable-next-line: no-string-literal
      const status = item['status'] && item['status'] === LifeCycleStatus.Inactive ? 'BLOCKED' : '';
      switch (column.key) {
        case 'status':
          return (
            <span
              className={mergeStyles({
                color: 'red',
                width: '25px',
              })}
            >
              {status}
            </span>
          );
        case 'adURL':
          // To Do: update it with destination Url
          return (
            <Link href={toHttpsLink(destUrl as string)} target="_blank">
              {(adUrl as string).substring(0, 80)}
            </Link>
          );
        case 'images':
          return <span key={index}>{imageElements}</span>;
        case 'adSource':
          return <span>{adProvider}</span>;
        case 'adUnitName':
          return (
            <span
            // className={mergeStyles({
            //   display: 'inline-block',
            //   width: '10em',
            //   overflow: 'hidden',
            //   textOverflow: 'ellipsis',
            // })}
            >
              {placement}
            </span>
          );
        case 'revenue':
          return <span>{'$' + Math.round((fieldContent as number) * 100) / 100}</span>;
        default:
          return <span>{fieldContent}</span>;
      }
    }

    private _getItemCountForPage = (itemIndex: number, surfaceRect: IRectangle): number => {
      if (itemIndex === 0) {
        this._columnCount = Math.ceil(surfaceRect.width / MAX_ROW_HEIGHT);
        this._columnWidth = Math.floor(surfaceRect.width / this._columnCount);
        this._rowHeight = this._columnWidth;
      }

      return this._columnCount * ROWS_PER_PAGE;
    };
    private _getPageHeight = (): number => {
      return this._rowHeight * ROWS_PER_PAGE;
    };

    private _onRenderImage = (item: string, index: number): JSX.Element => {
      const store = getStore();
      return (
        <div
          className={this._classNames.imageListTitle}
          data-is-focusable={true}
          style={{
            width: 100 / this._columnCount + '%',
          }}
        >
          <div className={this._classNames.imageListSizer}>
            <div className={this._classNames.imageListPadder}>
              <a href={item} target="_blank" rel="noopener noreferrer">
                <img src={item} alt={noImage} className={this._classNames.imageListImage} />
              </a>
              {!isImageBlockable(
                item,
                store.blockAdsPage.adPerfBlockAdsData[0].adType,
                store.blockAdsPage.adPerfBlockAdsData[0].adSource
              ) && (
                <span className={this._classNames.imageListLabel}>
                  {this.props.intl.formatMessage(messages.viewOnlyImageLabel, { itemNumber: index + 1 })}
                </span>
              )}
              {isImageBlocked(
                item,
                store.blockAdsPage.adPerfBlockAdsData[0].adId!,
                store.blockedImagesContext,
                store.blockedAdsContext
              ) && <span className={this._classNames.imageListLabel}>{this.props.intl.formatMessage(messages.blockedLabel)}</span>}
              {isImageBlockable(item, store.blockAdsPage.adPerfBlockAdsData[0].adType, store.blockAdsPage.adPerfBlockAdsData[0].adSource) &&
                !isImageBlocked(
                  item,
                  store.blockAdsPage.adPerfBlockAdsData[0].adId!,
                  store.blockedImagesContext,
                  store.blockedAdsContext
                ) && (
                  <Checkbox
                    className={this._classNames.imageListLabel}
                    onChange={(_, value) => onBlockAdsBlockedImagesChanged(item, value)}
                  />
                )}
            </div>
          </div>
        </div>
      );
    };

    private _createDropDown(accounts?: IIdNamePair[]): IComboBoxOption[] {
      const accountList: IComboBoxOption[] = [];

      if (accounts) {
        accounts.forEach((account) => {
          accountList!.push({
            key: account.id,
            text: account.name,
            ariaLabel: account.name,
          });
        });
      }
      accountList.sort((a, b) => {
        const firstValue = a.text;
        const secondValue = b.text;

        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      });

      accountList.unshift({
        key: -1,
        text: 'All',
        ariaLabel: 'All',
      });
      return accountList;
    }

    private _createLocaleOptions(addAll?: boolean): IComboBoxOption[] {
      const availableLocales: IComboBoxOption[] = [];
      const LOCALE = LOCALE_LANGUAGE_MAPPING[getAppStore().locale];

      if (addAll) {
        availableLocales.push({
          key: 0,
          text: 'All',
          ariaLabel: 'All',
        });
      }

      Object.keys(adQualityLocales).forEach((adQualityLocale) => {
        availableLocales!.push({
          key: parseInt(adQualityLocale, 10),
          text: LOCALE[adQualityLocale],
          ariaLabel: LOCALE[adQualityLocale],
        });
      });

      availableLocales.sort((a, b) => {
        const firstValue = a.text;
        const secondValue = b.text;

        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      });
      return availableLocales;
    }

    private _createBlockingMarketsDropdown(selectedMarket: string): IComboBoxOption[] {
      // TODO: re-enable market/locale-based blocking when SAMRTB begins enforcing them
      // const { formatMessage } = this.props.intl;
      const availableMarkets: IComboBoxOption[] = [];

      availableMarkets!.push(
        {
          key: 'All',
          text: 'All',
          ariaLabel: 'All',
        } /* ,
        {
          key: selectedMarket,
          text: formatMessage(LocaleMessages[selectedMarket]),
          ariaLabel: formatMessage(LocaleMessages[selectedMarket]),
        }
        */
      );

      return availableMarkets;
    }

    private _createBlockRequestSourcesDropdown(data: Map<number, string>): IDropdownOption[] {
      const blockRequestSources: IDropdownOption[] = [];
      data.forEach((blockRequestSourceName: string, blockRequestSourceId: number) => {
        blockRequestSources.push({
          key: blockRequestSourceId,
          text: blockRequestSourceName,
          ariaLabel: blockRequestSourceName,
        });
      });

      return blockRequestSources;
    }
  }
);
