import { mutatorAction } from 'satcheljs';
import { IColumnMemberDetails } from '../store/schema';
import { getReportPageStore } from '../store/store';

export const setColumnMembers = mutatorAction('setColumnMembers', (dimension: string, columnMembers: IColumnMemberDetails[]) => {
  getReportPageStore().columnMembers[dimension] = { isDataLoaded: true, data: columnMembers };
});
export const resetColumnMembers = mutatorAction('resetColumnMembers', () => {
  getReportPageStore().columnMembers = {};
});
export const setReportType = mutatorAction('setReportType', (reportType: string) => {
  getReportPageStore().reportType = reportType;
});
