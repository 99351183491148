import React, { useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { SupportPageRoute } from '../../../../../Routes';
import { IHeaderItem } from '../../../../../components/Header/Header.types';
import { APP_NAME_NEW } from '../../../../../constants/AppConstants';
import { useCMUIHelpExperience, usePreloadSVGImages } from '../../../../../hooks';
import { injectIntlAndObserver } from '../../../../../utils';
import messages from './ResourcesSection.messages';
import { getClassNames } from './ResourcesSection.styles';
import ResourceCard, { IResourceCardProps } from './components/ResourceCard';
import DocumentationIcon from './resources/DocumentationCenterIcon.svg';
import FeedBackIcon from './resources/FeedBackIcon.svg';
import SupportTeamIcon from './resources/SupportTeamIcon.svg';
import { createPanel, getHeaderItems, showPanel } from './utils/utils';

interface IHeaderData {
  items?: IHeaderItem[];
}

export const connectToSupportTeam = (titleParam?: string) => {
  window.open(titleParam ? `${SupportPageRoute}?${titleParam}` : SupportPageRoute, '_blank');
};

const ResourceSection = injectIntlAndObserver<InjectedIntlProps>(({ intl: { formatMessage } }) => {
  const imgPreloadQueue = [DocumentationIcon, SupportTeamIcon, FeedBackIcon];
  usePreloadSVGImages(imgPreloadQueue);
  const { container, headerSection } = getClassNames();
  const { CMUIHeaderMenuItemSerializer } = useCMUIHelpExperience();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const itemsData: IHeaderData = getHeaderItems(CMUIHeaderMenuItemSerializer, formatMessage);

  const resources: IResourceCardProps[] = [
    {
      icon: DocumentationIcon,
      iconAlt: formatMessage(messages.learnTitle),
      title: formatMessage(messages.learnTitle),
      description: formatMessage(messages.learnDescription),
      onClick: () => window.open('https://help.ads.microsoft.com/#apex/pcv4/en/07501/-1', '_blank'),
    },
    {
      icon: SupportTeamIcon,
      iconAlt: formatMessage(messages.assistanceTitle),
      title: formatMessage(messages.assistanceTitle),
      description: formatMessage(messages.assistanceDescription),
      onClick: () => {
        connectToSupportTeam();
      },
    },
    {
      icon: FeedBackIcon, // AccountManagement -> FeedbackIcon
      iconAlt: formatMessage(messages.improveTitle, { appName: APP_NAME_NEW }),
      title: formatMessage(messages.improveTitle, { appName: APP_NAME_NEW }),
      description: formatMessage(messages.improveDescription),
      onClick: () => {
        showPanel(setIsFeedbackOpen);
      },
    },
  ];

  return (
    <>
      <h2 className={headerSection}>{formatMessage(messages.resourceTitle)}</h2>
      {createPanel(itemsData.items![1], isFeedbackOpen, formatMessage, setIsFeedbackOpen)}
      <div className={container}>
        {resources.map((resource, index) => (
          <ResourceCard key={index} {...resource} />
        ))}
      </div>
    </>
  );
});

export default ResourceSection;
