import { orchestrator } from 'satcheljs';
import { AdUnit, getAppStore, listEntity, NativeAdTemplate, onLoadingChanged, showErrorToastNotification } from '../../../../../@data';
import messages from '../../Manage/ManagePage.messages';
import { onFetchAdUnitTemplate } from '../actions';
import { setTemplate } from '../mutatorActions/setAdUnitTemplate';

orchestrator(onFetchAdUnitTemplate, async ({ entityId }) => {
  const { publisher, account, userContext } = getAppStore();

  if (publisher && account && entityId) {
    const adUnit = new AdUnit(entityId);
    onLoadingChanged(true);

    try {
      const template = await listEntity([publisher, adUnit], userContext, NativeAdTemplate);
      if (template.length !== 0) {
        const adUnitid = +(entityId ?? 0);
        template[0].adUnitId = adUnitid;
        setTemplate(template[0]);
      }
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.adunitTemplateFailedToLoad });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: messages.adunitTemplateFailedToLoad });
  }
});
