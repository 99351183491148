import { Link, getTheme } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, MessageValue } from 'react-intl';
import { PropertyServingConfigs } from '../../../../../@data/store/schema/models/ServingConfigs';
import { CLARITY_DEMO, CLARITY_PRIVACY_DISCLOSURE } from '../../../../../constants/AppConstants';
import { ITutorialCalloutSteps } from '../../../../../layout/TutorialCallout';
import messages from '../messages';
import { getClassNames, getStyles } from '../styles';

export const getClarityTutorialSteps = (
  formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor, values?: { [key: string]: MessageValue }) => string,
  configs?: PropertyServingConfigs[]
): ITutorialCalloutSteps[] => {
  const styles = getClassNames(getStyles, { theme: getTheme() });

  return [
    {
      containerId: 'clarityTourIcon',
      content: (
        <div className={styles.hoverCardInfo}>
          <span className={styles.hoverCardTitle}>{formatMessage(messages.clarityTour)}</span>
          <span>{formatMessage(messages.clarityBenefits)}</span>
          <ol>
            <li>{formatMessage(messages.clarityFirstBenefit)}</li>
            <li>
              {formatMessage(messages.claritySecondBenefit)}{' '}
              <Link href={CLARITY_DEMO} target="_blank" rel="noreferrer noopener" className={styles.redirectionLink}>
                {formatMessage(messages.claritySecondBenefitRedirection)}
              </Link>
            </li>
            <li>
              {formatMessage(messages.clarityThirdBenefit)}{' '}
              <Link href={CLARITY_PRIVACY_DISCLOSURE} target="_blank" rel="noreferrer noopener" className={styles.redirectionLink}>
                {formatMessage(messages.clarityThirdBenefitRedirection)}
              </Link>
            </li>
          </ol>
        </div>
      ),
      isContentReady: true,
    },
    {
      containerId: 'clarityProjectId',
      content: (
        <div className={styles.hoverCardInfo}>
          <span className={styles.hoverCardTitle}>{formatMessage(messages.clarityTourSecondPageHeader)}</span>
          <span>{formatMessage(messages.clarityTourSecondPageDesc)}</span>
        </div>
      ),
      isContentReady: (configs && configs?.length > 0 && configs.some((config) => config.clarityConfig?.clarityId !== undefined)) || false,
    },
  ];
};
