import { classNamesFunction, DefaultButton, getTheme, IProcessedStyleSet, MessageBarType, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { ISignUpPageProps, ISignUpPageStyleProps, ISignUpPageStyles } from '.';
import { Log } from '../../../logging/src';
import { getAppStore, onNotificationChanged } from '../../@data';
import { PublisherType } from '../../@data/store/schema/enums/PublisherType';
import AppMessages from '../../App.messages';
import { FormState } from '../../components/Form';
import { Logo } from '../../components/Logo/Logo';
import { Stepper } from '../../components/Stepper/Stepper';
import { StepperDirection } from '../../components/Stepper/Stepper.types';
import { InternationalizationLayout } from '../../layout/InternationalizationLayout';
import { NotificationLayout } from '../../layout/NotificationLayout/NotificationLayout';
import { Page } from '../../layout/PageLayout/PageLayout';
import { injectIntlWithObserver, validateSchema } from '../../utils';
import { onFetchTnC } from '../terms-and-conditions/@data/actions';
import { getSignUpStore, ISignUpViewState, signUpErrorModel } from './@data';
import { onFormInitialized, onFormSubmitted, onSignUpErrorChanged, triggerOneVetReview } from './@data/actions';
import { AccountType } from './component/AccountType/AccountType';
import { Finish } from './component/Finish/Finish';
import { SignUpDetails } from './component/SignupDetails/SignUpDetails';
import messages from './SignUpPage.messages';
import { getStyles } from './SignUpPage.styles';

export const WIDTH = '570px';

const getClassNames = classNamesFunction<ISignUpPageStyleProps, ISignUpPageStyles>();
interface ISignUpState {
  currentStep: number;
  smallWidth: boolean;
}

export const SignUpPage = injectIntlWithObserver(
  class extends React.Component<ISignUpPageProps, ISignUpState> {
    private _classNames: IProcessedStyleSet<ISignUpPageStyles>;
    private _formatMessage = this.props.intl.formatMessage;

    constructor(props: ISignUpPageProps) {
      super(props);
      this.state = {
        currentStep: 1,
        smallWidth: window.innerWidth > 640 ? false : true,
      };
      this.updateState = this.updateState.bind(this);
      this.updateDimensions = this.updateDimensions.bind(this);
      onFormInitialized(FormState.InPageEdit);
    }

    public componentDidMount() {
      const { userContext } = getAppStore();
      window.addEventListener('resize', this.updateDimensions);
      onFetchTnC();
      if (!userContext || !userContext.email) {
        Log.write('Redirecting user to landing page, invalid user context');
        this.props.history.push('landing-page');
      }
    }

    public componentWillUnmount(): void {
      window.removeEventListener('resize', this.updateDimensions);
    }

    public updateDimensions() {
      if (window.innerWidth <= 640) {
        this.setState({ smallWidth: true });
      } else {
        this.setState({ smallWidth: false });
      }
    }

    public updateState(step: number) {
      this.setState({
        currentStep: step,
      });
    }

    public render(): JSX.Element {
      const { formatMessage } = this.props.intl;
      const theme = getTheme();
      this._classNames = getClassNames(getStyles, { theme });
      const store = getSignUpStore();
      const currentStep = this.state.currentStep;
      const labelArray = [formatMessage(messages.accountType), formatMessage(messages.signupDetails), formatMessage(messages.finish)];

      return (
        <div className={this._classNames.root}>
          <Page {...this.props} renderLeftMostSection={<Logo />}>
            <InternationalizationLayout />
            <NotificationLayout {...this.props} ignoreGlobalAsync>
              <div className={this._classNames.stepper}>
                <Stepper
                  labelArray={labelArray}
                  currentStep={currentStep}
                  direction={this.state.smallWidth ? StepperDirection.Vertical : StepperDirection.Horizontal}
                />
              </div>

              <div className={this._classNames.body}>
                <div className={this._classNames.componentContainer}>
                  {currentStep === 1 && (
                    <AccountType
                      history={this.props.history}
                      titleText={formatMessage(messages.acctTitle)}
                      descriptionText={formatMessage(messages.acctContent)}
                    />
                  )}
                  {currentStep === 2 && <SignUpDetails />}
                  {currentStep === 3 && <Finish {...this.props} />}
                </div>
                <div className={this._classNames.buttonSection}>
                  {currentStep >= 2 && this._renderBackButton(currentStep)}
                  {this._renderNextButton(currentStep, store)}
                </div>
              </div>
            </NotificationLayout>
          </Page>
        </div>
      );
    }

    // Function to render the back step button
    private _renderBackButton = (step?: number | null): JSX.Element | null => {
      if (step! >= 2) {
        return (
          <DefaultButton className={this._classNames.buttonStyle} onClick={() => this.updateState(this.state.currentStep - 1)}>
            {this._formatMessage(messages.back)}
          </DefaultButton>
        );
      } else {
        return null;
      }
    };

    private _handleFinalSubmit = () => {
      onFormSubmitted(this.props.history);
    };

    private _handleSignUpDetailsSubmit = async () => {
      try {
        await validateSchema(signUpErrorModel(), getSignUpStore().active);
        onSignUpErrorChanged();
        triggerOneVetReview();

        this.updateState(this.state.currentStep + 1);
      } catch (e) {
        onNotificationChanged({
          textMessageDescriptor: messages.signupFixInvalidFields,
          intlMessageValues: { count: Object.keys(e).length },
          messageBarType: MessageBarType.severeWarning,
        });
        onSignUpErrorChanged(e);
        return;
      }
    };

    /*
     * Function to render the next step button
     * Text: { Next, Ready to Go }
     * Disable: { Selected Account Type and checked agreement checkbox }
     * onClick: { Next component, Navigate to HomePage }
     */
    private _renderNextButton = (step?: number, store?: ISignUpViewState | null): JSX.Element => {
      let text = '';
      let _isDisabled = true;
      switch (step) {
        case 1: {
          text = this._formatMessage(AppMessages.next);
          _isDisabled = !(
            store?.active.accountType === PublisherType.organization || store?.active.accountType === PublisherType.individual
          );
          break;
        }
        case 2: {
          text = this._formatMessage(AppMessages.next);
          _isDisabled = !getSignUpStore().isTermsAndConditionsAgreed;
          break;
        }
        case 3: {
          text = this._formatMessage(messages.ready);
          _isDisabled = false;
          break;
        }
        default: {
          text = this._formatMessage(AppMessages.next);
          break;
        }
      }
      return (
        <PrimaryButton
          className={this._classNames.buttonStyle}
          onClick={() => {
            switch (this.state.currentStep) {
              case 1:
                this.updateState(this.state.currentStep + 1);
                break;
              case 2:
                this._handleSignUpDetailsSubmit();
                break;
              case 3:
                this._handleFinalSubmit();
                break;
              default:
                break;
            }
          }}
          disabled={_isDisabled}
        >
          {text}
        </PrimaryButton>
      );
    };
  }
);
