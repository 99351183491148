import { getCurrentPublisher } from '../../selectors';
import { getAppStore } from '../store';

export const getCurrentUserContext = () => {
  return getAppStore().userContext;
};

export const getPrimaryUserId = () => {
  return getCurrentPublisher()?.extendedProperties?.primaryContactUserId || getCurrentUserContext()?.id;
};
