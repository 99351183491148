import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  AdUnit,
  AdUnitMarketClassification,
  AdUnitType,
  Channel,
  getAppStore,
  listEntity,
  NativeAdTemplate,
  onLoadingChanged,
  onNotificationChanged,
  Property,
  Publisher,
  PublisherGroupConfig,
  showErrorToastNotification,
} from '../../../../../@data';
import { getAdUnitTaxonomyValues } from '../../../../../@data/services/MetadataService';
import { isTythonSelfServeUser } from '../../../../../@data/utils/checkUserType';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { setPublisherAndAccountContext } from '../../../@data/services/AdManagementService';
import messages from '../../Manage/ManagePage.messages';
import { onAdunitChanged, onAdunitErrorChanged, onFormInitialized, onRawChanged } from '../actions';
import { onAdunitEnablePrivateMarketplaceChanged } from '../mutatorActions';
import { fetchAdunitById, fetchAdunitMediationStatus } from '../services/AdunitService';
import { IAdunitViewModel } from '../store/schema';

orchestrator(onFormInitialized, async ({ mode, publisherId, accountId, entityId, showLoadingSpinner = true }) => {
  const { userContext } = getAppStore();

  const viewModel: IAdunitViewModel = {
    channels: [],
    marketClassification: AdUnitMarketClassification.Syndicated,
    publisherGroupConfig: PublisherGroupConfig.DefaultPlus,
  };

  const parsedPublisherId = parseInt(publisherId, 10);
  const parsedAccountId = parseInt(accountId, 10);
  const tythonRole = isTythonSelfServeUser();

  if (!isNaN(parsedPublisherId) && !isNaN(parsedAccountId)) {
    const publisher = new Publisher(parsedPublisherId);
    let account = new Account(parsedAccountId);

    try {
      setPublisherAndAccountContext(publisher, account);

      onAdunitErrorChanged();
      if (showLoadingSpinner) {
        onLoadingChanged(true);
      }

      if (mode !== FormState.Edit && mode !== FormState.InPageEdit) {
        let response;

        if (tythonRole) {
          response = await Promise.all([listEntity([publisher], userContext, Property)]);
          viewModel.properties = response[0];
          viewModel.adUnitType = AdUnitType.Native;
        } else {
          response = await Promise.all([
            listEntity([publisher, account], userContext, Channel),
            listEntity([publisher], userContext, Property),
          ]);
          viewModel.channels = response[0] ? response[0].map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];
          viewModel.properties = response[1];
        }
      }

      viewModel.adUnitTaxonomyValues = await getAdUnitTaxonomyValues();

      if (
        (mode === FormState.Edit ||
          mode === FormState.InPageEdit ||
          mode === FormState.MultiStepCreation ||
          mode === FormState.MultiStepEdit) &&
        entityId
      ) {
        const raw = await fetchAdunitById(publisher, entityId, userContext);

        account = new Account(raw.accountId);

        let response;

        if (tythonRole) {
          response = await Promise.all([
            listEntity([publisher], userContext, Property),
            fetchAdunitMediationStatus(publisher, account, entityId),
          ]);

          viewModel.properties = response[0];
          viewModel.adUnitType = AdUnitType.Native;
          viewModel.adSenseMediationToggle = response[1][0].mediationStatus;
          viewModel.adSenseMediationStatus = response[1][0].mediationStatus;
        } else {
          const adUnit = new AdUnit(entityId);

          response = await Promise.all([
            listEntity([publisher, account], userContext, Channel),
            listEntity([publisher], userContext, Property),
          ]);

          try {
            const isBoost = sessionStorage.getItem('IsBoostPublisher');
            if (isBoost === 'true') {
              const template = await listEntity([publisher, adUnit], userContext, NativeAdTemplate);
              if (template && template.length > 0) {
                const adUnitid = +(entityId ?? 0);
                template[0].adUnitId = adUnitid;
                viewModel.BoostTemplate = template[0];
              }
            }
          } catch (e) {
            showErrorToastNotification({ textMessageDescriptor: messages.adunitTemplateFailedToLoad });
          }

          viewModel.channels = response[0] ? response[0].map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];
          viewModel.channelsSelected = raw.channelIds ? viewModel.channels?.filter((c) => raw.channelIds.includes(c.id)) : [];
          viewModel.properties = response[1];
        }

        const enablePrivateMarketplace = raw.storeIds ? raw.storeIds.length !== 0 : false;

        viewModel.id = raw.id;
        viewModel.name = String(raw.name);
        viewModel.marketClassification = raw.classification ? raw.classification.marketClassification : undefined;
        viewModel.existingFinancialClassification = raw.classification ? raw.classification.financialClassification : undefined;

        viewModel.businessModel = raw.attributes ? raw.attributes.Taxonomy : undefined;
        viewModel.isImpressionFeedbackRequired = !!raw.requireImpressionFeedbackForBillableTraffic;

        viewModel.publisherGroupConfig = raw.publisherGroupConfiguration;
        viewModel.servingTier = raw.servingCostTier;

        if (raw.partnerCodes) {
          viewModel.distributionPartnerCodes = raw.partnerCodes.join('\n');
        }

        viewModel.propertySelected = raw.propertyId ? viewModel.properties?.filter((p) => p.id === raw.propertyId)[0] : undefined;

        if (raw.storeIds) {
          viewModel.storeIds = raw.storeIds.join('\n');
        }

        onRawChanged(raw);
        onAdunitEnablePrivateMarketplaceChanged(enablePrivateMarketplace);
      } else {
        onRawChanged();
        onAdunitEnablePrivateMarketplaceChanged(false);
      }

      onAdunitChanged(viewModel);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.adunitDetailsFailedToLoad });
    } finally {
      if (showLoadingSpinner) {
        onLoadingChanged(false);
      }
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.createAdunitInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
