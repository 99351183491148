import {
  DetailsHeader,
  DetailsListLayoutMode,
  DetailsRow,
  IDetailsColumnRenderTooltipProps,
  IShimmeredDetailsListProps,
  mergeStyleSets,
  SelectionMode,
  ShimmeredDetailsList,
  TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { useSortableGridColumns } from '../../hooks';
import { IPropertyLevelAdBlock } from '../../pages/ad-management/AdBlock/components/PropertyLevelBlocking';
import type { ICustomColumn } from '../../types';
import { FormTitle } from '../Form';
import { getClassNames, IGridWithCustomColumnStyles } from './GridWithCustomColumns.styles';

const DEFAULT_SHIMMER_LINES = 5;
export interface IGridWithCustomColumns extends IShimmeredDetailsListProps, InjectedIntlProps {
  columns: ICustomColumn[];
  calloutArticleQuery?: string;
  headingText?: string;
  headingCalloutContent?: string;
  styleProps?: Partial<IGridWithCustomColumnStyles>;
  onSortItems?: (items: IPropertyLevelAdBlock[]) => void;
  onCleanupSortItems?: () => void;
  renderZeroItemsComponent?: () => JSX.Element;
  helpInfoProps?: {
    id: string;
    calloutContent?: string;
    iconButtonOnClick: () => void;
    calloutOnDismiss: () => void;
  };
  titleHeadingLevel?: number;
}

export const GridWithCustomColumns = injectIntl(function GridWithCustomColumnsComponent({
  columns,
  calloutArticleQuery,
  items,
  styleProps,
  headingText,
  headingCalloutContent,
  intl,
  shimmerLines = DEFAULT_SHIMMER_LINES,
  enableShimmer = true,
  helpInfoProps = undefined,
  titleHeadingLevel,
  onSortItems,
  onCleanupSortItems,
  renderZeroItemsComponent,
}: IGridWithCustomColumns) {
  const { detailsListStyles, detailsHeaderRootStyle, detailsRowRootStyle, headingRootStyle, disabledRowStyle } = mergeStyleSets(
    getClassNames(),
    styleProps
  );
  const { gridColumns, handleColumnHeaderClick } = useSortableGridColumns<IPropertyLevelAdBlock>(
    columns,
    items,
    onSortItems,
    onCleanupSortItems
  );

  return (
    <div className="gridWithCustomColumns">
      {headingText && (
        <FormTitle
          intl={intl}
          title={headingText}
          calloutContent={headingCalloutContent}
          styles={{ root: headingRootStyle }}
          articleQuery={calloutArticleQuery}
          {...(helpInfoProps ? helpInfoProps : {})}
          titleHeadingLevel={titleHeadingLevel}
        />
      )}

      {!enableShimmer && items.length === 0 && renderZeroItemsComponent?.()}

      {(enableShimmer || items.length > 0) && (
        <ShimmeredDetailsList
          enableShimmer={enableShimmer}
          shimmerLines={shimmerLines}
          items={items}
          detailsListStyles={{ root: detailsListStyles }}
          onRenderRow={(detailsRowProps) => {
            const { item } = detailsRowProps ?? {};
            if (!detailsRowProps) {
              return null;
            }

            return (
              <DetailsRow
                disabled={item?.isDisabled}
                {...detailsRowProps}
                styles={({ disabled }) => ({ root: `${detailsRowRootStyle} ${disabled ? disabledRowStyle : ''}` })}
              />
            );
          }}
          onRenderDetailsHeader={(headerProps) => {
            if (!headerProps) {
              return null;
            }
            return (
              <DetailsHeader
                {...headerProps}
                styles={{ root: detailsHeaderRootStyle }}
                onRenderColumnHeaderTooltip={(tooltipHostProps: IDetailsColumnRenderTooltipProps) => {
                  return <TooltipHost {...tooltipHostProps} />;
                }}
              />
            );
          }}
          columns={gridColumns}
          selectionMode={SelectionMode.none}
          onColumnHeaderClick={handleColumnHeaderClick}
          layoutMode={DetailsListLayoutMode.justified}
        />
      )}
    </div>
  );
});
