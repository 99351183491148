import { FontSizes, FontWeights, classNamesFunction } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../theme';
import { IManagePageStyleProps, IManagePageStyles } from './ManagePage.types';

export const getStyles = (props: IManagePageStyleProps): IManagePageStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      flex: 1,
      marginLeft: spacing.size32,
      marginTop: spacing.size32,
      marginRight: spacing.size32,
    },
    tokenPanelsubTitle: {
      marginTop: spacing.size8,
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.medium,
    },
    tokenPanelsubTitleValue: {
      marginTop: spacing.size12,
      fontSize: FontSizes.medium,
    },
    tokenPanelDevToken: {
      width: '160px',
      height: '40px',
      backgroundColor: theme.palette.white,
      selectors: {
        ':hover': {
          background: theme.palette.neutralLighter,
        },
      },
    },
    tokenPanelAgreement: {
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.themeLighterAlt,
      fontSize: theme.fonts.small.fontSize,
      marginTop: spacing.size12,
      paddingTop: spacing.size12,
      paddingBottom: spacing.size12,
      paddingRight: spacing.size6,
      paddingLeft: spacing.size6,
    },
    tokenPanelAgreementBtn: {
      color: theme.palette.red,
      selectors: {
        ':hover': {
          color: theme.palette.red,
        },
      },
    },
    tokenPanelInfoButton: {
      float: 'left',
    },
    tokenPanelAgreementText: {
      backgroundColor: theme.palette.themeLighterAlt,
      width: 'inherit',
    },
    tokenSpinner: {
      width: '200px',
      marginTop: '10px',
    },
    tokenFetchFail: {
      width: '430px',
      color: theme.palette.red,
    },
  };
};

export const getClassNames = classNamesFunction<IManagePageStyleProps, IManagePageStyles>();
