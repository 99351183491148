import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { getAppStore } from '../../../../../@data';
import { onPopupOpenChanged, showErrorToastNotification } from '../../../../../@data/mutatorActions';
import { AdSenseOAuthStatus } from '../../../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { PopupType } from '../../../../../@data/store/schema/enums/PopupType';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import { onInitializeGoogleAccount } from '../../../Account/Settings/components/GoogleConnector/@data/actions';
import messages from '../../Manage/ManagePage.messages';
import { fetchAdUnitMediationStatus } from '../actions';
import { onAdSenseMediationToggleChanged } from '../mutatorActions';
import { fetchAdunitMediationStatus } from '../services/AdunitService';

orchestrator(fetchAdUnitMediationStatus, async ({ adUnitId, showLoadingSpinner = false, showNotifications = true }) => {
  const { publisher, account } = getAppStore();

  if (publisher && account) {
    let adSenseConnectionState = AdSenseOAuthStatus.Success.toString();

    onInitializeGoogleAccount(false);
    await when(() => {
      const { active: googleAdSense } = getGoogleAdSenseStore();

      if (!!googleAdSense.connectionStatus) {
        adSenseConnectionState = googleAdSense.connectionStatus;
      }

      return !!googleAdSense.connectionStatus;
    });

    if (adSenseConnectionState === AdSenseOAuthStatus.None.toString() && showNotifications) {
      showErrorToastNotification({ textMessageDescriptor: messages.noConnectionWithAdSense });
      return;
    }

    try {
      const list = await fetchAdunitMediationStatus(publisher, account, adUnitId);

      if (list[0].mediationStatus) {
        onAdSenseMediationToggleChanged(true);
      } else {
        onAdSenseMediationToggleChanged(false);
        if (showNotifications) {
          showErrorToastNotification({ textMessageDescriptor: messages.failedToFetchAdSenseAdunit });
        }
      }
    } catch (error) {
      onAdSenseMediationToggleChanged(false);
      if (showNotifications) {
        showErrorToastNotification({ textMessageDescriptor: messages.failedToFetchAdSenseAdunit });
      }
      Log.error(`Error while fetching connected adSense's adUnit: ${error}`);
    } finally {
      if (showLoadingSpinner) {
        onPopupOpenChanged(PopupType.None);
      }
    }
  } else {
    onAdSenseMediationToggleChanged(false);
    showErrorToastNotification({ textMessageDescriptor: messages.failedToFetchAdSenseAdunit });
  }
});
