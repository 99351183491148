import { defineMessages } from 'react-intl';

export default defineMessages({
  contains: {
    id: 'textFilter.filterConditionString.contains',
    defaultMessage: 'contains',
  },
  doesNotContain: {
    id: 'textFilter.filterConditionString.doesNotContain',
    defaultMessage: 'does not contain',
  },
  beginsWith: {
    id: 'textFilter.filterConditionString.beginsWith',
    defaultMessage: 'begins with',
  },
  endsWith: {
    id: 'textFilter.filterConditionString.endsWith',
    defaultMessage: 'ends with',
  },
  equals: {
    id: 'textFilter.filterConditionString.equals',
    defaultMessage: 'equals',
  },
  doesNotEqual: {
    id: 'textFilter.filterConditionString.doesNotEqual',
    defaultMessage: 'does not equal',
  },
  inputValue: {
    id: 'textFilter.filterCondition.inputValue',
    defaultMessage: 'Filter Value',
  },
  addMore: {
    id: 'textFilter.addition.addMore',
    defaultMessage: 'Add More',
  },
  selectFilter: {
    id: 'textFilter.selectFilter',
    defaultMessage: 'Select Filter',
  },
});
