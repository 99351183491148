import React from 'react';
import { getAppStore, onHelpInfoCalloutOpened, onHelpInfoChanged } from '../../../@data';
import { FormTitle } from '../../../components/Form';
import { Tabs } from '../../../components/Tabs';
import { injectIntlAndObserver } from '../../../utils';
import { UserInvitationsTab } from './UserInvitations/UserInvitations';
import messages from './UserManagement.messages';
import { getClassNames } from './UserManagement.styles';
import { UsersTab } from './Users/UsersTab';

const UserManagementPage = injectIntlAndObserver(function UserManagementPageComponent({ intl }) {
  const { title, usersTab, userInvitationsTab } = messages;
  const { formatMessage } = intl;
  const { root } = getClassNames();
  const { helpInfo, locale } = getAppStore();

  return (
    <div className={root}>
      <FormTitle
        intl={intl}
        title={formatMessage(title)}
        id="pop_PCv4_User_Managment"
        calloutContent={helpInfo}
        iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_User_Managment', locale)}
        calloutOnDismiss={() => onHelpInfoChanged()}
      />

      <Tabs
        tabs={[
          {
            key: 'users',
            headerText: formatMessage(usersTab),
            component: <UsersTab />,
          },
          {
            key: 'userInvitations',
            headerText: formatMessage(userInvitationsTab),
            component: <UserInvitationsTab />,
          },
        ]}
      />
    </div>
  );
});

export default UserManagementPage;
