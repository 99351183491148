import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { AccountSKU, getAppStore, onNotificationChanged, onSavingChanged, postEntity, putEntity } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { onAccountSkuFormCleared, onAccountSkuFormSubmitted, onAccountSkusChanged } from '../actions';
import { fetchAllMediumAccountSkus } from '../services/AccountService';
import { getStore } from '../store/store';

orchestrator(onAccountSkuFormSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { accountSku } = getStore();

  let notification: INotificationBarItem = {};

  if (publisher && accountSku) {
    const body: AccountSKU = {
      accountId: accountSku.accountId || msg.accountId,
      medium: accountSku.medium!,
      sku: accountSku.sku!,
      apiPath: undefined,
      className: 'accountSku',
    };

    onSavingChanged(true);

    try {
      if (msg.mode === FormState.InPageEdit) {
        await putEntity([publisher], body, userContext, AccountSKU);
        notification = {
          text: `Edited account SKU mapping ${accountSku.accountId} success`,
          messageBarType: MessageBarType.success,
        };
      } else if (msg.mode === FormState.InPageCreate) {
        await postEntity([publisher], AccountSKU, body, userContext);

        notification = {
          text: `Created new account SKU mapping ${msg.accountId} success`,
          messageBarType: MessageBarType.success,
        };
      }
      const accountSkus = await fetchAllMediumAccountSkus(publisher, String(body.accountId), userContext);
      onAccountSkusChanged(accountSkus.mediumMappedSkus);
      onAccountSkuFormCleared();
    } catch (e) {
      notification = {
        text: `Submitting the account SKU mapping form "${FormState[msg.mode]}" caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
    } finally {
      onSavingChanged(false);
    }
  } else {
    notification = {
      text: `Account SKU mapping  "${FormState[msg.mode]}" is invalid.`,
      messageBarType: MessageBarType.warning,
    };
  }
  onNotificationChanged(notification);
});
