import { ChoiceGroup, DefaultButton, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import React, { useState } from 'react';
import { UserRole } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { FormItem } from '../../../../components/Form';
import { getPanelClassNames } from '../../../../styles/PanelStyles';
import { injectIntlAndObserver } from '../../../../utils';
import UserManagementMessages from '../UserManagement.messages';
import { getUserRoleChoiceGroupOptions } from '../components/UserRoleChoiceGroupOptions';
import { getUserRoleFromUser } from '../utils/userRoleUtils';
import { onUserRoleChanged } from './@data/actions/usersTabActions';
import './@data/mutatorActions';
import { setUserRoleChangePanelStatus } from './@data/mutatorActions/onUsersTabChanged';
import './@data/orchestrators';
import { getUserManagementStore } from './@data/store';

const ROLE_CHANGE_PANEL_WIDTH = '466px';
const PANEL_FIELD_WIDTH = 416;

export const UserRoleChangePanel = injectIntlAndObserver(function UserRoleChangePanelComponent({ intl }) {
  const { roleChangePanelOpen, selectedItem } = getUserManagementStore();
  const { formatMessage } = intl;
  const { changeUserRole, roleChangePanelDesc } = UserManagementMessages;
  const { panelFooterButtonsWrapper, panelDescription } = getPanelClassNames();

  const onDismiss = () => setUserRoleChangePanelStatus(false, undefined);
  const existingUserRole = selectedItem ? getUserRoleFromUser(selectedItem) : undefined;

  const [role, setUserRole] = useState<UserRole | undefined>(existingUserRole);

  return (
    <Panel
      headerText={formatMessage(changeUserRole)}
      isOpen={roleChangePanelOpen}
      type={PanelType.custom}
      customWidth={ROLE_CHANGE_PANEL_WIDTH}
      onDismiss={onDismiss}
      closeButtonAriaLabel={formatMessage(AppMessages.close)}
      isFooterAtBottom={true}
      onRenderFooterContent={() => {
        return (
          <div className={panelFooterButtonsWrapper}>
            <PrimaryButton
              id="roleChangeButton"
              text={formatMessage(AppMessages.save)}
              disabled={!role || role === existingUserRole}
              onClick={(ev) => {
                if (role) {
                  onUserRoleChanged(role);
                  onDismiss();
                }
              }}
            />
            <DefaultButton text={formatMessage(AppMessages.cancel)} onClick={onDismiss} />
          </div>
        );
      }}
    >
      <React.Fragment>
        <div className={panelDescription}>{formatMessage(roleChangePanelDesc)}</div>
        <FormItem formWidth={PANEL_FIELD_WIDTH}>
          <ChoiceGroup
            selectedKey={role || existingUserRole}
            options={getUserRoleChoiceGroupOptions(intl)}
            onChange={(_, option) => setUserRole(option?.key as UserRole)}
          />
        </FormItem>
      </React.Fragment>
    </Panel>
  );
});
