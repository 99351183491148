import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router';
import messages from './IndexPage.messages';
import { RouteName, defaultRoute } from '../@data';
import { IIndexPageProps } from './IndexPage.types';
import { onCurrentRouteChanged } from '../@data/actions';
import { getAppStore } from '../../../@data';

interface IIndexState {
  baseUrl?: string;
  currentRoute?: string;
}

/**
 * The index page is responsible managing the default state of all the pages that
 * don't get matched. For example, when an adunit is not specified, it will redirect
 * to this page which will make sure the publisher and account are properly used.
 */
export const IndexPage = injectIntl(class extends React.Component<IIndexPageProps, IIndexState> {
  constructor(props) {
    super(props);

    if (props.match.params.id) {
      const pageId = props.match.params.id;
      const baseUrl = props.match.url.substring(0, props.match.url.indexOf(pageId) - 1);
      const currentRoute = pageId in RouteName ? pageId : defaultRoute;
      this.state = { baseUrl, currentRoute };
    } else {
      this.state = { baseUrl: props.match.url, currentRoute: RouteName[defaultRoute]};
    }
  }

  public componentDidMount() {
    onCurrentRouteChanged(RouteName[this.state.currentRoute || defaultRoute], this.props.intl.formatMessage);
  }

  public render(): JSX.Element {
    const store = getAppStore();

    if (store.publisher && store.account &&
        store.publisher.id && store.account.id) {
      const publisherId = store.publisher.id.toString();
      const accountId = store.account.id.toString();
      const url = `${this.state.baseUrl}/${publisherId}-${accountId}/${this.state.currentRoute}`;

      if (url !== this.props.location.pathname) {
        return <Redirect to={url} />;
      }
    }

    return (
      <React.Fragment>
        <h2>{this.props.intl.formatMessage(messages.title)}</h2>
      </React.Fragment>
    );
  }
});