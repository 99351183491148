import { IChoiceGroupOption, IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { History } from 'history';
import { action } from 'satcheljs';
import { IPRange, ISubGeography, PublisherCreate } from '../../../../../@data';
import { FormState } from '../../../../../components/Form';

export const onFormPublisherPartnerChanged = action('onFormPublisherPartnerChanged', (value: IDropdownOption) => ({ value }));

export const onFormIdChanged = action('onFormIdChanged', (value: number) => ({ value }));

export const onFormPublisherNameChanged = action('onFormPublisherNameChanged', (value?: string) => ({ value }));

export const onFormPublisherNetworkChanged = action('onFormPublisherNetworkChanged', (value: IChoiceGroupOption) => ({ value }));

export const onFormPublisherAddressLine1Changed = action('onFormPublisherAddressLine1Changed', (value?: string) => ({ value }));

export const onFormPublisherAddressLine2Changed = action('onFormPublisherAddressLine2Changed', (value?: string) => ({ value }));

export const onFormPublisherAddressCityChanged = action('onFormPublisherAddressCityChanged', (value?: string) => ({ value }));

export const onFormPublisherAddressCountryChanged = action('onFormPublisherAddressCountryChanged', (value?: IComboBoxOption) => ({
  value,
}));

export const onFormPublisherAddressCountryCodeChanged = action('onFormPublisherAddressCountryCodeChanged', (value?: IComboBoxOption) => {
  return {
    value,
  };
});

export const onFormPublisherAddressStateChanged = action('onFormPublisherAddressStateChanged', (value?: IDropdownOption) => ({ value }));

export const onFormPublisherAddressStateListsChanged = action('onFormPublisherAddressStateListsChanged', (value?: ISubGeography[]) => ({
  value,
}));

export const onFormPublisherAddressZipcodeChanged = action('onFormPublisherAddressZipcodeChanged', (value?: string) => ({ value }));

export const onFormPublisherTierChanged = action('onFormPublisherTierChanged', (value: IDropdownOption) => ({ value }));

export const onFormPublisherOverrideTierChanged = action('onFormPublisherOverrideTierChanged', (value: IDropdownOption) => ({ value }));

export const onFormPublisherEntityLimitTierChanged = action('onFormPublisherEntityLimitTierChanged', (value: IDropdownOption) => ({
  value,
}));

export const onFormPublisherServiceLevelChanged = action('onFormPublisherServiceLevelChanged', (value: IDropdownOption) => ({
  value,
}));

export const onFormPublisherWebAdunitsChanged = action('onFormPublisherWebAdunitsChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherMobileSearchChanged = action('onFormPublisherMobileSearchChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherDistributionChanged = action('onFormPublisherDistributionChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherDistributionV2Changed = action('onFormPublisherDistributionV2Changed', (value?: boolean) => ({
  value,
}));

export const onFormPublisherStartDistributionChanged = action('onFormPublisherStartDistributionChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherStartDistributionContentChanged = action(
  'onFormPublisherStartDistributionContentChanged',
  (value?: boolean) => ({
    value,
  })
);

export const onFormPublisherSelectTierChanged = action('onFormPublisherSelectTierChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherMediationChanged = action('onFormPublisherMediationChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherNativeChanged = action('onFormPublisherNativeChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherSyndicationChanged = action('onFormPublisherSyndicationChanged', (value?: boolean) => ({
  value,
}));

export const onFormPublisherPropertyAutoChanged = action('onFormPublisherPropertyAutoChanged', (value?: boolean) => ({
  value,
}));
export const onFormPublisherPSReportingAPIChanged = action('onFormPublisherPSReportingAPIChanged', (value?: boolean) => ({
  value,
}));
export const onFormPublisherPSDashboardChanged = action('onFormPublisherPSDashboardChanged', (value?: boolean) => ({
  value,
}));
export const onFormPublisherEnableDefaultTythonV2Changed = action('onFormPublisherEnableDefaultTythonV2Changed', (value?: boolean) => ({
  value,
}));

export const onFormPublisherDynamicPropertyRegistrationChanged = action(
  'onFormPublisherDynamicPropertyRegistrationChanged',
  (value?: boolean) => ({
    value,
  })
);

export const onFormPublisherIpNameChanged = action('onFormPublisherIpNameChanged', (value?: string) => ({
  value,
}));

export const onFormPublisherMinimumIpChanged = action('onFormPublisherMinimumIpChanged', (value?: string) => ({
  value,
}));

export const onFormPublisherMaximumIpChanged = action('onFormPublisherMaximumIpChanged', (value?: string) => ({
  value,
}));

export const onFormPublisherIpChanged = action('onFormPublisherIpChanged', (value: IPRange[], mode: FormState) => ({
  value,
  mode,
}));

export const onFormPublisherIpRangesChanged = action('onFormPublisherIpRangesChanged', (value?: IPRange[]) => ({
  value,
}));

export const onFormPublisherAccountName = action('onFormPublisherAccountName', (value: string) => ({
  value,
}));

export const onFormPublisherAccountLanguage = action('onFormPublisherAccountLanguage', (value: IDropdownOption) => ({
  value,
}));

export const onFormPublisherAccountCountry = action('onFormPublisherAccountCountry', (value: IDropdownOption) => ({
  value,
}));

export const onFormPublisherAccountCurrency = action('onFormPublisherAccountCurrency', (value?: string) => ({ value }));

export const onFormPublisherAccountTimezoneChanged = action('onFormPublisherAccountTimezoneChanged', (value?: IDropdownOption) => ({
  value,
}));

export const onFormInitialized = action('onFormInitialized', (mode: FormState, publisherId?: string, entityId?: string) => ({
  mode,
  publisherId,
  entityId,
}));

export const onFormEditted = action('onFormEditted', (routeHistory?: History) => ({ routeHistory }));

export const onFormCancelled = action('onFormCancelled');

export const onFormCleared = action('onFormCleared');

export const onFormCreated = action('onFormCreated', (routeHistory?: History) => ({ routeHistory }));

export const onPanelCleared = action('onPanelCleared');

export const onPublisherCreated = action('onPublisherCreated', (publisher: PublisherCreate) => ({ publisher }));
