import { getTheme, IStackTokens, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../theme';

type StyleProperties = 'contentStyle' | 'markerWrapper' | 'listItem' | 'panelButton' | 'columnTooltip' | 'columnCheckboxWrapper';
type ChartMetricPickerStyles = Record<StyleProperties, string>;
const theme = getTheme();
const spacing = theme.spacing as IPubcenterSpacing;
const palette = theme.palette as IPubcenterPalette;
export const getStyles = (): ChartMetricPickerStyles => {
  return mergeStyleSets({
    contentStyle: { display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' },
    markerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: palette.neutralLighter,
      },
      minWidth: '75px',
    },
    listItem: {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'block',
      minHeight: '30px',
      padding: spacing.size12,
      ':hover': {
        backgroundColor: palette.neutralLighter,
      },
    },
    panelButton: {
      display: 'flex',
      selectors: {
        '.ms-Button': {
          marginRight: spacing.size8,
        },
      },
    },
    columnTooltip: {
      paddingTop: spacing.size8,
      paddingBottom: spacing.size8,
      marginBottom: spacing.size8,
    },
    columnCheckboxWrapper: {
      display: 'flex',
      gap: spacing.size8,
      alignItems: 'center',
    },
  });
};

export const customStyles = {
  addMetricButtonRootStyle: { width: '100%' },
  sidePanelStyles: {
    header: {
      marginBottom: spacing.size24,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    footer: {
      borderTopStyle: 'solid',
      borderColor: theme.palette.neutralLight,
      borderTopWidth: 1,
    },
  },
  columnCheckbox: {
    root: {
      paddingTop: spacing.size8,
      paddingBottom: spacing.size8,
      marginBottom: spacing.size8,
    },
  },
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles();
}
export function getStackTokens(): IStackTokens {
  return { childrenGap: spacing.size12 };
}
