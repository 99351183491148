import { mutator } from 'satcheljs';

import { onFilterPanelHide, onFilterPanelShown } from '../actions';
import { getStore } from '../store/store';

mutator(onFilterPanelShown, (msg) => {
  const store = getStore();
  store.filterPanel = msg.filterPanel;
});

mutator(onFilterPanelHide, () => {
  const store = getStore();
  store.filterPanel = undefined;
});
