import { TextField } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { FormItem } from '../../../../../../components/Form';
import { injectIntlAndObserver } from '../../../../../../utils';
import messages from './messages';

export interface IPropertyIdFieldProps {
  id?: number;
}

const PropertyIdField = injectIntlAndObserver<InjectedIntlProps & IPropertyIdFieldProps>(({ intl: { formatMessage }, id }) => {
  if (id === undefined) {
    return null;
  }

  return (
    <FormItem>
      <TextField label={formatMessage(messages.propertyId)} required={true} disabled={true} value={String(id)} />
    </FormItem>
  );
});

export default PropertyIdField;
