import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  getEntity,
  ISubGeography,
  NetworkAffiliation,
  onLoadingChanged,
  onLoadingSetting,
  onLoadingWaiting,
  onNotificationChanged,
  Partner,
  Publisher,
} from '../../../../../@data';
import {
  getSupportedCountries,
  getSupportedLanguages,
  getSupportedSubGeographies,
  getSupportedTimezones,
} from '../../../../../@data/services/MetadataService';
import { getAppStore } from '../../../../../@data/store/store';
import { FormState } from '../../../../../components/Form';
import { setPublisherContext } from '../../../@data/service/PartnerManagementService';
import { onFormInitialized, onPublisherChanged, onPublisherErrorChanged, onRawChanged } from '../actions';
import '../mutators/PublisherChanged';
import { IPublisherViewModel } from '../store/schema/IPublisherViewModel';

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();

  try {
    onLoadingChanged(true);
    onLoadingSetting(false);
    const timeout = 30000;
    const id = setTimeout(() => onLoadingWaiting(true), timeout);

    const metadata = await Promise.all([
      getSupportedSubGeographies('US'),
      getSupportedCountries(),
      getSupportedLanguages(),
      getSupportedTimezones(),
    ]);

    clearTimeout(id);

    const defaultUSStates = metadata[0];
    const viewModel: IPublisherViewModel = {
      availableCountries: metadata[1],
      accountAvailableLanguages: metadata[2],
      availableTimezones: metadata[3],
      partner: Partner.Yahoo,
      country: 'US',
      availableStates: defaultUSStates,
      state: 'AL',
      networkAffiliation: NetworkAffiliation.OwnedAndOperated,
      accountLanguage: 'EN',
      accountCountry: 'US',
      accountCurrency: 'USD',
      accountTimezoneId: 75,
    };

    if (msg.entityId && !isNaN(parseInt(msg.entityId, 10))) {
      const publisher = new Publisher(parseInt(msg.entityId, 10));

      setPublisherContext(publisher);
      onPublisherErrorChanged();

      if (msg.mode === FormState.Edit) {
        const raw = await getEntity<Publisher>([publisher], userContext);

        let availableStates: ISubGeography[] = [];

        if (raw.address && raw.address.country) {
          availableStates = await getSupportedSubGeographies(raw.address.country);
        }

        viewModel.partner = raw.partner;
        viewModel.id = raw.id;
        viewModel.name = String(raw.name);
        viewModel.networkAffiliation = raw.networkAffiliation;
        viewModel.addressLine1 = raw.address ? raw.address.line1 : '';
        viewModel.addressLine2 = raw.address ? raw.address.line2 : '';
        viewModel.city = raw.address ? raw.address.city : '';
        viewModel.state = raw.address ? raw.address.stateOrProvince : '';
        viewModel.availableStates = availableStates;
        viewModel.country = raw.address ? raw.address.country : '';
        viewModel.postalcode = raw.address ? raw.address.postalCode : '';
        viewModel.publisherTier = raw.publisherTier;
        viewModel.overrideFeatureTier = raw.overrideFeatureTier;
        viewModel.entityLimitTier = raw.entityLimitTier;
        viewModel.serviceLevel = raw.serviceLevel;
        viewModel.allowWebAdUnit = raw.allowWebAdUnit;
        viewModel.allowMobileSearchAdUnit = raw.allowMobileSearchAdUnit;
        viewModel.allowDistributionReporting = raw.allowDistributionReporting;
        viewModel.allowDistributionV2Reporting = raw.allowDistributionV2Reporting;
        viewModel.allowStartDistributionReporting = raw.allowStartDistributionReporting;
        viewModel.allowStartDistributionContentReporting = raw.allowStartDistributionContentReporting;
        viewModel.allowSelectTierReporting = raw.allowEndorReporting;
        viewModel.allowMediationReporting = raw.allowMediationReporting;
        viewModel.allowNativeReporting = raw.allowNativeReporting;
        viewModel.allowSyndicationReporting = raw.allowSyndicationReporting;
        viewModel.propertyAutoApproved = raw.propertyAutoApproved;
        viewModel.allowPaidSearchReportingApi = raw.allowPaidSearchReportingApi;
        viewModel.allowPaidSearchReportingDashboard = raw.allowPaidSearchReportingDashboard;
        viewModel.allowDynamicPropertyRegistration = raw.allowDynamicPropertyRegistration;
        viewModel.enableDefaultTythonV2Reporting = raw.enableDefaultTythonV2Reporting;
        viewModel.ipAddressGrid = raw.ipRanges;
        viewModel.financialStatus = raw.financialStatus;
        viewModel.allowedMarkets = raw.allowedMarkets;

        onRawChanged(raw);
      } else {
        onRawChanged();
      }
    }

    onPublisherChanged(viewModel);
  } catch (e) {
    onNotificationChanged({
      text: 'Failed to load entity',
      messageBarType: MessageBarType.severeWarning,
    });
  } finally {
    onLoadingChanged(false);
  }
});
