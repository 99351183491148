import { getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubcenterTheme } from '../../../theme';
import { StepperDirection } from '../../components/Stepper/Stepper.types';
import { IDetailPageWithStepperStyleProps, IDetailPageWithStepperStyles } from './DetailPageWithStepper.types';

const STEPPER_CONTAINER_WIDTH = 270;

export const getStyles = (props: IDetailPageWithStepperStyleProps): IDetailPageWithStepperStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { fonts, spacing, palette } = theme;
  const direction = props.direction;
  const { ResponsiveSmall, ResponsiveMedium } = BreakPoints;

  return mergeStyleSets({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: '1',
    },
    navBackButton: [
      'ms-navBackButton',
      {
        display: 'flex',
        backgroundColor: palette.neutralLighter,
        height: spacing.size44,
        alignItems: 'center',
      },
    ],
    mainContainer: {
      display: 'flex',
      flex: '1',
      flexDirection: direction === StepperDirection.Horizontal ? 'column' : 'row',
      alignItems: direction === StepperDirection.Horizontal ? 'center' : 'stretch',
      [ResponsiveMedium]: {
        display: 'block',
      },
      [ResponsiveSmall]: {
        display: 'block',
      },
    },
    leftPane: {
      display: 'flex',
      font: fonts.size14,
      textAlign: 'center',
      backgroundColor: palette.neutralLighterAlt,
      padding: `${spacing.size32} ${spacing.size48} ${spacing.size32} ${spacing.size24}`,
      minWidth: 'max-content',
      maxWidth: STEPPER_CONTAINER_WIDTH,
      [ResponsiveMedium]: {
        backgroundColor: 'transparent',
      },
      [ResponsiveSmall]: {
        backgroundColor: 'transparent',
      },
    },
    rightPane: {
      flexGrow: '1',
    },
    scrollablePanel: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      height: '90%',
      maxHeight: '75vh',
      width: '100%',
      marginBottom: spacing.size12,
      [ResponsiveMedium]: {
        maxHeight: 'none',
      },
      [ResponsiveSmall]: {
        maxHeight: 'none',
      },
    },
    content: {
      marginLeft: spacing.size48,
      position: 'relative',
      height: 'inherit',
      marginRight: '3rem',
    },
    buttons: {
      borderTop: `1px solid ${palette.neutralLight}`,
      position: 'static',
      bottom: '0',
      display: 'flex',
      padding: `${spacing.size16} 0 ${spacing.size16} ${spacing.size48}`,
      selectors: {
        '.ms-Button': {
          marginRight: spacing.size8,
        },
      },
    },
    subComponentStyles: {
      formTitle: {
        root: {
          marginLeft: spacing.size48,
          marginTop: spacing.size32,
          marginBottom: spacing.size24,
        },
      },
      breadcrumb: {
        root: {
          margin: 0,
          alignItems: 'center',
        },
        item: {
          lineHeight: '22px',
          fontSize: fonts.mediumPlus?.fontSize,
          color: theme.palette.neutralPrimary,
        },
        chevron: {
          lineHeight: '22px',
          color: palette.neutralPrimary,
        },
      },
    },
  });
};

export function getClassNames<PropType>(props?: PropType): IDetailPageWithStepperStyles {
  return getStyles({ theme: getTheme(), ...props });
}
