import { Overlay, Spinner, mergeStyleSets } from '@fluentui/react';
import React from 'react';

export interface IRedirectionSpinnerStyles {
  overlayStyle: string;
  spinnerStyle: string;
}

const getStyles = () =>
  mergeStyleSets({
    overlayStyle: {
      flex: 1,
      display: 'flex',
      position: 'fixed',
      zIndex: 9999999,
    },
    spinnerStyle: {
      flex: 1,
      display: 'flex',
      selectors: {
        '.ms-Spinner-circle': {
          height: 67,
          width: 67,
          borderWidth: '4px',
        },
        '.ms-Spinner-label': {
          color: '#000',
          fontSize: '24px',
          fontWeight: 'bold',
        },
      },
    },
  });

export const RedirectionSpinner = ({ label }) => {
  const { overlayStyle, spinnerStyle } = getStyles();
  return (
    <>
      <Overlay className={overlayStyle}>
        <Spinner className={spinnerStyle} label={label} labelPosition="bottom" />
      </Overlay>
    </>
  );
};
