import { Property } from '../../../../../@data';

export const getSiteSelectionSummary = (properties: Property[], propertyId?: number): string | undefined => {
  if (!propertyId) {
    return undefined;
  }

  const property = properties.find((p) => p.id === propertyId);

  return property ? `${property.id} - ${property.url}` : undefined;
};
