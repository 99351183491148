import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../../../../theme';
import { ICommonStyleProps } from '../../../../../types';

type StyleProperties = 'dateInCurrentMonth' | 'dateNotInCurrentMonth' | 'dateSelected' | 'dateSelectedInBetween' | 'disabledDate';

type SingleDayStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SingleDayStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  const day = {
    padding: spacing.size8,
    borderRadius: 2,
    cursor: 'pointer',
  };

  return mergeStyleSets({
    dateNotInCurrentMonth: {
      ...day,
      color: '#737373',
      cursor: 'not-allowed',
    },
    disabledDate: {
      ...day,
      color: '#737373',
      cursor: 'not-allowed',
    },
    dateInCurrentMonth: {
      ...day,
      background: palette.neutralLight,
      '&:hover': {
        color: palette.white,
        background: palette.themePrimary,
      },
    },
    dateSelected: {
      ...day,
      background: palette.themePrimary,
      color: palette.white,
    },
    dateSelectedInBetween: {
      ...day,
      background: '#CBE7F2',
    },
  });
};

export function getClassNames<PropType>(props?: PropType): SingleDayStyles {
  return getStyles({ theme: getTheme(), ...props });
}
