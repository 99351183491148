import { IStyle } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../theme';

export interface INotificationLayoutStyles {
  dialogRoot: IStyle;
  dialogTitle: IStyle;
  dialogContent: IStyle;
  dialogWrapper: IStyle;
  imgWrapper: IStyle;
  dialogButtonCancel: IStyle;
}

export const getStyles = (props) => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    dialogRoot: {
      // overwrite fluentUI responsive size
      width: `${spacing.size480} !important`,
      minWidth: `30vw !important`,
      maxWidth: `80vw !important`,
      '.ms-Dialog-header': {
        display: 'none',
      },
      '.ms-Dialog-inner': {
        paddingTop: `${spacing.size24}`,
      },
    },
    dialogWrapper: {
      minHeight: spacing.size128,
    },
    dialogContent: {
      height: '100%',
      paddingLeft: spacing.size12,
    },
    imgWrapper: {
      alignSelf: 'center',
      img: { width: spacing.size72 },
    },
  };
};
