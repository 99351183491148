import { action, orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import {
  Property,
  Publisher,
  PublisherTier,
  getAppStore,
  getEntity,
  isTythonSelfServeUser,
  listEntity,
  showErrorToastNotification,
} from '../../../../../../@data';
import { fetchPropertiesServingConfigs } from '../../../../AdBlock/@data/services';
import PropertyMessages from '../../../Property.messages';
import { setFetchingData, setLoading, setPropertiesFetching, setPropertiesServingConfigs, setPropertyData } from '../mutatorActions';
import { getGridPropertyStore } from '../store/store';

export const onManagePageInitialized = action('onManagePageInitialized');

orchestrator(onManagePageInitialized, async () => {
  try {
    const { publisher, userContext } = getAppStore();
    const properties = getGridPropertyStore().data;

    if (publisher && userContext) {
      if (properties === undefined || properties.length === 0) {
        setLoading(true);
      }
      setFetchingData(true);
      setPropertiesFetching(true);

      const data = await listEntity([publisher], userContext, Property);

      let publisherFullInfo = {} as Publisher;
      if (!isTythonSelfServeUser()) {
        publisherFullInfo = await getEntity<Publisher>([publisher], userContext);
      }

      // Check for self serve user or publisher service level if publisher is being searched from Internal UI
      // This is because only self serve publishers have property serving configs that need to be fetched
      if ((isTythonSelfServeUser() || publisherFullInfo.publisherTier === PublisherTier.SelfServe3rdParty) && data.length > 0) {
        const propertyServingConfig = await fetchPropertiesServingConfigs(publisher);
        data.forEach((property) => {
          const propertyServingConfigForProperty = propertyServingConfig.find((config) => config.propertyId === property.id);
          if (propertyServingConfigForProperty) {
            property.servingConfig = propertyServingConfigForProperty;
          }
        });
        setPropertiesServingConfigs(propertyServingConfig);
      }

      setPropertyData(data.sort((a, b) => b.id - a.id));
      setLoading(false);
    }
  } catch (error) {
    Log.error(error);
    showErrorToastNotification({ textMessageDescriptor: PropertyMessages.propertiesFetchFailed });
  } finally {
    setLoading(false);
    setFetchingData(false);
    setPropertiesFetching(false);
  }
});
