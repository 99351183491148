import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import { Publisher, getAccessPermission, getAppStore } from '../../../../../@data';
import { hasUpdateAccess } from '../../../../../@data/services/UserRoleService';
import AppMessages from '../../../../../App.messages';
import { IGridV2Column } from '../../../../../layout/GridPageV2';
import messages from '../ManagePage.messages';

export function getGridColumns(formatMessage, isWindowRegularSize, routeHistory): IGridV2Column[] {
  const { publisher } = getAppStore();

  const columns: IGridV2Column[] = [
    {
      columnOrder: 1,
      key: 'name',
      fieldName: 'name',
      name: formatMessage(messages.name),
      minWidth: 200,
      maxWidth: 200,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 2,
      key: 'id',
      fieldName: 'id',
      name: formatMessage(messages.id),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
    },
    {
      columnOrder: 3,
      key: 'status',
      fieldName: 'status',
      name: formatMessage(AppMessages.status),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
    },
    {
      columnOrder: 4,
      key: 'actions',
      name: formatMessage(messages.actions),
      minWidth: 150,
      maxWidth: 150,
      isPadded: true,
      isSearchable: true,
      isVisible: true,
      suppressSorting: true,
      onRender: (item: Publisher) => {
        const accessPermission = getAccessPermission(item);

        return (
          <CommandBarButton
            id={`adunitCallout-${item.id}`}
            styles={{ root: { background: 'transparent' } }}
            iconProps={{ iconName: 'edit' }}
            ariaLabel={formatMessage(AppMessages.edit)}
            text={isWindowRegularSize ? formatMessage(AppMessages.edit) : ''}
            disabled={!hasUpdateAccess(accessPermission) || publisher === undefined}
            onClick={() => routeHistory.push(`/partner-management/${publisher?.id}/publisher/edit/${item.id}`)}
          />
        );
      },
    },
  ];

  return columns;
}
