import { defineMessages } from 'react-intl';

export default defineMessages({
  backButtonAriaDesc: {
    id: 'detailPage.backButtonAriaDesc',
    defaultMessage: 'Click to go back to Dashboard',
  },
  breadcrumbAriaLabel: {
    id: 'detailPage.breadcrumbAriaLabel',
    defaultMessage: 'Bread crumb showing the heirarchy of reports',
  },
  exportButton: {
    id: 'detailPage.exportButton',
    defaultMessage: 'Export',
  },
  removeBreakdown: {
    id: 'detailPage.removeBreakdown',
    defaultMessage: 'Remove current break down',
  },
  generatingAndExportingPNG: {
    id: 'detailsPage.generatingAndExportingPNG',
    defaultMessage: 'Generating and exporting PNG',
  },
});
