import { getTheme, IStackTokens, ITheme, mergeStyleSets } from '@fluentui/react';
import { AccessibilityContrast, IPubcenterPalette, IPubcenterTheme } from '../../../../../../theme';
export interface IEstimatedEarningsStyleProps {
  theme: ITheme;
}

type StyleProperties = 'activeButton' | 'buttonBorder' | 'chartTypes' | 'cardContainer';
type EstimatedEarningsStyles = Record<StyleProperties, string>;

export const getStyles = (props: IEstimatedEarningsStyleProps): EstimatedEarningsStyles => {
  const { theme } = props;
  const palette = theme.palette as IPubcenterPalette;
  const { Activated } = AccessibilityContrast;

  return mergeStyleSets({
    activeButton: {
      backgroundColor: palette.themePrimary,
      color: palette.themeLight,
      ':hover': {
        backgroundColor: palette.themePrimary,
        color: palette.themeLight,
      },
      ':active': {
        backgroundColor: palette.themePrimary,
        color: palette.themeLight,
      },
      [Activated]: {
        color: 'window',
        backgroundColor: 'windowtext',
        borderColor: 'windowtext',
        forcedColorAdjust: 'none',
      },
    },
    buttonBorder: {
      border: '1px solid',
      borderRadius: '2px',
      borderColor: palette.neutralLight,
    },
    chartTypes: {
      marginRight: '15px !important',
    },
    cardContainer: {
      height: '100%',
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
