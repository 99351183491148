import { getTheme, mergeStyleSets } from '@fluentui/react';
import { BreakPoints, IPubCenterFonts, IPubcenterSpacing } from '../../../../theme';

export interface ILoginPageStyles {
  root: string;
  main: string;
  title: string;
  email: string;
  button: string;
}

export const getClassNames = (): ILoginPageStyles => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const fonts = theme.fonts as IPubCenterFonts;
  const { size16 } = fonts;

  return mergeStyleSets({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    main: {
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: spacing.size360,
      gap: spacing.size12,
      alignItems: 'center',
      textAlign: 'center',
      '@media(max-width: 360px)': {
        width: '90%',
      },
    },
    title: {
      ...size16,
      fontWeight: 600,
    },
    email: {
      ...size16,
      fontWeight: 'normal',
    },
    button: {
      width: spacing.size320,
      [BreakPoints.ResponsiveSmall]: {
        width: '90%',
      },
    },
  });
};
