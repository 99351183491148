import { action, orchestrator } from 'satcheljs';

import { Log } from '../../../../../../../../../logging/src';
import {
  ApiError,
  ClarityType,
  NotificationLabel,
  NotificationSeverity,
  Property,
  PropertyApprovalStatus,
  getAppStore,
  listEntity,
  onNavigateBack,
  onNotificationCardAdded,
  onSavingChanged,
  postEntity,
  putEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../../../../@data';
import notificationMessages from '../../../../../../../../NotificationsPanel.Types.messages';
import { FormState } from '../../../../../../../../components/Form';
import { StepState } from '../../../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { RouteName } from '../../../../../../@data';
import { createReturnUrl } from '../../../../../../@data/services/AdManagementService';
import { getGridPropertyStore } from '../../../../../Manage/__data__/store/store';
import PropertyMessages from '../../../../../Property.messages';
import { onIdChanged, onSettingClarityErrorMessage } from '../../../../__data__/mutatorActions';
import { getCreatePropertyStore } from '../../../../__data__/store/store';
import { isFormValid } from '../../utils/validateForm';

export const onFormSubmitted = action(
  'onFormSubmitted',
  (mode: FormState, routeHistory?, onSubmit?: () => void, stepState?: StepState) => ({
    mode,
    routeHistory,
    onSubmit,
    stepState,
  })
);

/**
 * 1) Set Loading to true
 * 2) Submit API call to Submit Data to Store
 * 3) Check errors, if any, send action to notification that error exists
 * 4) Clear the form
 * 5) Navigate back to property list
 */
orchestrator(onFormSubmitted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { active, raw } = getCreatePropertyStore();

  if (publisher && raw) {
    const body: Property = {
      ...raw,
      id: active.id,
      name: active.name,
      approvalStatus: PropertyApprovalStatus.Approved,
      approvalStatusComments: '',
      associatedAdUnitIds: [],
    };

    onSavingChanged(true);

    const properties = getGridPropertyStore().data || (await listEntity([publisher], userContext, Property));
    const isEditOperation =
      msg.mode === FormState.MultiStepEdit || (msg.mode === FormState.MultiStepCreation && msg.stepState === StepState.Dirty);

    const isValid = await isFormValid(properties, isEditOperation, active);
    if (!isValid) {
      onSavingChanged(false);
      return;
    }

    if (publisher.id) {
      body.publisherId = publisher.id;
    }

    if (active.url) {
      body.url = active.url;
    }

    if (active.clarity) {
      if (active.clarity.clarityType !== ClarityType.None) {
        body.clarityType = active.clarity.clarityType;
      } else {
        body.clarityProjectIdForTython = active.clarity.clarityId;
      }
    }

    try {
      let property: Property | undefined;

      if (isEditOperation) {
        property = await putEntity([publisher], body, userContext, Property);
        showSuccessToastNotification({
          textMessageDescriptor: PropertyMessages.propertyEditSuccess,
          intlMessageValues: { entityId: property.id },
        });
      } else {
        property = await postEntity([publisher], Property, body, userContext);
        if (property && property.id) {
          onIdChanged(property.id);
        }
        showSuccessToastNotification({
          textMessageDescriptor: PropertyMessages.propertyCreateSuccess,
          intlMessageValues: { entityId: property.id },
        });
        if (active.clarity?.clarityType === ClarityType.None) {
          showSuccessToastNotification({
            textMessageDescriptor: PropertyMessages.clarityRequestSuccess,
          });
        }
      }

      if (msg.mode !== FormState.InPageCreate) {
        // return to manage page
        const returnUrl = createReturnUrl(RouteName.property, String(publisher.id), undefined, String(property.id), msg.mode);
        onNavigateBack(msg.routeHistory, returnUrl);
      }

      msg.onSubmit?.();
    } catch (e) {
      const getClarityErrorMessage = (e: ApiError) => {
        if (e.code === 404) {
          return 'Clarity project ID does not exist';
        } else {
          return e.message.split(`description":"`)[1].split(`"`)[0];
        }
      };

      Log.error(e);
      const apiErrorObj = e as ApiError;
      if (apiErrorObj.code === 404) {
        onSettingClarityErrorMessage(getClarityErrorMessage(apiErrorObj));
      } else if (apiErrorObj.code === 500) {
        showErrorToastNotification({ textMessageDescriptor: notificationMessages.PropertyEditFailedNotificationContent });
      } else {
        onSettingClarityErrorMessage(getClarityErrorMessage(apiErrorObj));
      }

      onNotificationCardAdded({
        // tslint:disable-next-line: no-any
        severity: NotificationSeverity.Error,
        titleMessageDescriptor: notificationMessages.PropertyEditFailedNotificationTitle,
        contentMessageDescriptor: notificationMessages.PropertyEditFailedNotificationContent,
        notificationLabel: NotificationLabel.PropertyEditFailedNotification,
      });
    } finally {
      onSavingChanged(false);
    }
  } else {
    showErrorToastNotification({
      textMessageDescriptor: PropertyMessages.invalidForm,
      intlMessageValues: { formOperation: FormState[msg.mode], entityId: active.id },
    });
  }
});
