import { getAppStore, Property, Publisher, putEntity, UserContext } from '../../../../../@data';
import { PropertyServingConfig } from '../../../../../@data/store/schema/models/ServingConfigs';

export async function updatePropertyServingConfig(
  publisher: Publisher,
  property: Property,
  body: PropertyServingConfig,
  userContext?: UserContext
): Promise<PropertyServingConfig> {
  const effectiveUserContext = userContext || getAppStore().userContext;
  const propertyServingConfig = await putEntity([publisher, property], body, effectiveUserContext, PropertyServingConfig);
  return propertyServingConfig;
}
