import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class PublisherSettings extends Model {
  public className = UserRoleEntity.PublisherSettings;
  public mediation: number;
  public publisherPreferences: number;
  public publisherDelete: number;

  constructor(params: PublisherSettings) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: PublisherSettings, publisherSettings: PublisherSettings) {
    // tslint:disable:no-bitwise
    this.accessPermission |= publisherSettings.accessPermission;
    this.mediation |= publisherSettings.mediation;
    this.publisherPreferences |= publisherSettings.publisherPreferences;
    this.publisherDelete |= publisherSettings.publisherDelete;
    // tslint:enable:no-bitwise
  }
}
