import { mutator } from 'satcheljs';
import { onIsAcceptInviteFlowChanged, onUserInvitationCodeChanged } from '..';
import { Log } from '../../../logging/src';
import { StorageIsUserInviteFlowKey, StorageUserInvitationCodeFlowKey, getAppStore } from '../store/store';

mutator(onIsAcceptInviteFlowChanged, (msg) => {
  Log.debug('Setting IsAcceptInviteFlow in cache as: ' + msg.isAcceptInviteFlow);
  localStorage.setItem(StorageIsUserInviteFlowKey, JSON.stringify(msg.isAcceptInviteFlow));
});

mutator(onUserInvitationCodeChanged, (msg) => {
  Log.debug('UserInvitationCode changed: ' + msg.invitationCode);
  if (msg.invitationCode !== 'redirect') {
    localStorage.setItem(StorageUserInvitationCodeFlowKey, msg.invitationCode);
    getAppStore().userInvitationCode = msg.invitationCode;
  }
});
