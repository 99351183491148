import { createStore } from 'satcheljs';
import '../orchestrators';
import { IAdManagementViewState } from './schema/IAdManagementViewState';

export enum RouteName {
  default = 'default',
  adunit = 'adunit',
  channel = 'channel',
  adfilter = 'adfilter',
  property = 'property',
  adquality = 'adquality',
  home = 'home',
  inventory = 'inventory',
  ads = 'ads',
  dashboard = 'dashboard',
  adblock = 'adblock',
  publisherProfile = 'publisherProfile' /* Tython account/profile management tab */,
  userSettings = 'userSettings',
  reportDetails = 'report-details',
  support = 'support',
  auditHistory = 'auditHistory',
}

export const PUBLISHER_BASED_ROUTES = [
  RouteName.property,
  RouteName.adquality,
  RouteName.home,
  RouteName.adblock,
  RouteName.dashboard,
  RouteName.publisherProfile,
];

export const defaultRoute = RouteName.adunit;

export const getAdManagementStore = createStore<IAdManagementViewState>('AdManagementViewState', {
  panelIsOpen: false,
  doNotRenderDefaultPage: false,
});
