import { orchestrator } from 'satcheljs';
import { APP_NAME_NEW, PUBCENTER_TITLE } from '../../../../constants/AppConstants';
import { isLocationPubCenter } from '../../../../utils';
import { getRoutes } from '../../ReportRouter';
import { onCurrentRouteChanged } from '../actions';

orchestrator(onCurrentRouteChanged, ({ currentRoute, formatMessage }) => {
  const pageName = formatMessage ? getRoutes(formatMessage).find((route) => route.key === currentRoute)?.text : currentRoute.toString();
  const appName = isLocationPubCenter() ? PUBCENTER_TITLE : APP_NAME_NEW;
  document.title = `${appName} - ${pageName}`;
});
