import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { getAppStore, isPublisherProfileDataPresent, onLoadingChanged, showErrorToastNotification } from '../../../../../@data';
import { getXPayJwtToken } from '../../../../../@data/services/PaymentsService';
import { XPayJwtTokenRequest, getPrimaryUserId } from '../../../../../@data/store';
import { fetchAccountById } from '../../../../partner-management/Account/@data/services/AccountService';
import { fetchPublisherEvaluationStatusAction } from '../../../@data/actions';
import { setXpayJwtToken, setXpayJwtTokenExpirationTime, setXpayJwtTokenStatusIsLoading } from '../../../Account/@data/actions';
import messages from '../../ProfilePage.messages';
import { fetchPublisherProfileDetailsAction, fetchUserDetailsAction, onInitializeProfilePage } from '../actions';
import { setLoadingStateAction } from '../actions/mutatorActions';
import { isPrimaryUserDataPresent } from '../store/selectors';

orchestrator(onInitializeProfilePage, async ({ publisherId, countryCode }) => {
  setLoadingStateAction(true);
  onLoadingChanged(true);

  try {
    fetchPublisherProfileDetailsAction(publisherId);

    // Action to trigger GET user details API call
    // For Tython, primary user is the current logged in user
    // But this will change when user-management is implemented and multiple users are supported
    const primaryUserId = getPrimaryUserId();
    if (!primaryUserId) {
      throw new Error('User not found. Please login again.');
    }
    fetchUserDetailsAction(primaryUserId);

    if (!getAppStore().publisherEvaluationStatus || getAppStore().isPublisherEvaluationStatusFetchFailed) {
      fetchPublisherEvaluationStatusAction();
    }

    setXpayJwtTokenStatusIsLoading(true);
    const userContext = getAppStore().userContext;
    const publisher = getAppStore().publisher;
    const accountId = getAppStore().account?.id;
    const account = await fetchAccountById(publisher!, accountId, userContext);
    const xPayJWTRequest = new XPayJwtTokenRequest();
    xPayJWTRequest.publisherId = publisher?.id!;
    xPayJWTRequest.countryCode = account.countryCode || countryCode;
    const tokenResponse = await getXPayJwtToken(xPayJWTRequest, userContext!);
    const tokenExpirationTime = new Date();
    tokenExpirationTime.setMinutes(tokenExpirationTime.getMinutes() + 5);
    setXpayJwtToken(tokenResponse.xpayJwtToken, xPayJWTRequest);
    setXpayJwtTokenExpirationTime(tokenExpirationTime.getTime());

    // wait till the publisher details are fetched and set in the store
    await when(() => {
      return isPrimaryUserDataPresent() && isPublisherProfileDataPresent();
    });
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: messages.publisherProfileDetailsFailed });
    Log.error(`Error while initializing profile page ${error}`);
  } finally {
    setLoadingStateAction(false);
    onLoadingChanged(false);
    setXpayJwtTokenStatusIsLoading(false);
  }
});
