import { object, string } from 'yup';
import { IdNamePairErrorModel } from '../../../../../../@data/store/schema';
import { ArrayObservableSchemaYupType } from '../../../../../../utils/validation';
import { IChannelViewModel } from './IChannelViewModel';

export function channelErrorModel() {
  return object<IChannelViewModel>().shape({
    name: string().trim().required(),
    adunitsSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel).required(),
    filtersSelected: new ArrayObservableSchemaYupType().of(IdNamePairErrorModel)
  });
}
