import { AdTypes, NativeAdProperties } from '../../../../pages/ad-management/Adunit/TythonAdunit/CreateAdunit/types';
import { Model } from './Model';

export class TythonAdUnits extends Model {
  public apiPath = undefined;
  public className = 'adUnitServingConfigs';
  public adunit?: {
    adUnitId?: number;
    accountId: number;
    propertyId: number;
    name: string;
    adTypes: AdTypes;
    nativeProperties: NativeAdProperties;
  };
}
