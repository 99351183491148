import { action } from 'satcheljs';
import { ResourceType } from '../../../../@data/store/schema/enums/ResourceType';
import { ResourceTypeDistribution } from '../../../../@data/store/schema/enums/ResourceTypeDistribution';
import { IGlobalSearchFilter } from '../../../../@data/store/schema/interfaces/IGlobalSearchFilter';
import { IGlobalSearchResultData } from '../../../../@data/store/schema/interfaces/IGlobalSearchResultData';

export const onSearchTriggered = action(
  'onSearchTriggered',
  (
    query: string,
    resourceTypes?: ResourceType[],
    globalFilters?: IGlobalSearchFilter[],
    resourceTypeDistribution?: ResourceTypeDistribution,
    pageSize?: number
  ) => ({
    query,
    resourceTypes,
    globalFilters,
    resourceTypeDistribution,
    pageSize
  })
);

export const setGlobalSearchResult = action('setGlobalSearchResult', (data?: IGlobalSearchResultData) => ({ data }));

export const setSearchQuery = action('setSearchQuery', (searchQuery: string) => ({ searchQuery }));

export const setSearchingStatus = action('setSearchingStatus', (searching: boolean) => ({ searching }));
