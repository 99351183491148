import { orchestrator } from 'satcheljs';
import {
  NotificationLabel,
  NotificationSeverity,
  getAppStore,
  onNotificationCardAdded,
  showErrorToastNotification,
} from '../../../../../@data';
import { getBalance } from '../../../../../@data/services/PaymentsService';
import notificationMessages from '../../../../../NotificationsPanel.Types.messages';
import { getStore } from '../../Settings/@data/store/store';
import { setBalanceDataAction, setBalanceIsDataLoadedAction } from '../actions';
import { fetchPaymentsPageBalance } from '../actions/actions';

orchestrator(fetchPaymentsPageBalance, async () => {
  const userContext = getAppStore().userContext;
  const publisher = getAppStore().publisher;
  try {
    const { systemPreferences } = getStore().active;

    const data = await getBalance(publisher?.id!, systemPreferences?.currency, userContext!);
    setBalanceDataAction(data);
    setBalanceIsDataLoadedAction(true);
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: notificationMessages.LoadingBalanceDataFailedContent });

    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      titleMessageDescriptor: notificationMessages.LoadingBalanceDataFailedTitle,
      contentMessageDescriptor: notificationMessages.LoadingBalanceDataFailedContent,
      notificationLabel: NotificationLabel.LoadingBalanceDataFailed,
    });
  }
});
