import { getAccountStatusData } from './accountStatusStore';
import { getPaymentsPageData } from './paymentsPageStore';

export function getPaymentsPageBalance() {
  return getPaymentsPageData().balance;
}

export function getPaymentsPageEstimatedEarnings() {
  return getPaymentsPageData().estimatedEarnings;
}

export const getAccountStatus = () => getAccountStatusData().status;

export const getAccountStatusIsLoading = () => getAccountStatusData().isLoading;
