import { DefaultButton, DialogFooter, PrimaryButton, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import AppMessages from '../../App.messages';
import { IDestructiveDialogLabelsProps } from '../../layout/GridPageV2';
import { injectIntlAndObserver } from '../../utils';
import { PopupCard } from './PopupCard';
import { getClassNames, getPermanentDeleteButtonStyles } from './PopupCard.styles';

export interface IDeleteConfirmationPopupProps extends InjectedIntlProps {
  onDeleteClicked: () => void;
  onDialogDismissed: () => void;
  isOpen: boolean;
  deleteLabels?: IDestructiveDialogLabelsProps;
  confirmActionMessage?: FormattedMessage.MessageDescriptor;
  deleteButtonText?: FormattedMessage.MessageDescriptor;
}

export const DeleteConfirmationPopup = injectIntlAndObserver(function DeleteConfirmationPopupComp({
  intl: { formatMessage },
  onDeleteClicked,
  onDialogDismissed,
  isOpen,
  deleteLabels,
  confirmActionMessage,
  deleteButtonText,
}: IDeleteConfirmationPopupProps): JSX.Element | null {
  const [confirmationText, setConfirmationText] = useState('');
  const { confirmation } = getClassNames();

  if (!isOpen) {
    return null;
  }

  return (
    <PopupCard
      isShown={isOpen}
      popUpTitle={formatMessage(deleteLabels?.title ?? AppMessages.deleteGenericTitle)}
      popUpDescription={formatMessage(deleteLabels?.description ?? AppMessages.userDeleteWarning)}
      onPopupDismiss={onDialogDismissed}
    >
      <React.Fragment>
        <div className={confirmation}>{formatMessage(confirmActionMessage ?? AppMessages.confirmActionMessage)}</div>
        <TextField value={confirmationText} onChange={(_, val: string) => setConfirmationText(val)} />
        <DialogFooter>
          <PrimaryButton
            text={formatMessage(deleteButtonText ?? AppMessages.permanentDelete)}
            disabled={confirmationText.toLowerCase() !== 'yes'}
            onClick={() => {
              onDeleteClicked();
              onDialogDismissed();
            }}
            styles={getPermanentDeleteButtonStyles()}
          />
          <DefaultButton onClick={() => onDialogDismissed()} text={formatMessage(AppMessages.cancel)} />
        </DialogFooter>
      </React.Fragment>
    </PopupCard>
  );
});
