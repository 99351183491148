import { ActionButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { InjectedIntl } from 'react-intl';
import { INamePair, User } from '../../../../@data';
import { onPopupOpenChanged } from '../../../../@data/mutatorActions';
import { getPrimaryUserId } from '../../../../@data/store';
import { PopupType } from '../../../../@data/store/schema/enums/PopupType';
import AppMessages from '../../../../App.messages';
import { GridCell, getGridClassNames } from '../../../../components/GridWithCustomColumns';
import { IGridV2Column } from '../../../../layout/GridPageV2/GridV2.types';
import messages from '../UserManagement.messages';
import { getUserRoleString } from '../utils/userRoleUtils';
import { setSelectedItem, setUserRoleChangePanelStatus } from './@data/mutatorActions';

export const getUserName = (name?: INamePair, userName?: string) => {
  return name ? name.firstName ?? '' + name.lastName ?? '' : userName ?? '';
};

export function getColumns(intl: InjectedIntl, longestNameLength, longestEmailLength): IGridV2Column[] {
  const { formatMessage } = intl;
  const { gridActionButton, actionButtonsWrapper } = mergeStyleSets(getGridClassNames());
  const primaryUserId = getPrimaryUserId();

  return [
    {
      columnOrder: 0,
      key: 'name',
      name: formatMessage(AppMessages.name),
      ariaLabel: formatMessage(AppMessages.sortByColumnHeaderTooltipLabel, { columnName: formatMessage(AppMessages.name) }),
      minWidth: longestNameLength,
      maxWidth: longestNameLength + 10,
      sortableValue: (item: User) => getUserName(item.name, item.username),
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      onRender: (item: User) => <GridCell>{getUserName(item.name, item.username)}</GridCell>,
    },
    {
      columnOrder: 1,
      key: 'email',
      name: formatMessage(messages.email),
      ariaLabel: formatMessage(AppMessages.sortByColumnHeaderTooltipLabel, { columnName: formatMessage(messages.email) }),
      minWidth: longestEmailLength,
      maxWidth: longestEmailLength + 10,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      sortableValue: (item: User) => item.email || '',
      onRender: (item: User) => <GridCell>{item.email}</GridCell>,
    },
    {
      columnOrder: 2,
      key: 'userRole',
      name: formatMessage(messages.role),
      ariaLabel: formatMessage(AppMessages.sortByColumnHeaderTooltipLabel, { columnName: formatMessage(messages.role) }),
      minWidth: 100,
      maxWidth: 150,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      sortableValue: (item: User) => getUserRoleString(formatMessage, item),
      onRender: (item: User) => <GridCell>{getUserRoleString(formatMessage, item)}</GridCell>,
    },
    {
      columnOrder: 3,
      key: 'actions',
      name: formatMessage(messages.actions),
      minWidth: 200,
      maxWidth: 300,
      suppressSorting: true,
      isPadded: true,
      isVisible: true,
      isResizable: true,
      isExportable: false,
      onRender: (item: User) => {
        return (
          <div className={actionButtonsWrapper}>
            <ActionButton
              className={gridActionButton}
              iconProps={{ iconName: 'PeopleRepeat' }}
              disabled={primaryUserId === item.id}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                setUserRoleChangePanelStatus(true, item);
              }}
            >
              {formatMessage(messages.role)}
            </ActionButton>
            <ActionButton
              className={gridActionButton}
              iconProps={{ iconName: 'delete' }}
              disabled={primaryUserId === item.id}
              onClick={(ev) => {
                onPopupOpenChanged(PopupType.DeleteConfirmation);
                setSelectedItem(item);
              }}
            >
              {formatMessage(AppMessages.delete)}
            </ActionButton>
          </div>
        );
      },
    },
  ];
}
