import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  deleteEntity,
  getAppStore,
  onNotificationChanged,
  showErrorToastNotification,
  showSuccessToastNotification,
  User,
} from '../../../../../../@data';
import { getSuccessNotificationDeleteItem } from '../../../../../../utils';
import messages from '../../../UserManagement.messages';
import { onUsersDeleted } from '../actions/usersTabActions';
import { setUsers } from '../mutatorActions';
import { getUserManagementStore } from '../store';

orchestrator(onUsersDeleted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { selectedItem, users } = getUserManagementStore();

  if (userContext && publisher && selectedItem) {
    const entityInstance = new User(selectedItem.id);
    try {
      const result = await deleteEntity([publisher, entityInstance], undefined, userContext);

      if (result && users) {
        setUsers(users.filter((user) => user.id !== entityInstance.id));
      }

      showSuccessToastNotification({ text: getSuccessNotificationDeleteItem([selectedItem], entityInstance, msg.formatMessage) });
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.userManagementFailedToDeleteItem });
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.userManagementInvalidState,
    });
  }
});
