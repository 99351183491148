import { Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { getAppStore, isTythonSelfServeUser } from '../../../../../../@data';
import AppMessages from '../../../../../../App.messages';
import { IHeaderItem, PanelTypes } from '../../../../../../components/Header/Header.types';
import { CUSTOM_WIDTH } from '../../../../../../constants/AppConstants';
import messages from '../../../../../../layout/GlobalSearchHeader/GlobalSearchHeader.messages';
import { NotificationsPanel, renderNotificationFooterContent } from '../../../../../../layout/PageLayout/header-panels';

interface IHeaderData {
  items?: IHeaderItem[];
}

export const getHeaderItems = (CMUIHeaderMenuItemSerializer, formatMessage) => {
  const notifications = getAppStore().panelNotifications;
  const items: IHeaderItem[] = [
    {
      key: 'notifications',
      icon: 'Notifications',
      title: formatMessage(messages.notifications),
      type: PanelTypes.Notifications,
      panel: NotificationsPanel,
      panelProps: {
        isFooterAtBottom: true,
        onRenderFooterContent: renderNotificationFooterContent,
      },
      shouldOverflowUnderExtraSmallScreen: true,
      showRedDot: notifications && notifications.length > 0,
    },
  ];

  const itemsData: IHeaderData = {
    items: CMUIHeaderMenuItemSerializer(isTythonSelfServeUser(), items),
  };

  return itemsData;
};

export const createPanel = (
  item: IHeaderItem,
  isNotificationPanelOpen: boolean,
  formatMessage,
  setIsNotificationPanelOpen
): JSX.Element => {
  // For icons such as CMUI help icon, panels are integrated with 3rd party libraries no need to render native panel
  if (item && !item.panel) {
    return <></>;
  }

  return (
    <Panel
      headerText={item?.title}
      type={PanelType.custom}
      customWidth={CUSTOM_WIDTH}
      isOpen={isNotificationPanelOpen}
      isLightDismiss={true}
      onDismiss={() => hidePanel(setIsNotificationPanelOpen)}
      closeButtonAriaLabel={formatMessage(AppMessages.close)}
      isFooterAtBottom={item?.panelProps?.isFooterAtBottom}
      onRenderFooterContent={() =>
        item?.panelProps?.onRenderFooterContent?.({ onDismiss: () => hidePanel(setIsNotificationPanelOpen) }) || null
      }
    >
      {item?.panel ? React.cloneElement(<item.panel />, { hidePanel: () => hidePanel(setIsNotificationPanelOpen) }) : <p>Loading...</p>}
    </Panel>
  );
};

export const showPanel = (setIsNotificationPanelOpen) => {
  setIsNotificationPanelOpen(true);
};

const hidePanel = (setIsNotificationPanelOpen) => {
  setIsNotificationPanelOpen(false);
};
