import { useEffect, useRef } from 'react';
import { isTythonSelfServeUser } from '../../@data';
import { onFeatureFlagChanged } from './actions/windowsFeatureFlagActions';
import './mutators/windowsFeatureFlagMutators';
import { useWindowsAppFeatureFlagType } from './types';

const useWindowsAppFeatureFlag: useWindowsAppFeatureFlagType = (userContext, publisher) => {
  const isFeatureFlagInitialized = useRef(false);

  /**
   * TODO: Integrate fetch feature flag by publisher id when api is ready to use
   */
  // const fetchWindowsAppFeatureFlagByPublisherId = async (publisherId: string) => {
  //   const url = `${uiConfig.getApiBaseAddress('1.2')}/publishers/${publisherId}/WindowsAppEnable`;
  //   onLoadingChanged(true);
  //   try {
  //     const headers = await buildRequestHeadersWithAuthToken(url, userContext);
  //     const response = await fetch(url, { method: 'GET', headers });
  //     if (!response.ok) {
  //       console.error(`HTTP error! status: ${response.status}`);
  //     }
  //     const featureFlag = await response.json();
  //     // onFeatureFlagChanged(featureFlag)
  //   } catch (error) {
  //     // mark publisher as disabled for testing 321792029,322468545
  //     // tython-test-user-may13@outlook-int.com, tython-test-user-may22@outlook-int.com
  //     onFeatureFlagChanged(!['321792029', 321792029, '322468545', 322468545].includes(publisher?.id!));
  //     console.error(error);
  //   } finally {
  //     onLoadingChanged(false);
  //   }
  // };

  const ENABLED_PUBLISHERS_PROD = ['253874386'];
  const DISABLED_PUBLISHERS_NON_PROD = ['321792029', '322468545'];
  useEffect(() => {
    if (!isFeatureFlagInitialized.current && userContext && publisher?.id && isTythonSelfServeUser()) {
      isFeatureFlagInitialized.current = true;
      /**
       * TODO: call fetch feature flag once api ready
       * fetchWindowsAppFeatureFlagByPublisherId(publisher.id);
       */
      const isProdEnv = process.env.REACT_APP_ENV === 'production';
      if (isProdEnv) {
        onFeatureFlagChanged(ENABLED_PUBLISHERS_PROD.includes(publisher.id!.toString()));
      } else {
        onFeatureFlagChanged(!DISABLED_PUBLISHERS_NON_PROD.includes(publisher.id!.toString()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext, publisher]);
};

export default useWindowsAppFeatureFlag;
