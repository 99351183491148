import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { useI18n } from '../../../../../i18n/useI18n';
import { getAppStore } from '../../../../@data';
import { injectIntlAndObserver } from '../../../../utils';
import { getStore } from '../../../ad-management/Account/Settings/@data/store/store';
import { TythonColumnsEnum } from '../../@data/store/schema/ColumnsEnum';
import { getReportColumnMetadataDisplayName } from '../../utils/intlHelper';
import { formatMetric } from '../../utils/metricsFormatter';
import { IReportsMetadata, ReportDataSource, TythonReportingDataRow } from '../@data/store/schema';
import { getReportPageStore } from '../@data/store/store';
import { MetricCard } from './MetricCard/MetricCard';
import { ITopMetricsStyleProps, getClassNames } from './MetricsSummaryCards.styles';

export interface ITopMetricsProps extends InjectedIntlProps {
  reportMetadata?: IReportsMetadata;
  summaryData?: TythonReportingDataRow[];
}

export const MetricsSummaryCards = injectIntlAndObserver(({ reportMetadata, summaryData, intl: { formatMessage } }: ITopMetricsProps) => {
  const { reportType } = getReportPageStore();
  const { metricsWrapper, smallCards, cardsColumn } = getClassNames<ITopMetricsStyleProps>({
    evenCards: reportType === ReportDataSource.TythonV2,
  });
  const { publisher, userContext } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });

  const summary = (summaryData && summaryData[0] ? summaryData[0] : { DateTime: '' }) as TythonReportingDataRow;
  const isSummaryDataLaded = !!summaryData;

  return (
    <div className={metricsWrapper}>
      {reportType === ReportDataSource.MSXTython && (
        <div>
          <MetricCard
            isDataLoaded={isSummaryDataLaded && !!reportMetadata}
            title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.Revenue, formatMessage)) || ''}
            dataValue={`${formatMetric(TythonColumnsEnum.Revenue, summary.Revenue, locale)}`}
          />
        </div>
      )}
      <div className={smallCards}>
        {reportType === ReportDataSource.TythonV2 && (
          <div className={cardsColumn}>
            <div>
              <MetricCard
                isDataLoaded={isSummaryDataLaded && !!reportMetadata}
                title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.Revenue, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.Revenue, summary.Revenue, locale)}`}
              />
            </div>
            <div>
              <MetricCard
                isDataLoaded={isSummaryDataLaded && !!reportMetadata}
                title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.FillRate, formatMessage)) || ''}
                dataValue={`${formatMetric(TythonColumnsEnum.FillRate, summary.FillRate, locale)}`}
              />
            </div>
          </div>
        )}
        <div className={cardsColumn}>
          <div>
            <MetricCard
              isDataLoaded={isSummaryDataLaded && !!reportMetadata}
              title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.ImpressionsServed, formatMessage)) || ''}
              dataValue={`${formatMetric(TythonColumnsEnum.ImpressionsServed, summary.ImpressionsServed, locale)}`}
            />
          </div>
          <div>
            <MetricCard
              isDataLoaded={isSummaryDataLaded && !!reportMetadata}
              title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.Viewability, formatMessage)) || ''}
              dataValue={`${formatMetric(TythonColumnsEnum.Viewability, summary.Viewability, locale)}`}
            />
          </div>
        </div>
        <div className={cardsColumn}>
          <div>
            <MetricCard
              isDataLoaded={isSummaryDataLaded && !!reportMetadata}
              title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.ImpressionsViewed, formatMessage)) || ''}
              dataValue={`${formatMetric(TythonColumnsEnum.ImpressionsViewed, summary.ImpressionsViewed, locale)}`}
            />
          </div>
          <div>
            <MetricCard
              isDataLoaded={isSummaryDataLaded && !!reportMetadata}
              title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.CTR, formatMessage)) || ''}
              dataValue={`${formatMetric(TythonColumnsEnum.CTR, summary.CTR, locale)}`}
            />
          </div>
        </div>
        <div className={cardsColumn}>
          <div>
            <MetricCard
              isDataLoaded={isSummaryDataLaded && !!reportMetadata}
              title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.eCPM, formatMessage)) || ''}
              dataValue={`${formatMetric(TythonColumnsEnum.eCPM, summary.eCPM, locale)}`}
            />
          </div>
          <div>
            <MetricCard
              isDataLoaded={isSummaryDataLaded && !!reportMetadata}
              title={(!!reportMetadata && getReportColumnMetadataDisplayName(TythonColumnsEnum.Clicks, formatMessage)) || ''}
              dataValue={`${formatMetric(TythonColumnsEnum.Clicks, summary.Clicks, locale)}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
