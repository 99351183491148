import { IDropdownOption } from '@fluentui/react';
import { AdUnit, isTythonSelfServeUser } from '../../../../../@data';
import AppMessages from '../../../../../App.messages';
import { FilterType, IFilterDetails } from '../../../../../components/GridFilter/GridFilter.types';
import { IFilterItem } from '../../../../../layout/GridPageV2';
import messages from '../ManagePage.messages';

export function getFilterItems(formatMessage, isAppItems = false): IFilterItem[] {
  const nonTythonFilters: IFilterItem[] = [
    { columnName: 'id', filterName: formatMessage(messages.id), filterType: FilterType.relational },
    {
      columnName: 'status',
      filterName: formatMessage(AppMessages.status),
      filterType: FilterType.custom,
      filterOptions: [
        { key: 'Active', text: formatMessage(AppMessages.statusActive) },
        { key: 'Inactive', text: formatMessage(AppMessages.statusInactive) },
      ] as IDropdownOption[],
    },
    { columnName: 'propertyUrl', filterName: formatMessage(messages.propertyUrl), filterType: FilterType.string },
    {
      columnName: 'propertyId',
      filterName: formatMessage(messages.propertyId),
      filterType: FilterType.relational,
    },
    { columnName: 'creationDate', filterName: formatMessage(messages.adUnitCreationDate), filterType: FilterType.date },
    { columnName: 'taxonomy', filterName: formatMessage(messages.businessModel), filterType: FilterType.string },
    {
      columnName: 'marketClassification',
      filterName: formatMessage(messages.marketClassification),
      filterType: FilterType.custom,
      filterOptions: [
        { key: 'OwnedAndOperatedCore', text: formatMessage(messages.ownedAndOperatedCore) },
        { key: 'OwnedAndOperated', text: formatMessage(messages.ownedAndOperated) },
        { key: 'Syndicated', text: formatMessage(messages.syndicated) },
        { key: 'Sponsored', text: formatMessage(messages.sponsored) },
        { key: 'SponsoredEnhanced', text: formatMessage(messages.sponsoredEnhanced) },
      ] as IDropdownOption[],
    },
  ];

  let tythonFilters: IFilterItem[] = [
    { columnName: 'id', filterName: formatMessage(messages.id), filterType: FilterType.relational },
    { columnName: 'name', filterName: formatMessage(messages.name), filterType: FilterType.string },
    {
      columnName: 'status',
      filterName: formatMessage(AppMessages.status),
      filterType: FilterType.custom,
      filterOptions: [
        { key: 'Active', text: formatMessage(AppMessages.statusActive) },
        { key: 'Inactive', text: formatMessage(AppMessages.statusInactive) },
      ] as IDropdownOption[],
    },
    { columnName: 'propertyUrl', filterName: formatMessage(messages.propertyUrl), filterType: FilterType.string },
    {
      columnName: 'mediatedWithAdSense',
      filterName: formatMessage(messages.higherMonetization),
      filterType: FilterType.custom,
      filterOptions: [
        { key: 'true', text: formatMessage(AppMessages.on) },
        { key: 'false', text: formatMessage(AppMessages.off) },
      ] as IDropdownOption[],
      customFunction: (dataRow: AdUnit, filterDetails: IFilterDetails) => {
        if (dataRow.mediatedWithAdSense.mediationStatus?.toString() === filterDetails.filterCondition) {
          return true;
        }
        return false;
      },
    },
  ];

  if (isAppItems) {
    tythonFilters = tythonFilters.filter((f) => !['mediatedWithAdSense', 'propertyUrl'].includes(f.columnName!));
  }

  return isTythonSelfServeUser() ? tythonFilters : nonTythonFilters;
}
