import { array } from 'yup';
import { stringToList } from '../StringUtils';

/**
 * Schema which transform a ',' or '\n' separated string
 * into an array string.
 */
export class StringListTransformerYupType extends array {
  constructor() {
    super();

    this.withMutation(() => {
      this.transform(function(value, originalvalue) {
        if (this.isType(value)) {
          return value;
        }

        return stringToList(originalvalue);
      });
    });
  }
}