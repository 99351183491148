import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { AdUnit, ApiError, ApiErrorCodes, Publisher, getAppStore, getEntity, onNotificationChanged } from '../../../../../../../../@data';
import { AdunitServingScript } from '../../../../../../../../@data/store/schema/models/AdunitServingScript';
import messages from '../../ScriptPage.messages';
import { onScriptChanged, onScriptPageInitialized } from '../actions/onScriptPageActions';

orchestrator(onScriptPageInitialized, async (msg) => {
  const publisherId = parseInt(msg.publisherId, 10);
  const adunitId = parseInt(msg.adunitId, 10);
  const { userContext } = getAppStore();

  if (!isNaN(publisherId) && !isNaN(adunitId)) {
    const publisher = new Publisher(publisherId);
    const adunit = new AdUnit(adunitId);

    try {
      const script = (await getEntity([publisher, adunit], userContext, AdunitServingScript)).toString();
      onScriptChanged(script);
    } catch (e) {
      const errorObj = JSON.parse((e as ApiError).message);
      if (errorObj && errorObj[0].code === ApiErrorCodes.AdUnitDoesNotHaveTemplate) {
        onScriptChanged(null);
      } else {
        onNotificationChanged({
          textMessageDescriptor: messages.failedToFetchScript,
          messageBarType: MessageBarType.severeWarning,
        });
      }
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.inValidStateInScriptPage,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
