import {
  Checkbox,
  ChoiceGroup,
  classNamesFunction,
  Dropdown,
  getTheme,
  IChoiceGroupOption,
  IconButton,
  IDropdownOption,
  IProcessedStyleSet,
  Label,
  Panel,
  PanelType,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import * as React from 'react';
import { Log } from '../../../../../logging/src/Log';
import {
  AdUnitMarketClassification,
  AdUnitServingTier,
  getAppStore,
  NativeAdTemplate,
  onHelpInfoCalloutOpened,
  onHelpInfoChanged,
  onNavigateBack,
  PrivateMarketplace,
  PublisherGroupConfig,
  PublisherTier,
} from '../../../../@data';
import { onPopupOpenChanged } from '../../../../@data/mutatorActions';
import { completeAccess, hasReadAccess, hasUpdateAccess, UserRoleEntity, userRoleKey } from '../../../../@data/services/UserRoleService';
import { AdSenseOAuthStatus } from '../../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { PopupType } from '../../../../@data/store/schema/enums/PopupType';
import { AdUnit as AdUnitAccessPermissions } from '../../../../@data/store/schema/userrolemodels/AdUnit';
import { Property as PropertyAccessPermissions } from '../../../../@data/store/schema/userrolemodels/Property';
import { isTythonSelfServeUser } from '../../../../@data/utils/checkUserType';
import AppMessages from '../../../../App.messages';
import { FormItem, FormSection, FormState } from '../../../../components/Form';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { isCreating, isEditing } from '../../../../components/Form/FormState/formUtils';
import { MultiSelectPicker } from '../../../../components/MultiSelectPicker/MultiSelectPicker';
import { DetailPage } from '../../../../layout/DetailPage/DetailPage';
import { StepState } from '../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { PropertyPicker } from '../../../../layout/PropertyPicker/PropertyPicker';
import { IPropertyPickerItem } from '../../../../layout/PropertyPicker/PropertyPicker.types';
import { PropertySitePicker } from '../../../../layout/PropertySitePicker/PropertySitePicker';
import { injectIntlWithObserver } from '../../../../utils/ReactUtils';
import { getAdManagementStore, RouteName } from '../../@data';
import { onPanelOpenChanged } from '../../@data/actions';
import { createReturnUrl } from '../../@data/services/AdManagementService';
import { getGoogleAdSenseStore } from '../../Account/Settings/components/GoogleConnector/@data';
import { CreateProperty } from '../../Property/Create/variations/CreateNonTythonProperty';
import propertyMessages from '../../Property/Create/variations/CreateNonTythonProperty/messages';
import { getAdunitStore, isPartnerCodeEnabled } from '../@data';
import {
  onAdunitNameChanged,
  onAssociatedPropertyChanged,
  onBusinessModelChanged,
  onChannelsChanged,
  onCreatePropertyPanelOpenChanged,
  onDistributionPartnerCodesChanged,
  onFormCancelled,
  onFormInitialized,
  onFormSubmitted,
  onImpressionFeedbackSignalChanged,
  onMarketplaceChanged,
  onPubGroupConfigChanged,
  onServingTierChanged,
  onStoreIdsChanged,
} from '../@data/actions';
import { onAdSenseMediationToggleChanged, onAdunitPrivateMarketplaceChanged } from '../@data/mutatorActions';
import { setTemplate } from '../@data/mutatorActions/setAdUnitTemplate';
import { BoostJsonFileUpload } from './components/BoostTemplateUploader/BoostJsonFileUpload';
import { OptOutMediationPopup } from './components/DisablingMediationPopup';
import messages from './CreateAdunit.messages';
import { getStyles } from './CreateAdunit.styles';
import { ICreateAdunitProps, ICreateAdunitStyleProps, ICreateAdunitStyles, IFormInitializationState } from './CreateAdunit.types';

export const getClassNames = classNamesFunction<ICreateAdunitStyleProps, ICreateAdunitStyles>();
export const CreateAdunit = injectIntlWithObserver(
  class CreateAdunitPage extends React.Component<ICreateAdunitProps, IFormInitializationState> {
    private _classNames: IProcessedStyleSet<ICreateAdunitStyles>;
    private _tythonUser = isTythonSelfServeUser();

    constructor(props: ICreateAdunitProps) {
      super(props);
      this.state = {
        initializeForm: true,
        downloadTemplateMounted: false,
      };
    }
    public async componentDidMount() {
      const appStore = getAppStore();
      const params = this.props.match.params;
      const { privateMarketplace } = getAdunitStore();

      // Adunit id should be passed as props, if not present in route, incase of InPage modes
      const entityId = params.id || this.props.adunitId;
      this.setState({ id: entityId, privateMarketPlaceCheck: privateMarketplace });

      if (params.publisherId && params.accountId) {
        onFormInitialized(this.props.mode, params.publisherId, params.accountId, entityId);
      } else if (appStore.publisher && appStore.account) {
        onFormInitialized(this.props.mode, appStore.publisher.id, appStore.account.id, entityId);
      }
    }

    public async componentDidUpdate(prevProps, prevState) {
      const { active } = getAdunitStore();

      if (active.BoostTemplate && prevState.privateMarketPlaceCheck !== PrivateMarketplace.Boost) {
        this.setState({ privateMarketPlaceCheck: PrivateMarketplace.Boost });
      }
    }

    public render(): JSX.Element {
      const theme = getTheme();
      this._classNames = getClassNames(getStyles, { theme });
      const { mode, stepState } = this.props;
      const { saving, loading } = getAppStore();

      const { formatMessage } = this.props.intl;
      const publisherId = this.props.match.params.publisherId;
      const accountId = this.props.match.params.accountId || '0';

      const store = getAdunitStore();
      const { helpInfo, locale } = getAppStore();
      const { active } = getGoogleAdSenseStore();

      const marketPlaceDropdownOptions: IDropdownOption[] = [
        {
          key: AdUnitMarketClassification.OwnedAndOperatedCore,
          text: formatMessage(messages.marketPlaceOptionCore),
          ariaLabel: formatMessage(messages.marketPlaceOptionCore),
          title: '',
        },
        {
          key: AdUnitMarketClassification.OwnedAndOperated,
          text: formatMessage(messages.marketPlaceOptionRest),
          ariaLabel: formatMessage(messages.marketPlaceOptionRest),
          title: '',
        },
        {
          key: AdUnitMarketClassification.Syndicated,
          text: formatMessage(messages.marketPlaceOptionSyndicated),
          ariaLabel: formatMessage(messages.marketPlaceOptionSyndicated),
          title: '',
        },
        {
          key: AdUnitMarketClassification.Sponsored,
          text: formatMessage(messages.marketPlaceOptionsSponsored),
          ariaLabel: formatMessage(messages.marketPlaceOptionsSponsored),
          title: '',
        },
        {
          key: AdUnitMarketClassification.SponsoredEnhanced,
          text: formatMessage(messages.marketPlaceOptionsSponsoredEnhanced),
          ariaLabel: formatMessage(messages.marketPlaceOptionsSponsoredEnhanced),
          title: '',
        },
        {
          key: AdUnitMarketClassification.SelectTier,
          text: formatMessage(messages.marketPlaceOptionSelectTier),
          ariaLabel: formatMessage(messages.marketPlaceOptionSelectTier),
          title: '',
        },
      ];

      const businessModelDropdownOptions: IDropdownOption[] = [
        { key: 0, text: formatMessage(messages.businessModelLevel1DefaultOptionText), title: '' },
      ];
      if (store.active.adUnitTaxonomyValues) {
        store.active.adUnitTaxonomyValues
          .filter((a) => !a.parentValue)
          .forEach((a) =>
            businessModelDropdownOptions.push({ key: a.key, text: a.displayName, ariaLabel: a.displayName, title: '' } as IDropdownOption)
          );
      }

      let businessModelLevel1Key = 0,
        businessModelLevel2Key = 0;
      if (store.active.businessModel && store.active.adUnitTaxonomyValues) {
        const selectedBusinessModel = store.active.adUnitTaxonomyValues.filter((a) => a.key === store.active.businessModel)[0];
        if (selectedBusinessModel) {
          if (selectedBusinessModel.parentValue) {
            businessModelLevel2Key = selectedBusinessModel.key;

            const parentBusinessModel = store.active.adUnitTaxonomyValues.filter((a) => a.value === selectedBusinessModel.parentValue)[0];
            businessModelLevel1Key = parentBusinessModel.key;
          } else {
            businessModelLevel1Key = selectedBusinessModel.key;
          }
        } else {
          Log.write('selectedBusinessModel cannot be found or API failed to fetch adunit taxonomy values');
        }
      } else {
        Log.write('adunit taxonomy is null');
      }

      const adunitAccessPermissions = this._getAdUnitAccessPermission();
      const propertyAccessPermissions = this._getPropertyAccessPermission();

      const addProperty = () => {
        onPanelOpenChanged(true);
        onCreatePropertyPanelOpenChanged(true);
      };

      const isValidPublisherForFeedbackSignals = () => {
        const publisherTier = getAppStore().publisher?.publisherTier;
        Log.write('PublisherTier: ' + publisherTier);

        if (
          publisherTier === PublisherTier.InternalPremium ||
          publisherTier === PublisherTier.ManagedPremium ||
          publisherTier === PublisherTier.SelfServe1stPartyBoost
        ) {
          return true;
        }
        return false;
      };

      const renderAdunitDetails = () => (
        <>
          {/** Ad unit name */}
          <FormItem formWidth={440}>
            <TextField
              label={formatMessage(messages.adunitNameLabel)}
              required={true}
              errorMessage={store.errors.name}
              value={store.active.name}
              onChange={(_, value) => onAdunitNameChanged(value)}
              disabled={this._isDisabled(mode, adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.name)}
            />
          </FormItem>
          {/** Ad unit id */}
          {(isEditing(mode) || (isCreating(mode) && stepState === StepState.Dirty)) && (
            <FormItem formWidth={440}>
              <TextField label={formatMessage(messages.adunitId)} required={true} disabled={true} value={String(store.active.id ?? '')} />
            </FormItem>
          )}
          {/** Ad unit site id + site url */}
          {this._tythonUser &&
            hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.websiteURL) &&
            (isEditing(mode) || (isCreating(mode) && stepState === StepState.Dirty)) && (
              <FormItem formWidth={440}>
                <TextField
                  label={formatMessage(messages.site)}
                  required={true}
                  disabled={true}
                  value={
                    loading
                      ? ''
                      : store.active.propertySelected
                      ? store.active.propertySelected.id + ' - ' + store.active.propertySelected.url
                      : formatMessage(messages.siteDefaultNotFound)
                  }
                />
              </FormItem>
            )}
          {/** Ad unit marketplace */}
          {hasReadAccess(adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.markeplaceClassification) && (
            <FormItem>
              <Dropdown
                label={formatMessage(messages.marketplaceLabel)}
                required={true}
                options={marketPlaceDropdownOptions}
                defaultSelectedKey={store.active.marketClassification}
                onChange={(_, value) => onMarketplaceChanged(value!)}
                disabled={this._isDisabled(
                  mode,
                  adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.markeplaceClassification
                )}
              />
            </FormItem>
          )}
          {/** Ad unit business model */}
          {hasReadAccess(adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.businessModel) && (
            <div className={this._classNames.businessModel}>
              <FormItem formWidth={241}>
                <Dropdown
                  label={formatMessage(messages.businessModelLabel)}
                  options={businessModelDropdownOptions}
                  defaultSelectedKey={businessModelLevel1Key}
                  onChange={(_, value) => onBusinessModelChanged(value)}
                  disabled={this._isDisabled(
                    mode,
                    adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.businessModel
                  )}
                />
              </FormItem>
              <FormItem formWidth={241}>
                <div className={this._classNames.businessModelLevel2Dropdown}>
                  <Dropdown
                    className={this._classNames.dropdown}
                    ariaLabel={formatMessage(messages.businessModelLevel2AriaLabel)}
                    options={this._fetchBusinessModelLevel2Options(businessModelLevel1Key)}
                    defaultSelectedKey={businessModelLevel2Key}
                    onChange={(_, value) => {
                      if (value && value.key !== 0) {
                        onBusinessModelChanged(value);
                      }
                    }}
                    disabled={
                      this._isDisabled(mode, adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.businessModel) ||
                      !store.active.businessModel
                    }
                  />
                </div>
              </FormItem>
            </div>
          )}

          {isValidPublisherForFeedbackSignals() && (
            <FormItem formWidth={480}>
              <FormLabel
                id="requiredFeedbackSignalsRadioLabel"
                labelContent={formatMessage(messages.requiredFeedbackSignalsLabel)}
                calloutContent={helpInfo}
                iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AdUnitsFeedbackSignals', locale)}
                calloutOnDismiss={() => onHelpInfoChanged()}
                intl={this.props.intl}
              />
              <Checkbox
                id={formatMessage(messages.requiredImpressionFeedbackSignalOptionLabel)}
                aria-label={formatMessage(messages.requiredImpressionFeedbackSignalOptionLabel)}
                label={formatMessage(messages.requiredImpressionFeedbackSignalOptionLabel)}
                checked={!!store.active.isImpressionFeedbackRequired}
                onChange={(_, value) => onImpressionFeedbackSignalChanged(value)}
                styles={this._classNames.subComponentStyles.checkbox}
              />
            </FormItem>
          )}

          {/** Ad unit Search Distribution Partner Codes */}
          {isPartnerCodeEnabled(publisherId) &&
            hasReadAccess(adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.distributionPartnerCodes) && (
              <FormItem formWidth={440}>
                <div className={this._classNames.distributionPartnerCodes}>
                  <TextField
                    errorMessage={store.errors.distributionPartnerCodes}
                    multiline={true}
                    placeholder={formatMessage(messages.distributionPartnerCodesTextfieldPlaceholder)}
                    description={formatMessage(messages.distributionPartnerCodesLabelDescription)}
                    aria-labelledby="distributionPartnerCodesLabel"
                    onRenderLabel={() => {
                      return (
                        <FormLabel
                          id="distributionPartnerCodesLabel"
                          labelContent={formatMessage(messages.distributionPartnerCodesLabel)}
                          calloutContent={helpInfo}
                          iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_SearchDistributionPartner', locale)}
                          calloutOnDismiss={() => onHelpInfoChanged()}
                          intl={this.props.intl}
                        />
                      );
                    }}
                    value={store.active.distributionPartnerCodes}
                    onChange={(_, value) => onDistributionPartnerCodesChanged(value)}
                    disabled={this._isDisabled(
                      this.props.mode,
                      adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.distributionPartnerCodes
                    )}
                  />
                </div>
              </FormItem>
            )}
          {/** Ad unit channel */}
          {hasReadAccess(adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.channelList) && (
            <FormSection title={formatMessage(messages.channelTitle)} styles={this._classNames.subComponentStyles.formSection}>
              {this._channelList(adunitAccessPermissions)}
            </FormSection>
          )}
          {/** Ad unit property */}
          {/* tslint:disable-next-line: max-line-length */}
          {!this._tythonUser && hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.websiteURL) && (
            <FormSection title={formatMessage(messages.propertySectionTitle)} styles={this._classNames.subComponentStyles.formSection}>
              {this._propertyPicker(adunitAccessPermissions)}
            </FormSection>
          )}
          {/** Ad unit site for Tython */}
          {this._tythonUser &&
            (isCreating(mode) || stepState === StepState.Empty) &&
            hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.websiteURL) &&
            !store.active.id && (
              <FormItem formWidth={550}>
                <div className={this._classNames.siteSection}>
                  {this._propertySitePicker(adunitAccessPermissions)}
                  <button onClick={addProperty} className={this._classNames.button}>
                    {formatMessage(messages.siteButton)}
                  </button>
                </div>
              </FormItem>
            )}
          {/** Ad unit advanced options */}
          {hasReadAccess(adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.servingTier) && (
            <FormSection title={formatMessage(messages.advOptionTitle)} styles={this._classNames.subComponentStyles.formSection}>
              {this._advancedOptions(adunitAccessPermissions)}
            </FormSection>
          )}
          {/** Ad unit property panel */}
          {hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.websiteURL) &&
            this._renderPropertyPanel(store.isPanelOpen)}
          {/** Ad unit type choice group */}
          {/* TODO: remove tython components */}
          {/* {this._tythonUser && isCreating(mode) && store.active.adUnitType && (
            <div className={this._classNames.adTypeContainer}>
              <ChoiceGroup
                label={formatMessage(messages.adunitType)}
                options={renderAdTypeOptions(formatMessage, this._classNames.subComponentStyles.adTypeChoiceGroupOption)}
                required={true}
                selectedKey={store.active.adUnitType}
                onChange={(_, option: IChoiceGroupOption) => {
                  onAdunitTypeChanged(option.key as AdUnitType);
                }}
              />
            </div>
          )} */}
          {/* {this._tythonUser && <AdUnitTypeSelector />} */}
          {/** Ad unit mediation toggle */}
          {this._tythonUser && active.connectionStatus === AdSenseOAuthStatus.Success && active.isMediationEnabled === true && (
            <>
              <OptOutMediationPopup />
              <div className={this._classNames.mediationHeader}>{formatMessage(messages.mediationHeader)}</div>
              <div>
                <p>{formatMessage(messages.mediationDescription)}</p>
              </div>
              <div className={this._classNames.mediationToggle}>
                <p>{formatMessage(messages.mediationToggleLabel)}</p>
                <Toggle
                  ariaLabel={formatMessage(messages.mediationToggleLabel)}
                  checked={store.active.adSenseMediationToggle === true}
                  title="mediationToggle"
                  onChange={(_, state) => {
                    onAdSenseMediationToggleChanged(state || false);

                    if (store.active.adSenseMediationStatus === true && state === false) {
                      onPopupOpenChanged(PopupType.AdUnitOptOut);
                    }
                  }}
                />
              </div>
            </>
          )}
        </>
      );

      return mode !== FormState.MultiStepCreation && mode !== FormState.MultiStepEdit ? (
        <DetailPage
          titleLabel={formatMessage(isEditing(mode) ? messages.editPageTitle : messages.createPageTitle)}
          helpId={'pop_PCv4_AdUnitsCreate'}
          submitLabel={this._getSubmitLabel(mode)}
          onSubmit={() => this._onSubmit(publisherId, accountId)}
          onCancel={() => this._onCancel(publisherId, accountId)}
          onNavClick={() => this._onCancel(publisherId, accountId)}
          saving={saving}
          loading={loading}
          pageMode={mode}
        >
          {renderAdunitDetails()}
        </DetailPage>
      ) : (
        renderAdunitDetails()
      );
    }

    /**
     *	Takes a boolean, and return a Panel component
     *	@param isPanelOpen second input
     *	@returns Property Panel component
     */

    private _renderPropertyPanel = (isPanelOpen?: boolean | null): JSX.Element => {
      const store = getAdunitStore();
      const { formatMessage } = this.props.intl;
      return (
        <Panel
          isOpen={getAdManagementStore().panelIsOpen}
          type={PanelType.medium}
          customWidth={store.active.createPropertyPanelOpen ? '500px' : '700px'}
          onDismiss={() => {
            onPanelOpenChanged(false);
            if (store.active.createPropertyPanelOpen) {
              onCreatePropertyPanelOpenChanged(false);
            }
          }}
          onRenderNavigation={() => (
            <div className={this._classNames.newPropertyPanelHeader} role="navigation">
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                aria-label={formatMessage(AppMessages.close)}
                onClick={() => {
                  onPanelOpenChanged(false);
                  if (store.active.createPropertyPanelOpen) {
                    onCreatePropertyPanelOpenChanged(false);
                  }
                }}
              />
            </div>
          )}
          headerText={formatMessage(propertyMessages.createPageTitle)}
          headerTextProps={{ hidden: true }}
        >
          {store.active.createPropertyPanelOpen && (
            <CreateProperty
              {...this.props}
              mode={FormState.InPageCreate}
              onCancel={() => {
                onPanelOpenChanged(false);
                if (store.active.createPropertyPanelOpen) {
                  onCreatePropertyPanelOpenChanged(false);
                }
              }}
              onSubmit={() => {
                onPanelOpenChanged(false);
                onCreatePropertyPanelOpenChanged(false);
              }}
            />
          )}
        </Panel>
      );
    };

    /**
     *	Takes an access permission and return a channel MultiSelectPicker component
     *	@param adUnitAccessPermissions input to _channelList
     *	@returns channel MultiSelectPicker component
     */

    private _channelList = (adunitAccessPermissions: AdUnitAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getAdunitStore();
      const selectedChannels = store.active.channelsSelected;
      return (
        <MultiSelectPicker
          className={this._classNames.multiSelectPicker}
          intl={this.props.intl}
          required={true}
          ariaDescription={formatMessage(messages.channelPickerAriaDesc)}
          errorMessage={store.errors.channelsSelected}
          items={store.active.channels || []}
          onSelectedItems={(items) => onChannelsChanged(items)}
          initialSelectedItems={selectedChannels}
          enableCreateButton={this.props.mode !== FormState.InPageCreate}
          createButtonLabel={formatMessage(AppMessages.createChannel)}
          onCreateButtonClick={() => onPanelOpenChanged(true)}
          disabled={this._isDisabled(
            this.props.mode,
            adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.channelList
          )}
        />
      );
    };

    /**
     *	Takes an access permission and return a site PropertySitePicker component
     *	@param adUnitAccessPermissions input to _propertySitePicker
     *	@returns site PropertySitePicker component
     */

    private _propertySitePicker = (adUnitAccessPermissions: AdUnitAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getAdunitStore();
      const properties = store.active.properties
        ? store.active.properties.map((p) => ({ id: p.id, url: p.url } as IPropertyPickerItem))
        : [];

      return (
        <PropertySitePicker
          title={formatMessage(messages.site)}
          items={properties}
          searchBoxPlaceholder={
            store.active.propertySelected
              ? store.active.propertySelected.id + ' - ' + store.active.propertySelected.url
              : formatMessage(messages.propertyDefaultOptionText)
          }
          propertyOptionsAriaLabel={formatMessage(messages.propertyOptionsAriaLabel)}
          onCreateButtonClick={() => {
            onPanelOpenChanged(true);
            onCreatePropertyPanelOpenChanged(true);
          }}
          onListItemClicked={(item) => {
            if (item && store.active.properties) {
              const selectedProperty = store.active.properties.filter((p) => p.id === item.id)[0];
              onAssociatedPropertyChanged(selectedProperty);
            } else {
              onAssociatedPropertyChanged(undefined);
            }
          }}
          emptyOptionLabel={formatMessage(messages.propertyPickerEmptyOptionLabel)}
          searchBoxAriaDescription={formatMessage(messages.propertyPickerSearchBoxAriaDesc)}
          itemSelectedAnnounceMessage={formatMessage(messages.propertyPickerItemSelectedAnnounceMsg)}
          formatMessage={formatMessage}
        />
      );
    };

    /**
     *	Takes an access permission and return a site PropertyPicker component
     *	@param adUnitAccessPermissions input to _propertyPicker
     *	@returns property PropertyPicker component
     */

    private _propertyPicker = (adUnitAccessPermissions: AdUnitAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getAdunitStore();
      const properties = store.active.properties
        ? store.active.properties.map((p) => ({ id: p.id, url: p.url } as IPropertyPickerItem))
        : [];

      return (
        <div className={this._classNames.propertySection}>
          <PropertyPicker
            items={properties}
            searchBoxPlaceholder={formatMessage(messages.propertySearchPlaceholder)}
            isCreateButtonDisabled={this.props.mode === FormState.InPageCreate}
            createButtonLabel={formatMessage(messages.createPropertyButtonLabel)}
            propertyOptionsAriaLabel={formatMessage(messages.propertyOptionsAriaLabel)}
            onCreateButtonClick={() => {
              onPanelOpenChanged(true);
              onCreatePropertyPanelOpenChanged(true);
            }}
            onListItemClicked={(item) => {
              if (item && store.active.properties) {
                const selectedProperty = store.active.properties.filter((p) => p.id === item.id)[0];
                onAssociatedPropertyChanged(selectedProperty);
              } else {
                onAssociatedPropertyChanged(undefined);
              }
            }}
            emptyOptionLabel={formatMessage(messages.propertyPickerEmptyOptionLabel)}
            createButtonAriaDescription={formatMessage(messages.createPropButtonAriaDesc)}
            searchBoxAriaDescription={formatMessage(messages.propertyPickerSearchBoxAriaDesc)}
            itemSelectedAnnounceMessage={formatMessage(messages.propertyPickerItemSelectedAnnounceMsg)}
          />

          {store.active.propertySelected && (
            <div className={this._classNames.propertyDetails}>
              <FormItem>
                <Label aria-label={formatMessage(messages.propertyName)}>{formatMessage(messages.propertyName)}</Label>
                {store.active.propertySelected!.name}
              </FormItem>
              <FormItem>
                <Label>{formatMessage(messages.websiteUrl)}</Label>
                {store.active.propertySelected.url}
              </FormItem>
              <FormItem>
                <Label>{formatMessage(messages.propertyId)}</Label>
                {store.active.propertySelected.id}
              </FormItem>
            </div>
          )}
        </div>
      );
    };

    /**
     *	Takes an access permission and return advance options section
     *	@param adUnitAccessPermissions input to _advancedOptions
     *	@returns advance options section
     */

    private _advancedOptions = (adunitAccessPermissions: AdUnitAccessPermissions | null): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const store = getAdunitStore();
      const { helpInfo, locale } = getAppStore();
      const ChoiceGroupOption: IChoiceGroupOption[] = [
        {
          key: AdUnitServingTier.Default,
          text: formatMessage(AppMessages.default),
          ariaLabel: formatMessage(AppMessages.default),
        },
        {
          key: AdUnitServingTier.lowCost,
          text: formatMessage(messages.servingTierOptionLowcost),
          ariaLabel: formatMessage(messages.servingTierOptionLowcost),
        },
      ];
      const isBoost = sessionStorage.getItem('IsBoostPublisher');

      const handlePrivateMarketPlace = (
        ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked: boolean | undefined,
        type: PrivateMarketplace
      ) => {
        if (!checked) {
          onAdunitPrivateMarketplaceChanged(undefined);
          this.setState({ privateMarketPlaceCheck: undefined });
        } else {
          if (type === PrivateMarketplace.InHouse) {
            this.setState({ privateMarketPlaceCheck: PrivateMarketplace.InHouse });
          } else {
            this.setState({ privateMarketPlaceCheck: PrivateMarketplace.Boost });
          }
          onAdunitPrivateMarketplaceChanged(type);
        }
      };

      return (
        <>
          {hasReadAccess(adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.servingTier) && (
            <FormItem>
              <FormLabel
                id="servingTierTitle"
                labelContent={formatMessage(messages.servingTierTitle)}
                calloutContent={helpInfo}
                iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AdUnitsTier', locale)}
                calloutOnDismiss={() => onHelpInfoChanged()}
                intl={this.props.intl}
                disableHeading
                disableAriaLevel
              />
              <ChoiceGroup
                options={ChoiceGroupOption}
                ariaLabelledBy={'servingTierTitle'}
                styles={this._classNames.subComponentStyles.servingTierChoiceGroup}
                onChange={(_, value) => onServingTierChanged(value!)}
                selectedKey={store.active.servingTier ?? AdUnitServingTier.Default}
                disabled={this._isDisabled(
                  this.props.mode,
                  adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.servingTier
                )}
              />
            </FormItem>
          )}
          <FormItem>
            <Dropdown
              label={formatMessage(messages.publisherGroupConfigLabel)}
              ariaLabel={formatMessage(messages.publisherGroupConfigLabel)}
              defaultSelectedKey={store.active.publisherGroupConfig}
              onChange={(_, value) => onPubGroupConfigChanged(value)}
              options={[
                {
                  key: PublisherGroupConfig.DefaultPlus,
                  text: formatMessage(messages.publisherGroupConfigOptionDefaultPlus),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionDefaultPlus),
                },
                {
                  key: PublisherGroupConfig.Default,
                  text: formatMessage(messages.publisherGroupConfigOptionDefault),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionDefault),
                },
                {
                  key: PublisherGroupConfig.Generalist,
                  text: formatMessage(messages.publisherGroupConfigOptionGeneralist),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionGeneralist),
                },
                {
                  key: PublisherGroupConfig.YieldOptimize,
                  text: formatMessage(messages.publisherGroupConfigOptionYieldOptimizer),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionYieldOptimizer),
                },
                {
                  key: PublisherGroupConfig.YieldMaximize,
                  text: formatMessage(messages.publisherGroupConfigOptionYieldMaximizer),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionYieldMaximizer),
                },
                {
                  key: PublisherGroupConfig.BingYahooOAndO,
                  text: formatMessage(messages.publisherGroupConfigOptionBingandYahoo),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionBingandYahoo),
                },
                {
                  key: PublisherGroupConfig.LowQualityDistribution,
                  text: formatMessage(messages.publisherGroupConfigOptionLowQualityDistribution),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionLowQualityDistribution),
                },
                {
                  key: PublisherGroupConfig.MediumQualityDistribution,
                  text: formatMessage(messages.publisherGroupConfigOptionMediumQualityDistribution),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionMediumQualityDistribution),
                },
                {
                  key: PublisherGroupConfig.HighQualityDistribution,
                  text: formatMessage(messages.publisherGroupConfigOptionHighQualityDistribution),
                  ariaLabel: formatMessage(messages.publisherGroupConfigOptionHighQualityDistribution),
                },
              ]}
              disabled={this._isDisabled(
                this.props.mode,
                adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.publisherGroupConfig
              )}
            />
          </FormItem>
          {hasReadAccess(adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.inhousePromotions) && (
            <FormItem formWidth={440}>
              <FormLabel
                id="privateMarketplaceRadioLabel"
                labelContent={formatMessage(messages.privateMarketplaceLabel)}
                calloutContent={helpInfo}
                iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AdUnitsPromotions', locale)}
                calloutOnDismiss={() => onHelpInfoChanged()}
                intl={this.props.intl}
                disableHeading
                disableAriaLevel
              />
              <Stack tokens={{ childrenGap: 10 }}>
                <Checkbox
                  id={formatMessage(messages.promotionRadioOptionLabel)}
                  aria-label={formatMessage(messages.promotionRadioOptionLabel)}
                  ariaLabelledBy={'privateMarketplaceRadioLabel'}
                  label={formatMessage(messages.promotionRadioOptionLabel)}
                  checked={this.state.privateMarketPlaceCheck === PrivateMarketplace.InHouse}
                  onChange={(ev, checked) => handlePrivateMarketPlace(ev, checked, PrivateMarketplace.InHouse)}
                  disabled={this._isDisabled(
                    this.props.mode,
                    adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.inhousePromotions
                  )}
                  styles={{ checkbox: { borderRadius: '100%' } }}
                />
                <Checkbox
                  id={formatMessage(messages.boostRadioOptionLabel)}
                  aria-label={formatMessage(messages.promotionRadioOptionLabel)}
                  ariaLabelledBy={'privateMarketplaceRadioLabel'}
                  label={formatMessage(messages.boostRadioOptionLabel)}
                  checked={this.state.privateMarketPlaceCheck === PrivateMarketplace.Boost}
                  onChange={(ev, checked) => handlePrivateMarketPlace(ev, checked, PrivateMarketplace.Boost)}
                  disabled={this._isDisabled(
                    this.props.mode,
                    adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.inhousePromotions
                  )}
                  styles={{ checkbox: { borderRadius: '100%' } }}
                />
              </Stack>
              {store.enablePrivateMarketplace && (
                <FormItem formWidth={440}>
                  <TextField
                    multiline
                    autoAdjustHeight
                    placeholder={formatMessage(this._getAdvertisersWhitelistTextFieldPlaceholder())}
                    ariaLabel={formatMessage(this._getAdvertisersWhitelistTextFieldPlaceholder())}
                    errorMessage={store.errors.storeIds}
                    onRenderLabel={this._onRenderAdvertiserWhitelistLabel}
                    value={store.active.storeIds}
                    onChange={(_, value) => onStoreIdsChanged(value)}
                    disabled={this._isDisabled(
                      this.props.mode,
                      adunitAccessPermissions === null ? completeAccess : adunitAccessPermissions.inhousePromotions
                    )}
                  />
                </FormItem>
              )}

              {this.state.privateMarketPlaceCheck === PrivateMarketplace.Boost && isBoost === 'true' && (
                <div className={this._classNames.boostUploadOuter}>
                  <div className={this._classNames.boostUploadDiv}>
                    <p>{this.props.intl.formatMessage(messages.uploadBoostTemplate)}</p>
                  </div>
                  <BoostJsonFileUpload
                    {...this.props}
                    adUnitId={this.props.match.params.id || this.props.adunitId}
                    onNewFileUpload={(file: NativeAdTemplate) => setTemplate(file)}
                    onReplaceFile={(file: NativeAdTemplate) => setTemplate(file)}
                  />
                </div>
              )}
            </FormItem>
          )}
        </>
      );
    };

    private _fetchBusinessModelLevel2Options = (businessModelLevel1Key?: number) => {
      const dropdownoptions: IDropdownOption[] = [
        { key: 0, text: this.props.intl.formatMessage(messages.businessModelLevel2DefaultOptionText) },
      ];
      const { adUnitTaxonomyValues } = getAdunitStore().active;
      if (adUnitTaxonomyValues && businessModelLevel1Key) {
        const level1BusinessModel = adUnitTaxonomyValues.filter((a) => a.key === businessModelLevel1Key)[0];
        const businessModelLevel2Values = adUnitTaxonomyValues.filter((a) => a.parentValue === level1BusinessModel.value);
        businessModelLevel2Values.forEach((a) =>
          dropdownoptions.push({ key: a.key, text: a.displayName, ariaLabel: a.displayName } as IDropdownOption)
        );
      }

      return dropdownoptions;
    };
    private _getAdvertisersWhitelistTextFieldLabel = () => {
      const store = getAdunitStore();
      return store.privateMarketplace === PrivateMarketplace.InHouse
        ? messages.promotionOptionTextfieldLabel
        : store.privateMarketplace === PrivateMarketplace.Boost
        ? messages.boostOptionTextfieldLabel
        : messages.privateMarketplaceTextfieldLabel;
    };
    private _getAdvertisersWhitelistTextFieldPlaceholder = () => {
      const store = getAdunitStore();
      return store.privateMarketplace === PrivateMarketplace.InHouse
        ? messages.promotionOptionTextfieldPlaceholder
        : store.privateMarketplace === PrivateMarketplace.Boost
        ? messages.boostOptionTextfieldPlaceholder
        : messages.privateMarketplaceTextfieldPlaceholder;
    };
    private _onRenderAdvertiserWhitelistLabel = (): JSX.Element => {
      const { formatMessage } = this.props.intl;
      const { helpInfo, locale } = getAppStore();
      return (
        <FormLabel
          id="promotionsInputLabel"
          labelContent={formatMessage(this._getAdvertisersWhitelistTextFieldLabel())}
          calloutContent={helpInfo}
          iconButtonOnClick={() => onHelpInfoCalloutOpened('pop_PCv4_AdUnitsPromotions', locale)}
          calloutOnDismiss={() => onHelpInfoChanged()}
          intl={this.props.intl}
        />
      );
    };

    private _onSubmit = (publisherId?: string, accountId?: string): void => {
      this.setState({ initializeForm: false });
      onFormSubmitted(this.props.mode, this.props.history);
      Log.debug('Ad Unit form submitted');
    };

    private _onCancel = (publisherId?: string, accountId?: string): void => {
      const { mode, onCancel } = this.props;
      this.setState({ initializeForm: false });
      onFormCancelled();

      if (mode !== FormState.InPageCreate && mode !== FormState.InPageEdit) {
        // return to manage page
        const returnUrl = createReturnUrl(RouteName.adunit, publisherId, accountId);
        onNavigateBack(this.props.history, returnUrl);
      }
      if (onCancel) {
        onCancel();
      }
      Log.debug('Ad Unit form cancelled');
    };

    private _getAdUnitAccessPermission(): AdUnitAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.AdUnit) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }

    private _getPropertyAccessPermission(): PropertyAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.Property) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }

    /**
     *	Takes FormState and returns correct label for tython or non-tython user
     *	@param mode input to _getSubmitLabel
     *	@returns label depending on mode (Create || Edit) && (tython user)
     */

    private _getSubmitLabel(mode: FormState): string {
      const { formatMessage } = this.props.intl;
      let submitButtonLabel: ReactIntl.FormattedMessage.MessageDescriptor;

      if (mode === FormState.Edit || mode === FormState.InPageEdit) {
        submitButtonLabel = messages.updateAdUnit;
      } else {
        if (this._tythonUser) {
          submitButtonLabel = messages.continueAdUnit;
        } else {
          submitButtonLabel = messages.createPageTitle;
        }
      }

      return formatMessage(submitButtonLabel);
    }

    private _isDisabled(mode: FormState, permission: number): boolean {
      if (mode === FormState.Create || mode === FormState.InPageCreate) {
        return false;
      }

      return !hasUpdateAccess(permission);
    }
  }
);
