import { IBreadcrumbItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { onPopupOpenChanged, showInfoToastNotification } from '../../../@data/mutatorActions';
import { PopupType } from '../../../@data/store/schema/enums/PopupType';
import { FormState } from '../../../components/Form';
import { IStepperStep, StepState } from '../DetailPageWithStepper.types';

export const useStepperLogic = (
  pageMode: FormState,
  process: string,
  steps: IStepperStep[],
  startAt: number,
  backToBase: () => void,
  goToErrorMessage: string
) => {
  const [currentStep, setCurrentStep] = useState(startAt);
  const [stepsState, setStepState] = useState(Array(steps.length).fill(StepState.Empty));

  const updateStepState = (step: number) => {
    setStepState((prevState) => {
      const newState = prevState;
      newState[step] = StepState.Dirty;
      return newState;
    });
  };

  const reset = () => {
    setCurrentStep(0);
    setStepState(Array(steps.length).fill(StepState.Empty));
  };

  const nextStep = () => {
    const stepUpdater = () => {
      if (currentStep + 1 === steps.length) {
        reset();
        return;
      }

      setCurrentStep((prevStep) => (prevStep >= steps.length - 1 ? prevStep : prevStep + 1));
      updateStepState(currentStep);
    };

    if (steps[currentStep].onNext) {
      steps[currentStep].onNext?.(stepUpdater, stepsState[currentStep]);
    } else {
      stepUpdater();
    }
  };

  const previousStep = () => {
    if (currentStep - 1 === -1) {
      backToBase();
      return;
    }

    setCurrentStep((prevStep) => (prevStep <= 0 ? prevStep : prevStep - 1));
    updateStepState(currentStep);
  };

  const navigateBack = () => {
    onPopupOpenChanged(PopupType.Leaving);
  };

  const goToStep = (step: number) => {
    if (
      steps[step].allowFreeNavigation === false ||
      (!steps[step].allowFreeNavigation && stepsState[step] === StepState.Empty) ||
      (pageMode === FormState.MultiStepCreation && stepsState[step] === StepState.Empty)
    ) {
      showInfoToastNotification({ text: steps[step].freeNavigationErrorMessage || goToErrorMessage });
      return;
    }

    setCurrentStep(step);
    updateStepState(step);
  };

  const getStepperTitle = (): string[] => steps.map((step) => step.name);

  const getBreadCrumpTitle = (): IBreadcrumbItem[] => [
    {
      key: '1',
      text: process,
    },
    {
      key: '2',
      text: steps[currentStep].name,
    },
  ];

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('step', currentStep.toString());
    window.history.replaceState('', '', url.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    setStepState(
      Array(steps.length)
        .fill(StepState.Dirty)
        .map((stepState, index) => {
          if (steps[index].isStepDirty === false) {
            stepState = StepState.Empty;
          }
          return stepState;
        })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageMode, steps]);

  return {
    currentStep,
    step: steps[currentStep],
    state: stepsState[currentStep],
    stepsState,
    goToStep,
    nextStep,
    previousStep,
    navigateBack,
    getStepperTitle: getStepperTitle(),
    getBreadCrumpTitle: getBreadCrumpTitle(),
  };
};
