import { mutator } from 'satcheljs';
import { AdUnitMarketClassification, AdUnitServingTier, PublisherGroupConfig } from '../../../../../@data';
import {
  onAdUnitFormSubmittable,
  onAdunitChannelListChanged,
  onAdunitIdChanged,
  onAdunitNameChanged,
  onAdunitPropertyListChanged,
  onAdunitTypeChanged,
  onAssociatedPropertyChanged,
  onBusinessModelChanged,
  onChannelsChanged,
  onCreatePropertyPanelOpenChanged,
  onDistributionPartnerCodesChanged,
  onFormCleared,
  onImpressionFeedbackSignalChanged,
  onMarketplaceChanged,
  onPubGroupConfigChanged,
  onServingTierChanged,
  onStoreIdsChanged,
} from '../actions';
import { getAdunitStore } from '../store/store';

mutator(onAdunitIdChanged, (msg) => {
  getAdunitStore().active.id = msg.value;
});

mutator(onAdunitNameChanged, (msg) => {
  getAdunitStore().active.name = msg.value;
});

mutator(onAdunitTypeChanged, (msg) => {
  getAdunitStore().active.adUnitType = msg.value;
});

mutator(onMarketplaceChanged, (msg) => {
  getAdunitStore().active.marketClassification = msg.value.key as AdUnitMarketClassification;
});

mutator(onBusinessModelChanged, (msg) => {
  if (msg.value) {
    getAdunitStore().active.businessModel = parseInt(msg.value.key.toString(), 10);
  }
});

mutator(onChannelsChanged, (msg) => {
  getAdunitStore().active.channelsSelected = msg.values;
});

mutator(onAdunitChannelListChanged, (msg) => {
  getAdunitStore().active.channels = msg.values;
});

mutator(onAssociatedPropertyChanged, (msg) => {
  getAdunitStore().active.propertySelected = msg.value;
});

mutator(onAdunitPropertyListChanged, (msg) => {
  getAdunitStore().active.properties = msg.values;
});

mutator(onCreatePropertyPanelOpenChanged, (msg) => {
  getAdunitStore().active.createPropertyPanelOpen = msg.isOpen;
});

mutator(onServingTierChanged, (msg) => {
  if (msg.value) {
    getAdunitStore().active.servingTier = msg.value.key as AdUnitServingTier;
  }
});

mutator(onAdUnitFormSubmittable, (msg) => {
  getAdunitStore().isSubmittable = msg.isSubmittable;
});

mutator(onPubGroupConfigChanged, (msg) => {
  if (msg.value) {
    getAdunitStore().active.publisherGroupConfig = msg.value.key as PublisherGroupConfig;
  }
});

mutator(onStoreIdsChanged, (msg) => {
  getAdunitStore().active.storeIds = msg.values;
});

mutator(onFormCleared, () => {
  getAdunitStore().active = {};
  getAdunitStore().template = undefined;
});

mutator(onDistributionPartnerCodesChanged, (msg) => {
  if (msg.value) {
    getAdunitStore().active.distributionPartnerCodes = msg.value;
  }
});

mutator(onImpressionFeedbackSignalChanged, (msg) => {
  getAdunitStore().active.isImpressionFeedbackRequired = !!msg.value;
});
