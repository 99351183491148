import { action, orchestrator } from 'satcheljs';
import { AdUnit, Publisher, getAppStore, listEntity, onLoadingChanged, showErrorToastNotification } from '../../../../../../../../@data';
import { FormState } from '../../../../../../../../components/Form';
import { IHierarchicalPickerItem } from '../../../../../../../../components/HierarchicalMultiSelectPicker';
import PropertyMessages from '../../../../../Property.messages';
import { onPropertyChanged } from '../../../../__data__/mutatorActions';
import { IPropertyViewModel } from '../../../../__data__/store/schema';
import { getCreatePropertyStore } from '../../../../__data__/store/store';

export const loadAttachedAdUnits = action(
  'loadAttachedAdUnits',
  (mode: FormState, publisherId: string, accountId?: string, entityId?: string) => ({
    mode,
    publisherId,
    accountId,
    entityId,
  })
);

orchestrator(loadAttachedAdUnits, async (msg) => {
  const { active, raw } = getCreatePropertyStore();
  const { userContext } = getAppStore();
  const publisherId = parseInt(msg.publisherId, 10);

  if (!isNaN(publisherId)) {
    try {
      const property: IPropertyViewModel = { ...active };

      onLoadingChanged(true);

      const publisher = new Publisher(publisherId);
      const adunits = await listEntity([publisher], userContext, AdUnit, new URLSearchParams([['extendedInfo', 'true']]));
      property.adunits = adunits.map(
        (a) => ({ id: a.id, name: a.name, parentId: a.accountId, parentName: a.accountName } as IHierarchicalPickerItem)
      );

      if (msg.mode === FormState.Edit && msg.entityId) {
        property.adunitsSelected = raw?.associatedAdUnitIds
          ? property.adunits.filter((f) => f.id && raw.associatedAdUnitIds!.includes(f.id))
          : [];
      }

      onPropertyChanged(property);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: PropertyMessages.adUnitsFetchFailed });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: PropertyMessages.adUnitsFetchFailed });
  }
});
