import { orchestrator } from 'satcheljs';
import {
  Account,
  LifeCycleStatus,
  Model,
  NotificationLabel,
  NotificationSeverity,
  Publisher,
  UpdateStatus,
  deleteEntity,
  getAppStore,
  onNotificationCardAdded,
  patchEntity,
} from '../../../../@data';
import {
  onPopupOpenChanged,
  showErrorToastNotification,
  showLoadingToastNotification,
  showSuccessToastNotification,
} from '../../../../@data/mutatorActions';
import { PopupType } from '../../../../@data/store/schema/enums/PopupType';
import AppMessages from '../../../../App.messages';
import notificationMessages from '../../../../NotificationsPanel.Types.messages';
import { getSuccessNotificationChangingStatus } from '../../../../utils';
import messages from '../../GridV2.messages';
import { onChangeItemsStatus } from '../actions/commandBarActions';
import { getStore } from '../store/store';

orchestrator(onChangeItemsStatus, async (msg) => {
  onPopupOpenChanged(PopupType.None);
  const { entity } = getStore();
  const status = msg.status;
  const selectedItems = msg.selectedItems;

  const showErrorMessage = () => {
    showErrorToastNotification({ textMessageDescriptor: messages.gridDataSubmittingChangingStatusFailed });
    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      titleMessageDescriptor: notificationMessages.ChangingItemStatusFailureTitle,
      contentMessageDescriptor: notificationMessages.ChangingItemStatusFailureContent,
      notificationLabel: NotificationLabel.ChangingItemStatusFailure,
    });
  };

  const updateSucceeded = (result, entityInstance, ids) => {
    if (result) {
      const gridData = [...getStore().data];

      selectedItems.forEach((selItem) => {
        const itemCopy = { ...selItem } as Model;
        const index = gridData?.findIndex((dataItem) => dataItem.id === selItem.id);
        itemCopy.status = status;
        if (index !== -1) {
          gridData[index] = itemCopy;
        }
      });

      msg.setData?.(gridData);
      msg.onItemsStatusChanged?.(selectedItems, status);

      const commandText = status === LifeCycleStatus.Inactive ? AppMessages.deactivate.defaultMessage : AppMessages.activate.defaultMessage;
      // const notification = getSuccessNotification(commandText!, selectedItems, entityInstance, ids);
      const notification = getSuccessNotificationChangingStatus(commandText, selectedItems, entityInstance, msg.formatMessage, ids);
      showSuccessToastNotification({ text: notification });
    }
  };

  if (entity && selectedItems && status) {
    const { userContext, publisher } = getAppStore();

    if (userContext && publisher) {
      const ids = encodeURI(selectedItems.map((item) => item.id).join(','));

      let entityInstance = new entity();
      try {
        let result;
        if (entityInstance instanceof Account) {
          entityInstance.id = selectedItems[0].id;
          const updateParams = new URLSearchParams([['status', status]]);
          showLoadingToastNotification({
            textMessageDescriptor: messages.gridDataSubmittingChangingStatus,
            onDismiss: async () => {
              try {
                result = await patchEntity([publisher, entityInstance], UpdateStatus, undefined, userContext, updateParams);
                updateSucceeded(result, entityInstance, ids);
              } catch (e) {
                showErrorMessage();
              }
            },
          });
        } else if (entityInstance instanceof Publisher) {
          entityInstance = new Publisher(selectedItems[0].id);
          showLoadingToastNotification({
            textMessageDescriptor: messages.gridDataSubmittingChangingStatus,
            onDismiss: async () => {
              try {
                result = await deleteEntity([entityInstance], undefined, userContext);
                updateSucceeded(result, entityInstance, ids);
              } catch (e) {
                showErrorMessage();
              }
            },
          });
        } else {
          const updateParams = new URLSearchParams([
            ['ids', ids],
            ['status', status],
          ]);
          showLoadingToastNotification({
            textMessageDescriptor: messages.gridDataSubmittingChangingStatus,
            onDismiss: async () => {
              try {
                result = await patchEntity([publisher, entityInstance], UpdateStatus, undefined, userContext, updateParams);
                updateSucceeded(result, entityInstance, ids);
              } catch (e) {
                showErrorMessage();
              }
            },
          });
        }
      } catch (e) {
        showErrorMessage();
      }
    }
  }
  onPopupOpenChanged(PopupType.None);
});
