import { mutatorAction } from 'satcheljs';

import { HomePageStatus } from '../store/schema/IHomeViewState';
import { getStore } from '../store/store';

export const setHomePageStatus = mutatorAction(
  'setHomePageStatus',
  (profileStatus: HomePageStatus, siteStatus: HomePageStatus, adUnitStatus: HomePageStatus, isHomePageSetupComplete: boolean) => {
    getStore().profileStatus = profileStatus;
    getStore().siteStatus = siteStatus;
    getStore().adUnitStatus = adUnitStatus;
    getStore().isSetupDone = isHomePageSetupComplete;
  }
);

export const setIsSetupDone = mutatorAction('setIsSetupDone', (isSetupDone: boolean) => {
  getStore().isSetupDone = isSetupDone;
});

export const setIsStatusLoading = mutatorAction('setIsStatusLoading', (isStatusLoading: boolean) => {
  getStore().isStatusLoading = isStatusLoading;
});

export const setIsFRECardShown = mutatorAction('setIsFRECardShown', (isFRECardShown: boolean) => {
  getStore().isFRECardShown = isFRECardShown;
});
