import {
  FontWeights,
  ICheckboxStyleProps,
  ICheckboxStyles,
  IPanelStyleProps,
  IPanelStyles,
  IStyle,
  IStyleFunctionOrObject,
  ITheme,
  classNamesFunction,
  getTheme,
  keyframes,
  mergeStyleSets,
} from '@fluentui/react';
import { IPubCenterFonts, IPubcenterSpacing, IPubcenterTheme } from '../../../theme';
import { IFormTitleStyleProps, IFormTitleStyles } from '../../components/Form';
import { noPointerEvents } from '../../utils/GlobalStyleUtils';

export interface IGridStylesProps {
  theme: ITheme;
}

export interface IGridStyles {
  root?: IStyle;
  modifyColumnDescription?: IStyle;
  panelButton?: IStyle;
  subComponentStyles: {
    formTitle?: IStyleFunctionOrObject<IFormTitleStyleProps, IFormTitleStyles>;
    modifyColumnPanel?: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles>;
    columnCheckbox?: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles>;
  };
  emptyGridTextStyle?: IStyle;
  titleContainer?: IStyle;
  detailsHeaderRootStyle?: IStyle;
  detailsRowRootStyle?: IStyle;
  disabledRowStyle?: IStyle;
  checkCell?: IStyle;
  columnTooltip?: IStyle;
  columnCheckboxWrapper?: IStyle;
  columnHeaderCallout?: IStyle;
  columnHeaderIcon?: IStyle;
  calloutFocusable?: IStyle;
}

export const getStyles = (props: IGridStylesProps): IGridStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { palette } = theme;
  const fonts = theme.fonts as IPubCenterFonts;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    root: {
      marginTop: spacing.size8,
      height: '100%',
      '.ms-DetailsList': {
        paddingRight: spacing.size1,
        marginRight: `-${spacing.size1}`,
      },
      '.ms-DetailsRow-cell': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    emptyGridTextStyle: {
      ...fonts.size14,
      margin: `${spacing.size16} 0 0 ${spacing.size10}`,
    },
    detailsHeaderRootStyle: {
      fontWeight: FontWeights.regular,
      padding: 0,
      borderTop: `1px solid ${palette.neutralPrimary}`,
      borderBottom: `1px solid ${palette.neutralPrimary}`,
      '.ms-DetailsHeader-cell:not(:last-child)': {
        borderRight: `0.5px solid ${palette.neutralLight}`,
        marginRight: '-0.5px',
      },
      '.ms-DetailsHeader-cellName': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    detailsRowRootStyle: {
      // To make the text in the cell copyable as it is not by default in fluentUI
      // ------------------------------
      userSelect: 'text',
      cursor: 'text',
      width: 'fit-content',
      // ------------------------------
      ...fonts.size14,
      '.ms-DetailsRow-cell:not(:last-child)': {
        marginRight: '-0.5px',
        borderRight: `0.5px solid ${palette.neutralLight}`,
      },
      '.ms-DetailsRow-fields.updated': {
        animation: keyframes({
          '50%': {
            backgroundColor: palette.themeLighter,
          },
        }),
        animationDuration: '300ms',
      },
    },
    disabledRowStyle: {
      ...noPointerEvents,
    },
    checkCell: {
      alignSelf: 'center',
    },
    columnHeaderCallout: {
      display: 'flex',
      alignItems: 'center',
    },
    columnHeaderIcon: {
      color: palette.themePrimary,
      padding: `0px ${spacing.size8}`,
      cursor: 'pointer',
      '& :hover': {
        backgroundColor: palette.themeLighter,
      },
    },
    modifyColumnDescription: [
      'ms-fontWeight-semibold',
      {
        fontSize: theme.fonts.size12,
        marginBottom: spacing.size16,
      },
    ],
    panelButton: {
      display: 'flex',
      selectors: {
        '.ms-Button': {
          marginRight: spacing.size8,
        },
      },
    },
    subComponentStyles: {
      modifyColumnPanel: {
        header: {
          marginBottom: spacing.size24,
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
        },
        footer: {
          borderTopStyle: 'solid',
          borderColor: theme.palette.neutralLight,
          borderTopWidth: 1,
        },
      },
      columnCheckbox: {
        root: {
          paddingTop: spacing.size8,
          paddingBottom: spacing.size8,
          marginBottom: spacing.size8,
        },
      },
    },
    columnTooltip: {
      paddingTop: spacing.size8,
      paddingBottom: spacing.size8,
      marginBottom: spacing.size8,
    },
    columnCheckboxWrapper: {
      display: 'flex',
      gap: spacing.size8,
      alignItems: 'center',
    },
    calloutFocusable: {
      '&:focus': {
        border: '2px solid black',
        borderRadius: spacing.size4,
      },
    },
  });
};

const classNames = classNamesFunction<IGridStylesProps, IGridStyles>();

export function getClassNames<PropType>(props?: PropType) {
  return classNames(getStyles, { theme: getTheme(), ...props });
}
