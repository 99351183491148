import { useCallback } from 'react';
import { InjectedIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Log } from '../../../../../../logging/src';
import {
  ClarityType,
  IAppState,
  NotificationLabel,
  NotificationSeverity,
  Property,
  onNotificationCardAdded,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../../@data';
import notificationMessages from '../../../../../NotificationsPanel.Types.messages';
import { IPropertySitePickerItem } from '../../../../../layout/PropertySitePicker/PropertySitePicker.types';
import { getTythonAdsManageRoute } from '../../../../../utils/routeUtils';
import { IGoogleAdSenseViewState } from '../../../Account/Settings/components/GoogleConnector/@data';
import { isFormValid } from '../../../Property/Create/variations/CreateTythonProperty/utils/validateForm';
import { createTythonAdUnit } from '../services/createTythonAdUnit';
import { disableTythonAdUnitMediation } from '../services/disableTythonAdUnitMediation';
import { enableTythonAdUnitMediation } from '../services/enableTythonAdUnitMediation';
import { updateTythonAdUnit } from '../services/updateTythonAdUnit';
import messages from './messages';
import { AdTypes, ITythonAdUnit, NativeAdProperties } from './types';

type UseAdunitEventHandlersProps = {
  getActiveAdunit: () => ITythonAdUnit | undefined;
  setActiveAdunit: (adUnit: ITythonAdUnit) => void;
  setIsAdUnitSaving: (isSaving: boolean) => void;
  createProperty: (property: { url: string; name: string; clarityType?: ClarityType; clarityId?: string }) => void;
  activeNewPropertyCreation: () => void;
  activeScriptView: () => void;
  formatMessage: InjectedIntl['formatMessage'];
  appStore: IAppState;
  googleAdsenseStore: IGoogleAdSenseViewState;
  initialAdUnit: ITythonAdUnit | undefined;
  routeProps: RouteComponentProps;
  properties: Property[];
  deActiveNewPropertyCreation: () => void;
};

const useAdunitEventHandlers = ({
  getActiveAdunit,
  setActiveAdunit,
  createProperty,
  activeNewPropertyCreation,
  setIsAdUnitSaving,
  activeScriptView,
  deActiveNewPropertyCreation,
  formatMessage,
  appStore,
  googleAdsenseStore,
  initialAdUnit,
  routeProps,
  properties,
}: UseAdunitEventHandlersProps) => {
  const onRedirectBackToAdUnitManagePage = useCallback(() => {
    const { publisher, account } = appStore;
    if (!publisher || !account) {
      return;
    }
    routeProps.history.push(getTythonAdsManageRoute());
  }, [appStore, routeProps.history]);

  const onAdunitNameChange = useCallback(
    (_, value: string) => {
      const currentActiveAdunit = getActiveAdunit();
      setActiveAdunit({ ...currentActiveAdunit, name: value } as ITythonAdUnit);
    },
    [getActiveAdunit, setActiveAdunit]
  );

  const onAdunitTypeChange = useCallback(
    ({ adTypes, nativeProperties }: { adTypes?: AdTypes; nativeProperties?: NativeAdProperties }) => {
      const currentActiveAdunit = getActiveAdunit();
      setActiveAdunit({
        ...currentActiveAdunit,
        ...(adTypes ? { adTypes: adTypes } : {}),
        ...(nativeProperties ? { nativeProperties: nativeProperties } : {}),
      } as ITythonAdUnit);
    },
    [getActiveAdunit, setActiveAdunit]
  );

  const onAdunitMediationChange = useCallback(
    (active: boolean) => {
      const currentActiveAdunit = getActiveAdunit();
      setActiveAdunit({ ...currentActiveAdunit, mediationEnabled: active } as ITythonAdUnit);
    },
    [getActiveAdunit, setActiveAdunit]
  );

  const onAddSiteClick = useCallback(() => {
    activeNewPropertyCreation();
  }, [activeNewPropertyCreation]);

  const onSiteChange = useCallback(
    (item: IPropertySitePickerItem) => {
      const currentActiveAdunit = getActiveAdunit();
      setActiveAdunit({ ...currentActiveAdunit, propertyId: item.id } as ITythonAdUnit);
    },
    [getActiveAdunit, setActiveAdunit]
  );

  const onCreatingNewProperty = useCallback(
    async ({ name, url, clarityType, clarityId }: { name: string; url: string; clarityType?: ClarityType; clarityId?: string }) => {
      const isValid = await isFormValid(properties, false, undefined);
      if (!isValid) {
        showErrorToastNotification({ text: formatMessage(messages.duplicatedSiteCreationErrorToastNotification) });
        onNotificationCardAdded({
          // tslint:disable-next-line: no-any
          severity: NotificationSeverity.Error,
          title: formatMessage(notificationMessages.DuplicatedSiteCreationErrorNotificationTitle),
          content: formatMessage(notificationMessages.DuplicatedSiteCreationErrorNotificationContent, { name: name, url: url }),
          intlContentMessageValues: { name: name, url: url },
          notificationLabel: NotificationLabel.DuplicatedSiteCreationErrorNotification,
        });
        return;
      }
      await createProperty({ name, url, clarityType, clarityId });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createProperty, properties]
  );

  const onAdUnitSubmit = useCallback(async () => {
    const isCreatingNewAdUnit = window?.location?.href.includes('adunit/create');
    const activeAdunit = getActiveAdunit();
    const { publisher, userContext, account } = appStore;

    if (!publisher || !userContext || !account || !activeAdunit) {
      return;
    }

    if (isCreatingNewAdUnit) {
      await createTythonAdUnit(publisher, userContext, account, activeAdunit, setIsAdUnitSaving).then((data) => {
        Log.info(`create tython ad unit success ${JSON.stringify(data)}`);
        const currentActiveAdunit = getActiveAdunit()!;
        const newAdUnitId = data.data.servingConfig?.adUnitId!;
        showSuccessToastNotification({ text: formatMessage(messages.createAdUnitSuccessNotification, { adunitId: newAdUnitId }) });
        setActiveAdunit({ ...currentActiveAdunit, id: newAdUnitId });
        if (activeAdunit.mediationEnabled) {
          enableTythonAdUnitMediation([newAdUnitId]);
        }
        activeScriptView();
      });
    } else {
      await updateTythonAdUnit(publisher, userContext, activeAdunit, initialAdUnit?.name !== activeAdunit.name, setIsAdUnitSaving).then(
        (data) => {
          if (data && (data as { isDirty?: boolean }).isDirty === false) {
            activeScriptView();
            return;
          }
          Log.info(`update tython ad unit success ${JSON.stringify(data)}`);
          /**
           * update mediation if save ad unit success
           */
          if (activeAdunit.mediationEnabled !== initialAdUnit?.mediationEnabled) {
            activeAdunit.mediationEnabled && enableTythonAdUnitMediation([activeAdunit.id!]);
            !activeAdunit.mediationEnabled && disableTythonAdUnitMediation([activeAdunit.id!]);
          }
          showSuccessToastNotification({ text: formatMessage(messages.editAdUnitSuccessNotification, { adunitId: activeAdunit.id }) });
          activeScriptView();
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActiveAdunit, appStore, googleAdsenseStore, initialAdUnit]);

  return {
    onAdunitNameChange,
    onAdunitTypeChange,
    onAdUnitSubmit,
    onAdunitMediationChange,
    onAddSiteClick,
    onSiteChange,
    onCreatingNewProperty,
    onRedirectBackToAdUnitManagePage,
  };
};

export default useAdunitEventHandlers;
