import { RedirectRequest } from '@azure/msal-browser';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { onPubCenterAADLogin, onSwitchAccount } from '../actions';

orchestrator(onSwitchAccount, async (msg) => {
  try {
    // passing prompt parameter as 'select_account', which will redirect user select account page listing all the accounts in session or
    // any remembered accounts or an option to choose to use a different account
    const requestParams: Partial<RedirectRequest> = { prompt: 'select_account' };
    onPubCenterAADLogin(requestParams);
  } catch (e) {
    Log.write(e);
  }
});
