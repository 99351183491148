import * as React from 'react';
import { useEffect } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { isTythonSelfServeUser } from '../../../@data';
import { RoutePrefix } from '../../../Routes';
import { FormState } from '../../../components/Form';
import { injectIntlWithObserver } from '../../../utils';
import { RouteName } from '../@data';
import { onCurrentRouteChanged } from '../@data/actions';
import './@data/orchestrators';
import { CreateProperty } from './Create/variations/CreateNonTythonProperty';
import CreateTythonAppProperty from './Create/variations/CreateTythonAppProperty';
import { CreateTythonProperty } from './Create/variations/CreateTythonProperty';
import { ManagePage } from './Manage';

export const PropertyPage = injectIntlWithObserver((props: RouteComponentProps & InjectedIntlProps) => {
  const isTythonUser = isTythonSelfServeUser();

  const getRedirectionPage = (operation: FormState, componentProps: RouteComponentProps, isAppProperty = false) => {
    if (isTythonUser) {
      const mode = operation === FormState.Create ? FormState.MultiStepCreation : FormState.MultiStepEdit;
      if (isAppProperty) {
        // @ts-ignore
        return <CreateTythonAppProperty mode={mode} />;
      }
      return <CreateTythonProperty {...componentProps} mode={mode} />;
    } else {
      const mode = operation === FormState.Create ? FormState.Create : FormState.Edit;
      return <CreateProperty {...componentProps} mode={mode} />;
    }
  };

  useEffect(() => {
    onCurrentRouteChanged(RouteName.property, props.intl.formatMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${props.match.url}`} component={ManagePage} />
        <Route
          exact
          path={`/${RoutePrefix.adManagement}/:publisherId/${RouteName[RouteName.property]}/create`}
          render={(componentProps) => getRedirectionPage(FormState.Create, componentProps)}
        />
        <Route
          path={`/${RoutePrefix.adManagement}/:publisherId/${RouteName[RouteName.property]}/create/:id`}
          render={(componentProps) => getRedirectionPage(FormState.Create, componentProps)}
        />
        <Route
          path={`/${RoutePrefix.adManagement}/:publisherId/${RouteName[RouteName.property]}/edit/:id`}
          render={(componentProps) => getRedirectionPage(FormState.Edit, componentProps)}
        />
        <Route
          path={`/${RoutePrefix.adManagement}/:publisherId/${RouteName[RouteName.property]}/createApp`}
          render={(componentProps) => getRedirectionPage(FormState.Create, componentProps, true)}
        />
        <Route
          path={`/${RoutePrefix.adManagement}/:publisherId/${RouteName[RouteName.property]}/editApp/:id`}
          render={(componentProps) => getRedirectionPage(FormState.Create, componentProps, true)}
        />
      </Switch>
    </React.Fragment>
  );
});
