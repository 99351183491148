import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { onNotificationChanged } from '../../../../../@data';
import { uiConfig } from '../../../../../config/ui.config';
import { validateUserInvitationCode } from '../actions';

orchestrator(validateUserInvitationCode, async ({ invitationCode }) => {
  if (invitationCode !== 'redirect') {
    const defaultHeaders = [['content-type', 'application/json']];
    const headers = new Headers([...defaultHeaders]);
    const body = { invitationCode: invitationCode };
    const url = uiConfig.getApiBaseAddress() + '/public/userInvitations/validate';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });

      if (!response.ok && response.status === 400) {
        onNotificationChanged({
          text: 'Invalid invitation code, please check with your admin to resend user invitation',
          messageBarType: MessageBarType.error,
          suppressAutoDismiss: true,
        });
        return;
      }
    } catch (error) {
      Log.error(error);
    }
  }
});
