import {
  ActionButton,
  BaseComponent,
  Callout,
  Checkbox,
  DatePicker,
  DefaultButton,
  DirectionalHint,
  Dropdown,
  FocusZone,
  FocusZoneDirection,
  IDropdownOption,
  IProcessedStyleSet,
  Icon,
  IconButton,
  Label,
  TextField,
  classNamesFunction,
  css,
  elementContains,
  focusFirstChild,
  getDocument,
  getId,
  getWindow,
  on,
} from '@fluentui/react';
import * as React from 'react';
import { FilterCondition } from '../../@data/store/schema/enums/FilterCondition';
import appMessages from '../../App.messages';
import { IFilterItem } from '../../layout/GridPageV2';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { getScreenReaderTextStyles } from '../../utils/GlobalStyleUtils';
import { DayPickerStrings } from '../DateTimePicker/DateTimePicker.types';
import { FormItem } from '../Form';
import messages from './GridFilter.messages';
import { getStyles } from './GridFilter.styles';
import { FilterType, IFilterDetails, IGridFilterProps, IGridFilterStyleProps, IGridFilterStyles } from './GridFilter.types';

export interface IGridFilterState {
  isFilterBarFocused: boolean;
  isFilterBeingModified: boolean;
  filterMenuCalloutOpen: boolean;
  filterSelectionCalloutOpen: boolean;
  filterSummaryCalloutOpen: boolean;
  appliedFilterList: IFilterDetails[];
  filterSelectionCalloutTarget?: string;
  filterSelectionColumn?: IFilterDetails;
  textBoxErrorMessage?: string;
}

const getClassNames = classNamesFunction<IGridFilterStyleProps, IGridFilterStyles>();

export class GridFilterBase extends BaseComponent<IGridFilterProps, IGridFilterState> {
  private _classNames: IProcessedStyleSet<IGridFilterStyles> = getClassNames(getStyles, { theme: this.props.theme! });
  private _gridFilter = React.createRef<HTMLDivElement>();
  private _tempDisposables: (() => void)[] = [];
  private _filterListContainer = React.createRef<HTMLDivElement>();

  constructor(props: IGridFilterProps) {
    super(props);

    this.state = {
      isFilterBarFocused: false,
      isFilterBeingModified: false,
      filterMenuCalloutOpen: false,
      filterSelectionCalloutOpen: false,
      filterSummaryCalloutOpen: false,
      appliedFilterList: this.props.initialFilterListValues || [],
      // showClearAll: this.props.showClearAll,
    };
  }

  public componentDidMount() {
    this._addListeners();
  }

  public componentDidUpdate(prevProps: IGridFilterProps) {
    if (prevProps === this.props) {
      return;
    }

    if (this.props.hideCallout) {
      this.setState({ appliedFilterList: this.props.initialFilterListValues ?? [] });
    } else if (this.state.appliedFilterList.length === 0) {
      this.setState({ appliedFilterList: this.props.initialFilterListValues ?? [] });
    } else {
      this.setState({ appliedFilterList: this.state.appliedFilterList });
    }

    this.setState({
      filterMenuCalloutOpen: this.props.filterMenuCalloutOpen !== undefined ? this.props.filterMenuCalloutOpen : false,
    });
  }

  public componentWillUnmount() {
    this._async.dispose();
    this._tempDisposables.forEach((dispose: () => void) => dispose());
  }

  public resetGridFilter() {
    this.setState({
      isFilterBarFocused: false,
      isFilterBeingModified: false,
      filterMenuCalloutOpen: false,
      filterSelectionCalloutOpen: false,
      filterSummaryCalloutOpen: false,
      appliedFilterList: [],
    });
  }

  public render(): JSX.Element {
    const {
      appliedFilterList,
      isFilterBarFocused,
      filterSelectionCalloutOpen,
      filterSelectionColumn,
      filterSummaryCalloutOpen,
    } = this.state;

    return (
      <div ref={this._gridFilter} className={this._classNames.root}>
        {this.state.filterMenuCalloutOpen && this._renderFilterMenuCallout()}
        {filterSelectionCalloutOpen && filterSelectionColumn && !this.props.hideCallout && this._renderFilterSelectionCallout()}
        {filterSummaryCalloutOpen && this._renderFilterSummaryCallout()}
        {appliedFilterList.length > 0
          ? isFilterBarFocused
            ? this._renderAppliedFiltersFocusedList()
            : this._renderAppliedFiltersUnfocusedList()
          : null}
        <span id="filterListAriaDescription" style={getScreenReaderTextStyles()}>
          {this.props.intl.formatMessage(messages.filterListAriaDescription)}
        </span>
      </div>
    );
  }

  private _renderAppliedFiltersFocusedList = (): JSX.Element => {
    const { appliedFilterList, isFilterBeingModified, filterSelectionColumn } = this.state;

    return (
      <div ref={this._filterListContainer} id={'appliedFilterFocusedList'} className={this._classNames.filterBarFocused}>
        <FocusZone
          className={this._classNames.filterListPageStyle}
          direction={FocusZoneDirection.horizontal}
          isCircularNavigation={true}
          aria-label={this.props.intl.formatMessage(messages.appliedFilterListAriaLabel)}
          aria-describedby="filterListAriaDescription"
          role="list"
        >
          {appliedFilterList.map((fil: IFilterDetails, index: number) => {
            const applyModifyFilterStyles: boolean =
              filterSelectionColumn && isFilterBeingModified && fil.filterId === filterSelectionColumn.filterId ? true : false;

            return (
              <div
                className={applyModifyFilterStyles ? this._classNames.highlightedFilterInfoContainer : this._classNames.filterInfoContainer}
                id={fil.filterId}
                key={'filterFocusedList-' + index}
                data-list-index={index}
                role="listitem"
              >
                <div
                  className={css(
                    this._classNames.filterInfoFocused,
                    applyModifyFilterStyles ? this._classNames.highlightedFilterInfoSelectors : this._classNames.filterInfoSelectors
                  )}
                  onClick={this._onFilterInfoFocusedClicked}
                  onKeyDown={(ev) => isButtonPressKey(ev.key) && this._onFilterInfoFocusedClicked(ev)}
                  role="button"
                  tabIndex={0}
                  data-is-focusable={true}
                >
                  {fil.filterType === FilterType.custom
                    ? `${fil.filterName}: ${fil.filterCondition}`
                    : fil.filterType === FilterType.date
                    ? `${fil.filterName}${fil.startDate ? ' from ' + fil.startDate.toLocaleDateString() : ''}${
                        fil.endDate ? ' to ' + fil.endDate.toLocaleDateString() : ''
                      }`
                    : `${fil.filterName} ${fil.filterCondition} ${fil.filterText}`}
                  {fil.includeNulls ? ` ${fil.showNullsCheckboxLabel}` : ''}
                </div>
                <div
                  className={css(
                    this._classNames.filterCloseIcon,
                    applyModifyFilterStyles ? this._classNames.highlightedFilterInfoSelectors : this._classNames.filterInfoSelectors
                  )}
                  onClick={(ev) => {
                    this._onFilterRemoveButtonClicked(ev);
                    if (this.props.hideCallout) {
                      if (fil.filterName && fil.filterCondition && fil.filterText) {
                        const filterCondition = Object.values(FilterCondition).find((v) => v === fil.filterCondition);
                        if (filterCondition && this.props.onDeleteTextCondition) {
                          this.props.onDeleteTextCondition(fil.filterName, filterCondition, fil.filterText);
                        }
                      } else if (fil.filterName && fil.filterText && this.props.onListValueChanged) {
                        this.props.onListValueChanged(fil.filterName, fil.filterText);
                      }
                    }
                  }}
                  onKeyDown={(ev) => isButtonPressKey(ev.key) && this._onFilterRemoveButtonClicked(ev)}
                  role="button"
                  tabIndex={0}
                  aria-label={this.props.intl.formatMessage(messages.removeFilterIconAriaLabel)}
                  data-is-focusable={true}
                >
                  <Icon iconName="Cancel" />
                </div>
              </div>
            );
          })}
        </FocusZone>
        {this.props.showClearAll && appliedFilterList.length > 0 ? (
          <ActionButton
            className={this._classNames.clearAllButton}
            id={'clearAllButton'}
            onClick={(_) => {
              if (this.props.onClearAll) {
                this.props.onClearAll();
              }
            }}
          >
            {'Clear all'}
          </ActionButton>
        ) : null}
      </div>
    );
  };

  private _renderAppliedFiltersUnfocusedList = (): JSX.Element => {
    const { appliedFilterList } = this.state;

    return (
      <div id={'appliedFilterUnfocusedList'} className={this._classNames.filterBarUnFocused}>
        <Icon className={this._classNames.filterIcon} iconName="Filter" />
        <FocusZone
          className={this._classNames.filterListPageStyle}
          direction={FocusZoneDirection.horizontal}
          isCircularNavigation={true}
          aria-label={this.props.intl.formatMessage(messages.appliedFilterListAriaLabel)}
          aria-describedby="filterListAriaDescription"
        >
          {appliedFilterList.map((fil: IFilterDetails, index: number) => (
            <div
              className={this._classNames.filterInfoUnfocused}
              key={'appliedFilterUnfocusedList' + index}
              onClick={this._onFilterInfoUnfocusedClicked}
              onKeyDown={(ev) => isButtonPressKey(ev.key) && this._onFilterInfoUnfocusedClicked(ev)}
              role="button"
              tabIndex={0}
              data-list-index={index}
              data-is-focusable={true}
            >
              {fil.filterType === FilterType.custom
                ? `${fil.filterName}: ${fil.filterCondition}`
                : fil.filterType === FilterType.date
                ? `${fil.filterName}${fil.startDate ? ' from ' + fil.startDate.toLocaleDateString() : ''}${
                    fil.endDate ? ' to ' + fil.endDate.toLocaleDateString() : ''
                  }`
                : `${fil.filterName} ${fil.filterCondition} ${fil.filterText}`}
              {fil.includeNulls ? ` ${fil.showNullsCheckboxLabel}` : ''}
              {index !== appliedFilterList.length - 1 ? ';' : ''}
            </div>
          ))}
        </FocusZone>
        {/* {appliedFilterList.length >= 4 ? (
          <ActionButton
            className={this._classNames.viewAllButton}
            id={'viewAllButton'}
            onClick={(_) => this.setState({ filterSummaryCalloutOpen: true })}
          >
            {'View all'}
          </ActionButton>
        ) : null} */}
      </div>
    );
  };

  private _renderFilterMenuCallout = (): JSX.Element => {
    return (
      <Callout
        id="filterMenuDialog"
        target={'#addFilterButton'}
        isBeakVisible={false}
        calloutWidth={180}
        directionalHint={DirectionalHint.bottomLeftEdge}
        onDismiss={() => {
          this.setState({ filterMenuCalloutOpen: false });
          this.props.onFilterMenuCalloutClose();
        }}
        setInitialFocus={true}
        role="dialog"
        shouldRestoreFocus={true}
        onKeyDown={(ev: React.KeyboardEvent<HTMLDivElement>) => {
          if (ev.key === 'Tab' || (ev.shiftKey && ev.key === 'Tab')) {
            this.setState({ filterMenuCalloutOpen: false });
            this.props.onFilterMenuCalloutClose();
          }
        }}
      >
        <FocusZone
          role="listbox"
          direction={FocusZoneDirection.vertical}
          isCircularNavigation={true}
          aria-label={this.props.intl.formatMessage(messages.filterMenuAriaLabel)}
        >
          {this.props.filterItems.map((col: IFilterItem, index: number) => (
            <div
              className={this._classNames.filterMenuListItem}
              id={`gridFilterMenuItem-${index}`}
              key={`gridFilterMenuItem-${index}`}
              onClick={this._onFilterMenuItemClicked}
              onKeyDown={(ev) => isButtonPressKey(ev.key) && this._onFilterMenuItemClicked(ev)}
              role="option"
              aria-selected={false}
              tabIndex={0}
              data-list-index={index}
              data-is-focusable={true}
            >
              {col.filterName}
            </div>
          ))}
        </FocusZone>
      </Callout>
    );
  };

  private _renderFilterSelectionCallout = (): JSX.Element => {
    const { formatMessage } = this.props.intl;
    const {
      filterSelectionColumn,
      filterSelectionCalloutTarget,
      textBoxErrorMessage,
      isFilterBeingModified,
      appliedFilterList,
    } = this.state;

    const relationalFilterOptions: IDropdownOption[] = [
      { key: FilterCondition.Equals, text: formatMessage(messages.equals) },
      { key: FilterCondition.DoesNotEqual, text: formatMessage(messages.doesNotEqual) },
      { key: FilterCondition.GreaterThan, text: formatMessage(messages.greaterThan) },
      { key: FilterCondition.GreaterThanEqual, text: formatMessage(messages.greaterThanEqual) },
      { key: FilterCondition.LessThan, text: formatMessage(messages.lessThan) },
      { key: FilterCondition.LessThanEqual, text: formatMessage(messages.lessThanEqual) },
    ];

    const stringFilterTypeOptions: IDropdownOption[] = [
      { key: FilterCondition.Contains, text: formatMessage(messages.contains) },
      { key: FilterCondition.DoesNotContain, text: formatMessage(messages.doesNotContain) },
      { key: FilterCondition.BeginsWith, text: formatMessage(messages.beginsWith) },
      { key: FilterCondition.EndsWith, text: formatMessage(messages.endsWith) },
      { key: FilterCondition.Equals, text: formatMessage(messages.equals) },
      { key: FilterCondition.DoesNotEqual, text: formatMessage(messages.doesNotEqual) },
    ];

    let filterOptions: IDropdownOption[] = [];

    if (filterSelectionColumn) {
      switch (filterSelectionColumn.filterType) {
        case FilterType.relational:
          filterOptions = relationalFilterOptions;
          break;
        case FilterType.string:
          filterOptions = stringFilterTypeOptions;
          break;
        case FilterType.custom:
          filterOptions = filterSelectionColumn.filterOptions || [];
          break;
        default:
          filterOptions = [];
      }

      if (!filterSelectionColumn.filterCondition) {
        filterSelectionColumn.filterCondition = filterOptions.length > 0 ? filterOptions[0].key.toString() : undefined;
      }
    }

    const applyButtonDisabled: boolean =
      filterSelectionColumn !== undefined &&
      filterSelectionColumn.filterType !== FilterType.custom &&
      !filterSelectionColumn.filterText &&
      !filterSelectionColumn.startDate &&
      !filterSelectionColumn.endDate &&
      !filterSelectionColumn.includeNulls;

    return (
      <>
        <Callout
          target={filterSelectionCalloutTarget && isFilterBeingModified ? filterSelectionCalloutTarget : '#addFilterButton'}
          isBeakVisible={false}
          calloutWidth={212}
          directionalHint={DirectionalHint.bottomLeftEdge}
          setInitialFocus={true}
          role="dialog"
          ariaLabelledBy="filterSelDialogLabel"
          shouldRestoreFocus={true}
          onDismiss={(ev) => {
            if (!ev) {
              return;
            }
            // if target clicks on another filterInfo then no action required.
            const target = ev.target as HTMLElement;
            const isTargetOutsideGridFilter = this._gridFilter.current && !elementContains(this._gridFilter.current, target);
            const isTargetInsideFilterContainer = this._filterListContainer.current && this._filterListContainer.current === target;
            if (isTargetOutsideGridFilter || isTargetInsideFilterContainer || ('key' in ev && ev.key === 'Escape')) {
              this.setState({
                appliedFilterList: [...appliedFilterList],
                filterSelectionCalloutOpen: false,
                isFilterBeingModified: false,
              });
            }
          }}
        >
          {filterSelectionColumn ? (
            <div className={this._classNames.filterSelectionCallout}>
              <Label id="filterSelDialogLabel" className={this._classNames.filterSelectionCalloutTitle}>
                {filterSelectionColumn.filterName}
              </Label>

              {filterSelectionColumn.filterType !== FilterType.date ? (
                <>
                  <FormItem formWidth={180}>
                    <Dropdown
                      options={filterOptions}
                      defaultSelectedKey={filterSelectionColumn.filterCondition}
                      onChange={(_, option) => {
                        if (option) {
                          filterSelectionColumn.filterCondition = option.key.toString();
                          this.setState({ filterSelectionColumn: { ...filterSelectionColumn } });
                        }
                      }}
                      ariaLabel={
                        filterSelectionColumn.filterType === FilterType.custom
                          ? formatMessage(messages.filterTextBoxAriaLabel)
                          : formatMessage(messages.filterOptionDropdownAriaLabel)
                      }
                    />
                  </FormItem>

                  {filterSelectionColumn.filterType !== FilterType.custom ? (
                    <FormItem formWidth={180}>
                      <TextField
                        errorMessage={textBoxErrorMessage}
                        value={filterSelectionColumn.filterText}
                        placeholder={formatMessage(messages.filterTextBoxAriaLabel)}
                        onChange={(_, filter) => {
                          if (filterSelectionColumn) {
                            filterSelectionColumn.filterText = filter;

                            let errorMessage: string | undefined = undefined;
                            if (filter && filterSelectionColumn.filterType === FilterType.relational && isNaN(Number(filter))) {
                              errorMessage = formatMessage(messages.filterSelectionTextBoxErrorMessage);
                            }
                            this.setState({ filterSelectionColumn: { ...filterSelectionColumn }, textBoxErrorMessage: errorMessage });
                          }
                        }}
                      />
                    </FormItem>
                  ) : null}
                </>
              ) : (
                <>
                  <FormItem formWidth={180}>
                    <DatePicker
                      strings={DayPickerStrings}
                      allowTextInput={true}
                      placeholder={formatMessage(messages.startDatePlaceholder)}
                      value={filterSelectionColumn.startDate}
                      maxDate={filterSelectionColumn.endDate || new Date(Date.now())}
                      onSelectDate={(value) => {
                        filterSelectionColumn.startDate = value!;
                        this.setState({ filterSelectionColumn: filterSelectionColumn });
                      }}
                      disabled={filterSelectionColumn.includeNulls === true}
                    />
                  </FormItem>
                  <FormItem formWidth={180}>
                    <DatePicker
                      strings={DayPickerStrings}
                      allowTextInput={true}
                      placeholder={formatMessage(messages.endDatePlaceholder)}
                      maxDate={new Date(Date.now())}
                      minDate={filterSelectionColumn.startDate}
                      value={filterSelectionColumn.endDate}
                      onSelectDate={(value) => {
                        filterSelectionColumn.endDate = value!;
                        this.setState({ filterSelectionColumn: filterSelectionColumn });
                      }}
                      disabled={filterSelectionColumn.includeNulls === true}
                    />
                  </FormItem>
                </>
              )}

              {filterSelectionColumn.showNullsCheckboxLabel ? (
                <FormItem formWidth={180}>
                  <Checkbox
                    label={filterSelectionColumn.showNullsCheckboxLabel}
                    checked={filterSelectionColumn.includeNulls === undefined ? false : filterSelectionColumn.includeNulls}
                    onChange={(_, checked) => {
                      filterSelectionColumn.includeNulls = checked;
                      this.setState({ filterSelectionColumn: filterSelectionColumn });
                    }}
                    disabled={!!(filterSelectionColumn.startDate || filterSelectionColumn.endDate)}
                  />
                </FormItem>
              ) : null}

              <div className={this._classNames.filterSelectionToolBar}>
                <DefaultButton
                  className={!applyButtonDisabled ? this._classNames.filterSelectionApplyButton : this._classNames.disabledApplyButton}
                  text={formatMessage(messages.applyFilterButtonLabel)}
                  disabled={applyButtonDisabled}
                  ariaHidden={applyButtonDisabled}
                  ariaDescription={formatMessage(messages.applyFilterAriaDesc)}
                  onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
                    return isFilterBeingModified
                      ? this._onFilterModified(ev, filterSelectionCalloutTarget)
                      : this._onFilterApplyButtonClicked(ev);
                  }}
                />
                <DefaultButton
                  className={this._classNames.filterSelectionCancelButton}
                  text={formatMessage(appMessages.cancel)}
                  onClick={() => {
                    this.setState({
                      filterSelectionCalloutOpen: false,
                      isFilterBeingModified: false,
                      appliedFilterList: [...appliedFilterList],
                    });
                    // here in the condition when the filter is clicked from a link which is not focussed,
                    // we need to get the focused list element from the DOM and return the focus on it
                    const target = getDocument()!.querySelector(this.state.filterSelectionCalloutTarget!) as HTMLElement;
                    focusFirstChild(target);
                  }}
                  onKeyDown={(ev) => {
                    // applied on the cancel button as it's last active item on the callout
                    if (ev.key === 'Tab' && !ev.shiftKey) {
                      this.setState({
                        filterSelectionCalloutOpen: false,
                        isFilterBeingModified: false,
                        appliedFilterList: [...appliedFilterList],
                      });
                    }
                  }}
                />
              </div>
            </div>
          ) : null}
        </Callout>
      </>
    );
  };

  private _renderFilterSummaryCallout = (): JSX.Element => {
    const { appliedFilterList } = this.state;

    return (
      <Callout
        className={this._classNames.filterSummaryCallout}
        target={'#viewAllButton'}
        ariaLabelledBy="viewAllDailogLabel"
        role="dialog"
        directionalHint={DirectionalHint.rightCenter}
        setInitialFocus={true}
        shouldRestoreFocus={true}
        onDismiss={() => this.setState({ filterSummaryCalloutOpen: false })}
      >
        <div className={this._classNames.filterSummaryCalloutHeader}>
          <Label id="viewAllDailogLabel" className={this._classNames.filterSummaryCalloutTitle}>
            {this.props.intl.formatMessage(messages.filtersTitle)}
          </Label>
          <IconButton
            className={this._classNames.calloutCloseIcon}
            iconProps={{ iconName: 'Cancel' }}
            onClick={(_) => this.setState({ filterSummaryCalloutOpen: false })}
            tabIndex={0}
            onKeyDown={(ev: React.KeyboardEvent<HTMLDivElement>) => {
              // To make sure shift + Tab focuses on the previous element in the tab order,
              // which will be taken care if callout is dismissed
              if ((ev.shiftKey && ev.key === 'Tab') || ev.key === 'Enter ' || ev.key === ' ') {
                this.setState({ filterSummaryCalloutOpen: false });
              }
            }}
          />
        </div>

        <FocusZone
          role="list"
          direction={FocusZoneDirection.vertical}
          isCircularNavigation={true}
          aria-describedby="filterListAriaDescription"
        >
          {appliedFilterList.map((fil: IFilterDetails, index: number) => (
            <div
              className={this._classNames.filterInfoSummaryItem}
              id={`gridFilterSummary-${index}`}
              key={`gridFilterSummary-${index}`}
              onClick={(ev) => {
                this.setState({ filterSummaryCalloutOpen: false });
                this._onFilterInfoUnfocusedClicked(ev);
              }}
              onKeyDown={(ev: React.KeyboardEvent<HTMLDivElement>) => {
                // Dismiss the callout if tab key is pressed, so that focus is moved to next element in the tab order
                if (ev.key === 'Tab' && !ev.shiftKey) {
                  this.setState({ filterSummaryCalloutOpen: false });
                }
                if (isButtonPressKey(ev.key)) {
                  this.setState({ filterSummaryCalloutOpen: false });
                  this._onFilterInfoUnfocusedClicked(ev);
                }
              }}
              role="button"
              tabIndex={0}
              data-is-focusable={true}
              data-list-index={index}
            >
              {fil.filterType === FilterType.custom
                ? `${fil.filterName}: ${fil.filterCondition}`
                : fil.filterType === FilterType.date
                ? `${fil.filterName}${fil.startDate ? ' from ' + fil.startDate.toLocaleDateString() : ''}${
                    fil.endDate ? ' to ' + fil.endDate.toLocaleDateString() : ''
                  }`
                : `${fil.filterName} ${fil.filterCondition} ${fil.filterText}`}
              {fil.includeNulls ? ` ${fil.showNullsCheckboxLabel}` : ''}
            </div>
          ))}
        </FocusZone>
      </Callout>
    );
  };

  private _onFilterMenuItemClicked = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>): void => {
    const index = this._getIndexOfElement(ev);

    if (index !== undefined) {
      const { filterItems } = this.props;
      const filterItem = filterItems[index];

      if (filterItem) {
        const filterSelectionColumn: IFilterDetails = {
          filterId: this._getFilterContainerId(filterItem),
          filterType: filterItem.filterType || FilterType.string,
          filterOptions: filterItem.filterOptions,
          columnName: filterItem.columnName,
          filterName: filterItem.filterName,
          customFunction: filterItem.customFunction,
          includeNulls: filterItem.includeNullsInFilterResults,
          showNullsCheckboxLabel: filterItem.showNullsCheckboxLabel,
        };

        this.setState({
          filterMenuCalloutOpen: false,
          filterSelectionCalloutOpen: true,
          filterSelectionColumn: filterSelectionColumn,
        });
      }
      this.props.onFilterMenuCalloutClose();
    }
  };

  private _onFilterApplyButtonClicked = (ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): void => {
    const { appliedFilterList, filterSelectionColumn } = this.state;
    const isFilterUnique = this._isFilterUnique(filterSelectionColumn);

    if (filterSelectionColumn && isFilterUnique) {
      appliedFilterList.push(filterSelectionColumn);

      this.setState({
        appliedFilterList: [...appliedFilterList],
        filterSelectionCalloutOpen: false,
        isFilterBarFocused: true,
        isFilterBeingModified: false,
      });

      this.props.onFilterApplied(appliedFilterList);
      return;
    }

    // if filter is not unique then open the existing filter
    // updating appliedFilterList so that list is re-rendered
    if (filterSelectionColumn && !isFilterUnique) {
      this.setState({
        appliedFilterList: [...appliedFilterList],
        isFilterBarFocused: true,
        isFilterBeingModified: true,
        filterSelectionCalloutOpen: true,
        filterSelectionCalloutTarget: `#${filterSelectionColumn.filterId}`,
      });
    }
  };

  private _isFilterUnique = (fil: IFilterDetails | undefined): boolean => {
    const { appliedFilterList } = this.state;

    if (!fil) {
      return false;
    }

    switch (fil.filterType) {
      case FilterType.string:
      case FilterType.relational: {
        return appliedFilterList.filter(
          (f) => f.columnName === fil.columnName && f.filterCondition === fil.filterCondition && f.filterText === fil.filterText
        ).length > 0
          ? false
          : true;
      }

      case FilterType.custom:
        return appliedFilterList.filter((f) => f.columnName === fil.columnName && f.filterCondition === fil.filterCondition).length > 0
          ? false
          : true;

      case FilterType.date:
        return appliedFilterList.filter(
          (f) => f.columnName === fil.columnName && f.startDate === fil.startDate && f.endDate === fil.endDate
        ).length > 0
          ? false
          : true;
      default:
        return true;
    }
  };

  private _onFilterModified = (ev: React.MouseEvent<HTMLDivElement>, filterId: string | undefined): void => {
    const { appliedFilterList, filterSelectionColumn } = this.state;

    if (filterSelectionColumn && filterId !== undefined) {
      filterId = filterId?.slice(1); // to remove # placed at index 0

      const modifyFilter = appliedFilterList.filter((f) => f.filterId === filterId)?.[0];
      if (modifyFilter) {
        modifyFilter.filterCondition = filterSelectionColumn.filterCondition;
        modifyFilter.filterText = filterSelectionColumn.filterText;
        modifyFilter.startDate = filterSelectionColumn.startDate;
        modifyFilter.endDate = filterSelectionColumn.endDate;
      }

      this.setState({
        isFilterBeingModified: false,
        filterSelectionCalloutOpen: false,
        isFilterBarFocused: true,
        appliedFilterList: [...appliedFilterList],
      });
      this.props.onFilterApplied(appliedFilterList);
    }
  };

  private _onFilterRemoveButtonClicked = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>): void => {
    const { appliedFilterList } = this.state;
    const index = this._getIndexOfParentElement(ev);

    if (index !== undefined) {
      appliedFilterList.splice(index, 1);

      // in cases when user removes the filter when filterSelectionCallout is open for filter edit, dismiss the callout
      this.setState({ appliedFilterList: [...appliedFilterList], filterSelectionCalloutOpen: false, isFilterBeingModified: false });
      this.props.onFilterApplied(appliedFilterList);
    }
  };

  private _onFilterInfoFocusedClicked = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>): void => {
    const { appliedFilterList } = this.state;
    const index = this._getIndexOfParentElement(ev);

    if (index !== undefined) {
      const filterCol = appliedFilterList[index];

      // updating appliedFilterList so that List is re-rendered after applying updated state properties
      this.setState({
        appliedFilterList: [...appliedFilterList],
        isFilterBarFocused: true,
        isFilterBeingModified: true,
        filterSelectionCalloutOpen: true,
        filterSelectionColumn: filterCol,
        filterSelectionCalloutTarget: `#${filterCol.filterId}`,
      });
    }
  };

  private _onFilterInfoUnfocusedClicked = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ): void => {
    const { appliedFilterList } = this.state;
    const index = this._getIndexOfElement(ev);

    if (index !== undefined) {
      const filterCol = appliedFilterList[index];

      this.setState({
        isFilterBarFocused: true,
        isFilterBeingModified: true,
        filterSelectionCalloutOpen: true,
        filterSelectionColumn: filterCol,
        filterSelectionCalloutTarget: `#${filterCol.filterId}`,
      });
    }
  };

  private _getIndexOfElement = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ): number | undefined => {
    const el = ev.currentTarget as HTMLDivElement;

    if (el.getAttribute('data-list-index')) {
      const index = Number(el.getAttribute('data-list-index'));
      return index;
    }
    return undefined;
  };

  private _getIndexOfParentElement = (ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>): number | undefined => {
    const el = ev.currentTarget.parentElement as HTMLDivElement;

    if (el.getAttribute('data-list-index')) {
      const index = Number(el.getAttribute('data-list-index'));
      return index;
    }
    return undefined;
  };

  private _addListeners() {
    const window = getWindow()!;

    this._async.setTimeout(
      () =>
        this._tempDisposables.push(
          on(window.document.documentElement, 'focus', this._dismissOnLostFocus, true),
          on(window.document.documentElement, 'click', this._dismissOnLostFocus, true)
        ),
      0
    );
  }

  private _dismissOnLostFocus = (ev: Event) => {
    const target = ev.target as HTMLElement;
    const isEventTargetOutsideGridFilter = this._gridFilter.current && !elementContains(this._gridFilter.current, target);

    const currentDoc: Document = getDocument()!;
    const addFiltertarget = currentDoc ? (currentDoc.querySelector('#addFilterButton') as HTMLElement) : null;
    const isTargetAddFilterButton =
      (addFiltertarget && target === addFiltertarget) || elementContains(addFiltertarget, target, true) ? true : false;

    if (isEventTargetOutsideGridFilter && !isTargetAddFilterButton) {
      this.setState({ isFilterBarFocused: false });
    }
  };

  private _getFilterContainerId = (fil?: IFilterItem): string => {
    let filterContainerId = fil ? fil.columnName : '';
    filterContainerId = getId(filterContainerId);
    return filterContainerId;
  };
}
