import { mutator } from 'satcheljs';
import { setGlobalSearchResult, setSearchingStatus, setSearchQuery } from '../actions/onSearchTriggered';
import { getGlobalSearchCalloutStore } from '../store/store';

mutator(setGlobalSearchResult, msg => {
  const store = getGlobalSearchCalloutStore();
  store.data = msg.data;
});

mutator(setSearchQuery, msg => {
  const store = getGlobalSearchCalloutStore();
  store.searchText = msg.searchQuery;
});

mutator(setSearchingStatus, msg => {
  getGlobalSearchCalloutStore().searching = msg.searching;
});
