import { IPubcenterSpacing } from '../../../theme';
import { IPageLayoutStyleProps, IPageLayoutStyles } from './PageLayout.types';

export const getStyles = (props: IPageLayoutStyleProps): IPageLayoutStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      overflow: 'hidden',
    },
    wrapper: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    supportButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    supportButton: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing.size12,
      img: {
        marginRight: spacing.size6,
      },
    },
    spinnerStyle: {
      flex: 1,
      display: 'flex',
      selectors: {
        '.ms-Spinner-circle': {
          height: 67,
          width: 67,
        },
      },
    },
    overlayStyle: {
      flex: 1,
      display: 'flex',
      position: 'fixed',
      backgroundColor: 'white',
      zIndex: 9999999,
    },
  };
};
