import { AuditLog } from '../../../../@data';
import ActionDetailsMessages from '../ActionDetails.messages';
import { ActionDetail } from './getActionDetails';

export function getActionDetailsForUser(actionDetail: ActionDetail, auditLog: AuditLog, formatMessage) {
  actionDetail.title = formatMessage(ActionDetailsMessages.notificationPreferencesTitle);
  // Update it to "Updated user details" in case there are other attributes updated

  if (auditLog.changes) {
    actionDetail.changes = auditLog.changes.map((change) => {
      if (change.log) {
        switch (change.attributeChanged) {
          case 'ServingStatusNotification':
            return `${formatMessage(ActionDetailsMessages.ServingStatusNotification, { status: change.log })}`;
          case 'BillingAlertNotification':
            return `${formatMessage(ActionDetailsMessages.BillingAlertNotification, { status: change.log })}`;
          case 'InvitedUserNotification':
            return `${formatMessage(ActionDetailsMessages.InvitedUserNotification, { status: change.log })}`;
          case 'ProfileReviewNotification':
            return `${formatMessage(ActionDetailsMessages.ProfileReviewNotification, { status: change.log })}`;
        }
      }
      if (change.attributeChanged) {
        return `${formatMessage(ActionDetailsMessages.updated)} ${change.attributeChanged}`;
      }

      return '';
    });
  }
}
