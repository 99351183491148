import { CommandBarButton, DirectionalHint, IconButton, Link, getTheme } from '@fluentui/react';
import React from 'react';
import {
  AdsTxtStatus,
  ClarityStatus,
  EntityEvaluationStatus,
  PopupType,
  Property,
  ServingStatus,
  ServingStatusLabel,
  getAccessPermission,
  getAppStore,
  isTythonSelfServeUser,
} from '../../../../../@data';
import {
  UserRoleEntity,
  completeAccess,
  hasDeleteAccess,
  hasReadAccess,
  hasUpdateAccess,
  userRoleKey,
} from '../../../../../@data/services/UserRoleService';
import { Property as PropertyAccessPermissions } from '../../../../../@data/store/schema/userrolemodels/Property';
import AppMessages from '../../../../../App.messages';
import {
  ADS_DOT_TEXT_HELP_URL,
  CLARITY_WEB_APP,
  CONNECT_SITE_HELP_URL,
  SITE_APPROVAL_HELP_URL,
} from '../../../../../constants/AppConstants';
import { IGridV2Column } from '../../../../../layout/GridPageV2';
import { setDialogType } from '../../../../../layout/GridPageV2/@data/mutatorActions';
import adManagementMessages from '../../../AdManagement.messages';
import messages from '../messages';

import { FormattedMessage } from 'react-intl';
import { FormState } from '../../../../../components/Form';
import Tag, { TagType } from '../../../../../components/Tag';
import { CellInfoCallout } from '../../../../../layout/GridPageV2/components/CellInfoCallout';
import { HeaderInfoCallout } from '../../../../../layout/GridPageV2/components/HeaderInfoCallout';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/services/AdManagementService';
import { connectToSupportTeam } from '../../../Home/components/ResourcesSection/ResourcesSection';
import { onResendClarityLink } from '../../Create/__data__/orchestrators';
import { setCodePanelVisibility } from '../__data__/mutatorActions';
import { getGridPropertyStore } from '../__data__/store/store';
import { getClassNames, getStyles } from '../styles';
import { getServingStatusCalloutContent } from './getServingStatusCalloutContent';

export const getTagType = (status: ServingStatus): TagType => {
  switch (status) {
    case ServingStatus.Serving:
      return TagType.Success;
    case ServingStatus.Pending:
      return TagType.Pending;
    case ServingStatus.NotServing:
      return TagType.Fail;
    default:
      return TagType.Pending;
  }
};

const getClarityStatus = (status?: ClarityStatus, formatMessage?) => {
  switch (status) {
    case ClarityStatus.Approved:
      return formatMessage(messages.clarityApproved);
    case ClarityStatus.Pending:
      return formatMessage(messages.clarityPendingApproval);
    case ClarityStatus.Declined:
      return formatMessage(messages.clarityRejected);
    case ClarityStatus.Expired:
      return formatMessage(messages.clarityExpired);
    case ClarityStatus.Cancelled:
      return formatMessage(messages.clarityCancelled);
    case ClarityStatus.NotFound:
      return formatMessage(messages.clarityNotFound);
    default:
      return formatMessage(messages.clarityNotConnected);
  }
};

const getClarityCalloutDescriptionStatus = (status?: ClarityStatus): ReactIntl.FormattedMessage.MessageDescriptor => {
  switch (status) {
    case ClarityStatus.Pending:
      return messages.clarityLinkPendingStatusCalloutDescription;
    case ClarityStatus.Declined:
      return messages.clarityLinkRejectStatusCalloutDescription;
    case ClarityStatus.Expired:
      return messages.clarityLinkExpiredStatusCalloutDescription;
    case ClarityStatus.Cancelled:
      return messages.clarityLinkCancelledStatusCalloutDescription;
    case ClarityStatus.NotConnected:
      return messages.clarityLinkNotConnectedStatusCalloutDescription;
    default:
      return messages.clarityLinkNotConnectedStatusCalloutDescription;
  }
};

const editRouter = (item: Property, routeHistory, step?: number, showExtraBtns?: boolean) => {
  const { publisher, account } = getAppStore();
  const returnUrl = createReturnUrl(RouteName.property, publisher!.id, account!.id, item.id, FormState.MultiStepEdit, step, showExtraBtns);
  routeHistory.push(returnUrl);
};

const getCallOutContent = (
  item: Property,
  formatMessage,
  routeHistory,
  clarityProjectId?: string,
  status?: ClarityStatus
): JSX.Element | null => {
  const styles = getClassNames(getStyles, { theme: getTheme() });

  return status === ClarityStatus.NotFound ? (
    formatMessage(messages.clarityLinkNotFoundStatusCalloutDescription)
  ) : (
    <>
      <span className={styles.clarityCalloutTitle}>{formatMessage(messages.clarityLinkStatusCalloutTitle)}</span>
      {status !== ClarityStatus.NotConnected && (
        <>
          <br />
          <span className={styles.clarityCalloutTitle}>
            {formatMessage(messages.clarityLinkStatusCalloutProjectStatus, {
              id: clarityProjectId,
              status: getClarityStatus(status, formatMessage),
            })}
          </span>
        </>
      )}
      <p>{formatMessage(getClarityCalloutDescriptionStatus(status))}</p>
      <div className={styles.clarityCalloutFooter}>
        <Link onClick={() => onResendClarityLink(item.id, clarityProjectId!)}>
          {formatMessage(messages.clarityLinkStatusCalloutResendAction)}
        </Link>
        <Link onClick={() => editRouter(item, routeHistory, 0, true)}>{formatMessage(AppMessages.edit)}</Link>
      </div>
    </>
  );
};

const getPropertyAccessPermission = (): PropertyAccessPermissions | null => {
  const userRoles = localStorage.getItem(userRoleKey);
  if (userRoles === null) {
    return null;
  }
  const userRolesJSON = JSON.parse(userRoles);

  for (const value in userRolesJSON) {
    if (value === UserRoleEntity.Property) {
      return JSON.parse(userRolesJSON[value]);
    }
  }

  return null;
};

export const getServingStatusLabel = (servingStatus: ServingStatus, formatMessage) => {
  const servingStatusType = Object.keys(ServingStatus)[Object.values(ServingStatus).indexOf(servingStatus)];
  return servingStatusType ? formatMessage(adManagementMessages[ServingStatusLabel[servingStatusType]]) : '';
};

export const getGridColumns = (
  formatMessage,
  routeHistory,
  isWindowRegularSize,
  isFetchingPropertyStatus,
  servingConfigs,
  changeState
): IGridV2Column[] => {
  const tythonUser = isTythonSelfServeUser();
  const propertyAccessPermissions = getPropertyAccessPermission();
  const { publisher, publisherEvaluationStatus } = getAppStore();
  const styles = getClassNames(getStyles, { theme: getTheme() });
  const isPublisherRejected = publisherEvaluationStatus?.approvalStatus === EntityEvaluationStatus.Rejected;

  const commonColumns: IGridV2Column[] = [
    {
      columnOrder: 0,
      key: 'name',
      fieldName: 'name',
      name: formatMessage(tythonUser ? messages.siteName : messages.name),
      minWidth: 80,
      maxWidth: 150,
      isSearchable: true,
      isVisible: tythonUser ? true : hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.name),
      isShowingDisabled: tythonUser
        ? true
        : hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.name),
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 1,
      key: 'id',
      fieldName: 'id',
      name: formatMessage(tythonUser ? messages.siteId : messages.id),
      minWidth: 50,
      maxWidth: 100,
      isSearchable: true,
      isVisible: tythonUser ? true : hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.id),
      isShowingDisabled: tythonUser
        ? true
        : hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.id),
      isExportable: true,
      isResizable: true,
      isSortedDescending: true,
    },
    {
      columnOrder: 2,
      key: 'url',
      fieldName: 'url',
      name: formatMessage(messages.url),
      minWidth: 100,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: tythonUser
        ? true
        : hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.websiteURL),
      isShowingDisabled: tythonUser
        ? true
        : hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.websiteURL),
      isExportable: true,
    },
    {
      columnOrder: 99,
      key: 'actions',
      name: formatMessage(messages.actions),
      minWidth: 220,
      maxWidth: 350,
      isPadded: true,
      isResizable: true,
      isSearchable: true,
      isVisible: true,
      suppressSorting: true,
      onRender: (item: Property) => {
        const accessPermission = getAccessPermission(item);

        return (
          <div>
            <CommandBarButton
              id={`siteCallout-${item.id}`}
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'edit' }}
              ariaLabel={formatMessage(AppMessages.edit)}
              text={isWindowRegularSize ? formatMessage(AppMessages.edit) : ''}
              disabled={!hasUpdateAccess(accessPermission) || publisher === undefined}
              onClick={() => routeHistory.push(`/ad-management/${publisher?.id}/property/edit/${item.id}`)}
            />
            {isTythonSelfServeUser() && item.id !== undefined && (
              <CommandBarButton
                styles={{ root: { background: 'transparent' } }}
                iconProps={{ iconName: 'PasteAsCode' }}
                ariaLabel={formatMessage(AppMessages.code)}
                text={isWindowRegularSize ? formatMessage(AppMessages.code) : ''}
                onClick={() => setCodePanelVisibility(true, item.id.toString(), item.servingConfig?.clarityConfig?.clarityStatus)}
              />
            )}
            <CommandBarButton
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'Delete' }}
              text={isWindowRegularSize ? formatMessage(AppMessages.delete) : ''}
              onClick={() => setDialogType(PopupType.DeleteConfirmation)}
              disabled={!hasDeleteAccess(accessPermission)}
            />
          </div>
        );
      },
    },
  ];

  const tythonColumns: IGridV2Column[] = [
    {
      columnOrder: 4,
      key: 'servingConfig.servingStatus',
      fieldName: 'servingConfig.servingStatus',
      name: formatMessage(messages.approvalStatus),
      minWidth: 50,
      maxWidth: 200,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isExportable: true,
      isLoading: getGridPropertyStore().isFetchingPropertyStatus && getGridPropertyStore()?.servingConfigs === undefined,
      exportFunction: (item: Property) => item?.servingConfig?.servingStatus,
      onRenderHeader: HeaderInfoCallout({
        width: 350,
        calloutContent: (
          <div>
            <ul style={{ paddingInlineStart: 20 }}>
              <li>{formatMessage(messages.approvalStatusPendingInfo)}</li>
              <li>{formatMessage(messages.approvalStatusApprovalInfo)}</li>
              <li>{formatMessage(messages.approvalStatusRejectedInfo)}</li>
            </ul>
            <FormattedMessage
              {...messages.approvalStatusInfoHelp}
              values={{
                guidelines: (
                  <Link href={SITE_APPROVAL_HELP_URL} target="_blank" rel="noreferrer noopener">
                    {formatMessage(messages.approvalStatusInfoGuideLines)}
                  </Link>
                ),
                support: (
                  <Link onClick={() => connectToSupportTeam()} aria-label={formatMessage(messages.approvalStatusInfoGuideLines)}>
                    {formatMessage(messages.approvalStatusInfoSupport)}
                  </Link>
                ),
              }}
            />
          </div>
        ),
      }),
      onRenderData: (item: Property) =>
        item?.servingConfig?.servingStatus ? (
          <Tag
            status={getTagType(ServingStatus[item?.servingConfig?.servingStatus])}
            text={getServingStatusLabel(ServingStatus[item?.servingConfig?.servingStatus], formatMessage)}
            calloutProps={{
              placement: DirectionalHint.topCenter,
              calloutContent: getServingStatusCalloutContent(formatMessage, isPublisherRejected, item),
            }}
          />
        ) : (
          <></>
        ),
    },
    {
      columnOrder: 3,
      key: 'servingConfig.clarityConfig.clarityId',
      fieldName: 'servingConfig.clarityConfig.clarityId',
      name: formatMessage(messages.clarityProject),
      minWidth: 50,
      maxWidth: 200,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      isLoading: getGridPropertyStore().isFetchingPropertyStatus && getGridPropertyStore()?.servingConfigs === undefined,
      exportFunction: (item: Property) => {
        const clarityProjectId = item?.servingConfig?.clarityConfig?.clarityId;
        const clarityStatus = item?.servingConfig?.clarityConfig?.clarityStatus;

        if (clarityProjectId && clarityStatus === ClarityStatus.Approved) {
          const refLink = CLARITY_WEB_APP.replace('{id}', clarityProjectId);
          return `=HYPERLINK("${refLink}", "${clarityProjectId}")`;
        } else {
          return getClarityStatus(clarityStatus, formatMessage);
        }
      },
      onRenderHeader: () => {
        return (
          <>
            {formatMessage(messages.clarityProject)}
            <IconButton
              id="clarityTourIcon"
              title="Information"
              iconProps={{ iconName: 'Info' }}
              onClick={(e) => {
                changeState();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
      onRenderData: (item: Property) => {
        const clarityProjectId = item?.servingConfig?.clarityConfig?.clarityId;
        const clarityStatus = item?.servingConfig?.clarityConfig?.clarityStatus;

        if (clarityProjectId && clarityStatus === ClarityStatus.Approved) {
          return (
            <Link id="clarityProjectId" href={CLARITY_WEB_APP.replace('{id}', clarityProjectId)} target="_blank" rel="noreferrer noopener">
              {clarityProjectId}
            </Link>
          );
        } else {
          return clarityStatus === ClarityStatus.NotConnected || clarityStatus === undefined ? (
            getClarityStatus(clarityStatus, formatMessage)
          ) : (
            <CellInfoCallout
              placement={DirectionalHint.bottomCenter}
              calloutContent={getCallOutContent(item, formatMessage, routeHistory, clarityProjectId, clarityStatus)}
            >
              <div className={styles.clarityStatusUnderline}>{getClarityStatus(clarityStatus, formatMessage)}</div>
            </CellInfoCallout>
          );
        }
      },
    },
    {
      columnOrder: 5,
      key: 'issues',
      fieldName: 'issues',
      name: formatMessage(messages.issues),
      minWidth: 180,
      maxWidth: 300,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      isLoading: getGridPropertyStore().isFetchingPropertyStatus && getGridPropertyStore()?.servingConfigs === undefined,
      onRenderData: (item: Property) => (
        <>
          <ul className={styles.propertyIssuesUnorderedList}>
            {item?.servingConfig?.scriptStatus === false && (
              <li className={styles.errorBulletPoint}>
                <Link onClick={() => setCodePanelVisibility(true, item?.id.toString(), item?.servingConfig?.clarityConfig?.clarityStatus)}>
                  {formatMessage(messages.siteNotServingPubCenterScriptInActive)}
                </Link>
              </li>
            )}
            {item?.servingConfig?.servingStatus &&
              ServingStatus[item?.servingConfig?.servingStatus.toString()] === ServingStatus.NotServing && (
                <li className={styles.errorBulletPoint}>
                  <Link href={CONNECT_SITE_HELP_URL} target="_blank" rel="noreferrer noopener">
                    {formatMessage(messages.siteNotServingSiteIsRejected)}
                  </Link>
                </li>
              )}
            {item?.servingConfig?.adsDotTxtStatus &&
              (AdsTxtStatus[item?.servingConfig?.adsDotTxtStatus?.toString()] === AdsTxtStatus.NotFound ||
                AdsTxtStatus[item?.servingConfig?.adsDotTxtStatus?.toString()] === AdsTxtStatus.Scanning) && (
                <li className={styles.warningBulletPoint}>
                  <Link href={ADS_DOT_TEXT_HELP_URL} target="_blank" rel="noreferrer noopener">
                    {formatMessage(messages.siteNotServingWithoutAdsTxt)}
                  </Link>
                </li>
              )}
          </ul>
        </>
      ),
    },
  ];

  const nonTythonColumns: IGridV2Column[] = [
    {
      columnOrder: 3,
      key: 'status',
      fieldName: 'status',
      name: formatMessage(messages.status),
      minWidth: 50,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 4,
      key: 'approvalStatus',
      fieldName: 'approvalStatus',
      name: formatMessage(tythonUser ? messages.approvalStatus : messages.approvalStatus),
      minWidth: 50,
      maxWidth: 100,
      isSearchable: true,
      isVisible: hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.approvalStatus),
      isShowingDisabled: hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.approvalStatus),
      isExportable: true,
      isResizable: true,
      onRender: (item: Property) => item.approvalStatus,
    },
    {
      columnOrder: 5,
      key: 'clarityStatus',
      fieldName: 'clarityStatus',
      name: formatMessage(messages.clarityStatus),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSearchable: true,
      isVisible: hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.clarity),
      isShowingDisabled: hasReadAccess(propertyAccessPermissions === null ? completeAccess : propertyAccessPermissions.clarity),
      isExportable: true,
      onRender: (item: Property) => {
        if (item.clarityProjectId && (item.clarityStatus === 'Approved' || item.clarityStatus === item.clarityProjectId)) {
          return (
            <Link href={CLARITY_WEB_APP.replace('{id}', item?.clarityProjectId)} target="_blank" rel="noreferrer noopener">
              {item.clarityStatus}
            </Link>
          );
        } else {
          return item.clarityStatus;
        }
      },
      exportFunction: (item: Property) => {
        if (item.clarityStatus === 'Approved' && item.clarityProjectId) {
          const refLink = CLARITY_WEB_APP.replace('{id}', item.clarityProjectId);
          return `=HYPERLINK("${refLink}", "${item.clarityProjectId}")`;
        } else {
          return item.clarityStatus;
        }
      },
    },
  ];

  if (getGridPropertyStore().servingConfigs !== undefined && getGridPropertyStore().servingConfigs!.length > 0) {
    nonTythonColumns.push({
      columnOrder: 4.5,
      key: 'servingStatus',
      fieldName: 'servingStatus',
      name: formatMessage(messages.servingStatus),
      minWidth: 100,
      maxWidth: 200,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
      isLoading: getGridPropertyStore().isFetchingPropertyStatus && getGridPropertyStore()?.servingConfigs === undefined,
      onRenderData: (item: Property) => {
        const servingStatus = getGridPropertyStore()?.servingConfigs?.find((property) => property.propertyId === item.id)?.servingStatus;
        return <>{servingStatus}</>;
      },
    });
  }

  const newColumns = [...commonColumns, ...(isTythonSelfServeUser() ? tythonColumns : nonTythonColumns)];
  return newColumns;
};
