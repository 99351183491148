export const countryToCurrencyMap = {
  AE: 'EUR',
  AF: 'USD',
  AG: 'XCD',
  AL: 'USD',
  AM: 'USD',
  AO: 'USD',
  AR: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AZ: 'AZN',
  BA: 'USD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'USD',
  BJ: 'XOF',
  BO: 'USD',
  BR: 'USD',
  BW: 'BWP',
  BY: 'USD',
  CA: 'CAD',
  CD: 'USD',
  CF: 'XAF',
  CG: 'XAF',
  CH: 'CHF',
  CI: 'XOF',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'USD',
  CO: 'COP',
  CR: 'CRC',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DK: 'DKK',
  DM: 'XCD',
  DO: 'DOP',
  DZ: 'USD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  ER: 'USD',
  ES: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FR: 'EUR',
  GB: 'GBP',
  GE: 'USD',
  GH: 'GHS',
  GN: 'USD',
  GR: 'EUR',
  GT: 'GTQ',
  HK: 'HKD',
  HN: 'USD',
  HR: 'HRK',
  HT: 'USD',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IN: 'INR',
  IQ: 'USD',
  IS: 'USD',
  IT: 'EUR',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KH: 'USD',
  KM: 'USD',
  KR: 'KRW',
  KW: 'KWD',
  KZ: 'KZT',
  LA: 'USD',
  LB: 'LBP',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'USD',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'LVL',
  MA: 'MAD',
  MC: 'EUR',
  ME: 'EUR',
  MG: 'USD',
  MK: 'USD',
  ML: 'XOF',
  MN: 'USD',
  MT: 'EUR',
  MU: 'MUR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'USD',
  MZ: 'USD',
  NE: 'XOF',
  NG: 'NGN',
  NI: 'USD',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'USD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'USD',
  PE: 'PEN',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PT: 'EUR',
  PY: 'USD',
  QA: 'QAR',
  RO: 'USD',
  RS: 'USD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SE: 'SEK',
  SG: 'SGD',
  SI: 'EUR',
  SK: 'EUR',
  SL: 'USD',
  SN: 'XOF',
  SO: 'USD',
  SV: 'USD',
  TD: 'XAF',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'USD',
  TL: 'USD',
  TM: 'USD',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'USD',
  UG: 'UGX',
  US: 'USD',
  UY: 'UYU',
  UZ: 'USD',
  VC: 'XCD',
  VE: 'USD',
  VN: 'VND',
  ZA: 'ZAR',
  ZM: 'ZMW',
  ZW: 'USD'
};
