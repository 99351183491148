import { AuditLog } from '../../../../@data';
import messages from '../ActionDetails.messages';
import AuditHistoryMessages from '../AuditHistory.messages';
import { ActionDetail } from './getActionDetails';

export function getActionDetailsForPayment(actionDetail: ActionDetail, auditLog: AuditLog, formatMessage) {
  if (auditLog.changes) {
    actionDetail.changes = auditLog.changes.map((change) => {
      if (change.log) {
        if (change.attributeChanged === 'Transaction') {
          const [status, amount] = getTransactionStatusAndAmount(change.log, formatMessage);
          return formatMessage(messages.TransactionMessage, { n: amount, status: status });
        }
      }

      if (!change.oldValue && change.newValue) {
        return `${formatMessage(messages.addedAs, {
          attributeChanged: formatMessage(messages.PaymentAccountStatus),
          newValue: change.newValue,
        })}`;
      }

      if (change.oldValue && change.newValue) {
        return `${formatMessage(messages.updatedMessageFormat, {
          attributeChanged: formatMessage(messages.PaymentAccountStatus),
          oldValue: change.oldValue,
          newValue: change.newValue,
        })}`;
      }
      return '';
    });
  }
  if (actionDetail.changes && actionDetail.changes.length === 1) {
    actionDetail.title = actionDetail.changes[0];
    actionDetail.changes = [];
  } else {
    actionDetail.title = `${formatMessage(messages.updated)} ${formatMessage(AuditHistoryMessages.payment)} ${formatMessage(
      messages.details
    )}`;
  }
}

function getTransactionStatusAndAmount(message: string, formatMessage) {
  let status = '',
    amount = 0;
  message = message.toLowerCase();

  if (message.includes('failed')) {
    status = formatMessage(messages.Failed);
  } else if (message.includes('settled')) {
    status = formatMessage(messages.Settled);
  } else if (message.includes('processing')) {
    status = formatMessage(messages.Processing);
  }

  const transactionMessageParts = message.split(' ');
  if (transactionMessageParts.length > 2) {
    amount = Number(transactionMessageParts[1]);
  }

  return [status, amount];
}
