import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterSpacing } from '../../../theme';
import { IColorPickerClassNames, IColorPickerStyleProps } from './ColorPicker.types';

export const getClassNames = (props: IColorPickerStyleProps): IColorPickerClassNames => {
  const theme = getTheme();
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;

  return mergeStyleSets({
    root: {
      display: 'flex',
      padding: `3px ${spacing.size8}`,
      cursor: 'pointer',
      backgroundColor: palette.white,
      border: `1px solid`,
      borderColor: 'transparent',
      selectors: {
        ':hover': {
          backgroundColor: palette.neutralLighter,
        },
        '&:pressed': {
          backgroundColor: palette.neutralLight,
        },
        '&:focus': {
          borderRadius: '2px',
          borderColor: palette.neutralSecondary,
        },
      },
    },
    container: {
      display: 'relative',
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: spacing.size8,
    },
    bucketIcon: {
      fontSize: theme.fonts.xLarge.fontSize,
      color: palette.neutralPrimary,
    },
    chevronIcon: {
      color: palette.neutralPrimary,
      fontSize: theme.fonts.small.fontSize,
      alignContent: 'center',
      width: spacing.size12,
      paddingTop: spacing.size8,
    },
    colorIcon: {
      borderWidth: '0.5px',
      borderStyle: 'solid',
      borderColor: palette.neutralQuaternaryAlt,
      height: spacing.size4,
      backgroundColor: props.colorSelected,
      marginTop: spacing.size2,
    },
    boldFieldTitle: {
      lineHeight: spacing.size20,
      fontSize: theme.fonts.medium.fontSize,
      fontWeight: FontWeights.semibold,
      color: palette.neutralPrimary,
    },
    swatchColorPickerCallout: {
      padding: spacing.size12,
      width: 'fit-content',
      backgroundColor: palette.white,
      gap: spacing.size8,
      position: 'absolute',
      boxShadow: '0px 1.2px 3.6px rgba(0,0,0.1), 0px 6.4px 14.4px rgba(0,0,0.13)',
    },
  });
};
