import React, { useEffect } from 'react';
import { usePreloadSVGImages } from '../../../../../hooks';
import { injectRouterAndIntlWithObserver } from '../../../../../utils';
import {
  getAdBlocksPageRoute,
  getSitesRoute,
  getTythonAccountSettingPageRoute,
  getTythonPaymentPageRoute,
} from '../../../../../utils/routeUtils';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import { onInitializeRecommendationSection } from '../../@data/actions';
import { getStore } from '../../@data/store/store';
import performanceImg from '../PerformanceSection/resources/Payment.svg';
import { SetupCard } from '../SetupCard/SetupCard';
import { ISetupCard } from '../SetupCard/SetupCardUtils';
import messages from './RecommendationsSection.messages';
import { getClassNames } from './RecommendationsSection.styles';
import adBlockImg from './resources/adBlock.svg';
import adsTxtImg from './resources/adsTxt.svg';
import mediationImg from './resources/mediation.svg';
import mediationIconImg from './resources/mediationIcon.svg';
import paymentImg from './resources/payment.svg';
import { getRecommendationsCards, getRecommendationsCardsPlaceholder } from './utils/getCards';

export const RecommendationsSection = injectRouterAndIntlWithObserver(function RecommendationsSectionComponent({
  intl: { formatMessage },
  history,
}) {
  const imgPreloadQueue = [adBlockImg, adsTxtImg, mediationImg, mediationIconImg, paymentImg, performanceImg];
  usePreloadSVGImages(imgPreloadQueue);
  const { cardsContainer, headerSection } = getClassNames();
  const { isSetupDone } = getStore();
  const { active } = getGoogleAdSenseStore();

  let recommendationsCards = getRecommendationsCardsPlaceholder();

  if (active.connectionStatus) {
    recommendationsCards = getRecommendationsCards(
      formatMessage,
      handlePaymentCardButtonClick,
      handleMediationButtonClick,
      handleAdsTxtCardButtonClick,
      handleAdBlockCardButtonClick,
      active.isMediationEnabled
    );
  }

  useEffect(() => {
    onInitializeRecommendationSection();
  }, []);

  function handlePaymentCardButtonClick() {
    history.push(getTythonPaymentPageRoute());
  }

  function handleMediationButtonClick() {
    history.push(getTythonAccountSettingPageRoute());
  }

  function handleAdsTxtCardButtonClick() {
    history.push(getSitesRoute());
  }

  function handleAdBlockCardButtonClick() {
    history.push(getAdBlocksPageRoute());
  }

  const displaySetupCards = (cards: ISetupCard[]) => {
    return cards.filter((card) => !card.hidden).map((card, index) => <SetupCard card={card} key={index} />);
  };

  return (
    <>
      {isSetupDone ? (
        <div>
          <h2 className={headerSection}>{formatMessage(messages.recommendationsTitle)}</h2>
          <div className={cardsContainer}>{displaySetupCards(recommendationsCards)}</div>
        </div>
      ) : null}
    </>
  );
});
