import { defineMessages } from 'react-intl';

export default defineMessages({
  inputName: {
    id: 'admanagement.adfilter.inputName',
    defaultMessage: 'Ad filter name',
  },
  inputNameLength: {
    id: 'admanagement.adfilter.inputNameLength',
    defaultMessage: 'Do not exceed 35 characters',
  },
  createFilter: {
    id: 'admanagement.adfilter.createFilter',
    defaultMessage: 'Create ad filter',
  },
  editFilter: {
    id: 'admanagement.adfilter.editFilter',
    defaultMessage: 'Update ad filter',
  },
  filterId: {
    id: 'admanagement.adfilter.filterId',
    defaultMessage: 'Ad filter ID',
  },
  filterType: {
    id: 'admanagement.adfilter.filterType',
    defaultMessage: 'Filter Type',
  },
  accountFilter: {
    id: 'admanagement.adfilter.accountFilter',
    defaultMessage: 'Account level filter',
  },
  publisherFilter: {
    id: 'admanagement.adfilter.publisherFilter',
    defaultMessage: 'Publisher level filter',
  },
  inputExcludedURLs: {
    id: 'admanagement.adfilter.inputExcludedURLs',
    defaultMessage: 'Excluded URLs',
  },
  excludedUrlPlaceholder: {
    id: 'admanagement.adfilter.excludedUrlPH',
    defaultMessage: 'http://www.contoso.com',
  },
  inputExcludedKeywordAd: {
    id: 'admanagement.adfilter.inputExcludedKeywordAd',
    defaultMessage: 'Excluded keywords - ad level',
  },
  excludedKeywordPlaceholder: {
    id: 'admanagement.adfilter.excludedKeywordPH',
    defaultMessage: 'Example Keyword 1\nExample Keyword 2\nExample Keyword 3',
  },
  inputExcludedKeywordQuery: {
    id: 'admanagement.adfilter.inputExcludedKeywordQuery',
    defaultMessage: 'Excluded keywords - query level',
  },
  inputAttachChannel: {
    id: 'admanagement.adfilter.inputAttachChannel',
    defaultMessage: 'Attach to channels',
  },
  channelPickerAriaDesc: {
    id: 'admanagement.adfilter.channelPickerAriaDesc',
    defaultMessage: 'Select channels to be attached with the ad filter',
  },
  navMessage: {
    id: 'admanagement.adfilter.navMessage',
    defaultMessage: 'Go back to Ad filter management',
  },
  excludedItems: {
    id: 'admanagement.adfilter.excludedItems',
    defaultMessage: 'Excluded items',
  },
  excludedDescription: {
    id: 'admanagement.adfilter.excludedDescription',
    defaultMessage:
      // tslint:disable-next-line:max-line-length
      'You can enter the items you want to block manually or upload a txt. file. New items are appended to the current list and duplicates are removed.',
  },
  manualEntry: {
    id: 'admanagement.adfilter.manualEntry',
    defaultMessage: 'Manual entry',
  },
  fileUpload: {
    id: 'admanagement.adfilter.fileUpload',
    defaultMessage: 'File upload',
  },
  excludedUrlDescription: {
    id: 'admanagement.adfilter.excludedUrlDescription',
    defaultMessage: 'List URLs to exclude(one per line). May contain up to 250 URLs.',
  },
  excludeAdLevelKeywordsDescription: {
    id: 'admanagement.adfilter.excludeAdLevelKeywordsDescription',
    defaultMessage: 'List ad level keywords to exclude(one per line). May contain up to 50 keywords.',
  },
  excludedQueryLevelKeywordDescription: {
    id: 'admanagement.adfilter.excludedQueryLevelKeywordDescription',
    defaultMessage: 'List query level keywords to exclude(one per line). May contain up to 250 keywords.',
  },
  excludedUrlBulkUploadDescription: {
    id: 'admanagement.adfilter.excludedUrlBulkUploadDescription',
    defaultMessage: 'File may contain up to 5,000 urls.',
  },
  exlcudedKeywordsBulkUploadDescription: {
    id: 'admanagement.adfilter.exlcudedKeywordsBulkUploadDescription',
    defaultMessage: 'File may contain up to 5,000 keywords.',
  },
  exportButtonDesc: {
    id: 'admanagement.adfilter.exportButtonDesc',
    defaultMessage: 'Export a file containing existing {label}',
  },
  timeRangeSectionTitle: {
    id: 'admanagement.adfilter.timeRangeSectionTitle',
    defaultMessage: 'Filter Time Range',
  },
  timeRangeSectionDescription: {
    id: 'admanagement.adfilter.timeRangeSectionDescription',
    defaultMessage:
      '(Optional) Select time range to apply the filter in a specific time range. Start time is set to current datetime by default.',
  },
  filterStartTimeLabel: {
    id: 'admanagement.adfilter.filterStartTimeLabel',
    defaultMessage: 'Filter Start Time (UTC)',
  },
  filterEndTimeLabel: {
    id: 'admanagement.adfilter.filterEndTimeLabel',
    defaultMessage: 'Filter End Time (UTC)',
  },
  filterEndTimePlaceholder: {
    id: 'admanagement.adfilter.filterEndTimePlaceholder',
    defaultMessage: 'Select your end date',
  },
  resetTimeRange: {
    id: 'admanagement.adfilter.resetTimeRange',
    defaultMessage: 'Reset time range',
  },
  undoTimeRange: {
    id: 'admanagement.adfilter.undoTimeRange',
    defaultMessage: 'Undo time range',
  },
  filterUpdateType: {
    id: 'admanagement.adfilter.filterUpdateType',
    defaultMessage: 'Filter update type',
  },
  appendOnly: {
    id: 'admanagement.adfilter.appendOnly',
    defaultMessage: 'Append only',
  },
  nonAppendOnly: {
    id: 'admanagement.adfilter.nonAppendOnly',
    defaultMessage: 'Non-append only',
  },
  startDateErrorMessage: {
    id: 'admanagement.adfilter.startDateErrorMessage',
    defaultMessage: 'Start date must be before end date',
  },
  endDateErrorMessage: {
    id: 'admanagement.adfilter.endDateErrorMessage',
    defaultMessage: 'End date must be after start date and current date time',
  },
});
