import { defineMessages } from 'react-intl';

export default defineMessages({
  headLineGreeting: {
    id: 'TnC.Signature.HeadlineGreeting',
    defaultMessage: 'Dear Publisher,',
  },
  summary: {
    id: 'TnC.summary.content',
    defaultMessage:
      '{appName} has updated Terms and Conditions on {releaseDate}. Please accept the new terms in order to continue using {appName}.',
  },
  thanksMsg: {
    id: 'TnC.summary.thanksMsg',
    defaultMessage: 'Thank you,',
  },
  thanksMsgSubLine: {
    id: 'TnC.summary.thanksMsg.subline',
    defaultMessage: '{appName} Team',
  },
  ackMsg: {
    id: 'TnC.summary.ackMsg',
    defaultMessage: `Yes, I've read and accept the agreement`,
  },
  ackMsgSubline: {
    id: 'TnC.summary.ackMsg.subline',
    defaultMessage:
      'You represent that you are an administrator for this Account and that you are authorized to act on behalf of, and bind to this Agreement, the owner of this Account.',
  },
  acceptButton: {
    id: 'TnC.action.accept',
    defaultMessage: 'Accept',
  },
  skipButton: {
    id: 'TnC.action.skip',
    defaultMessage: 'Skip for now',
  },
  submitting: {
    id: 'TnC.action.submitting',
    defaultMessage: 'Submitting...',
  },
  goToAccept: {
    id: 'TnC.notification.goToAccept',
    defaultMessage: 'Go to accept',
  },
  unknown: {
    id: 'TnC.notification.date.unknown',
    defaultMessage: 'Unknown',
  },
  twoWeekBuffer: {
    id: 'TnC.notification.date.twoWeekBuffer',
    defaultMessage: '2 weeks',
  },
  dayBuffer: {
    id: 'TnC.notification.date.dayBuffer',
    defaultMessage: '{buffer} day(s)',
  },
  tncNotificationContent: {
    id: 'Tnc.notification.content',
    defaultMessage:
      'Accept our latest Terms and Conditions to continue using {appName}. {appName} has updated Terms and Conditions on {latestTnCReleaseDate}. Please accept within {buffer} or your account will be terminated.',
  },
});
