import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import messages from './HelpPanel.messages';

export const HelpPanel = injectIntl(class extends React.Component<InjectedIntlProps>  {
  public render(): JSX.Element {
    const { formatMessage } = this.props.intl;
    return (
      <p>{formatMessage(messages.helpNotImplemented)}</p>
    );
  }
});
