import { getTheme, IStackTokens, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterPalette, IPubcenterTheme } from '../../../../../theme';

interface IPaymentsPageStyleProps {
  theme: ITheme;
}
type StyleProperties = 'chartCard' | 'pageTitleStyle' | 'cardsTitleBarStyle';
type PaymentsPageStyles = Record<StyleProperties, string>;

export const getStyles = (props: IPaymentsPageStyleProps): PaymentsPageStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const palette = theme.palette as IPubcenterPalette;
  const { spacing } = theme;
  return mergeStyleSets({
    chartCard: {
      width: '49%',
      height: 'auto',
    },
    pageTitleStyle: {
      margin: `${spacing.size32} 0 ${spacing.size12}`,
    },
    cardsTitleBarStyle: {
      borderBottom: `1px solid ${palette.neutralLight}`,
      minHeight: '30px',
    },
  });
};
export const chartsContainersStyles = { root: { padding: '1rem' } };
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
export function getStackTokens(): IStackTokens {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing;

  return { childrenGap: spacing.size12 };
}
