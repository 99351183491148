import { action } from 'satcheljs';
import { Channel, IStringDictionary } from '../../../../../@data';
import { IChannelViewModel } from '../store/schema';

export const onRawChanged = action('onRawChanged', (value?: Channel) => ({ value }));

export const onChannelChanged = action('onChannelChanged', (channel: IChannelViewModel) => ({ channel }));

export const onChannelErrorChanged = action('onChannelErrorChanged', (values?: IStringDictionary) => ({ values }));

export const onFetchChannelsLoading = action('onFetchChannelsLoading', (isLoading: boolean) => ({ isLoading }));

export const onFetchingChannelsData = action('onFetchingChannelsData', (isFetchingData: boolean) => ({ isFetchingData }));

export const onChannelsFetched = action('onChannelsFetched', (items: Channel[]) => ({ items }));
