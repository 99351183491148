import { defineMessages } from 'react-intl';

export default defineMessages({
  imageAlt: {
    id: 'NoAccess.imageAlt',
    defaultMessage: 'User does not have access to this website',
  },
  pageTitle: {
    id: 'NoAccess.pageTitle',
    defaultMessage: 'No Access',
  },
  errorMessage: {
    id: 'NoAccess.errorMessage',
    defaultMessage: `{appName} does not recognize this account`,
  },
  errorDescriptionPart1: {
    id: 'NoAccess.errorDescription',
    defaultMessage: `We are unable to validate your request at this time. Please `,
  },
  contactSupport: {
    id: 'NoAccess.contactSupport',
    defaultMessage: `contact support`,
  },
  errorDescriptionPart2: {
    id: 'NoAccess.errorDescription',
    defaultMessage: ` with subject "No access" and use reference message code: {messageCode} and transaction ID: {transactionId}`,
  },
});
