import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react';

export interface IMenuItemWarningIconStyleProps {
  theme: ITheme;
}

type StyleProperties = 'wrapper';
type MenuItemWarningIconStyles = Record<StyleProperties, string>;

export const getStyles = (props: IMenuItemWarningIconStyleProps): MenuItemWarningIconStyles => {
  return mergeStyleSets({
    wrapper: {
      display: 'flex',
      gap: '10px',
    },
  });
};
export function getClassNames<PropType>(props?: PropType) {
  return getStyles({ theme: getTheme(), ...props });
}
