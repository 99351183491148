import { mutator } from 'satcheljs';
import { EntityLimitTier, FeatureOverideTier, NetworkAffiliation, Partner, PublisherTier, ServiceLevel } from '../../../../../@data';
import { countryToCurrencyMap } from '../../../../../config/countryToCurrencyMapper.config';
import {
  onFormCleared,
  onFormIdChanged,
  onFormPublisherAccountCountry,
  onFormPublisherAccountLanguage,
  onFormPublisherAccountName,
  onFormPublisherAccountTimezoneChanged,
  onFormPublisherAddressCityChanged,
  onFormPublisherAddressCountryChanged,
  onFormPublisherAddressLine1Changed,
  onFormPublisherAddressLine2Changed,
  onFormPublisherAddressStateChanged,
  onFormPublisherAddressStateListsChanged,
  onFormPublisherAddressZipcodeChanged,
  onFormPublisherDistributionChanged,
  onFormPublisherDistributionV2Changed,
  onFormPublisherDynamicPropertyRegistrationChanged,
  onFormPublisherEnableDefaultTythonV2Changed,
  onFormPublisherEntityLimitTierChanged,
  onFormPublisherIpNameChanged,
  onFormPublisherIpRangesChanged,
  onFormPublisherMaximumIpChanged,
  onFormPublisherMediationChanged,
  onFormPublisherMinimumIpChanged,
  onFormPublisherMobileSearchChanged,
  onFormPublisherNameChanged,
  onFormPublisherNativeChanged,
  onFormPublisherNetworkChanged,
  onFormPublisherOverrideTierChanged,
  onFormPublisherPSDashboardChanged,
  onFormPublisherPSReportingAPIChanged,
  onFormPublisherPartnerChanged,
  onFormPublisherPropertyAutoChanged,
  onFormPublisherSelectTierChanged,
  onFormPublisherServiceLevelChanged,
  onFormPublisherStartDistributionChanged,
  onFormPublisherStartDistributionContentChanged,
  onFormPublisherSyndicationChanged,
  onFormPublisherTierChanged,
  onFormPublisherWebAdunitsChanged,
  onPanelCleared,
} from '../actions';
import { getStore } from '../store/store';

mutator(onFormPublisherPartnerChanged, (msg) => {
  getStore().active.partner = msg.value.key as Partner;
});

mutator(onFormIdChanged, (msg) => {
  getStore().active.id = msg.value;
});

mutator(onFormPublisherNameChanged, (msg) => {
  getStore().active.name = msg.value;
});

mutator(onFormPublisherNetworkChanged, (msg) => {
  getStore().active.networkAffiliation = msg.value.key as NetworkAffiliation;
});

mutator(onFormPublisherAddressLine1Changed, (msg) => {
  getStore().active.addressLine1 = msg.value;
});

mutator(onFormPublisherAddressLine2Changed, (msg) => {
  getStore().active.addressLine2 = msg.value;
});

mutator(onFormPublisherAddressCityChanged, (msg) => {
  getStore().active.city = msg.value;
});

mutator(onFormPublisherAddressCountryChanged, (msg) => {
  getStore().active.country = msg.value!.key as string;
});

mutator(onFormPublisherAddressStateChanged, (msg) => {
  getStore().active.state = msg.value!.key as string;
});

mutator(onFormPublisherAddressStateListsChanged, (msg) => {
  getStore().active.availableStates = msg.value;
});

mutator(onFormPublisherAddressZipcodeChanged, (msg) => {
  getStore().active.postalcode = msg.value;
});

mutator(onFormPublisherTierChanged, (msg) => {
  getStore().active.publisherTier = msg.value.key as PublisherTier;
});

mutator(onFormPublisherOverrideTierChanged, (msg) => {
  getStore().active.overrideFeatureTier = msg.value.key as FeatureOverideTier;
});

mutator(onFormPublisherEntityLimitTierChanged, (msg) => {
  getStore().active.entityLimitTier = msg.value.key as EntityLimitTier;
});

mutator(onFormPublisherServiceLevelChanged, (msg) => {
  getStore().active.serviceLevel = msg.value.key as ServiceLevel;
});

mutator(onFormPublisherWebAdunitsChanged, (msg) => {
  getStore().active.allowWebAdUnit = msg.value;
});

mutator(onFormPublisherMobileSearchChanged, (msg) => {
  getStore().active.allowMobileSearchAdUnit = msg.value;
});

mutator(onFormPublisherDistributionChanged, (msg) => {
  getStore().active.allowDistributionReporting = msg.value;
});

mutator(onFormPublisherDistributionV2Changed, (msg) => {
  getStore().active.allowDistributionV2Reporting = msg.value;
});

mutator(onFormPublisherStartDistributionChanged, (msg) => {
  getStore().active.allowStartDistributionReporting = msg.value;
});

mutator(onFormPublisherStartDistributionContentChanged, (msg) => {
  getStore().active.allowStartDistributionContentReporting = msg.value;
});

mutator(onFormPublisherEnableDefaultTythonV2Changed, (msg) => {
  getStore().active.enableDefaultTythonV2Reporting = msg.value;
});

mutator(onFormPublisherSelectTierChanged, (msg) => {
  getStore().active.allowSelectTierReporting = msg.value;
});

mutator(onFormPublisherMediationChanged, (msg) => {
  getStore().active.allowMediationReporting = msg.value;
});

mutator(onFormPublisherNativeChanged, (msg) => {
  getStore().active.allowNativeReporting = msg.value;
});

mutator(onFormPublisherSyndicationChanged, (msg) => {
  getStore().active.allowSyndicationReporting = msg.value;
});

mutator(onFormPublisherPropertyAutoChanged, (msg) => {
  getStore().active.propertyAutoApproved = msg.value;
});

mutator(onFormPublisherPSReportingAPIChanged, (msg) => {
  getStore().active.allowPaidSearchReportingApi = msg.value;
});

mutator(onFormPublisherPSDashboardChanged, (msg) => {
  getStore().active.allowPaidSearchReportingDashboard = msg.value;
});

mutator(onFormPublisherDynamicPropertyRegistrationChanged, (msg) => {
  getStore().active.allowDynamicPropertyRegistration = msg.value;
});

mutator(onFormPublisherIpNameChanged, (msg) => {
  getStore().active.ipAddressName = msg.value;
});

mutator(onFormPublisherMinimumIpChanged, (msg) => {
  getStore().active.minIPAddress = msg.value;
});

mutator(onFormPublisherMaximumIpChanged, (msg) => {
  getStore().active.maxIPAddress = msg.value;
});

mutator(onFormPublisherIpRangesChanged, (msg) => {
  getStore().active.ipAddressGrid = msg.value;
});

mutator(onFormPublisherAccountName, (msg) => {
  getStore().active.accountName = msg.value;
});

mutator(onFormPublisherAccountLanguage, (msg) => {
  getStore().active.accountLanguage = msg.value.key as string;
});

mutator(onFormPublisherAccountTimezoneChanged, (msg) => {
  getStore().active.accountTimezoneId = msg.value!.key as number;
});

mutator(onFormPublisherAccountCountry, (msg) => {
  getStore().active.accountCountry = msg.value.key as string;
  getStore().active.accountCurrency = countryToCurrencyMap[msg.value.key as string];
});

mutator(onFormCleared, () => {
  getStore().active = {};
});

mutator(onPanelCleared, () => {
  getStore().active.ipAddressName = undefined;
  getStore().active.maxIPAddress = undefined;
  getStore().active.minIPAddress = undefined;
});
