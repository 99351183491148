import atlas, { AuthenticationType, ControlPosition, Popup, control } from 'azure-maps-control';
import React, { useEffect, useState } from 'react';
import { useI18n } from '../../../i18n/useI18n';
import { getAppStore } from '../../@data/store/store';
import { getStore } from '../../pages/ad-management/Account/Settings/@data/store/store';
import { IGeoJSONFeature } from './GeoJson.types';
import './styles.css';

interface IBubbleLayerProps {
  data: IGeoJSONFeature[];
}

const option: atlas.Options = {
  center: [-100, 40],
  zoom: 1,
  style: 'grayscale_light',
  authOptions: {
    authType: AuthenticationType.subscriptionKey,
    subscriptionKey: process.env.REACT_APP_AZURE_MAPS_SUB,
  },
};

const BubbleLayer: React.FC<IBubbleLayerProps> = ({ data }) => {
  const [popup] = useState<Popup>(new Popup({ closeButton: false }));
  const { publisher, userContext } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });
  option.language = locale;

  // Calculate min and max values from the data
  const values = data.map((feature) => feature.properties.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  useEffect(() => {
    try {
      // Add CSS link
      const link = document.createElement('link');
      link.href = 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/3/atlas.min.css';
      link.rel = 'stylesheet';
      document.head.appendChild(link);

      // Add JS script
      const script = document.createElement('script');
      script.src = 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/3/atlas.min.js';
      script.async = false;
      document.body.appendChild(script);

      // Cleanup function to remove the script and link tags when the component unmounts
      return () => {
        document.head.removeChild(link);
        document.body.removeChild(script);
      };
    } catch (error) {
      console.error('Error loading Azure Maps SDK:', error);
    }
  }, []);

  useEffect(() => {
    try {
      const mapInstance = new atlas.Map('myMap', option);
      console.log('Map instance created successfully.');

      mapInstance.events.add('ready', () => {
        mapInstance.controls.add(new control.ZoomControl(), {
          position: ControlPosition.TopRight,
        });

        const datasource = new atlas.source.DataSource();
        mapInstance.sources.add(datasource);
        datasource.add(data);

        const bubbleLayer = new atlas.layer.BubbleLayer(datasource, undefined, {
          radius: [
            'interpolate',
            ['linear'],
            ['ln', ['get', 'value']],
            Math.log(minValue),
            25, // Minimum value -> Radius 25
            Math.log(maxValue),
            60, // Maximum value -> Radius 60
          ],
          color: '#B7CAEC', // Fixed color for all bubbles
          strokeWidth: 0,
          filter: ['has', 'value'], // Only render data points which have a value property
        });
        mapInstance.layers.add(bubbleLayer);

        const symbolLayer = new atlas.layer.SymbolLayer(datasource, undefined, {
          iconOptions: {
            image: 'none', // Hide the icon image.
          },
          textOptions: {
            textField: ['get', 'value'],
            offset: [0, 0.4],
          },
        });
        mapInstance.layers.add(symbolLayer);

        mapInstance.events.add('mouseover', bubbleLayer, (e: atlas.MapMouseEvent) => {
          if (e.shapes && e.shapes.length > 0) {
            const shape = e.shapes[0] as atlas.Shape;
            const properties = shape.getProperties();
            const coordinates = shape.getCoordinates() as atlas.data.Position;
            const popupContent = `<div class="tooltip">
  <div class="tooltip-header">
    <span class="tooltip-dot"></span>
    <span class="tooltip-title">${properties.name}</span>
  </div>
  <div class="tooltip-content">
    ${properties.dimension}: ${properties.formattedValue}
  </div>
</div>
`;
            popup.setOptions({
              position: coordinates,
              content: popupContent,
              pixelOffset: [0, 0],
            });
            popup.open(mapInstance);
          }
        });

        mapInstance.events.add('mouseout', bubbleLayer, () => {
          popup.close();
        });
      });

      return () => {
        if (mapInstance) {
          mapInstance.dispose();
        }
      };
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }, [data, maxValue, minValue, popup]);

  return <div id="myMap" style={{ height: '300px' }} />;
};

export default BubbleLayer;
