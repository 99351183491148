import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { getAppStore } from '../../../../../../@data';
import { FormState } from '../../../../../../components/Form';
import { DetailPageWithStepper } from '../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper';
import { IStepperStep } from '../../../../../../layout/DetailPageWithStepper/DetailPageWithStepper.types';
import { injectRouterAndIntlWithObserver } from '../../../../../../utils';
import { getTythonInventoryPage } from '../../../../../../utils/routeUtils';
import { AdsTextPage } from '../CreateTythonProperty/components/AdsTextPage';
import AppPropertyDetailForm from './components/AppPropertyDetailForm';
import FinishPage from './components/FinishPage';
import messages from './messages';
import useCreateTythonAppProperty from './useCreateTythonAppProperty';

type CreateTythonAppPropertyProps = {
  mode: FormState;
};

type CreateTythonAppPropertyType = React.FC<CreateTythonAppPropertyProps & InjectedIntlProps & RouteComponentProps>;

const CreateTythonAppProperty: CreateTythonAppPropertyType = ({ mode, intl: { formatMessage }, history, location, match }) => {
  const { id: propertyId } = match.params as { id: string };
  const { publisher, userContext } = getAppStore();
  const { appPropertyFormProps, isValidAppInventoryCreation, onAppPropertyFormSubmit } = useCreateTythonAppProperty({
    mode,
    propertyId,
    publisher,
    userContext,
    formatMessage,
  });

  const steps: IStepperStep[] = [
    {
      name: formatMessage(messages.CreateSiteStep),
      title: formatMessage(messages.CreateSiteStepFormTitle),
      component: <AppPropertyDetailForm {...appPropertyFormProps} />,
      // helpId: 'pop_PCv4_UniversalBlockSetting', //TODO: update help id for App inventory
      isNextDisabled: !!!isValidAppInventoryCreation || appPropertyFormProps.isSaving,
      isStepDirty: propertyId !== undefined, // TODO: update dirty flag
      onNext: onAppPropertyFormSubmit,
    },
    {
      name: formatMessage(messages.AdsTxtCreationStep),
      title: formatMessage(messages.AdsTxtCreationStepTitle),
      isStepDirty: propertyId !== undefined,
      component: <AdsTextPage history={history} location={location} match={match} />,
    },
    {
      name: formatMessage(messages.finishStepperTittle),
      title: formatMessage(messages.getCodeTitle),
      component: <FinishPage />,
      isStepDirty: propertyId !== undefined,
      onNext: () => history.push(getTythonInventoryPage(true)),
    },
  ];

  return (
    <>
      <DetailPageWithStepper
        openStep={window.location.search.includes('step=1') ? 1 : 0}
        process={mode === FormState.MultiStepCreation ? formatMessage(messages.processNameCreate) : formatMessage(messages.processNameEdit)}
        steps={steps}
        pageMode={mode}
        backToBase={() => history.goBack()}
        history={history}
        location={location}
        match={match}
      />
    </>
  );
};

export default injectRouterAndIntlWithObserver(CreateTythonAppProperty);
