import { IPubcenterSpacing } from '../../../../../theme';
import { ICreateAdFilterStyleProps, ICreateAdFilterStyles } from './CreateAdFilter.types';

export const getStyles = (props: ICreateAdFilterStyleProps): ICreateAdFilterStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  return {
    description: {
      fontSize: 12
    },
    excludedItemsTextField: {
      width: 440
    },
    exportButton: {
      display: 'flex',
      flexDirection: 'row'
    },
    panelHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '5px 5px 0px 0px',
    },
    subComponentStyles: {
      inPagePanel: {},
      excludedItemsLabel: {
        root: {
          fontSize: 16,
          borderTopStyle: 'solid',
          borderColor: theme.palette.neutralLight,
          borderTopWidth: 1,
          paddingTop: spacing.size16
        }
      },
      excludedDescription: {
        root: [
          'ms-fontWeight-regular',
          {
            fontSize: 14
          }
        ]
      },
      choiceGroup: {
        root: {
          marginBottom: spacing.size16
        },
        flexContainer: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          selectors: {
            '.ms-ChoiceField': {
              marginRight: theme.spacing.m
            }
          }
        }
      }
    }
  };
};
