import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import {
  Model,
  NotificationLabel,
  NotificationSeverity,
  onNotificationCardAdded,
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../../@data';
import notificationMessages from '../../../../NotificationsPanel.Types.messages';
import { CSVHelper } from '../../../../utils';
import messages from '../../GridV2.messages';
import { DownloadOption } from '../../GridV2.types';
import { onDownloadButtonClicked } from '../actions/commandBarActions';
import { getStore } from '../store/store';

orchestrator(onDownloadButtonClicked, async (msg) => {
  Log.write('onDownloadButtonClicked');
  const { selectedItems, paginator } = getStore();

  const getActivePage = (items: Model[], pageNumber?: number, pageSize?: number) => {
    const dataLength = items?.length;
    if (dataLength && pageNumber && pageSize) {
      return items?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize) || [];
    } else {
      return items || [];
    }
  };

  let downloadData: Model[] = msg.data;
  if (msg.downloadType === DownloadOption.selection && !!selectedItems && selectedItems.length !== 0) {
    downloadData = selectedItems;
  } else if (msg.downloadType === DownloadOption.page) {
    downloadData = getActivePage(msg.data, paginator.currentPageNumber, paginator.currentPageSize);
  } else if (msg.downloadType === DownloadOption.all) {
    downloadData = msg.data;
  }

  let stringDataForCSV: string[];
  let headers: string;

  if (downloadData && msg.columns) {
    const columns = msg.columns.filter((col) => col.isExportable && col.isVisible);
    headers = columns.map((col) => CSVHelper.escapeStringForCSV(col.csvHeaderName ?? col.name)).toString();
    stringDataForCSV = downloadData.map((rowData) => {
      return CSVHelper.getCSVStringsV2(rowData, columns);
    });

    CSVHelper.downloadDataAsCSV(stringDataForCSV.join('\n'), headers, msg.csvFileName);

    showSuccessToastNotification({
      textMessageDescriptor: messages.gridDataDownloadSuccess,
    });
  } else {
    showErrorToastNotification({ textMessageDescriptor: notificationMessages.GridDataDownloadFailureContent });
    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      title: msg.formatMessage(notificationMessages.GridDataDownloadFailureTitle),
      content: msg.formatMessage(notificationMessages.GridDataDownloadFailureContent),
      notificationLabel: NotificationLabel.GridDataDownloadFailure,
    });
  }
});
