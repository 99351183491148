import { IDropdownOption } from '@fluentui/react';
import { action } from 'satcheljs';
import { IStringDictionary } from '../../../../../@data';
import { IAdListingsData, IBlockItemViewModel } from '../store/schema';
import { AdPerformanceData } from '../store/schema/models';

export const onAdDescriptionChanged = action('onAdDescriptionChanged', (value: boolean) => ({ value }));

export const onBlockAdsViewChanged = action('onBlockAdsViewChanged', (value: IBlockItemViewModel) => ({ value }));

export const onBlockAdInitialized = action('onBlockAdInitiallized', (value: AdPerformanceData[], isImageBlock?: boolean) => ({
  value,
  isImageBlock,
}));

export const onBlockAdsSamChanged = action('onBlockAdsSamChanged', (value: AdPerformanceData[]) => ({ value }));

export const onBlockAdsChanged = action('onBlockAdsChanged', (value: AdPerformanceData[]) => ({ value }));

export const onBlockImageAdsChanged = action('onBlockImageAdsChanged', (value: AdPerformanceData[], isAuctionData: boolean = false) => ({
  value,
  isAuctionData,
}));

export const onBlockAdsImagesChanged = action(
  'onBlockAdsImagesChanged',
  (images?: string[], index?: number, needUrlProcess?: boolean, isAuctionData: boolean = false) => ({
    images,
    index,
    needUrlProcess,
    isAuctionData,
  })
);

export const onBlockAdsAdPerfDataCleared = action('onBlockAdsAdPerfDataCleared');

export const onBlockAdsBlockedImagesChanged = action('onBlockAdsBlockedImagesChanged', (item: string, isChecked?: boolean) => ({
  isChecked,
  item,
}));

export const onBlockAdsChannelsAndRevenueChanged = action('onBlockAdsChannelsAndRevenueChanged', (value: AdPerformanceData[]) => ({
  value,
}));

export const onBlockAdsSelectedMarketChanged = action('onBlockAdsSelectedMarketChanged', (value: IDropdownOption) => ({ value }));

export const onBlockAdsReasonLevelsChanged = action(
  'onBlockAdsReasonLevelsChanged',
  (value: IDropdownOption, formatter: Function, index: number) => ({
    value,
    formatter,
    index,
  })
);

export const onBlockAdsChannelsChanged = action('onBlockAdsChannelsListChanged', (accountId: number, channelId?: number) => ({
  accountId,
  channelId,
}));

export const onBlockAdsSelectedBlockRequestSourceChanged = action(
  'onBlockAdsSelectedBlockRequestSourceChanged',
  (value: IDropdownOption) => ({
    value,
  })
);

export const onBlockAdsRevenueChanged = action('onBlockAdsRevenueChanged', (value: number, index: number) => ({ value, index }));

export const onBlockAdsListingsChanged = action('onBlockAdsListingsChanged', (value: IAdListingsData, index: number) => ({ value, index }));

export const onBlockAdsBlockReasonChanged = action('onBlockAdsBlockReasonChanged', (value: IDropdownOption) => ({ value }));

export const onBlockAdsBlockDetailsChanged = action('onBlockAdsBlockDetailsChanged', (value?: string) => ({ value }));

export const onAdListingPageSelection = action('onAdListingPageSelection', (value?: number, index?: number) => ({ value, index }));

export const onBlockAdsSubmitted = action('onBlockAdsSubmitted');

export const onMSANBlockAdsSubmitted = action('onMSANBlockAdsSubmitted');

export const onSAMBlockAdsSubmitted = action('onSAMBlockAdsSubmitted');

export const onBlockImagesSubmitted = action('onBlockImagesSubmitted');

export const onBlockAdsErrorChanged = action('onBlockAdsErrorChanged', (values?: IStringDictionary) => ({ values }));

export const onBlockImagesErrorChanged = action('onBlockImagesErrorChanged', (values?: IStringDictionary) => ({ values }));
