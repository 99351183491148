import { action } from 'satcheljs';
import { AdUnit, IStringDictionary } from '../../../../../@data';
import { IAdunitViewModel } from '../store/schema';

export const onRawChanged = action('onRawChanged', (value?: AdUnit) => ({ value }));

export const onAdunitChanged = action('onAdunitChanged', (adunit: IAdunitViewModel) => ({ adunit }));

export const onAdunitErrorChanged = action('onAdunitErrorChanged', (values?: IStringDictionary) => ({ values }));

export const onAdunitMediationUpdateStart = action('onAdunitMediationUpdateStart', (ids: number[]) => ({ ids }));

export const onAdunitMediationUpdateEnd = action('onAdunitMediationUpdateEnd', (ids: number[]) => ({ ids }));
