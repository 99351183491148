import { History } from 'history';
import { action } from 'satcheljs';

export const onNavigateBack = action('onNavigateBack', (routeHistory?: History, returnUrl?: string) => ({ routeHistory, returnUrl }));

export const redirectToReportingDashboard = action('redirectToReportingDashboard');

export const handleDomainRedirection = action('handleDomainRedirection', (username?: string, pathname?: string) => ({
  username,
  pathname,
}));
