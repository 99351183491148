import { IPubcenterSpacing } from '../../../../../theme';
import { IInviteUserStyleProps, IInviteUserStyles } from './InviteUser.types';

export const getStyles = (props: IInviteUserStyleProps): IInviteUserStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    subComponentStyles: {
      checkbox: {
        root: {
          marginBottom: spacing.size8
        }
      }
    }
  };
};
