import { action, orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { Property, getAppStore, listEntity, showErrorToastNotification } from '../../../../../@data';
import { setLoading, setPropertyData } from '../../Manage/__data__/mutatorActions';
import messages from '../../Property.messages';
import { loadPropertiesServingConfigs } from './loadPropertyServingConfigs';

export const fetchPropertiesDataAction = action('fetchPropertiesDataAction');

orchestrator(fetchPropertiesDataAction, async () => {
  try {
    const { publisher, userContext } = getAppStore();
    if (!publisher) {
      return;
    }

    setLoading(true);
    const data = await listEntity([publisher], userContext, Property);
    setPropertyData(data);
    loadPropertiesServingConfigs();
  } catch (error) {
    Log.error(error);
    showErrorToastNotification({ textMessageDescriptor: messages.failedToLoadSites });
  } finally {
    setLoading(false);
  }
});
