import { IPubcenterSpacing } from '../../../../../theme';
import { IAcceptInviteStyleProps, IAcceptInviteStyles } from './AcceptInvite.types';

export const getStyles = (props: IAcceptInviteStyleProps): IAcceptInviteStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    switchNowButton: {
      color: theme.palette.themePrimary,
    },
    subComponentStyles: {
      checkbox: {
        root: {
          marginBottom: spacing.size8,
        },
      },
    },
    privacyEmailLinkWrapper: {
      marginTop: `-${spacing.size12}`,
      display: 'flex',
      gap: spacing.size6,
    },
  };
};
