import { ICustomColumn } from '../../types';

export function sortItemsBasedOnColumn<ItemType>(items: ItemType[], column?: ICustomColumn): ItemType[] {
  if (!column) {
    return items;
  }

  if (!items || !items[0]) {
    return [];
  }

  const { sortFunction, sortableValue, isSortedDescending, fieldName } = column;

  // Use the sorting function if provided
  if (typeof sortFunction === 'function') {
    return items.sort((a, b) => sortFunction(a, b));
  }

  const sortedItems = items.concat([]).sort((a: ItemType, b: ItemType) => {
    const firstValue = getSortableValue(a, fieldName, sortableValue);
    const secondValue = getSortableValue(b, fieldName, sortableValue);

    if (firstValue === undefined && secondValue !== undefined) {
      return 1;
    } else if (secondValue === undefined) {
      return -1;
    }

    if (typeof firstValue === 'string' && typeof secondValue === 'string') {
      if (isSortedDescending) {
        return firstValue.toLowerCase() > secondValue.toLowerCase() ? -1 : 1;
      } else {
        return firstValue.toLowerCase() > secondValue.toLowerCase() ? 1 : -1;
      }
    } else {
      if (isSortedDescending) {
        return firstValue > secondValue ? -1 : 1;
      } else {
        return firstValue > secondValue ? 1 : -1;
      }
    }
  });
  return sortedItems;
}

function getSortableValue(item, fieldName, sortableValue) {
  // Use the sortableValue function if provided
  return sortableValue ? sortableValue(item) : item[fieldName || ''];
}
