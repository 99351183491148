import {
  Callout,
  DirectionalHint,
  FocusZone,
  FocusZoneTabbableElements,
  getId,
  Icon,
  MaskedTextField,
  SwatchColorPicker,
} from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import messages from './ColorPicker.messages';
import { getClassNames } from './ColorPicker.styles';
import { IColorPickerProps } from './ColorPicker.types';
import { defaultColor, getColorByHex, swatchColors } from './swatchColors';

const HEX_COLOR_PREFIX = 'Hex ';
const HEX_CHARACTER_REGEX = /[0-9a-fA-F]/;
const HEX_MASK_REGEX = /(Hex )*(_)*/g;

export const ColorPicker = injectIntl(function ColorPickerComponent(props: IColorPickerProps): JSX.Element {
  const [color, setColor] = useState(props.selectedColor || props.defaultColor || defaultColor);
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [customColor, setCustomColor] = useState(
    swatchColors.find((swatchColor) => swatchColor.color === props.selectedColor) ? undefined : props.selectedColor
  );
  const [buttonId] = useState(getId('colorPicker'));
  const colorPickerButtonRef = useRef<HTMLButtonElement>(null);
  const classNames = getClassNames({ colorSelected: customColor ?? color });
  const { formatMessage } = props.intl;

  function handleColorChanged(_color: string) {
    setColor(_color);
    setCalloutVisible(false);
    setCustomColor(undefined);

    props.onChange?.(_color);
  }
  function handleHexCodeFieldChanged(ev) {
    const hexValue = ev.target.value.replaceAll(HEX_MASK_REGEX, '');
    if (hexValue !== '#') {
      setCustomColor(hexValue);
      setColor(color || defaultColor);
      props.onChange?.(hexValue);
    }
  }

  useEffect(() => {
    if (props.selectedColor && props.controlled) {
      setColor(props.selectedColor);
    }
  }, [props.selectedColor, props.controlled]);

  const onCalloutDismiss = () => {
    setCalloutVisible(false);
    colorPickerButtonRef?.current?.focus();
  };
  return (
    <div className={classNames.container}>
      <button
        type="button"
        title={
          formatMessage(messages.colorPicker) +
          (props.colorType ? ` for ${props.colorType}` : '') +
          ` selected: ${color || customColor || ''}`
        }
        id={buttonId}
        className={classNames.root}
        onClick={() => setCalloutVisible(true)}
        onKeyDown={(ev) => isButtonPressKey(ev.key) && setCalloutVisible(true)}
        ref={colorPickerButtonRef}
      >
        <div className={classNames.iconContainer}>
          <Icon iconName="BucketColor" className={classNames.bucketIcon} />
          <div className={classNames.colorIcon} />
        </div>
        <Icon iconName="ChevronDown" className={classNames.chevronIcon} />
      </button>
      {calloutVisible && (
        <Callout
          className={`${classNames.swatchColorPickerCallout} ms-Fabric--isFocusVisible`}
          target={`#${buttonId}`}
          role="dialog"
          onDismiss={onCalloutDismiss}
          isBeakVisible={false}
          setInitialFocus={true}
          directionalHint={DirectionalHint.bottomRightEdge}
          ariaLabel="Color picker"
        >
          <label className={classNames.boldFieldTitle}>{formatMessage(messages.themeColors)}</label>
          <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation defaultTabbableElement={'#swatchColorPicker-b-1'}>
            <SwatchColorPicker
              colorCells={swatchColors}
              columnCount={6}
              cellShape={'circle'}
              defaultSelectedId={customColor ? undefined : getColorByHex(color)?.id || getColorByHex(defaultColor)?.id}
              onChange={(_, id, _color) => handleColorChanged(_color || defaultColor)}
              id="swatchColorPicker"
              doNotContainWithinFocusZone
            />
            <MaskedTextField
              label={formatMessage(messages.customColor)}
              mask={`${HEX_COLOR_PREFIX}#******`}
              value={customColor}
              maskFormat={{ '*': HEX_CHARACTER_REGEX }}
              onBlur={handleHexCodeFieldChanged}
              onKeyUp={(ev) => isButtonPressKey(ev.key) && handleHexCodeFieldChanged(ev)}
            />
          </FocusZone>
        </Callout>
      )}
    </div>
  );
});
