import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import { getAppStore, listEntity, onLoadingChanged, showErrorToastNotification, UserInvitation } from '../../../../../../@data';
import { loadUsers } from '../../../Users/@data/actions/usersTabActions';
import messages from '../../UserInvitations.messages';
import { setUserInvitations } from '../mutatorActions';

orchestrator(loadUsers, async () => {
  const { userContext, publisher } = getAppStore();
  Log.debug('Loading user invitations');

  if (userContext && publisher) {
    try {
      onLoadingChanged(true);

      const userInvitations = await listEntity([publisher], userContext, UserInvitation);
      setUserInvitations(userInvitations);
    } catch (e) {
      showErrorToastNotification({ textMessageDescriptor: messages.failedToFetchInvitedUser });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    showErrorToastNotification({ textMessageDescriptor: messages.invalidState });
  }
});
