import { UserRoleEntity } from '../../../services/UserRoleService';
import { Model } from './Model';

export class Account extends Model {
  public className = UserRoleEntity.Account;
  public publisherName: number;
  public accountName: number;
  public primaryContact: number;
  public lanuage: number;
  public countryOrRegion: number;
  public currency: number;
  public timeZone: number;
  public paymentOption: number;
  public financialStatus: number;
  public paymentLifecycle: number;
  public operationCostRation: number;
  public accountLevelAttribute: number;
  public inHousePromotion: number;
  public partnerCodesforDistributionReport: number;
  public formCodesforDistributionReport: number;
  public startDistributionReporting: number;
  public SKUs: number;

  constructor(params: Account) {
    super(params.accessPermission);
    Object.assign(this, params);
  }

  public or(this: Account, account: Account) {
    // tslint:disable:no-bitwise
    this.accessPermission |= account.accessPermission;
    this.publisherName |= account.publisherName;
    this.accountName |= account.accountName;
    this.primaryContact |= account.primaryContact;
    this.lanuage |= account.lanuage;
    this.countryOrRegion |= account.countryOrRegion;
    this.currency |= account.currency;
    this.timeZone |= account.timeZone;
    this.paymentOption |= account.paymentOption;
    this.financialStatus |= account.financialStatus;
    this.paymentLifecycle |= account.paymentLifecycle;
    this.operationCostRation |= account.operationCostRation;
    this.accountLevelAttribute |= account.accountLevelAttribute;
    this.inHousePromotion |= account.inHousePromotion;
    this.partnerCodesforDistributionReport |= account.partnerCodesforDistributionReport;
    this.formCodesforDistributionReport |= account.formCodesforDistributionReport;
    this.startDistributionReporting |= account.startDistributionReporting;
    this.SKUs |= account.SKUs;
    // tslint:enable:no-bitwise
  }
}
