// Copy of NativeAdTemplate with nessesary edits for boost user experience
// tslint:disable: max-line-length

import { Model } from './Model';
import { Asset, RenderingConfig } from './NativeAdTemplate';

export class NativeAdTemplateBoost extends Model {
  public apiPath = undefined;
  public className = 'templates';

  public type: TemplateTypeNumber;
  public adUnitId: number;
  public numberOfAds: number;
  public assets: Asset[];
  public renderingConfig: RenderingConfig;
}

export enum TemplateTypeNumber {
  Custom = 0,
  ImageAbove = 1,
  ImageOnSide = 2,
  TitleAbove = 3,
  TextOnly = 4,
  TextOnImage = 5,
}
