import { Log } from '../../../../../../logging/src';
import {
  InventoryPlatformType,
  NotificationLabel,
  NotificationSeverity,
  Property,
  Publisher,
  UserContext,
  listEntity,
  onNotificationCardAdded,
} from '../../../../../@data';
import { PropertyServingConfigs } from '../../../../../@data/store/schema/models/ServingConfigs';
import notificationMessages from '../../../../../NotificationsPanel.Types.messages';
import { IPropertySitePickerItem } from '../../../../../layout/PropertySitePicker/PropertySitePicker.types';
import { setPropertyData } from '../../../Property/Manage/__data__/mutatorActions';

export const fetchProperties = async (
  publisher: Publisher,
  userContext?: UserContext,
  updateLoadingStatus?: (isLoading: boolean) => void
) => {
  let data;
  updateLoadingStatus?.(true);
  try {
    const propertiesPromise = listEntity([publisher!], userContext, Property);
    const propertyConfigsPromise = listEntity([publisher], userContext, PropertyServingConfigs);
    const [properties, propertyConfigs] = await Promise.all([propertiesPromise, propertyConfigsPromise]);
    data = properties.filter(
      (p) => p.id && propertyConfigs.some((pc) => pc.propertyId === p.id && pc.inventoryPlatformType !== InventoryPlatformType.UWP)
    );
  } catch (e) {
    onNotificationCardAdded({
      // tslint:disable-next-line: no-any
      severity: NotificationSeverity.Error,
      titleMessageDescriptor: notificationMessages.LoadingSitesFailedTitle,
      contentMessageDescriptor: notificationMessages.LoadingSitesFailedContent,
      notificationLabel: NotificationLabel.LoadingSitesFailed,
    });
    Log.error(`properties failed to fetch ${e}`);
  } finally {
    updateLoadingStatus?.(false);
    if (!data) {
      return [];
    }
    Log.info('Properties fetched in ad unit builder form');
    setPropertyData(data);
    return data;
  }
};

export const propertySerializer = (properties: Property[]): IPropertySitePickerItem[] => properties.map((p) => ({ id: p.id, url: p.url }));
