import { AuditType } from '../../../../@data';
import { FilterType } from '../../../../components/GridFilter/GridFilter.types';
import { IFilterItem } from '../../../../layout/GridPageV2';
import UserManagementMessages from '../../UserManagement/UserManagement.messages';
import messages from '../AuditHistory.messages';
import { getEntityTypeDisplayMap } from './convertAttributeValues';

export function getFilterItems(formatMessage): IFilterItem[] {
  const { superAdmin, accountManager, reportUser } = UserManagementMessages;

  return [
    {
      filterName: formatMessage(messages.userEmail),
      filterType: FilterType.string,
      columnName: 'userEmail',
    },
    {
      filterName: formatMessage(messages.userRole),
      filterType: FilterType.custom,
      columnName: 'userRole',
      filterOptions: [
        {
          key: 'SuperAdmin',
          text: formatMessage(superAdmin),
        },
        {
          key: 'AccountManager',
          text: formatMessage(accountManager),
        },
        {
          key: 'ReportUser',
          text: formatMessage(reportUser),
        },
      ],
    },
    {
      filterName: formatMessage(messages.actionType),
      filterType: FilterType.custom,
      columnName: 'actionType',
      filterOptions: [
        {
          key: AuditType.CREATE,
          text: formatMessage(messages.create),
        },
        {
          key: AuditType.UPDATE,
          text: formatMessage(messages.update),
        },
        {
          key: AuditType.DELETE,
          text: formatMessage(messages.delete),
        },
        // {
        //   key: AuditType.INVITE,
        //   text: formatMessage(messages.invite),
        // },
        // {
        //   key: AuditType.INVITE_ACCEPT,
        //   text: formatMessage(messages.inviteAccept),
        // },
        // {
        //   key: AuditType.EXPORT,
        //   text: formatMessage(messages.export),
        // },
        // {
        //   key: AuditType.DOWNLOAD,
        //   text: formatMessage(messages.download),
        // },
      ],
    },
    {
      filterName: formatMessage(messages.actionOn),
      filterType: FilterType.custom,
      columnName: 'actionOn',
      filterOptions: [
        {
          key: 'adunit',
          text: getEntityTypeDisplayMap('adunit', formatMessage),
        },
        {
          key: 'property',
          text: getEntityTypeDisplayMap('property', formatMessage),
        },
        {
          key: 'user',
          text: getEntityTypeDisplayMap('user', formatMessage),
        },
        {
          key: 'publisher',
          text: getEntityTypeDisplayMap('publisher', formatMessage),
        },
        {
          key: 'payment',
          text: getEntityTypeDisplayMap('payment', formatMessage),
        },
        // {
        //   key: 'report',
        //   text: formatMessage(messages.report),
        // },
      ],
    },
  ];
}
