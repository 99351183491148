import { orchestrator } from 'satcheljs';
import { ISubGeography } from '../../../../../@data';
import { getSupportedSubGeographies } from '../../../../../@data/services/MetadataService';
import {
  onFormPublisherAddressCountryChanged,
  onFormPublisherAddressCountryCodeChanged,
  onFormPublisherAddressStateListsChanged
} from '../actions';

orchestrator(onFormPublisherAddressCountryCodeChanged, async msg => {
  onFormPublisherAddressCountryChanged(msg.value);
  let availableStateList: ISubGeography[] = [];
  if (msg.value) {
    availableStateList = await getSupportedSubGeographies(msg.value.key as string);
  }
  onFormPublisherAddressStateListsChanged(availableStateList);
});
