import { History } from 'history';
import { action } from 'satcheljs';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';

export const onFormIdChanged = action('onFormIdChanged', (value: number) => ({ value }));

export const onFormChannelNameChanged = action('onFormChannelNameChanged', (value?: string) => ({ value }));

export const onFormAdunitsChanged = action('onFormAdunitsChanged', (values: IIdNamePair[]) => ({ values }));

export const onFormFiltersChanged = action('onFormFiltersChanged', (values: IIdNamePair[]) => ({ values }));

export const onFormInitialized = action(
  'onFormInitialized',
  (mode: FormState, publisherId: string, accountId: string, entityId?: string) => ({ mode, publisherId, accountId, entityId })
);

export const onFormSubmitted = action(
  'onFormSubmitted',
  (mode: FormState, routeHistory?: History, entityId?: string) => ({ mode, routeHistory, entityId })
);

export const onFormCancelled = action('onFormCancelled', (mode: FormState) => ({ mode }));

export const onFormCleared = action('onFormCleared');
