import { useEffect, useMemo, useRef, useState } from 'react';
import { Log } from '../../../logging/src/Log';
import {
  Publisher,
  User,
  UserContext,
  buildRequestHeadersWithAuthToken,
  constructUrl,
  isTythonSelfServeUser,
  onNotificationChanged,
  onNotificationRemoved,
} from '../../@data';
import { INotificationBarItem } from '../../components/NotificationBar';
import { ITnCModalProps } from '../../components/TnCModal';
import { TnCNotification } from '../../components/TnCModal/TnCNotification';
import { ITnCSignatureStatus } from '../ad-management/Account/Settings/@data/store/schema/ISettingsViewModel';
import { setNotificationPanelTriggered } from './@data';
import { ITnC } from './@data/store/store';

type UseTnC = ({
  tncStore,
  tncSignatureStatus,
  userContext,
  publisher,
  shouldDisableTnC,
  isAppLoading,
  barNotifications,
}: {
  tncStore: ITnC;
  tncSignatureStatus?: ITnCSignatureStatus;
  userContext?: UserContext;
  publisher?: Publisher;
  shouldDisableTnC: boolean;
  isAppLoading?: boolean;
  barNotifications?: INotificationBarItem[];
}) => { tncModalProps: ITnCModalProps };

type SignatureRetryProps = {
  userContext?: UserContext;
  publisher?: Publisher;
  isSkipping?: boolean;
  latestTnCVersion?: string;
  onPatchStart?: () => void;
  onPatchFinished?: () => void;
  onSuccess?: () => void;
  withRetry?: boolean;
  patchUrl?: string;
};

const TNC_NOTIFICATION_ID = 'tnc-notification-pushed';
export const TNC_PRE_SIGNATURE_FLAG = 'tnc-pre-signed-latest';

const useTnC: UseTnC = ({ tncStore, tncSignatureStatus, publisher, userContext, shouldDisableTnC, isAppLoading, barNotifications }) => {
  const handledTnCPopup = useRef<boolean>(false);
  const [shouldRenderTnCModal, setRenderTnC] = useState<boolean>(false);
  const [acceptingTnC, setAcceptingTnC] = useState<boolean>(false);
  const { content, nonTythonContent, isDataLoaded, latestTnCReleaseDate, latestTnCVersion, isNotificationPanelTrigerred } = tncStore;
  const { isLatestAccepted, signatureDate, acceptedVersion } = tncSignatureStatus ?? {};
  const isTnCPreSigned = window.localStorage.getItem(TNC_PRE_SIGNATURE_FLAG);

  const notification = {
    id: TNC_NOTIFICATION_ID,
    messageBarType: 1,
    component: TnCNotification({
      onAction: () => {
        setRenderTnC(true);
        onNotificationRemoved(notification);
      },
      latestTnCReleaseDate: latestTnCReleaseDate!,
      signatureDate: signatureDate,
    }),
    suppressAutoDismiss: true,
    messageBarStyles: {
      text: {
        marginTop: 0,
        marginBottom: 0,
        alignItems: 'center',
        paddingTop: 2,
      },
      content: { alignItems: 'center' },
    },
  };

  const shouldHandleTnCForExistingUser = useMemo(
    () =>
      (!isAppLoading && isDataLoaded && !isLatestAccepted && isNotificationPanelTrigerred) ||
      (!isAppLoading && isDataLoaded && !isLatestAccepted && !handledTnCPopup.current && shouldDisableTnC && !isTnCPreSigned),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDataLoaded, handledTnCPopup.current, shouldDisableTnC, isLatestAccepted, isAppLoading, isNotificationPanelTrigerred]
  );

  useEffect(() => {
    if (shouldHandleTnCForExistingUser) {
      if (
        !isNotificationPanelTrigerred &&
        localStorage.getItem(TNC_NOTIFICATION_ID) &&
        signatureDate &&
        new Date(signatureDate) > new Date()
      ) {
        !barNotifications?.find((n) => n.id === notification.id) && onNotificationChanged(notification);
      } else {
        setRenderTnC(true);
        handledTnCPopup.current = true;
        localStorage.setItem(TNC_NOTIFICATION_ID, 'true');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHandleTnCForExistingUser]);

  /**
   * hide popup when user login session has expired
   */
  const shouldForceHidePopup = window?.location?.pathname.includes('login');

  /**
   * if isSkipping is true, that means user is skipping signing the TnC,
   * in backend mechanism we consider this is "accepting null TnC"
   *  */
  const onAcceptTnC = async (isSkipping = false) => {
    if (!userContext || !publisher) {
      return;
    }
    patchAcceptTnC({
      userContext,
      publisher,
      isSkipping,
      latestTnCVersion,
      onPatchStart: () => setAcceptingTnC(true),
      onPatchFinished: () => setAcceptingTnC(false),
      onSuccess: () => setRenderTnC(false),
    });
    if (isNotificationPanelTrigerred) {
      setNotificationPanelTriggered(false);
    }
  };

  /**
   * Sign empty TnC only when first time user skip the TnC
   * - signatureDate is not ahead of today utc
   * - signatureDate is undefined as by default tncSignatureStatus is undefined
   */
  const onSkipTnC = () => {
    const isSkippingFirstTime =
      !signatureDate || signatureDate === 'undefined' || (new Date() > new Date(signatureDate) && signatureDate !== '');
    if (isSkippingFirstTime) {
      onAcceptTnC(true);
    }
    setRenderTnC(false);
    if (isNotificationPanelTrigerred) {
      setNotificationPanelTriggered(false);
    } else {
      onNotificationChanged(notification);
    }
  };

  /**
   * disable the skip button when user has seen latest TnC but not
   * signed within the next 14 days,
   * - if tnc first time expose to user, signatureDate is undefined, in this case we will
   *   not disable the skip button
   * - if acceptedVersion is empty string (user skipped TnC previously) and signature
   *   date(at this point signatureDate is date of first time user saw TnC + 14days)
   *   is before today date, we should disable the skip button
   */
  const shouldDisableSkip = useMemo(() => {
    if (!signatureDate) {
      return false;
    }
    const bufferPassed = new Date(signatureDate) < new Date();
    const userSkippedTnCPreviously = acceptedVersion === '';
    return bufferPassed && userSkippedTnCPreviously;
  }, [signatureDate, acceptedVersion]);

  const tncModalProps = {
    onSkip: shouldDisableSkip ? undefined : onSkipTnC,
    onAccept: () => onAcceptTnC(),
    isActive: shouldRenderTnCModal && !shouldForceHidePopup,
    content: isTythonSelfServeUser() ? content : nonTythonContent,
    releaseDate: latestTnCReleaseDate,
    isLoading: acceptingTnC,
  };

  return { tncModalProps };
};

export const patchAcceptTnC = async ({
  userContext,
  publisher,
  isSkipping = false,
  latestTnCVersion,
  onPatchStart = () => null,
  onPatchFinished = () => null,
  onSuccess = () => null,
  withRetry = false,
  patchUrl,
}: SignatureRetryProps) => {
  onPatchStart();
  let response: Response;
  let url;
  if (patchUrl) {
    url = patchUrl;
  } else {
    const user = new User(userContext?.id);
    url = constructUrl([publisher!, user]) + `/accept-terms/${isSkipping ? null : latestTnCVersion}`;
  }

  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  try {
    response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
    });

    const responseJson = await response.json();

    if (response.ok) {
      onSuccess();
    } else {
      if (withRetry) {
        throw Error('Patch failed');
      } else {
        return Log.error(
          `${isSkipping ? 'Skipping' : 'Signing'} latest terms and conditions failed. Error: ${JSON.stringify(responseJson.errors)}`
        );
      }
    }
  } catch (e) {
    if (withRetry) {
      throw e;
    }
    Log.error(e);
  } finally {
    if (!withRetry) {
      onPatchFinished();
    }
  }
};

/**
 * accept latest tnc api will return errors within 2-5 minutes right after user signup, which is
 * a known backend issue, as a work around, set localStorage flag after user finish signup flow,
 * once tnc hook see this flag, will consider user has pre signed latest TnC per login session
 * and fire the signature api call in the next few minutes.
 */
export const preSignTnC = (value?: boolean) => {
  if (value) {
    window.localStorage.setItem(TNC_PRE_SIGNATURE_FLAG, 'true');
  } else {
    window.localStorage.removeItem(TNC_PRE_SIGNATURE_FLAG);
  }
};

export default useTnC;
