import { Model } from './Model';

export class ReportingDataResponse {
  public TrackingGuid: string;
  public CallerGuid: string;
  public Data: ReportingDataRow[];
}
export class MediationReportingDataResponse {
  public trackingId: string;
  public data: MediationReportingDataRow[];
}

export class ReportingDataRow extends Model {
  public className: string = '';
  public apiPath: string = '';
}
export class TythonReportingDataRow extends ReportingDataRow {
  public DateTime?: string;
  public AccountId?: string;
  public AccountName?: string;
  public AdUnitId?: string;
  public AdUnitName?: string;
  public Country?: string;
  public CountryCode?: string;
  public ImpressionsServed?: number;
  public ImpressionsViewed?: number;
  public Viewability?: string;
  public Revenue?: number;
  public eCPM?: string;
  public Clicks?: number;
  public CTR?: string;
  public FillRate?: string;
}
export class MediationReportingDataRow extends ReportingDataRow {
  public AdUnitId?: string;
  public PubCenterAdUnitId?: string;
  public AdUnitName?: string;
  public SiteName?: string;
  public Country?: string;
  public Date?: string;
  public OperatingSystem?: string;
  public Revenue?: number;
  public Impressions?: number;
  public AdClicks?: number;
  public AdCTR?: string;
  public AdECPM?: string;
}
