import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  AdUnit,
  Filter,
  FilterScope,
  getAppStore,
  listEntity,
  onLoadingChanged,
  onNotificationChanged,
  Publisher,
} from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { setPublisherAndAccountContext } from '../../../@data/services/AdManagementService';
import { fetchAdunitById } from '../../../Adunit/@data/services/AdunitService';
import { onChannelChanged, onChannelErrorChanged, onFormInitialized, onRawChanged } from '../actions';
import { fetchChannelById } from '../services/ChannelService';
import { IChannelViewModel } from '../store/schema';

orchestrator(onFormInitialized, async (msg) => {
  const { userContext } = getAppStore();

  const viewModel: IChannelViewModel = {
    filters: [],
    adunits: [],
  };

  const publisherId = parseInt(msg.publisherId, 10);
  let accountId = parseInt(msg.accountId, 10);

  // To load create channel page in adunit edit page with 'All accounts' scope
  if (!isNaN(publisherId) && accountId === 0 && msg.mode === FormState.InPageCreate && msg.entityId) {
    const adunit = await fetchAdunitById(new Publisher(publisherId), msg.entityId, userContext);
    accountId = adunit.accountId || NaN;
  }

  if (!isNaN(publisherId) && !isNaN(accountId)) {
    const publisher = new Publisher(publisherId);
    const account = new Account(accountId);

    try {
      setPublisherAndAccountContext(publisher, account);

      onChannelErrorChanged();
      onLoadingChanged(true);

      const response = await Promise.all([
        listEntity([publisher, account], userContext, AdUnit),
        listEntity([publisher, account], userContext, Filter),
      ]);

      viewModel.adunits = response[0] ? response[0]!.map((f) => ({ id: f.id, name: f.name } as IIdNamePair)) : [];

      // Filters for this scenario need to be scoped by Account, in order to edit/save them in UI.
      viewModel.filters = response[1]
        ? response[1]!.filter((f) => f.filterScope === FilterScope.Account).map((f) => ({ id: f.id, name: f.name } as IIdNamePair))
        : [];

      if (msg.mode === FormState.Edit && msg.entityId) {
        const raw = await fetchChannelById(publisher, msg.entityId, userContext);

        viewModel.id = raw.id;
        viewModel.name = String(raw.name);
        viewModel.filtersSelected = raw.associatedFilterIds ? viewModel.filters.filter((a) => raw.associatedFilterIds!.includes(a.id)) : [];
        viewModel.adunitsSelected = raw.associatedAdUnitIds ? viewModel.adunits.filter((f) => raw.associatedAdUnitIds!.includes(f.id)) : [];

        onRawChanged(raw);
      } else {
        onRawChanged();
      }

      onChannelChanged(viewModel);
    } catch (e) {
      onNotificationChanged({
        text: 'Failed to load entity',
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      text: `You have arrived in an invalid state, please report.`,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
