import { defineMessages } from 'react-intl';

export default defineMessages({
  feedbackThank: {
    id: 'feedback.thank',
    defaultMessage: 'Thank you for providing us with {appName} feedback.',
  },
  feedbackSupport: {
    id: 'feedback.support',
    defaultMessage: 'We’d love to learn about your experience with {appName}. If you have any questions, please reach out to ',
  },
  feedbackType: {
    id: 'feedback.type',
    defaultMessage: 'Type of feedback',
  },
  feedbackRate: {
    id: 'feedback.rate',
    defaultMessage: 'Rate your experience',
  },
  feedbackRequired: {
    id: 'feedback.required',
    defaultMessage: 'Required',
  },
  feedbackSend: {
    id: 'feedback.send',
    defaultMessage: 'Send Feedback',
  },
  feedbackTitle: {
    id: 'feedback.title',
    defaultMessage: 'Title',
  },
  feedbackTitlePlaceholder: {
    id: 'feedback.titlePlaceholder',
    defaultMessage: 'Add a title for your feedback',
  },
  feedbackTellUsMore: {
    id: 'feedback.tellUsMore',
    defaultMessage: 'Tell us more',
  },
  feedbackTellUsMorePlaceholder: {
    id: 'feedback.tellUsMorePlaceholder',
    defaultMessage: 'Please don’t include any confidential or personal information in your feedback.',
  },
  feedbackAllowContact: {
    id: 'feedback.allowContact',
    defaultMessage: 'Allow us to follow up with you about your feedback.',
  },
  feedbackAllowContactPlaceholder: {
    id: 'feedback.allowContactPlaceholder',
    defaultMessage: 'Type your email address',
  },
  feedbackContactSupport: {
    id: 'feedback.contactSupport',
    defaultMessage: 'support.',
  },
  feedbackPrivacyStatement: {
    id: 'feedback.privacyStatement',
    defaultMessage:
      'To improve our service, we may collect information about your account or device. For more details, please refer to the ',
  },
  feedbackMPS: {
    id: 'feedback.microsoftPrivacyStatement',
    defaultMessage: 'Microsoft privacy statement.',
  },
  feedbackCharacterCounter: {
    id: 'feedback.characterCounter',
    defaultMessage: '{currentCounter}/{limit} characters',
  },
  feedbackTypeAppreciation: {
    id: 'feedback.appreciation',
    defaultMessage: 'Appreciation',
  },
  feedbackTypeFeatureRequest: {
    id: 'feedback.featureRequest',
    defaultMessage: 'Feature request',
  },
  feedbackTypeBugReport: {
    id: 'feedback.bugReport',
    defaultMessage: 'Bug report',
  },
  feedbackTypeOther: {
    id: 'feedback.other',
    defaultMessage: 'Other',
  },
  feedbackDataCollection: {
    id: 'feedback.dataCollection',
    defaultMessage: `To request deletion of your submitted data, please contact our {supportTeamPlaceholder} with the subject "Delete feedback data".`,
  },
  supportTeamPlaceholder: {
    id: 'feedback.dataCollection.supportTeamPlaceholder',
    defaultMessage: 'support team',
  },
  deleteFeedbackDataFormSubject: {
    id: 'feedback.dataCollection.form.subject.deleteFeedbackData',
    defaultMessage: 'Delete feedback data',
  },
});
