import { Checkbox, Link, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { injectIntlAndObserver } from '../../../utils';
import { FormSection } from '../../Form';
import { IIdNamePair } from '../../MultiSelectPicker';
import messages from './ListFilter.messages';
import { getClassNames } from './ListFilter.styles';
import { IListFilterProps } from './ListFilter.types';

export const ListFilter = injectIntlAndObserver(function ListFilterComponent(props: IListFilterProps): JSX.Element {
  const { formatMessage } = props.intl;
  const { root, content, checkBoxRow, buttonSection } = mergeStyleSets(getClassNames());
  const { selectAll, deselectAll } = messages;
  const { heading, list, onSelect, onSelectAll, onDeselectAll } = props;

  const areAllElementsSelected = (): boolean => {
    const lst: IIdNamePair[] = list ?? [];
    return lst?.every((elem: IIdNamePair) => {
      return elem.isSelected;
    });
  };

  const hasSelectedElements = (): boolean => {
    const lst: IIdNamePair[] = list ?? [];
    return lst?.some((elem: IIdNamePair) => {
      return elem.isSelected;
    });
  };

  return (
    <div className={root}>
      <FormSection formWidth={300} title={heading} expanded={hasSelectedElements()} rightChevron>
        <div className={content}>
          <div>
            {list?.map((elemList: IIdNamePair, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className={checkBoxRow}>
                    <Checkbox
                      label={elemList.name}
                      checked={!!elemList.isSelected}
                      onChange={(_, value) => {
                        if (heading && onSelect) {
                          return onSelect(heading, elemList, !!value);
                        }
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className={buttonSection}>
            <Link
              onClick={() => {
                if (heading && onSelectAll && onDeselectAll) {
                  return !areAllElementsSelected() ? onSelectAll(heading) : onDeselectAll(heading);
                }
              }}
            >
              {!areAllElementsSelected() ? formatMessage(selectAll) : formatMessage(deselectAll)}
            </Link>
          </div>
        </div>
      </FormSection>
    </div>
  );
});
