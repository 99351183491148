import { Callout, DefaultButton, DirectionalHint, PrimaryButton, ShimmerElementType, ShimmerElementsGroup, Stack } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import appMessages from '../../../../../../App.messages';
import { Card } from '../../../../../../components/Card';
import { ICheckbox } from '../../../../../../components/CheckboxGroup';
import { CheckboxTreeWithSelectionBox, ISelection } from '../../../../../../components/CheckboxTreeWithSelectionBox';
import { injectIntlAndObserver } from '../../../../../../utils';
import { getReportColumnMetadataDisplayName } from '../../../../utils/intlHelper';
import { IMetadataElement } from '../../../@data/store/schema';
import { getClassNames, getStackTokens } from './ManageFilter.styles';

export interface IManageFilterProps extends InjectedIntlProps {
  onFilterApplied: (dimension: IMetadataElement, selectedFilters: ISelection[]) => void;
  onDismiss: () => void;
  initialFilterMembers: ICheckbox[];
  buttonId: string;
  isFilterOpen: boolean;
  areMembersLoaded: boolean;
  activeDimension?: IMetadataElement;
  isIncompatible?: boolean;
  inCompatibilityMessage?: string;
}

export const ManageFilter = injectIntlAndObserver(
  ({
    onFilterApplied,
    onDismiss,
    initialFilterMembers,
    buttonId,
    isFilterOpen,
    activeDimension,
    intl: { formatMessage },
    areMembersLoaded,
    isIncompatible,
    inCompatibilityMessage,
  }: IManageFilterProps) => {
    const classNames = getClassNames();
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    const [selectedFilters, setSelectedFilter] = useState<ISelection[]>(getSelectedFilters(initialFilterMembers));
    const [filterMembers, setFilterMembers] = useState<ICheckbox[]>(initialFilterMembers);

    const onChildCheckboxChange = (event, isChecked, item: ICheckbox) => {
      event?.stopPropagation();
      const filterKey = item?.key as string;
      if (!filterKey) {
        return;
      }
      let newSelection;
      if (isChecked) {
        selectedFilters.push({
          key: filterKey,
          title: item.label || '',
          isDisabled: false,
        });
        newSelection = selectedFilters;
      } else {
        newSelection = selectedFilters.filter((selected) => {
          return selected.key !== filterKey;
        });
      }
      onSelectionChanged(newSelection);
    };
    const onSelectionChanged = (newSelection: ISelection[]) => {
      setSelectedFilter(newSelection);
      setFilterMembers(
        filterMembers.map((filterItem) => {
          filterItem.checked = !!newSelection.find((value) => value.key === filterItem.key);
          return filterItem;
        })
      );
    };
    const onApplyClicked = () => {
      activeDimension && onFilterApplied(activeDimension, selectedFilters);
      onDismiss();
    };

    useEffect(() => {
      setFilterMembers(initialFilterMembers);
      setSelectedFilter(getSelectedFilters(initialFilterMembers));
    }, [initialFilterMembers]);
    return (
      <>
        {isFilterOpen && (
          <Callout
            ariaLabelledBy={labelId}
            ariaDescribedBy={descriptionId}
            role="dialog"
            target={`#${buttonId}`}
            onDismiss={onDismiss}
            directionalHint={DirectionalHint.rightTopEdge}
            setInitialFocus
            isBeakVisible={false}
          >
            <div className={classNames.root}>
              <Card
                shimmerCustomElement={
                  <ShimmerElementsGroup
                    flexWrap
                    width="100%"
                    shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 20 }]}
                  />
                }
                isDataLoaded={areMembersLoaded}
                titleText={getReportColumnMetadataDisplayName(activeDimension?.key || '', formatMessage) || ''}
                styleProps={{ rootStyle: classNames.cardRootStyle }}
                {...(isIncompatible && {
                  titleIconTooltipProps: {
                    iconName: 'warning',
                    tooltipText: inCompatibilityMessage || '',
                  },
                })}
              >
                <CheckboxTreeWithSelectionBox
                  searchPlaceholderText={`${formatMessage(appMessages.search)} ${getReportColumnMetadataDisplayName(
                    activeDimension?.key || '',
                    formatMessage
                  )}`}
                  onChildCheckboxChange={onChildCheckboxChange}
                  onRemoveSelection={(key) => {
                    const newSelection = selectedFilters.filter((selected) => {
                      return selected.key !== key;
                    });
                    onSelectionChanged(newSelection);
                  }}
                  onRemoveAllSelection={() => {
                    onSelectionChanged([]);
                  }}
                  selectionList={selectedFilters}
                  selectionTitle={''}
                  groups={filterMembers}
                  onParentCheckboxChange={() => {
                    return;
                  }}
                />
                <Stack horizontal horizontalAlign="space-between" tokens={getStackTokens()}>
                  <Stack.Item grow>
                    <PrimaryButton onClick={onApplyClicked} className={classNames.buttonAction}>
                      {formatMessage(appMessages.apply)}
                    </PrimaryButton>
                  </Stack.Item>
                  <Stack.Item grow>
                    <DefaultButton onClick={onDismiss} className={classNames.buttonAction}>
                      {formatMessage(appMessages.cancel)}
                    </DefaultButton>
                  </Stack.Item>
                </Stack>
              </Card>
            </div>
          </Callout>
        )}
      </>
    );
  }
);
function getSelectedFilters(initialFilterMembers: ICheckbox[]): ISelection[] {
  return initialFilterMembers
    .filter((filterMember) => filterMember.checked)
    .map((filterMember) => {
      return {
        key: String(filterMember.key),
        title: filterMember.label || '',
        isDisabled: false,
      };
    });
}
