import { Toggle } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { EntityType } from '../../../../../../@data';
import { completeAccess, hasReadAccess, hasUpdateAccess, UserRoleEntity } from '../../../../../../@data/services/UserRoleService';
import { PublisherSettings } from '../../../../../../@data/store/schema/userrolemodels/PublisherSettings';
import { UserSettings } from '../../../../../../@data/store/schema/userrolemodels/UserSettings';
import { getEntityAccessPermissions } from '../../../../../../@data/utils/getAccessPermission';
import AppMessages from '../../../../../../App.messages';
import { SummaryCard } from '../../../../../../components/SummaryCard';
import { injectIntlAndObserver } from '../../../../../../utils';
import { toggleBillingAlerts, toggleInvitedUsers, toggleProfileReview, toggleServingStatus } from '../../@data/actions';
import '../../@data/mutatorActions';
import '../../@data/orchestrators';
import { getStore } from '../../@data/store/store';
import settingsIcon from '../../resources/settings.svg';
import messages from './NotificationConfiguration.messages';
import { getClassNames } from './NotificationConfiguration.styles';

export interface INotificationConfigurationProps extends InjectedIntlProps {}

export const NotificationConfiguration = injectIntlAndObserver(function NotificationConfigurationComponent({
  intl,
}: INotificationConfigurationProps): JSX.Element | null {
  const { formatMessage } = intl;
  const { HeadingRow, HeadingTitle, Body, Section, SectionContent, SectionTitle } = SummaryCard;
  const { gridDisplay, flexDisplay } = getClassNames();
  const { notificationPreferences, entityType } = getStore().active;

  const { billingAlerts, servingStatus, profileReview, invitedUsers } = notificationPreferences;

  const publisherSettingsPermissions = getEntityAccessPermissions(UserRoleEntity.PublisherSettings) as PublisherSettings | null;
  const hasPublisherPreferencesReadAccess = hasReadAccess(
    publisherSettingsPermissions ? publisherSettingsPermissions.publisherPreferences : completeAccess
  );
  const hasPublisherPreferencesUpdateAccess = hasReadAccess(
    publisherSettingsPermissions ? publisherSettingsPermissions.publisherPreferences : completeAccess
  );
  const userSettingsPermissions = getEntityAccessPermissions(UserRoleEntity.UserSettings) as UserSettings | null;

  const hasSettingsReadAccess = (field: string) => {
    if (entityType === EntityType.Publisher && hasPublisherPreferencesReadAccess) {
      return true;
    } else {
      return hasReadAccess(!userSettingsPermissions ? completeAccess : userSettingsPermissions[field]);
    }
  };

  const hasSettingsUpdateAccess = (field: string) => {
    if (entityType === EntityType.Publisher && hasPublisherPreferencesUpdateAccess) {
      return true;
    } else {
      return hasUpdateAccess(!userSettingsPermissions ? completeAccess : userSettingsPermissions[field]);
    }
  };

  const hasBillingAlertsAccess = hasSettingsReadAccess('billingAlerts');
  const hasServingStatusAccess = hasSettingsReadAccess('servingStatus');
  const hasProfileReviewAccess = hasSettingsReadAccess('profileReview');
  const hasInvitedUsersAccess = hasSettingsReadAccess('invitedUsers');

  if (!hasBillingAlertsAccess && !hasServingStatusAccess && !hasProfileReviewAccess && !hasInvitedUsersAccess) {
    return null;
  }

  return (
    <React.Fragment>
      <SummaryCard>
        <HeadingRow>
          <HeadingTitle text={formatMessage(messages.headerLabel)} icon={settingsIcon} />
        </HeadingRow>
        <Body styleProps={{ bodyContainerStyle: gridDisplay }}>
          {hasBillingAlertsAccess && (
            <Section styleProps={{ sectionStyle: flexDisplay }}>
              <div>
                <SectionTitle text={formatMessage(messages.billingLabel)} />
                <SectionContent text={formatMessage(messages.billingDescription)} />
              </div>
              <Toggle
                inlineLabel
                checked={billingAlerts}
                onText={formatMessage(AppMessages.on)}
                offText={formatMessage(AppMessages.off)}
                onChange={(_, checked) => toggleBillingAlerts(entityType, checked)}
                disabled={
                  !(hasSettingsUpdateAccess('billingAlerts') && (entityType === EntityType.User ? billingAlerts !== undefined : true))
                }
              />
            </Section>
          )}
          {hasServingStatusAccess && (
            <Section styleProps={{ sectionStyle: flexDisplay }}>
              <div>
                <SectionTitle text={formatMessage(messages.servingLabel)} />
                <SectionContent text={formatMessage(messages.servingDescription)} />
              </div>
              <Toggle
                inlineLabel
                checked={servingStatus}
                onText={formatMessage(AppMessages.on)}
                offText={formatMessage(AppMessages.off)}
                onChange={(_, checked) => toggleServingStatus(entityType, checked)}
                disabled={
                  !(hasSettingsUpdateAccess('servingStatus') && (entityType === EntityType.User ? servingStatus !== undefined : true))
                }
              />
            </Section>
          )}
          {hasProfileReviewAccess && (
            <Section styleProps={{ sectionStyle: flexDisplay }}>
              <div>
                <SectionTitle text={formatMessage(messages.profileLabel)} />
                <SectionContent text={formatMessage(messages.profileDescription)} />
              </div>
              <Toggle
                inlineLabel
                checked={profileReview}
                onText={formatMessage(AppMessages.on)}
                offText={formatMessage(AppMessages.off)}
                onChange={(_, checked) => toggleProfileReview(entityType, checked)}
                // UM_TODO: write UT for this assumption. If preference is disabled on publisher level, user cannot override it.
                disabled={
                  !(hasSettingsUpdateAccess('profileReview') && (entityType === EntityType.User ? profileReview !== undefined : true))
                }
              />
            </Section>
          )}
          {hasInvitedUsersAccess && (
            <Section styleProps={{ sectionStyle: flexDisplay }}>
              <div>
                <SectionTitle text={formatMessage(messages.invitedUserLabel)} />
                <SectionContent text={formatMessage(messages.invitedUserDescription)} />
              </div>
              <Toggle
                inlineLabel
                checked={invitedUsers}
                onText={formatMessage(AppMessages.on)}
                offText={formatMessage(AppMessages.off)}
                onChange={(_, checked) => toggleInvitedUsers(entityType, checked)}
                disabled={
                  !(hasSettingsUpdateAccess('invitedUsers') && (entityType === EntityType.User ? invitedUsers !== undefined : true))
                }
              />
            </Section>
          )}
        </Body>
      </SummaryCard>
    </React.Fragment>
  );
});
