import { mixed, object, string } from 'yup';
import { StringListSchemaYupType } from '../../../../../utils';
import { IShareViewModel } from './IShareViewModel';
import { Schedule } from './ShareSchedule';

export function shareErrorModel() {
  return object<IShareViewModel>().shape({
    schedule: mixed().oneOf([Schedule.NONE, Schedule.DAILY, Schedule.WEEKLY, Schedule.MONTHLY]).required(),
    emails: new StringListSchemaYupType().of(string().trim().email().required()).required(),
  });
}
