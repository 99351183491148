/* eslint no-useless-escape: 0 */
export const DEFAULT_NON_TYTHON_TNC = `<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="33333.fld/filelist.xml">
<link rel=Edit-Time-Data href="33333.fld/editdata.mso">
<!--[if !mso]>
<style>
v\:* {behavior:url(#default#VML);}
o\:* {behavior:url(#default#VML);}
w\:* {behavior:url(#default#VML);}
.shape {behavior:url(#default#VML);}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:DocumentProperties>
  <o:Author>Aolin Yang</o:Author>
  <o:LastAuthor>Aolin Yang</o:LastAuthor>
  <o:Revision>3</o:Revision>
  <o:TotalTime>10</o:TotalTime>
  <o:Created>2023-04-21T22:54:00Z</o:Created>
  <o:LastSaved>2023-04-21T22:56:00Z</o:LastSaved>
  <o:Pages>1</o:Pages>
  <o:Words>8216</o:Words>
  <o:Characters>46834</o:Characters>
  <o:Lines>390</o:Lines>
  <o:Paragraphs>109</o:Paragraphs>
  <o:CharactersWithSpaces>54941</o:CharactersWithSpaces>
  <o:Version>16.00</o:Version>
 </o:DocumentProperties>
 <o:OfficeDocumentSettings>
  <o:AllowPNG/>
 </o:OfficeDocumentSettings>
</xml><![endif]-->
<link rel=themeData href="33333.fld/themedata.thmx">
<link rel=colorSchemeMapping href="33333.fld/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:Zoom>150</w:Zoom>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:PunctuationKerning/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-US</w:LidThemeOther>
  <w:LidThemeAsian>ZH-CN</w:LidThemeAsian>
  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
   <w:UseFELayout/>
  </w:Compatibility>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" Priority="0" SemiHidden="true"
   UnhideWhenUsed="true" Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-536870145 1107305727 0 0 415 0;}
@font-face
	{font-family:DengXian;
	panose-1:2 1 6 0 3 1 1 1 1 1;
	mso-font-alt:等线;
	mso-font-charset:134;
	mso-generic-font-family:auto;
	mso-font-pitch:variable;
	mso-font-signature:-1610612033 953122042 22 0 262159 0;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073732485 9 0 511 0;}
@font-face
	{font-family:"\@DengXian";
	panose-1:2 1 6 0 3 1 1 1 1 1;
	mso-font-charset:134;
	mso-generic-font-family:auto;
	mso-font-pitch:variable;
	mso-font-signature:-1610612033 953122042 22 0 262159 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
h1
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 1 Char";
	mso-style-next:Normal;
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:0in;
	mso-pagination:none;
	mso-outline-level:1;
	mso-list:l1 level1 lfo2;
	tab-stops:1.0in;
	font-size:11.0pt;
	mso-bidi-font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	text-transform:uppercase;
	mso-font-kerning:0pt;
	mso-fareast-language:EN-US;
	mso-bidi-font-weight:normal;}
h2
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	mso-style-link:"Heading 2 Char";
	margin-top:6.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:27.0pt;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:2;
	mso-list:l1 level2 lfo2;
	mso-layout-grid-align:none;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:Arial;
	mso-fareast-language:EN-US;
	mso-bidi-font-style:italic;}
h3
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"Heading 2";
	mso-style-link:"Heading 3 Char";
	margin-top:6.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:49.5pt;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:3;
	mso-list:l1 level3 lfo2;
	tab-stops:45.0pt;
	mso-layout-grid-align:none;
	font-size:11.0pt;
	mso-bidi-font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:16.0pt;
	mso-fareast-language:EN-US;
	font-weight:normal;
	mso-bidi-font-weight:bold;
	mso-bidi-font-style:italic;
	text-decoration:underline;
	text-underline:single;}
h4
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 4 Char";
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:175.5pt;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:4;
	mso-list:l1 level4 lfo2;
	mso-layout-grid-align:none;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-fareast-language:EN-US;
	font-weight:normal;
	mso-bidi-font-weight:bold;}
h5
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 5 Char";
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.25in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	mso-list:l1 level5 lfo2;
	mso-layout-grid-align:none;
	font-size:11.0pt;
	mso-bidi-font-size:13.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-fareast-language:EN-US;
	font-weight:normal;
	mso-bidi-font-weight:bold;
	mso-bidi-font-style:italic;}
h6
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 6 Char";
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:6;
	mso-list:l1 level6 lfo2;
	mso-layout-grid-align:none;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-fareast-language:EN-US;
	font-weight:normal;
	mso-bidi-font-weight:bold;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 7 Char";
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.75in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:7;
	mso-list:l1 level7 lfo2;
	mso-layout-grid-align:none;
	font-size:10.0pt;
	mso-bidi-font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-fareast-language:EN-US;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 8 Char";
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.0in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:8;
	mso-list:l1 level8 lfo2;
	mso-layout-grid-align:none;
	font-size:10.0pt;
	mso-bidi-font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	mso-fareast-language:EN-US;
	mso-bidi-font-style:italic;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"Heading 9 Char";
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.25in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	mso-outline-level:9;
	mso-list:l1 level9 lfo2;
	mso-layout-grid-align:none;
	font-size:9.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:Arial;
	mso-fareast-language:EN-US;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	mso-pagination:widow-orphan;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-priority:99;
	mso-style-link:"Footer Char";
	margin:0in;
	mso-pagination:widow-orphan;
	tab-stops:center 3.25in right 6.5in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
a:link, span.MsoHyperlink
	{mso-style-priority:99;
	color:blue;
	text-decoration:underline;
	text-underline:single;}
a:visited, span.MsoHyperlinkFollowed
	{mso-style-noshow:yes;
	mso-style-priority:99;
	color:#954F72;
	mso-themecolor:followedhyperlink;
	text-decoration:underline;
	text-underline:single;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 1";
	mso-ansi-font-size:11.0pt;
	text-transform:uppercase;
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;
	font-weight:bold;
	mso-bidi-font-weight:normal;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 2";
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:Arial;
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;
	font-weight:bold;
	mso-bidi-font-style:italic;}
span.Heading3Char
	{mso-style-name:"Heading 3 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 3";
	mso-ansi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:16.0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;
	mso-bidi-font-weight:bold;
	mso-bidi-font-style:italic;
	text-decoration:underline;
	text-underline:single;}
span.Heading4Char
	{mso-style-name:"Heading 4 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 4";
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;
	mso-bidi-font-weight:bold;}
span.Heading5Char
	{mso-style-name:"Heading 5 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 5";
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:13.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;
	mso-bidi-font-weight:bold;
	mso-bidi-font-style:italic;}
span.Heading6Char
	{mso-style-name:"Heading 6 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 6";
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;
	mso-bidi-font-weight:bold;}
span.Heading7Char
	{mso-style-name:"Heading 7 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 7";
	mso-ansi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;}
span.Heading8Char
	{mso-style-name:"Heading 8 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 8";
	mso-ansi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:"Times New Roman";
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;
	mso-bidi-font-style:italic;}
span.Heading9Char
	{mso-style-name:"Heading 9 Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Heading 9";
	mso-ansi-font-size:9.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:"Times New Roman";
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:Arial;
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Comment Text";
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:Footer;
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-font-kerning:0pt;
	mso-ligatures:none;
	mso-fareast-language:EN-US;}
p.OutHead1, li.OutHead1, div.OutHead1
	{mso-style-name:OutHead1;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:6.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.5in;
	mso-pagination:widow-orphan;
	mso-outline-level:1;
	mso-list:l2 level1 lfo1;
	tab-stops:list .5in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;}
p.OutHead2, li.OutHead2, div.OutHead2
	{mso-style-name:OutHead2;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:6.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.5in;
	mso-pagination:widow-orphan;
	mso-outline-level:2;
	mso-list:l2 level2 lfo1;
	tab-stops:list .5in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;}
p.OutHead3, li.OutHead3, div.OutHead3
	{mso-style-name:OutHead3;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:6.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:1.0in;
	text-align:justify;
	text-justify:inter-ideograph;
	text-indent:-.5in;
	mso-pagination:widow-orphan;
	mso-outline-level:3;
	mso-list:l2 level3 lfo1;
	tab-stops:list 1.0in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;}
p.OutHead4, li.OutHead4, div.OutHead4
	{mso-style-name:OutHead4;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-indent:-.25in;
	mso-pagination:widow-orphan;
	page-break-after:avoid;
	mso-outline-level:4;
	mso-list:l2 level4 lfo1;
	tab-stops:list 1.0in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;
	font-weight:bold;
	mso-bidi-font-weight:normal;}
p.OutHead5, li.OutHead5, div.OutHead5
	{mso-style-name:OutHead5;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.5in;
	text-indent:-.5in;
	mso-pagination:widow-orphan;
	page-break-after:avoid;
	mso-outline-level:5;
	mso-list:l2 level5 lfo1;
	tab-stops:list 2.5in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;
	font-weight:bold;
	mso-bidi-font-weight:normal;}
p.OutHead6, li.OutHead6, div.OutHead6
	{mso-style-name:OutHead6;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:3.0in;
	text-indent:-.5in;
	mso-pagination:widow-orphan;
	page-break-after:avoid;
	mso-outline-level:6;
	mso-list:l2 level6 lfo1;
	tab-stops:list 3.0in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;
	font-weight:bold;
	mso-bidi-font-weight:normal;}
p.OutHead7, li.OutHead7, div.OutHead7
	{mso-style-name:OutHead7;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:3.5in;
	text-indent:-.5in;
	mso-pagination:widow-orphan;
	page-break-after:avoid;
	mso-outline-level:7;
	mso-list:l2 level7 lfo1;
	tab-stops:list 3.5in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;
	font-weight:bold;
	mso-bidi-font-weight:normal;}
p.OutHead8, li.OutHead8, div.OutHead8
	{mso-style-name:OutHead8;
	mso-style-priority:1;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:4.0in;
	text-indent:-.5in;
	mso-pagination:widow-orphan;
	page-break-after:avoid;
	mso-outline-level:8;
	mso-list:l2 level8 lfo1;
	tab-stops:list 4.0in;
	font-size:11.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-bidi-font-family:"Times New Roman";
	color:black;
	mso-fareast-language:EN-US;
	font-weight:bold;
	mso-bidi-font-weight:normal;}
span.normaltextrun
	{mso-style-name:normaltextrun;
	mso-style-priority:1;
	mso-style-unhide:no;}
span.eop
	{mso-style-name:eop;
	mso-style-priority:1;
	mso-style-unhide:no;}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
span.GramE
	{mso-style-name:"";
	mso-gram-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:DengXian;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;}
 /* Page Definitions */
 @page
	{mso-footnote-separator:url("33333.fld/header.html") fs;
	mso-footnote-continuation-separator:url("33333.fld/header.html") fcs;
	mso-endnote-separator:url("33333.fld/header.html") es;
	mso-endnote-continuation-separator:url("33333.fld/header.html") ecs;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;
	mso-header-margin:.5in;
	mso-footer-margin:.5in;
	mso-even-footer:url("33333.fld/header.html") ef1;
	mso-footer:url("33333.fld/header.html") f1;
	mso-first-footer:url("33333.fld/header.html") ff1;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 @list l0
	{mso-list-id:255947117;
	mso-list-type:hybrid;
	mso-list-template-ids:1311002444 796035260 -1765366880 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l0:level1
	{mso-level-number-format:roman-lower;
	mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.5in;}
@list l0:level2
	{mso-level-number-format:alpha-lower;
	mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:92.0pt;
	text-indent:-20.0pt;
	mso-ansi-font-weight:bold;}
@list l0:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:1.75in;
	text-indent:-9.0pt;}
@list l0:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.25in;
	text-indent:-.25in;}
@list l0:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.75in;
	text-indent:-.25in;}
@list l0:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:3.25in;
	text-indent:-9.0pt;}
@list l0:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.75in;
	text-indent:-.25in;}
@list l0:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.25in;
	text-indent:-.25in;}
@list l0:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:4.75in;
	text-indent:-9.0pt;}
@list l1
	{mso-list-id:582490527;
	mso-list-template-ids:-1777312264;}
@list l1:level1
	{mso-level-style-link:"Heading 1";
	mso-level-text:"Section %1";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;
	font-variant:normal !important;
	color:black;
	mso-text-animation:none;
	mso-hide:none;
	text-transform:none;
	position:relative;
	top:0pt;
	mso-text-raise:0pt;
	letter-spacing:0pt;
	mso-font-kerning:0pt;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	mso-no-proof:no;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l1:level2
	{mso-level-style-link:"Heading 2";
	mso-level-text:"%1\.%2";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:27.0pt;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-line-through:none;}
@list l1:level3
	{mso-level-number-format:alpha-lower;
	mso-level-style-link:"Heading 3";
	mso-level-text:"\(%3\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:49.5pt;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	text-transform:none;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l1:level4
	{mso-level-number-format:roman-lower;
	mso-level-style-link:"Heading 4";
	mso-level-text:"\(%4\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:175.5pt;
	text-indent:-.25in;
	mso-ansi-font-size:13.5pt;
	mso-bidi-font-size:13.5pt;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;}
@list l1:level5
	{mso-level-style-link:"Heading 5";
	mso-level-text:"%5\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.25in;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;}
@list l1:level6
	{mso-level-style-link:"Heading 6";
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.5in;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;}
@list l1:level7
	{mso-level-style-link:"Heading 7";
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.75in;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;}
@list l1:level8
	{mso-level-style-link:"Heading 8";
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.0in;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;}
@list l1:level9
	{mso-level-style-link:"Heading 9";
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.25in;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;}
@list l2
	{mso-list-id:876699326;
	mso-list-template-ids:993452568;}
@list l2:level1
	{mso-level-style-link:OutHead1;
	mso-level-tab-stop:.5in;
	mso-level-number-position:left;
	text-indent:-.5in;
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:uppercase;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level2
	{mso-level-style-link:OutHead2;
	mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:.5in;
	mso-level-number-position:left;
	margin-left:.5in;
	text-indent:-.5in;
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:none;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level3
	{mso-level-style-link:OutHead3;
	mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:1.0in;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.5in;
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:none;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:normal;
	mso-bidi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level4
	{mso-level-style-link:OutHead4;
	mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%4\.";
	mso-level-tab-stop:1.0in;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.25in;
	mso-ansi-font-size:12.0pt;
	font-family:"Times New Roman",serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:none;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level5
	{mso-level-style-link:OutHead5;
	mso-level-text:"\(%5\)";
	mso-level-tab-stop:2.5in;
	mso-level-number-position:left;
	text-indent:-.5in;
	mso-ansi-font-size:12.0pt;
	font-family:"Times New Roman",serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:none;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level6
	{mso-level-number-format:alpha-lower;
	mso-level-style-link:OutHead6;
	mso-level-text:"\(%6\)";
	mso-level-tab-stop:3.0in;
	mso-level-number-position:left;
	text-indent:-.5in;
	mso-ansi-font-size:12.0pt;
	font-family:"Times New Roman",serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:none;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level7
	{mso-level-number-format:roman-lower;
	mso-level-style-link:OutHead7;
	mso-level-text:"%7\)";
	mso-level-tab-stop:3.5in;
	mso-level-number-position:left;
	text-indent:-.5in;
	mso-ansi-font-size:12.0pt;
	font-family:"Times New Roman",serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:none;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level8
	{mso-level-number-format:alpha-lower;
	mso-level-style-link:OutHead8;
	mso-level-text:"%8\)";
	mso-level-tab-stop:4.0in;
	mso-level-number-position:left;
	text-indent:-.5in;
	mso-ansi-font-size:12.0pt;
	font-family:"Times New Roman",serif;
	font-variant:normal !important;
	mso-text-animation:none;
	text-transform:none;
	text-effect:none;
	text-shadow:none;
	text-effect:none;
	text-effect:none;
	mso-ansi-font-weight:bold;
	mso-ansi-font-style:normal;
	text-decoration:none;
	text-underline:none;
	text-decoration:none;
	text-line-through:none;
	vertical-align:baseline;}
@list l2:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:2.25in;
	mso-level-number-position:left;
	margin-left:2.25in;
	text-indent:-.25in;}
@list l3
	{mso-list-id:884752434;
	mso-list-type:hybrid;
	mso-list-template-ids:565467318 -1 2042009676 -1 -1 -1 -1 -1 -1 -1;}
@list l3:level1
	{mso-level-number-format:roman-lower;
	mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.5in;}
@list l3:level2
	{mso-level-number-format:roman-lower;
	mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:.75in;
	text-indent:-.25in;}
@list l3:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:1.75in;
	text-indent:-9.0pt;}
@list l3:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.25in;
	text-indent:-.25in;}
@list l3:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.75in;
	text-indent:-.25in;}
@list l3:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:3.25in;
	text-indent:-9.0pt;}
@list l3:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.75in;
	text-indent:-.25in;}
@list l3:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.25in;
	text-indent:-.25in;}
@list l3:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:4.75in;
	text-indent:-9.0pt;}
@list l4
	{mso-list-id:957879444;
	mso-list-type:hybrid;
	mso-list-template-ids:-2135918686 -1 67698689 -1 -1 -1 -1 -1 -1 -1;}
@list l4:level1
	{mso-level-number-format:roman-lower;
	mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.5in;}
@list l4:level2
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.25in;
	text-indent:-.25in;
	font-family:Symbol;}
@list l4:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:1.75in;
	text-indent:-9.0pt;}
@list l4:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.25in;
	text-indent:-.25in;}
@list l4:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.75in;
	text-indent:-.25in;}
@list l4:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:3.25in;
	text-indent:-9.0pt;}
@list l4:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.75in;
	text-indent:-.25in;}
@list l4:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.25in;
	text-indent:-.25in;}
@list l4:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:4.75in;
	text-indent:-9.0pt;}
@list l5
	{mso-list-id:1865241303;
	mso-list-type:hybrid;
	mso-list-template-ids:878460456 2042009676 1153736034 1142858962 -2106013266 -1037651666 -1431029476 1977495350 -1972974876 409270202;}
@list l5:level1
	{mso-level-number-format:roman-lower;
	mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:.75in;
	text-indent:-.25in;}
@list l5:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.25in;
	text-indent:-.25in;}
@list l5:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:1.75in;
	text-indent:-9.0pt;}
@list l5:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.25in;
	text-indent:-.25in;}
@list l5:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.75in;
	text-indent:-.25in;}
@list l5:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:3.25in;
	text-indent:-9.0pt;}
@list l5:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.75in;
	text-indent:-.25in;}
@list l5:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.25in;
	text-indent:-.25in;}
@list l5:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:4.75in;
	text-indent:-9.0pt;}
ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Table Normal";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0in 5.4pt 0in 5.4pt;
	mso-para-margin:0in;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;
	mso-ligatures:standardcontextual;}
</style>
<![endif]-->
</head>

<body lang=EN-US link=blue vlink="#954F72" style='tab-interval:.5in;word-wrap:
break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-top:12.0pt;line-height:normal'><b><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Microsoft pubCenter Publisher Terms and
Conditions</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:12.0pt;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>IF YOUR PRINCIPAL PLACE OF BUSINESS IS
IN THE UNITED STATES, SECTION 19 CONTAINS A BINDING ARBITRATION CLAUSE AND
CLASS ACTION WAIVER. IT AFFECTS YOUR RIGHTS ABOUT HOW TO RESOLVE ANY DISPUTE
WITH MICROSOFT. PLEASE READ IT.</span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='line-height:normal'><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>1.&nbsp;INTRODUCTION.&nbsp;</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>These publisher terms and
conditions are an agreement (“<b>Agreement</b>”) between&nbsp;you&nbsp;(&quot;<b>You</b>&quot;)
and Microsoft.<span style='mso-spacerun:yes'>  </span></span><strong><span
style='font-size:10.0pt;font-family:"Calibri",sans-serif;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;background:white'>“Microsoft”&nbsp;</span></strong><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128;background:white'>means Microsoft Online, Inc. (6880 Sierra
Center Parkway, Reno, NV 89511 USA) unless your principal place of business is
in (a) India in which case “Microsoft” means Microsoft Corporation (India)
Private Limited (Level 10, Tower C, Epitome, Building No. 5, DLF Cyber City,
Phase 3, Gurugram 122002 – Haryana, India), (b) Brazil in which case “Microsoft”
means Microsoft do <span class=SpellE>Brasil</span> <span class=SpellE>Importação</span>
e <span class=SpellE>Comércio</span> de Software e Video Games Ltda., enrolled
with the CNPJ under No. 04.712.500/0001-07 (Av. <span class=SpellE>Presidente</span>
<span class=SpellE>Juscelino</span> <span class=SpellE>Kubitscheck</span> 1909,
Torre Sul, 18 Andar, conj. 181 – Vila Nova <span class=SpellE>Conceição</span>,
CEP: 04543-907 São Paulo/SP <span class=SpellE>Brasil</span>), or (c) Europe,
the Middle East,</span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'> or<span style='background:white'>
Africa (“EMEA”) or Asia-Pacific (“APAC”) in which case “Microsoft” means
Microsoft Ireland Operations Limited (One Microsoft Place, South County
Business Park, <span class=SpellE>Leopardstown</span>, Dublin, Ireland 18, D18
P521)</span></span><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>. <span style='mso-spacerun:yes'> </span>This Agreement incorporates by
reference the terms of the Microsoft Services Agreement
(https://www.microsoft.com/en-us/servicesagreement/); provided, however, in the
event of any conflict between the terms of the Microsoft Services Agreement
that are applicable to You and this Agreement, the terms of this Agreement
shall control.<span style='mso-spacerun:yes'>  </span>This Agreement applies to
Your participation in or use of one or more of the following offerings
available through Microsoft pubCenter (collectively, &quot;pubCenter
Programs&quot;), including the following Microsoft pubCenter offerings: <span
style='mso-spacerun:yes'> </span>(a) the Microsoft pubCenter offering for web
publishers (“<b>pubCenter Program for Web Publishers</b>”); (b) the Microsoft
pubCenter offering for mobile application developers (“<b>pubCenter Program for
Mobile App Developers</b>”); (c) the Microsoft pubCenter offering for Windows
applications developers (“<b>pubCenter Program for Win App Developers</b>”);
and (d) the Microsoft pubCenter offering for Xbox applications developers (“<b>pubCenter
Program for Xbox App Developers</b>”).<span style='mso-spacerun:yes'>  </span>Notwithstanding
the foregoing, (a) if You have separately negotiated an agreement with
Microsoft covering a pubCenter Program, that separately negotiated agreement
continues to apply with respect to the applicable pubCenter Program, or (b) if
You have another type of separately negotiated agreement with Microsoft (i.e.,
not specifically covering a pubCenter Program) that separately negotiated
agreement shall apply to the extent it conflicts with the terms of this
Agreement.&nbsp; <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>2.&nbsp;MICROSOFT PUBCENTER</span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>. Subject to Your compliance with this
Agreement and such other rules, policies, requirements, and procedures as
Microsoft may publish on the pubCenter website located at&nbsp;</span><span
style='font-size:8.0pt'><a href="http://pubcenter.microsoft.com/"><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>http://pubcenter.microsoft.com</span></a></span><u><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> </span></u><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>(the &quot;<b>pubCenter
Site</b>”) or as published at</span><span style='font-size:8.0pt;mso-ascii-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'> </span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>https://about.ads.microsoft.com/en-us/policies/home
(“</span><span style='font-size:8.0pt'><a
href="https://about.ads.microsoft.com/en-us/policies/home"><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>Policies and Guidelines - Microsoft Advertising</span></a></span><span
style='font-size:8.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>”)</span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'> or as otherwise made available to<span style='mso-spacerun:yes'> 
</span>You from time to time (the “<b>Policies</b>”),&nbsp;You&nbsp;may access
the pubCenter Programs through the pubCenter website. You may access the
pubCenter Site solely to manage&nbsp;Your&nbsp;accounts for the pubCenter
Programs (“<b>Accounts</b>”) for the purpose(s) described hereunder. You may
also authorize third parties (“<b>Agents</b>”) to access the pubCenter Site and
manage Your Accounts on Your behalf; provided, however that You will be jointly
and severally responsible for the activities of Your Agents in association with
the use of the pubCenter Site.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>3.&nbsp;PUBCENTER CREDENTIALS.</span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>&nbsp;You </span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128;background:white'>agree not to use any false, <span
class=GramE>inaccurate</span> or misleading information when signing up for
Your Accounts, and You </span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>must protect any passwords or other credentials associated with Your
Accounts (“<b>Credentials</b>”), keep the Credentials confidential and take
full and sole responsibility for any use of Your Credentials and any activities
occurred under Your Account. You must <span class=GramE>keep all required
registration and other Account related information up to date at all times</span>.
If&nbsp;You&nbsp;suspect any improper or unauthorized use of Your Accounts or
other security issues that may affect a pubCenter Program, <span class=GramE>You</span>
must contact Microsoft immediately at&nbsp;</span><span style='font-size:8.0pt'><a
href="mailto:pubhelp@microsoft.com"><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;text-decoration:none;text-underline:none'>pubhelp@microsoft.com</span></a></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> or another email address
specified by Microsoft.<span style='mso-spacerun:yes'>  </span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128;background:white'>If we reasonably suspect that Your Accounts
are at risk of being used by a third party fraudulently (for example, as a
result of an Account compromise), or if Microsoft discovers a security breach
that is affecting a pubCenter Program, Microsoft may take actions that
Microsoft deems necessary to address or minimize the impact of the security
issue, including but not limited to suspending Your Account until You can
reclaim ownership,</span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'> requiring you to update your<span
style='background:white'> </span>Credentials, <span style='background:white'>or
stop delivering advertising.</span> You agree to cooperate in a timely manner
with reasonable requests from Microsoft in connection with any security or data
incidents. </span><span style='font-size:6.5pt;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;background:white'>&nbsp;</span><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></b></p>

<p class=MsoCommentText><b><span style='mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>4.&nbsp;USE OF PUBCENTER PROGRAMS.&nbsp;<o:p></o:p></span></b></p>

<p class=MsoCommentText style='margin-bottom:0in'><span class=GramE><b><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>a</span></b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>)When</span></span><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> accessing and using the
pubCenter Programs, You agree to comply with the terms of this Agreement, the
Policies, and all applicable laws and regulations. You may access and use only
the pubCenter Programs for which&nbsp;You&nbsp;have been approved by Microsoft.
Approval for Your access and use of a pubCenter Program is at Microsoft’s sole
discretion and does not provide&nbsp;You&nbsp;with any right to access and use
any other pubCenter Program without separate Microsoft approval. In addition, <span
class=GramE>You</span> agree to access and use the pubCenter Programs,
including any associated tags, APIs, or code, (a) only in accordance with
Microsoft’s technical specifications and (b) only with Your websites (“<b>Websites</b>”),
mobile applications (“<b>Mobile&nbsp;Apps</b>”), Xbox applications (“<b>Xbox
Apps</b>”) and Windows applications (“<b>Win Apps</b>”) that have been approved
by Microsoft and properly registered with the pubCenter Programs. You also
agree to the Microsoft API terms of use at </span><span style='font-size:6.5pt'><a
href="https://learn.microsoft.com/en-us/legal/microsoft-apis/terms-of-use"><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>https://learn.microsoft.com/en-us/legal/microsoft-apis/terms-of-use</span></a></span><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>, if applicable to your access or use.
For the purposes of this Agreement, Websites, Mobile Apps, Xbox Apps, and Win
Apps are referred to collectively as&nbsp;<b>“Properties.</b>”<span
style='mso-spacerun:yes'>  </span></span><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>You acknowledge that Microsoft’s
approval of a <span class=GramE>Property</span> is not a certification or
endorsement of that Property.<span style='mso-spacerun:yes'>  </span>Your
Properties shall <span class=GramE>at all times</span> comply with the
Policies.</span><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><span class=GramE><b><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>b</span></b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>)<u>Advertisers</u></span></span><u><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>’ Right to <span class=SpellE>Opt</span> In or <span class=SpellE>Opt</span>
Out</span></u><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>. Microsoft may provide
advertisers with a list of Properties and publisher names for the purpose of
allowing advertisers to identify certain Properties and publishers on which it
does or does not wish to place its ads.<span style='mso-spacerun:yes'> 
</span>Microsoft will not be required to disclose to You whether any individual
advertiser chose to exercise this right <span class=GramE>with regard to</span>
You or Your Properties.<o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>c</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)</span><span
style='font-size:4.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>&nbsp;</span><u><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Cooperation</span></u><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>.&nbsp;&nbsp;You will provide, via
telephone and e-mail, <span class=GramE>cooperation</span> and assistance that
Microsoft reasonably requests related to customer service, billing and
collections for the advertisers associated with the ads that Microsoft sells
within Your Properties.<o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>d</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)Implementation
Responsibilities.&nbsp;&nbsp;You will:<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;mso-add-space:auto;text-align:justify;
text-justify:inter-ideograph;text-indent:-.5in;line-height:normal;mso-list:
l0 level1 lfo4'><![if !supportLists]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><span style='mso-list:Ignore'>i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>Deploy identifiers or other technologies designated by Microsoft (“<b>Microsoft
Ads SDK</b>”), and </span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>provide information that Microsoft
reasonably requests, in accordance with Microsoft’s technical requirements and <span
class=GramE>instructions<span style='mso-fareast-font-family:"Times New Roman"'>;</span></span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;mso-add-space:auto;text-align:justify;
text-justify:inter-ideograph;text-indent:-.5in;line-height:normal;mso-list:
l0 level1 lfo4'><![if !supportLists]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><span style='mso-list:Ignore'>ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>test&nbsp;on Your Property the deployment of ad formats and browsers
specified by <span class=GramE>Microsoft;</span><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;mso-add-space:auto;text-align:justify;
text-justify:inter-ideograph;text-indent:-.5in;line-height:normal;mso-list:
l0 level1 lfo4'><![if !supportLists]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><span style='mso-list:Ignore'>iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>in&nbsp;a timely manner, make reasonable efforts to cooperate to resolve
problems identified during testing of Your <span class=GramE>Property;</span><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;mso-add-space:auto;text-align:justify;
text-justify:inter-ideograph;text-indent:-.5in;line-height:normal;mso-list:
l0 level1 lfo4'><![if !supportLists]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><span style='mso-list:Ignore'>iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>incorporate into Your Property all guidance, specifications, placement
guidelines, documentation and other consultation related to ads (e.g.,
placement, sizes, experiences, formats, etc.) that Microsoft provides to You
(if any<span class=GramE>);</span><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;mso-add-space:auto;text-align:justify;
text-justify:inter-ideograph;text-indent:-.5in;line-height:normal;mso-list:
l0 level1 lfo4'><![if !supportLists]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><span style='mso-list:Ignore'>v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>cooperate&nbsp;in good faith with Microsoft’s reasonable requests to
optimize and test modifications to the Microsoft Ads SDK to improve performance;
and<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:1.0in;mso-add-space:auto;text-align:justify;
text-justify:inter-ideograph;text-indent:-.5in;line-height:normal;mso-list:
l0 level1 lfo4'><![if !supportLists]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><span style='mso-list:Ignore'>vi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>implement&nbsp;updates that Microsoft makes to the Microsoft Ads SDK
within 60 days after Microsoft makes the update available to You.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><span class=GramE><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>e</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)Traffic</span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> Quality and Ad
Performance.</span><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-spacerun:yes'> 
</span></span><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><o:p></o:p></span></p>

<h4 style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.75in;
mso-list:none'><a name="_Hlk510784399"><span style='font-size:10.0pt;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><o:p>&nbsp;</o:p></span></a></h4>

<h4 style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.75in;
mso-list:l5 level1 lfo3'><span style='mso-bookmark:_Hlk510784399'><![if !supportLists]><span
style='font-size:10.0pt;mso-fareast-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><span
style='mso-list:Ignore'>i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Microsoft may
require that a quality score be maintained for traffic from Your Properties, in
a manner and method determined by Microsoft.<o:p></o:p></span></span></h4>

<h4 style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.75in;
mso-list:l5 level1 lfo3;mso-layout-grid-align:auto'><span style='mso-bookmark:
_Hlk510784399'><![if !supportLists]><span style='font-size:10.0pt;mso-fareast-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'><span style='mso-list:Ignore'>ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='font-size:10.0pt;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>You will manage
traffic quality using methods </span></span><span style='font-size:10.0pt;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>to track each traffic source when made available by Microsoft. You will
maintain traffic quality levels as set by Microsoft.&nbsp; <o:p></o:p></span></h4>

<h4 style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:1.0in;
text-indent:-.5in;mso-list:none;mso-layout-grid-align:auto'><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>iii) <span style='mso-spacerun:yes'>  </span><span
style='mso-spacerun:yes'> </span>Microsoft may warn You or suspend a <span
class=GramE>Property</span> if that Property has fallen below acceptable levels
of quality. If You have received such warning or suspension, <span class=GramE>You</span>
will not directly or indirectly use, distribute, or offer the ad inventory in
your Properties for Microsoft ads through any third party within the Microsoft
Advertising network. You will minimize automated, <span class=GramE>fraudulent</span>
and lower quality traffic.&nbsp; Microsoft will determine the validity and
quality of all traffic in its sole discretion.<o:p></o:p></span></h4>

<h3 style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:
0in;text-indent:0in;mso-pagination:none;mso-list:none'><span
class=Heading3Char><b><span style='font-size:10.0pt;mso-fareast-font-family:
DengXian;mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;mso-bidi-font-style:normal;
text-decoration:none;text-underline:none'>f</span></b></span><span
class=Heading3Char><span style='font-size:10.0pt;mso-fareast-font-family:DengXian;
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;mso-bidi-font-weight:normal;mso-bidi-font-style:
normal;text-decoration:none;text-underline:none'>)</span></span><span
style='font-size:8.0pt;mso-bidi-font-size:9.0pt;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;text-decoration:none;
text-underline:none'> </span><span class=Heading3Char><span style='font-size:
10.0pt;mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;mso-bidi-font-weight:normal;mso-bidi-font-style:normal;text-decoration:
none;text-underline:none'>Performance.</span></span><span style='font-size:
10.0pt;mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;text-decoration:none;text-underline:none'> The parties will monitor the
performance of each Property displaying ads served by a pubCenter Program,
including user acceptance and overall deal performance (e.g., volume,
monetization, and revenue). If either party reasonably believes a <span
class=GramE>Property</span> is performing below standards, the parties will
cooperate and use commercially reasonable efforts to modify the Property to
improve its performance.<span style='mso-spacerun:yes'>  </span>If such efforts
do not improve the Property’s performance to Microsoft’s reasonable
satisfaction, </span><span class=GramE><span style='font-size:10.0pt;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;text-decoration:none;text-underline:none'>You</span></span><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128;text-decoration:none;text-underline:none'> will promptly
remove the poor-quality traffic and You will not directly or indirectly use,
distribute, or offer such ad inventory for Microsoft ads through any third
party within the Microsoft Advertising network.</span><span style='font-size:
10.0pt;mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;text-decoration:none;text-underline:none'><o:p></o:p></span></h3>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:.4in;text-align:justify;text-justify:
inter-ideograph;text-indent:-.4in;line-height:normal'><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>5.&nbsp;GENERAL
PROHIBITIONS.&nbsp;</span></b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>You will not do any of the following:<o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>a</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) work around any
technical limitations of the pubCenter Site or any pubCenter Program or
introduce or use any device, software, or routine that interferes or attempts
to interfere with the operation of the pubCenter Site or any pubCenter Program,
or otherwise attempt to access either of the foregoing in any ways other than
those authorized by <span class=GramE>Microsoft;</span><o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>b</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) use any pubCenter
Program to display advertising anywhere other than on a <span class=GramE>Property</span>
that has been approved by Microsoft and properly registered with the applicable
pubCenter Program;<o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>c</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) cache, store, copy,
distribute, or redirect any advertising delivered by any pubCenter <span
class=GramE>Program;</span><o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>d</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) directly or indirectly </span><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:DengXian;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>implement or
distribute ad inventory, or </span><span style='mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>generate, or authorize or encourage others to generate, </span><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:DengXian;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>revenue hereunder
through any automated, deceptive, fraudulent or other invalid means including
but not limited to repeated manual clicks, the use of robots or other automated
query tools or computer-generated search/page requests, clicks that are
prohibited under any guidelines provided by Microsoft or the terms of this Agreement,
or the unauthorized use of other search engine optimization services or
software,</span><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> or through the use of
incentives (e.g. awarding users cash, points prizes, contest entries, etc.);<o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>e</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) edit, resize, modify,
filter, obscure, hide, make transparent, or reorder any advertising (including
their associated links) delivered by any pubCenter <span class=GramE>Program;</span><o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>f</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) display advertising
from any pubCenter Program in Properties that do not have distinct, substantial
and legitimate content and purpose other than the display of <span class=GramE>advertising;</span><o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>g</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) collect or use any user
identifier created or provided to You by Microsoft for any purpose other than
passing such identifier to a pubCenter Program as part of Your use of that
pubCenter <span class=GramE>Program;</span><o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>h</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) frame, minimize,
remove, redirect, delay (e.g., insert interstitial ads or <span class=SpellE><span
class=GramE>pop up</span></span> windows) or otherwise inhibit or modify any
advertisements served by a pubCenter Program into your Properties or the
display of any web page accessed by the links included with specific
advertising; <o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><span class=SpellE><b><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>i</span></b></span><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>) take&nbsp;any action that imposes an
unreasonably or disproportionately large burden on Microsoft infrastructure,
including the pubCenter Site or a pubCenter <span class=GramE>Program;</span> <o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>j</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) display advertising
from any pubCenter Program on any portion of your Properties that violate any
applicable law, statute, ordinance or regulation (including export control), or
includes materials or links to materials that are unlawful (including the sale
of counterfeit goods or copyright piracy), obscene, pornographic,
discriminatory, misleading, deceptive, false, gambling related or religious,
that constitute hate speech or defamation, or otherwise contain materials that
do not comply with the Policies or other Microsoft policies; or that infringe
on any third party’s proprietary rights;<span style='mso-spacerun:yes'> 
</span><o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>k</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) display advertising on
any portion of your <span class=GramE>Properties</span> that are generated by
adware, spyware or P2P applications; <o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>l</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>) store, distribute, copy
or otherwise use or repurpose data that You receive from Microsoft for any
purpose other than to display ads in accordance with this <span class=GramE>Agreement;</span>
<o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>m</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)&nbsp;display
advertising from any pubCenter Program on any portion of your <span
class=GramE>Properties</span> that would download any type of unauthorized or
unlawful program to a computer or other device, or link a user to a website
intended to download this type of software; or <o:p></o:p></span></p>

<p class=MsoCommentText style='margin-bottom:0in'><b><span style='mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>n</span></b><span style='mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)&nbsp;allow any ad tags
(within ads served by Microsoft) or the Microsoft Ads SDK to (A) be used by a
third party, (B)&nbsp;be placed anywhere other than Your Properties, or (C)&nbsp;display
ads anywhere other than on Your Property.&nbsp;&nbsp;&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>If You engage in any of the activities prohibited by this Section 5 or
otherwise prohibited by Microsoft, or your Property violates this Section 5 or
another Microsoft requirement or the Policies, then without limiting any other
rights and remedies Microsoft may have under this Agreement, Microsoft may, as
Microsoft deems appropriate under the circumstances, do any of the following
including in order to manage advertiser satisfaction issues:&nbsp;&nbsp;credit
advertisers for ad impressions; receive “make goods” from You; withhold or
deduct payments due to You for the time period within which Microsoft
determines, after reasonable investigation, the prohibited action occurred; or
suspend or terminate Your Account.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>6.&nbsp;REPORTS.&nbsp;</span></b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>As part of a pubCenter Program, Microsoft may provide&nbsp;You&nbsp;with
access to online reporting systems to view and use a variety of online reports
related to Your use of the pubCenter Program. You may not publicly publish or
otherwise distribute or disclose any of these reports to third parties and may
only use these reports for&nbsp;Your&nbsp;internal business purposes. These and
similar reports are considered “Confidential Information” as defined in Section
[30].<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>7.&nbsp;ADVERTISER PREFERENCES.&nbsp;</span></b><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>As part of some pubCenter Programs,
Microsoft may provide&nbsp;You&nbsp;with functionality and features that allow
You to specify Your preference regarding the display of advertising from
certain advertisers or groups of advertisers on or in Your Properties. If this
functionality is provided through a pubCenter Program, it will
be&nbsp;Your&nbsp;responsibility to specify and maintain Your preferences.
However, Microsoft does not guarantee that all advertising served on or in Your
Properties will meet or otherwise be subject to the preferences that&nbsp;You&nbsp;specify.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>8.&nbsp;INVOICING AND PAYMENT.&nbsp;<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><span class=GramE><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>a</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)<u>Payments</u></span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>.<span
style='mso-spacerun:yes'>  </span>On a monthly basis, Microsoft will
pay&nbsp;You&nbsp;in arrears on a net-45 basis for advertising displayed on or
in Your Properties based upon the then-current payment schedules if <span
class=GramE>Your</span> earned balance equals or exceeds $100 USD (the “<b>Payment
</b><b style='mso-bidi-font-weight:normal'>Threshold</b>”). If <span
class=GramE>Your</span> earned balance equals less than the Payment Threshold,
Microsoft may elect to defer such payments (without penalty or late fee) to the
next applicable payment date that the payment due is greater than the Payment
Threshold or, if such amount has not increased in the trailing twelve month
period, cancel such payment permanently without notice.&nbsp;Microsoft may
update the timing of the payments, payment schedules, or minimum payment
amounts at any time upon notice.<span style='mso-spacerun:yes'>  </span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
DengXian;mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>If a negative amount is due to You (for example, as result of a prior
period adjustment for invalid Paid Search Ad traffic), Microsoft may, in its
sole discretion, (<span class=SpellE>i</span>) invoice You for the negative
amount, which You will pay within 45 days of receipt, or (ii) offset such
amount against future payments.<span style='mso-spacerun:yes'>  </span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>In addition,
if&nbsp;You&nbsp;receive a payment that was not due to You, Microsoft may
reverse or seek return of that payment and You agree to cooperate with
Microsoft (or its’ Payment Processor) in our efforts to do this. Microsoft may
also reduce or offset&nbsp;Your&nbsp;earned balance without notice, to adjust
for any previous overpayment. You acknowledge that the amount&nbsp;You&nbsp;<span
class=GramE>actually receive</span> will depend in part on the rates and fees
imposed by Your bank or the Payment Processor (as defined herein) and on any
applicable tax withholding requirements by U.S. legislation and the domestic
legislation of Your domicile. You must provide Microsoft (or the Payment
Processor) with all financial, tax and banking information requested <span
class=GramE>in order to</span> make payment of amounts owed under this
Agreement. Microsoft will notify&nbsp;You&nbsp;of any changes to the required
information. Failure to provide such information within 60 days after creation
of Your Account or failure to keep such information current and accurate may
result in the closing of Your Account by Microsoft and forfeiture of amounts
owed to&nbsp;You&nbsp;under this Agreement. <o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><span class=GramE><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>b</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)<u>Data</u></span></span><u><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> for Calculating
Payments; </span></u><u><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Unauthorized or Fraudulent Revenue from
Ads Served by a pubCenter Program</span></u><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:DengXian;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>.<span
style='mso-spacerun:yes'>  </span></span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>To the extent that payment is based on
the number of clicks, impressions, or actions generated by advertising that is
displayed on or in Your Properties, reports generated by the applicable
pubCenter Programs will be the sole measurement for purposes of invoicing and
payment.<span style='mso-spacerun:yes'>  </span>Impressions, clicks, or other
or actions generated by advertising that a pubCenter Program registers as
coming from IP addresses owned or controlled by You, or clicks associated with
Your violation of this Agreement, the Policies or other applicable Microsoft
requirements, are not valid impressions, clicks, or actions.<span
style='mso-spacerun:yes'>  </span>In response to Microsoft’s request,&nbsp;<span
class=GramE>You</span>&nbsp;will provide to Microsoft information relating to
the&nbsp;pubCenter Program for Your Properties, including user IP addresses,
referring URL (HTTP Referrer), user agent, and time of submission (as
applicable).<b>&nbsp;&nbsp;</b></span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:DengXian;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Microsoft will determine whether the
data calculating the number of clicks, impressions, or actions is valid, and
Microsoft will not owe You payment in connection with any data that Microsoft
judges to have been generated through invalid means or in any manner that
violates the Agreement or is otherwise inaccurate.<span
style='mso-spacerun:yes'>  </span></span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>MICROSOFT MAKES NO PROMISES OR
GUARANTEES REGARDING (A) THE NUMBER OF ADVERTISEMENTS YOU MAY EXPECT TO BE
DISPLAYED ON OR IN YOUR PROPERTIES BY A PUBCENTER PROGRAM OR (B) THE AMOUNT OF
ANY PAYMENTS YOU MAY RECEIVE.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>c</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)<u>Currency</u>.<span
style='mso-spacerun:yes'>  </span></span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;mso-bidi-font-weight:
bold'>All amounts owed by Microsoft under this Agreement will be expressed and
paid in the currency chosen by You in your Account; the Account may limit the
choice of currencies available for selection.<span style='mso-spacerun:yes'> 
</span>If amounts owed by Microsoft under this Agreement are described in this
Agreement in one currency and You choose a different currency, then at time of
payment Microsoft will convert its payment amounts to the equivalent amount in
Your chosen currency</span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'> based on Microsoft’s standard
practices. </span><b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><o:p></o:p></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>IF YOU RESIDE IN CHINA</span></b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>, Microsoft may remit payment to&nbsp;You&nbsp;in US dollars only. You are
solely responsible for obtaining the qualifications and/or completing relevant
necessary filings or registrations with the government authorities as required
by the applicable law, <span class=GramE>in order to</span> accept payments in
U.S dollars and convert the payment into&nbsp;Your&nbsp;local currency.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>9.&nbsp;PAYMENT
PROCESSING.&nbsp;<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
class=GramE><b style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>a</span></b><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>)You</span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> may only receive
payments if&nbsp;You&nbsp;reside in one of our supported pubCenter countries that
are qualified to receive payments.&nbsp;<b style='mso-bidi-font-weight:normal'>&nbsp;</b>
<b style='mso-bidi-font-weight:normal'>MICROSOFT TAKES NO RESPONSIBILITY FOR
AND DISCLAIMS ANY OBLIGATIONS TO MAKE PAYMENTS TO PUBLISHERS WHO ARE OPERATING
OUTSIDE OF OUR SUPPORTED COUNTRIES.</b>&nbsp;&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
class=GramE><b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>b</span></b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>)</span><b style='mso-bidi-font-weight:normal'><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Third</span></b></span><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>-Party Payment Processor<span
style='mso-bidi-font-weight:bold'>.<span style='mso-spacerun:yes'>  </span></span></span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>All payments are processed by a Microsoft-designated
third-party payment processor (“<b>Payment Processor</b>”).<span
style='mso-spacerun:yes'>  </span>Microsoft may share information about You
with the Payment Processor and other third parties <span class=GramE>in order
to</span> provide the payments.<span style='mso-spacerun:yes'>    </span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-indent:.5in;line-height:normal'><span
class=SpellE><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>i</span></span><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)<span
style='mso-tab-count:1'>                </span><b>Terms Applicable to Use of
Stripe as Payment Processor</b>.<span style='mso-spacerun:yes'>  </span>Payment
processing services provided by Stripe are subject to the<span
style='background:white'>&nbsp;</span></span><span style='font-size:8.0pt'><a
href="https://stripe.com/connect-account/legal/full"><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;border:none windowtext 1.0pt;
mso-border-alt:none windowtext 0in;padding:0in;background:white;text-decoration:
none;text-underline:none'>Stripe Connected Account Agreement</span></a></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128;background:white'>, </span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>which includes
the<span style='background:white'>&nbsp;</span></span><span style='font-size:
8.0pt'><a href="https://stripe.com/legal"><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;border:none windowtext 1.0pt;
mso-border-alt:none windowtext 0in;padding:0in;background:white;text-decoration:
none;text-underline:none'>Stripe Terms of Service</span></a></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128;background:white'>&nbsp;</span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>(collectively,
the “<b>Stripe Services Agreement</b>”).<span style='mso-spacerun:yes'> 
</span>By agreeing to this Agreement and using Stripe as Payment Processor, <a
name="_Int_WqBpYLpe"></a><span class=GramE><span style='mso-bookmark:_Int_WqBpYLpe'>You</span></span><span
style='mso-bookmark:_Int_WqBpYLpe'></span> agree to be bound by the Stripe
Services Agreement, as the same may be modified by Stripe from time to
time.<span style='mso-spacerun:yes'>  </span>As a condition of Microsoft
enabling payment processing services through Stripe, <span class=GramE>You</span>
agrees to provide Microsoft accurate and complete information about You and its
business, and You authorize Microsoft to share such information and transaction
information related to Your use of the payment processing services provided by
Stripe. </span><span style='font-size:8.0pt;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=OutHead2 style='text-indent:0in;mso-list:none;tab-stops:.5in'><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>ii)<span style='mso-tab-count:1'>              </span><b>Terms
Applicable to Use of Other Payment Processors</b>.<span
style='mso-spacerun:yes'>  </span>Microsoft reserves the right to change or add
Payment Processors at any time in Microsoft’s sole discretion.<span
style='mso-spacerun:yes'>  </span>Your continued engagement in the pubCenter
Program may require Your agreement to additional terms specific to additional
or other Payment Processors. <o:p></o:p></span></p>

<p class=OutHead1 style='margin-left:0in;text-indent:0in;mso-list:none;
tab-stops:.5in'><b style='mso-bidi-font-weight:normal'><span style='font-size:
10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>c)<span style='mso-bidi-font-weight:bold'> </span>Payment
Processor Onboarding<span style='mso-bidi-font-weight:bold'>.<span
style='mso-spacerun:yes'>  </span></span></span></b><span style='font-size:
10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>To enable payments, <a name="_Int_zsklxzjJ"></a><span
class=GramE><span style='mso-bookmark:_Int_zsklxzjJ'>You</span></span><span
style='mso-bookmark:_Int_zsklxzjJ'></span> must have, or obtain, an appropriate
account with a Payment Processor (“<b>Payment Processor Account</b>”), complete
any onboarding requirements the Payment Processor or Microsoft may have, and connect
Your Payment Processor Account with Your pubCenter Account.<span
style='mso-spacerun:yes'>  </span>Microsoft is not responsible for Payment
Processor’s decisions about whether to create or maintain a Payment Processor
Account for You.<span style='mso-spacerun:yes'>  </span>You are solely
responsible for maintaining Your Payment Processor Account, including the
accuracy and completeness of all information related to such account, whether
provided to the Payment Processor or to Microsoft.<span
style='mso-spacerun:yes'>  </span>You acknowledge and understand that the
Payment Processor or Microsoft may, in their sole discretion, suspend or
terminate Your ability to receive payments through Your Payment Processor
Account.<span style='mso-spacerun:yes'>  </span>You must maintain the security
of Your Payment Processor Account and promptly notify Microsoft if You discover
or suspect that someone has accessed Your Payment Processor Account without
permission.<span style='mso-spacerun:yes'>  </span>You are solely responsible
for all activities that occur under or through Your Payment Processor Account
whether such actions were taken by You or not.<span style='mso-spacerun:yes'> 
</span>You authorize Microsoft to take certain actions through Your Payment
Processor Account as permitted under the Agreement and the additional terms You
agree to with the Payment Processor including communicating information about
transactions conducted through the Payment Processor Account such as charges, refunds,
adjustments, and the handling of disputes. <o:p></o:p></span></p>

<p class=OutHead1 style='margin-left:0in;text-indent:0in;mso-list:none;
tab-stops:.5in'><b style='mso-bidi-font-weight:normal'><span style='font-size:
10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>d</span></b><span style='font-size:10.0pt;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;mso-bidi-font-weight:
bold'>)</span><b style='mso-bidi-font-weight:normal'><span style='font-size:
10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>Violation of Payment Processor Agreements<span
style='mso-bidi-font-weight:bold'>.<span style='mso-spacerun:yes'>  </span></span></span></b><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>If Microsoft receives notice that Your activity
violates its agreements with the Payment Processor, Microsoft may in its sole
discretion <span class=GramE>take action</span> against Your account to comply
with Microsoft’s obligations to the Payment Processor.<span
style='mso-spacerun:yes'>  </span>Such actions may include canceling
transactions, disabling Your connection to the Payment Processor, and/or
suspending Your access to or use of the pubCenter Programs. <o:p></o:p></span></p>

<p class=OutHead1 style='margin-left:0in;text-indent:0in;mso-list:none;
tab-stops:.5in'><span class=GramE><b><span style='font-size:10.0pt;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>e</span></b><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>)<b style='mso-bidi-font-weight:normal'>Your</b></span></span><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'> Acknowledgment<span
style='mso-bidi-font-weight:bold'>.<span style='mso-spacerun:yes'>  </span></span></span></b><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>You agree and acknowledge that: <o:p></o:p></span></p>

<p class=OutHead2 style='text-indent:0in;mso-list:none;tab-stops:.5in'><span
class=SpellE><span style='font-size:10.0pt;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>i</span></span><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>)<span style='mso-tab-count:1'>                </span>Notwithstanding
anything to the contrary herein (including the section regarding assignment and
sublicensing), Microsoft will have no responsibility or liability for any
losses or damages relating to or arising out of the Payment Processor’s
services, including but not limited to the receipt or transmission of payments,
losses or damages from misdirected or lost payments, breaches of security,
chargebacks, fraud, theft of funds, violations of banking, money-laundering,
privacy, or any other laws relating to or arising out of the use of the Payment
Processor’s services; <o:p></o:p></span></p>

<p class=OutHead2 style='text-indent:0in;mso-list:none;tab-stops:.5in'><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>ii)<span style='mso-tab-count:1'>              </span>Nothing
herein is intended to limit any rights You may have against the Payment <span
class=GramE>Processor;</span><o:p></o:p></span></p>

<p class=OutHead2 style='text-indent:0in;mso-list:none;tab-stops:.5in'><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>iii)<span style='mso-tab-count:1'>             </span>Designation
of any Payment Processor does not represent an endorsement by Microsoft of the
Payment Processor or its services, and You will rely on Your own diligence <span
class=GramE>with regard to</span> the Payment Processor, and You agree that You
will not state or imply any such endorsement by Microsoft<a
name="_heading=h.ihv636"></a>; and<b style='mso-bidi-font-weight:normal'><o:p></o:p></b></span></p>

<p class=OutHead2 style='margin-bottom:8.0pt;text-indent:0in;mso-list:none;
tab-stops:.5in'><span style='font-size:10.0pt;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>iv)<span
style='mso-tab-count:1'>             </span>Microsoft is not a bank or other
depository institution, payment institution, a money transmitter or a money
services business and does not offer any banking, money transmission or payment
services. <a name="_heading=h.4i7ojhp"></a><a name="_heading=h.1mrcu09"></a><b
style='mso-bidi-font-weight:normal'><o:p></o:p></b></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>10.&nbsp;TAXES.&nbsp;&nbsp;</span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Each party will
bear its own taxes in connection with the transactions under this Agreement,
and all such taxes (including but not limited to net income or gross receipts
taxes, and taxes arising from sales between a party and its customers) will be
the financial responsibility of the party who is obligated by operation of law
to pay such tax.<span style='mso-spacerun:yes'>  </span>Each party will pay to
the other party any sales, use or value added taxes that are owed by that party
solely as a result of entering into this Agreement and which are required to be
collected under applicable law.<span style='mso-spacerun:yes'>  </span>A party
may provide to the other party a valid exemption certificate, in which case the
other party will not collect the taxes covered by such certificate.<span
style='mso-spacerun:yes'>  </span>If any taxes are required to be withheld on
payments made by one party to the other, the paying party will deduct such
taxes from the amount otherwise owed and pay them to the appropriate taxing
authority.<span style='mso-spacerun:yes'>  </span>The paying party will secure
and deliver to the other party an official receipt for those withholdings and
other documents reasonably requested by the other party in order to claim a
foreign tax credit or refund.<span style='mso-spacerun:yes'>  </span>The
parties will use reasonable efforts to ensure that any taxes withheld are
minimized to the extent possible under applicable law.</span><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>11.&nbsp;DATA AND <span
style='mso-bidi-font-weight:bold'>TRADEMARKS.</span>&nbsp;</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>a) </span></b><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Microsoft will collect data
about&nbsp;Your&nbsp;use of a pubCenter Program and its performance in
connection with Your Properties. As between You and Microsoft, Microsoft owns
and controls all data collected in connection with pubCenter Programs or from
Microsoft online properties, apps, and other technology like our tags, pixels,
or other unique tracking codes (“<b>pubCenter Data</b>”). You understand and
acknowledge that Microsoft uses pubCenter Data for its own purposes, including
to (a) generate aggregated reports that may be viewed by Microsoft’s clients
and partners, (b) improve the pubCenter Program and other Microsoft advertising
products and services and optimize their performance and (c) provide campaign
reporting to advertisers whose advertising was displayed on Your Properties or
otherwise related to reporting and performance analysis. In&nbsp;addition, with
respect to the pubCenter Program for Mobile App Developers and the pubCenter
Program for Win App Developers, Microsoft will have the right to, without any
additional consent or approval from You, share with advertisers and partners
(e.g.,&nbsp;&nbsp;ad networks or exchanges) information specific to Your Mobile
Apps and Win Apps including, without limitation, the Mobile App’s or Win App’s
name, Your name as publisher of the Mobile App or Win App, impression data and
targeting availability.<b style='mso-bidi-font-weight:normal'>&nbsp;</b>You
expressly agree to worldwide disclosure and transfer of data described in this
Section 11 to Microsoft without any charge.<span style='mso-spacerun:yes'> 
</span>If Microsoft makes available to&nbsp;You&nbsp;reports or other
information related to Your Properties,&nbsp;upon Microsoft’s request,&nbsp;<span
class=GramE>You</span>&nbsp;will provide screenshots or other representations
of such reports and information to Microsoft, and You agree that Microsoft may
copy, distribute and otherwise use these reports and information solely to
provide and promote the pubCenter Program, and promote Microsoft’s relationship
with You to potential advertisers.<span style='mso-spacerun:yes'> 
</span>Microsoft does not share personal information that may be contained in
pubCenter Data collected <span class=GramE>as a result of</span> Your use of
pubCenter Programs with third parties in any way that identifies individuals
personally. To the extent such pubCenter Data is also personal information, we
collect, use, and disclose such data as described in the Microsoft Privacy
Statement available at https://privacy.microsoft.com/en-us/privacystatement.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>b</span></b><b style='mso-bidi-font-weight:normal'><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>) </span></b><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>You grant to Microsoft a worldwide,
nonexclusive, royalty-free, fully paid-up license to display and otherwise use
Your trademarks and trade names that You provide to Microsoft in writing
(including via email) (the “<b style='mso-bidi-font-weight:normal'>Publisher
Marks</b>”) for the purposes of providing and promoting the&nbsp;pubCenter
Programs.&nbsp;&nbsp;Microsoft will comply with&nbsp;Your&nbsp;trademark usage
guidelines that You provide to Microsoft in writing.&nbsp;&nbsp;All goodwill,
rights, and benefits in the Publisher Marks that arise from Microsoft’s use
under this Agreement will inure solely to&nbsp;You.&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>12.&nbsp;PRIVACY.&nbsp;</span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Nothing in this Agreement provides for
the collection or transfer between&nbsp;You&nbsp;and Microsoft of any
personally identifying&nbsp;information&nbsp;of an end user without the express
consent of the end user. You must maintain and comply with a posted privacy
policy on&nbsp;Your&nbsp;Properties and must use the pubCenter Programs and all
other Microsoft technologies in a manner consistent with this privacy policy.
You assume all liability for the collection, use and disclosure of data related
to users of Your Properties and&nbsp;You&nbsp;will comply with all applicable
laws and regulations in Your collection, use and disclosure of this data.
Microsoft does not intend to share any personally identifying&nbsp;information
under this Agreement with You. However, to the extent that Microsoft does share
any information with You that could be considered “personal information” or
“personal data” under the California Consumer Privacy Act (&quot;<b>CCPA</b>”),
the General Data Protection Regulation (“<b>GDPR</b>”), or any other applicable
privacy law, You are prohibited from: (<span class=SpellE>i</span>) selling or
sharing such personal information unless the consumer is first provided notice
and an opportunity to opt out of the sale or sharing in the United States; or
(ii) retaining, using, or disclosing such personal information for any purpose
other than for the specific purpose intended when it was shared. You will
comply with all applicable obligations under the CCPA, GDPR and other
applicable privacy laws and will provide the level of privacy protection
required of your business. You will notify Microsoft if you determine that you
can no longer comply with this section of the Agreement, and you will maintain
reasonable security procedures and practices appropriate to the nature of the
personal information to protect the personal information from unauthorized or
illegal access, destruction, use, modification, or disclosure. <a
name="_Ref267068822"><o:p></o:p></a></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='mso-bookmark:_Ref267068822'><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>13.</span></b></span><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>&nbsp;CONSUMER
NOTICES.&nbsp;</span></b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>You will maintain a privacy policy, along with consumer privacy notices
that refer to&nbsp;Your&nbsp;privacy policy, that are all prominently visible
on or in Your Properties and <span class=GramE>all of</span> Your websites.
Your privacy policies and notices must be based on then-current laws,
regulations and industry best practices and must include at a minimum: (a) a
full, accurate and clear disclosure regarding the placement, use and reading of
cookies (if applicable) and related technologies, and any collection, sharing
and use of data related to activity by users; (b) Your use of Microsoft and/or
other third party ad providers and a pubCenter Program for the delivery of
advertising and data collection by Microsoft and/or other third party ad
providers in connection with the pubCenter Program; (c)&nbsp;&nbsp;explicitly
obtain affirmative user consent prior to collecting or using user-related
information;&nbsp;and (d) a disclosure that users may choose to not participate
in Microsoft’s or other third-party ad providers’ personalized advertising
services, along with a clear, meaningful and prominent link (such as in the
footer of its website) to Microsoft-specified web address at&nbsp;&nbsp;</span><span
style='font-size:8.0pt'><a href="https://choice.live.com/AdvertisementChoice/"><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>https://choice.live.com/AdvertisementChoice/</span></a></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>&nbsp;or other industry
opt-out tools approved by Microsoft where users may &quot;opt out&quot; of such
personalized advertising services.&nbsp;&nbsp;Currently approved industry
opt-outs include without limitation, www.networkadvertising.org/ or the
Self-Regulatory Program for Behavioral Advertising Consumer Choice opt-out page
located at&nbsp;</span><span style='font-size:8.0pt'><a
href="http://www.aboutads.info/"><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>www.AboutAds.info</span></a></span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>&nbsp;for the US and Canada or&nbsp;</span><span
style='font-size:8.0pt'><a href="http://www.youronlinechoices.eu/"><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>http://www.youronlinechoices.eu/</span></a></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>&nbsp;for Europe, or the
applicable laws and regulations in the People’s Republic of China in this
regard.&nbsp;&nbsp;If at any time after acceptance of these terms specific US
and/or legislation of any country applicable to You or Your Properties provides
for additional legal requirements to Your Properties or relevant activities (e.g.,
data collection), You agree to immediately adjust Your Properties so that they
become compliant with all applicable legislation regardless of any notice from
Microsoft in this regard. You agree that&nbsp;You&nbsp;are solely liable for
complying with <span class=GramE>any and all</span> applicable legislation from
the countries where Your Properties are developed and intended to be made
available or legislation that is otherwise applicable to You.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><a name="_Ref270378698"><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>14.</span></b></a><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>&nbsp;SUPPORT AND FEEDBACK.&nbsp;</span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Microsoft is not obligated to provide
any technical or other support to&nbsp;You&nbsp;for the pubCenter Programs.
If&nbsp;You&nbsp;give feedback about the pubCenter Programs to Microsoft, then
You grant to Microsoft, without charge, the right to use, share, and
commercialize Your feedback in any way and for any purpose. You also grant to third
parties, without charge, any patent rights necessary for their products, <span
class=GramE>technologies</span> and services to use or interface with any
specific parts of a Microsoft software or service that
incorporates&nbsp;Your&nbsp;feedback. You will not give feedback that is subject
to a license that requires Microsoft to license its software or documentation
to third parties because Microsoft includes&nbsp;Your&nbsp;feedback in our
software or documentation. These rights that You grant to Microsoft and third
parties in this Section 14 will survive any termination of this Agreement or
any termination of Your rights to use the pubCenter Programs. In addition,
if&nbsp;You&nbsp;receive any feedback, comments, or complaints from users of
Your Properties about any advertising delivered by the pubCenter Programs, <span
class=GramE>You</span> will promptly forward this information to&nbsp;</span><span
style='font-size:8.0pt'><a href="mailto:pubhelp@microsoft.com"><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>pubhelp@microsoft.com</span></a></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>15.&nbsp;USE OF
THIRD-PARTY ADVERTISING PLATFORMS. </span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span
style='mso-spacerun:yes'> </span>You have the option of authorizing the serving
of ads from third-party advertising serving platforms (e.g., Google AdSense)
with whom you have an account (the “<b style='mso-bidi-font-weight:normal'>Third
Party Platform Program</b>”).<span style='mso-spacerun:yes'>  </span>If you
would like to participate in the <span class=GramE>Third Party</span> Platform
Program, you will need to configure the settings for ad placement in your
Account, supply the requested authentication and authorization, and comply with
the third party platform’s applicable terms and policies (including privacy
rules).<span style='mso-spacerun:yes'>  </span>If you have elected to
participate in the <span class=GramE>Third Party</span> Platform Program, you
will be prompted to log into your account with the third party platform and to
authorize Microsoft to have access to such account and data to facilitate ad
serving and other related activities (e.g., reporting). Microsoft will have no
responsibility or liability for any losses or damages relating to or arising
out of use of the <span class=GramE>Third Party</span> Platform Program or its
functionality in connection with a pubCenter Program.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>16.PUBLICITY/INFORMATION
REQUESTS.</span></b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>&nbsp;You may not cause or permit to be released any publicity,
advertisement, news release, public announcement, or denial or confirmation of
the same, in whatever form, regarding any aspect of this Agreement or the
relationship between&nbsp;You&nbsp;and Microsoft, without Microsoft’s prior
written consent. In addition,&nbsp;<span class=GramE>You</span>&nbsp;may not
use Microsoft’s name, trade name, service marks, trademarks, trade dress or
logo in publicity releases, advertising or similar activities without
Microsoft’s prior written consent and compliance with the Microsoft Trademark
Guidelines.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>17.&nbsp;RESERVATION OF RIGHTS.&nbsp;</span></b><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>Microsoft retains ownership of all
intellectual property rights associated with the pubCenter Programs and the
pubCenter Site, its technology and any enhancements or modifications thereof.
As between Microsoft and You, <span class=GramE>You</span> retain all
intellectual property rights in the contents of Your Properties, other than
such content as Microsoft or its clients may supply. Microsoft’s clients will
retain all right to the advertising displayed on Your Properties.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>18.&nbsp;WARRANTIES.&nbsp;</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>At all times during the
term of this Agreement,<b>&nbsp;</b><span class=GramE>You</span> represent,
warrant and undertake to Microsoft that:<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><![endif]><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><span
style='mso-spacerun:yes'>   </span></span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>You have the power and authority to
enter into this Agreement and to fully perform Your obligations under this <span
class=GramE>Agreement;</span><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>ii)<span
style='font:7.0pt "Times New Roman"'> </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>You are a business or
sole proprietor and not a <span class=GramE>consumer;</span><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>You are authorized to act
on behalf of any third party for which You facilitate participation in the
pubCenter <span class=GramE>Programs;</span><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>iv)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>You have obtained any and
all consents, approvals or licenses (including written consents of third
parties where applicable) required for the display of advertising by a
pubCenter Program on or in Your Properties, including without limitation, the
internet content provider permit, as <span class=GramE>applicable;</span><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>v)<span
style='font:7.0pt "Times New Roman"'> </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Your <span class=GramE>Properties</span>,
including all activities carried on or through Your Properties, comply with all
applicable laws and regulations;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>vi)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Your <span class=GramE>Properties</span>
(including the content available on or through Your Properties) do not
infringe, misappropriate or otherwise violate any third-party intellectual
property right;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>vii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Your <span class=GramE>Properties</span>
do not violate the rights of any person or entity, including, without
limitation, rights of publicity or privacy, and are not defamatory, </span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>misleading, false, libelous, obscene,
unlawful, or injurious to any third party</span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;mso-add-space:auto;text-indent:-.25in;
line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>viii)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Your transfer and
disclosures of data to Microsoft are compliant with all applicable data
protection laws; and<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:.75in;mso-add-space:auto;
text-indent:-.25in;line-height:normal;mso-list:l3 level2 lfo6;tab-stops:45.0pt'><![if !supportLists]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><span style='mso-list:Ignore'>ix)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>The
information&nbsp;You&nbsp;provide to Microsoft under or in connection with this
Agreement is true, accurate, current, and complete and not misleading.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>If the person agreeing to these terms is an individual acting on behalf of
a business or sole proprietorship, such person hereby represents and warrants
that they are authorized to act on behalf of such business or sole
proprietorship and that they are at least the older of 18 years of age or the
age of majority in their applicable jurisdiction.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>19.&nbsp;BINDING
ARBITRATION AND CLASS ACTION WAIVER IF YOUR PRINCIPAL PLACE OF BUSINESS IS IN
THE UNITED STATES.&nbsp;</span></b><strong><span style='font-size:10.0pt;
font-family:"Calibri",sans-serif;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>We hope we never have a dispute, but if we do, You and we agree to try for
60 days to work it out informally. If we can’t, You and we agree to binding
individual arbitration before the American Arbitration Association (“AAA”)
under the Federal Arbitration Act, and not to sue in court in front of a judge
or jury. Instead, a neutral arbitrator will decide. Class action lawsuits,
class-wide arbitrations, private attorney-general actions, and any other
proceeding where someone acts in a representative capacity aren’t allowed. Nor
is combining individual proceedings without the consent of all parties.<span
style='mso-spacerun:yes'>  </span></span></strong><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>This Section applies to any dispute<b
style='mso-bidi-font-weight:normal'>&nbsp;EXCEPT DISPUTES RELATING TO THE
ENFORCEMENT OR VALIDITY OF YOUR, YOUR LICENSORS’, MICROSOFT’S, OR MICROSOFT’S
LICENSORS’ INTELLECTUAL PROPERTY RIGHTS.&nbsp;</b>The term “dispute” means any
dispute, action, or other controversy between&nbsp;You&nbsp;and Microsoft
concerning the pubCenter Programs (including their price) or this Agreement,
whether in contract, warranty, tort, statute, regulation, ordinance, or any
other legal or equitable basis. “Dispute” will be given the broadest possible
meaning allowable under law.</span><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>The complete Arbitration Agreement and
Class Action Waiver contains more terms and is at&nbsp;</span></b><span
style='font-size:8.0pt'><a
href="https://about.ads.microsoft.com/en-us/resources/policies/class-action-waiver-and-binding-arbitration"><span
style='font-size:10.0pt;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:
text1;mso-themetint:128'>https://about.ads.microsoft.com/en-us/resources/policies/class-action-waiver-and-binding-arbitration</span></a></span><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>. You and we agree to it. Please read it.</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><a name="_Ref299921916"><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>20.</span></b></a><b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>&nbsp;DISCLAIMER OF
WARRANTIES.&nbsp;</span></b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>MICROSOFT PROVIDES THE PUBCENTER SITE AND PUBCENTER PROGRAMS
&quot;AS-IS,&quot; &quot;WITH ALL FAULTS,&quot; AND &quot;AS AVAILABLE.&quot;
YOU BEAR THE RISK OF USING THE PUBCENTER SITE AND PUBCENTER PROGRAMS TO DISPLAY
ADVERTISING ON OR IN YOUR PROPERTIES. TO THE MAXIMUM EXTENT PERMITTED BY LOCAL
LAW, MICROSOFT EXCLUDES ANY EXPRESS, <span class=GramE>STATUTORY</span> OR
IMPLIED WARRANTIES OR CONDITIONS, INCLUDING THOSE OF PRODUCT LIABILITY,
MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE
EFFORT, TITLE, AND NON-INFRINGEMENT, RELATING TO THE PUBCENTER SITE AND
PUBCENTER PROGRAMS. </span><strong><span style='font-size:10.0pt;font-family:
"Calibri",sans-serif;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;
border:none windowtext 1.0pt;mso-border-alt:none windowtext 0in;padding:0in;
background:white;font-weight:normal'>YOU MAY HAVE CERTAIN RIGHTS UNDER YOUR
LOCAL LAW. NOTHING IN THESE TERMS IS INTENDED TO AFFECT THOSE RIGHTS, IF THEY
ARE APPLICABLE. </span></strong><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>WITHOUT LIMITING ANY OF THE FOREGOING, </span><strong><span
style='font-size:10.0pt;font-family:"Calibri",sans-serif;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;border:none windowtext 1.0pt;mso-border-alt:
none windowtext 0in;padding:0in;background:white;font-weight:normal;mso-bidi-font-weight:
bold'>YOU ACKNOWLEDGE THAT COMPUTER AND TELECOMMUNICATIONS SYSTEMS ARE NOT
FAULT-FREE AND OCCASIONAL PERIODS OF</span></strong><strong><span
style='font-size:10.0pt;font-family:"Calibri",sans-serif;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;border:none windowtext 1.0pt;mso-border-alt:
none windowtext 0in;padding:0in;background:white;mso-bidi-font-weight:normal'> </span></strong><strong><span
style='font-size:10.0pt;font-family:"Calibri",sans-serif;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;border:none windowtext 1.0pt;mso-border-alt:
none windowtext 0in;padding:0in;background:white;font-weight:normal;mso-bidi-font-weight:
bold'>DOWNTIME OCCUR.</span></strong><strong><span style='font-size:6.5pt;
font-family:"Calibri",sans-serif;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;border:none windowtext 1.0pt;mso-border-alt:none windowtext 0in;padding:
0in;background:white'><span style='mso-spacerun:yes'>  </span></span></strong><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>MICROSOFT EXPRESSLY
DISCLAIMS ANY WARRANTIES OR CONDITIONS THAT ACCESS TO OR USE OF THE PUBCENTER
SITE AND PUBCENTER PROGRAMS WILL BE UNINTERRUPTED, SECURE, OR ERROR FREE.&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal;vertical-align:top'><span lang=EN-AU style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;mso-ansi-language:EN-AU'>IF YOU LIVE IN
AUSTRALIA, there are guarantees that are implied under the Australian Consumer
Law that may apply to the goods and services supplied to&nbsp;You&nbsp;as part
of the <span class=SpellE>pubCenter</span> Programs (the “<b>AU Guarantees</b>”).
Should the AU Guarantees apply to&nbsp;You, then these AU Guarantees are not
included in the disclaimers and exclusions specified by this Section 20. For
those <span class=SpellE>pubCenter</span> Programs that include services
(rather than goods), should Microsoft breach any of the AU Guarantees, <span
class=GramE>Your</span> remedy is limited to receiving the supply of the
service again or the payment of the cost of having the services supplied again.
For those <span class=SpellE>pubCenter</span> Programs that includes goods
(which includes computer software), the goods come with AU Guarantees that
cannot be excluded under the Australian Consumer Law and You are entitled to a
replacement or refund for a major failure and compensation for any other
reasonably foreseeable loss or damage. You are also entitled to have the goods
repaired or replaced if the goods fail to be of acceptable quality and the
failure does not amount to a&nbsp;major failure. In the case of software, the
repair of goods may not be practicable, and a replacement will be provided if
this is the case.&nbsp;For any AU Guarantees related issues, please
contact&nbsp;</span><span style='font-size:8.0pt'><a
href="mailto:pubhelp@microsoft.com"><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>pubhelp@microsoft.com</span></a></span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><a name="_Ref299921012"><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>21.</span></b></a><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>&nbsp;EXCLUSIONS AND LIMITATION OF LIABILITY.&nbsp;</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>TO THE MAXIMUM EXTENT
PERMITTED BY LAW, IN NO EVENT WILL MICROSOFT BE LIABLE TO YOU FOR ANY INDIRECT,
INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT
OF OR THAT RELATE IN ANY WAY TO THIS AGREEMENT OR ITS PERFORMANCE. THIS
EXCLUSION WILL APPLY REGARDLESS OF THE LEGAL THEORY UPON WHICH ANY CLAIM FOR
SUCH DAMAGES IS BASED, WHETHER MICROSOFT HAD BEEN ADVISED OF THE POSSIBILITY OF
SUCH DAMAGES, WHETHER SUCH DAMAGES WERE REASONABLY FORESEEABLE, OR WHETHER
APPLICATION OF THE EXCLUSION CAUSES ANY REMEDY TO FAIL OF ITS ESSENTIAL PURPOSE.
IN ADDITION, MICROSOFT WILL NOT BE LIABLE TO YOU FOR DAMAGES OR LOSSES OF ANY
CLASS OR KIND IN EXCESS OF AMOUNTS REMAINING DUE AND PAYABLE TO YOU UNDER A
PUBCENTER PROGRAM. HOWEVER, NONE OF THE FORGOING LIMITATIONS WILL APPLY TO
MICROSOFT’S INDEMNIFICATION OBLIGATION UNDER SECTION 22 (INDEMNIFCATION).&nbsp;</span><b><span
lang=EN-GB style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;mso-ansi-language:EN-GB'>NOTHING
</span></b><b style='mso-bidi-font-weight:normal'><span lang=EN-GB
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;mso-ansi-language:EN-GB'>IN<span
style='mso-bidi-font-weight:bold'> THIS</span> SECTION 21 WILL AFFECT THE
STATUTORY RIGHTS OF ANY CONSUMER TO EXCLUDE OR RESTRICT LIABILITY FOR DEATH OR
PERSONAL INJURY ARISING FROM MICROSOFT’S NEGLIGENCE, FRAUD, OR GROSS NEGLIGENCE
OR WILLFUL INTENT. SOME OR ALL OF THESE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY
TO YOU IF&nbsp;YOUR&nbsp;STATE, PROVINCE, OR COUNTRY DOES NOT ALLOW THE
EXCLUSION OR LIMITATION OF INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES.</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.4in;text-align:justify;text-justify:inter-ideograph;text-indent:
-.4in;line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>22.&nbsp;INDEMNIFICATION.</span></b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>a</span></b><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>)&nbsp;<b>By You.&nbsp;</b>You will
defend, indemnify and hold Microsoft and Microsoft’s affiliates, agents and
employees, harmless from all losses, damages, liability and expenses (including
reasonable attorneys' fees) from any third-party claims, proceedings or suits
resulting from or related to (<span class=SpellE>i</span>) any improper use by
You or Your Agents of the pubCenter Programs, (ii) any access or use by third
parties of the pubCenter Programs through Your Account or with Your
Credentials; (iii) any improper use of any tags, IDs, code, or the Microsoft
Ads SDK related to the pubCenter Programs that are provided to You, (iv) Your
breach of the Policies, Your warranties or any other term or provision of this
Agreement; (v) Your Properties and (vi) Your collection, use and disclosure of
user related data.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>b</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)&nbsp;<b
style='mso-bidi-font-weight:normal'>By Microsoft.&nbsp;</b>Microsoft will
defend, <span class=GramE>indemnify</span> and hold You harmless from and
against any and all losses, damages, liability and expenses from any
third-party claims, proceedings, or suits that arise out of a claim that the
pubCenter Programs or pubCenter Site, when accessed or used in accordance with
this Agreement and all applicable Microsoft policies and technical
requirements, infringes the intellectual property rights of any third party.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>c</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>)&nbsp;<b
style='mso-bidi-font-weight:normal'>Requirements&nbsp;For&nbsp;Indemnification.&nbsp;</b>In
connection with any claims subject to the indemnification obligations above,
the party receiving indemnification (“<b style='mso-bidi-font-weight:normal'>Indemnified
Party</b>”) will (<span class=SpellE>i</span>) give the other party (“<b
style='mso-bidi-font-weight:normal'>Indemnifying Party</b>”) prompt written
notice of the claim, (ii) cooperate with the Indemnifying Party (at the
Indemnifying Party's expense) in connection with the defense and settlement of
the claim, and (iii) permit the Indemnifying Party (through mutually agreed
counsel) to answer and defend the claim. The Indemnifying Party will not settle
the claim against the Indemnified Party without the Indemnified Party's prior
written consent.<span style='mso-spacerun:yes'>  </span></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'>The Indemnifying Party will not be responsible for any
settlement made by the Indemnified Party without the Indemnifying Party’s
consent.</span><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'> The Indemnified Party (at its cost) may participate in the defense and
settlement of the claim with counsel of its own choosing.&nbsp;<a
name="_Ref298984492"><o:p></o:p></a></span></p>

<p class=MsoCommentText><span style='mso-bookmark:_Ref298984492'><b><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>23.</span></b></span><b><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>&nbsp;TERM AND TERMINATION.&nbsp;</span></b><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>The term of this Agreement will become
effective upon&nbsp;Your&nbsp;access of either the pubCenter Site or a
pubCenter Program and continue until terminated by either You or Microsoft as
provided by this Section 23.<b>&nbsp;</b>You may cancel Your Accounts and
terminate this Agreement at any time by providing written notice to Microsoft.
Microsoft will have the right to terminate or suspend, entirely or in part,
Your use of the pubCenter Site and pubCenter Programs at any time upon notice
with respect to either all or some of Your Properties without cause or
explanation. In addition, if Microsoft reasonably believes that You have
breached this Agreement or that any of Your Properties are in violation of any
applicable laws, regulations or Microsoft policies (whether internal or
external), then Microsoft will have the right to immediately terminate or
suspend, entirely or in part, Your use of the pubCenter Site and pubCenter
Programs with respect to either all or some of Your Properties. Except for
payments due and payable to&nbsp;You&nbsp;under a pubCenter Program, Microsoft
will have no liability to You for any termination or suspension under this
Section 23.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>24.&nbsp;CONTACTS AND
NOTICE.&nbsp;</span></b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>You must identify an individual to serve as the primary contact under this
Agreement. This primary contact will be the default administrator for this
Agreement and will receive all notices unless&nbsp;You&nbsp;change the primary
contact by updating Your Account information through the pubCenter Site.
Microsoft may provide notice to&nbsp;You&nbsp;via email or through the
pubCenter Site. All notices that&nbsp;You&nbsp;provide to Microsoft under this
Agreement must be sent to&nbsp;</span><span style='font-size:8.0pt'><a
href="mailto:pubhelp@microsoft.com"><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>pubhelp@microsoft.com</span></a></span><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>. Microsoft may
disclose&nbsp;Your&nbsp;or Your designated agent’s business contact information
as necessary for Microsoft to administer this Agreement through its affiliates
and other third parties that help Microsoft administer this Agreement.<a
name="_Ref270379359"></a><a name="_Ref270379320"></a><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>25.&nbsp;ASSIGNMENT AND
SUBLICENSE.</span></b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>&nbsp;Microsoft may assign this Agreement to a Microsoft affiliate at any
time. You may not assign this Agreement or any rights or obligations hereunder
without the prior written consent of Microsoft. Microsoft may also delegate its
rights under this Agreement to third parties to assist Microsoft in performing
its obligations under this Agreement, <span class=GramE>provided that</span>
Microsoft will be responsible for the performance of those third parties
subject to the terms of this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>26.&nbsp;VENUE, CHOICE OF
LAW.&nbsp;</span></b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>If you live in (or, if a business, your principal place of business is in)
the United States, the laws of the state where you live (or, if a business,
where your principal place of business is located) govern all claims,
regardless of conflict of laws principles, except that the Federal Arbitration
Act governs all provisions relating to arbitration. If your principal place of
business is in any other country,&nbsp;Nevada, USA law governs, regardless of
conflict of laws principles. You and Microsoft irrevocably consent to the exclusive
jurisdiction and venue of the state or federal courts in King County,
Washington, USA for all disputes arising out of or relating to this Agreement
or a pubCenter Program that are heard in court (not arbitration and not small
claims court).<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>27.&nbsp;SURVIVAL; NON-EXCLUSIVITY.&nbsp;</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>Sections of this
Agreement that, by their terms, require performance after the termination or
expiration of this Agreement will survive. This Agreement is nonexclusive, and
nothing in this Agreement may be construed as restricting Microsoft from
offering the pubCenter Programs or any other Microsoft product or service to
any third party.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>28.</span></b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>&nbsp;<b>UPDATES.&nbsp;</b>Microsoft may update this Agreement at any time
in its sole discretion by providing&nbsp;You&nbsp;with notice of the update (“<b>Update
Notice</b>”). Following Microsoft’s provision to&nbsp;You&nbsp;of an Update
Notice, by continuing to access and use either the pubCenter Site or a
pubCenter Program You agree to be bound by the updated version of this
Agreement referenced in the Update Notice. If&nbsp;You&nbsp;do not agree with
an update, do not continue to <span class=GramE>access</span> and use the
pubCenter Site and pubCenter Programs and immediately notify Microsoft that You
are terminating this Agreement. Your continued access or use of either the
pubCenter Site or a pubCenter Program binds&nbsp;You&nbsp;to any updates to
this Agreement specified in an Update Notice.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>29.</span></b><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>&nbsp;<b>LANGUAGE.&nbsp;</b></span><span lang=EN-CA style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;mso-ansi-language:EN-CA'>It is the
express wish of the parties that this Agreement and all related documents be in
English.&nbsp;</span><span lang=FR-CH style='font-size:10.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;mso-ansi-language:FR-CH'>C’est la volonté expresse des parties que la
présente convention ainsi que les documents qui s’y rattachent soient rédigés
en anglais.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal;mso-pagination:none'><b><span lang=FR-CH style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128;mso-ansi-language:FR-CH'>30.</span></b><span
lang=FR-CH style='font-size:8.0pt;mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;
mso-themetint:128'> </span><b><span style='font-size:4.0pt;mso-ascii-font-family:
Calibri;mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128;text-transform:uppercase'>&nbsp;</span></b><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128;text-transform:uppercase'>Confidentiality</span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>.<span
style='mso-spacerun:yes'>  </span>Neither party will disclose the other’s
Confidential Information to third parties.&nbsp;&nbsp;Each party will use the
other’s Confidential Information only for purposes of the business
relationship. Each party agrees to take reasonable steps to protect the other’s
Confidential Information.&nbsp;&nbsp;A party may disclose the other’s
Confidential Information to its affiliates, <span class=GramE>employees</span>
and contractors only, so long as the disclosing party remains responsible for
any unauthorized use or disclosure. These disclosures may be made only on a
need-to-know basis, subject to the obligations of this Section. “<b>Confidential
Information</b>” means non-public information, know-how and trade secrets in
any form, which is designated as confidential, or a reasonable person
reasonably should understand to be confidential. The following types of
information, however marked, are not confidential information: information that
(<span class=SpellE>i</span>) is, or becomes, publicly available without a
breach of this Agreement; (ii) is lawfully known to the receiver of the information
without an obligation to keep it confidential; (iii) is received from another
source who can disclose it lawfully and without an obligation to keep it
confidential; (iv) is independently developed without reference to the other
party’s Confidential Information; or (v) is a comment or suggestion one party
volunteers about the other’s business, products or services.&nbsp;&nbsp;Each
party may disclose the other’s Confidential Information if required to comply
with a court order or other government demand that has the force of law. Before
doing so, the receiving party must seek the highest level of protection
available and, when possible, give the disclosing party enough prior notice to
provide a reasonable chance to seek a protective order for its Confidential Information.</span><span
style='font-size:8.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal;mso-pagination:none'><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>If You and Microsoft Corporation have
entered into a standard Microsoft Corporation Non-Disclosure Agreement (“<b>NDA</b>”),
then the terms most favorable to Microsoft between this Agreement and the NDA
will prevail. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal;mso-pagination:none'><b><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>31</span></b><span style='font-size:
10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>.<b style='mso-bidi-font-weight:normal'>Export
<span style='mso-bidi-font-weight:bold'>Controls</span></b>. The Parties acknowledge
that the devices, services, software, and related technology (“Items”) may be
subject to U.S. and other countries’ export jurisdictions. Each Party will
comply with all laws and regulations applicable to the import or export of the
Items, including but not limited to trade laws such as the U.S. Export
Administration Regulations and International Traffic in Arms Regulations, and
sanctions regulations administered by the U.S. Office of Foreign Assets Control
(“OFAC”) (“trade laws”). You will not take any action that causes us to violate
U.S. or other applicable trade laws. <span class=GramE>In the event that</span>
you learn of a potential violation of trade laws relating to the performance of
this Agreement, or a potential violation of the terms in this subsection, it
will alert us as soon as possible, but in no event more than 14 days after
acquiring this knowledge. We may suspend or terminate this Agreement to the
extent that we reasonably conclude that performance would cause us to violate
U.S. or other applicable trade laws, including those described above, or put it
at risk of becoming the subject of economic sanctions under such laws.<span
class=normaltextrun><b style='mso-bidi-font-weight:normal'>&nbsp;</b> For
additional information related to exporting, see </span></span><span
style='font-size:8.0pt'><a href="http://www.microsoft.com/exporting"><span
style='font-size:10.0pt;mso-fareast-font-family:Calibri;mso-bidi-font-family:
Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>http://www.microsoft.com/exporting</span></a></span><span
class=normaltextrun><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'>.</span></span><span class=eop><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>&nbsp;</span></b></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'><o:p></o:p></span></p>

<p class=MsoNormal style='line-height:normal;mso-pagination:none;page-break-after:
avoid'><b style='mso-bidi-font-weight:normal'><span style='font-size:10.0pt;
mso-ascii-font-family:Calibri;mso-fareast-font-family:"Times New Roman";
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>32. Anti-Bribery/Anti-Corruption. </span></b><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>You represent and warrant
that you have reviewed and will comply with the Anti-Corruption Policy for Microsoft
Representatives available at </span><span style='font-size:8.0pt'><a
href="http://www.microsoft.com/en-us/Legal/Compliance/anticorruption"><span
style='mso-fareast-font-family:Calibri;mso-bidi-font-family:Calibri;color:#7F7F7F;
mso-themecolor:text1;mso-themetint:128'>http://www.microsoft.com/en-us/Legal/Compliance/anticorruption</span></a></span><span
style='font-size:10.0pt;mso-ascii-font-family:Calibri;mso-fareast-font-family:
"Times New Roman";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
color:#7F7F7F;mso-themecolor:text1;mso-themetint:128'>. You will provide annual
training to your employees who resell, distribute, or market Microsoft products
or services on compliance with Anti-Corruption Laws.&nbsp;&nbsp; You <span
class=GramE>represents</span> and warrants that this Anti-Corruption training
has been provided to its employees and, if not, you agree to participate annually
in online Anti-Corruption training&nbsp;made available free of charge by us and
certify its completion, understanding, and compliance with the Anti-Corruption
Policy for Microsoft Representatives. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-size:10.0pt;mso-ascii-font-family:Calibri;
mso-fareast-font-family:"Times New Roman";mso-hansi-font-family:Calibri;
mso-bidi-font-family:Calibri;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><span style='font-size:8.0pt;line-height:107%'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><span style='font-size:8.0pt;line-height:107%'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><span style='font-size:8.0pt;line-height:107%'><o:p>&nbsp;</o:p></span></p>

</div>

</body>

</html>
`;

// TODO - replace with actual TnC for tython experience once they are ready from business side
export const DEFAULT_TNC = `
  default TnC for Tython Experience
`;
