import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Account,
  AccountProfileFeature,
  EntityLimitTier,
  FeatureOverideTier,
  getAppStore,
  IAddress,
  LifeCycleStatus,
  onNavigateBack,
  onNotificationChanged,
  onSavingChanged,
  PaymentInstrumentLifeCycleStatus,
  PaymentOption,
  postEntity,
  Publisher,
  PublisherCreate,
  PublisherTier,
  ServiceLevel,
} from '../../../../../@data';
import { AdExtensionType } from '../../../../../@data/store/schema/enums/AdExtensionType';
import { FinancialStatus } from '../../../../../@data/store/schema/enums/FinancialStatus';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { countryToCurrencyMap } from '../../../../../config/countryToCurrencyMapper.config';
import { validateSchema } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { createReturnUrl, setPublisherContext } from '../../../@data/service/PartnerManagementService';
import { onFormCleared, onFormCreated, onFormIdChanged, onPublisherCreated, onPublisherErrorChanged } from '../actions';
import { getStore, publisherCreateErrorModel } from '../store';

orchestrator(onFormCreated, async (msg) => {
  const { userContext } = getAppStore();
  const { active } = getStore();

  let notification: INotificationBarItem = {};

  const publisherBody = new PublisherCreate();

  const accountBody = new Account();

  try {
    await validateSchema(publisherCreateErrorModel(), active);
    onPublisherErrorChanged();
  } catch (e) {
    onPublisherErrorChanged(e);
    onNotificationChanged({
      text: `Publisher - You have ${Object.keys(e).length} errors in the form, please fix them to continue.`,
      messageBarType: MessageBarType.severeWarning,
    });
    return;
  }

  onSavingChanged(true);

  const address: IAddress = {
    line1: active.addressLine1,
    line2: active.addressLine2,
    city: active.city,
    country: active.country,
    stateOrProvince: active.state,
    postalCode: active.postalcode,
  };

  publisherBody.partner = active.partner;
  publisherBody.networkAffiliation = active.networkAffiliation;
  publisherBody.address = address;
  publisherBody.name = active.name;

  publisherBody.publisherTier = PublisherTier.ManagedPremium;
  publisherBody.overrideFeatureTier = FeatureOverideTier.ManagedPremium;
  publisherBody.entityLimitTier = EntityLimitTier.Small;
  publisherBody.serviceLevel = ServiceLevel.Premium;
  publisherBody.allowWebAdUnit = true;
  publisherBody.allowNativeReporting = true;
  publisherBody.allowSyndicationReporting = true;
  publisherBody.allowDistributionReporting = false;
  publisherBody.allowDistributionV2Reporting = true;
  publisherBody.allowStartDistributionReporting = false;
  publisherBody.allowStartDistributionContentReporting = false;
  publisherBody.allowSelectTierReporting = false;
  publisherBody.allowMediationReporting = false;
  publisherBody.allowPaidSearchReportingDashboard = true;
  publisherBody.allowPaidSearchReportingApi = false;
  publisherBody.enableDefaultTythonV2Reporting = false;
  publisherBody.propertyAutoApproved = true;
  publisherBody.financialStatus = FinancialStatus.NoHold;

  accountBody.name = active.accountName;
  accountBody.languageCode = active.accountLanguage;
  accountBody.countryCode = active.accountCountry;
  accountBody.timeZoneId = active.accountTimezoneId;
  accountBody.currencyCode = countryToCurrencyMap[active.accountCountry!];
  accountBody.status = LifeCycleStatus.Active;
  accountBody.primaryContactUserId = userContext!.id;
  accountBody.primaryContactUsername = userContext!.email;
  accountBody.enabledFeatures = [AccountProfileFeature.EnableGlobalDefaultKeywords, AccountProfileFeature.EnablePassedKeywords];
  accountBody.adExtensionOptions = [
    {
      type: AdExtensionType.Call,
      enabled: true,
    },
    {
      type: AdExtensionType.SiteLink,
      enabled: true,
    },
    {
      type: AdExtensionType.Location,
      enabled: true,
    },
    {
      type: AdExtensionType.MeteredCall,
      enabled: true,
    },
    {
      type: AdExtensionType.MerchantRatings,
      enabled: true,
    },
  ];
  accountBody.paymentInformation = {
    financialStatus: FinancialStatus.TaxOnHold,
    revenueSharePercentage: 100,
    paymentOption: PaymentOption.MonthlyThreshold,
    paymentInstrumentationLifeCycleStatus: PaymentInstrumentLifeCycleStatus.Validated,
    operationCostRatio: 0,
  };

  try {
    let publisherEntity: PublisherCreate | undefined;
    let publisher: Publisher | undefined;
    let accountEntity: Account | undefined;

    publisherEntity = await postEntity([], PublisherCreate, publisherBody, userContext);

    // NOTE: This action is a no-op for now but future side-effects should be handled by the orchestrator/mutator of this action separately.
    onPublisherCreated(publisherEntity);
    publisher = new Publisher();
    publisher.id = publisherEntity.id;
    accountEntity = await postEntity([publisher], Account, accountBody, userContext);

    if (publisherEntity && publisherEntity.id && accountEntity) {
      onFormIdChanged(publisherEntity.id);
    }
    notification = {
      text: `Created new publisher  success`,
      messageBarType: MessageBarType.success,
    };

    // return to manage page
    setPublisherContext(publisher);
    const returnUrl = createReturnUrl(RouteName.publisher, String(publisherEntity.id));
    onNavigateBack(msg.routeHistory, returnUrl);
    onFormCleared();
  } catch (e) {
    notification = {
      text: `Submitting the publisher form create caught an error "${e}"`,
      messageBarType: MessageBarType.error,
    };
  } finally {
    onSavingChanged(false);
  }

  onNotificationChanged(notification);
});
