import { mutatorAction } from 'satcheljs';
import { AccountStatus, PaymentsPageBalance, PaymentsPageEstimatedEarnings, XPayJwtTokenRequest } from '../../../../../@data';
import { ChartGrain } from '../../Payments/EstimatedEarnings/EstimatedEarnings.types';
import { getAccountStatusData } from '../store/accountStatusStore';
import { getPaymentsPageData } from '../store/paymentsPageStore';
import { getPaymentsPageBalance, getPaymentsPageEstimatedEarnings } from '../store/selectors';

export const setBalanceIsDataLoadedAction = mutatorAction('setBalanceIsDataLoadedAction', (isDataLoaded: boolean) => {
  getPaymentsPageBalance().isDataLoaded = isDataLoaded;
});
export const setEstimatedEarningsIsDataLoadedAction = mutatorAction(
  'setEstimatedEarningsIsDataLoadedAction',
  (isDataLoaded: boolean, grain: ChartGrain) => {
    getPaymentsPageEstimatedEarnings()[grain].isDataLoaded = isDataLoaded;
  }
);
export const setBalanceDataAction = mutatorAction('setBalanceDataAction', (data: PaymentsPageBalance) => {
  getPaymentsPageBalance().data = data;
});
export const setEstimatedEarningsDataAction = mutatorAction(
  'setEstimatedEarningsDataAction',
  (data: PaymentsPageEstimatedEarnings, grain: ChartGrain) => {
    getPaymentsPageEstimatedEarnings()[grain].data = data;
  }
);
export const setAccountStatusDataAction = mutatorAction('setAccountStatusDataAction', (data: AccountStatus) => {
  getAccountStatusData().status = data.status;
});
export const setAccountStatusIsLoading = mutatorAction('setAccountStatusIsLoading', (isLoading: boolean) => {
  getAccountStatusData().isLoading = isLoading;
});

export const setXpayJwtToken = mutatorAction('setXpayJwtToken', (xpayJwtToken: string, xpayJwtTokenRequest: XPayJwtTokenRequest) => {
  getPaymentsPageData().xpayJwtToken = xpayJwtToken;
  getPaymentsPageData().xpayJwtTokenRequest = xpayJwtTokenRequest;
});
export const setXpayJwtTokenExpirationTime = mutatorAction('setXpayJwtTokenExpirationTime', (tokenExpirationTime: number) => {
  getPaymentsPageData().xpayTokenExpirationTimeStamp = tokenExpirationTime;
});
export const setXpayJwtTokenStatusIsLoading = mutatorAction('setXpayJwtTokenStatusIsLoading', (isLoading: boolean) => {
  getPaymentsPageData().xpayJwtTokenStatusIsLoading = isLoading;
});
