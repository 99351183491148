import { ActionButton, Breadcrumb, DefaultButton, getId, mergeStyleSets, Overlay, PrimaryButton, Spinner } from '@fluentui/react';
import * as React from 'react';
import { getAppStore, LayoutMode, onHelpInfoCalloutOpened, onHelpInfoChanged, onLayoutChanged, onNavigateBack } from '../../@data';
import AppMessages from '../../App.messages';
import { FormState, FormTitle } from '../../components/Form';
import { Main } from '../../components/Main/Main';
import { injectIntlAndObserver } from '../../utils';
import messages from './DetailPage.messages';
import { getClassNames } from './DetailPage.styles';
import { IDetailPageProps } from './DetailPage.types';

/**
 * DetailPage is a layout component which is to be used when a form is displayed
 * with submit and cancel buttons. And the page should have a title and a help icon.
 */
export const DetailPage = injectIntlAndObserver(
  class DetailsPageComponent extends React.Component<IDetailPageProps> {
    public componentDidMount() {
      const { pageMode } = this.props;
      if (pageMode !== FormState.InPageCreate && pageMode !== FormState.InPageEdit && pageMode !== FormState.InPageView) {
        onLayoutChanged(LayoutMode.ContentOnly);
      }
    }

    public render(): JSX.Element {
      const {
        titleLabel,
        helpId,
        onSubmit,
        onCancel,
        submitLabel,
        cancelLabel,
        navMessageLabel,
        onNavClick,
        saving,
        loading,
        pageMode,
        submitButtonDisabled,
        hideSubmitButton,
        breadcrumbItems,
        styles,
        hasFormTitle = false,
        hideNavButton = false,
        additionalHeightBoundary,
        overlayHeight = undefined,
      } = this.props;
      const classNames = mergeStyleSets(getClassNames({ pageMode, hasFormTitle, additionalHeightBoundary, overlayHeight }), styles);
      const { root, navBackButton, scrollablePanel, buttons, content, subComponentStyles, overlayStyles } = classNames;
      const { helpInfo, locale } = getAppStore();
      const { formatMessage } = this.props.intl;
      const formTitleId = getId('formTitle');

      const showOverlay = saving || loading;

      return (
        <div className={root}>
          {pageMode !== FormState.InPageCreate &&
            pageMode !== FormState.InPageEdit &&
            pageMode !== FormState.AcceptInvite &&
            pageMode !== FormState.InPageView &&
            !hideNavButton && (
              <div className={navBackButton}>
                {showOverlay && <Overlay />}
                <ActionButton
                  iconProps={{
                    iconName: 'Back',
                  }}
                  onClick={onNavClick ? onNavClick : () => onNavigateBack()}
                  ariaDescription={formatMessage(messages.backButtonAriaDesc)}
                >
                  {navMessageLabel || formatMessage(AppMessages.back)}
                </ActionButton>
                {breadcrumbItems && (
                  <Breadcrumb
                    items={breadcrumbItems}
                    ariaLabel={formatMessage(messages.breadcrumbAriaLabel)}
                    styles={subComponentStyles.breadcrumb}
                  />
                )}
              </div>
            )}
          <Main className={scrollablePanel}>
            {showOverlay && <Overlay />}
            {titleLabel ? (
              <FormTitle
                id={formTitleId}
                title={titleLabel}
                calloutContent={helpInfo}
                iconButtonOnClick={helpId !== undefined ? () => onHelpInfoCalloutOpened(helpId, locale) : undefined}
                calloutOnDismiss={() => onHelpInfoChanged()}
                styles={subComponentStyles.formTitle}
                intl={this.props.intl}
                noHelpPopup={helpId !== undefined ? false : true}
              />
            ) : null}

            <div role="form" aria-labelledby={formTitleId} className={content}>
              {this.props.children}
              {showOverlay && <Overlay className={overlayStyles} />}
            </div>
          </Main>
          <footer className={buttons} aria-label={formatMessage(AppMessages.detailPageFooterArialLabel)}>
            {!hideSubmitButton && <PrimaryButton text={submitLabel} onClick={onSubmit} disabled={saving || submitButtonDisabled} />}

            {pageMode !== FormState.AcceptInvite && onCancel && (
              <DefaultButton
                text={cancelLabel || formatMessage(AppMessages.cancel)}
                onClick={onCancel}
                disabled={saving}
                ariaDescription={formatMessage(messages.backButtonAriaDesc)}
              />
            )}

            {saving && <Spinner label={formatMessage(AppMessages.saving)} labelPosition={'right'} />}
          </footer>
        </div>
      );
    }
  }
);
