import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, IAddress, onNavigateBack, onNotificationChanged, onSavingChanged, Publisher, putEntity } from '../../../../../@data';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { validateSchema } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { createReturnUrl } from '../../../@data/service/PartnerManagementService';
import { onFormCleared, onFormEditted, onPublisherErrorChanged } from '../actions';
import { getStore, publisherEditErrorModel } from '../store';

orchestrator(onFormEditted, async (msg) => {
  const { publisher, userContext } = getAppStore();
  const { active, raw } = getStore();

  let notification: INotificationBarItem = {};

  if (publisher && raw) {
    const body: Publisher = {
      ...raw,
      id: active.id,
      name: active.name,
    };
    try {
      await validateSchema(publisherEditErrorModel(), active);
      onPublisherErrorChanged();
    } catch (e) {
      onPublisherErrorChanged(e);
      onNotificationChanged({
        text: `Publisher - You have ${Object.keys(e).length} errors in the form, please fix them to continue.`,
        messageBarType: MessageBarType.severeWarning,
      });
      return;
    }

    onSavingChanged(true);

    const address: IAddress = {
      ...raw.address,
      line1: active.addressLine1,
      line2: active.addressLine2,
      city: active.city,
      country: active.country,
      stateOrProvince: active.state,
      postalCode: active.postalcode,
    };

    body.partner = active.partner;
    body.networkAffiliation = active.networkAffiliation;
    body.address = address;
    body.publisherTier = active.publisherTier;
    body.overrideFeatureTier = active.overrideFeatureTier;
    body.entityLimitTier = active.entityLimitTier;
    body.serviceLevel = active.serviceLevel;
    body.allowWebAdUnit = active.allowWebAdUnit;
    body.allowMobileSearchAdUnit = active.allowMobileSearchAdUnit;
    body.allowDistributionReporting = active.allowDistributionReporting;
    body.allowDistributionV2Reporting = active.allowDistributionV2Reporting;
    body.allowStartDistributionReporting = active.allowStartDistributionReporting;
    body.allowStartDistributionContentReporting = active.allowStartDistributionContentReporting;
    body.allowEndorReporting = active.allowSelectTierReporting;
    body.allowMediationReporting = active.allowMediationReporting;
    body.allowNativeReporting = active.allowNativeReporting;
    body.allowSyndicationReporting = active.allowSyndicationReporting;
    body.propertyAutoApproved = active.propertyAutoApproved;
    body.allowPaidSearchReportingApi = active.allowPaidSearchReportingApi;
    body.allowPaidSearchReportingDashboard = active.allowPaidSearchReportingDashboard;
    body.allowDynamicPropertyRegistration = active.allowDynamicPropertyRegistration;
    body.enableDefaultTythonV2Reporting = active.enableDefaultTythonV2Reporting;
    body.ipRanges = active.ipAddressGrid;
    body.financialStatus = active.financialStatus;
    body.allowedMarkets = active.allowedMarkets;
    try {
      let entity: Publisher | undefined;

      entity = await putEntity<Publisher>([publisher], body, userContext);

      notification = {
        text: `Edited publisher ${entity.id} success`,
        messageBarType: MessageBarType.success,
      };

      // return to manage page
      const returnUrl = createReturnUrl(RouteName.publisher, String(publisher.id));
      onNavigateBack(msg.routeHistory, returnUrl);
      onFormCleared();
    } catch (e) {
      notification = {
        text: `Submitting the publisher form edit caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
    } finally {
      onSavingChanged(false);
    }
  } else {
    notification = {
      text: `Publisher form edit is invalid id ${active.id}`,
      messageBarType: MessageBarType.warning,
    };
  }
  onNotificationChanged(notification);
});
