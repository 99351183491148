import { mutatorAction } from 'satcheljs';
import { IUseWindowSize, IWindowSize, WindowSizeType } from './index.types';
import { getStore } from './store/store';

export const useWindowSize = (): IUseWindowSize => {
  const windowSizeFormatter = (width?: number): WindowSizeType => {
    if (!width) {
      return undefined;
    } else if (width < 320) {
      return 'extraSmall';
    } else if (width < 640) {
      return 'small';
    } else if (width < 1024) {
      return 'medium';
    }
    return 'regular';
  };

  const onWindowResize = mutatorAction('WindowSize', ({ innerHeight, innerWidth }: Window) => {
    if (window.innerWidth < 320) {
      window.resizeTo(320, window.innerHeight);
    }
    const store = getStore();
    store.height = innerHeight;
    store.width = innerWidth;
  });

  const windowStoreSerializer = (windowSize: IWindowSize) => {
    const windowSizeType = windowSizeFormatter(windowSize.width);
    return {
      ...windowSize,
      isWindowExtraSmallSize: windowSizeType === 'extraSmall',
      isWindowSmallSize: windowSizeType === 'small',
      isWindowMediumSize: windowSizeType === 'medium',
      isWindowRegularSize: windowSizeType === 'regular',
    };
  };

  return {
    onWindowResize,
    windowStoreSerializer,
    windowSizeFormatter,
  };
};
