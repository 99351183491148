export const LOADING_SPINNER = 'loading-spinner';
export const WHITE_PAGE_LOADING_SPINNER = 'white-page-loading-spinner';

export const REPORTING_DATE_RANGE_PICKER = 'reporting-date-range-picker';
export const DATE_RANGE_PICKER_PRESET_CUSTOM = 'date-range-picker-preset-custom';
export const DATE_RANGE_PICKER_PRESET_TODAY = 'date-range-picker-preset-today';
export const DATE_RANGE_PICKER_PRESET_YESTERDAY = 'date-range-picker-preset-yesterday';
export const DATE_RANGE_PICKER_PRESET_THIS_WEEK_SUN_TODAY = 'date-range-preset-this-week-sun-today';
export const DATE_RANGE_PICKER_PRESET_THIS_WEEK_MON_TODAY = 'date-range-preset-this-week-mon-today';
export const DATE_RANGE_PICKER_PRESET_LAST_7_DAYS = 'date-range-picker-preset-last-7-days';
export const DATE_RANGE_PICKER_PRESET_LAST_WEEK_SUN_SAT = 'date-range-picker-preset-last-week-sun-sat';
export const DATE_RANGE_PICKER_APPLY_BUTTON = 'date-range-picker-apply-button';
export const GRAIN_SELECTOR_BUTTON = 'granularity-selector-button';
export const PERFORMANCE_CHART_TYPE_LINE_BUTTON = 'performance-chart-type-line-button';
export const PERFORMANCE_CHART_TYPE_BAR_BUTTON = 'performance-chart-type-bar-button';
export const PERFORMANCE_CHART_TYPE_DONUT_BUTTON = 'performance-chart-type-donut-button';
export const ADUNIT_CHART_TYPE_GRID_BUTTON = 'adunit-chart-type-grid-button';
export const ADUNIT_CHART_TYPE_BAR_BUTTON = 'adunit-chart-type-bar-button';
export const ADUNIT_CHART_TYPE_DONUT_BUTTON = 'adunit-chart-type-donut-button';
export const COUNTRY_CHART_TYPE_MAP_BUTTON = 'country-chart-type-map-button';
export const COUNTRY_CHART_TYPE_BAR_BUTTON = 'country-chart-type-bar-button';
export const COUNTRY_CHART_TYPE_GRID_BUTTON = 'country-chart-type-grid-button';
export const MEDIATION_CHART_TYPE_GRID_BUTTON = 'mediation-chart-type-grid-button';
export const MEDIATION_CHART_TYPE_BAR_BUTTON = 'mediation-chart-type-bar-button';
export const MEDIATION_CHART_TYPE_DONUT_BUTTON = 'mediation-chart-type-donut-button';
export const SITE_CHART_TYPE_BAR_BUTTON = 'site-chart-type-bar-button';
export const SITE_CHART_TYPE_GRID_BUTTON = 'site-chart-type-grid-button';
export const SITE_CHART_TYPE_DONUT_BUTTON = 'site-chart-type-donut-button';
export const CHART_WRAPPER = 'chart-wrapper';
export const CLARITY_BEHAVIORAL_RADIO_BUTTON = 'clarity-behavioral-radio-button';
export const CLARITY_BASIC_RADIO_BUTTON = 'clarity-basic-radio-button';
export const CLARITY_EXISTING_RADIO_BUTTON = 'clarity-existing-radio-button';
export const PROPERTY_NAME_INPUT = 'property-name-input';
export const PROPERTY_DOMAIN_INPUT = 'property-domain-input';
