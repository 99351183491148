import { DirectionalHint, Icon, TooltipHost, getTheme } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CellInfoCallout } from '../../layout/GridPageV2/components/CellInfoCallout';
import { getClassNames } from './styles';

export enum TagType {
  Success = 'success',
  Pending = 'pending',
  Fail = 'fail',
}

export enum TagSize {
  Large,
  Medium,
  Small,
}

export interface ITagCalloutProps {
  placement: DirectionalHint;
  calloutContent: JSX.Element | null;
}

export interface ITagProps {
  status: TagType;
  size?: TagSize;
  text: string;
  tooltipContent?: string;
  icon?: string;
  calloutProps?: ITagCalloutProps;
}

export const Tag = observer(({ text, size = 1, tooltipContent, status, icon, calloutProps }: ITagProps) => {
  const classNames = getClassNames({
    theme: getTheme(),
    size: size,
    hoverAble: tooltipContent !== undefined || calloutProps !== undefined,
  });
  const toolTipId = 'status';

  if (status === undefined) {
    return null;
  }

  const getTagStyle = () => classNames[status.toString()];

  const renderTag = () => {
    return (
      <div aria-describedby={toolTipId} className={getTagStyle()}>
        <span>{text}</span>
        {icon !== undefined && <Icon iconName={icon} />}
      </div>
    );
  };

  const renderTagWithTooltip = () => {
    if (tooltipContent === undefined) {
      return renderTag();
    }

    return (
      <div className={classNames.widthFit}>
        <TooltipHost
          content={tooltipContent}
          id={toolTipId}
          directionalHint={12}
          aria-describedby={tooltipContent}
          aria-label={tooltipContent}
        >
          {renderTag()}
        </TooltipHost>
      </div>
    );
  };

  const renderTagWithCallout = () => {
    if (calloutProps === undefined || calloutProps.calloutContent === null) {
      return renderTag();
    }

    return (
      <CellInfoCallout placement={calloutProps?.placement} calloutContent={calloutProps?.calloutContent}>
        {renderTag()}
      </CellInfoCallout>
    );
  };

  return tooltipContent ? renderTagWithTooltip() : calloutProps ? renderTagWithCallout() : renderTag();
});

export default Tag;
