import { DefaultButton, Image, Link } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { isTythonSelfServeUser } from '../../../@data';
import AppMessages from '../../../App.messages';
import { SupportPageRoute } from '../../../Routes';
import { SUPPORT_EMAIL, TNC_HREF_PATH } from '../../../constants/AppConstants';
import { injectIntlAndObserver } from '../../../utils';
import messages from './SuspendedUser.messages';
import { getClassNames } from './SuspendedUser.styles';
import suspendedUser from './resources/UserSuspended.svg';

const SuspendedUserOverlay = injectIntlAndObserver<InjectedIntlProps>(({ intl: { formatMessage } }) => {
  const { page, container, titleStyle, descriptionStyle } = getClassNames();

  return (
    <div className={page}>
      <div className={container}>
        <Image src={suspendedUser} imageFit={1} shouldFadeIn />
        <div>
          <span className={titleStyle}>{formatMessage(messages.title)}</span>
          <p className={descriptionStyle}>
            {formatMessage(messages.description)}
            <Link target="_blank" href={TNC_HREF_PATH}>
              {formatMessage(messages.termAndConditions)}
            </Link>
          </p>
          <DefaultButton
            href={isTythonSelfServeUser() ? `${SupportPageRoute}` : `mailto:${SUPPORT_EMAIL}`}
            target="_blank"
            rel="noopener noreferrer"
            text={formatMessage(AppMessages.contactSupport)}
          />
        </div>
      </div>
    </div>
  );
});

export default SuspendedUserOverlay;
