import { orchestrator } from 'satcheljs';
import { Account, getEntity } from '..';
import { Log } from '../../../logging/src/Log';
import { onAccountChanged, onAccountSelected, onLoadingChanged } from '../actions';
import { getAppStore } from '../store/store';

orchestrator(onAccountSelected, async (msg) => {
  const userContext = getAppStore().userContext;
  const accountId = parseInt(msg.id, 10);

  if (isNaN(accountId)) {
    return;
  }

  const store = getAppStore();

  // Account can only get selected when a publisher is present.
  if (!store.publisher) {
    return;
  }

  if (store.account && store.account.id === accountId) {
    // The stored account params are the same. No need to reset it.
    return;
  }

  onLoadingChanged(true);

  const account = new Account(accountId);

  try {
    const accountAsync = await getEntity([store.publisher, account], userContext);
    if (accountAsync) {
      onAccountChanged(new Account(accountAsync.id, accountAsync.name));
    } else {
      onAccountChanged(undefined);
    }
  } catch (e) {
    Log.error('TODO: Error Handling for API Requests');
  } finally {
    onLoadingChanged(false);
  }
});
