import React from 'react';
import { IconTooltip } from '../../../../../components/IconTooltip/IconTooltip';
import { getClassNames } from './MenuItemWarningIcon.styles';

export interface IMenuItemWarningIconProps {
  text: string;
  warningMessage: string;
  isIncompatible: Boolean;
}

export const MenuItemWarningIcon = (props: IMenuItemWarningIconProps) => {
  const classNames = getClassNames(props);
  const { warningMessage, isIncompatible, text } = props;
  return (
    <div className={classNames.wrapper}>
      {text}
      {isIncompatible && renderWarningIcon(warningMessage)}
    </div>
  );
};
function renderWarningIcon(message: string): React.ReactNode {
  return <IconTooltip iconName="warning" tooltipMessage={message} />;
}
