import { Model } from './Model';

export class AddressValidation extends Model {
  public apiPath = undefined;
  public className = 'addressValidation';
  public city?: string;
  public country?: string;
  public line1?: string;
  public line2?: string;
  public postalCode?: string;
  public stateOrProvince?: string;
  public isAddressValid?: boolean;
  public isOrgAddressValid?: boolean;
  public isOrg?: boolean;
}
