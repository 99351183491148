import { defineMessages } from 'react-intl';

export default defineMessages({
  feedback: {
    id: 'header.feedback',
    defaultMessage: 'Feedback to {appName}',
  },
  notifications: {
    id: 'header.notifications',
    defaultMessage: 'Notification Center',
  },
  settings: {
    id: 'header.settings',
    defaultMessage: 'Settings',
  },
  help: {
    id: 'header.help',
    defaultMessage: 'Help',
  },
  account: {
    id: 'header.account',
    defaultMessage: 'My account',
  },
  searchPlaceHolder: {
    id: 'globalSearch.placeHolder',
    defaultMessage: 'Search {appName}',
  },
  searchBoxAriaLabel: {
    id: 'globalSearch.SearchBoxAriaLabel',
    defaultMessage: 'Global search box',
  },
  searchResultListAriaLabel: {
    id: 'globalSearch.ResultListAriaLabel',
    defaultMessage: 'Global Search Results',
  },
  viewCategoryResult: {
    id: 'globalSearch.viewCategoryResults',
    defaultMessage: '{category} ({totalCount})',
  },
  viewAllResult: {
    id: 'globalSearch.viewAllResults',
    defaultMessage: 'View all results for {searchQuery}',
  },
  viewNoResult: {
    id: 'globalSearch.viewNoResult',
    defaultMessage: 'No results for {searchQuery}',
  },
  viewCategoryResultsAriaDescription: {
    id: 'globalSearch.viewCategoryResultsAriaDescription',
    defaultMessage: 'Click this to view more results for {categoryName}',
  },
  entityInfoButtonAriaDescription: {
    id: 'globalSearch.entityInfoButtonAriaDescription',
    defaultMessage: 'Click to view entity details',
  },
  footerAriaDescription: {
    id: 'globalSearch.footerAriaDescription',
    defaultMessage: 'Click to view all search results',
  },
});
