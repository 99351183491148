import { getTheme, ITheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../theme';

// NOTE: Keep the style types in the same file where the style definitions are present.
// This helps in better DX (Developer Experience) as we needn't switch files to see what's the type.
export interface ICheckboxGroupStyleProps {
  theme: ITheme;
}

type StyleProperties = 'collapseStyle' | 'expandStyle' | 'expandIconStyle' | 'collapseIconStyle' | 'disabledStyle' | 'commonCheckboxStyle';
export type CheckboxGroupStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICheckboxGroupStyleProps): CheckboxGroupStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing } = theme;

  const commonCollapsibleIconStyle = {
    transition: 'transform 200ms',
    padding: `0 ${spacing.size4}`,
  };

  return mergeStyleSets({
    collapseStyle: {
      display: 'none',
    },
    expandStyle: {
      display: 'block',
      marginLeft: spacing.size24,
    },
    collapseIconStyle: {
      transform: 'rotate(0)',
      ...commonCollapsibleIconStyle,
    },
    expandIconStyle: {
      transform: 'rotate(90deg)',
      ...commonCollapsibleIconStyle,
    },
    commonCheckboxStyle: {
      padding: `${spacing.size8} 0`,
      display: 'flex',
      alignItems: 'center',
    },
    disabledStyle: {
      opacity: 0.6,
    },
  });
};

export function getClassNames<PropType>(props?: PropType): CheckboxGroupStyles {
  return getStyles({ theme: getTheme(), ...props });
}
