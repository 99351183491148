import { defineMessages } from 'react-intl';

export default defineMessages({
  productName: {
    id: 'aboutUs.productName',
    defaultMessage: '{appName}',
  },
  pubCenterNewBrand: {
    id: 'aboutUs.pubCenterNewBrand',
    defaultMessage: 'Microsoft Monetize Now',
  },
  slogan: {
    id: 'aboutUs.slogan',
    defaultMessage: 'Earn more money from your site with Microsoft',
  },
  incentive: {
    id: 'aboutUs.incentive',
    defaultMessage: 'Get more out of your site with high-quality, relevant ads.',
  },
  contactUs: {
    id: 'aboutUs.contactUs',
    defaultMessage: 'Contact us',
  },
  alreadyMember: {
    id: 'aboutUs.alreadyMember',
    defaultMessage: 'Already using {appName}?',
  },
  alreadyMemberNewBrand: {
    id: 'aboutUs.alreadyMemberNewBrand',
    defaultMessage: 'Already using Microsoft Monetize Now?',
  },
  emailAddressLabel: {
    id: 'aboutUs.emailAddressLabel',
    defaultMessage: 'Enter your email address to sign in:',
  },
  signIn: {
    id: 'aboutUs.signIn',
    defaultMessage: 'Sign In',
  },
  signUp: {
    id: 'aboutUs.signUp',
    defaultMessage: 'Sign Up',
  },
  usersToBeQuestion: {
    id: 'aboutUs.usersToBeQuestion',
    defaultMessage: 'Who should get started?',
  },
  usersToBeQuirkOne: {
    id: 'aboutUs.usersToBeQuirkOne',
    defaultMessage: 'Any small publisher or blog looking to build their audience and make it profitable through native ads.',
  },
  usersToBeQuirkTwo: {
    id: 'aboutUs.usersToBeQuirkTwo',
    defaultMessage: 'Sites focused on travel, entertainment, sports, food, finance, auto, community, news, telecom, and others.',
  },
  usersToBeQuirkThree: {
    id: 'aboutUs.usersToBeQuirkThree',
    defaultMessage: 'Publishers with up to 5M page views per month.',
  },
  moneyLabel: {
    id: 'aboutUs.moneyLabel',
    defaultMessage: 'Earn more money',
  },
  moneyDesc: {
    id: 'aboutUs.moneyDesc',
    defaultMessage: 'Serve ads that drive higher engagement and more revenue for you.',
  },
  adsLabel: {
    id: 'aboutUs.adsLabel',
    defaultMessage: 'Show beautiful ads',
  },
  adsDesc: {
    id: 'aboutUs.adsDesc',
    defaultMessage: 'Engage your audience with impactful images that blend naturally with your site experience.',
  },
  timeLabel: {
    id: 'aboutUs.timeLabel',
    defaultMessage: 'Save time',
  },
  timeDesc: {
    id: 'aboutUs.timeDesc',
    defaultMessage: 'Leverage the simple set up and management, so you can focus on what you do best.',
  },
  advantageOneHeader: {
    id: 'aboutUs.advantageOneHeader',
    defaultMessage: 'We make earning money easier, so you can focus on creating great content.',
  },
  advantageOneDescOne: {
    id: 'aboutUs.advantageOneDescOne',
    defaultMessage: 'Deliver ads that match the look and feel of your site and appeal to your audience.',
  },
  advantageOneDescTwo: {
    id: 'aboutUs.advantageOneDescTwo',
    defaultMessage: 'Get the flexibility to control where ads show on the page and block content that doesn’t resonate with your audience.',
  },
  advantageTwoHeader: {
    id: 'aboutUs.advantageTwoHeader',
    defaultMessage: 'Grow with your audience',
  },
  advantageTwoDescOne: {
    id: 'aboutUs.advantageTwoDescOne',
    defaultMessage: 'The more you attract an engaged audience, the more you monetize.',
  },
  advantageTwoDescTwo: {
    id: 'aboutUs.advantageTwoDescTwo',
    defaultMessage: 'And the more you monetize, the more you can invest in growing your audience.',
  },
  startingHeader: {
    id: 'aboutUs.startingHeader',
    defaultMessage: 'Get started easily',
  },
  startingDesc: {
    id: 'aboutUs.startingDesc',
    defaultMessage: 'Start earning with minimal effort on your side.',
  },
  stepOneHeader: {
    id: 'aboutUs.stepOneHeader',
    defaultMessage: 'Get access',
  },
  stepOneDesc: {
    id: 'aboutUs.stepOneHeader',
    defaultMessage: 'Access your account via a custom link (at no cost).',
  },
  stepTwoHeader: {
    id: 'aboutUs.stepTwoHeader',
    defaultMessage: 'Set up your ads',
  },
  stepTwoDesc: {
    id: 'aboutUs.stepTwoDesc',
    defaultMessage: 'Browse our templates and use them to create your ads.',
  },
  stepThreeHeader: {
    id: 'aboutUs.stepThreeHeader',
    defaultMessage: 'Connect your site',
  },
  stepThreeDesc: {
    id: 'aboutUs.stepThreeDesc',
    defaultMessage: `Place one line of code on your page. It's easy and we’ll show you how to do it.`,
  },
  monetizationHeader: {
    id: 'aboutUs.monetizationHeader',
    defaultMessage: `Start monetizing`,
  },
  monetizationDesc: {
    id: 'aboutUs.monetizationDesc',
    defaultMessage: `We’re looking for businesses like yours to help you earn more in fewer steps.`,
  },
  imgBackgroundAlt: {
    id: 'aboutUs.imgBackgroundAlt',
    defaultMessage: 'Background image in about us page sign in section',
  },
  imgMoneyBagAlt: {
    id: 'aboutUs.imgMoneyBagAlt',
    defaultMessage: 'Money bag icon',
  },
  imgAdsAlt: {
    id: 'aboutUs.imgAdsAlt',
    defaultMessage: 'Ads icon',
  },
  imgTimeAlt: {
    id: 'aboutUs.imgTimeAlt',
    defaultMessage: 'Clock icon',
  },
  imgPrimaryOnboardAlt: {
    id: 'aboutUs.imgPrimaryOnboardAlt',
    defaultMessage: 'Example with no image fit value and only width is specified.',
  },
  imgSecondaryOnboardAltOne: {
    id: 'aboutUs.imgSecondaryOnboardAltOne',
    defaultMessage: 'Example with image fit value of 1.',
  },
  imgSecondaryOnboardAltTwo: {
    id: 'aboutUs.imgSecondaryOnboardAltTwo',
    defaultMessage: 'Example with image fit value of 2.',
  },
  imgSecondaryOnboardAltThree: {
    id: 'aboutUs.imgSecondaryOnboardAltThree',
    defaultMessage: 'Example with image fit value of 3.',
  },
  emailInputPlaceholder: {
    id: 'aboutUs.emailInputPlaceholder',
    defaultMessage: 'Enter your email address',
  },
});
