import { MAIN_CONTENT_ID } from '../constants/AppConstants';

export function handleSkipToMainContentClick(): void {
  const mainContentNode = document.getElementById(MAIN_CONTENT_ID);
  if (mainContentNode) {
    mainContentNode.tabIndex = -1;
    mainContentNode.focus();
    mainContentNode.removeAttribute('tabIndex');
  }
}
