import { mixed, object, string } from 'yup';
import { AdSource } from '../../../../../../@data';
import { IBlockItemViewModel } from './IBlockItemViewModel';
import { BlockItemType } from './models';

export function samBlockItemErrorModel() {
  return object<IBlockItemViewModel>().shape({
    selectedMarket: string().when('adSource', {
      is: AdSource.Auction,
      then: string().required(),
    }),
    blockReason: string().required(),
    blockItemType: mixed().oneOf([BlockItemType.Ads, BlockItemType.Images]),
  });
}
