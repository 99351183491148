import { orchestrator } from 'satcheljs';
import { APP_NAME_NEW, PUBCENTER_TITLE } from '../../../../constants/AppConstants';
import { isLocationPubCenter } from '../../../../utils';
import { onCurrentRouteChanged } from '../actions/onAdManagementStoreChanged';
import { getRoutes } from '../services/getAdmanagementRoutes';

orchestrator(onCurrentRouteChanged, ({ currentRoute, formatMessage, intlMessageId }) => {
  const pageName = intlMessageId
    ? formatMessage(intlMessageId)
    : getRoutes(formatMessage).find((route) => route.key === currentRoute)?.text ?? currentRoute.toString();
  const appName = isLocationPubCenter() ? PUBCENTER_TITLE : APP_NAME_NEW;
  document.title = `${appName} - ${pageName}`;
});
