import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { getAppStore, isTythonSelfServeUser } from '../../../../../@data';
import { AdSenseOAuthStatus } from '../../../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { getGoogleAdSenseStore } from '../../../Account/Settings/components/GoogleConnector/@data';
import { onInitializeGoogleAccount as fetchAdSenseAccount } from '../../../Account/Settings/components/GoogleConnector/@data/actions';
import { fetchAdUnitsDataAction } from '../actions';
import { onManagePageInitialized } from '../actions/onManagePageInitialized';
import { setAdUnitsLoading } from '../mutatorActions';
import { getAdunitStore } from '../store/store';

orchestrator(onManagePageInitialized, async () => {
  const { publisher, account, userContext } = getAppStore();
  const { active } = getGoogleAdSenseStore();
  const {
    adUnitsRaw: { data },
  } = getAdunitStore();

  if (publisher && account && userContext) {
    if (data === undefined || data.length === 0) {
      setAdUnitsLoading(true);
    }

    if (isTythonSelfServeUser()) {
      fetchAdSenseAccount(false, false);
      await when(() => {
        const { active: googleAdSenseActive } = getGoogleAdSenseStore();
        return !!googleAdSenseActive.connectionStatus;
      });
    }
    fetchAdUnitsDataAction();

    await when(() => {
      const { adUnitsRaw } = getAdunitStore();
      if (adUnitsRaw.data !== undefined) {
        setAdUnitsLoading(false);
        return true;
      }
      return false;
    });

    if (isTythonSelfServeUser() && active.connectionStatus === AdSenseOAuthStatus.Success) {
      // wait when data inside adUnitRaw is set with the new response data
      await when(() => {
        const { adUnitsRaw } = getAdunitStore();
        return !!adUnitsRaw.data?.[0]?.mediatedWithAdSense;
      });
    }
  }
});
