import { Shimmer } from '@fluentui/react';
import React, { ReactElement } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { useI18n } from '../../../../../../i18n/useI18n';
import { getAppStore } from '../../../../../@data';
import { injectIntlAndObserver } from '../../../../../utils';
import { getStore } from '../../../Account/Settings/@data/store/store';
import { getClassNames } from './AnnouncementCard.styles';
import { getShimmerAnnouncementCard } from './utils/getShimmerAnnouncementCards';
import { dateFormate } from './utils/utils';

export interface IAnnouncementCard {
  title: string;
  description: string;
  date: number;
  button?: ReactElement;
}

export interface IAnnouncementCardProps extends InjectedIntlProps {
  card: IAnnouncementCard;
}

export const AnnouncementCard = injectIntlAndObserver(function AnnouncementCardComponent(props: IAnnouncementCardProps) {
  // need to be loaded from store, is the value of announcement in store is retrieved or not. ex: undefined or not
  const isDataLoaded = false;
  const { publisher, userContext } = getAppStore();
  const { systemPreferences } = getStore().active;
  const { locale } = useI18n({ systemPreferences, publisher, userContext });
  const date = new Date(props.card.date);
  const { containerStyle, contentStyle, headerStyle, dateStyle, descriptionStyle, buttonStyle } = getClassNames();

  return (
    <div className={containerStyle}>
      {!isDataLoaded ? (
        <>
          <div className={contentStyle}>
            <span className={headerStyle}>{props.card.title}</span>
            <span className={dateStyle}>{dateFormate(date, locale)}</span>
            <p className={descriptionStyle}>{props.card.description}</p>
          </div>
          <div className={buttonStyle}>{props.card.button}</div>
        </>
      ) : (
        <Shimmer customElementsGroup={getShimmerAnnouncementCard()} style={{ minHeight: '320px' }} />
      )}
    </div>
  );
});
