import { IDropdownStyles } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../theme';
import { ICreateAccountStyleProps, ICreateAccountStyles } from './CreateAccount.types';

export const getStyles = (props: ICreateAccountStyleProps): ICreateAccountStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    root: {
      marginTop: theme.spacing.m,
      marginLeft: theme.spacing.m,
    },
    datagrid: {
      display: 'flex',
      flexDirection: 'column',
      height: '300px',
      flex: 1,
    },

    subComponentStyles: {
      checkbox: {
        root: {
          marginBottom: spacing.size8,
        },
      },
      dropdown: ({
        dropdown: [
          {
            height: 46,
          },
        ],
      } as unknown) as IDropdownStyles,
    },
  };
};
