import { AuditLog } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import messages from '../ActionDetails.messages';
import { getAddedRemovedCount } from './convertAttributeValues';
import { ActionDetail } from './getActionDetails';

export function getActionDetailsForPublisher(actionDetail: ActionDetail, auditLog: AuditLog, formatMessage) {
  actionDetail.title = `${formatMessage(messages.updated)} ${formatMessage(AppMessages.publisher)} ${formatMessage(messages.details)}`;
  const adblockMessages: string[] = [];

  if (auditLog.changes) {
    actionDetail.changes = [];
    actionDetail.changes = auditLog.changes.map((change) => {
      let attributeChanged = change.attributeChanged;
      if (['ServingStatus', 'UniversalAdBlockStatus'].includes(attributeChanged)) {
        attributeChanged = formatMessage(messages[attributeChanged]);
      }

      if (change.log) {
        actionDetail.title = formatMessage(messages.notificationPreferencesTitle);
        switch (change.attributeChanged) {
          case 'ServingStatusNotification':
            return `${formatMessage(messages.ServingStatusNotification, { status: change.log })}`;
          case 'BillingAlertNotification':
            return `${formatMessage(messages.BillingAlertNotification, { status: change.log })}`;
          case 'InvitedUserNotification':
            return `${formatMessage(messages.InvitedUserNotification, { status: change.log })}`;
          case 'ProfileReviewNotification':
            return `${formatMessage(messages.ProfileReviewNotification, { status: change.log })}`;
          case 'UniversalAdBlockCategories': {
            actionDetail.title = formatMessage(messages.AdblocksUpdated);
            const [addCount, removeCount] = getAddedRemovedCount(change.log);
            if (!isNaN(addCount) && addCount > 0) {
              adblockMessages.push(
                `${addCount} ${formatMessage(
                  addCount > 1 ? messages.UniversalAdBlockCategoriesPlural : messages.UniversalAdBlockCategories
                )} ${formatMessage(messages.added)}`
              );
            }
            if (!isNaN(removeCount) && removeCount > 0) {
              adblockMessages.push(
                `${removeCount} ${formatMessage(
                  removeCount > 1 ? messages.UniversalAdBlockCategoriesPlural : messages.UniversalAdBlockCategories
                )} ${formatMessage(messages.removed)}`
              );
            }
          }
        }
      } else if (!change.oldValue && change.newValue) {
        return `${formatMessage(messages.addedAs, { attributeChanged: attributeChanged, newValue: change.newValue })}`;
      } else if (change.oldValue && !change.newValue) {
        return `${formatMessage(messages.removed)} ${attributeChanged}`;
      } else if (change.oldValue && change.newValue) {
        return `${formatMessage(messages.updatedMessageFormat, {
          attributeChanged: attributeChanged,
          oldValue: change.oldValue,
          newValue: change.newValue,
        })}`;
      }

      return '';
    });
    actionDetail.changes.push(...adblockMessages);
  }
}
