import { getAppStore } from '../../app/@data/store/store';
import { ILogUserContext } from './ILogUserContext';

export class DefaultLogUserContext implements ILogUserContext {
  public publisherId: number;
  public publisherName: string;
  public userName: string;
  public userId: number;
  public userEmail: string;
  public userContext;

  // future enhancements
  public trackingId: string;
  public url: string;

  constructor() {
    const pubObj = getAppStore().publisher;
    if (pubObj !== undefined) {
      this.publisherName = pubObj.name ? JSON.stringify(pubObj.name) : 'undefined';
      this.publisherId = pubObj.id ? pubObj.id : 0;
    }

    this.userContext = getAppStore().userContext;
    this.userName = this.userContext?.name;
    this.userId = this.userContext?.id;
    this.userEmail = this.userContext?.email ?? this.userContext?.username ?? undefined;
  }
}
