import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'globalSearch.id',
    defaultMessage: 'ID'
  },
  name: {
    id: 'globalSearch.name',
    defaultMessage: 'Name'
  },
  createdDate: {
    id: 'globalSearch.createdDate',
    defaultMessage: 'Created date'
  },
  numberOfChannels: {
    id: 'globalSearch.numberOfChannels',
    defaultMessage: 'Channels'
  },
  numberOfExcludedURLs: {
    id: 'globalSearch.numberOfExcludedURLs',
    defaultMessage: 'Excluded URLs'
  },
  numberOfExcludedKeywords: {
    id: 'globalSearch.numberOfExcludedKeywords',
    defaultMessage: 'Excluded keywords'
  },
  numberOfAdunits: {
    id: 'globalSearch.numberOfAdunits',
    defaultMessage: 'AdUnits'
  },
  numberOfAdFilters: {
    id: 'globalSearch.numberOfAdfilters',
    defaultMessage: 'AdFilters'
  },
  lastModifiedDateTime: {
    id: 'globalSearch.lastModifiedDateTime',
    defaultMessage: 'Modified date'
  },
  fullName: {
    id: 'globalSearch.fullName',
    defaultMessage: 'Full name'
  },
  publisherName: {
    id: 'globalSearch.publisherName',
    defaultMessage: 'Publisher name'
  },
  websiteUrl: {
    id: 'globalSearch.websiteUrl',
    defaultMessage: 'Website URL'
  },
  notes: {
    id: 'globalSearch.notes',
    defaultMessage: 'Notes'
  },
  clickableCellAriaDesc: {
    id: 'globalSearch.clickableCellAriaDesc',
    defaultMessage: 'Click to view or edit entity'
  },
  showMoreDescription: {
    id: 'globalSearch.showMoreDescription',
    defaultMessage: 'Click to fetch more results for {entity}'
  }
});
