import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubCenterFonts, IPubcenterPalette, IPubcenterSpacing, IPubcenterTheme } from '../../../../../theme';
import { IFeedbackPanelClassNames } from './FeedbackPanel.types';

export const getClassNames = (): IFeedbackPanelClassNames => {
  const theme = (getTheme() as unknown) as IPubcenterTheme;
  const spacing = theme.spacing as IPubcenterSpacing;
  const palette = theme.palette as IPubcenterPalette;
  const { size12, size16 } = theme.fonts as IPubCenterFonts;
  const WIDTH = '456px';
  const OPTION_WIDTH = '111px';
  return mergeStyleSets({
    root: {
      position: 'relative',
      width: WIDTH,
      maxWidth: WIDTH,
    },
    titleStyle: {},
    descriptionStyle: {},
    checkboxStyle: {},
    emailTextBoxStyle: {
      marginTop: spacing.size8,
    },
    buttonSection: {
      display: 'flex',
      gap: spacing.size8
    },
    button: {
      marginRight: spacing.size2,
    },
    characterCounterStyle: {
      margin: '0',
      textAlign: 'right',
      size: size12,
      lineHeight: size16,
      color: palette.neutralSecondary,
    },
    links: {
      color: palette.themeDarkAlt,
      lineHeight: spacing.size22,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    subComponentStyles: {
      choiceGroup: {
        flexContainer: {
          display: 'flex',
          flexFlow: 'initial',
          alignItems: 'center',
          gap: spacing.size2,
          width: WIDTH,
        },
      },
      choiceGroupOption: {
        root: {},
        choiceFieldWrapper: {
          width: OPTION_WIDTH,
        },
        innerField: {
          width: OPTION_WIDTH,
        },
        labelWrapper: {
          width: OPTION_WIDTH,
          maxWidth: OPTION_WIDTH,
        },
        field: {
          width: OPTION_WIDTH,
        },
      },
    },
  });
};
