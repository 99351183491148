import { mutatorAction } from 'satcheljs';
import { PropertyApprovalStatus } from '../../../../../../../../@data';
import { IHierarchicalPickerItem } from '../../../../../../../../components/HierarchicalMultiSelectPicker';
import { getCreatePropertyStore } from '../../../../__data__/store/store';

export const onApprovalStatusChanged = mutatorAction('onApprovalStatusChanged', (status: PropertyApprovalStatus) => {
  getCreatePropertyStore().active.approvalStatus = status;
});

export const onApprovalStatusCommentsChanged = mutatorAction('onApprovalStatusCommentsChanged', (comments: string) => {
  getCreatePropertyStore().active.approvalStatusComments = comments;
});

export const onAttachedAdunitsChanged = mutatorAction('onAttachedAdunitsChanged', (adunits: IHierarchicalPickerItem[]) => {
  getCreatePropertyStore().active.adunitsSelected = adunits;
});

export const onEnableClarityToggleChanged = mutatorAction('onEnableClarityToggleChanged', (enabled: boolean) => {
  const store = getCreatePropertyStore();
  const clarity = store.active.clarity;
  if (clarity) {
    clarity.clarityLinkingEnabled = enabled;
    if (enabled === false) {
      clarity.clarityId = '';
    } else {
      clarity.clarityId = clarity.originalClarityId?.trim();
    }
  }
});
