import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import { History } from 'history';
import { action, mutatorAction } from 'satcheljs';
import { CustomerHelp } from '../../../../@data/store/schema/enums/CustomerHelp';
import { PublisherType } from '../../../../@data/store/schema/enums/PublisherType';
import { FormState } from '../../../../components/Form';
import { COUNTRY_CODE_CURRENCY_MAPPING } from '../../../../globalization';
import { getSignUpStore } from '../store/store';

export const onFormInitialized = action('onFormInitialized', (mode: FormState) => ({
  mode,
}));

export const onFormSubmitted = action('onFormSubmitted', (routeHistory: History) => ({ routeHistory }));

export const onAccountTypeChanged = mutatorAction('onAccountTypeChanged', (value?: IChoiceGroupOption) => {
  getSignUpStore().active.accountType = value?.key as PublisherType;
});

export const onOrgNameChanged = mutatorAction('onOrgNameChanged', (value?: string) => {
  getSignUpStore().active.organizationName = value;
});

export const onCustomerHelpChanged = mutatorAction('onCustomerHelpChanged', (value?: IChoiceGroupOption) => {
  getSignUpStore().active.customerHelp = value?.key as CustomerHelp;
});

export const onCountrySelectedChanged = mutatorAction('onCountrySelectedChanged', (value: IDropdownOption) => {
  getSignUpStore().active.selectedCountry = value?.key.toString();
});

export const onCountryCodeChanged = mutatorAction('onCountryCodeChanged', (value: IDropdownOption) => {
  getSignUpStore().active.countryCode = value?.key.toString();
  getSignUpStore().active.currencyCode = COUNTRY_CODE_CURRENCY_MAPPING[value.key.toString()];
});

export const onPreferredLanguageChanged = mutatorAction('onPreferredLanguageChanged', (value: IDropdownOption) => {
  getSignUpStore().active.preferredLanguageCode = value.key.toString();
});

export const onCurrencyCodeChanged = mutatorAction('onCurrencyCodeChanged', (value: IDropdownOption) => {
  getSignUpStore().active.currencyCode = value.key.toString();
});

export const onTermsAndConditionsCheckChanged = mutatorAction('onTermsAndConditionsCheckChanged', (value?: boolean) => {
  getSignUpStore().isTermsAndConditionsAgreed = value || false;
});

export const onOptInPromotionalEmailCheckChanged = mutatorAction('onOptInPromotionalEmailCheckChanged', (value?: boolean) => {
  getSignUpStore().optInPromotionalEmail = value || false;
});

export const onUserFirstNameChanged = mutatorAction('onUserFirstNameChanged', (value?: string) => {
  getSignUpStore().active.userFirstName = value;
});

export const onUserLastNameChanged = mutatorAction('onUserLastNameChanged', (value?: string) => {
  getSignUpStore().active.userLastName = value;
});

export const triggerOneVetReview = action('triggerOneVetReview');

export const setFetchingOvdfStatus = mutatorAction('setFetchingOvdfStatus', (value: boolean) => {
  getSignUpStore().fetchingOvdfStatus = value;
});
