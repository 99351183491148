import React from 'react';
import { MAIN_CONTENT_ID } from '../../constants/AppConstants';
import { IMainProps } from './Main.types';

export function Main({ children, className }: IMainProps) {
  return (
    <main id={MAIN_CONTENT_ID} className={className || ''}>
      {children}
    </main>
  );
}
