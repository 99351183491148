import { mergeStyleSets } from '@fluentui/react';
import { IPubcenterSpacing } from '../../../../../../../theme';
import { IClarityPanelStyle, IClarityPanelStyleProps } from './types';

export const getStyles = (props: IClarityPanelStyleProps): IClarityPanelStyle => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return mergeStyleSets({
    panelButton: {
      display: 'flex',
      gap: spacing.size8,
    },
    panelAgreement: {
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.themeLighterAlt,
      fontSize: theme.fonts.small.fontSize,
      padding: `${spacing.size12} ${spacing.size6}`,
      marginTop: spacing.size12,
    },
    panelDescriptor: {
      marginTop: spacing.size12,
      fontSize: theme.fonts.small.fontSize,
    },
    panelPendingResendButton: {
      textDecoration: 'underline',
      color: theme.palette.blue,
      cursor: 'Pointer',
      paddingRight: spacing.size2,
      paddingLeft: spacing.size2,
    },
  });
};
