import { defineMessages } from 'react-intl';

export default defineMessages({
  processNameCreate: {
    id: 'siteCreationProcess.processNameCreate',
    defaultMessage: 'Create site',
  },
  processNameEdit: {
    id: 'siteCreationProcess.processNameEdit',
    defaultMessage: 'Edit site',
  },
  CreateSiteStep: {
    id: 'siteCreationProcess.CreateSiteStep',
    defaultMessage: 'Site details',
  },
  AdsTxtCreationStep: {
    id: 'siteCreationProcess.AdsTxtCreationStep',
    defaultMessage: 'Ads.txt creation',
  },
  GetCodeAndFinishStep: {
    id: 'siteCreationProcess.GetCodeAndFinishStep',
    defaultMessage: 'Get code',
  },
  createPropertyBtnLbl: {
    id: 'siteCreationProcess.createPropertyBtnLbl',
    defaultMessage: 'Create Property',
  },
  createPropertySuccessNotification: {
    id: 'siteCreationProcess.createPropertySuccessNotification',
    defaultMessage: 'You’ve successfully created a new property {entityId}',
  },
  clarityProjectDoesNotExistNotification: {
    id: 'siteCreationProcess.clarityProjectDoesNotExistNotification',
    defaultMessage: 'Clarity project ID does not exist',
  },
  createPropertyFailedNotification: {
    id: 'siteCreationProcess.createPropertyFailedNotification',
    defaultMessage: 'Create properties failed, please try again later.',
  },
});
