import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IAppState, getAppStore, isPublisherRejectionUnappealable } from '../../@data';
import { hasAdQualityAccess } from '../../@data/utils/AdTakeDownService';
import { isTythonSelfServeUser } from '../../@data/utils/checkUserType';
import { IAppProps } from '../../ApplicationRoute';
import { IRoute } from '../../Routes.types';
import { ContentLayout } from '../../layout/ContentLayout/ContentLayout';
import { IMenuProps } from '../../layout/ContentLayout/ContentLayout.types';
import { NullableJSXElement } from '../../types';
import { injectRouterAndIntlWithObserver } from '../../utils';
import { getTythonHomePageRoute, getTythonProfilePageRoute } from '../../utils/routeUtils';
import SuspendedUserOverlay from '../error-pages/SuspendedUser/SuspendedUser';
import { PUBLISHER_BASED_ROUTES, RouteName } from './@data';
import { fetchPublisherEvaluationStatusAction } from './@data/actions';
import { getRoutes } from './@data/services/getAdmanagementRoutes';
import { UserSettingsPage } from './Account/Settings/UserSettingsPage';
import { AdManagementHeader } from './AdManagementHeader';
import { AuditHistoryPage } from './AuditHistory/AuditHistory';
import { IndexPage } from './Index/IndexPage';
import { editProfileAction } from './Profile/@data/actions';
import { renderCustomNotifications } from './renderPartials';

function getDefaultRoute(): string {
  return RouteName[isTythonSelfServeUser() ? RouteName.home : RouteName.adunit];
}

/**
 * The router will make sure /publisher-account/pageName is valid, otherwise it will
 * make sure it keeps the state consistent with /pagaeName
 */
export const AdManagementRouter = injectRouterAndIntlWithObserver(
  class AdManagementRouterComponent extends React.Component<IAppProps> {
    private _renderCustomNotifications: () => NullableJSXElement;

    constructor(props: IAppProps) {
      super(props);
      this._renderCustomNotifications = renderCustomNotifications.bind(this);
      this._handleProfileEditButtonClick = this._handleProfileEditButtonClick.bind(this);
    }

    public componentDidMount(): void {
      if (isTythonSelfServeUser() && this.props.location.pathname !== getTythonHomePageRoute()) {
        fetchPublisherEvaluationStatusAction();
      }
    }

    public render(): JSX.Element {
      const appStore = getAppStore();
      const menuProps: IMenuProps = {
        ...this.props,
        routes: this._generateMenuRoutes(appStore),
      };
      const {
        intl: { formatMessage },
      } = this.props;

      return (
        <ContentLayout
          {...this.props}
          menuProps={menuProps}
          renderCustomNotifications={this._renderCustomNotifications}
          onRenderHeader={this._renderHeader}
        >
          <Redirect exact path={this.props.match.url} to={`${this.props.match.url}/${getDefaultRoute()}`} />
          {isPublisherRejectionUnappealable() ? (
            <SuspendedUserOverlay />
          ) : (
            <>
              {getRoutes(formatMessage).map((route: IRoute, index: number) => (
                <Route
                  key={index}
                  path={[
                    `${this.props.match.url}/${route.key}`,
                    `${this.props.match.url}/:publisherId-:accountId/${route.key}`,
                    `${this.props.match.url}/:publisherId/${route.key}`,
                  ]}
                  component={route.component}
                />
              ))}
              <Route
                key="userSettings"
                path={`${this.props.match.url}/:publisherId/${RouteName[RouteName.userSettings]}`}
                component={UserSettingsPage}
              />
              <Route
                key="auditHistory"
                path={[
                  `${this.props.match.url}/:publisherId/${RouteName[RouteName.auditHistory]}`,
                  `${this.props.match.url}/${RouteName[RouteName.auditHistory]}`,
                ]}
                component={AuditHistoryPage}
              />
            </>
          )}
          <Route key="catch-all" path={`${this.props.match.url}/:id`} component={IndexPage} />
        </ContentLayout>
      );
    }

    private _handleProfileEditButtonClick() {
      editProfileAction();
      this.props.history.push(getTythonProfilePageRoute());
    }

    private _generateMenuRoutes(store: IAppState) {
      let keyFnc: (key: string) => string;

      const {
        intl: { formatMessage },
      } = this.props;

      if (store.publisher && store.account && store.publisher.id && store.account.id) {
        const publisherId = store.publisher.id.toString();
        const accountId = store.account.id.toString();
        keyFnc = (key) => {
          if (PUBLISHER_BASED_ROUTES.includes(key as RouteName)) {
            return `/${publisherId}/${key}`;
          }
          return `/${publisherId}-${accountId}/${key}`;
        };
      } else if (store.publisher && store.publisher.id) {
        const publisherId = store.publisher.id.toString();
        keyFnc = (key) => `/${publisherId}/${key}`;
      } else {
        keyFnc = (key) => `/${key}`;
      }

      if (isTythonSelfServeUser()) {
        return getRoutes(formatMessage)
          .filter((route) => ![RouteName.adquality, RouteName.adfilter, RouteName.channel].includes(route.key as RouteName))
          .map(
            (route) =>
              ({
                key: keyFnc(route.key),
                text: route.text,
                icon: route.icon,
                disabled: route.disabled,
              } as IRoute)
          );
      }

      if (!store.publisher || (store.publisher && !hasAdQualityAccess(store.publisher.id))) {
        return getRoutes(formatMessage)
          .filter((route) => route.key !== RouteName.adquality)
          .map(
            (route) =>
              ({
                key: keyFnc(route.key),
                text: route.text,
                icon: route.icon,
                disabled: route.disabled,
              } as IRoute)
          );
      }
      // Update the menu routes according to whether the publisher/account were specified.

      return getRoutes(formatMessage).map(
        (route) =>
          ({
            key: keyFnc(route.key),
            text: route.text,
            icon: route.icon,
            disabled: route.disabled,
          } as IRoute)
      );
    }

    private _renderHeader = (): JSX.Element => {
      // Support non pub/acc urls as well. When nothing matched, go back to home to do the default route.
      return (
        <React.Fragment>
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/${RouteName.adquality}`}
              render={(props) => <AdManagementHeader {...props} isAccountHidden={true} />}
            />

            <Route
              exact
              path={`${this.props.match.url}/${RouteName.property}`}
              render={(props) => <AdManagementHeader {...props} isAccountHidden={true} />}
            />

            <Route exact path={`${this.props.match.url}/:publisherId/${RouteName.adunit}`} component={AdManagementHeader} />

            <Route exact path={`${this.props.match.url}/:id?`} component={AdManagementHeader} />
            <Route path={`${this.props.match.url}/:publisherId-:accountId`} component={AdManagementHeader} />

            <Route
              path={`${this.props.match.url}/:publisherId`}
              render={(props) => <AdManagementHeader {...props} isAccountHidden={true} />}
            />

            <Route path="*" render={() => <Redirect to={this.props.match.url} />} />
          </Switch>
        </React.Fragment>
      );
    };
  }
);
