import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  IPanelProps,
  Link,
  mergeStyleSets,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Log } from '../../../../../logging/src';
import { isTythonSelfServeUser } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { FormItem } from '../../../../components/Form';
import { APP_NAME_NEW, APP_NAME_PUBCENTER, PRIVACY_STATEMENT, SUPPORT_EMAIL } from '../../../../constants/AppConstants';
import { connectToSupportTeam } from '../../../../pages/ad-management/Home/components/ResourcesSection/ResourcesSection';
import { injectIntlAndObserver } from '../../../../utils';
import { getFeedbackPanelStore } from './@data';
import { onFormSubmitted } from './@data/actions';
import {
  onFeedbackCheckboxChanged,
  onFeedbackDescriptionChanged,
  onFeedbackEmailChanged,
  onFeedbackTitleChanged,
  onFeedbackTypeChanged,
  onFormCleared,
  removeEmail,
} from './@data/mutatorActions';
import { FeedbackType } from './@data/store/schema/enums/FeedbackType';
import messages from './FeedbackPanel.messages';
import { getClassNames } from './FeedbackPanel.styles';
import { IFeedbackPanelProps } from './FeedbackPanel.types';

export const renderFooterContent = (panelProps: IPanelProps) => <FeedbackPanelFooterButtons {...panelProps} />;

export const FeedbackPanelFooterButtons = observer(function (panelProps: IPanelProps) {
  const { button, buttonSection } = mergeStyleSets(getClassNames());
  const { active, isContactMeChecked } = getFeedbackPanelStore();
  const { title, description, email } = active;

  const onCancelClicked = () => {
    panelProps.onDismiss?.();
    onFormCleared();
  };

  const onSubmit = (): void => {
    if (!isContactMeChecked && active.email) {
      // remove email from active since it wont be validated because isContactMeChecked is false, to not to be sent to the BE
      removeEmail();
    }
    onFormSubmitted(panelProps.onDismiss);
    Log.debug('Feedback form submitted');
  };

  return (
    <div className={buttonSection}>
      <PrimaryButton className={button} onClick={() => onSubmit()} disabled={!title || !description || (isContactMeChecked && !email)}>
        <FormattedMessage {...AppMessages.submit} />
      </PrimaryButton>
      <DefaultButton onClick={() => onCancelClicked()}>
        <FormattedMessage {...AppMessages.cancel} />
      </DefaultButton>
    </div>
  );
});

export const FeedbackPanel = injectIntlAndObserver<IFeedbackPanelProps>(function FeedbackPanelComponent(
  props: IFeedbackPanelProps
): JSX.Element | null {
  const { formatMessage } = props.intl;
  const {
    root,
    titleStyle,
    descriptionStyle,
    characterCounterStyle,
    checkboxStyle,
    emailTextBoxStyle,
    links,
    subComponentStyles,
  } = mergeStyleSets(getClassNames());
  const store = getFeedbackPanelStore();
  const TITLE_LIMIT = 250;
  const DESCRIPTION_LIMIT = 2000;
  const FORM_ITEM_WIDTH = 456;

  const feedbackTypes: IChoiceGroupOption[] = [
    {
      key: FeedbackType.Appreciation,
      text: formatMessage(messages.feedbackTypeAppreciation),
      iconProps: { iconName: 'Emoji2' },
      styles: subComponentStyles.choiceGroupOption,
    },
    {
      key: FeedbackType.FeatureRequest,
      text: formatMessage(messages.feedbackTypeFeatureRequest),
      iconProps: { iconName: 'ClipboardList' },
      styles: subComponentStyles.choiceGroupOption,
    },
    {
      key: FeedbackType.BugReport,
      text: formatMessage(messages.feedbackTypeBugReport),
      iconProps: { iconName: 'Bug' },
      styles: subComponentStyles.choiceGroupOption,
    },
    {
      key: FeedbackType.Others,
      text: formatMessage(messages.feedbackTypeOther),
      iconProps: { iconName: 'CommentAdd' },
      styles: subComponentStyles.choiceGroupOption,
    },
  ];

  const checkedStateChanged = () => {
    onFeedbackCheckboxChanged(!store.isContactMeChecked);
  };

  return (
    <div className={root}>
      <p>
        {formatMessage(messages.feedbackSupport, { appName: isTythonSelfServeUser() ? APP_NAME_NEW : APP_NAME_PUBCENTER })}
        <Link
          className={links}
          onClick={isTythonSelfServeUser() ? () => connectToSupportTeam() : () => null}
          href={isTythonSelfServeUser() ? undefined : `mailto:${SUPPORT_EMAIL}`}
          aria-label={formatMessage(messages.feedbackContactSupport)}
        >
          {formatMessage(messages.feedbackContactSupport)}
        </Link>
      </p>

      <FormItem formWidth={FORM_ITEM_WIDTH}>
        <ChoiceGroup
          label={formatMessage(messages.feedbackType)}
          required
          options={feedbackTypes}
          onChange={(_, value) => onFeedbackTypeChanged(value)}
          selectedKey={store.active.type || FeedbackType.Appreciation}
          styles={subComponentStyles.choiceGroup}
        />
      </FormItem>

      <div className={titleStyle}>
        <FormItem formWidth={FORM_ITEM_WIDTH}>
          <TextField
            label={formatMessage(messages.feedbackTitle)}
            required
            placeholder={formatMessage(messages.feedbackTitlePlaceholder)}
            value={store.active.title || ''}
            maxLength={TITLE_LIMIT}
            onChange={(ev, value) => {
              onFeedbackTitleChanged(value);
            }}
            errorMessage={store.errors.title}
          />
          <p id="titleCounterLabel" className={characterCounterStyle}>
            {formatMessage(messages.feedbackCharacterCounter, {
              currentCounter: store.active.title ? store.active.title.length : 0,
              limit: TITLE_LIMIT,
            })}
          </p>
        </FormItem>
      </div>
      <div className={descriptionStyle}>
        <FormItem formWidth={FORM_ITEM_WIDTH}>
          <TextField
            label={formatMessage(messages.feedbackTellUsMore)}
            required
            multiline
            rows={4}
            maxLength={DESCRIPTION_LIMIT}
            placeholder={formatMessage(messages.feedbackTellUsMorePlaceholder)}
            value={store.active.description || ''}
            onChange={(ev, value) => {
              onFeedbackDescriptionChanged(value);
            }}
            errorMessage={store.errors.description}
          />
          <div>
            <p id="descriptionCounterLabel" className={characterCounterStyle}>
              {formatMessage(messages.feedbackCharacterCounter, {
                currentCounter: store.active.description ? store.active.description.length : 0,
                limit: DESCRIPTION_LIMIT,
              })}
            </p>
          </div>
        </FormItem>
      </div>
      <div className={checkboxStyle}>
        <Checkbox label={formatMessage(messages.feedbackAllowContact)} checked={store.isContactMeChecked} onChange={checkedStateChanged} />
        {store.isContactMeChecked && (
          <TextField
            className={emailTextBoxStyle}
            aria-label={formatMessage(messages.feedbackAllowContact)}
            placeholder={formatMessage(messages.feedbackAllowContactPlaceholder)}
            onChange={(ev, value) => onFeedbackEmailChanged(value)}
            errorMessage={store.errors.email}
            value={store.active.email}
          />
        )}
      </div>
      <div>
        <p>
          {formatMessage(messages.feedbackPrivacyStatement)}
          <a
            href={PRIVACY_STATEMENT}
            target="_blank"
            className={links}
            aria-label={formatMessage(messages.feedbackMPS)}
            rel="noopener noreferrer"
          >
            {formatMessage(messages.feedbackMPS)}
          </a>
        </p>
      </div>
      <div>
        <FormattedMessage
          {...messages.feedbackDataCollection}
          values={{
            supportTeamPlaceholder: (
              <Link
                className={links}
                onClick={
                  isTythonSelfServeUser()
                    ? () => connectToSupportTeam(`subject=${formatMessage(messages.deleteFeedbackDataFormSubject)}`)
                    : () => null
                }
                href={isTythonSelfServeUser() ? undefined : `mailto:${SUPPORT_EMAIL}`}
              >
                {formatMessage(messages.supportTeamPlaceholder)}
              </Link>
            ),
          }}
        />
      </div>
    </div>
  );
});
