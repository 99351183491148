import { array } from 'yup';

/**
 * Schema which transforms a Mobx observable array to normal for validation.
 */
export class ArrayObservableSchemaYupType extends array {
  constructor() {
    super();

    this.withMutation(() => {
      this.transform(function(value, originalvalue) {
        if (this.isType(value)) {
          return value;
        }

        // const array = new Array(originalvalue.length);
        return [...originalvalue];
      });
    });
  }
}
