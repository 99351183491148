import * as React from 'react';
import { useEffect, useState } from 'react';
import { AdUnit, InventoryPlatformType, getAppStore } from '../../../../@data';
import { isTythonSelfServeUser } from '../../../../@data/utils/checkUserType';
import { FormState } from '../../../../components/Form';
import { ZeroResults } from '../../../../components/ZeroResults/ZeroResults';
import { useWindowSize } from '../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../hooks/useWindowSize/store/store';
import { GridPageLayout } from '../../../../layout/GridPageLayout/GridPageLayout';
import { IGridV2Column } from '../../../../layout/GridPageV2';
import { GridV2 } from '../../../../layout/GridPageV2/GridV2';
import { IRouterWithIntl, injectIntlAndObserver } from '../../../../utils/ReactUtils';
import { RouteName } from '../../@data';
import { createReturnUrl } from '../../@data/services/AdManagementService';
import { getGridPropertyStore } from '../../Property/Manage/__data__/store/store';
import { getAdunitStore } from '../@data';
import { onManagePageInitialized } from '../@data/actions/onManagePageInitialized';
import { setAdUnitsData } from '../@data/mutatorActions';
import messages from './ManagePage.messages';
import { AdUnitCallout } from './components/AdUnitNameCallout/AdUnitNameCallout';
import { CodePanel } from './components/CodePanel/CodePanel';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

export interface IManagePageProps extends IRouterWithIntl {
  withParentLayout?: boolean;
  abortDataFetch?: boolean;
}

const { windowStoreSerializer } = useWindowSize();

export const ManagePage = injectIntlAndObserver((props: IManagePageProps) => {
  const { history, match, withParentLayout, abortDataFetch } = props;
  const tythonUser = isTythonSelfServeUser();
  const { publisher, account } = getAppStore();
  const { adUnitsRaw } = getAdunitStore();
  const { formatMessage } = props.intl;
  const { isWindowRegularSize, isWindowExtraSmallSize } = windowStoreSerializer(getWindowSizeStore());
  const [visibleCallOut, setVisibleCallOut] = useState<number>(0);
  const gridDefaultColumns: IGridV2Column[] = getGridColumns(
    formatMessage,
    history,
    isWindowRegularSize,
    setVisibleCallOut,
    getAdunitStore().isFetchingAdUnitStatus,
    getGridPropertyStore().isFetchingPropertyStatus,
    undefined // Added this for tython manage page that will enable in future
  );

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={tythonUser || (!tythonUser && !!publisher && !!account) ? formatMessage(messages.zeroAdUnitsTitle) : undefined}
      description={formatMessage(
        tythonUser || (!tythonUser && publisher && account) ? messages.zeroAdUnitsDescription : messages.noPublisherSelected
      )}
      buttonText={formatMessage(messages.zeroAdUnitsCallToAction)}
      onButtonClick={
        tythonUser || (!tythonUser && !!publisher && !!account)
          ? () => {
              const returnUrl = createReturnUrl(
                RouteName.adunit,
                publisher!.id,
                account!.id,
                undefined,
                isTythonSelfServeUser() ? FormState.MultiStepCreation : FormState.Create
              );
              history.push(returnUrl);
            }
          : undefined
      }
    />
  );

  useEffect(() => {
    if (abortDataFetch) {
      return;
    }
    onManagePageInitialized();
    // eslint-disable-next-line
  }, [publisher, account]);

  const adUnitsGridReady = tythonUser ? !adUnitsRaw.isLoading && !getGridPropertyStore().isFetchingPropertyStatus : !adUnitsRaw.isLoading;
  const adUnitsGridData = publisher && account ? adUnitsRaw.data || [] : [];
  const pageContent = (
    <>
      <GridV2
        history={history}
        location={props.location}
        match={match}
        entity={AdUnit}
        pageTitle={{ title: formatMessage(messages.adUnits), helpId: 'pop_PCv4_AdUnits' }}
        commandBarProps={getCommandBarItems(formatMessage, history, match, isWindowExtraSmallSize)}
        isGridReady={adUnitsGridReady}
        isDataLoading={adUnitsRaw.isFetchingData}
        dataGridProps={{
          gridV2Columns: gridDefaultColumns,
          data: tythonUser
            ? adUnitsGridData.filter((adunit) =>
                getGridPropertyStore()?.servingConfigs?.find(
                  (config) => config.propertyId === adunit.propertyId && config.inventoryPlatformType !== InventoryPlatformType.UWP
                )
              )
            : adUnitsGridData,
          setData: setAdUnitsData,
          shimmerLines: 10,
          renderZeroItemsComponent: NoResultComponent,
          noEntityFound: messages.notFound,
        }}
        paginationProps={{
          shouldGridHandlePagination: true,
        }}
      />
      <>{isTythonSelfServeUser() && <CodePanel {...props} />}</>
      <AdUnitCallout {...props} visibleCallOut={visibleCallOut} setVisibleCallOut={setVisibleCallOut} />
    </>
  );

  return withParentLayout ? <>{pageContent}</> : <GridPageLayout>{pageContent}</GridPageLayout>;
});
