import { defineMessages } from 'react-intl';

export default defineMessages({
  pickerAriaDescription: {
    id: 'hierarchicalMultiSelectPicker.pickerAriaDescription',
    defaultMessage: 'Select items using the hierarchical multi-selection picker'
  },
  createButtonAriaDesc: {
    id: 'hierarchicalMultiSelectPicker.createButtonAriaDesc',
    defaultMessage: 'Click this button to {createElement} within this page'
  },
  addAllAriaDescription: {
    id: 'hierarchicalMultiSelectPicker.addAllAriaDescription',
    defaultMessage: 'Click this button to select all items of group {groupName}'
  },
  removeAllAriaDescription: {
    id: 'hierarchicalMultiSelectPicker.removeAllAriaDescription',
    defaultMessage: 'Click to remove all selected items'
  },
  addIconAriaLabel: {
    id: 'hierarchicalMultiSelectPicker.addIconAriaLabel',
    defaultMessage: 'Add item'
  },
  removeIconAriaLabel: {
    id: 'hierarchicalMultiSelectPicker.removeIconAriaLabel',
    defaultMessage: 'Remove item'
  },
  expandGroupAriaLabel: {
    id: 'hierarchicalMultiSelectPicker.expandGroupAriaLabel',
    defaultMessage: 'Expand group'
  },
  selectedListAriaLabel: {
    id: 'hierarchicalMultiSelectPicker.selectedListAriaLabel',
    defaultMessage: 'List of selected items grouped by group name'
  }
});