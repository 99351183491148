import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../../../logging/src';
import { getAppStore, getEntity, onLoadingChanged, putEntity, showErrorToastNotification } from '../../../../../../../../@data';
import { AdSenseOAuthStatus } from '../../../../../../../../@data/store/schema/enums/AdSenseOAuthStatus';
import { AdSenseMediation, AdSenseMediationHealthCheck } from '../../../../../../../../@data/store/schema/models/AdSenseMediation';
import { googleConnectorMessages } from '../../GoogleConnector.messages';
import { onInitializeGoogleAccount } from '../actions';
import { onChangeConnection } from '../mutatorActions';

export const handleMediationResponse = (response: AdSenseMediation) => {
  if (response && response?.oAuthConnectionStatus && AdSenseOAuthStatus[response?.oAuthConnectionStatus] === AdSenseOAuthStatus.Success) {
    onChangeConnection({
      email: response?.email,
      isMediationEnabled: response?.mediationStatus,
      connectionStatus: AdSenseOAuthStatus[response?.oAuthConnectionStatus],
      connectedDate: response?.connectedTimeStamp,
      connectedImageSrc: response?.userImageUri,
    });
  } else if (
    response.oAuthConnectionStatus &&
    AdSenseOAuthStatus[response.oAuthConnectionStatus] !== AdSenseOAuthStatus.Success &&
    AdSenseOAuthStatus[response.oAuthConnectionStatus] !== AdSenseOAuthStatus.None
  ) {
    onChangeConnection({
      email: response.email,
      isMediationEnabled: response.mediationStatus,
      connectionStatus: AdSenseOAuthStatus[response.oAuthConnectionStatus],
      connectedDate: response.connectedTimeStamp,
      connectionStatusTip: response.oauthFailureReasons?.[0].description,
      actionItems: response.oauthFailureReasons,
    });
  } else {
    onChangeConnection({
      connectionStatus: AdSenseOAuthStatus.None,
    });
  }
};

orchestrator(onInitializeGoogleAccount, async ({ showLoadingSpinner = true, showNotifications = true, onFinished = () => null }) => {
  const { publisher, account } = getAppStore();

  if (showLoadingSpinner) {
    onLoadingChanged(true);
  }

  if (publisher && account) {
    try {
      const urlParams = new URLSearchParams();
      urlParams.append('routeSuffix', 'api/mediation/oauth/getDetails');
      let response = await getEntity([], undefined, AdSenseMediation, urlParams, undefined);

      // perform health check if oAuthConnectionStatus is valid, endpoint will return same contract as getDetails response
      if (response && response.mediationStatus) {
        const healthCheckUrlParams = new URLSearchParams();
        healthCheckUrlParams.append('routeSuffix', 'api/mediation/admanager/healthCheck');
        const request: AdSenseMediationHealthCheck = {
          apiPath: 'preauth/mediation',
          className: 'oauth',
        };

        const healthCheckResponse = await putEntity([], request, undefined, AdSenseMediationHealthCheck, healthCheckUrlParams, undefined);
        response = healthCheckResponse;
      }
      // extract response logic handler and reuse it to handle GAM network selection response
      handleMediationResponse(response);
    } catch (error) {
      if (showNotifications) {
        showErrorToastNotification({ textMessageDescriptor: googleConnectorMessages.googleConnectorFailedToFetchAdSenseConnectionDetails });
      }
      Log.error(`Error while fetching google account ${error}`);
      onChangeConnection({
        connectionStatus: AdSenseOAuthStatus.None,
      });
    } finally {
      if (showLoadingSpinner) {
        onLoadingChanged(false);
      }
      onFinished();
    }
  } else {
    if (showNotifications) {
      showErrorToastNotification({ textMessageDescriptor: googleConnectorMessages.initializeGoogleAccountFailed });
    }
  }
});
