import * as React from 'react';
import { InjectedIntl, InjectedIntlProps } from 'react-intl';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { IAppState, getAppStore } from '../../@data';
import '../../@data/orchestrators';
import { UserRoleEntity, completeAccess, hasReadAccess, userRoleKey } from '../../@data/services/UserRoleService';
import { User as UserAccessPermissions } from '../../@data/store/schema/userrolemodels/User';
import AppMessages from '../../App.messages';
import { IRoute } from '../../Routes.types';
import { ContentLayout } from '../../layout/ContentLayout/ContentLayout';
import { IMenuProps } from '../../layout/ContentLayout/ContentLayout.types';
import { injectIntlWithObserver } from '../../utils';
import { RouteName } from './@data';
import './@data/mutators';
import { AccountPage } from './Account/AccountPage';
import { IndexPage } from './Index/IndexPage';
import { PartnerManagementHeader } from './PartnerManagementHeader';
import { PublisherPage } from './Publisher/PublisherPage';
import { UserPage } from './User/UserPage';

export const getRoutes = (formatMessage: InjectedIntl['formatMessage']): IRoute[] => [
  {
    key: RouteName[RouteName.publisher],
    text: formatMessage(AppMessages.navPublisherManagement),
    icon: 'CRMReport',
    component: PublisherPage,
  },
  {
    key: RouteName[RouteName.account],
    text: formatMessage(AppMessages.navAccountManagement),
    icon: 'AccountManagement',
    component: AccountPage,
  },
  {
    key: RouteName[RouteName.user],
    text: formatMessage(AppMessages.navUserManagement),
    icon: 'Contact',
    component: UserPage,
  },
];

const DefaultRoute = RouteName[RouteName.publisher];

export const PartnerManagementRouter = injectIntlWithObserver(
  class extends React.Component<RouteComponentProps & InjectedIntlProps> {
    public render(): JSX.Element {
      const store = getAppStore();

      const menuProps: IMenuProps = {
        ...this.props,
        routes: this._generateMenuRoutes(store),
      };

      return (
        <ContentLayout {...this.props} menuProps={menuProps} onRenderHeader={this._renderHeader}>
          <Redirect exact path={this.props.match.url} to={`${this.props.match.url}/${DefaultRoute}`} />
          {getRoutes(this.props.intl.formatMessage).map((route: IRoute, index: number) => (
            <Route
              key={index}
              path={[`${this.props.match.url}/${route.key}`, `${this.props.match.url}/:publisherIdentifier/${route.key}`]}
              component={route.component}
            />
          ))}
          <Route key="catch-all" path={`${this.props.match.url}/:id`} component={IndexPage} />
        </ContentLayout>
      );
    }

    private _generateMenuRoutes(store: IAppState) {
      let keyFnc: (key: string) => string;
      const userAccessPermissions = this._getUserAccessPermission();

      if (store.publisher && store.publisher.id) {
        const publisherId = store.publisher.id.toString();
        keyFnc = (key) => {
          return `/${publisherId}/${key}`;
        };
      } else {
        keyFnc = (key) => `/${key}`;
      }

      if (!hasReadAccess(userAccessPermissions === null ? completeAccess : userAccessPermissions.accessPermission)) {
        return getRoutes(this.props.intl.formatMessage)
          .filter((route) => route.key !== RouteName.user)
          .map(
            (route) =>
              ({
                key: keyFnc(route.key),
                text: route.text,
                icon: route.icon,
              } as IRoute)
          );
      }
      // Update the menu routes according to whether the publisher/account were specified.
      return getRoutes(this.props.intl.formatMessage).map(
        (route) =>
          ({
            key: keyFnc(route.key),
            text: route.text,
            icon: route.icon,
          } as IRoute)
      );
    }

    private _renderHeader = (): JSX.Element => {
      // Support non pub urls as well. When nothing matched, go back to home to do the default route.
      return (
        <React.Fragment>
          <Switch>
            <Route
              path={`${this.props.match.url}/:publisherId`}
              render={(props) => <PartnerManagementHeader {...props} isAccountHidden={true} isAccountSelectionDisabled={true} />}
            />
            <Route path="*" render={() => <Redirect to={this.props.match.url} />} />
          </Switch>
        </React.Fragment>
      );
    };

    private _getUserAccessPermission(): UserAccessPermissions | null {
      const userRoles = localStorage.getItem(userRoleKey);
      if (userRoles === null) {
        return null;
      }
      const userRolesJSON = JSON.parse(userRoles);

      for (const value in userRolesJSON) {
        if (value === UserRoleEntity.User) {
          return JSON.parse(userRolesJSON[value]);
        }
      }

      return null;
    }
  }
);
