import { IStackTokens, getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../../../theme';

type StyleProperties = 'buttonAction' | 'root' | 'cardRootStyle';
type ManageFilterStyles = Record<StyleProperties, string>;
interface IReportDetailsPageStyleProps {}
const theme = (getTheme() as unknown) as IPubcenterTheme;
const spacing = theme.spacing;

export const getStyles = (props: IReportDetailsPageStyleProps): ManageFilterStyles => {
  return mergeStyleSets({
    buttonAction: {
      width: '100%',
    },
    root: {
      padding: '20px',
    },
    cardRootStyle: {
      boxShadow: 0,
    },
  });
};
export const formTitleStyles = {
  root: {
    marginBottom: spacing.size24,
  },
};
export function getClassNames<PropType>(props?: PropType): ManageFilterStyles {
  return getStyles({ theme: getTheme(), ...props });
}
export function getStackTokens(): IStackTokens {
  return { childrenGap: spacing.size12 };
}
