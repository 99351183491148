import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import {
  Channel,
  ChannelType,
  getAppStore,
  onNavigateBack,
  onNotificationChanged,
  onSavingChanged,
  postEntity,
  putEntity,
} from '../../../../../@data';
import { FormState } from '../../../../../components/Form';
import { IIdNamePair } from '../../../../../components/MultiSelectPicker';
import { INotificationBarItem } from '../../../../../components/NotificationBar';
import { validateSchema } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { onPanelOpenChanged } from '../../../@data/actions';
import { createReturnUrl } from '../../../@data/services/AdManagementService';
import { onFilterChannelListChanged } from '../../../AdFilter/@data/actions';
import { getFilterStore } from '../../../AdFilter/@data/store/store';
import { getAdunitStore } from '../../../Adunit/@data';
import { onAdunitChannelListChanged } from '../../../Adunit/@data/actions';
import { fetchAdunitById } from '../../../Adunit/@data/services/AdunitService';
import { onChannelErrorChanged, onFormCleared, onFormIdChanged, onFormSubmitted } from '../actions';
import { fetchChannelById } from '../services/ChannelService';
import { channelErrorModel } from '../store/schema/ChannelErrorModel';
import { getStore } from '../store/store';

/**
 * 1) Set Loading to true
 * 2) Submit API call to Submit Data to Store
 * 3) Check errors, if any, send action to notification that error exists
 * 4) Clear the form
 * 5) Navigate back to channel list
 */
orchestrator(onFormSubmitted, async (msg) => {
  const { publisher, account, userContext } = getAppStore();
  const { active, raw } = getStore();

  let notification: INotificationBarItem = {};

  if (userContext && publisher && account && raw) {
    const body: Channel = {
      ...raw,
      id: active.id,
      name: active.name,
      accountId: account.id,
    };

    if (body.accountId === 0 && msg.entityId && msg.mode === FormState.InPageCreate) {
      // fetch accountId using adunit id
      const adunit = await fetchAdunitById(publisher, msg.entityId, userContext);
      body.accountId = adunit.accountId || NaN;
    }

    try {
      await validateSchema(channelErrorModel(), active);
      onChannelErrorChanged();
    } catch (e) {
      onChannelErrorChanged(e);
      onNotificationChanged({
        text: `Channel - You have ${Object.keys(e).length} missing fields, please fill them to continue.`,
        messageBarType: MessageBarType.severeWarning,
      });
      return;
    }

    onSavingChanged(true);

    body.type = ChannelType.CUSTOM;
    body.urls = [];
    body.associatedAdUnitIds = active.adunitsSelected ? active.adunitsSelected.map((m) => m.id) : [];
    body.associatedFilterIds = active.filtersSelected ? active.filtersSelected.map((m) => m.id) : [];

    try {
      let entity: Channel | undefined;

      if (msg.mode === FormState.Edit) {
        entity = await putEntity([publisher], body, userContext, Channel);

        notification = {
          text: `Edited channel ${entity.id} success`,
          messageBarType: MessageBarType.success,
        };
      } else {
        entity = await postEntity([publisher], Channel, body, userContext);

        if (entity && entity.id) {
          onFormIdChanged(entity.id);
          const filterStore = getFilterStore();
          const adunitStore = getAdunitStore();

          const channel = await fetchChannelById(publisher, entity.id + '', userContext);
          const filterChannelList = [] as IIdNamePair[];
          const adunitChannelList = [] as IIdNamePair[];

          if (filterStore.active.channels) {
            const newChannel = { id: channel.id, name: channel.name } as IIdNamePair;
            filterChannelList.push(...filterStore.active.channels);
            filterChannelList.push(newChannel);
          }

          if (adunitStore.active.channels) {
            const newChannel = { id: channel.id, name: channel.name } as IIdNamePair;
            adunitChannelList.push(...adunitStore.active.channels);
            adunitChannelList.push(newChannel);
          }
          onFilterChannelListChanged(filterChannelList);
          onAdunitChannelListChanged(adunitChannelList);
        }

        notification = {
          text: `Created new channel ${entity.id} success`,
          messageBarType: MessageBarType.success,
        };
      }

      if (msg.mode === FormState.InPageCreate) {
        onPanelOpenChanged(false);
      } else {
        // return to manage page
        const returnUrl = createReturnUrl(RouteName.channel, String(publisher.id), String(account.id));
        onNavigateBack(msg.routeHistory, returnUrl);
      }

      onFormCleared();
    } catch (e) {
      notification = {
        text: `Submitting the channel form "${FormState[msg.mode]}" caught an error "${e}"`,
        messageBarType: MessageBarType.error,
      };
    } finally {
      onSavingChanged(false);
    }
  } else {
    notification = {
      text: `Channel form "${FormState[msg.mode]}" is invalid id ${active.id}`,
      messageBarType: MessageBarType.warning,
    };
  }

  onNotificationChanged(notification);
});
