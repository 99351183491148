import { orchestrator } from 'satcheljs';
import { deepClone } from '../../../../../../utils';
import {
  onUpdateNotificationPreferences,
  toggleBillingAlerts,
  toggleInvitedUsers,
  toggleProfileReview,
  toggleServingStatus,
} from '../actions/onNotificationPreferencesChanged';
import { getStore } from '../store/store';

orchestrator(toggleBillingAlerts, ({ entityType, checked }) => {
  const { notificationPreferences } = getStore().active;
  const np = deepClone(notificationPreferences);
  np.billingAlerts = checked;
  onUpdateNotificationPreferences(entityType, np);
});

orchestrator(toggleServingStatus, ({ entityType, checked }) => {
  const { notificationPreferences } = getStore().active;
  const np = deepClone(notificationPreferences);
  np.servingStatus = checked;
  onUpdateNotificationPreferences(entityType, np);
});

orchestrator(toggleProfileReview, ({ entityType, checked }) => {
  const { notificationPreferences } = getStore().active;
  const np = deepClone(notificationPreferences);
  np.profileReview = checked;
  onUpdateNotificationPreferences(entityType, np);
});

orchestrator(toggleInvitedUsers, ({ entityType, checked }) => {
  const { notificationPreferences } = getStore().active;
  const np = deepClone(notificationPreferences);
  np.invitedUsers = checked;
  onUpdateNotificationPreferences(entityType, np);
});
