import { Checkbox, ICheckboxProps, Icon, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';
import { isButtonPressKey } from '../../utils/AccessibilityUtils';
import { ConditionalTooltip } from '../ConditionalTooltip';
import { getClassNames } from './CheckboxGroup.styles';
import { ICheckbox, ICheckboxGroup, ICheckboxGroupProps } from './CheckboxGroup.types';

/**
 * A controlled component to render a single node of checkbox-tree (singe parent and many children)
 * Meaning, the consumer of this component needs to control/handle the state and behavior for the component.
 */
export function CheckboxGroup({
  group,
  isExpanded,
  onExpand,
  onCollapse,
  onParentCheckboxChange,
  onChildCheckboxChange,
  styleProps,
  disabledCheckboxTooltipText,
}: ICheckboxGroupProps): JSX.Element {
  const areAllChildrenDisabled = (): boolean => group.children.every((checkbox) => !!checkbox.disabled);
  const areAllChildrenChecked = (): boolean => group.children.every((checkbox) => !!checkbox.checked);
  const isNoneChildrenChecked = (): boolean => group.children.every((checkbox) => !checkbox.checked);
  const areSomeChildrenChecked = (): boolean => !areAllChildrenChecked() && !isNoneChildrenChecked();

  const [isInExpandedState, { toggle: toggleIsInExpandedState }] = useBoolean(isExpanded ?? areSomeChildrenChecked());
  const { commonCheckboxStyle, collapseStyle, expandStyle, expandIconStyle, collapseIconStyle, disabledStyle } = mergeStyleSets(
    getClassNames(),
    styleProps
  );

  const handleExpandCollapseButtonClick = (ev: React.MouseEvent | React.KeyboardEvent) => {
    ev.stopPropagation();
    ev.preventDefault();

    if (isInExpandedState) {
      onCollapse?.(group);
    } else {
      onExpand?.(group);
    }
    toggleIsInExpandedState();
  };

  const handleParentCheckboxClick = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    if (areSomeChildrenChecked()) {
      // check all child checkboxes
      return onParentCheckboxChange?.(ev, true, group);
    }
    onParentCheckboxChange?.(ev, checked, group);
  };

  const isParentCheckboxDisabled = (): boolean => {
    return areAllChildrenDisabled();
  };

  const renderLabel = (props?: ICheckboxProps): JSX.Element => {
    return (
      <div className={props?.disabled ? disabledStyle : ''}>
        <Icon
          iconName="CaretRightSolid8"
          className={isInExpandedState ? expandIconStyle : collapseIconStyle}
          onClick={handleExpandCollapseButtonClick}
          onKeyDown={(ev) => isButtonPressKey(ev.key) && handleExpandCollapseButtonClick(ev)}
          tabIndex={0}
          aria-label={isInExpandedState ? 'Expanded' : 'Collapsed'}
        />
        {props?.label}
      </div>
    );
  };

  const getCheckboxId = (checkbox: ICheckbox | ICheckboxGroup) => `check-${checkbox.key}`;

  const renderChildCheckbox = (checkbox: ICheckbox): JSX.Element => {
    return (
      <Checkbox
        className={commonCheckboxStyle}
        id={getCheckboxId(checkbox)}
        checked={checkbox.checked}
        label={checkbox.label}
        disabled={checkbox.disabled}
        onChange={(ev, checked) => onChildCheckboxChange?.(ev, checked, checkbox)}
      />
    );
  };

  const renderParentCheckbox = (parent: ICheckboxGroup): JSX.Element => {
    return (
      <Checkbox
        id={getCheckboxId(parent)}
        key={parent.label}
        className={commonCheckboxStyle}
        checked={areAllChildrenChecked()}
        indeterminate={areSomeChildrenChecked()}
        label={parent.label}
        onChange={handleParentCheckboxClick}
        onRenderLabel={renderLabel}
        disabled={isParentCheckboxDisabled()}
      />
    );
  };

  return (
    <div>
      <ConditionalTooltip
        showTooltip={isParentCheckboxDisabled()}
        content={disabledCheckboxTooltipText}
        targetSelector={`#${getCheckboxId(group)}`}
      >
        {renderParentCheckbox(group)}
      </ConditionalTooltip>
      <div className={isInExpandedState ? expandStyle : collapseStyle}>
        {group.children.map((child) => {
          return (
            <ConditionalTooltip
              key={child.key}
              showTooltip={child.disabled}
              targetSelector={`#${getCheckboxId(child)}`}
              content={disabledCheckboxTooltipText}
            >
              {renderChildCheckbox(child)}
            </ConditionalTooltip>
          );
        })}
      </div>
    </div>
  );
}
