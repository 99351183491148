import { Account, getEntity, listEntity, Model, postEntity, Publisher, UserContext } from '../../../../@data';
import { restoreTypeWithObject } from '../../../../utils';

export async function getData(models: Model[], userContext: UserContext, entity?: new () => Model, params?: URLSearchParams) {
  return await listEntity(models, userContext, entity, params);
}

export async function duplicateData(models: Model[], userContext: UserContext, entityType: new () => Model, params?: URLSearchParams) {
  let result: Model;
  try {
    result = await getEntity<Model>(models, userContext, entityType, params);
  } catch (error) {
    return Promise.reject(error);
  }

  if (result) {
    let entityToDuplicate = result[0] as Model;
    entityToDuplicate.id = undefined;
    entityToDuplicate.name += ' (copy)';
    entityToDuplicate.eTag = undefined;
    entityToDuplicate = restoreTypeWithObject(new entityType(), entityToDuplicate)!;

    return await postEntity(models, entityType, entityToDuplicate, userContext);
  }
}

export async function duplicateAccount(publisher: Publisher, accountIdToDuplicate: number, userContext: UserContext) {
  let result: Model;
  try {
    const account = new Account(accountIdToDuplicate);
    result = await getEntity([publisher, account], userContext);
  } catch (error) {
    return Promise.reject(error);
  }

  if (result) {
    let entityToDuplicate = result as Model;
    entityToDuplicate.id = undefined;
    entityToDuplicate.name += ' (copy)';
    entityToDuplicate.eTag = undefined;
    entityToDuplicate = restoreTypeWithObject(new Account(), entityToDuplicate)!;
    return await postEntity([publisher], Account, entityToDuplicate, userContext);
  }
}
