import { mutatorAction } from "satcheljs";
import { isEmpty } from "../../../../../../../../utils";
import { IGoogleAdSenseModel } from "../store/schema/IGoogleAdSenseModel";
import { getGoogleAdSenseStore } from "../store/store";

export const onChangeConnection = mutatorAction('onChangeConnection', (connection: IGoogleAdSenseModel) => {
  getGoogleAdSenseStore().active = connection;
});

export const onChangeMediationToggle = mutatorAction('onChangeMediationToggle', (isToggled: boolean) => {
  const { active} = getGoogleAdSenseStore();
  active.isMediationEnabled = isToggled;
});

export const onChangeConnectionStatus = (msg) => {
  const { active} = getGoogleAdSenseStore();
  active.connectionStatus = msg.status;
}

export const isPublisherConnectedWithAdSense = () => {
  const { active } = getGoogleAdSenseStore();
  if (active.email === undefined)
  {
    return false;
  }

  return isEmpty(active.email);
}