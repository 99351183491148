import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'account.create',
    defaultMessage: 'Create Account',
  },
  notFound: {
    id: 'account.notFound',
    defaultMessage: 'No accounts found for this publisher, create one.',
  },
  name: {
    id: 'account.name',
    defaultMessage: 'Account name',
  },
  id: {
    id: 'account.id',
    defaultMessage: 'Account ID',
  },
  number: {
    id: 'account.number',
    defaultMessage: 'Account Number',
  },
  contact: {
    id: 'account.contact',
    defaultMessage: 'Primary contact',
  },
  numAdUnits: {
    id: 'account.numAdUnits',
    defaultMessage: '# of ad units',
  },
  adunitnames: {
    id: 'account.adunitnames',
    defaultMessage: 'Ad unit names',
  },
  createdDate: {
    id: 'account.createdDate',
    defaultMessage: 'Date Created',
  },
  accounts: {
    id: 'account',
    defaultMessage: 'Accounts',
  },
  noPublisherSelected: {
    id: 'publisher.noPublisherSelected',
    defaultMessage: 'A publisher isn’t selected yet. To start managing accounts, search for a publisher',
  },
  actions: {
    id: 'publisher.actions',
    defaultMessage: 'Actions',
  },
  zeroResultTitle: {
    id: 'publisher.zeroResultTitle',
    defaultMessage: 'No accounts yet',
  },
  zeroResultDescription: {
    id: 'publisher.zeroResultDescription',
    defaultMessage: 'You haven’t created any accounts yet',
  },
});
