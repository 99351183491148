import { ITheme } from '@fluentui/react';
import { FontWeights } from '@fluentui/theme';
import { BreakPoints, IPubCenterFonts, IPubcenterBoxShadow, IPubcenterPalette, IPubcenterSpacing } from '../../../../../theme';
import { TythonAdUnitDisplayAdTypeLayout } from '../TythonAdunit/CreateAdunit/constants';
import { DEFAULT_AD_PREVIEW_HEIGHT } from '../TythonAdunit/components/AdUnitTypeSelector/useAdUnitTypeSelector';
import { ICreateAdunitStyleProps, ICreateAdunitStyles } from './CreateAdunit.types';

export const getStyles = (props: ICreateAdunitStyleProps): ICreateAdunitStyles => {
  const { theme, adPreviewHeight, displayAdLayout } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const fonts = theme.fonts as IPubCenterFonts;
  const { ResponsiveSmall } = BreakPoints;
  const palette = theme.palette as IPubcenterPalette;
  const boxShadow = (theme as ITheme & { boxShadow: IPubcenterBoxShadow }).boxShadow;
  const [isDisplayAdHorizontal, isDisplayAdSquare, isDisplayVertical] = [
    displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Horizontal,
    displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Square,
    displayAdLayout === TythonAdUnitDisplayAdTypeLayout.Vertical,
  ];
  return {
    root: {
      marginTop: theme.spacing.m,
      marginLeft: theme.spacing.m,
    },
    title: [
      'ms-font-xl',
      {
        display: 'flex',
        marginBottom: theme.spacing.l1,
      },
    ],
    adtypesTitle: {
      display: 'flex',
    },
    channelTitle: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: spacing.size12,
      marginBottom: spacing.size16,
    },
    button: {
      background: 'none',
      border: 'none',
      height: '28px',
      fontSize: '14px',
      color: '#0078D4',

      cursor: 'pointer',
      position: 'relative',
      top: '38px',
      borderRadius: '2px',
      marginLeft: spacing.size4,
      boxSizing: 'border-box',
      selectors: {
        ':focus': {
          outline: `2px solid ${palette.themePrimary}`,
        },
      },
    },
    businessModel: {
      display: 'flex',
      flexDirection: 'row',
    },
    businessModelLevel2Dropdown: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      margin: '28.5px 0 0 21px',
    },
    dropdown: {
      width: '220px',
    },
    combobox: {
      width: '400px',
    },
    multiSelectPicker: {
      marginBottom: theme.spacing.s1,
    },
    propertySection: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: spacing.size16,
    },
    siteSection: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 50,
    },
    fileUploadLabelStyle: {
      color: palette.themePrimary,
      cursor: 'pointer',
      float: 'right',
      marginLeft: 'auto',
    },
    boostUploadDiv: {
      display: 'flex',
      alignItems: 'center',
    },
    boostUploadOuter: {
      marginBottom: '0px',
      paddingBottom: '0px',
    },
    advanceOptionTextfieldLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    formAction: {
      marginTop: theme.spacing.l1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    propertyDetails: {
      paddingTop: spacing.size12,
    },
    adTypeContainer: {
      marginTop: spacing.size48,
    },
    subComponentStyles: {
      servingTierChoiceGroup: {
        flexContainer: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          selectors: {
            '.ms-ChoiceField': {
              marginRight: theme.spacing.m,
            },
          },
        },
      },
      formSection: {
        root: {
          borderBottomWidth: 0,
        },
      },
      checkbox: {
        root: {
          marginBottom: theme.spacing.s1,
        },
      },
      adTypeChoiceGroupOption: {
        root: {
          margin: `0 ${spacing.size16} ${spacing.size16} 0`,
          borderRadius: '5px',
          height: 'auto',
          [ResponsiveSmall]: {
            width: '100%',
          },
        },
        choiceFieldWrapper: {
          [ResponsiveSmall]: {
            width: '100%',
          },
        },
        labelWrapper: {
          height: 'fit-content',
          margin: `${spacing.size16} 0`,
          lineHeight: 'normal',
        },
      },
      textBox: {
        root: {
          width: spacing.size96,
        },
        prefix: {
          padding: 0,
          background: 'none',
        },
        errorMessage: {
          width: spacing.size128,
        },
      },
    },
    mediationHeader: [
      'ms-fontWeight-semibold',
      {
        ...fonts.size20,
      },
    ],
    mediationToggle: {
      display: 'flex',
      alignItems: 'baseline',
      gap: spacing.size16,
      fontWeight: fonts.size14Medium.fontWeight,
    },
    adunitTypeAccordionWrapper: {
      width: `calc(100% - ${spacing.size48})`,
      position: 'relative',
      '.ms-Overlay': {
        userSelect: 'none', // Standard syntax
        WebkitUserSelect: 'none', // Chrome, Safari, and Opera
        MozUserSelect: 'none', // Firefox
        msUserSelect: 'none', // IE 10+
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        fontSize: '20px',
        backgroundColor: 'rgba(228, 227, 226, 0.5)',
        fontWeight: 700,
        color: palette.neutralSecondaryAltWhiteContrast,
        position: 'absolute',
        width: '100%',
        borderRadius: spacing.size12,
      },
    },
    adunitTypeAccordionCheckboxLabel: {
      'ms-checkbox-text': {
        fontSize: spacing.size18,
      },
    },
    adunitTypeWarningMsgBar: {
      width: `calc(100% - ${spacing.size48})`,
    },
    adunitTypeAccordionContentWrapper: {
      width: '100%',
      '.nativeAdType': {
        minHeight: (adPreviewHeight ?? DEFAULT_AD_PREVIEW_HEIGHT) + 146,
      },
      [ResponsiveSmall]: {
        flexDirection: 'column',
      },
    },
    adunitTypeLeftSection: {
      borderRight: `${spacing.size1} solid ${palette.neutralLight}`,
      padding: `${spacing.size24} ${spacing.size46}`,
      width: spacing.size480,
      [ResponsiveSmall]: {
        width: `calc(100% - ${spacing.size92})`,
      },
    },
    adunitTypeRightSection: {
      padding: `${spacing.size24} ${spacing.size46}`,
      [ResponsiveSmall]: {
        width: `calc(100% - ${spacing.size92})`,
        borderTop: `${spacing.size1} solid ${palette.neutralLight}`,
      },
    },
    previewSDKWrapper: {
      [ResponsiveSmall]: {
        maxWidth: '100%',
        overflowX: 'auto',
      },
      '& > div > div[data-template="horizontal"]': {
        boxShadow: boxShadow.soft,
      },
    },
    adunitTypeFormTitlePrimary: {
      fontSize: spacing.size16,
      fontWeight: 600,
      color: palette.black,
      marginBottom: spacing.size12,
    },
    boldFieldTitle: {
      lineHeight: spacing.size20,
      fontSize: spacing.size14,
      fontWeight: FontWeights.semibold,
      marginRight: spacing.size2,
    },
    horizontalSelectionWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: spacing.size12,
    },
    infoMsgBar: {
      backgroundColor: palette.themeLighterAlt,
      '.ms-MessageBar-content': { alignItems: 'center' },
    },
    expandIcon: {
      paddingLeft: spacing.size4,
    },
    newPropertyPanelHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '5px 5px 0px 0px',
    },
    adPreviewWrapper: {
      overflowY: 'auto',
      width: '100%',
    },
    mediationDescriptionWrapper: {
      [ResponsiveSmall]: {
        width: spacing.size320,
      },
    },
    adunitTypeDisplayAdPreviewHead: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '>span': {
        marginBottom: 0,
        [ResponsiveSmall]: {
          marginBottom: spacing.size6,
        },
      },
    },
    adunitTypeDisplayAdPreviewHeadButtons: {
      textAlign: 'right',
      [ResponsiveSmall]: {
        textAlign: 'left',
      },
      marginBottom: spacing.size18,
      '.ms-Pivot--tabs': {
        '.ms-Pivot-linkContent': {
          padding: `0px ${spacing.size12}`,
        },
        '.ms-Button': {
          border: `1px solid ${palette.neutralLight}`,
          height: spacing.size36,
        },
        '.ms-Button:nth-child(2)': {
          borderLeft: 'none',
          borderRight: 'none',
        },
        '.is-selected': {
          fontWeight: 600,
        },
      },
    },
    adunitTypeDisplayAdPreviewContainer: {},
    adunitTypeDisplayAdPreviewLayoutWrapper: {
      overflowX: 'auto',
      paddingBottom: spacing.size12,
      display: 'flex',
      flexDirection: isDisplayAdHorizontal ? 'column' : 'row',
      gap: spacing.size12,
      img: {
        boxShadow: boxShadow.soft,
        display: 'block',
        ...(isDisplayAdHorizontal ? { width: spacing.size600 } : {}),
        ...(isDisplayAdSquare ? { width: spacing.size250 } : {}),
        ...(isDisplayVertical ? { width: spacing.size160 } : {}),
      },
      ...(isDisplayAdSquare || isDisplayVertical
        ? {
            '> div:nth-child(1)': {
              marginRight: spacing.size12,
            },
            '> div:nth-child(2)': {
              marginRight: spacing.size12,
            },
          }
        : {}),
    },
    /**
     *  the last vertical ad preview has different border width, adding
     *  this class to override the nested img width.
     */
    verticalAdPreviewBottomWrapper: {
      width: `${spacing.size159} !important`,
    },
    displayAdPreviewExampleTitle: {
      fontWeight: 600,
      marginBottom: spacing.size6,
    },
  };
};
