import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src';
import { Publisher, User, getAppStore } from '../../../../@data';
import { fetchOneVetReviewStatus } from '../../../../@data/services/OneVetIntegrationService';
import { setFetchingOvdfStatus, triggerOneVetReview } from '../actions';
import { getPublisherName } from '../store/selectors';
import { getSignUpStore } from '../store/store';

orchestrator(triggerOneVetReview, async () => {
  const { userContext } = getAppStore();
  const { active } = getSignUpStore();

  setFetchingOvdfStatus(true);

  const user = new User();
  user.email = userContext?.email;
  user.username = userContext?.userName;
  user.name = {
    firstName: active.userFirstName || '',
    lastName: active.userLastName || '',
  };
  const publisherProfile = new Publisher();
  publisherProfile.name = getPublisherName();
  publisherProfile.publisherType = active.accountType;
  const reviewStatus = await fetchOneVetReviewStatus('signup', publisherProfile, user);

  Log.debug(`User: ${userContext?.email}, trying to signup, OVDF resulted as ${reviewStatus}`);

  setFetchingOvdfStatus(false);
});
