import { orchestrator } from 'satcheljs';
import { AdSource } from '../../../../../@data';
import {
  onBlockAdInitialized,
  onBlockAdsChanged,
  onBlockAdsChannelsAndRevenueChanged,
  onBlockAdsImagesChanged,
  onBlockAdsSamChanged,
  onBlockImageAdsChanged,
} from '../actions';
import { getStore } from '../store/store';

orchestrator(onBlockAdInitialized, async (msg) => {
  const selectedAds = msg.value;
  const { active } = getStore();
  if (active.selectedAdSource === AdSource.Microsoft) {
    if (!msg.isImageBlock) {
      onBlockAdsChanged(selectedAds);
      selectedAds.forEach((item, index) => onBlockAdsImagesChanged(item.images, index, true, false));
      onBlockAdsChannelsAndRevenueChanged(selectedAds);
    } else {
      onBlockImageAdsChanged(selectedAds, true);
      selectedAds.forEach((item, index) => onBlockAdsImagesChanged(item.images, index, true, false));
    }
  } else {
    onBlockAdsSamChanged(selectedAds);
    selectedAds.forEach((item, index) => onBlockAdsImagesChanged(item.images, index, false, true));
  }
});
