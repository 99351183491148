import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { PublisherType } from '../../../../../@data/store/schema/enums/PublisherType';
import AppMessages from '../../../../../App.messages';
import { OptionCard } from '../../../../../components/OptionCard/OptionCard';
import { DEFAULT_SELECTED_ACCOUNT_TYPE, getSignUpStore } from '../../../@data';
import { onAccountTypeChanged } from '../../../@data/actions';
import indImage from './../../../resources/individual.png';
import orgImage from './../../../resources/organization.png';
import accountTypeMessages from './ChooseAccount.messages';
import { getChoiceGroupStyles, getOptionStyles, getStyles } from './ChooseAccount.styles';
import { IChooseAccountProps, IChooseAccountStyles } from './ChooseAccount.types';

export const ChooseAccount = injectIntl<IChooseAccountProps>(function ChooseAccountComponent({ styleProps, intl }) {
  const { formatMessage } = intl;
  const store = getSignUpStore();
  const [accountType, setAccountType] = useState<PublisherType>(store.active.accountType || DEFAULT_SELECTED_ACCOUNT_TYPE);
  const accountOptions = getAccountOptions(formatMessage, getStyles(), accountType);

  return (
    <ChoiceGroup
      aria-label={formatMessage(accountTypeMessages.chooseOption)}
      options={accountOptions}
      required={true}
      defaultSelectedKey={accountType}
      styles={getChoiceGroupStyles()}
      onChange={(_, value) => {
        onAccountTypeChanged(value!!);
        setAccountType(value?.key as PublisherType);
      }}
    />
  );
});

const imageIconWidth = 32;
const imageIconHeight = 32;

export const getAccountOptions = (
  formatMessage,
  classNames: IChooseAccountStyles,
  selectedAccountType: PublisherType
): IChoiceGroupOption[] => [
  {
    key: PublisherType.individual,
    text: formatMessage(AppMessages.individual),
    imageSrc: indImage,
    imageAlt: formatMessage(AppMessages.individual),
    selectedImageSrc: indImage,
    imageSize: { width: imageIconWidth, height: imageIconHeight },
    onRenderField: (props, render) => {
      return (
        <div>
          {render?.(props)}
          <OptionCard
            content={formatMessage(accountTypeMessages.individualContent)}
            subContent={formatMessage(accountTypeMessages.individualSubContentI)}
            isSelected={selectedAccountType === PublisherType.individual}
            info={formatMessage(accountTypeMessages.individualRequirements)}
            linkText={formatMessage(accountTypeMessages.individualHelperLinkText)}
            linkHref="http://go.microsoft.com/fwlink/?LinkId=2266928"
          />
        </div>
      );
    },
    onRenderLabel: (props, render) => {
      return (
        <div className={classNames.optionTitleWrapper}>
          <div className={classNames.selectedOptionLabelWrapper}>{render?.(props)}</div>
          <span className={classNames.optionTitleBadge}>{formatMessage(accountTypeMessages.individualBadgeText)}</span>
        </div>
      );
    },
    styles: getOptionStyles(selectedAccountType === PublisherType.individual),
  },
  {
    key: PublisherType.organization,
    text: formatMessage(AppMessages.organization),
    imageSrc: orgImage,
    imageAlt: formatMessage(AppMessages.organization),
    selectedImageSrc: orgImage,
    imageSize: { width: imageIconWidth, height: imageIconHeight },
    onRenderField: (props, render) => {
      return (
        <div>
          {render?.()}
          <OptionCard
            content={formatMessage(accountTypeMessages.organizationContent)}
            subContent={formatMessage(accountTypeMessages.organizationSubContentI)}
            isSelected={selectedAccountType === PublisherType.organization}
            info={formatMessage(accountTypeMessages.organizationRequirements)}
          />
        </div>
      );
    },
    onRenderLabel: (props, render) => {
      return (
        <div className={classNames.optionTitleWrapper}>
          <div className={classNames.selectedOptionLabelWrapper}>{render?.(props)}</div>
        </div>
      );
    },
    styles: getOptionStyles(selectedAccountType === PublisherType.organization),
  },
];
