import { DefaultButton, IconButton, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import { IChartProps, ILineChartPoints, IVerticalBarChartDataPoint, LineChart, VerticalBarChart } from '@fluentui/react-charting';
import React, { ReactNode } from 'react';
import { PaymentsPageEstimatedEarnings } from '../../../../../@data';
import { EmptyChart } from '../../../../../components/EmptyChart/EmptyChart';
import messages from '../PaymentsPage.messages';
import { getClassNames } from './EstimatedEarnings.styles';
import { ChartGrain, EstimatedEarningsChartTypes } from './EstimatedEarnings.types';

const chartColor = '#0099BC';
export const getShimmerCustomElements = () => {
  return (
    <div>
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
          { type: ShimmerElementType.gap, height: 30, width: '100%' },
          { type: ShimmerElementType.line, width: '100%' },
        ]}
      />
    </div>
  );
};

export function mapToLineChartData(data: PaymentsPageEstimatedEarnings, grain) {
  const points: ILineChartPoints[] = [
    {
      data: data.earningDates.map((date, index) => {
        const localDate = parseDateKey(date);

        return { x: localDate, y: +data.earningValues[index].toFixed(2) };
      }),
      legend: 'Balance',
      color: chartColor,
    },
  ];
  const chartData: IChartProps = {
    chartTitle: 'Line Chart',
    lineChartData: points,
  };
  return chartData;
}

function parseDateKey(date: string) {
  const dateArray = date.split('-');
  const year = Number(dateArray[0]);
  const month = Number(dateArray[1]) || 1;
  const localDate = new Date(year, month - 1, 1, 0, 0, 0);
  return localDate;
}

export function mapToBarChartData(data: PaymentsPageEstimatedEarnings, grain): IVerticalBarChartDataPoint[] {
  const dynamicData: IVerticalBarChartDataPoint[] = data.earningDates.map((date, index) => {
    const localDate = parseDateKey(date);
    const localeMonth = localDate.toLocaleString('default', { month: 'short' });
    const localYear = localDate.toLocaleString('default', { year: 'numeric' });
    // \u2800 is unicode BRAILLE PATTERN BLANK character which is rendered as empty space
    // work around to prevent year from being parsed as number
    const xValue = grain === ChartGrain.MONTHLY ? `${localeMonth} ${localYear}` : `\u2800${localYear}\u2800`;
    return { x: xValue, y: data.earningValues[index] };
  });
  return dynamicData;
}

export const getTopBarActions = (currentChartTypeStateManagement, activeGrainStateManagement, formatMessage, props): ReactNode => {
  const { setCurrentChartType, currentChartType } = currentChartTypeStateManagement;
  const { activeGrain, setActiveGrain } = activeGrainStateManagement;
  const isMonthlyGrain = activeGrain === ChartGrain.MONTHLY;
  const isYearlyGrain = activeGrain === ChartGrain.YEARLY;
  const classNames = getClassNames(props);
  const { activeButton, buttonBorder, chartTypes } = classNames;
  return (
    <>
      <div className={`${chartTypes} ${buttonBorder}`}>
        <IconButton
          iconProps={{ iconName: 'LineChart' }}
          ariaLabel="Line chart"
          className={currentChartType === EstimatedEarningsChartTypes.LINE ? activeButton : ''}
          aria-expanded={currentChartType === EstimatedEarningsChartTypes.LINE ? true : false}
          onClick={() => {
            setCurrentChartType(EstimatedEarningsChartTypes.LINE);
          }}
        />
        <IconButton
          iconProps={{ iconName: 'BarChartVertical' }}
          ariaLabel="Bar chart vertical"
          className={currentChartType === EstimatedEarningsChartTypes.VERTICAL_BAR ? activeButton : ''}
          aria-expanded={currentChartType === EstimatedEarningsChartTypes.VERTICAL_BAR ? true : false}
          onClick={() => {
            setCurrentChartType(EstimatedEarningsChartTypes.VERTICAL_BAR);
          }}
        />
      </div>
      <div className={buttonBorder}>
        <DefaultButton
          ariaLabel="Monthly"
          className={isMonthlyGrain ? activeButton : buttonBorder}
          toggle
          checked={isMonthlyGrain}
          text={formatMessage(messages.estimatedEarningMonthly)}
          onClick={() => {
            setActiveGrain(ChartGrain.MONTHLY);
          }}
          allowDisabledFocus
        />
        <DefaultButton
          ariaLabel="Yearly"
          className={isYearlyGrain ? activeButton : buttonBorder}
          toggle
          checked={isYearlyGrain}
          text={formatMessage(messages.estimatedEarningYearly)}
          onClick={() => {
            setActiveGrain(ChartGrain.YEARLY);
          }}
          allowDisabledFocus
        />
      </div>
    </>
  );
};

export const getChart = (
  currentChartType: EstimatedEarningsChartTypes,
  currentGrain: ChartGrain,
  data: PaymentsPageEstimatedEarnings,
  size: { height: number; width: number },
  formatMessage
) => {
  if (!data || !data.earningDates || data.earningDates.length <= 0) {
    return (
      <EmptyChart
        noDataText={formatMessage(messages.estimatedEarningsNoDataText)}
        noDataMessage={formatMessage(messages.estimatedEarningsNoDataMessage)}
        showGridLines
      />
    );
  }
  switch (currentChartType) {
    case EstimatedEarningsChartTypes.LINE:
      return (
        <LineChart
          yMaxValue={200}
          hideLegend={true}
          data={mapToLineChartData(data, currentGrain)}
          height={size.height}
          width={size.width}
          customDateTimeFormatter={customFormatDateAxisData(currentGrain)}
          tickValues={data.earningDates.map((date) => parseDateKey(date))}
        />
      );
    case EstimatedEarningsChartTypes.VERTICAL_BAR:
      return (
        <VerticalBarChart
          useSingleColor={true}
          colors={[chartColor]}
          hideLegend={true}
          yMaxValue={200}
          data={mapToBarChartData(data, currentGrain)}
          height={size.height}
          width={size.width}
        />
      );
  }
};
function customFormatDateAxisData(currentGrain: ChartGrain): (dateTime: Date) => string {
  return (dateTime) => {
    const month = dateTime.toLocaleString('default', { month: 'short' });
    const year = dateTime.toLocaleString('default', { year: 'numeric' });
    const xValue = currentGrain === ChartGrain.MONTHLY ? `${month} ${year}` : `${year}`;
    return xValue;
  };
}
