import {
  Body1,
  Button,
  Caption1,
  Checkbox,
  Dropdown,
  Field,
  FluentProvider,
  InfoLabel,
  Input,
  Label,
  LabelProps,
  MessageBar,
  Skeleton,
  SkeletonItem,
  Text,
  mergeClasses,
  webLightTheme,
} from '@fluentui/react-components';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { getAppStore, onHelpInfoCalloutOpened } from '../../../../../../../@data';
import { FormItem, FormState } from '../../../../../../../components/Form';
import { HelpcenterInfoBubbleV2 } from '../../../../../../../components/HelpcenterInfoBubbleV2';
import messages from '../messages';
import ConnectAppDropdown from './ConnectAppDropdown';
import { useAppPropertyDetailPageStyles } from './styles';

type AppPropertyDetailFormProps = {
  mode: FormState;
  appName: string;
  isAppNameInputDisabled: boolean;
  onAppNameChange: (value: string) => void;
  appUrl: string;
  isAppUrlInputDisabled: boolean;
  onAppUrlChange: (value: string) => void;
  appSearchString: string;
  onAppSearchStringChange: (value: string) => void;
  appAgreement: boolean;
  onAppAgreementChange: (value: boolean) => void;
  appSellerId: string;
  onAppSellerIdChange: (value: string) => void;
  inventoryAppStoreId: string;
  onInventoryAppStoreIdChange: (value: string) => void;
  isSaving: boolean;
  errorState: { appSellerId?: string; appName?: string; appUrl?: string };
};

type AppPropertyDetaulFormType = React.FC<AppPropertyDetailFormProps & InjectedIntlProps>;

const AppPropertyDetailForm: AppPropertyDetaulFormType = ({
  intl: { formatMessage },
  onAppNameChange,
  appName,
  isAppNameInputDisabled,
  onAppUrlChange,
  appUrl,
  isAppUrlInputDisabled,
  appAgreement,
  onAppAgreementChange,
  appSellerId,
  onAppSellerIdChange,
  inventoryAppStoreId,
  onInventoryAppStoreIdChange,
  isSaving,
  errorState,
}) => {
  const classNames = useAppPropertyDetailPageStyles();
  const isEditMode = window.location.pathname.includes('editApp');
  const { helpInfo, locale } = getAppStore();
  const renderSkeleton = () => (
    <Skeleton>
      <SkeletonItem />
    </Skeleton>
  );

  return (
    <>
      <FluentProvider theme={webLightTheme}>
        <FormItem>
          <Body1>{formatMessage(messages.appPlatformInfo)}</Body1>
        </FormItem>

        <FormItem id="appInventoryPlatform">
          <Text block className={mergeClasses(classNames.textTitle, classNames.sectionTitleOffset)}>
            {formatMessage(messages.appPlatformTitleLabel)}
          </Text>
          <Dropdown className={classNames.platformDropdown} placeholder={formatMessage(messages.appPlatformWindowsUWPLabel)} disabled />
        </FormItem>

        <FormItem id="appInventoryConnectAppId">
          <Field
            label={{
              children: (_: unknown, slotProps: LabelProps) => (
                <Label className={classNames.sectionTitleOffset}>
                  {formatMessage(messages.appPlatformConnectLiveAppTitleLabel)}
                  <Button
                    className={classNames.infoButton}
                    appearance="transparent"
                    onClick={() => onHelpInfoCalloutOpened('pop_PCv4_UniversalBlockSetting', locale)}
                  >
                    <InfoLabel info={<HelpcenterInfoBubbleV2 html={helpInfo} />} />
                  </Button>
                </Label>
              ),
            }}
            aria-required={true}
            className={classNames.sectionTitle}
          >
            <ConnectAppDropdown inventoryAppStoreId={inventoryAppStoreId} onChange={onInventoryAppStoreIdChange} isSaving={isSaving} />
          </Field>
        </FormItem>

        <FormItem id="appInventoryName">
          <Field
            label={formatMessage(messages.appNameFieldLabel)}
            required={true}
            aria-required={true}
            className={classNames.sectionTitle}
            {...(errorState.appName && { validationMessage: errorState.appName, validationState: 'error' })}
          >
            {isSaving ? (
              renderSkeleton()
            ) : (
              <Input
                id="appInventoryName"
                value={appName}
                placeholder={formatMessage(messages.appNameFieldPlaceholder)}
                disabled={isAppNameInputDisabled}
                onChange={(_, data) => onAppNameChange(data.value.slice(0, 200))} // limit to 200 characters
              />
            )}
          </Field>
        </FormItem>

        {!isEditMode && (
          <FormItem id="appInventorySellerId">
            <Field
              label={{
                children: (_: unknown, slotProps: LabelProps) => (
                  <Label className={classNames.sectionTitleOffset}>
                    {formatMessage(messages.appSellerIdFieldLabel)}
                    <Button
                      className={classNames.infoButton}
                      appearance="transparent"
                      onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
                    >
                      <InfoLabel info={<HelpcenterInfoBubbleV2 html={helpInfo} />} />
                    </Button>
                  </Label>
                ),
              }}
              aria-required={true}
              className={classNames.sectionTitle}
              {...(errorState.appSellerId && { validationMessage: errorState.appSellerId, validationState: 'error' })}
            >
              {isSaving ? (
                renderSkeleton()
              ) : (
                <Input
                  id="appInventorySellerId"
                  value={appSellerId}
                  placeholder={formatMessage(messages.appSellerIdFieldPlaceholder)}
                  disabled={isAppNameInputDisabled}
                  onChange={(_, data) => onAppSellerIdChange(data.value)}
                />
              )}
            </Field>
          </FormItem>
        )}

        <FormItem id="appInventoryUrl">
          <Field
            className={classNames.sectionTitle}
            label={formatMessage(messages.appUrlFieldLabel)}
            required={true}
            aria-required={true}
            {...(errorState.appUrl && { validationMessage: errorState.appUrl, validationState: 'error' })}
          >
            {isSaving ? (
              renderSkeleton()
            ) : (
              <Input
                type="url"
                contentBefore={<span className={classNames.urlPrefix}>https://</span>}
                id="appInventoryUrl"
                value={appUrl}
                placeholder={formatMessage(messages.appUrlFieldPlaceholder)}
                disabled={isAppUrlInputDisabled}
                onChange={(_, data) => onAppUrlChange(data.value)}
              />
            )}
          </Field>
        </FormItem>

        {!isEditMode && (
          <>
            <Field label={formatMessage(messages.appAgreement)} required={true} aria-required={true} className={classNames.sectionTitle} />
            <Caption1 block className={classNames.agreementContent}>
              {formatMessage(messages.appAgreementSection1)}
            </Caption1>
            <Caption1 block className={classNames.agreementContent}>
              {formatMessage(messages.appAgreementSection2)}
            </Caption1>
            {isSaving ? (
              <div className={classNames.skeletonWrapper}>{renderSkeleton()}</div>
            ) : (
              <Checkbox
                className={classNames.agreementTitle}
                id="appInventoryAgreement"
                checked={appAgreement}
                label={formatMessage(messages.appAgreementCheckboxLabel)}
                onChange={(ev, data) => onAppAgreementChange(!!data.checked)}
              />
            )}
            <MessageBar className={classNames.infoMsgBar} intent="info">
              <Caption1>{formatMessage(messages.coopaTnCInfo)}</Caption1>
            </MessageBar>
          </>
        )}
      </FluentProvider>
    </>
  );
};

export default injectIntl(AppPropertyDetailForm);
