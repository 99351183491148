import { DEFAULT_CURRENCY_CODE } from '../../../constants/AppConstants';
import { roundNumber } from '../../../utils';
import { getStore } from '../../ad-management/Account/Settings/@data/store/store';
import { TythonColumnsEnum } from '../@data/store/schema/ColumnsEnum';
export function roundMetric(metric: TythonColumnsEnum, value, toString = false) {
  if (!value) {
    switch (metric) {
      case TythonColumnsEnum.Revenue:
      case TythonColumnsEnum.Viewability:
      case TythonColumnsEnum.FillRate:
      case TythonColumnsEnum.WinRate:
      case TythonColumnsEnum.CTR:
      case TythonColumnsEnum.eCPM:
      case TythonColumnsEnum.AdECPM:
      case TythonColumnsEnum.AdCTR:
        return toString ? convertToTwoDecimalPlaces(value) : 0;
      default:
        return 0;
    }
  }
  switch (metric) {
    case TythonColumnsEnum.Revenue:
    case TythonColumnsEnum.Viewability:
    case TythonColumnsEnum.FillRate:
    case TythonColumnsEnum.WinRate:
    case TythonColumnsEnum.CTR:
    case TythonColumnsEnum.eCPM:
    case TythonColumnsEnum.AdECPM:
    case TythonColumnsEnum.AdCTR:
      return toString ? convertToTwoDecimalPlaces(value) : roundNumber(value, 2);
    default:
      return value;
  }
}
export function formatMetric(metric: TythonColumnsEnum, value, locale: string) {
  if (!value) {
    return '';
  }
  const { systemPreferences } = getStore().active;

  switch (metric) {
    case TythonColumnsEnum.ImpressionRequests:
    case TythonColumnsEnum.ImpressionsReturned:
    case TythonColumnsEnum.ImpressionsServed:
    case TythonColumnsEnum.ImpressionsViewed:
    case TythonColumnsEnum.Clicks:
    case TythonColumnsEnum.WinningBids:
    case TythonColumnsEnum.AdClicks:
      return nFormatter(value, 1);
    case TythonColumnsEnum.Revenue:
    case TythonColumnsEnum.eCPM:
    case TythonColumnsEnum.AdECPM:
      return value.toLocaleString(locale, { style: 'currency', currency: systemPreferences?.currency || DEFAULT_CURRENCY_CODE });
    case TythonColumnsEnum.Viewability:
    case TythonColumnsEnum.FillRate:
    case TythonColumnsEnum.WinRate:
    case TythonColumnsEnum.CTR:
    case TythonColumnsEnum.AdCTR:
      return Number(value).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    default:
      return value;
  }
}

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Q' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const format = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return format ? (num / format.value).toFixed(digits).replace(rx, '$1') + format.symbol : '0';
}

export function convertToTwoDecimalPlaces(num: number): string {
  const roundedNumber = Math.round(num * 100) / 100; // Rounds the number to two decimal places
  return roundedNumber.toFixed(2); // Converts the rounded number to a string with two decimal places
}
