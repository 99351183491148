export const DEFAULT_AD_PREVIEW_FONT = 'Arial';
export const DEFAULT_AD_PREVIEW_BACKGROUND_COLOR = '#ffffff';
export const DEFAULT_AD_PREVIEW_BUTTON_BACKGROUND_COLOR = '#ffffff';
export const DEFAULT_AD_PREVIEW_TITLE_BACKGROUND_COLOR = '#3a474e';

export enum TythonAdTypes {
  Native = 'Native',
  Display = 'Display',
}

export enum TythonAdUnitDisplayAdTypeLayout {
  Horizontal = 'Horizontal',
  Square = 'Square',
  Vertical = 'Vertical',
}

export type TythonAdUnitDisplayAdTypeLayoutKey = keyof typeof TythonAdUnitDisplayAdTypeLayout;

export const TythonAdUnitDisplayAdTypeLayoutLabel: { [key in TythonAdUnitDisplayAdTypeLayoutKey]: string } = {
  Horizontal: 'displayAdHorizontal',
  Square: 'displayAdSqaure',
  Vertical: 'displayAdVertical',
};
