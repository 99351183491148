export enum UserConstants {
  publisherId = 'publisherId',
  publisherName = 'publisherName',
  userId = 'userId',
  userName = 'userName',
  trackingId = 'trackingId',
  url = 'url',
  sessionId = 'sessionId',
  logMessage = 'logMessage',
  logLevel = 'logLevel',
  userEmail = 'userEmail',
}
