import { mutatorAction } from 'satcheljs';
import { IStringDictionary } from '../../../../@data';
import { ISignUpViewModel } from '../store/schema';
import { getSignUpStore } from '../store/store';

export const onAccountChanged = mutatorAction('ON_ACCOUNT_CHANGED', function accountChanged(account: ISignUpViewModel) {
  getSignUpStore().active = account;
});

export const onSignUpErrorChanged = mutatorAction('ON_SIGNUP_ERROR_CHANGED', function signUpErrorChanged(values?: IStringDictionary) {
  getSignUpStore().errors = values || {};
});
