import { defineMessages } from 'react-intl';

export default defineMessages({
  profileNotImplemented: {
    id: 'profile.notImplemented',
    defaultMessage: 'Profile Not Implemented Yet',
  },
  profileSignOut: {
    id: 'profile.signout',
    defaultMessage: 'Sign out',
  },
  userSettings: {
    id: 'profile.userSettings',
    defaultMessage: 'User settings',
  },
  auditHistory: {
    id: 'profile.auditHistory',
    defaultMessage: 'Audit history',
  },
});
