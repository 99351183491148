import { defineMessages } from 'react-intl';

export default defineMessages({
  websiteUrl: {
    id: 'admanagement.property.websiteUrl',
    defaultMessage: 'Website URL',
  },
  siteUrl: {
    id: 'admanagement.property.siteUrl',
    defaultMessage: 'Site Domain',
  },
  websiteUrlExample: {
    id: 'admanagement.property.websiteUrlExample',
    defaultMessage: 'Enter your Site Domain. ex: www.website.com',
  },
  websiteUrlInvalidMsg: {
    id: 'admanagement.property.websiteUrlInvalidMsg',
    defaultMessage: 'Invalid domain',
  },
});
