import { IHomeViewModel } from './IHomeViewModel';

export interface IHomeViewState {
  active: IHomeViewModel;
  isStatusLoading: boolean;
  isSetupDone: boolean;
  adUnitStatus?: HomePageStatus;
  siteStatus?: HomePageStatus;
  profileStatus?: HomePageStatus;
  isFRECardShown?: boolean;
}

export enum HomePageStatus {
  NotStarted = 'NotStarted',
  AwaitingVerification = 'AwaitingVerification',
  Completed = 'Completed',
  Rejected = 'Rejected',
}
