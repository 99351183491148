import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../../logging/src';
import {
  getAppStore,
  onNotificationChanged,
  postEntity,
  showErrorToastNotification,
  showSuccessToastNotification,
  UserInvitation,
  UserInvitationResend,
} from '../../../../../../@data';
import messages from '../../UserInvitations.messages';
import { onResendUserInvitation } from '../actions/userInvitationActions';
import { updateUserInvitationExpiry } from '../mutatorActions';

orchestrator(onResendUserInvitation, async (msg) => {
  const { publisher, userContext } = getAppStore();

  if (publisher && userContext) {
    const newInvite = new UserInvitation();
    newInvite.id = msg.userInvite.id;
    try {
      await postEntity([publisher, newInvite], UserInvitationResend, newInvite, userContext);

      /* Updating the user invitation expiry date on successful resend */
      updateUserInvitationExpiry(msg.userInvite.id, new Date(new Date().setDate(new Date().getDate() + 30)));

      showSuccessToastNotification({
        textMessageDescriptor: messages.userInvitationResendSuccessfully,
        intlMessageValues: { email: msg.userInvite.email },
      });
    } catch (e) {
      Log.error(`Failed to resend email invite, error: ${JSON.stringify(e)}`);
      showErrorToastNotification({ textMessageDescriptor: messages.resendEmailInviteFailed });
    }
  } else {
    onNotificationChanged({
      messageBarType: MessageBarType.error,
      textMessageDescriptor: messages.userInvitationInvalidStateReportOrLogin,
    });
  }
});
