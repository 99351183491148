import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../logging/src/Log';
import { getAppStore, onLoadingChanged } from '../../../../@data';
import { postSearch } from '../../../../@data/services/GlobalSearchApiService';
import { ResourceType } from '../../../../@data/store/schema/enums/ResourceType';
import { ResourceTypeDistribution } from '../../../../@data/store/schema/enums/ResourceTypeDistribution';
import { IGlobalSearchResultData } from '../../../../@data/store/schema/interfaces/IGlobalSearchResultData';
import { GlobalSearchRequest } from '../../../../@data/store/schema/models/GlobalSearchRequest';
import { getEnumNames } from '../../../../utils';
import { onSearchTriggered, setData, showMoreLoading } from '../actions/onSearchTriggered';
import { buildSearchResults, getOrderedResources } from '../services/GlobalSearchHelperService';
import { ISearchResultInfo } from '../store/schema/IGlobalSearchResultPageState';
import { getStore } from '../store/store';

const defaultPageToken = '';
const defaultPageSize = 5;
const showMorePagesize = 20;

orchestrator(onSearchTriggered, async (msg) => {
  const { userContext } = getAppStore();
  const store = getStore();
  let resourceTypes: string[] = [];
  let pageSize: number;
  let pageToken = '';

  if (msg.resourceType) {
    pageSize = showMorePagesize;
    const searchResultInfo = store.results.get(msg.resourceType);
    resourceTypes.push(msg.resourceType);
    if (searchResultInfo) {
      pageToken = searchResultInfo.pageToken;
    }
    showMoreLoading(msg.resourceType, true);
  } else {
    pageSize = defaultPageSize;
    pageToken = defaultPageToken;
    resourceTypes = getEnumNames(ResourceType);
    onLoadingChanged(true);
  }

  try {
    const promiseResponses: Promise<IGlobalSearchResultData>[] = [];
    for (const resource of resourceTypes) {
      const reqResourceTypes = [resource];
      /* const crossEntityResourceTypes = crossEntityResourceTypeMap.get(resource);
      if (crossEntityResourceTypes) {
        // eslint-disable-next-line
        crossEntityResourceTypes.map((resourceType: string) => {
          reqResourceTypes.push(resourceType);
        });
      }*/

      const request: GlobalSearchRequest = {
        query: store.searchQuery,
        resourceTypes: reqResourceTypes,
        globalFilters: [],
        options: {
          paginationOptions: { pageSize: pageSize, pageToken: pageToken },
          resultPriorityOptions: { resourceTypeDistribution: ResourceTypeDistribution[ResourceTypeDistribution.DynamicBucketSize] },
        },
      };
      promiseResponses.push(postSearch(request, userContext));
    }

    const responses = await Promise.all(promiseResponses);
    const responseMap: Map<string, ISearchResultInfo> = new Map<string, ISearchResultInfo>();
    const resourceTypeMap: Map<string, number> = new Map<string, number>();

    for (let i = 0; i < resourceTypes.length; i++) {
      // Setting search result items for each resource type
      const searchResultInfo = buildSearchResults(responses[i]);
      responseMap.set(resourceTypes[i], searchResultInfo);
      // Setting search result item count for each resource type
      let totalResultCount = 0;
      const resultMetadata = responses[i].resultMetadata;
      if (resultMetadata) {
        totalResultCount = resultMetadata.ResultCountPerResourceType[resourceTypes[i]];
      }
      resourceTypeMap.set(resourceTypes[i], totalResultCount);
    }

    setData(responseMap, getOrderedResources(resourceTypeMap, store.sourceRef || ''));
    if (msg.resourceType) {
      showMoreLoading(msg.resourceType, false);
    }
  } catch (e) {
    Log.error(`To do error handling in search page: ${JSON.stringify(e)}`);
  }
});
