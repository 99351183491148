import { PublicClientApplication } from '@azure/msal-browser';
import { aadConfig } from './config/pubCenterAAD.config';

/* Singleton class to create PubCenter AAD authentication context using MSAL library */
class PubCenterAuthContext {
  public static initializeAuthContext(): PublicClientApplication {
    return new PublicClientApplication(aadConfig);
  }
}

export const pubcenterApp = PubCenterAuthContext.initializeAuthContext();
