import { useState } from 'react';
import { IGridV2Column, ISelectableRow } from '../GridV2.types';

function flattenNestedObject(obj, parentKey = '') {
  let result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        const nestedValues = flattenNestedObject(obj[key], newKey);
        result = { ...result, ...nestedValues };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
}

// tslint:disable-next-line: no-any
export const useSearchableGrid = (columns: IGridV2Column[], items: any[], updateSearchWord?: (word: string) => void) => {
  const [searchWord, setSearchWord] = useState('');

  function handleSearch(value: string): ISelectableRow[] {
    setSearchWord(value);
    updateSearchWord?.(value);
    const filteredItems = items.filter((item) => {
      item = flattenNestedObject(item);

      // if any column has a search function, then return true
      if (columns.filter((col) => col.isVisible && col.searchFunction).some((col) => col?.searchFunction?.(item, value))) {
        return true;
      }

      return Object.entries(item)
        .map(([IKey, IValue]) => {
          const column = columns.find((i) => i.key === IKey && i.isVisible);
          return IValue && column?.isSearchable && String(IValue).toLowerCase().includes(value.toLowerCase());
        })
        .some(Boolean);
    });

    return filteredItems;
  }

  return { handleSearch, searchWord };
};
