import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { Publisher, User, getAppStore, isPublisherProfileDataPresent, onPublisherChanged } from '../../../../../@data';
import { fetchOneVetReviewStatus } from '../../../../../@data/services/OneVetIntegrationService';
import { isPrimaryUserDataPresent } from '../../../Profile/@data';
import { setPrimaryUserDetailsAction } from '../../../Profile/@data/actions';
import { fetchPublisherProfileDetails } from '../../../Profile/@data/services';
import { fetchUserDetails } from '../../../Profile/@data/services/fetchUserDetails';
import { triggerOneVetAPIs } from '../actions';

orchestrator(triggerOneVetAPIs, async ({ eventType, useCache = true }) => {
  const sessionId = sessionStorage.getItem('session_id');

  // if (process.env.REACT_APP_ENV === 'test' || !sessionId) {
  //   return;
  // }

  // Skip API in case review status is present in cache
  if (useCache && sessionStorage.getItem('review_status')) {
    return;
  }

  const { userContext, publisher } = getAppStore();

  if (userContext && publisher && sessionId) {
    Log.write(`Triggering oneVetAPI ${sessionId}`);

    let publisherProfileDetails = getAppStore().publisher;
    let userDetails: User = new User();
    if (!isPublisherProfileDataPresent()) {
      publisherProfileDetails = await fetchPublisherProfileDetails(new Publisher(publisher.id));
      onPublisherChanged(publisherProfileDetails);
    }
    if (!isPrimaryUserDataPresent()) {
      userDetails = await fetchUserDetails(new User(userContext.id));
      setPrimaryUserDetailsAction(userDetails);
    }

    fetchOneVetReviewStatus(eventType, publisherProfileDetails, userDetails);
  } else {
    Log.error('Could not trigger OVDF API: userContext or publisher or sessionId is null');
  }
});
