import { defineMessages } from 'react-intl';

export default defineMessages({
  invite: {
    id: 'user.invite',
    defaultMessage: 'Invite User',
  },
  notFound: {
    id: 'user.notFound',
    defaultMessage: 'No users found for this publisher, create one.',
  },
  notInvitationFound: {
    id: 'user.notInvitationFound',
    defaultMessage: 'No user invitations found for this publisher, create one.',
  },
  name: {
    id: 'userpage.name',
    defaultMessage: 'Name',
  },
  emailAddress: {
    id: 'userpage.emailAddress',
    defaultMessage: 'E-mail address',
  },
  resendInvitation: {
    id: 'userpage.resendInvitation',
    defaultMessage: 'Re-send',
  },
  deleteInvitation: {
    id: 'userpage.deleteInvitation',
    defaultMessage: 'Delete Invitation',
  },
  deleteInvitationDescription: {
    id: 'userpage.deleteInvitationDescription',
    defaultMessage: 'Are you sure you want to delete invitation?',
  },
  noPublisherSelected: {
    id: 'user.noPublisherSelected',
    defaultMessage: 'Select a publisher.',
  },
  deactivateTitle: {
    id: 'user.deactivateTitle',
    defaultMessage: 'Do you want to deactivate user(s)?',
  },
  username: {
    id: 'user.userName',
    defaultMessage: 'User name',
  },
  dateJoined: {
    id: 'user.dateJoined',
    defaultMessage: 'Date joined',
  },
  userRole: {
    id: 'user.userRole',
    defaultMessage: 'Role',
  },
  lastActivity: {
    id: 'user.lastActivity',
    defaultMessage: 'Last activity',
  },
  dateExpires: {
    id: 'user.dateExpires',
    defaultMessage: 'Date Expires',
  },
  tokenPanelUserNameLabel: {
    id: 'app.tokenPanelUserNameLabel',
    defaultMessage: 'User name',
  },
  tokenPanelEmailLabel: {
    id: 'app.tokenPanelEmailLabel',
    defaultMessage: 'E-mail address',
  },
  developerTokenLabel: {
    id: 'app.developerTokenLabel',
    defaultMessage: 'Developer Token',
  },
  tokenServiceAgreementLabelPrefix: {
    id: 'app.tokenServiceAgreementLabelPrefix',
    defaultMessage:
      'By accessing the Microsoft pubCenter API, you agree that the Microsoft pubCenter API is part of the services made available to you under the Microsoft Developer Services Agreement (see ',
  },
  tokenServiceAgreementLabelLink: {
    id: 'app.tokenServiceAgreementLabelLink',
    defaultMessage: 'https://go.microsoft.com/fwlink/?linkid=861648',
  },
  tokenServiceAgreementLabelSuffix: {
    id: 'app.tokenServiceAgreementLabelSuffix',
    defaultMessage: ' or a succesor location (the “Agreement”)) and accept the terms and conditions in the agreement.',
  },
  tokenStatusLabel: {
    id: 'app.tokenStatusLabel',
    defaultMessage: 'Token Status',
  },
  tokenPanelGenerateButtonAriaDesc: {
    id: 'app.tokenPanelGenerateButtonAriaDesc',
    defaultMessage: 'Click to generate a new token for this user',
  },
  tokenPanelGenerateButtonLabel: {
    id: 'app.tokenPanelGenerateButtonLabel',
    defaultMessage: 'Generate Token',
  },
  tokenPanelFetchFailLabel: {
    id: 'app.tokenPanelFetchFailLabel',
    defaultMessage: 'Fetching dev token failed. Please reopen this panel to try again',
  },
  zeroResultTitleUser: {
    id: 'channel.zeroResultTitle',
    defaultMessage: 'No users yet',
  },
  zeroResultTitleUserInvitations: {
    id: 'channel.zeroResultTitleUserInvitations',
    defaultMessage: 'No user invitations yet',
  },
  zeroResultDescription: {
    id: 'channel.zeroResultDescription',
    defaultMessage: 'You haven’t created any channel yet',
  },
});
