import { MediaType } from '..';
import { Model } from './Model';

export class AccountSKU extends Model {
  public apiPath = undefined;
  public className = 'accountSku';
  public accountId: number;
  public medium: MediaType;
  public sku: string;
}
