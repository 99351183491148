import { ActionButton, IconButton, Panel, PanelType, PrimaryButton, Spinner, getTheme } from '@fluentui/react';
import React, { useState } from 'react';
import AppMessages from '../../../../../../App.messages';
import { COPY_BUTTON_TIMEOUT } from '../../../../../../constants/AppConstants';
import { IRouterWithIntl, injectIntlWithObserver } from '../../../../../../utils';
import { getStore } from '../../../@data';
import { onDevTokenPanelHide } from '../../../@data/actions';
import messages from '../../ManagePage.messages';
import { getClassNames, getStyles } from '../../ManagePage.styles';
import { DevTokenFetch } from '../../ManagePage.types';

export const DevTokenPanel = injectIntlWithObserver((props: IRouterWithIntl) => {
  const [copyButtonType, setCopyButtonType] = useState('Copy');
  const { devTokenPanelParams } = getStore();
  if (!devTokenPanelParams || devTokenPanelParams.panelClosed) {
    return <></>;
  }
  const classNames = getClassNames(getStyles, { theme: getTheme() });
  const { formatMessage } = props.intl;

  const onCopyTokenClick = (copyText) => {
    navigator.clipboard.writeText(copyText);
    setCopyButtonType('Accept');
    setTimeout(() => {
      setCopyButtonType('Copy');
    }, COPY_BUTTON_TIMEOUT);
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.medium}
      onDismiss={() => onDevTokenPanelHide()}
      headerText={formatMessage(messages.tokenStatusLabel)}
      closeButtonAriaLabel={formatMessage(AppMessages.panelCloseButtonAriaDesc)}
    >
      <div className={classNames.tokenPanelsubTitle}>{formatMessage(messages.tokenPanelUserNameLabel)}</div>
      <div className={classNames.tokenPanelsubTitleValue}>{devTokenPanelParams.userName ? devTokenPanelParams.userName : ''}</div>
      <div className={classNames.tokenPanelsubTitle}>{formatMessage(messages.tokenPanelEmailLabel)}</div>
      <div className={classNames.tokenPanelsubTitleValue}>{devTokenPanelParams.email ? devTokenPanelParams.email : ''}</div>
      <div className={classNames.tokenPanelsubTitle}>{formatMessage(messages.developerTokenLabel)}</div>
      <div>
        {!devTokenPanelParams.developerToken ||
        devTokenPanelParams.developerToken === DevTokenFetch.TokenNotFound ||
        devTokenPanelParams.developerToken === DevTokenFetch.ApiCallFailed ? (
          <div>
            {devTokenPanelParams.developerToken === DevTokenFetch.TokenNotFound ||
            devTokenPanelParams.developerToken === DevTokenFetch.ApiCallFailed ? (
              <div>
                {devTokenPanelParams.developerToken === DevTokenFetch.TokenNotFound ? (
                  <PrimaryButton
                    className={classNames.tokenPanelsubTitle}
                    onClick={() => {
                      devTokenPanelParams.onGenerateDevToken();
                    }}
                    text={formatMessage(messages.tokenPanelGenerateButtonLabel)}
                    ariaDescription={formatMessage(messages.tokenPanelGenerateButtonAriaDesc)}
                  />
                ) : (
                  <div className={classNames.tokenFetchFail}>
                    <IconButton iconProps={{ iconName: 'Warning' }} className={classNames.tokenPanelAgreementBtn} />
                    <span>{formatMessage(messages.tokenPanelFetchFailLabel)}</span>
                  </div>
                )}
              </div>
            ) : (
              <div className={classNames.tokenSpinner}>
                <Spinner label={devTokenPanelParams.SpinnerText} ariaLive="assertive" labelPosition="right" />
              </div>
            )}
          </div>
        ) : (
          <div className={classNames.tokenPanelDevToken}>
            <span id="devToken">{devTokenPanelParams.developerToken}</span>
            <ActionButton
              menuIconProps={{ iconName: copyButtonType }}
              title="Copy"
              onClick={() => onCopyTokenClick(devTokenPanelParams.developerToken)}
              className="button"
            />
          </div>
        )}
      </div>
      <div className={classNames.tokenPanelAgreement}>
        <div className={classNames.tokenPanelInfoButton}>
          <IconButton iconProps={{ iconName: 'Info' }} />
        </div>
        <div className={classNames.tokenPanelAgreementText}>
          {formatMessage(messages.tokenServiceAgreementLabelPrefix)}
          <a href={formatMessage(messages.tokenServiceAgreementLabelLink)}>{formatMessage(messages.tokenServiceAgreementLabelLink)}</a>
          {formatMessage(messages.tokenServiceAgreementLabelSuffix)}
        </div>
      </div>
    </Panel>
  );
});
