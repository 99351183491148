import { defineMessages } from 'react-intl';

export default defineMessages({
  Failed: {
    id: 'auditHistory.Failed',
    defaultMessage: 'failed',
  },
  Settled: {
    id: 'auditHistory.Settled',
    defaultMessage: 'settled',
  },
  Processing: {
    id: 'auditHistory.Processing',
    defaultMessage: 'processing',
  },
  TransactionTitle: {
    id: 'auditHistory.TransactionTitle',
    defaultMessage: 'Transaction {status}',
  },
  TransactionMessage: {
    id: 'auditHistory.TransactionMessage',
    defaultMessage: 'Transaction of {n} USD {status}',
  },
  AdBlockCategories: {
    id: 'auditHistory.AdBlockCategories',
    defaultMessage: 'ad category block',
  },
  AdBlockCategoriesPlural: {
    id: 'auditHistory.AdBlockCategories',
    defaultMessage: 'ad category blocks',
  },
  UniversalAdBlockCategories: {
    id: 'auditHistory.UniversalAdBlockCategories',
    defaultMessage: 'universal category block',
  },
  UniversalAdBlockCategoriesPlural: {
    id: 'auditHistory.UniversalAdBlockCategories',
    defaultMessage: 'universal category blocks',
  },
  UniversalAdBlockStatus: {
    id: 'auditHistory.UniversalAdBlockStatus',
    defaultMessage: 'Universal ad block status',
  },
  PaymentAccountStatus: {
    id: 'auditHistory.PaymentAccountStatus',
    defaultMessage: 'Payment account status',
  },
  AdblocksUpdated: {
    id: 'auditHistory.AdblocksUpdated',
    defaultMessage: 'Ad blocks updated',
  },
  AdBlockStatusEnabled: {
    id: 'auditHistory.AdBlockStatusEnabled',
    defaultMessage: 'Ad block status enabled',
  },
  AdBlockStatusDisabled: {
    id: 'auditHistory.AdBlockStatusDisabled',
    defaultMessage: 'Ad block status disabled',
  },
  ClarityProjectID: {
    id: 'auditHistory.ClarityProjectID',
    defaultMessage: 'Clarity project ID {id} added ',
  },
  ServingStatus: {
    id: 'auditHistory.ServingStatus',
    defaultMessage: 'Serving status',
  },
  ClarityStatus: {
    id: 'auditHistory.ClarityStatus',
    defaultMessage: 'Clarity project link is Approved',
  },
  PropertyName: {
    id: 'auditHistory.PropertyName',
    defaultMessage: 'Site name',
  },
  created: {
    id: 'auditHistory.created',
    defaultMessage: 'Created',
  },
  deleted: {
    id: 'auditHistory.deleted',
    defaultMessage: 'Deleted',
  },
  updated: {
    id: 'auditHistory.updated',
    defaultMessage: 'Updated',
  },
  updatedSmallCase: {
    id: 'auditHistory.updatedSmallCase',
    defaultMessage: 'updated',
  },
  changed: {
    id: 'auditHistory.changed',
    defaultMessage: 'changed',
  },
  exported: {
    id: 'auditHistory.exported',
    defaultMessage: 'Exported',
  },
  details: {
    id: 'auditHistory.details',
    defaultMessage: 'details',
  },
  downloaded: {
    id: 'auditHistory.downloaded',
    defaultMessage: 'Downloaded',
  },
  notificationPreferencesTitle: {
    id: 'auditHistory.notificationPreferencesTitle',
    defaultMessage: 'Notification preferences updated',
  },
  ServingStatusNotification: {
    id: 'auditHistory.ServingStatusNotification',
    defaultMessage: 'Serving status notifications are {status}',
  },
  BillingAlertNotification: {
    id: 'auditHistory.BillingAlertNotification',
    defaultMessage: 'Billing alert notifications are {status}',
  },
  InvitedUserNotification: {
    id: 'auditHistory.InvitedUserNotification',
    defaultMessage: 'User invite notifications are {status}',
  },
  ProfileReviewNotification: {
    id: 'auditHistory.ProfileReviewNotification',
    defaultMessage: 'Profile review notifications are {status}',
  },
  BackgroundColor: {
    id: 'BackgroundColor',
    defaultMessage: 'Background color',
  },
  ButtonBackgroundColor: {
    id: 'BackgroundColor',
    defaultMessage: 'Button background color',
  },
  AdUnitTypes: {
    id: 'AdUnitTypes',
    defaultMessage: 'Ad unit type',
  },
  TitleColor: {
    id: 'TitleColor',
    defaultMessage: 'Title color',
  },
  Font: {
    id: 'Font',
    defaultMessage: 'Font',
  },
  addedAs: {
    id: 'auditHistory.addedAs',
    defaultMessage: '{attributeChanged} added as {newValue}',
  },
  selectedAs: {
    id: 'auditHistory.selectdAs',
    defaultMessage: '{attributeChanged} selected as {newValue}',
  },
  updatedMessageFormat: {
    id: 'auditHistory.updatedMessageFormat',
    defaultMessage: '{attributeChanged} changed from {oldValue} to {newValue}',
  },
  LifeCycleStatusID: {
    id: 'auditHistory.LifeCycleStatusID',
    defaultMessage: 'Serving status',
  },
  nameChangedTo: {
    id: 'auditHistory.nameChangedTo',
    defaultMessage: '{oldValue} name changed to {newValue}',
  },
  added: {
    id: 'auditHistory.added',
    defaultMessage: 'added',
  },
  removed: {
    id: 'auditHistory.removed',
    defaultMessage: 'removed',
  },
});
