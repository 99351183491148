import { Account, AccountSKU, getEntity, listEntity, MediaTypeString, Publisher, User, UserContext } from '../../../../../@data';

export const fetchAccountById = async (publisher: Publisher, id: string, userContext?: UserContext): Promise<Account> => {
  const accountId = parseInt(id, 10);
  const account = new Account(accountId);
  return await getEntity<Account>([publisher, account], userContext);
};

export const fetchCcmtAccountById = async (publisher: Publisher, accountId: number, userContext?: UserContext): Promise<Account> => {
  const account = new Account(accountId);
  const urlParams = new URLSearchParams([['isCCMTOnly', 'true']]);
  return await getEntity<Account>([publisher, account], userContext, undefined, urlParams);
};

export const fetchAllMediumAccountSkus = async (publisher: Publisher, id: string, userContext?: UserContext) => {
  // return [new AccountSKU(1, 'some sku2'), new AccountSKU(2, 'some sku1')];

  const allMediaTypes: string[] = [];

  Object.keys(MediaTypeString).forEach((mediaTypeKey) => {
    allMediaTypes.push(MediaTypeString[mediaTypeKey]);
  });

  const nonMappedMediums: string[] = [];
  let mediumMappedSkus: AccountSKU[] = [];
  mediumMappedSkus = await Promise.all(
    allMediaTypes.map(async (allMediaType) => {
      const enparams = new URLSearchParams([
        ['medium', allMediaType],
        ['accountId', id],
      ]);
      try {
        return await getEntity([publisher], userContext, AccountSKU, enparams);
      } catch {
        nonMappedMediums.push(allMediaType);
        return new AccountSKU();
      }
    })
  );

  return { mediumMappedSkus: mediumMappedSkus, nonMappedMediums: nonMappedMediums };
};

export const fetchAllAvailableUsers = async (publisher: Publisher, userContext?: UserContext): Promise<User[]> => {
  const users = await listEntity<User>([publisher], userContext, User);

  if (userContext) {
    if (users && (users.length === 0 || !users.some((u) => u.id === userContext!.id))) {
      const currentUser = new User();
      currentUser.id = userContext.id;
      currentUser.username = userContext.email;
      users.push(currentUser);
    }
  }
  return users;
};
