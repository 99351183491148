import { IPubcenterSpacing } from '../../../theme';
import { IGridFilterStyleProps, IGridFilterStyles } from './GridFilter.types';

export const getStyles = (props: IGridFilterStyleProps): IGridFilterStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;

  return {
    root: ['ms-GridFilter'],
    filterBarFocused: {
      backgroundColor: theme.palette.neutralLighter,
      display: 'flex',
      marginBottom: spacing.size16,
      position: 'relative',
    },
    filterBarUnFocused: {
      marginBottom: spacing.size16,
      marginLeft: spacing.size24,
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    filterInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.neutralSecondary,
      margin: '8px 0 8px 16px',
      height: 28,
      borderRadius: '2px',
      color: theme.palette.white,
      cursor: 'pointer',
    },
    highlightedFilterInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.themePrimary,
      margin: '8px 0 8px 16px',
      height: 28,
      borderRadius: '2px',
      color: theme.palette.white,
      cursor: 'pointer',
    },
    filterInfoFocused: {
      fontSize: theme.fonts.medium.fontSize,
      alignContent: 'center',
      padding: '4px 16px',
      lineHeight: '19px',
      display: 'inline-block',
    },
    filterInfoSelectors: {
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.neutralPrimaryAlt,
        },
      },
    },
    highlightedFilterInfoSelectors: {
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.themeDark,
        },
      },
    },
    filterCloseIcon: {
      width: 12,
      padding: spacing.size8,
    },
    filterIcon: {
      padding: '5px 4px',
      color: theme.palette.themePrimary,
    },
    filterInfoUnfocused: {
      color: theme.palette.themePrimary,
      textAlign: 'center',
      verticalAlign: 'center',
      cursor: 'pointer',
      padding: '3px 4px',
    },
    filterMenuListItem: {
      fontSize: theme.fonts.medium.fontSize,
      padding: spacing.size8,
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.neutralLighter,
        },
      },
      cursor: 'pointer',
    },
    filterSelectionCallout: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing.size16,
    },
    filterSelectionCalloutTitle: {
      lineHeight: '28px',
      textAlign: 'left',
      fontSize: theme.fonts.large.fontSize,
      marginBottom: spacing.size8,
    },
    filterSelectionToolBar: {
      display: 'flex',
      flexDirection: 'row',
    },
    filterSelectionApplyButton: {
      marginRight: spacing.size16,
      backgroundColor: theme.palette.themePrimary,
      borderColor: theme.palette.white,
      color: theme.palette.white,
      borderRadius: '2px',
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.themeDarkAlt,
          color: theme.palette.white,
        },
      },
    },
    filterSelectionCancelButton: {
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.neutralLighter,
        },
      },
    },
    disabledApplyButton: {
      marginRight: spacing.size16,
      backgroundColor: theme.palette.neutralQuaternaryAlt,
      borderColor: theme.palette.white,
      borderRadius: '2px',
    },
    viewAllButton: {
      color: theme.palette.themePrimary,
      textDecoration: 'underline',
      display: 'flex run-in',
      height: 24,
      width: 80,
    },
    clearAllButton: {
      color: theme.palette.themePrimary,
      textDecoration: 'underline',
      display: 'flex run-in',
      height: 24,
      width: 80,
      top: 10,
    },
    filterSummaryCallout: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 5px 16px 24px',
      minWidth: 180,
    },
    calloutCloseIcon: {
      marginTop: '1px',
      padding: spacing.size4,
      cursor: 'pointer',
      position: 'absolute',
      right: '2px',
      color: theme.palette.black,
      selectors: {
        '&:focus, &:hover': {
          backgroundColor: theme.palette.neutralLighter,
          color: theme.palette.black,
        },
      },
    },
    filterSummaryCalloutHeader: {
      display: 'flex',
      flexDirection: 'row',
    },
    filterInfoSummaryItem: {
      color: theme.palette.themePrimary,
      verticalAlign: 'center',
      cursor: 'pointer',
      padding: '3px 20px 3px 4px',
    },
    filterSummaryCalloutTitle: {
      lineHeight: '20px',
      textAlign: 'left',
      fontSize: theme.fonts.large.fontSize,
      marginTop: spacing.size12,
      marginBottom: spacing.size4,
    },
    filterListPageStyle: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
    subComponentStyles: {
      iconButton: {
        root: [
          'ms-font-m',
          {
            color: theme.palette.white,
          },
        ],
      },
    },
  };
};
