import { SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { getAppStore, UserInvitation } from '../../../../../@data';
import AppMessages from '../../../../../App.messages';
import { ZeroResults } from '../../../../../components/ZeroResults/ZeroResults';
import { useCanvasTextWidth, useWindowSize } from '../../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../../hooks/useWindowSize/store/store';
import { GridV2, IGridV2Column } from '../../../../../layout/GridPageV2';
import { RoutePrefix } from '../../../../../Routes';
import { injectIntlWithObserver, IRouterWithIntl } from '../../../../../utils';
import { RouteName } from '../../../@data';
import { getStore } from '../../@data';
import { onUserInvitationsChanged, onUserInvitationsGridInitialized } from '../../@data/actions';
import messages from '../ManagePage.messages';
import { getCommandBarItems } from './utils/getCommandBarItems';
import { getGridColumns } from './utils/getGridColumns';

export const UserInvitationsGrid = injectIntlWithObserver((props: IRouterWithIntl) => {
  const { publisher } = getAppStore();
  const { userInvitations, isFetchingUserInvitationsLoading, isFetchingUserInvitations } = getStore();
  const { formatMessage } = props.intl;
  const { windowStoreSerializer } = useWindowSize();
  const { isWindowRegularSize } = windowStoreSerializer(getWindowSizeStore());

  const longestEmail = React.useMemo(
    () => userInvitations?.map((user) => user.email || '').reduce((a, b) => (a.length > b.length ? a : b), '') || '',
    [userInvitations]
  );
  const gridDefaultColumns: IGridV2Column[] = getGridColumns(formatMessage, isWindowRegularSize, useCanvasTextWidth(longestEmail));

  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={!!publisher ? formatMessage(messages.zeroResultTitleUserInvitations) : undefined}
      description={formatMessage(!!publisher ? messages.notInvitationFound : messages.noPublisherSelected)}
      buttonText={formatMessage(messages.invite)}
      onButtonClick={
        !!publisher ? () => props.history.push(`/${RoutePrefix.partnerManagement}/${publisher?.id}/${RouteName.user}/create`) : undefined
      }
    />
  );

  useEffect(() => {
    onUserInvitationsGridInitialized(gridDefaultColumns);
    // eslint-disable-next-line
  }, [publisher]);

  return (
    <GridV2
      {...props}
      entity={UserInvitation}
      pageTitle={{ title: formatMessage(AppMessages.userInvitations), helpId: 'pop_PCv4_UserInvite' }}
      commandBarProps={getCommandBarItems(formatMessage, props.history, props.match)}
      dataGridProps={{
        gridV2Columns: gridDefaultColumns,
        data: publisher ? userInvitations || [] : [],
        setData: onUserInvitationsChanged,
        shimmerLines: 10,
        selectionMode: SelectionMode.single,
        renderZeroItemsComponent: NoResultComponent,
      }}
      isGridReady={!isFetchingUserInvitationsLoading}
      isDataLoading={isFetchingUserInvitations}
      paginationProps={{
        shouldGridHandlePagination: true,
      }}
    />
  );
});
