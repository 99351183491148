import { defineMessages } from 'react-intl';

export const mediationPopupMessages = defineMessages({
  title: {
    id: 'mediationPopupMessages.title',
    defaultMessage: 'Are you sure you want to opt out of mediation?',
  },
  description: {
    id: 'mediationPopupMessages.description',
    defaultMessage: 'Mediation will help boost your revenue for each ad placement.',
  },
  turnOff: {
    id: 'mediationPopupMessages.turnOff',
    defaultMessage: 'Turn off',
  },
});
