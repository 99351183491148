import { CommandBarButton } from '@fluentui/react';
import React from 'react';
import { Model, PopupType, Property, getAccessPermission } from '../../../../@data';
import { hasDeleteAccess } from '../../../../@data/services/UserRoleService';
import AppMessages from '../../../../App.messages';
import { ZeroResults } from '../../../../components/ZeroResults/ZeroResults';
import { CommandBarActions, IBuildCommandBarProps } from '../../../../layout/GridPageV2';
import { setDialogType } from '../../../../layout/GridPageV2/@data/mutatorActions';
import { getTythonAppInventoryCreationRoute, getTythonAppInventoryEditionRoute } from '../../../../utils/routeUtils';
import messages from './messages';

const useAppInventoryGrid = (formatMessage, routeHistory, isWindowRegularSize, publisher) => {
  const NoResultComponent = (
    <ZeroResults
      showDefaultImage
      title={formatMessage(messages.appInventoryZeroResultTitle)}
      description={formatMessage(messages.appInventoryZeroResultDescription)}
      buttonText={formatMessage(messages.appInventoryZeroResultCallToAction)}
      onButtonClick={() => routeHistory.push(getTythonAppInventoryCreationRoute())}
    />
  );

  const gridColumns = [
    {
      columnOrder: 0,
      key: 'name',
      fieldName: 'name',
      name: formatMessage(messages.appNameColumnLabel),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isVisible: true,
      isShowingDisabled: true,
      isExportable: true,
      isResizable: true,
      suppressSorting: false,
    },
    {
      columnOrder: 1,
      key: 'id',
      fieldName: 'id',
      name: formatMessage(messages.appIdColumnLabel),
      minWidth: 100,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isShowingDisabled: true,
      isExportable: true,
      isResizable: true,
      isSortedDescending: true,
    },
    {
      columnOrder: 2,
      key: 'url',
      fieldName: 'url',
      name: formatMessage(messages.appUrlColumnLabel),
      minWidth: 150,
      maxWidth: 150,
      isSearchable: true,
      isResizable: true,
      isVisible: true,
      isShowingDisabled: true,
      isExportable: true,
    },
    {
      columnOrder: 99,
      key: 'actions',
      name: formatMessage(messages.appAdUnitActionsColumnLabel),
      minWidth: 220,
      maxWidth: 220,
      isPadded: true,
      isSearchable: true,
      isVisible: true,
      suppressSorting: true,
      onRender: (item: Property) => {
        const accessPermission = getAccessPermission(item);

        return (
          <div>
            <CommandBarButton
              id={`siteCallout-${item.id}`}
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'edit' }}
              ariaLabel={formatMessage(AppMessages.edit)}
              text={isWindowRegularSize ? formatMessage(AppMessages.edit) : ''}
              disabled={false}
              onClick={() => routeHistory.push(`${getTythonAppInventoryEditionRoute(item.id)}`)}
            />
            <CommandBarButton
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: 'Delete' }}
              text={isWindowRegularSize ? formatMessage(AppMessages.delete) : ''}
              onClick={() => setDialogType(PopupType.DeleteConfirmation)}
              disabled={!hasDeleteAccess(accessPermission)}
            />
          </div>
        );
      },
    },
  ];

  const pageTitle = {
    title: formatMessage(messages.appInventoryTableTitle),
    helpId: 'pop_PCv4_Sites',
  };

  const commandBarItems: IBuildCommandBarProps<Model> = {
    primaryButtonUrl: getTythonAppInventoryCreationRoute(),
    primaryButtonLabel: messages.appAdunitGridCreateButtonLabel,
    includeActions: [CommandBarActions.new],
    includeFarActions: [CommandBarActions.searchBox, CommandBarActions.code, CommandBarActions.download, CommandBarActions.modifyColumns],
    filterItems: [],
    deleteLabels: {
      title: messages.appInventoryDeletePopupTitle,
      description: messages.appInventoryDeletePopupDescription,
    },
  };

  return { gridColumns, pageTitle, commandBarItems, NoResultComponent };
};

export default useAppInventoryGrid;
