import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { getAppStore, onUserContextChanged } from '../../../@data';
import { APP_NAME_PUBCENTER, ENTERPRISE_USER_ACCESS_URL } from '../../../constants/AppConstants';
import { onSwitchAccount } from '../../login/@data';
import '../../login/@data/orchestrators';
import { InvalidUserErrorPage } from './InvalidUser';
import messages from './InvalidUser.messages';
import { getClassNames } from './InvalidUser.styles';

interface IUnauthorizedInternalUserPageProps extends InjectedIntlProps, RouteComponentProps {}

export const UnauthorizedInternalUserPage = injectIntl(function UnauthorizedInternalUserErrorPageComp(
  props: IUnauthorizedInternalUserPageProps
): JSX.Element {
  const { formatMessage } = props.intl;
  const { userContext } = getAppStore();
  const { title: titleStyle } = getClassNames();

  const onSwitchAccountClicked = (): void => {
    if (userContext) {
      onUserContextChanged({ ...userContext });
    }
    onSwitchAccount();
  };

  const onRedirectToEnterpriseUserAccessUI = (): void => {
    window.location.replace(encodeURI(ENTERPRISE_USER_ACCESS_URL));
  };

  const renderTitle = (): JSX.Element => {
    return <h1 className={titleStyle}>{formatMessage(messages.unauthorizedInternalUser, { appName: APP_NAME_PUBCENTER })}</h1>;
  };

  return (
    <InvalidUserErrorPage
      {...props}
      errorImageAltText={formatMessage(messages.imageAlt)}
      renderTitle={renderTitle}
      primaryButtonLabel={formatMessage(messages.requestAccess, { appName: APP_NAME_PUBCENTER })}
      defaultButtonLabel={formatMessage(messages.signInWithMSA)}
      onPrimaryButtonClick={onRedirectToEnterpriseUserAccessUI}
      onDefaultButtonClick={onSwitchAccountClicked}
    />
  );
});
