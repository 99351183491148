import { orchestrator } from 'satcheljs';
import { getAppStore, showErrorToastNotification } from '../../../../../@data';
import { getAccountStatus } from '../../../../../@data/services/PaymentsService';
import messages from '../../AccountPage.messages';
import { setAccountStatusDataAction, setAccountStatusIsLoading } from '../actions';
import { fetchAccountStatus } from '../actions/actions';

orchestrator(fetchAccountStatus, async () => {
  const userContext = getAppStore().userContext;
  const publisher = getAppStore().publisher;
  setAccountStatusIsLoading(true);
  try {
    const data = await getAccountStatus(publisher?.id!, userContext!);
    setAccountStatusDataAction(data);
  } catch (error) {
    showErrorToastNotification({ textMessageDescriptor: messages.loadAccountStatusFailed });
  }
  setAccountStatusIsLoading(false);
});
