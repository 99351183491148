import {
  DATE_RANGE_PICKER_PRESET_CUSTOM,
  DATE_RANGE_PICKER_PRESET_LAST_7_DAYS,
  DATE_RANGE_PICKER_PRESET_LAST_WEEK_SUN_SAT,
  DATE_RANGE_PICKER_PRESET_THIS_WEEK_MON_TODAY,
  DATE_RANGE_PICKER_PRESET_THIS_WEEK_SUN_TODAY,
  DATE_RANGE_PICKER_PRESET_TODAY,
  DATE_RANGE_PICKER_PRESET_YESTERDAY,
} from '../../../../../constants/DataTestIdConstants';
import messages from '../CustomCalender.messages';
import { IDateElements, IDay, RangeType, RangeTypeMessage } from '../CustomCalender.types';

export const getDatesInRange = (startDate: Date, endDate: Date): Date[] => {
  const dateArray: Date[] = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0);
  }
  return dateArray;
};

export const isDayInFuture = (day: IDateElements): boolean => {
  return new Date(day.year, day.month, day.day) > new Date();
};

export const getToday = (): Date[] => {
  return [new Date(), new Date()];
};

export const getYesterday = (): Date[] => {
  const date = new Date();
  date.setDate(date.getDate() - 1);

  return [date, date];
};

export const getSundayToToday = (): Date[] => {
  const today = new Date();
  const sunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());

  return getDatesInRange(sunday, today);
};

export const getMondayToToday = (): Date[] => {
  const today = new Date();
  const mondayOffset = today.getDay() === 0 ? 6 : today.getDay() - 1;
  const monday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - mondayOffset);

  return getDatesInRange(monday, today);
};

export const get7DaysExcludingToday = (): Date[] => {
  const today = new Date();
  const sevenDaysLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return getDatesInRange(sevenDaysLater, yesterday);
};

export const getLastSundayToSaturday = (): Date[] => {
  const today = new Date();
  const lastSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
  const lastSaturday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1);

  return getDatesInRange(lastSunday, lastSaturday);
};

export const getSelectedPeriodLabel = (type: RangeType, formatMessage) => {
  return `${formatMessage(messages[RangeTypeMessage[Object.keys(RangeType)[Object.values(RangeType).indexOf(type)]]])}: `;
};

export const getSelectedStartingDate = (range: Date[], locale: string) => {
  const options: Intl.DateTimeFormatOptions = {};
  options.day = 'numeric';
  options.month = 'short';

  return range[0].toLocaleDateString(locale, options);
};

export const getSelectedEndingDate = (range: Date[], locale: string) => {
  const options: Intl.DateTimeFormatOptions = {};
  options.day = 'numeric';
  options.month = 'short';
  const endDate = range.length - 1;
  if (range[0].getTime() === range[endDate].getTime()) {
    return;
  }

  return ` - ${range[endDate].toLocaleDateString(locale, options)}`;
};

export const getMonthlyCalendar = (year: number, month: number): IDay[] => {
  month = month >= 12 ? 0 : month;
  const calender: IDay[] = [];

  const prevMonth = month >= 0 ? month - 1 : 11;
  const prevYear = month >= 0 ? year : year - 1;

  // Determine the number of days in the previous month
  const prevMonthDays = new Date(prevYear, prevMonth + 1, 0).getDate();

  // Determine the number of days in the current month
  const currentMonthDays = new Date(year, month + 1, 0).getDate();

  // Determine the starting day of the current month (0 is Sunday, 6 is Saturday)
  const firstDay = new Date(year, month, 1).getDay();

  // Determine the total number of days needed to fill the calendar (should be 42)
  const totalDays = 42;

  // Determine the starting day for the calendar
  const day = prevMonthDays - firstDay + 1;

  // Loop through all of the days needed to fill the calendar from the previous month
  for (let index = day; index <= prevMonthDays; index++) {
    calender.push({
      day: index,
      isInCurrentMonth: false,
      fullDay: { day: index, month: prevMonth, year: prevYear },
    });
  }

  // Loop through all of the days needed to fill the calendar from the current month
  for (let index = 1; index <= currentMonthDays; index++) {
    calender.push({ day: index, isInCurrentMonth: true, fullDay: { day: index, month: month, year: prevYear } });
  }

  // Loop through all of the days needed to fill the calendar from the next month
  for (let index = calender.length; index < totalDays; index++) {
    const dayInNextMonth = index - (firstDay + currentMonthDays) + 1;
    const nextMonth = month + 1 >= 12 ? 0 : month + 1;
    const nextMonthYear = month + 1 >= 12 ? prevYear + 1 : prevYear;

    calender.push({
      day: index - (firstDay + currentMonthDays) + 1,
      isInCurrentMonth: false,
      fullDay: { day: dayInNextMonth, month: nextMonth, year: nextMonthYear },
    });
  }

  return calender;
};

export const getPresetDataTestId = (preset: RangeType) => {
  switch (preset) {
    case RangeType.Today:
      return DATE_RANGE_PICKER_PRESET_TODAY;
    case RangeType.Yesterday:
      return DATE_RANGE_PICKER_PRESET_YESTERDAY;
    case RangeType.SundayToday:
      return DATE_RANGE_PICKER_PRESET_THIS_WEEK_SUN_TODAY;
    case RangeType.MondayToday:
      return DATE_RANGE_PICKER_PRESET_THIS_WEEK_MON_TODAY;
    case RangeType.Last7ExcludingToday:
      return DATE_RANGE_PICKER_PRESET_LAST_7_DAYS;
    case RangeType.Last7FromSunToSat:
      return DATE_RANGE_PICKER_PRESET_LAST_WEEK_SUN_SAT;
    case RangeType.Custom:
      return DATE_RANGE_PICKER_PRESET_CUSTOM;
  }
};
