import { IPubcenterSpacing } from '../../../theme';
import { IBulkUploadStyleProps, IBulkUploadStyles } from './bulkUpload.types';

export const getStyles = (props: IBulkUploadStyleProps): IBulkUploadStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  return {
    root: {},
    uploadDescription: [
      'ms-fontWeight-regular',
      {
        fontSize: 12,
        paddingTop: spacing.size8
      }
    ]
  };
};
