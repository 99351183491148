import {
  Account,
  getAppStore,
  getEntity,
  isTythonSelfServeUser,
  onAccountChanged,
  onPublisherChanged,
  onSetFirstPartyBoost,
  Publisher,
} from '../../../../@data';
import { FormState } from '../../../../components/Form';
import { getMultiStepperRoute } from '../../../../layout/DetailPageWithStepper/utils/getMultiStepperRoute';
import { RoutePrefix } from '../../../../Routes';
import { RouteName } from '../store/store';

export async function setPublisherAndAccountContext(publisher: Publisher, account?: Account) {
  const appStore = getAppStore();
  if (appStore.publisher === undefined || appStore.publisher.id !== publisher.id) {
    const publisherAsync = await getEntity<Publisher>([publisher], appStore.userContext);
    onSetFirstPartyBoost(publisherAsync.publisherTier);

    if (publisherAsync) {
      const changedPublisher = new Publisher(publisherAsync.id, publisherAsync.name);
      changedPublisher.publisherTier = publisherAsync.publisherTier;
      onPublisherChanged(changedPublisher);
    } else {
      onPublisherChanged(undefined);
    }
  }

  if (!!account) {
    if (appStore.account === undefined || appStore.account.id !== account.id) {
      if (appStore.account && appStore.account.id !== account.id && appStore.account.id === 0) {
        // to allow adunit edit while all accounts option is selected
        return;
      }
      const accountAsync = await getEntity([publisher, account], appStore.userContext);
      if (accountAsync) {
        onAccountChanged(new Account(accountAsync.id, accountAsync.name));
      } else {
        onAccountChanged(undefined);
      }
    }
  }
}

export function createReturnUrl(
  entity: RouteName,
  publisherId?: string,
  accountId?: string,
  id?: string,
  formState?: FormState,
  step?: number,
  showExtraBtns?: boolean
): string {
  let returnUrl = `/${RoutePrefix.adManagement}/${RouteName[entity]}`;

  switch (entity) {
    case RouteName.property:
      if (publisherId && !isTythonSelfServeUser()) {
        returnUrl = `/${RoutePrefix.adManagement}/${publisherId}/${RouteName[entity]}`;
      } else if (publisherId && isTythonSelfServeUser()) {
        if (formState !== undefined) {
          returnUrl = getMultiStepperRoute(RouteName[entity], formState, publisherId, accountId, id, step);
        } else {
          returnUrl = `/${RoutePrefix.adManagement}/${publisherId}/${RouteName[entity]}`;
        }
      }
      break;
    case RouteName.adunit:
      if (publisherId && accountId === '0') {
        returnUrl = `/${RoutePrefix.adManagement}/${publisherId}/${RouteName[entity]}`;
      } else if (publisherId && accountId && formState !== undefined) {
        returnUrl = getMultiStepperRoute(RouteName[entity], formState, publisherId, accountId, id, step, showExtraBtns);
      } else {
        returnUrl = `/${RoutePrefix.adManagement}/${publisherId}-${accountId}/${RouteName[entity]}`;
      }
      break;
    case RouteName.inventory:
    case RouteName.adfilter:
    case RouteName.channel:
    case RouteName.default:
      if (publisherId && accountId) {
        returnUrl = `/${RoutePrefix.adManagement}/${publisherId}-${accountId}/${RouteName[entity]}`;
      }
  }
  return returnUrl;
}
