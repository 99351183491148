import { MessageBarType } from '@fluentui/react';
import { orchestrator } from 'satcheljs';
import { getAppStore, onLoadingChanged, onNotificationChanged } from '../../../../../@data';
import { validateSchema } from '../../../../../utils';
import messages from '../../AdReview/AdCreativePage.messages';
import { onAdCreativeAdPerfDataChanged, onAdCreativeRefreshDataChanged } from '../actions';
import { getAdPerfData } from '../services/AdCreativeService';
import { TARGET_CUSTOMER_ID } from '../services/utility/constants';
import { constructAdPerfRequest } from '../services/utility/utility';
import { converterIn } from '../store/adQualityConverter';
import { adCreativeViewErrorModel } from '../store/schema/AdCreativeViewErrorModel';
import { getStore } from '../store/store';

orchestrator(onAdCreativeRefreshDataChanged, async (msg) => {
  const { publisher } = getAppStore();
  const { active, adDescription } = getStore();
  if (publisher && publisher.id) {
    try {
      await validateSchema(adCreativeViewErrorModel(), active);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adqualityProvideSearchTerm,
        messageBarType: MessageBarType.severeWarning,
      });

      return;
    }

    try {
      onLoadingChanged(true);
      const adSource = active.selectedAdSource;
      if (!adSource) {
        return;
      }
      const data = converterIn(adSource, true);
      const param = new URLSearchParams([[TARGET_CUSTOMER_ID, publisher.id.toString()]]);
      const adPerfDataEnhanced = await getAdPerfData(constructAdPerfRequest(active, data, publisher, undefined, adDescription), param);
      onAdCreativeAdPerfDataChanged(adPerfDataEnhanced);
    } catch (e) {
      onNotificationChanged({
        textMessageDescriptor: messages.adCreativeServiceLoadEntityFailed,
        messageBarType: MessageBarType.severeWarning,
      });
    } finally {
      onLoadingChanged(false);
    }
  } else {
    onNotificationChanged({
      textMessageDescriptor: messages.adqualityInvalidState,
      messageBarType: MessageBarType.severeWarning,
    });
  }
});
