import {
  Body1,
  Body1Strong,
  Body1Stronger,
  Button,
  Caption1,
  Caption1Strong,
  Dropdown,
  Field,
  InfoLabel,
  Input,
  Label,
  LabelProps,
  MessageBar,
  MessageBarBody,
  Option,
  Radio,
} from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getAppStore, onHelpInfoCalloutOpened } from '../../../../../@data';
import { ColorPicker } from '../../../../../components/ColorPicker/ColorPicker';
import { FormItem } from '../../../../../components/Form';
import { useCMUIHelpExperience } from '../../../../../hooks';
import { injectIntlAndObserver } from '../../../../../utils';
import { fontOptions } from '../../TythonAdunit/services/fontOptions';
import { AppAdUnitType, SelectedAppAdUnitBannerSize, VideoPlayMethod, appAdUnitVideoPlayMethod } from '../CreateAdUnit/types';
import messages from '../messages';
import NativeAdDynamicAdPreview from './NativeAdDynamicAdPreview';
import StaticAdPreview from './StaticAdPreview';
import { InfoLabelContent } from './V2HelpInfoBubble';
import V2SectionalAccordion from './V2SectionalAccordion';
import { useAppAdUnitPrimaryFormStyles } from './styles';
import { AppAdUnitPrimaryFormType } from './types';

/**
 * this form is build on top of fluent 2, it is required to wrap it inside a fluent provider
 * @returns
 */
const AppAdUnitPrimaryForm: AppAdUnitPrimaryFormType = ({
  intl: { formatMessage },
  applicationOptions,
  adUnitName,
  adUnitType,
  adUnitApplicationId,
  onAdUnitApplicationIdChange,
  onAdUnitNameChange,
  onAdUnitTypeChange,
  validationError,
  shouldRenderPrimaryForm,
  selectedBannerAdUnitAdSize,
  onBannerAdUnitAdSizeChange,
  bannerAdSizeOptions,
  videoAdUnitSkipAllowed,
  onVideoAdUnitSkipAllowedChange,
  videoAdUnitDuration,
  onVideoAdUnitDurationChange,
  nativeAdUnitConfig,
  onNativeAdUnitConfigChange,
  videoAdUnitPlayMethod,
  onVideoAdUnitPlayMethodChange,
  isAdUnitLoading,
  isEditMode,
}) => {
  const classNames = useAppAdUnitPrimaryFormStyles();
  const { CMUIPopupArticleByIdAnchor } = useCMUIHelpExperience();

  if (!shouldRenderPrimaryForm) {
    return <></>;
  }

  const { helpInfo, locale } = getAppStore();

  const adTypeAccordionItems = [
    {
      adType: AppAdUnitType.Native,
      title: formatMessage(messages.appAdUnitTypeNativeAdsTitle),
      summary: () => (
        <>
          <Body1Stronger block>{formatMessage(messages.appAdUnitNativeAdConfigTitle)}</Body1Stronger>
          <br />
          <Field
            label={{
              children: (_: unknown, slotProps: LabelProps) => (
                <Label>
                  <Body1>{formatMessage(messages.appAdUnitNativeAdsConfigFontLabel)}</Body1>
                  <Button
                    className={classNames.infoBubbleNoRequire}
                    appearance="transparent"
                    onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
                  >
                    <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                  </Button>
                </Label>
              ),
            }}
          />
          <Dropdown
            className={classNames.dimensionDropdown}
            onOptionSelect={(ev, data) => onNativeAdUnitConfigChange({ ...nativeAdUnitConfig, fontFamily: data.optionValue! })}
            selectedOptions={[nativeAdUnitConfig.fontFamily!]}
            value={nativeAdUnitConfig.fontFamily}
          >
            {fontOptions.map((option) => (
              <Option key={option.key} value={option.key as string} checkIcon={undefined}>
                {option.text}
              </Option>
            ))}
          </Dropdown>
          <div className={classNames.colorSelectorWrapper}>
            <Body1>{formatMessage(messages.appAdUnitNativeAdsConfigTitleColorLabel)}</Body1>
            <ColorPicker
              selectedColor={nativeAdUnitConfig.titleColor}
              onChange={(color) => onNativeAdUnitConfigChange({ ...nativeAdUnitConfig, titleColor: color })}
              colorType={formatMessage(messages.appAdUnitNativeAdsConfigTitleColorLabel)}
              controlled
            />
          </div>
          <div className={classNames.colorSelectorWrapper}>
            <Body1>{formatMessage(messages.appAdUnitNativeAdsConfigBackgroundLabel)}</Body1>
            <ColorPicker
              selectedColor={nativeAdUnitConfig.background}
              onChange={(color) => onNativeAdUnitConfigChange({ ...nativeAdUnitConfig, background: color })}
              colorType={formatMessage(messages.appAdUnitNativeAdsConfigBackgroundLabel)}
              controlled
            />
          </div>
          <div className={classNames.colorSelectorWrapper}>
            <Body1>{formatMessage(messages.appAdUnitNativeAdsConfigButtonBackgroundLabel)}</Body1>
            <ColorPicker
              selectedColor={nativeAdUnitConfig.buttonBackground}
              onChange={(color) => onNativeAdUnitConfigChange({ ...nativeAdUnitConfig, buttonBackground: color })}
              colorType={formatMessage(messages.appAdUnitNativeAdsConfigButtonBackgroundLabel)}
              controlled
            />
          </div>
          <MessageBar intent="info" className={classNames.msgBar}>
            <MessageBarBody>
              <Caption1>{formatMessage(messages.appAdUnitNativeAdsConfigMsgBar)}</Caption1>
            </MessageBarBody>
          </MessageBar>
        </>
      ),
      preview: () => <NativeAdDynamicAdPreview config={nativeAdUnitConfig} />,
    },
    {
      adType: AppAdUnitType.Display,
      title: formatMessage(messages.appAdUnitTypeDisplayAdsTitle),
      summary: () => (
        <>
          <Field
            label={{
              children: (_: unknown, slotProps: LabelProps) => (
                <Label>
                  <Body1Strong>{formatMessage(messages.appAdUnitDisplayAdsBannerAdSizeLabel)}</Body1Strong>
                  <Button
                    className={classNames.infoBubbleNoRequire}
                    appearance="transparent"
                    onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
                  >
                    <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                  </Button>
                </Label>
              ),
            }}
          />
          <Dropdown
            className={classNames.dimensionDropdown}
            onOptionSelect={(ev, data) => onBannerAdUnitAdSizeChange(data.optionValue! as SelectedAppAdUnitBannerSize)}
            selectedOptions={[selectedBannerAdUnitAdSize]}
            value={selectedBannerAdUnitAdSize}
          >
            {bannerAdSizeOptions.map((option) => (
              <Option key={option.key} value={option.key} checkIcon={undefined}>
                {option.value}
              </Option>
            ))}
          </Dropdown>
          <MessageBar intent="info" className={classNames.msgBar}>
            <MessageBarBody>
              <Caption1>
                <FormattedMessage
                  {...messages.adTypeInfoMsgContent}
                  values={{
                    helpLink: (
                      <CMUIPopupArticleByIdAnchor query={'ext07504'} name={formatMessage(messages.adTypeInfoMsgHelpLink)} useV2Link />
                    ),
                  }}
                />
              </Caption1>
            </MessageBarBody>
          </MessageBar>
        </>
      ),
      preview: () => <StaticAdPreview dimension={selectedBannerAdUnitAdSize} />,
    },
    {
      adType: AppAdUnitType.Video,
      disabled: true,
      title: formatMessage(messages.appAdUnitTypeVideoAdsTitle),
      summary: () => (
        <>
          <Body1Stronger block>{formatMessage(messages.appAdUnitVideoAdsInterstitialVideoAdConfigTitle)}</Body1Stronger>
          <br />
          <Field
            label={{
              children: (_: unknown, slotProps: LabelProps) => (
                <Label>
                  <Body1Strong>{formatMessage(messages.appAdUnitVideoAdsInterstitialVideoAdConfigVideoSkipLabel)}</Body1Strong>
                  <Button
                    className={classNames.infoButton}
                    appearance="transparent"
                    onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
                  >
                    <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                  </Button>
                </Label>
              ),
            }}
          />
          <Radio
            checked={videoAdUnitSkipAllowed === true}
            onChange={() => onVideoAdUnitSkipAllowedChange(true)}
            label={<Body1>{formatMessage(messages.appAdUnitVideoSkipYes)}</Body1>}
          />
          <Radio
            checked={videoAdUnitSkipAllowed === false}
            onChange={() => onVideoAdUnitSkipAllowedChange(false)}
            label={<Body1>{formatMessage(messages.appAdUnitVideoSkipNo)}</Body1>}
          />
          <Field
            label={{
              children: (_: unknown, slotProps: LabelProps) => (
                <Label>
                  <Body1Strong>{formatMessage(messages.appAdUnitVideoAdsDuration)}</Body1Strong>
                  <Button
                    className={classNames.infoButton}
                    appearance="transparent"
                    onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
                  >
                    <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                  </Button>
                </Label>
              ),
            }}
          />
          <Input
            contentAfter={<Body1>{formatMessage(messages.appAdUnitVideoAdsDurationMetric)}</Body1>}
            value={videoAdUnitDuration ?? ''}
            placeholder="0-30"
            onChange={(e) => {
              const newValue = parseInt(e.target.value, 10);
              if (newValue >= 0 && newValue <= 30) {
                onVideoAdUnitDurationChange(`${newValue}`);
              } else if (e.target.value === '') {
                onVideoAdUnitDurationChange(undefined); // Handle empty string if the input is cleared
              }
            }}
            type="number"
          />
          <Field
            label={{
              children: (_: unknown, slotProps: LabelProps) => (
                <Label>
                  <Body1Strong>{formatMessage(messages.appAdUnitVideoPlayMethodFieldLabel)}</Body1Strong>
                  <Button
                    className={classNames.infoButton}
                    appearance="transparent"
                    onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
                  >
                    <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                  </Button>
                </Label>
              ),
            }}
          />
          <Dropdown
            className={classNames.dimensionDropdown}
            onOptionSelect={(ev, data) => onVideoAdUnitPlayMethodChange(data.optionValue! as VideoPlayMethod)}
            selectedOptions={[videoAdUnitPlayMethod]}
            value={formatMessage(appAdUnitVideoPlayMethod.find((item) => item.value === videoAdUnitPlayMethod)?.label!)}
          >
            {appAdUnitVideoPlayMethod.map((option) => (
              <Option key={option.value} value={option.value} checkIcon={undefined}>
                {formatMessage(option.label)}
              </Option>
            ))}
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <Body1 className={classNames.subTitle} block>
        {formatMessage(messages.appDetailSubTitle)}
      </Body1>
      <FormItem>
        <Field
          label={{
            children: (_: unknown, slotProps: LabelProps) => (
              <Label>
                {formatMessage(messages.appAdUnitNameFieldLabel)}
                <Button
                  id={adUnitName}
                  className={classNames.infoButton}
                  appearance="transparent"
                  onClick={() => onHelpInfoCalloutOpened('pop_PCv4_SiteLevel', locale)}
                >
                  <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                </Button>
              </Label>
            ),
          }}
          aria-required={true}
          validationMessage={validationError.addUnitName}
          className={classNames.sectionTitle}
        >
          <Input
            value={adUnitName ? `${adUnitName}` : ''}
            placeholder={formatMessage(messages.appAdUnitNamePlaceholder)}
            disabled={isAdUnitLoading}
            onChange={(_, data) => onAdUnitNameChange(data.value)}
          />
        </Field>
      </FormItem>
      <FormItem>
        <Field
          label={{
            children: (_: unknown, slotProps: LabelProps) => (
              <Label>
                {formatMessage(messages.appAdUnitApplicationIdFieldLabel)}
                <Button
                  className={classNames.infoButton}
                  appearance="transparent"
                  onClick={() => onHelpInfoCalloutOpened('pop_PCv4_UniversalBlockSetting', locale)}
                >
                  <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                </Button>
              </Label>
            ),
          }}
          required={true}
          aria-required={true}
          validationMessage={validationError.addUnitName}
          className={classNames.sectionTitle}
        >
          <Dropdown
            className={classNames.applicationDropdown}
            onOptionSelect={(ev, data) => onAdUnitApplicationIdChange(data.optionValue!)}
            value={adUnitApplicationId ? applicationOptions.find((item) => item.key === +adUnitApplicationId)?.label : ''}
            disabled={isEditMode}
          >
            {applicationOptions.map((option) => (
              <Option key={option.key} value={`${option.key}`} className={classNames.propertyOption} text={option.label}>
                <div>
                  <Caption1Strong>{option.label}</Caption1Strong>
                  <Caption1>{option.secondaryLabel}</Caption1>
                </div>
              </Option>
            ))}
          </Dropdown>
        </Field>
      </FormItem>
      <FormItem>
        <Field
          label={{
            children: (_: unknown, slotProps: LabelProps) => (
              <Label>
                {formatMessage(messages.appAdUnitTypeFieldLabel)}
                <Button
                  className={classNames.infoButton}
                  appearance="transparent"
                  onClick={() => onHelpInfoCalloutOpened('pop_PCv4_UniversalBlockSetting', locale)}
                >
                  <InfoLabel info={<InfoLabelContent html={helpInfo} />} />
                </Button>
              </Label>
            ),
          }}
          required={true}
          aria-required={true}
          className={classNames.sectionTitle}
        />
      </FormItem>
      {adTypeAccordionItems.map((item) => (
        <>
          <V2SectionalAccordion
            key={item.adType}
            isAccordionSelected={adUnitType === item.adType}
            onTitleToggle={() => onAdUnitTypeChange(item.adType)}
            onRenderLeft={item.summary}
            onRenderRight={item.preview}
            titleLabel={item.title}
            disabled={item.disabled}
            isLoading={isAdUnitLoading}
          />
          <br />
        </>
      ))}
    </>
  );
};

export default injectIntlAndObserver(AppAdUnitPrimaryForm);
