import { IStyle, ITheme } from '@fluentui/react';

export interface IManagePageStyleProps {
  theme: ITheme;
}

export interface IManagePageStyles {
  root?: IStyle;
  tokenPanelsubTitle?: IStyle;
  tokenPanelsubTitleValue?: IStyle;
  tokenPanelAgreement?: IStyle;
  tokenPanelAgreementBtn?: IStyle;
  tokenPanelDevToken?: IStyle;
  tokenPanelInfoButton?: IStyle;
  tokenPanelAgreementText?: IStyle;
  tokenSpinner?: IStyle;
  tokenFetchFail?: IStyle;
}

export enum DevTokenFetch {
  TokenNotFound = 'TokenNotFound',
  ApiCallFailed = 'ApiCallFailed',
}

export interface IDevTokenPanelParams {
  userName?: string;
  email?: string;
  developerToken?: string;
  panelClosed?: boolean;
  SpinnerText?: string;
  onGenerateDevToken: () => void;
}
