import { ChoiceGroup, DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, TextField } from '@fluentui/react';
import React, { useEffect } from 'react';
import { UserRole, getAppStore } from '../../../../@data';
import { getSupportedLanguagesDropDownOptions } from '../../../../@data/utils/supportedLanguages';
import AppMessages from '../../../../App.messages';
import { FormItem } from '../../../../components/Form';
import { getPanelClassNames } from '../../../../styles/PanelStyles';
import { injectIntlAndObserver } from '../../../../utils';
import UserManagementMessages from '../UserManagement.messages';
import { getUserRoleChoiceGroupOptions } from '../components/UserRoleChoiceGroupOptions';
import { onUserInvited } from './@data/actions/userInvitationActions';
import { setFirstName, setLastName, updateEmail, updateRecipientPreferredLanguage, updateRole } from './@data/mutatorActions';
import { setUserInvitationPanelStatus } from './@data/mutatorActions/onUserInvitationTabChanged';
import { getUserInvitationStore } from './@data/store';
import messages from './UserInvitations.messages';

const USER_INVITE_PANEL_WIDTH = '466px';
const PANEL_FIELD_WIDTH = 416;

export const UserInvitePanel = injectIntlAndObserver(function UserInvitePanelComponent({ intl }) {
  const { formatMessage } = intl;
  const {
    inviteUser,
    panelDescription,
    inviteUserEmailTitle,
    emailPlaceholder,
    send,
    firstNameTitle,
    lastNameTitle,
    recipientPreferredLanguageTitle,
  } = messages;
  const { locale } = getAppStore();
  const { panelDescription: panelDesc, panelFooterButtonsWrapper } = getPanelClassNames();
  const { userInvitePanelOpen, userInviteErrors } = getUserInvitationStore();

  const { firstName, lastName, email, userRole, recipientPreferredLang } = getUserInvitationStore().userInviteViewModel;
  const onDismiss = () => setUserInvitationPanelStatus(false);

  useEffect(() => {
    updateRecipientPreferredLanguage(locale);
  }, [locale]);

  const isFormComplete = () => {
    if (firstName?.trim() !== '' && lastName?.trim() !== '' && email?.trim() !== '' && recipientPreferredLang?.trim() !== '' && userRole) {
      return true;
    } else {
      return false;
    }
  };

  const onUserLanguageChanged = (ev, option) => {
    updateRecipientPreferredLanguage(option.key);
  };

  if (!userInvitePanelOpen) {
    return null;
  }

  return (
    <React.Fragment>
      <Panel
        headerText={formatMessage(inviteUser)}
        isOpen={userInvitePanelOpen}
        type={PanelType.custom}
        customWidth={USER_INVITE_PANEL_WIDTH}
        onDismiss={onDismiss}
        closeButtonAriaLabel={formatMessage(AppMessages.close)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
          return (
            <div className={panelFooterButtonsWrapper}>
              <PrimaryButton text={formatMessage(send)} disabled={!isFormComplete()} onClick={onUserInvited} />
              <DefaultButton text={formatMessage(AppMessages.cancel)} onClick={onDismiss} />
            </div>
          );
        }}
      >
        <React.Fragment>
          <div className={panelDesc}>{formatMessage(panelDescription)}</div>
          <FormItem formWidth={PANEL_FIELD_WIDTH}>
            <TextField
              required
              label={formatMessage(firstNameTitle)}
              ariaLabel={formatMessage(firstNameTitle)}
              value={firstName}
              onChange={(_, value: string) => setFirstName(value)}
              errorMessage={userInviteErrors?.firstName}
            />
          </FormItem>
          <FormItem formWidth={PANEL_FIELD_WIDTH}>
            <TextField
              required
              label={formatMessage(lastNameTitle)}
              ariaLabel={formatMessage(lastNameTitle)}
              value={lastName}
              onChange={(_, value: string) => setLastName(value)}
              errorMessage={userInviteErrors?.lastName}
            />
          </FormItem>
          <FormItem formWidth={PANEL_FIELD_WIDTH}>
            <TextField
              label={formatMessage(inviteUserEmailTitle)}
              placeholder={formatMessage(emailPlaceholder)}
              value={email}
              onChange={(_, val: string) => updateEmail(val)}
              errorMessage={userInviteErrors?.email}
              required
            />
          </FormItem>
          <FormItem formWidth={PANEL_FIELD_WIDTH}>
            <Dropdown
              label={formatMessage(recipientPreferredLanguageTitle)}
              options={getSupportedLanguagesDropDownOptions(locale)}
              onChange={onUserLanguageChanged}
              selectedKey={recipientPreferredLang}
              errorMessage={userInviteErrors?.recipientPreferredLang}
              required
            />
          </FormItem>
          <FormItem formWidth={PANEL_FIELD_WIDTH}>
            <ChoiceGroup
              label={formatMessage(UserManagementMessages.role)}
              options={getUserRoleChoiceGroupOptions(intl)}
              onChange={(_, option) => option && updateRole(option!.key as UserRole)}
              defaultSelectedKey={userRole}
            />
          </FormItem>
        </React.Fragment>
      </Panel>
    </React.Fragment>
  );
});
