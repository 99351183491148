import { mutator } from 'satcheljs';
import { Publisher } from '../../../../../@data';
import { mergeTypeWithObject } from '../../../../../utils/ObjectUtils';
import {
  onFetchPublishersLoading,
  onFetchingPublishersData,
  onPublisherChanged,
  onPublisherErrorChanged,
  onPublishersFetched,
  onRawChanged,
} from '../actions/onPublisherChanged';
import { getStore } from '../store/store';

mutator(onRawChanged, (msg) => {
  getStore().raw = mergeTypeWithObject(new Publisher(), msg.value);
});

mutator(onPublisherChanged, (msg) => {
  getStore().active = msg.publisher;
});

mutator(onPublisherErrorChanged, (msg) => {
  getStore().errors = msg.values || {};
});

mutator(onFetchPublishersLoading, (msg) => {
  getStore().isLoading = msg.isLoading;
});

mutator(onFetchingPublishersData, (msg) => {
  getStore().isFetchingData = msg.isFetchingData;
});

mutator(onPublishersFetched, (msg) => {
  getStore().publishers = msg.items;
});
