import { defineMessages } from 'react-intl';

export default defineMessages({
  inventoryCreationTitle: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationTitle',
    defaultMessage: 'Connect your inventory',
  },
  inventoryCreationContentSectionOne: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationContentSectionOne',
    defaultMessage:
      'To start monetizing with Microsoft pubCenter, we need you to connect your Site or your Application. Which type of Inventory setup you would like? {helpLinkPlaceholder}',
  },
  inventoryCreationContentSectionOneLink: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationContentSectionOneLink',
    defaultMessage: 'Learn more about our supported platforms.',
  },
  inventoryCreationContentSectionTwo: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationContentSectionTwo',
    defaultMessage:
      'We strongly suggest that you review our {helpLinkPlaceholder}. Sites and apps that do not meet these guidelines will be rejected and won’t be able to serve ads.',
  },
  inventoryCreationContentSectionTwoLink: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationContentSectionTwoLink',
    defaultMessage: 'inventory guidelines',
  },
  inventoryCreationCardTitleSite: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationCardTitleSite',
    defaultMessage: 'Connect Site',
  },
  adsCreationCardTitleSite: {
    id: 'app.layout.EntityCreationLayout.adsCreationCardTitleSite',
    defaultMessage: 'Site ad unit',
  },
  adsCreationCardTitleApp: {
    id: 'app.layout.EntityCreationLayout.adsCreationCardTitleApp',
    defaultMessage: 'App ad unit',
  },
  inventoryCreationCardDescriptionSite: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationCardDescriptionSite',
    defaultMessage:
      'Connect your website to showcase ads. Each website is reviewed in accordance with our {MSPolicyPlaceholder} and {AdRenderingPoliciesPlaceholder}. To ensure your website passes our review, please follow our {SiteApprovalGuidelinesPlaceholder} before submitting your site for approval.',
  },
  inventoryCreationCardDescriptionApp: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationCardDescriptionApp',
    defaultMessage:
      'Connect your App to showcase ads. Each app will be reviewed in accordance with our {MSPolicyPlaceholder} and {AdRenderingPoliciesPlaceholder}. To ensure your app passes our review, please follow our {AppApprovalGuidelinesPlaceholder} before submitting your app for approval.',
  },
  inventoryCreationCardTitleApp: {
    id: 'app.layout.EntityCreationLayout.inventoryCreationCardTitleApp',
    defaultMessage: 'Connect App',
  },
  MSPolicyPlaceholder: {
    id: 'app.layout.EntityCreationLayout.MSPolicyPlaceholder',
    defaultMessage: 'Microsoft policies',
  },
  AdRenderingPoliciesPlaceholder: {
    id: 'app.layout.EntityCreationLayout.AdRenderingPoliciesPlaceholder',
    defaultMessage: 'Ad rendering policies',
  },
  SiteApprovalGuidelinesPlaceholder: {
    id: 'app.layout.EntityCreationLayout.SiteApprovalGuidelinesPlaceholder',
    defaultMessage: 'site approval guidelines',
  },
  AppApprovalGuidelinesPlaceholder: {
    id: 'app.layout.EntityCreationLayout.AppApprovalGuidelinesPlaceholder',
    defaultMessage: 'app approval guidelines',
  },
  adsCreationTitle: {
    id: 'app.layout.EntityCreationLayout.adsCreationTitle',
    defaultMessage: 'What type of ad unit do you want to create?',
  },
  adsCreationContentSectionOne: {
    id: 'app.layout.EntityCreationLayout.adsCreationContentSectionOne',
    defaultMessage: 'Ad units allow you to control where and when ads show across your inventory. Each ad unit must be connected to an existing piece of inventory.',
  },

});
