import * as React from 'react';
import { injectIntlWithObserver, IRouterWithIntl } from '../../utils';
import { GlobalSearchResultPage } from './GlobalSearchResultPage/GlobalSearchResultPage';

export const GlobalSearchResultRouter = injectIntlWithObserver(
  class extends React.Component<IRouterWithIntl> {
    public render(): JSX.Element {
      return <GlobalSearchResultPage {...this.props} />;
    }
  }
);
