import { getAppStore, getCurrentPublisher, patchEntity, UpdateUserContactDetails, User } from '../../../../../@data';
import { getPrimaryUserId } from '../../../../../@data/store';

export async function updateUserContactDetails(body: UpdateUserContactDetails) {
  const publisher = getCurrentPublisher();
  const primaryUser = new User(getPrimaryUserId());

  if (!publisher) {
    return Promise.reject('No publisher found');
  }

  if (!primaryUser.id) {
    return Promise.reject('No primary user found');
  }

  const { userContext } = getAppStore();
  const userDetails = await patchEntity([publisher, primaryUser], UpdateUserContactDetails, body, userContext);
  return userDetails;
}
