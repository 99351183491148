import { mutator } from 'satcheljs';
import { AdUnit } from '../../../../../@data';
import { mergeTypeWithObject } from '../../../../../utils';
import { onAdunitChanged, onAdunitErrorChanged, onAdunitMediationUpdateEnd, onAdunitMediationUpdateStart, onRawChanged } from '../actions/';
import { getAdunitStore } from '../store/store';

mutator(onRawChanged, (msg) => {
  getAdunitStore().raw = mergeTypeWithObject(new AdUnit(), msg.value);
});

mutator(onAdunitChanged, (msg) => {
  getAdunitStore().active = msg.adunit;
});

mutator(onAdunitErrorChanged, (msg) => {
  getAdunitStore().errors = msg.values || {};
});

mutator(onAdunitMediationUpdateStart, (msg) => {
  getAdunitStore().mediationUpdatePool = [...getAdunitStore().mediationUpdatePool, ...msg.ids];
});

mutator(onAdunitMediationUpdateEnd, (msg) => {
  getAdunitStore().mediationUpdatePool = getAdunitStore().mediationUpdatePool.filter((id) => !msg.ids.includes(id));
});
