import { Dropdown, IconButton, IDropdownOption, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { PAGINATION_PAGE_SIZES } from '../../../../../../constants/AppConstants';
import { useWindowSize } from '../../../../../../hooks';
import { getStore as getWindowSizeStore } from '../../../../../../hooks/useWindowSize/store/store';
import { IPaginationProps } from '../../Pagination';
import messages from './NavigatedPagination.messages';
import { getClassNames } from './NavigatedPagination.styles';

export interface INavigatedPaginationProps extends IPaginationProps, InjectedIntlProps {}

export const NavigatedPagination = ({
  intl,
  currentPageNumber,
  pageSize,
  className,
  onPageNumberChange,
  onPageSizeChange,
  totalItemCount,
  totalPageCount,
  styleProps,
}: INavigatedPaginationProps) => {
  const { formatMessage } = intl;
  const { containerStyle, itemStyle, dropdownStyle, disabledItemStyle } = mergeStyleSets(getClassNames(), styleProps);
  const { windowStoreSerializer } = useWindowSize();
  const { isWindowSmallSize, isWindowExtraSmallSize } = windowStoreSerializer(getWindowSizeStore());

  const lastPage = totalPageCount || (totalItemCount && pageSize && Math.ceil(totalItemCount / pageSize)) || 0;
  if (lastPage === 0) {
    // Either totalPageCount or totalItemCount and pageSize must be provided
    return <></>;
  }

  const onFirst = () => {
    onPageNumberChange?.(1);
  };

  const onNext = () => {
    onPageNumberChange?.(currentPageNumber + 1);
  };

  const onPrevious = () => {
    onPageNumberChange?.(currentPageNumber - 1);
  };

  const onLast = () => {
    onPageNumberChange?.(lastPage as number);
  };

  const getPageSizeOptions = () => {
    return PAGINATION_PAGE_SIZES.map((size) => {
      return {
        key: size.toString(),
        text: size.toString(),
        isSelected: size === currentPageNumber,
      };
    }) as IDropdownOption[];
  };

  const getPageOptions = () => {
    return Array.from({ length: lastPage as number }, (_, i) => i + 1).map((index) => {
      return {
        key: index.toString(),
        text: index.toString(),
        isSelected: index === currentPageNumber,
      };
    }) as IDropdownOption[];
  };

  return (
    <div className={`${containerStyle} ${className || ''}`}>
      <span>{formatMessage(messages.showRows)}</span>
      <Dropdown
        ariaLabel={formatMessage(messages.showRows)}
        selectedKey={pageSize?.toString()}
        options={getPageSizeOptions()}
        className={dropdownStyle}
        onChange={(_, e) => {
          if (e) {
            onFirst();
            onPageSizeChange?.(Number(e.text));
          }
        }}
      />

      {!isWindowSmallSize && !isWindowExtraSmallSize && (
        <IconButton
          id="firstPage"
          iconProps={{ iconName: 'DoubleChevronLeft' }}
          className={`${itemStyle} ${currentPageNumber === 1 ? disabledItemStyle : ''}`}
          ariaLabel={formatMessage(messages.firstPage)}
          onClick={onFirst}
        />
      )}
      <IconButton
        id="previousPage"
        iconProps={{ iconName: 'ChevronLeft' }}
        className={`${itemStyle} ${currentPageNumber === 1 ? disabledItemStyle : ''}`}
        ariaLabel={formatMessage(messages.prevPage)}
        onClick={onPrevious}
      />

      <Dropdown
        id="pageChangerDropdown"
        selectedKey={currentPageNumber.toString()}
        options={getPageOptions()}
        className={dropdownStyle}
        onChange={(_, e) => e && onPageNumberChange?.(Number(e.text))}
        ariaLabel={currentPageNumber.toString()}
      />
      <span>
        {formatMessage(messages.of)} {lastPage}
      </span>

      <IconButton
        id="nextPage"
        iconProps={{ iconName: 'ChevronRight' }}
        className={`${itemStyle} ${currentPageNumber === lastPage ? disabledItemStyle : ''}`}
        ariaLabel={formatMessage(messages.nextPage)}
        onClick={onNext}
      />
      {!isWindowSmallSize && !isWindowExtraSmallSize && (
        <IconButton
          id="lastPage"
          iconProps={{ iconName: 'DoubleChevronRight' }}
          className={`${itemStyle} ${currentPageNumber === lastPage ? disabledItemStyle : ''}`}
          ariaLabel={formatMessage(messages.lastPage)}
          onClick={onLast}
        />
      )}
    </div>
  );
};
