import { Property, Publisher, UserContext, listEntity } from '../../../../../@data';

export const fetchPropertyById = async (
  publisher: Publisher,
  id: string,
  userContext?: UserContext,
  updateLoadingStatus?: (isLoading: boolean) => void
): Promise<Property> => {
  updateLoadingStatus?.(true);
  const list = await listEntity(
    [publisher],
    userContext,
    Property,
    new URLSearchParams([
      ['type', 'Search'],
      ['ids', id],
    ])
  );

  if (!list || !list.length) {
    throw Error('Cannot find property Id');
  }
  updateLoadingStatus?.(false);
  return list[0];
};
