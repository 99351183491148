import { when } from 'jquery';
import { orchestrator } from 'satcheljs';
import { Log } from '../../../../../../logging/src';
import { getAppStore, getEntity, isPublisherUnderReview } from '../../../../../@data';
import { EntitySummary } from '../../../../../@data/store/schema/models/EntitySummary';
import { Home } from '../../../../../@data/store/schema/models/Home';
import { fetchSetupCardsStatus } from '../actions/actions';
import { setHomePageStatus, setIsStatusLoading } from '../mutatorActions';
import { HomePageStatus } from '../store/schema/IHomeViewState';
import { getStore } from '../store/store';

orchestrator(fetchSetupCardsStatus, async () => {
  const { publisher, account, userContext } = getAppStore();

  if (publisher && account && userContext) {
    setIsStatusLoading(true);

    try {
      const data = (await getEntity([new Home(), publisher], userContext, EntitySummary)) as EntitySummary;
      if (data.profile === 'Rejected' && isPublisherUnderReview()) {
        data.profile = HomePageStatus.AwaitingVerification;
      }
      setHomePageStatus(data.profile, data.site, data.adUnit, data.isHomePageSetupComplete);
      await when(() => {
        const { adUnitStatus, siteStatus, profileStatus, isSetupDone } = getStore();
        if (adUnitStatus !== undefined && siteStatus !== undefined && profileStatus !== undefined && isSetupDone !== undefined) {
          setIsStatusLoading(false);
          return true;
        }
        return false;
      });
    } catch (error) {
      Log.error(error);
    } finally {
      setIsStatusLoading(false);
    }
  }
});
