import UserMessages from '../UserManagement.messages';

import { ActionButton } from '@fluentui/react';
import React from 'react';
import { UserInvitation } from '../../../../@data';
import AppMessages from '../../../../App.messages';
import { GridCell } from '../../../../components/GridWithCustomColumns';
import { IGridV2Column } from '../../../../layout/GridPageV2';
import { getClassNames } from '../UserManagement.styles';
import { getUserRoleMapping } from '../utils/userRoleUtils';
import { onDeleteUserInvitation, onResendUserInvitation } from './@data/actions/userInvitationActions';
import UserInvitationsMessages from './UserInvitations.messages';

export function getColumns(intl, longestEmailLength): IGridV2Column[] {
  const { formatMessage } = intl;
  const { actionButtonsWrapper } = getClassNames();
  const { pending, expirationDate, expired, resend } = UserInvitationsMessages;

  const getUserInvitationExpiryStatus = (invite: UserInvitation) => {
    const currTime = new Date();
    const expiryDate = invite.expirationDate ? new Date(invite.expirationDate) : undefined;
    return expiryDate ? formatMessage(expiryDate > currTime ? pending : expired) : '';
  };

  return [
    {
      columnOrder: 0,
      key: 'email',
      name: formatMessage(UserMessages.email),
      ariaLabel: formatMessage(AppMessages.sortByColumnHeaderTooltipLabel, { columnName: formatMessage(UserMessages.email) }),
      minWidth: longestEmailLength,
      maxWidth: longestEmailLength + 10,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      sortableValue: (invite: UserInvitation) => invite.email ?? '',
      onRender: (invite: UserInvitation) => <GridCell>{invite.email}</GridCell>,
    },
    {
      columnOrder: 1,
      key: 'role',
      name: formatMessage(UserMessages.role),
      ariaLabel: formatMessage(AppMessages.sortByColumnHeaderTooltipLabel, { columnName: formatMessage(UserMessages.role) }),
      minWidth: 100,
      maxWidth: 150,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      sortableValue: (invite: UserInvitation) => getUserRoleMapping(invite.roleIds, formatMessage),
      onRender: (invite: UserInvitation) => <GridCell>{getUserRoleMapping(invite.roleIds, formatMessage)}</GridCell>,
    },
    {
      columnOrder: 2,
      key: 'status',
      name: formatMessage(AppMessages.status),
      ariaLabel: formatMessage(AppMessages.sortByColumnHeaderTooltipLabel, { columnName: formatMessage(AppMessages.status) }),
      minWidth: 50,
      maxWidth: 100,
      isSearchable: true,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      sortableValue: getUserInvitationExpiryStatus,
      onRender: (invite: UserInvitation) => <GridCell>{getUserInvitationExpiryStatus(invite)}</GridCell>,
    },
    {
      columnOrder: 3,
      key: 'expirationDate',
      name: formatMessage(expirationDate),
      ariaLabel: formatMessage(AppMessages.sortByColumnHeaderTooltipLabel, { columnName: formatMessage(expirationDate) }),
      minWidth: 100,
      maxWidth: 150,
      isVisible: true,
      isExportable: true,
      isResizable: true,
      sortableValue: (invite: UserInvitation) => new Date(invite.expirationDate as Date).toISOString() ?? '',
      onRender: (invite: UserInvitation) => {
        const expiryDate = invite.expirationDate ? new Date(invite.expirationDate) : undefined;
        const expiryDateString = expiryDate ? expiryDate.toISOString().split('T')[0] : '';
        return <GridCell>{expiryDateString}</GridCell>;
      },
    },
    {
      columnOrder: 4,
      key: 'actions',
      name: formatMessage(UserMessages.actions),
      minWidth: 180,
      maxWidth: 200,
      isVisible: true,
      isExportable: false,
      suppressSorting: true,
      isPadded: true,
      isResizable: true,
      onRender: (invite: UserInvitation) => {
        return (
          <div className={actionButtonsWrapper}>
            <ActionButton iconProps={{ iconName: 'Send' }} onClick={(ev) => onResendUserInvitation(invite)}>
              {formatMessage(resend)}
            </ActionButton>
            <ActionButton iconProps={{ iconName: 'delete' }} onClick={() => onDeleteUserInvitation(invite)}>
              {formatMessage(AppMessages.delete)}
            </ActionButton>
          </div>
        );
      },
    },
  ];
}
