import { IColorCellProps } from '@fluentui/react';

export const swatchColors: IColorCellProps[] = [
  { id: 'a', label: 'Soft peach', color: '#f8ece9' },
  { id: 'b', label: 'Early dawn', color: '#fdf8e8' },
  { id: 'c', label: 'White smoke', color: '#f0f7ef' },
  { id: 'd', label: 'Porcelain', color: '#e8effd' },
  { id: 'e', label: 'Amour', color: '#f4ecf7' },
  { id: 'f', label: 'White', color: '#ffffff' },
  { id: 'g', label: 'Pale rose', color: '#edc9c4' },
  { id: 'h', label: 'Pale peach', color: '#f9e9b8' },
  { id: 'i', label: 'Beryl green', color: '#cee5cb' },
  { id: 'j', label: 'Light blue', color: '#cad9f9' },
  { id: 'k', label: 'Pink flare', color: '#dbbfe4' },
  { id: 'l', label: 'Light grey', color: '#dddddd' },
  { id: 'm', label: 'Faded red', color: '#ca5040' },
  { id: 'n', label: 'Light orange', color: '#eab73e' },
  { id: 'o', label: 'Dull green', color: '#67ad5b' },
  { id: 'p', label: 'Soft blue', color: '#5383ec' },
  { id: 'q', label: 'Warm purple', color: '#9031aa' },
  { id: 'r', label: 'Grey', color: '#777777' },
  { id: 's', label: 'Brownish red', color: '#ab3d2b' },
  { id: 't', label: 'Orange', color: '#e08731' },
  { id: 'u', label: 'Green', color: '#326f3e' },
  { id: 'v', label: 'Blue', color: '#3555bf' },
  { id: 'w', label: 'Purple', color: '#612194' },
  { id: 'x', label: 'Black', color: '#3a474e' },
];

export const defaultColor = '#ffffff';

export const getColorByHex = (hex: string): IColorCellProps | undefined => {
  return swatchColors.find((color) => color.color === hex.toLocaleLowerCase());
};
