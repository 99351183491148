import { observer } from 'mobx-react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface IRouterWithIntl<Params extends { [K in keyof Params]?: string } = {}>
  extends InjectedIntlProps,
    RouteComponentProps<Params> {}

export function injectRouterWithIntl(component: React.ComponentType<IRouterWithIntl>) {
  return withRouter(injectIntl(component));
}

export function injectIntlWithObserver<T extends IRouterWithIntl>(component: React.ComponentType<T>) {
  return injectIntl(observer(component));
}

export function injectRouterAndIntlWithObserver<T extends IRouterWithIntl>(component: React.ComponentType<T>) {
  return withRouter(injectIntl(observer(component)));
}

export function injectIntlAndObserver<T extends InjectedIntlProps>(component: React.ComponentType<T>) {
  return injectIntl(observer(component));
}
