import { defineMessages } from 'react-intl';

export default defineMessages({
  microsoftClarity: {
    id: 'clarity.microsoftClarity',
    defaultMessage: 'Microsoft Clarity',
  },
  microsoftClarityTC: {
    id: 'clarity.microsoftClarityTC',
    defaultMessage: 'I’ve reviewed the {privacy} and agree to grant Microsoft permission to collect and use this data.',
  },
  microsoftClarityPrivacy: {
    id: 'clarity.microsoftClarityPrivacy',
    defaultMessage: 'privacy statement',
  },
  microsoftClarityInformation: {
    id: 'clarity.microsoftClarityTermsOfUse',
    defaultMessage:
      'Microsoft Clarity can help provide insights into your site’s performance and better protect you from fraudulent activity. ',
  },
  microsoftClarityTermsOfUse: {
    id: 'clarity.microsoftClarityTermsOfUse',
    defaultMessage: 'Learn more about Clarity.',
  },
  advancedOptionHeader: {
    id: 'clarity.advancedOptionHeader',
    defaultMessage: 'Clarity – Advanced insights & behavioral analytics',
  },
  advancedOptionDesc: {
    id: 'clarity.advancedOptionDesc',
    defaultMessage:
      'Clarity - Advanced insights and behavioral analytics  provides behavior-focused insights into how people interact with your website. With instant heat maps and session recordings, you can identify the most engaging sections on your sites and measure visitor scrolling behavior that can turn site analytics into opportunities.',
  },
  advancedOptionApproval: {
    id: 'clarity.advancedOptionApproval',
    defaultMessage: 'Clarity Advanced privacy statement approval ',
  },
  basicOptionHeader: {
    id: 'clarity.basicOptionHeader',
    defaultMessage: 'Clarity – Basic Insights',
  },
  basicOptionDesc: {
    id: 'clarity.basicOptionDesc',
    defaultMessage:
      'Clarity - Basic Insights provides fraud detection and leverages data collected from your website to identify and mitigate potential threats to your account.',
  },
  existingClarityOptionHeader: {
    id: 'clarity.existingClarityOptionHeader',
    defaultMessage: 'I have an existing Clarity project',
  },
  existingClarityOptionDesc: {
    id: 'clarity.existingClarityOptionDesc',
    defaultMessage: `You can find it in the project URL from the browser. {br}
      Example: https://clarity.microsoft.com/projects/view/”projectid”/ {br}
      Project linking can be completed once the admin of Clarity project approves this linking by clicking on “Approve” from their email.`,
  },
  advantageOneHeader: {
    id: 'clarity.advantageOneHeader',
    defaultMessage: 'Powerful insights',
  },
  advantageOneDesc: {
    id: 'clarity.advantageOneDesc',
    defaultMessage: 'Quickly discover where users get frustrated and turn these problems into opportunities.',
  },
  advantageTwoHeader: {
    id: 'clarity.advantageTwoHeader',
    defaultMessage: 'Instant heatmaps',
  },
  advantageTwoDesc: {
    id: 'clarity.advantageTwoDesc',
    defaultMessage:
      'Quickly view which areas on your page drive the most engagement and how far your visitors scroll with Clarity instant heatmaps.',
  },
  advantageThreeHeader: {
    id: 'clarity.advantageThreeHeader',
    defaultMessage: 'Session recordings',
  },
  advantageThreeDesc: {
    id: 'clarity.advantageThreeDesc',
    defaultMessage: `Go beyond the numbers. View what's working on your site, learn what needs to be fixed, and test new ideas`,
  },
  recommendationTag: {
    id: 'clarity.recommendationTag',
    defaultMessage: 'RECOMMENDED',
  },
  sidePanelPending: {
    id: 'property.sidePanelPending',
    defaultMessage: 'Pending Approval. Click continue to resubmit the request.',
  },
  sidePanelDeclined: {
    id: 'property.sidePanelDeclined',
    defaultMessage: 'Request was declined. Click continue to resubmit the request.',
  },
  sidePanelExpired: {
    id: 'property.sidePanelExpired',
    defaultMessage: 'Request was expired. Click continue to resubmit the request.',
  },
  sidePanelCancelled: {
    id: 'property.sidePanelCancelled',
    defaultMessage: 'Request was cancelled. Click continue to resubmit the request.',
  },
  clarityId: {
    id: 'property.clarityId',
    defaultMessage: 'Clarity Project id',
  },
});
