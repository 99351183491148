export const userLocales = {
  1031: 'de-DE',
  3079: 'de-AT',
  1033: 'en-US',
  4105: 'en-CA',
  6153: 'en-IE',
  2057: 'en-GB',
  16393: 'en-IN',
  3082: 'es-ES',
  3084: 'fr-CA',
  1036: 'fr-FR',
  1040: 'it-IT',
  3081: 'en-AU',
  5129: 'en-NZ',
  1046: 'pt-BR',
  1028: 'zh-TW',
  4100: 'zh-SG',
  2070: 'pt-PT',
  1042: 'ko-KR',
  13322: 'es-CL',
  9226: 'es-CO',
  5132: 'fr-LU',
  7177: 'en-ZA',
  2052: 'zh-CN',
  1041: 'ja-JP',
  2058: 'es-MX',
};

export const adQualityLocales = {
  14337: 'ar-ae',
  3073: 'ar-eg',
  1025: 'ar-sa',
  1093: 'bn-in',
  1029: 'cs-cz',
  1030: 'da-dk',
  3079: 'de-at',
  2055: 'de-ch',
  1031: 'de-de',
  1032: 'el-gr',
  // Note: my system did not find an LCID for en-ae (MSN's English UAE), so I am using placeholder -4
  '-4': 'en-ae',
  3081: 'en-au',
  4105: 'en-ca',
  2057: 'en-gb',
  14345: 'en-id',
  6153: 'en-ie',
  16393: 'en-in',
  17417: 'en-my',
  5129: 'en-nz',
  13321: 'en-ph',
  18441: 'en-sg',
  1033: 'en-us',
  // Note: my system did not find an LCID for en-xl (MSN's English International), so I am using placeholder -1
  '-1': 'en-xl',
  7177: 'en-za',
  11274: 'es-ar',
  13322: 'es-cl',
  9226: 'es-co',
  12298: 'es-ec',
  3082: 'es-es',
  2058: 'es-mx',
  10250: 'es-pe',
  21514: 'es-us',
  8202: 'es-ve',
  // Note: my system did not find an LCID for es-xl (MSN's Spanish - Latin America), so I am using placeholder -3
  '-3': 'es-xl',
  1035: 'fi-fi',
  2060: 'fr-be',
  3084: 'fr-ca',
  4108: 'fr-ch',
  1036: 'fr-fr',
  5132: 'fr-lu',
  // Note: my system did not find an LCID for fr-xl (MSN's French - Africa), so I am using placeholder -2
  '-2': 'fr-xl',
  1037: 'he-il',
  1081: 'hi-in',
  1038: 'hu-hu',
  1057: 'id-id',
  1040: 'it-it',
  1041: 'ja-jp',
  1042: 'ko-kr',
  1063: 'lt-lt',
  1102: 'mr-in',
  1044: 'nb-no',
  2067: 'nl-be',
  1043: 'nl-nl',
  1045: 'pl-pl',
  1046: 'pt-br',
  2070: 'pt-pt',
  1049: 'ru-ru',
  1053: 'sv-se',
  1098: 'te-in',
  1054: 'th-th',
  1055: 'tr-tr',
  1066: 'vi-vn',
  2052: 'zh-cn',
  3076: 'zh-hk',
  4100: 'zh-sg',
  1028: 'zh-tw',
};

export const reverseLocales = {
  'en-us': 1033,
  'fr-fr': 1036,
  'de-de': 1031,
  'it-it': 1040,
  'en-gb': 2057,
  'fr-ca': 3084,
  'es-es': 3082,
  'es-mx': 2058,
};
