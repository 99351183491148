import { IDictionary } from '../../../../globalization';

export enum NotificationLabel {
  // Critical
  ProfileReviewTSTFailed = 'ProfileReviewTSTFailed',
  ProfileReviewPublisherReputationFailed = 'ProfileReviewPublisherReputationFailed',
  PaymentFailed = 'PaymentFailed',
  PolicyUpdate = 'PolicyUpdate',
  ClarityLinkDeclined = 'ClarityLinkDeclined',
  ClarityLinkExpired = 'ClarityLinkExpired',

  ProfileReviewPublisherReputationPassed = 'ProfileReviewPublisherReputationPassed',

  // Payment failed reasons
  PaymentFailedAccountClosed = 'PaymentFailedAccountClosed',
  PaymentFailedBankAccountRestricted = 'PaymentFailedBankAccountRestricted',
  PaymentFailedStripeCouldNotProcess = 'PaymentFailedStripeCouldNotProcess',
  PaymentFailedCouldNotProcess = 'PaymentFailedCouldNotProcess',
  PaymentFailedDeclined = 'PaymentFailedDeclined',
  PaymentFailedExpiredCard = 'PaymentFailedExpiredCard',
  PaymentFailedIncorrectAccountHolderName = 'PaymentFailedIncorrectAccountHolderName',
  PaymentFailedInvalidAccountNumber = 'PaymentFailedInvalidAccountNumber',
  PaymentFailedInvalidCard = 'PaymentFailedInvalidCard',
  PaymentFailedInvalidCurrency = 'PaymentFailedInvalidCurrency',
  PaymentFailedLostOrStolenCard = 'PaymentFailedLostOrStolenCard',
  PaymentFailedNoAccount = 'PaymentFailedNoAccount',
  PaymentFailedNoAccountLocated = 'PaymentFailedNoAccountLocated',

  //  b. Warning
  DomainReviewPassed = 'DomainReviewPassed',
  DomainReviewFailed = 'DomainReviewFailed',
  MissingPaymentInformation = 'MissingPaymentInformation',

  UserSpecificTestLabel = 'UserSpecificTestLabel',

  SomeTestAnnouncement = 'SomeTestAnnouncement',

  // Error
  ChooseGamNetworkFailure = 'ChooseGamNetworkFailure',
  ChangingItemStatusFailure = 'ChangingItemStatusFailure',
  DeletingItemFailure = 'DeletingItemFailure',
  GridDataDownloadFailure = 'GridDataDownloadFailure',
  DuplicateGridItemFailure = 'DuplicateGridItemFailure',
  FeedbackErrorMessage = 'FeedbackErrorMessage',
  FeedBackInvalidFormMessage = 'FeedBackInvalidFormMessage',
  LoadingBalanceDataFailed = 'LoadingBalanceDataFailed',
  PayoutInformationFailed = 'PayoutInformationFailed',
  LoadPayoutInfoFailed = 'LoadPayoutInfoFailed',
  DeletingUserWentWrong = 'DeletingUserWentWrong',
  AdblockUpdateFailed = 'AdblockUpdateFailed',
  AdblockToggleFailed = 'AdblockToggleFailed',
  ToggleUniversalBlockFailed = 'ToggleUniversalBlockFailed',
  UniversalAdblockUpdateFailed = 'UniversalAdblockUpdateFailed',
  DuplicatedSiteCreationErrorNotification = 'DuplicatedSiteCreationErrorNotification',
  LoadingSitesFailed = 'LoadingSitesFailed',
  PropertyEditFailedNotification = 'PropertyEditFailedNotification',
  CreateTythonAdUnitFailed = 'CreateTythonAdUnitFailed',
}

export const mappingReasonsToLabels: IDictionary<NotificationLabel> = {
  PaymentFailedReason_account_closed: NotificationLabel.PaymentFailedAccountClosed,
  PaymentFailedReason_bank_account_restricted: NotificationLabel.PaymentFailedBankAccountRestricted,
  PaymentFailedReasonStripe_could_not_process: NotificationLabel.PaymentFailedStripeCouldNotProcess,
  PaymentFailedReason_could_not_process: NotificationLabel.PaymentFailedCouldNotProcess,
  PaymentFailedReason_declined: NotificationLabel.PaymentFailedDeclined,
  PaymentFailedReason_expired_card: NotificationLabel.PaymentFailedExpiredCard,
  PaymentFailedReason_incorrect_account_holder_name: NotificationLabel.PaymentFailedIncorrectAccountHolderName,
  PaymentFailedReason_invalid_account_number: NotificationLabel.PaymentFailedInvalidAccountNumber,
  PaymentFailedReason_invalid_card: NotificationLabel.PaymentFailedInvalidCard,
  PaymentFailedReason_invalid_currency: NotificationLabel.PaymentFailedInvalidCurrency,
  PaymentFailedReason_lost_or_stolen_card: NotificationLabel.PaymentFailedLostOrStolenCard,
  PaymentFailedReason_no_account: NotificationLabel.PaymentFailedNoAccount,
  PaymentFailedReasonLocate_no_account: NotificationLabel.PaymentFailedNoAccountLocated,
};

export const primaryExternalNotificationLabels = [
  NotificationLabel.PolicyUpdate,
  NotificationLabel.PaymentFailed,
  NotificationLabel.PaymentFailedAccountClosed,
  NotificationLabel.PaymentFailedBankAccountRestricted,
  NotificationLabel.PaymentFailedStripeCouldNotProcess,
  NotificationLabel.PaymentFailedCouldNotProcess,
  NotificationLabel.PaymentFailedDeclined,
  NotificationLabel.PaymentFailedExpiredCard,
  NotificationLabel.PaymentFailedIncorrectAccountHolderName,
  NotificationLabel.PaymentFailedInvalidAccountNumber,
  NotificationLabel.PaymentFailedInvalidCard,
  NotificationLabel.PaymentFailedInvalidCurrency,
  NotificationLabel.PaymentFailedLostOrStolenCard,
  NotificationLabel.PaymentFailedNoAccount,
  NotificationLabel.PaymentFailedNoAccountLocated,
  NotificationLabel.ClarityLinkDeclined,
  NotificationLabel.ClarityLinkExpired,
];
