import { Model } from './Model';

export class PhoneVerificationVerifyCode extends Model {
  public apiPath = 'tokenVerification/verifyCode';
  public className = 'verifyCode';
  public requestId: string;
  public code: string;
  public data: IPhoneVerificationData;
}

export class PhoneVerificationGenerateCode extends Model {
  public apiPath = 'tokenVerification/generateCode';
  public className = 'generateCode';
  public phoneNumberWithCountryCode?: string;
  public email?: string;
  public locale: string;
  public verificationMediumType: PhoneVerificationMediumType;
  public data: IPhoneVerificationData;
}

export interface IPhoneVerificationData {
  requestId: string;
  status: string;
  statusId: number;
  subStatus: string;
  createdDateTime: string;
}

export enum PhoneVerificationMediumType {
  email = 'Email',
  phoneSms = 'PhoneSms',
  phoneCall = 'PhoneVoice',
}

// Might need to make Status/SubStatus enum
export enum PhoneVerificationSubStatus {
  codeMismatched = 'Code mismatched',
  codeMatched = 'Code matched',
  codeSent = 'Code sent',
  deliveryFailed = 'Code delivery failed',
  expired = 'Expired',
}
export enum VerificationStatus {
  PASS = 'Pass',
  FAILED = 'Fail',
  IN_PROGRESS = 'In progress',
}
