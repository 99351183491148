import { getAppStore, getEntity, Property, Publisher, UserContext } from '../../../../../@data';
import { PropertyServingConfig } from '../../../../../@data/store/schema/models/ServingConfigs';

export async function fetchPropertyServingConfig(
  publisher: Publisher,
  property: Property,
  userContext?: UserContext
): Promise<PropertyServingConfig> {
  const effectiveUserContext = userContext || getAppStore().userContext;
  const propertyServingConfig = await getEntity([publisher, property], effectiveUserContext, PropertyServingConfig);
  return propertyServingConfig;
}
