import { defineMessages } from 'react-intl';

export default defineMessages({
  adsText: {
    id: 'adsText.adsTxt',
    defaultMessage: `Ads.txt`,
  },
  adsTextExplanation: {
    id: 'adsText.explanation',
    defaultMessage: `As a {appName} publisher, you’re required to declare that {appName} is an authorized seller for your advertising inventory by including your publisher ID in your ads.txt file. Please note that each site you register with {appName} must have an ads.txt file that adheres to these guidelines.`,
  },
  adsTextObligation: {
    id: 'adsText.AdsTextObligation',
    defaultMessage:
      'As a {appName} publisher, you’re required to declare that {appName} is an authorized seller by including your publisher ID in your ads.txt file. Each site you register with your {appName} account must have an ads.txt file added to the root directory. The root directory refers to the folder or directory immediately following the top-level domain of your website (e.g., contoso.com/ads.txt).',
  },
  createAdsTxtStepTitle: {
    id: 'adsText.createAdsTxtStepTitle',
    defaultMessage: 'Create an ads.txt file',
  },
  addpubCenterToFileStepTitle: {
    id: 'adsText.addpubCenterToFileStepTitle',
    defaultMessage: 'Add {appName} to an existing ads.txt file',
  },
  copyCodeButton: {
    id: 'adsText.copyCodeButton',
    defaultMessage: 'Copy',
  },
  stepOne: {
    id: 'adsText.stepOne',
    defaultMessage: `Step 1`,
  },
  stepTwo: {
    id: 'adsText.stepTwo',
    defaultMessage: 'Step 2',
  },
  stepThree: {
    id: 'adsText.stepThree',
    defaultMessage: 'Step 3',
  },
  downloadFilePrefix: {
    id: 'adsText.downloadFilePrefix',
    defaultMessage: 'Download an ',
  },
  downloadFile: {
    id: 'adsText.downloadFile',
    defaultMessage: 'ads.txt file.',
  },
  addingFileToRootDirectoryStep: {
    id: 'adsText.addingFileToRootDirectory',
    defaultMessage:
      'Upload your ads.txt file to the root directory of your site. The root directory refers to the folder or directory immediately following the top level domain of your website (e.g. contoso.com/ads.txt).',
  },
  verifyingFileStep: {
    id: 'adsText.verifyingFileStep',
    defaultMessage:
      'Check if the content of the file is displayed when you access the ads.txt URL (https://example.com/ads.txt) using a web browser. If you can view the file content in the browser, it’s likely that {appName} will be able to locate it successfully.',
  },
  addpubCenterToFileStep: {
    id: 'adsText.addpubCenterToFileStep',
    defaultMessage: 'Find your existing ads.txt and copy paste the following records',
  },
});
