import {
  classNamesFunction,
  DefaultButton,
  Dialog,
  DialogType,
  getTheme,
  ImageBase,
  ITheme,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean, useSetTimeout } from '@fluentui/react-hooks';
import React, { useEffect, useMemo, useRef } from 'react';
import notificationMessages from '../../.../../NotificationsPanel.Types.messages';
import { useI18n } from '../../../i18n/useI18n';
import {
  EntityEvaluationStatus,
  fetchBackendNotifications,
  getAppStore,
  getPublisherEvaluationApprovalStatus,
  NotificationLabel,
  NotificationSeverity,
  onNotificationCardAdded,
  onNotificationCardRemoved,
  onNotificationChanged,
  onNotificationRemoved,
  PaymentsPageBalance,
} from '../../@data';
import { removeToastNotification } from '../../@data/mutatorActions';
import { isTythonSelfServeUser } from '../../@data/utils/checkUserType';
import { NotificationBar } from '../../components/NotificationBar';
import { TnCModal } from '../../components/TnCModal';
import { ToastNotification } from '../../components/ToastNotification';
import { APP_NAME_NEW } from '../../constants/AppConstants';
import { useWindowSize } from '../../hooks';
import { getStore } from '../../hooks/useWindowSize/store/store';
import { PAYMENTS_TAB_KEY } from '../../pages/ad-management/Account';
import { fetchAccountStatus, fetchPaymentsPageBalance } from '../../pages/ad-management/Account/@data/actions';
import { getAccountStatus, getPaymentsPageBalance } from '../../pages/ad-management/Account/@data/store/selectors';
import { SetupPaymentNotification } from '../../pages/ad-management/Account/Payments/Onboarding/PaymentsOnboarding';
import { paymentCheerMessages } from '../../pages/ad-management/Account/Payments/Onboarding/paymentsOnboarding.messages';
import {
  NOTIFICATION_RENDER_BUFFER,
  setPaymentNotificationBuffer,
  SETUP_PAYMENTS_CHEER_DIALOG_FLAG,
  SETUP_PAYMENTS_NOTIFICATION_FLAG,
  SETUP_PAYMENTS_NOTIFICATION_ID,
} from '../../pages/ad-management/Account/Payments/Onboarding/usePaymentsOnboarding';
import { onFetchUserPreferences } from '../../pages/ad-management/Account/Settings/@data/actions';
import { getStore as getUserSettingStore } from '../../pages/ad-management/Account/Settings/@data/store/store';
import dialogImg from '../../pages/ad-management/Home/resources/partyPopper.svg';
import { getStore as getTnCStore } from '../../pages/terms-and-conditions/@data/store/store';
import useTnC from '../../pages/terms-and-conditions/useTnC';
import { AccountPageRoute, DefaultRoute } from '../../Routes';
import { injectRouterAndIntlWithObserver, IRouterWithIntl } from '../../utils';
import messages from './NotificationLayout.messages';
import { getStyles, INotificationLayoutStyles } from './NotificationLayout.styles';

const getClassNames = classNamesFunction<{ theme: ITheme }, INotificationLayoutStyles>();

export interface INotificationLayoutProps {
  children?: React.ReactNode;
  renderCustomNotifications?: () => React.ReactNode;
  ignoreGlobalAsync?: boolean;
}

export const NotificationLayout = injectRouterAndIntlWithObserver(
  ({ children, intl, renderCustomNotifications, history, ignoreGlobalAsync = false }: INotificationLayoutProps & IRouterWithIntl) => {
    // TODO: wrap render logic into a hook
    const classNames = getClassNames(getStyles, { theme: getTheme() });
    const { notifications: barNotifications, publisher, userContext, account, toastNotifications, menuCollapsed, loading } = getAppStore();
    const { isDataLoaded, data } = getPaymentsPageBalance();
    const accountStatus = getAccountStatus();
    const { setTimeout } = useSetTimeout();
    const isPaymentNotificationHandledPerUserSession = localStorage.getItem(SETUP_PAYMENTS_NOTIFICATION_FLAG);
    const isPaymentCheerDialogHandledPerUserSession = localStorage.getItem(SETUP_PAYMENTS_CHEER_DIALOG_FLAG);

    const { systemPreferences } = getUserSettingStore().active;
    const { locale } = useI18n({ systemPreferences, publisher, userContext });

    const [paymentCheerDialogActive, { setFalse: dismissPaymentCheerDialog, setTrue: activatePaymentCheerDialog }] = useBoolean(false);
    const { windowStoreSerializer } = useWindowSize();
    const { isWindowSmallSize, isWindowExtraSmallSize } = windowStoreSerializer(getStore());
    const publisherEvaluationStatus = getPublisherEvaluationApprovalStatus();
    const handledPreference = useRef<boolean>(false);
    const tncStore = getTnCStore();
    const { tncSignatureStatus } = getUserSettingStore().active;
    const { tncModalProps } = useTnC({
      barNotifications,
      tncStore,
      tncSignatureStatus,
      userContext,
      publisher,
      shouldDisableTnC: isTythonSelfServeUser() && !ignoreGlobalAsync,
      isAppLoading: loading,
    });
    const MissingPaymentInformationNotificationId = 'MissingPaymentInformationNotification';
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const windowLocationPath = useMemo(() => window.location.pathname, [window.location.pathname]);

    /**
     * fetch user level account preference for tython users
     */
    const shouldFetchUserPreference = useMemo(
      () => !ignoreGlobalAsync && publisher && userContext && !handledPreference.current && isTythonSelfServeUser(),
      [publisher, userContext, ignoreGlobalAsync]
    );

    /**
     * fetch account status and balance when tython user landed default route
     */
    const shouldFetchPaymentOnboarding = useMemo(
      () => publisher?.id && isTythonSelfServeUser() && windowLocationPath.includes(DefaultRoute),
      [publisher, windowLocationPath]
    );

    /**
     * fetch backend notifications when tython user landed default route
     */
    const shouldFetchBackendNotifications = useMemo(
      () => publisher?.id && account?.id && userContext?.id && isTythonSelfServeUser() && windowLocationPath.includes(DefaultRoute),
      [publisher, account, userContext, windowLocationPath]
    );

    /**
     * hide notification when window in smaller screen and menu is expanded to save some screen space
     */
    const shouldHideBarNotifications = useMemo(() => (isWindowExtraSmallSize || isWindowSmallSize) && !menuCollapsed, [
      isWindowExtraSmallSize,
      isWindowSmallSize,
      menuCollapsed,
    ]);

    /**
     * handle payment notification push based on current route and localstorage flag
     * as this notification suppose to show only once per valid user login
     * push notification when
     * - account status is loaded
     * - balance is loaded
     * - notification localStorage flag is empty
     * - Publisher evaluation status is not rejected
     * - user is tython super admin
     */
    const shouldPushPaymentNotification = useMemo(
      () =>
        accountStatus === 'NotCreated' &&
        isDataLoaded &&
        !isPaymentNotificationHandledPerUserSession &&
        shouldFetchPaymentOnboarding &&
        publisherEvaluationStatus &&
        publisherEvaluationStatus !== EntityEvaluationStatus.Rejected &&
        isTythonSelfServeUser() &&
        userContext?.userRoles?.includes(41),
      [
        isDataLoaded,
        accountStatus,
        isPaymentNotificationHandledPerUserSession,
        shouldFetchPaymentOnboarding,
        publisherEvaluationStatus,
        userContext,
      ]
    );

    /**
     * remove payment notification in panel based on account status
     */
    const shouldRemovePaymentNotificationInPanel = useMemo(
      () =>
        shouldFetchPaymentOnboarding &&
        accountStatus &&
        (accountStatus === 'Active' || accountStatus === 'InActive' || accountStatus === 'Error') &&
        isDataLoaded,
      [isDataLoaded, accountStatus, shouldFetchPaymentOnboarding]
    );

    /**
     * handle payment cheer message render behavior, render the cheer popup only when
     * - notification is valid to push
     * - user balance > threshold
     */
    const shouldTriggerPaymentCheerDialog = useMemo(
      () =>
        shouldPushPaymentNotification &&
        !isPaymentCheerDialogHandledPerUserSession &&
        (data as PaymentsPageBalance).currBalance > (data as PaymentsPageBalance).threshold,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [shouldPushPaymentNotification, isPaymentCheerDialogHandledPerUserSession]
    );

    const setupPaymentNotification = {
      messageBarType: 5,
      component: <SetupPaymentNotification publisher={publisher?.id} onAction={() => onNotificationRemoved(setupPaymentNotification)} />,
      suppressAutoDismiss: true,
      id: SETUP_PAYMENTS_NOTIFICATION_ID,
      messageBarStyles: {
        text: {
          marginTop: 0,
          marginBottom: 0,
          alignItems: 'center',
          paddingTop: 2,
        },
        content: { alignItems: 'center' },
      },
    };

    useEffect(() => {
      if (shouldFetchUserPreference) {
        onFetchUserPreferences(locale);
        handledPreference.current = true;
      }
    }, [shouldFetchUserPreference, locale]);

    useEffect(() => {
      shouldFetchPaymentOnboarding && fetchPaymentsPageBalance();
      shouldFetchPaymentOnboarding && fetchAccountStatus();
    }, [shouldFetchPaymentOnboarding]);

    useEffect(() => {
      if (shouldFetchBackendNotifications) {
        fetchBackendNotifications(intl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetchBackendNotifications]);

    useEffect(() => {
      if (shouldPushPaymentNotification) {
        onNotificationCardAdded({
          // tslint:disable-next-line: no-any
          id: MissingPaymentInformationNotificationId,
          notificationLabel: NotificationLabel.MissingPaymentInformation,
          severity: NotificationSeverity.Warning,
          title: intl.formatMessage(notificationMessages.MissingPaymentInformationTitle),
          content: intl.formatMessage(notificationMessages.MissingPaymentInformationContent, { appName: APP_NAME_NEW }),
          intlContentMessageValues: { appName: APP_NAME_NEW },
          primaryActionText: intl.formatMessage(notificationMessages.MissingPaymentInformationPrimaryActionLabel),
          primaryActionLink: `/ad-management/${publisher?.id}${AccountPageRoute}#payments`,
        });
        setTimeout(() => {
          onNotificationChanged(setupPaymentNotification);
          setPaymentNotificationBuffer(SETUP_PAYMENTS_NOTIFICATION_FLAG);
        }, NOTIFICATION_RENDER_BUFFER);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldPushPaymentNotification]);

    useEffect(() => {
      if (shouldRemovePaymentNotificationInPanel) {
        onNotificationCardRemoved(MissingPaymentInformationNotificationId);
      }
    }, [shouldRemovePaymentNotificationInPanel]);

    useEffect(() => {
      if (shouldTriggerPaymentCheerDialog) {
        activatePaymentCheerDialog();
        setPaymentNotificationBuffer(SETUP_PAYMENTS_CHEER_DIALOG_FLAG);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldTriggerPaymentCheerDialog]);

    const handlePaymentSetupClick = () => {
      dismissPaymentCheerDialog();
      if (publisher?.id) {
        history.push(`/ad-management/${publisher.id}${AccountPageRoute}#payments`, { defaultTab: PAYMENTS_TAB_KEY });
      }
    };

    return (
      <>
        {renderCustomNotifications?.()}
        <ToastNotification
          items={toastNotifications}
          intl={intl}
          onRemoved={(notification) => removeToastNotification(notification)}
          dismissButtonAriaLabel={intl.formatMessage(messages.messageBarDismissButtonAriaLabel)}
        />
        <NotificationBar
          intl={intl}
          items={shouldHideBarNotifications ? [] : barNotifications}
          onRemoved={(notification) => onNotificationRemoved(notification)}
          dismissButtonAriaLabel={intl.formatMessage(messages.messageBarDismissButtonAriaLabel)}
        />
        <Dialog
          hidden={!paymentCheerDialogActive}
          onDismiss={dismissPaymentCheerDialog}
          dialogContentProps={{
            type: DialogType.normal,
            closeButtonAriaLabel: intl.formatMessage(paymentCheerMessages.cheerMessageCancelArialabel),
          }}
          styles={{ main: classNames.dialogRoot }}
        >
          <Stack horizontal className={classNames.dialogWrapper}>
            <Stack.Item grow={1} className={classNames.imgWrapper}>
              <ImageBase src={dialogImg} loading="lazy" />
            </Stack.Item>
            <Stack.Item grow={5}>
              <Stack verticalAlign="space-between" className={classNames.dialogContent}>
                <Stack.Item>
                  <Text variant="xLarge">{intl.formatMessage(paymentCheerMessages.cheerMessageTitle)}</Text>
                </Stack.Item>
                <Stack.Item>
                  <Text>{intl.formatMessage(paymentCheerMessages.cheerMessageContent)}</Text>
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontal horizontalAlign="space-between">
                    <Stack.Item>
                      <PrimaryButton onClick={handlePaymentSetupClick}>
                        {intl.formatMessage(paymentCheerMessages.cheerMessageButtonAction)}
                      </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item>
                      <DefaultButton onClick={dismissPaymentCheerDialog}>
                        {intl.formatMessage(paymentCheerMessages.cheerMessageButtonCancel)}
                      </DefaultButton>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Dialog>
        <TnCModal {...tncModalProps} />
        {children}
      </>
    );
  }
);
