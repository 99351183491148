import React from 'react';
import { getClassNames } from './EmptyChart.styles';

const DATA_METRIC_ID = 'empty-chart';
export interface IEmptyChartProps {
  noDataText: string;
  noDataMessage: string;
  showGridLines: Boolean;
}

export const EmptyChart = (props: IEmptyChartProps) => {
  const classNames = getClassNames(props);
  const { noDataMessage, noDataText, showGridLines } = props;
  return (
    <div className={classNames.container} data-metric={DATA_METRIC_ID}>
      <div className={classNames.noDataText}>
        <div>
          <b>{noDataText}</b> {noDataMessage}
        </div>
      </div>
      {showGridLines && (
        <div className={classNames.chartGrid}>
          <hr className={classNames.chartLine} />
          <hr className={classNames.chartLine} />
          <hr className={classNames.chartLine} />
          <hr className={classNames.chartLine} />
        </div>
      )}
    </div>
  );
};
