import { Model } from '../../../../../../../@data';

export class SAMBlockAdData extends Model {
  public apiPath = undefined;
  public className = 'samBlockAdsData';
  public creativeAdId?: string;
  public adTitle?: string;
  public allAdImages?: string[];
  public advertiserName?: string;
  public adProvider?: string;
  public blockRequestSourceId?: number;
  public reasonCategoryId?: number;
  public reasonCategoryString?: string;
  public blockReasonMainCategory?: string;
  public blockReasonSubCategory?: string;
  public reasonComment?: string;
  public modifiedBy?: number;
  public publisherId?: number;
  public sevenDayRevenueImpact?: number;
  public localeScope?: string;
  public filterSource?: string;
}
