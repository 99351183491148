import { IPubcenterPalette, IPubcenterSpacing } from '../../../theme';
import { IReportPageStyleProps, IReportPageStyles } from './ReportPage.types';

export const getStyles = (props: IReportPageStyleProps): IReportPageStyles => {
  const { theme } = props;
  const spacing = theme.spacing as IPubcenterSpacing;
  const pallete = theme.palette as IPubcenterPalette;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      flex: 1,
    },
    description: {
      color: theme.palette.neutralSecondary,
      position: 'relative',
      marginTop: spacing.size12,
      fontSize: theme.fonts.medium.fontSize,
    },
    link: [
      'ms-fontSize-Body14',
      {
        color: pallete.themePrimary,
        selectors: {
          ':hover': {
            cursor: 'pointer',
          },
        },
        marginTop: spacing.size12,
      },
    ],
    linkTitle: {
      marginLeft: '6px',
      position: 'relative',
      textAlign: 'center',
    },
    subComponentStyles: {
      formTitle: {
        root: {
          marginBottom: spacing.size24,
        },
      },
    },
  };
};
