import { Log } from '../../../../../../logging/src';
import {
  AuditHistoryRequest,
  buildRequestHeadersWithAuthToken,
  getAppStore,
  handleApiErrors,
  showErrorToastNotification,
} from '../../../../../@data';
import { uiConfig } from '../../../../../config/ui.config';
import AuditHistoryMessages from '../../AuditHistory.messages';
import { setIsDataLoading } from '../actions';
import { getAuditHistoryStore } from '../store/store';

export async function fetchAuditLogs(pageSize?: number, shouldUpdateGrid = true) {
  const { publisher, userContext } = getAppStore();

  if (publisher && userContext) {
    const url = generateAuditHistoryUrl(publisher.id, pageSize);
    const headers = await buildRequestHeadersWithAuthToken(url, userContext);

    let response: Response;

    const auditHistoryStore = getAuditHistoryStore();
    const { startDate, endDate, filters, entityTypes, userRoles, auditTypes } = auditHistoryStore;

    // rounding off to last minute to avoid int overflow
    startDate.setMinutes(startDate.getHours(), startDate.getMinutes(), 0);
    endDate.setUTCMinutes(endDate.getHours(), endDate.getMinutes(), 0);

    const auditHistoryRequest: AuditHistoryRequest = {
      startDateTime: startDate,
      endDateTime: endDate,
      auditTypes: auditTypes,
      userRoles: userRoles,
      entityTypes: entityTypes,
      filters: filters,
    };

    try {
      if (shouldUpdateGrid) {
        setIsDataLoading(true);
      }

      response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(auditHistoryRequest),
      });

      if (!response.ok) {
        await handleApiErrors(response, url);
      }

      const responseJson = (await response.json()).data;
      return responseJson;
    } catch (err) {
      const error = `Failed to fetch audit history ${url}. Errors: ${err}`;
      Log.error(error);
      showErrorToastNotification({ textMessageDescriptor: AuditHistoryMessages.apiCallFailedNotification });
    } finally {
      setIsDataLoading(false);
    }
  }
}

function generateAuditHistoryUrl(publisherId: string, inputPageSize?: number): string {
  const url = uiConfig.getApiBaseAddress() + '/publishers/' + publisherId + '/changelogs/fetch';

  const { pageNumber, pageSize } = getAuditHistoryStore();
  const queryString = `PageSize=${inputPageSize ?? pageSize ?? 10}&PageNumber=${pageNumber ?? 1}`;

  return `${url}?${queryString}`;
}
