import { createStore } from 'satcheljs';
import { IProfileViewState } from './schema';

// !NOTE: This is to be added explicitly for the orchestrators to register themselves.
import { PhoneVerificationGenerateCode, PhoneVerificationVerifyCode } from '../../../../../@data';
import '../orchestrators';

export const getProfileStore = createStore<IProfileViewState>('ProfileViewState', {
  active: {
    phoneVerificationGenerateCode: new PhoneVerificationGenerateCode(),
    phoneVerificationVerifyCode: new PhoneVerificationVerifyCode(),
    verificationCodeGenerationLimitReached: false,
  },
  raw: {},
  errors: {},
});
