import React, { useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { isTythonSelfServeUser } from '../../../../../@data';
import { SupportPageRoute } from '../../../../../Routes';
import { APP_NAME_NEW, SUPPORT_EMAIL } from '../../../../../constants/AppConstants';
import { injectIntlAndObserver } from '../../../../../utils';
import { getStore } from '../../@data/store/store';
import messages from './FAQSection.messages';
import { getClassNames } from './FAQSection.styles';
import FAQCard, { IFAQProps } from './components/FAQCard';

const FAQSection = injectIntlAndObserver<InjectedIntlProps>(({ intl: { formatMessage, formatHTMLMessage } }) => {
  const { container, headerSection } = getClassNames();
  // TODO - get isSetupDone from the store to control display of section
  const { isSetupDone } = getStore();
  const [isOpen, setIsOpen] = useState(-1);
  const resources: IFAQProps[] = [
    {
      title: formatMessage(messages.firstQuestion, { appName: APP_NAME_NEW }),
      description: formatMessage(messages.firstAnswer),
    },
    {
      title: formatMessage(messages.secondQuestion),
      description: (
        <>
          {formatMessage(messages.secondAnswer)}
          <a href="https://about.ads.microsoft.com/en-us/h/a/publishers">{formatMessage(messages.redirectUser)}</a>
        </>
      ),
    },
    {
      title: formatMessage(messages.thirdQuestion),
      description: formatMessage(messages.thirdAnswer),
    },
    {
      title: formatMessage(messages.fourthQuestion),
      description: formatMessage(messages.fourthAnswer),
    },
    {
      title: formatMessage(messages.fifthQuestion),
      description: (
        <>
          {formatMessage(messages.fifthAnswer)}
          <a href={isTythonSelfServeUser() ? `${SupportPageRoute}` : `mailto:${SUPPORT_EMAIL}`}>{formatMessage(messages.contactSupport)}</a>
        </>
      ),
    },
  ];

  return (
    <>
      {!isSetupDone ? (
        <div>
          <h2 className={headerSection}>{formatMessage(messages.faqTitle)}</h2>
          <div className={container}>
            {resources.map((resource, index) => (
              <FAQCard key={index} {...resource} isOpen={isOpen === index} order={index} setIsOpen={setIsOpen} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
});

export default FAQSection;
