import React from 'react';

export const useNativeAdSdk = () => {
  const [isSdkLoaded, setSdkLoaded] = React.useState(false);

  // load ad preview SDK at parent level to cache the script
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://adsdk.microsoft.com/ad-preview/sdk.js';
    script.async = true;
    script.crossOrigin = 'anonymous';

    script.onload = () => {
      setSdkLoaded(true);
    };

    document.head.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return {
    isSdkLoaded,
  };
};
