import { action } from 'satcheljs';

export const onInitializeRecommendationSection = action('onInitializeRecommendationSection');
export const fetchSetupCardsStatus = action('fetchSetupCardsStatus');
export const triggerOneVetAPIs = action('triggerOneVetAPIs', (eventType: 'login' | 'signup', useCache?: boolean) => ({
  eventType,
  useCache,
}));
export const dismissFRE = action('dismissFRE');
export const onInitializeSystemPreferences = action('onInitializeSystemPreferences');
