import { AdsTxtStatus, ClarityStatus, InventoryPlatformType, Model } from '..';
import { BlockAdCategoryNames, BlockCategoryXandrNames } from '../../../../pages/ad-management/AdBlock/@data/constants/CategoryNames';
import { ServingStatus } from '../enums/ServingStatus';

export type IABCode = keyof typeof BlockAdCategoryNames;
export type XandrCat = keyof typeof BlockCategoryXandrNames;

export class ServingConfig extends Model {
  public apiPath = undefined;
  public className = 'servingConfig';
  public servingStatus: ServingStatus;
  public scriptStatus?: boolean;
  public adsDotTxtStatus?: AdsTxtStatus;
  public enablebCat?: boolean;
  public enablebAdv?: boolean;
  public enablexBrands?: boolean;
  public xBrands?: number[];
  public bCat?: IABCode[];
  public xCat?: XandrCat[];
  public bAdv?: string[];
  public eTag?: string;
  public isBusy?: boolean;
}

export class AdunitServingConfig extends ServingConfig {
  public adUnitId: number;
}

export class PublisherServingConfig extends ServingConfig {
  public publisherId: number;
}

export class PropertyServingConfig extends ServingConfig {
  public propertyId: number;
  public MicrosoftStoreAppId?: string | null;
  public COPPATimestamp?: string | null;
  public inventoryPlatformType?: InventoryPlatformType;
  public name?: string;
}

export class PropertyServingConfigs extends PropertyServingConfig {
  public url?: string;
  public className = 'propertyServingConfigs';
  public clarityConfig?: ClarityConfig;
  public name?: string;
}

export class AdunitServingConfigs extends AdunitServingConfig {
  public className = 'adUnitServingConfigs';
}

export class ClarityConfig {
  public clarityId?: string;
  public clarityType?: string;
  public clarityTs?: string;
  public clarityIsEnabled?: boolean;
  public clarityStatus?: ClarityStatus;
}
