import { getTheme, mergeStyleSets } from '@fluentui/react';
import { IPubcenterTheme } from '../../../../../theme';
import { ICommonStyleProps } from '../../../../types';
import { flexWithAlignCenter } from '../../../../utils/GlobalStyleUtils';

type StyleProperties = 'pageTitleStyle' | 'root' | 'deleteButton' | 'flexDisplay';
type SettingsPageStyles = Record<StyleProperties, string>;

export const getStyles = (props: ICommonStyleProps): SettingsPageStyles => {
  const theme = (props.theme as unknown) as IPubcenterTheme;
  const { spacing } = theme;

  return mergeStyleSets({
    pageTitleStyle: {
      margin: `${spacing.size32} 0 ${spacing.size16} 0`,
    },
    root: {
      margin: `0 ${spacing.size32}`,
      height: '-webkit-fill-available',
    },
    deleteButton: {
      color: theme.palette.redDark,
      selectors: {
        '&:focus, &:hover, &:pressed': {
          color: theme.palette.redDark,
        },
      },
    },
    footerRowStyle: {
      borderTop: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
      gap: spacing.size12,
      ...flexWithAlignCenter,
      margin: `${spacing.size24} -${spacing.size32} 0`,
      padding: `${spacing.size16} ${spacing.size32} 0`,
    },
    preTextStyle: {
      margin: 0,
      fontFamily: 'inherit',
    },
    fieldsContainerStyle: {
      display: 'flex',
      flexWrap: 'wrap',
      '> *': {
        flexBasis: '50%',
      },
      '> *:nth-child(odd)': {
        paddingRight: spacing.size24,
      },
      '> *:last-child': {
        marginBottom: spacing.size8,
      },
      label: {
        color: theme.palette.neutralPrimary,
      },
    },
    flexDisplay: {
      width: '100%',
    },
  });
};

export function getClassNames<PropType>(props?: PropType): SettingsPageStyles {
  return getStyles({ theme: getTheme(), ...props });
}
