import { MessageBarType } from '@fluentui/react';
import { when } from 'mobx';
import { orchestrator } from 'satcheljs';
import { PhoneVerificationMediumType, isOrganizationPublisher, onLoadingChanged, onNotificationChanged } from '../../../../../@data';
import { validateSchema } from '../../../../../utils';
import messages from '../../ProfilePage.messages';
import {
  generateVerificationCode,
  setIsAddressValid,
  setIsOrgAddressValid,
  setIsPhoneVerificationMethodsShown,
  setIsPreviewShownAction,
  setPhoneVerificationMediumType,
  setProfileErrorsAction,
  validateAndPreviewProfileChangesAction,
} from '../actions';
import { profileValidationModel } from '../store/schema';
import { getProfileActiveData, getPublisherProfileActiveData } from '../store/selectors';
import { getProfileStore } from '../store/store';

orchestrator(validateAndPreviewProfileChangesAction, async ({ profile, formatMessage, isVerificationRequired }) => {
  try {
    onLoadingChanged(true);
    await validateSchema(profileValidationModel(), profile);
    // validateAddress(getPublisherProfileActiveData(), formatMessage);
    setIsAddressValid(true);
    if (isOrganizationPublisher()) {
      // await validateOrgAddress(getPublisherProfileActiveData(), formatMessage);
      setIsOrgAddressValid(true);
    }
    setProfileErrorsAction();

    when(
      () => {
        const isAddressValid = getProfileActiveData().isAddressValid;
        const isOrgAddressValid = getProfileActiveData().isOrgAddressValid;

        let retValue = isAddressValid === true || isAddressValid === false;
        if (isOrganizationPublisher()) {
          retValue = retValue && (isOrgAddressValid === true || isOrgAddressValid === false);
        }

        return retValue;
      },
      async () => {
        let isAddressValid = getProfileActiveData().isAddressValid;
        if (isOrganizationPublisher()) {
          isAddressValid = isAddressValid && getProfileActiveData().isOrgAddressValid;
        }
        if (!getProfileStore().active.isLoading && isAddressValid) {
          if (isOrganizationPublisher()) {
            setPhoneVerificationMediumType(PhoneVerificationMediumType.email);
            if (isVerificationRequired) {
              generateVerificationCode(getPublisherProfileActiveData());
            } else {
              onLoadingChanged(false);
              setIsPreviewShownAction(true);
            }
          } else {
            onLoadingChanged(false);
            setIsPhoneVerificationMethodsShown(true);
          }
        }
        setIsAddressValid(undefined);
        setIsOrgAddressValid(undefined);
      }
    );
  } catch (e) {
    setProfileErrorsAction(e);
    onNotificationChanged({
      text: formatMessage(messages.profileInvalidFieldsErrorMessage, {
        count: Object.keys(e).length,
      }),
      messageBarType: MessageBarType.severeWarning,
    });
    onLoadingChanged(false);
    return;
  }
});
